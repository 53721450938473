import React from "react";
import { CircularProgress } from "@material-ui/core";
import { XAxis, YAxis, BarChart, Tooltip } from "recharts";
import Bar from "recharts/lib/cartesian/Bar";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";

const BarChartCustomers = (props) => {
  const { data, loader } = props;

  return (
    <>
      <div className="col-md-12">
        <div className="card shadow" style={{ border: "none" }}>
          <div className="card-body">
            <div className="card-text">
              {" "}
              <h5>
                {" "}
                <strong>Cartera de clientes</strong>{" "}
              </h5>
            </div>
            {loader ? (
              <div className="mx-auto text-center">
                <CircularProgress size={20} />
              </div>
            ) : data.length === 0 ? (
              <p>Sin datos para mostrar</p>
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart className="mx-auto" data={data}>
                  <XAxis dataKey="quarter" />
                  <YAxis dataKey="earnings" />
                  <Tooltip />
                  <Bar
                    dataKey="earnings"
                    fill="#33eaff"
                    activeBar={0}
                    activeIndex={0}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BarChartCustomers;
