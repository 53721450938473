import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import SettingsIcon from "@material-ui/icons/Settings";

// styles
import useStyles from "./styles";
import "./custom.css";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/admin/dashboard",
    icon: <img src="/images/admin-home.png" alt="icon" />,
  },
  {
    id: 1,
    label: "Estadísticas Globales",
    link: "/admin/stats",
    icon: <img src="/images/admin-reportes.png" alt="icon" />,
  },
  {
    id: 3,
    label: "Membresías",
    link: "/admin/membresia",
    icon: <img src="/images/admin-payments.png" alt="icon" />,
  },
  {
    id: 4,
    label: "Configuraciones",
    link: "/admin/configuraciones",
    icon: <img src="/images/config-icon.png" alt="icon" />,
  },
  {
    id: 5,
    label: "Formatos de facturas",
    link: "/admin/formatos",
    icon: <img src="/images/admin-factura.png" alt="icon" />,
  },
  {
    id: 6,
    label: "Usuarios Cloud",
    link: "/admin/usuarios",
    icon: <img src="/images/admin-cloud.png" alt="icon" />,
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      id="sidebar"
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {isSidebarOpened ? (
          ""
        ) : (
          <div className="text-center" style={{ marginBottom: "80px" }}>
            <img src="/images/admin-navbar-logo.png" />
          </div>
        )}

        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
