import { base_url, axios } from "../api";

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getAllDirections = async (id) => {
  return await axios.post(
    `${base_url}/web/cmi/obtener-direcciones-entrega-cliente`,
    { id_cliente_web_app: id },
    { headers: headers() }
  );
};

const createDirecction = async (direction) => {
  return await axios.post(`${base_url}/web/cmi/add-sale`, direction, {
    headers: headers(),
  });
};

export default {
  getAllDirections,
  createDirecction,
};
