// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link, useHistory } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import DataTable from "react-data-table-component";
import { CircularProgress } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";
import { isAleko } from "../../helpers/isAleko";
import { isCmi } from "../../helpers/isCmi";
import print from "print-js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import { getMoneda } from "../../helpers/moneda";

// components

export default function Users_all(props) {
  const moneda = getMoneda();
  const moment = require("moment");
  const history = useHistory();

  useEffect(() => {
    getCreditNote();
  }, []);

  // states
  const [nota_credito, set_notaCredito] = useState({
    serie_nc: "",
    motivo: "",
    client: {
      nombre: "",
    },
    fecha: "",
    vendedor: {
      nombre: "",
    },
    tipo_monto: 0,
    monto: "",
  });
  const [loader, setLoader] = useState(true);
  const [isGuatefactura, setIsGuatefactura] = useState(false);
  const [isCreditNote, setIsCreditNote] = useState(false);
  const [isGranContribuyente, setIsGranContribuyente] = useState(false);
  const [open, setOpen] = useState(false);

  // set states
  const convertir = require("numero-a-letras");
  const getTotalCredito = () =>
    Number(
      nota_credito.orderItems.reduce((a, b) => a + b.total_devolver, 0)
    ).toFixed(2);
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //function
  const getCreditNote = () => {
    let id_nota = props.match.params.id.toString();
    API.sales
      .getNotaCredito({ id_nota })
      .then((res) => {
        let response = res.data;
        console.log("res", res.data);
        if (response.success) {
          set_notaCredito(response.data);
          validCreditNote(response.data);
          setLoader(false);
          printCreditNote();
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const validCreditNote = (nota_credito) => {
    if (
      nota_credito.id_nota_credito_web_app != undefined ||
      nota_credito.id_nota_credito_web_app > 0
    ) {
      setIsCreditNote(true);
    }

    if (nota_credito?.certificador?.codigoSatGuatefactura != undefined) {
      setIsGuatefactura(true);
    }

    if (nota_credito.granContribuyente) {
      setIsGranContribuyente(true);
    }
  };

  const printCreditNote = () => {
    print({
      printable: "creditForm",
      type: "html",
      targetStyles: ["*"],
      modalMessage: "Imprimiendo",
      // font_size: '8px',
      showModal: true,
      onLoadingEnd: function () {
        setTimeout(() => setOpen(true), 2000);
      },
    });
  };

  const handleClose = (comeback) => {
    setOpen(false);

    if (comeback) {
      window.localStorage.removeItem("granContribuyente");
      window.localStorage.removeItem("cod_contribuyente");
    }

    if (comeback) {
      history.push("/app/operaciones_historial");
    }
  };

  const comebackSell = (event) => {
    if (event.key === "Enter") {
      if (open) {
        handleClose(true);
      }
    }
  };

  const openOriginalInvoice = () => {
    localStorage.setItem("invoice_data", JSON.stringify(nota_credito));

    const newWindow = window.open(
      "/#/app/vender/resumen/" + nota_credito.invoice.id_pos_movimiento,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const openCNInvoice = () => {
    /* localStorage.setItem("invoice_data", JSON.stringify(nota_credito));
    const newWindow = window.open(
      "/#/app/nota_credito/factura",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null; */
    printCreditNote();
  };

  return (
    <div id="dashboard-section">
      <Dialog
        open={open}
        onClose={handleClose}
        onKeyPress={comebackSell}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"CLOUD"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea regresar a Historial de Operaciones?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleClose(true)}
            color="primary"
          >
            Sí
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClose(false)}
            style={{ backgroundColor: "#ff3f3f", color: "white" }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Nota de crédito</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Resumen de nota de crédito</strong>
        </h4>
      </div>

      <form autocomplete="on">
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Resumen de nota de crédito</strong>
                </h5>
                <p className="mb-0">
                  Consulte los detalles de su nota de crédito.
                </p>
              </div>

              <div>
                <Button
                  type="submit"
                  className="btn-cs1 mx-1 mt-1 brand2-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/operaciones_historial"}
                  component={Link}
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Número de nota de crédito</strong>
                </label>
                {loader ? (
                  <div className="my-auto text-center">
                    <CircularProgress size={25} className="mx-auto" />
                  </div>
                ) : (
                  <TextField
                    disabled
                    value={nota_credito.serie_nc}
                    type="text"
                    className="w-100"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">N°</InputAdornment>
                      ),
                    }}
                  />
                )}
              </div>

              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Motivo de la nota de crédito</strong>
                </label>
                {loader ? (
                  <div className="my-auto text-center">
                    <CircularProgress size={25} className="mx-auto" />
                  </div>
                ) : (
                  <TextField
                    disabled
                    value={nota_credito.motivo}
                    type="text"
                    className="w-100"
                  />
                )}
              </div>

              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Nombre del cliente</strong>
                </label>
                {loader ? (
                  <div className="my-auto text-center">
                    <CircularProgress size={25} className="mx-auto" />
                  </div>
                ) : (
                  <TextField
                    disabled
                    value={nota_credito.client.nombre}
                    type="text"
                    className="w-100"
                  />
                )}
              </div>

              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Fecha de la emisión</strong>
                </label>
                {loader ? (
                  <div className="my-auto text-center">
                    <CircularProgress size={25} className="mx-auto" />
                  </div>
                ) : (
                  <TextField
                    value={moment(nota_credito.fecha).format("YYYY-MM-DD")}
                    disabled
                    type="date"
                    className="w-100"
                  />
                )}
              </div>

              {/*  Second Row */}
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Hora de la emisión</strong>
                </label>
                {loader ? (
                  <div className="my-auto text-center">
                    <CircularProgress size={25} className="mx-auto" />
                  </div>
                ) : (
                  <TextField
                    disabled
                    value={moment.utc(nota_credito.fecha).format("HH:mm:ss")}
                    type="time"
                    className="w-100"
                  />
                )}
              </div>

              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Vendedor</strong>
                </label>
                {loader ? (
                  <div className="my-auto text-center">
                    <CircularProgress size={25} className="mx-auto" />
                  </div>
                ) : (
                  <TextField
                    disabled
                    value={nota_credito.vendedor?.nombre}
                    type="text"
                    className="w-100"
                  />
                )}
              </div>

              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Tipo de devolución</strong>
                </label>
                {loader ? (
                  <div className="my-auto text-center">
                    <CircularProgress size={25} className="mx-auto" />
                  </div>
                ) : (
                  <TextField
                    disabled
                    value={nota_credito.tipo_monto === 1 ? "Parcial" : "Total"}
                    type="text"
                    className="w-100"
                  />
                )}
              </div>

              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Monto de la nota de crédito</strong>
                </label>
                {loader ? (
                  <div className="my-auto text-center">
                    <CircularProgress size={25} className="mx-auto" />
                  </div>
                ) : (
                  <TextField
                    disabled
                    value={nota_credito.monto}
                    type="text"
                    className="w-100"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3 d-flex justify-content-end">
                <Button
                  type="submit"
                  className="btn-cs1 mx-1 mt-1 brand2-btn"
                  variant="contained"
                  color="primary"
                  onClick={openOriginalInvoice}
                >
                  Ver factura original
                </Button>
                <Button
                  type="submit"
                  className="btn-cs1 mx-1 mt-1"
                  variant="contained"
                  color="primary"
                  onClick={openCNInvoice}
                >
                  Descargar recibo
                </Button>
              </div>

              {nota_credito.tipo_monto === 1 ? (
                <DataTable
                  className="datatable-cs datatable-cs-v2 datatable-h-80 mt-2"
                  title={"s"}
                  columns={[
                    {
                      name: "",
                      selector: "none",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <img
                            className="product-img"
                            src={row.img_url}
                            alt=""
                          />
                        </div>
                      ),
                    },
                    {
                      name: "Producto",
                      selector: "name",
                      sortable: true,
                      cell: (row) => (
                        <div className="w-100">
                          <div
                            className=""
                            style={{ width: "110px", fontSize: "0.7rem" }}
                          >
                            {row.nombre} <br />
                            <strong>{row.codigo}</strong>
                          </div>
                        </div>
                      ),
                    },
                    {
                      name: "Precio (IVA Includ.)",
                      selector: "price_iva",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}. {row.precio}
                        </span>
                      ),
                    },
                    {
                      name: "Cantidad",
                      selector: "qty",
                      sortable: true,
                      cell: (row) => <span>{row.cantidad}</span>,
                    },
                    {
                      name: "Precio Total",
                      selector: "price_total",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}. {row.precio_total}{" "}
                        </span>
                      ),
                    },
                    {
                      name: "Cantidad - devolución",
                      selector: "action",
                      cell: (row) => (
                        <div className="d-flex align-items-center w-100">
                          <input
                            value={row.cantidad_devolucion}
                            type="number"
                            style={{ maxWidth: "80px" }}
                            min="0"
                            max={row.qty}
                          />
                        </div>
                      ),
                    },
                    {
                      name: "Total a devolver",
                      selector: "total_devolver",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}. {row.total_devolver}{" "}
                        </span>
                      ),
                    },
                  ]}
                  data={nota_credito.orderItems}
                  pagination
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                />
              ) : (
                ""
              )}
            </div>
            {/* Nota de crédito */}
            {Object.keys(nota_credito).length > 0 ? (
              <div style={{ display: "none" }}>
                <div
                  style={{
                    width: isCmi() ? "col-md-12" : "1240px",
                    overflowX: isCmi() ? "none" : "none",
                    lineHeight: "normal",
                  }}
                  className="d-flex justify-content-center"
                  id="creditForm"
                >
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={16} className="mx-auto" />
                    </div>
                  ) : (
                    <div className="pdf-target px-4 py-3">
                      {nota_credito.fecha_anulacion ? (
                        <div className="anulado-overlay">
                          <p style={{ color: "red" }}>NOTA ANULADA</p>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* {nota_credito?.certificador?.codigoSatGuatefactura ? (
                        <div className="col-md-12 mx-auto text-center">
                          <img
                            className="mb-0 img_factura"
                            src={`${
                              isGuatefactura
                                ? `/images/avicola_icon.jpeg`
                                : "/images/cloudLogo.png"
                            }`}
                            alt="factura-imagen"
                          />
                        </div>
                      ) : (
                        ""
                      )} */}
                      {isAleko() ? (
                        <div className="col-12 d-flex justify-content-center">
                          <img
                            className="mb-0"
                            src={"/images/logo_aleko_v1.png"}
                            alt="factura-imagen"
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-md-12 mx-auto text-center">
                        <p className="mb-0">
                          <strong>{nota_credito.empresa.nombre}</strong>
                        </p>
                        <p className="mb-0">
                          <strong>NIT: {nota_credito.empresa.nit}</strong>
                        </p>
                        <p className="mb-0">
                          {nota_credito.sucursal.nombre_sucursal}
                        </p>
                        <p className="mb-0">
                          {nota_credito.sucursal.direccion_sucursal}
                        </p>
                      </div>

                      <br />

                      <div className="text-center">
                        <p className="mb-0">
                          <strong>DOCUMENTO TRIBUTARIO ELECTRÓNICO</strong>
                        </p>
                        <p className="mb-0">
                          <strong style={{ color: isGuatefactura ? "" : "" }}>
                            NOTA DE CRÉDITO
                          </strong>
                        </p>
                      </div>

                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "SERIE: " : "SERIE FEL:"}
                        </strong>
                        {nota_credito.felData.seriefel}
                      </p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "NÚMERO: " : "NÚMERO FEL:"}
                        </strong>
                        {nota_credito.felData.numerofel}
                      </p>
                      {isCreditNote ? (
                        <div className="col-12 px-0">
                          <p className="mb-0">
                            <strong>NÚMERO DE AUTORIZACIÓN</strong>
                          </p>
                          <p className="mb-0">{nota_credito.id_dte}</p>
                          <p>
                            <strong>FECHA DE EMISIÓN: </strong>

                            {nota_credito.datosCertificador.fecha_dte
                              ? nota_credito.datosCertificador.fecha_dte
                                  .replace("-", "/")
                                  .replace("-", "/")
                              : ""}
                            {/* {moment(nota_credito.datosCertificador.fecha_dte).utc().format("DD/MM/YYYY")}  */}
                          </p>
                        </div>
                      ) : (
                        <p className="mb-3">
                          <strong>Fecha Autorizacion FEL: </strong>
                          {nota_credito.fecha_correcta
                            ? moment(nota_credito.fecha_correcta).format(
                                "DD/MM/YYYY, h:mm:ss a"
                              )
                            : moment(nota_credito.fecha).format(
                                "DD/MM/YYYY, h:mm:ss a"
                              )}
                        </p>
                      )}

                      <div className="col-md-12 px-0 mt-2">
                        <p className="mb-0">
                          <strong>NIT:</strong> {nota_credito.client.nit}
                        </p>
                        <p className="mb-0">
                          <strong> NOMBRE: </strong>{" "}
                          {nota_credito.certificador
                            ? nota_credito.certificador.nombre
                              ? nota_credito.certificador.nombre
                              : nota_credito.client.nombre
                            : nota_credito.client.nombre}
                        </p>
                        <p className="mb-0">
                          <strong>DIRECCIÓN: </strong>
                          {nota_credito.client.direccion || "No especificada"}
                        </p>
                      </div>

                      {/* <div className="divider" /> */}
                      <hr style={{ borderTop: "3px dashed #bbb" }} />
                      <div className="row">
                        <div className="col-2 text-center">Cantidad</div>
                        <div className="col-4 text-center">Descripción</div>
                        <div className="col-4 text-center">
                          {" "}
                          {isGuatefactura ? "Precio en Q." : "Precio"}
                        </div>
                        <div className="col-2 text-center">
                          <strong>
                            {isGuatefactura ? "Total en Q." : "Total"}
                          </strong>
                        </div>
                      </div>
                      {/* <div className="divider" /> */}
                      <hr style={{ borderTop: "3px dashed #bbb" }} />
                      {nota_credito.orderItems.map((product) => (
                        <div className="row">
                          <div className="col-2 text-center">
                            {product.cantidad_devolucion}
                          </div>
                          <div className="col-4 text-center">
                            {product.codigo} <br /> {product.nombre}
                          </div>
                          <div className="col-4 text-center">
                            {formaterPriceFunction(
                              parseFloat(product.precio).toFixed(2)
                            )}
                          </div>
                          <div className="col-2 text-center">
                            {formaterPriceFunction(
                              parseFloat(product.total_devolver).toFixed(2)
                            )}
                          </div>
                        </div>
                      ))}
                      {/* <div className="divider" /> */}
                      <p className="mb-0 mt-4">
                        <strong>
                          {nota_credito.nivel_precio
                            ? `Nivel de precio: ${nota_credito.nivel_precio}`
                            : ""}
                        </strong>
                      </p>
                      <p className="mb-0 mt-4">
                        <strong>
                          {isGuatefactura ? "FACTURA ASOCIADA: " : ""}
                          {/* {nota_credito.invoice.firmafel} */}
                        </strong>
                      </p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "Serie: " : "Serie Factura FEL: "}
                        </strong>
                        {nota_credito.invoice.seriefel}
                      </p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "Número: " : "Número Factura FEL: "}
                        </strong>
                        {nota_credito.invoice.numerofel}
                      </p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura
                            ? "Número de Autorización: "
                            : "Número de Autorización Factura FEL: "}
                        </strong>
                      </p>
                      <p className="mb-0">{nota_credito.invoice.id_dte}</p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "Fecha emisión: " : ""}
                        </strong>
                        {/* {nota_credito.invoice.fecha.split("T")[0].split("-")[2] +
                          "/" +
                          nota_credito?.invoice?.fecha
                            ?.split("T")[0]
                            .split("-")[1] +
                          "/" +
                          nota_credito?.invoice?.fecha
                            .split("T")[0]
                            .split("-")[0]} */}
                        {moment(nota_credito.invoice.fecha)
                          .utc()
                          .format("DD/MM/YYYY")}
                      </p>

                      <hr style={{ borderTop: "3px dashed #bbb" }} />

                      <div className="row">
                        <div
                          className="col-12 text-right"
                          style={{ paddingRight: "44px" }}
                        >
                          <strong>
                            {/* Total Nota de Credito {moneda} {getTotalCredito()} */}
                            TOTAL NOTA DE CRÉDITO {moneda} {nota_credito.monto}
                          </strong>
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          paddingLeft: isCmi() ? "" : "150px",
                          marginTop: "10px",
                        }}
                      >
                        <label style={{ fontSize: "13px" }}>
                          <strong>TOTAL EN LETRAS:</strong>
                          {getTotalCredito().toString() === "1.00"
                            ? moneda == "L"
                              ? "UN LEMPIRA 00/100"
                              : "UN QUETZAL CON 00/100"
                            : ""}

                          {getTotalCredito().toString() !== "1.00" && (
                            <>
                              {convertir
                                .NumerosALetras(
                                  isCmi()
                                    ? nota_credito.monto
                                    : nota_credito.monto
                                )
                                .replace(
                                  "Pesos",
                                  moneda == "L" ? "LEMPIRAS" : "QUETZALES CON"
                                )
                                .replace("M.N.", "")
                                .toLocaleUpperCase()}
                            </>
                          )}
                        </label>
                        <br />
                        <br />
                      </div>

                      {isGuatefactura ? (
                        isGranContribuyente ? (
                          <p className="text-center">
                            <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                            <br />
                            <strong>Agente de Retención del IVA</strong>
                          </p>
                        ) : (
                          <div className="col-12 my-2">
                            <p className="text-center">
                              <strong>
                                NO GENERA DERECHO A CRÉDITO FISCAL
                              </strong>
                            </p>
                          </div>
                        )
                      ) : (
                        <>
                          <p className="text-center">
                            <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                            <br />
                            <strong>Agente de Retención del IVA</strong>
                          </p>
                        </>
                      )}

                      <hr style={{ borderTop: "3px dashed #bbb" }} />

                      <p className="mb-0">
                        <strong>FECHA DE IMPRESIÓN:</strong>{" "}
                        {nota_credito.fecha_correcta
                          ? moment
                              .utc(nota_credito.fecha_correcta)
                              .format("DD/MM/YYYY, h:mm:ss a")
                          : moment
                              .utc(nota_credito.fecha)
                              .format("DD/MM/YYYY, h:mm:ss a")}
                      </p>

                      {/* {isGuatefactura ? (
                        ""
                      ) : (
                        <div className="text-center col-12 my-4">
                          <p className="mb-0">
                            <strong>NUMERO DE AUTORIZACIÓN</strong>
                          </p>
                          <p className="mb-0">
                            <strong>{nota_credito.id_dte}</strong>
                          </p>

                        </div>
                      )} */}

                      <div
                        className={
                          isGuatefactura
                            ? "row mt-2 justify-content-center"
                            : "row mt-2 justify-content-center"
                        }
                      >
                        <p>
                          <strong>CERTIFICADOR: </strong>{" "}
                          {nota_credito.serviceData.certificador}
                        </p>
                        <div className="col-4 text-center">
                          <strong>Nit: </strong>{" "}
                          {nota_credito.fel
                            ? nota_credito.fel.nit
                            : nota_credito.service_nit}
                        </div>
                      </div>

                      <div className="text-center mt-4">
                        <p className="mb-0">
                          <strong>Powered by</strong>
                        </p>
                        <p className="mb-0">
                          <a
                            href="https://www.cloud.com.gt"
                            className="href-link"
                          >
                            www.cloud.com.gt
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            {/* Nota de crédito */}
          </div>
        </div>
      </form>
    </div>
  );
}
