import React, { useState, useEffect, useRef, useCallback } from "react";
import useInfinityScroll from "./utils/useInfinity";

const BottomSection = () => {
  const [page, setPage] = useState(0);
  let { products } = useInfinityScroll(page);
  products = products.sort((a, b) => b.qty - a.qty);

  const loader = useRef(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 10);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return (
    <div
      className="card shadow mt-3"
      style={{ height: "calc(100vh - 130px)", border: "none" }}
    >
      <div className="py-2 d-flex align-items-center px-2">
        {" "}
        <div>
          <h5 className="m-0">
            <strong>Productos más comprados</strong>
          </h5>
        </div>
        <div className="ml-auto">
          <div className="section-title">
            <select className="form-select">
              <option>Todas las tiendas</option>
            </select>
            <select className="form-select">
              <option>Últimos 30 días</option>
            </select>
          </div>
        </div>
      </div>
      <div className="table-responsive scroll" style={{ height: "100%" }}>
        <table className="table mb-0">
          <thead style={{ background: "#0160E7" }}>
            <tr className="text-light w-100">
              <th scope="col">Producto</th>
              <th scope="col">Monto</th>
              <th scope="col">Categoría</th>
              <th scope="col">Cant. Comprada</th>
              <th scope="col">Tienda</th>
              <th scope="col">Última vez comprado</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr className="bg-white">
                <td scope="row">Lorem ipsum</td>
                <td>Q. {product.amount.toFixed(2)}</td>
                <td>lorem ipsum</td>
                <td>N° {product.qty}</td>

                <td>Miraflores</td>
                <td>May, {Math.round(Math.random() * 30)} 2021</td>
              </tr>
            ))}
            <div
              style={{
                display: products.length ? "block" : "none",
                padding: "1px",
              }}
              ref={loader}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BottomSection;
