export const FRONT = "Frente";
export const BACK = "Trasera";
export const RIGHT = "Der.";
export const left = "Izq.";
export const HANDLE_SAVE_POINTS = "HANDLE_SAVE_POINTS";
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT";
export const SAVE_SELECTED_FIELD = "SAVE_SELECTED_FIELD";
export const CLEAR_STATE = "CLEAR_STATE";
export const CREATE_ALEKO_ORDER = "CREATE_ALEKO_ORDER";
export const HANDLE_FORMS = "HANDLE_FORMS";
export const HANDLE_SAVE_VARIANTS = "HANDLE_SAVE_VARIANTS";
export const HANDLE_DELETE_FILE = "HANDLE_DELETE_FILE";
export const HANDLE_DELETE_AREA = " HANDLE_DELETE_AREA";
export const HANDLE_DELETE_VARIANT = "HANDLE_DELETE_VARIANT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const SET_VALIDATION_TRUE = "SET_VALIDATION_TRUE";
export const SET_VALIDATION_FALSE = "SET_VALIDATION_FALSE";
export const SET_ORDER = "SET_ORDER";
export const GET_ID_PEDIDO = "GET_ID_PEDIDO";
export const HANDLE_PERSONALIZATIONS = "HANDLE_PERSONALIZATIONS";
// ----------------------------------------------------------------
export const DISABLE_DISCUNT = "DISABLE_DISCUNT";
export const ENABLE_CUSTOMIZATION = "ENABLE_CUSTOMIZATION";
