import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import API from "../../api";
import { toast } from "react-toastify";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import AddBoxIcon from "@material-ui/icons/AddBox";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// components

//data

const payment_periods = [
  {
    id: 1,
    option: "Pago único",
    value: "0",
  },
  {
    id: 2,
    option: "3 meses",
    value: "3",
  },
  {
    id: 3,
    option: "6 meses",
    value: "6",
  },
  {
    id: 4,
    option: "12 meses",
    value: "12",
  },
  {
    id: 5,
    option: "18 meses",
    value: "18",
  },
  {
    id: 6,
    option: "24 meses",
    value: "24",
  },
  {
    id: 7,
    option: "36 meses",
    value: "36",
  },
];

export default function Sell(props) {
  let history = useHistory();
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#cancelSale").modal("hide");
    };
  }, []);

  useEffect(() => {
    if (
      !!localStorage.getItem("selectedProducts") &&
      !!localStorage.getItem("salesCost")
    ) {
      setSelectedProducts(JSON.parse(localStorage.getItem("selectedProducts")));
      setSalesCost(JSON.parse(localStorage.getItem("salesCost")));
      setLoader(false);
    } else {
      toast.error("Ha ocurrido un error, por favor regrese al catalogo.", {
        autoClose: 10000,
      });
    }
  }, []);

  // states
  const store = JSON.parse(localStorage.getItem("store"));
  const caja = JSON.parse(localStorage.getItem("caja"));
  const seller = JSON.parse(localStorage.getItem("seller"));

  const [clientName, setClientName] = useState("");
  const [clientNit, setClientNit] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  // TODO use this for date
  const [deliveryDate, setDeliveryDate] = useState("");
  const [creditDays, setCreditDays] = useState("");
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [userFound, setUserFound] = useState(false);
  const [periods, setPeriods] = useState(payment_periods[0].value);
  const [invoice_number, setInvoiceNumber] = useState("");
  const [giftcard_code, setGiftCardCode] = useState("");
  const [additional_invoice, setAdditionalInvoice] = useState("");
  const [additional_invoices, setAdditionalInvoices] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [salesCost, setSalesCost] = useState({
    total: 0,
    subtotal: 0,
    iva: 0,
  });
  const [paidAmount, setPaidAmount] = useState({
    paid: 0,
    change: 0,
  });
  const [paidByGiftCard, setPaidByGiftCard] = useState(0);
  const [paidByCard, setPaidByCard] = useState(0);
  const [paidByCash, setPaidByCash] = useState(0);
  const [selectedMethods, setSelectedMethods] = useState([0]);
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [clientCreationLoader, setClientCreationLoader] = useState(false);
  const [creditLimit, setCreditLimit] = useState(0);
  const [searchText, setSearchText] = useState("");

  // set states

  const changePeriods = (event) => {
    setPeriods(event.target.value);
  };

  const addNewAdditionalInvoice = () => {
    if (additional_invoice !== "") {
      let newArray = [...additional_invoices];
      newArray.push(additional_invoice);
      setAdditionalInvoices(newArray);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "nit":
        setClientNit(value);
        break;
      case "search_text":
        setSearchText(value);
        break;
      case "invoice_number":
        setInvoiceNumber(value);
        break;
      case "giftcard_code":
        setGiftCardCode(value);
        break;
      case "additional_invoice":
        setAdditionalInvoice(value);
        break;
      default:
      case "nombre_cliente":
        setClientName(value);
        break;
      case "direccion_fiscal":
        setClientAddress(value);
        break;
      case "email":
        setClientEmail(value);
        break;
      case "date":
        setDeliveryDate(value);
        break;
    }
  };

  const handleBoxClick = (x) => {
    let chosenMethods = [...selectedMethods];
    if (x === 4) {
      // If user clicks on the 'multiples' button
      if (chosenMethods.includes(4)) {
        // If multiples was previously selected
        $(".payment-method-box").each(function () {
          if ($(this).hasClass("active-box")) {
            $(this).removeClass("active-box");
          }
        });
        setSelectedMethods([0]);
      } else {
        // If user clicks multiples for the first time
        $(".payment-method-box").each(function () {
          if ($(this).hasClass("active-box")) {
            $(this).removeClass("active-box");
          }
        });
        let currentElement = "box-" + x;
        $(`#${currentElement}`).addClass("active-box");
        setSelectedMethods([4]);
      }
    } else {
      /* if (chosenMethods.includes(4)) {
                let currentElement = "box-" + x;
                if (chosenMethods.includes(x)) {
                    $(`#${currentElement}`).removeClass("active-box");
                    setSelectedMethods(
                        chosenMethods.filter((value) => value !== x)
                    );
                    let existingData = paidAmount;
                    switch (x) {
                        case 1:
                            setPaidByCash("");
                            existingData.paid = paidByCard + paidByGiftCard;
                            break;
                        case 2:
                            setPaidByCard("");
                            existingData.paid = paidByCash + paidByGiftCard;
                            break;
                        case 3:
                            setPaidByGiftCard("");
                            existingData.paid = paidByCard + paidByCash;
                            break;
                        default:
                            break;
                    }
                    if (existingData.paid > salesCost.total) {
                        existingData.change =
                            existingData.paid - salesCost.total;
                        existingData.change =
                            Math.round(
                                (existingData.change + Number.EPSILON) * 100
                            ) / 100;
                    } else {
                        existingData.change = 0;
                    }
                    setPaidAmount(existingData);
                } else {
                    $(`#${currentElement}`).addClass("active-box");
                    chosenMethods.push(x);
                    setSelectedMethods(chosenMethods);
                }
            } else { */
      $(".payment-method-box").each(function () {
        if ($(this).hasClass("active-box")) {
          $(this).removeClass("active-box");
        }
      });
      let currentElement = "box-" + x;
      $(`#${currentElement}`).addClass("active-box");
      setSelectedMethods([x]);
      switch (x) {
        case 1:
          setPaidByCard(0);
          setPaidByGiftCard(0);
          break;
        case 2:
          setPaidByGiftCard(0);
          setPaidByCash(0);
          break;
        case 3:
          setPaidByCash(0);
          setPaidByCard(0);
          break;
        default:
          break;
      }
      let existingData = paidAmount;
      existingData.paid = 0;
      existingData.change = 0;
      setPaidAmount(existingData);
    }
    /*  } */
  };

  const updateValue = (e) => {
    let target = e.target;
    let existingData = paidAmount;
    let parsedValue = isNaN(parseFloat(target.value))
      ? 0
      : Math.abs(parseFloat(target.value));
    e.target.value = isNaN(parseFloat(target.value)) ? "" : target.value;
    if (target.name === "paidByCash") {
      setPaidByCash(parsedValue);
      existingData.paid = parsedValue + paidByCard + paidByGiftCard;
    }
    if (target.name === "paidByCard") {
      setPaidByCard(parsedValue);
      existingData.paid = paidByCash + parsedValue + paidByGiftCard;
    }
    if (target.name === "paidByGiftCard") {
      setPaidByGiftCard(parsedValue);
      existingData.paid = paidByCash + paidByCard + parsedValue;
    }

    if (existingData.paid > salesCost.total) {
      existingData.change = existingData.paid - salesCost.total;
      existingData.change =
        Math.round((existingData.change + Number.EPSILON) * 100) / 100;
    } else {
      existingData.change = 0;
    }
    setPaidAmount(existingData);
  };

  const toggleCheck = (e) => {
    let target = e.target;
    if (target.name === "check1") {
      setCheck1(!check1);
    } else {
      setCheck2(!check2);
    }
  };

  const gotoResumen = () => {
    if (submitLoader) return;
    let valid = true;
    if (!clientName) {
      valid = false;
      toast.error("Debe seleccionar un cliente antes de continuar", {
        autoClose: 10000,
      });
    }
    if (selectedMethods.includes(5)) {
      if (creditLimit < salesCost.total) {
        valid = false;
        toast.error("El límite de crédito no es suficiente para esta venta.", {
          autoClose: 10000,
        });
      }
    } else {
      if (paidAmount.paid < salesCost.total) {
        valid = false;
        toast.error(
          "El monto pagado no puede ser menor que el total de la venta",
          { autoClose: 10000 }
        );
      }
    }

    if (
      selectedMethods.includes(2) &&
      (invoice_number === "" || periods === "")
    ) {
      valid = false;
      toast.error(
        "Debe completar los campos de la verificación del pago con tarjeta.",
        { autoClose: 10000 }
      );
    }

    if (selectedMethods.includes(3) && giftcard_code === "") {
      valid = false;
      toast.error("Debe introducir el código de la gift card", {
        autoClose: 10000,
      });
    }

    if (valid) {
      setSubmitLoader(true);
      API.sales
        .createNew({
          id_vendedor_web_app: seller.id_vendedor_web_app,
          id_sucursal_bodega_ubicacion: store.id_sucursal_bodega_ubicacion,
          id_caja_web_app: caja.id_caja_web_app,
          nombre: clientName,
          direccion: clientAddress,
          email: clientEmail,
          nit: clientNit,
          forma_pago: selectedMethods.includes(4) ? 4 : selectedMethods[0],
          efectivo: paidByCash,
          tarjeta: paidByCard,
          credito: 0,
          gift_card: paidByGiftCard,
          gift_card_codigo: giftcard_code,
          subtotal: salesCost.subtotal,
          /* vat_amount: salesCost.iva, */
          monto_total: salesCost.total,
          recibo_numero: invoice_number,
          adicional_recibo: additional_invoices.join(","),
          cuotas: periods,
          selectedProducts: JSON.stringify(selectedProducts),
          cambios: paidAmount.change,
        })
        .then((res) => {
          let response = res.data;
          if (response.success && JSON.stringify(response.data) !== "[]") {
            console.log("Sale added, obtained this:", response.data);
            setSubmitLoader(false);
            const toDelete = [
              "selectedProducts",
              "invoiceInfo",
              "clientInfo",
              "salesCost",
              "fel_data",
            ];
            toDelete.map((value) => {
              localStorage.removeItem(value);
            });
            selectedProducts.map((product) => {
              product.precio = parseFloat(
                (product.total / product.cantidad).toFixed(2)
              );
            });
            history.push({
              pathname: "/app/vender/resumen",
              state: {
                invoiceInfo: {
                  salesCost,
                  paidAmount,
                  paidByGiftCard,
                  paidByCard,
                  paidByCash,
                  selectedProducts,
                  invoicesInfo: {
                    recibo_numero: invoice_number,
                    adicional_recibo: additional_invoices,
                    gift_card_codigo: giftcard_code,
                    cuotas: periods,
                  },
                },
                clientInfo: {
                  nombre: clientName,
                  direccion: clientAddress,
                  email: clientEmail,
                  nit: clientNit,
                },
                fel_data: response.data,
                seller: seller,
              },
            });
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
            setSubmitLoader(false);
          }
        })
        .catch((err) => {
          setSubmitLoader(false);
          let response = err.response;
          console.log(response);
          if (typeof response.data !== "undefined") {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            } else {
              toast.error("Error del servidor", {
                autoClose: 10000,
              });
            }
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        });
    }
  };
  const backToCatalogo = () => {
    history.push({
      pathname: "/app/vender",
    });
  };

  const searchClient = () => {
    setLoader2(true);
    API.clients
      .searchClient({ search_text: searchText })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("This is the data:", response.data);
          setClientName(response.data.nombre);
          setClientNit(response.data.nit);
          setClientAddress(response.data.direccion);
          setClientEmail(response.data.email);
          setCreditDays(response.data.dias_credito);
          setCreditLimit(response.data.limite_credito);
          setLoader2(false);
          setUserFound(true);
        } else {
          setUserFound(false);
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setUserFound(false);
        setLoader2(false);
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const resetClient = () => {
    setClientName("");
    setClientNit("");
    setClientAddress("");
    setClientEmail("");
    setCreditDays("");
    setCreditLimit("");
    setUserFound(false);
  };

  const houseKeeping = () => {
    const toDelete = [
      "selectedProducts",
      "invoiceInfo",
      "clientInfo",
      "salesCost",
      "seller",
      "store",
      "caja",
    ];
    toDelete.map((value) => {
      localStorage.removeItem(value);
    });
    history.push({
      pathname: "/app/dashboard",
    });
  };
  const submitClient = () => {
    setClientCreationLoader(true);
    let validForm = true;
    let emailFormat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailFormat.test(clientEmail)) {
      validForm = false;
      toast.error("El correo no tiene formato válido", {
        autoClose: 10000,
      });
    }

    if (clientNit === "") {
      validForm = false;
    }
    if (clientName === "") {
      validForm = false;
    }
    if (clientAddress === "") {
      validForm = false;
    }

    if (!!validForm) {
      API.clients
        .add_new({
          nombre: clientName,
          direccion: clientAddress,
          email: clientEmail,
          nit: clientNit,
          limit: 0,
          dias_credito: 0,
        })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success("Cliente creado con exito", {
              autoClose: 10000,
            });
            setUserFound(true);
          } else {
            toast.error(response.message, { autoClose: 10000 });
          }
          setClientCreationLoader(false);
        })
        .catch((err) => {
          setClientCreationLoader(false);
          console.log(err.response);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        });
    } else {
      setClientCreationLoader(false);
      toast.error(
        "Debe llenar todos los campos de cliente para poder realizar la creación.",
        { autoClose: 10000 }
      );
    }
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select disabled native>
            <option selected value={store.id_sucursal_bodega_ubicacion}>
              {store.nombre_sucursal}
            </option>
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select disabled native>
            <option selected value={caja.id_caja_web_app}>
              {caja.descripcion}
            </option>
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select disabled native>
            <option selected value={seller.id_vendedor_web_app}>
              {seller.nombre}
            </option>
          </Select>
        </FormControl>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="content-card card">
            <div className="card-header">
              <h4 className="mb-0">
                <strong>Datos del cliente</strong>
              </h4>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Buscar cliente</strong>
                  </p>
                  <div className="input-group search-bar">
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleInputChange}
                      name="search_text"
                      className="form-control"
                      placeholder="Buscar cliente por nombre, correo o NIT"
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        onClick={searchClient}
                        style={{ backgroundColor: "#0160E7" }}
                      >
                        {loader2 ? (
                          <CircularProgress size={16} className="mx-auto" />
                        ) : (
                          <SearchIcon />
                        )}
                      </span>
                      <span
                        className="input-group-text"
                        onClick={resetClient}
                        style={{ backgroundColor: "red" }}
                      >
                        <ClearIcon />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>NIT</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    name="nit"
                    value={clientNit}
                    onChange={handleInputChange}
                    placeholder="Ingrese el NIT del cliente"
                    disabled={userFound ? true : false}
                  />
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Nombre de cliente</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={clientName}
                    onChange={handleInputChange}
                    name="nombre_cliente"
                    placeholder="Ingrese el nombre del cliente"
                    disabled={userFound ? true : false}
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Dirección fiscal del cliente</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={clientAddress}
                    onChange={handleInputChange}
                    name="direccion_fiscal"
                    placeholder="Ingrese la dirección fiscal del cliente"
                    disabled={userFound ? true : false}
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Correo electrónico</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={clientEmail}
                    onChange={handleInputChange}
                    name="email"
                    type="email"
                    placeholder="Ingrese el correo electrónico del cliente"
                    disabled={userFound ? true : false}
                  />
                </div>

                {(() => {
                  // TODO test when custom products can be taken from db

                  selectedProducts.push({ producto_personalizado: true });

                  if (
                    selectedProducts.filter((a) => a.producto_personalizado)
                      .length > 0
                  ) {
                    return (
                      <div className="w-100 my-3">
                        <p className="mb-0">
                          <strong>Fecha de entrega</strong>
                        </p>
                        <TextField
                          className="w-100"
                          variant="standard"
                          defaultValue={deliveryDate}
                          onChange={handleInputChange}
                          name="deliveryDate"
                          type="date"
                          placeholder="Ingrese el correo electrónico del cliente"
                        />
                      </div>
                    );
                  }
                })()}
                <div className="w-25 mx-auto">
                  <Button
                    style={{
                      color: "white",
                    }}
                    className="my-2 w-75 crearcliente-button bg_secondary_color"
                    variant="contained"
                    onClick={submitClient}
                    disabled={userFound ? true : false}
                  >
                    <span
                      style={{
                        marginBottom: "-4px",
                        textTransform: "none",
                      }}
                    >
                      {clientCreationLoader ? (
                        <CircularProgress size={16} className="mx-auto" />
                      ) : (
                        "Crear cliente"
                      )}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row justify-content-between p-3">
            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIosIcon style={{ fontSize: "15px" }} />}
              onClick={backToCatalogo}
            >
              <span style={{ marginBottom: "-4px" }}>Seguir comprando</span>
            </Button>
            <Button
              className="btn-cs1 icon-btn-delete mx-1"
              variant="contained"
              color="primary"
              data-toggle="modal"
              data-target="#cancelSale"
            >
              <span style={{ marginBottom: "-4px" }}>Cancelar venta</span>
            </Button>
          </div>
          <div className="card content-card mt-2">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-center flex-wrap mb-3">
                <div
                  className="payment-method-box"
                  id="box-1"
                  onClick={() => handleBoxClick(1)}
                >
                  <LocalAtmIcon />
                  <p>Efectivo</p>
                </div>
                <div
                  className="payment-method-box"
                  id="box-2"
                  onClick={() => handleBoxClick(2)}
                >
                  <CreditCardIcon />
                  <p>Tarjeta</p>
                </div>
                {/*  <div
                                    className="payment-method-box"
                                    id="box-3"
                                    onClick={() => handleBoxClick(3)}
                                >
                                    <CardGiftcardIcon />
                                    <p>Gift card</p>
                                </div> */}
                <div
                  className="payment-method-box"
                  id="box-4"
                  onClick={() => handleBoxClick(4)}
                >
                  <AddBoxIcon />
                  <p>Multiples</p>
                </div>
                <div
                  className="payment-method-box"
                  id="box-5"
                  onClick={() => handleBoxClick(5)}
                >
                  <AssignmentTurnedInIcon />
                  <p>A crédito</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-7">
                  <div className="row">
                    {selectedMethods.includes(1) ||
                    selectedMethods.includes(4) ? (
                      <div className="col-md-12">
                        <p>
                          <strong>Efectivo</strong>
                        </p>
                        <TextField
                          type="number"
                          className="w-100 my-2"
                          variant="standard"
                          step="any"
                          value={paidByCash === 0 ? "" : paidByCash}
                          name="paidByCash"
                          onChange={(e) => updateValue(e)}
                          placeholder="Monto pagado en efectivo"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Q.
                              </InputAdornment>
                            ),
                            inputProps: { min: 0 },
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedMethods.includes(2) ||
                    selectedMethods.includes(4) ? (
                      <div className="col-md-12">
                        <p>
                          <strong>Tarjeta de débito/crédito</strong>
                        </p>
                        <TextField
                          className="w-100 my-2"
                          variant="standard"
                          type="number"
                          value={paidByCard === 0 ? "" : paidByCard}
                          name="paidByCard"
                          onChange={(e) => updateValue(e)}
                          placeholder="Monto pagado por tarjeta de débito/crédito"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Q.
                              </InputAdornment>
                            ),
                            inputProps: { min: 0 },
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedMethods.includes(3) ? (
                      <div className="col-md-12">
                        <p>
                          <strong>Gift card</strong>
                        </p>
                        <TextField
                          type="number"
                          className="w-100 my-2"
                          variant="standard"
                          value={paidByGiftCard === 0 ? "" : paidByGiftCard}
                          name="paidByGiftCard"
                          onChange={(e) => updateValue(e)}
                          placeholder="Monto pagado por Gift Card"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Q.
                              </InputAdornment>
                            ),
                            inputProps: { min: 0 },
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedMethods.includes(5) ? (
                      <div
                        className="col-md-12 mt-3"
                        style={{
                          borderBottom: "1px solid #E0E0E0",
                        }}
                      >
                        <p>
                          <strong>Información del cliente</strong>
                        </p>
                        <p>
                          Este cliente cumple con los requisitos para optar por
                          crédito de:
                          <strong> {creditDays || 0} días</strong>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {loader ? (
                  <div className="my-auto mx-auto">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <div className="col-sm-5 invoice-column">
                    <div className="row">
                      <div className="col-12 h-75">
                        <div className="row p-0">
                          <div className="col-6 p-0 m-0 text-center titles my-auto">
                            {/*    <p>SubTotal:</p>
                                                <p>IVA (12%):</p> */}
                            <p>Total:</p>
                            <p>Pagado:</p>
                            <p>Vuelto:</p>
                          </div>
                          <div className="col-6 p-0 m-0 text-center my-auto">
                            {/*    <p>Q. {salesCost.subtotal}</p>
                                                <p>Q. {salesCost.iva}</p> */}
                            <p>Q. {salesCost.total.toFixed(2)}</p>
                            <p>Q. {paidAmount.paid}</p>
                            <p>Q. {paidAmount.change}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 h-25 d-flex justify-content-center w-100 m-0">
                        <Button
                          style={{
                            backgroundColor: "white",
                            color: "#1a2eab",
                          }}
                          className="my-2 w-75 facturar-button"
                          variant="contained"
                          onClick={gotoResumen}
                        >
                          <span
                            style={{
                              marginBottom: "-4px",
                              textTransform: "none",
                            }}
                          >
                            {submitLoader ? (
                              <CircularProgress size={16} className="mx-auto" />
                            ) : (
                              "Facturar"
                            )}
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!selectedMethods.includes(5) &&
      selectedMethods[0] !== 1 &&
      selectedMethods[0] !== 0 ? (
        <div className="row mt-3">
          <div className="col-12">
            <div className="content-card card">
              <div className="card-header">
                <h4>
                  <strong>Verificación del pago</strong>
                </h4>
              </div>
              <div className="card-body">
                <div className="row">
                  {selectedMethods.includes(2) ||
                  selectedMethods.includes(4) ? (
                    <>
                      <div className="col-md my-auto">
                        <p>
                          <strong>
                            Número del recibo (Operación con tarjeta)
                          </strong>
                        </p>
                        <TextField
                          className="w-100"
                          variant="standard"
                          value={invoice_number}
                          name="invoice_number"
                          onChange={handleInputChange}
                          placeholder="Ingrese el número del recibo emitido por el pto. de venta"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                N°
                              </InputAdornment>
                            ),
                          }}
                          disabled={!check1}
                        />
                      </div>
                      <div className="col-md my-auto">
                        <p>
                          <strong>Número de cuotas</strong>
                        </p>

                        <Select
                          native
                          value={periods}
                          onChange={changePeriods}
                          style={{ width: "100%" }}
                          variant="outlined"
                        >
                          {payment_periods.map((period) => (
                            <option value={period.value}>
                              {period.option}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {selectedMethods.includes(3) ? (
                    <div className="col-md my-auto">
                      <p>
                        <strong>Ingrese el código de Gift Card</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={giftcard_code}
                        name="giftcard_code"
                        onChange={handleInputChange}
                        placeholder="     -      -      -"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {selectedMethods.includes(2) ||
                  selectedMethods.includes(4) ? (
                    <>
                      <div className="col-md-12">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="check1"
                            checked={check1}
                            onChange={toggleCheck}
                          />
                          <label
                            class="form-check-label ml-2"
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            Ingresar manualmente
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 mt-5 pl-5">
                        <div className="row w-100 justify-content-center justify-content-md-start">
                          <div className="col-md-1 text-center mb-2">
                            <AddIcon
                              className="bg_secondary_color"
                              style={{
                                fontSize: "30px",
                                color: "white",
                              }}
                              onClick={addNewAdditionalInvoice}
                            />
                          </div>
                          <div className="col-md-6">
                            <p className="mb-0">
                              <strong>
                                Número del recibo de pago para tarjeta adicional
                              </strong>
                            </p>
                            <TextField
                              className="w-100"
                              variant="standard"
                              value={additional_invoice}
                              name="additional_invoice"
                              onChange={handleInputChange}
                              placeholder="Ingrese el número del recibo emitido con una tarjeta adicional"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    N°
                                  </InputAdornment>
                                ),
                              }}
                              disabled={!check2}
                            />
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="check2"
                                checked={check2}
                                onChange={toggleCheck}
                              />
                              <label
                                class="form-check-label ml-2"
                                for="defaultCheck1"
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                Ingresar manualmente
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row w-100 mt-2 justify-content-center justify-content-md-start">
                          {additional_invoices.map((invoice) => (
                            <div className="col-xl-4 px-1">
                              <TextField
                                className="w-100"
                                variant="standard"
                                disabled
                                value={invoice}
                                name="invoice_number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      Recibo N°
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* Modal */}
      <div className="modal fade" id="cancelSale" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row justify-content-center align-items-center p-3">
                <div className="col-md-12">
                  <CheckCircleIcon
                    className="d-block mx-auto"
                    style={{
                      color: "#08BFE6",
                      fontSize: "60",
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <h4 className="text-center">
                    <strong>
                      ¿Estás seguro de que quieres cancelar esta venta?
                    </strong>
                  </h4>
                </div>
              </div>
              <div className="col-md-8 mx-auto d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={houseKeeping}
                >
                  Sí, cancelar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
