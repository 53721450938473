import React, { useContext, useEffect } from "react";

// Icons
import { Add } from "@material-ui/icons";

// Styled Components
import { FlexContainer, AddProduct, Product } from "./styled/styled.components";
import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { useHistory } from "react-router-dom";

const CustomizationTabs = ({ products, currentTab, setCurrentTab }) => {
  const history = useHistory();
  const { setCurrentProduct, clearState, setVariants, setCurrentProductTab } =
    useContext(ProductPersonalizationContext);
  const handleTab = (i, item) => {
    // handleSavePoints(
    //   products[currentTab].custom.perspectives,
    //   products[currentTab].title
    // );
    setCurrentTab(i);
    setCurrentProduct(item);
  };

  useEffect(() => {
    setVariants([products[0]]);
    // setCurrentProduct(products[0]);
  }, []);

  return (
    <FlexContainer
      gridColumn="1/-1"
      boxShadow="0px 2px 12px rgba(0, 0, 0, 0.06)"
    >
      {products.map((item, i) => {
        return (
          <Product
            borderRadius="0"
            key={i}
            active={currentTab == i ? true : false}
            onClick={() => {
              handleTab(i, item);
            }}
          >
            {item.title}
          </Product>
        );
      })}
      <AddProduct
        borderRadius="0"
        whiteSpace="nowrap"
        onClick={() => {
          history.push("/app/vender");

          // clearState();
        }}
      >
        Añadir Producto <Add />
      </AddProduct>
    </FlexContainer>
  );
};

export default CustomizationTabs;
