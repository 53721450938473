import React, { useContext, useState, useEffect } from "react";
import { ChromePicker, CustomPicker, BlockPicker } from "react-color";
import AlekoContext from "../../../context/ContextoAleko/AlekoContext";

import ColorPickerContext from "../../../context/ColorPickerContext/ColorPickContext";

const ColorPicker = () => {
  const {
    saveColor,
    colors,
    deleteForgivenColors,
    oneMorePick,
    getColors,
    setShowColorPicker,
  } = useContext(ColorPickerContext);
  const { setProductColor, productColor } = useContext(AlekoContext);

  const [color, setColor] = useState({ background: "#fff" });
  const handleColorChange = (color) => {
    setColor({ background: color.hex });
  };

  const handleSetColor = ({ background }) => {
    setColor({ background });
    oneMorePick({ background });
  };
  useEffect(() => {
    getColors();
    return () => {
      deleteForgivenColors();
    };
  }, []);

  return (
    <>
      <div className="d-flex w-100" style={{ flexWrap: "wrap" }}>
        <label
          style={{
            width: "100%",
            margin: 0,
            color: "black",
            background: "rgb(242,242,242)",
          }}
          className="py-1"
        >
          {" "}
          <strong>Actualizar color</strong>
        </label>
        <div className="w-100 d-flex justify-content-between border-bottom align-items-center px-1">
          <div className="d-flex align-items-center py-1">
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: "50%",
                background: `${color.background}`,
              }}
              className="border"
            />
            {color.background}
          </div>
          <button
            type="button"
            style={{
              fontSize: 13,
              border: "none",
              outline: "none",
              background: "rgb(242,242,242)",
            }}
            className="py-1"
            onClick={() => {
              setProductColor({
                ...productColor,
                codigo_hexa_color: color.background,
              });
              setShowColorPicker(false);
            }}
          >
            Agregar
          </button>
        </div>
        <span className="w-100 ml-1">Frecuentes</span>
        {colors
          .map((color) => (
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: "50%",
                background: color.background,
              }}
              className="border mr-1 ml-1 py-1 mt-2"
              onClick={() => handleSetColor(color)}
            />
          ))
          .splice(0, 11)}
        <div
          style={{
            height: 30,
            width: 30,
            borderRadius: "50%",

            background: "rgb(242,242,242)",
            lineHeight: "21px",
            textAlign: "center",
            cursor: "pointer",
          }}
          className="border mr-1 ml-1 py-1 mt-2"
          onClick={() => saveColor({ ...color, picks: 1 })}
        >
          <span>+</span>
        </div>
      </div>
      <div className="w-100 mt-2">
        <ChromePicker
          color={color.background}
          onChange={handleColorChange}
          className="mx-auto"
        />
      </div>
    </>
  );
};

export default CustomPicker(ColorPicker);
