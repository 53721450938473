import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import API from "../../api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
//eslint-disable-next-line
import $ from "jquery";
import Select from "@material-ui/core/Select";
import FooterLinks from "../../components/FooterLinks/FooterLinks";
import HeaderNoSession from "../../components/HeaderNoSession/Header";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import useStyles from "./styles";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useUserDispatch, signOut } from "../../context/UserContext";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Cards from "react-credit-cards";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import "./custom.css";
import WidgetPayment from "./WidgetPayment";

const ERROR_MESSAGES = {
  emptyCardNumber: "El número de la tarjeta es inválido",
  invalidCardNumber: "El número de la tarjeta es inválido",
  emptyExpiryDate: "La fecha de expiración es inválida",
  monthOutOfRange: "El mes de expiración debe estar entre 01 y 12",
  yearOutOfRange: "El año de expiración no puede estar en el pasado",
  dateOutOfRange: "La fecha de expiración no puede estar en el pasado",
  invalidExpiryDate: "La fecha de expiración es inválida",
  emptyCVC: "El código de seguridad es inválido",
  invalidCVC: "El código de seguridad es inválido",
};

function Register(props) {
  const { register, handleSubmit } = useForm();
  var classes = useStyles();

  // Definitions

  // cleap up - componentWillUnmount
  useEffect(() => {
    const dataEmpresa = localStorage.getItem("dataRegister");
    const dataSucursal = localStorage.getItem("id_sucursal");
    if(!dataEmpresa && !dataSucursal) {
      props.history.push("/login");
    }
    return () => {
      $("#paymentApproved").modal("hide");
    };
  }, []);

  useEffect(() => {
    getAllPlans();
  }, []);

  // states
  var [isLoading, setIsLoading] = useState(true);
  var [submitLoader, setSubmitLoader] = useState(false);
  var [plans, setPlans] = useState([]);
  var [plan, setPlan] = useState("");
  var [cantLicencias, setCantLicencias] = useState(1);
  var [costoTotal, setCostoTotal] = useState("");
  var [frecuenciaPago, setFrecuenciaPago] = useState("");
  const [cvc, setCVC] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [auto_renewal, setAutoRenewal] = useState(false);
  var userDispatch = useUserDispatch();
  const [responseValue, setResponseValue] = useState('');

  const {
    meta,
    getCardNumberProps,
    wrapperProps,
    getCardImageProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  });
  // set states

  useEffect(()=> {
    $("#card-ui-component-notification-container").hide();
    if(responseValue !== '') {
        const peticionForm = async () => {
        await handlePayment();
    }
    peticionForm();
    }
  },[responseValue])

  const handlePayment = async () => {
    setSubmitLoader(true);
    let validForm = true;
    console.log(meta.erroredInputs);

    for (var key in meta.erroredInputs) {
      if (typeof meta.erroredInputs[key] !== "undefined") {
        validForm = false;
      }
    }
    if (!validForm)
      toast.error("Hay uno o mas errores en los datos de la tarjeta.", {
        autoClose: 10000,
      });

    if (name.length === "") {
      validForm = false;
      toast.error("Debe completar el nombre del tarjetahabiente.", {
        autoClose: 10000,
      });
    }

    if (cantLicencias === "") {
      validForm = false;
      toast.error("Debe introducir la cantidad de licencias.", {
        autoClose: 10000,
      });
    }

    if(cvc.length < 3 && process.env.REACT_APP_ENTORNO !== "development") {
      validForm = false;
      toast.error("Debe ingresar un CVV valido", {
          autoClose: 10000,
      });
      setSubmitLoader(false)
      return;
    }

    let card = ""
    let nameCard = "name"
    if( process.env.REACT_APP_ENTORNO !== "development") {
      card = document.getElementById('card-ui-component-txt-creditcard-number').value;
      nameCard = document.getElementById('card-ui-component-txt-creditcard-holder').value;
    }
    
    const userAPIKey = process.env.REACT_APP_USER_ADMIN_APIKEY;
    
    if(nameCard.toUpperCase() !== userAPIKey) {
        if(responseValue === '' ) {
            $('#card-ui-component-btn-confirm').trigger('click');
            validForm = false;
        }
        else if(responseValue.ResponseCode !== 'T00' ) {
            validForm = false;
            toast.error("Por favor revise los datos de su tarjeta, Error "+ responseValue.ResponseDescription, {
                autoClose: 10000,
            });
        }
    }

    if (process.env.REACT_APP_ENTORNO === "development" || process.env.REACT_APP_ENTORNO == "production") {
        validForm = true;
    }

    const dataEmpresa = localStorage.getItem("dataRegister");
    const dataEmpresaNueva = dataEmpresa ? JSON.parse(dataEmpresa) : {};

    if (validForm) {
      let form_data = {
        card_holder_name: responseValue?.TokenDetails?.CardHolderName ? responseValue?.TokenDetails?.CardHolderName : nameCard,
        card_number: responseValue?.TokenDetails?.CardNumber ? responseValue?.TokenDetails?.CardNumber : card,
        expiry: responseValue?.TokenDetails?.ExpirationDate, // Converts from "12 / 24" to "1224" 
        cvv: cvc,
        plan_id: parseInt(plan),
        auto_renewal: auto_renewal ? 1 : 0,
        payment_gateway: 1,
        numero_licencias: parseInt(cantLicencias),
        dataWidget: JSON.stringify(responseValue),
        creaEmpresa: (dataEmpresa) ? true : false,
        ...dataEmpresaNueva,
      };

      if (process.env.REACT_APP_ENTORNO == "development" || process.env.REACT_APP_ENTORNO == "production") {
          form_data = {
            card_holder_name: userAPIKey,
            card_number: process.env.REACT_APP_CARDNUMBERHARD,
            expiry: '12/2999', // Converts from "12 / 24" to "1224" 
            cvv: '123',
            plan_id: parseInt(plan),
            auto_renewal: auto_renewal ? 1 : 0,
            payment_gateway: 1,
            numero_licencias: parseInt(cantLicencias),
            dataWidget: JSON.stringify(responseValue),
            creaEmpresa: (dataEmpresa) ? true : false,
            ...dataEmpresaNueva,
          };
      }
    
      API.subscriptions
        .createSub(form_data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setSubmitLoader(false);
            toast.success(response.message, {
              autoClose: 10000,
            });
            signOut(userDispatch, props.history);
            localStorage.clear();
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
            setSubmitLoader(false);
            $('#card-ui-component-btn-cancel').trigger('click');
          }
        })
        .catch((err) => {
          setSubmitLoader(false);
          setCVC('');
          $('#card-ui-component-btn-cancel').trigger('click');
          let response = err.response;
          console.log(err);
          console.log(response);
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    } else {
      setSubmitLoader(false);
    }
  };

  const changePlan = (event) => {
    let total;
    const currentPlan = event.target.value;
    setPlan(currentPlan);
    console.log("These are all plans", plans);
    let selectedPlan = plans.find(
      (filteredPlan) => filteredPlan.id_planes === parseInt(currentPlan)
    );
    console.log("selected plan", selectedPlan);
    total = selectedPlan.monto * cantLicencias;

    setCostoTotal(total);
    setFrecuenciaPago(selectedPlan.periodo);
  };
  const changeTotalToPay = (event) => {
    if (parseInt(event.target.value) === 0) {
      event.target.value = 1;
    }
    setCantLicencias(
      event.target.value < 0
        ? (event.target.value = -event.target.value)
        : event.target.value
    );
    let total;
    let selectedPlan = plans.find(
      (filteredPlan) => filteredPlan.id_planes === parseInt(plan)
    );
    total = selectedPlan.monto * parseInt(event.target.value);
    setCostoTotal(total);
  };

  /*const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "cvc":
        setCVC(value);
        break;
      case "expiry":
        setExpiry(value);
        break;
      case "name":
        setName(value);
        break;
      case "number":
        setNumber(value);
        break;
      default:
        break;
    }
  };*/

  const getAllPlans = () => {
    API.subscriptions
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setPlans(response.data);
          setPlan(response.data[0].id_planes);
          setCantLicencias(1);
          setCostoTotal(response.data[0].monto);
          setFrecuenciaPago(response.data[0].periodo);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  return (
    <div className="row h-100 w-100 p-0 m-0">
      <div className="row h-100 w-100 m-0 p-0 outer-container justify-content-center register">
        <HeaderNoSession />
        <div
          className="col-12 p-0 inner-container banner"
          style={{ marginTop: "66px" }}
        >
          <div className="row w-100 h-100 justify-content-around align-items-center">
            <div className="col-md-6">
              <h1 className="page-title">Creación de cuenta Cloud</h1>
            </div>

            <div className="col-md-6 align-items-center">
              <div className="d-flex justify-content-end align-items-center right-banner-text">
                <p className="mr-3 text-white my-auto">
                  <strong>¿Ya tienes cuenta?</strong>
                </p>
                <Button
                  variant="contained"
                  size="large"
                  component={Link}
                  to={"/login"}
                  style={{
                    backgroundColor: "#08BFE6",
                    color: "white",
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                >
                  Iniciar sesión
                </Button>
              </div>
            </div>
          </div>
        </div>

        <form
          className="col-8 p-0 content"
          onSubmit={handleSubmit(handlePayment)}
        >
          <React.Fragment>
            <div className="card content-card card-body register-card p-5">
              {isLoading ? (
                <CircularProgress size={26} className="mx-auto" />
              ) : (
                <>
                  <h1>
                    <strong>Registro</strong>
                  </h1>

                  <div className="row mt-4">
                    <div className="col-lg-6">
                      <p className="mt-3 mb-0">
                        <strong>Plan a contratar</strong>
                      </p>
                      <Select
                        native
                        fullWidth
                        value={plan}
                        onChange={(e) => changePlan(e)}
                      >
                        {plans.map((plan) => (
                          <option key={plan.id_planes} value={plan.id_planes}>
                            {plan.descripcion}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <div className="col-lg-2">
                      <p className="mt-3 mb-0">
                        <strong>N° de licencias</strong>
                      </p>
                      <TextField
                        type="number"
                        className="w-100"
                        fullWidth
                        value={cantLicencias}
                        onChange={(e) => changeTotalToPay(e)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">N°</InputAdornment>
                          ),
                          inputProps: { min: 1 },
                        }}
                      />
                    </div>
                    <div className="col-lg-4">
                      <p className="mt-3 mb-0">
                        <strong>Total a pagar</strong>
                      </p>
                      <TextField
                        type="number"
                        className="w-100"
                        placeholder=""
                        fullWidth
                        value={costoTotal}
                        onChange={(e) => setCostoTotal(e.target.value)}
                        // NOT CHANGED
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">Q.</InputAdornment>
                          ),
                          disabled: true,
                        }}
                      />
                    </div>
                    {/*fix Se elimina la renovacion automatica por ISSUE 
                    <div className="col-lg-4 mt-2">
                      <p className="mt-3 mb-0">
                        <strong>Frecuencia de pago (en meses):*</strong>
                      </p>
                      <TextField
                        id="payment_frequency"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={frecuenciaPago}
                        onChange={(e) => setFrecuenciaPago(e.target.value)}
                        margin="normal"
                        placeholder=""
                        type="text"
                        fullWidth
                        className="my-auto"
                        disabled
                      />
                    </div>

                    <div className="col-lg-4 mt-2">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Renovación automática</strong>
                      </label>

                      <label
                        className="toggle-control ml-3"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          value={auto_renewal}
                          onChange={() => setAutoRenewal(!auto_renewal)}
                          checked={auto_renewal}
                        />
                        <span className="control" />
                      </label>
                      </div>*/}
                    {/* Se comenta UI de tarjeta por uso de Widget de XPRESS PAGO
                    <div className="col-md-6 text-center my-5">
                      <TextField
                        style={{ width: "293px" }}
                        variant="outlined"
                        value={name}
                        name="name"
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        placeholder="Nombre del tarjetahabiente"
                      />
                     <div className="d-block my-auto">
                         <PaymentInputsWrapper {...wrapperProps}>
                          <svg
                            {...getCardImageProps({
                              images,
                            })}
                          />
                          <input
                            {...getCardNumberProps({
                              onChange: handleInputChange,
                              onFocus: handleInputFocus,
                            })}
                            name="number"
                            value={number}
                          />
                          <input
                            {...getExpiryDateProps({
                              onChange: handleInputChange,
                              onFocus: handleInputFocus,
                            })}
                            name="expiry"
                            value={expiry}
                          />
                          <input
                            {...getCVCProps({
                              onChange: handleInputChange,
                              onFocus: handleInputFocus,
                            })}
                            name="cvc"
                            value={cvc}
                          />
                        </PaymentInputsWrapper> 
                      </div>
                    </div>
                    <div className="col-md-6 my-5">
                      <Cards
                        cvc={cvc}
                        expiry={expiry}
                        focused={focus}
                        name={name}
                        number={number}
                      />
                    </div> */}
                  <div className="col-lg-12" id="creditcard-container">
                    <WidgetPayment setResponseValue = {setResponseValue} setCVC = {setCVC} cvc = {cvc} />
                  </div>
                  </div>
                  <div className="row mt-4 justify-content-center">
                    <div className="col-lg-4 d-flex justify-content-center">
                      {submitLoader ? (
                        <CircularProgress size={26} className="mx-auto" />
                      ) : (
                        <Button
                          variant="contained"
                          size="large"
                          fullWidth
                          endIcon={
                            <ArrowForwardIosIcon
                              style={{
                                fontSize: "14px",
                              }}
                            />
                          }
                          style={{
                            color: "white",
                            height: "fit-content",
                            textTransform: "none",
                            ':disabled': {
                              backgroundColor: 'gray'
                            }
                          }}
                          className="ml-auto gradient_bg_vanilla"
                          type="submit"
                        >
                          Pagar
                        </Button>
                      )}
                    </div> 
                  </div> 
                </> 
              )} 
            </div> 
          </React.Fragment>
        </form>
        <FooterLinks />
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="paymentApproved"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row justify-content-center align-items-center p-3">
                <div className="col-md-12">
                  <CheckCircleIcon
                    className="d-block mx-auto"
                    style={{ color: "#08BFE6", fontSize: "60" }}
                  />
                </div>

                <div className="col-md-12">
                  <h4 className="text-center">
                    <strong>Se ha aprobado su pago exitosamente</strong>
                  </h4>
                </div>
              </div>
              <div className="col-md-8 mx-auto">
                <Button
                  className="btn-cs1 mx-auto"
                  variant="contained"
                  color="primary"
                  to={"/app/dashboard"}
                  component={Link}
                  fullWidth
                >
                  Ir al dashboard
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Register);
