import {
  CLEAR_STATE,
  DELETE_IMAGE,
  DELETE_PRODUCT_COLOR,
  DELETE_PRODUCT_PERSONALIZATION,
  DELETE_PRODUCT_SIZE,
  GET_ALEKO_COLORS,
  GET_ALEKO_PRODUCT_PERSONALIZATIONS,
  GET_ALEKO_SIZES,
  GET_PRODUCT,
  HANDLE_FILE,
  SET_FORM,
  SET_PRODUCT_COLOR,
  SET_PRODUCT_PERSONALIZATION,
  SET_PRODUCT_SIZE,
} from "./types";

export const ProductDetailsReducer = (state, action) => {
  switch (action.type) {
    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case GET_ALEKO_SIZES:
      return {
        ...state,
        sizes: action.payload || [],
      };
    case GET_ALEKO_PRODUCT_PERSONALIZATIONS:
      return {
        ...state,
        personalizations: action.payload,
      };
    case GET_ALEKO_COLORS:
      return {
        ...state,
        colors: action.payload,
      };
    case SET_PRODUCT_COLOR:
      return {
        ...state,
        colors: [...state.colors, action.payload],
      };
    case DELETE_PRODUCT_COLOR:
      return {
        ...state,
        colors: state.colors.filter(
          (item) => item.id_new_detalle_color !== action.payload
        ),
      };

    case SET_PRODUCT_SIZE:
      return {
        ...state,
        sizes: [...state.sizes, action.payload],
      };

    case DELETE_PRODUCT_SIZE:
      const { sizes } = state;
      return {
        ...state,
        sizes: sizes.filter(
          (item) => item.id_new_detalle_tallas !== action.payload
        ),
      };

    case SET_PRODUCT_PERSONALIZATION:
      return {
        ...state,
        personalizations: [...state.personalizations, action.payload],
      };
    case DELETE_PRODUCT_PERSONALIZATION:
      const { personalizations } = state;
      return {
        ...state,
        personalizations: personalizations.filter(
          (item) => item.id_new_personalizable !== action.payload
        ),
      };
    case SET_FORM:
      return {
        ...state,
        product: {
          ...state.product,
          [action.payload.name]: isNaN(action.payload.value)
            ? action.payload.value
            : +action.payload.value,
        },
      };
    case HANDLE_FILE:
      const image = URL.createObjectURL(action.payload.target.files[0]);
      const image_file = action.payload.target.files[0];
      console.log(typeof image_file);
      return {
        ...state,
        product: {
          ...state.product,
          [`imagen_${action.index + 1}`]: image_file,
        },
        images: {
          ...state.images,
          [`imagen_${action.index + 1}`]: image,
        },
      };
    case CLEAR_STATE:
      return {
        ...state,
        product: { precio_venta: 10, descuento_precio: 0 },
        colors: [],
        sizes: [],
        personalizations: [],
      };
    case DELETE_IMAGE:
      return {
        ...state,
        product: { ...state.product, [`imagen_${action.payload + 1}`]: null },
        images: { ...state.images, [`imagen_${action.payload + 1}`]: null },
      };
    default:
      return state;
  }
};
