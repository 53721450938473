import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../../api";
import { toast } from "react-toastify";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { TrendingUpSharp } from "@material-ui/icons";

// components

//data

export default function Users_all(props) {
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {};
  }, []);

  //component did mount
  useEffect(() => {
    console.log(user);
  }, []);

  //initial data
  const user = props.history.location.state ? props.history.location.state : {};

  // states
  const { register, handleSubmit } = useForm();
  const [saveLoader, setSaveLoader] = useState(false);

  // set states
  const deleteUser = () => {
    setSaveLoader(true);
    API.admin_user
      .deleteuseradmin(user.id_superadmin)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSaveLoader(false);
          props.history.push("/admin/usuarios");
          toast.success(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.success(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setSaveLoader(false);
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const save = (data) => {
    data.id_superadmin = user.id_superadmin;
    setSaveLoader(true);
    let validForm = true;
    if (data.contrasena) {
      if (data.contrasena != data.confirm_password) {
        validForm = false;
        toast.error("Las contraseñas deben coincidir ", { autoClose: 3000 });
        setSaveLoader(false);
      }
    }

    if (validForm) {
      API.admin_user
        .updateDatauser(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success(response.message, {
              autoClose: 10000,
            });
            if (data.contrasena) {
              API.admin_user
                .emailChangePassword({
                  contrasena: data.contrasena,
                  confirmacion_contrasena: data.confirm_password,
                  correo: data.correo,
                })
                .then((res) => {
                  let response = res.data;
                  if (response.success) {
                    toast.success(response.message, {
                      autoClose: 10000,
                    });
                  } else {
                    toast.error(response.message, {
                      autoClose: 10000,
                    });
                  }
                  setSaveLoader(false);
                })
                .catch((err) => {
                  setSaveLoader(false);
                  let response = err.response;
                  console.log(err);
                  console.log(response);
                  if (response.data) {
                    if (response.data.message) {
                      toast.error(response.data.message, {
                        autoClose: 10000,
                      });
                    }
                  } else {
                    toast.error("Ha ocurrido un error", { autoClose: 10000 });
                  }
                });
            } else {
              setSaveLoader(false);
            }
          } else {
            setSaveLoader(false);
            toast.success(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          setSaveLoader(false);
          let response = err.response;
          console.log(err);
          console.log(response);
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    }
  };

  //function

  return (
    <div id="dashboard-section">
      <form className="content-card card mt-3" onSubmit={handleSubmit(save)}>
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Editar usuario SuperCloud</strong>
              </h5>
              <p className="mb-0">Editar usuario administrativo</p>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-1 mt-1 delete-btn"
                variant="contained"
                color="primary"
                component={Link}
                to={"/admin/usuarios"}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1 mx-1 mt-1"
                variant="contained"
                color="primary"
              >
                {saveLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Editar"
                )}
              </Button>
              <Button
                onClick={() => deleteUser()}
                type="button"
                className=" mx-1 mt-1"
                style={{ background: "red", color: "white" }}
              >
                {saveLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Eliminar Usuario"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Nombre</strong>
              </label>

              <TextField
                type="text"
                className="w-100"
                placeholder="Escriba el nombre del usuario"
                inputRef={register}
                name="nombre"
                required={true}
                defaultValue={user.nombre}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Teléfono</strong>
              </label>

              <TextField
                type="text"
                className="w-100"
                placeholder="(         )         -            -"
                inputRef={register}
                name="telefono"
                required={true}
                defaultValue={user.telefono}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Cargo en la empresa</strong>
              </label>

              <TextField
                type="text"
                className="w-100"
                placeholder="Escriba el cargo del usuario"
                inputRef={register}
                name="cargo"
                required={true}
                defaultValue={user.cargo}
              />
            </div>

            {/* Second Row */}
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Correo electrónico </strong>
              </label>

              <TextField
                type="email"
                className="w-100"
                placeholder="Escriba el correo electrónico del usuario"
                inputRef={register}
                name="correo"
                required={true}
                defaultValue={user.correo}
              />
            </div>

            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Ingrese nueva contraseña</strong>
              </label>

              <TextField
                name="contrasena"
                type="password"
                className="w-100"
                placeholder="Introduzca su nueva contraseña"
                inputRef={register}
              />
            </div>

            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Reingrese nueva contraseña </strong>
              </label>

              <TextField
                name="confirm_password"
                type="password"
                className="w-100"
                placeholder="Vuelva a introducir su nueva contraseña"
                inputRef={register}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
