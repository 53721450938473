import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const HistoriaCorteCajaReporte = ({ objeto }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="historialCorteCaja-reporte-table">
        <thead>
          <tr>
            <th>N° de Cierre</th>
            <th> Fecha / Hora</th>
            <th>Tienda</th>
            <th>Vendedor</th>
            <th>Monto total</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            console.log(x);

            return (
              <tr key={index}>
                <td>{x.id_caja_corte_web_app}</td>
                <td>
                  {x.fecha.split("T")[0].split("-")[2] +
                    "/" +
                    x.fecha.split("T")[0].split("-")[1] +
                    "/" +
                    x.fecha.split("T")[0].split("-")[0] +
                    " " +
                    x.fecha.split("T")[1].split(".")[0]}{" "}
                </td>
                <td>{x.sucursal}</td>
                <td>{x.vendedor}</td>
                <td>
                  {moneda}.{" "}
                  {formaterPriceFunction(x.monto_total.toFixed(2) + "")}{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default HistoriaCorteCajaReporte;
