import { DataUsage } from "@material-ui/icons";
import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const getDate = async () => {
  return await axios.get(`${base_url}/web/get-date`, {
    headers: headers(),
  });
};

const getSummary = async (idEmpresa, data) => {
  console.log("test ->", data);
  console.log("test", idEmpresa);
  idEmpresa = isNaN(parseInt(idEmpresa)) ? null : idEmpresa;
  data = isNaN(parseInt(data)) ? null : data;

  const tiSend = {
    id_new_empresa: idEmpresa,
    id_sucursal_bodega_ubicacion: parseInt(data),
  };
  console.log("test", tiSend);
  if (idEmpresa) {
    return await axios.post(
      `${base_url}/web/get-summary`,
      qs.stringify(tiSend),
      { headers: headers() }
    );
  }

  return await axios.post(`${base_url}/web/get-summary`, qs.stringify(tiSend), {
    headers: headers(),
  });
};

const getSummaryNew = async (idEmpresa, data) => {
  idEmpresa = isNaN(parseInt(idEmpresa)) ? null : idEmpresa;
  data = isNaN(parseInt(data)) ? null : data;
  console.log(data);
  const tiSend = { id_sucursal_bodega_ubicacion: data };

  return await axios.post(
    `${base_url}/web/get-consolidado-pos`,
    qs.stringify(tiSend),
    { headers: headers() }
  );
};

const getSummaryCf = async (id_empresa) => {
  return axios.get(
    `${base_url}/web/get-summary-cf?id_new_empresa=${id_empresa}`,
    {
      headers: headers(),
    }
  );
};

const getRecentTraslado = async (id_empresa) => {
  if (id_empresa) {
    return await axios.get(
      `${base_url}/web/get-recent-traslado?id_new_empresa=${id_empresa}`,
      {
        headers: headers(),
      }
    );
  } else {
    return await axios.get(`${base_url}/web/get-recent-traslado`, {
      headers: headers(),
    });
  }
};

const getMostSaleProducts = async (data) => {
  return await axios.post(
    `${base_url}/web/get-most-sale-products-dashboard`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getLowStockProducts = async (data) => {
  return await axios.post(
    `${base_url}/web/get-low-stock-products-dashboard`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getAllProducts = async (data) => {
  return await axios.post(
    `${base_url}/web/get-products-dashboard`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getNearExpiryProducts = async (data) => {
  return await axios.post(
    `${base_url}/web/get-near-expiry-products-dashboard`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getStatistics = async (data) => {
  data.web = true;
  return await axios.post(
    `${base_url}/web/get-dashboard-statistics`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getSalesReports = async (data) => {
  return await axios.post(`${base_url}/web/sales-report`, qs.stringify(data), {
    headers: headers(),
  });
};

// gerente regional
const getCompanyData = async (id_empresa) => {
  return axios.get(
    `${base_url}/web/get-company-data?id_new_empresa=${id_empresa}`,
    { headers: headers() }
  );
};

export default {
  getDate,
  getSummary,
  getSummaryNew,
  getRecentTraslado,
  getStatistics,
  getMostSaleProducts,
  getLowStockProducts,
  getAllProducts,
  getNearExpiryProducts,
  getSalesReports,
  // gerente regional
  getCompanyData,
  getSummaryCf,
};
