import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const VentasReporte = ({ objeto, isGerente }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="ventas-reporte-table">
        <thead>
          <tr>
            <th>Sucursal</th>
            <th>División</th>
            <th>Categoría</th>
            <th>Item</th>
            <th>Monto</th>
            <th>Vendedor</th>
            <th>Unidades</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            return (
              <tr key={index}>
                <td>{x.sucursal}</td>
                <td>{x.division}</td>
                <td>{x.categoria}</td>
                <td>{x.item}</td>
                <td>
                  {moneda}.{formaterPriceFunction(x.monto.toFixed(2) + "")}
                </td>
                <td>{x.id_vendedor_web_app}</td>
                <td>
                  {moneda}.{formaterPriceFunction(x.unidades.toFixed(2) + "")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VentasReporte;
