import React, { useState } from "react";
import { IconButton, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const Supplie = ({
  input,
  remove_input,
  index,
  editSupplie,
  handleSupplieUpdate,
}) => {
  const [editMode, setEditMode] = useState(false);
  return (
    <div className="row justify-content-center">
      <div className="col-md-1 mt-3 d-flex justify-content-center align-items-center">
        <IconButton
          className="btn-cs1 icon-btn-delete"
          variant="contained"
          color="primary"
          type="button"
          onClick={() => remove_input(index, input.id_new_item, input.idReceta)}
        >
          <DeleteIcon
            style={{
              color: "white",
            }}
          />
        </IconButton>
      </div>

      <div className="col-md-3 mt-3">
        <p
          style={{
            fontSize: "0.8rem",
          }}
        >
          <strong>{input.nombre}</strong>
        </p>
        <p
          style={{
            fontSize: "0.8rem",
          }}
        >
          SKU: {input.codigo}
        </p>
      </div>

      <div className="col-md-3 mt-3">
        <p
          style={{
            fontSize: "0.8rem",
          }}
        >
          <strong>Unidad/Medida</strong>
        </p>
        <p
          style={{
            fontSize: "0.8rem",
          }}
        >
          {input.unidad}
        </p>
      </div>
      <div className="col-md-3 mt-3">
        <p
          style={{
            fontSize: "0.8rem",
          }}
        >
          <strong>Cant. utilizada/producto</strong>
        </p>
        {!editMode ? (
          <p
            style={{
              fontSize: "0.8rem",
            }}
          >
            {input.cantidad}
          </p>
        ) : (
          <TextField
            type="number"
            inputProps={{ style: { fontSize: "0.8rem" } }}
            InputLabelProps={{ style: { fontSize: "0.8rem" } }}
            placeholder={input.cantidad}
            onChange={(e) => {
              handleSupplieUpdate({
                ...input,
                cantidad: +e.target.value,
              });
            }}
          />
        )}
      </div>
      <div className="col-md-2 mt-3">
        <p
          style={{
            fontSize: "0.8rem",
          }}
        >
          <strong>Editar</strong>
        </p>

        <button
          type="button"
          className={`btn btn-small ${
            editMode ? "btn-success" : "btn-primary"
          }`}
          onClick={() => {
            setEditMode((prev) => !prev);
            if (editMode) {
              editSupplie(input.id_new_item);
            }
          }}
        >
          {editMode ? "Guardar" : "Editar"}
        </button>
      </div>
    </div>
  );
};

export default Supplie;
