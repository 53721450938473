import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const addToNotifyList = async (data) => {
  return await axios.post(
    `${base_url}/web/setup-notification`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getUserNotifications = async (data) => {
  return await axios.post(
    `${base_url}/web/get-notifications-web`,
    qs.stringify(data),
    { headers: headers() }
  );
};

export default {
  addToNotifyList,
  getUserNotifications,
};
