import React, { useEffect, useReducer, useState } from "react";
import CambioMasivoContext from "./CambioMasivoContext";
import { CambioMasivoReducer } from "./CambioMasivoReducer";
import {
  CLEAR_STATE,
  GET_SUCURSALS,
  HANDLE_CHANGE_SUCURSAL,
  LOADER_UPDATE,
  SET_ENTERPRISE,
  SET_ENTERPRISES,
  SET_LOADER,
  SET_NEW_PRICE,
  SET_PRODUCTS,
  SET_SELECTED_PRODUCTS,
  SET_VALIDATION,
  SUCCESS_UPDATE,
} from "./types";

import API from "../../api";

const initialState = {
  sucursals: [],
  sucursal: "",
  enterprises: [],
  enterprise: "",
  products: [],
  selectedProducts: [],
  newPrice: 0,
  validation: false,
  loading: false,
  loaderUpdate: false,
  updated: false,
};
const CambioMasivoProvider = (props) => {
  const [state, dispatch] = useReducer(CambioMasivoReducer, initialState);
  const [productName, setProductName] = useState("");
  const getSucursals = async () => {
    const id_empresa = localStorage.getItem("id_empresa");
    try {
      const { data } = await API.sucursal.getAll(parseInt(id_empresa), true);
      dispatch({ type: GET_SUCURSALS, payload: data.data });
    } catch (error) {
      console.log(error);
    }
  };
  const clearState = () => {
    setProductName("");
    dispatch({ type: CLEAR_STATE, payload: initialState });
  };
  const changeSucursal = (e) => {
    dispatch({ type: HANDLE_CHANGE_SUCURSAL, payload: e.target.value });
  };
  const getEnterprises = async (id_user) => {
    let newEmpresa = [];

    try {
      const res = await API.users.getEmpresaPorUsuario(id_user);
      const data = res.data.data;

      // filtrar las empresas
      data.forEach((empresa) => newEmpresa.push(empresa.empresa));

      let filtradas = newEmpresa.reduce((acc, el) => {
        if (!acc.find((item) => item.id_new_empresa == el.id_new_empresa)) {
          acc.push(el);
        }
        return acc;
      }, []);

      dispatch({ type: SET_ENTERPRISES, payload: filtradas });
    } catch (error) {
      console.log("error al obtener empresas gerente ->", error);
    }
  };
  const handleChangeEnterprise = async (e) => {
    dispatch({ type: SET_ENTERPRISE, payload: e.target.value });
    try {
      const { data } = await API.sucursal.getAll(
        parseInt(e.target.value),
        true
      );
      dispatch({ type: GET_SUCURSALS, payload: data.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetProducts = async (nombre) => {
    dispatch({ type: SET_LOADER });
    try {
      const { data } = await API.products.getAll({
        // id_sucursal_bodega_ubicacion: state.sucursal,
        id_sucursal_bodega_ubicacion: null,
        id_new_empresa: state.enterprise,
        nombre,
      });
      dispatch({ type: SET_PRODUCTS, payload: data.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectProducts = (info) => {
    let { selectedRows } = info;
    dispatch({
      type: SUCCESS_UPDATE,
      payload: false,
    });
    dispatch({ type: SET_SELECTED_PRODUCTS, payload: selectedRows });
  };

  const handleNewPrice = (e) => {
    e.target.value = Math.abs(e.target.value);
    dispatch({ type: SET_NEW_PRICE, payload: e.target.value });
    disableValidation();
  };

  const handleUpdateProducts = async (setOpen) => {
    if (state.loaderUpdate) return;
    dispatch({ type: LOADER_UPDATE, payload: true });
    const updatedProducts = state.selectedProducts.map((item) => ({
      id_new_item: item.id_new_item,
      precio: parseFloat(state.newPrice),
    }));
    if (parseFloat(state.newPrice)) {
      try {
        const { data } = await API.products.actualizacionMasiva({
          productos: JSON.stringify(updatedProducts),
        });
        const newUpdatedProducts = state.products.map((x) =>
          updatedProducts.find((y) => y.id_new_item === x.id_new_item)
            ? {
                ...state.products.find((y) => y.id_new_item === x.id_new_item),
                precio_venta: parseFloat(state.newPrice),
              }
            : x
        );
        if (data.ok) {
          setOpen(false);
          dispatch({
            type: SUCCESS_UPDATE,
            payload: true,
          });
          dispatch({ type: SET_PRODUCTS, payload: newUpdatedProducts });
          dispatch({ type: SET_SELECTED_PRODUCTS, payload: [] });
          dispatch({ type: LOADER_UPDATE, payload: false });
          dispatch({ type: SET_NEW_PRICE, payload: 0 });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch({ type: SET_VALIDATION, payload: true });
      dispatch({ type: LOADER_UPDATE, payload: false });
    }
  };
  const disableValidation = () => {
    dispatch({ type: SET_VALIDATION, payload: false });
  };

  useEffect(() => {
    if (productName.trim() && state.enterprise && state.sucursal) {
      handleGetProducts(productName);
    }
  }, [state.sucursal, state.enterprise]);
  return (
    <CambioMasivoContext.Provider
      value={{
        getSucursals,
        changeSucursal,
        getEnterprises,
        handleChangeEnterprise,
        handleGetProducts,
        handleSelectProducts,
        handleNewPrice,
        handleUpdateProducts,
        disableValidation,
        sucursals: state.sucursals,
        sucursal: state.sucursal,
        enterprises: state.enterprises,
        enterprise: state.enterprise,
        loading: state.loading,
        loaderUpdate: state.loaderUpdate,
        products: state.products,
        selectedProducts: state.selectedProducts,
        validation: state.validation,
        updated: state.updated,
        productName,
        setProductName,
        clearState,
      }}
    >
      {props.children}
    </CambioMasivoContext.Provider>
  );
};

export { CambioMasivoProvider };
