import { Tab, Tabs } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router";
import TabPanel from "../../../../components/TabPanel";
import ProductPersonalizationContext from "../../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { CancelButton } from "../../../styled/styled.buttons";
import {
  destryActualOrder,
  getActualOrder,
} from "../helpers/handleLocalOrders";
import Details from "./details";
import { useOrder } from "./hooks/useOrder";
import ProductsDetail from "./products";

const client = {
  nombre: "Juan Fernández",
  email: "juanfernandez@gmail.com",
  telefono: "(1234) 4567-123",
  NIT: "123456789",
  order: {
    tienda: "Miraflores",
    empleado: "Luis fernandez",
    fecha_creacion: "Jun, 22 2021",
    fecha_entrega: "jun, 28 2021",
    estatus: 1,
  },
};

const OrderDetails = () => {
  const params = useParams();
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(0);
  const { currentOrder } = useOrder(params.id);
  const { pedido, setPedido } = useContext(ProductPersonalizationContext);

  useEffect(() => {
    if (!pedido) {
      const actuarOrder = getActualOrder();
      setPedido(actuarOrder);
    }
  }, []);

  const handleTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const status = (num) => {
    switch (num) {
      case 1:
        return "En proceso";

      default:
        return "boton";
    }
  };

  const goBack = () => {
    destryActualOrder();
    history.goBack();
  };
  console.log(pedido);
  return (
    <div className="row">
      <div className="col-md-12 d-flex justify-content-between">
        <div
          className="btn text-light "
          style={{ background: "rgba(1, 96, 231, 1)" }}
          onClick={goBack}
        >
          Volver
        </div>
        <div>
          <button
            className="btn  text-light mx-2 d-none"
            style={{ background: "rgba(235,87,87,1)" }}
            onClick={goBack}
          >
            Cancelar
          </button>
          <button
            className="btn  text-light d-none"
            style={{ background: "rgba(8,191,230,1)" }}
          >
            Imprimir
          </button>
        </div>
      </div>
      <div className="col-md 12 mt-3">
        <div className="card shadow-sm" style={{ border: "none" }}>
          <div className="card-body px-0 pt-0">
            <div className="px-3 pt-3 card-title shadow-sm">
              <h3>
                <strong>Detalles de la orden</strong>
              </h3>
              <Tabs
                className="mt-2"
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTab}
              >
                <Tab className="tab" label="Detalles" />
                <Tab className="tab" label="Productos" />
              </Tabs>
            </div>
            <TabPanel value={selectedTab} index={0}>
              <Details client={client} pedido={pedido} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <ProductsDetail
                currentOrder={currentOrder}
                firma={pedido?.firma_cliente}
              />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
