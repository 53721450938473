import { Button, TextField } from "@material-ui/core";
import React from "react";
import { useSap } from "../CustomHooks/useSap";
import moment from "moment";
import "../custom.css";
const CardHeader = (props) => {
  const { fecha, handleFecha, downloadSap } = props;
  return (
    <div className="card-header">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center flex-wrap">
          <div className="mb-2">
            <p className="mb-0">
              <strong>Descargas a SAP</strong>
            </p>
            <p className="mb-0">
              Consulte y descargue las operaciones realizadas en SAP
            </p>
          </div>

          <div className="mx-sm-2 mb-2">
            <p className="mb-0">Filtrar por:</p>
            <div className="d-flex align-items-center flex-wrap">
              <TextField
                type="date"
                className="w-100"
                value={moment(fecha).format("YYYY-MM-DD")}
                onChange={handleFecha}
                inputProps={{
                  max: moment().format("YYYY-MM-DD"),
                  step: "any",
                }}
              />
            </div>
          </div>
        </div>
        <Button
          className="btn-cs1"
          variant="contained"
          color="primary"
          onClick={downloadSap}
          style={{
            width: "max-content",
            height: "43px",
            boxShadow: "none",
          }}
        >
          <span style={{ color: "#fff", fontSize: "14px" }}>
            Realizar descargas
          </span>
        </Button>
      </div>
    </div>
  );
};

export default CardHeader;
