import { admin_base_url, axios } from "../../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("AdminToken") };
};

const login = async (data) => {
  //console.log(admin_base_url)
  return await axios.post(`${admin_base_url}/login`, qs.stringify(data));
};

const updateDatauser = async (data) => {
  return await axios.put(
    `${admin_base_url}/web/update-profile`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const deleteuseradmin = async (iduser) => {
  let headersimple = {
    authorization: localStorage.getItem("AdminToken"),
  };
  //console.log(headersimple)
  return await axios.delete(
    `${admin_base_url}/web/delete-user-admin/${iduser}`,
    { headers: headersimple }
  );
};

const logoutOfAllDevices = async (data) => {
  return await axios.post(
    `${admin_base_url}/logout-of-all-devices`,
    qs.stringify(data)
  );
};

const logout = async () => {
  return await axios.post(
    `${admin_base_url}/logout`,
    {},
    { headers: headers() }
  );
};

const register = async (data) => {
  return await axios.post(`${admin_base_url}/signup`, qs.stringify(data));
};

const sendEmailCode = async (data) => {
  return await axios.post(
    `${admin_base_url}/send-email-code`,
    qs.stringify(data)
  );
};

const verifyEmailCode = async (data) => {
  return await axios.post(
    `${admin_base_url}/verify-email-code`,
    qs.stringify(data)
  );
};

const emailChangePassword = async (data) => {
  return await axios.post(
    `${admin_base_url}/update-password`,
    qs.stringify(data)
  );
};

const getAdmins = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/get-admins`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getCurrentUser = async () => {
  return await axios.get(`${admin_base_url}/web/get-admin`, {
    headers: headers(),
  });
};

const updatePicture = async (file) => {
  const form_data = new FormData();
  form_data.append("images", file);
  return await axios.post(`${admin_base_url}/web/update-picture`, form_data, {
    headers: headers(),
  });
};

export default {
  login,
  updateDatauser,
  logoutOfAllDevices,
  logout,
  register,
  sendEmailCode,
  verifyEmailCode,
  emailChangePassword,
  getAdmins,
  getCurrentUser,
  deleteuseradmin,
  updatePicture,
};
