import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';

const WidgetPayment = ({setResponseValue, setCVC, cvc}) => {
  const widgetRef = useRef(null);
  const [reload, setReload] = useState(false);

  const handleCVVChange = (event) => {
    if(event.target.value.length <= 4) {
      setCVC(event.target.value);
    }
    event.target.value = event.target.value.substring(0,4);
  };

  useEffect(() => {
    const url = process.env.REACT_APP_URL_APIKEY;
    const apiKey = process.env.REACT_APP_APIKEY;

    $.ajax({
      url: url,
      type: 'GET',
      data: { "APIKey": apiKey, "Token": '', "Culture": "es" },
      success: function (jsonResponse) {
        $(widgetRef.current).html(jsonResponse);

        const scriptElement = document.createElement('script');
        scriptElement.innerHTML = `
          var respData = '';
          function SaveCreditCard_FailureCallback(response) {				
            //console.log(response);
          }
          function SaveCreditCard_SuccessCallback(response) {		
            respData = response;
            const event = new CustomEvent('respDataChange', { detail: respData });
            window.dispatchEvent(event);
          } 
          function SaveCreditCard_CancelCallback() {
            //$("#creditcard-container").slideUp(500);
            respData = '';
            const event = new CustomEvent('respDataChange', { detail: respData });
            window.dispatchEvent(event);
          }
        `;
        widgetRef.current.appendChild(scriptElement);

        //Incrustar input para toma del cvv
        //Obtener input incrustados por widget
        const divContainers = $(".card-ui-component-input-style");
        const lastDivContainer = divContainers.last();

        //Creacion de div para label y nuevo input cvv
        const inputContainer = document.createElement('div');
        inputContainer.style.display = 'flex';
        inputContainer.style.alignItems = 'center';
        lastDivContainer.append(inputContainer);

        //Crear label
        const labelElement = document.createElement('label');
        labelElement.textContent = 'CVV: ';
        labelElement.className = 'card-ui-component-label-style';
        lastDivContainer.append(labelElement);

        //Crear input
        const nuevoInputElement = document.createElement('input');
        nuevoInputElement.id = 'car_ui_component_id_custom';
        nuevoInputElement.className = 'card-ui-component-field';
        nuevoInputElement.type = 'number';
        nuevoInputElement.value = cvc;
        nuevoInputElement.addEventListener('input', handleCVVChange);
        nuevoInputElement.style.width = '100%'; // Eliminar la propiedad width por defecto de clase
        nuevoInputElement.style.maxWidth = '175px'
        inputContainer.append(nuevoInputElement);
        lastDivContainer.append(inputContainer);

        //Ocultar botones que incrusta el widget
        $("#creditcard-container").slideDown(500);
        $("#card-ui-component-btn-confirm").hide();
        $("#card-ui-component-btn-cancel").hide();
        $("#card-ui-component-ddl-expiry-month").css('width', '75px');
        $("#card-ui-component-ddl-expiry-year").css('width', '100px');
        $('#card-ui-component-form-creditcard').addClass("mt-5");
        $("#card-ui-component-txt-creditcard-response").css('visibility','hidden');
        $("#close-button").css('visibility','hidden');
        $('#card-ui-component-txt-creditcard-response').css('height', '0');
        $("#card-ui-component-btn-cancel").click(function () {
          setReload(!reload);
        });
      },
      error: function (error) {
        console.error(error);
      }
    });

  }, [reload]);

  useEffect(() => {
    const handleRespDataChange = (event) => {
      const respData = event.detail;
      setResponseValue(respData);
    };

    // Suscribirse a los cambios en respData
    window.addEventListener('respDataChange', handleRespDataChange);

    // Remover la suscripción cuando el componente se desmonte
    return () => {
      window.removeEventListener('respDataChange', handleRespDataChange);
    };
  }, []);

  return (
  <>
    <div ref={widgetRef}></div>;
  </>
  )
};

export default WidgetPayment;
