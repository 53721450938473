import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";

const TrasladosReporte = ({ objeto }) => {
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="traslados-reporte-table">
        <thead>
          <tr>
            <th>N° de traslado</th>
            <th>Fecha / Hora</th>
            <th>Salida - Destino</th>
            <th>N° de Productos</th>
            <th>Estatus</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            console.log(x);

            return (
              <tr key={index}>
                <td>{x.identificador}</td>
                <td>
                  {moment(x.fecha).utcOffset(0).format("DD/MM/YYYY h:mm:ss a")}
                </td>
                <td>
                  {x.origin_sucursal} - {x.destino_sucursal}
                </td>
                <td>{numberFormater(x.total_productos)}</td>
                <td>{x.estado} </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TrasladosReporte;
