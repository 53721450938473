import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import useInfinityScroll from "./utils/useInfinity";
import { getLocalParseToken } from "../../../../helpers/handleLocalStorage";
import { calcularPorcentageBarLine } from "../../../../helpers/utilsFunctions";
import api from "../../../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#33eaff",
  },
}))(LinearProgress);

const Markets = ({ sucursal, loader }) => {
  // const loader = useRef();

  // const [page, setPage] = useState(0);
  const [markets, setMarkets] = useState([]);

  // let { markets } = useInfinityScroll(page);
  // markets = markets.sort((a, b) => b.amount - a.amount);

  // const handleObserver = useCallback((entries) => {
  //   const target = entries[0];
  //   if (target.isIntersecting) {
  //     setPage((prev) => prev + 10);
  //   }
  // }, []);

  // useEffect(() => {
  //   const option = {
  //     root: null,
  //     rootMargin: "20px",
  //     threshold: 0,
  //   };
  //   const observer = new IntersectionObserver(handleObserver, option);

  //   if (loader.current) observer.observe(loader.current);
  // }, [handleObserver]);

  useEffect(() => {
    if (sucursal) {
      getTiendasAleko();
    }
  }, [sucursal]);

  const getTiendasAleko = async () => {
    let idEmpresa = getLocalParseToken("id_empresa");
    const response = await api.informesAleko.getInformesTiendas(
      idEmpresa,
      sucursal.id_linea_web_app
    );

    if (response.data.succes) {
      setMarkets(response.data.data.grafica_sucursals);
    } else {
      toast.warning("No hay datos registrados de las tiendas", {
        autoClose: 10000,
      });
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-text">
          <div className="shadow-sm px-3 py-3 d-flex justify-content-between align-items-center">
            <div>
              <h5 className="p-0 m-0">
                <strong>Tiendas</strong>
              </h5>
            </div>
            <div>
              {/* <div className="section-title">
                <select className="form-select">
                  <option>Últimos 30 días</option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className="card-body scroll"
          style={{
            height: "calc(100vh - 130px - 55.31px)",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          <div>
            {loader ? (
              <div className="mx-auto text-center">
                <CircularProgress size={20} />
              </div>
            ) : markets.length === 0 ? (
              <div className="mx-auto text-center">
                <p>Sin datos para mostrar</p>{" "}
              </div>
            ) : (
              markets.map((market, index) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-md-1 d-flex align-items-center justify-content-center border-right">
                        {index + 1}
                      </div>
                      <div className="col-md-8 border-right">
                        <div>
                          <strong>{market.nombre_sucursal}</strong>{" "}
                        </div>

                        <BorderLinearProgress
                          variant="determinate"
                          value={calcularPorcentageBarLine(market.porcentaje)}
                        />
                      </div>
                      <div className="col-md-3 d-flex align-items-end justify-content-center ">
                        <div>Q. {market.Monto.toFixed(2)}</div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
            {/* <div
              style={{
                display: markets.length ? "block" : "none",
                padding: "2px",
              }}
              ref={loader}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Markets;
