import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  Menu as MenuIcon,
  NotificationsNone as NotificationsIcon,
  Notifications as NotificationsIconFull,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import API from "../../api";
import { base_url_images } from "../../api";

// styles
import useStyles from "./styles";
import "./customStyle.css";

//icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MailIcon from "@material-ui/icons/Mail";
import CloseIcon from "@material-ui/icons/Close";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";

export default function Header(props) {
  const moment = require("moment");
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  const history = useHistory();
  // local
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [notifications, setNotifications] = useState([]);
  var [profileMenu, setProfileMenu] = useState(null);
  const [profileImg, setProfileImg] = useState("/images/profile-logo.png");

  useEffect(() => {
    return history.listen((location) => {
      getNotifications();
    });
  }, [history]);

  useEffect(() => {
    getNotifications();
    get_users();
  }, []);

  const get_users = () => {
    API.profile
      .getCompanyData()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          if (response.data.logo) {
            setProfileImg(base_url_images + response.data.logo);
          }
        }
      })
      .catch((err) => {
        let response = err.response?.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
        } else {
          console.log("Ha ocurrido un error con las sucursales");
        }
      });
  };

  const getNotifications = () => {
    if (!!localStorage.getItem("player_id")) {
      let data = {
        player_id: localStorage.getItem("player_id"),
      };
      API.notifications
        .getUserNotifications(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            console.log("GETTING THESE NOTIFICATIONS", response.data);
            setNotifications(response.data);
          }
        })
        .catch((err) => {
          let response = err.response.data;
          console.log(response);
          console.log("An error has occurred while getting user notifications");
        });
    } else {
      console.log("player_id not found for getNotifications");
    }
  };

  return (
    <AppBar
      position="fixed"
      className={[classes.appBar, "navbar-wrapper"].join(" ")}
    >
      <Toolbar className="navbar">
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon className="icon" />
          ) : (
            <MenuIcon className="icon" />
          )}
        </IconButton>
        <Link to={"/"}>
          <div className="logoContainer">
            <img src="/images/logo.png" alt="icon" />
          </div>
        </Link>
        <div className={classes.grow} />

        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setNotificationsMenu(e.currentTarget);
          }}
          className={classes.headerMenuButton}
        >
          {notifications.length === 0 ? (
            <NotificationsIcon className="icon" />
          ) : (
            <NotificationsIconFull className="icon" />
          )}
        </IconButton>

        <div
          id="profile-menu-btn"
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <div style={{ width: "45px" }} className="row mx-0">
            <div className="col-9 px-0">
              <img className="w-100 h-100" src={profileImg} alt="profile" />
            </div>
            <div className="col-3 profile-expand px-0">
              <ExpandMoreIcon style={{ color: "white" }} fontSize="small" />
            </div>
          </div>
        </div>

        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          class="notification_header"
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          disableAutoFocusItem
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div className="notification-header">
            <p className="mb-0">
              <div className="d-flex justify-content-between align-items-center px-2">
                <strong>Notificaciones</strong>
                <CloseIcon
                  aria-haspopup="true"
                  className="fa fa-times"
                  onClick={() => setNotificationsMenu(null)}
                />
              </div>
            </p>
          </div>
          {notifications.length > 0 ? (
            <ul className="notification-wrapper">
              {notifications.map((notification) => (
                <>
                  {/* {(moment(notification.data.fecha_notificacion).diff(moment(), "minutes") < 0)
										? */}
                  <div className="message-wrapper unread">
                    <div className="row mx-0">
                      <div className="col-12">
                        {notification.data.type === "traslado" ? (
                          <p className="type mt-2">
                            <strong style={{ color: "#0160E7" }}>
                              Traslados
                            </strong>
                          </p>
                        ) : (
                          ""
                        )}
                        {notification.data.type === "traslado" ? (
                          <p className="description">
                            <strong>{notification.data.nombre}</strong>{" "}
                            {notification.contents.en}
                          </p>
                        ) : (
                          ""
                        )}
                        {notification.data.type === "existencia" ? (
                          <p className="type mt-2">
                            <strong style={{ color: "#0160E7" }}>
                              {notification.headings.en}
                            </strong>
                          </p>
                        ) : (
                          ""
                        )}
                        {notification.data.type === "existencia" ? (
                          <p className="description">
                            <strong>{notification.data.nombre}</strong> solo
                            tiene {notification.data.stock} unidades. La
                            cantidad mínima son {notification.data.stock_minimo}
                          </p>
                        ) : (
                          ""
                        )}
                        {notification.data.type === "expiracion" ? (
                          <p className="type mt-2">
                            <strong style={{ color: "#0160E7" }}>
                              {notification.headings.en}
                            </strong>
                          </p>
                        ) : (
                          ""
                        )}
                        {notification.data.type === "expiracion" ? (
                          <p className="description">
                            <strong>{notification.data.nombre}</strong>{" "}
                            {notification.contents.en}
                          </p>
                        ) : (
                          ""
                        )}
                        <small className="mb-0 date">
                          <strong>Hace: </strong>
                          {Math.abs(
                            moment(notification.data.fecha_notificacion).diff(
                              moment(new Date()),
                              "minutes"
                            )
                          ) < 60
                            ? ` ${Math.abs(
                                moment(
                                  notification.data.fecha_notificacion
                                ).diff(moment(new Date()), "minutes")
                              )} minutos`
                            : ""}
                          {Math.abs(
                            moment(notification.data.fecha_notificacion).diff(
                              moment(new Date()),
                              "minutes"
                            )
                          ) > 60
                            ? ` ${Math.abs(
                                moment(
                                  notification.data.fecha_notificacion
                                ).diff(moment(new Date()), "hours")
                              )} horas`
                            : ""}
                          {Math.abs(
                            moment(notification.data.fecha_notificacion).diff(
                              moment(new Date()),
                              "days",
                              true
                            )
                          ) > 1.0
                            ? ` ${Math.abs(
                                moment(
                                  notification.data.fecha_notificacion
                                ).diff(moment(new Date()), "days")
                              )} días`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* 	: ""} */}
                </>
              ))}
            </ul>
          ) : (
            <div className="text-center p-4">
              <p>No tienes notificaciones</p>
            </div>
          )}
        </Menu>
        {/*  THIS FOLLOWING WAS COMMENTED 15-02-2021 AND TAKEN OUT OF ITS PLACE */}
        {/* <div className="col-2 px-0 d-flex justify-content-center align-items-center">
									<MailIcon className="mail-icon" style={{ fontSize: '24px' }} />
								</div> */}
        {/* <div className="notification-footer px-4 py-1">
					<p className="mb-0">Filtrar por no leídas</p>
				</div> */}

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <MenuItem className="justify-content-end menuItem-border">
            <Link
              to="/app/profile"
              style={{ color: "#4A4A4A" }}
              id="enterprise-profile-button"
            >
              Perfil de empresa
            </Link>
          </MenuItem>
          <MenuItem
            className="justify-content-end"
            onClick={() => signOut(userDispatch, props.history)}
          >
            Cerrar sesión
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
