import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
//eslint-disable-next-line
import $ from "jquery";
//eslint-disable-next-line
import { Link } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import DataTable from "react-data-table-component";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import CashRegister from "./CashRegister.png";
import Document from "./Document.png";

// components

//data

const payment_periods = [
  {
    id: 1,
    option: "Pago único",
    value: "0",
  },
  {
    id: 2,
    option: "3 meses",
    value: "3",
  },
  {
    id: 3,
    option: "6 meses",
    value: "6",
  },
  {
    id: 4,
    option: "12 meses",
    value: "12",
  },
  {
    id: 5,
    option: "18 meses",
    value: "18",
  },
  {
    id: 6,
    option: "24 meses",
    value: "24",
  },
  {
    id: 7,
    option: "36 meses",
    value: "36",
  },
];

const detailed_expenses = [
  {
    producto: {
      nombre: "Zapatos elegantes para caballeros",
      codigo: "01231231232103",
    },
    precio: "1223.25",
    cantidad: "10",
    total: "1370,04",
  },
  {
    producto: {
      nombre: "Zapatos elegantes para caballeros",
      codigo: "013220112312332103",
    },
    precio: "1223.25",
    cantidad: "03",
    total: "1370,04",
  },
  {
    producto: {
      nombre: "Zapatos elegantes para caballeros",
      codigo: "013515151032103",
    },
    precio: "1223.25",
    cantidad: "01",
    total: "1370,04",
  },
  {
    producto: {
      nombre: "Zapatos elegantes para caballeros",
      codigo: "0133232311032103",
    },
    precio: "5000.25",
    cantidad: "05",
    total: "4000",
  },
  {
    producto: {
      nombre: "Zapatos elegantes para caballeros",
      codigo: "999901211032103",
    },
    precio: "1223.25",
    cantidad: "02",
    total: "1200",
  },
  {
    producto: {
      nombre: "Zapatos elegantes para caballeros",
      codigo: "666161211032103",
    },
    precio: "100.25",
    cantidad: "01",
    total: "1370,04",
  },
  {
    producto: {
      nombre: "Zapatos elegantes para caballeros",
      codigo: "2323231211032103",
    },
    precio: "1000.25",
    cantidad: "01",
    total: "1370,04",
  },
];

export default function Sell(props) {
  // states
  const [nit] = useState("1245425422");
  const [clientName] = useState("Marcelo Jiménez");
  const [direccion_fiscal] = useState(
    "Calle occidente, con 2 y 3, quinta estación"
  );
  const [email] = useState("marcelo@gmail.com");
  const [seller] = useState("Nombre del vendedor");
  //eslint-disable-next-line
  const [periods, setPeriods] = useState("");
  //eslint-disable-next-line
  const [nota_autor, setNotaAutor] = useState("Juan Manuel Álvarez");
  //eslint-disable-next-line
  const [monto_ajustado, setMontoAjustado] = useState("1,000.00");
  const [total_actualizado, setTotalActualizado] = useState("2,646.45");
  const [nota_concepto, setNotaConcepto] = useState("Ajuste de precio");
  const [date] = useState("12-03-2020 / 13:52:21");

  // set states

  const changePeriods = (event) => {
    setPeriods(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "nota_autor":
        setNotaAutor(value);
        break;
      case "nota_concepto":
        setNotaConcepto(value);
        break;
      case "total_actualizado":
        setTotalActualizado(value);
        break;
      case "monto_ajustado":
        setMontoAjustado(value);
        break;
      default:
        break;
    }
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Facturación</strong>
        </h4>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="d-flex justify-content-center justify-content-sm-between flex-wrap mb-2">
            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<img src={Document} alt="cash-register-icon" />}
            >
              <span style={{ marginBottom: "-4px" }}>Ver documento</span>
            </Button>

            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<img src={CashRegister} alt="cash-register-icon" />}
              component={Link}
              to={"/app/vender"}
            >
              <span style={{ marginBottom: "-4px" }}>Volver a la caja</span>
            </Button>
          </div>
          <div className="content-card card">
            <div className="card-header">
              <h4 className="mb-0">
                <strong>Resumen de la compra</strong>
              </h4>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Fecha / hora</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={date}
                    name="fecha"
                    disabled
                  />
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>NIT de cliente</strong>
                  </p>

                  <TextField
                    className="w-100"
                    variant="standard"
                    name="nit"
                    value={nit}
                    disabled
                  />
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Nombre de cliente</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={clientName}
                    name="client_name"
                    disabled
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Dirección fiscal del cliente</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={direccion_fiscal}
                    name="direccion_fiscal"
                    disabled
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Correo electrónico</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={email}
                    name="email"
                    disabled
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Vendedor</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={seller}
                    name="seller"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card content-card mt-2">
            <div className="card-header p-0" id="facturacion-products-header">
              <DataTable
                className="datatable-cs datatable-cs-v2 m-0"
                title={"s"}
                columns={[
                  {
                    name: "Producto",
                    selector: "producto",
                    sortable: true,
                    cell: (row) => (
                      <div>
                        {row.producto.nombre} <br />
                        {row.producto.codigo}
                      </div>
                    ),
                  },
                  {
                    name: "Precio",
                    selector: "precio",
                    sortable: true,
                    cell: (row) => <div>Q. {row.precio}</div>,
                  },
                  {
                    name: "Cantidad",
                    selector: "cantidad",
                    sortable: true,
                  },
                  {
                    name: "Total",
                    selector: "total",
                    sortable: true,
                    cell: (row) => <div>Q. {row.total}</div>,
                  },
                ]}
                data={detailed_expenses}
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
              />
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-7">
                  <div className="row">
                    <div
                      className="col-md-12 mb-2"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <p>
                        <strong>Tarjeta de débito/crédito</strong>
                      </p>
                      <p>Q. 1,000.45</p>
                    </div>
                    <div
                      className="col-md-12 mb-2"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <p>
                        <strong>Efectivo</strong>
                      </p>
                      <p>Q. 400.00</p>
                    </div>
                    <div
                      className="col-md-12 mb-2"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <p>
                        <strong>Método de pago seleccionado</strong>
                      </p>
                      <p>Q. 300.00</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 invoice-column">
                  <div className="row h-75">
                    <div className="col-6 p-0 m-0 text-center titles my-auto">
                      <p>SubTotal:</p>
                      <p>IVA (12%):</p>
                      <p>Total:</p>
                      <p>Pagado:</p>
                      <p>Vuelto:</p>
                    </div>
                    <div className="col-6 p-0 m-0 text-center my-auto">
                      <p>Q. 1,470.04</p>
                      <p>Q. 176.4</p>
                      <p>Q. 1,646.45 </p>
                      <p>Q. 1,700.45</p>
                      <p>Q. 54.00</p>
                    </div>
                  </div>
                  <div className="row h-25 justify-content-center w-100 m-0">
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "#1a2eab",
                      }}
                      className="my-2 w-75 facturar-button"
                      variant="contained"
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                          textTransform: "none",
                        }}
                      >
                        Pagado
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <div className="content-card card">
            <div className="card-header">
              <h4>
                <strong>Nota de débito</strong>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 my-auto">
                  <p>
                    <strong>Nota creada por</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={nota_autor}
                    name="nota_autor"
                    onChange={handleInputChange}
                    placeholder=""
                  />
                </div>
                <div className="col-md-3 my-auto">
                  <p>
                    <strong>Motivo de nota de débito</strong>
                  </p>

                  <TextField
                    className="w-100"
                    variant="standard"
                    value={nota_concepto}
                    name="nota_concepto"
                    onChange={handleInputChange}
                    placeholder=""
                  />
                </div>
                <div className="col-md-3 my-auto">
                  <p>
                    <strong>Monto ajustado</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={monto_ajustado}
                    name="monto_ajustado"
                    onChange={handleInputChange}
                    placeholder=""
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Q.</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col-md-3 my-auto">
                  <p>
                    <strong>Monto total actualizado</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={total_actualizado}
                    name="total_actualizado"
                    onChange={handleInputChange}
                    placeholder=""
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Q.</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
