import {
  CLEAR_ALEKO_STATE,
  CREATE_BASE_PRODUCT,
  DELETE_PRODUCT_COLOR,
  DELETE_PRODUCT_PERSONALIZATION,
  DELETE_PRODUCT_SIZE,
  GET_ALEKO,
  SET_PRODUCT_COLOR,
  SET_PRODUCT_PERSONALIZATION,
  SET_PRODUCT_SIZE,
} from "./types";

const validatePayload = (data) => Array.isArray(data);

export const AlekoReducer = (state, action) => {
  switch (action.type) {
    case GET_ALEKO:
      return {
        ...state,
        aleko: action.payload,
      };
    case CREATE_BASE_PRODUCT:
      return {
        ...state,
        alekoProduct: action.payload,
      };
    case SET_PRODUCT_COLOR:
      const nuevoColor = [...state.colors, action.payload];
      console.log("state ->", state.colors);
      console.log("payload ->", action.payload);
      console.log("nuevoColor ->", nuevoColor);

      return {
        ...state,
        colors: validatePayload(action.payload) ? nuevoColor[0] : nuevoColor,
      };
    case DELETE_PRODUCT_COLOR:
      const { colors } = state;
      return {
        ...state,
        colors: colors.filter(
          // (item) => item.id_new_detalle_color !== action.payload
          (item) => item.id_colores_personalizados_producto !== action.payload
        ),
      };

    case SET_PRODUCT_SIZE:
      const nuevoSize = [...state.sizes, action.payload];
      return {
        ...state,
        sizes: validatePayload(action.payload) ? nuevoSize[0] : nuevoSize,
      };
    case DELETE_PRODUCT_SIZE:
      const { sizes } = state;
      return {
        ...state,
        sizes: sizes.filter(
          (item) => item.id_tallas_personalizadas_producto !== action.payload
        ),
      };

    case SET_PRODUCT_PERSONALIZATION:
      const nuevoPersonalizacion = [...state.personalizations, action.payload];

      return {
        ...state,
        personalizations: validatePayload(action.payload)
          ? nuevoPersonalizacion[0]
          : nuevoPersonalizacion,
      };
    case DELETE_PRODUCT_PERSONALIZATION:
      const { personalizations } = state;
      return {
        ...state,
        personalizations: personalizations.filter(
          (item) => item.id_personalizacion_producto !== action.payload
        ),
      };

    case CLEAR_ALEKO_STATE:
      return {
        ...state,
        alekoProduct: {},
        colors: [],
        sizes: [],
        personalizations: [],
      };
    default:
      return state;
  }
};
