import styled from "styled-components";

export const ContainerAddProduct = styled.div`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  margin: ${(props) => props.margin || "auto 0"};
  grid-column: ${(props) => props.gridColumn};
  grid-area: ${(props) => props.gridArea};
  border: ${(props) => props.border};
`;
