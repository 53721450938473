import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Button from "@material-ui/core/Button";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { getMoneda } from "../../helpers/moneda";
// styles
import "./custom.css";

//herramienta reporte
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//reportes
import HistorialCorteCajaReporte from "../../reportes/historialCorteCajaReporte";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import "moment/locale/es";
import { isCmi } from "../../helpers/isCmi";
moment.locale("es");

// components

//data

export default function Users_all(props) {
  const moneda = getMoneda();
  let history = useHistory();
  let default_dates = [new Date(), new Date()];
  // states
  const [loader, setLoader] = useState(true);
  const [sucursal, setSucursal] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [seller, setSeller] = useState("");
  const [sellers, setSellers] = useState([]);
  const [caja, setCaja] = useState("");
  const [cajas, setCajas] = useState([]);
  const [dateRange, setDateRange] = useState(default_dates);
  const [allCortes, setAllCortes] = useState([]);
  const [filteredCortes, setFilteredCortes] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [totalCortes, setTotalCortes] = useState(null);
  const [cortesInfo, setCortesInfo] = useState([]);

  //cajero disable
  const [cajeroDisable, setCajeroDisable] = useState(false); // variable que verifica si es cajero

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    cajeroValidate();
    getSucursals();
  }, []);

  //Valida al usuario de tipo cajero
  const cajeroValidate = () => {
    if (JSON.parse(localStorage.getItem("pms"))) {
      if (JSON.parse(localStorage.getItem("pms")).tipo_usuario === "CAJERO") {
        // si es usuario tipo cajero
        setCajeroDisable(true);
      }
    }
  };

  useEffect(() => {
    if (seller !== "") getHistory(dateRange);
  }, [seller]);

  // set states
  const changeSucursal = (event) => {
    const sucursalName = event.target.value;
    setSucursal(sucursalName);
    getCajas(event.target.value);
  };
  const changeCaja = (event) => {
    const cajaId = event.target.value;
    setCaja(cajaId);
    getSellers(event.target.value);
  };
  const changeSeller = (event) => {
    const sellerId = event.target.value;
    setSeller(sellerId);
  };
  const openCorte = (id) => {
    history.push({
      pathname: `/app/cortes_caja/details/${id}`,
    });
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success && JSON.stringify(response.data) != "[]") {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
          getCajas(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getCajas = (value) => {
    API.cajas
      .getCajas({ id_sucursal_bodega_ubicacion: value })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCajas(response.data);
          setCaja(response.data[0].id_caja_web_app);
          getSellers(response.data[0].id_caja_web_app);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getCorteZ = () => {
    console.log("prueba", sucursal);
    console.log("prueba", dateRange);
    API.cajas
      .getCorteZ({
        id_sucursal_bodega_ubicacion: sucursal,
        cmi: isCmi(),
        fecha: moment(dateRange[0].toDateString()).utc().format("YYYY-MM-DD"),
      })
      .then((res) => {
        console.log("prueba", res);

        if (res.data.success) {
          let objeto = res.data.data;
          //
          let comerLocal = {
            ventas: objeto.sales.filter((item) => item.tipo_venta === 4).length,
            total: objeto.sales
              .filter((item) => item.tipo_venta === 4)
              .reduce((a, b) => a + b.monto_total, 0)
              .toFixed(2),
          };

          //
          let llevar = {
            ventas: objeto.sales.filter((item) => item.tipo_venta === 3).length,
            total: objeto.sales
              .filter((item) => item.tipo_venta === 3)
              .reduce((a, b) => a + b.monto_total, 0)
              .toFixed(2),
          };

          //
          let domicilio = {
            ventas: objeto.sales.filter((item) => item.tipo_venta === 5).length,
            total: objeto.sales
              .filter((item) => item.tipo_venta === 5)
              .reduce((a, b) => a + b.monto_total, 0)
              .toFixed(2),
          };

          localStorage.removeItem("corte_details");
          localStorage.setItem(
            "corte_details",
            JSON.stringify({
              caja: objeto.caja,
              corte_data: objeto.corte_data,
              ordenes: objeto.ordenes,
              retiros: objeto.retiros,
              sales: objeto.sales,
              sucursal: objeto.sucursal,
              total_anticipos: objeto.total_anticipos,
              total_gifcard: objeto.total_gifcard,
              comerLocal,
              llevar,
              domicilio,
              corteZ: true,
            })
          );
          const newWindow = window.open(
            "/#/app/cortes_caja/recibo",
            "_blank",
            "noopener,noreferrer"
          );
        } else {
          toast.info(res.data.message);
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSellers = (value) => {
    API.cajas
      .getVendedores({ id_caja_web_app: value })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSellers(response.data);
          setSeller(
            JSON.parse(localStorage.getItem("seller")).id_vendedor_web_app
          );
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getHistory = (dates) => {
    setLoader(true);
    let data = {
      id_caja_web_app: caja,
      id_sucursal_bodega_ubicacion: sucursal,
      id_vendedor_web_app: seller,
      cmi: isCmi(),
      from_date: dates[0].toDateString(),
      to_date: dates[1].toDateString(),
    };
    API.cajas
      .cierresHistory(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setAllCortes(response.data);
          setFilteredCortes(response.data);
          let monto_total = 0;
          response.data.map((corte) => {
            monto_total += corte.monto_total;
          });
          monto_total = parseFloat(monto_total.toFixed(2));
          setTotalCortes(monto_total);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  const filterCortes = (e) => {
    let monto_total = 0;
    setFilterText(e.target.value);

    if (e.target.value === "") {
      setFilteredCortes(allCortes);
      allCortes.map((corte) => {
        monto_total += corte.monto_total;
      });
    } else {
      let filtered = allCortes.filter((item) =>
        item.id_caja_corte_web_app.toString().includes(e.target.value)
      );
      setFilteredCortes(filtered);
      filtered.map((corte) => {
        monto_total += corte.monto_total;
      });
    }
    monto_total = parseFloat(monto_total.toFixed(2));
    setTotalCortes(monto_total);
  };

  const changeDate = (value) => {
    setLoader(true);
    setDateRange(value);
    getHistory(value);
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;

    let cortesInfo = selectedRows;
    console.log(selectedRows);

    setCortesInfo(cortesInfo);
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Historial de cortes de caja</strong>
        </h4>
      </div>

      {/* write content here "whithout {}"*/}
      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex flex-wrap align-items-center">
            <span>Filtre por:</span>
            <FormControl variant="outlined" className="px-1">
              <Select
                native
                value={sucursal}
                disabled={cajeroDisable}
                onChange={changeSucursal}
                className="sm-select"
              >
                {sucursals.map((sucursal) => (
                  <option value={sucursal.id_sucursal_bodega_ubicacion}>
                    {sucursal.nombre_sucursal}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="px-1">
              <Select
                native
                value={caja}
                disabled={cajeroDisable}
                onChange={changeCaja}
                className="sm-select"
              >
                {cajas.map((caja) => (
                  <option value={caja.id_caja_web_app}>
                    {caja.descripcion}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="px-1">
              <Select
                native
                value={seller}
                onChange={changeSeller}
                className="sm-select"
              >
                {sellers.map((seller) => (
                  <option value={seller.id_vendedor_web_app}>
                    {seller.nombre}
                  </option>
                ))}
              </Select>
            </FormControl>
            <DateRangePicker
              onChange={(value) => changeDate(value)}
              value={dateRange}
              format={"dd-MM-y"}
              className="date-picker-cs px-1"
            />
            {isCmi() && (
              <Button
                className="btn-cs1 mt-2"
                variant="contained"
                color="primary"
                onClick={() => {
                  getCorteZ();
                }}
              >
                Corte Z
              </Button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
            {!cortesInfo.length > 0 ? (
              <Button
                className="btn-cs1 mt-2"
                variant="contained"
                color="primary"
              >
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            ) : (
              <Button
                className="btn-cs1 mt-2"
                variant="contained"
                color="primary"
                onClick={() => {
                  document
                    .getElementById("descargar-reporteHistorialCorte")
                    .click();
                }}
              >
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            )}

            <ReactHTMLTableToExcel
              id="descargar-reporteHistorialCorte"
              className="buton-excel"
              table="historialCorteCaja-reporte-table"
              filename={"Cortes de Caja"}
              sheet="tablexls"
              buttonText="Descargar"
            />

            <HistorialCorteCajaReporte
              objeto={cortesInfo}
            ></HistorialCorteCajaReporte>
            <div>
              <p className="mb-1" style={{ fontSize: "0.85rem" }}>
                <strong>Buscar por número de cierre de caja:</strong>
              </p>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ingrese el número de cierre de caja"
                  style={{ width: "300px" }}
                  value={filterText}
                  onChange={(event) => filterCortes(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <>
              <DataTable
                className="datatable-cs"
                title={"s"}
                columns={[
                  {
                    name: "N° de cierre",
                    selector: "id_caja_corte_web_app",
                    sortable: true,
                    cell: (row) => (
                      <div className="text-center w-100">
                        {row.id_caja_corte_web_app}
                      </div>
                    ),
                  },
                  {
                    name: "Fecha / Hora",
                    selector: "fecha",
                    sortable: true,
                    cell: (row) => (
                      <div className="text-center w-100">
                        {row.fecha.split("T")[0].split("-")[2] +
                          "/" +
                          row.fecha.split("T")[0].split("-")[1] +
                          "/" +
                          row.fecha.split("T")[0].split("-")[0] +
                          " " +
                          row.fecha.split("T")[1].split(".")[0]}
                      </div>
                    ),
                  },
                  {
                    name: "Tienda",
                    selector: "sucursal",
                    sortable: true,
                    cell: (row) => (
                      <div className="text-center w-100">{row.sucursal}</div>
                    ),
                  },
                  {
                    name: "Vendedor",
                    selector: "vendedor",
                    sortable: true,
                    cell: (row) => (
                      <div className="text-center w-100">{row.vendedor}</div>
                    ),
                  },
                  {
                    name: "Monto total",
                    selector: "monto_total",
                    sortable: true,
                    cell: (row) => (
                      <div className="text-center w-100">
                        {moneda}.{" "}
                        {formaterPriceFunction(row.monto_total.toFixed(2) + "")}
                      </div>
                    ),
                  },
                  {
                    name: "Acción",
                    selector: "action",
                    cell: (row) => (
                      <div className="text-center w-100">
                        <Button
                          className="btn-cs1 ver-detalles-btn-container"
                          variant="contained"
                          color="primary"
                          endIcon={<ArrowForwardIosIcon />}
                          onClick={() => openCorte(row.id_caja_corte_web_app)}
                        >
                          <span
                            style={{
                              marginBottom: "-4px",
                              fontSize: "12px",
                            }}
                          >
                            Ver{" "}
                            <span
                              className="d-none d-xl-inline"
                              style={{ fontSize: "12px" }}
                            >
                              detalles
                            </span>
                          </span>
                        </Button>
                      </div>
                    ),
                  },
                ]}
                data={filteredCortes}
                defaultSortField="fecha"
                defaultSortAsc={false}
                selectableRows
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se han encontrado cortes de caja"
                onSelectedRowsChange={handleSelect}
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <small>
                  *El monto total corresponde al período seleccionado.
                </small>
                <div className="px-4 py-2 d-flex flex-wrap bg_secondary_color">
                  <p className="mb-0 mx-5" style={{ color: "white" }}>
                    <strong>Monto total</strong>
                  </p>
                  <p className="mb-0 mx-5" style={{ color: "white" }}>
                    <strong>
                      {moneda}. {formaterPriceFunction(totalCortes + "") || 0}
                    </strong>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
