import { admin_base_url, axios } from "../../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("AdminToken") };
};

const getAll = async () => {
  return await axios.get(`${admin_base_url}/web/get-clients-web`, {
    headers: headers(),
  });
};

const createNew = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/create-client`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getById = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/get-empresa`,
    qs.stringify(data),
    { headers: headers() }
  );
};

//Cllient profile
const getClientStores = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/get-empresa-sucursals`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const updateClient = async (data) => {
  return await axios.put(
    `${admin_base_url}/web/update-client`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getStoreUsers = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/get-sucursal-users`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getCompanyStats = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/empresa-details-stats`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getCompanyProducts = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/empresa-products`,
    qs.stringify(data),
    { headers: headers() }
  );
};

//Subscriptions
const createSub = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/create-subscription`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const deleteSubscription = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/delete-subscription`,
    qs.stringify(data),
    { headers: headers() }
  );
};

export default {
  getAll,
  createNew,
  createSub,
  getById,
  getClientStores,
  getStoreUsers,
  deleteSubscription,
  updateClient,
  getCompanyStats,
  getCompanyProducts,
};
