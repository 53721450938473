// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { base_url_images, realImageURL } from "../../api";
import { getMoneda } from "../../helpers/moneda";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";

// components

const sortAlphabetically = (array, field) => {
  let sortedArray = array.sort(function (a, b) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
};

export default function Users_all(props) {
  const moneda = getMoneda();
  const AutosuggestHighlightMatch = require("autosuggest-highlight/match");
  const AutosuggestHighlightParse = require("autosuggest-highlight/parse");

  // states
  const [loader, setLoader] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const { register, handleSubmit } = useForm();

  const [imageInputs, setImageInput] = useState([
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
  ]);
  const [files, setFiles] = useState([null, null, null, null, null]);
  const [discountPriceEnabled, setDiscountPriceEnabled] = useState(false);
  const [lowStockNotification, setLowStockNotification] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selected_products, setSelectedProducts] = useState([]);
  const [found_product, setFoundProduct] = useState(null);
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [itemQuantity, setItemQuantity] = useState(1);
  const [itemPrice, setItemPrice] = useState("");
  const [sales_price, setSalesPrice] = useState("");

  //priceFormater
  const [descuentoValue, setDescuentoValue] = useState("");

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  useEffect(() => {
    getSucursals();
    getProducts();
  }, []);

  useEffect(() => {
    if (sucursal !== "") {
      getProducts();
    }

    if (selected_products.length > 0) {
      toast.warning(
        "Los productos seleccionados han sido borrados. Solamente puede seleccionar productos de una sucursal.",
        {
          autoClose: 10000,
        }
      );
      setSelectedProducts([]);
      setFoundProduct(null);
    }
  }, [sucursal]);

  useEffect(() => {
    if (found_product) {
      if (!found_product.stock || found_product.stock < 0) {
        toast.warn("El producto seleccionado no tiene stock", {
          autoClose: 10000,
        });
        setFoundProduct(null);
      }
    }
  }, [found_product]);

  useEffect(() => {
    if (selected_products.length > 0) {
      let sales_price = Number(
        selected_products.reduce(
          (a, b) => a + b.precio_unitario * b.cantidad,
          0
        )
      ).toFixed(2);
      console.log("precio de oferta", sales_price);
      setSalesPrice(formaterPriceFunction(sales_price));
    }
  }, [selected_products]);

  const onSubmit = (data) => {
    setLoader(true);
    console.log("THIS IS RAW DATA", data);
    console.log("Files", files);
    let invalidForm = false;
    if (
      data.descuento_precio &&
      parseFloat(data.descuento_precio) > parseFloat(sales_price)
    ) {
      invalidForm = true;
      toast.error(
        "El precio de descuento no puede ser mayor que el precio de venta.",
        {
          autoClose: 10000,
        }
      );
    }

    console.log("descuento", data.descuento_precio);
    if (!invalidForm) {
      let form_data = {
        combo_items: JSON.stringify(selected_products),
        precio_venta: sales_price.replace(",", ""),
        descuento_precio: data.descuento_precio
          ? data.descuento_precio.replace(",", "")
          : 0,
        codigo: data.codigo,
        nombre: data.nombre,
        descripcion: data.descripcion,
        id_sucursal_bodega_ubicacion: parseInt(sucursal),
      };
      API.products
        .createCombo(form_data, files)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setLoader(false);
            $("#save").modal();
          } else {
            setLoader(false);
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          let response = err.response;
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            } else {
              toast.error("Ha ocurrido un error al crear el combo.", {
                autoClose: 10000,
              });
            }
          } else {
            toast.error(
              "Ha ocurrido un error a nivel de base de datos. Presiona F12 y revisa la consola para más información.",
              {
                autoClose: 10000,
              }
            );
          }
          console.log("Ha ocurrido un error", response, response.data);
        });
    }
  };
  const setImage = (e, img_index) => {
    if (typeof e.target.files[0] !== "undefined") {
      let image = URL.createObjectURL(e.target.files[0]);
      let image_file = e.target.files[0];
      let newData = [...imageInputs];
      let newData_2 = [...files];
      newData_2[img_index] = image_file;
      newData[img_index] = image;
      setFiles(newData_2);
      setImageInput(newData);
    }
  };
  const deleteImage = (img_index) => {
    let newData = [...imageInputs];
    newData[img_index] = "/images/photo_main.png";
    setImageInput(newData);
  };

  const handleDiscountCheck = () => {
    setDiscountPriceEnabled(!discountPriceEnabled);
  };

  const handleLowStockNotification = () => {
    setLowStockNotification(!lowStockNotification);
  };

  const changeFilterText = (e, { newValue }) => {
    setFilterText(newValue);
  };

  const getSuggestions = () => {
    const inputValue = document
      .getElementById("product_query")
      .value.trim()
      .toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : allProducts
          .filter((product) =>
            product.nombre.toLowerCase().includes(inputValue)
          )
          .filter((product) => !product.combo)
          .filter((product) => !product.id_padre)
          .slice(0, 5);
  };

  const getSuggestionValue = (suggestion) => {
    setFoundProduct(suggestion);
    return suggestion.nombre;
  };

  const renderSuggestion = (suggestion) => {
    const matches = AutosuggestHighlightMatch(suggestion.nombre, filterText);
    const parts = AutosuggestHighlightParse(suggestion.nombre, matches);

    return (
      <div>
        {parts.map((part, index) => {
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  const onSuggestionsFetchRequested = () => {
    setSuggestions(getSuggestions(filterText));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Escribe el nombre o SKU del producto",
    value: filterText,
    onChange: changeFilterText,
    id: "product_query",
  };

  const getProducts = () => {
    API.products
      .getAll({
        id_sucursal_bodega_ubicacion: sucursal,
        no_combos: 1,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Im obtaining these products:", response.data);
          setAllProducts(response.data);
        }
      })
      .catch((err) => {
        console.log("Ha ocurrido un error / Búsqueda de productos");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined")
          if (typeof response.data.message !== "undefined") {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error", response);
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
      });
  };

  const changeSucursal = (event) => {
    setSucursal(event.target.value);
  };

  const unselectProduct = () => {
    setFoundProduct(null);
  };
  const selectProduct = () => {
    let already_selected = [...selected_products];
    let currentIndex = already_selected.findIndex(
      (x) => x.id_new_item === found_product.id_new_item
    );

    if (already_selected[currentIndex]) {
      toast.error("El producto ya fue seleccionado para el combo.", {
        autoClose: 10000,
      });
      return;
    }

    if (!itemPrice) {
      toast.error("Debe introducir el precio unitario del producto.", {
        autoClose: 10000,
      });
      return;
    }

    if (parseFloat(itemQuantity) > found_product.stock) {
      toast.error(
        "La cantidad por combo no puede ser mayor al stock disponible del producto",
        { autoClose: 10000 }
      );
      return;
    }

    if (!found_product.stock || found_product.stock < 0) {
      toast.error("El producto seleccionado no tiene stock disponible", {
        autoClose: 10000,
      });
      return;
    }
    already_selected.push({
      id_new_item: found_product.id_new_item,
      cantidad: parseFloat(itemQuantity),
      precio_unitario: parseFloat(itemPrice.replace(",", "")),
      nombre: found_product.nombre,
      codigo: found_product.codigo,
      precio_venta: found_product.precio_venta,
    });
    setSelectedProducts(already_selected);

    setItemPrice(null);
    setItemQuantity(1);
    setFoundProduct(null);
  };

  const updateMoney = (e) => {
    let target = e.target;
    let parsedValue = isNaN(parseFloat(target.value))
      ? ""
      : Math.abs(parseFloat(target.value));
    e.target.value = isNaN(parseFloat(target.value)) ? "" : target.value;
    setItemPrice(parsedValue);
  };

  const remove_input = (i) => {
    setSelectedProducts(selected_products.filter((e, index) => index !== i));
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div className="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardado.</p>

              <div className="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="save" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Su combo se ha <br />
                    guardado con éxito
                  </strong>
                </h4>
              </div>

              <div className="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Agregar combo</strong>
        </h4>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <FormControl variant="outlined">
          <Select native value={sucursal} onChange={changeSucursal}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        autocomplete="on"
        encType="multipart/form-data"
      >
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Crear nuevo combo</strong>
                </h5>
                <p className="mb-0">
                  Cree un nuevo combo a partir de los productos previamente
                  cargados.
                </p>
              </div>

              <div>
                {loader ? (
                  <CircularProgress size={16} className="mx-auto" />
                ) : (
                  <Button
                    type="submit"
                    className="btn-cs1_blue mx-1 mt-1"
                    variant="contained"
                    color="primary"
                  >
                    Guardar
                  </Button>
                )}

                <Button
                  className="btn-cs1 mx-1 mt-1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmExit"
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-4 mt-3 ">
                <div>
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Nombre del combo</strong>
                  </label>

                  <TextField
                    inputRef={register}
                    required
                    name="nombre"
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el nombre de su producto"
                  />
                </div>
              </div>
              <div className="col-md-4 mt-3 ">
                <div>
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Precio de venta</strong>
                  </label>
                  <TextField
                    inputRef={register}
                    type="text"
                    className="w-100"
                    disabled
                    placeholder="Calculado automáticamente"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                      inputProps: { min: 0, step: "0.00001" },
                    }}
                    value={sales_price}
                  />
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Precio con descuento</strong>
                    </label>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      inputRef={register}
                      type="checkbox"
                      checked={discountPriceEnabled}
                      onClick={handleDiscountCheck}
                      className="custom-control-input"
                      id={`customSwitchPrice`}
                    />
                    <label
                      className="custom-control-label"
                      for={`customSwitchPrice`}
                    />
                  </div>
                </div>
                <TextField
                  inputRef={register}
                  value={descuentoValue}
                  type="text"
                  className="w-100"
                  disabled={!discountPriceEnabled}
                  placeholder="Ingrese el precio promocional del combo"
                  name="descuento_precio"
                  onChange={(event) => {
                    setDescuentoValue(
                      formaterPriceFunction(event.target.value)
                    );
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {moneda}.
                      </InputAdornment>
                    ),
                    inputProps: { min: 0, step: "0.00001" },
                  }}
                />
                <small>
                  El precio con descuento sustituirá el precio original de
                  venta, puede activar y desactivar esta opción en cualquier
                  momento.
                </small>
              </div>
              {/* Third Row */}
              {/*  Forth Row */}

              {/* Sixth row */}
              {/* Add images */}
              <div className="col-md-6 mt-3">
                <div className="d-flex">
                  <div className="d-flex" style={{ flex: "1" }}>
                    <div className="w-100" style={{ position: "relative" }}>
                      <HighlightOffIcon
                        className="delete-image-icon"
                        onClick={() => deleteImage(0)}
                      />
                      <img
                        src={imageInputs[0]}
                        className="add_image_bck"
                        alt="img-input"
                      />
                      <input
                        accept="image/*"
                        name="images"
                        className="add_image_btn"
                        inputRef={register}
                        type="file"
                        onChange={(event) => setImage(event, 0)}
                      />
                    </div>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div className="d-flex">
                      <div
                        style={{
                          position: "relative",
                          flex: "1",
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(1)}
                        />
                        <img
                          src={imageInputs[1]}
                          className="add_image_bck"
                          alt="img-input"
                        />
                        <input
                          name="images"
                          accept="image/*"
                          className="add_image_btn"
                          inputRef={register}
                          type="file"
                          onChange={(event) => setImage(event, 1)}
                        />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          flex: "1",
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(2)}
                        />
                        <img
                          src={imageInputs[2]}
                          className="add_image_bck"
                          alt="img-input"
                        />
                        <input
                          name="images"
                          accept="image/*"
                          className="add_image_btn"
                          inputRef={register}
                          type="file"
                          onChange={(event) => setImage(event, 2)}
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div
                        style={{
                          position: "relative",
                          flex: "1",
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(3)}
                        />
                        <img
                          src={imageInputs[3]}
                          className="add_image_bck"
                          alt="img-input"
                        />
                        <input
                          name="images"
                          accept="image/*"
                          className="add_image_btn"
                          inputRef={register}
                          type="file"
                          onChange={(event) => setImage(event, 3)}
                        />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          flex: "1",
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(4)}
                        />
                        <img
                          src={imageInputs[4]}
                          className="add_image_bck"
                          alt="img-input"
                        />
                        <input
                          name="images"
                          accept="image/*"
                          className="add_image_btn"
                          inputRef={register}
                          type="file"
                          onChange={(event) => setImage(event, 4)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <div className="mt-2">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Descripción de producto (Opcional)</strong>
                  </label>
                  <TextField
                    name="descripcion"
                    inputRef={register}
                    type="text"
                    multiline
                    className="w-100"
                    placeholder="Ingrese una breve descripción del combo."
                  />
                </div>

                <div className="mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Código del combo</strong>
                    </label>
                    <Tooltip title="Ingrese el código SKU combo que identifica al producto.">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    name="codigo"
                    inputRef={register}
                    type="text"
                    required
                    className="w-100"
                    placeholder="Ingrese el SKU / código de barras del producto"
                  />
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Selección de productos</strong>
                  </label>
                </div>
                <p className="mb-2">
                  Seleccione los productos que estarán dentro de este combo.
                </p>
              </div>
              <div className="col-md-8 mt-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <div
                      className="input-group search-bar autosuggest-search-bar"
                      style={{ width: "100%" }}
                    >
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text autosuggest-search-icon">
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {selected_products.length > 0 ? (
                <div className="col-12">
                  {selected_products.map((input, index) => (
                    <div className="row justify-content-center">
                      <div className="col-md-1 mt-3 d-flex justify-content-center align-items-center">
                        <IconButton
                          className="btn-cs1 icon-btn-delete"
                          variant="contained"
                          color="primary"
                          onClick={() => remove_input(index)}
                        >
                          <DeleteIcon
                            style={{
                              color: "white",
                            }}
                          />
                        </IconButton>
                      </div>

                      <div className="col-md-4 mt-3">
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>{input.nombre}</strong>
                        </p>
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          SKU: {input.codigo}
                        </p>
                      </div>

                      <div className="col-md-3 mt-3">
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Cant. por combo</strong>
                        </p>
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {input.cantidad}
                        </p>
                      </div>
                      <div className="col-md-3 mt-3">
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Precio unitario</strong>
                        </p>
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {formaterPriceFunction(input.precio_unitario + "")}
                        </p>
                      </div>
                      <div className="col-md-1 mt-3" />
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
              {found_product ? (
                <>
                  <div className="col-md-4 d-flex justify-content-between">
                    <div className="d-flex align-items-end">
                      <Button
                        className="btn-cs1_blue mt-1"
                        variant="contained"
                        color="primary"
                        onClick={selectProduct}
                      >
                        Guardar
                      </Button>
                    </div>
                    <div className="d-flex align-items-end">
                      <Button
                        className="btn-cs1 mt-1 delete-btn"
                        variant="contained"
                        color="primary"
                        onClick={unselectProduct}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </div>
                  <div
                    className="col-md-8 text-center my-auto p-5"
                    style={{ backgroundColor: "#F2F2F2" }}
                  >
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={
                            found_product.imagen_1
                              ? realImageURL(found_product.imagen_1)
                              : base_url_images +
                                "images/placeholder-image.jpeg"
                          }
                          className="combo-product-image"
                          alt="Cloud POS - Producto"
                        />
                      </div>
                      <div className="col-lg-3">
                        <p>
                          <strong>{found_product.nombre}</strong>
                        </p>

                        <p>SKU: {found_product.codigo}</p>
                        <p>
                          Precio: {moneda}.{" "}
                          {found_product.precio_venta.toFixed(2)}
                        </p>
                        <p>Stock: {found_product.stock}</p>
                      </div>
                      <div className="col-lg-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Cant. por combo</strong>
                        </label>
                        <TextField
                          type="number"
                          className="w-100"
                          value={itemQuantity}
                          onChange={(event) =>
                            setItemQuantity(
                              event.target.value < 0
                                ? (event.target.value = -event.target.value)
                                : event.target.value
                            )
                          }
                          placeholder="Ingrese la cantidad"
                          InputProps={{
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Precio unitario</strong>
                        </label>
                        <TextField
                          type="text"
                          className="w-100"
                          value={itemPrice}
                          onChange={(event) =>
                            setItemPrice(
                              formaterPriceFunction(event.target.value)
                            )
                          }
                          placeholder="Ingrese el precio unitario"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {moneda}.
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {!found_product ? (
                <div
                  className="col-md-12 text-center my-auto p-5"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  <p>
                    No hay resultados, ingrese el nombre o código SKU de un
                    producto para buscar
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
