export const format = (value) => {
  return value
    .replace(/(?!\.)\D/g, "")
    .replace(/(?<=\..*)\./g, "")
    .replace(/(?<=\.\d\d).*/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const formaterPriceFunction = (value) => {
  return value
    .toString()
    .replace(/(?!\.)\D/g, "")
    .replace(/(?<=\..*)\./g, "")
    .replace(/(?<=\.\d\d).*/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberFormater = (value) => {
  let newValue = format(parseFloat(value).toFixed(2) + "");
  return newValue;
};
