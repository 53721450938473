// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";
import { getMoneda } from "../../helpers/moneda";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// components

export default function Users_all(props) {
  const moneda = getMoneda();
  // states
  const [loader, setLoader] = useState(true);
  const [unidades, setUnidades] = useState([]);
  const { register, handleSubmit } = useForm();

  const [imageInputs, setImageInput] = useState([
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
  ]);
  const [files, setFiles] = useState([null, null, null, null, null]);
  const [expire_lote, setExpire_lote] = useState(false);
  const [lowStockNotification, setLowStockNotification] = useState(false);
  const [Clasificationsupply, setClasificationsupply] = useState([]);

  //priceFormater
  const [priceValue, setPriceValue] = useState("");
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  useEffect(() => {
    API.products
      .getProductAttributes({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setUnidades(response.data.empaques);
        }
        getAllclasificationSupply();
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
        setLoader(false);
      });
  }, []);

  const onSubmit = (data) => {
    data.costo_unitario = data.costo_unitario.replace(",", "");

    console.log(data);

    let invalidForm = false;
    if (!lowStockNotification) {
      delete data.stock_minimo;
    }
    if (!expire_lote) {
      delete data.vencimiento_fecha;
    }

    if (!invalidForm) {
      API.supplies
        .createSupply(data, files)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            $("#save").modal();
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          let response = err.response;
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            } else {
              toast.error("Ha ocurrido un error al crear el insumo.", {
                autoClose: 10000,
              });
            }
          } else {
            toast.error(
              "Ha ocurrido un error a nivel de base de datos. Presiona F12 y revisa la consola para más información.",
              {
                autoClose: 10000,
              }
            );
          }
          console.log("Ha ocurrido un error", response, response.data);
        });
    }
  };
  const setImage = (e, img_index) => {
    if (typeof e.target.files[0] !== "undefined") {
      let image = URL.createObjectURL(e.target.files[0]);
      let image_file = e.target.files[0];
      let newData = [...imageInputs];
      let newData_2 = [...files];
      newData_2[img_index] = image_file;
      newData[img_index] = image;
      setFiles(newData_2);
      setImageInput(newData);
    }
  };

  const getAllclasificationSupply = () => {
    API.supplies
      .getClasificationsupply({})
      .then((res) => {
        let response = res.data;
        if (response.ok) {
          setClasificationsupply(response.data);
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        //setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const deleteImage = (img_index) => {
    let newData = [...imageInputs];
    newData[img_index] = "/images/photo_main.png";
    setImageInput(newData);
  };
  const lote_expiredDate = (e) => {
    setExpire_lote(!expire_lote);
  };

  const handleLowStockNotification = () => {
    setLowStockNotification(!lowStockNotification);
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div className="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardado.</p>

              <div className="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="save" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Su insumo se ha <br />
                    guardado con éxito
                  </strong>
                </h4>
              </div>

              <div className="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Agregar insumo</strong>
        </h4>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autocomplete="on">
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Crear nuevo insumo</strong>
                </h5>
                <p className="mb-0">
                  Agregue manualmente un nuevo insumo a su inventario.
                </p>
              </div>

              <div>
                <Button
                  type="submit"
                  className="btn-cs1_blue mx-1 mt-1"
                  variant="contained"
                  color="primary"
                >
                  Guardar
                </Button>
                <Button
                  className="btn-cs1 mx-1 mt-1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmExit"
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-3 mt-3 add_supply_image">
                <div className="d-flex justify-content-center">
                  <div className="w-100" style={{ position: "relative" }}>
                    <HighlightOffIcon
                      className="delete-image-icon"
                      onClick={() => deleteImage(0)}
                    />
                    <img
                      src={imageInputs[0]}
                      className="add_image_bck"
                      alt="img-input"
                    />
                    <input
                      accept="image/*"
                      name="images"
                      className="add_image_btn"
                      inputRef={register}
                      type="file"
                      onChange={(event) => setImage(event, 0)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-9 mt-3">
                <div className="row w-100 p-0">
                  <div className="col-md-6 mt-3 ">
                    <div>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Nombre del insumo</strong>
                      </label>

                      <TextField
                        inputRef={register}
                        required
                        name="nombre"
                        type="text"
                        className="w-100"
                        placeholder="Ingrese el nombre de su insumo"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Código del insumo</strong>
                      </label>
                      <Tooltip title="Ingrese el código SKU del insumo que identifica al insumo.">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      name="codigo"
                      inputRef={register}
                      type="text"
                      required
                      className="w-100"
                      placeholder="Ingrese el SKU del insumo"
                    />
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Descripción del insumo (Opcional)</strong>
                    </label>
                    <TextField
                      name="descripcion"
                      inputRef={register}
                      type="text"
                      multiline
                      className="w-100"
                      placeholder="Ingrese una breve descripción del insumo."
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Unidad de medida</strong>
                      </label>
                    </div>

                    <FormControl variant="outlined" className="w-100">
                      <Select
                        inputRef={register}
                        native
                        name="id_unidad_medida_web_app"
                        required
                        className="cs-select1 select-w100"
                      >
                        <option value="">Seleccione la unidad de medida</option>
                        {unidades.map((unit) => (
                          <option value={unit.id_unidad_medida_web_app}>
                            {unit.descripcion}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Clasificación</strong>
                      </label>
                    </div>

                    <FormControl variant="outlined" className="w-100">
                      <Select
                        inputRef={register}
                        native
                        name="id_clasificacion1_web_app"
                        required
                        className="cs-select1 select-w100"
                      >
                        <option value="">Seleccione la Clasificación</option>
                        {Clasificationsupply.map((data) => (
                          <option value={data.id_clasificacion1_web_app}>
                            {data.descripcion}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Código de receta</strong>
                      </label>
                      <Tooltip title="Ingrese el código de receta según su empresa.">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      name="codigo_receta"
                      inputRef={register}
                      type="text"
                      required
                      className="w-100"
                      placeholder="Ingrese el código de receta"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Costo del producto</strong>
                      </label>
                      <Tooltip title="Indique el costo del producto.">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      inputRef={register}
                      type="text"
                      name="costo_unitario"
                      className="w-100"
                      placeholder="Ingrese el costo del producto."
                      required
                      value={priceValue}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        setPriceValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Sixth row */}

              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Lote del insumo</strong>
                </label>

                <TextField
                  inputRef={register}
                  type="text"
                  className="w-100"
                  name="lote"
                  placeholder="Ingrese número de lote del insumo"
                />
              </div>

              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Inventario</strong>
                </label>

                <TextField
                  inputRef={register}
                  type="number"
                  name="stock"
                  required
                  className="w-100"
                  placeholder="Ingrese el inventario de este insumo"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: "0.00001",
                    },
                  }}
                  onChange={(event) =>
                    event.target.value < 0
                      ? (event.target.value = -event.target.value)
                      : event.target.value
                  }
                />
              </div>

              <div className="col-md-3 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Notificaciones de bajo inventario</strong>
                    </label>
                    <Tooltip
                      title="Indique la cantidad mínima por la que el sistema le notificará que está bajo de inventario."
                      className="mx-1"
                    >
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      inputRef={register}
                      type="checkbox"
                      className="custom-control-input"
                      name="notificaciones_inventario"
                      checked={lowStockNotification}
                      onClick={handleLowStockNotification}
                      id={`customSwitchNotification`}
                    />
                    <label
                      className="custom-control-label"
                      for={`customSwitchNotification`}
                    />
                  </div>
                </div>
                <TextField
                  inputRef={register}
                  type="number"
                  className="w-100"
                  placeholder="Ingrese la cantidad de inventario mínimo"
                  name="stock_minimo"
                  disabled={!lowStockNotification}
                  onChange={(event) =>
                    event.target.value < 0
                      ? (event.target.value = -event.target.value)
                      : event.target.value
                  }
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: "0.00001",
                    },
                  }}
                />
              </div>

              <div className="col-md-3 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Fecha de vencimiento</strong>
                    </label>
                    <Tooltip title="Indique la fecha de vencimiento del lote del insumo.">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      inputRef={register}
                      type="checkbox"
                      className="custom-control-input"
                      name="vencimiento"
                      id={`customSwitchExpireDate`}
                      onChange={(event) => lote_expiredDate(event)}
                      checked={expire_lote}
                    />
                    <label
                      className="custom-control-label"
                      for={`customSwitchExpireDate`}
                    />
                  </div>
                </div>
                <TextField
                  inputRef={register}
                  type="date"
                  className="w-100"
                  name="vencimiento_fecha"
                  disabled={!expire_lote ? true : false}
                />
              </div>

              {/* Seventh row */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
