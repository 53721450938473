import React from "react";
import { toast } from "react-toastify";
import API from "../api";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  autoLogin,
};

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);
  API.auth
    .login({
      correo_electronico: login,
      contrasena: password,
    })
    .then((res) => {
      let response = res.data;
      if (response.success) {
        localStorage.setItem("token", response.data.token.authorization);
        localStorage.setItem("id_sucursal", response.data.usuario.id_sucursal);
        localStorage.setItem(
          "tipo",
          response.data.usuario.id_tipo_usuario_web_app
        );
        if (response.data.empresa.aleko) {
          localStorage.setItem("aleko", response.data.empresa.aleko);
        }
        if (localStorage.getItem("selectedProducts")) {
          localStorage.removeItem("salesCost");
          localStorage.removeItem("selectedProducts");
        }
        if (!response.data.empresa.aleko && localStorage.getItem("aleko")) {
          localStorage.removeItem("aleko");
        }
        if (response.data.empresa.cmi) {
          localStorage.setItem("cmi", response.data.empresa.cmi);
        }
        if (!response.data.empresa.cmi && localStorage.getItem("cmi")) {
          localStorage.removeItem("cmi");
        }
        localStorage.setItem("email", response.data.usuario.correo);
        localStorage.setItem(
          "id_empresa",
          response.data.empresa.id_new_empresa
        );

        const getData = async () => {
          try {
            const { data } = await API.profile.getCompanyData();
            const moneda = data.data.monedas.find(
              (item) => item.id_moneda_web_app === data.data.id_moneda_web_app
            );
            localStorage.setItem("moneda", moneda.simbolo.trim());
          } catch (error) {
            console.log(error);
          }
        };

        getData();
        setError(null);
        setIsLoading(false);
        toast.success("¡Ha iniciado sesión correctamente!", {
          autoClose: 10000,
        });
        (function one() {
          if (
            localStorage.getItem("token") === response.data.token.authorization
          ) {
            if (!!localStorage.getItem("AdminToken"))
              // Allows only one session, either normal user or admin
              localStorage.removeItem("AdminToken");
            dispatch({ type: "LOGIN_SUCCESS" });
          } else {
            setTimeout(one, 30);
          }
        })();
      }
    })
    .catch((err) => {
      let response = err.response;
      console.log(response);
      if (err.response?.status === 418) {
        toast(<LogoutOfAllDevices id={err.response.data.data} />);
        setIsLoading(false);
        return;
      }
      setError(true);
      setIsLoading(false);
      if (!response) {
        toast.error("Error del servidor");
        return;
      }
      if (typeof response.data !== "undefined") {
        if (response.data.message) {
          if (response.data.message !== "El token no es válido") {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          if (!response.data.success) {
            toast.error("Las credenciales no son válidas", {
              autoClose: 10000,
            });
          }
        }
      } else {
        //console.log("Ha ocurrido un error");
        //toast.error("Ha ocurrido un error", { autoClose: 10000 });
      }
      dispatch({ type: "LOGIN_FAILURE" });
    });
}

function autoLogin(dispatch, token, history) {
  localStorage.setItem("token", token);
  dispatch({ type: "LOGIN_SUCCESS" });
  history.push("/app/dashboard");
}

function signOut(dispatch, history) {
  const toDelete = [
    "token",
    "selectedProducts",
    "invoiceInfo",
    "clientInfo",
    "salesCost",
    "store",
    "caja",
    "seller",
  ];
  API.auth
    .logout()
    .then((res) => {
      let response = res.data;
      if (response.success) {
        toDelete.map((value) => {
          localStorage.removeItem(value);
        });
        if (!!localStorage.getItem("pms")) {
          localStorage.removeItem("pms");
        }
        if (localStorage.getItem("aleko")) {
          localStorage.removeItem("aleko");
        }
        if (localStorage.getItem("cmi")) {
          localStorage.removeItem("cmi");
        }
        dispatch({ type: "SIGN_OUT_SUCCESS" });
        history.push("/login");
      } else {
        toast.error(response.message, {
          autoClose: 10000,
        });
      }
    })
    .catch((err) => {
      toDelete.map((value) => {
        localStorage.removeItem(value);
      });

      console.log(err, err.response);

      let response = err.response;
      if (typeof response.data !== "undefined") {
        if (response.data.message) {
          if (response.data.message !== "El token no es válido") {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        }
      } else {
        //console.log("Ha ocurrido un error");
        //toast.error("Ha ocurrido un error", { autoClose: 10000 });
      }
      history.push("/login");
    });
}
function LogoutOfAllDevices({ id }) {
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("id_new_user", id);
    API.auth
      .logoutOfAllDevices(formData)
      .then((res) => {
        console.log(res);
        toast("Se ha cerrado sesión de todos los dispositivos");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      Ya hay una sesión activa, si deseas cerrar sesión en los demás
      dispositivos,{" "}
      <button className="btn btn-primary" onClick={() => handleSubmit()}>
        Clic aquí
      </button>
    </div>
  );
}
