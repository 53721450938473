// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import { getMoneda } from "../../helpers/moneda";

export default function Users_all(props) {
  const moneda = getMoneda();
  const moment = require("moment");
  let sale_id = props.match.params.id.toString();
  const [loader, setLoader] = useState(true);
  const [sale, setSale] = useState({
    anulacion: {
      motivo: "",
      fecha: "",
      monto: "",
    },
    client: {
      nombre: "",
    },
    seller: {
      nombre: "",
    },
  });
  const [seller, setSeller] = useState({});
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [invoices, setInvoices] = useState({});
  const [felData, setFelData] = useState({});
  console.log(sale);
  useEffect(() => {
    getAnulacion();
  }, []);

  useEffect(() => {
    if (sale.anulacion.monto !== "") setLoader(false);
  }, [sale]);

  const getAnulacion = () => {
    console.log("SEARCHING BY, ", sale_id);
    API.sales
      .getSingleAnulacion({ id_pos_movimiento_web_app: sale_id })
      .then((res) => {
        let response = res.data;
        console.log("OBTAINED THIS DATA", response.data);
        if (response.success) {
          setSale(response.data);
          setSeller({
            id_vendedor_web_app: response.data.seller.id_vendedor_web_app,
            nombre: response.data.seller.nombre,
          });
          setInvoiceInfo({
            salesCost: {
              total: response.data.monto_total,
              subtotal: response.data.subtotal,
              iva: response.data.vat_amount,
            },
            paidAmount: {
              paid:
                response.data.efectivo + response.data.tarjeta !== 0
                  ? parseFloat(
                      (response.data.efectivo + response.data.tarjeta).toFixed(
                        2
                      )
                    )
                  : response.data.monto_total,
              change: response.data.cambios,
            },
            paidByCard: response.data.tarjeta,
            paidByCash: response.data.efectivo,
            paidByGiftCard: 0,
            selectedProducts: response.data.orderItems,
            flag_estado: response.data.flag_estado,
          });
          setInvoices({
            recibo_numero: response.data.recibo || "",
            adicional_recibo: response.data.adicional_recibos || [],
            gift_card_codigo: response.data.gift_card_code,
            cuotas: response.data.cuotas,
          });
          setFelData({
            numerofel: response.data.numerofel,
            seriefel: response.data.seriefel,
            fecha: response.data.fecha,
            identificador: response.data.identificador,
            id_dte: response.data.id_dte,
            service_certificador: response.data.service_certificador,
            service_id: response.data.service_id,
            service_nit: response.data.service_nit,
            id_sucursal_bodega_ubicacion:
              response.data.id_sucursal_bodega_ubicacion,
          });
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      "/#/app/vender/resumen/" + sale.id_pos_movimiento,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const downloadRecibo = () => {
    localStorage.setItem(
      "factura_data",
      JSON.stringify({
        fel_data: felData,
        selectedProducts: invoiceInfo.selectedProducts,
        seller: seller,
        client: sale.client,
        invoiceInfo,
        fecha_anulacion: sale.anulacion.fecha,
      })
    );
    const newWindow = window.open(
      "/#/app/vender/factura",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Anulaciones de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Resumen de anulación</strong>
        </h4>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Resumen de anulación</strong>
              </h5>
              <p className="mb-0">Consulte los detalles de su anulación.</p>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-2 brand2-btn"
                variant="contained"
                color="primary"
                to={"/app/operaciones_historial"}
                component={Link}
              >
                Volver
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body anulacion-details-receipt">
          <div className="row mb-4">
            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Número de anulación</strong>
              </label>
              {loader ? (
                <div className="my-auto text-center">
                  <CircularProgress size={25} className="mx-auto" />
                </div>
              ) : (
                <TextField
                  disabled
                  className="w-100"
                  value={sale.serie}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">N°</InputAdornment>
                    ),
                  }}
                />
              )}
            </div>

            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Motivo de la anulación</strong>
              </label>
              {loader ? (
                <div className="my-auto text-center">
                  <CircularProgress size={25} className="mx-auto" />
                </div>
              ) : (
                <TextField
                  disabled
                  className="w-100"
                  defaultValue={sale.anulacion.motivo}
                />
              )}
            </div>

            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Nombre del cliente</strong>
              </label>
              {loader ? (
                <div className="my-auto text-center">
                  <CircularProgress size={25} className="mx-auto" />
                </div>
              ) : (
                <TextField
                  disabled
                  className="w-100"
                  defaultValue={sale.client.nombre}
                />
              )}
            </div>

            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Fecha de la anulación</strong>
              </label>
              {loader ? (
                <div className="my-auto text-center">
                  <CircularProgress size={25} className="mx-auto" />
                </div>
              ) : (
                <TextField
                  disabled
                  type="date"
                  className="w-100"
                  defaultValue={moment(sale.anulacion.fecha).format(
                    "YYYY-MM-DD"
                  )}
                />
              )}
            </div>

            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Hora de la anulación</strong>
              </label>
              {loader ? (
                <div className="my-auto text-center">
                  <CircularProgress size={25} className="mx-auto" />
                </div>
              ) : (
                <TextField
                  disabled
                  type="time"
                  className="w-100"
                  defaultValue={moment
                    .utc(sale.anulacion.fecha)
                    .format("HH:mm:ss")}
                />
              )}
            </div>

            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Vendedor</strong>
              </label>
              {loader ? (
                <div className="my-auto text-center">
                  <CircularProgress size={25} className="mx-auto" />
                </div>
              ) : (
                <TextField
                  disabled
                  className="w-100"
                  placeholder=""
                  defaultValue={sale.seller.nombre}
                />
              )}
            </div>

            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Monto de la anulación</strong>
              </label>
              {loader ? (
                <div className="my-auto text-center">
                  <CircularProgress size={25} className="mx-auto" />
                </div>
              ) : (
                <TextField
                  disabled
                  className="w-100"
                  defaultValue={sale.anulacion.monto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {moneda}.{" "}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </div>

            <div className="col-md-3 mt-3" />

            <div className="col-12 mt-3">
              <Button
                className="btn-cs1 mx-2 brand2-btn"
                variant="contained"
                color="primary"
                onClick={openInNewTab}
              >
                Ver factura anulada
              </Button>
              {/* <Button
                className="btn-cs1 mx-2"
                variant="contained"
                color="primary"
                onClick={downloadRecibo}
              >
                Descargar recibo
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
