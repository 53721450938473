import React from "react";
import { getMoneda } from "../../../../helpers/moneda";
import moment from "moment";

export default function PdfDocument({
  currentOrder,
  iva,
  selectedProducts,
  firma,
}) {
  const products = Object.entries(currentOrder.data).map((item) => item[1]);
  const moneda = getMoneda();
  const total = products.reduce((acc, x) => acc + x[0].valor_total, 0);

  return (
    <div className="row" style={{ height: "100vh" }}>
      <div className="col-md-12">
        <div className="card">
          <div className="card body">
            <div className="d-flex justify-content-between py-2 px-3 border-bottom">
              <span>
                <strong>Confirmación de orden</strong>
              </span>
              <span>
                <strong>N° de orden {currentOrder.codigo_pedido}</strong>
              </span>
            </div>
            <div className="col-md-12">
              <span>
                <strong>Cotización</strong>
              </span>
            </div>

            <div className="col-md-12 d-flex justify-content-between">
              <div>
                <strong>Fecha</strong>
                <br />
                {moment(new Date()).format("DD/MM/YYYY")}
              </div>
              <div>
                <strong> N° de cotizacion</strong>
                <br />
                {currentOrder.id_pedido}
              </div>
            </div>
            <br />
            <div className="col-md-12">
              <div className="row border-bottom">
                <div className="col-md-3">
                  <strong>Cantidad</strong>
                </div>
                <div className="col-md-3">
                  <strong>Nombre</strong>
                </div>
                <div className="col-md-3">
                  <strong>Talla</strong>
                </div>
                <div className="col-md-3">
                  <strong>Precio</strong>
                </div>
              </div>
              {products.map((product, index) => (
                <div className="row" key={index}>
                  <div className="col-md-3 border-bottom">
                    {" "}
                    {product[0].cantidad_producto}
                  </div>
                  <div className="col-md-3 border-bottom">
                    {
                      selectedProducts.find(
                        (x) => x.id_new_item === product[0].id_new_item
                      )?.nombre
                    }
                  </div>
                  <div className="col-md-3 border-bottom">
                    {" "}
                    {product[0].valor_talla}
                  </div>
                  <div className="col-md-3 border-bottom">
                    {" "}
                    {moneda}.{" "}
                    {Math.max(
                      ...product.map((product) => product.valor_total)
                    ).toFixed(2)}
                  </div>
                  <br />
                  {product.map((itemA) => {
                    const variante = itemA.variante.split(" ").join("_");
                    const images = Object.entries(itemA)
                      .filter((img) => img[0].includes("images"))
                      .map((imgB) => imgB[1])
                      .filter((x) => x.includes(variante));

                    const entries = Object.entries(itemA)
                      .filter((i) => i[0].includes("nombre_area"))
                      .map((x) => x[1])
                      .filter((x) => x);

                    return (
                      <>
                        <div className="col-1" />
                        <div className="col-3">Area</div>
                        <div className="col-3">Tipo</div>
                        <div className="col-5">Dimensiones</div>

                        {entries.map((item, index) => (
                          <>
                            <div className="col-1" />
                            <div className="col-3">
                              {" "}
                              {itemA[`nombre_area_${index + 1}`]}
                            </div>
                            <div className="col-3">
                              {" "}
                              {itemA[`personalizacion_tipo_${index + 1}`]}
                            </div>
                            <div className="col-5">
                              {" "}
                              Ancho: {itemA[`medidas_ancho_${index + 1}`]} cm
                              Alto: {itemA[`medidas_alto_${index + 1}`]} cm
                            </div>
                          </>
                        ))}
                      </>
                    );
                  })}
                </div>
              ))}
            </div>

            <div className="col-md-12 d-flex justify-content-between">
              {firma ? (
                <img src={firma} alt="" width="200" height={"100%"} />
              ) : (
                <span />
              )}

              <div>
                Subtotal: {total - total * (iva / 100)} {moneda}. <br />
                IVA({iva}%)
                <br />
                Gran total: {total} {moneda}.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
