import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import API from "../../../api";

import { toast } from "react-toastify";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// components

//data

export default function Users_all(props) {
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    getPeriods();
  }, []);

  // states
  const { register, handleSubmit } = useForm();
  const [saveLoader, setSaveLoader] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [periods, setPeriods] = useState([]);
  const [promotion_active, setPromotionActive] = useState(false);

  // set states

  const save = (data) => {
    setSaveLoader(true);

    API.admin_memberships
      .createPlan({
        descripcion: data.descripcion,
        numero_mes: parseInt(data.numero_mes),
        periodo: parseInt(data.periodo),
        monto: parseFloat(data.monto),
        promotion_is_active: promotion_active,
        precio_promocional: promotion_active ? data.precio_promocional : 0,
        fecha_promocion_inicio: promotion_active
          ? data.fecha_promocion_inicio
          : "",
        fecha_promocion_final: promotion_active
          ? data.fecha_promocion_final
          : "",
      })
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
          props.history.goBack();
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al crear el plan", {
              autoClose: 10000,
            });
          }
        }
        setSaveLoader(false);
      })
      .catch((err) => {
        setSaveLoader(false);
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al crear el plan", {
          autoClose: 10000,
        });
      });
  };

  const getPeriods = () => {
    API.userConfig
      .getPeriods()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setPeriods(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener los periodos", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener los periodos", {
          autoClose: 10000,
        });
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <Button
          className="btn-cs1 mx-1 mt-1"
          variant="contained"
          color="primary"
          to={"/admin/membresia"}
          component={Link}
        >
          <ArrowBackIosIcon className="mr-1" style={{ fontSize: "16px" }} />
          Volver
        </Button>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Membresías</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Crear membresía</strong>
        </h4>
      </div>

      <form className="content-card card mt-3" onSubmit={handleSubmit(save)}>
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Crear membresía</strong>
              </h5>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-1 mt-1 delete-btn"
                variant="contained"
                color="primary"
                component={Link}
                to={"/admin/membresia"}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1 mx-1 mt-1"
                variant="contained"
                color="primary"
              >
                {saveLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Guardar"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Nombre de la membresía</strong>
              </label>

              <TextField
                type="text"
                className="w-100"
                placeholder="Asigne un nombre para la membresía"
                inputRef={register}
                name="descripcion"
                required={true}
              />
            </div>
            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Asignar frecuencia de pago (en meses)</strong>
              </label>

              <FormControl variant="outlined" className="w-100">
                <Select
                  name="periodo"
                  native
                  inputRef={register}
                  className="cs-select1 select-w100"
                  required={true}
                >
                  <option value="">Seleccione la frecuencia de pago</option>
                  {periods.map((period) => (
                    <option value={period.id_periodo}>
                      {period.descripcion}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Precio de la membresía</strong>
              </label>

              <TextField
                type="number"
                className="w-100"
                placeholder="0"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Q.</InputAdornment>
                  ),
                  inputProps: { min: 0, step: "0.01" },
                }}
                inputRef={register}
                required={true}
                name="monto"
              />
            </div>

            {/* Second Row */}
            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Duracion (en meses):</strong>
              </label>

              <TextField
                type="number"
                className="w-100"
                placeholder="Introduzca la duración de la suscripción"
                inputRef={register}
                name="numero_mes"
                required={true}
              />
            </div>
            <div className="col-md-3 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Promoción</strong>
              </label>

              <label
                class="toggle-control mx-auto"
                style={{ left: "-15px", height: "40px" }}
              >
                <input
                  type="checkbox"
                  value={promotion_active}
                  onClick={() => setPromotionActive(!promotion_active)}
                />
                <span class="control" />
              </label>
            </div>
            {promotion_active ? (
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Precio Promocional</strong>
                </label>

                <TextField
                  type="number"
                  className="w-100"
                  placeholder="0"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Q.</InputAdornment>
                    ),
                    inputProps: { min: 0, step: "0.01" },
                  }}
                  inputRef={register}
                  required={true}
                  name="precio_promocional"
                />
              </div>
            ) : null}
            {promotion_active ? (
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Inicio de la Promoción</strong>
                </label>

                <TextField
                  inputRef={register}
                  type="date"
                  className="w-100"
                  required={true}
                  name="fecha_promocion_inicio"
                />
              </div>
            ) : null}
            {promotion_active ? (
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Fin de la Promoción</strong>
                </label>

                <TextField
                  inputRef={register}
                  type="date"
                  className="w-100"
                  required={true}
                  name="fecha_promocion_final"
                />
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
}
