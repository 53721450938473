import React from "react";
import DataTable from "react-data-table-component";

import { getMoneda } from "../../../../helpers/moneda";

const ProductsTable = (props) => {
  const { filteredProducts, handleSelectProducts, formaterPriceFunction } =
    props;
  const moneda = getMoneda();
  return (
    <DataTable
      className="datatable-cs datatable-cs-v2"
      title={"s"}
      columns={[
        {
          name: "Codigo SAP",
          selector: "codigo_sap",
          sortable: true,
        },
        {
          name: "Nombre",
          selector: "nombre",
          sortable: true,
        },
        {
          name: "Precio",
          selector: "precio",
          sortable: true,
          cell: (row) => `${moneda}. ${formaterPriceFunction(row.precio + "")}`,
        },
        {
          name: "Cantidad",
          selector: "cantidad",
          sortable: true,
          cell: (row) =>
            `${row.cantidad} ${
              row.vendido_por === "unidades" ? `und. ` : "lbs."
            }`,
        },
        {
          name: "Total",
          selector: "total",
          sortable: true,
          cell: (row) => `${moneda}. ${formaterPriceFunction(row.total + "")}`,
        },
      ]}
      data={filteredProducts}
      defaultSortField="fecha"
      defaultSortAsc={false}
      pagination
      paginationPerPage={10}
      paginationComponentOptions={{
        rangeSeparatorText: "de",
        noRowsPerPage: true,
        selectAllRowsItem: false,
        selectAllRowsItemText: "All",
      }}
      selectableRows
      noDataComponent="No hay ventas pendientes"
      onSelectedRowsChange={handleSelectProducts}
    />
  );
};

export default ProductsTable;
