import React, { useReducer, useState } from "react";
import { ColorPickerReducer } from "./ColorPicksReducers";
import ColorPickerContext from "./ColorPickContext";
import { GET_COLORS, SAVE_COLOR } from "./types";

const ColorPickerProvider = (props) => {
  const initialState = {
    colors: [],
  };
  const [state, dispatch] = useReducer(ColorPickerReducer, initialState);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const saveColor = (color) => {
    const colors = JSON.parse(localStorage.getItem("colores"));
    if (colors && colors.some((item) => item.background === color.background)) {
      return;
    }
    if (!colors) {
      localStorage.setItem("colores", JSON.stringify([color]));
    } else {
      localStorage.setItem("colores", JSON.stringify([...colors, color]));
    }
    dispatch({ type: SAVE_COLOR, payload: color });
  };

  const getColors = () => {
    let colors;
    colors = JSON.parse(localStorage.getItem("colores")) || [];
    if (colors.length) {
      colors = colors.sort((a, b) => b.picks - a.picks);
    }
    dispatch({ type: GET_COLORS, payload: colors });
  };

  const deleteForgivenColors = () => {
    const colors = JSON.parse(localStorage.getItem("colores"));
    if (!colors) return;
    if (colors.length < 12) {
      localStorage.setItem("colores", JSON.stringify(colors.splice(0, 11)));
    }
  };

  const oneMorePick = ({ background }) => {
    let colors;

    colors = JSON.parse(localStorage.getItem("colores"));
    colors = colors.map((color) =>
      color.background === background
        ? { ...color, picks: color.picks + 1 }
        : color
    );
    localStorage.setItem("colores", JSON.stringify(colors));
  };

  return (
    <ColorPickerContext.Provider
      value={{
        setShowColorPicker,
        showColorPicker,
        saveColor,
        getColors,
        colors: state.colors,
        deleteForgivenColors,
        oneMorePick,
      }}
    >
      {props.children}
    </ColorPickerContext.Provider>
  );
};

export { ColorPickerProvider };
