import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { CSVLink } from "react-csv";

// styles
import "./custom.css";

//herramienta reporte
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//reportes
import UsuariosReporte from "../../reportes/usuariosReporte";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SearchIcon from "@material-ui/icons/Search";
import { isCmi } from "../../helpers/isCmi";

// components

export default function Users_all(props) {
  let history = useHistory();

  // states
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [CSVUsers, setCSVUsers] = useState([]);
  const [userToDelete, setUserToDelete] = useState("");

  const [isGerenteRegional, setIsGerenteRegional] = useState(false);
  const [isAdministrator, setIsAdministrador] = useState(false);
  const [empresasGerente, setEmpresasGerente] = useState([]);
  const [sucursalesGerenteCmi, setSucursalesGerenteCmi] = useState([]);

  useEffect(() => {
    return () => {
      $("#confirmDeletion").modal("hide");
    };
  }, []);
  //component did mount
  useEffect(() => {
    const userLocal = JSON.parse(window.localStorage.getItem("pms"));
    if (isCmi()) {
      if (userLocal) {
        if (userLocal.tipo_usuario.toLowerCase() === "gerente regional") {
          setIsGerenteRegional(true);
          // buscamos las empresas para el gerente y filtramos sus sucursales.
          getEmpresasGerenteCmi(userLocal.id_new_user);
        } else {
          setIsGerenteRegional(false);
        }
      }
    }
    if (userLocal) {
      if (userLocal.tipo_usuario.toLowerCase() === "administrador") {
        setIsAdministrador(true);
      }
    }

    get_users(userLocal.tipo_usuario.toLowerCase());
    get_sucursals();
  }, []);

  useEffect(() => {
    if (empresasGerente.length > 0) {
      searchSucursales();
    }
  }, [empresasGerente]);

  // set states
  const changeStore = (event) => {
    let sucursal_id = null;

    //console.log("event to change store ->", event);

    if (event?.target?.value) {
      sucursal_id = event?.target?.value;
    } else {
      sucursal_id = "";
    }

    console.log("users ->", users);

    if (sucursal_id === "") {
      setFilteredUsers(users);
    } else {
      let usersData = users.filter(
        (user) => user.id_sucursal === parseInt(sucursal_id)
      );
      console.log("usersData ->", usersData);
      setFilteredUsers(usersData);
    }
  };

  const resourceAction = (e, user) => {
    console.log("This is user:", user);
    if (e.target.value === "editar") {
      history.push({
        pathname: "/app/users/new",
        state: user,
      });
    }
    if (e.target.value === "eliminar") {
      setUserToDelete(user.id_new_user);
      $("#confirmDeletion").modal();
    }
  };

  const get_users = (puesto) => {
    let isAdmin = puesto === "administrador";
    let isGerente = puesto === "gerente regional";
    const getidsucursal = window.localStorage.getItem("id_sucursal");
    setLoader(true);
    API.users
      .getAll()
      .then((res) => {
        let response = res.data;

        console.log("usuarios ->", response);

        if (response.success) {
          let usersData = response.data;
          setUsers(usersData);
          if (isCmi()) {
            if (isGerente) {
              setFilteredUsers(usersData);
            } else {
              let usersFiltered = usersData.filter(
                (user) => user.id_sucursal === parseInt(getidsucursal)
              );
              setFilteredUsers(usersFiltered);
            }
          } else {
            if (isAdmin) {
              setFilteredUsers(usersData);
            } else {
              let usersFiltered = usersData.filter(
                (user) => user.id_sucursal === parseInt(getidsucursal)
              );
              setFilteredUsers(usersFiltered);
            }
          }

          //setFilteredUsers(response.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log("Ha ocurrido un error", response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  const filterUsers = (e) => {
    setFilterText(e.target.value);
    setFilteredUsers(
      users.filter((item) =>
        item.nombre.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredUsers(users);
    }
  };
  const get_sucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSucursals(response.data);
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const deleteUser = (id) => {
    API.users
      .deleteUsers({
        ids_users: id,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("Usuario eliminado", {
            autoClose: 10000,
          });
          $("#confirmDeletion").modal("hide");
          get_users();
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;
    let csv_users = selectedRows;

    setCSVUsers(csv_users);
  };

  // gerente regional
  const getEmpresasGerenteCmi = async (id_user) => {
    let newEmpresa = [];

    try {
      const res = await API.users.getEmpresaPorUsuario(id_user);
      const data = await res.data.data;
      console.log("empresas ->", data);

      // filtrar las empresas
      data.forEach((empresa) => newEmpresa.push(empresa.empresa));

      let filtradas = newEmpresa.reduce((acc, el) => {
        if (!acc.find((item) => item.id_new_empresa == el.id_new_empresa)) {
          acc.push(el);
        }
        return acc;
      }, []);

      console.log("filtradas ->", filtradas);

      // seteamos las empresas
      setEmpresasGerente(filtradas);

      // buscamos las sucursales de esa empresa
      await handleChangeEmpresaGteCmi(filtradas[0].id_new_empresa);
    } catch (error) {
      console.log("error al obtener empresas gerente ->", error);
    }
  };

  const searchSucursales = async (id_empresa) => {
    let data = [];

    for (const empresa of Object.values(empresasGerente)) {
      const res = await API.sucursal.getSucursalsAndCajas(
        empresa.id_new_empresa
      );
      data.push(res);
    }

    //console.log("sucursales ->", data);
  };

  const handleChangeEmpresaGteCmi = async (event) => {
    let idToSearch = "";

    if (event?.target == undefined) {
      idToSearch = event;
    } else {
      const { name, value } = event.target;
      idToSearch = value;
    }

    try {
      // sucursales
      const res = await API.sucursal.getAll(parseInt(idToSearch), true);
      const data = await res.data.data;
      setSucursals(data);
      setSucursalesGerenteCmi(data);

      console.log("data sucursal ->", data);

      //buscamos por la primera sucursal
      //changeStore(data[0].id_sucursal_bodega_ubicacion);
    } catch (error) {
      console.log("error al traer sucursales para gerente ->", error);
    }
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Usuarios</strong>
        </h4>

        {isGerenteRegional && (
          <>
            <ArrowForwardIosIcon
              style={{ color: "#333333", fontSize: "12px" }}
              className="mx-2"
            />

            <FormControl variant="outlined">
              <Select native onChange={handleChangeEmpresaGteCmi}>
                {empresasGerente.map((itemEmpresa) => (
                  <option value={itemEmpresa.id_new_empresa}>
                    {itemEmpresa.nombre}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          {isCmi() ? (
            <Select native onChange={changeStore}>
              {isGerenteRegional && <option value="">Todas las tiendas</option>}
              {sucursals.map((sucursal) => (
                <option value={sucursal.id_sucursal_bodega_ubicacion}>
                  {sucursal.nombre_sucursal}
                </option>
              ))}
            </Select>
          ) : (
            <Select native onChange={changeStore}>
              {isAdministrator && <option value="">Todas las tiendas</option>}
              {sucursals.map((sucursal) => (
                <option value={sucursal.id_sucursal_bodega_ubicacion}>
                  {sucursal.nombre_sucursal}
                </option>
              ))}
            </Select>
          )}
        </FormControl>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <p className="mb-0">
                <strong>Usuarios</strong>
              </p>
              <p className="mb-0">
                Consulte y gestione los usuarios de sus tiendas.
              </p>
            </div>

            <Button
              className="btn-cs1"
              variant="contained"
              color="primary"
              startIcon={<AddBoxIcon />}
              onClick={(e) => null}
              component={Link}
              to={"/app/users/new"}
            >
              <span style={{ marginBottom: "-4px" }}>Añadir usuario</span>
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div className="row mt-2">
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Usuarios registrados
              </p>
              <h6 className="mb-0 secondary_color">
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>{filteredUsers.length}</strong>
                )}
              </h6>
            </div>
            <div className="col-md-2 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Usuarios administradores
              </p>
              <h6 className="mb-0 secondary_color">
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {
                      filteredUsers.filter(
                        (user) =>
                          user.tipo_nombre.toLowerCase() === "administrador"
                      ).length
                    }
                  </strong>
                )}
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Vendedores
              </p>
              <h6 className="mb-0 secondary_color">
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {
                      filteredUsers.filter(
                        (user) => user.tipo_nombre.toLowerCase() === "cajero"
                      ).length
                    }
                  </strong>
                )}
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Soporte
              </p>
              <h6 className="mb-0 secondary_color">
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {
                      filteredUsers.filter(
                        (user) => user.tipo_nombre.toLowerCase() === "soporte"
                      ).length
                    }
                  </strong>
                )}
              </h6>
            </div>
          </div>

          <div class="row mt-3">
            <div className="col-md-4" />
            <div className="col-md-4" />
            <div className="col-md-4">
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por nombre"
                  value={filterText}
                  onChange={(event) => filterUsers(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div>
              <div className="row">
                <div className="col-md-4 px-2 text-left">
                  {CSVUsers.length > 0 ? (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        document
                          .getElementById("descargar-reporteUsuarios")
                          .click();
                      }}
                    >
                      Descargar
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  ) : (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                    >
                      Descargar
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  )}
                  <ReactHTMLTableToExcel
                    id="descargar-reporteUsuarios"
                    className="buton-excel"
                    table="usuarios-reporte-table"
                    filename="Cloud-Reporte-Usuarios"
                    sheet="tablexls"
                    buttonText="Descargar"
                  />
                  <UsuariosReporte objeto={CSVUsers}></UsuariosReporte>
                </div>
                <div className="col-md-4 px-2" />
                <div className="col-md-4 px-2" />
              </div>
              <DataTable
                className="datatable-cs datatable-cs-v2 mt-2"
                title={"s"}
                columns={[
                  {
                    name: "Nombre del cliente",
                    selector: "nombre",
                  },
                  {
                    name: "Cargo",
                    selector: "tipo_nombre",
                    sortable: true,
                  },
                  {
                    name: "Tienda",
                    selector: "nombre_sucursal",
                    sortable: true,
                  },
                  {
                    name: "Correo electrónico",
                    selector: "correo",
                  },
                  {
                    name: "",
                    selector: "action",
                    cell: (row) => (
                      <div className="text-center w-100">
                        <FormControl variant="outlined">
                          <Select
                            native
                            value=""
                            onChange={(event) => resourceAction(event, row)}
                            className="cs-select"
                          >
                            <option value="">Acciones</option>
                            <option value="editar">Editar usuario</option>
                            <option value="eliminar">Eliminar usuario</option>
                          </Select>
                        </FormControl>
                      </div>
                    ),
                  },
                ]}
                data={filteredUsers}
                selectableRows
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se ha encontrado ningún resultado"
                onSelectedRowsChange={handleSelect}
              />
            </div>
          )}
        </div>
      </div>
      <div class="modal fade" id="confirmDeletion" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea eliminar este usuario?</strong>
              </h4>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => deleteUser(userToDelete)}
                >
                  Sí, eliminar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
