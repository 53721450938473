import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createPriceLevel,
  deletePriceLevel,
  getProductPriceLevels,
} from "../../api/products";

import CmiProductContext from "./CmiProductContext";

const CmiProductProvider = (props) => {
  const [niveles_precio, setNivelesPrecio] = useState("");
  const [newPrecioVenta, setNewPrecioVenta] = useState({
    precio_venta: 0,
    precio_venta_descuento: 0,
  });
  const [activarPrecioVentas, setActiovarPrecioVenta] = useState(false);
  const [savedNiveles, setSavedNiveles] = useState({});
  const [createSubProd, setCreateSubProd] = useState(false);
  const [createNivel, setCreateNivel] = useState(false);
  const [newNivel, setNewNivel] = useState({
    user_tipe: "none",
    unid_venta: "none",
    precio_venta: 0,
  });
  const [nivelesLocales, setNivelesLocales] = useState([]);
  const [firstClick, setFirstClick] = useState(true);

  const [listSubniveles, setListSubniveles] = useState([]);
  const [storedLevels, setStoredLevels] = useState([]);
  const [movimiento, setMovimiento] = useState(null);
  const [activarModal, setActivarModal] = useState(false);
  const [activarCierre, setActivarCierre] = useState(false);
  const [retiroAutorizado, setRetiroAutorizado] = useState(false);
  const [activarModalAlerta, setActivarModalAlerta] = useState(false);
  const [cantidadRetiro, setCantidadRetiro] = useState({
    total: 0,
    cantidad: 0,
  });
  const [activarModalCorte, setActivarModalCorte] = useState(false);
  const [activarCierreAutorizacion, setActivarCierreAutorizacion] =
    useState(false);

  const [retiroData, setRetiroData] = useState({
    id_caja_web_app: "",
    id_sucursal_bodega_ubicacion: "",
    id_vendedor_web_app: "",
    id_new_empresa: "",
    retiro_fecha: new Date(),
    cantidad_retiro: 0,
    id_new_usuario_gerente: "",
  });

  const [efectivoDisponible, setEfectivoDisponible] = useState(0);

  const clearCmiState = () => {
    setNivelesPrecio("");
    setNewPrecioVenta({});
    setActiovarPrecioVenta(false);
    setSavedNiveles({});
    setCreateSubProd(false);
    setCreateNivel(false);
    setNewNivel({
      user_tipe: "none",
      unid_venta: "none",
      precio_venta: 0,
    });
    setNivelesLocales([]);
    setFirstClick(true);
  };

  const [modalErrorCampos, setModalErrorCampos] = useState(false);
  const [modalErrorCamposCorte, setModalErrorCamposCorte] = useState(false);
  const [modalDiaAnterior, setModalDiaAnterior] = useState(false);
  const [idSucursal, setIdSucursal] = useState(null);
  const [montoRetiro, setMontoRetiro] = useState(0);

  const createPriceLevels = async (id) => {
    let savedLevels = JSON.parse(localStorage.getItem("newNivel"));
    if (savedLevels) {
      const id_new_empresa = localStorage.getItem("id_empresa");

      savedLevels = savedLevels.map((level) => {
        const {
          precio_venta: precio,
          unid_venta: unidad_venta,
          id_nivel: id_tipo_cliente_or_sublevel,
        } = level;

        return {
          id_new_empresa,
          id_tipo_cliente_or_sublevel,
          typelevel: "subnivel",
          id_new_item: id,
          precio,
          unidad_venta,
        };
      });

      try {
        await createPriceLevel({ price_level: JSON.stringify(savedLevels) });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (savedNiveles.length > 0) {
      console.log("Saved Niveles", savedNiveles);

      // array de subniveles
      let arraySubniveles = [];

      // extraemos los subniveles
      savedNiveles.map((item) => {
        let { subniveles } = item;

        subniveles.map((sub) => {
          let newSubniveles = {
            ...sub,
            nombre: sub.subnivel,
            desde: sub.cantidad_desde,
            hasta: sub.cantidad_hasta,
          };
          arraySubniveles.push(newSubniveles);
        });
      });

      setListSubniveles(arraySubniveles);
    }
  }, [savedNiveles]);

  const getCurrentProductPriceLevels = async (id) => {
    const id_empresa = localStorage.getItem("id_empresa");
    try {
      const { data } = await getProductPriceLevels(id_empresa, id);
      const levels = data.data.map((level) => ({
        ...level,
        id: level.id_level_price,
        precio_venta: level.precio.toString(),
        unid_venta: level.unidad_venta,
        user_tipe: listSubniveles.find(
          (item) => item.id === level.id_tipo_cliente_or_sublevel
        ).subnivel,
      }));
      setNivelesLocales(levels);
      window.localStorage.setItem("newNivel", JSON.stringify(levels));
      setStoredLevels(levels);
      console.log(levels);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCurrentLevel = async (id) => {
    try {
      await deletePriceLevel(id);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CmiProductContext.Provider
      value={{
        niveles_precio,
        setNivelesPrecio,
        newPrecioVenta,
        setNewPrecioVenta,
        activarPrecioVentas,
        setActiovarPrecioVenta,
        savedNiveles,
        setSavedNiveles,
        createSubProd,
        setCreateSubProd,
        createNivel,
        setCreateNivel,
        newNivel,
        setNewNivel,
        nivelesLocales,
        setNivelesLocales,
        clearCmiState,
        setFirstClick,
        firstClick,
        createPriceLevels,
        getCurrentProductPriceLevels,
        listSubniveles,
        deleteCurrentLevel,
        storedLevels,
        modalErrorCampos,
        setModalErrorCampos,
        modalDiaAnterior,
        setModalDiaAnterior,
        setMovimiento,
        activarModal,
        setActivarModal,
        setActivarCierre,
        activarCierre,
        idSucursal,
        setIdSucursal,
        setRetiroAutorizado,
        retiroAutorizado,
        montoRetiro,
        setMontoRetiro,
        activarModalAlerta,
        setActivarModalAlerta,
        retiroData,
        setRetiroData,
        efectivoDisponible,
        setEfectivoDisponible,
        setCantidadRetiro,
        cantidadRetiro,
        setActivarModalCorte,
        activarModalCorte,
        activarCierreAutorizacion,
        setActivarCierreAutorizacion,
        modalErrorCamposCorte,
        setModalErrorCamposCorte,
      }}
    >
      {props.children}
    </CmiProductContext.Provider>
  );
};

export { CmiProductProvider };
