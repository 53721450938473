import React from "react";
import CustomerTable from "./Table";
import PieChartCustomers from "./PieChart";
import BarChartCustomers from "./BarChart/BarChartCustomers";

const Customers = (props) => {
  const { clients, cartera, formasPago, loader } = props;

  return (
    <>
      <div className="row mt-3">
        <CustomerTable clients={clients} loader={loader} />

        <div className="col-md-5">
          <div className="row">
            <BarChartCustomers data={cartera} loader={loader} />
            <PieChartCustomers data={formasPago} loader={loader} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
