import React, { useState, useEffect, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import InfoIcon from "@material-ui/icons/Info";
import EditIcon from "@material-ui/icons/Edit";

// components

//data

export default function Users_all(props) {
  let history = useHistory();

  let userData = {
    id_new_user: "",
    id_usuario: "",
    id_new_empresa: "",
    nombre: "",
    id_tipo_usuario_web_app: "",
    correo: "",
    id_sucursal: "",
    id_vendedor: "",
    serie: "",
    tipo_nombre: "",
    permisos: [0, 0, 0, 0, 0, 0, 0],
    caja: "",
    telefono: "",
  };

  if (props.history.location.state) {
    let data = props.history.location.state;
    userData.nombre = data.nombre;
    userData.tipo_nombre = data.tipo_usuario;
    userData.correo = data.correo;
  } else {
    history.push("/admin/dashboard");
  }

  const [user, setUser] = useState(userData);

  const { register, handleSubmit, errors, watch, reset } = useForm();
  const password = useRef({});
  const [newUserForm, setNewUserForm] = useState(true);
  password.current = watch("contrasena", "");

  //component will mount
  useEffect(() => {}, []);

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
    };
  }, []);

  useEffect(() => {}, []);

  // states
  const [loader, setLoader] = useState(false);

  // set states

  const save_data = (data) => {
    setLoader(true);

    let permisos = [0, 0, 0, 0, 0, 0, 0];

    permisos[0] = data.permiso1 ? 1 : 0;
    permisos[1] = data.permiso2 ? 1 : 0;
    permisos[2] = data.permiso3 ? 1 : 0;
    permisos[3] = data.permiso4 ? 1 : 0;
    permisos[4] = data.permiso5 ? 1 : 0;
    permisos[6] = data.permiso6 ? 1 : 0;
    permisos[7] = data.permiso7 ? 1 : 0;

    if (data.contrasena != data.confirm_password) {
      toast.error("Las contraseñas no coinciden", {
        autoClose: 3000,
      });
    }

    data.permisos = permisos;
    console.log(data);
  };

  const deleteUser = () => {
    $("#confirmExit").modal("hide");
  };

  return (
    <div id="dashboard-section">
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea eliminar este usuario?</strong>
              </h4>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => deleteUser()}
                >
                  Sí, eliminar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <h4 className="mb-0">
          <strong>Detalle del cliente</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Crear usuario</strong>
        </h4>
      </div>

      <form
        className="content-card card mt-3"
        onSubmit={handleSubmit(save_data)}
      >
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Usuario: {user.nombre}</strong>
              </h5>
              <p className="mb-0">Consulte y gestione los datos del usuario.</p>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-2 delete-btn"
                variant="contained"
                color="primary"
                onClick={() => props.history.goBack()}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1 mx-2"
                variant="contained"
                color="primary"
              >
                {loader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Guardar"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Nombre del usuario </strong>
                </label>
                <Tooltip title="nombre de usuario">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <TextField
                defaultValue={user.nombre}
                className="w-100"
                placeholder="Ingrese un nombre de usuario"
                name="nombre_contacto"
                required={true}
                inputRef={register}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditIcon style={{ color: "#0160E7" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <input type="hidden" name="id_usuario" ref={register} />
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Correo electrónico de acceso</strong>
                </label>
                <Tooltip title="Correo electrónico de acceso">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <TextField
                defaultValue={user.correo}
                type="email"
                className="w-100"
                placeholder="Ingrese el correo electrónico del usuario"
                name="correo_electronico"
                required={true}
                inputRef={register}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditIcon style={{ color: "#0160E7" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Sucursal</strong>
                </label>
                <Tooltip title="Sucursal">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <FormControl variant="outlined" className="w-100">
                <Select
                  className="cs-select1 select-w100"
                  inputRef={register}
                  required={true}
                  name="id_sucursal"
                  native
                  onChange={(event) => null}
                >
                  <option value="">Seleccione un sucursal</option>
                  <option value="sucrusal">sucursal</option>
                </Select>
              </FormControl>
            </div>

            {/* Second Row */}

            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Número de teléfono</strong>
                </label>
                <Tooltip title="Número de teléfono">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <TextField
                type="text"
                className="w-100"
                placeholder="Número de teléfono "
                inputRef={register}
                required={true}
                name="contacto_telefono"
                defaultValue={user.telefono}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditIcon style={{ color: "#0160E7" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Asignar número de serie </strong>
                </label>
                <Tooltip title="Asignar número de serie ">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <TextField
                defaultValue={user.serie}
                className="w-100"
                placeholder="Asigne un número de serie para este usuario"
                inputRef={register}
                required={true}
                name="serie"
              />
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Asignar caja</strong>
                </label>
                <Tooltip title="Asignar caja">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <FormControl variant="outlined" className="w-100">
                <Select
                  native
                  className="cs-select1 select-w100"
                  inputRef={register}
                  required={true}
                  name="id_caja_web_app"
                >
                  <option value="">Seleccione una caja</option>
                  <option value="caja">caja</option>
                </Select>
              </FormControl>
            </div>

            {/* Third Row */}
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Restauración de contraseña</strong>
              </label>
              <p className="mb-0">
                Las sesiones abiertas del usuarios se cerrarán y deberás
                ingresar la nueva contraseña.
              </p>
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Nueva contraseña </strong>
                </label>
                <Tooltip title="Contraseña de acceso">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <TextField
                type="password"
                className="w-100"
                placeholder="Ingrese una nueva contraseña"
                inputRef={register}
                name="contrasena"
              />
            </div>
            <div className="col-md-4 mt-3">
              <Button
                type="button"
                className="btn-cs1 mx-2"
                variant="contained"
                color="primary"
              >
                {loader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Restaurar contraseña"
                )}
              </Button>
            </div>
          </div>

          <h5 className="mb-0 mt-5">
            <strong>Permisos</strong>
          </h5>
          <p className="mb-0">
            Personalice los permisos de los usuarios o asigne los permisos por
            defecto.
          </p>
          <hr />

          <div className="row mt-4">
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Tipos de usuario</strong>
                </label>
                <Tooltip title="Tipos de usuario">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <FormControl variant="outlined" className="w-100">
                <Select
                  native
                  className="cs-select1 select-w100"
                  inputRef={register}
                  required={true}
                  name="tipo_usuario"
                >
                  <option value="">Seleccione el tipo de usuario</option>
                  <option value="tipo usuario">tipo</option>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-4 mt-3">
              <p className="mb-0" style={{ color: "#4F4F4F" }}>
                Puede asignar los permisos predeterminado para cada tipo de
                usuario, o personalizar los permisos para cada usuario.
              </p>
            </div>
            <div className="col-md-4 mt-3 text-left">
              <Button
                className="btn-cs1 mx-2 delete-btn"
                variant="contained"
                color="primary"
                data-toggle="modal"
                data-target="#confirmExit"
              >
                Eliminar usuario
              </Button>
            </div>

            {/* Second row */}

            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Dashboard</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá consultar las estadísticas y toda la
                    información del Dashboard
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input
                      type="checkbox"
                      ref={register}
                      name="permiso1"
                      defaultChecked={user.permisos[0]}
                    />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Clientes</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá consultar la base de datos con los
                    clientes, liquidar anticipos y cancelar facturas a crédito
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input
                      type="checkbox"
                      ref={register}
                      name="permiso2"
                      defaultChecked={user.permisos[1]}
                    />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>
            {/* Third row */}
            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Inventario</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá consultar los productos del inventario,
                    realizar ingresos y egresos
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input
                      type="checkbox"
                      ref={register}
                      name="permiso3"
                      defaultChecked={user.permisos[2]}
                    />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Traslados</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá emitir y administrar traslados entrantes y
                    salientes.
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input
                      type="checkbox"
                      ref={register}
                      name="permiso4"
                      defaultChecked={user.permisos[3]}
                    />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Usuarios</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá administrar y añadir nuevos usuarios en su
                    sistema Cloud
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input
                      type="checkbox"
                      ref={register}
                      name="permiso5"
                      defaultChecked={user.permisos[4]}
                    />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Configuración</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá administrar la información de su empresa,
                    sucursales, formato de facturas...
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input
                      type="checkbox"
                      ref={register}
                      name="permiso6"
                      defaultChecked={user.permisos[5]}
                    />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>POS</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá facturar, consultar operaciones y cortes de
                    caja.
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input
                      type="checkbox"
                      ref={register}
                      name="permiso7"
                      defaultChecked={user.permisos[6]}
                    />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
