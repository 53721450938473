import React from "react";
import General from "./General";
import Markets from "./Markets";
import Personalization from "./Personalization";
import Products from "./Products";

const TopSection = () => {
  return (
    <div className="row mt-3">
      <div className="col-md-6">
        <Personalization />
      </div>
      <div className="col-md-6">
        <Markets />
      </div>
      <div className="col-md-6 mt-3">
        <Products />
      </div>
      <div className="col-md-6 mt-3">
        <General />
      </div>
      <div className="col-md-12"></div>
    </div>
  );
};

export default TopSection;
