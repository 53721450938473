import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const TopBarCargaMasiva = () => {
  return (
    <div className="section-title mb-4">
      <HomeIcon className="d-none d-sm-block" />
      <ArrowForwardIosIcon
        style={{ color: "#333333", fontSize: "12px" }}
        className="mx-2 d-none d-sm-block"
      />
      <h4 className="mb-0">
        <strong>Inventario</strong>
      </h4>
      <ArrowForwardIosIcon
        style={{ color: "#333333", fontSize: "12px" }}
        className="mx-2"
      />
      <h4 className="mb-0">
        <strong>Carga de productos a toda la cadena </strong>
      </h4>
      <div className="border-bottom" />
    </div>
  );
};

export default TopBarCargaMasiva;
