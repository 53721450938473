import React from "react";

const InvoiceActions = ({ vars }) => {
  let {
    IconButton,
    DeleteIcon,
    gotoPayment,
    CreditCardIcon,
    Tooltip,
    InfoIcon,
    salesCost,
    aleko,
  } = vars;

  return (
    <div className="card-body">
      <div className="row invoice-column p-3">
        <div className="col-sm-5 catalog-invoice">
          <div className="row h-100 justify-content-around align-items-center">
            <IconButton
              className="mx-1 my-1"
              size="small"
              style={{
                background: "#FFFFFF",
                color: "white",
                width: "106px",
                height: "106px",
                borderRadius: "5px",
              }}
              data-toggle="modal"
              data-target="#cancelSale"
            >
              <div className="text-center">
                <DeleteIcon
                  style={{
                    color: "#EB5757",
                    fontSize: "40px",
                  }}
                />
                <p style={{ color: "#EB5757" }}>
                  <strong>Cancelar</strong>
                </p>
              </div>
            </IconButton>

            <IconButton
              className="mx-1 my-1"
              size="small"
              style={{
                background: "#FFFFFF",
                color: "white",
                width: "106px",
                height: "106px",
                borderRadius: "5px",
              }}
              onClick={gotoPayment}
            >
              <div className="text-center">
                <CreditCardIcon
                  style={{
                    color: "#1A2EAB",
                    fontSize: "40px",
                  }}
                />
                <p style={{ color: "#1A2EAB" }}>
                  {aleko ? <strong>Continuar</strong> : <strong>Pagar</strong>}
                </p>
              </div>
            </IconButton>
          </div>
        </div>
        <div className="col-sm-7">
          <div className="row h-100">
            <div className="col-6 p-0 m-0 text-center titles my-auto">
              {/* <p>SubTotal:</p>
					    <p>IVA (12%):</p> */}
              <p>
                <Tooltip title="Este costo incluye el descuento de cada producto.">
                  <InfoIcon
                    style={{
                      color: " #FFF",
                      fontSize: "1.2rem",
                    }}
                  />
                </Tooltip>
                Total:
              </p>
            </div>
            <div className="col-6 p-0 m-0 text-center my-auto costs">
              {/*   <p>Q. {salesCost.subtotal.toFixed(2)}</p>
					    <p>Q. {salesCost.iva.toFixed(2)}</p> */}
              <p>Q. {salesCost.total.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceActions;
