import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const ClientesDeudoresReporte = ({ objeto, all }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table
        className="tableReporte"
        id={
          all
            ? "todosClientesDeudores-reporte-table"
            : "clientesDeudores-reporte-table"
        }
      >
        <thead>
          <tr>
            <th>Nombre del cliente</th>
            <th>NIT</th>
            <th>Saldo deudor</th>
            <th>Estatus</th>
            <th>Vencimiento</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            return (
              <tr key={index}>
                <td>{x.nombre} </td>
                <td>{x.nit}</td>
                <td>
                  {moneda}.{" "}
                  {x.saldo_deudor
                    ? formaterPriceFunction(x.saldo_deudor.toFixed(2) + "")
                    : "0.00"}
                </td>
                <td>{x.estado}</td>
                <td>
                  {x.veoncimiento
                    ? moment(x.veoncimiento).format("DD/MM/YYYY, h:mm:ss a")
                    : "0.00"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ClientesDeudoresReporte;
