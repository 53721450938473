import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// components

import MermaContext from "../../../../context/MermaContext/MermaContext";
import GraphicsMerma from "./Grafica";
import { InputAdornment } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getMoneda } from "../../../../helpers/moneda";

const Merma_prodChild = ({ submitLoader, unidadVenta, stock = 0 }) => {
  const {
    form,
    setForm,
    handleChange,
    mermaEstimada,
    valorMerma,
    cantidadActual,
  } = useContext(MermaContext);
  const params = useParams();

  const moneda = getMoneda();

  useEffect(() => {
    let newObj = {};
    if (unidadVenta === "unidades") {
      newObj = { ...newObj, cantidad_unidades: stock };
    }
    setForm({
      ...form,
      ...newObj,
      medida_merma: unidadVenta === "unidades" ? "unidades" : "%",
    });
  }, []);

  return (
    <div className="row mb-0">
      <div className="col-md-6">
        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-6">
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Merma</strong>
            </label>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-around">
            <div className="custom-control custom-switch">
              <label
                class="toggle-control mx-auto"
                style={{ left: "-15px", height: "40px" }}
              >
                <input
                  type="checkbox"
                  checked={form.merma}
                  onClick={() =>
                    setForm({ ...form, merma: form.merma ? 0 : 1 })
                  }
                />
                <span class="control" />
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <p style={{ fontSize: "0.8rem" }} className="mb-0">
            Permite estimar la cantidad de productos aptos para la venta y la
            cantidad que no estará disponible para la venta debido a su pérdida
            en el tiempo.
          </p>
        </div>
      </div>
      <div className="col-md-6 text-right">
        {params.id && (
          <Button
            className="btn-cs1 mt-1 mx-1"
            variant="contained"
            color="primary"
            type="submit"
          >
            {submitLoader ? (
              <div className="text-center">
                <CircularProgress
                  size={26}
                  className="mx-auto"
                  style={{ color: "white" }}
                />
              </div>
            ) : (
              "Actualizar"
            )}
          </Button>
        )}
      </div>
      <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center">
        <div className="col-md-2">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Cantidad actual</strong>
          </label>
          <TextField
            type="text"
            disabled
            className="w-100"
            value={parseFloat(cantidadActual).toFixed(2)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {form.medida_merma === "%" ? "lbs." : "ud."}
                </InputAdornment>
              ),
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
        </div>
        <div className="col-md-3">
          <div className="w-100">
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Cantidad de merma</strong>
            </label>
          </div>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <TextField
              type="text"
              className="w-100"
              disabled={!form.merma}
              name="cantidad_merma"
              placeholder="000,000/Lbs."
              value={form.cantidad_merma}
              onChange={handleChange}
            />
            <FormControl variant="outlined" className="w-100">
              <Select
                native
                name="medida_merma"
                required
                className="cs-select1 select-w100"
                value={form.medida_merma}
                onChange={handleChange}
              >
                {unidadVenta === "mixto" ||
                unidadVenta === "libras" ||
                !unidadVenta ? (
                  <>
                    <option value="%">%</option>
                  </>
                ) : (
                  ""
                )}

                {unidadVenta === "unidades" ? (
                  <option value="unidades">Unidades</option>
                ) : (
                  ""
                )}
              </Select>
            </FormControl>
          </div>
        </div>
        {/* <div className="col-md-3">
          <div className="w-100">
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Frecuencia</strong>
            </label>
          </div>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <TextField
              type="text"
              className="w-100"
              name="frecuencia"
              placeholder="000,000/Lbs."
            />
            <FormControl variant="outlined" className="w-100">
              <Select
                native
                name="frecuencia_merma"
                required
                className="cs-select1 select-w100"
              >
                <option value="diario">Diario</option>
                <option value="semanal">Semanal</option>
                <option selected value="quincenal">
                  Quincenal
                </option>
                <option value="mensual">Mensual</option>
                <option value="cuatrimestral">Cuatrimestral</option>
                <option value="semestral">Semestral</option>
                <option value="anual">Anual</option>
              </Select>
            </FormControl>
          </div>
        </div> */}
        <div className="col-md-7">
          <div className="w-100">
            <label style={{ fontSize: "0.8rem" }}>Merma estimada</label>
          </div>
          <div className="w-100 d-flex justify-content-start align-items-center">
            <div className="w-25 d-flex justify-content-center align-items-center">
              <label>Cantidad</label>
            </div>
            <div className="w-75 d-flex justify-content-center align-items-center">
              <TextField
                disabled
                value={
                  typeof mermaEstimada === "string"
                    ? mermaEstimada
                    : parseFloat(mermaEstimada).toFixed(2)
                }
                type="text"
                className="w-75"
                placeholder="000,000/Lbs."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {form.medida_merma === "%" ? "lbs." : "ud."}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="w-25 d-flex justify-content-center align-items-center">
              <label>Valor</label>
            </div>
            <div className="w-75 d-flex justify-content-center align-items-center">
              <TextField
                type="text"
                disabled
                className="w-75"
                name="valor_merma_estimada"
                value={valorMerma.toFixed(2)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{moneda}.</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Graficos */}
      <div className="col-md-12">
        <div className="col-md-6 border border-danger">
          <GraphicsMerma />
        </div>
      </div>
    </div>
  );
};

export default Merma_prodChild;
