import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const ClientesReporte = ({ objeto, all }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table
        className="tableReporte"
        id={all ? "todos-clientes-reporte-table" : "clientes-reporte-table"}
      >
        <thead>
          <tr>
            <th>Nombre del cliente</th>
            <th>NIT</th>
            <th>Tipo de cliente</th>
            <th>Ticket medio</th>
            <th>Valor de vida</th>
            <th>Saldo acreedor</th>
            <th>Saldo deudor</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            return (
              <tr key={index}>
                <td>{x.nombre}</td>
                <td>{x.nit}</td>
                <td>{x.diferenciacion}</td>
                <td>
                  {moneda}. {x.half_ticket ? x.half_ticket.toFixed(2) : "0.00"}
                </td>
                <td>
                  {moneda}.{" "}
                  {x.life_value
                    ? formaterPriceFunction(x.life_value.toFixed(2) + "")
                    : "0.00"}
                </td>
                <td>
                  {moneda}.{" "}
                  {x.saldo_acreedor ? numberFormater(x.saldo_acreedor) : "0.00"}
                </td>
                <td>
                  {moneda}.{" "}
                  {x.saldo_duedor
                    ? formaterPriceFunction(x.saldo_duedor.toFixed(2) + "")
                    : "0.00"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ClientesReporte;
