import styled from "styled-components";

import { colors, device } from "./config.styled.components";

export const GridContainer = styled.div`
  padding: ${(props) => props.padding};
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridTemplateColumns || "repeat(12,1fr)"};

  grid-template-areas: ${(props) => props.gridTemplateAreas};
  grid-column: ${(props) => props.gridColumn};
  grid-gap: ${(props) => props.gridGap};
  background-color: ${(props) => props.backgroundColor};

  box-shadow: ${(props) => props.boxShadow};
  border-bottom: ${(props) => props.borderBottom};
  border-radius: ${(props) => props.borderRadius};
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  margin: ${(props) => props.margin};
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};
  overflow: ${(props) => props.overflow};
`;

export const FlexContainer = styled.div`
  display: flex;
  cursor: ${(props) => props.cursor};
  flex-wrap: ${(props) => props.flexWrap || "wrap"};
  border: ${(props) => props.border};

  grid-column: ${(props) => props.gridColumn};
  grid-area: ${(props) => props.gridArea};

  margin: ${(props) => props.margin};

  box-shadow: ${(props) => props.boxShadow};
  align-items: ${(props) => props.alignItems};
  align-self: ${(props) => props.alignSelf};
  justify-content: ${(props) => props.justifyContent};
  background-color: ${(props) => props.backgroundColor};
  flex-direction: ${(props) => props.flexDirection};
  flex-flow: ${(props) => props.flexFlow};

  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};
  overflow: ${(props) => props.overflow};
  border-radius: ${(props) => props.borderRadius};
  border-right: ${(props) => props.borderRight};
  border-bottom: ${(props) => props.borderBottom};
  padding: ${(props) => props.padding};
  row-gap: ${(props) => props.rowGap};
  column-gap: ${(props) => props.columnGap};
  text-align: ${(props) => props.textAlign};
  position: ${(props) => props.position || "relative"};
`;

export const PointsContainer = styled(GridContainer)`
  grid-template-columns: auto;
  grid-template-areas: "a b c d";

  @media ${device.mobileM} {
    grid-template-areas: "a b" "c d";
  }

  @media ${device.mobileS} {
    grid-template-areas: "a" "b" "c" "d";
  }
`;

export const PointsViewContainer = styled(GridContainer)`
  @media ${device.tablet} {
    grid-column: 1/-1;
  }
`;
export const ViewContainer = styled(PointsViewContainer)``;

export const VariantContainer = styled(GridContainer)``;

export const GeneralContainer = styled(GridContainer)`
  @media ${device.mobileL} {
    grid-template-areas:
      "a"
      "b"
      "e"
      "c"
      "f";
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const AreaContainer = styled(GridContainer)`
  @media ${device.laptop} {
    grid-template-areas:
      "a b c"
      "e f g"
      "d j j"
      "h j j"
      "h i i";
    grid-gap: 20px;
  }

  @media ${device.tablet} {
    grid-template-areas:
      "a b"
      "e f"
      "c d"
      "g h"
      "j j"
      "i i";
    grid-template-columns: auto;
  }

  @media ${device.mobileL} {
    grid-template-areas:
      "a"
      "e"
      "b"
      "f"
      "c"
      "g"
      "d"
      "h"
      "j"
      "i";
  }
`;

export const LeftScreen = styled(GridContainer)`
  @media ${device.tablet} {
    grid-column: 1/-1;
  }
`;
export const RightScreen = styled(GridContainer)`
  @media ${device.tablet} {
    grid-column: 1/-1;
  }
`;

export const ActionsContainer = styled(FlexContainer)`
  justify-content: ${(p) => p.justifyContent || "space-between"};
`;

export const ColorDiv = styled(FlexContainer)`
  background-color: ${(p) => `#${p.backgroundColor}`};
  width: ${(p) => p.width || "25px"};
  height: ${(p) => p.height || "25px"};
  border-radius: 50%;
  border: ${(p) => p.border || `1px solid ${colors.blackColor}`};
`;

export const PerspectiveContainer = styled(FlexContainer)``;
export const Img = styled.img`
  max-width: ${(p) => p.maxWidth};
  max-height: ${(p) => p.maxHeight};
  border: ${(p) => p.border};
  border-radius: ${(p) => p.borderRadius};
  object-fit: ${(p) => p.objectFit};
  margin: ${(p) => p.margin};
  position: relative;
`;

export const Download = styled(FlexContainer)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  background-color: black;
  z-index: 2;
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  opacity: 0.5;
`;

export const ViewsContainer = styled(GridContainer)`
  @media ${device.laptop} {
    grid-template-columns: 1fr;
  }

  @media ${device.tablet} {
    grid-column: 1/-1;
    grid-template-columns: repeat(4, 1fr);
  }
`;
