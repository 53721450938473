// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import API, { realImageURL } from "../../api";
import { toast } from "react-toastify";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import DoneIcon from "@material-ui/icons/Done";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import queryString from "query-string";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Recibo from "./Recibo";
import print from "print-js";
import { getMoneda } from "../../helpers/moneda";
import { isAdmin } from "../../helpers/isAdmin";
import { isCmi } from "../../helpers/isCmi";

// components

export default function Users_all(props) {
  const moneda = getMoneda();
  let history = useHistory();
  const moment = require("moment");
  const [menuTransfer, setmenuTransfer] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [comentario, setComentario] = useState("");
  const [traslado, setTraslado] = useState({
    id_traslado_web_app: "",
    id_new_empresa: "",
    id_new_user: "",
    id_sucursal_origen: "",
    id_new_ubicacion_origen: "",
    id_new_bodega_origen: "",
    origin_sucursal: "",
    id_new_sucursal_destino: "",
    id_new_bodega_destino: "",
    id_new_ubicacion_destino: "",
    destino_sucursal: "",
    fecha_origen: "",
    fecha_destino: "",
    id_new_tipo_documento: "",
    serie: "",
    numero: "",
    operacion: "",
    estado: "",
    documentType: "",
    usuario: "",
    total_productos: "",
    trasladoDetalles: [
      {
        id_traslado_detalle_web_app: "",
        id_new_empresa: "",
        id_traslado_web_app: "",
        id_new_item: "",
        cantidad: "",
        precio: "",
        lote: "",
        codigo: "",
        fecha: "",
      },
    ],
  });
  const [refresh, setRefresh] = useState(false);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#confirmExit2").modal("hide");
    };
  }, []);

  //componentdidmount - code to run on component mount
  useEffect(() => {
    if (sucursal) {
      getTraslado(sucursal);
    }
  }, [sucursal, refresh]);
  useEffect(() => {
    const getData = async () => {
      const { data } = await API.profile.getCompanyData();
      setCompanyName(data.data.nombre);
    };
    getData();
    getSucursals();
  }, []);
  const printSale = () => {
    print({
      printable: "printform",
      type: "html",
      targetStyles: ["*"],
    });
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success && JSON.stringify(response.data) != "[]") {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  // states

  const [loader, setLoader] = useState(true);  
  const [loaderAcepted, setLoaderAcepted] = useState(false);
  const [loaderRejected, setLoaderRejected] = useState(false);

  // set states

  //function

  const getTraslado = (sucursalId) => {
    let id = props.match.params.id.toString();
    API.traslados
      .getById({
        id_traslado_web_app: id,
        id_sucursal_bodega_ubicacion: sucursalId,
      })
      .then((res) => {
        let response = res.data;
        console.log("Traslado received: ", response.data);
        if (response.success) {
          if (!isCmi()) {
            let data = response.data;
            let params = queryString.parse(props.location.search);
            if (params) {
              if (params.id_sucursal) {
                let activeMenuTransfer =
                  data.estado === "Pendiente" &&
                  data.id_new_bodega_destino === parseInt(params.id_sucursal) &&
                  isAdmin();
                setmenuTransfer(activeMenuTransfer);
              }
            }
          }
          setTraslado(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (
      (traslado.id_traslado_web_app !== "" &&
        companyName &&
        traslado.estado === "Rechazado") ||
      traslado.estado === "Aprobado" ||
      traslado.estado === "Pendiente"
    ) {
      printSale();
    }
  }, [companyName, traslado]);

  const sendReceivedAmount = (productId) => {
    setLoader(true);
    let received = document.getElementById("cantidad-" + productId).value;
    let data = {
      id_traslado_web_app: traslado.id_traslado_web_app,
      id_new_item: productId,
      cantidad: parseFloat(received),
    };
    API.traslados
      .updateQuantity(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(
            "La cantidad recibida fue fijada. El traslado se actualizó",
            {
              autoClose: 10000,
            }
          );
          let currentTraslado = traslado;
          let existingData = [...traslado.trasladoDetalles];
          let currentIndex = existingData.findIndex(
            (x) => x.id_new_item === productId
          );
          existingData[currentIndex].cantidad = received;
          currentTraslado.trasladoDetalles = existingData;
          setTraslado(currentTraslado);
        }
        setLoader(false);
        setRefresh((prev) => !prev);
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (response) {
          toast.error(response.data.message, { autoClose: 10000 });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  const rejectTraslado = () => {
    setLoaderRejected(true);
    API.traslados
      .reject({ id_traslado_web_app: traslado.id_traslado_web_app, comentario })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("El traslado fue rechazado", {
            autoClose: 10000,
          });
          setRefresh(!refresh);
          $("#confirmExit").modal("hide");
          $("#confirmExit2").modal("hide");
          //history.push(`/app/traslados`);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      })
      .finally(()=> {
        setLoaderRejected(false);
      })
  };

  const acceptTraslado = () => {
    setLoaderAcepted(true);
    API.traslados
      .accept({ id_traslado_web_app: traslado.id_traslado_web_app, comentario })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("El traslado fue aceptado", {
            autoClose: 10000,
          });
          setRefresh(!refresh);
          $("#confirmExit").modal("hide");
          $("#confirmExit2").modal("hide");
          //history.push(`/app/traslados`);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      })
      .finally(() => {
        setLoaderAcepted(false);
      })
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                {/* <CheckCircleIcon className="checkIcon" /> */}

                <h4>
                  <strong>¿Seguro que desea aceptar el traslado?</strong>
                </h4>
              </div>
              <div className="d-flex w-100">
                <FormControl variant="outlined" className="w-100">
                  <TextareaAutosize
                    name="comentario"
                    aria-label="minimum heigth"
                    placeholder="Indique el motivo"
                    className="w-100 my-1"
                    maxLength={160}
                    minRows={3}
                    onChange={(e) => setComentario(e.target.value)}
                    style={{ minHeight: "40px" }}
                  />
                </FormControl>
              </div>
              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Cancelar
                </Button>
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={acceptTraslado}
                  disabled={loaderAcepted}
                >
                  {loaderAcepted ? <CircularProgress size={26} /> : "Aceptar"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div class="modal fade" id="confirmExit2" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                <LocalShippingIcon className="checkIcon" />

                <h4>
                  <strong>
                    ¿Está seguro que quieres <br />
                    rechazar este traslado?
                  </strong>
                </h4>
              </div>

              <div class="d-flex justify-content-between flex-wrap">
                <FormControl variant="outlined" className="w-100">
                  <TextareaAutosize
                    name="comentario"
                    aria-label="minimum heigth"
                    placeholder="Indique el motivo"
                    className="w-100 my-1"
                    maxLength={160}
                    minRows={3}
                    onChange={(e) => setComentario(e.target.value)}
                    style={{ minHeight: "40px" }}
                  />
                </FormControl>
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={rejectTraslado}
                  disabled={loaderRejected}
                >
                  {loaderRejected ? <CircularProgress size={26} /> : "Sí, rechazar"}
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Traslados</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Detalles del Traslado</strong>
        </h4>
      </div>

      <form>
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Detalles del Traslado</strong>
                </h5>
                <p className="mb-0">
                  Información detallada acerca del traslado.
                </p>
              </div>

              <div>
                <Button
                  className="btn-cs1 mx-1 mt-1"
                  variant="contained"
                  color="primary"
                  to={"/app/traslados"}
                  component={Link}
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            {loader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <div className="row mb-4">
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Número de traslado</strong>
                  </label>

                  <TextField
                    disabled
                    value={traslado.numero}
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el número de traslado"
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Origen</strong>
                    </label>
                    <Tooltip title="Sucursal de origen del traslado">
                      <InfoIcon
                        style={{ color: " #828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select disabled native className="cs-select1 select-w100">
                      <option value="">{traslado.origin_sucursal}</option>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Destino</strong>
                    </label>
                    <Tooltip title="Sucursal de destino del traslado">
                      <InfoIcon
                        style={{ color: " #828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select disabled native className="cs-select1 select-w100">
                      <option value="">{traslado.destino_sucursal}</option>
                    </Select>
                  </FormControl>
                </div>

                {/* Second Row */}

                <div className="col-md-2 mt-3 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Fecha del traslado</strong>
                  </label>

                  <TextField
                    type="date"
                    className="w-100"
                    disabled
                    value={moment(new Date(traslado.fecha_origen))
                      .utcOffset(0)
                      .format("YYYY-MM-DD")}
                  />
                </div>

                <div className="col-md-2 mt-3 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>hora del traslado</strong>
                  </label>

                  <TextField
                    type="text"
                    className="w-100"
                    disabled
                    value={moment(new Date(traslado.fecha_origen), ["HH"])
                      .utcOffset(0)
                      .format("hh:mm A")}
                  />
                </div>

                <div className="col-md-4 mt-3 d-flex align-items-center">
                  <div className="d-flex align-items-center mr-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Estatus del traslado</strong>
                    </label>
                    <Tooltip title="Estatus del traslado" className="ml-2">
                      <InfoIcon
                        style={{ color: " #828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>
                  {traslado.estado === "Pendiente" ? (
                    <span
                      className="p-2 warning-span"
                      style={{ color: "white" }}
                    >
                      {traslado.estado}
                    </span>
                  ) : (
                    ""
                  )}
                  {traslado.estado === "Rechazado" ? (
                    <span
                      className="p-2 danger-span"
                      style={{ color: "white" }}
                    >
                      {traslado.estado}
                    </span>
                  ) : (
                    ""
                  )}
                  {traslado.estado === "Aprobado" ? (
                    <span
                      className="p-2 success-span"
                      style={{ color: "white" }}
                    >
                      Aceptado
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                {menuTransfer ? (
                  <div className="col-md-12 mt-3 mt-3 d-flex align-items-center justify-content-end">
                    <Button
                      className="btn-cs1 mx-1 mt-1 delete-btn"
                      variant="contained"
                      color="primary"
                      data-toggle="modal"
                      data-target="#confirmExit2"
                      disabled={loaderRejected}
                    >
                      {loaderRejected ? <CircularProgress size={26} /> : "Rechazar traslado"}
                    </Button>

                    <Button
                      className="btn-cs1 mx-1 mt-1"
                      variant="contained"
                      color="primary"
                      data-toggle="modal"
                      data-target="#confirmExit"
                      disabled={loaderAcepted}
                    >
                      {loaderAcepted ? <CircularProgress size={26} /> : "Aceptar traslado"}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={printSale}
                      disabled={
                        traslado.id_traslado_web_app === "" || !companyName
                      }
                    >
                      Imprimir
                    </Button>
                  </div>
                ) : (
                  <div className="col-md-4 mt-3 d-flex align-items-center justify-content-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={printSale}
                      disabled={
                        traslado.id_traslado_web_app === "" || !companyName
                      }
                    >
                      Imprimir
                    </Button>
                  </div>
                )}

                <div className="col-12 mt-3" />

                <DataTable
                  className="datatable-cs datatable-cs-v2 datatable-h-80"
                  title={"s"}
                  columns={[
                    {
                      name: "Nombre",
                      selector: "nombre",
                      sortable: true,
                      cell: (row) => (
                        <div className="d-flex w-100">
                          <img
                            className="product-img"
                            src={realImageURL(row.pricipal_imagen)}
                            alt=""
                          />
                          <div
                            className="ml-2 my-auto"
                            style={{ width: "150px" }}
                          >
                            {row.nombre}
                          </div>
                        </div>
                      ),
                    },
                    { name: "Código", selector: "codigo", sortable: true },
                    {
                      name: "Cantidad - enviada",
                      selector: "cantidad",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {formaterPriceFunction(
                            row.cantidad_rechazada
                              ? (
                                  row.cantidad_rechazada + row.cantidad_aceptada
                                ).toString()
                              : row.cantidad + ""
                          )}{" "}
                          / unidades
                        </span>
                      ),
                    },
                    {
                      name: "Precio - producto",
                      selector: "precio",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {formaterPriceFunction(row.precio.toFixed(2) + "")}{" "}
                        </span>
                      ),
                    },
                    {
                      name: "Cantidad - recibida",
                      selector: "action",
                      omit: !traslado.origen || traslado.estado === "Rechazado",

                      cell: (row) =>
                        !row.estado_aceptado ? (
                          <div
                            className="d-flex align-items-center w-100"
                            key={"row-" + row.id_new_item}
                          >
                            <TextField
                              type="text"
                              className="w-100"
                              placeholder="Cantidad recibida"
                              variant="filled"
                              size="small"
                              onChange={(e) => {
                                if (isNaN(parseFloat(e.target.value))) {
                                  e.target.value = "";
                                }
                                if (parseFloat(e.target.value) > row.cantidad) {
                                  e.target.value = "";
                                }
                              }}
                              key={row.id_new_item}
                              id={"cantidad-" + row.id_new_item}
                            />
                            <Button
                              className="btn-cs1 mx-1 mt-1 p-2"
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() =>
                                sendReceivedAmount(row.id_new_item)
                              }
                            >
                              <DoneIcon />
                            </Button>
                          </div>
                        ) : (
                          row.cantidad_aceptada + " /unidades"
                        ),
                    },
                  ]}
                  data={traslado.trasladoDetalles}
                  pagination
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </form>
      <Recibo traslado={traslado} companyName={companyName} />
    </div>
  );
}
