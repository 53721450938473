import React, { useState, useEffect } from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../api";
import ReactCodeInput from "react-code-input";

// styles
import useStyles from "./styles";
import "./custom.css";

// icons
import LockIcon from "@material-ui/icons/Lock";

const codeInputProps = {
  className: ReactCodeInput,
  inputStyle: {
    margin: "15px",
    MozAppearance: "textfield",
    width: "50px",
    borderRadius: "3px",
    height: "50px",
    fontSize: "20px",
    paddingLeft: "7px",
    backgroundColor: "#F2F2F2",
    color: "black",
    border: "1px solid #0160E7",
    textAlign: "center",
  },
  inputStyleInvalid: {
    margin: "15px",
    MozAppearance: "textfield",
    width: "50px",
    height: "50px",
    borderRadius: "3px",
    fontSize: "20px",
    paddingLeft: "7px",
    backgroundColor: "#F2F2F2",
    color: "red",
    border: "1px solid red",
    textAlign: "center",
  },
};

function Login(props) {
  var classes = useStyles();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [code, setCode] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (props.history.location.state) {
      setEmail(props.history.location.state.userEmail);
    } else {
      toast.error(
        "Ha ocurrido un error. Por favor comienza el proceso nuevamente.",
        {
          autoClose: 10000,
        }
      );
    }
  }, []);

  const confirm_code = () => {
    setIsLoading(true);
    API.admin_user
      .verifyEmailCode({
        correo: email,
        code: code,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setIsLoading(false);
          props.history.push({
            pathname: "/admin/forgot/reset",
            state: { userEmail: email },
          });
          toast.success("Tu identidad ha sido confirmada.", {
            autoClose: 10000,
          });
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  return (
    <div className="row h-100" style={{ marginRight: "-17.5px" }}>
      <div className="col-lg-6 ">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="row p-0 w-100 justify-content-center">
            <div className="col-8">
              <div
                className="content-card card"
                style={{ position: "relative" }}
              >
                <div
                  className="p-2"
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    borderRadius: "50%",
                    top: "-25px",
                    left: "46%",
                  }}
                >
                  <LockIcon style={{ color: "#0160E7", fontSize: "38px" }} />
                </div>

                <div className="card-body ">
                  <h1 style={{ fontSize: "2.1rem" }} className="mb-1">
                    <strong>Introduce el código de recuperación</strong>
                  </h1>

                  <div className="text-center">
                    <ReactCodeInput
                      type="text"
                      fields={4}
                      {...codeInputProps}
                      value={code}
                      onChange={(value) => setCode(value)}
                    />
                  </div>

                  <div>
                    {isLoading ? (
                      <div className="text-center">
                        <CircularProgress size={26} className="mx-auto" />
                      </div>
                    ) : (
                      <Button
                        onClick={() => confirm_code()}
                        variant="contained"
                        size="large"
                        style={{
                          backgroundColor: "#0160E7",
                          color: "white",
                          textTransform: "none",
                        }}
                        className="w-100 mt-4"
                      >
                        <strong>Verificar</strong>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-6 logo-container recovery2 d-flex justify-content-center align-items-center ">
        <img
          style={{ width: "400px", height: "auto" }}
          src={"/images/admin-logo.png"}
        />
      </div>
    </div>
  );
}

export default withRouter(Login);
