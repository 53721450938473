import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import API from "../../../../../api";
import {
  getAlekoOrder,
  getAlekoPedido,
  getStatusListOrder,
  updateStatusorder,
} from "../../../../../api/sales";
import { getIdEmpresa } from "../../helpers/handleLocalOrders";
import { useOrder } from "../hooks/useOrder";

const Details = ({ client, pedido }) => {
  const { order } = client;
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const [listStatusOrder, setlistStatusOrder] = useState([]);
  const [newStatus, setNewStatus] = useState({
    id_new_pedido: 0,
    codigo_pedido: 0,
  });
  const [store, setStore] = useState("");
  const [seller, setSeller] = useState("");

  const [detailsPedido, setdetailsPedido] = useState({});

  useEffect(() => {
    if (pedido) {
      getDetailsOrder();
      getStatusOrder();
      getSucursal();
    }
  }, [pedido]);

  const getDetailsOrder = async () => {
    const data = {
      id_pedido_item: pedido?.id_new_pedido,
      id_bodega_sucursal: pedido?.id_bodega_sucursal,
    };
    try {
      const response = await getAlekoPedido(pedido?.id_new_pedido);
      setdetailsPedido(response.data.data[0]);
      console.log("response pedido", response.data.data[0]);
      setLoading(false);
    } catch (error) {
      console.log("error al obtener detalles del pedido", error);
    }
  };

  const getStatusOrder = async () => {
    const id_empresa = getIdEmpresa();
    const response = await getStatusListOrder(id_empresa);
    setlistStatusOrder(response.data.data);
  };

  const getSucursal = async () => {
    const { data } = await API.sucursal.getAll();
    const store = data.data.filter(
      (item) =>
        item.id_sucursal_bodega_ubicacion ===
        pedido?.id_sucursal_bodega_ubicacion
    );
    setStore(store[0].nombre_sucursal);

    const sellers = await API.cajas.getCompanySellers({
      id_sucursal_bodega_ubicacion: store.id_sucursal_bodega_ubicacion,
    });

    const seller = sellers.data.data.filter(
      (item) => item.id_vendedor_web_app === pedido?.id_vendedor_web_app
    );

    setSeller(seller[0].nombre);
  };

  const formatDate = (date) => {
    const splited = date.split("-");
    let year = splited[0];
    let month = splited[1];
    let day = splited[2].split("T")[0];

    return year + "-" + month + "-" + day;
  };

  const handleUpdateStatusOrder = (e) => {
    const { value } = e.target;
    setNewStatus({
      id_new_pedido: id,
      codigo_pedido: Number(value),
    });
  };

  const updateStatus = async () => {
    console.log("data ->", newStatus);

    try {
      const statusForUpdate = await updateStatusorder(newStatus);

      if (statusForUpdate.data.succes) {
        toast.success("Estatus actualizado", { autoClose: 10000 });
        await getDetailsOrder();
        return;
      }

      return toast.error("Error al actualizar status de la orden", {
        autoClose: 10000,
      });
    } catch (error) {
      console.log("error al actualizar pedido", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const formaterPriceFunction = (value) => {
    return value
      .toFixed(2)
      .toString()
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const colorHandler = (state) => {
    if (state.toUpperCase() == "COTIZADO") return "rgba(86,204,242,1)";
    if (state.toUpperCase() == "ENTREGADO") return "rgba(39,174,96,1)";
    if (state.toUpperCase() == "CANCELADO") return "rgba(235,87,87,1)";
    if (state.toUpperCase() == "POR ENTREGAR") return "rgba(242,153,74,1)";
  };

  const estado_color = colorHandler(detailsPedido?.nombre_estado_pedido);

  // console.log("params", params);

  return (
    <div className="row">
      <div className="col-md-3 pr-0">
        <h5>
          <strong>Detalles del cliente</strong>
        </h5>
      </div>
      <div className="col-md-9 pl-0 pr-3">
        <hr />
      </div>
      <div className="col-md-3">
        <h6>
          <strong>Nombre</strong>
        </h6>
      </div>
      <div className="col-md-3">
        <h6>
          {" "}
          <strong>Correo electrónico</strong>
        </h6>
      </div>
      <div className="col-md-3">
        <h6>
          {" "}
          <strong>Teléfono</strong>
        </h6>
      </div>
      <div className="col-md-3">
        <h6>
          {" "}
          <strong>NIT</strong>
        </h6>
      </div>

      <div className="col-md-3 pr-0 mt-1">
        <h6>{detailsPedido?.nombre}</h6>
      </div>

      <div className="col-md-3 mt-1">
        <h6>{detailsPedido?.correo ? detailsPedido?.correo : "No posee"}</h6>
      </div>
      <div className="col-md-3 mt-1">
        <h6>
          {" "}
          {detailsPedido?.telefono ? detailsPedido?.telefono : "No posee"}
        </h6>
      </div>
      <div className="col-md-3 mt-1">
        <h6> {detailsPedido?.NIT ? detailsPedido?.NIT : detailsPedido?.nit}</h6>
      </div>

      <div className="col-md-3 pr-0 mt-2">
        <h5>
          <strong>Detalles de la orden</strong>
        </h5>
      </div>
      <div className="col-md-9 pl-0 pr-3 mt-2">
        <hr />
      </div>

      <div className="col-md-3">
        <h6>
          <strong>Tienda</strong>
        </h6>
      </div>
      <div className="col-md-3">
        <h6>
          {" "}
          <strong>Empleado</strong>
        </h6>
      </div>
      <div className="col-md-3">
        <h6>
          {" "}
          <strong>Fecha de creación</strong>
        </h6>
      </div>
      <div className="col-md-3">
        <h6>
          {" "}
          <strong>Fecha de entrega</strong>
        </h6>
      </div>
      <div className="col-md-3 pr-0 mt-1">
        <h6>{store && store}</h6>
      </div>

      <div className="col-md-3 mt-1">
        <h6>{seller && seller}</h6>
      </div>
      <div className="col-md-3 mt-1">
        <h6>
          {" "}
          {detailsPedido?.fecha_registro &&
            formatDate(detailsPedido?.fecha_registro)}
        </h6>
      </div>
      <div className="col-md-3 mt-1">
        <h6>
          {" "}
          {detailsPedido?.fecha_plazo && formatDate(detailsPedido?.fecha_plazo)}
        </h6>
      </div>

      <div className="col-md-3">
        <h6>
          <strong>Estatus de la orden</strong>
        </h6>
      </div>
      <div className="col-md-3">
        <h6>
          {" "}
          <strong>Actualizar estatus</strong>
        </h6>
      </div>
      <div className="col-md-6"></div>
      <div className="col-md-3">
        <button
          className="btn"
          style={{ color: "white", backgroundColor: `${estado_color}` }}
          disabled
        >
          {detailsPedido?.nombre_estado_pedido[0] +
            detailsPedido?.nombre_estado_pedido.toLowerCase().slice(1)}
        </button>
      </div>
      <div className="col-md-9">
        <div name="selectStatus" className="section-title">
          <select
            className="form-select mr-3"
            onChange={handleUpdateStatusOrder}
          >
            {listStatusOrder.map((status) => (
              <option
                selected={
                  status.codigo_pedido === detailsPedido?.codigo_pedido
                    ? true
                    : false
                }
                value={status.codigo_pedido}
              >
                {status.nombre_estado_pedido}
              </option>
            ))}
          </select>
          <button
            className="btn btn-primary"
            style={{ height: "35.56px" }}
            onClick={updateStatus}
          >
            Actualizar
          </button>
        </div>
      </div>
      <div className="col-md-3 pr-0 mt-2">
        <h5>
          <strong>Detalles del pago</strong>
        </h5>
      </div>
      <div className="col-md-9 pl-0 pr-3 mt-2">
        <hr />
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-12">
            <h6>
              <strong>Métodos de pago utilizados</strong>
            </h6>
          </div>
          <div className="col-md-6">
            <h6>Efectivo</h6>
          </div>
          <div className="col-md-6">
            <h6>Q. {formaterPriceFunction(detailsPedido?.efectivo)}</h6>
          </div>
          <div className="col-md-6">
            <h6>Tarjeta</h6>
          </div>
          <div className="col-md-6">
            <h6>Q. {formaterPriceFunction(detailsPedido?.tarjeta)}</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-6">
            <h6>
              <strong>Cantidad a pagar</strong>
            </h6>
          </div>
          <div className="col-md-6">
            <h6>
              {" "}
              <strong>
                Q. {formaterPriceFunction(detailsPedido?.monto_total)}
              </strong>
            </h6>
          </div>
          <div className="col-md-6">
            <h6>Total pagado:</h6>
          </div>
          <div className="col-md-6">
            <h6>Q. {formaterPriceFunction(detailsPedido?.monto_total)}</h6>
          </div>
          <div className="col-md-6">
            <h6>Cambio:</h6>
          </div>
          <div className="col-md-6">
            <h6>Q. {formaterPriceFunction(detailsPedido?.cambios)}</h6>
          </div>
          {/* <div className="col-md-6">
            <h6>Q. 0.00</h6>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Details;
