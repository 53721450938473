import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
//eslint-disable-next-line
import $ from "jquery";
//eslint-disable-next-line
import { Link } from "react-router-dom";
import API from "../../api";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import CashRegister from "./CashRegister.png";
import Document from "./Document.png";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AddIcon from "@material-ui/icons/Add";
import AddBoxIcon from "@material-ui/icons/AddBox";

import { getMoneda } from "../../helpers/moneda";

// components

//data

const payment_periods = [
  {
    id: 1,
    option: "Pago único",
    value: "0",
  },
  {
    id: 2,
    option: "3 meses",
    value: "3",
  },
  {
    id: 3,
    option: "6 meses",
    value: "6",
  },
  {
    id: 4,
    option: "12 meses",
    value: "12",
  },
  {
    id: 5,
    option: "18 meses",
    value: "18",
  },
  {
    id: 6,
    option: "24 meses",
    value: "24",
  },
  {
    id: 7,
    option: "36 meses",
    value: "36",
  },
];

export default function Sell(props) {
  const moneda = getMoneda();
  const [loader, setLoader] = useState(true);
  const [saldarFacturaLoader, setSaldarFacturaLoader] = useState(false);
  const [paidByGiftCard, setPaidByGiftCard] = useState(0);
  const [paidByCard, setPaidByCard] = useState(0);
  const [paidByCash, setPaidByCash] = useState(0);
  const [selectedMethods, setSelectedMethods] = useState([0]);
  const [periods, setPeriods] = useState(payment_periods[0].value);
  const [invoice_number, setInvoiceNumber] = useState("");
  const [giftcard_code, setGiftCardCode] = useState("");
  const [additional_invoice, setAdditionalInvoice] = useState("");
  const [additional_invoices, setAdditionalInvoices] = useState([]);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState({
    monto: 0,
  });
  const [cuentaPorCobrar, setCuentaPorCobrar] = useState({
    due: {
      id_cliente_deudor_web_app: 0,
      id_cliente_web_app: 0,
      id_new_empresa: 0,
      id_pos_movimiento: 0,
      id_tipo_documento_web_app: 1,
      documento: 0,
      serie: "",
      estado: 0,
      nro_productos: 0,
      monto: 0,
      fecha: "",
    },
    invoice: {
      efectivo: 0,
      monto_total: 0,
      tarjeta: 0,
      gift_card_code: "",
      gift_card: 0,
      credito: null,
      subtotal: 0,
      vat_amount: 0,
      fecha: "",
      cuotas: 0,
      cambios: 0,
    },
    client: {
      nombre: "",
      dias_credito: "",
      nit: "",
      direccion: "",
      email: "",
    },
    seller: {
      nombre: "",
    },
    orderItems: [],
    total_paid: 0,
    estado: "",
  });

  useEffect(() => {
    getCuenta();
  }, []);

  const getCuenta = () => {
    let id = props.match.params.id.toString();
    setLoader(true);
    API.clients
      .getCuentaPorCobrarByPk({ id_cliente_deudor_web_app: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setLoader(false);
          setCuentaPorCobrar(response.data);
          console.log(response.data);
          setSelectedCredit(response.data.due);
        } else {
          setLoader(false);
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const openInNewTab = () => {
    localStorage.removeItem("factura_data");
    localStorage.setItem(
      "factura_data",
      JSON.stringify({
        fel_data: {
          numerofel: cuentaPorCobrar.invoice.numerofel,
          seriefel: cuentaPorCobrar.invoice.seriefel,
          fecha: cuentaPorCobrar.invoice.fecha,
          identificador: cuentaPorCobrar.invoice.identificador,
          id_dte: cuentaPorCobrar.invoice.id_dte,
          service_certificador: cuentaPorCobrar.invoice.service_certificador,
          service_nit: cuentaPorCobrar.invoice.service_nit,
          service_id: cuentaPorCobrar.invoice.service_id,
          id_sucursal_bodega_ubicacion:
            cuentaPorCobrar.invoice.id_sucursal_bodega_ubicacion,
        },
        selectedProducts: cuentaPorCobrar.orderItems,
        seller: cuentaPorCobrar.seller,
        client: cuentaPorCobrar.client,
        invoiceInfo: {
          salesCost: {
            total: cuentaPorCobrar.invoice.monto_total,
            subtotal: cuentaPorCobrar.invoice.subtotal,
            iva: cuentaPorCobrar.invoice.vat_amount,
          },
          paidAmount: {
            paid:
              cuentaPorCobrar.invoice.efectivo +
                cuentaPorCobrar.invoice.tarjeta !==
              0
                ? parseFloat(
                    (
                      cuentaPorCobrar.invoice.efectivo +
                      cuentaPorCobrar.invoice.tarjeta
                    ).toFixed(2)
                  )
                : cuentaPorCobrar.invoice.monto_total,
            change: cuentaPorCobrar.invoice.cambios,
          },
          paidByCard: cuentaPorCobrar.invoice.tarjeta,
          paidByCash: cuentaPorCobrar.invoice.efectivo,
          paidByGiftCard: cuentaPorCobrar.invoice.gift_card,
          selectedProducts: cuentaPorCobrar.orderItems,
          flag_estado: cuentaPorCobrar.invoice.flag_estado,
        },
      })
    );
    const newWindow = window.open(
      "/#/app/vender/factura",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const submitSaldar = () => {
    let invalidForm = false;
    let data = {
      id_cliente_deudor_web_app: selectedCredit.id_cliente_deudor_web_app,
      tarjeta: paidByCard,
      efectivo: paidByCash,
      gift_card: paidByGiftCard,
      gift_card_codigo: giftcard_code,
      recibo_numero: invoice_number,
      adicional_recibo: additional_invoices.join(","),
      cuotas: periods,
      cambios: 0,
    };
    let total_paid =
      parseFloat(paidByCard) +
      parseFloat(paidByCash) +
      parseFloat(paidByGiftCard);
    if (selectedCredit.monto < total_paid)
      data.cambios = parseFloat((total_paid - selectedCredit.monto).toFixed(2));
    console.log("Data", data);

    if (total_paid === 0) {
      invalidForm = true;
      toast.error("No puede saldar una factura sin un monto pagado.", {
        autoClose: 10000,
      });
    }
    if (paidByCard > 0 && !invoice_number) {
      invalidForm = true;
      toast.error(
        "Al pagar con tarjeta debe introducir al menos un numero de recibo.",
        {
          autoClose: 10000,
        }
      );
    }

    if (!invalidForm) {
      API.clients
        .saldarFactura(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setSaldarFacturaLoader(false);
            toast.success(response.message, {
              autoClose: 10000,
            });
            $("#saldar_factura").modal("hide");
          } else {
            setSaldarFacturaLoader(false);
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          setSaldarFacturaLoader(false);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        });
    }
  };

  const changePeriods = (event) => {
    setPeriods(event.target.value);
  };

  const addNewAdditionalInvoice = () => {
    if (additional_invoice !== "") {
      let newArray = [...additional_invoices];
      newArray.push(additional_invoice);
      setAdditionalInvoices(newArray);
    }
  };

  const toggleCheck = (e) => {
    let target = e.target;
    if (target.name === "check1") {
      setCheck1(!check1);
    } else {
      setCheck2(!check2);
    }
  };

  const updateValue = (e) => {
    let target = e.target;
    let parsedValue = isNaN(parseFloat(target.value))
      ? 0
      : Math.abs(parseFloat(target.value));
    e.target.value = isNaN(parseFloat(target.value)) ? "" : target.value;
    if (target.name === "paidByCash") {
      setPaidByCash(parsedValue);
    }
    if (target.name === "paidByCard") {
      setPaidByCard(parsedValue);
    }
    if (target.name === "paidByGiftCard") {
      setPaidByGiftCard(parsedValue);
    }
  };

  const handleBoxClick = (x) => {
    let chosenMethods = [...selectedMethods];
    if (x === 4) {
      // If user clicks on the 'multiples' button
      if (chosenMethods.includes(4)) {
        // If multiples was previously selected
        $(".payment-method-box").each(function () {
          if ($(this).hasClass("active-box")) {
            $(this).removeClass("active-box");
          }
        });
        setSelectedMethods([0]);
      } else {
        // If user clicks multiples for the first time
        $(".payment-method-box").each(function () {
          if ($(this).hasClass("active-box")) {
            $(this).removeClass("active-box");
          }
        });
        let currentElement = "box-" + x;
        $(`#${currentElement}`).addClass("active-box");
        setSelectedMethods([4]);
      }
    } else {
      /* if (chosenMethods.includes(4)) {
                let currentElement = "box-" + x;
                if (chosenMethods.includes(x)) {
                    $(`#${currentElement}`).removeClass("active-box");
                    setSelectedMethods(
                        chosenMethods.filter((value) => value !== x)
                    );
                    switch (x) {
                        case 1:
                            setPaidByCash("");
                            break;
                        case 2:
                            setPaidByCard("");
                            break;
                        case 3:
                            setPaidByGiftCard("");
                            break;
                        default:
                            break;
                    }
                } else {
                    $(`#${currentElement}`).addClass("active-box");
                    chosenMethods.push(x);
                    setSelectedMethods(chosenMethods);
                }
            } else { */
      $(".payment-method-box").each(function () {
        if ($(this).hasClass("active-box")) {
          $(this).removeClass("active-box");
        }
      });
      let currentElement = "box-" + x;
      $(`#${currentElement}`).addClass("active-box");
      setSelectedMethods([x]);
      switch (x) {
        case 1:
          setPaidByCard(0);
          setPaidByGiftCard(0);
          break;
        case 2:
          setPaidByGiftCard(0);
          setPaidByCash(0);
          break;
        case 3:
          setPaidByCash(0);
          setPaidByCard(0);
          break;
        default:
          break;
      }
      /* } */
    }
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Facturación</strong>
        </h4>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="d-flex justify-content-center justify-content-sm-between flex-wrap mb-2">
            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<img src={Document} alt="cash-register-icon" />}
              onClick={openInNewTab}
            >
              <span style={{ marginBottom: "-4px" }}>Ver documento</span>
            </Button>

            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<img src={CashRegister} alt="cash-register-icon" />}
              component={Link}
              to={"/app/vender"}
            >
              <span style={{ marginBottom: "-4px" }}>Volver a la caja</span>
            </Button>
          </div>
          <div className="content-card card">
            <div className="card-header">
              <h4 className="mb-0">
                <strong>Resumen de la compra</strong>
              </h4>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>NIT de cliente</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      name="nit"
                      value={cuentaPorCobrar.client.nit}
                      disabled
                    />
                  )}
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Nombre de cliente</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={cuentaPorCobrar.client.nombre}
                      name="client_name"
                      disabled
                    />
                  )}
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Dirección fiscal del cliente</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={
                        cuentaPorCobrar.client.direccion || "No especificada"
                      }
                      name="direccion_fiscal"
                      disabled
                    />
                  )}
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Correo electrónico</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={cuentaPorCobrar.client.email || "No especificado"}
                      name="email"
                      disabled
                    />
                  )}
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Vendedor</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={cuentaPorCobrar.seller.nombre}
                      name="seller"
                      disabled
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card content-card mt-2">
            <div className="card-header p-0" id="facturacion-products-header">
              {loader ? (
                <div className="text-center">
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : (
                <DataTable
                  className="datatable-cs datatable-cs-v2 m-0"
                  title={"s"}
                  columns={[
                    {
                      name: "Producto",
                      selector: "producto",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {row.nombre} <br />
                          {row.codigo}
                        </div>
                      ),
                    },
                    {
                      name: "Precio",
                      selector: "precio",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {moneda}. {parseFloat(row.precio.toFixed(2))}
                        </div>
                      ),
                    },
                    {
                      name: "Cantidad",
                      selector: "cantidad",
                      sortable: true,
                    },
                    {
                      name: "Total",
                      selector: "precio",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {moneda}.{" "}
                          {parseFloat(row.precio * row.cantidad).toFixed(2)}
                        </div>
                      ),
                    },
                  ]}
                  data={cuentaPorCobrar.orderItems}
                  pagination
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                />
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-7">
                  <div className="row">
                    <div
                      className="col-md-12 mt-3"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <p>
                        <strong>Información del cliente</strong>
                      </p>
                      <p>
                        Este cliente cumple con los requisitos para optar por
                        crédito de:
                        <strong>
                          {" "}
                          {cuentaPorCobrar.client.dias_credito || 0} días
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 invoice-column">
                  <div className="row h-75">
                    <div className="col-6 p-0 m-0 text-center titles my-auto">
                      <p>SubTotal:</p>
                      <p>IVA (12%):</p>
                      <p>Total:</p>
                      <p>Pagado:</p>
                      <p>Vuelto:</p>
                    </div>
                    <div className="col-6 p-0 m-0 text-center my-auto">
                      <p>
                        {moneda}. {cuentaPorCobrar.invoice.subtotal.toFixed(2)}
                      </p>
                      <p>
                        {moneda}.{" "}
                        {cuentaPorCobrar.invoice.vat_amount.toFixed(2)}
                      </p>
                      <p>
                        {moneda}.{" "}
                        {cuentaPorCobrar.invoice.monto_total.toFixed(2)}
                      </p>
                      <p>
                        {moneda}. {cuentaPorCobrar.total_paid.toFixed(2)}
                      </p>
                      <p>
                        {moneda}. {cuentaPorCobrar.invoice.cambios.toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div className="row h-25 justify-content-center w-100 m-0">
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "#1a2eab",
                      }}
                      className="my-2 w-75 facturar-button"
                      variant="contained"
                      data-toggle="modal"
                      data-target="#saldar_factura"
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                          textTransform: "none",
                        }}
                      >
                        Pagar ahora
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="saldar_factura" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content" style={{ minHeight: "450px" }}>
            <div class="d-flex justify-content-end px-2 py-1">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body card content-card mt-2 p-0">
              <div
                className="card-header saldar-factura-header"
                style={{ boxShadow: "none" }}
              >
                <div className="row h-100">
                  <div className="col-sm-8 d-flex align-items-center justify-content-center flex-wrap mb-3">
                    <div
                      className="payment-method-box"
                      id="box-1"
                      onClick={() => handleBoxClick(1)}
                    >
                      <LocalAtmIcon />
                      <p>Efectivo</p>
                    </div>
                    <div
                      className="payment-method-box"
                      id="box-2"
                      onClick={() => handleBoxClick(2)}
                    >
                      <CreditCardIcon />
                      <p>Tarjeta</p>
                    </div>
                    {/*  <div
                                            className="payment-method-box"
                                            id="box-3"
                                            onClick={() => handleBoxClick(3)}
                                        >
                                            <CardGiftcardIcon />
                                            <p>Gift card</p>
                                        </div> */}
                    <div
                      className="payment-method-box"
                      id="box-4"
                      onClick={() => handleBoxClick(4)}
                    >
                      <AddBoxIcon />
                      <p>Multiples</p>
                    </div>
                  </div>
                  <div className="col-sm-4 invoice-column text-center titles saldar-blue-box">
                    <p className="my-auto">Monto a saldar:</p>
                    <TextField
                      type="number"
                      className="w-75 mb-2"
                      variant="standard"
                      value={
                        selectedCredit.monto === 0
                          ? ""
                          : selectedCredit.monto.toFixed(5)
                      }
                      placeholder="Monto a saldar"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                        inputProps: { min: 0 },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      {selectedMethods.includes(1) ||
                      selectedMethods.includes(4) ? (
                        <div className="col-md">
                          <p>
                            <strong>Efectivo</strong>
                          </p>
                          <TextField
                            type="number"
                            className="w-100 my-2"
                            variant="standard"
                            step="any"
                            value={paidByCash === 0 ? "" : paidByCash}
                            name="paidByCash"
                            onChange={(e) => updateValue(e)}
                            placeholder="Monto pagado en efectivo"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {selectedMethods.includes(2) ||
                      selectedMethods.includes(4) ? (
                        <div className="col-md">
                          <p>
                            <strong>Tarjeta de débito/crédito</strong>
                          </p>
                          <TextField
                            className="w-100 my-2"
                            variant="standard"
                            type="number"
                            value={paidByCard === 0 ? "" : paidByCard}
                            name="paidByCard"
                            onChange={(e) => updateValue(e)}
                            placeholder="Monto pagado por tarjeta de débito/crédito"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {selectedMethods.includes(3) ? (
                        <div className="col-md">
                          <p>
                            <strong>Gift card</strong>
                          </p>
                          <TextField
                            type="number"
                            className="w-100 my-2"
                            variant="standard"
                            value={paidByGiftCard === 0 ? "" : paidByGiftCard}
                            name="paidByGiftCard"
                            onChange={(e) => updateValue(e)}
                            placeholder="Monto pagado por Gift Card"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {!selectedMethods.includes(5) &&
                      selectedMethods[0] !== 1 &&
                      selectedMethods[0] !== 0 ? (
                        <>
                          <div className="col-12 mt-3">
                            <p class="text-left my-auto">
                              <strong>Verificación del pago</strong>
                            </p>
                          </div>

                          {selectedMethods.includes(2) ||
                          selectedMethods.includes(4) ? (
                            <>
                              <div className="col-md-6 my-auto">
                                <p>
                                  <strong>
                                    Número del recibo (Operación con tarjeta)
                                  </strong>
                                </p>
                                <TextField
                                  className="w-100"
                                  variant="standard"
                                  value={invoice_number}
                                  name="invoice_number"
                                  onChange={(e) =>
                                    setInvoiceNumber(e.target.value)
                                  }
                                  placeholder="Ingresar numero de recibo"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        N°
                                      </InputAdornment>
                                    ),
                                  }}
                                  disabled={!check1}
                                />
                              </div>
                              <div className="col-md-6 my-auto">
                                <p>
                                  <strong>Número de cuotas</strong>
                                </p>

                                <Select
                                  native
                                  value={periods}
                                  onChange={changePeriods}
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  {payment_periods.map((period) => (
                                    <option value={period.value}>
                                      {period.option}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {selectedMethods.includes(3) ? (
                            <div className="col-md my-auto">
                              <p>
                                <strong>Ingrese el código de Gift Card</strong>
                              </p>
                              <TextField
                                className="w-100"
                                variant="standard"
                                value={giftcard_code}
                                onChange={(e) =>
                                  setGiftCardCode(e.target.value)
                                }
                                placeholder="     -      -      -"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {selectedMethods.includes(2) ||
                          selectedMethods.includes(4) ? (
                            <>
                              <div className="col-md-12">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="check1"
                                    checked={check1}
                                    onChange={toggleCheck}
                                  />
                                  <label
                                    class="form-check-label ml-2"
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    Ingresar manualmente
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 mt-5 pl-5">
                                <div className="row w-100 justify-content-center justify-content-md-start">
                                  <div className="col-md-1 text-center mb-2">
                                    <AddIcon
                                      style={{
                                        fontSize: "30px",
                                        color: "white",
                                      }}
                                      className="bg_secondary_color"
                                      onClick={addNewAdditionalInvoice}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <p className="mb-0">
                                      <strong>
                                        Número del recibo de pago para tarjeta
                                        adicional
                                      </strong>
                                    </p>
                                    <TextField
                                      className="w-100"
                                      variant="standard"
                                      value={additional_invoice}
                                      onChange={(e) =>
                                        setAdditionalInvoice(e.target.value)
                                      }
                                      placeholder="Ingrese el número del recibo emitido con una tarjeta adicional"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            N°
                                          </InputAdornment>
                                        ),
                                      }}
                                      disabled={!check2}
                                    />
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="check2"
                                        checked={check2}
                                        onChange={toggleCheck}
                                      />
                                      <label
                                        class="form-check-label ml-2"
                                        for="defaultCheck1"
                                        style={{
                                          fontSize: "0.8rem",
                                        }}
                                      >
                                        Ingresar manualmente
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row w-100 mt-2 justify-content-center justify-content-md-start">
                                  {additional_invoices.map((invoice) => (
                                    <div className="col-xl-4 px-1">
                                      <TextField
                                        className="w-100"
                                        variant="standard"
                                        disabled
                                        value={invoice}
                                        name="invoice_number"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              Recibo N°
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center px-5 py-3">
                <Button
                  className="btn-cs1 icon-btn-delete mx-1"
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  <span style={{ marginBottom: "-4px" }}>Cancelar</span>
                </Button>
                <Button
                  className="btn-cs1_blue mx-1"
                  variant="contained"
                  color="primary"
                  onClick={submitSaldar}
                >
                  {saldarFacturaLoader ? (
                    <div className="text-center">
                      <CircularProgress
                        size={18}
                        className="mx-auto"
                        style={{ color: "white" }}
                      />
                    </div>
                  ) : (
                    <span style={{ marginBottom: "-4px" }}>Guardar</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
