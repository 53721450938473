import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { CircularProgress } from "@material-ui/core";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import moment from "moment";

import { isCmi } from "../../helpers/isCmi";
import print from "print-js";
import { getMoneda } from "../../helpers/moneda";
import CorteDeCajaPdf from "./corteDeCajaPdf";

const generatePdf = async (name) => {
  const element = document.querySelector(".pdf-target");

  /* const options = {
		filename: name,
		overrideWidth: 1850
	};

	return domToPdf(element, options, () => {
		// callback function
	}); */
  window.html2canvas = html2canvas;
  const pdf = new jsPDF("p", "pt", "a4");
  pdf.html(element, {
    callback: function (doc) {
      pdf.save(name);
    },
    html2canvas: { scale: 0.475 },
  });
  /* 	await pdf.html(element, { html2canvas: { scale: 0.57 } })
    await pdf.save("YOYOYO.pdf"); */
};

const formaterPriceFunction = (value) => {
  return value
    .toFixed(2)
    .toString()
    .replace(/(?!\.)\D/g, "")
    .replace(/(?<=\..*)\./g, "")
    .replace(/(?<=\.\d\d).*/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const printInvoice = () => {
  print({
    printable: "printform",
    type: "html",
    targetStyles: ["*"],
  });
};
const printInvoice2 = () => {
  print({
    printable: "print-form",
    type: "html",
    targetStyles: ["*"],
  });
};

const ref = React.createRef();

export default function Users_all(props) {
  const CMI = isCmi();
  const moneda = getMoneda();
  const [loader, setLoader] = useState(true);
  const [sucursal, setSucursal] = useState({});
  const [caja, setCaja] = useState({});
  const [seller, setSeller] = useState({});
  const [corte_data, setCorte_data] = useState({});
  const [sales, setSales] = useState([]);
  const [products, setProducts] = useState([]);
  const [noData, setNoData] = useState(false);
  const [llevar, setLlevar] = useState({ total: 0, ventas: [] });
  const [comerLocal, setComerLocal] = useState({ total: 0, ventas: [] });
  const [domicilio, setDomicilio] = useState({ total: 0, ventas: [] });
  const [bigTotal, setBigTotal] = useState(0);
  const [retiros, setRetiros] = useState([]);
  const [totalGiftCard, setTotalGiftCard] = useState(0);
  const [orderItems, setOrderItems] = useState([]);
  const [totalAnticipos, setTotalAnticipos] = useState([]);
  const [isCorteZ, setIsCorteZ] = useState(false);
  useEffect(() => {
    if (!!localStorage.getItem("corte_details")) {
      let data = JSON.parse(localStorage.getItem("corte_details"));
      console.log("DATA RECEIVED FOR INVOICE:", data);
      setSucursal(data.sucursal);
      setCaja(data.caja);
      setSeller(data.vendedor);
      setCorte_data(data.corte_data);
      setSales(data.sales);
      setLoader(false);
      setLlevar(data.llevar);
      setComerLocal(data.comerLocal);
      setDomicilio(data.domicilio);
      setRetiros(data.retiros || []);
      setTotalGiftCard(data.total_gifcard);

      setTotalAnticipos(data.total_anticipos);
      setIsCorteZ(data.corteZ);
      let products = [];
      data.sales.map((sale) => {
        sale.orderItems.map((producto) => {
          products.push(producto);
        });
      });
      setProducts(products);
    } else {
      setNoData(true);
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    if (Object.values(corte_data).length > 0) {
      getGranTotal();
    }
  }, [corte_data]);

  const getGranTotal = () => {
    let bigTotal = (
      parseFloat(corte_data.total_efectivo) +
        parseFloat(corte_data.total_tarjetas) +
        parseFloat(corte_data.total_cambios) +
        parseFloat(totalGiftCard) || 0
    ).toFixed(2);

    setBigTotal(bigTotal);
  };

  const getSubtotales = (payload) => {
    return formaterPriceFunction(
      parseFloat(
        products
          .filter((product) =>
            product.flag_impuesto_honduras?.includes(payload)
          )
          .reduce((a, b) => a + b.monto_moneda, 0)
      )
    );
  };

  const totalRetiros = retiros.reduce((a, b) => a + b.cantidad_retiro, 0);

  const getFormaPago = (id) => {
    switch (id) {
      case 1:
        return "Efectivo";
      case 2:
        return "Tarjeta";
      case 3:
        return "Vale";
      case 4:
        return "Multiples";
      case 5:
        return "A crédito";
      case 6:
        return "Anticipo";
    }
  };

  const getTotalesCobrados = () => {
    // console.log("gift_card", totalGiftCard);
    // console.log("total_tarjetas", corte_data.total_tarjetas);
    // console.log("total_efectivo", corte_data.total_efectivo);
    // console.log("total_anticipos", totalAnticipos);
    return (
      parseFloat(totalGiftCard) +
      parseFloat(corte_data.total_tarjetas) +
      parseFloat(corte_data.total_efectivo) +
      parseFloat(totalAnticipos)
    );
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Recibo de corte de caja</strong>
        </h4>
      </div>

      <div className="content-card card mb-3">
        <div className="card-body">
          {noData ? (
            <div className="text-center">
              <p>
                Para ver el recibo debe provenir de la página de creación del
                corte de caja o del historial de cortes de caja.
              </p>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    generatePdf(
                      isCorteZ
                        ? "Reporte corte Z"
                        : "Corte de caja número " +
                            corte_data.id_caja_corte_web_app
                    );
                  }}
                >
                  Descargar PDF
                </button>

                <button
                  className="btn btn-primary ml-3"
                  onClick={() => {
                    printInvoice2();
                  }}
                >
                  Imprimir
                </button>
              </div>
              <CorteDeCajaPdf
                sucursal={sucursal}
                cmi={CMI}
                caja={caja}
                seller={seller}
                corte_data={corte_data}
                getFormaPago={getFormaPago}
                formaterPriceFunction={formaterPriceFunction}
                sales={sales}
                comerLocal={comerLocal}
                domicilio={domicilio}
                llevar={llevar}
                products={products}
                retiros={retiros}
                totalRetiros={totalRetiros}
                totalGiftCard={totalGiftCard}
                totalAnticipos={totalAnticipos}
                getTotalesCobrados={getTotalesCobrados}
                getSubtotales={getSubtotales}
                isCorteZ={isCorteZ}
              />
              <div className="d-flex justify-content-center">
                <div
                  style={{
                    border: "1px solid gray",
                    width: "1240px",
                    overflowX: "scroll",
                  }}
                  className="mt-4"
                >
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <div
                      className="pdf-target px-4 py-3"
                      ref={ref}
                      id="printform"
                    >
                      {!CMI && (
                        <>
                          <h4 className="mb-0">{sucursal.nombre_sucursal}</h4>
                          <h5 className="mb-2">
                            {sucursal.direccion_sucursal}
                          </h5>
                          <h5 className="mb-0">{caja.descripcion}</h5>
                          <div className="text-center">
                            <h5 className="mb-0">
                              {!isCorteZ ? <strong>Usuario:</strong> : <></>}
                            </h5>
                            <h5 className="mb-0">{seller?.nombre}</h5>
                          </div>
                          <h5 className="mb-0">
                            <strong>
                              CORTE DE CAJA {corte_data.id_caja_corte_web_app}
                            </strong>
                          </h5>
                        </>
                      )}
                      {CMI && (
                        <>
                          <div className="row">
                            <div className={`col-12 text-center`}>
                              <p className="mb-0">
                                <strong>{sucursal.nombre_sucursal}</strong>
                              </p>
                              <p className="mb-0">
                                {!isCorteZ ? (
                                  <strong>Vendedor: {seller?.nombre}</strong>
                                ) : (
                                  <></>
                                )}
                              </p>
                              {/* <p className="mb-0">NIT: 7879036-0</p> */}
                              <p>{sucursal.direccion_sucursal}</p>
                              {/* <p>Tel.- {sucursal.telefono_sucursal}</p> */}

                              {/* <p className="mb-0">
                                <strong>
                                  **DOCUMENTO TRIBUTARIO ELECTRONICO**
                                </strong>
                              </p> */}

                              {/* <p className="mb-0">
                                NUMERO DE AUTORIZACION: <br /> FA1181981278
                              </p> */}
                              <p className="mb-0">
                                <strong>FACTURA</strong>
                              </p>
                              {/* 
                              <p className="mb-0">
                                <strong>
                                  SERIE: "5EF8F90A"
                                  <br />
                                  Número: "3710536805"
                                </strong>
                              </p> */}

                              <strong>
                                CORTE DE FACTURACIÓN DIARIA{" "}
                                {isCorteZ ? "Z" : "Y"}
                              </strong>

                              {/*{felData.id_dte ? (
								
												) : (
													""
												)}*/}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="divider" />
                      <div className="row">
                        <div className="col-2">
                          <span className="ml-3">Vendedor</span>
                        </div>
                        <div className="col-2">Serie</div>
                        <div className="col-2">Documento</div>
                        <div className="col-2">Fecha</div>
                        <div className="col-1">Cliente</div>
                        <div className="col-2">Forma pago</div>
                        <div className="col-1 text-right">Total</div>
                      </div>
                      <div className="divider" />
                      {sales.map((sale) => (
                        <div className="row">
                          <div className="col-2">
                            <span className="ml-3">{sale.vendedor}</span>
                          </div>
                          <div className="col-2">{sale.serie}</div>
                          <div className="col-2">{sale.documento}</div>
                          <div className="col-2">
                            {moment(sale.fecha)
                              .utcOffset(0)
                              .format("DD/MM/YYYY")}
                          </div>
                          <div className="col-1">{sale.client.nombre}</div>
                          <div className="col-2">
                            {getFormaPago(sale.id_forma_pago)}
                          </div>
                          <div className="col-1 text-right">
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              parseFloat(sale.monto_total)
                            )}
                          </div>
                          <div className="col-12">
                            <br />
                          </div>
                        </div>
                      ))}
                      <div className="divider" />

                      <div className="divider" />
                      <div className="row">
                        <div className="col-5" />
                        <div className="col-3">
                          <h5 className="mb-0">
                            <strong>GRAN TOTAL</strong>
                          </h5>
                        </div>
                        <div className="col-4 text-right">
                          <h5 className="mb-0">
                            <strong>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                parseFloat(
                                  sales.reduce((a, b) => a + b.monto_total, 0)
                                )
                              )}
                            </strong>
                          </h5>
                        </div>
                      </div>
                      <div className="divider" />
                      <p className="mb-2">
                        <strong>{corte_data.fecha}</strong>
                      </p>
                      <div className="row mb-3">
                        <div className="col-5">
                          <strong>FACTURAS EMITIDAS </strong>
                        </div>
                        <div className="col-1">
                          <strong>{sales.length}</strong>
                        </div>
                        <div className="col-5">
                          <strong>FACTURAS ANULADAS </strong>
                        </div>
                        <div className="col-1">
                          <strong>{corte_data.ventasAnuladas}</strong>
                        </div>
                        <div className="col-5">
                          {!CMI ? (
                            <strong>N° DE VENTAS CONSUMO LOCAL </strong>
                          ) : (
                            <strong>N° DE VENTAS PICKUP</strong>
                          )}
                        </div>
                        <div className="col-1">
                          <strong>{comerLocal.ventas}</strong>
                        </div>
                        <div className="col-5">
                          {!CMI ? (
                            <strong>N° DE VENTAS A DOMICILIO </strong>
                          ) : (
                            <strong>N° DE VENTAS ENTREGA</strong>
                          )}
                        </div>
                        <div className="col-1">
                          <strong>{domicilio.ventas}</strong>
                        </div>
                        <div className="col-5">
                          {!CMI ? (
                            <strong>N° DE VENTAS PARA LLEVAR </strong>
                          ) : (
                            <strong>N° DE VENTAS POR PRESENCIAL</strong>
                          )}
                        </div>
                        <div className="col-1">
                          <strong>{llevar.ventas}</strong>
                        </div>
                        <div className="col-5">
                          <strong>N° DE NOTAS DE CRÉDITO</strong>
                        </div>
                        <div className="col-1">
                          <strong>{corte_data.no_notas_credito}</strong>
                        </div>
                        <div className="col-6"></div>
                        <div className="col-5">
                          <strong>N° DE NOTAS DE DEBITO</strong>
                        </div>
                        <div className="col-1">
                          <strong>{corte_data.no_notas_debito}</strong>
                        </div>
                        <div className="col-6"></div>
                        {CMI && !isCorteZ && (
                          <>
                            <div className="col-5">
                              <strong>FONDO PARA CAMBIOS</strong>
                            </div>

                            <div className="col-1">
                              <strong>
                                {moneda}.{" "}
                                {formaterPriceFunction(
                                  parseFloat(corte_data.total_cambios)
                                )}
                              </strong>
                            </div>
                          </>
                        )}
                      </div>
                      <h5 className="mb-0 mt-2">
                        <strong>RESUMEN DE PRODUCTOS VENDIDOS</strong>
                      </h5>
                      <div className="divider" />
                      <div className="row">
                        <div className="col-6">
                          <span className="ml-3">Item</span>
                        </div>
                        <div className="col-3 text-center">Total Unidades</div>
                        <div className="col-3 text-right">Monto</div>
                      </div>
                      <div className="divider" />

                      {products.map((product) => (
                        <div className="row">
                          <div className="col-6">
                            <span className="ml-3">{product.nombre}</span>
                          </div>
                          <div className="col-3 text-center">
                            {product.cantidad}
                          </div>
                          <div className="col-3 text-right">
                            {formaterPriceFunction(
                              parseFloat(product.monto_moneda)
                            )}
                          </div>
                        </div>
                      ))}

                      <div className="divider" />
                      <div className="row">
                        <div className="col-6" />
                        <div className="col-3 text-center">
                          <strong>TOTAL</strong>
                        </div>
                        <div className="col-3 text-right">
                          <strong>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              parseFloat(
                                products.reduce((a, b) => a + b.monto_moneda, 0)
                              )
                            )}
                          </strong>
                        </div>
                      </div>
                      {isCmi() && (
                        <>
                          <div className="divider" />
                          <h5 className="mb-0 mt-2">
                            <strong>RESUMEN DE RETIROS DE EFECTIVO</strong>
                          </h5>
                          <div className="divider" />
                          <div className="row">
                            <div className="col-3">
                              <span className="ml-3">N° de retiro</span>
                            </div>
                            <div className="col-3">Nombre</div>
                            <div className="col-3 text-center">Fecha</div>
                            <div className="col-3 text-right">Monto</div>
                          </div>
                          <div className="divider" />
                          {!retiros.length ? "Sin datos." : ""}
                          {retiros.map((retiro, index) => (
                            <div className="row">
                              <div className="col-3">
                                <span className="ml-3">{index + 1}</span>
                              </div>
                              <div className="col-3">
                                <span>
                                  {retiro.nombre_usuario
                                    ? retiro.nombre_usuario
                                    : retiro.nombre}
                                </span>
                              </div>
                              <div className="col-3 text-center">
                                {moment(retiro.retiro_fecha)
                                  .utcOffset(0)
                                  .format("DD/MM/YYYY h:mm:ss a")}
                              </div>
                              <div className="col-3 text-right">
                                {retiro.cantidad_retiro.toFixed(2)}
                              </div>
                            </div>
                          ))}
                          <div className="divider" />
                          <div className="row">
                            <div className="col-6" />
                            <div className="col-3 text-center">
                              <strong>TOTAL</strong>
                            </div>
                            <div className="col-3 text-right">
                              <strong>
                                {moneda}.{" "}
                                {formaterPriceFunction(
                                  parseFloat(totalRetiros)
                                )}
                              </strong>
                            </div>
                          </div>
                          <div className="divider" />
                          <div className="text-center mt-3">
                            <strong>ANULACIONES</strong>
                          </div>
                          <div className="divider" />
                          <div className="row">
                            <div className="col-2">
                              <span className="ml-3">Vendedor</span>
                            </div>
                            <div className="col-2">Serie</div>
                            <div className="col-2">Documento</div>
                            <div className="col-2">Fecha</div>
                            <div className="col-1">Cliente</div>
                            <div className="col-2">Forma pago</div>
                            <div className="col-1 text-right">Total</div>
                          </div>
                          <div className="divider" />
                          {!corte_data.anulaciones.length ? "Sin datos." : ""}
                          {corte_data.anulaciones.map((sale) => (
                            <div className="row">
                              <div className="col-2">
                                <span className="ml-3">{sale.vendedor}</span>
                              </div>
                              <div className="col-2">{sale.serie}</div>
                              <div className="col-2">{sale.documento}</div>
                              <div className="col-2">
                                {moment(sale.fecha)
                                  .utcOffset(0)
                                  .format("DD/MM/YYYY")}
                              </div>
                              <div className="col-1">{sale.client.nombre}</div>
                              <div className="col-2">
                                {getFormaPago(sale.id_forma_pago)}
                              </div>
                              <div className="col-1 text-right">
                                {moneda}.{" "}
                                {formaterPriceFunction(
                                  parseFloat(sale.monto_total)
                                )}
                              </div>
                              <div className="col-12">
                                <br />
                              </div>
                            </div>
                          ))}
                          <div className="divider" />
                          <div className="row">
                            <div className="col-6" />
                            <div className="col-3 text-center">
                              <span>
                                <strong>TOTAL ANULADO</strong>
                              </span>
                            </div>
                            <div className="col-3 text-right">
                              <span>
                                <strong>
                                  {moneda}.{" "}
                                  {formaterPriceFunction(
                                    parseFloat(
                                      corte_data.anulaciones.reduce(
                                        (a, b) => a + b.monto_total,
                                        0
                                      )
                                    )
                                  )}
                                </strong>
                              </span>
                            </div>
                          </div>

                          <div className="divider" />
                        </>
                      )}
                      <div className="row">
                        <div className="col-6">
                          <h5 className="mb-0 mt-4">
                            <strong>
                              {" "}
                              {!CMI
                                ? "RESUMEN FORMA DE PAGO"
                                : "TOTALES COBRADOS"}
                            </strong>
                          </h5>
                        </div>

                        <div className="col-6 d-flex justify-content-between">
                          <span className="mb-0 mt-4">
                            <strong>TOTAL EFECTIVO EN CAJA</strong>
                          </span>
                          <span className="mb-0 mt-4">
                            <strong>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                parseFloat(
                                  corte_data.total_efectivo +
                                    corte_data.total_cambios -
                                    totalRetiros
                                )
                              )}
                            </strong>
                          </span>
                        </div>
                        <div className="col-6" />
                        <div className="col-6 d-flex justify-content-between">
                          <span className="mb-0 mt-1">
                            <strong>TOTAL CRÉDITO</strong>
                          </span>
                          <span className="mb-0 mt-1">
                            <strong>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                parseFloat(corte_data.total_facturas_credito)
                              )}{" "}
                            </strong>
                          </span>
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="row">
                        <div className="col-5" />
                        <div className="col-4">
                          <strong>TOTAL EFECTIVO</strong>
                        </div>
                        <div className="col-3 text-right">
                          <strong>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              parseFloat(corte_data.total_efectivo)
                            )}
                          </strong>
                        </div>

                        <div className="col-5" />
                        <div className="col-4">
                          <strong>TOTAL TARJETAS</strong>
                        </div>
                        <div className="col-3 text-right">
                          <strong>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              parseFloat(corte_data.total_tarjetas)
                            ) || "0.00"}
                          </strong>
                        </div>

                        <div className="col-5" />
                        <div className="col-4">
                          <strong>TOTAL VALE</strong>
                        </div>
                        <div className="col-3 text-right">
                          <strong>
                            {moneda}.{" "}
                            {formaterPriceFunction(parseFloat(totalGiftCard))}
                          </strong>
                        </div>
                        <div className="col-5" />
                        <div className="col-4">
                          <strong>TOTAL ANTICIPOS</strong>
                        </div>
                        <div className="col-3 text-right">
                          <strong>
                            {moneda}.{" "}
                            {formaterPriceFunction(parseFloat(totalAnticipos))}
                          </strong>
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="row">
                        <div className="col-5" />
                        <div className="col-4">
                          <strong>GRAN TOTAL</strong>
                        </div>
                        <div className="col-3 text-right">
                          {/* <strong>{corte_data.ventas_brutas.toFixed(2)}</strong> */}
                          <strong>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              parseFloat(getTotalesCobrados())
                            )}
                          </strong>
                        </div>
                      </div>
                      <div className="divider mb-4" />

                      <h5 className="mb-0">
                        <strong>
                          {" "}
                          {!CMI ? "RESUMEN" : "TOTALES FACTURADOS"}
                        </strong>
                      </h5>
                      <div className="divider" />
                      <div className="row">
                        <div className="col-5" />
                        <div className="col-4">
                          <strong>VENTAS BRUTAS</strong>
                        </div>
                        <div className="col-3 text-right">
                          <strong>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              parseFloat(
                                sales.reduce((a, b) => a + b.monto_total, 0)
                              )
                            )}
                          </strong>
                        </div>
                      </div>

                      {/* <div className="row">
								<div className="col-5" />
								<div className="col-4">
									<strong>Descuentos</strong>
								</div>
								<div className="col-3 text-right">
									<strong>0.00</strong>
								</div>
							</div> */}

                      {/* <div className="row">
								<div className="col-5" />
								<div className="col-4">
									<strong>Rebajas</strong>
								</div>
								<div className="col-3 text-right">
									<strong>0.00</strong>
								</div>
							</div> */}

                      {/* <div className="row">
								<div className="col-5" />
								<div className="col-4">
									<strong>Devoluciones</strong>
								</div>
								<div className="col-3 text-right">
									<strong>0.00</strong>
								</div>
							</div> */}

                      <div className="row">
                        <div className="col-5" />
                        <div className="col-4">
                          <strong>VENTAS NETAS</strong>
                        </div>
                        <div className="col-3 text-right">
                          <strong>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              parseFloat(
                                sales.reduce((a, b) => a + b.monto_total, 0) -
                                  sales.reduce((a, b) => a + b.monto_total, 0) *
                                    (corte_data.iva / 100)
                              )
                            )}
                          </strong>
                        </div>
                      </div>

                      <div className="row">
                        {moneda !== "L" ? (
                          <>
                            <div className="col-5" />
                            <div className="col-4">
                              <strong>IVA({corte_data.iva}%)</strong>
                            </div>
                            <div className="col-3 text-right">
                              <strong>
                                {moneda}.{" "}
                                {formaterPriceFunction(
                                  parseFloat(
                                    (corte_data.iva / 100) *
                                      sales.reduce(
                                        (a, b) => a + b.monto_total,
                                        0
                                      )
                                  )
                                )}
                              </strong>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-5" />
                            <div className="col-4">
                              <strong>TOTAL EXENTO</strong>
                            </div>
                            <div className="col-3 text-right">
                              <strong>L{getSubtotales("Exento")}</strong>
                            </div>
                            <div className="col-5" />
                            <div className="col-4">
                              <strong>TOTAL EXONERADO</strong>
                            </div>
                            <div className="col-3 text-right">
                              <strong>L{getSubtotales("Exonerado")}</strong>
                            </div>
                            <div className="col-5" />
                            <div className="col-4">
                              <strong>ISV 15%</strong>
                            </div>
                            <div className="col-3 text-right">
                              <strong>L{getSubtotales("15%")}</strong>
                            </div>
                            <div className="col-5" />
                            <div className="col-4">
                              <strong>ISV 18%</strong>
                            </div>
                            <div className="col-3 text-right">
                              <strong>L{getSubtotales("18%")}</strong>
                            </div>
                          </>
                        )}
                      </div>
                      {!CMI && !isCorteZ && (
                        <div className="row">
                          <div className="col-5" />
                          <div className="col-4">
                            <strong>IDP</strong>
                          </div>
                          <div className="col-3 text-right">
                            <strong>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                parseFloat(corte_data.monto_idp)
                              )}
                            </strong>
                          </div>
                        </div>
                      )}
                      <span>
                        <strong>Realizado por: </strong>
                      </span>
                      {corte_data.usuario_corte}
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="row d-flex justify-content-around">
                        <div
                          style={{ width: "200px" }}
                          className="d-flex flex-wrap"
                        >
                          <hr
                            className="w-100"
                            style={{ borderTop: "1px solid black" }}
                          />
                          Jefe de sala
                        </div>
                        <div
                          style={{ width: "200px" }}
                          className="d-flex flex-wrap"
                        >
                          <hr
                            className="w-100"
                            style={{ borderTop: "1px solid black" }}
                          />
                          Cajero
                        </div>
                      </div>

                      {/* <div className="divider" />
							<h5 className="mb-0">MONTOS DEJADOS EN CAJA</h5>
							<div className="divider" />
							<div className="row">
								<div className="col-5" />
								<div className="col-4">
									<strong>MONTO ANTERIOR</strong>
								</div>
								<div className="col-3 text-right">
									<strong>0.00</strong>
								</div>
							</div>

							<div className="row">
								<div className="col-5" />
								<div className="col-4">
									<strong>MONTO ESTE CORTE</strong>
								</div>
								<div className="col-3 text-right">
									<strong>200.00</strong>
								</div>
							</div> */}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
