import { base_url, axios } from "../api";

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getSummaryInformesAleko = async (idEmpresa, idSucursak, fecha1, fecha2) =>
  axios.get(
    `${base_url}/web/aleko/pedidos/informes?id_new_empresa=${idEmpresa}&id_sucursal_bodega_ubicacion=${idSucursak}&filter=general&fecha_inicio=${fecha1}&fecha_final=${fecha2}`,
    { headers: headers() }
  );

const getInforme = async () => {
  return await axios.get(`${base_url}/web/aleko/pedidos/informes`, {
    headers: headers(),
    params: {},
  });
};

const getFacturacionInformesAleko = async (
  idEmpresa,
  idSucursal,
  fechaInicial,
  fechaFunal,
  idVendedor
) => {
  return await axios.get(
    `${base_url}/web/aleko/pedidos/informes?id_new_empresa=${idEmpresa}&id_sucursal_bodega_ubicacion=${idSucursal}&fecha_inicial_facturacion=${fechaInicial}&fecha_final_facturacion=${fechaFunal}&id_vendedor_web_app=${idVendedor}&filter=facturacion`,
    { headers: headers() }
  );
};

const getInformesProductos = async (idEmpresa, idSucursal, month_limit = 3) => {
  return await axios.get(
    `${base_url}/web/aleko/pedidos/informes?id_new_empresa=${idEmpresa}&id_sucursal_bodega_ubicacion=${idSucursal}&month_limit=${month_limit}&filter=productos`,
    { headers: headers() }
  );
};

const getInformesTiendas = async (idEmpresa, idSucursal, month_limit = 3) => {
  return await axios.get(
    `${base_url}/web/aleko/pedidos/informes?id_new_empresa=${idEmpresa}&id_sucursal_bodega_ubicacion=${idSucursal}&month_limit=${month_limit}&filter=sucursales`
  );
};

const getInformesEmpleados = async (idEmpresa, idSucursal, month_limit = 3) => {
  return await axios.get(
    `${base_url}/web/aleko/pedidos/informes?id_new_empresa=${idEmpresa}&id_sucursal_bodega_ubicacion=${idSucursal}&month_limit=${month_limit}&filter=empleados`
  );
};

export default {
  getSummaryInformesAleko,
  getInforme,
  getFacturacionInformesAleko,
  getInformesProductos,
  getInformesTiendas,
  getInformesEmpleados,
};
