import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Autosuggest from "react-autosuggest";
import SearchIcon from "@material-ui/icons/Search";
import { useForm } from "react-hook-form";
import API from "../../../api";

import { Link } from "react-router-dom";
import { realImageURL, base_url_images } from "../../../api";
import { toast } from "react-toastify";
import {
  deleteProductSupplie,
  editProductSupplie,
  getIdReceta,
  getProductSupplies,
  saveProductSupplie,
} from "../../../api/supplies";
import Supplie from "./supplie";
import { CircularProgress } from "@material-ui/core";

const Insumos = ({
  productoCompuesto,
  setProductoCompuesto,
  product,
  submitLoader,
}) => {
  const AutosuggestHighlightMatch = require("autosuggest-highlight/match");
  const AutosuggestHighlightParse = require("autosuggest-highlight/parse");

  const [itemQuantity, setItemQuantity] = useState(1);
  const [categories, setCategories] = useState([]);
  const [empaques, setEmpaques] = useState([]);
  const [selectedSupplies, setSelectedSupplies] = useState([]);

  const [foundSupply, setFoundSupply] = useState(null);

  const [categoriesFilterText, setCategoriesFilterText] = useState("");

  const [suppliesSuggestions, setSuppliesSuggestions] = useState([]);
  const [allSupplies, setAllSupplies] = useState([]);
  const [supplyFilterText, setSupplyFilterText] = useState("");
  const [selected_products, setSelectedProducts] = useState([]);
  const [idReceta, setIdReceta] = useState(null);

  useEffect(() => {
    API.products
      .getProductAttributes({})
      .then((res) => {
        let response = res.data;
        console.log(response.data);
        if (response.success) {
          getSupplies();
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  }, []);

  const getSuggestions = (type) => {
    if (type === "category") {
      const inputValue = document
        .getElementById("category_query")
        .value.trim()
        .toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0
        ? []
        : categories
            .filter((category) =>
              category.descripcion.toLowerCase().includes(inputValue)
            )
            .slice(0, 5);
    } else if (type === "empaque") {
      const inputValue = document
        .getElementById("empaque_query")
        .value.trim()
        .toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0
        ? []
        : empaques
            .filter((empaque) =>
              empaque.descripcion.toLowerCase().includes(inputValue)
            )
            .slice(0, 5);
    } else if (type === "insumo") {
      const inputValue = document
        .getElementById("supply_query")
        .value.trim()
        .toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0
        ? []
        : allSupplies
            .filter(function (insumo) {
              if (insumo.nombre.toLowerCase().includes(inputValue)) {
                return insumo;
              }
              if (insumo.codigo.toLowerCase().includes(inputValue)) {
                return insumo;
              }
            })
            .slice(0, 5);
    }
  };

  const getSuppliesSuggestionValue = (suggestion) => {
    setFoundSupply(suggestion);
    return suggestion.nombre;
  };

  const renderSupplySuggestion = (suggestion) => {
    const matches = AutosuggestHighlightMatch(
      suggestion.nombre,
      categoriesFilterText
    );

    const parts = AutosuggestHighlightParse(
      suggestion.nombre + " - " + suggestion.codigo,
      matches
    );

    return (
      <div>
        {parts.map((part, index) => {
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  const changeCategoryFilterText = (e, { newValue }) => {
    setCategoriesFilterText(newValue);
  };

  const changeSupplyFilterText = (e, { newValue }) => {
    setSupplyFilterText(newValue);
  };

  const onSuggestionsFetchRequested = (type) => {
    if (type === "insumo") {
      setSuppliesSuggestions(getSuggestions("insumo"));
    }
  };

  const onSuggestionsClearRequested = (type) => {
    if (type === "insumo") {
      setSuppliesSuggestions([]);
    }
  };

  const suppliesInputProps = {
    placeholder: "Ingrese el SKU del insumo.",
    value: supplyFilterText,
    onChange: changeSupplyFilterText,
    id: "supply_query",
  };

  const getSupplies = () => {
    let id_sucursal_bodega_ubicacion = JSON.parse(
      localStorage.getItem("id_sucursal")
    );
    API.supplies
      .getAll({
        id_sucursal_bodega_ubicacion,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Im obtaining these insumos:", response.data);
          setAllSupplies(response.data.insumos);
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        console.log("Ha ocurrido un error / Búsqueda de insumos");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const unselectProduct = () => {
    setFoundSupply(null);
  };
  const selectProduct = async () => {
    if (!idReceta || !productoCompuesto) {
      return toast.warn(
        "Antes de crear un insumo asegúrese de presionar el boton actualizar y activar la bandera",
        { autoClose: 10000 }
      );
    }
    let already_selected = [...selected_products];
    let currentIndex = already_selected.findIndex(
      (x) => x.id_new_item === foundSupply.id_new_item
    );

    if (already_selected[currentIndex]) {
      toast.error("El insumo ya fue seleccionado para el producto.", {
        autoClose: 10000,
      });
      return;
    }
    const form_data = new FormData();
    form_data.append(
      "insumos",
      JSON.stringify({ ...foundSupply, cantidad: itemQuantity })
    );
    form_data.append("id_new_receta", idReceta);
    form_data.append("id_new_item", product.id_new_item);
    try {
      const { data } = await saveProductSupplie(form_data);
    } catch (error) {
      console.log(error);
    }

    already_selected.push({
      ...foundSupply,
      cantidad: parseFloat(itemQuantity),
    });
    setSelectedProducts(already_selected);
    setItemQuantity(1);
    setFoundSupply(null);
  };

  const editSupplie = async () => {
    if (!selectedSupplies.length) return;

    try {
      const form_data = new FormData();

      form_data.append("id_new_receta", idReceta);
      form_data.append("idInsumo", selectedSupplies[0].id_new_item);
      form_data.append("cantidadInsumo", selectedSupplies[0].cantidad);

      await editProductSupplie(form_data);
      const { data } = await getProductSupplies(product.id_new_item);
      setSelectedProducts(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSupplieUpdate = (data) => {
    setSelectedSupplies([data]);
  };

  const remove_input = async (i, id_insumo, id_receta) => {
    setSelectedProducts(selected_products.filter((e, index) => index !== i));
    await deleteProductSupplie({
      id_receta,
      id_insumo,
    });
  };

  useEffect(() => {
    const getInsumos = async () => {
      try {
        const { data } = await getProductSupplies(product.id_new_item);
        const id = await getIdReceta(product.id_new_item);
        setIdReceta(id.data.data.id_new_receta);
        if (!data.ok) return;
        setSelectedProducts(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getInsumos();
  }, [product, submitLoader]);

  return (
    <div className="row mb-4">
      <div className="col-md-6 mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Producto compuesto</strong>
            </label>
            <p className="mb-0" style={{ fontSize: "0.8rem" }}>
              Activa esta sección únicamente cuando su producto contenga uno o
              más insumos de su almacén.
            </p>
          </div>
          <div className="custom-control custom-switch">
            <label
              class="toggle-control mx-auto"
              style={{ left: "-15px", height: "40px" }}
            >
              <input
                type="checkbox"
                checked={productoCompuesto}
                onClick={() => setProductoCompuesto(!productoCompuesto)}
              />
              <span class="control" />
            </label>
          </div>
        </div>
      </div>
      <div className="col-md-6 text-right">
        <Button
          className="btn-cs1 mt-1 mx-1"
          variant="contained"
          color="primary"
          type="submit"
        >
          {submitLoader ? (
            <div className="text-center">
              <CircularProgress
                size={26}
                className="mx-auto"
                style={{ color: "white" }}
              />
            </div>
          ) : (
            "Actualizar"
          )}
        </Button>
      </div>

      <div className="col-md-4 mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Selección de insumos</strong>
            </label>
            <p className="mb-0" style={{ fontSize: "0.8rem" }}>
              Seleccione los insumos requeridos para la creación de este
              producto. Tenga en cuenta que los insumos deben estar previamente
              registrados.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6 my-auto">
        <div
          className="input-group search-bar autosuggest-search-bar mx-auto"
          style={{ width: "fit-content" }}
        >
          <Autosuggest
            suggestions={suppliesSuggestions}
            onSuggestionsFetchRequested={() =>
              onSuggestionsFetchRequested("insumo")
            }
            onSuggestionsClearRequested={() =>
              onSuggestionsClearRequested("insumo")
            }
            getSuggestionValue={getSuppliesSuggestionValue}
            renderSuggestion={renderSupplySuggestion}
            inputProps={suppliesInputProps}
          />
          <div className="input-group-append">
            <span className="input-group-text autosuggest-search-icon">
              <SearchIcon />
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-2 my-auto text-right">
        <Button
          type="submit"
          className="btn-cs1_blue mx-1 mt-1"
          variant="contained"
          color="primary"
          component={Link}
          to="/app/inventario/add_supply"
        >
          Crear insumo
        </Button>
      </div>
      {selected_products.length > 0 ? (
        <div className="col-12">
          {selected_products.map((input, index) => (
            <Supplie
              input={input}
              index={index}
              remove_input={remove_input}
              editSupplie={editSupplie}
              handleSupplieUpdate={handleSupplieUpdate}
            />
          ))}
        </div>
      ) : (
        ""
      )}

      {foundSupply ? (
        <>
          <div className="col-md-3 d-flex justify-content-between">
            <div className="d-flex align-items-end">
              <Button
                className="btn-cs1_blue mt-1"
                variant="contained"
                color="primary"
                onClick={selectProduct}
                type="button"
              >
                Guardar
              </Button>
            </div>
            <div className="d-flex align-items-end">
              <Button
                className="btn-cs1 mt-1 delete-btn"
                variant="contained"
                color="primary"
                onClick={unselectProduct}
                type="button"
              >
                Cancelar
              </Button>
            </div>
          </div>
          <div
            className="col-md-9 text-center my-auto p-5"
            style={{ backgroundColor: "#F2F2F2" }}
          >
            <div className="row">
              <div className="col-lg-4">
                <img
                  src={
                    foundSupply.imagen_1
                      ? realImageURL(foundSupply.imagen_1)
                      : base_url_images + "images/placeholder-image.jpeg"
                  }
                  className="combo-product-image"
                  alt="Cloud POS - Insumo"
                />
              </div>
              <div className="col-lg-4">
                <p>
                  <strong>{foundSupply.nombre}</strong>
                </p>

                <p>SKU: {foundSupply.codigo}</p>
                <p>Stock: {foundSupply.stock}</p>
                <p>Unidad/Medida: {foundSupply.unidad}</p>
              </div>
              <div className="col-lg-4">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Cant. utilizada por producto</strong>
                </label>
                <TextField
                  type="number"
                  className="w-100"
                  value={itemQuantity}
                  onChange={(event) =>
                    setItemQuantity(
                      event.target.value < 0
                        ? (event.target.value = -event.target.value)
                        : event.target.value
                    )
                  }
                  placeholder="Ingrese la cantidad"
                  InputProps={{
                    inputProps: { min: 0, step: "0.00001" },
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Insumos;
