import React, { useState, useEffect, useRef, useContext } from "react";
// Icons
import {
  CloudDownload,
  Search,
  ArrowForwardIos,
  ExpandMore,
  ExpandLess,
} from "@material-ui/icons/";
import { CircularProgress } from "@material-ui/core";
// Styled Components
import styled from "styled-components";
import {
  FlexContainer,
  GridContainer,
  BigTitle,
  BackButton,
  Input,
  ContinueButton,
  Select,
} from "../../styled/styled.components";

// Components
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getMoneda } from "../../../helpers/moneda";
import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { saveActualOrder } from "./helpers/handleLocalOrders";
import { getStatusListOrder } from "../../../api/sales";
import { getIdEmpresa } from "./helpers/handleLocalOrders";
import api from "../../../api";
import { toast } from "react-toastify";

const StyledSelect = styled(Select)`
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.06);
  border: 0.5px solid rgba(189, 189, 189, 1);
  padding: 8px 20px;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
`;
const StyledTableCell = styled(TableCell)`
  color: white;
  font-weight: 700;
  cursor: pointer;
  background-color: rgba(1, 96, 231, 1);
`;

const formaterPriceFunction = (value) => {
  return value
    .toFixed(2)
    .toString()
    .replace(/(?!\.)\D/g, "")
    .replace(/(?<=\..*)\./g, "")
    .replace(/(?<=\.\d\d).*/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const moneda = getMoneda();

const Head = ({
  ordersResumen,
  sucursals,
  sellers,
  filterChangeOrders,
  dateRange,
  filterChangeDateOrders,
  loading,
}) => {
  const [listStatusOrder, setlistStatusOrder] = useState([]);
  const [actualSeller, setActualSeller] = useState({});

  useEffect(() => {
    getStatusOfOrder();
  }, []);

  // useEffect(() => {
  //   const filter = currentOrders.filter((a) => {
  //     if (a.fecha_plazo) {
  //       let dateSplited = a.fecha_plazo.split("T")[0];
  //       const date = dateSplited.split("-");
  //       const year = date[0];
  //       const month = date[1];
  //       const day = date[2];
  //       const formatDate = new Date(`${month}/${day}/${year}`);

  //       const startDate = new Date(dateRange[0]);
  //       const finishDate = new Date(dateRange[1]);

  //       if (
  //         (formatDate.getTime() > startDate.getTime()) &
  //         (formatDate.getTime() < finishDate.getTime())
  //       )
  //         return true;
  //       return false;
  //     }
  //   });

  //   setAllOrders(filter.length > 0 ? filter : currentOrders);
  // }, [dateRange]);

  // const handleSelectChange = (column, value) => {
  //   const filter = currentOrders.filter((a) => a[column] == value);
  //   setAllOrders(value != "" ? filter : currentOrders);
  // };

  // const handleSellers = (value) => {
  //   let actual = sellers.filter(
  //     (item) => item.id_vendedor_web_app === Number(value)
  //   );
  //   setActualSeller(actual[0]);
  // };

  // const handleChangeFilterOrder = (value) => {
  //   let idStatusOrder = value;
  //   let idSeller = actualSeller?.id_vendedor_web_app
  //     ? actualSeller.id_vendedor_web_app
  //     : sellers[0].id_vendedor_web_app;
  // };

  const getStatusOfOrder = async () => {
    const idEmpresa = getIdEmpresa();
    const response = await getStatusListOrder(idEmpresa);
    // console.log("status ->", response.data.data);
    setlistStatusOrder(response.data.data);
  };

  // console.log("ordersResumen", ordersResumen);

  return (
    <>
      <BigTitle gridColumn="1/-1" fontSize="20px !important" fontWeight="700">
        Órdenes de trabajo
      </BigTitle>
      <FlexContainer
        margin="10px"
        columnGap="10px"
        rowGap="10px"
        gridColumn="1/-1"
      >
        <StyledSelect name="sucursals" onChange={filterChangeOrders}>
          {sucursals.map((sucursal, i) => (
            <option kye={i} value={sucursal.id_sucursal_bodega_ubicacion}>
              {sucursal.nombre_sucursal}
            </option>
          ))}
        </StyledSelect>

        <StyledSelect name="status" onChange={filterChangeOrders}>
          <option value={0}>Todos los estados</option>
          {listStatusOrder.map((status, i) => (
            <option key={i} value={status.id_estado_pedido}>
              {status.nombre_estado_pedido}
            </option>
          ))}
        </StyledSelect>

        <StyledSelect name="cashiers" onChange={filterChangeOrders}>
          <option value={0}>Todos los cajeros</option>
          {sellers.map((seller, i) => (
            <option key={i} value={seller.id_vendedor_web_app}>
              {seller.nombre}
            </option>
          ))}
        </StyledSelect>

        <DateRangePicker
          name="ordersDate"
          onChange={(ev) => filterChangeDateOrders(ev)}
          value={dateRange}
          format={"y-MM-dd"}
          className="date-picker-cs px-1"
        />
      </FlexContainer>

      <FlexContainer flexDirection="row" margin="20px 0" gridColumn="1/-1">
        <FlexContainer
          borderRight="1px solid rgba(224,224,224,1)"
          rowGap="20px"
          padding="5px 20px"
          flexDirection="column"
        >
          <BigTitle fontWeight="400" fontSize="16px !important">
            N° de órdenes activas
          </BigTitle>
          <BigTitle
            fontWeight="700"
            fontSize="26px !important"
            color="rgba(1,96,231,1)"
          >
            {loading ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : ordersResumen.pedidosActivos ? (
              ordersResumen.pedidosActivos
            ) : (
              0
            )}
          </BigTitle>
        </FlexContainer>
        <FlexContainer
          rowGap="20px"
          borderRight="1px solid rgba(224,224,224,1)"
          padding="5px 20px"
          flexDirection="column"
        >
          <BigTitle fontWeight="400" fontSize="16px !important">
            Ticket medio
          </BigTitle>
          <BigTitle
            fontWeight="700"
            fontSize="26px !important"
            color="rgba(1,96,231,1)"
          >
            {loading ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : ordersResumen.ticket_promedio ? (
              ordersResumen.ticket_promedio.toFixed(2)
            ) : (
              "0.00"
            )}
          </BigTitle>
        </FlexContainer>
        <FlexContainer
          padding="5px 20px"
          borderRight="1px solid rgba(224,224,224,1)"
          rowGap="20px"
          flexDirection="column"
        >
          <BigTitle fontWeight="400" fontSize="16px !important">
            Facturación total
          </BigTitle>
          <BigTitle
            fontWeight="700"
            fontSize="26px !important"
            color="rgba(1,96,231,1)"
          >
            {loading ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : ordersResumen.totalFacturacion ? (
              ordersResumen.totalFacturacion.toFixed(2)
            ) : (
              "0.00"
            )}
          </BigTitle>
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

const Actions = ({ currentOrders, setAllOrders, selected }) => {
  const handleDownload = () => {
    // TODO this should download the invoices.
    console.log(selected);
  };

  let inputInvoiceID = useRef(null);

  const handleInvoiceNumber = (e) => {
    let filtered = currentOrders.filter((a) =>
      a.id_factura.includes(e.target ? e.target.value : e.current.value)
    );
    setAllOrders(filtered);
  };

  return (
    <FlexContainer gridColumn="1/-1" justifyContent="space-between">
      {selected.length ? (
        <CSVLink data={selected} filename={"ordenes.csv"}>
          <BackButton columnGap="10px">
            Descargar seleccionados <CloudDownload />
          </BackButton>
        </CSVLink>
      ) : (
        <BackButton columnGap="10px">
          Descargar seleccionados <CloudDownload />
        </BackButton>
      )}

      <FlexContainer flexDirection="row" alignItems="center">
        <Input
          ref={inputInvoiceID}
          onChange={handleInvoiceNumber}
          type="number"
          min="0"
          placeholder="Buscar por número de facturación"
        />
        <BackButton
          onClick={() => {
            handleInvoiceNumber(inputInvoiceID);
          }}
        >
          <Search />
        </BackButton>
      </FlexContainer>
    </FlexContainer>
  );
};

const Data = ({
  currentOrders,
  ordersFilterred,
  selected,
  setSelected,
  loading,
}) => {
  const history = useHistory();

  // console.log("orders ->", orders);

  const colorHandler = (state) => {
    if (state.toUpperCase() == "COTIZADO") return "rgba(86,204,242,1)";
    if (state.toUpperCase() == "ENTREGADO") return "rgba(39,174,96,1)";
    if (state.toUpperCase() == "CANCELADO") return "rgba(235,87,87,1)";
    if (state.toUpperCase() == "POR ENTREGAR") return "rgba(242,153,74,1)";
  };

  const { setPedido } = useContext(ProductPersonalizationContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = currentOrders.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const selectedHandler = (order) => {
    const selectedIndex = selected.indexOf(order);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, order);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (order) => selected.indexOf(order) !== -1;

  const [sortBy, setSortBy] = useState({ column: "id_factura", type: "asc" });

  const sortByIcon = (sort) => {
    if (sortBy.type == "asc") return <ExpandLess />;
    if (sortBy.type == "des") return <ExpandMore />;
  };

  const handleSort = (column) => {
    let type = "asc";
    if (column == sortBy.column) {
      if (sortBy.type == "asc") {
        type = "des";
      } else {
        type = "asc";
      }
    }
    setSortBy({ column, type });
  };

  return (
    <FlexContainer gridColumn="1/-1">
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Checkbox color="primary" onChange={handleSelectAll} />
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  handleSort("id_factura");
                }}
              >
                N° Factura {sortBy.column == "id_factura" && sortByIcon()}
              </StyledTableCell>
              <StyledTableCell>Estatus</StyledTableCell>
              <StyledTableCell>Nombre del cliente</StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  handleSort("fecha_plazo");
                }}
              >
                Entrega {sortBy.column == "fecha_plazo" && sortByIcon()}
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  handleSort("productsQuantity");
                }}
              >
                N° productos{" "}
                {sortBy.column == "productsQuantity" && sortByIcon()}
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  handleSort("total");
                }}
              >
                Monto {sortBy.column == "total" && sortByIcon()}
              </StyledTableCell>
              <StyledTableCell>Acciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersFilterred
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .sort((a, b) => {
                if (sortBy.type == "asc") {
                  if (/^[0-9]*$/.test(a[sortBy.column])) {
                    return a[sortBy.column] - b[sortBy.column];
                  }
                  if (a[sortBy.column] < b[sortBy.column]) {
                    return -1;
                  }
                  if (a[sortBy.column] > b[sortBy.column]) {
                    return 1;
                  }
                  return 0;
                } else {
                  if (/^[0-9]*$/.test(a[sortBy.column])) {
                    return b[sortBy.column] - a[sortBy.column];
                  }
                  if (a[sortBy.column] > b[sortBy.column]) {
                    return -1;
                  }
                  if (a[sortBy.column] < b[sortBy.column]) {
                    return 1;
                  }
                  return 0;
                }
              })
              .map((item, i) => {
                const isItemSelected = isSelected(item);
                return (
                  <TableRow key={item.factura_id}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={() => {
                          selectedHandler(item);
                        }}
                      />
                    </TableCell>
                    <TableCell>{item.identificador}</TableCell>
                    <TableCell>
                      <ContinueButton
                        margin="0"
                        backgroundColor={() => {
                          return colorHandler(item.nombre_estado_pedido);
                        }}
                      >
                        {item.nombre_estado_pedido[0] +
                          item.nombre_estado_pedido.toLowerCase().slice(1)}
                      </ContinueButton>
                    </TableCell>
                    <TableCell>
                      {item.nombre_cliente_final
                        ? item.nombre_cliente_final
                        : item.nombre}
                      {/* <br />
                      NIT. {item.client.NIT}
                      nit */}
                    </TableCell>
                    <TableCell>
                      {moment(item.fecha_plazo)
                        .utcOffset(0)
                        .format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{item.total_pedidos}</TableCell>
                    <TableCell>
                      {moneda}.{" "}
                      {formaterPriceFunction(parseFloat(item.monto_total))}
                    </TableCell>
                    <TableCell>
                      <ContinueButton
                        backgroundColor="transparent"
                        fontWeight="400"
                        border="0.5px solid rgba(244,244,244,1)"
                        boxShadow="4px 4px 10px rgba(0, 0, 0, 0.06)"
                        color="#000000"
                        onClick={() => {
                          history.push(
                            "/app/operaciones_historial/orden_aleko/" +
                              item.id_new_pedido
                          );
                          setPedido(item);
                          saveActualOrder(item);
                        }}
                      >
                        Ver detalles{" "}
                        <ArrowForwardIos
                          style={{
                            "font-size": "14px",
                            "font-weight": "400",
                          }}
                        />
                      </ContinueButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={currentOrders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </FlexContainer>
  );
};

const WorkOrders = ({ sucursals, sellers }) => {
  // const [allOrders, setAllOrders] = useState(currentOrders);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [ordersFilterred, setOrdersFilterred] = useState([]);
  const [ordersResumen, setOrdersResumen] = useState({
    numeroVentas: "",
    pedidosActivos: "",
    ticket_promedio: "",
  });
  const [selected, setSelected] = useState([]);
  const [actualSucursal, setActualSucursal] = useState(0);
  const [actualSeller, setActualSeller] = useState(0);

  const [dateRange, setDateRange] = useState([
    new Date().setDate(new Date().getDate() - 7),
    new Date(),
  ]);

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    let idEmpresa = JSON.parse(window.localStorage.getItem("id_empresa"));
    const configToOrders = {
      id_new_empresa: idEmpresa,
      id_sucursal_bodega_ubicacion: sucursals[0].id_sucursal_bodega_ubicacion,
      id_vendedor_web_app: 0,
      codigo_pedido: 0,
      fecha_inicial: moment(dateRange[0]).format("YYYY-MM-DD"),
      fecha_final: moment(dateRange[1]).format("YYYY-MM-DD"),
    };

    let orders = await api.sales.getAlekoOrders(configToOrders);

    if (!orders.data.data.length) {
      toast.error("No hay ordenes en este momento", { autoClose: 10000 });
      setLoading(false);
      return;
    }

    // console.log("ordenes obtenidas ->", orders.data);

    setOrders(orders.data.data);
    setOrdersFilterred(orders.data.data);
    setOrdersResumen({
      numeroVentas: orders.data.numeroVentas,
      pedidosActivos: orders.data.pedidosActivos,
      ticket_promedio: orders.data.ticket_promedio,
      totalFacturacion: orders.data.data.reduce(
        (acc, el) => acc + el.monto_total,
        0
      ),
    });
    setLoading(false);
  };

  const filterChangeOrders = (event) => {
    setLoading(true);
    let { name, value } = event.target;
    value = Number(value);

    switch (name) {
      case "sucursals":
        setOrdersFilterred(
          orders.filter((item) => item.id_sucursal_bodega_ubicacion === value)
        );
        setActualSucursal(value);
        setLoading(false);
        break;

      case "status":
        if (value !== 0) {
          setOrdersFilterred(
            orders.filter((item) => item.id_estado_pedido === value)
          );
          setLoading(false);
          break;
        }

        setOrdersFilterred(orders);
        setLoading(false);
        break;

      case "cashiers":
        if (value !== 0) {
          setOrdersFilterred(
            orders.filter((item) => item.id_vendedor_web_app === value)
          );
          setActualSeller(value);
          setLoading(false);
          break;
        }

        setOrdersFilterred(orders);
        setLoading(false);
        break;

      default:
        setOrdersFilterred(orders);
        setLoading(false);
    }
  };

  const filterChangeDateOrders = async (value) => {
    setLoading(true);
    let idEmpresa = JSON.parse(window.localStorage.getItem("id_empresa"));
    let currentDates = [value[0].toISOString(), value[1].toISOString()];
    const configToOrders = {
      id_new_empresa: idEmpresa,
      id_sucursal_bodega_ubicacion:
        actualSucursal !== 0
          ? actualSucursal
          : sucursals[0].id_sucursal_bodega_ubicacion,
      id_vendedor_web_app: actualSeller,
      codigo_pedido: 0,
      fecha_inicial: moment(currentDates[0]).format("YYYY-MM-DD"),
      fecha_final: moment(currentDates[1]).format("YYYY-MM-DD"),
    };

    let orders = await api.sales.getAlekoOrders(configToOrders);

    if (!orders.data.data.length) {
      toast.error("No hay ordenes entre estas fechas", { autoClose: 10000 });
      setLoading(false);
      return;
    }

    setOrdersFilterred(orders.data.data);
    setLoading(false);
  };

  // <div className="text-center">
  //   <CircularProgress size={26} className="mx-auto" />
  // </div>

  return (
    <GridContainer>
      <Head
        currentOrders={ordersFilterred}
        ordersResumen={ordersResumen}
        filterChangeOrders={filterChangeOrders}
        dateRange={dateRange}
        filterChangeDateOrders={filterChangeDateOrders}
        loading={loading}
        // allOrders={allOrders}
        // setAllOrders={setAllOrders}
        // activeOrders={activeOrders}
        // averageInvoices={averageInvoices}
        // totalInvoices={totalInvoices}
        sucursals={sucursals}
        sellers={sellers}
      />
      <Actions
        currentOrders={orders}
        selected={selected}
        // setAllOrders={setAllOrders}
      />
      <Data
        selected={selected}
        setSelected={setSelected}
        currentOrders={orders}
        orders={orders}
        ordersFilterred={ordersFilterred}
        loading={loading}
        setLoading={setLoading}
        // allOrders={allOrders}
        // setAllOrders={setAllOrders}
      />
    </GridContainer>
  );
};

export default WorkOrders;
