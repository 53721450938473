import React, { useContext, useEffect } from "react";
import HomeIcon from "@material-ui/icons/Home";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FormControl, Select } from "@material-ui/core";
import CambioMasivoContext from "../../../../context/CambioMasivoContext/CambioMasivoContext";

const TopBarCambioMasivo = () => {
  const {
    getSucursals,
    sucursals,
    sucursal,
    changeSucursal,
    getEnterprises,
    enterprises,
    enterprise,
    handleChangeEnterprise,
    clearState,
  } = useContext(CambioMasivoContext);
  useEffect(() => {
    let user = JSON.parse(window.localStorage.getItem("pms"));

    getEnterprises(user.id_new_user);
    getSucursals();
    return () => clearState();
  }, []);

  return (
    <div className="section-title mb-4">
      <HomeIcon className="d-none d-sm-block" />
      <ArrowForwardIosIcon
        style={{ color: "#333333", fontSize: "12px" }}
        className="mx-2 d-none d-sm-block"
      />
      <h4 className="mb-0">
        <strong>Inventario</strong>
      </h4>
      <ArrowForwardIosIcon
        style={{ color: "#333333", fontSize: "12px" }}
        className="mx-2"
      />
      <h4 className="mb-0">
        <strong>Cambio masivo de precios</strong>
      </h4>
      {/* <ArrowForwardIosIcon
        style={{ color: "#333333", fontSize: "12px" }}
        className="mx-2"
      /> */}

      {/* <FormControl variant="outlined" size="small">
        <Select value={enterprise} native onChange={handleChangeEnterprise}>
          <option value="Todas">Todas las empresas</option>
          {enterprises.map((itemEmpresa) => (
            <option
              key={itemEmpresa.id_new_empresa}
              value={itemEmpresa.id_new_empresa}
            >
              {itemEmpresa.nombre.length > 18
                ? `${itemEmpresa.nombre.slice(0, 18)}...`
                : itemEmpresa.nombre}
            </option>
          ))}
        </Select>
      </FormControl>
      <ArrowForwardIosIcon
        style={{ color: "#333333", fontSize: "12px" }}
        className="mx-2"
      />

      <FormControl variant="outlined" size="small">
        <Select
          native
          value={enterprise === "Todas" ? "Todas" : sucursal}
          disabled={enterprise === "Todas"}
          onChange={changeSucursal}
        >
          {enterprise === "Todas" && (
            <option value={"Todas"}>Todas las sucursales</option>
          )}
          {sucursals.map((sucursal) => (
            <option
              key={sucursal.id_sucursal_bodega_ubicacion}
              value={sucursal.id_sucursal_bodega_ubicacion}
            >
              {sucursal.nombre_sucursal}
            </option>
          ))}
        </Select>
      </FormControl> */}
    </div>
  );
};

export default TopBarCambioMasivo;
