import React from "react";
import "../custom.css";
import { isAleko } from "../../../helpers/isAleko";
const CardEntregaCloud = ({ typeSell, changeTypesell }) => {
  return (
    <div className="row px-2">
      <div className="col-md-6 px-1">
        <div
          className="d-flex align-items-center"
          style={{
            height: "60px",
            borderRadius: "10px",
            background: "rgba(242, 242, 242, 0.5)",
            border:
              typeSell === "1" ? "1px solid #0160E7" : "1px solid #BDBDBD",
          }}
        >
          &nbsp;
          <input
            value="1"
            checked={typeSell === "1"}
            onChange={changeTypesell}
            type="radio"
            className="radio_card_entrega"
          />
          &nbsp; &nbsp;
          <span> {`${isAleko() ? "Pick-up" : "Para llevar"}`}</span>
        </div>
      </div>
      <div className="col-md-6 px-1">
        <div
          className="d-flex align-items-center"
          style={{
            height: "60px",
            borderRadius: "10px",
            background: "rgba(242, 242, 242, 0.5)",
            border:
              typeSell === "2" ? "1px solid #0160E7" : "1px solid #BDBDBD",
          }}
        >
          &nbsp;
          <input
            value="2"
            checked={typeSell === "2"}
            onChange={changeTypesell}
            type="radio"
            className="radio_card_entrega"
          />
          &nbsp; &nbsp;<span> Consumo local</span>
        </div>
      </div>
      {/* <div className="col-md-4 px-1">
        <div
          className="d-flex align-items-center"
          style={{
            height: "60px",
            borderRadius: "10px",
            background: "rgba(242, 242, 242, 0.5)",
            border:
              typeSell === "3" ? "1px solid #0160E7" : "1px solid #BDBDBD",
          }}
        >
          &nbsp;
          <input
            value="3"
            checked={typeSell === "3"}
            onChange={changeTypesell}
            type="radio"
            className="radio_card_entrega"
          />
          &nbsp; &nbsp;
          <span> {`${isAleko() ? "Delivery" : "A domicilio"}`}</span>
        </div>
      </div> */}
    </div>
  );
};

export default CardEntregaCloud;
