import React, { useContext, useRef, useEffect, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
// Styled Components

import {
  GridContainer,
  FlexContainer,
  ViewsContainer,
  ViewContainer,
  PointsViewContainer,
  PointsContainer,
  CancelButton,
  BigTitle,
  Img,
  ColorDiv,
  ContinueButton,
} from "./styled/styled.components";

// Components

import ImgDownload from "./image.download";

import ConfirmationImage from "./ConfirmationImage";

import { realImageURL } from "../../../api";
import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { toast } from "react-toastify";
import API from "../../../api";
import PdfDocument from "./PdfDocument";

const Table = ({ currentOrder, iva }) => {
  const { setOrder, order } = useContext(ProductPersonalizationContext);
  const getMoneda = () => localStorage.getItem("moneda") || "Q";
  let signature = useRef(null);
  const moneda = getMoneda();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const clearSignature = () => {
    signature.current.clear();
  };

  const saveSignature = () => {
    // This is a base64 signature image (PNG)
    if (signature.current.isEmpty()) {
      return;
    }
    let png = signature.current.toDataURL();
    setOrder({ name: "firma", value: png });
    toast.success("Orden firmada", { autoClose: 10000 });
  };

  const products = Object.entries(currentOrder.data).map((item) => item[1]);
  const colors = ["#08BFE6", "#0160E7", "#219653", "#fff200"];

  /* Función para sumar el valor total de las variantes */
  const cb = (acc, x) => {
    let sum = x[0].valor_total;

    if (x.length > 1) {
      sum = x.reduce((acc, item) => acc + item.valor_total, 0);
    }

    return acc + sum;
  };

  let total = products.reduce(cb, 0);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const getProducts = Promise.all(
      products.map(async (x) => {
        const { data } = await API.products.getById({
          id_new_item: x[0].id_new_item,
        });
        return data.data;
      })
    );
    getProducts.then((x) => setSelectedProducts(x));
  }, []);

  useEffect(() => {
    if (selectedProducts.length) {
      const salesCost = JSON.parse(localStorage.getItem("salesCost"));
      const updatePrice = selectedProducts.map((x) => {
        const currentProduct = products.find(
          (prod) => prod[0].id_new_item === x.id_new_item
        )[0];

        return {
          ...x,
          cantidad: +currentProduct.cantidad_producto,
          precio_venta:
            currentProduct.valor_total / +currentProduct.cantidad_producto,
          total: currentProduct.valor_total,
          nivel_precio:
            currentProduct.valor_total / +currentProduct.cantidad_producto,
          total: currentProduct.valor_total,
        };
      });
      localStorage.setItem(
        "salesCost",
        JSON.stringify({ ...salesCost, total })
      );
      localStorage.setItem("selectedProducts", JSON.stringify(updatePrice));
    }
  }, [selectedProducts]);
  const ref = useRef();

  return (
    <>
      <div style={{ display: "none" }}>
        <div ref={ref} id="printform">
          <PdfDocument
            currentOrder={currentOrder}
            selectedProducts={selectedProducts}
            iva={iva}
            firma={order.firma}
          />
        </div>
      </div>

      <GridContainer gridColumn="1/-1">
        <GridContainer
          backgroundColor="rgba(242,242,242,1)"
          padding="9px 0"
          gridColumn="1/-1"
        >
          <BigTitle gridColumn="span 2" fontSize="16px" fontWeight="400">
            Cant.
          </BigTitle>
          <BigTitle gridColumn="span 4" fontSize="16px" fontWeight="400">
            Nombre del producto
          </BigTitle>
          <BigTitle gridColumn="span 2" fontSize="16px" fontWeight="400">
            Talla
          </BigTitle>
          <BigTitle gridColumn="span 2" fontSize="16px" fontWeight="400">
            Color
          </BigTitle>
          <BigTitle gridColumn="11/span 2" fontSize="16px" fontWeight="400">
            Total
          </BigTitle>
        </GridContainer>

        {products.map((item, i) => {
          // let { title, quantity, size, price, perspectives } = item;

          return (
            <GridContainer
              borderBottom="1px solid rgba(189,189,189,1)"
              padding="9px 0"
              gridColumn="1/-1"
              key={i}
            >
              <BigTitle gridColumn="span 2" fontSize="16px" fontWeight="400">
                {item.length === 1 ? item[0].cantidad_producto : null}
                {item.length > 1
                  ? item.reduce((acc, item) => acc + item.cantidad_producto, 0)
                  : null}
              </BigTitle>
              <BigTitle gridColumn="span 4" fontSize="16px" fontWeight="400">
                {
                  selectedProducts.find(
                    (x) => x.id_new_item === item[0].id_new_item
                  )?.nombre
                }
              </BigTitle>
              <BigTitle gridColumn="span 2" fontSize="16px" fontWeight="400">
                {item[0].valor_talla}
              </BigTitle>
              <BigTitle gridColumn="span 2" fontSize="16px" fontWeight="400">
                <div
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: "50%",
                    background: item[0].valor_color,
                  }}
                />
              </BigTitle>
              <BigTitle gridColumn="11/span 2" fontSize="16px" fontWeight="400">
                {moneda}.{" "}
                {item.length === 1
                  ? Math.max(...item.map((item) => item.valor_total)).toFixed(2)
                  : null}
                {item.length > 1
                  ? Math.max(
                      item.reduce((acc, item) => acc + item.valor_total, 0)
                    ).toFixed(2)
                  : null}
              </BigTitle>

              <ViewContainer
                gridGap="10px"
                gridColumn="1/span 10"
                className="scroll"
              >
                <ViewsContainer
                  margin="10px 0"
                  gridTemplateColumns="repeat(2,1fr)"
                  gridColumn="span 4"
                  maxHeight="300px"
                  rowGap="20px"
                  overflow="auto"
                >
                  {item.map((item, i) => {
                    return (
                      <>
                        <ConfirmationImage
                          key={i}
                          item={item}
                          perspectives={item?.perspectives}
                        />
                      </>
                    );
                  })}
                </ViewsContainer>
                <PointsViewContainer
                  maxHeight="300px"
                  overflow="auto"
                  gridColumn="5/ span 8"
                >
                  {item.map((itemA) => {
                    const variante = itemA.variante.split(" ").join("_");
                    const images = Object.entries(itemA)
                      .filter((img) => img[0].includes("images"))
                      .map((imgB) => imgB[1])
                      .filter((x) => x.includes(variante));

                    const entries = Object.entries(itemA)
                      .filter((i) => i[0].includes("nombre_area"))
                      .map((x) => x[1])
                      .filter((x) => x);
                    return (
                      <>
                        {entries.map((item, index) => (
                          <PointsContainer
                            key={index}
                            flexDirection="row"
                            gridColumn="1/-1"
                          >
                            <FlexContainer
                              gridArea="a"
                              flexDirection="column"
                              rowGap="10px"
                              margin="10px 0"
                            >
                              <FlexContainer
                                columnGap="10px"
                                alignItems="center"
                              >
                                <ColorDiv
                                  width="14px"
                                  height="14px"
                                  backgroundColor={colors[index]}
                                />
                                <BigTitle
                                  margin="0"
                                  fontSize="16px"
                                  fontWeight="400"
                                >
                                  {itemA[`nombre_area_${index + 1}`]}
                                </BigTitle>
                              </FlexContainer>
                              {/* <ImgDownload
                                width="50px"
                                height="50px"
                                uri={realImageURL(images[index])}
                              /> */}
                              <BigTitle
                                margin="0"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                Observaciones
                              </BigTitle>
                              <BigTitle
                                margin="0"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                {itemA[`observaciones_${index + 1}`]
                                  ? itemA[`observaciones_${index + 1}`]
                                  : "Ninguna"}
                              </BigTitle>
                            </FlexContainer>

                            <FlexContainer
                              margin="10px 0"
                              flexDirection="column"
                              rowGap="10px"
                              gridArea="b"
                            >
                              <BigTitle
                                margin="0"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                Tipo
                              </BigTitle>
                              <BigTitle
                                margin="0"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                {itemA[`personalizacion_tipo_${index + 1}`]}
                              </BigTitle>
                            </FlexContainer>

                            <FlexContainer
                              margin="10px 0"
                              flexDirection="column"
                              rowGap="10px"
                              gridArea="c"
                            >
                              <BigTitle
                                margin="0"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                Dimensiones
                              </BigTitle>
                              <BigTitle
                                margin="0"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                Ancho: {itemA[`medidas_ancho_${index + 1}`]} cm
                              </BigTitle>
                              <BigTitle
                                margin="0"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                Alto: {itemA[`medidas_alto_${index + 1}`]} cm
                              </BigTitle>
                            </FlexContainer>
                            <FlexContainer
                              margin="10px 0"
                              flexDirection="column"
                              rowGap="10px"
                              gridArea="d"
                            >
                              <BigTitle
                                margin="0"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                Precio U.
                              </BigTitle>
                              <BigTitle
                                margin="0"
                                fontSize="16px"
                                fontWeight="400"
                              >
                                {moneda}.{" "}
                                {Math.max(
                                  itemA[`precio_personalizacion_${index + 1}`]
                                ).toFixed(2)}
                              </BigTitle>
                            </FlexContainer>
                          </PointsContainer>
                        ))}
                      </>
                    );
                  })}
                </PointsViewContainer>
              </ViewContainer>
            </GridContainer>
          );
        })}
        <FlexContainer backgroundColor="rgba(242,242,242,1)" gridColumn="1/-1">
          <FlexContainer
            margin="0 20px 0 auto"
            padding="10px 0"
            flexWrap="nowrap"
            flexDirection="row"
            maxWidth="300px"
            width="100%"
            justifyContent="space-between"
          >
            <FlexContainer
              rowGap="20px"
              justifyContent="left"
              flexDirection="column"
            >
              <BigTitle fontSize="16px" fontWeight="400">
                Subtotal
              </BigTitle>
              <BigTitle fontSize="16px" fontWeight="400">
                IVA ({iva}%)
              </BigTitle>
              <BigTitle fontSize="16px" fontWeight="700">
                Gran total
              </BigTitle>
            </FlexContainer>
            <FlexContainer
              rowGap="20px"
              justifyContent="center"
              textAlign="right"
              flexDirection="column"
            >
              <BigTitle fontSize="16px" fontWeight="400">
                {(total - total * (iva / 100)).toFixed(2)}
              </BigTitle>
              <BigTitle fontSize="16px" fontWeight="400">
                {(total * (iva / 100)).toFixed(2)}
              </BigTitle>
              <BigTitle fontSize="16px" fontWeight="700">
                {moneda}. {total.toFixed(2)}
              </BigTitle>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer
          gridColumn="1/-1"
          flexWrap="nowrap"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding="20px"
        >
          <FlexContainer
            width="100%"
            maxWidth="400px"
            height="200px"
            border="1px solid rgba(130,130,130,1)"
          >
            <SignaturePad ref={signature} />
          </FlexContainer>
          <FlexContainer flexDirection="column" alignItems="center">
            <BigTitle fontSize="16px" fontWeight="700">
              Firme Aqui
            </BigTitle>
            <div className="w-100 d-flex justify-content-between mt-1">
              <CancelButton
                onClick={clearSignature}
                fontSize="16px"
                margin="auto 10px"
                fontWeight="400"
              >
                Borrar
              </CancelButton>
              <ContinueButton
                onClick={saveSignature}
                fontSize="16px"
                margin="auto 10px"
                fontWeight="400"
              >
                Firmar
              </ContinueButton>
            </div>
          </FlexContainer>
        </FlexContainer>
      </GridContainer>
    </>
  );
};

export default Table;
