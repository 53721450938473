import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel";
import InputAdornment from "@material-ui/core/InputAdornment";
import DataTable from "react-data-table-component";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import API, { base_url_images } from "../../../api";
import Cards from "react-credit-cards";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { CSVLink } from "react-csv";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";

// components

//data

const ERROR_MESSAGES = {
  emptyCardNumber: "El número de la tarjeta es inválido",
  invalidCardNumber: "El número de la tarjeta es inválido",
  emptyExpiryDate: "La fecha de expiración es inválida",
  monthOutOfRange: "El mes de expiración debe estar entre 01 y 12",
  yearOutOfRange: "El año de expiración no puede estar en el pasado",
  dateOutOfRange: "La fecha de expiración no puede estar en el pasado",
  invalidExpiryDate: "La fecha de expiración es inválida",
  emptyCVC: "El código de seguridad es inválido",
  invalidCVC: "El código de seguridad es inválido",
};

export default function User_conifg(props) {
  let history = useHistory();
  const { register, handleSubmit } = useForm();
  const moment = require("moment");

  let default_dates = [
    new Date(new Date().setDate(new Date().getDate() - 7)),
    new Date(),
  ];
  let hideColsInit = new Array(6).fill(false);

  const [configTab, setConfigTab] = useState(0);
  const [regions, setRegions] = useState([]);
  const [classes, setClasses] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [country, setCountry] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const [subscriptionLoader, setSubscriptionLoader] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [massiveUploadLoader, setMassiveUploadLoader] = useState(false);
  const [clientUpdateLoader, setClientUpdateLoader] = useState(false);

  const [empresaInfoLoader, setEmpresaInfoLoader] = useState(true);
  const [storesLoader, setStoresLoader] = useState(true);
  const [usersLoader, setUsersLoader] = useState(true);
  const [parameters, setParameters] = useState();
  const [empresaInfo, setEmpresaInfo] = useState({
    id_new_empresa: "",
  });

  const [stores, setStores] = useState([]);
  const [storeUsers, setStoreUsers] = useState([]);
  const [sucursalFilter, setSucursalFilter] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState("");
  const [dateRange, setDateRange] = useState(default_dates);
  const [userTypes, setUserTypes] = useState([]);
  const [clientSubscription, setClientSubscription] = useState([
    {
      plan: {
        descripcion: "",
      },
      fecha_final: "",
      numero_licencias: "",
    },
  ]);
  const [file_sucursal, setFile_sucursal] = useState("");
  const [displayItems, setDisplayItems] = useState(true);
  const [displayTranstactions, setDisplayTranstactions] = useState(true);
  const [showProducts, setShowProducts] = useState(false);
  const [hideCols, setHideCols] = useState(hideColsInit);
  //Subscriptions
  var [plans, setPlans] = useState([]);
  var [plan, setPlan] = useState("");
  var [cantLicencias, setCantLicencias] = useState(1);
  var [costoTotal, setCostoTotal] = useState("");
  var [frecuenciaPago, setFrecuenciaPago] = useState("");
  const [cvc, setCVC] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [auto_renewal, setAutoRenewal] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [type, setType] = useState(4);
  const [companyStats, setCompanyStats] = useState([]);
  const [companyProducts, setCompanyProducts] = useState([]);
  const [companyFilteredProducts, setCompanyFilteredProducts] = useState([]);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [statsLoader, setStatsLoader] = useState(true);
  const [productsLoader, setProductsLoader] = useState(true);
  const [productsFilter, setProductsFilter] = useState("");
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {};
  }, []);

  //component did mount
  useEffect(() => {
    getClient();
    getClasses();
    getRegions();
    getCurrencies();
    getStores();
    getUserTypes();
    getClientSubscription();
    getAllPlans();
    getEmpresaStats();
    getEmpresaProducts();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (productsFilter === "") {
      setCompanyFilteredProducts({ items: companyProducts.items });
    } else {
      setCompanyFilteredProducts({
        items: companyProducts.items.filter(
          (item) =>
            item.nombre.toLowerCase().includes(productsFilter.toLowerCase()) ||
            item.sucursal.toLowerCase().includes(productsFilter.toLowerCase())
        ),
      });
    }
  }, [productsFilter]);

  useEffect(() => {
    let canRender = true;
    if (empresaInfo.id_new_empresa === "") canRender = false;
    if (currencies.length === 0) canRender = false;
    if (regions.length === 0) canRender = false;
    if (classes.length === 0) canRender = false;

    if (canRender) setEmpresaInfoLoader(false);
  }, [regions, classes, currencies, empresaInfo]);

  //component did update
  useEffect(() => {
    getFilteredUsers();
  }, [sucursalFilter, userTypeFilter]);

  useEffect(() => {
    if (pageLoaded) {
      getEmpresaStats();
      getEmpresaProducts();
    }
  }, [type]);

  //component did update
  useEffect(() => {
    getFilteredUsers();
  }, [sucursalFilter, userTypeFilter]);

  // set states
  const handleConfigTabs = (event, newValue) => {
    setConfigTab(newValue);
  };
  const save = (data) => {
    let filledForm = true;

    if (!filledForm) {
      toast.error("Falta campos por rellenar", { autoClose: 3000 });
    } else {
      //api call
    }
  };

  const getFilteredUsers = () => {
    setUsersLoader(true);
    console.log("dsad", sucursalFilter);
    API.admin_clients
      .getStoreUsers({
        id_new_empresa: props.match.params.id,
        id_sucursal_bodega_ubicacion: sucursalFilter,
        user_type: userTypeFilter,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setStoreUsers(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setUsersLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setUsersLoader(false);
      });
  };

  const usersAction = (e, user) => {
    if (e.target.value === "ver") {
      history.push({
        pathname: `/admin/users/new/` + props.match.params.id,
        state: user,
      });
    }
    if (e.target.value === "eliminar") {
      setUserToDelete(user.id_new_user);
      $("#confirmDeletion").modal();
    }
  };

  const sendFile = (event) => {
    if (typeof event.target.files[0] !== "undefined") {
      setMassiveUploadLoader(true);
      API.products
        .uploadXLS(event.target.files[0], file_sucursal, props.match.params.id)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success(response.message, {
              autoClose: 10000,
            });
            setMassiveUploadLoader(false);
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
            setMassiveUploadLoader(false);
          }
        })
        .catch((err) => {
          setMassiveUploadLoader(false);
          let response = err;
          console.log(response);
          toast.error("Ha ocurrido un error.", {
            autoClose: 10000,
          });

          console.log("Ha ocurrido un error", response);
        });
    }
  };

  const hideCol = (index) => {
    let newData = [...hideCols];
    newData[index] = true;
    setHideCols(newData);
  };

  const displayCol = (index) => {
    if (index == "all") {
      setHideCols(hideColsInit);
    } else {
      let newData = [...hideCols];
      newData[parseInt(index)] = false;
      setHideCols(newData);
    }
  };

  const getClient = () => {
    API.admin_clients
      .getById({ id_new_empresa: props.match.params.id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setEmpresaInfo(response.data.company);
          if (response.data.FELParameters) {
            setParameters(response.data.FELParameters);
          } else {
            setParameters({});
          }
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setEmpresaInfoLoader(false);
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getClasses = () => {
    API.userConfig
      .getClasses()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setClasses(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las clases", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las clases", {
          autoClose: 10000,
        });
      });
  };

  const getRegions = () => {
    API.userConfig
      .getRegions()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setRegions(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las clases", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las clases", {
          autoClose: 10000,
        });
      });
  };

  const getCurrencies = () => {
    API.userConfig
      .getCurrencies()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setCurrencies(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las monedas", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las monedas", {
          autoClose: 10000,
        });
      });
  };

  const getStores = () => {
    setStoresLoader(true);
    API.admin_clients
      .getClientStores({ id_new_empresa: props.match.params.id })
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setStores(response.data);
          if (response.data.length > 0)
            setFile_sucursal(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las sucursales", {
              autoClose: 10000,
            });
          }
        }
        setStoresLoader(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las sucursales", {
          autoClose: 10000,
        });
        setStoresLoader(false);
      });
  };

  const getUserTypes = () => {
    API.userConfig
      .getUserTypes()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setUserTypes(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las sucursales", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las sucursales", {
          autoClose: 10000,
        });
      });
  };

  const getClientSubscription = () => {
    setSubscriptionLoader(true);
    API.subscriptions
      .getUserSubscriptions(props.match.params.id)
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          console.log("Getting this response", response.data);
          if (!Array.isArray(response.data)) {
            setClientSubscription([response.data]);
          } else {
            setClientSubscription(response.data);
          }
        } else {
          if (response.message) {
            if (response.message !== "No se encontró ninguna suscripción") {
              toast.error(response.message, { autoClose: 10000 });
            }
          } else {
            toast.warning(
              "Ha ocurrido un error al obtener la suscripcion del cliente",
              { autoClose: 10000 }
            );
          }
        }
        setSubscriptionLoader(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error(
          "Ha ocurrido un error al obtener la suscripcion del cliente",
          {
            autoClose: 10000,
          }
        );
        setSubscriptionLoader(false);
      });
  };

  const deleteSubscription = () => {
    API.admin_clients
      .deleteSubscription({ id_new_empresa: props.match.params.id })
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
          getClientSubscription();
          setClientSubscription([
            {
              plan: {
                descripcion: "",
              },
              fecha_final: "",
              numero_licencias: "",
            },
          ]);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning(
              "Ha ocurrido un error al cancelar la suscripcion del cliente",
              { autoClose: 10000 }
            );
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error(
          "Ha ocurrido un error al cancelar la suscripcion del cliente",
          {
            autoClose: 10000,
          }
        );
      });
  };

  const getEmpresaStats = () => {
    setStatsLoader(true);
    API.admin_clients
      .getCompanyStats({
        id_new_empresa: parseInt(props.match.params.id),
        tipo: type,
      })
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setCompanyStats(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las sucursales", {
              autoClose: 10000,
            });
          }
        }
        setStatsLoader(false);
      })
      .catch((err) => {
        setStatsLoader(false);
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las sucursales", {
          autoClose: 10000,
        });
      });
  };

  const getEmpresaProducts = () => {
    setProductsLoader(true);
    API.admin_clients
      .getCompanyProducts({
        id_new_empresa: parseInt(props.match.params.id),
        tipo: type,
      })
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setCompanyProducts(response.data);
          setCompanyFilteredProducts(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las sucursales", {
              autoClose: 10000,
            });
          }
        }
        setProductsLoader(false);
      })
      .catch((err) => {
        setProductsLoader(false);
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las sucursales", {
          autoClose: 10000,
        });
      });
  };

  //Subscription

  const {
    meta,
    getCardNumberProps,
    wrapperProps,
    getCardImageProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  });
  // set states

  const handlePayment = () => {
    setSubmitLoader(true);
    let validForm = true;
    console.log(meta.erroredInputs);

    for (var key in meta.erroredInputs) {
      if (typeof meta.erroredInputs[key] !== "undefined") {
        validForm = false;
      }
    }
    if (!validForm)
      toast.error("Hay uno o mas errores en los datos de la tarjeta.", {
        autoClose: 10000,
      });

    if (name.length === "") {
      validForm = false;
      toast.error("Debe completar el nombre del tarjetahabiente.", {
        autoClose: 10000,
      });
    }

    if (cantLicencias === "") {
      validForm = false;
      toast.error("Debe introducir la cantidad de licencias.", {
        autoClose: 10000,
      });
    }

    let form_data = {
      card_holder_name: name,
      card_number: number.replace(/\s+/g, ""),
      expiry: expiry.replace(/\//g, "").replace(/\s+/g, ""), // Converts from "12 / 24" to "1224"
      cvv: cvc,
      plan_id: parseInt(plan),
      auto_renewal: auto_renewal ? 1 : 0,
      payment_gateway: 1,
      numero_licencias: parseInt(cantLicencias),
      id_new_empresa: parseInt(props.match.params.id),
    };
    if (validForm) {
      API.admin_clients
        .createSub(form_data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            getClientSubscription();
            toast.success(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
          setSubmitLoader(false);
        })
        .catch((err) => {
          setSubmitLoader(false);
          let response = err.response;
          console.log(err);
          console.log(response);
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    } else {
      setSubmitLoader(false);
    }
  };

  const changePlan = (event) => {
    let total;
    const currentPlan = event.target.value;
    setPlan(currentPlan);
    console.log("These are all plans", plans);
    let selectedPlan = plans.find(
      (filteredPlan) => filteredPlan.id_planes === parseInt(currentPlan)
    );
    console.log("selected plan", selectedPlan);
    total = selectedPlan.monto * cantLicencias;

    setCostoTotal(total);
    setFrecuenciaPago(selectedPlan.periodo);
  };
  const changeTotalToPay = (event) => {
    if (parseInt(event.target.value) === 0) {
      event.target.value = 1;
    }
    setCantLicencias(
      event.target.value < 0
        ? (event.target.value = -event.target.value)
        : event.target.value
    );
    let total;
    let selectedPlan = plans.find(
      (filteredPlan) => filteredPlan.id_planes === parseInt(plan)
    );
    total = selectedPlan.monto * parseInt(event.target.value);
    setCostoTotal(total);
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "cvc":
        setCVC(value);
        break;
      case "expiry":
        setExpiry(value);
        break;
      case "name":
        setName(value);
        break;
      case "number":
        setNumber(value);
        break;
      default:
        break;
    }
  };

  const getAllPlans = () => {
    API.subscriptions
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setPlans(response.data);
          setPlan(response.data[0].id_planes);
          setCantLicencias(1);
          setCostoTotal(response.data[0].monto);
          setFrecuenciaPago(response.data[0].periodo);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setSaveLoader(false);
      })
      .catch((err) => {
        setSaveLoader(false);
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const update_client = (data) => {
    setClientUpdateLoader(true);
    data.id_new_empresa = parseInt(props.match.params.id);

    API.admin_clients
      .updateClient(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setClientUpdateLoader(false);
      })
      .catch((err) => {
        setClientUpdateLoader(false);
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  //end of subscription

  // Users

  const deleteUser = (id) => {
    API.users
      .deleteUsers({
        ids_users: id,
        id_new_empresa: props.match.params.id,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("Usuario eliminado", {
            autoClose: 10000,
          });
          $("#confirmDeletion").modal("hide");
          getFilteredUsers();
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        {showProducts ? (
          <React.Fragment>
            <Button
              className="btn-cs1 mx-3"
              variant="contained"
              color="primary"
              style={{ paddingBottom: "0px" }}
              onClick={() => setShowProducts(false)}
            >
              <ArrowBackIosIcon
                className="mr-1"
                style={{ fontSize: "16px", marginBottom: "3px" }}
              />
              <strong>Volver</strong>
            </Button>
            <ArrowForwardIosIcon
              style={{ color: "#333333", fontSize: "12px" }}
              className="mx-2"
            />
            <h4 className="mb-0">
              <strong>Listado de productos</strong>
            </h4>
          </React.Fragment>
        ) : (
          <h4 className="mb-0">
            <strong>Detalles del cliente</strong>
          </h4>
        )}
      </div>

      <form className="content-card card mt-3" onSubmit={handleSubmit(save)}>
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            {showProducts ? (
              <div>
                <h5 className="mb-0">
                  <strong>Listado de productos según atributos</strong>
                </h5>
                <p className="mb-0">
                  Consulte y gestione los productos con el atributo seleccionado
                </p>
              </div>
            ) : (
              <div>
                <h5 className="mb-0">
                  <strong>Nombre del cliente</strong>
                </h5>
                <p className="mb-0">
                  Consulte y gestione los detalles del cliente.
                </p>
              </div>
            )}
          </div>

          <Tabs
            className="mt-2"
            value={configTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleConfigTabs}
          >
            <Tab className="tab" label="Información General" />
            <Tab className="tab" label="Sucursales" />
            <Tab className="tab" label="Usuarios" />
            <Tab className="tab" label="Membresía" />
            <Tab className="tab" label="Estadísticas" />
            <Tab className="tab" label="Cargar productos" />
          </Tabs>
        </div>
        <div className="card-body pt-0">
          {empresaInfoLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" color="white" />
            </div>
          ) : (
            <TabPanel value={configTab} index={0} className="config-tab">
              <form onSubmit={handleSubmit(update_client)}>
                <div
                  className="col-md-12 d-flex justify-content-end"
                  style={{ marginTop: "10px" }}
                >
                  <Button
                    className="btn-cs1 mt-2 mx-1"
                    variant="contained"
                    color="primary"
                    type
                  >
                    {clientUpdateLoader ? (
                      <div className="text-center">
                        <CircularProgress
                          size={26}
                          className="mx-auto"
                          color="white"
                        />
                      </div>
                    ) : (
                      "Actualizar"
                    )}
                  </Button>
                </div>
                {/*<div className="row mb-4">
									<div className="col-md-12 d-flex justify-content-end">
										<Button className="btn-cs1 mt-2 mx-1" variant="contained" color="primary" type>
											{clientUpdateLoader
												?
												<div className="text-center">
													<CircularProgress size={26} className="mx-auto" color="white" />
												</div>
												: "Actualizar"}

										</Button>
									</div>
								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Código del emisor </strong>
									</label>

									<TextField
										defaultValue={parameters.fe_cod_cliente}
										className="w-100"
										placeholder="Ingrese el código del emisor"
										inputRef={register}
											name="fe_cod_cliente"
											required={true}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<EditIcon style={{ color: '#0160E7' }} />
												</InputAdornment>
											)
										}}
									/>
								</div>
								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Nombre del emisor</strong>
									</label>

									<TextField
										type="text"
										className="w-100"
										placeholder="Ingrese el nombre del emisor"
										inputRef={register}
										defaultValue={parameters.fe_nombre_emisor}
											name="fe_nombre_emisor"
											required={true}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<EditIcon style={{ color: '#0160E7' }} />
												</InputAdornment>
											)
										}}
									/>
								</div>
								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>NIT del emisor</strong>
									</label>

									<TextField
										type="text"
										className="w-100"
										placeholder="Ingrese el NIT  del emisor"
										inputRef={register}
										defaultValue={parameters.fe_nit_emisor}
											name="fe_nit_emisor"
											required={true}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<EditIcon style={{ color: '#0160E7' }} />
												</InputAdornment>
											)
										}}
									/>
								</div>

								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Código de país</strong>
									</label>

									<FormControl variant="outlined" className="w-100">
										<Select
												name="fe_cod_pais"
											native
											inputRef={register}
											defaultValue={parameters.fe_cod_pais}
											className="cs-select1 select-w100"
												required={true}
										>
											<option value="">Seleccione el país</option>
											<option value="GT">Guatemala</option>
											<option value="GT">El Salvador</option>
											<option value="GT">Honduras</option>
											<option value="GT">Nicaragua</option>
											<option value="GT">Costa Rica</option>
											<option value="GT">Estados Unidos</option>
											<option value="GT">Panamá</option>
											<option value="GT">Suramerica</option>
											<option value="GT">México</option>
											<option value="GT">El caribe</option>
											<option value="GT">Otro</option>
										</Select>
									</FormControl>
								</div>
								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>País del emisor</strong>
									</label>

									<FormControl variant="outlined" className="w-100">
										<Select
												name="fe_pais_emisor"
											native
											inputRef={register}
											defaultValue={parameters.fe_pais_emisor ? parameters.fe_pais_emisor.toString().trim() : ""}
											className="cs-select1 select-w100"
												required={true}
										>
											<option value="" id="AR">
												Seleccione el país
										</option>
											<option value="Argentina" id="AR">
												Argentina
										</option>
											<option value="Bolivia" id="BO">
												Bolivia
										</option>
											<option value="Brasil" id="BR">
												Brasil
										</option>
											<option value="Chile" id="CL">
												Chile
										</option>
											<option value="Colombia" id="CO">
												Colombia
										</option>
											<option value="Costa Rica" id="CR">
												Costa Rica
										</option>
											<option value="Cuba" id="CU">
												Cuba
										</option>
											<option value="Dinamarca" id="DK">
												Dinamarca
										</option>
											<option value="Dominica" id="DM">
												Dominica
										</option>
											<option value="Ecuador" id="EC">
												Ecuador
										</option>
											<option value="El Salvador" id="SV">
												El Salvador
										</option>
											<option value="España" id="ES">
												España
										</option>
											<option value="Estados Unidos" id="US">
												Estados Unidos
										</option>
											<option value="Guatemala" id="GT">
												Guatemala
										</option>
											<option value="Guayana" id="GY">
												Guayana
										</option>
											<option value="Haití" id="HT">
												Haití
										</option>
											<option value="Honduras" id="HN">
												Honduras
										</option>
											<option value="México" id="MX">
												México
										</option>
											<option value="Nicaragua" id="NI">
												Nicaragua
										</option>
											<option value="Panamá" id="PA">
												Panamá
										</option>
											<option value="Paraguay" id="PY">
												Paraguay
										</option>
											<option value="Perú" id="PE">
												Perú
										</option>
											<option value="Puerto Rico" id="PR">
												Puerto Rico
										</option>
											<option value="República Dominicana" id="DO">
												República Dominicana
										</option>
											<option value="Trinidad y Tobago" id="TT">
												Trinidad y Tobago
										</option>
											<option value="Uruguay" id="UY">
												Uruguay
										</option>
											<option value="Venezuela" id="VE">
												Venezuela
										</option>
										</Select>
									</FormControl>
								</div>
								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Departamento del emisor</strong>
									</label>

									<TextField
										inputRef={register}
											name="fe_departamento_emisor"
										defaultValue={parameters.fe_departamento_emisor}
										type="text"
										className="w-100"
											placeholder="Ingrese el departamento del emisor"
											required={true}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<EditIcon style={{ color: '#0160E7' }} />
												</InputAdornment>
											)
										}}
									/>
								</div>

								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Municipio del emisor</strong>
									</label>

									<TextField
										className="w-100"
										placeholder="Ingrese el municipio del emisor"
										inputRef={register}
											name="fe_municipio_emisor"
										defaultValue={parameters.fe_municipio_emisor}
											required={true}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<EditIcon style={{ color: '#0160E7' }} />
												</InputAdornment>
											)
										}}
									/>
								</div>
								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Dirección del emisor</strong>
									</label>

									<TextField
											name="fe_direccion_emisor"
										inputRef={register}
										defaultValue={parameters.fe_direccion_emisor}
										type="text"
										className="w-100"
										placeholder="Ingrese la dirección del emisor"
											required={true}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<EditIcon style={{ color: '#0160E7' }} />
												</InputAdornment>
											)
										}}
									/>
								</div>
								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Alias del emisor (Usuario)</strong>
									</label>

									<TextField
											name="fe_alias_cliente"
										inputRef={register}
										defaultValue={parameters.fe_alias_cliente}
										type="text"
										className="w-100"
										placeholder="Ingrese el nombre de usuario del emisor"
											required={true}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<EditIcon style={{ color: '#0160E7' }} />
												</InputAdornment>
											)
										}}
									/>
								</div>


								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Proveedor FEL</strong>
									</label>

									<FormControl variant="outlined" className="w-100">
										<Select
											name="id_prov_sat"
											native
											className="cs-select1 select-w100"
											inputRef={register}
											defaultValue={parameters.id_prov_sat}
												required={true}
										>
											<option value="">Seleccione</option>
											<option value="0">Ninguno</option>
											<option value="2">INFILE</option>
											<option value="3">COFIDI</option>
											<option value="1">DOCUTEC</option>
										</Select>
									</FormControl>
								</div>

								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Llave - Firma</strong>
									</label>

									<TextField
										type="text"
										className="w-100"
										placeholder="Ingrese la Llave - Firma"
											name="fe_llave_firma"
										inputRef={register}
										defaultValue={parameters.fe_llave_firma}
											required={true}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<EditIcon style={{ color: '#0160E7' }} />
												</InputAdornment>
											)
										}}
									/>
								</div>
								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>Llave – Certificación</strong>
									</label>

									<TextField
											name="fe_llave_certificacion"
										type="text"
										className="w-100"
										placeholder="Ingrese la Llave - Certificación"
										inputRef={register}
										defaultValue={parameters.fe_llave_certificacion}
											required={true}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<EditIcon style={{ color: '#0160E7' }} />
												</InputAdornment>
											)
										}}
									/>
								</div>
								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>FEL – Usuario</strong>
									</label>

									<TextField
										name="username"
										type="text"
										className="w-100"
										placeholder="Ingrese el usuario FEL"
										inputRef={register}
										defaultValue={parameters.username}
											required={true}
										/>
								</div>

								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>FEL – ID cliente</strong>
									</label>

									<TextField
										name="clientid"
										type="text"
										className="w-100"
										placeholder="Ingrese el ID del cliente FEL"
										inputRef={register}
										defaultValue={parameters.clientid}
											required={true}
										/>
								</div>

								<div className="col-md-4 mt-3">
									<label style={{ fontSize: '0.8rem' }}>
										<strong>FEL – Clave</strong>
									</label>

									<TextField
											name="apipassword"
										type="text"
										className="w-100"
										placeholder="Ingrese la clave del usuario FEL"
										inputRef={register}
										defaultValue={parameters.password}
											required={true}
										/>
								</div>
							</div>*/}

                <div className="row mb-4">
                  <div className="col-12">
                    <h5 className="mb-0">
                      <strong>Datos de la empresa</strong>
                    </h5>
                    <p className="mb-0">
                      Consulte y actualice los datos asociado a la empresa del
                      cliente.
                    </p>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Código</strong>
                    </label>

                    <TextField
                      className="w-100"
                      placeholder="Ingrese el código de su empresa"
                      name="code"
                      inputRef={register}
                      defaultValue={empresaInfo.codigo}
                      required={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon style={{ color: "#0160E7" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Nombre de la empresa</strong>
                    </label>

                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="Ingrese el nombre de la empresa"
                      name="name"
                      inputRef={register}
                      defaultValue={empresaInfo.nombre}
                      required={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon style={{ color: "#0160E7" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Dirección de la empresa</strong>
                    </label>

                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="Ingrese la dirección de la empresa"
                      required={true}
                      name="address"
                      inputRef={register}
                      defaultValue={empresaInfo.direccion}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon style={{ color: "#0160E7" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  {/* Second Row */}
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>NIT de la empresa</strong>
                    </label>

                    <TextField
                      className="w-100"
                      placeholder="Ingrese el NIT de su empresa"
                      name="nit"
                      inputRef={register}
                      defaultValue={empresaInfo.nit}
                      required={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon style={{ color: "#0160E7" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Teléfono de la empresa</strong>
                    </label>

                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="(       )        -           -"
                      name="phone"
                      inputRef={register}
                      defaultValue={empresaInfo.telefono}
                      required={true}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" />,
                      }}
                    />
                  </div>
                  <div className="col-md-2 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>IVA (%)</strong>
                    </label>

                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="Ingrese el IVA"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {" "}
                            <EditIcon style={{ color: "#0160E7" }} /> %
                          </InputAdornment>
                        ),
                      }}
                      name="iva"
                      inputRef={register}
                      defaultValue={empresaInfo.iva}
                      required={true}
                    />
                  </div>

                  <div className="col-md-2 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Moneda</strong>
                    </label>

                    <FormControl variant="outlined" className="w-100">
                      <Select
                        native
                        className="cs-select1 select-w100"
                        name="currency"
                        inputRef={register}
                        defaultValue={empresaInfo.id_moneda_web_app}
                        required={true}
                      >
                        <option value="">Seleccione</option>
                        {currencies.map((currency) => (
                          <option value={currency.id_moneda_web_app}>
                            {currency.descripcion}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {/* Third Row */}
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Código del establecimiento</strong>
                    </label>

                    <TextField
                      className="w-100"
                      placeholder="Ingrese el código del establecimiento"
                      name="establishment_code"
                      inputRef={register}
                      defaultValue={empresaInfo.codigo_establecimiento}
                      required={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon style={{ color: "#0160E7" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Dispositivo</strong>
                    </label>

                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="Ingrese el dispositivo"
                      name="device"
                      inputRef={register}
                      defaultValue={empresaInfo.dispositivo}
                      required={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon style={{ color: "#0160E7" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Régimen de ISR</strong>
                    </label>

                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="Ingrese el régimen de ISR de la empresa"
                      name="isr_regime"
                      inputRef={register}
                      defaultValue={empresaInfo.regimen_isr}
                      required={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon style={{ color: "#0160E7" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  {/* Forth Row */}
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Tipo de negocio</strong>
                    </label>

                    <FormControl variant="outlined" className="w-100">
                      <Select
                        native
                        className="cs-select1 select-w100"
                        name="type"
                        inputRef={register}
                        defaultValue={empresaInfo.tipo_negocio}
                        required={true}
                      >
                        <option value="">Seleccione el tipo de negocio</option>
                        <option value="1">ABARROTERIA Y MISCELANEA</option>
                        <option value="2">
                          TELECOMUNICACIONES E INFORMATICA
                        </option>
                        <option value="3">COOPERATIVAS</option>
                        <option value="4">
                          ASESORIAS Y SERVICIOS PROFESIONALES
                        </option>
                        <option value="5">FARMACEUTICOS</option>
                        <option value="6">
                          TELEFONIA, CELULARES Y ACCESORIOS
                        </option>
                        <option value="7">
                          FERRETERIA Y VENTA DE MATERIALES
                        </option>
                        <option value="8">
                          INSTITUCIONES DE CREDITO Y SERVICIOS FINANCIEROS
                        </option>
                        <option value="9">OTROS</option>
                        <option value="10">LIBRERÍA Y PAPELERIA</option>
                        <option value="11">ROPA Y TEXTILES</option>
                        <option value="13">
                          SERVICIOS, REPUESTOS Y ACCESORIOS AUTOMOTRICES Y
                          VEHICULOS
                        </option>
                        <option value="14">AGROSERVICIOS</option>
                        <option value="15">EDUCATIVO</option>
                        <option value="16">ZAPATERIA</option>
                        <option value="17">RESTAURANTE</option>
                        <option value="18">SERVICIOS DE CORREO</option>
                        <option value="19">ASOCIACIONES</option>
                        <option value="20">HOGAR Y ELECTRODOMESTICOS</option>
                        <option value="21">
                          GASOLINERAS Y ESTACION DE SERVICIOS
                        </option>
                        <option value="22">TRANSPORTES</option>
                        <option value="23">HOGAR Y ELECTRODOMESTICOS</option>
                        <option value="24">SALON BELLEZA</option>
                        <option value="25">PANADERIA</option>
                        <option value="26">
                          EQUIPO INDUSTRIAL Y MAQUINARIA
                        </option>
                        <option value="27">VIDRIERIA</option>
                        <option value="28">SERIGRAFIA</option>
                        <option value="29">MUEBLERIAS Y CARPINTERIAS</option>
                        <option value="30">JOYERIA Y RELOJERIA</option>
                        <option value="31">INMOBILIARIA</option>
                        <option value="32">HELADERIA</option>
                        <option value="33">CONSTRUCTORA</option>
                        <option value="34">CARNICERIA</option>
                        <option value="35">
                          VETERINARIA Y ESTETICA CANINA
                        </option>
                        <option value="36">VENTA DE GAS PROPANO</option>
                        <option value="37">SOCIEDAD</option>
                        <option value="38">
                          PURIFICADORA Y VENTA DE AGUA PURA
                        </option>
                        <option value="39">HOTELERIA</option>
                        <option value="40">
                          FABRICACION Y VENTA DE PINTURAS
                        </option>
                        <option value="41">EQUIPO DE AUDIO</option>
                        <option value="42">BOLETOS AEREOS</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Región</strong>
                    </label>

                    <FormControl variant="outlined" className="w-100">
                      <Select
                        native
                        className="cs-select1 select-w100"
                        name="region"
                        inputRef={register}
                        required={true}
                        defaultValue={empresaInfo.region}
                      >
                        <option value="">Seleccione la región</option>
                        {regions.map((region) => (
                          <option value={region.id_region_web_app}>
                            {region.descripcion}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Clase</strong>
                    </label>

                    <FormControl variant="outlined" className="w-100">
                      <Select
                        native
                        className="cs-select1 select-w100"
                        name="class_id"
                        inputRef={register}
                        defaultValue={empresaInfo.id_new_grupo1}
                        required={true}
                      >
                        <option value="">Seleccione la clase</option>
                        {classes.map((clase) => (
                          <option value={clase.id_new_grupo1}>
                            {clase.descripcion}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {/* 	<div className="col-12 mt-3">
									<Button className="btn-cs1" variant="contained" color="primary">
										<CloudDownloadIcon className="mr-2" />
									Descargar perfil
								</Button>
								</div> */}
                </div>
              </form>
            </TabPanel>
          )}
          <TabPanel value={configTab} index={1} className="config-tab">
            <div className="d-flex justify-content-between align-items-center flex-wrap my-2">
              {stores.length > 0 ? (
                <CSVLink
                  data={stores.map(
                    ({
                      nombre_sucursal,
                      direccion_sucursal,
                      totalUsuarios,
                      totalProductos,
                    }) => ({
                      nombre_sucursal,
                      direccion_sucursal,
                      totalUsuarios,
                      totalProductos,
                    })
                  )}
                  filename={`Sucursales de ${empresaInfo.nombre}.csv`}
                >
                  <Button
                    className="btn-cs1 mt-2 mx-1"
                    variant="contained"
                    color="primary"
                  >
                    Descargar
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                </CSVLink>
              ) : (
                <Button
                  className="btn-cs1 mt-2 mx-1"
                  variant="contained"
                  color="primary"
                >
                  Descargar
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              )}

              <Button
                className="btn-cs1 mt-2 mx-1"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                component={Link}
                to={"/admin/sucursal/new/" + props.match.params.id}
              >
                <span style={{ marginBottom: "-4px" }}>Crear Sucursal</span>
              </Button>
            </div>

            {storesLoader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" color="black" />
              </div>
            ) : (
              <DataTable
                className="datatable-cs datatable-cs-v2 "
                title={"s0"}
                columns={[
                  {
                    name: "Nombre",
                    selector: "nombre_sucursal",
                    sortable: true,
                  },
                  {
                    name: "Dirección",
                    selector: "direccion_sucursal",
                    sortable: true,
                  },
                  {
                    name: "N° Usuarios",
                    selector: "totalUsuarios",
                    sortable: true,
                    cell: (row) => (
                      <span className="w-100 text-center">
                        {row.totalUsuarios}
                      </span>
                    ),
                  },
                  {
                    name: "N° Productos",
                    selector: "totalProductos",
                    sortable: true,
                    cell: (row) => (
                      <span className="w-100 text-center">
                        {row.totalProductos}
                      </span>
                    ),
                  },
                  {
                    name: "Acciones",
                    selector: "action",
                    cell: (row) => (
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <Button
                          className="brand2-btn"
                          variant="contained"
                          color="primary"
                          endIcon={<ArrowForwardIosIcon />}
                          onClick={() =>
                            history.push({
                              pathname: `/admin/sucursal/new/${props.match.params.id}`,
                              state: {
                                id: row.id_sucursal_bodega_ubicacion,
                              },
                            })
                          }
                        >
                          <span
                            style={{ marginBottom: "-4px", fontSize: "12px" }}
                          >
                            Ver detalles
                          </span>
                        </Button>
                      </div>
                    ),
                  },
                ]}
                data={stores}
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se encontraron sucursales"
              />
            )}
          </TabPanel>

          <TabPanel value={configTab} index={2} className="config-tab">
            <div className="d-flex justify-content-between align-items-center flex-wrap my-2">
              <div className="d-flex align-items-center">
                {storeUsers.length > 0 ? (
                  <CSVLink
                    data={storeUsers.map(
                      ({ nombre, sucursal, tipo_nombre, correo }) => ({
                        nombre,
                        sucursal,
                        tipo_nombre,
                        correo,
                      })
                    )}
                    filename={`Usuarios de ${empresaInfo.nombre}.csv`}
                  >
                    <Button
                      className="btn-cs1 mt-2 mx-1"
                      variant="contained"
                      color="primary"
                    >
                      Descargar
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  </CSVLink>
                ) : (
                  <Button
                    className="btn-cs1 mt-2 mx-1"
                    variant="contained"
                    color="primary"
                  >
                    Descargar
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                )}
                <div className="ml-3">
                  <p className="mb-0">Filtrar por:</p>
                  <div className="d-flex">
                    <div className="d-flex align-items-center flex-wrap">
                      <FormControl variant="outlined" className="px-1">
                        <Select
                          native
                          className="sm-select"
                          onChange={(event) =>
                            setUserTypeFilter(event.target.value)
                          }
                        >
                          <option value="">Todos los Tipos de usuario</option>
                          {userTypes.map((type) => (
                            <option value={type.id_usuario_tipo_web_app}>
                              {type.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="d-flex align-items-center flex-wrap">
                      <FormControl variant="outlined" className="px-1">
                        <Select
                          native
                          className="sm-select"
                          onChange={(event) =>
                            setSucursalFilter(event.target.value)
                          }
                        >
                          <option value="">Todas las Sucursales</option>
                          {stores.map((store) => (
                            <option value={store.id_sucursal_bodega_ubicacion}>
                              {store.nombre_sucursal}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                className="btn-cs1 mt-2 mx-1"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                component={Link}
                to={"/admin/users/new/" + props.match.params.id}
              >
                <span style={{ marginBottom: "-4px" }}>Crear Usuario</span>
              </Button>
            </div>

            {usersLoader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" color="black" />
              </div>
            ) : (
              <DataTable
                className="datatable-cs datatable-cs-v2 "
                title={"s0"}
                columns={[
                  {
                    name: "Nombre",
                    selector: "nombre",
                    sortable: true,
                  },
                  {
                    name: "Sucursal",
                    selector: "sucursal",
                    sortable: true,
                  },
                  {
                    name: "Tipo de usuario",
                    selector: "tipo_nombre",
                    sortable: true,
                  },
                  {
                    name: "Correo electrónico",
                    selector: "correo",
                    sortable: true,
                  },
                  {
                    name: "Acciones",
                    selector: "action",
                    cell: (row) => (
                      <div className="text-center w-100">
                        <FormControl variant="outlined">
                          <Select
                            native
                            value=""
                            onChange={(e) => usersAction(e, row)}
                            className="cs-select"
                          >
                            <option value="">Acciones</option>
                            <option value="ver">Ver detalles</option>
                            <option value="eliminar">Eliminar usuario</option>
                          </Select>
                        </FormControl>
                      </div>
                    ),
                  },
                ]}
                data={storeUsers}
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se encontraron usuarios"
              />
            )}
          </TabPanel>

          <TabPanel value={configTab} index={3} className="config-tab">
            {subscriptionLoader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" color="black" />
              </div>
            ) : (
              <>
                {clientSubscription[0].numero_licencias === "" ? (
                  <form onSubmit={handleSubmit(handlePayment)}>
                    <React.Fragment>
                      <div className="card content-card card-body register-card p-5">
                        {saveLoader ? (
                          <CircularProgress size={26} className="mx-auto" />
                        ) : (
                          <>
                            <h1>
                              <strong>
                                Realiza el pago de suscripcion de la empresa
                              </strong>
                            </h1>

                            <div className="row mt-4">
                              <div className="col-lg-6">
                                <p className="mt-3 mb-0">
                                  <strong>Plan a contratar</strong>
                                </p>
                                <Select
                                  native
                                  fullWidth
                                  value={plan}
                                  onChange={(e) => changePlan(e)}
                                >
                                  {plans.map((plan) => (
                                    <option value={plan.id_planes}>
                                      {plan.descripcion}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                              <div className="col-lg-2">
                                <p className="mt-3 mb-0">
                                  <strong>N° de licencias</strong>
                                </p>
                                <TextField
                                  type="number"
                                  className="w-100"
                                  fullWidth
                                  value={cantLicencias}
                                  onChange={(e) => changeTotalToPay(e)}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        N°
                                      </InputAdornment>
                                    ),
                                    inputProps: { min: 1 },
                                  }}
                                />
                              </div>
                              <div className="col-lg-4">
                                <p className="mt-3 mb-0">
                                  <strong>Total a pagar</strong>
                                </p>
                                <TextField
                                  type="number"
                                  className="w-100"
                                  placeholder=""
                                  fullWidth
                                  value={costoTotal}
                                  onChange={(e) =>
                                    setCostoTotal(e.target.value)
                                  }
                                  // NOT CHANGED
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        Q.
                                      </InputAdornment>
                                    ),
                                    disabled: true,
                                  }}
                                />
                              </div>
                              <div className="col-lg-4 mt-2">
                                <p className="mt-3 mb-0">
                                  <strong>
                                    Frecuencia de pago (en meses):*
                                  </strong>
                                </p>
                                <TextField
                                  id="payment_frequency"
                                  value={frecuenciaPago}
                                  onChange={(e) =>
                                    setFrecuenciaPago(e.target.value)
                                  }
                                  margin="normal"
                                  placeholder=""
                                  type="text"
                                  fullWidth
                                  className="my-auto"
                                  disabled
                                />
                              </div>

                              <div className="col-lg-4 mt-2">
                                <label style={{ fontSize: "0.8rem" }}>
                                  <strong>Renovación automática</strong>
                                </label>

                                <label
                                  class="toggle-control ml-3"
                                  style={{ left: "-15px", height: "40px" }}
                                >
                                  <input
                                    type="checkbox"
                                    value={auto_renewal}
                                    onChange={() =>
                                      setAutoRenewal(!auto_renewal)
                                    }
                                    checked={auto_renewal}
                                  />
                                  <span class="control" />
                                </label>
                              </div>

                              <div className="col-md-6 text-center my-5">
                                <TextField
                                  style={{ width: "293px" }}
                                  variant="outlined"
                                  value={name}
                                  name="name"
                                  onChange={handleInputChange}
                                  onFocus={handleInputFocus}
                                  placeholder="Nombre del tarjetahabiente"
                                />
                                <div className="d-block my-auto">
                                  <PaymentInputsWrapper {...wrapperProps}>
                                    <svg
                                      {...getCardImageProps({
                                        images,
                                      })}
                                    />
                                    <input
                                      {...getCardNumberProps({
                                        onChange: handleInputChange,
                                        onFocus: handleInputFocus,
                                      })}
                                      name="number"
                                      value={number}
                                    />
                                    <input
                                      {...getExpiryDateProps({
                                        onChange: handleInputChange,
                                        onFocus: handleInputFocus,
                                      })}
                                      name="expiry"
                                      value={expiry}
                                    />
                                    <input
                                      {...getCVCProps({
                                        onChange: handleInputChange,
                                        onFocus: handleInputFocus,
                                      })}
                                      name="cvc"
                                      value={cvc}
                                    />
                                  </PaymentInputsWrapper>
                                </div>
                              </div>
                              <div className="col-md-6 my-5">
                                <Cards
                                  cvc={cvc}
                                  expiry={expiry}
                                  focused={focus}
                                  name={name}
                                  number={number}
                                />
                              </div>
                            </div>
                            <div className="row mt-4 justify-content-center">
                              <div className="col-lg-4 d-flex justify-content-center">
                                {submitLoader ? (
                                  <CircularProgress
                                    size={26}
                                    className="mx-auto"
                                  />
                                ) : (
                                  <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    endIcon={
                                      <ArrowForwardIosIcon
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      />
                                    }
                                    style={{
                                      color: "white",
                                      height: "fit-content",
                                      textTransform: "none",
                                    }}
                                    className="ml-auto gradient_bg_vanilla"
                                    type="submit"
                                  >
                                    Pagar
                                  </Button>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </React.Fragment>
                  </form>
                ) : (
                  <>
                    <div className="row mb-4">
                      <div className="col-md-4 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Tipo de membresía</strong>
                        </label>

                        <FormControl variant="outlined" className="w-100">
                          <Select
                            inputRef={register}
                            name="plan"
                            native
                            className="cs-select1 select-w100"
                            required={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EditIcon style={{ color: "#0160E7" }} />
                                </InputAdornment>
                              ),
                            }}
                          >
                            {/* <option value="">Seleccione una frecuencia de pago</option> */}
                            <option value="" selected>
                              {clientSubscription[0].plan.descripcion}
                            </option>
                          </Select>
                        </FormControl>
                      </div>

                      <div className="col-md-4 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Método de pago</strong>
                        </label>

                        <FormControl variant="outlined" className="w-100">
                          <Select
                            inputRef={register}
                            name="metodo_pago"
                            native
                            className="cs-select1 select-w100"
                            required={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EditIcon style={{ color: "#0160E7" }} />
                                </InputAdornment>
                              ),
                            }}
                          >
                            {/* <option value="">Seleccione método de pago</option> */}
                            <option value="" selected>
                              XpressPago
                            </option>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-4 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Próxima facturación</strong>
                        </label>

                        <TextField
                          value={moment(
                            clientSubscription[0].fecha_final
                          ).format("YYYY-MM-DD")}
                          type="date"
                          className="w-100"
                          placeholder="0"
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: (
                              <InputAdornment position="end">
                                <EditIcon style={{ color: "#0160E7" }} />
                              </InputAdornment>
                            ),
                          }}
                          inputRef={register}
                          onChange={(val) => console.log(val.target.value)}
                          required={true}
                          name="proxima_facturacion"
                        />
                      </div>

                      {/* Second Row */}
                      <div className="col-md-2 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>N° licencias:</strong>
                        </label>

                        <FormControl variant="outlined" className="w-100">
                          <TextField
                            value={clientSubscription[0].numero_licencias}
                            type="number"
                            className="w-100"
                            placeholder="0"
                            inputRef={register}
                            /* onChange={(val) => console.log(val.target.value)} */
                            required={true}
                            name="numero_licencias"
                          />
                        </FormControl>
                      </div>

                      <div className="col-md-3 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Dar de baja al cliente</strong>
                        </label>
                        <p className="mb-0">
                          Esta acción restringirá inmediatamente el acceso del
                          cliente a su cuenta Cloud
                        </p>
                      </div>

                      <div className="col-md-3 mt-3">
                        <Button
                          className="btn-cs1 mx-1 mt-1 delete-btn"
                          variant="contained"
                          color="primary"
                          onClick={deleteSubscription}
                        >
                          Dar de baja a cliente
                        </Button>
                      </div>
                      <div className="col-md-4 mt-3" />
                    </div>

                    {/* <div className="d-flex justify-content-between align-items-center flex-wrap my-2">
							<Button className="btn-cs1 mt-2 mx-1" variant="contained" color="primary">
								Descargar
								<CloudDownloadIcon className="ml-2" />
							</Button>

							<div>
								<p className="mb-0">Filtrar por:</p>
								<div className="d-flex align-items-center flex-wrap">
									<DateRangePicker
										onChange={(val) => handleDateChange(val)}
										value={dateRange}
										format={'dd-MM-y'}
										className="date-picker-cs px-1"
									/>
								</div>
							</div>
						</div> */}

                    <DataTable
                      className="datatable-cs datatable-cs-v2 "
                      title={"s0"}
                      columns={[
                        {
                          name: "Fecha / Hora",
                          selector: "fecha_inicio",
                          sortable: true,
                          cell: (row) => (
                            <div className="w-100">
                              {moment(row.fecha_inicio).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </div>
                          ),
                        },
                        {
                          name: "Concepto",
                          selector: "plan.descripcion",
                          sortable: true,
                        },
                        {
                          name: "Método",
                          selector: "metodo",
                          sortable: true,
                          cell: (row) => (
                            <div className="text-center w-100">XpressPago</div>
                          ),
                        },
                        {
                          name: "Monto",
                          selector: "plan.monto",
                          sortable: true,
                          cell: (row) => (
                            <div className="text-center w-100">
                              Q. {row.plan.monto}
                            </div>
                          ),
                        },
                        /* {
									name: 'Acciones',
									selector: 'action',
									cell: (row) => (
										<div className="text-center w-100">
											<Button
												className="btn-cs1 brand2-btn m-4"
												variant="contained"
												color="primary"
												to={'/admin/membresia'}
												component={Link}
												style={{ paddingBottom: '0px' }}
												size={'small'}
											>
												<CloudDownloadIcon className="mr-3" style={{ marginBottom: '3px' }} />
												Factura
											</Button>
										</div>
									)
								} */
                      ]}
                      data={clientSubscription}
                      pagination
                      paginationIconFirstPage={null}
                      paginationIconLastPage={null}
                      paginationComponentOptions={{
                        rangeSeparatorText: "de",
                        noRowsPerPage: true,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: "All",
                      }}
                      noDataComponent="No se encontraron datos de membresía"
                    />
                  </>
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={configTab} index={4} className="config-tab">
            {showProducts ? (
              <React.Fragment>
                <div className="row mt-3">
                  <div
                    className="col-md-3 px-2"
                    style={{ borderRight: "1px solid #E0E0E0" }}
                  >
                    <p
                      className="mb-1"
                      style={{ fontSize: "1.1rem", color: "black" }}
                    >
                      Total de productos
                    </p>
                    <h5 className="mb-0" style={{ color: "#0160E7" }}>
                      {productsLoader ? (
                        <div className="text-center">
                          <CircularProgress
                            size={26}
                            className="mx-auto"
                            color="black"
                          />
                        </div>
                      ) : (
                        <strong>
                          {companyProducts.items
                            ? companyProducts.items.length
                            : 0}
                        </strong>
                      )}
                    </h5>
                  </div>
                  <div className="col-md-3 px-2">
                    <p
                      className="mb-1"
                      style={{ fontSize: "1.1rem", color: "black" }}
                    >
                      Precio promedio
                    </p>
                    <h5 className="mb-0" style={{ color: "#0160E7" }}>
                      {productsLoader ? (
                        <div className="text-center">
                          <CircularProgress
                            size={26}
                            className="mx-auto"
                            color="black"
                          />
                        </div>
                      ) : (
                        <strong>Q. {companyProducts.precio_promedio}</strong>
                      )}
                    </h5>
                  </div>
                  <div className="col-md-3 px-2" />
                  <div className="col-md-3 px-2 text-right">
                    <Button
                      className="btn-cs1 mt-2 mx-1"
                      variant="contained"
                      color="primary"
                    >
                      <span
                        style={{ marginBottom: "-4px" }}
                        onClick={() => setShowProducts(false)}
                      >
                        Ver estadísticas
                      </span>
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#E0E0E0",
                  }}
                  className="my-3"
                />

                <div className="row">
                  <div className="col-lg-4 my-1"></div>
                  <div className="col-lg-4 my-1" />
                  <div className="col-lg-4 d-flex align-items-center my-1">
                    <div class="input-group search-bar">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar por nombre o sucursal"
                        onChange={(e) => setProductsFilter(e.target.value)}
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {productsLoader ? (
                  <div className="text-center">
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      color="black"
                    />
                  </div>
                ) : (
                  <DataTable
                    className="datatable-cs datatable-cs-v2 mt-2"
                    title={"s"}
                    columns={[
                      {
                        name: "Nombre",
                        cell: (row) => (
                          <div className="w-100 text-center">{row.nombre}</div>
                        ),
                        sortable: true,
                      },

                      {
                        name: "Sucursal",
                        cell: (row) => (
                          <div className="w-100 text-center">
                            {row.sucursal}
                          </div>
                        ),
                        sortable: true,
                      },
                      {
                        name: "Precio",
                        cell: (row) => (
                          <div className="w-100 text-center">
                            Q.
                            {row.descuento_precio
                              ? parseFloat(row.descuento_precio.toFixed(2))
                              : parseFloat(row.precio_venta.toFixed(2))}
                          </div>
                        ),
                        sortable: true,
                      },
                      {
                        name: "Cantidad",
                        cell: (row) => (
                          <div className="w-100 text-center">{row.stock}</div>
                        ),
                        sortable: true,
                      },
                      {
                        name: (
                          <div className="d-flex align-items-center">
                            <CloseIcon
                              style={{ fontSize: "16px" }}
                              onClick={() => hideCol(0)}
                            />
                            <span style={{ paddingTop: "2px" }}>
                              Distribuidor
                            </span>
                          </div>
                        ),
                        cell: (row) => (
                          <div className="w-100 text-center">
                            {row.distribuidor}
                          </div>
                        ),
                        sortable: true,
                        omit: hideCols[0],
                      },
                      {
                        name: (
                          <div className="d-flex align-items-center">
                            <CloseIcon
                              style={{ fontSize: "16px" }}
                              onClick={() => hideCol(1)}
                            />
                            <span style={{ paddingTop: "2px" }}>Categoría</span>
                          </div>
                        ),
                        cell: (row) => (
                          <div className="w-100 text-center">
                            {row.categoria}
                          </div>
                        ),
                        sortable: true,
                        omit: hideCols[1],
                      },
                      {
                        name: (
                          <div className="d-flex align-items-center">
                            <CloseIcon
                              style={{ fontSize: "16px" }}
                              onClick={() => hideCol(2)}
                            />
                            <span style={{ paddingTop: "2px" }}>Empaque</span>
                          </div>
                        ),
                        cell: (row) => (
                          <div className="w-100 text-center">{row.empaque}</div>
                        ),
                        sortable: true,
                        omit: hideCols[2],
                      },
                      {
                        name: (
                          <div className="d-flex align-items-center">
                            <CloseIcon
                              style={{ fontSize: "16px" }}
                              onClick={() => hideCol(3)}
                            />
                            <span style={{ paddingTop: "2px" }}>División</span>
                          </div>
                        ),
                        cell: (row) => (
                          <div className="w-100 text-center">
                            {row.division}
                          </div>
                        ),
                        sortable: true,
                        omit: hideCols[3],
                      },
                      {
                        name: (
                          <div className="d-flex align-items-center">
                            <CloseIcon
                              style={{ fontSize: "16px" }}
                              onClick={() => hideCol(4)}
                            />
                            <span style={{ paddingTop: "2px" }}>Contenido</span>
                          </div>
                        ),
                        cell: (row) => (
                          <div className="w-100 text-center">
                            {row.contenido}
                          </div>
                        ),
                        sortable: true,
                        omit: hideCols[4],
                      },
                      {
                        name: (
                          <div className="d-flex align-items-center text-center">
                            <CloseIcon
                              style={{ fontSize: "16px" }}
                              onClick={() => hideCol(5)}
                            />
                            <span style={{ paddingTop: "2px" }}>
                              Unidad de venta
                            </span>
                          </div>
                        ),
                        cell: (row) => (
                          <div className="w-100 text-center">
                            {row.vendido_por}
                          </div>
                        ),
                        sortable: true,
                        omit: hideCols[5],
                      },
                    ]}
                    data={
                      companyFilteredProducts.items
                        ? companyFilteredProducts.items
                        : []
                    }
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se ha encontrado ningún resultado"
                  />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div>
                    <ButtonGroup
                      className="tab-buttons my-2"
                      color="primary"
                      aria-label="outlined primary button group"
                      size="small"
                    >
                      <Button
                        style={{ fontSize: "15px" }}
                        className={type === 4 ? "active" : ""}
                        onClick={() => setType(4)}
                      >
                        Anual
                      </Button>
                      <Button
                        style={{ fontSize: "15px" }}
                        className={type === 3 ? "active" : ""}
                        onClick={() => setType(3)}
                      >
                        Mensual
                      </Button>
                      <Button
                        style={{ fontSize: "15px" }}
                        className={type === 2 ? "active" : ""}
                        onClick={() => setType(2)}
                      >
                        Semanal
                      </Button>
                    </ButtonGroup>
                  </div>

                  <div className="d-flex align-items-center my-2">
                    <div className="stat-legend">
                      <div
                        className="stat-color"
                        style={{ backgroundColor: "#1A2EAB" }}
                      />
                      <strong>Items creados</strong>
                      <ButtonGroup
                        color="primary"
                        size="small"
                        style={{ margin: "0 10px" }}
                      >
                        <Button
                          className={
                            !displayItems ? "legend_enable" : "legend_disable"
                          }
                          onClick={() => setDisplayItems(false)}
                        >
                          Ocultar
                        </Button>
                        <Button
                          className={
                            displayItems ? "legend_enable" : "legend_disable"
                          }
                          onClick={() => setDisplayItems(true)}
                        >
                          Mostrar
                        </Button>
                      </ButtonGroup>
                    </div>
                    <div className="stat-legend">
                      <div
                        className="stat-color"
                        style={{ backgroundColor: "#08BFE6" }}
                      />
                      <strong>Transacciones</strong>
                      <ButtonGroup
                        color="primary"
                        size="small"
                        style={{ margin: "0 10px" }}
                      >
                        <Button
                          className={
                            !displayTranstactions
                              ? "legend_enable"
                              : "legend_disable"
                          }
                          onClick={() => setDisplayTranstactions(false)}
                        >
                          Ocultar
                        </Button>
                        <Button
                          className={
                            displayTranstactions
                              ? "legend_enable"
                              : "legend_disable"
                          }
                          onClick={() => setDisplayTranstactions(true)}
                        >
                          Mostrar
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>

                {statsLoader ? (
                  <div className="text-center">
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      color="black"
                    />
                  </div>
                ) : (
                  <ResponsiveContainer width="100%" height={323}>
                    <BarChart
                      width={500}
                      height={300}
                      data={companyStats}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="2 2" />
                      <XAxis dataKey="name" />
                      <YAxis tickFormatter={(text) => `${text}`} />
                      <Tooltip />

                      {displayItems ? (
                        <Bar dataKey="items" fill="#1A2EAB" barSize={10} />
                      ) : (
                        ""
                      )}
                      {displayTranstactions ? (
                        <Bar
                          dataKey="transacciones"
                          fill="#1FE3D8"
                          barSize={10}
                        />
                      ) : (
                        ""
                      )}
                    </BarChart>
                  </ResponsiveContainer>
                )}

                <div className="row mt-2">
                  <div
                    className="col-md-3 px-2"
                    style={{ borderRight: "1px solid #E0E0E0" }}
                  >
                    <p
                      className="mb-1"
                      style={{ fontSize: "1.1rem", color: "black" }}
                    >
                      Total de transacciones
                    </p>
                    <h5 className="mb-0" style={{ color: "#0160E7" }}>
                      <strong>
                        {companyStats.length > 0
                          ? companyStats.reduce(
                              (a, b) => a + b.transacciones,
                              0
                            )
                          : ""}
                      </strong>
                    </h5>
                  </div>
                  <div className="col-md-3 px-2">
                    <p
                      className="mb-1"
                      style={{ fontSize: "1.1rem", color: "black" }}
                    >
                      Total de items creados
                    </p>
                    <h5 className="mb-0" style={{ color: "#0160E7" }}>
                      <strong>
                        {companyStats.length > 0
                          ? companyStats.reduce((a, b) => a + b.items, 0)
                          : ""}
                      </strong>
                    </h5>
                  </div>
                  <div className="col-md-3 px-2">
                    <Button
                      className="btn-cs1 mt-2 mx-1"
                      variant="contained"
                      color="primary"
                    >
                      <span
                        style={{ marginBottom: "-4px" }}
                        onClick={() => setShowProducts(true)}
                      >
                        Ver productos
                      </span>
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </TabPanel>

          <TabPanel value={configTab} index={5} className="config-tab">
            <div className="row mb-4">
              <div className="col-md-6 mt-3">
                <div
                  className="card shadow mb-4 download-excel"
                  onClick={(event) => null}
                >
                  <div className="p-2 d-flex align-items-center">
                    <CloudDownloadIcon
                      className="mx-2"
                      style={{ color: "#08BFE6", fontSize: "30px" }}
                    />
                    <a
                      href={
                        base_url_images +
                        "files/formato_carga_masiva_items.xlsx"
                      }
                      className="massive-load-button"
                    >
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Descargar la plantilla Excel base</strong>
                      </label>
                      <p style={{ fontSize: "0.75rem" }} className="mb-0">
                        Con esta plantilla evitará que surjan errores al subir
                        sus productos.
                      </p>
                    </a>
                  </div>
                </div>

                <div className="card shadow mb-4">
                  <div className="p-2 d-flex align-items-center">
                    <CloudUploadIcon
                      className="mx-2"
                      style={{ color: "#08BFE6", fontSize: "30px" }}
                    />
                    <div>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Subir plantilla Excel</strong>
                      </label>
                      <p style={{ fontSize: "0.75rem" }} className="mb-0">
                        Cargue su plantilla con los datos de sus productos.
                      </p>
                    </div>
                  </div>
                  <input
                    onChange={(event) => sendFile(event)}
                    type="file"
                    name="images"
                    className="file-input-excel"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <p>Indique sucursal donde será cargado los nuevos productos:</p>
                <FormControl variant="outlined" className="w-100">
                  <Select
                    name="sucursal"
                    native
                    value={file_sucursal}
                    onChange={(event) => setFile_sucursal(event.target.value)}
                    className="cs-select1 select-w100"
                  >
                    <option value="">Seleccione sucursal</option>
                    {stores.map((store) => (
                      <option value={store.id_sucursal_bodega_ubicacion}>
                        {store.nombre_sucursal}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                {massiveUploadLoader ? (
                  <div className="text-center">
                    <CircularProgress
                      size={26}
                      className="mx-auto mt-4"
                      color="black"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </TabPanel>
        </div>
      </form>
      <div class="modal fade" id="confirmDeletion" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea eliminar este usuario?</strong>
              </h4>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => deleteUser(userToDelete)}
                >
                  Sí, eliminar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
