import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import TodayIcon from "@material-ui/icons/Today";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CreditCardIcon from "@material-ui/icons/CreditCard";

const General = () => {
  return (
    <div className="row" style={{ height: "100%" }}>
      <div className="col-md-6">
        <div
          className="card shadow-sm py-1"
          style={{
            border: "none",
            background: "rgb(251,251,251)",
          }}
        >
          <div className="card-body d-flex">
            <div
              className="border"
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10%",
                background: "rgb(242,242,242)",
                lineHeight: "50px",
                textAlign: "center",
              }}
            >
              <ShoppingCartIcon />
            </div>
            <div className="ml-2">
              <strong>Tasa de recompra</strong>
              <br />
              <ArrowDropUpIcon
                className="text-success"
                style={{ fontSize: "28px" }}
              />
              44%
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div
          className="card shadow-sm py-1"
          style={{ border: "none", background: "rgb(251,251,251)" }}
        >
          <div className="card-body d-flex">
            <div
              className="border"
              style={{
                width: 50,
                height: 50,
                borderRadius: "10%",
                background: "rgb(242,242,242)",
                lineHeight: "50px",
                textAlign: "center",
              }}
            >
              <TodayIcon />
            </div>
            <div className="ml-2">
              <strong>Última compra</strong>
              <br />
              Mayo, 22, 2021
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6" style={{ marginTop: 20 }}>
        <div
          className="card shadow-sm py-1"
          style={{ border: "none", background: "rgb(251,251,251)" }}
        >
          <div className="card-body d-flex">
            <div
              className="border"
              style={{
                width: 50,
                height: 50,
                borderRadius: "10%",
                background: "rgb(242,242,242)",
                lineHeight: "50px",
                textAlign: "center",
              }}
            >
              <LocalAtmIcon />
            </div>
            <div className="ml-2">
              <strong>Ticket Medio</strong>
              <br />
              <ArrowDropDownIcon
                className="text-danger"
                style={{ fontSize: "28px" }}
              />
              Q. 1,563.33
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6" style={{ marginTop: 20 }}>
        <div
          className="card shadow-sm py-1"
          style={{ border: "none", background: "rgb(251,251,251)" }}
        >
          <div className="card-body d-flex">
            <div
              className="border"
              style={{
                width: 50,
                height: 50,
                borderRadius: "10%",
                background: "rgb(242,242,242)",
                lineHeight: "50px",
                textAlign: "center",
              }}
            >
              <CreditCardIcon />
            </div>
            <div className="ml-2">
              <strong>Método Más usado</strong>
              <br />
              Efectivo
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
