import React, { useState, useEffect } from "react";
import { getLocalParseToken } from "../../helpers/handleLocalStorage";
import API from "../../api";
import BottomSection from "./BottomSection";
import Customers from "./Customers";
import Products from "./Productos";
import Sells from "./Sells/Sells";

import TopBar from "./TopBar/TopBar";
import { isCajero } from "../../helpers/isCajero";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const Informes = () => {
  const [summaryAleko, setSummaryAleko] = useState({});
  const [divisions, setDivisions] = useState([]);
  const [actualSucursal, setActualSucursal] = useState({});
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  let default_dates = [
    new Date(new Date(new Date().getTime() + 3 * 60 * 60 * 1000)),
    new Date(new Date(new Date().getTime() + 3 * 60 * 60 * 1000)),
  ];
  const [dateRange, setDateRange] = useState(default_dates);
  // sucursales
  useEffect(() => {
    if (isCajero()) {
      toast.warn("No tienes acceso para acceder a este apartado", {
        autoClose: 10000,
      });
      history.goBack();
    }
    const getDivisions = async () => {
      const { data } = await API.products.getProductAttributes();
      setDivisions(data.data.divisions);
    };
    getDivisions();
  }, []);

  // await API.sucursal.getAll(parseInt(idToSearch), true);

  // summary;
  useEffect(() => {
    if (divisions.length) {
      getSummary();
    }
  }, [divisions]);

  useEffect(() => {
    if (actualSucursal?.id_linea_web_app) {
      getSummary(actualSucursal.id_linea_web_app);
    }
  }, [actualSucursal, dateRange, dateRange]);

  // useEffect(() => {
  //   const getData = async () => {
  //     const { data } = await API.dashboard.getSummary({
  //       id_sucursal_bodega_ubicacion: id_sucursal,
  //     });
  //     setFacturacion(data.data.facturacion);
  //   };
  //   getData();
  // }, []);

  // useEffect(() => {
  //   const getTicket = async () => {
  //     const { data } = await API.dashboard.getStatistics({ tipo_ordenar: 4 });
  //     setTicket(data.data.ticket_promedio);
  //   };
  //   getTicket();
  // }, []);

  const getSummary = async (idSucursal = "") => {
    setLoader(true);

    const id_empresa = getLocalParseToken("id_empresa");
    const id_sucursal =
      idSucursal === "" ? divisions[0].id_linea_web_app : idSucursal;
    const response = await API.informesAleko.getSummaryInformesAleko(
      id_empresa,
      id_sucursal,
      dateRange[0],
      dateRange[1]
    );

    // console.log("summary", response.data);

    setSummaryAleko(response.data.data);
    setLoader(false);
  };

  // console.log("actualSucursal", actualSucursal);

  return (
    <>
      <TopBar
        divisions={divisions}
        summaryAleko={summaryAleko}
        loader={loader}
        actualSucursal={actualSucursal}
        setActualSucursal={setActualSucursal}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Customers
        clients={summaryAleko.tablaClientes}
        cartera={summaryAleko.graficaClientes}
        formasPago={summaryAleko.graficaFormaPago}
        loader={loader}
      />
      <Sells
        sucursals={divisions}
        actualSucursal={actualSucursal}
        setActualSucursal={setActualSucursal}
      />
      <Products
        summary={summaryAleko.categoria}
        actualSucursal={actualSucursal}
        setActualSucursal={setActualSucursal}
        loader={loader}
      />
      <BottomSection actualSucursal={actualSucursal} loader={loader} />
    </>
  );
};

export default Informes;
