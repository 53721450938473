import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const getAll = async (data) => {
  return await axios.post(`${base_url}/web/get-traslados`, qs.stringify(data), {
    headers: headers(),
  });
};

const getById = async (data) => {
  return await axios.post(
    `${base_url}/web/get-traslado-id`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const createNew = async (data) => {
  console.log("Submitting this data to traslado:", data);
  return await axios.post(`${base_url}/web/add-traslado`, qs.stringify(data), {
    headers: headers(),
  });
};

const updateQuantity = async (data) => {
  return await axios.post(
    `${base_url}/web/update-traslado_item`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const reject = async (data) => {
  return await axios.post(
    `${base_url}/web/reject-traslado`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const accept = async (data) => {
  return await axios.post(
    `${base_url}/web/approve-traslado`,
    qs.stringify(data),
    { headers: headers() }
  );
};

export default {
  getAll,
  createNew,
  getById,
  updateQuantity,
  reject,
  accept,
};
