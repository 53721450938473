import React, { useState } from "react";
import HomeIcon from "@material-ui/icons/Home";
import { CircularProgress } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MinimizeIcon from "@material-ui/icons/Minimize";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";

const TopBar = (props) => {
  const {
    divisions,
    summaryAleko,
    actualSucursal,
    setActualSucursal,
    loader,
    dateRange,
    setDateRange,
  } = props;

  const handleChangeSucursal = (e) => {
    const { value } = e.target;
    const selected = divisions.filter(
      (item) => item.id_linea_web_app === Number(value)
    );
    setActualSucursal(selected[0]);
  };

  const handleDate = (val) => {
    setDateRange(val);
  };

  return (
    <div>
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Informes</strong>
        </h4>
        <select className="form-select" onChange={handleChangeSucursal}>
          {/* <option selected>Todas las tiendas</option> */}
          {divisions?.map((division) => (
            <option value={division.id_linea_web_app}>
              {division.descripcion}
            </option>
          ))}
        </select>
        <DateRangePicker
          onChange={handleDate}
          value={dateRange}
          format={"dd-MM-y"}
          className="date-picker-cs ml-1 bg-white"
        />
      </div>
      <div className="row">
        <div className="col-lg-4 my-2">
          <div
            className="card py-3 shadow"
            style={{ border: "none", flexDirection: "row" }}
          >
            <div
              className="p-3 ml-2 border"
              style={{
                background: "rgb(242,242,242)",
                borderRadius: "10%",
                width: 60,
                height: 60,
              }}
            >
              <LocalAtmIcon style={{ color: "#03a9f4" }} />
            </div>
            <div>
              <h5 className="ml-1">
                <strong>
                  {" "}
                  Facturación total
                  {/* (hoy) */}
                </strong>
              </h5>{" "}
              {loader ? (
                <div className="mx-auto text-center">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <span className="ml-1">
                  Q. {summaryAleko.facturacion_today}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 my-2">
          <div
            className="card py-3 shadow"
            style={{ border: "none", flexDirection: "row" }}
          >
            <div
              className="p-3 ml-2 border"
              style={{
                background: "rgb(242,242,242)",
                borderRadius: "10%",
                width: 60,
                height: 60,
              }}
            >
              {summaryAleko.porcentaje_crecimiento &&
                (summaryAleko.porcentaje_crecimiento > 0 ? (
                  <TrendingUpIcon style={{ color: "#03a9f4" }} />
                ) : summaryAleko.porcentaje_crecimiento === 0 ? (
                  <TrendingFlatIcon />
                ) : (
                  summaryAleko.porcentaje_crecimiento < 0 && (
                    <TrendingDownIcon className="text-danger" />
                  )
                ))}
              {/* {random > 0 ? (
                <TrendingUpIcon style={{ color: "#03a9f4" }} />
              ) : random === 0 ? (
                <TrendingFlatIcon />
              ) : (
                <TrendingDownIcon className="text-danger" />
              )} */}
            </div>
            <div>
              <h5 className="ml-1">
                <strong>
                  {" "}
                  Crecimiento
                  {/* (Mensual) */}
                </strong>
              </h5>{" "}
              {loader ? (
                <div className="mx-auto text-center">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <span className="ml-1">
                  {summaryAleko.porcentaje_crecimiento &&
                    (summaryAleko.porcentaje_crecimiento > 0 ? (
                      <ArrowDropUpIcon className="text-success" />
                    ) : summaryAleko.porcentaje_crecimiento === 0 ? (
                      <MinimizeIcon />
                    ) : (
                      summaryAleko.porcentaje_crecimiento < 0 && (
                        <ArrowDropDownIcon className="text-danger" />
                      )
                    ))}
                  {summaryAleko.porcentaje_crecimiento
                    ? summaryAleko.porcentaje_crecimiento.toFixed(2)
                    : "0.00"}
                  %
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-4 my-2">
          <div
            className="card py-3 shadow"
            style={{ border: "none", flexDirection: "row" }}
          >
            <div
              className="p-3 ml-2 border"
              style={{
                background: "rgb(242,242,242)",
                borderRadius: "10%",
                width: 60,
                height: 60,
              }}
            >
              <LocalMallIcon style={{ color: "#03a9f4" }} />
            </div>
            <div>
              <h5 className="ml-1">
                <strong> Ticket medio</strong>
              </h5>{" "}
              {loader ? (
                <div className="mx-auto text-center">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <span className="ml-1">Q. {summaryAleko.ticket_medio}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
