import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { isCmi } from "../../helpers/isCmi";
import { getMoneda } from "../../helpers/moneda";
import millify from "millify";

//herramienta reporte
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//reportes
import RankingCompradoresReporte from "../../reportes/rankingCompradoresReporte";
import VentasReporte from "../../reportes/ventasReporte";
import InventarioTodosReporte from "../../reportes/inventarioTodosReporte";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import { Pagination } from "@material-ui/lab";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import $ from "jquery";
import moment from "moment";
import "moment/locale/es";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import API, { base_url_images } from "../../api";
// styles
import "./custom.css";
import { isGerenteMercadeo } from "../../helpers/isGerenteMercadeo";

// styles
//icons

// components
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tab-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//data

export default function Dashboard(props) {
  const moneda = getMoneda();
  let history = useHistory();
  let default_dates = [new Date(), new Date()];
  const pms = localStorage.getItem("pms");
  window.addEventListener("popstate", () => {
    // console.log()

    if (localStorage.getItem("token") === null) {
      history.push("/login");
      //props.history.push("/login");
    }
  });

  useEffect(() => {
    if (pms) {
      validarUsuario();
    }
  }, [pms]);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // states
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [facturacionTime, setFacturacionTime] = useState("Anual");
  const [est_compradores, setEst_compradores] = useState("Anual");
  const [productsMenu, setProductsMenu] = useState(null);
  const [productsTab, setProductsTab] = useState(0);
  const [statisticsLoader, setStatisticsLoader] = useState(true);
  const [graphsLoader, setGraphsLoader] = useState(true);
  const [productsLoader, setProductsLoader] = useState(true); // Have to set to true when i load products
  const [printLoader, setPrintLoader] = useState(false);
  const [productsToPrint, setProductsToPrint] = useState([]);
  const [summary, setSummary] = useState({
    totalInventoryProduct: 0,
    totalSalesOfTheDay: 0,
    totalNumberOfUser: 0,
    totalLowInventoryProduct: 0,
    totalProduct: 0,
    totalOutOfStockProduct: 0,
    totalNearExpiry: 0,
    totalPrefacturas: 0,
    totalItemsPrefacturas: 0
  });
  const [lowStockProducts, setLowStockProducts] = useState({
    paginatedData: [],
    page: "",
    perPage: "",
    total: "",
    lastPage: "",
  });
  const [allProducts, setAllProducts] = useState({
    paginatedData: [],
    page: "",
    perPage: "",
    total: "",
    lastPage: "",
  });
  const [bestSellingProducts, setBestSellingProducts] = useState({
    paginatedData: [],
    page: "",
    perPage: "",
    total: "",
    lastPage: "",
  });
  const [nearExpProducts, setNearExpProducts] = useState({
    paginatedData: [],
    page: "",
    perPage: "",
    total: "",
    lastPage: "",
  });
  const [reports, setReports] = useState({
    paginatedData: [],
    page: "1",
    perPage: "10",
    total: "1",
    lastPage: "1",
  });

  const [buyerData, setBuyerData] = useState([]);
  const [dailyFacturacionData, setDailyFacturacionData] = useState([]);
  const [weeklyFacturacionData, setWeeklyFacturacionData] = useState([]);
  const [monthlyFacturacionData, setMonthlyFacturacionData] = useState([]);
  const [anualFacturacionData, setAnualFacturacionData] = useState([]);
  const [dailyPaymentMethodsStats, setDailyPaymentMethodsStats] = useState([]);
  const [weeklyPaymentMethodsStats, setWeeklyPaymentMethodsStats] = useState(
    []
  );
  const [monthlyPaymentMethodsStats, setMonthlyPaymentMethodsStats] = useState(
    []
  );
  const [anualPaymentMethodsStats, setAnualPaymentMethodsStats] = useState([]);
  const [dailyClientsStats, setDailyClientsStats] = useState([]);
  const [weeklyClientsStats, setWeeklyClientsStats] = useState([]);
  const [monthlyClientsStats, setMonthlyClientsStats] = useState([]);
  const [anualClientsStats, setAnualClientsStats] = useState([]);
  const [dailyClientsInfo, setDailyClientsInfo] = useState([]);
  const [weeklyClientsInfo, setWeeklyClientsInfo] = useState([]);
  const [monthlyClientsInfo, setMonthlyClientsInfo] = useState([]);
  const [anualClientsInfo, setAnualClientsInfo] = useState([]);
  const [facturacionData, setFacturacionData] = useState([]);
  const [paymentMethodsStats, setPaymentMethodsStats] = useState([]);
  const [clientsStats, setClientsStats] = useState([]);
  const [clientsInfo, setClientsInfo] = useState({});
  const [recentTraslado, setRecentTraslado] = useState(null);
  const [trasladoLoader, setTrasladoLoader] = useState(true);
  const [lowStockLoader, setLowStockLoader] = useState(true);
  const [mostSaleLoader, setMostSaleLoader] = useState(true);
  const [nearExpiryLoader, setNearExpiryLoader] = useState(true);
  const [selectedAllProducts, setSelectedAllProducts] = useState([]);
  const [selectedBestSellingProducts, setSelectedBestSellingProducts] =
    useState([]);
  const [selectedLowStockProducts, setSelectedLowStockProducts] = useState([]);
  const [selectedNearExpProducts, setSelectedNearExpProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [dateRange, setDateRange] = useState(default_dates);
  const [salesReportsLoader, setSalesReportsLoader] = useState(true);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [divisionesFilter, setDivisionesFilter] = useState("");
  const [sucursalFilter, setSucursalFilter] = useState("");
  const [salesDivisions, setSalesDivisions] = useState([]);
  const [salesCategories, setSalesCategories] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [sellerFilter, setSellerFilter] = useState("");
  const [textStadisticdate, setTextstatisticDate] = useState("");
  const [subscriptionData, setSubscriptionData] = useState({
    plan: {
      descripcion: "",
      numero_mes: "",
    },
  });
  const [subscriptionDates, setSubscriptionsDates] = useState({
    days: "",
    hours: "",
    minutes: "",
  });

  // empresas para cmi y usuario gerente
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [isGerente, setIsGerente] = useState(false);
  const [isAdministrador, setIsAdministrador] = useState(false);
  const [empresasGerenteCmi, setEmpresasGerenteCmi] = useState([]);
  const [sucursalesGerenteCmi, setSucursalesGerenteCmi] = useState([]);
  const [actualSucursalGerente, setActualSucursalGerente] = useState("");
  const [actualEmpresaGteCmi, setActualEmpresaGteCmi] = useState({});

  //cajero disable
  const [cajeroDisable, setCajeroDisable] = useState(false); // variable que verifica si es cajero

  useEffect(() => {
    validarUsuario();
  }, []);

  useEffect(() => {
    if (isGerente) {
      let user = JSON.parse(window.localStorage.getItem("pms"));
      // console.log("user ->", user);
      getEmpresasGerenteCmi(user.id_new_user);
    }
  }, [isGerente]);

  //place info google
  const [placeInfo, setPlaceInfo] = useState({ objeto: null, status: "ready" });

  const validarUsuario = () => {
    if (JSON.parse(localStorage.getItem("pms"))) {
      if (getTipeUser("CAJERO")) {
        // si es usuario tipo cajero
        setCajeroDisable(true);
      }
    }

    if (isCmi()) {
      let userSavedLocal = JSON.parse(window.localStorage.getItem("pms"));

      if (userSavedLocal !== null) {
        setTipoUsuario(userSavedLocal.tipo_usuario.toLowerCase());

        if (
          getTipeUser("gerente regional") ||
          getTipeUser("gerente mercadeo")
        ) {
          setIsGerente(true);
          //getEmpresasGerenteCmi(userSavedLocal.id_new_user);
        }

        if (getTipeUser("administrador")) {
          setIsAdministrador(true);
        }
      }
    }
  };

  const getTipeUser = (userToSearch) => {
    let user = JSON.parse(window.localStorage.getItem("pms"));
    if (user) {
      return user.tipo_usuario.toLowerCase() === userToSearch.toLowerCase()
        ? true
        : false;
    }
  };

  const getSucursalPlace = (id) => {
    setPlaceInfo({ objeto: null, status: "loading" });

    API.reviews
      .getAllDataPlace("ChIJISed7IxfKowRk_BMoIbEimk")
      .then((res) => {
        setPlaceInfo({ objeto: res.data.result, status: "ready" });
      })
      .catch((err) => {
        setPlaceInfo({ objeto: null, status: "ready" });
        let response = err.response;
        if (!response) {
          toast.error("Ha ocurrido un error al obtener la informacion.", {
            autoClose: 10000,
          });
          return;
        }

        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error con las sucursales");
          toast.error("Ha ocurrido un error al obtener la informacion", {
            autoClose: 10000,
          });
        }
      });
  };

  useEffect(() => {
    render_fact_graph("Anual");
    if (!isGerente || !isAdministrador) {
      getSucursals();
      console.log("sdsadad");
    }
    getSalesFilters();

    //Get recent traslado
    getRecentTraslado();
    oneSignalUpsert();

    userSubscriptions();
  }, []);

  useEffect(() => {
    if (subscriptionData.fecha_final !== "") {
      const interval = setInterval(() => {
        updateRemainingTime(subscriptionData.fecha_final);
      }, 60000); // 60K MS = 1 second
      return () => clearInterval(interval);
    }
  }, [subscriptionData]);

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirm_deletion").modal("hide");
    };
  }, []);

  // set states
  const changeSucursal = (event) => {
    if (event?.target?.value) {
      console.log("sucursal seleccionada (value) ->", event.target.value);

      const sucursalName = event.target.value;
      setSucursal(sucursalName);
      setSucursalFilter(sucursalName);
      getSalesReports(1, event.target.value);
      getAllSellers(event.target.value);
      getStatistics(1, false, event.target.value);
      getStatistics(2, false, event.target.value);
      getStatistics(3, false, event.target.value);
      getStatistics(4, true, event.target.value);
      getAllProducts(1, event.target.value);
      getLowStock(1, event.target.value);
      getNearExpiry(1, event.target.value);
      getMostSale(1, event.target.value);

      getSummary(event.target.value);
    } else {
      console.log("sucursal seleccionada (id) ->", event);

      getAllProducts(1, event);
      getLowStock(1, event);
      getNearExpiry(1, event);
      getMostSale(1, event);

      getSummary(event);
    }
  };

  // gerente general

  useEffect(() => {
    if (sucursal === "") {
      setPlaceInfo({ objeto: null, status: "ready" });
    } else {
      getSucursalPlace(sucursal);
    }
  }, [sucursal]);

  const changeSurcusal_sales = (event) => {
    setSucursalFilter(event.target.value);
    getSalesReports(1, event.target.value);
    getAllSellers(event.target.value);
  };

  const changeDivison_sales = (event) => {
    setDivisionesFilter(event.target.value);
  };

  const changeCategory_sales = (event) => {
    setCategoryFilter(event.target.value);
  };

  const changeSeller_sales = (event) => {
    setSellerFilter(event.target.value);
  };

  useEffect(() => {
    getSalesReports(1, divisionesFilter);
  }, [categoryFilter, divisionesFilter, dateRange, sellerFilter]);

  const render_fact_graph = (time) => {
    let days = [
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
      "Domingo",
    ];
    let months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    let date = new Date(Date.now());
    let dateToday = date.toLocaleDateString();
    let dayName = days[date.getDay()];
    let monthName = months[date.getMonth()];
    let year = date.getFullYear();

    setFacturacionTime(time);
    switch (time) {
      case "Diario":
        setFacturacionData(dailyFacturacionData);
        setTextstatisticDate(dayName + " " + dateToday);
        break;

      case "Semanal":
        setFacturacionData(weeklyFacturacionData);
        setTextstatisticDate("Mes: " + monthName);
        break;

      case "Mensual":
        setFacturacionData(monthlyFacturacionData);
        setTextstatisticDate("Mes: " + monthName);
        break;

      case "Anual":
        setFacturacionData(anualFacturacionData);
        setTextstatisticDate("Año: " + year);
        break;

      default:
        break;
    }
  };

  const render_compradores_graph = (time) => {
    setEst_compradores(time);
    switch (time) {
      case "Diario":
        setPaymentMethodsStats(dailyPaymentMethodsStats);
        setClientsStats(dailyClientsStats);
        setClientsInfo(dailyClientsInfo);
        break;
      case "Semanal":
        setPaymentMethodsStats(weeklyPaymentMethodsStats);
        setClientsStats(weeklyClientsStats);
        setClientsInfo(weeklyClientsInfo);
        break;
      case "Mensual":
        setPaymentMethodsStats(monthlyPaymentMethodsStats);
        setClientsStats(monthlyClientsStats);
        setClientsInfo(monthlyClientsInfo);
        break;
      case "Anual":
        setPaymentMethodsStats(anualPaymentMethodsStats);
        setClientsStats(anualClientsStats);
        setClientsInfo(anualClientsInfo);
        break;
      default:
        break;
    }
  };
  const handleProductTabs = (event, newValue) => {
    setProductsTab(newValue);
  };

  const buyer_profile = (nit) => {
    const newWindow = window.open(
      `/#/app/clients/profile/${nit}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const productAction = (e, id) => {
    if (e.target.value === "detalles")
      history.push(`/app/inventario/product_details/${id}`);
    if (e.target.value === "eliminar") {
      setSelectedProductId(id);
      $("#confirm_deletion").modal();
    }
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;

        console.log("sucursales", response.data);

        if (response.success) {
          let sucursales_ordenadas = orderBySucursal(response.data);
          setSucursals(sucursales_ordenadas);
          setSucursal(sucursales_ordenadas[0].id_sucursal_bodega_ubicacion);
          setSucursalFilter(
            sucursales_ordenadas[0].id_sucursal_bodega_ubicacion
          );

          getSummary(sucursales_ordenadas[0].id_sucursal_bodega_ubicacion);

          getStatistics(
            1,
            false,
            sucursales_ordenadas[0].id_sucursal_bodega_ubicacion
          );
          getStatistics(
            2,
            false,
            sucursales_ordenadas[0].id_sucursal_bodega_ubicacion
          );
          getStatistics(
            3,
            false,
            sucursales_ordenadas[0].id_sucursal_bodega_ubicacion
          );
          getStatistics(
            4,
            true,
            sucursales_ordenadas[0].id_sucursal_bodega_ubicacion
          );
          getAllProducts(
            1,
            sucursales_ordenadas[0].id_sucursal_bodega_ubicacion
          );
          getLowStock(1, sucursales_ordenadas[0].id_sucursal_bodega_ubicacion);
          getNearExpiry(
            1,
            sucursales_ordenadas[0].id_sucursal_bodega_ubicacion
          );
          getMostSale(1, sucursales_ordenadas[0].id_sucursal_bodega_ubicacion);
          getSalesReports(
            1,
            sucursales_ordenadas[0].id_sucursal_bodega_ubicacion
          );
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        if (!response) {
          toast.error(
            "Ha ocurrido un error al obtener los productos por vencer.",
            { autoClose: 10000 }
          );
          return;
        }

        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error con las sucursales");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const deleteProduct = () => {
    API.products
      .deleteById({ id_new_item: selectedProductId })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("Producto eliminado", { autoClose: 10000 });
          getSummary(1);
          getAllProducts(1);
          getMostSale(1);
          getLowStock(1);
          getNearExpiry(1);
          $("#confirm_deletion").modal("hide");
        } else {
          toast.error(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        let response = err.response;

        console.log("Ha ocurrido un error al anular el producto");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getSummary = async (sucursalId) => {
    if (isGerente || isAdministrador) {
      setStatisticsLoader(true);

      let idUserGet = JSON.parse(window.localStorage.getItem("pms"));

      try {
        const response = await API.dashboard.getSummaryNew("", sucursalId);

        console.log("summary to gerente and admin ->", response.data.data);

        if (response.data.success) {
          setSummary({
            totalNearExpiry: response.data.data.totalNearExpiry,
            totalInventoryProduct: response.data.data.totalInventoryProduct,
            totalSalesOfTheDay: response.data.data.facturacion,
            totalNumberOfUser: response.data.data.totalNumberOfUser,
            totalLowInventoryProduct:
              response.data.data.totalLowInventoryProduct,
            totalProduct: response.data.data.totalProduct,
            totalExpiredProduct: response.data.data.totalExpiredProduct,
            totalOutOfStockProduct: response.data.data.totalOutOfStockProduct,
            totalItemsPrefacturas: response.data.data.totalItemsPrefacturas,
            totalPrefacturas: response.data.data.totalPrefacturas,
          });

          setStatisticsLoader(false);
        }
      } catch (error) {
        console.log("Error al buscar estadisticas ->", error);
        setStatisticsLoader(false);
      }
    } else {
      API.dashboard
        //.getSummary(null, sucursalId ? sucursalId : sucursal)
        .getSummaryNew("", sucursalId)
        .then((res) => {
          let response = res.data;
          console.log("summary ->", response);

          if (response.success) {
            setSummary({
              totalNearExpiry: response.data.totalNearExpiry,
              totalInventoryProduct: response.data.totalInventoryProduct,
              totalSalesOfTheDay: response.data.facturacion,
              totalNumberOfUser: response.data.totalNumberOfUser,
              totalLowInventoryProduct: response.data.totalLowInventoryProduct,
              totalProduct: response.data.totalProduct,
              totalExpiredProduct: response.data.totalExpiredProduct,
              totalOutOfStockProduct: response.data.totalOutOfStockProduct,
              totalItemsPrefacturas: response.data.totalItemsPrefacturas,
              totalPrefacturas: response.data.totalPrefacturas,
            });

            if (isGerente === false) {
              // console.log("buyer ->", response.data.allClientes);
              //setBuyerData(response.data.clients)
              setBuyerData(response.data.allClientes);
              console.log(response.data.allClientes);
            }
          } else {
            toast.warning(response.message);
          }
          setStatisticsLoader(false);
        })
        .catch((err) => {
          setStatisticsLoader(false);
          if (err?.message?.data) {
            let response = err.response.data;

            if (typeof response.message !== "undefined") {
              toast.error(response.message, {
                autoClose: 10000,
              });
            } else {
              console.log("Ha ocurrido un error con las estadisticas");
              toast.error("Ha ocurrido un error", { autoClose: 10000 });
            }
          }
        });
    }
  };

  const getLowStock = (page, sucursalId) => {
    setLowStockLoader(true);
    API.dashboard
      .getLowStockProducts({
        page,
        id_sucursal_bodega_ubicacion: sucursalId ? sucursalId : sucursal,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          response.data.paginatedData.map((product) => {
            if (product.vencimiento_fecha === "")
              product.vencimiento_fecha = "N/A";
          });
          setLowStockLoader(false);
          setLowStockProducts(response.data);
        } else {
          setLowStockLoader(false);
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setLowStockLoader(false);
        let response = err.response;

        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log(
            "Ha ocurrido un error con los productos de bajo inventario"
          );
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSalesReports = (page, sucursalId, filters = {}) => {
    setSalesReportsLoader(true);
    let currentDates = [
      moment(dateRange[0]).format("YYYY-MM-DD"),
      moment(dateRange[1]).format("YYYY-MM-DD"),
    ];
    if (sucursalId == "") {
      if (sucursalFilter == "") {
        return;
      }
    }
    let data = {
      id_sucursal_bodega_ubicacion: sucursalId ? sucursalId : sucursalFilter,
      page,
      from_date: currentDates[0],
      to_date: currentDates[1],
      division: divisionesFilter,
      category: categoryFilter,
      search_text: textFilter,
      id_vendedor_web_app: sellerFilter,
    };

    API.dashboard
      .getSalesReports(data)
      .then((res) => {
        let response = res.data;

        if (response.success) {
          let data = response.data;
          data.paginatedData = data.data;
          setReports(data);
        } else {
          setReports({
            paginatedData: [],
            page: "1",
            perPage: "10",
            total: "1",
            lastPage: "1",
          });
        }

        setSalesReportsLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        if (!response) {
          toast.error(
            "Ha ocurrido un error al obtener los productos por vencer.",
            { autoClose: 10000 }
          );
          return;
        }

        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, { autoClose: 10000 });
        } else {
          console.log("Ha ocurrido un error al obtener todos los productos");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setSalesReportsLoader(false);
      });
  };

  const handlePageforSales = (event, value) => {
    getSalesReports(value);
  };

  const getAllProducts = (page, sucursalId) => {
    // setProductsLoader(true);
    //setStatisticsLoader(true);
    API.dashboard
      .getAllProducts({
        id_sucursal_bodega_ubicacion: sucursalId ? sucursalId : sucursal,
        page,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          response.data.paginatedData.map((product) => {
            if (product.vencimiento_fecha === "")
              product.vencimiento_fecha = "N/A";
          });
          setAllProducts(response.data);
        }
        setProductsLoader(false);
        // setStatisticsLoader(false);
      })
      .catch((err) => {
        setProductsLoader(false);
        // setStatisticsLoader(false);
        let response = err.response;
        if (!response) {
          toast.error(
            "Ha ocurrido un error al obtener los productos por vencer.",
            { autoClose: 10000 }
          );
          return;
        }

        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error al obtener todos los productos");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const printAllProducts = () => {
    setPrintLoader(true);
    API.products
      .getAll({
        id_sucursal_bodega_ubicacion: sucursal,
        pagination: true,
        page: 1,
        limit: 1000000000000,
      })
      .then((res) => {
        let response = res.data.data;
        setProductsToPrint(response);
        document.getElementById("descargar-reporteInventario-Todos2").click();
        setProductsToPrint([]);
        setPrintLoader(false);
      })
      .catch((err) => {
        setPrintLoader(false);
        toast.error("Ha ocurrido un error al imprimir los productos", {
          autoClose: 10000,
        });
      });
  };

  const getNearExpiry = (page, sucursalId) => {
    API.dashboard
      .getNearExpiryProducts({
        page,
        id_sucursal_bodega_ubicacion: sucursalId ? sucursalId : sucursal,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          response.data.paginatedData.map((product) => {
            if (product.vencimiento_fecha === "")
              product.vencimiento_fecha = "N/A";
          });
          setNearExpiryLoader(false);
          setNearExpProducts(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
          setNearExpiryLoader(false);
        }
      })
      .catch((err) => {
        setNearExpiryLoader(false);
        let response = err.response;
        if (!response) {
          toast.error(
            "Ha ocurrido un error al obtener los productos por vencer.",
            { autoClose: 10000 }
          );
          return;
        }
        if (typeof response.data !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log(
            "Ha ocurrido un error con los productos cercanos a vencimiento"
          );
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getMostSale = (page, sucursalId) => {
    setMostSaleLoader(true);
    API.dashboard
      .getMostSaleProducts({
        page,
        id_sucursal_bodega_ubicacion: sucursalId ? sucursalId : sucursal,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          response.data.paginatedData.map((product) => {
            if (product.vencimiento_fecha === "")
              product.vencimiento_fecha = "N/A";
          });
          setMostSaleLoader(false);
          setBestSellingProducts(response.data);
        } else {
          setMostSaleLoader(false);
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setMostSaleLoader(false);
        let response = err.response;
        if (!response) {
          toast.error(
            "Ha ocurrido un error al obtener los productos por vencer.",
            { autoClose: 10000 }
          );
          return;
        }

        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log(
            "Ha ocurrido un error con los productos de mayores ventas"
          );
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getStatistics = (
    orderType,
    initialLoad,
    id_sucursal_bodega_ubicacion
  ) => {
    API.dashboard
      .getStatistics({
        tipo_ordenar: orderType,
        id_sucursal_bodega_ubicacion: id_sucursal_bodega_ubicacion,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          // Daily
          if (orderType === 1) {
            setDailyFacturacionData(response.data.net_incomes);
            setDailyPaymentMethodsStats(response.data.payment_methods);
            setDailyClientsStats(response.data.clients);
            setDailyClientsInfo({
              tasa_recompra: response.data.tasa_recompra,
              ticket_promedio: response.data.ticket_promedio,
            });
          }
          // Weekly
          if (orderType === 2) {
            setWeeklyFacturacionData(response.data.net_incomes);
            setWeeklyPaymentMethodsStats(response.data.payment_methods);
            setWeeklyClientsStats(response.data.clients);
            setWeeklyClientsInfo({
              tasa_recompra: response.data.tasa_recompra,
              ticket_promedio: response.data.ticket_promedio,
            });
          }
          // Monthly
          if (orderType === 3) {
            let net_incomes = [];
            response.data.net_incomes.map((income) => {
              net_incomes.push({
                name: "Día " + income.name,
                "Ingresos netos": income["Ingresos netos"],
              });
            });
            setMonthlyFacturacionData(net_incomes);
            setMonthlyPaymentMethodsStats(response.data.payment_methods);

            let clients_stats = [];
            response.data.clients.map((stat) => {
              clients_stats.push({
                name: "Día " + stat.name,
                cantidad: stat.cantidad,
              });
            });
            setMonthlyClientsStats(clients_stats);

            setMonthlyClientsInfo({
              tasa_recompra: response.data.tasa_recompra,
              ticket_promedio: response.data.ticket_promedio,
            });
          }
          // Yearly
          if (orderType === 4) {
            setAnualFacturacionData(response.data.net_incomes);
            setAnualPaymentMethodsStats(response.data.payment_methods);
            setAnualClientsStats(response.data.clients);
            setAnualClientsInfo({
              tasa_recompra: response.data.tasa_recompra,
              ticket_promedio: response.data.ticket_promedio,
            });
            if (initialLoad) {
              setFacturacionData(response.data.net_incomes);
              setPaymentMethodsStats(response.data.payment_methods);
              setClientsStats(response.data.clients);
              setClientsInfo({
                tasa_recompra: response.data.tasa_recompra,
                ticket_promedio: response.data.ticket_promedio,
              });
              setGraphsLoader(false);
            }
          }
        }
      })
      .catch((err) => {
        let response = err.response;
        if (!response) {
          toast.error(
            "Ha ocurrido un error al obtener los productos por vencer.",
            { autoClose: 10000 }
          );
          return;
        }

        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log(
            "Ha ocurrido un error al obtener las estadisticas gráficas"
          );
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getRecentTraslado = () => {
    setTrasladoLoader(true);
    API.dashboard
      .getRecentTraslado()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setRecentTraslado(response.data);
        }
        setTrasladoLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        if (!response) {
          toast.error(
            "Ha ocurrido un error al obtener los productos por vencer.",
            { autoClose: 10000 }
          );
          return;
        }

        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error al obtener un traslado reciente");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const openTraslado = (id) => {
    /* const newWindow = window.open(
      "/#/app/traslados/details/" + id,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null; */
    history.push("/app/traslados");
  };

  const oneSignalUpsert = () => {
    if (!!localStorage.getItem("player_id")) {
      let data = {
        player_id: localStorage.getItem("player_id"),
        flag_vencer: 1,
      };
      API.notifications
        .addToNotifyList(data)
        .then((res) => {
          let response = res.data;
        })
        .catch((err) => {
          let response = err.response;

          console.log(
            "An error has occurred while upserting user to notifications list"
          );
        });
    } else {
      console.log("player_id not found");
    }
  };

  const handleSelect = async (info) => {
    let { allSelected, selectedCount, selectedRows } = info;
    let data = selectedRows;

    if (productsTab === 0) {
      if (allSelected) {
        const response = await API.products.getAll({
          id_sucursal_bodega_ubicacion: sucursalFilter,
          no_combos: 1,
        });
        setSelectedAllProducts(response.data.data);
        return;
      }
      setSelectedAllProducts(data);
    } else if (productsTab === 1) {
      setSelectedBestSellingProducts(data);
    } else if (productsTab === 2) {
      setSelectedLowStockProducts(data);
    } else if (productsTab === 3) {
      setSelectedNearExpProducts(data);
    }
  };

  const handlePageChange = (event, value) => {
    if (productsTab === 0) {
      getAllProducts(value);
    } else if (productsTab === 1) {
      getMostSale(value);
    } else if (productsTab === 2) {
      getLowStock(value);
    } else if (productsTab === 3) {
      getNearExpiry(value);
    }
  };

  const changeDate = (value) => {
    setDateRange(value);
  };

  const getSalesFilters = () => {
    API.products
      .getProductAttributes({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          // console.log("Divisiones ->", response);

          let divisions = [];
          let categories = [];

          response.data.divisions.map((division) => {
            divisions.push({
              id: division.id_linea_web_app,
              descripcion: division.descripcion,
            });
          });

          response.data.categories.map((category) => {
            categories.push({
              id: category.id_clasificacion1_web_app,
              descripcion: category.descripcion,
            });
          });

          setSalesDivisions(sortAlphabetically(divisions));
          setSalesCategories(sortAlphabetically(categories));
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const sortAlphabetically = (array) => {
    let sortedArray = array.sort(function (a, b) {
      if (a.descripcion < b.descripcion) {
        return -1;
      }
      if (a.descripcion > b.descripcion) {
        return 1;
      }
      return 0;
    });
    return sortedArray;
  };

  const csvLink = useRef();

  const downloadReports = () => {
    if (reports.paginatedData.length > 0) {
      setDownloadLoader(true);

      let currentDates = [
        moment(dateRange[0]).format("YYYY-MM-DD"),
        moment(dateRange[1]).format("YYYY-MM-DD"),
      ];

      let data = {
        id_sucursal_bodega_ubicacion: sucursalFilter,
        from_date: currentDates[0],
        to_date: currentDates[1],
        division: divisionesFilter,
        category: categoryFilter,
        search_text: textFilter,
      };

      API.dashboard
        .getSalesReports(data)
        .then((res) => {
          let response = res.data;

          if (response.success) {
            let data = response.data;
            setDownloadData(data);
            setDownloadLoader(false);
            // csvLink.current.link.click();
            document.getElementById("descargar-reporteVentas").click();
          } else {
            console.log(response);
          }
          setDownloadLoader(false);
        })
        .catch((err) => {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
          setDownloadLoader(false);
        });
    } else {
      toast.error("No hay ventas con los filtros seleccionados", {
        autoClose: 10000,
      });
    }
  };

  const getAllSellers = (id) => {
    API.cajas
      .getCompanySellers({ id_sucursal_bodega_ubicacion: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSellers(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error al obtener los vendedores");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const userSubscriptions = () => {
    API.subscriptions
      .getUserSubscriptions()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSubscriptionData(response.data);

          updateRemainingTime(response.data.fecha_final);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const updateRemainingTime = (fecha_final) => {
    var duration = moment.duration(moment(fecha_final).diff());
    //Get Days and subtract from duration
    var days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, "days"));

    //Get hours and subtract from duration
    var hours = duration.hours();
    duration.subtract(moment.duration(hours, "hours"));

    //Get Minutes and subtract from duration
    var minutes = duration.minutes();
    duration.subtract(moment.duration(minutes, "minutes"));

    setSubscriptionsDates({
      days,
      hours,
      minutes,
    });
  };

  const orderBySucursal = (data) => {
    // extraemos la sucursal guardada
    let id_sucursal_local = window.localStorage.getItem("id_sucursal");
    let sucursal_filtrada = data.filter((item) =>
      item.id_sucursal_bodega_ubicacion == Number(id_sucursal_local)
        ? item.id_sucursal_bodega_ubicacion
        : ""
    );

    // onjetos que son diferentes a la extraidata
    let sucursales_diferentes = data.filter(
      (item) => item.id_sucursal_bodega_ubicacion != Number(id_sucursal_local)
    );

    let sucursales_ordenadas = [...sucursal_filtrada, ...sucursales_diferentes];
    return sucursales_ordenadas;
  };

  // para gerente regional cmi
  const getEmpresasGerenteCmi = async (id_user) => {
    let newEmpresa = [];

    try {
      const res = await API.users.getEmpresaPorUsuario(id_user);
      const data = await res.data.data;

      // filtrar las empresas
      data.forEach((empresa) => newEmpresa.push(empresa.empresa));

      let filtradas = newEmpresa.reduce((acc, el) => {
        if (!acc.find((item) => item.id_new_empresa == el.id_new_empresa)) {
          acc.push(el);
        }
        return acc;
      }, []);

      // seteamos las empresas
      setEmpresasGerenteCmi(filtradas);

      // ranking compradores
      const ranking = await API.dashboard.getSummaryCf(
        filtradas[0].id_new_empresa
      );
      setBuyerData(ranking.data.data);
      // console.log("ranking ->", ranking.data.data);

      // buscamos las sucursales de esa empresa
      await handleChangeEmpresaGteCmi(filtradas[0].id_new_empresa);
    } catch (error) {
      console.log("error al obtener empresas gerente ->", error);
    }
  };

  const handleChangeEmpresaGteCmi = async (event) => {
    let idToSearch = "";

    if (event?.target == undefined) {
      idToSearch = event;
    } else {
      const { name, value } = event.target;
      idToSearch = value;
    }

    setActualEmpresaGteCmi(idToSearch);

    try {
      // suscripciones
      const resSuscripcionEmpresa =
        await API.subscriptions.getUserSubscriptions(idToSearch);
      const suscripcion = await resSuscripcionEmpresa.data.data;
      setSubscriptionData(suscripcion);

      // traslados
      const resTrasladosEmpresa = await API.dashboard.getRecentTraslado(
        idToSearch
      );
      const traslados = await resTrasladosEmpresa.data.data;
      if (traslados.length <= 0) {
        toast.warning("No existen traslados para esta empresa", {
          autoClose: 10000,
        });
        setRecentTraslado(null);
      } else {
        //console.log('traslados ->', traslados)
        setRecentTraslado(traslados);
      }

      // sucursales
      const res = await API.sucursal.getAll(parseInt(idToSearch), true);
      const data = await res.data.data;
      setSucursals(data);
      setSucursalesGerenteCmi(data);
      console.log("sucursales ->", data);

      // ranking compradores
      const ranking = await API.dashboard.getSummaryCf(idToSearch);
      setBuyerData(ranking.data.data);
      // console.log("ranking ->", ranking.data.data);

      // //buscamos datos de la primera sucursal
      changeSucursal(data[0].id_sucursal_bodega_ubicacion);
      console.log(data[0].id_sucursal_bodega_ubicacion);
      // data empresa
      await getStatisticsByEmpresa(1, false, idToSearch);
      await getStatisticsByEmpresa(2, false, idToSearch);
      await getStatisticsByEmpresa(3, false, idToSearch);
      await getStatisticsByEmpresa(4, true, idToSearch);
    } catch (error) {
      console.log("error al traer sucursales para gerente ->", error);
    }
  };

  // estadisticas para gerente regional
  const getStatisticsByEmpresa = async (orderType, initialLoad, id_empresa) => {
    try {
      const res = await API.dashboard.getStatistics({
        tipo_ordenar: orderType,
        id_new_empresa: id_empresa,
      });
      const response = await res.data;

      if (response.success) {
        // Daily
        if (orderType === 1) {
          setDailyFacturacionData(response.data.net_incomes);
          setDailyPaymentMethodsStats(response.data.payment_methods);
          setDailyClientsStats(response.data.clients);
          setDailyClientsInfo({
            tasa_recompra: response.data.tasa_recompra,
            ticket_promedio: response.data.ticket_promedio,
          });
        }
        // Weekly
        if (orderType === 2) {
          setWeeklyFacturacionData(response.data.net_incomes);
          setWeeklyPaymentMethodsStats(response.data.payment_methods);
          setWeeklyClientsStats(response.data.clients);
          setWeeklyClientsInfo({
            tasa_recompra: response.data.tasa_recompra,
            ticket_promedio: response.data.ticket_promedio,
          });
        }
        // Monthly
        if (orderType === 3) {
          let net_incomes = [];
          response.data.net_incomes.map((income) => {
            net_incomes.push({
              name: "Día " + income.name,
              "Ingresos netos": income["Ingresos netos"],
            });
          });
          setMonthlyFacturacionData(net_incomes);
          setMonthlyPaymentMethodsStats(response.data.payment_methods);

          let clients_stats = [];
          response.data.clients.map((stat) => {
            clients_stats.push({
              name: "Día " + stat.name,
              cantidad: stat.cantidad,
            });
          });
          setMonthlyClientsStats(clients_stats);

          setMonthlyClientsInfo({
            tasa_recompra: response.data.tasa_recompra,
            ticket_promedio: response.data.ticket_promedio,
          });
        }
        // Yearly
        if (orderType === 4) {
          setAnualFacturacionData(response.data.net_incomes);
          setAnualPaymentMethodsStats(response.data.payment_methods);
          setAnualClientsStats(response.data.clients);
          setAnualClientsInfo({
            tasa_recompra: response.data.tasa_recompra,
            ticket_promedio: response.data.ticket_promedio,
          });
          if (initialLoad) {
            setFacturacionData(response.data.net_incomes);
            setPaymentMethodsStats(response.data.payment_methods);
            setClientsStats(response.data.clients);
            setClientsInfo({
              tasa_recompra: response.data.tasa_recompra,
              ticket_promedio: response.data.ticket_promedio,
            });
            setGraphsLoader(false);
          }
        }
      }
    } catch (err) {
      let response = err.response;
      if (!response) {
        toast.error(
          "Ha ocurrido un error al obtener los productos por vencer.",
          { autoClose: 10000 }
        );
        return;
      }

      if (typeof response.data.message !== "undefined") {
        toast.error(response.data.message, {
          autoClose: 10000,
        });
      } else {
        console.log(
          "Ha ocurrido un error al obtener las estadisticas gráficas"
        );
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      }
    }
  };

  const orderRankingByHigerValue = (arr) => {
    arr.sort((itemOne, itemTwo) => {
      if (itemOne.life_value > itemTwo.life_value) {
        return -1;
      } else if (itemOne.life_value < itemTwo.life_value) {
        return 1;
      } else {
        return 0;
      }
    });

    //console.log("precios buyer ->", arr);

    return arr;
  };

  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="chart-tooltip">
          <div className="">
            <span>{payload[0].payload.name}</span>
          </div>
          <div className="">
            <span>
              Ingresos netos:{" "}
              {formaterPriceFunction(
                parseFloat(payload[0].payload["Ingresos netos"]).toFixed(2) + ""
              )}
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <h4 className="mb-0">
          <strong>Dashboard</strong>
        </h4>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        {isGerenteMercadeo() && (
          <>
            <FormControl variant="outlined">
              <Select native onChange={handleChangeEmpresaGteCmi}>
                {empresasGerenteCmi.map((itemEmpresa) => (
                  <option value={itemEmpresa.id_new_empresa}>
                    {itemEmpresa.nombre}
                  </option>
                ))}
              </Select>
            </FormControl>

            <ArrowForwardIosIcon
              style={{ color: "#333333", fontSize: "12px" }}
              className="mx-2"
            />

            {/* <FormControl variant="outlined">
              <Select native onChange={handleChangeSucursalesGteCmi}>
                {sucursalesGerenteCmi.map((itemSucursal) => (
                  <option value={itemSucursal.id_sucursal_bodega_ubicacion}>
                    {itemSucursal.nombre_sucursal}
                  </option>
                ))}
              </Select>
            </FormControl> */}
          </>
        )}

        <FormControl variant="outlined">
          <Select native value={sucursal} onChange={changeSucursal}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* <ArrowForwardIosIcon
          style={{ color: '#333333', fontSize: '12px' }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={sucursal} onChange={changeSucursal}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl> */}
      </div>

      <div
        className="row card-wrapper  card-wrapper-dashboard"
        style={{ alignItems: "stretch" }}
      >
        <div className="col-xl-3 col-lg-6 mb-2">
          <div className="content-card card " style={{ height: "100%" }}>
            <div
              className="card-body card-dashboard"
              style={{ height: "100%" }}
            >
              <h5 style={{ color: "black" }}>N° Productos</h5>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ flexWrap: "wrap" }}
              >
                {statisticsLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong
                    className="mb-0"
                    style={{ fontSize: "1.2rem", marginRight: "30px" }}
                  >
                    {formaterPriceFunction(
                      parseFloat(summary.totalProduct) + ""
                    )}
                  </strong>
                )}

                {!isGerente && (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    endIcon={
                      <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                    }
                    style={{ fontWeight: "normal" }}
                    component={Link}
                    to={"/app/inventario"}
                  >
                    Ver detalles
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 mb-2">
          <div className="content-card card" style={{ height: "100%" }}>
            <div
              className="card-body card-dashboard"
              style={{ height: "100%" }}
            >
              <h5 style={{ color: "black" }}>Facturación del día</h5>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ flexWrap: "wrap" }}
              >
                {statisticsLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong
                    className="mb-0"
                    style={{ fontSize: "1.2rem", marginRight: "30px" }}
                  >
                    {formaterPriceFunction(
                      parseFloat(summary.totalSalesOfTheDay).toFixed(2) + ""
                    )}
                  </strong>
                )}

                {!isGerente && (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    endIcon={
                      <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                    }
                    style={{ fontWeight: "normal" }}
                    component={Link}
                    to={"/app/operaciones_historial"}
                  >
                    Ver detalles
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 mb-2">
          <div className="content-card card" style={{ height: "100%" }}>
            <div
              className="card-body card-dashboard"
              style={{ height: "100%" }}
            >
              <h5 style={{ color: "black" }}>Usuarios registrados</h5>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ flexWrap: "wrap" }}
              >
                {statisticsLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong
                    className="mb-0"
                    style={{ fontSize: "1.2rem", marginRight: "35px" }}
                  >
                    {formaterPriceFunction(
                      parseFloat(summary.totalNumberOfUser) + ""
                    )}
                  </strong>
                )}
                {!isGerenteMercadeo() && (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    endIcon={
                      <ArrowForwardIosIcon
                        style={{
                          fontSize: "10px",
                          marginBottom: "2px",
                        }}
                      />
                    }
                    style={{ fontWeight: "normal" }}
                    component={Link}
                    to={"/app/users"}
                  >
                    Ver detalles
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 mb-2">
          <div className="content-card card" style={{ height: "100%" }}>
            <div
              className="card-body card-dashboard"
              style={{ height: "100%" }}
            >
              <h5 style={{ color: "black" }}>N° Productos en Prefactura</h5>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ flexWrap: "wrap" }}
              >
                {statisticsLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong
                    className="mb-0"
                    style={{ fontSize: "1.2rem", marginRight: "30px" }}
                  >
                    {formaterPriceFunction(
                      parseFloat(summary.totalItemsPrefacturas) + ""
                    )}
                  </strong>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 mb-2">
          <div className="content-card card" style={{ height: "100%" }}>
            <div
              className="card-body card-dashboard"
              style={{ height: "100%" }}
            >
              <h5 style={{ color: "black" }}>Ordenes en Prefactura</h5>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ flexWrap: "wrap" }}
              >
                {statisticsLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong
                    className="mb-0"
                    style={{ fontSize: "1.2rem", marginRight: "30px" }}
                  >
                    {formaterPriceFunction(
                      parseFloat(summary.totalPrefacturas) + ""
                    )}
                  </strong>
                )}

                {!isGerente && (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    endIcon={
                      <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                    }
                    style={{ fontWeight: "normal" }}
                    component={Link}
                    to={"/app/pre-vender"}
                  >
                    Ver detalles
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        {isCmi() && (
          <div className="col-xl-3 col-lg-6 mb-2">
            <div className="content-card card" style={{ height: "100%" }}>
              <div
                className="card-body card-dashboard"
                style={{ height: "100%" }}
              >
                <h5 style={{ color: "black" }}>Calificación</h5>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ flexWrap: "wrap" }}
                >
                  {placeInfo.status === "loading" ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <strong className="mb-0" style={{ fontSize: "1.2rem" }}>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.48326 14.0819C8.80106 13.8901 9.19894 13.8901 9.51674 14.0819L13.151 16.2754C13.9083 16.7325 14.8425 16.0535 14.6416 15.192L13.6771 11.0578C13.5928 10.6963 13.7156 10.3178 13.9962 10.0748L17.2086 7.29189C17.8772 6.71268 17.5198 5.61442 16.6384 5.53964L12.4109 5.18098C12.0413 5.14962 11.7194 4.91629 11.5747 4.57479L9.92072 0.672371C9.57613 -0.140657 8.42387 -0.140659 8.07928 0.672369L6.42532 4.57479C6.28058 4.91629 5.95871 5.14962 5.58914 5.18098L1.36163 5.53964C0.480202 5.61442 0.122796 6.71268 0.791394 7.29189L4.00379 10.0748C4.28436 10.3178 4.40721 10.6963 4.32287 11.0578L3.35841 15.192C3.15745 16.0535 4.09168 16.7325 4.849 16.2754L8.48326 14.0819Z"
                          fill="#F2C94C"
                        />
                      </svg>
                      {placeInfo.objeto === null ? 0 : placeInfo.objeto.rating}{" "}
                      / 5
                    </strong>
                  )}
                  {!isGerenteMercadeo() && (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                      endIcon={
                        <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                      }
                      style={{ fontWeight: "normal" }}
                      component={Link}
                      to={"/app/reviews"}
                    >
                      Ver detalles
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-lg-8">
          <div className="content-card card">
            <div className="card-header">
              <h5 className="mb-0">
                <strong>Estadísticas</strong>
              </h5>
              <p className="mb-0" style={{ fontSize: "1.2rem" }}>
                Consulte de forma rápida las estadísticas de su negocio.
              </p>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h5 className="mb-0">Facturación neta</h5>
                <ButtonGroup
                  className="tab-buttons"
                  color="primary"
                  aria-label="outlined primary button group"
                  size="small"
                >
                  <Button
                    style={{ fontSize: "15px" }}
                    className={facturacionTime === "Anual" ? "active" : ""}
                    onClick={() => render_fact_graph("Anual")}
                  >
                    Año
                  </Button>
                  <Button
                    style={{ fontSize: "15px" }}
                    className={facturacionTime === "Mensual" ? "active" : ""}
                    onClick={() => render_fact_graph("Mensual")}
                  >
                    Mes
                  </Button>
                  <Button
                    style={{ fontSize: "15px" }}
                    className={facturacionTime === "Semanal" ? "active" : ""}
                    onClick={() => render_fact_graph("Semanal")}
                  >
                    Semana
                  </Button>
                  <Button
                    style={{ fontSize: "15px" }}
                    className={facturacionTime === "Diario" ? "active" : ""}
                    onClick={() => render_fact_graph("Diario")}
                  >
                    Día
                  </Button>
                </ButtonGroup>
              </div>

              <div className="graph-wrapper w-100">
                <ResponsiveContainer width="100%" height={250}>
                  {graphsLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <LineChart
                      data={facturacionData}
                      margin={{
                        top: 30,
                        right: 40,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis
                        tickFormatter={(text) =>
                          `${millify(parseFloat(text))} ${moneda}.`
                        }
                      />
                      <Tooltip content={<CustomizedTooltip />} />
                      <Line
                        connectNulls
                        type="monotone"
                        dataKey="Ingresos netos"
                        stroke="#08bfe6"
                        fill="#08bfe6"
                        strokeWidth={2}
                      />
                    </LineChart>
                  )}
                </ResponsiveContainer>
                {!graphsLoader ? (
                  <div className="text-center">
                    <label>{textStadisticdate}</label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 d-flex flex-column justify-content-between">
          <div className="content-card card card-type2 mb-2">
            <div className="card-body">
              <h4>
                <strong>Membresía</strong>
              </h4>
              <p>Días restantes:</p>
              <h5>
                <strong>
                  {subscriptionDates.days} días - {subscriptionDates.hours}{" "}
                  horas
                </strong>
              </h5>
              <p>Fecha de expiración:</p>
              <div className="d-flex justify-content-between align-items-center">
                <h5>
                  <strong>
                    {moment(subscriptionData.fecha_final).format("DD/MM/YYYY")}
                  </strong>
                </h5>
                {!isGerenteMercadeo() && (
                  <Button
                    className="btn-cs2"
                    variant="contained"
                    color="primary"
                    endIcon={
                      <ArrowForwardIosIcon
                        style={{
                          fontSize: "10px",
                          marginBottom: "2px",
                        }}
                      />
                    }
                    style={{ fontWeight: "normal" }}
                    component={Link}
                    to={"/app/profile"}
                  >
                    Renovar
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="content-card card card-type2 ">
            <div className="card-body">
              <h5>
                <strong>Traslados</strong>
              </h5>
              {trasladoLoader ? (
                <div className="text-center">
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    style={{ color: "white" }}
                  />
                </div>
              ) : (
                <>
                  {recentTraslado ? (
                    <>
                      <p>{recentTraslado.estado}</p>
                      <h4>
                        <strong>
                          {recentTraslado.origin_sucursal} a{" "}
                          {recentTraslado.destino_sucursal}
                        </strong>
                      </h4>
                    </>
                  ) : (
                    "Aún no tiene ningún traslado"
                  )}
                </>
              )}

              <div className="d-flex justify-content-end align-items-center">
                {trasladoLoader ? (
                  <div className="text-center">
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      style={{ color: "white" }}
                    />
                  </div>
                ) : (
                  <>
                    {recentTraslado
                      ? !isGerente && (
                          <Button
                            className="btn-cs2"
                            variant="contained"
                            color="primary"
                            endIcon={
                              <ArrowForwardIosIcon
                                style={{
                                  fontSize: "10px",
                                  marginBottom: "2px",
                                }}
                              />
                            }
                            style={{ fontWeight: "normal" }}
                            onClick={() =>
                              openTraslado(recentTraslado.id_traslado_web_app)
                            }
                          >
                            Más información
                          </Button>
                        )
                      : ""}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-4">
          <div className="card content-card">
            <div className="card-header gradient_bg">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0" style={{ color: "white" }}>
                  <strong>Ranking de compradores</strong>
                </h5>

                <CloudDownloadIcon
                  onClick={() => {
                    document
                      .getElementById("descargar-reporteRankingCompradores")
                      .click();
                  }}
                  style={{ color: "white", cursor: "pointer" }}
                />

                <ReactHTMLTableToExcel
                  id="descargar-reporteRankingCompradores"
                  className="buton-excel"
                  table="rankingCompradores-reporte-table"
                  filename={
                    "Ranking de compradores " + moment().format("DD-MM-YYYY")
                  }
                  sheet="tablexls"
                  buttonText="Descargar"
                />
                <RankingCompradoresReporte
                  objeto={buyerData}
                  isGerente={isGerente}
                ></RankingCompradoresReporte>
              </div>
            </div>

            <div class="card-body buyers-ranking-table scroll">
              {statisticsLoader ? (
                <div className="text-center">
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : (
                <>
                  {buyerData.length > 0 ? (
                    <>
                      {/* {isGerente &&
                        buyerData.map((buyer) => (
                          <div class="row buyer mx-0 mb-2">
                            <div
                              className="col-5"
                              style={{
                                borderRight: "1px solid #E0E0E0",
                              }}
                            >
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "0.85rem",
                                }}
                              >
                                <strong>Nombre</strong>
                              </p>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "0.85rem",
                                }}
                              >
                                {buyer.nombre}
                              </p>
                            </div>
                            <div
                              className="col-6"
                              style={{
                                borderRight: "1px solid #E0E0E0",
                              }}
                            >
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "0.85rem",
                                }}
                              >
                                <strong>Valor de vida del cliente</strong>
                              </p>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "0.85rem",
                                }}
                              >
                                {moneda}.{" "}
                                {buyer.life_value ? buyer.life_value : "0.00"}
                              </p>
                              <p
                                className="mb-0"
                                style={{ fontSize: "0.8rem" }}
                              >
                                <strong>Ticket Promedio</strong>
                              </p>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "0.85rem",
                                }}
                              >
                                {moneda}.{" "}
                                {buyer.half_ticket ? buyer.half_ticket : "0.00"}
                              </p>
                            </div>
                            <div className="col-1 d-flex align-items-center justify-content-center">
                              <IconButton
                                className="px-0"
                                onClick={(e) => buyer_profile(buyer.nit)}
                                disabled={isGerente ? true : false}
                              >
                                <ArrowForwardIosIcon
                                  className="primary_color"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                />
                              </IconButton>
                            </div>
                          </div>
                        ))} */}
                      {orderRankingByHigerValue(buyerData).map((buyer) => (
                        <div class="row buyer mx-0 mb-2">
                          <div
                            className="col-5"
                            style={{
                              borderRight: "1px solid #E0E0E0",
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "0.85rem",
                              }}
                            >
                              <strong>Nombre</strong>
                            </p>
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "0.85rem",
                              }}
                            >
                              {buyer.nombre_cliente_final
                                ? buyer.nombre_cliente_final
                                : buyer.nombre}
                            </p>
                          </div>
                          <div
                            className="col-6"
                            style={{
                              borderRight: "1px solid #E0E0E0",
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "0.85rem",
                              }}
                            >
                              <strong>Valor de vida del cliente</strong>
                            </p>
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "0.85rem",
                              }}
                            >
                              {moneda}.{" "}
                              {buyer?.value
                                ? formaterPriceFunction(
                                    buyer.value.toFixed(2) + ""
                                  )
                                : buyer.life_value
                                ? formaterPriceFunction(
                                    buyer.life_value.toFixed(2) + ""
                                  )
                                : "0.00"}
                            </p>
                            {isGerente || isAdministrador ? (
                              <>
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  <strong>Ticket Promedio</strong>
                                </p>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "0.85rem",
                                  }}
                                >
                                  {moneda}.{" "}
                                  {buyer?.half_ticket
                                    ? formaterPriceFunction(
                                        buyer.half_ticket.toFixed(2)
                                      )
                                    : "0.00"}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-1 d-flex align-items-center justify-content-center">
                            <IconButton
                              className="px-0"
                              disabled={isGerenteMercadeo()}
                              onClick={(e) =>
                                buyer_profile(buyer.id_cliente_web_app)
                              }
                            >
                              <ArrowForwardIosIcon
                                className="primary_color"
                                style={{
                                  fontSize: "12px",
                                }}
                              />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="text-center">
                      <p className="my-auto">
                        Aún no existen datos para este ranking
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="content-card card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h4 className="mb-0">
                  <strong>Estadísticas de los compradores</strong>
                </h4>
                <ButtonGroup
                  className="tab-buttons"
                  color="primary"
                  aria-label="outlined primary button group"
                  size="small"
                >
                  <Button
                    style={{ fontSize: "15px" }}
                    className={est_compradores === "Anual" ? "active" : ""}
                    onClick={() => render_compradores_graph("Anual")}
                  >
                    Año
                  </Button>
                  <Button
                    style={{ fontSize: "15px" }}
                    className={est_compradores === "Mensual" ? "active" : ""}
                    onClick={() => render_compradores_graph("Mensual")}
                  >
                    Mes
                  </Button>
                  <Button
                    style={{ fontSize: "15px" }}
                    className={est_compradores === "Semanal" ? "active" : ""}
                    onClick={() => render_compradores_graph("Semanal")}
                  >
                    Semana
                  </Button>
                  <Button
                    style={{ fontSize: "15px" }}
                    className={est_compradores === "Diario" ? "active" : ""}
                    onClick={() => render_compradores_graph("Diario")}
                  >
                    Día
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div className="card-body">
              <div class="row">
                <div
                  className="col-md-6 px-2 py-2"
                  style={{
                    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.06)",
                  }}
                >
                  <p
                    className="mb-0 text-center"
                    style={{ fontSize: "1.3rem" }}
                  >
                    <strong>Métodos de pagos</strong>
                  </p>
                  {graphsLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <>
                      {paymentMethodsStats[0] === 0 &&
                      paymentMethodsStats[1] === 0 &&
                      paymentMethodsStats[2] === 0 ? (
                        <div className="text-center">
                          <p>Aún no existen datos para este gráfico.</p>
                        </div>
                      ) : (
                        <ReactApexChart
                          options={{
                            tooltip: {
                              y: {
                                formatter: function (value) {
                                  return formaterPriceFunction(
                                    value.toFixed(2) + ""
                                  );
                                },
                              },
                            },
                            chart: {
                              width: "60%",
                              height: "100px",
                              type: "donut",
                            },
                            colors: [
                              "#00F2E4",
                              "#15B6D7" /* ,
                                                            "#192DA6", */,
                            ],
                            labels: [
                              "Efectivo",
                              "Tarjetas de débito / crédito" /* ,
                                                            "Gift Card", */,
                            ],
                            legend: {
                              position: "bottom",
                            },
                          }}
                          series={paymentMethodsStats.slice(0, 2)}
                          type="donut"
                        />
                      )}
                    </>
                  )}
                </div>
                <div
                  className="col-md-6 px-0 py-2"
                  style={{
                    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.06)",
                  }}
                >
                  <p
                    className="mb-0 text-center"
                    style={{ fontSize: "1.3rem" }}
                  >
                    <strong>Nuevos clientes</strong>
                  </p>
                  <ResponsiveContainer width="80%" height={250}>
                    {graphsLoader ? (
                      <div className="text-center">
                        <CircularProgress size={26} className="mx-auto" />
                      </div>
                    ) : (
                      <BarChart
                        width={500}
                        height={300}
                        data={clientsStats}
                        margin={{
                          top: 5,
                          bottom: 5,
                          left: -20,
                          right: 8,
                        }}
                      >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Bar dataKey="cantidad" barSize={10} fill="#08BFE6" />
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                  <div className="row mt-4">
                    <div className="col-6 pl-4">
                      <p
                        style={{
                          fontSize: "1.3rem",
                          color: "black",
                        }}
                        className="mb-2"
                      >
                        Tasa de recompra
                      </p>
                      <p
                        className="mb-0 secondary_color"
                        style={{
                          fontSize: "1.2rem",
                        }}
                      >
                        {graphsLoader ? (
                          <div className="text-center">
                            <CircularProgress size={26} className="mx-auto" />
                          </div>
                        ) : (
                          <strong>
                            {formaterPriceFunction(
                              clientsInfo?.tasa_recompra
                                ? clientsInfo?.tasa_recompra.toFixed(2)
                                : "0.00"
                            )}
                            %
                          </strong>
                        )}
                      </p>
                    </div>
                    <div className="col-1 px-0">
                      <div
                        style={{
                          backgroundColor: "#E0E0E0",
                          width: "1px",
                          height: "100%",
                        }}
                      />
                    </div>
                    <div className="col-5 pl-0">
                      <p
                        style={{
                          fontSize: "1.3rem",
                          color: "black",
                        }}
                        className="mb-2"
                      >
                        Ticket promedio
                      </p>
                      <p
                        className="mb-0 secondary_color"
                        style={{
                          fontSize: "1.2rem",
                        }}
                      >
                        {graphsLoader ? (
                          <div className="text-center">
                            <CircularProgress size={26} className="mx-auto" />
                          </div>
                        ) : (
                          <strong>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              clientsInfo.ticket_promedio.toFixed(2) + ""
                            )}
                          </strong>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="row">
            <div className="col-md-4">
              <div>
                <p className="mb-0">
                  <strong>Reportes de venta</strong>
                </p>
              </div>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por código del producto"
                  value={textFilter}
                  onChange={(event) => setTextFilter(event.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") getSalesReports(1);
                  }}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon onClick={() => getSalesReports(1)} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="d-flex flex-wrap align-items-center">
              {" "}
              {/* si no es tipo cajero lo muestra */}
              {!cajeroDisable && (
                <FormControl variant="outlined" className="px-1">
                  <Select
                    native
                    value={sucursalFilter}
                    onChange={changeSurcusal_sales}
                    className="sm-select my-2"
                  >
                    <option value="">Todas las tiendas</option>
                    {sucursals.map((sucursal) => (
                      <option value={sucursal.id_sucursal_bodega_ubicacion}>
                        {sucursal.nombre_sucursal}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              <DateRangePicker
                onChange={(value) => changeDate(value)}
                value={dateRange}
                format={"dd-MM-y"}
                className="date-picker-cs px-1 my-2"
              />
              {/* si no es tipo cajero lo muestra */}
              {/* {!cajeroDisable && (
                <FormControl variant="outlined" className="px-1">
                  <Select
                    native
                    value={divisionesFilter}
                    onChange={changeDivison_sales}
                    className="sm-select"
                  >
                    <option value="">Todas las divisiones</option>
                    {salesDivisions.map((division) => (
                      <option value={division.id}>
                        {division.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )} */}
              <FormControl variant="outlined" className="px-1 my-2">
                <Select
                  native
                  value={categoryFilter}
                  onChange={changeCategory_sales}
                  className="sm-select"
                >
                  <option value="">Todas las categorías</option>
                  {salesCategories.map((category) => (
                    <option value={category.id}>{category.descripcion}</option>
                  ))}
                </Select>
              </FormControl>
              {/* si no es tipo cajero lo muestra */}
              {!cajeroDisable && (
                <FormControl variant="outlined" className="px-1 my-2">
                  <Select
                    native
                    value={sellerFilter}
                    onChange={changeSeller_sales}
                    className="sm-select"
                  >
                    <option value="">Todos los vendedores</option>
                    {sellers.map((seller) => (
                      <option value={seller.id_vendedor_web_app}>
                        {seller.nombre}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>

            <Button
              className="btn-cs1 my-2"
              variant="contained"
              color="primary"
              onClick={() => {
                downloadReports();
                // document.getElementById("descargar-reporteVentas").click();
              }}
            >
              {downloadLoader ? (
                <div className="text-center mt-2">
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    style={{ color: "white" }}
                  />
                </div>
              ) : (
                <React.Fragment>
                  Descargar reporte
                  <CloudDownloadIcon className="ml-2" />
                </React.Fragment>
              )}
            </Button>
          </div>

          <ReactHTMLTableToExcel
            id="descargar-reporteVentas"
            className="buton-excel"
            table="ventas-reporte-table"
            filename={
              "Reportes de venta " +
              moment(dateRange[0]).format("YYYY-MM-DD") +
              " al " +
              moment(dateRange[1]).format("YYYY-MM-DD")
            }
            sheet="tablexls"
            buttonText="Descargar"
          />
          <VentasReporte objeto={downloadData}></VentasReporte>

          <div>
            <div className="row">
              <div className="col-md-4 px-2 text-left"></div>
              <div className="col-md-4 px-2" />
              <div className="col-md-4 px-2" />
            </div>
            {salesReportsLoader ? (
              <div
                className="text-center mt-2 d-flex align-items-center justify-content-center"
                style={{ height: "570px" }}
              >
                <CircularProgress size={35} className="mx-auto" />
              </div>
            ) : (
              <React.Fragment>
                {" "}
                <DataTable
                  className="datatable-cs datatable-cs-v2 mt-2"
                  title={"s"}
                  columns={[
                    {
                      name: "Sucursal",
                      selector: "sucursal",
                    },
                    {
                      name: "División",
                      selector: "division",
                      sortable: true,
                    },
                    {
                      name: "Categoría",
                      selector: "categoria",
                      sortable: true,
                    },
                    {
                      name: "Item",
                      selector: "item",
                      sortable: true,
                    },
                    {
                      name: "Monto",
                      selector: "monto",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}.
                          {formaterPriceFunction(row.monto.toFixed(2) + "")}
                        </span>
                      ),
                    },
                    {
                      name: "Vendedor",
                      selector: "id_vendedor_web_app",
                      sortable: true,
                    },
                    {
                      name: "Unidades",
                      selector: "unidades",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {formaterPriceFunction(row.unidades.toFixed(2) + "")}
                        </span>
                      ),
                    },
                  ]}
                  data={reports.paginatedData}
                  noDataComponent="No se ha encontrado ningún resultado"
                />
                <div className="text-center d-flex justify-content-center pagination mt-2">
                  <Pagination
                    count={reports.lastPage}
                    page={reports.page}
                    onChange={handlePageforSales}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Productos</strong>
              </h5>
              <p className="mb-0" style={{ fontSize: "1.1rem" }}>
                Consulte y gestione su inventario.
              </p>
            </div>
            {!isGerente && (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                onClick={(e) => setProductsMenu(e.currentTarget)}
              >
                Añadir producto
                <ExpandMoreIcon />
              </Button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row mt-2">
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p className="mb-1" style={{ fontSize: "1rem", color: "black" }}>
                Total de productos
              </p>
              <h6 className="mb-0 secondary_color">
                {statisticsLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {formaterPriceFunction(
                      summary.totalProduct.toFixed(2) + ""
                    )}
                  </strong>
                )}
              </h6>
            </div>
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p className="mb-1" style={{ fontSize: "1rem", color: "black" }}>
                Productos sin stock
              </p>
              <h6 className="mb-0 secondary_color">
                {statisticsLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {formaterPriceFunction(
                      summary.totalOutOfStockProduct.toFixed(2) + ""
                    )}
                  </strong>
                )}
              </h6>
            </div>
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p className="mb-1" style={{ fontSize: "1rem", color: "black" }}>
                Productos a vencer
              </p>
              <h6 className="mb-0 secondary_color">
                {statisticsLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {formaterPriceFunction(
                      summary.totalNearExpiry.toFixed(2) + ""
                    )}
                  </strong>
                )}
              </h6>
            </div>
            <div className="col-md-3 px-2">
              <p className="mb-1" style={{ fontSize: "1rem", color: "black" }}>
                Productos con bajo inventario
              </p>
              <h6 className="mb-0 secondary_color">
                {statisticsLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {formaterPriceFunction(
                      summary.totalLowInventoryProduct.toFixed(2) + ""
                    )}
                  </strong>
                )}
              </h6>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <Tabs
              className="mt-2"
              value={productsTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleProductTabs}
            >
              <Tab className="tab" label="Todos" />
              <Tab className="tab" label="Más vendidos" />
              <Tab className="tab" label="Bajo de inventario" />
              <Tab className="tab" label="Próximo a vencer" />
            </Tabs>

            {productsTab === 0 ? (
              <div>
                {/* {selectedAllProducts.length === 0 ? (
                  <Button
                    className="btn-cs1 mt-2"
                    variant="contained"
                    color="primary"
                  >
                    Descargar seleccionados
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                ) : (
                  <Button
                    className="btn-cs1 mt-2"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      document
                        .getElementById("descargar-reporteInventario-Todos2")
                        .click();
                    }}
                  >
                    Descargar seleccionados
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                )} */}
                <Button
                  className="btn-cs1 mt-2"
                  variant="contained"
                  color="primary"
                  onClick={printAllProducts}
                  disabled={printLoader}
                >
                  {printLoader ? "Descargando..." : "Descargar todos"}
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              </div>
            ) : (
              ""
            )}

            {productsTab === 1 ? (
              <div>
                {selectedBestSellingProducts.length === 0 ? (
                  <Button
                    className="btn-cs1 mt-2"
                    variant="contained"
                    color="primary"
                  >
                    Descargar seleccionados
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                ) : (
                  <Button
                    className="btn-cs1 mt-2"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      document
                        .getElementById("descargar-reporteInventario-Todos2")
                        .click();
                    }}
                  >
                    Descargar seleccionados
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                )}
              </div>
            ) : (
              ""
            )}

            <ReactHTMLTableToExcel
              id="descargar-reporteInventario-Todos2"
              className="buton-excel"
              table="InventarioTodos-reporte-table"
              filename="Cloud-Reporte-Productos.xlsx"
              sheet="tablexls"
              buttonText="Descargar"
            />

            <InventarioTodosReporte
              objeto={
                productsTab === 0
                  ? productsToPrint
                  : productsTab === 1
                  ? selectedBestSellingProducts
                  : productsTab === 2
                  ? selectedLowStockProducts
                  : productsTab === 3
                  ? selectedNearExpProducts
                  : []
              }
            ></InventarioTodosReporte>

            {productsTab === 2 ? (
              <div>
                {selectedLowStockProducts.length === 0 ? (
                  <Button
                    className="btn-cs1 mt-2"
                    variant="contained"
                    color="primary"
                  >
                    Descargar seleccionados
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                ) : (
                  <Button
                    className="btn-cs1 mt-2"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      document
                        .getElementById("descargar-reporteInventario-Todos2")
                        .click();
                    }}
                  >
                    Descargar seleccionados
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                )}
              </div>
            ) : (
              ""
            )}

            {productsTab === 3 ? (
              <div>
                {selectedNearExpProducts.length === 0 ? (
                  <Button
                    className="btn-cs1 mt-2"
                    variant="contained"
                    color="primary"
                  >
                    Descargar seleccionados
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                ) : (
                  <Button
                    className="btn-cs1 mt-2"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      document
                        .getElementById("descargar-reporteInventario-Todos2")
                        .click();
                    }}
                  >
                    Descargar seleccionados
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          <TabPanel value={productsTab} index={0}>
            <div className="products-table">
              {productsLoader ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "570px" }}
                >
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : (
                <div>
                  {allProducts.paginatedData.length === 0 ? (
                    <p className="text-center">No hay productos para mostrar</p>
                  ) : (
                    <div>
                      <DataTable
                        className="datatable-cs datatable-cs-v2 datatable-h-80"
                        title={"s"}
                        columns={
                          isGerente
                            ? [
                                {
                                  name: "Nombre",
                                  selector: "nombre",
                                  sortable: true,
                                  cell: (row) => (
                                    <div className="d-flex w-100">
                                      <img
                                        className="product-img"
                                        src={
                                          row.imagen_1
                                            ? base_url_images + row.imagen_1
                                            : base_url_images +
                                              "images/placeholder-image.jpeg"
                                        }
                                        alt=""
                                      />
                                      <div
                                        className="ml-2"
                                        style={{
                                          width: "150px",
                                        }}
                                      >
                                        {row.nombre}
                                        <br />
                                        <strong>{row.codigo}</strong>
                                      </div>
                                    </div>
                                  ),
                                },
                                {
                                  name: "Vencimiento",
                                  selector: "vencimiento_fecha",
                                  sortable: true,
                                  cell: (row) => (
                                    <span>
                                      {row.vencimiento_fecha === "N/A"
                                        ? "N/A"
                                        : moment(row.vencimiento_fecha).format(
                                            "DD-MM-YYYY h:mm:ss a"
                                          )}
                                    </span>
                                  ),
                                },
                                {
                                  name: "Categoría del producto",
                                  selector: "category",
                                  sortable: true,
                                },
                                {
                                  name: "Cantidad de existencias",
                                  selector: "stock",
                                  sortable: true,
                                  cell: (row) => (
                                    <span>
                                      {formaterPriceFunction(
                                        parseFloat(row.stock).toFixed(2) + ""
                                      )}
                                    </span>
                                  ),
                                },
                                {
                                  name: "Precio",
                                  selector: "precio_venta",
                                  sortable: true,
                                  cell: (row) => (
                                    <span>
                                      {moneda}.
                                      {row.descuento_precio > 0.0
                                        ? formaterPriceFunction(
                                            (
                                              row.descuento_precio +
                                              (row.impuesto_idp || 0)
                                            ).toFixed(2) + ""
                                          )
                                        : formaterPriceFunction(
                                            (
                                              row.precio_venta +
                                              (row.impuesto_idp || 0)
                                            ).toFixed(2)
                                          ) + ""}
                                    </span>
                                  ),
                                },
                              ]
                            : [
                                {
                                  name: "Nombre",
                                  selector: "nombre",
                                  sortable: true,
                                  cell: (row) => (
                                    <div className="d-flex w-100">
                                      <img
                                        className="product-img"
                                        src={
                                          row.imagen_1
                                            ? base_url_images + row.imagen_1
                                            : base_url_images +
                                              "images/placeholder-image.jpeg"
                                        }
                                        alt=""
                                      />
                                      <div
                                        className="ml-2"
                                        style={{
                                          width: "150px",
                                        }}
                                      >
                                        {row.nombre}
                                        <br />
                                        <strong>{row.codigo}</strong>
                                      </div>
                                    </div>
                                  ),
                                },
                                {
                                  name: "Vencimiento",
                                  selector: "vencimiento_fecha",
                                  sortable: true,
                                  cell: (row) => (
                                    <span>
                                      {row.vencimiento_fecha === "N/A"
                                        ? "N/A"
                                        : moment(row.vencimiento_fecha).format(
                                            "DD-MM-YYYY h:mm:ss a"
                                          )}
                                    </span>
                                  ),
                                },
                                {
                                  name: "Categoría del producto",
                                  selector: "category",
                                  sortable: true,
                                },
                                {
                                  name: "Cantidad de existencias",
                                  selector: "stock",
                                  sortable: true,
                                  cell: (row) => (
                                    <span>
                                      {formaterPriceFunction(
                                        parseFloat(row.stock).toFixed(2) + ""
                                      )}
                                    </span>
                                  ),
                                },

                                {
                                  name: "Precio",
                                  selector: "precio_venta",
                                  sortable: true,
                                  cell: (row) => (
                                    <span>
                                      {moneda}.
                                      {row.descuento_precio > 0.0
                                        ? formaterPriceFunction(
                                            (
                                              row.descuento_precio +
                                              (row.impuesto_idp || 0)
                                            ).toFixed(2) + ""
                                          )
                                        : formaterPriceFunction(
                                            (
                                              row.precio_venta +
                                              (row.impuesto_idp || 0)
                                            ).toFixed(2)
                                          ) + ""}
                                    </span>
                                  ),
                                },
                                {
                                  name: "Acciones",
                                  selector: "action",
                                  cell: (row) => (
                                    <div className="double-button d-flex justify-content-center align-items-center">
                                      <div className="d-block text-center w-50 mx-1">
                                        <FormControl variant="outlined">
                                          <Select
                                            native
                                            value=""
                                            onChange={(event) =>
                                              productAction(
                                                event,
                                                row.id_new_item
                                              )
                                            }
                                            className="cs-select"
                                          >
                                            <option value="">Acciones</option>
                                            <option value="detalles">
                                              Ver detalles
                                            </option>
                                            <option value="eliminar">
                                              Eliminar
                                            </option>
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  ),
                                },
                              ]
                        }
                        data={allProducts.paginatedData}
                        selectableRows
                        defaultSortField="vencimiento_fecha"
                        noDataComponent="No se ha encontrado ningún resultado"
                        onSelectedRowsChange={handleSelect}
                      />
                      <div className="text-center d-flex justify-content-center pagination mt-2">
                        <Pagination
                          count={allProducts.lastPage}
                          page={allProducts.page}
                          onChange={handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={productsTab} index={1}>
            {mostSaleLoader ? (
              <div className="text-center" style={{ height: "570px" }}>
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <>
                {bestSellingProducts.paginatedData.length === 0 ? (
                  <p className="text-center">No hay productos para mostrar</p>
                ) : (
                  <div>
                    <DataTable
                      className="datatable-cs datatable-cs-v2 datatable-h-80"
                      title={"s"}
                      columns={
                        isGerente
                          ? [
                              {
                                name: "Nombre",
                                selector: "nombre",
                                sortable: true,
                                cell: (row) => (
                                  <div className="d-flex w-100">
                                    <img
                                      className="product-img"
                                      src={
                                        row.imagen_1
                                          ? base_url_images + row.imagen_1
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt=""
                                    />
                                    <div
                                      className="ml-2"
                                      style={{
                                        width: "150px",
                                      }}
                                    >
                                      {row.nombre}
                                      <br />
                                      <strong>{row.codigo}</strong>
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: "Vencimiento",
                                selector: "vencimiento_fecha",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {row.vencimiento_fecha === "N/A"
                                      ? "N/A"
                                      : moment(row.vencimiento_fecha).format(
                                          "DD-MM-YYYY h:mm:ss a"
                                        )}
                                  </span>
                                ),
                              },
                              {
                                name: "Categoría del producto",
                                selector: "category",
                                sortable: true,
                              },
                              {
                                name: "Cantidad de existencias",
                                selector: "stock",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {formaterPriceFunction(
                                      parseFloat(row.stock).toFixed(2) + ""
                                    )}
                                  </span>
                                ),
                              },

                              {
                                name: "Precio",
                                selector: "precio_venta",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {moneda}.
                                    {row.descuento_precio > 0.0
                                      ? formaterPriceFunction(
                                          (
                                            row.descuento_precio +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2)
                                        ) + ""
                                      : formaterPriceFunction(
                                          (
                                            row.precio_venta +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2)
                                        ) + ""}
                                  </span>
                                ),
                              },
                            ]
                          : [
                              {
                                name: "Nombre",
                                selector: "nombre",
                                sortable: true,
                                cell: (row) => (
                                  <div className="d-flex w-100">
                                    <img
                                      className="product-img"
                                      src={
                                        row.imagen_1
                                          ? base_url_images + row.imagen_1
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt=""
                                    />
                                    <div
                                      className="ml-2"
                                      style={{
                                        width: "150px",
                                      }}
                                    >
                                      {row.nombre}
                                      <br />
                                      <strong>{row.codigo}</strong>
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: "Vencimiento",
                                selector: "vencimiento_fecha",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {row.vencimiento_fecha === "N/A"
                                      ? "N/A"
                                      : moment(row.vencimiento_fecha).format(
                                          "DD-MM-YYYY h:mm:ss a"
                                        )}
                                  </span>
                                ),
                              },
                              {
                                name: "Categoría del producto",
                                selector: "category",
                                sortable: true,
                              },
                              {
                                name: "Cantidad de existencias",
                                selector: "stock",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {formaterPriceFunction(
                                      parseFloat(row.stock).toFixed(2) + ""
                                    )}
                                  </span>
                                ),
                              },

                              {
                                name: "Precio",
                                selector: "precio_venta",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {moneda}.
                                    {row.descuento_precio > 0.0
                                      ? formaterPriceFunction(
                                          (
                                            row.descuento_precio +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2)
                                        ) + ""
                                      : formaterPriceFunction(
                                          (
                                            row.precio_venta +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2)
                                        ) + ""}
                                  </span>
                                ),
                              },
                              {
                                name: "Acciones",
                                selector: "action",
                                cell: (row) => (
                                  <div className="double-button d-flex justify-content-center align-items-center">
                                    <div className="d-block text-center w-50 mx-1">
                                      <FormControl variant="outlined">
                                        <Select
                                          native
                                          value=""
                                          onChange={(event) =>
                                            productAction(
                                              event,
                                              row.id_new_item
                                            )
                                          }
                                          className="cs-select"
                                        >
                                          <option value="">Acciones</option>
                                          <option value="detalles">
                                            Ver detalles
                                          </option>
                                          <option value="eliminar">
                                            Eliminar
                                          </option>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                ),
                              },
                            ]
                      }
                      data={bestSellingProducts.paginatedData}
                      selectableRows
                      defaultSortField="vencimiento_fecha"
                      noDataComponent="No se ha encontrado ningún resultado"
                      onSelectedRowsChange={handleSelect}
                    />
                    <div className="text-center d-flex justify-content-center pagination mt-2">
                      <Pagination
                        count={bestSellingProducts.lastPage}
                        page={bestSellingProducts.page}
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={productsTab} index={2}>
            {lowStockLoader ? (
              <div className="text-center" style={{ height: "570px" }}>
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <div className="products-table">
                {lowStockProducts.paginatedData.length === 0 ? (
                  <p className="text-center">
                    No hay productos con bajo inventario
                  </p>
                ) : (
                  <div>
                    <DataTable
                      className="datatable-cs datatable-cs-v2 datatable-h-80"
                      title={"s"}
                      columns={
                        isGerente
                          ? [
                              {
                                name: "Nombre",
                                selector: "nombre",
                                sortable: true,
                                cell: (row) => (
                                  <div className="d-flex w-100">
                                    <img
                                      className="product-img"
                                      src={
                                        row.imagen_1
                                          ? base_url_images + row.imagen_1
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt=""
                                    />
                                    <div
                                      className="ml-2"
                                      style={{
                                        width: "150px",
                                      }}
                                    >
                                      {row.nombre}
                                      <br />
                                      <strong>{row.codigo}</strong>
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: "Vencimiento",
                                selector: "vencimiento_fecha",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {row.vencimiento_fecha === "N/A"
                                      ? "N/A"
                                      : moment(row.vencimiento_fecha).format(
                                          "DD-MM-YYYY h:mm:ss a"
                                        )}
                                  </span>
                                ),
                              },
                              {
                                name: "Categoría del producto",
                                selector: "category",
                                sortable: true,
                              },
                              {
                                name: "Cantidad de existencias",
                                selector: "stock",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {formaterPriceFunction(
                                      parseFloat(row.stock).toFixed(2) + ""
                                    )}
                                  </span>
                                ),
                              },

                              {
                                name: "Precio",
                                selector: "precio_venta",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {moneda}.
                                    {row.descuento_precio > 0.0
                                      ? formaterPriceFunction(
                                          (
                                            row.descuento_precio +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2) + ""
                                        )
                                      : formaterPriceFunction(
                                          (
                                            row.precio_venta +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2) + ""
                                        )}
                                  </span>
                                ),
                              },
                            ]
                          : [
                              {
                                name: "Nombre",
                                selector: "nombre",
                                sortable: true,
                                cell: (row) => (
                                  <div className="d-flex w-100">
                                    <img
                                      className="product-img"
                                      src={
                                        row.imagen_1
                                          ? base_url_images + row.imagen_1
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt=""
                                    />
                                    <div
                                      className="ml-2"
                                      style={{
                                        width: "150px",
                                      }}
                                    >
                                      {row.nombre}
                                      <br />
                                      <strong>{row.codigo}</strong>
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: "Vencimiento",
                                selector: "vencimiento_fecha",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {row.vencimiento_fecha === "N/A"
                                      ? "N/A"
                                      : moment(row.vencimiento_fecha).format(
                                          "DD-MM-YYYY h:mm:ss a"
                                        )}
                                  </span>
                                ),
                              },
                              {
                                name: "Categoría del producto",
                                selector: "category",
                                sortable: true,
                              },
                              {
                                name: "Cantidad de existencias",
                                selector: "stock",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {formaterPriceFunction(
                                      parseFloat(row.stock).toFixed(2) + ""
                                    )}
                                  </span>
                                ),
                              },

                              {
                                name: "Precio",
                                selector: "precio_venta",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {moneda}.
                                    {row.descuento_precio > 0.0
                                      ? formaterPriceFunction(
                                          (
                                            row.descuento_precio +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2) + ""
                                        )
                                      : formaterPriceFunction(
                                          (
                                            row.precio_venta +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2) + ""
                                        )}
                                  </span>
                                ),
                              },
                              {
                                name: "Acciones",
                                selector: "action",
                                cell: (row) => (
                                  <div className="double-button d-flex justify-content-center align-items-center">
                                    <div className="d-block text-center w-50 mx-1">
                                      <FormControl variant="outlined">
                                        <Select
                                          native
                                          value=""
                                          onChange={(event) =>
                                            productAction(
                                              event,
                                              row.id_new_item
                                            )
                                          }
                                          className="cs-select"
                                        >
                                          <option value="">Acciones</option>
                                          <option value="detalles">
                                            Ver detalles
                                          </option>
                                          <option value="eliminar">
                                            Eliminar
                                          </option>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                ),
                              },
                            ]
                      }
                      data={lowStockProducts.paginatedData}
                      selectableRows
                      defaultSortField="vencimiento_fecha"
                      noDataComponent="No se ha encontrado ningún resultado"
                      onSelectedRowsChange={handleSelect}
                    />
                    <div className="text-center d-flex justify-content-center pagination mt-2">
                      <Pagination
                        count={lowStockProducts.lastPage}
                        page={lowStockProducts.page}
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </TabPanel>
          <TabPanel value={productsTab} index={3}>
            {nearExpiryLoader ? (
              <div className="text-center" style={{ height: "570px" }}>
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <>
                {nearExpProducts.paginatedData.length === 0 ? (
                  <p className="text-center">
                    No hay productos proximos a vencer
                  </p>
                ) : (
                  <div>
                    <DataTable
                      className="datatable-cs datatable-cs-v2 datatable-h-80"
                      title={"s"}
                      columns={
                        isGerente
                          ? [
                              {
                                name: "Nombre",
                                selector: "nombre",
                                sortable: true,
                                cell: (row) => (
                                  <div className="d-flex w-100">
                                    <img
                                      className="product-img"
                                      src={
                                        row.imagen_1
                                          ? base_url_images + row.imagen_1
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt=""
                                    />
                                    <div
                                      className="ml-2"
                                      style={{
                                        width: "150px",
                                      }}
                                    >
                                      {row.nombre}
                                      <br />
                                      <strong>{row.codigo}</strong>
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: "Vencimiento",
                                selector: "vencimiento_fecha",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {row.vencimiento_fecha === "N/A"
                                      ? "N/A"
                                      : moment(row.vencimiento_fecha).format(
                                          "DD-MM-YYYY h:mm:ss a"
                                        )}
                                  </span>
                                ),
                              },
                              {
                                name: "Categoría del producto",
                                selector: "category",
                                sortable: true,
                              },
                              {
                                name: "Cantidad de existencias",
                                selector: "stock",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {formaterPriceFunction(
                                      parseFloat(row.stock).toFixed(2) + ""
                                    )}
                                  </span>
                                ),
                              },

                              {
                                name: "Precio",
                                selector: "precio_venta",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {moneda}.
                                    {row.descuento_precio > 0.0
                                      ? formaterPriceFunction(
                                          (
                                            row.descuento_precio +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2) + ""
                                        )
                                      : formaterPriceFunction(
                                          (
                                            row.precio_venta +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2) + ""
                                        )}
                                  </span>
                                ),
                              },
                            ]
                          : [
                              {
                                name: "Nombre",
                                selector: "nombre",
                                sortable: true,
                                cell: (row) => (
                                  <div className="d-flex w-100">
                                    <img
                                      className="product-img"
                                      src={
                                        row.imagen_1
                                          ? base_url_images + row.imagen_1
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt=""
                                    />
                                    <div
                                      className="ml-2"
                                      style={{
                                        width: "150px",
                                      }}
                                    >
                                      {row.nombre}
                                      <br />
                                      <strong>{row.codigo}</strong>
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: "Vencimiento",
                                selector: "vencimiento_fecha",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {row.vencimiento_fecha === "N/A"
                                      ? "N/A"
                                      : moment(row.vencimiento_fecha).format(
                                          "DD-MM-YYYY h:mm:ss a"
                                        )}
                                  </span>
                                ),
                              },
                              {
                                name: "Categoría del producto",
                                selector: "category",
                                sortable: true,
                              },
                              {
                                name: "Cantidad de existencias",
                                selector: "stock",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {formaterPriceFunction(
                                      parseFloat(row.stock).toFixed(2) + ""
                                    )}
                                  </span>
                                ),
                              },

                              {
                                name: "Precio",
                                selector: "precio_venta",
                                sortable: true,
                                cell: (row) => (
                                  <span>
                                    {moneda}.
                                    {row.descuento_precio > 0.0
                                      ? formaterPriceFunction(
                                          (
                                            row.descuento_precio +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2) + ""
                                        )
                                      : formaterPriceFunction(
                                          (
                                            row.precio_venta +
                                            (row.impuesto_idp || 0)
                                          ).toFixed(2) + ""
                                        )}
                                  </span>
                                ),
                              },
                              {
                                name: "Acciones",
                                selector: "action",
                                cell: (row) => (
                                  <div className="double-button d-flex justify-content-center align-items-center">
                                    <div className="d-block text-center w-50 mx-1">
                                      <FormControl variant="outlined">
                                        <Select
                                          native
                                          value=""
                                          onChange={(event) =>
                                            productAction(
                                              event,
                                              row.id_new_item
                                            )
                                          }
                                          className="cs-select"
                                        >
                                          <option value="">Acciones</option>
                                          <option value="detalles">
                                            Ver detalles
                                          </option>
                                          <option value="eliminar">
                                            Eliminar
                                          </option>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                ),
                              },
                            ]
                      }
                      data={nearExpProducts.paginatedData}
                      selectableRows
                      defaultSortField="vencimiento_fecha"
                      noDataComponent="No se ha encontrado ningún resultado"
                      onSelectedRowsChange={handleSelect}
                    />
                    <div className="text-center d-flex justify-content-center pagination mt-2">
                      <Pagination
                        count={nearExpProducts.lastPage}
                        page={nearExpProducts.page}
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </TabPanel>
        </div>
        <div
          class="modal fade"
          id="confirm_deletion"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <h4>
                  <strong>¿Seguro que desea eliminar ese producto?</strong>
                </h4>

                <div class="d-flex justify-content-between">
                  <Button
                    className="btn-cs1 delete-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => deleteProduct()}
                  >
                    Sí, eliminar
                  </Button>
                  <Button
                    className="btn-cs1 "
                    variant="contained"
                    color="primary"
                    data-dismiss="modal"
                  >
                    Volver
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Menu
        open={Boolean(productsMenu)}
        anchorEl={productsMenu}
        style={{ width: "300px" }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setProductsMenu(null)}
      >
        <MenuItem
          className="menuItem-border"
          component={Link}
          to={"/app/inventario/add_product"}
        >
          Añadir individualmente
        </MenuItem>
        <MenuItem
          className="menuItem-border"
          component={Link}
          to={"/app/inventario/carga_masiva"}
        >
          Carga masiva de productos
        </MenuItem>
      </Menu>
    </div>
  );
}
