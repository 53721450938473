import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getAll = async () => {
  return await axios.get(`${base_url}/web/get-plans`, { headers: headers() });
};

const createSub = async (data) => {
  return await axios.post(
    `${base_url}/web/create-subscription`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getUserSubscriptions = async (data) => {
  if (data) {
    return await axios.get(
      `${base_url}/web/get-subscription?id_new_empresa=${data}`,
      { headers: headers() }
    );
  } else {
    return await axios.get(`${base_url}/web/get-subscription`, {
      headers: headers(),
    });
  }
};

const cancelSubscription = async (data) => {
  return await axios.post(
    `${base_url}/web/unsubscribe-subscription`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const renewSubscription = async (data) => {
  return await axios.post(
    `${base_url}/web/renew-subscription`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const addNewCard = async (data) => {
  return await axios.post(`${base_url}/web/add-card`, qs.stringify(data), {
    headers: headers(),
  });
};

const selectDefaultCard = async (data) => {
  return await axios.post(`${base_url}/web/select-card`, qs.stringify(data), {
    headers: headers(),
  });
};

const deleteCard = async (data) => {
  return await axios.post(`${base_url}/web/delete-card`, qs.stringify(data), {
    headers: headers(),
  });
};

const updateAutoRenewal = async (data) => {
  return await axios.post(
    `${base_url}/web/update-auto-renewal`,
    qs.stringify(data),
    { headers: headers() }
  );
};

export default {
  getAll,
  createSub,
  getUserSubscriptions,
  cancelSubscription,
  renewSubscription,
  addNewCard,
  selectDefaultCard,
  deleteCard,
  updateAutoRenewal,
};
