import { QuestionAnswer, TramRounded } from "@material-ui/icons";
import { base_url, axios } from "../api";
var qs = require("qs");
const moment = require("moment");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};
const alekoHeaders = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: token };
};

const getAll = async (data) => {
  data.from_date = moment(data.from_date).format("YYYY-MM-DD");
  data.to_date = moment(data.to_date).format("YYYY-MM-DD");
  return await axios.post(`${base_url}/web/get-sales`, qs.stringify(data), {
    headers: headers(),
  });
};

const getAnulacionesApplicableSales = async (data) => {
  return await axios.post(
    `${base_url}/web/get-uncancelled-movements`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getNCApplicableSales = async (data) => {
  return await axios.post(
    `${base_url}/web/get-nc-applicable-sales`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getNDApplicableSales = async (data) => {
  return await axios.post(
    `${base_url}/web/get-nd-applicable-sales`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getSingleSale = async (data) => {
  data.web = true;
  return await axios.post(`${base_url}/web/get-invoice`, qs.stringify(data), {
    headers: headers(),
  });
};

const getPriceLevels = async (data) => {
  return await axios.get(`${base_url}/web/accumulated-sales-from-client`, {
    headers: headers(),
    params: data,
  });
};

const createNew = async (data) => {
  console.log("This is the data im sending:", data);
  console.log(JSON.stringify(data));

  return await axios.post(
    `${base_url}/web/add-sale`,
    qs.stringify(data, { arrayFormat: "comma", encode: false }),
    { headers: headers(), timeout: 600000 }
  );
};

const createNewPrefacturacion = async (data) => {
  return await axios.post(
    `${base_url}/web/add-post-sale`,
    qs.stringify(data, { arrayFormat: "comma", encode: false }),
    { headers: headers(), timeout: 600000 }
  );
};

const getAllPrefacturas = async (data) => {
  return await axios.post(
    `${base_url}/web/get-post-sales`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getAllItemsPrefacturas = async (data) => {
  return await axios.post(
    `${base_url}/web/get-post-sales-items`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getsucursalprincipalretainers = async (params) => {
  return await axios.get(
    `${base_url}/web/fel/proveedor/` + params.idBodegaSucursal,
    { headers: headers() }
  );
};

const getNotasDebito = async (data) => {
  return await axios.post(
    `${base_url}/web/get-nota-debitos`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getNotaDebito = async (data) => {
  return await axios.post(
    `${base_url}/web/get-debit-note`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const createNotaDebito = async (data) => {
  return await axios.post(`${base_url}/web/debit-note`, qs.stringify(data), {
    headers: headers(),
  });
};

const createNotaCredito = async (data) => {
  return await axios.post(`${base_url}/web/credit-note`, qs.stringify(data), {
    headers: headers(),
  });
};

const getNotasCredito = async (data) => {
  return await axios.post(
    `${base_url}/web/get-nota-creditos`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getNotaCredito = async (data) => {
  return await axios.post(
    `${base_url}/web/get-credit-note`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const anularVenta = async (data) => {
  return await axios.post(
    `${base_url}/web/anulaciones-venta`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const anularPostVenta = async (data) => {
  return await axios.post(
    `${base_url}/web/anulaciones-post-venta`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const anularNota = async (data) => {
  data.fecha = new Date(); // This is just incase fecha gets added
  return await axios.post(`${base_url}/web/cancel-nota`, qs.stringify(data), {
    headers: headers(),
  });
};

const getSingleAnulacion = async (data) => {
  data.id_pos_movimiento_web_app = parseInt(data.id_pos_movimiento_web_app);
  return await axios.post(
    `${base_url}/web/get-single-anulacion`,
    qs.stringify(data),
    { headers: headers() }
  );
};

// metodos de entrega
const obtenerCanalesVentas = async () => {
  return axios.get(`${base_url}/web/attention-channels/allChannelsCMISala`, {
    headers: headers(),
  });
};

const getUltimaDireccion = (data) =>
  axios.post(`${base_url}/web/cmi/ultima-direccion`, qs.stringify(data), {
    headers: headers(),
  });

export const personalizarPedido = (data) =>
  axios.post(`${base_url}/web/aleko/pedidos/personalizar-pedido`, data, {
    headers: alekoHeaders(),
  });

export const getAlekoOrder = (data) =>
  axios.get(
    `${base_url}/web/aleko/pedidos/personalizar-pedido/${data.id_pedido_item}`,

    {
      headers: alekoHeaders(),
    }
  );

export const createDirection = (data) =>
  axios.post(`${base_url}/web/cmi/crear-direcciones-entrega-cliente`, data, {
    headers: headers(),
  });

export const updateDirection = (data) =>
  axios.post(
    `${base_url}/web/cmi/actualizar-direcciones-entrega-cliente`,
    data,
    { headers: headers() }
  );

export const deleteDirection = (data) =>
  axios.post(`${base_url}/web/cmi/eliminar-direcciones-entrega-cliente`, data, {
    headers: headers(),
  });

export const existeApertura = (data) =>
  axios.post(
    `${base_url}/web/cmi/consultar-existencia-apertura-caja`,
    qs.stringify(data),
    { headers: headers() }
  );

export const aperturarCaja = (data) =>
  axios.post(`${base_url}/web/cmi/add-apertura_caja`, qs.stringify(data), {
    headers: headers(),
  });

export const validarAnulacion = (data) =>
  axios.post(
    `${base_url}/web/cmi/validar-anulacion-usuarios-gerentes`,
    qs.stringify(data),
    { headers: headers() }
  );

export const validarReimpresion = (data) =>
  axios.post(
    `${base_url}/web/cmi/validar-impresion-Gerente`,
    qs.stringify(data),
    { headers: headers() }
  );

export const retirarEfectivo = (data) =>
  axios.post(`${base_url}/web/cmi/add-retiro-caja`, qs.stringify(data), {
    headers: headers(),
  });

export const getRetiroCaja = (data) =>
  axios.post(`${base_url}/web/cmi/get-retiro-caja`, qs.stringify(data), {
    headers: headers(),
  });

export const cancelAlekoOrder = (data) =>
  axios.post(`${base_url}/web/aleko/pedidos/personalizar-pedido/cancel`, data, {
    headers: alekoHeaders(),
  });

export const getAlekoOrders = (data) => {
  return axios.get(
    `${base_url}/web/aleko/pedidos/lista-ordenes?id_new_empresa=${data.id_new_empresa}&id_sucursal_bodega_ubicacion=${data.id_sucursal_bodega_ubicacion}&id_vendedor_web_app=${data.id_vendedor_web_app}&codigo_pedido=${data.codigo_pedido}&fecha_inicial=${data.fecha_inicial}&fecha_final=${data.fecha_final}`,
    {
      headers: alekoHeaders(),
    }
  );
};

export const getAlekoPedido = (id) =>
  axios.get(`${base_url}/web/aleko/pedidos/detalle-ordenes/${id}`);

export const getStatusListOrder = (idEmpresa) =>
  axios.get(
    `${base_url}/web/aleko/pedidos/get-estado-pedido?id_new_empresa=${idEmpresa}`,
    { headers: alekoHeaders() }
  );

export const updateStatusorder = (config) => {
  return axios.put(
    `${base_url}/web/aleko/pedidos/cambiar-estado_pedido`,
    qs.stringify(config),
    {
      headers: alekoHeaders(),
    }
  );
};

export default {
  createNew,
  createNewPrefacturacion,
  getAllPrefacturas,
  getAllItemsPrefacturas,
  getAll,
  getSingleSale,
  getNotasDebito,
  createNotaDebito,
  getNotasCredito,
  createNotaCredito,
  anularVenta,
  getNCApplicableSales,
  getNDApplicableSales,
  getAnulacionesApplicableSales,
  getSingleAnulacion,
  anularNota,
  anularPostVenta,
  getNotaCredito,
  getNotaDebito,
  getsucursalprincipalretainers,
  obtenerCanalesVentas,
  getUltimaDireccion,
  updateDirection,
  deleteDirection,
  createDirection,
  getPriceLevels,
  getAlekoPedido,
  getAlekoOrders,
};
