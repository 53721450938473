import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import API from "../../../api";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
} from "recharts";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// components

//data

export default function Users_all(props) {
  let history = useHistory();
  const moment = require("moment");
  // states
  const [configTab, setConfigTab] = useState(0);
  const [plansDetails, setPlansDetails] = useState([]);
  const [membershipStats, setMembershipStats] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getPlansDetails();
    getMembershipStats();
  }, []);

  // set states
  const handleConfigTabs = (event, newValue) => {
    setConfigTab(newValue);
  };

  const customTooltipOnYourLine = ({ active, payload }) => {
    if (active && payload[0]) {
      let num_clients = payload[0].payload.client_amount;

      return (
        <div className="custom-tooltip">
          <p className="mb-0">Clientes: {num_clients}</p>
        </div>
      );
    } else {
      return "";
    }
  };

  const getPlansDetails = () => {
    API.admin_memberships
      .getPlansDetails()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setPlansDetails(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning(
              "Ha ocurrido un error al obtener detalles de los planes",
              { autoClose: 10000 }
            );
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener detalles de los planes", {
          autoClose: 10000,
        });
      });
  };

  const getMembershipStats = () => {
    API.admin_memberships
      .getMembershipStats()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setMembershipStats(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning(
              "Ha ocurrido un error al obtener detalles de los planes",
              { autoClose: 10000 }
            );
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener detalles de los planes", {
          autoClose: 10000,
        });
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4" />

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <h5 className="mb-0">
              <strong>Membresías</strong>
            </h5>

            <Button
              className="btn-cs1"
              variant="contained"
              color="primary"
              component={Link}
              to={"/admin/membresia/new"}
            >
              <span style={{ marginBottom: "-4px" }}>Crear membresía</span>
            </Button>
          </div>

          <Tabs
            className=""
            value={configTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleConfigTabs}
          >
            <Tab className="tab" label="Listado de membresías" />
            <Tab className="tab" label="Estadísticas" />
          </Tabs>
        </div>
        <div className="card-body">
          <TabPanel value={configTab} index={0} className="config-tab">
            {loader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" color="black" />
              </div>
            ) : (
              <DataTable
                className="datatable-cs datatable-cs-v2 mt-2"
                title={"s"}
                columns={[
                  {
                    name: "Nombre de la membresía",
                    selector: "descripcion",
                    sortable: true,
                  },
                  {
                    name: "Frecuencia de pago (en meses)",
                    selector: "numero_mes",
                    sortable: true,
                  },

                  {
                    name: "Precio",
                    selector: "monto",
                    cell: (row) => (
                      <div className="w-100 text-center">Q. {row.monto}</div>
                    ),
                    sortable: true,
                  },
                  {
                    name: "Número de clientes",
                    selector: "clients_amount",
                    cell: (row) => (
                      <div className="w-100 text-center">
                        {row.clients_amount}
                      </div>
                    ),
                    sortable: true,
                  },

                  {
                    name: "",
                    selector: "action",
                    cell: (row) => (
                      <div className="text-center w-100">
                        <Button
                          className="brand2-btn"
                          variant="contained"
                          color="primary"
                          endIcon={<ArrowForwardIosIcon />}
                          size="small"
                          onClick={() =>
                            history.push({
                              pathname: `/admin/membresia/detalles/${row.id_planes}`,
                            })
                          }
                        >
                          <span
                            style={{ marginBottom: "-4px", fontSize: "12px" }}
                          >
                            Ver detalles
                          </span>
                        </Button>
                      </div>
                    ),
                  },
                ]}
                data={plansDetails}
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se ha encontrado ningún resultado"
              />
            )}
          </TabPanel>

          <TabPanel value={configTab} index={1} className="config-tab">
            <div className="row">
              <div className="col-md-6">
                <h6>
                  <strong>Distribución de clientes según plan</strong>
                </h6>
                {loader ? (
                  <div className="text-center">
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      color="black"
                    />
                  </div>
                ) : (
                  <div className="distribuccion-container">
                    {plansDetails.map((plan) => (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-0 pl-2">{plan.descripcion}</p>
                          <p className="mb-0">
                            <strong>N° Clientes: </strong>
                            {plan.clients_amount}
                          </p>
                        </div>
                        <div class="progress custom-progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${plan.distribution_percentage}%`,
                            }}
                          >
                            {plan.distribution_percentage}%
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items justify-content-between">
                  <h6>
                    <strong>Cantidad de clientes por mes</strong>
                  </h6>

                  <h6 style={{ color: "#BDBDBD" }}>
                    <strong>Año {moment().get("year")}</strong>
                  </h6>
                </div>

                <ResponsiveContainer width="100%" height={323}>
                  <ComposedChart
                    width={500}
                    height={300}
                    data={membershipStats.statistics_data}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={(text) => `${text}`} />
                    <Line
                      dataKey="client_amount"
                      type="monotone"
                      stroke="#0160E7"
                      strokeWidth={2}
                    />
                    <Area
                      type="monotone"
                      dataKey="client_amount"
                      stroke="#0160E7"
                      fill="#50a0e27a"
                    />
                    <Tooltip content={customTooltipOnYourLine} />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#E0E0E0",
              }}
              className="my-2"
            />

            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex flex-wrap">
                <div className="card membresia-stat-card">
                  <div className="card-body">
                    <p>Total de clientes</p>
                    <h6 style={{ color: "#0160E7" }}>
                      <strong>{membershipStats.total_clients}</strong>
                    </h6>
                  </div>
                </div>

                <div className="card membresia-stat-card">
                  <div className="card-body">
                    <p>Clientes vencidos</p>
                    <h6 style={{ color: "#0160E7" }}>
                      <strong>{membershipStats.expired_clients}</strong>
                    </h6>
                  </div>
                </div>

                <div className="card membresia-stat-card">
                  <div className="card-body">
                    <h5 style={{ color: "#0160E7" }}>
                      <strong>{membershipStats.most_purchased_plan}</strong>
                    </h5>
                    <p>Plan más comprado</p>
                  </div>
                </div>

                <div className="card membresia-stat-card">
                  <div className="card-body">
                    <h5 style={{ color: "#0160E7" }}>
                      <strong>
                        {membershipStats.most_used_payment_method}
                      </strong>
                    </h5>
                    <p>Método de pago más usado</p>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
