import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState, useEffect, useContext } from "react";
import { getMoneda } from "../../../../../helpers/moneda";
import Personalization from "./Personalization";
import ProductPersonalizationContext from "../../../../../context/PersonalizacionProducto/ProductPersonalizationContext";
const calcTotal = (variant, point) => {
  const currentPersonalization = variant.custom.customType.find(
    (x) => x.nombre_personalizacion === point.customType.nombre_personalizacion
  );

  const cm = point.dimensions.width * point.dimensions.height;
  const getDiscount = (num) => (num * point.descuento) / 100;
  if (
    cm * currentPersonalization.factor <
    currentPersonalization.precio_minimo
  ) {
    return (
      currentPersonalization.precio_minimo -
      getDiscount(currentPersonalization.precio_minimo)
    );
  } else {
    return (
      cm * currentPersonalization.factor -
      getDiscount(cm * currentPersonalization.factor)
    );
  }
};
const Product = ({ variant }) => {
  const [expand, setExpand] = useState(true);
  const { handleForms } = useContext(ProductPersonalizationContext);
  const getTotalProduct = () => {
    let total =
      variant?.cantidad * (variant?.descuento_precio || variant?.precio_venta);
    if (!variant?.addProductWithDiscount) {
      total = 0;
    }
    const precioExtraColor = variant?.custom?.variant.find(
      (x) => x?.codigo_hexa_color === variant?.color
    );
    if (precioExtraColor) {
      total = total + precioExtraColor.precio_extra;
    }

    return total - total * (variant?.descuento_producto / 100);
  };

  const total =
    getTotalProduct() +
    variant?.points?.reduce((a, b) => a + calcTotal(variant, b), 0) *
      variant?.cantidad;

  useEffect(() => {
    handleForms({ variantId: variant?.variantId, valor_total: total });
  }, [total]);

  return (
    <>
      <thead className="col-md-12" style={{ background: "#ecf0f1" }}>
        <tr>
          <th className="col-md-6" scope="col">
            {expand ? (
              <ExpandMore
                style={{ cursor: "pointer" }}
                onClick={() => setExpand(false)}
              />
            ) : (
              <ExpandLess
                style={{ cursor: "pointer" }}
                onClick={() => setExpand(true)}
              />
            )}{" "}
            {variant?.title}
          </th>
          <th className="col-md-2" scope="col">
            {variant?.cantidad || 0}
          </th>
          <th className="col-md-2" scope="col">
            {variant?.descuento_producto || 0} (%)
          </th>
          <th className="col-md-2" scope="col">
            {getMoneda()}. {getTotalProduct().toFixed(2)}
          </th>
        </tr>
      </thead>
      <>
        {expand &&
          variant?.points?.map((point) => (
            <Personalization variant={variant} point={point} />
          ))}
      </>
    </>
  );
};

export default Product;
