import React from "react";

//estilos
import "./timeFilter.css";

const TimeFilter = ({ selected, changeSelected, openModal, loading }) => {
  const buttonStyles = (id) => {
    if (selected === id) {
      return {
        background: "#0160E7",
        color: "#fff",
      };
    } else {
      return {
        background: "#fff",
        color: "#0160E7",
      };
    }
  };
  return (
    <div className="timeFilter-component">
      <h5 className="mb-0">
        <strong>Histórico de mermas</strong>
      </h5>

      <div className="timeFilter-box">
        <button
          disabled={loading}
          style={buttonStyles("year")}
          onClick={() => {
            changeSelected("year");
          }}
        >
          Anual
        </button>
        <button
          disabled={loading}
          style={buttonStyles("month")}
          onClick={() => {
            changeSelected("month");
          }}
        >
          Mensual
        </button>
        <button
          disabled={loading}
          style={buttonStyles("day")}
          onClick={() => {
            changeSelected("day");
          }}
        >
          Diario
        </button>
        <button
          disabled={loading}
          style={buttonStyles("custom")}
          onClick={() => {
            changeSelected("custom");
            openModal();
          }}
        >
          Personalizado
        </button>
      </div>
    </div>
  );
};

export default TimeFilter;
