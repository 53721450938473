import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import API from "../../../api";
import Cards from "react-credit-cards";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";

// components

//data

const ERROR_MESSAGES = {
  emptyCardNumber: "El número de la tarjeta es inválido",
  invalidCardNumber: "El número de la tarjeta es inválido",
  emptyExpiryDate: "La fecha de expiración es inválida",
  monthOutOfRange: "El mes de expiración debe estar entre 01 y 12",
  yearOutOfRange: "El año de expiración no puede estar en el pasado",
  dateOutOfRange: "La fecha de expiración no puede estar en el pasado",
  invalidExpiryDate: "La fecha de expiración es inválida",
  emptyCVC: "El código de seguridad es inválido",
  invalidCVC: "El código de seguridad es inválido",
};

export default function User_conifg(props) {
  const { register, handleSubmit } = useForm({ shouldUnregister: false });
  const moment = require("moment");

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {};
  }, []);

  //component did mount
  useEffect(() => {
    getAllPlans();
    if (!Number.isInteger(parseInt(props.match.params.id))) {
      toast.error("Falta el ID del cliente.", { autoClose: 3000 });
      props.history.push("/admin/dashboard");
    }
  }, []);

  // states
  const [selectedTab, setSelectedTab] = useState(0);
  const [saveLoader, setSaveLoader] = useState(false);
  var [submitLoader, setSubmitLoader] = useState(false);
  var [plans, setPlans] = useState([]);
  var [plan, setPlan] = useState("");
  var [cantLicencias, setCantLicencias] = useState(1);
  var [costoTotal, setCostoTotal] = useState("");
  var [frecuenciaPago, setFrecuenciaPago] = useState("");
  const [cvc, setCVC] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [auto_renewal, setAutoRenewal] = useState(false);

  // set states
  const handleSelectedTabs = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const save = (data) => {
    let filledForm = true;

    if (!filledForm) {
      toast.error("Falta campos por rellenar", { autoClose: 3000 });
    } else {
      //api call
    }
  };

  //

  const {
    meta,
    getCardNumberProps,
    wrapperProps,
    getCardImageProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  });
  // set states

  const handlePayment = () => {
    setSubmitLoader(true);
    let validForm = true;
    console.log(meta.erroredInputs);
    if (plan !== "7") {
      for (var key in meta.erroredInputs) {
        if (typeof meta.erroredInputs[key] !== "undefined") {
          validForm = false;
        }
      }
    }
    if (!validForm && plan !== "7") {
      validForm = false;
      toast.error("Hay uno o mas errores en los datos de la tarjeta.", {
        autoClose: 10000,
      });
    }

    if (name.length === "" && plan !== "7") {
      validForm = false;
      toast.error("Debe completar el nombre del tarjetahabiente.", {
        autoClose: 10000,
      });
    }

    if (cantLicencias === "") {
      validForm = false;
      toast.error("Debe introducir la cantidad de licencias.", {
        autoClose: 10000,
      });
    }

    let renewal = 0;
    if (plan === "7") {
      renewal = 1;
    } else {
      renewal = auto_renewal ? 1 : 0;
    }

    let form_data = {
      card_holder_name: name,
      card_number: number.replace(/\s+/g, ""),
      expiry: expiry.replace(/\//g, "").replace(/\s+/g, ""), // Converts from "12 / 24" to "1224"
      cvv: cvc,
      plan_id: parseInt(plan),
      auto_renewal: renewal,
      payment_gateway: 1,
      numero_licencias: parseInt(cantLicencias),
      id_new_empresa: parseInt(props.match.params.id),
    };
    if (validForm) {
      API.admin_clients
        .createSub(form_data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            props.history.push(
              "/admin/clients/profile/" + props.match.params.id
            );
            toast.success(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
          setSubmitLoader(false);
        })
        .catch((err) => {
          setSubmitLoader(false);
          let response = err.response;
          console.log(err);
          console.log(response);
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    } else {
      setSubmitLoader(false);
    }
  };

  const changePlan = (event) => {
    let total;
    const currentPlan = event.target.value;
    setPlan(currentPlan);
    console.log("These are all plans", plans);
    let selectedPlan = plans.find(
      (filteredPlan) => filteredPlan.id_planes === parseInt(currentPlan)
    );
    console.log("selected plan", selectedPlan);
    total = selectedPlan.monto * cantLicencias;

    setCostoTotal(total);
    setFrecuenciaPago(selectedPlan.periodo);
  };
  const changeTotalToPay = (event) => {
    if (parseInt(event.target.value) === 0) {
      event.target.value = 1;
    }
    setCantLicencias(
      event.target.value < 0
        ? (event.target.value = -event.target.value)
        : event.target.value
    );
    let total;
    let selectedPlan = plans.find(
      (filteredPlan) => filteredPlan.id_planes === parseInt(plan)
    );
    total = selectedPlan.monto * parseInt(event.target.value);
    setCostoTotal(total);
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "cvc":
        setCVC(value);
        break;
      case "expiry":
        setExpiry(value);
        break;
      case "name":
        setName(value);
        break;
      case "number":
        setNumber(value);
        break;
      default:
        break;
    }
  };

  const getAllPlans = () => {
    API.subscriptions
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setPlans(response.data);
          setPlan(response.data[0].id_planes);
          setCantLicencias(1);
          setCostoTotal(response.data[0].monto);
          setFrecuenciaPago(response.data[0].periodo);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setSaveLoader(false);
      })
      .catch((err) => {
        setSaveLoader(false);
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Crear un nuevo cliente</strong>
        </h4>
      </div>

      <form
        className="content-card card mt-3"
        onSubmit={handleSubmit(handlePayment)}
      >
        <React.Fragment>
          <div className="card content-card card-body register-card p-5">
            {saveLoader ? (
              <CircularProgress size={26} className="mx-auto" />
            ) : (
              <>
                <h1>
                  <strong>
                    Realiza el pago de suscripcion del usuario para continuar
                  </strong>
                </h1>

                <div className="row mt-4">
                  <div className="col-lg-6">
                    <p className="mt-3 mb-0">
                      <strong>Plan a contratar</strong>
                    </p>
                    <Select
                      native
                      fullWidth
                      value={plan}
                      onChange={(e) => changePlan(e)}
                    >
                      {plans.map((plan) => (
                        <option value={plan.id_planes}>
                          {plan.descripcion}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-lg-2">
                    <p className="mt-3 mb-0">
                      <strong>N° de licencias</strong>
                    </p>
                    <TextField
                      type="number"
                      className="w-100"
                      fullWidth
                      value={cantLicencias}
                      onChange={(e) => changeTotalToPay(e)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">N°</InputAdornment>
                        ),
                        inputProps: { min: 1 },
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <p className="mt-3 mb-0">
                      <strong>Total a pagar</strong>
                    </p>
                    <TextField
                      type="number"
                      className="w-100"
                      placeholder=""
                      fullWidth
                      value={costoTotal}
                      onChange={(e) => setCostoTotal(e.target.value)}
                      // NOT CHANGED
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Q.</InputAdornment>
                        ),
                        disabled: true,
                      }}
                    />
                  </div>
                  {plan !== "7" ? (
                    <div className="col-lg-4 mt-2">
                      <p className="mt-3 mb-0">
                        <strong>Frecuencia de pago (en meses):*</strong>
                      </p>
                      <TextField
                        id="payment_frequency"
                        value={frecuenciaPago}
                        onChange={(e) => setFrecuenciaPago(e.target.value)}
                        margin="normal"
                        placeholder=""
                        type="text"
                        fullWidth
                        className="my-auto"
                        disabled
                      />
                    </div>
                  ) : null}

                  {plan !== "7" ? (
                    <div className="col-lg-4 mt-2">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Renovación automática</strong>
                      </label>

                      <label
                        class="toggle-control ml-3"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          value={auto_renewal}
                          onChange={() => setAutoRenewal(!auto_renewal)}
                          checked={auto_renewal}
                        />
                        <span class="control" />
                      </label>
                    </div>
                  ) : null}

                  {plan !== "7" ? (
                    <div className="col-md-6 text-center my-5">
                      <TextField
                        style={{ width: "293px" }}
                        variant="outlined"
                        value={name}
                        name="name"
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        placeholder="Nombre del tarjetahabiente"
                      />
                      <div className="d-block my-auto">
                        <PaymentInputsWrapper {...wrapperProps}>
                          <svg
                            {...getCardImageProps({
                              images,
                            })}
                          />
                          <input
                            {...getCardNumberProps({
                              onChange: handleInputChange,
                              onFocus: handleInputFocus,
                            })}
                            name="number"
                            value={number}
                          />
                          <input
                            {...getExpiryDateProps({
                              onChange: handleInputChange,
                              onFocus: handleInputFocus,
                            })}
                            name="expiry"
                            value={expiry}
                          />
                          <input
                            {...getCVCProps({
                              onChange: handleInputChange,
                              onFocus: handleInputFocus,
                            })}
                            name="cvc"
                            value={cvc}
                          />
                        </PaymentInputsWrapper>
                      </div>
                    </div>
                  ) : null}
                  {plan !== "7" ? (
                    <div className="col-md-6 my-5">
                      <Cards
                        cvc={cvc}
                        expiry={expiry}
                        focused={focus}
                        name={name}
                        number={number}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row mt-4 justify-content-center">
                  <div className="col-lg-4 d-flex justify-content-center">
                    {submitLoader ? (
                      <CircularProgress size={26} className="mx-auto" />
                    ) : (
                      <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        endIcon={
                          <ArrowForwardIosIcon
                            style={{
                              fontSize: "14px",
                            }}
                          />
                        }
                        style={{
                          color: "white",
                          height: "fit-content",
                          textTransform: "none",
                        }}
                        className="ml-auto gradient_bg_vanilla"
                        type="submit"
                      >
                        Pagar
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </React.Fragment>
      </form>
    </div>
  );
}
