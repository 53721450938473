import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const KardexReporte = ({ objeto }) => {
  const moneda = getMoneda();

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="kardex-reporte-table">
        <thead>
          <tr>
            <th>Fecha / Hora</th>
            <th>Producto</th>
            <th>Serie</th>
            <th>Numero</th>
            <th>Operación</th>
            <th>Cantidad Entrada</th>
            <th>Costo unitario Entrada</th>
            <th>Total Entrada</th>
            <th>Cantidad Salida</th>
            <th>Costo unitario Salida</th>
            <th>Total Salida</th>
            <th>Cantidad Existencias</th>
            <th>Costo unitario Existencias</th>
            <th>Total Existencias</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            console.log(x);

            return (
              <tr key={index}>
                <td>
                  {" "}
                  {moment(x.fecha).utcOffset(0).format("DD/MM/YYYY, h:mm:ss a")}
                </td>
                <td> {x.nombre_producto} </td>
                <td>{x.serie} </td>
                <td>{x.numero} </td>
                <td>{x.tipo_operacion} </td>
                <td>
                  {!x.id_new_motivo_ingreso ? "-" : numberFormater(x.cantidad)}{" "}
                </td>
                <td>
                  {!x.id_new_motivo_ingreso
                    ? "-"
                    : `${moneda}. ${formaterPriceFunction(
                        parseFloat(x.costo_unitario).toFixed(2) + ""
                      )}`}
                </td>
                <td>
                  {!x.id_new_motivo_ingreso ? (
                    "-"
                  ) : (
                    <strong>
                      {moneda}.{" "}
                      {formaterPriceFunction(
                        (x.cantidad * x.costo_unitario).toFixed(5) + ""
                      )}
                    </strong>
                  )}{" "}
                </td>

                <td>
                  {!x.id_new_motivo_egres
                    ? "-"
                    : `${numberFormater(x.cantidad)}`}{" "}
                </td>
                <td>
                  {!x.id_new_motivo_egres
                    ? "-"
                    : `${moneda}. ${formaterPriceFunction(
                        x.costo_unitario.toFixed(2) + ""
                      )}`}{" "}
                </td>

                <td>
                  {!x.id_new_motivo_egres ? (
                    "-"
                  ) : (
                    <strong>
                      {moneda}.{" "}
                      {formaterPriceFunction(
                        (x.costo_unitario * x.cantidad).toFixed(5) + ""
                      )}
                    </strong>
                  )}{" "}
                </td>

                <td>
                  {!x.saldo ? "-" : `${formaterPriceFunction(x.saldo + "")}`}{" "}
                </td>
                <td>
                  {x.costo_unitario === ""
                    ? "-"
                    : `${moneda}. ${formaterPriceFunction(
                        parseFloat(x.costo_unitario).toFixed(2) + ""
                      )}`}{" "}
                </td>

                <td>
                  {x.saldo === "" ? (
                    "-"
                  ) : (
                    <strong>
                      {moneda}.{" "}
                      {formaterPriceFunction(
                        (x.costo_unitario * x.saldo).toFixed(5) + ""
                      )}
                    </strong>
                  )}{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default KardexReporte;
