import React, { useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
//eslint-disable-next-line
import $ from "jquery";
import FooterLinks from "../../components/FooterLinks/FooterLinks";
import HeaderNoSession from "../../components/HeaderNoSession/Header";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { CircularProgress, Button } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import API from "../../api";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

// styles
import "./custom.css";
//

function Register(props) {
  let history = useHistory();

  // Definitions

  // states
  var [isLoading, setIsLoading] = useState(false);
  var [phone, setPhone] = useState("");

  // methods

  const submitForm = () => {
    setIsLoading(true);
    API.auth
      .sendOTP({
        contacto_telefono: phone,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setIsLoading(false);
          history.push({
            pathname: "/forgot/phone-2",
            state: { userPhone: phone },
          });
          toast.success("El código de recuperación ha sido enviado.", {
            autoClose: 10000,
          });
        } else {
          toast.warning(response.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  return (
    <div className="row h-100 w-100 p-0 m-0">
      <div className="row h-100 w-100 m-0 p-0 outer-container justify-content-center password-recovery">
        <HeaderNoSession />
        <div
          className="col-12 p-0 inner-container banner"
          style={{ marginTop: "66px" }}
        >
          <div className="row w-100 h-100 justify-content-around align-items-center">
            <div className="col-md-6">
              <h1 className="page-title">Recuperación de contraseña</h1>
            </div>

            <div className="col-md-6 align-items-center">
              <div className="d-flex justify-content-end align-items-center right-banner-text">
                <p className="mr-3 text-white my-auto">
                  <strong>¿Ya tienes cuenta?</strong>
                </p>
                <Button
                  variant="contained"
                  size="large"
                  component={Link}
                  to={"/login"}
                  style={{
                    backgroundColor: "#08BFE6",
                    color: "white",
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                >
                  Iniciar sesión
                </Button>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row justify-content-center">
              <div
                className="col-8 d-flex justify-content-around"
                id="steps-circles-col"
                role="tablist"
              >
                <div className="steps-circle-container">
                  <div className="steps-circle steps-circle-active">1</div>
                </div>
                <div className="steps-circle-container">
                  <div className="steps-circle">2</div>
                </div>
                <div className="steps-circle-container">
                  <div className="steps-circle">3</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-4 p-0 content"
          id="PWRecovery-step1"
          style={{ marginTop: "8rem" }}
        >
          <React.Fragment>
            <div className="card content-card card-body p-5">
              <h1>
                <strong>Verifique su identidad</strong>
              </h1>
              <p>Recibirá un código de verificación vía SMS</p>

              <div className="row">
                <div className="col-md-12">
                  <p className="mt-3 mb-0" style={{ color: "#0160E7" }}>
                    <strong>Número de teléfono</strong>
                  </p>
                  <PhoneInput
                    defaultCountry="GT"
                    international
                    countryCallingCodeEditable={false}
                    placeholder="Introduce el número de teléfono"
                    value={phone}
                    onChange={setPhone}
                    className={
                      isPossiblePhoneNumber(phone)
                        ? "correct-phone"
                        : "incorrect-phone"
                    }
                    error={
                      phone
                        ? isPossiblePhoneNumber(phone)
                          ? undefined
                          : "Número de teléfono no válido"
                        : "Se requiere un número de teléfono"
                    }
                  />
                </div>
              </div>

              {isLoading ? (
                <div className="row mt-5 justify-content-center">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                </div>
              ) : (
                <div className="row mt-5 justify-content-end">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <Button
                      disabled={!isPossiblePhoneNumber(phone)}
                      variant="contained"
                      size="large"
                      fullWidth
                      endIcon={
                        <ArrowForwardIosIcon
                          style={{
                            fontSize: "16px",
                            marginLeft: "0",
                            padding: "0",
                          }}
                        />
                      }
                      style={{
                        background: "#0160E7",
                        color: "white",
                        height: "fit-content",
                        textTransform: "none",
                        fontSize: "18px",
                      }}
                      className="ml-auto"
                      onClick={submitForm}
                    >
                      Enviar código
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <p className="text-center mt-4 mb-4">
              <strong>¿No recuerda su número de teléfono?</strong>
            </p>
            <Button
              variant="contained"
              size="large"
              fullWidth
              startIcon={
                <MailIcon
                  style={{
                    fontSize: "35px",
                  }}
                />
              }
              style={{
                background: "#0160E7",
                color: "white",
                height: "fit-content",
                textTransform: "none",
              }}
              className="ml-auto"
              component={Link}
              to={"/forgot/email-1"}
            >
              <div className="row justify-content-start p-0 m-0">
                <div className="col-md-10 text-left p-0 m-0">
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "0",
                    }}
                  >
                    <strong>Vía correo electrónico</strong>
                  </p>
                </div>
                <div className="col-md-10 text-left p-0 m-0">
                  <p
                    style={{
                      fontSize: "12px",
                      margin: "0",
                    }}
                  >
                    Recibe un correo electrónico de verificación
                  </p>
                </div>
              </div>
            </Button>
          </React.Fragment>
        </div>
        <FooterLinks />
      </div>
    </div>
  );
}

export default withRouter(Register);
