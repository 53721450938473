import React from "react";
// Icons

import { GetApp } from "@material-ui/icons/";
import { saveAs } from "file-saver";
// Styled Components

import { Img, Download, FlexContainer } from "./styled/styled.components";

const ImgDownload = ({ width, height, uri }) => {
  // const downloadURI = (uri, name) => {
  //   // TODO Maybe we can use this for download images.
  //   let link = document.createElement("a");
  //   link.download = name;
  //   link.href = uri;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const download = () => {
    if (!uri) return;
    const format = uri.split(".")[uri.split(".").length - 1];
    saveAs(uri, `image.${format}`);
  };

  return (
    <FlexContainer
      cursor="pointer"
      overflow="hidden"
      borderRadius="6px"
      width="fit-content"
    >
      <Download onClick={download}>
        <GetApp />
      </Download>
      <Img width={width} height={height} src={uri} objectFit="cover" />
    </FlexContainer>
  );
};

export default ImgDownload;
