import React from "react";
import MainCard from "./components/MainCard";

import TopBarCargaMasiva from "./components/TopBarCargaMasiva";
const CargaMasivaMercadeo = () => {
  return (
    <>
      <TopBarCargaMasiva />
      <MainCard />
    </>
  );
};

export default CargaMasivaMercadeo;
