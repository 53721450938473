import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const HistorialOperacionesReporte = ({ objeto }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="historialOperaciones-reporte-table">
        <thead>
          <tr>
            <th>N° de Operación</th>
            <th>Tiempo de operación</th>
            <th>Fecha / Hora</th>
            <th>N° d Productos</th>
            <th>Monto</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            console.log(x);

            return (
              <tr key={index}>
                <td>{x.numero_operacion}</td>
                <td>{x.tipo_operacion} </td>
                <td>
                  {moment(new Date(x.fecha)).utcOffset(0).format("DD/MM/YYYY")}
                </td>
                <td>{x.numero_productos} </td>
                <td>
                  {moneda}.{" "}
                  {formaterPriceFunction(Math.abs(x.monto).toFixed(2) + "")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default HistorialOperacionesReporte;
