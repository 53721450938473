// eslint-disable-next-line
import React, { useState, useEffect, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import API from "../../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";

// components

//data

export default function Users_all(props) {
  let history = useHistory();
  const [user, setUser] = useState({
    id_new_user: "",
    id_usuario: "",
    id_new_empresa: "",
    nombre: "",
    id_tipo_usuario_web_app: "",
    correo: "",
    id_sucursal: "",
    id_vendedor: "",
    serie: "",
    tipo_nombre: "",
    permisos: [0, 0, 0, 0, 0, 0, 0],
    caja: "",
    telefono: "",
  });

  const { register, handleSubmit, errors, watch, reset } = useForm();
  const password = useRef({});
  const [newUserForm, setNewUserForm] = useState(true);
  const [userId, setUserId] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [cajas, setCajas] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  password.current = watch("contrasena", "");

  //component will mount
  useEffect(() => {
    getStores();
  }, [userId]);

  const resetForm = (sucursalId) => {
    console.log("Reseting with this", sucursalId);
    if (props.history.location.state) {
      setNewUserForm(false);
      let userData = props.history.location.state;
      if (typeof userData.permisos[0] !== "number") {
        userData.permisos.map(
          (permiso, index) => (userData.permisos[index] = permiso.estado)
        );
      }

      setUser({
        nombre: userData.nombre,
        correo: userData.correo,
        id_usuario: userData.id_usuario,
        tipo_usuario: userData.id_tipo_usuario_web_app,
        telefono: userData.telefono,
        serie: userData.serie,
        id_sucursal: initialLoad ? userData.id_sucursal : sucursalId,
        tipo_usuario: userData.id_tipo_usuario_web_app,
        permisos: [
          userData.permisos[0] === 1 ? true : true,
          userData.permisos[1] === 1 ? true : true,
          userData.permisos[2] === 1 ? true : false,
          userData.permisos[3] === 1 ? true : false,
          userData.permisos[4] === 1 ? true : false,
          userData.permisos[5] === 1 ? true : false,
          userData.permisos[6] === 1 ? true : false,
        ],
        id_usuario: userData.id_usuario,
        id_caja: userData.id_caja,
      });

      reset({
        permiso1: userData.permisos[0],
        permiso2: userData.permisos[1],
        permiso3: userData.permisos[2],
        permiso4: userData.permisos[3],
        permiso5: userData.permisos[4],
        permiso6: userData.permisos[5],
        permiso7: userData.permisos[6],
      });
      setUserId(userData.id_new_user);
      setLoader(false);
      if (initialLoad) setInitialLoad(false);
    } else {
      setLoader(false);
    }
  };

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmDeletion").modal("hide");
      $("#confirmExit").modal("hide");
    };
  }, []);

  useEffect(() => {
    getUserTypes();
  }, []);

  // set states
  const getStores = () => {
    API.admin_clients
      .getClientStores({ id_new_empresa: props.match.params.id })
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          console.log("Response", response.data[0]);
          setSucursals(response.data);
          get_cajas({
            id_sucursal_bodega_ubicacion: props.history.location.state
              ? props.history.location.state.id_sucursal
              : response.data[0].id_sucursal_bodega_ubicacion,
          });
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las sucursales", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las sucursales", {
          autoClose: 10000,
        });
      });
  };
  const save_data = (data) => {
    setSubmitLoader(true);

    let permisos = [0, 0, 0, 0, 0, 0, 0];

    permisos[0] = data.permiso1 ? 1 : 0;
    permisos[1] = data.permiso2 ? 1 : 0;
    permisos[2] = data.permiso3 ? 1 : 0;
    permisos[3] = data.permiso4 ? 1 : 0;
    permisos[4] = data.permiso5 ? 1 : 0;
    permisos[5] = data.permiso6 ? 1 : 0;
    permisos[6] = data.permiso7 ? 1 : 0;

    data.permisos = permisos;
    console.log(data);

    if (newUserForm) {
      save_new_user(data);
    } else {
      update_user(data);
    }
  };

  const save_new_user = (data) => {
    data.id_new_empresa = parseInt(props.match.params.id);
    API.users
      .add_user(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("Usuario agregado con éxito", { autoClose: 10000 });
          props.history.goBack();
        } else {
          if (response.message) {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.error("Ocurrió un error.", {
              autoClose: 10000,
            });
          }
        }
        setSubmitLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        let data = response.data;
        console.log(response);
        if (data.message) {
          toast.error(data.message, { autoClose: 10000 });
        } else {
          toast.error("Ha ocurrido un error al crear el nuevo usuario", {
            autoClose: 10000,
          });
        }
        setSubmitLoader(false);
      });
  };

  const update_user = (data) => {
    data.id_new_empresa = props.match.params.id;
    data.id_usuario = user.id_usuario;
    API.users
      .update_user(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("Usuario actualizado con éxito.", { autoClose: 10000 });
          props.history.goBack();
        } else {
          if (response.message) {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.error("Ocurrió un error.", {
              autoClose: 10000,
            });
          }
        }
        setSubmitLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error al actualizar el usuario", {
          autoClose: 10000,
        });
        setSubmitLoader(false);
      });
  };
  const get_cajas = (data) => {
    API.cajas
      .getCajas(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Receiving these cajas", response.data);
          setCajas(response.data);
          resetForm(data.id_sucursal_bodega_ubicacion);
        }
      })
      .catch((err) => {
        console.log("err", err);
        /* let response = err.response;
				console.log(response);
				if (typeof response.data !== 'undefined') {
					toast.error(response.data.message, {
						autoClose: 10000
					});
				} else {
					toast.error('Ha ocurrido un error', { autoClose: 10000 });
				} */
      });
  };

  const changeSucursal = (e) => {
    get_cajas({ id_sucursal_bodega_ubicacion: e.target.value });
  };

  const getUserTypes = () => {
    API.userConfig
      .getUserTypes()
      .then((res) => {
        let response = res.data;
        if (response.success && JSON.stringify(response.data) !== "[]") {
          setUserTypes(response.data);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const deleteUser = (id) => {
    API.users
      .deleteUsers({
        ids_users: id,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("Usuario eliminado", {
            autoClose: 10000,
          });
          $("#confirmDeletion").modal("hide");
          history.push("/app/users");
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmDeletion" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => deleteUser(userId)}
                >
                  Sí, eliminar usuario
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => props.history.goBack()}
                >
                  Sí
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Usuarios</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Creación de usuario</strong>
        </h4>
      </div>

      <form
        className="content-card card mt-3"
        onSubmit={handleSubmit(save_data)}
      >
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Agregar un nuevo usuario</strong>
              </h5>
              <p className="mb-0">
                Añade un nuevo usuario y asigna los permisos administrativos.
              </p>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-2 delete-btn"
                variant="contained"
                color="primary"
                data-toggle="modal"
                data-target="#confirmExit"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1_blue mx-2"
                variant="contained"
                color="primary"
              >
                {submitLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Guardar"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Nombre del usuario </strong>
                </label>
                <Tooltip title="Indique un nombre de usuario. Ejemplo: John Doe">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  defaultValue={user.nombre}
                  className="w-100"
                  placeholder="Ingrese un nombre de usuario"
                  name="nombre_contacto"
                  required={true}
                  inputRef={register}
                />
              )}
              <input type="hidden" name="id_usuario" ref={register} />
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Correo electrónico de acceso</strong>
                </label>
                <Tooltip title="Ingrese un correo electrónico válido.">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  defaultValue={user.correo}
                  type="email"
                  className="w-100"
                  placeholder="Ingrese el correo electrónico del usuario"
                  name="correo_electronico"
                  required={true}
                  inputRef={register}
                />
              )}
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Sucursal</strong>
                </label>
                <Tooltip title="Seleccionar una sucursal.">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <FormControl variant="outlined" className="w-100">
                {loader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  <Select
                    className="cs-select1 select-w100"
                    inputRef={register}
                    required={true}
                    name="id_sucursal"
                    native
                    defaultValue={user.id_sucursal}
                    onChange={(event) => changeSucursal(event)}
                  >
                    <option value="">Seleccione un sucursal</option>
                    {sucursals.map((sucursal) => (
                      <option value={sucursal.id_sucursal_bodega_ubicacion}>
                        {sucursal.nombre_sucursal}
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>
            </div>

            {/* Second Row */}
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Contraseña de acceso</strong>
                </label>
                <Tooltip title="Ingrese una contraseña de al menos 8 caracteres que incluya mayúsculas, minúsculas, números y caracteres especiales para mayor seguridad.">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  type="password"
                  className="w-100"
                  placeholder="Asigne una contraseña de acceso"
                  inputRef={register}
                  required={newUserForm ? true : false}
                  name="contrasena"
                />
              )}
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Confirme contraseña </strong>
                </label>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  name="password_repeat"
                  type="password"
                  className="w-100"
                  placeholder="Ingrese nuevamente la contraseña"
                  inputRef={register({
                    validate: (value) =>
                      value === password.current ||
                      "Las contraseña no coinciden",
                  })}
                />
              )}
              {errors.password_repeat && (
                <strong style={{ color: "red" }}>
                  {errors.password_repeat.message}
                </strong>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Número de teléfono</strong>
                </label>
                <Tooltip title="Indique un número telefónico válido">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Número de teléfono "
                  inputRef={register}
                  required={true}
                  name="contacto_telefono"
                  defaultValue={user.telefono}
                />
              )}
            </div>

            {/* Third Row */}
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Asignar número de serie </strong>
                </label>
                <Tooltip title="Ingrese un número de serie que lo identifique.">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  defaultValue={user.serie}
                  className="w-100"
                  placeholder="Asigne un número de serie para este usuario"
                  inputRef={register}
                  required={true}
                  name="serie"
                />
              )}
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Asignar caja</strong>
                </label>
                <Tooltip title="Seleccione la caja para el vendedor">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <FormControl variant="outlined" className="w-100">
                {loader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  <Select
                    native
                    className="cs-select1 select-w100"
                    inputRef={register}
                    required={true}
                    name="id_caja_web_app"
                    defaultValue={user.id_caja}
                  >
                    <option value="">Seleccione una caja</option>
                    {cajas.map((caja) => (
                      <option value={caja.id_caja_web_app}>
                        {caja.descripcion}
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>
            </div>
          </div>

          <h5 className="mb-0 mt-5">
            <strong>Permisos</strong>
          </h5>
          <p className="mb-0">
            Personalice los permisos de los usuarios o asigne los permisos por
            defecto.
          </p>
          <hr />

          <div className="row mt-4">
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Tipos de usuario</strong>
                </label>
                <Tooltip title="Seleccione el tipo de usuario al que le asignará roles de usuarios.">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              <FormControl variant="outlined" className="w-100">
                {loader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  <Select
                    native
                    className="cs-select1 select-w100"
                    inputRef={register}
                    required={true}
                    name="tipo_usuario"
                    defaultValue={user.tipo_usuario}
                  >
                    <option value="">Seleccione el tipo de usuario</option>
                    {userTypes.map((type) => (
                      <option value={type.id_usuario_tipo_web_app}>
                        {type.descripcion}
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>
            </div>
            <div className="col-md-4 mt-3">
              <p className="mb-0" style={{ color: "#4F4F4F" }}>
                Puede asignar los permisos predeterminado para cada tipo de
                usuario, o personalizar los permisos para cada usuario.
              </p>
            </div>
            <div className="col-md-4 mt-3 text-right">
              {userId ? (
                <Button
                  className="btn-cs1 mx-2 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmDeletion"
                >
                  Eliminar usuario
                </Button>
              ) : null}
            </div>

            {/* Second row */}

            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Dashboard</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá consultar las estadísticas y toda la
                    información del Dashboard
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input type="checkbox" ref={register} name="permiso1" />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Inventario</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá consultar los productos del inventario,
                    realizar ingresos y egresos
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input type="checkbox" ref={register} name="permiso2" />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>
            {/* Third row */}

            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Clientes</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá consultar la base de datos con los
                    clientes, liquidar anticipos y cancelar facturas a crédito
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input type="checkbox" ref={register} name="permiso3" />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Traslados</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá emitir y administrar traslados entrantes y
                    salientes.
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input type="checkbox" ref={register} name="permiso4" />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Usuarios</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá administrar y añadir nuevos usuarios en su
                    sistema Cloud
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input type="checkbox" ref={register} name="permiso5" />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>Configuración</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá administrar la información de su empresa,
                    sucursales, formato de facturas...
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input type="checkbox" ref={register} name="permiso6" />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="row h-100">
                <div className="col-8">
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    <strong>POS</strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                    El usuario podrá facturar, consultar operaciones y cortes de
                    caja.
                  </p>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label
                    class="toggle-control"
                    style={{ left: "-15px", height: "40px" }}
                  >
                    <input type="checkbox" ref={register} name="permiso7" />
                    <span class="control" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
