import React from "react";

import "./leyenda.css";

const Leyenda = ({ lista }) => {
  return (
    <div className="leyendaComponent">
      {lista.map((x, index) => {
        return (
          <div className="leyendaItem">
            <div style={{ background: x.color }}></div>
            <span> {x.label}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Leyenda;
