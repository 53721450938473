import React, { useEffect, useState, useContext, Fragment } from "react";
import ProductPersonalizationContext from "../../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import {
  BigTitle,
  FlexContainer,
  Input,
  Select,
} from "../styled/styled.components";

const Tallas = ({ item, variant, index }) => {
  const { handleForms, formularios, currentProductTab, validation } =
    useContext(ProductPersonalizationContext);

  const currentField = formularios.find(
    (form) => form.variantId === variant.variantId
  );

  useEffect(() => {
    if (!currentField) {
      handleForms({
        variantId: variant?.variantId ? variant.variantId : "",
        valor_talla: item?.options[0]?.nombre_talla
          ? item.options[0].nombre_talla
          : "",
        id_talla: item?.options[0]?.id_new_detalle_tallas
          ? item.options[0].id_new_detalle_tallas
          : "",
        variante: `variante ${index + 1}`,
        cantidad_producto: 1,
        nombre: variant?.title ? variant.title : "",
        id_new_item: variant?.id_new_item ? variant.id_new_item : "",
        valor_color: "",
      });
    }
  }, [currentProductTab]);

  useEffect(() => {
    if (!currentField.cantidad_producto) {
      handleForms({
        variantId: variant.variantId,
        cantidad_producto: 1,
        id_producto: variant.id_new_item,
      });
      variant.cantidad = 1;
    }
  }, [variant]);

  const handleChange = (e) => {
    const talla = item.options.find(
      (itemC) => itemC.nombre_talla === e.target.value
    );
    variant.talla = talla.nombre_talla;
    handleForms({
      variantId: variant.variantId,
      valor_talla: talla.nombre_talla,
      id_talla: talla.id_new_detalle_tallas,
    });
  };

  return (
    <>
      <FlexContainer
        gridArea="e"
        flexWrap="wrap"
        alignItems="center"
        alignSelf="center"
        flexDirection="row"
        //border="#ec5454 solid 1px"
        position="static"
      ></FlexContainer>

      {/* END Talla Flex*/}
      {/* 3 Flex*/}
      <BigTitle gridArea="c" margin="0" fontSize="14px" fontWeight="700">
        Cantidad
      </BigTitle>
      <FlexContainer
        gridArea="f"
        flexWrap="wrap"
        alignItems="center"
        alignSelf="center"
        flexDirection="row"
        //border="#ec5454 solid 1px"
        position="static"
      >
        <span style={{ width: "10%" }}>N</span>
        <Input
          style={{ width: "90%" }}
          type="number"
          value={currentField ? currentField.cantidad_producto || "" : ""}
          onChange={(e) => {
            handleForms({
              variantId: variant.variantId,
              cantidad_producto: +e.target.value,
              id_producto: variant.id_new_item,
            });
            variant.cantidad = +e.target.value;
          }}
        />
        {validation && currentField && !currentField.cantidad_producto && (
          <span className="text-danger w-100">Requerido</span>
        )}
      </FlexContainer>
    </>
  );
};

export default Tallas;
