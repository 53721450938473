import React, { useState, useEffect } from "react";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { IconButton, InputAdornment } from "@material-ui/core";
import API from "../../../api";
import { toast } from "react-toastify";

// styles
import useStyles from "./styles";
import "./custom.css";

// icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockIcon from "@material-ui/icons/Lock";

function Login(props) {
  var classes = useStyles();

  // local
  var [isLoading, setIsLoading] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayPassword2, setDisplayPassword2] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (props.history.location.state) {
      setEmail(props.history.location.state.userEmail);
    } else {
      toast.error(
        "Ha ocurrido un error. Por favor comienza el proceso nuevamente.",
        {
          autoClose: 10000,
        }
      );
    }
  }, []);

  const updatePassword = () => {
    API.admin_user
      .emailChangePassword({
        correo: email,
        contrasena: password,
        confirmacion_contrasena: confirmPassword,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setIsLoading(false);
          toast.success(response.message);
          props.history.push("/admin/login");
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", {
            autoClose: 10000,
          });
        }
      });
  };

  return (
    <div className="row h-100" style={{ marginRight: "-17.5px" }}>
      <div className="col-lg-6 ">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="row p-0 w-100 justify-content-center">
            <div className="col-8">
              <div
                className="content-card card"
                style={{ position: "relative" }}
              >
                <div
                  className="p-2"
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    borderRadius: "50%",
                    top: "-25px",
                    left: "46%",
                  }}
                >
                  <LockIcon style={{ color: "#0160E7", fontSize: "38px" }} />
                </div>

                <div className="card-body ">
                  <h1 style={{ fontSize: "2.1rem" }} className="mb-1">
                    <strong>Actualizar contraseña</strong>
                  </h1>
                  <p>
                    Ingrese una nueva contraseña. Esta será su nueva contraseña
                    de acceso
                  </p>

                  <p className="mt-5 mb-0" style={{ color: "#0160E7" }}>
                    <strong>Contraseña</strong>
                  </p>

                  <TextField
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setDisplayPassword(!displayPassword)}
                          >
                            {displayPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    margin="normal"
                    placeholder="Ingrese su nueva contraseña"
                    type={displayPassword ? "text " : "password"}
                    fullWidth
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />

                  <p className="mt-5 mb-0" style={{ color: "#0160E7" }}>
                    <strong>Reingrese su contraseña</strong>
                  </p>

                  <TextField
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setDisplayPassword2(!displayPassword2)
                            }
                          >
                            {displayPassword2 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    margin="normal"
                    placeholder="Vuelva a ingresar su nueva contraseña"
                    type={displayPassword2 ? "text " : "password"}
                    fullWidth
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  />

                  <div>
                    {isLoading ? (
                      <CircularProgress size={26} className="mx-auto" />
                    ) : (
                      <Button
                        onClick={() => updatePassword()}
                        variant="contained"
                        size="large"
                        style={{
                          backgroundColor: "#0160E7",
                          color: "white",
                          textTransform: "none",
                        }}
                        className="w-100 mt-4"
                      >
                        <strong>Actualizar contraseña</strong>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-6 logo-container recovery2 d-flex justify-content-center align-items-center ">
        <img
          style={{ width: "400px", height: "auto" }}
          src={"/images/admin-logo.png"}
        />
      </div>
    </div>
  );
}

export default withRouter(Login);
