import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API, { base_url } from "../../../api";
import { base_url_images } from "../../../api";
import { toast } from "react-toastify";

// styles
import "./custom.css";

import EditIcon from "@material-ui/icons/Edit";

// components

//data

export default function Users_all(props) {
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    get_user();
  }, []);

  // states
  const { register, handleSubmit } = useForm();
  const [saveLoader, setSaveLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profileImg, setProfileImg] = useState(
    base_url_images + "images/placeholder-image.jpeg"
  );

  // set states

  const save = (data) => {
    data.id_superadmin = user.id_superadmin;
    setSaveLoader(true);
    //console.log(data)
    let validForm = true;
    if (validForm) {
      API.admin_user
        .updateDatauser(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setSaveLoader(false);
            window.location.reload();
            //props.history.push("/admin/usuarios");
            toast.success(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.success(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          setSaveLoader(false);
          let response = err.response;
          console.log(err);
          console.log(response);
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    }
    //console.log(data);
  };

  const updatePassword = () => {
    if (password !== "") {
      if (password != confirmPassword) {
        toast.error("Las contraseñas no coinciden", {
          autoClose: 3000,
        });
        return;
      }

      API.admin_user
        .emailChangePassword({
          contrasena: password,
          confirmacion_contrasena: confirmPassword,
          correo: user.correo,
        })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          let response = err.response;
          console.log(err);
          console.log(response);
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    } else {
      toast.error("Escriba su contraseña", { autoClose: 10000 });
    }
  };
  const updateImg = (e) => {
    if (typeof e.target.files[0] !== "undefined") {
      setProfileImg(URL.createObjectURL(e.target.files[0]));
      let image_file = e.target.files[0];
      update_picture(image_file);
    }
  };

  const get_user = () => {
    setLoader(true);
    API.admin_user
      .getCurrentUser()
      .then((res) => {
        let response = res.data;
        console.log("Getting this response", response.data);
        if (response.success) {
          console.log(response.data);
          setUser(response.data);
          if (response.data.url_imagen) {
            setProfileImg(base_url_images + response.data.url_imagen);
          }
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const update_picture = (file) => {
    API.admin_user
      .updatePicture(file)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          window.location.reload();
        } else {
          toast.success(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  return (
    <div id="dashboard-section">
      {/* write content here "whithout {}"*/}
      <form className="content-card card mt-3" onSubmit={handleSubmit(save)}>
        <div className="card-body">
          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div className="row mb-4">
              <div
                className="picture-container mx-2 mb-3"
                style={{
                  position: "relative",
                  width: "150px",
                  height: "150px",
                }}
              >
                <img
                  src={profileImg}
                  alt="Icono de perfil"
                  className="img-responsive enterprise-profile-picture"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                />

                <div
                  className="EditIcon"
                  style={{
                    position: "absolute",
                    right: "-12px",
                    bottom: "-18px",
                  }}
                >
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    onChange={(event) => updateImg(event)}
                  />
                  <label
                    htmlFor="raised-button-file"
                    className="p-2 hover"
                    style={{ backgroundColor: "#08BFE6", borderRadius: "50%" }}
                  >
                    <EditIcon
                      style={{
                        color: "#FFFFFF",
                        fontSize: "25px",
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="col-4">
                <h5>{user.nombre}</h5>
                <p>{user.cargo}</p>
              </div>

              <div className="col-6 text-right">
                <Button
                  type="submit"
                  className="btn-cs1 mx-1 mt-1"
                  variant="contained"
                  color="primary"
                >
                  {saveLoader ? (
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      color="white"
                    />
                  ) : (
                    "Editar perfil"
                  )}
                </Button>
              </div>

              <div className="col-12" />

              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Nombre</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Escriba el nombre del usuario"
                  inputRef={register}
                  name="nombre"
                  required={true}
                  defaultValue={user.nombre}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Teléfono</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="(         )         -            -"
                  inputRef={register}
                  name="telefono"
                  required={true}
                  defaultValue={user.telefono}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Cargo en la empresa</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Escriba el cargo del usuario"
                  inputRef={register}
                  name="cargo"
                  required={true}
                  defaultValue={user.cargo}
                />
              </div>

              {/* Second Row */}
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Correo electrónico </strong>
                </label>

                <TextField
                  type="email"
                  className="w-100"
                  placeholder="Escriba el correo electrónico del usuario"
                  inputRef={register}
                  name="correo"
                  required={true}
                  defaultValue={user.correo}
                />
              </div>
            </div>
          )}

          <h5 className="mb-2">
            <strong>Actualización de contraseñas</strong>
          </h5>

          <div className="row">
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Ingrese nueva contraseña</strong>
              </label>

              <TextField
                type="password"
                className="w-100"
                placeholder="Introduzca su nueva contraseña"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>

            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Reingrese nueva contraseña </strong>
              </label>

              <TextField
                type="password"
                className="w-100"
                placeholder="Vuelva a introducir su nueva contraseña"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </div>

            <div className="col-md-4 mt-3">
              <Button
                type="button"
                onClick={() => updatePassword()}
                className="btn-cs1 mx-1 mt-1"
                variant="contained"
                color="primary"
              >
                Actualizar contraseña
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
