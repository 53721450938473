import React from "react";
import CardHeader from "./CardHeader";
import CardMain from "./CardMain";

const Main = (props) => {
  return (
    <div className="content-card card mt-3">
      <CardHeader {...props} />
      <CardMain {...props} />
    </div>
  );
};

export default Main;
