import React, { useState, useEffect, useRef, useContext } from "react";

// Styled Components

import {
  GridContainer,
  FlexContainer,
  PerspectiveContainer,
  PerspectiveButton,
} from "./styled/styled.components";

import { toast } from "react-toastify";
// Components

import CustomizationView from "./customization.view";
import CustomizationPoints from "./customization.points";
import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";

const CustomizationPerspectives = ({
  perspectives,
  points,
  setPoints,
  currentPerspectiveTab,
  setCurrentPerspectiveTab,
  index,
  variantId,
  variant,
  contentType,
  customType,
}) => {
  // This handle the STAGE (canvas)
  const stageRef = useRef(null);
  // This gets the middle of STAGE (canvas)
  const [stageMiddle, setStageMiddle] = useState(200);
  const { currentProductTab, handleForms, formularios } = useContext(
    ProductPersonalizationContext
  );

  const currentField = formularios.find(
    (form) => form.variantId === variant.variantId
  );
  const getRandomColor = () => {
    // this gives you a random Hexadecimal code
    return `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")}`;
  };

  const downloadURI = (uri, name) => {
    // TODO Maybe we can use this for download images.
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let saveImg = () => {
    // TODO this is for save a base64 image
    const uri = stageRef.current.toDataURL();
    perspectives[currentPerspectiveTab].img64 = uri;
    // With this you can download a png of canvas
    downloadURI(uri, "test.png");
  };

  // useEffect(() => {
  //   if (!currentField?.vista) {
  //     variant.descuento_producto = 0;
  //     handleForms({
  //       variantId: variant.variantId,
  //       vista: perspectives[0].image,
  //       vista_index: 0,
  //       addProductWithDiscount: true,
  //       enable_customization: false,
  //       descuento_producto: "",
  //     });
  //   }
  // }, [currentField]);

  const handlePerspective = (i, item) => {
    //this changes the perspective tab
    setCurrentPerspectiveTab(i);
    variant.points = variant.points.map((x) => ({ ...x, vista: i }));
    handleForms({
      variantId: variant.variantId,
      vista: perspectives[i].image,
      vista_index: i,
    });
  };

  const addPoint = () => {
    //this add a circle in the middle of canvas
    if (points.length === 4) {
      toast.error("No puedes agregar mas de 4 areas por variante", {
        autoClose: 10000,
      });
      return false;
    }
    variant.points = [
      ...points,
      {
        title: "",
        color: getRandomColor(),
        x: stageMiddle,
        y: stageMiddle,
        contentType: "",
        customType: customType[0],
        dimensions: { width: 0, height: 0 },
        image: "",
        observations: "",
        vista: currentField?.vista || 0,
        descuento: 0,
      },
    ];
    setPoints([
      ...points,
      {
        title: "",
        color: getRandomColor(),
        x: stageMiddle,
        y: stageMiddle,
        contentType: "",
        customType: customType[0],
        dimensions: { width: 0, height: 0 },
        image: "",
        observations: "",
        descuento: 0,
      },
    ]);
    return true;
  };

  return (
    <GridContainer gridColumn="1/-1">
      <FlexContainer
        margin="auto"
        maxWidth="400px"
        gridColumn="1/-1"
        border="1px solid rgba(51,51,51,0.2)"
        padding="10px 0px"
        borderRadius="10px"
        justifyContent="center"
      >
        <PerspectiveContainer gridColumn="1/-1" justifyContent="center">
          {perspectives?.map((item, i) => {
            return (
              <PerspectiveButton
                key={i}
                active={currentField?.vista_index === i}
                margin="0"
                borderRadius="0"
                onClick={() => {
                  handlePerspective(i, item);
                }}
              >
                {item.title}
              </PerspectiveButton>
            );
          })}
        </PerspectiveContainer>
        <CustomizationView
          stageRef={stageRef}
          setStageMiddle={setStageMiddle}
          points={points}
          setPoints={setPoints}
          addPoint={addPoint}
          perspectives={perspectives}
          currentPerspectiveTab={currentField?.vista_index || 0}
          variant={variant}
        />
      </FlexContainer>
      <CustomizationPoints
        stageMiddle={stageMiddle}
        points={points}
        setPoints={setPoints}
        addPoint={addPoint}
        perspectives={perspectives}
        currentPerspectiveTab={currentPerspectiveTab}
        index={index}
        variantId={variantId}
        contentType={contentType}
        customType={customType}
        variant={variant}
      />
    </GridContainer>
  );
};

export default CustomizationPerspectives;
