import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const getAll = async (data) => {
  return await axios.post(`${base_url}/web/get-insumos`, qs.stringify(data), {
    headers: headers(),
  });
};

const getClasificationsupply = async (data) => {
  return await axios.get(`${base_url}/web/insumos/todos-clasificaciones-uno`, {
    headers: headers(),
  });
};

const createSupply = async (data, files) => {
  const form_data = new FormData();
  for (var key in data) {
    form_data.append(key, data[key]);
  }

  let count = 1;
  for (const file of files) {
    if (file) {
      let renamed_file = new File(
        [file],
        "$img_" + count.toString() + "$" + file.name,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );
      form_data.append("images", renamed_file);
    }
    count++;
  }

  return await axios.post(`${base_url}/web/add-insumo`, form_data, {
    headers: headers(),
  });
};

const updateSupply = async (data, files) => {
  const form_data = new FormData();
  for (var key in data) {
    form_data.append(key, data[key]);
  }

  let count = 1;
  let filesUploaded = false;

  for (const file of files) {
    if (file) {
      let renamed_file = new File(
        [file],
        "$img_" + count.toString() + "$" + file.name,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );
      form_data.append("images", renamed_file);
      filesUploaded = true;
    }
    count++;
  }
  if (!filesUploaded) {
    form_data.append("images", null);
    form_data.append("images", null);
    form_data.append("images", null);
    form_data.append("images", null);
    form_data.append("images", null);
  }

  return await axios.put(`${base_url}/web/update-insumo`, form_data, {
    headers: headers(),
  });
};

const getSupply = async (id) => {
  return await axios.get(`${base_url}/web/get-insumos/${id}`, {
    headers: headers(),
  });
};

const uploadXLS = async (file, sucursalId) => {
  const form_data = new FormData();
  form_data.append("images", file);
  form_data.append("id_sucursal_bodega_ubicacion", sucursalId);
  return await axios.post(`${base_url}/web/upload-supplies`, form_data, {
    headers: headers(),
  });
};

export const getProductSupplies = (id) =>
  axios.get(`${base_url}/web/compuestos/receta-completa/${id}`, {
    headers: headers(),
  });

export const saveProductSupplie = (data) =>
  axios.post(`${base_url}/web/compuestos/ingresos-insumos`, data, {
    headers: headers(),
  });
export const deleteProductSupplie = (data) =>
  axios.post(`${base_url}/web/compuestos/eliminar_insumo`, qs.stringify(data), {
    headers: headers(),
  });
export const editProductSupplie = (data) =>
  axios.post(`${base_url}/web/compuestos/editar-insumos`, data, {
    headers: headers(),
  });
export const getIdReceta = (id) =>
  axios.get(`${base_url}/web/compuestos/receta-id-producto/${id}`, {
    headers: headers(),
  });
export default {
  getAll,
  createSupply,
  updateSupply,
  getSupply,
  uploadXLS,
  getClasificationsupply,
};
