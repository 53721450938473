import React, { useState, useEffect, useRef, useCallback } from "react";
import "./scrollbar.css";
import { CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useInfinityScroll from "../../../../customHooks/useInfiniteScroll";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";

const Table = (props) => {
  const { clients, loader } = props;

  const [allClients, setAllClients] = useState([]);
  const [filterClients, setFilterClients] = useState([]);

  useEffect(() => {
    if (clients) {
      setAllClients(clients[0]);
      setFilterClients(clients[0]);
    }
  }, [clients]);

  // const [page, setPage] = useState(0);
  // const { clients } = useInfinityScroll(page);
  const background = localStorage.getItem("color_secundario");

  // const loader = useRef(null);

  // const handleObserver = useCallback((entries) => {
  //   const target = entries[0];
  //   if (target.isIntersecting) {
  //     setPage((prev) => prev + 10);
  //   }
  // }, []);

  // useEffect(() => {
  //   const option = {
  //     root: null,
  //     rootMargin: "20px",
  //     threshold: 0,
  //   };
  //   const observer = new IntersectionObserver(handleObserver, option);

  //   if (loader.current) observer.observe(loader.current);
  // }, [handleObserver]);

  const handleSearch = (e) => {
    const filtered = allClients.filter((item) =>
      item.nombre.toLowerCase().includes(e.target.value)
    );
    filtered ? setFilterClients(filtered) : setFilterClients(allClients);
  };

  return (
    <>
      <div className="col-md-7">
        <div
          className="card shadow"
          style={{ height: "calc(100vh - 94px)", border: "none" }}
        >
          <div className="py-2 d-flex align-items-center px-2">
            {" "}
            <div>
              <h5 className="m-0">
                <strong>Clientes</strong>
              </h5>
            </div>
            <div className="ml-auto">
              <div className="col-md-12">
                <div className="input-group search-bar">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Buscar cliente"
                    onChange={handleSearch}
                    disabled={allClients.length ? false : true}
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <SearchIcon />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive scroll" style={{ height: "100%" }}>
            <table className="table mb-0">
              <thead style={{ background }}>
                <tr className="text-light w-100">
                  <th scope="col">Nombre</th>
                  <th scope="col">Monto</th>
                  <th scope="col">Recompra</th>
                  <th scope="col">Tienda Frec.</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr className="mx-auto text-center">
                    <td colSpan={4}>
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : filterClients.length === 0 ? (
                  <p className="text-center mt-3">Sin datos para Mostrar</p>
                ) : (
                  filterClients.map((client) => (
                    <tr className="bg-white">
                      <td scope="row">{client.nombre}</td>
                      <td>{client.monto}</td>
                      <td>{client.recompra}%</td>
                      <td>{client.nombre_sucursal}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
