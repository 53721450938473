import {
  Button,
  FormControl,
  InputAdornment,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import InfoIcon from "@material-ui/icons/Info";
import { sale_units } from "./utils";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { realImageURL } from "../../../../api";
import ProductDetailsContext from "../../../../context/DetalleProductosAleko/ProductDetailsContext";

const General = () => {
  const {
    handleForm,
    product,
    handleFiles,
    images: arr_img,
    updateProduct,
    deleteImage,
  } = useContext(ProductDetailsContext);

  const [discountPrice, setDiscountPrice] = useState(false);
  const images = Object.entries(product)
    .filter((item) => item[0].includes("imagen"))
    .map((item) => item[1])
    .splice(0, 4);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="row">
      <div className="col-md-7">
        <div>
          <h5 className="mb-0">
            <strong>Detalles del producto</strong>
          </h5>
          <p className="mb-0">Información detallada acerca del producto.</p>
        </div>
      </div>
      <div className="col-md-5 d-flex justify-content-end">
        {/* <CSVLink
            data={product?ExcelData}
            filename={product?ExcelData[0].nombre + ".csv"}
          > */}
        <Button
          type="button"
          className="btn-cs1 brand2-btn mt-1 mx-1"
          variant="contained"
          color="primary"
        >
          <CloudDownloadIcon className="mr-2" />
          Descargar Excel
        </Button>
        <Button
          className="btn-cs1 mt-1 mx-1"
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => updateProduct()}
        >
          Actualizar
        </Button>
        {/* </CSVLink> */}
      </div>
      <div className="col-md-4 mt-3 ">
        <div>
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Nombre del producto</strong>
          </label>

          <TextField
            value={product?.nombre}
            name="nombre"
            onChange={handleForm}
            type="text"
            className="w-100"
            placeholder="Ingrese el nombre de su producto"
          />
        </div>
      </div>

      <div className="col-md-4 mt-3 ">
        <div>
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Precio de venta</strong>
          </label>
          <TextField
            value={formaterPriceFunction(
              product?.precio_venta?.toFixed(2) + ""
            )}
            name="precio_venta"
            onChange={handleForm}
            type="text"
            className="w-100"
            placeholder="Ingrese el precio de venta de su product"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Q.</InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <div className="col-md-4 mt-3 ">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Descripción de producto (Opcional)</strong>
        </label>
        <TextField
          value={product?.descripcion}
          onChange={handleForm}
          name="descripcion"
          type="text"
          multiline
          className="w-100"
          placeholder="Ingrese una breve descripción del product."
        />
      </div>

      <div className="col-md-4 mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Precio con descuento</strong>
            </label>
            <Tooltip title="Precio con descuento" className="mx-1">
              <InfoIcon
                style={{
                  color: " #828282",
                  fontSize: "1.2rem",
                }}
              />
            </Tooltip>
          </div>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              onChange={handleForm}
              name="descuento_toggle"
              checked={discountPrice}
              onClick={() => setDiscountPrice((prev) => !prev)}
              id={`customSwitchPrice`}
            />
            <label className="custom-control-label" for={`customSwitchPrice`} />
          </div>
        </div>
        <TextField
          value={formaterPriceFunction(
            product?.descuento_precio.toFixed(2) + ""
          )}
          type="text"
          name="descuento_precio"
          onChange={handleForm}
          className="w-100"
          disabled={!discountPrice}
          placeholder="Ingrese el precio promocional del product"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Q.</InputAdornment>
            ),
          }}
        />
        <small>
          El precio con descuento sustituirá el precio original de venta, puede
          activar y desactivar esta opción en cualquier momento.
        </small>
      </div>

      <div className="col-md-4 mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Código del producto</strong>
          </label>
          <Tooltip title="Código del product">
            <InfoIcon
              style={{
                color: " #828282",
                fontSize: "1.2rem",
              }}
            />
          </Tooltip>
        </div>
        <TextField
          value={product?.codigo}
          type="text"
          onChange={handleForm}
          name="codigo"
          className="w-100"
          placeholder="Ingrese el SKU / código de barras del product"
        />
      </div>

      <div className="col-md-4 mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Unidad de venta</strong>
          </label>
          <Tooltip title="Unidad de venta">
            <InfoIcon
              style={{
                color: " #828282",
                fontSize: "1.2rem",
              }}
            />
          </Tooltip>
        </div>

        <FormControl variant="outlined" className="w-100">
          <Select
            native
            onChange={handleForm}
            name="vendido_por"
            value={product?.vendido_por}
            className="cs-select1 select-w100"
          >
            <option value="">Seleccione la unidad de venta</option>
            {sale_units.map((unit) => (
              <option value={unit.value}>{unit.nombre}</option>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-md-4 mt-3 ">
        <div>
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Inventario del producto</strong>
          </label>

          <TextField
            value={product?.stock}
            onChange={handleForm}
            name="stock"
            type="text"
            className="w-100"
            placeholder="Ingrese el inventario del producto"
          />
        </div>
      </div>
      <div className="col-md-12 product-views">
        <div
          className="row py-3 mt-3"
          style={{ backgroundColor: "rgb(242,242,242)" }}
        >
          <div className="col-md-3 text-center">Frente</div>
          <div className="col-md-3 text-center">Atras</div>
          <div className="col-md-3 text-center">Derecha</div>
          <div className="col-md-3 text-center">Izquierda</div>
        </div>
      </div>
      <div className="col-md-12">
        <div
          className="row mt-3 py-3"
          style={{ background: "rgb(249,249,249)" }}
        >
          {images.map((image, index) => (
            <div className="col-md-3">
              <div className="d-flex">
                <div
                  className="w-100"
                  style={{ position: "relative", height: "250px" }}
                >
                  <HighlightOffIcon
                    className="delete-image-icon"
                    onClick={() => deleteImage(index)}
                  />
                  <img
                    src={
                      arr_img[`imagen_${index + 1}`] ||
                      realImageURL(image) ||
                      "/images/photo_main.png"
                    }
                    className="add_image_bck"
                    style={{ objectFit: "scale-down" }}
                    alt="img-input"
                  />
                  <input
                    accept="image/*"
                    className="add_image_btn"
                    type="file"
                    onChange={(e) => handleFiles(e, index)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default General;
