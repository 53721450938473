import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import API from "../../api";
import { base_url_images } from "../../api";

import { CircularProgress } from "@material-ui/core";

// styles
import "./custom.css";
import { getMoneda } from "../../helpers/moneda";
//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import moment from "moment";
import "moment/locale/es";
import print from "print-js";
moment.locale("es");

// components

const ref = React.createRef();

export default function Users_all(props) {
  useEffect(() => {
    if (!!localStorage.getItem("factura_data")) {
      let factura_data = JSON.parse(localStorage.getItem("factura_data"));
      console.log("Whole factura_data", factura_data);
      setFelData({
        ...factura_data.fel_data,
        client: factura_data.fel_data.client || factura_data.client,
        vendedor: factura_data.fel_data.vendedor || factura_data.seller,
        invoiceData: factura_data.invoiceInfo || null,
        fecha_anulacion: factura_data.fecha_anulacion
          ? factura_data.fecha_anulacion
          : null,
      });
      setOrderItems(
        factura_data.fel_data.orderItems || factura_data.selectedProducts
      );
      getInvoiceSucursal(factura_data.fel_data.id_sucursal_bodega_ubicacion);
      //localStorage.removeItem('factura_data');
    } else {
      setLoader(false);
      toast.error(
        "Ha ocurrido un error, por favor regrese a la página anterior.",
        {
          autoClose: 10000,
        }
      );
    }
  }, []);

  const [orderItems, setOrderItems] = useState([
    {
      cantidad: "",
      descripcion: "",
      codigo: "",
      costo_unitario: "",
      total: "",
    },
  ]);
  const [felData, setFelData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [invoiceSucursalData, setInvoiceSucursalData] = useState({});
  const [image, setImage] = useState(null);

  var convertir = require("numero-a-letras");

  const generatePdf = (filename) => {
    const element = document.querySelector(".pdf-target");

    /* const options = {
			filename: filename
		};

		return domToPdf(element, options, () => {
			// callback function
		}); */

    window.html2canvas = html2canvas;
    const pdf = new jsPDF("p", "pt", "a4");
    pdf.html(element, {
      callback: function (doc) {
        pdf.save(filename);
      },
      html2canvas: { scale: 0.475 },
      margin: [20, 15, 15, 20],
    });
  };

  const getInvoiceSucursal = (storeId) => {
    API.invoices
      .getSucursalInvoice({ id_sucursal_bodega_ubicacion: storeId })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setInvoiceSucursalData(response.data);
          setImage(base_url_images + response.data.imagen_factura);
        } else {
          console.log("Ha ocurrido un error: ", response.message);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const moneda = getMoneda();
  return (
    <div id="dashboard-section">
      {felData && (
        <>
          <div className="section-title mb-4">
            <HomeIcon className="d-none d-sm-block" />
            <ArrowForwardIosIcon
              style={{ color: "#333333", fontSize: "12px" }}
              className="mx-2 d-none d-sm-block"
            />
            <h4 className="mb-0">
              <strong>Caja de venta</strong>
            </h4>
            <ArrowForwardIosIcon
              style={{ color: "#333333", fontSize: "12px" }}
              className="mx-2"
            />
            <h4 className="mb-0">
              <strong>Facturación</strong>
            </h4>
          </div>

          <div
            className="content-card card mb-3"
            style={{ background: "none" }}
          >
            <div className="card-body">
              <div className="d-flex justify-content-center">
                {/*  <Pdf targetRef={ref} filename={'CloudPOS-factura-'+felData.numerofel}>
                            {({ toPdf }) => (
                                <button
                                    className="btn btn-primary"
                                    onClick={toPdf}
                                >
                                    Descargar PDF
                                </button>
                            )}
                        </Pdf> */}

                <button
                  className="btn btn-primary"
                  onClick={() =>
                    generatePdf("CloudPOS factura " + felData.identificador)
                  }
                >
                  Descargar PDF
                </button>
              </div>

              {loader ? (
                <div className="text-center">
                  <CircularProgress size={16} className="mx-auto" />
                </div>
              ) : invoiceSucursalData.factura_id === 1004 ? (
                <div className="d-flex justify-content-center" id="printform">
                  <div
                    style={{
                      border: "1px solid gray",
                      width: "100%",
                      overflowX: "scroll",
                    }}
                    className="mt-4"
                  >
                    <div
                      className="pdf-target px-4 py-3"
                      ref={ref}
                      style={{ backgroundColor: "white" }}
                    >
                      {false ? (
                        <div className="anulado-overlay">
                          <p style={{ color: "red" }}>FACTURA ANULADA</p>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="row">
                        <div className="col-3 px-1">
                          <img
                            src={image ? image : "/images/cloudLogo.png"}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                            }}
                            alt="factura-imagen"
                          />
                        </div>

                        <div className="col-7">
                          <p className="mb-0">
                            <strong>CLOUD, S.A</strong>
                          </p>
                          <p className="mb-0">
                            <strong>CLOUD</strong>
                          </p>
                          <p className="mb-0">
                            <strong>NIT: 7879036-0</strong>
                          </p>
                          <p>
                            Vía 4 1-00, zona 4 Edificio Campus Tec 2, 6to Nivel
                            Oficina 601. Teléfono 25033333
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="text-center">
                            <strong>DOCUMENTO TRIBUTARIO ELECTRONICO</strong>
                          </p>
                          <p className="text-center">
                            <strong>*** Factura Electrónica ***</strong>
                          </p>
                        </div>
                      </div>

                      <p className="ml-4">
                        FECHA: {moment(felData.fecha).local().format("L")}
                      </p>

                      <div className="d-flex justify-content-between">
                        <div class="ml-3">
                          <p className="mb-0">
                            <strong>Serie FEL:</strong> {felData.seriefel}
                          </p>
                          <p className="mb-0">
                            <strong>Numero FEL:</strong> {felData.numerofel}
                          </p>
                          <p className="mb-0">
                            <strong>Fecha Autorización:</strong>
                          </p>
                          <p className="mb-0">{felData.fechafel}</p>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "5px" }}
                      >
                        <div class="ml-0">
                          <p className="mb-0">
                            NOMBRE: {felData?.client?.nombre}
                          </p>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "10px" }}
                      >
                        <div class="ml-5">
                          <p className="mb-0">NIT : {felData?.client?.nit}</p>
                          <p className="mb-0">
                            DIR :{" "}
                            {felData?.client?.direccion || "No especificada."}
                          </p>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "10px" }}
                      >
                        <div class="ml-1">
                          <p className="mb-0">
                            FECHA {moment(felData.fecha).local().format("L")}
                          </p>
                        </div>
                      </div>

                      <div className="divider" />
                      <div className="row">
                        <div className="col-2 text-center">
                          <strong>Cantidad</strong>
                        </div>
                        <div className="col-5 text-center">
                          <strong>Descripción</strong>
                        </div>
                        <div className="col-3 text-center">
                          <strong>Precio Unitario</strong>
                        </div>
                        <div className="col-2 text-center">
                          <strong>Total</strong>
                        </div>
                      </div>
                      <div className="divider" />

                      {orderItems.map((product) => (
                        <div className="row">
                          <div className="col-2 text-center">
                            {product.cantidad}
                          </div>
                          <div className="col-5 text-center">
                            {product.descripcion} <br /> {product.codigo}
                          </div>
                          <div className="col-3 text-center">
                            {
                              product.total
                                ? product.precio.toFixed(2) // On this case, user comes from the sales process
                                : (product.precio / product.cantidad).toFixed(2) // On this case, user comes from wherever else
                            }
                          </div>
                          <div className="col-2 text-center">
                            {product.total
                              ? product.total.toFixed(2) // Same logic as before
                              : product.precio.toFixed(2)}
                          </div>
                        </div>
                      ))}

                      <div className="divider" />

                      <div className="row">
                        <div className="col-4" />
                        <div className="col-3" />
                        <div
                          className="col-5 text-right"
                          style={{ paddingRight: "44px" }}
                        >
                          <strong>
                            Total-Factura {moneda}{" "}
                            {orderItems[0].total
                              ? Number(
                                  orderItems.reduce((a, b) => a + b.total, 0)
                                ).toFixed(2)
                              : Number(
                                  orderItems.reduce((a, b) => a + b.precio, 0)
                                ).toFixed(2)}
                          </strong>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6 text-right">
                          <div className="row">
                            <div className="col-7 text-left">
                              {orderItems[0].total
                                ? Number(
                                    orderItems.reduce((a, b) => a + b.total, 0)
                                  ).toFixed(2)
                                : Number(
                                    orderItems.reduce((a, b) => a + b.precio, 0)
                                  ).toFixed(2) <= 1
                                ? convertir
                                    .NumerosALetras(
                                      orderItems[0].total
                                        ? Number(
                                            orderItems.reduce(
                                              (a, b) => a + b.total,
                                              0
                                            )
                                          ).toFixed(2)
                                        : Number(
                                            orderItems.reduce(
                                              (a, b) => a + b.precio,
                                              0
                                            )
                                          ).toFixed(2)
                                    )
                                    .toUpperCase()
                                    .replace(
                                      "PESO",
                                      moneda !== "L" ? "QUETZAL" : "LEMPIRA"
                                    )
                                : convertir
                                    .NumerosALetras(
                                      orderItems[0].total
                                        ? Number(
                                            orderItems.reduce(
                                              (a, b) => a + b.total,
                                              0
                                            )
                                          ).toFixed(2)
                                        : Number(
                                            orderItems.reduce(
                                              (a, b) => a + b.precio,
                                              0
                                            )
                                          ).toFixed(2)
                                    )
                                    .toUpperCase()
                                    .replace(
                                      "PESOS",
                                      moneda !== "L" ? "QUETZALES" : "LEMPIRAS"
                                    )}
                            </div>
                          </div>
                          {felData.efectivo > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">Efectivo:</div>
                              <div className="col-5 text-right">
                                {felData.efectivo}
                              </div>
                            </div>
                          ) : null}

                          {felData.cambio > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">Cambio:</div>
                              <div className="col-5 text-right">
                                {felData.cambios}
                              </div>
                            </div>
                          ) : null}

                          {felData.tarjeta > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">
                                Tarjeta Credito
                              </div>
                              <div className="col-5 text-right">
                                {felData.tarjeta}
                              </div>
                            </div>
                          ) : null}

                          {felData.pago_method ? (
                            <div className="row">
                              <div className="col-7 text-right">
                                Método de Pago
                              </div>
                              <div className="col-5 text-right">
                                {felData.pago_method}
                              </div>
                            </div>
                          ) : null}

                          {felData.monto_idp > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">Monto IDP</div>
                              <div className="col-5 text-right">
                                {felData.monto_idp}
                              </div>
                            </div>
                          ) : null}

                          {felData.monto_exento > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">
                                Monto Exento
                              </div>
                              <div className="col-5 text-right">
                                {felData.monto_exento.toFixed(2)}
                              </div>
                            </div>
                          ) : null}

                          <div className="row">
                            <div className="col-7 text-right">
                              Tipo de Venta
                            </div>
                            <div className="col-5 text-right">
                              {felData.tipo_venta === 0
                                ? "Para Llevar"
                                : felData.tipo_venta === 1
                                ? "Consumo Local"
                                : felData.tipo_venta === 2
                                ? "A domicilio (Delivery)"
                                : felData.tipo_venta === 3
                                ? "Presencial"
                                : felData.tipo_venta === 4
                                ? "Pick-Up"
                                : "Entrega"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-7 text-right">
                              Correlativo Interno
                            </div>
                            <div className="col-5 text-right">
                              B1
                              <label style={{ marginLeft: "50px" }}>618</label>
                            </div>
                          </div>
                          {/*<div className="row">
													<div className="col-7 text-right">Vendedor</div>
													<div className="col-5 text-right">Turno Mañana 1</div>

												</div>*/}

                          {/* <div className="row">
																<div className="col-7 text-right">Total Cupones:</div>
																<div className="col-5 text-right">{invoiceInfo.paidByGiftCard}</div>
															</div> */}
                        </div>
                      </div>

                      <p className="mb-0 mt-2 text-center">
                        SUJETO A PAGOS TRIMESTRALES
                      </p>
                      <p className="mb-0">
                        {moment(felData.fecha).format("L")}{" "}
                        {moment(felData.fecha).format("LTS")}
                      </p>
                      {/*<p><strong>Observaciones:</strong></p>*/}

                      <div className="row">
                        <div className="col-12">
                          <p className="text-center">
                            <strong>CLOUD, S.A</strong>
                          </p>
                          <p className="text-center">
                            <strong>{felData.service_id}</strong>
                          </p>
                          <p className="text-center">
                            <strong>Certificador</strong>{" "}
                            {felData.service_certificador} <strong>Nit</strong>{" "}
                            {felData.service_nit}
                          </p>
                        </div>
                      </div>

                      {invoiceSucursalData.texto_1 ? (
                        <>
                          <p className="mb-0 text-center">
                            <strong>{invoiceSucursalData.texto_1}</strong>
                          </p>
                        </>
                      ) : (
                        ""
                      )}

                      {invoiceSucursalData.texto_2 ? (
                        <>
                          <p className="mb-0 text-center">
                            <strong>{invoiceSucursalData.texto_2}</strong>
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      <p className="mb-0 text-center">
                        <strong>PBX: 2503 - 3333</strong>
                      </p>
                    </div>
                  </div>
                </div>
              ) : invoiceSucursalData.factura_id === 1003 ? (
                <div className="d-flex justify-content-center" id="printform">
                  <div
                    style={{
                      border: "1px solid gray",
                      width: "100%",
                      overflowX: "scroll",
                    }}
                    className="mt-4"
                  >
                    <div
                      className="pdf-target px-4 py-3"
                      ref={ref}
                      style={{ backgroundColor: "white" }}
                    >
                      {false ? (
                        <div className="anulado-overlay">
                          <p style={{ color: "red" }}>FACTURA ANULADA</p>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="row">
                        <div className="col-12">
                          <p className="text-center">
                            <strong>CLOUD, S.A</strong>
                          </p>
                          <p className="text-center">
                            <strong>CLOUD</strong>
                          </p>
                          <p className="text-center">
                            <strong>NIT: 7879036-0</strong>
                          </p>
                          <p className="text-center">
                            Vía 4 1-00, zona 4 Edificio Campus Tec 2, 6to Nivel
                            Oficina 601. Teléfono 25033333
                          </p>
                          <p className="text-center">
                            <strong>DOCUMENTO TRIBUTARIO ELECTRONICO</strong>
                          </p>
                          <p className="text-center">
                            <strong>*** Factura Electrónica ***</strong>
                          </p>
                        </div>
                      </div>

                      <p className="ml-4">
                        FECHA: {moment(felData.fecha).local().format("L")}
                      </p>

                      <div className="d-flex justify-content-between">
                        <div class="ml-3">
                          <p className="mb-0">
                            <strong>Serie FEL:</strong> {felData.seriefel}
                          </p>
                          <p className="mb-0">
                            <strong>Numero FEL:</strong> {felData.numerofel}
                          </p>
                          <p className="mb-0">
                            <strong>Fecha Autorización:</strong>
                          </p>
                          <p className="mb-0">{felData.fechafel}</p>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "5px" }}
                      >
                        <div class="ml-0">
                          <p className="mb-0">
                            NOMBRE: {felData?.client?.nombre}
                          </p>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "10px" }}
                      >
                        <div class="ml-5">
                          <p className="mb-0">NIT : {felData?.client?.nit}</p>
                          <p className="mb-0">
                            DIR :{" "}
                            {felData?.client?.direccion || "No especificada."}
                          </p>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "10px" }}
                      >
                        <div class="ml-1">
                          <p className="mb-0">
                            FECHA {moment(felData.fecha).local().format("L")}
                          </p>
                        </div>
                      </div>

                      <div className="divider" />
                      <div className="row">
                        <div className="col-2 text-center">
                          <strong>Cantidad</strong>
                        </div>
                        <div className="col-5 text-center">
                          <strong>Descripción</strong>
                        </div>
                        <div className="col-3 text-center">
                          <strong>Precio Unitario</strong>
                        </div>
                        <div className="col-2 text-center">
                          <strong>Total</strong>
                        </div>
                      </div>
                      <div className="divider" />

                      {orderItems.map((product) => (
                        <div className="row">
                          <div className="col-2 text-center">
                            {product.cantidad}
                          </div>
                          <div className="col-5 text-center">
                            {product.descripcion} <br /> {product.codigo}
                          </div>
                          <div className="col-3 text-center">
                            {
                              product.total
                                ? product.precio.toFixed(2) // On this case, user comes from the sales process
                                : (product.precio / product.cantidad).toFixed(2) // On this case, user comes from wherever else
                            }
                          </div>
                          <div className="col-2 text-center">
                            {product.total
                              ? product.total.toFixed(2) // Same logic as before
                              : product.precio.toFixed(2)}
                          </div>
                        </div>
                      ))}

                      <div className="divider" />

                      <div className="row">
                        <div className="col-4" />
                        <div className="col-3" />
                        <div
                          className="col-5 text-right"
                          style={{ paddingRight: "44px" }}
                        >
                          <strong>
                            Total-Factura {moneda}{" "}
                            {orderItems[0].total
                              ? Number(
                                  orderItems.reduce((a, b) => a + b.total, 0)
                                ).toFixed(2)
                              : Number(
                                  orderItems.reduce((a, b) => a + b.precio, 0)
                                ).toFixed(2)}
                          </strong>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6 text-right">
                          <div className="row">
                            <div className="col-7 text-left">
                              {orderItems[0].total
                                ? Number(
                                    orderItems.reduce((a, b) => a + b.total, 0)
                                  ).toFixed(2)
                                : Number(
                                    orderItems.reduce((a, b) => a + b.precio, 0)
                                  ).toFixed(2) <= 1
                                ? convertir
                                    .NumerosALetras(
                                      orderItems[0].total
                                        ? Number(
                                            orderItems.reduce(
                                              (a, b) => a + b.total,
                                              0
                                            )
                                          ).toFixed(2)
                                        : Number(
                                            orderItems.reduce(
                                              (a, b) => a + b.precio,
                                              0
                                            )
                                          ).toFixed(2)
                                    )
                                    .toUpperCase()
                                    .replace(
                                      "PESO",
                                      moneda !== "L" ? "QUETZAL" : "LEMPIRA"
                                    )
                                : convertir
                                    .NumerosALetras(
                                      orderItems[0].total
                                        ? Number(
                                            orderItems.reduce(
                                              (a, b) => a + b.total,
                                              0
                                            )
                                          ).toFixed(2)
                                        : Number(
                                            orderItems.reduce(
                                              (a, b) => a + b.precio,
                                              0
                                            )
                                          ).toFixed(2)
                                    )
                                    .toUpperCase()
                                    .replace(
                                      "PESOS",
                                      moneda !== "L" ? "QUETZALES" : "LEMPIRAS"
                                    )}
                            </div>
                          </div>
                          {felData.efectivo > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">Efectivo:</div>
                              <div className="col-5 text-right">
                                {felData.efectivo}
                              </div>
                            </div>
                          ) : null}

                          {felData.cambio > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">Cambio:</div>
                              <div className="col-5 text-right">
                                {felData.cambios}
                              </div>
                            </div>
                          ) : null}

                          {felData.tarjeta > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">
                                Tarjeta Credito
                              </div>
                              <div className="col-5 text-right">
                                {felData.tarjeta}
                              </div>
                            </div>
                          ) : null}

                          {felData.pago_method ? (
                            <div className="row">
                              <div className="col-7 text-right">
                                Método de Pago
                              </div>
                              <div className="col-5 text-right">
                                {felData.pago_method}
                              </div>
                            </div>
                          ) : null}

                          {felData.monto_idp > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">Monto IDP</div>
                              <div className="col-5 text-right">
                                {felData.monto_idp}
                              </div>
                            </div>
                          ) : null}

                          {felData.monto_exento > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">
                                Monto Exento
                              </div>
                              <div className="col-5 text-right">
                                {felData.monto_exento.toFixed(2)}
                              </div>
                            </div>
                          ) : null}

                          {felData.tipo_venta > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">
                                Tipo de Venta
                              </div>
                              <div className="col-5 text-right">
                                {felData.tipo_venta === 0
                                  ? "Para Llevar"
                                  : felData.tipo_venta === 1
                                  ? "Consumo Local"
                                  : felData.tipo_venta === 2
                                  ? "A domicilio (Delivery)"
                                  : felData.tipo_venta === 3
                                  ? "Presencial"
                                  : felData.tipo_venta === 4
                                  ? "Pick-Up"
                                  : "Entrega"}
                              </div>
                            </div>
                          ) : null}
                          <div className="row">
                            <div className="col-7 text-right">
                              Correlativo Interno
                            </div>
                            <div className="col-5 text-right">
                              B1
                              <label style={{ marginLeft: "50px" }}>618</label>
                            </div>
                          </div>
                          {/*<div className="row">
													<div className="col-7 text-right">Vendedor</div>
													<div className="col-5 text-right">Turno Mañana 1</div>

												</div>*/}

                          {/* <div className="row">
																<div className="col-7 text-right">Total Cupones:</div>
																<div className="col-5 text-right">{invoiceInfo.paidByGiftCard}</div>
															</div> */}
                        </div>
                      </div>

                      <p className="mb-0 mt-2 text-center">
                        SUJETO A PAGOS TRIMESTRALES
                      </p>
                      <p className="mb-0">
                        {moment(felData.fecha).format("L")}{" "}
                        {moment(felData.fecha).format("LTS")}
                      </p>
                      {/*<p><strong>Observaciones:</strong></p>*/}

                      <div className="row">
                        <div className="col-12">
                          <p className="text-center">
                            <strong>CLOUD, S.A</strong>
                          </p>
                          <p className="text-center">
                            <strong>{felData.service_id}</strong>
                          </p>
                          <p className="text-center">
                            <strong>Certificador</strong>{" "}
                            {felData.service_certificador} <strong>Nit</strong>{" "}
                            {felData.service_nit}
                          </p>
                        </div>
                      </div>

                      {invoiceSucursalData.texto_1 ? (
                        <>
                          <p className="mb-0 text-center">
                            <strong>{invoiceSucursalData.texto_1}</strong>
                          </p>
                        </>
                      ) : (
                        ""
                      )}

                      {invoiceSucursalData.texto_2 ? (
                        <>
                          <p className="mb-0 text-center">
                            <strong>{invoiceSucursalData.texto_2}</strong>
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      <p className="mb-0 text-center">
                        <strong>PBX: 2503 - 3333</strong>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center" id="printform">
                  <div
                    style={{
                      border: "1px solid gray",
                      width: "1240px",
                      overflowX: "scroll",
                    }}
                    className="mt-4"
                  >
                    <div className="pdf-target px-4 py-3" ref={ref}>
                      {felData.flag_estado === 0 ? (
                        <div className="anulado-overlay">
                          <p style={{ color: "red" }}>FACTURA ANULADA</p>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="row">
                        <div className="col-3 px-1">
                          <img
                            src={image ? image : "/images/cloudLogo.png"}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                            }}
                            alt="factura-imagen"
                          />
                        </div>

                        <div className="col-7">
                          <p className="mb-0">
                            <strong>CLOUD, S.A</strong>
                          </p>
                          <p className="mb-0">
                            <strong>CLOUD</strong>
                          </p>
                          <p className="mb-0">NIT: 7879036-0</p>
                          <p>
                            Vía 4 1-00, zona 4 Edificio Campus Tec 2, 6to Nivel
                            Oficina 601. Teléfono 25033333
                          </p>

                          {felData.seriefel ||
                          felData.id_dte ||
                          felData.numerofel ? (
                            <p className="mb-0">
                              <strong>DOCUMENTO TRIBUTARIO ELECTRONICO</strong>
                            </p>
                          ) : (
                            ""
                          )}

                          {felData.seriefel ? (
                            <p className="mb-0">
                              <strong>
                                SERIE "{felData.seriefel}" Número "
                                {felData.numerofel}"
                              </strong>
                            </p>
                          ) : (
                            ""
                          )}

                          {felData.id_dte ? (
                            <p className="mb-0">
                              <strong>Id. dte "{felData.id_dte}"</strong>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {felData.identificador ? (
                          <div className="col-2">
                            <p className="mb-0 text-right">
                              Id. único: {felData.identificador}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <p className="ml-4">
                        FECHA: {moment(felData.fecha).local().format("L")}
                      </p>

                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="mb-0">
                            Nombre: {felData?.client?.nombre}
                          </p>
                          <p className="mb-0">
                            Dirección:{" "}
                            {felData?.client?.direccion || "No especificada."}
                          </p>
                        </div>

                        <p className="mb-0">NIT: {felData?.client?.nit}</p>
                      </div>

                      <div className="divider" />
                      <div className="row">
                        <div className="col-2 text-center">
                          <strong>Cantidad</strong>
                        </div>
                        <div className="col-5 text-center">
                          <strong>Descripción</strong>
                        </div>
                        <div className="col-3 text-center">
                          <strong>Precio Unitario</strong>
                        </div>
                        <div className="col-2 text-center">
                          <strong>Total</strong>
                        </div>
                      </div>
                      <div className="divider" />

                      {orderItems.map((product) =>
                        product.combo ? (
                          <div className="row mt-1">
                            <div
                              className="col-2 text-center my-auto"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {product.cantidad}
                            </div>
                            <div
                              className="col-5 text-center my-auto"
                              style={{ fontSize: "0.9rem" }}
                            >
                              <div className="my-1">
                                <p style={{ fontSize: "0.9rem" }}>
                                  {product.nombre} <br /> {product.codigo}
                                </p>
                              </div>
                              <div>
                                {product.items.map((combo_item) => (
                                  <p style={{ fontSize: "0.7rem" }}>
                                    {combo_item.nombre} <br /> Cant. total:{" "}
                                    {combo_item.cantidad}
                                  </p>
                                ))}
                              </div>
                            </div>
                            <div
                              className="col-3 text-center my-auto"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {
                                product.total
                                  ? product.precio.toFixed(2) // On this case, user comes from the sales process
                                  : product.precio.toFixed(5) // On this case, user comes from wherever else
                              }
                            </div>
                            <div
                              className="col-2 text-center my-auto"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {product.total
                                ? (product.total * product.cantidad).toFixed(2) // Same logic as before
                                : (product.precio * product.cantidad).toFixed(
                                    2
                                  )}
                            </div>
                          </div>
                        ) : (
                          <div className="row mt-1">
                            <div
                              className="col-2 text-center my-auto"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {product.cantidad}
                            </div>
                            <div
                              className="col-5 text-center my-auto"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {product.nombre} <br /> {product.codigo}
                            </div>
                            <div
                              className="col-3 text-center my-auto"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {
                                product.total
                                  ? (product.total / product.cantidad).toFixed(
                                      2
                                    ) // On this case, user comes from the sales process
                                  : (product.precio / product.cantidad).toFixed(
                                      2
                                    ) // On this case, user comes from wherever else
                              }
                            </div>
                            <div
                              className="col-2 text-center my-auto"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {product.total
                                ? product.total.toFixed(2) // Same logic as before
                                : product.precio.toFixed(2)}
                            </div>
                          </div>
                        )
                      )}

                      <div className="divider" />

                      <div className="row">
                        <div className="col-4" />
                        <div className="col-3" />
                        <div
                          className="col-5 text-right"
                          style={{ paddingRight: "44px" }}
                        >
                          <strong>
                            Total-Factura {moneda}{" "}
                            {orderItems[0].total
                              ? Number(
                                  orderItems.reduce((a, b) => a + b.total, 0) -
                                    (felData.monto_exento
                                      ? felData.monto_exento
                                      : 0)
                                ).toFixed(2)
                              : Number(
                                  orderItems.reduce((a, b) => a + b.precio, 0) -
                                    (felData.monto_exento
                                      ? felData.monto_exento
                                      : 0)
                                ).toFixed(2)}
                          </strong>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <div className="row">
                            <div className="col-7 text-right">Efectivo:</div>
                            <div className="col-5 text-right">
                              {felData.efectivo ||
                                felData?.invoiceData?.paidByCash}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-7 text-right">Cambio:</div>
                            <div className="col-5 text-right">
                              {felData.cambios
                                ? felData.cambios.toFixed(2)
                                : felData?.invoiceData?.paidAmount.change}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-7 text-right">
                              Tarjeta Credito
                            </div>
                            <div className="col-5 text-right">
                              {felData.tarjeta ||
                                felData?.invoiceData?.paidByCard}
                            </div>
                          </div>
                          {felData.monto_exento > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">
                                Monto Exento
                              </div>
                              <div className="col-5 text-right">
                                {felData.monto_exento}
                              </div>
                            </div>
                          ) : null}

                          {felData.monto_idp > 0 ? (
                            <div className="row">
                              <div className="col-7 text-right">
                                Impuesto IDP
                              </div>
                              <div className="col-5 text-right">
                                {felData.monto_idp.toFixed(2)}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {/* <div className="row">
												<div className="col-7 text-right">Total Cupones:</div>
												<div className="col-5 text-right">{felData.gift_card}</div>
											</div> */}
                        </div>
                      </div>
                      {felData.flag_exentos || felData.flag_exentos === 1 ? (
                        <p className="mb-2 mt-2 text-center">
                          Exento según {felData.documento_exento}
                        </p>
                      ) : (
                        ""
                      )}

                      <p className="mb-0 mt-2 text-center">
                        REGIMEN SUJETO A RETENCION DEFINITIVA
                      </p>
                      <div className="divider" />

                      <p className="mb-0">
                        ATENDIÓ: {felData?.vendedor?.nombre}
                      </p>
                      <p className="mb-0">
                        CANAL DE ATENCIÓN:{" "}
                        {felData.canal_venta
                          ? felData.canal_venta.descripcion
                          : "No encontrado"}
                      </p>
                      {felData.tipo_venta !== 0 ? (
                        <p className="mb-0">
                          Tipo de Venta:{" "}
                          {felData.tipo_venta === 0
                            ? "Para Llevar"
                            : felData.tipo_venta === 1
                            ? "Consumo Local"
                            : felData.tipo_venta === 2
                            ? "A domicilio (Delivery)"
                            : felData.tipo_venta === 3
                            ? "Presencial"
                            : felData.tipo_venta === 4
                            ? "Pick-Up"
                            : "Entrega"}
                        </p>
                      ) : null}
                      {!felData.fecha_anulacion ? (
                        <p className="mb-0">
                          {felData.fecha.split("T")[0].split("-")[2] +
                            "/" +
                            felData.fecha.split("T")[0].split("-")[1] +
                            "/" +
                            felData.fecha.split("T")[0].split("-")[0] +
                            " " +
                            felData.fecha.split("T")[1].split(".")[0]}
                        </p>
                      ) : (
                        <p className="mb-0">
                          {felData.fecha_anulacion.split("T")[0].split("-")[2] +
                            "/" +
                            felData.fecha_anulacion
                              .split("T")[0]
                              .split("-")[1] +
                            "/" +
                            felData.fecha_anulacion
                              .split("T")[0]
                              .split("-")[0] +
                            " " +
                            felData.fecha_anulacion.split("T")[1].split(".")[0]}
                        </p>
                      )}
                      {felData.numerofel ? (
                        <p>Correlativo: {felData.numerofel}</p>
                      ) : (
                        ""
                      )}

                      {felData.service_id ? (
                        <div className="row">
                          <div className="col-3">FIRMA ELECTRONICA</div>
                          <div className="col-7">
                            <strong>{felData.service_id}</strong>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {felData.service_certificador ? (
                        <div className="row">
                          <div className="col-3">CERTIFICADOR:</div>
                          <div className="col-3">
                            {felData.service_certificador}
                          </div>
                          <div className="col-4">
                            {typeof felData.service_nit !== "undefined"
                              ? felData.service_nit
                              : ""}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {invoiceSucursalData.texto_1 ? (
                        <>
                          <div
                            className="divider"
                            style={{ marginTop: "250px" }}
                          />
                          <p className="mb-0 text-center">
                            {invoiceSucursalData.texto_1}
                          </p>
                          <div className="divider" />
                        </>
                      ) : (
                        ""
                      )}

                      {invoiceSucursalData.texto_2 ? (
                        <>
                          <p className="mb-0 text-center">
                            {invoiceSucursalData.texto_2}
                          </p>
                          <div className="divider" />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
