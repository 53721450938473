import React, { useState, useEffect } from "react";
// TODO maybe organize this
import WorkOrders from "./components/work.orders";
//
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import { base_url } from "../../api";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CSVLink } from "react-csv";
import TabPanel from "../../components/TabPanel";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
} from "recharts";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

//herramienta reporte
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//reportes
import HistorialOperacionesReporte from "../../reportes/historialOperacionesReporte";
import HistorialVentasReporte from "../../reportes/historialVentasReporte";
import HistorialAnulacionReporte from "../../reportes/historialAnulacionesReporte";
import HistorialNotasCreditoReporte from "../../reportes/historialNotasCreditoReporte";
import HistorialPorCobrarReporte from "../../reportes/historialPorCobrarReporte";
import HistorialNotaDebitoReporte from "../../reportes/historialNotaDebitoReporte";
import HistorialFelReporte from "../../reportes/historialFelReporte";

// styles
import "./custom.css";

//icons

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import "moment/locale/es";
import { isAleko } from "../../helpers/isAleko";
import { isCmi } from "../../helpers/isCmi";
import { getMoneda } from "../../helpers/moneda";
import { useOrders } from "../../customHooks/useOrders";
import millify from "millify";
moment.locale("es");
var qs = require("qs");

// components

// This is for tab Ordenes de trabajo
const ordenesTrabajo = true;
const ordenes = {
  activeOrders: "160",
  averageInvoices: "1,478.33",
  totalInvoices: "125,700.90",
  currentOrders: [
    {
      invoiceID: "0142511245233123",
      status: "entregado",
      client: { name: "María Benites", NIT: "123123123" },
      delivery: "12/01/2021",
      productsQuantity: "4",
      total: "1,202.22",
      currency: "Q",
    },

    {
      invoiceID: "0132511245233123",
      status: "cancelado",
      client: { name: "María Benites", NIT: "123123123" },
      delivery: "11/01/2021",
      productsQuantity: "34",
      total: "3,202.22",
      currency: "Q",
    },
    {
      invoiceID: "0112511245233123",
      status: "por entregar",
      client: { name: "María Benites", NIT: "123123123" },
      delivery: "10/01/2021",
      productsQuantity: "44",
      total: "4,202.22",
      currency: "Q",
    },
    {
      invoiceID: "0122511245233123",
      status: "cotizado",
      client: { name: "María Benites", NIT: "123123123" },
      delivery: "09/01/2021",
      productsQuantity: "40",
      total: "1,202.22",
      currency: "Q",
    },
  ],
};

//data
export default function Dashboard(props) {
  let history = useHistory();
  const moneda = getMoneda();
  let default_dates = [
    `${moment(new Date()).utcOffset(-6).format("YYYY-MM-DD")}T05:00:00.000Z`,
    `${moment(new Date())
      .utcOffset(-6)
      .add(1, "d")
      .format("YYYY-MM-DD")}T04:59:59.999Z`,
  ];

  // const [orders] = useOrders();

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };
  // states
  const [statsLoader, setStatsLoader] = useState(true);
  const [salesLoader, setSalesLoader] = useState(true);
  const [allOperationsLoader, setAllOperationsLoader] = useState(true);
  const [notasDebitoLoader, setNotasDebitoLoader] = useState(true);
  const [notasCreditoLoader, setNotasCreditoLoader] = useState(true);
  const [graphsLoader, setGraphsLoader] = useState(true);
  const [facturacionTime, setFacturacionTime] = useState("Anual");
  const [productsMenu, setProductsMenu] = useState(null);
  const [productsTab, setProductsTab] = useState(0);
  const [operationTime, setOperationTime] = useState("Anual");
  const [dateRange, setDateRange] = useState(default_dates);
  const [anulaciones, setAnulaciones] = useState([]);
  const [anulacionesLoader, setAnulacionesLoader] = useState(true);
  const [anulacionesFilterText, setAnulacionesFilterText] = useState("");
  const [salesFilterText, setSalesFilterText] = useState("");
  const [notasDebitoFilterText, setNotasDebitoFilterText] = useState("");
  const [filteredAnulaciones, setFilteredAnulaciones] = useState([]);
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [notasDebito, setNotasDebito] = useState([]);
  const [filteredNotasDebito, setFilteredNotasDebito] = useState([]);
  const [felHistory, setFelHistory] = useState([]);
  const [filteredFelHistory, setFilteredFelHistory] = useState([]);
  const [felHistoryLoader, setFelHistoryLoader] = useState(true);
  const [tipo_fel, setTipo_fel] = useState("0");
  const [operations_data, setOperationsData] = useState([]);
  const [dailyOpData, setDailyOpData] = useState([]);
  const [weeklyOpData, setWeeklyOpData] = useState([]);
  const [monthlyOpData, setMonthlyOpData] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [sellerSelected, setSellerSelected] = useState("");
  const [yearlyOpData, setYearlyOpData] = useState([]);
  const [cuentasPorCobrarLoader, setCuentasPorCobrarLoader] = useState(true);
  const [cuentasPorCobrar, setCuentasPorCobrar] = useState([]);
  const [filteredCuentasPorCobrar, setFilteredCuentasPorCobrar] = useState([]);
  const [stats, setStats] = useState([]);
  const [notasCredito, setNotasCredito] = useState([]);
  const [filteredNotasCredito, setFilteredNotasCredito] = useState([]);
  const [notasCreditoFilterText, setNotasCreditoFilterText] = useState("");
  const [FELFilterText, setFELFilterText] = useState("");
  const [allOperationsFilterText, setAllOperationsFilterText] = useState("");
  const [allOperations, setAllOperations] = useState([]);
  const [filteredAllOperations, setFilteredAllOperations] = useState([]);
  const [allOpsInfo, setAllOpsInfo] = useState({
    operaciones: "No hay información",
    ventas: "No hay información",
    anulaciones: "No hay información",
    notas_credito: "No hay información",
    notas_debito: "No hay información",
    cuentas_cobrar: "No hay información",
    cuentas_por_cobrar: [],
  });
  const [cuentasPorCobrarFilterText, setCuentasPorCobrarFilterText] =
    useState("");
  const [selectedAllOps, setSelectedAllOps] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [selectedCancellations, setSelectedCancellations] = useState([]);
  const [selectedCreditNotes, setSelectedCreditNotes] = useState([]);
  const [selectedFel, setSelectedFel] = useState([]);
  const [selectedCreditInvoices, setSelectedCreditInvoices] = useState([]);
  const [selectedDebitNotes, setSelectedDebitNotes] = useState([]);
  const [llevar, setLlevar] = useState({ total: 0, ventas: [] });
  const [comerLocal, setComerLocal] = useState({ total: 0, ventas: [] });
  const [domicilio, setDomicilio] = useState({ total: 0, ventas: [] });
  const [orders, setOrders] = useState([]);
  const [ordersResumen, setOrdersResumen] = useState({
    numeroVentas: "",
    pedidosActivos: "",
    ticket_promedio: "",
  });

  //cajero disable
  const [cajeroDisable, setCajeroDisable] = useState(false); // variable que verifica si es cajero

  const headers = () => {
    return { Authorization: "Bearer " + localStorage.getItem("token") };
  };

  useEffect(() => {
    if (sucursal !== "") {
      getNumericStats();
      let initial_dates = [dateRange[0], dateRange[1]];
      queryAllTabs(initial_dates);
    }
  }, [sucursal, sellerSelected]);

  // useEffect(() => {
  //   const getOrders = async () => {
  //     try {
  //       let idEmpresa = JSON.parse(window.localStorage.getItem("id_empresa"));
  //       const configToOrders = {
  //         id_new_empresa: idEmpresa,
  //         id_sucursal_bodega_ubicacion: sucursal,
  //         id_vendedor_web_app: 0,
  //         codigo_pedido: 0,
  //         fecha_inicial: moment(dateRange[0]).format("YYYY-MM-DD"),
  //         fecha_final: moment(dateRange[1]).format("YYYY-MM-DD"),
  //       };

  //       let orders = await API.sales.getAlekoOrders(configToOrders);
  //       // console.log("orders ->", orders);

  //       if (!orders.data.data.length && isAleko()) {
  //         toast.error("No hay ordenes en este momento", { autoClose: 10000 });
  //         return;
  //       }

  //       console.log("ordenes obtenidas ->", orders.data.data);

  //       setOrders(orders.data.data);
  //       setOrdersResumen({
  //         numeroVentas: orders.data.numeroVentas,
  //         pedidosActivos: orders.data.pedidosActivos,
  //         ticket_promedio: orders.data.ticket_promedio,
  //       });
  //     } catch (error) {
  //       console.log("error al obtener ordenes ->", error);
  //     }
  //   };
  //   if (sucursals.length > 0 && sellers.length > 0) {
  //     getOrders();
  //   }
  // }, [sucursals, sellers]);

  useEffect(() => {
    if (facturacionTime !== "" && sucursal !== "") {
      getNumericStats();
    }
  }, [facturacionTime]);

  useEffect(() => {
    getServerDate();
    cajeroValidate();
    getSucursales();
    //getGraphsData();
    localStorage.removeItem("feldata");
  }, []);

  //Valida al usuario de tipo cajero
  const cajeroValidate = () => {
    if (JSON.parse(localStorage.getItem("pms"))) {
      if (JSON.parse(localStorage.getItem("pms")).tipo_usuario === "CAJERO") {
        // si es usuario tipo cajero
        setCajeroDisable(true);
      }
    }
  };

  // set states
  const changeStore = (event) => {
    const storeName = event.target.value;
    setSucursal(storeName);
    getAllSellers(storeName);
    getGraphsData(storeName);
    //make api call
  };

  const getServerDate = () => {
    API.dashboard
      .getDate()
      .then((res) => {
        let { date, hour } = res.data.data;
        date = `${date}T05:00:00.000Z`;
        setDateRange([date, date]);
        console.log("Full Server Date", date, hour);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al obtener la fecha del servidor", {
          autoClose: 10000,
        });
      });
  };

  const getAllSellers = (sucursal) => {
    API.cajas
      .getCompanySellers({ id_sucursal_bodega_ubicacion: sucursal })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("response sellers", response);
          setSellers(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error al obtener los vendedores");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const render_fact_graph = (time) => {
    setFacturacionTime(time);
  };
  const handleProductTabs = (event, newValue) => {
    setProductsTab(newValue);
  };
  const render_op_graph = (time) => {
    setOperationTime(time);
    switch (time) {
      case "Diario":
        setOperationsData(dailyOpData);
        break;
      case "Semanal":
        setOperationsData(weeklyOpData);
        break;
      case "Mensual":
        setOperationsData(monthlyOpData);
        break;
      case "Anual":
        setOperationsData(yearlyOpData);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (value) => {
    setDateRange(value);
    let currentDates = [value[0], value[1]];
    queryAllTabs(currentDates);
  };

  const filterAnulaciones = (e) => {
    setAnulacionesFilterText(e.target.value);
    console.log(
      anulaciones.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setFilteredAnulaciones(
      anulaciones.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredAnulaciones(anulaciones);
    }
  };

  const filterSales = (e) => {
    setSalesFilterText(e.target.value);
    if (e.target.value === "") {
      setFilteredSales(sales);
    } else {
      const codeSales = sales
        .filter((item) => item.codigo_cliente)
        .filter((item) => item.codigo_cliente.includes(e.target.value));
      const nitSales = sales.filter((item) =>
        item.client.nit.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredSales([
        ...codeSales,
        ...nitSales,
        ...sales.filter((item) =>
          item.identificador
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ),
      ]);
    }
  };

  const filterNotasDebito = (e) => {
    if (e.target.value === "") {
      setNotasDebitoFilterText(e.target.value);
      setFilteredNotasDebito(notasDebito);
    } else {
      setNotasDebitoFilterText(e.target.value);
      setFilteredNotasDebito(
        notasDebito.filter((item) =>
          item.identificador
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const filterNotasCredito = (e) => {
    if (e.target.value === "") {
      setNotasCreditoFilterText(e.target.value);
      setFilteredNotasCredito(notasCredito);
    } else {
      setNotasCreditoFilterText(e.target.value);
      setFilteredNotasCredito(
        notasCredito.filter((item) =>
          item.identificador
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const filterFEL = (e) => {
    if (e.target.value === "") {
      setFELFilterText(e.target.value);
      setFilteredFelHistory(felHistory);
    } else {
      setFELFilterText(e.target.value);
      setFilteredFelHistory(
        felHistory.filter((item) =>
          item.serie.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const filterCuentasPorCobrar = (e) => {
    if (e.target.value === "") {
      setCuentasPorCobrarFilterText(e.target.value);
      setFilteredCuentasPorCobrar(cuentasPorCobrar);
    } else {
      setCuentasPorCobrarFilterText(e.target.value);
      setFilteredCuentasPorCobrar(
        cuentasPorCobrar.filter((item) =>
          item.id_pos_movimiento
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const filterAllOperations = (e) => {
    console.log(e);
    if (e.target.value === "") {
      setAllOperationsFilterText(e.target.value);
      setFilteredAllOperations(allOperations);
    } else {
      setAllOperationsFilterText(e.target.value);
      setFilteredAllOperations(
        allOperations.filter((item) =>
          item.numero_operacion
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const salesAction = (identificador, nit, id_pos_movimiento) => {
    history.push(`/app/vender/resumen/${id_pos_movimiento}`);
  };

  const allOpsAction = (event, operation) => {
    // console.log(operation);

    if (operation.tipo_operacion.includes("Venta")) {
      let currentSale = sales.find(
        (x) => x.identificador == operation.numero_operacion
      );
      const newWindow = window.open(
        "/#/app/vender/resumen/" + currentSale.id_pos_movimiento,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
    if (operation.tipo_operacion.includes("Cuenta por cobrar")) {
      let currentSale = allOpsInfo.cuentas_por_cobrar.find(
        (x) => x.identificador == operation.numero_operacion
      );
      if (!currentSale) return;
      const newWindow = window.open(
        "/#/app/vender/resumen/" + currentSale.id_pos_movimiento,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
    if (operation.tipo_operacion.includes("Anulación")) {
      let currentNote;
      if (operation.tipo_operacion.includes("crédito")) {
        currentNote = anulaciones.find(
          (x) => x.identificador === operation.numero_operacion
        );
        const newWindow = window.open(
          `/#/app/operaciones_historial/nota_credito/${currentNote.nota.id_nota_credito_web_app}`,
          "_blank",
          "noopener,noreferrer"
        );
        if (newWindow) newWindow.opener = null;
      } else if (operation.tipo_operacion.includes("débito")) {
        currentNote = anulaciones.find(
          (x) => x.identificador === operation.numero_operacion
        );
        const newWindow = window.open(
          `/#/app/operaciones_historial/nota_debito/${currentNote.nota.id_nota_debito_web_app}`,
          "_blank",
          "noopener,noreferrer"
        );
        if (newWindow) newWindow.opener = null;
      } else {
        let cancelledSale = anulaciones.find(
          (x) => x.identificador === operation.numero_operacion
        );
        history.push(
          `/app/operaciones_historial/resumen_anulacion/${cancelledSale.id_pos_movimiento}`
        );
      }
    }

    if (operation.tipo_operacion.includes("Nota de débito")) {
      let currentNote = notasDebito.find(
        (x) => x.identificador === operation.numero_operacion
      );
      const newWindow = window.open(
        `/#/app/operaciones_historial/nota_debito/${currentNote.id_nota_debito_web_app}`,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }

    if (operation.tipo_operacion.includes("Nota de crédito")) {
      let currentNote = notasCredito.find(
        (x) => x.identificador === operation.numero_operacion
      );
      const newWindow = window.open(
        `/#/app/operaciones_historial/nota_credito/${currentNote.id_nota_credito_web_app}`,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const getFelHistory = (dates, type) => {
    setFelHistoryLoader(true);
    let data = {
      from_date: dates[0],
      to_date: dates[1],
      tipo_fel: type,
      id_sucursal_bodega_ubicacion: sucursal,
    };
    API.historial_operaciones
      .get_fel_history(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setFelHistory(response.data);
          setFilteredFelHistory(response.data);
        }
        setFelHistoryLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setFelHistoryLoader(false);
      });
  };
  const changeFelType = (event) => {
    if (event.target.value != "") {
      setTipo_fel(event.target.value);
      getFelHistory(dateRange, event.target.value);
    }
  };

  const getGraphsData = (id_sucursal) => {
    setGraphsLoader(true);
    let data = {
      id_sucursal_bodega_ubicacion: id_sucursal,
    };
    API.historial_operaciones
      .get_graphs_data(data)
      .then((res) => {
        let response = res.data;

        console.log("Graph data", response);

        if (response.success && JSON.stringify(response.data) !== "[]") {
          setDailyOpData(response.data.daily);
          setWeeklyOpData(response.data.weekly);
          setMonthlyOpData(response.data.monthly);
          setYearlyOpData(response.data.yearly);
          setOperationsData(response.data.yearly);
        }
        setGraphsLoader(false);
      })
      .catch((err) => {
        if (err) {
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
          setGraphsLoader(false);
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
          setGraphsLoader(false);
        }
      });
  };

  const getNumericStats = () => {
    let factTimeMapping = ["Nothing", "Diario", "Semanal", "Mensual", "Anual"];
    let tipo = factTimeMapping.indexOf(facturacionTime);
    setStatsLoader(true);
    let data = {
      id_sucursal_bodega_ubicacion: sucursal,
      tipo_ordenar: tipo,
    };
    API.historial_operaciones
      .get_numeric_stats(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setStats(response.data);
        }
        setStatsLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        let response = err.response.data;
        //console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setStatsLoader(false);
      });
  };

  const getSucursales = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log("Sucursales", response);
        if (response.success) {
          // ordenamos segun la sucursal
          let sucursales_ordenadas = orderBySucursal(response.data);

          setSucursals(sucursales_ordenadas);
          setSucursal(sucursales_ordenadas[0].id_sucursal_bodega_ubicacion);
          getAllSellers(sucursales_ordenadas[0].id_sucursal_bodega_ubicacion);
          getGraphsData(sucursales_ordenadas[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response?.data;
        //console.log(response);
        if (typeof response?.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const initializeAllOperations = (
    filteredSales,
    rAnulaciones,
    filteredCreditNotes,
    filteredDebitNotes,
    rCuentasCobrar
  ) => {
    let all_operations = [];
    filteredSales.map((sale) => {
      all_operations.push({
        numero_operacion: sale.identificador,
        tipo_operacion: "Venta de producto",
        fecha: sale.fecha,
        numero_productos: sale.orderItems.length,
        monto: sale.monto_total,
      });
    });
    rAnulaciones.map((anulacion) => {
      //console.log(anulacion)
      all_operations.push({
        numero_operacion: anulacion.identificador,
        tipo_operacion: !anulacion.nota
          ? "Anulación de venta"
          : `${
              anulacion.nota.id_nota_debito_web_app
                ? "Anulación de nota de débito"
                : "Anulación de nota de crédito"
            }`,
        fecha: anulacion.nota ? anulacion.nota.fecha : anulacion.fecha,
        numero_productos: anulacion.nro_productos,
        monto: 0.0,
      });
    });
    filteredCreditNotes.map((nota) => {
      all_operations.push({
        numero_operacion: nota.identificador,
        tipo_operacion: "Nota de crédito",
        fecha: nota.fecha,
        numero_productos: nota.details.length,
        monto: nota.monto * -1,
      });
    });
    filteredDebitNotes.map((nota) => {
      all_operations.push({
        numero_operacion: nota.identificador,
        tipo_operacion: "Nota de débito",
        fecha: nota.fecha,
        numero_productos: "N/A",
        monto: nota.monto,
      });
    });
    rCuentasCobrar.map((cuenta) => {
      all_operations.push({
        numero_operacion: cuenta.identificador,
        tipo_operacion: "Cuenta por cobrar",
        fecha: cuenta.fecha,
        numero_productos: cuenta.nro_productos,
        monto: cuenta.monto,
      });
    });
    console.log("all_operations", all_operations);
    setAllOperations(all_operations);
    setFilteredAllOperations(all_operations);

    setAllOpsInfo({
      operaciones: all_operations.length,
      ventas: filteredSales.length,
      anulaciones: rAnulaciones.length,
      notas_credito: filteredCreditNotes.length,
      notas_debito: filteredDebitNotes.length,
      cuentas_cobrar: rCuentasCobrar.length,
      cuentas_por_cobrar: rCuentasCobrar,
    });
    setAllOperationsLoader(false);
  };

  const changeSeller_sales = (event) => {
    setSellerSelected(event.target.value);
  };

  const queryAllTabs = (dates) => {
    let id_sucursal_bodega_ubicacion = JSON.parse(
      localStorage.getItem("id_sucursal")
    );

    if (!sucursal && !id_sucursal_bodega_ubicacion) {
      return;
    }

    setSalesLoader(true);
    setAllOperationsLoader(true);
    setNotasDebitoLoader(true);
    setNotasCreditoLoader(true);
    setAnulacionesLoader(true);
    setFelHistoryLoader(true);
    setCuentasPorCobrarLoader(true);

    dates[0] = moment(dates[0]).format("YYYY-MM-DD");
    dates[1] = moment(dates[1]).format("YYYY-MM-DD");

    console.log(dates);

    axios
      .all([
        axios.post(
          `${base_url}/web/get-anulaciones-venta`,
          qs.stringify({
            from_date: dates[0],
            to_date: dates[1],
            id_vendedor_web_app: sellerSelected,
            id_sucursal_bodega_ubicacion: sucursal,
          }),
          { headers: headers() }
        ),

        axios.post(
          `${base_url}/web/get-fel-history`,
          qs.stringify({
            from_date: dates[0],
            to_date: dates[1],
            tipo_fel: 0,
            id_sucursal_bodega_ubicacion: sucursal
              ? sucursal
              : id_sucursal_bodega_ubicacion,
            id_vendedor_web_app: sellerSelected,
          }),
          { headers: headers() }
        ),

        axios.post(
          `${base_url}/web/cuentas_por_cobrar`,
          qs.stringify({
            from_date: dates[0],
            to_date: dates[1],
            id_sucursal_bodega_ubicacion: sucursal,
            id_vendedor_web_app: sellerSelected,
          }),
          { headers: headers() }
        ),
        axios.post(
          `${base_url}/web/get-nota-creditos`,
          qs.stringify({
            from_date: dates[0],
            to_date: dates[1],
            id_sucursal_bodega_ubicacion: sucursal,
            id_vendedor_web_app: sellerSelected,
          }),
          { headers: headers() }
        ),

        axios.post(
          `${base_url}/web/get-nota-debitos`,
          qs.stringify({
            from_date: dates[0],
            to_date: dates[1],
            id_sucursal_bodega_ubicacion: sucursal,
            id_vendedor_web_app: sellerSelected,
          }),
          { headers: headers() }
        ),

        axios.post(
          `${base_url}/web/get-sales`,
          qs.stringify({
            from_date: dates[0],
            to_date: dates[1],
            id_sucursal_bodega_ubicacion: sucursal,
            id_vendedor_web_app: sellerSelected,
          }),
          { headers: headers() }
        ),
      ])
      .then(
        axios.spread((...responses) => {
          //Process anulaciones de venta
          let rAnulaciones = responses[0].data.data;

          setAnulaciones(rAnulaciones);
          setFilteredAnulaciones(rAnulaciones);
          let info = {};
          info.total = rAnulaciones.length;

          setAnulacionesLoader(false);

          //Process fel history
          let rFel = responses[1].data.data;
          setFelHistory(rFel);
          setFilteredFelHistory(rFel);
          setFelHistoryLoader(false);

          //Process cuentas por cobrar
          let rCuentas = responses[2].data.data;

          setCuentasPorCobrar(rCuentas);
          setFilteredCuentasPorCobrar(rCuentas);
          setCuentasPorCobrarLoader(false);

          //Process notas de credito
          let rNC = responses[3].data.data;
          let filteredCreditNotes = rNC;
          // Put 0 amount to notes that have been cancelled
          rAnulaciones.map((cancellation) => {
            if (cancellation.identificador.includes("NC")) {
              filteredCreditNotes = filteredCreditNotes.filter(
                (x) =>
                  x.id_nota_credito_web_app !==
                  cancellation.nota.id_nota_credito_web_app
              );
              let foundIndex = rNC.findIndex(
                (x) =>
                  x.id_nota_credito_web_app ===
                  cancellation.nota.id_nota_credito_web_app
              );
              if (foundIndex !== -1) {
                //sconsole.log(rNC)
                rNC[foundIndex].monto !== undefined
                  ? (rNC[foundIndex].monto = 0.0)
                  : (rNC[foundIndex].monto_total = 0.0);
              }
            }
          });
          //console.log("Received RNC", rNC);
          setNotasCredito(rNC);
          setFilteredNotasCredito(rNC);
          setNotasCreditoLoader(false);

          //Process notas de débito
          let rND = responses[4].data.data;
          //console.log("Received RND", rND);

          let filteredDebitNotes = rND;
          setNotasDebito(rND);
          setFilteredNotasDebito(rND);
          setNotasDebitoLoader(false);

          rAnulaciones.map((cancellation) => {
            if (cancellation.identificador.includes("ND")) {
              filteredDebitNotes = filteredDebitNotes.filter(
                (x) =>
                  x.id_nota_debito_web_app !==
                  cancellation.nota.id_nota_debito_web_app
              );
              let foundIndex = rND.findIndex(
                (x) =>
                  x.id_nota_debito_web_app ===
                  cancellation.nota.id_nota_debito_web_app
              );
              rNC[foundIndex].monto !== undefined
                ? (rNC[foundIndex].monto = 0.0)
                : (rNC[foundIndex].monto_total = 0.0);
            }
          });

          //Process sales
          let rSales = responses[5].data.data;

          if (!isCmi()) {
            const array_llevar = rSales.filter(
              (item) => item.tipo_venta === 0
            ).length;
            const total_llevar = rSales
              .filter((item) => item.tipo_venta === 0)
              .reduce((a, b) => a + b.monto_total, 0);
            const array_comerLocal = rSales.filter(
              (item) => item.tipo_venta === 1
            ).length;
            const total_comerLocal = rSales
              .filter((item) => item.tipo_venta === 1)
              .reduce((a, b) => a + b.monto_total, 0);

            const array_domicilio = rSales.filter(
              (item) => item.tipo_venta === 2
            ).length;
            const total_domicilio = rSales
              .filter((item) => item.tipo_venta === 2)
              .reduce((a, b) => a + b.monto_total, 0);
            setLlevar({
              ventas: array_llevar,
              total: total_llevar.toFixed(2),
            });
            setComerLocal({
              ventas: array_comerLocal,
              total: total_comerLocal.toFixed(2),
            });
            setDomicilio({
              ventas: array_domicilio,
              total: total_domicilio.toFixed(2),
            });
          }
          if (isCmi()) {
            const array_llevar = rSales.filter(
              (item) => item.tipo_venta === 3
            ).length;
            const total_llevar = rSales
              .filter((item) => item.tipo_venta === 3)
              .reduce((a, b) => a + b.monto_total, 0);
            const array_comerLocal = rSales.filter(
              (item) => item.tipo_venta === 4
            ).length;
            const total_comerLocal = rSales
              .filter((item) => item.tipo_venta === 4)
              .reduce((a, b) => a + b.monto_total, 0);

            const array_domicilio = rSales.filter(
              (item) => item.tipo_venta === 5
            ).length;
            const total_domicilio = rSales
              .filter((item) => item.tipo_venta === 5)
              .reduce((a, b) => a + b.monto_total, 0);
            setLlevar({
              ventas: array_llevar,
              total: total_llevar.toFixed(2),
            });
            setComerLocal({
              ventas: array_comerLocal,
              total: total_comerLocal.toFixed(2),
            });
            setDomicilio({
              ventas: array_domicilio,
              total: total_domicilio.toFixed(2),
            });
          }

          let filteredSales = rSales;
          // Eliminate sales that have been cancelled

          rAnulaciones.map(
            (cancelled_sale) =>
              (filteredSales = filteredSales.filter(
                (x) => x.id_pos_movimiento !== cancelled_sale.id_pos_movimiento
              ))
          );

          setSales(filteredSales);
          console.log("filteredSales ->", filteredSales);
          setFilteredSales(filteredSales);
          setSalesLoader(false);
          //console.log(rAnulaciones)

          console.log(
            "SUCURSAL",
            sucursal,
            "Sales",
            filteredSales,
            "Anulaciones",
            rAnulaciones,
            "Credit Notes",
            filteredCreditNotes,
            "Debit Notes",
            filteredDebitNotes,
            "Cuentas por cobrar",
            rCuentas
          );

          initializeAllOperations(
            filteredSales,
            rAnulaciones,
            filteredCreditNotes,
            filteredDebitNotes,
            rCuentas
          );
        })
      )
      .catch((errors) => {
        console.log("Errors in axios.all:", errors);
        toast.error("Ha ocurrido un error al recibir los datos", {
          autoClose: 10000,
        });
      });
  };

  const openCuentaPorCobrarInvoice = (cuenta) => {
    const newWindow = window.open(
      `/#/app/vender/recibo-credito/${cuenta.id_cliente_deudor_web_app}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;
    let data = selectedRows;
    console.log(selectedRows);
    console.log(productsTab);
    if (productsTab == 0) {
      setSelectedAllOps(data);
    }
    if (productsTab == 1) {
      setSelectedSales(data);
    }
    if (productsTab == 2) {
      setSelectedCancellations(data);
    }
    if (productsTab == 3) {
      setSelectedCreditNotes(data);
    }
    if (productsTab == 4) {
      setSelectedFel(data);
    }
    if (productsTab == 5) {
      setSelectedCreditInvoices(data);
    }
    if (productsTab == 6) {
      setSelectedDebitNotes(data);
    }
  };

  const orderBySucursal = (data) => {
    // extraemos la sucursal guardada
    let id_sucursal_local = window.localStorage.getItem("id_sucursal");
    let sucursal_filtrada = data.filter((item) =>
      item.id_sucursal_bodega_ubicacion == Number(id_sucursal_local)
        ? item.id_sucursal_bodega_ubicacion
        : ""
    );

    // onjetos que son diferentes a la extraidata
    let sucursales_diferentes = data.filter(
      (item) => item.id_sucursal_bodega_ubicacion != Number(id_sucursal_local)
    );

    let sucursales_ordenadas = [...sucursal_filtrada, ...sucursales_diferentes];
    return sucursales_ordenadas;
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Historial</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select
            native
            value={sucursal}
            onChange={changeStore}
            disabled={cajeroDisable}
          >
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="row card-wrapper ">
        <div className="col-lg-6 mb-2">
          <div className="content-card card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h4>
                  <strong>Estadísticas</strong>
                </h4>

                <ButtonGroup
                  className="tab-buttons"
                  color="primary"
                  aria-label="outlined primary button group"
                  size="small"
                >
                  <Button
                    className={facturacionTime === "Anual" ? "active" : ""}
                    onClick={() => render_fact_graph("Anual")}
                  >
                    Anual
                  </Button>
                  <Button
                    className={facturacionTime === "Mensual" ? "active" : ""}
                    onClick={() => render_fact_graph("Mensual")}
                  >
                    Mensual
                  </Button>
                  <Button
                    className={facturacionTime === "Semanal" ? "active" : ""}
                    onClick={() => render_fact_graph("Semanal")}
                  >
                    Semanal
                  </Button>
                  <Button
                    className={facturacionTime === "Diario" ? "active" : ""}
                    onClick={() => render_fact_graph("Diario")}
                  >
                    Diario
                  </Button>
                </ButtonGroup>
              </div>

              <div className="mt-2 stat-card-cs">
                <div className="card-header">Ventas</div>

                <div className="row mt-2">
                  <div
                    className="col-md-4 px-2"
                    style={{
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "0.85rem",
                        color: "black",
                      }}
                    >
                      Facturación
                    </p>
                    <h6 className="mb-0 secondary_color">
                      {statsLoader ? (
                        <div className="text-center">
                          <CircularProgress size={26} className="mx-auto" />
                        </div>
                      ) : (
                        <strong>
                          {moneda}.{" "}
                          {stats.facturacion
                            ? formaterPriceFunction(
                                stats.facturacion.toFixed(2) + ""
                              )
                            : "0.00"}
                        </strong>
                      )}
                    </h6>
                  </div>
                  <div
                    className="col-md-4 px-2"
                    style={{
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "0.85rem",
                        color: "black",
                      }}
                    >
                      Ticket promedio
                    </p>
                    <h6 className="mb-0 secondary_color">
                      {statsLoader ? (
                        <div className="text-center">
                          <CircularProgress size={26} className="mx-auto" />
                        </div>
                      ) : (
                        <strong>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            stats.ticket_promedio.toFixed(2) + ""
                          )}
                        </strong>
                      )}
                    </h6>
                  </div>
                  <div className="col-md-4 px-2">
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "0.85rem",
                        color: "black",
                      }}
                    >
                      Productos vendidos
                    </p>
                    <h6 className="mb-0 secondary_color">
                      {statsLoader ? (
                        <div className="text-center">
                          <CircularProgress size={26} className="mx-auto" />
                        </div>
                      ) : (
                        <strong>
                          {formaterPriceFunction(
                            stats.productos_vendidos.toFixed(2) + ""
                          )}
                        </strong>
                      )}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="mt-2 stat-card-cs">
                <div className="card-header">Traslados</div>

                <div className="row mt-2">
                  <div
                    className="col-md-4 px-2"
                    style={{
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "0.85rem",
                        color: "black",
                      }}
                    >
                      N° de traslados
                    </p>
                    <h6 className="mb-0 secondary_color">
                      {statsLoader ? (
                        <div className="text-center">
                          <CircularProgress size={26} className="mx-auto" />
                        </div>
                      ) : (
                        <strong>
                          {formaterPriceFunction(
                            parseFloat(stats.no_traslados).toFixed(2) + ""
                          )}
                        </strong>
                      )}
                    </h6>
                  </div>
                  <div
                    className="col-md-4 px-2"
                    style={{
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "0.85rem",
                        color: "black",
                      }}
                    >
                      Traslados entrantes
                    </p>
                    <h6 className="mb-0 secondary_color">
                      {statsLoader ? (
                        <div className="text-center">
                          <CircularProgress size={26} className="mx-auto" />
                        </div>
                      ) : (
                        <strong>
                          {formaterPriceFunction(
                            parseFloat(stats.entrantes).toFixed(2) + ""
                          )}
                        </strong>
                      )}
                    </h6>
                  </div>
                  <div className="col-md-4 px-2">
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "0.85rem",
                        color: "black",
                      }}
                    >
                      Traslados salientes
                    </p>
                    <h6 className="mb-0 secondary_color">
                      {statsLoader ? (
                        <div className="text-center">
                          <CircularProgress size={26} className="mx-auto" />
                        </div>
                      ) : (
                        <strong>
                          {formaterPriceFunction(
                            parseFloat(stats.salientes).toFixed(2) + ""
                          )}
                        </strong>
                      )}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="mt-2 stat-card-cs mb-0">
                <div className="card-header">
                  Anulación de ventas y notas de crédito
                </div>

                <div className="row mt-2">
                  <div
                    className="col-md-4 px-2"
                    style={{
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "0.85rem",
                        color: "black",
                      }}
                    >
                      N° operaciones
                    </p>
                    <h6 className="mb-0 secondary_color">
                      {statsLoader ? (
                        <div className="text-center">
                          <CircularProgress size={26} className="mx-auto" />
                        </div>
                      ) : (
                        <strong>{numberFormater(stats.no_operaciones)}</strong>
                      )}
                    </h6>
                  </div>
                  <div
                    className="col-md-4 px-2"
                    style={{
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "0.85rem",
                        color: "black",
                      }}
                    >
                      N° de anulaciones
                    </p>
                    <h6 className="mb-0 secondary_color">
                      {statsLoader ? (
                        <div className="text-center">
                          <CircularProgress size={26} className="mx-auto" />
                        </div>
                      ) : (
                        <strong>{numberFormater(stats.no_anulaciones)}</strong>
                      )}
                    </h6>
                  </div>
                  <div className="col-md-4 px-2">
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "0.85rem",
                        color: "black",
                      }}
                    >
                      N° Notas de crédito
                    </p>
                    <h6 className="mb-0 secondary_color">
                      {statsLoader ? (
                        <div className="text-center">
                          <CircularProgress size={26} className="mx-auto" />
                        </div>
                      ) : (
                        <strong>{numberFormater(stats.no_credio)}</strong>
                      )}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="content-card card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <p>
                  <strong>Global de operaciones</strong>
                </p>

                <ButtonGroup
                  className="tab-buttons"
                  color="primary"
                  aria-label="outlined primary button group"
                  size="small"
                >
                  <Button
                    className={operationTime === "Anual" ? "active" : ""}
                    onClick={() => render_op_graph("Anual")}
                  >
                    Anual
                  </Button>
                  <Button
                    className={operationTime === "Mensual" ? "active" : ""}
                    onClick={() => render_op_graph("Mensual")}
                  >
                    Mensual
                  </Button>
                  <Button
                    className={operationTime === "Semanal" ? "active" : ""}
                    onClick={() => render_op_graph("Semanal")}
                  >
                    Semanal
                  </Button>
                  <Button
                    className={operationTime === "Diario" ? "active" : ""}
                    onClick={() => render_op_graph("Diario")}
                  >
                    Diario
                  </Button>
                </ButtonGroup>
                <ResponsiveContainer width="100%" height={323}>
                  {graphsLoader ? (
                    <div className="text-center">
                      <CircularProgress size={35} className="mx-auto my-auto" />
                    </div>
                  ) : (
                    <BarChart
                      width={500}
                      height={300}
                      data={operations_data}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      stackOffset="expand"
                    >
                      <CartesianGrid strokeDasharray="2 2" />
                      <XAxis dataKey="name" />
                      <YAxis tickFormatter={(text) => `${text * 100}%`} />
                      <Tooltip
                        formatter={(text) =>
                          moneda +
                          ". " +
                          formaterPriceFunction(parseFloat(text) + "")
                        }
                      />
                      <Legend />
                      <Bar
                        dataKey="Ventas (Facturas)"
                        stackId="a"
                        fill="#08BFE6"
                        barSize={10}
                      />
                      <Bar
                        dataKey="Anulaciones"
                        stackId="a"
                        fill="#1A2EAB"
                        barSize={10}
                      />
                      <Bar
                        dataKey="Notas de crédito"
                        stackId="a"
                        fill="#1FE3D8"
                        barSize={10}
                      />
                    </BarChart>
                  )}
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-card card mt-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <Tabs
            className="mt-2"
            value={productsTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleProductTabs}
          >
            <Tab className="tab" label="Todos" style={{ minWidth: "135px" }} />
            <Tab className="tab" label="Ventas" style={{ minWidth: "135px" }} />
            <Tab
              className="tab"
              label="Anulaciones"
              style={{ minWidth: "135px" }}
            />
            <Tab
              className="tab"
              label="Notas de crédito"
              style={{ minWidth: "135px" }}
            />
            <Tab className="tab" label="FEL" style={{ minWidth: "135px" }} />
            <Tab
              className="tab"
              label="Cuentas por cobrar"
              style={{ minWidth: "135px" }}
            />
            <Tab
              className="tab"
              label="Notas de débito"
              style={{ minWidth: "135px" }}
            />
            {ordenesTrabajo && isAleko() && (
              <Tab
                className="tab"
                label="Órdenes de trabajo"
                style={{ minWidth: "135px" }}
              />
            )}
          </Tabs>
        </div>

        <TabPanel value={productsTab} index={0}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <h4 class="mb-0">
              <strong>Historial de operaciones</strong>
            </h4>
            <div>
              <p className="mb-0">Filtrar por:</p>
              <div className="d-flex align-items-center flex-wrap">
                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sucursal}
                      onChange={changeStore}
                      className="sm-select"
                    >
                      {sucursals.map((sucursal) => (
                        <option value={sucursal.id_sucursal_bodega_ubicacion}>
                          {sucursal.nombre_sucursal}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sellerSelected}
                      onChange={changeSeller_sales}
                      className="sm-select"
                    >
                      <option value="">Todos los cajeros</option>
                      {sellers.map((seller) => (
                        <option value={seller.id_vendedor_web_app}>
                          {seller.nombre}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <DateRangePicker
                  onChange={(val) => {
                    handleDateChange(val);
                  }}
                  value={dateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° Operaciones
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>{numberFormater(allOpsInfo.operaciones)}</strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° Ventas
              </p>
              <h6 className="mb-2 secondary_color">
                <strong>{numberFormater(allOpsInfo.ventas)}</strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° Anulaciones
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>{numberFormater(allOpsInfo.anulaciones)}</strong>
              </h6>
            </div>
            <div className="col-md-2 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° Notas de Crédito
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>{numberFormater(allOpsInfo.notas_credito)}</strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° Notas de débito
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>{numberFormater(allOpsInfo.notas_debito)}</strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° Cuentas por cobrar
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>{numberFormater(allOpsInfo.cuentas_cobrar)}</strong>
              </h6>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
            {selectedAllOps.length > 0 ? (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                onClick={() => {
                  document
                    .getElementById("descargar-reporteHistorialOperaciones")
                    .click();
                }}
              >
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            ) : (
              <Button className="btn-cs1" variant="contained" color="primary">
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            )}

            <ReactHTMLTableToExcel
              id="descargar-reporteHistorialOperaciones"
              className="buton-excel"
              table="historialOperaciones-reporte-table"
              filename={
                "Operaciones desde el " +
                moment(dateRange[0]).format("DD-MM-YYYY") +
                " hasta " +
                moment(dateRange[1]).format("DD-MM-YYYY")
              }
              sheet="tablexls"
              buttonText="Descargar"
            />

            <HistorialOperacionesReporte
              objeto={selectedAllOps}
            ></HistorialOperacionesReporte>

            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por número de operación"
                  style={{ width: "300px" }}
                  value={allOperationsFilterText}
                  onChange={(event) => filterAllOperations(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {allOperationsLoader ? (
            <div className="text-center">
              <CircularProgress size={35} className="mx-auto my-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2"
              title={"s"}
              columns={[
                {
                  name: "N° Operación",
                  selector: "numero_operacion",
                  sortable: true,
                  cell: (row) => (
                    <div
                      className={
                        row.tipo_operacion.toLowerCase().includes("anulación")
                          ? "text-center w-100 red-text"
                          : "text-center w-100"
                      }
                    >
                      {row.numero_operacion}
                      <br />
                      {moment(new Date(row.fecha))
                        .utcOffset(0)
                        .format("DD/MM/YYYY")}
                    </div>
                  ),
                },
                {
                  name: "Tipo de operación",
                  selector: "tipo_operacion",
                  sortable: true,
                  cell: (row) => (
                    <div
                      className={
                        row.tipo_operacion.toLowerCase().includes("anulación")
                          ? "text-center w-100 red-text"
                          : "text-center w-100"
                      }
                    >
                      {row.tipo_operacion}
                    </div>
                  ),
                },
                {
                  name: "Fecha / Hora",
                  selector: "fecha",
                  sortable: true,
                  cell: (row) => (
                    <div
                      className={
                        row.tipo_operacion.toLowerCase().includes("anulación")
                          ? "text-center w-100 red-text"
                          : "text-center w-100"
                      }
                    >
                      {moment(new Date(row.fecha))
                        .utcOffset(0)
                        .format("DD/MM/YYYY h:mm:ss a")}
                    </div>
                  ),
                },
                {
                  name: "N° de productos",
                  selector: "numero_productos",
                  sortable: true,
                  cell: (row) => (
                    <div
                      className={
                        row.tipo_operacion.toLowerCase().includes("anulación")
                          ? "text-center w-100 red-text"
                          : "text-center w-100"
                      }
                    >
                      {row.numero_productos}
                    </div>
                  ),
                },
                {
                  name: "Monto",
                  selector: "monto",
                  sortable: true,
                  cell: (row) => (
                    <span
                      className={
                        row.tipo_operacion.toLowerCase().includes("anulación")
                          ? "red-text"
                          : ""
                      }
                    >
                      {moneda}.{" "}
                      {formaterPriceFunction(
                        Math.abs(row.monto).toFixed(2) + ""
                      )}
                    </span>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      <Button
                        className="btn-cs1"
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={(e) => allOpsAction(e, row)}
                      >
                        <span
                          style={{
                            marginBottom: "-4px",
                            fontSize: "12px",
                          }}
                        >
                          Ver{" "}
                          <span
                            className="d-none d-xl-inline"
                            style={{ fontSize: "12px" }}
                          >
                            detalles
                          </span>
                        </span>
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={filteredAllOperations}
              defaultSortField="fecha"
              defaultSortAsc={false}
              pagination
              selectableRows
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se encontraron resultados"
              onSelectedRowsChange={handleSelect}
            />
          )}
        </TabPanel>
        <TabPanel value={productsTab} index={1}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <h4 class="mb-0">
              <strong>Historial de operaciones</strong>
            </h4>
            <div>
              <p className="mb-0">Filtrar por:</p>
              <div className="d-flex align-items-center flex-wrap">
                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sucursal}
                      onChange={changeStore}
                      className="sm-select"
                    >
                      {sucursals.map((sucursal) => (
                        <option value={sucursal.id_sucursal_bodega_ubicacion}>
                          {sucursal.nombre_sucursal}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sellerSelected}
                      onChange={changeSeller_sales}
                      className="sm-select"
                    >
                      <option value="">Todos los cajeros</option>
                      {sellers.map((seller) => (
                        <option value={seller.id_vendedor_web_app}>
                          {seller.nombre}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <DateRangePicker
                  onChange={(val) => handleDateChange(val)}
                  value={dateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° de facturas
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>{numberFormater(sales.length)}</strong>
              </h6>
            </div>
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Ticket medio
              </p>
              <h6 className="mb-2 secondary_color">
                <strong>
                  {moneda}.{" "}
                  {sales.length > 0
                    ? formaterPriceFunction(
                        (
                          sales
                            .reduce((a, b) => a + b.monto_total, 0)
                            .toFixed(2) / sales.length
                        ).toFixed(2)
                      ) + ""
                    : 0}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° Ventas Por {!isCmi() ? "Domicilio" : "Entrega"}
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {moneda}.
                  {`${formaterPriceFunction(
                    domicilio.total + ""
                  )} | ${numberFormater(domicilio.ventas)}`}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° Ventas {!isCmi() ? "Consumo Local" : "por Pick-up"}
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {moneda}.
                  {`${formaterPriceFunction(
                    comerLocal.total + ""
                  )} | ${numberFormater(comerLocal.ventas)}`}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° Ventas {!isCmi() ? "Para Llevar" : "Presencial"}
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {moneda}.{" "}
                  {`${formaterPriceFunction(
                    llevar.total + ""
                  )} | ${numberFormater(llevar.ventas)}`}
                </strong>
              </h6>
            </div>
            <div className="col-md-3 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Valor monetario
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {moneda}.{" "}
                  {formaterPriceFunction(
                    sales.reduce((a, b) => a + b.monto_total, 0).toFixed(2) + ""
                  ) || 0}
                </strong>
              </h6>
            </div>
          </div>

          <div className="d-flex align-items-center mt-2 flex-wrap mb-2">
            <h4 className="mb-0">
              <strong>Historial de ventas</strong>
            </h4>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            {selectedSales.length > 0 ? (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                onClick={() => {
                  document
                    .getElementById("descargar-reporteHistorialVentas")
                    .click();
                }}
              >
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            ) : (
              <Button className="btn-cs1" variant="contained" color="primary">
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            )}

            <ReactHTMLTableToExcel
              id="descargar-reporteHistorialVentas"
              className="buton-excel"
              table="historialVentasOperaciones-reporte-table"
              filename={
                "Ventas desde el " +
                moment(dateRange[0]).format("DD-MM-YYYY") +
                " hasta " +
                moment(dateRange[1]).format("DD-MM-YYYY")
              }
              sheet="tablexls"
              buttonText="Descargar"
            />

            <HistorialVentasReporte
              objeto={selectedSales}
            ></HistorialVentasReporte>

            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por número de facturación"
                  style={{ width: "300px" }}
                  value={salesFilterText}
                  onChange={(event) => filterSales(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {salesLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2"
              title={"s"}
              columns={[
                {
                  name: "N° Factura",
                  selector: "identificador",
                  sortable: true,
                },
                {
                  name: "Fecha / Hora",
                  selector: "fecha",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {moment(new Date(row.fecha))
                        .utcOffset(0)
                        .format("DD/MM/YYYY h:mm:ss a")}
                    </div>
                  ),
                },
                {
                  name: "Nombre del cliente",
                  selector: "client.nombre",
                  sortable: true,
                },
                {
                  name: "NIT",
                  selector: "client.nit",
                  sortable: true,
                },
                {
                  name: "Código del cliente",
                  selector: "client.codigo_cliente",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {row.client.client_code || "N/A"}
                    </div>
                  ),
                },
                {
                  name: "N° productos",
                  selector: "orderItems",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {numberFormater(row.orderItems.length)}
                    </div>
                  ),
                },
                {
                  name: "Monto",
                  selector: "monto_total",
                  sortable: true,
                  cell: (row) => (
                    <span>
                      {moneda}.{" "}
                      {formaterPriceFunction(row.monto_total.toFixed(2) + "")}{" "}
                    </span>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      <Button
                        className="btn-cs1"
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={() =>
                          salesAction(
                            row.identificador,
                            row.client.nit,
                            row.id_pos_movimiento
                          )
                        }
                      >
                        <span
                          style={{
                            marginBottom: "-4px",
                            fontSize: "12px",
                          }}
                        >
                          Ver{" "}
                          <span
                            className="d-none d-xl-inline"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            detalles
                          </span>
                        </span>
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={filteredSales}
              defaultSortField="fecha"
              defaultSortAsc={false}
              pagination
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se encontraron resultados"
              selectableRows
              onSelectedRowsChange={handleSelect}
            />
          )}
        </TabPanel>
        <TabPanel value={productsTab} index={2}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <h4 class="mb-0">
              <strong>Historial de operaciones</strong>
            </h4>
            <div>
              <p className="mb-0">Filtrar por:</p>
              <div className="d-flex align-items-center flex-wrap">
                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sucursal}
                      onChange={changeStore}
                      className="sm-select"
                    >
                      {sucursals.map((sucursal) => (
                        <option value={sucursal.id_sucursal_bodega_ubicacion}>
                          {sucursal.nombre_sucursal}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sellerSelected}
                      onChange={changeSeller_sales}
                      className="sm-select"
                    >
                      <option value="">Todos los cajeros</option>
                      {sellers.map((seller) => (
                        <option value={seller.id_vendedor_web_app}>
                          {seller.nombre}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <DateRangePicker
                  onChange={(val) => handleDateChange(val)}
                  value={dateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de anulaciones
              </p>
              <h6 className="mb-0 secondary_color">
                {anulacionesLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>{numberFormater(anulaciones.length)}</strong>
                )}
              </h6>
            </div>
            <div className="col-md-3 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Valor monetario
              </p>
              <h6 className="mb-2 secondary_color">
                {anulacionesLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {moneda}.{" "}
                    {formaterPriceFunction(
                      anulaciones
                        .reduce((a, b) => a + b.monto_total, 0)
                        .toFixed(2) + ""
                    ) || 0}
                  </strong>
                )}
              </h6>
            </div>
            <div className="col-md-3 px-2" />
            <div className="col-md-3 d-flex justify-content-end align-items-center">
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                component={Link}
                to={`/app/operaciones_historial/crear_anulacion`}
              >
                Realizar anulación
              </Button>
            </div>
          </div>

          <div className="d-flex align-items-center mt-2 flex-wrap mb-2">
            <h4 className="mb-0">
              <strong>Historial de anulaciones</strong>
            </h4>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            {selectedCancellations.length > 0 ? (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                onClick={() => {
                  document
                    .getElementById("descargar-reporteAnulaciones")
                    .click();
                }}
              >
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            ) : (
              <Button className="btn-cs1" variant="contained" color="primary">
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            )}

            <ReactHTMLTableToExcel
              id="descargar-reporteAnulaciones"
              className="buton-excel"
              table="historialAnulacionOperaciones-reporte-table"
              filename={
                "Anulaciones desde el " +
                moment(dateRange[0]).format("DD-MM-YYYY") +
                " hasta " +
                moment(dateRange[1]).format("DD-MM-YYYY")
              }
              sheet="tablexls"
              buttonText="Descargar"
            />
            <HistorialAnulacionReporte
              objeto={selectedCancellations}
            ></HistorialAnulacionReporte>
            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por número de anulación"
                  style={{ width: "300px" }}
                  value={anulacionesFilterText}
                  onChange={(event) => filterAnulaciones(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <DataTable
            className="datatable-cs datatable-cs-v2"
            title={"s"}
            columns={[
              {
                name: "N° anulación",
                selector: "identificador",
                sortable: true,
              },
              {
                name: "Fecha / Hora",
                selector: "fecha",
                sortable: true,
                cell: (row) => (
                  <div className="text-center w-100">
                    {moment(new Date(row.fecha))
                      .utcOffset(0)
                      .format("DD/MM/YYYY h:mm:ss a")}
                  </div>
                ),
              },
              {
                name: "Nombre del cliente",
                selector: "client.nombre",
                sortable: true,
              },
              {
                name: "Motivo",
                selector: "motivo",
                sortable: true,
              },

              {
                name: "Monto",
                selector: "monto",
                sortable: true,
                cell: (row) => (
                  <span>
                    {moneda}.{" "}
                    {formaterPriceFunction(row.monto_total.toFixed(2) + "")}{" "}
                  </span>
                ),
              },
              {
                name: "Acciones",
                selector: "action",
                cell: (row) => (
                  <div className="text-center w-100">
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowForwardIosIcon />}
                      component={Link}
                      to={`/app/operaciones_historial/resumen_anulacion/${row.id_pos_movimiento}`}
                      size="small"
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                          fontSize: "12px",
                        }}
                      >
                        Ver{" "}
                        <span
                          className="d-none d-xl-inline"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          detalles
                        </span>
                      </span>
                    </Button>
                  </div>
                ),
              },
            ]}
            data={filteredAnulaciones}
            defaultSortField="fecha"
            defaultSortAsc={false}
            pagination
            paginationPerPage={10}
            paginationIconFirstPage={null}
            paginationIconLastPage={null}
            paginationComponentOptions={{
              rangeSeparatorText: "de",
              noRowsPerPage: true,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
            noDataComponent="No se encontraron resultados"
            selectableRows
            onSelectedRowsChange={handleSelect}
          />
        </TabPanel>
        <TabPanel value={productsTab} index={3}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <h4 class="mb-0">
              <strong>Historial de operaciones</strong>
            </h4>
            <div>
              <p className="mb-0">Filtrar por:</p>
              <div className="d-flex align-items-center flex-wrap">
                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sucursal}
                      onChange={changeStore}
                      className="sm-select"
                    >
                      {sucursals.map((sucursal) => (
                        <option value={sucursal.id_sucursal_bodega_ubicacion}>
                          {sucursal.nombre_sucursal}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sellerSelected}
                      onChange={changeSeller_sales}
                      className="sm-select"
                    >
                      <option value="">Todos los cajeros</option>
                      {sellers.map((seller) => (
                        <option value={seller.id_vendedor_web_app}>
                          {seller.nombre}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <DateRangePicker
                  onChange={(val) => handleDateChange(val)}
                  value={dateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de notas de crédito
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>{numberFormater(notasCredito.length) || "0.00"}</strong>
              </h6>
            </div>
            <div className="col-md-3 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Valor monetario
              </p>
              <h6 className="mb-2 secondary_color">
                <strong>
                  {moneda}.{" "}
                  {formaterPriceFunction(
                    notasCredito.reduce((a, b) => a + b.monto, 0).toFixed(2) +
                      ""
                  ) || 0}
                </strong>
              </h6>
            </div>
            <div className="col-md-3 px-2" />
            <div className="col-md-3 d-flex justify-content-end align-items-center">
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                component={Link}
                to={`/app/operaciones_historial/nota_credito/emitir`}
              >
                Emitir nota de crédito
              </Button>
            </div>
          </div>

          <div className="d-flex align-items-center mt-2 flex-wrap mb-2">
            <h4 className="mb-0">
              <strong>Historial de notas de crédito</strong>
            </h4>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            {selectedCreditNotes.length > 0 ? (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                onClick={() => {
                  document
                    .getElementById("descargar-reporteNotasCredito")
                    .click();
                }}
              >
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            ) : (
              <Button className="btn-cs1" variant="contained" color="primary">
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            )}

            <ReactHTMLTableToExcel
              id="descargar-reporteNotasCredito"
              className="buton-excel"
              table="historialNotaCreditoOperaciones-reporte-table"
              filename={
                "Notas de crédito desde el " +
                moment(dateRange[0]).format("DD-MM-YYYY") +
                " hasta " +
                moment(dateRange[1]).format("DD-MM-YYYY")
              }
              sheet="tablexls"
              buttonText="Descargar"
            />

            <HistorialNotasCreditoReporte
              objeto={selectedCreditNotes}
            ></HistorialNotasCreditoReporte>

            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por número de nota de crédito"
                  style={{ width: "300px" }}
                  value={notasCreditoFilterText}
                  onChange={(event) => filterNotasCredito(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {notasCreditoLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2"
              title={"s"}
              columns={[
                {
                  name: "N° Nota de crédito",
                  selector: "identificador",
                  sortable: true,
                  cell: (row) => (
                    <span className={row.fecha_anulacion ? "red-text" : ""}>
                      {row.identificador}
                    </span>
                  ),
                },
                {
                  name: "Fecha / Hora",
                  selector: "fecha",
                  sortable: true,
                  cell: (row) => (
                    <div
                      className={
                        row.fecha_anulacion
                          ? "text-center w-100 red-text"
                          : "text-center w-100"
                      }
                    >
                      {moment(new Date(row.fecha))
                        .utcOffset(0)
                        .format("DD/MM/YYYY h:mm:ss a")}
                    </div>
                  ),
                },
                {
                  name: "Nombre del cliente",
                  selector: "cliente.nombre",
                  sortable: true,
                  cell: (row) => (
                    <div className={row.fecha_anulacion ? "red-text" : ""}>
                      {row.cliente.nombre}
                    </div>
                  ),
                },
                {
                  name: "Motivo",
                  selector: "motivo",
                  sortable: true,
                  cell: (row) => (
                    <div className={row.fecha_anulacion ? "red-text" : ""}>
                      {row.motivo}
                    </div>
                  ),
                },

                {
                  name: "Monto",
                  selector: "monto",
                  sortable: true,
                  cell: (row) => (
                    <span className={row.fecha_anulacion ? "red-text" : ""}>
                      {moneda}.{" "}
                      {formaterPriceFunction(row.monto.toFixed(2)) + ""}{" "}
                    </span>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      <Button
                        className="btn-cs1"
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowForwardIosIcon />}
                        component={Link}
                        to={`/app/operaciones_historial/nota_credito/${row.id_nota_credito_web_app}`}
                        size="small"
                      >
                        <span
                          style={{
                            marginBottom: "-4px",
                            fontSize: "12px",
                          }}
                        >
                          Ver{" "}
                          <span
                            className="d-none d-xl-inline"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            detalles
                          </span>
                        </span>
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={filteredNotasCredito}
              pagination
              paginationPerPage={10}
              defaultSortField="fecha"
              defaultSortAsc={false}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se encontraron resultados"
              selectableRows
              onSelectedRowsChange={handleSelect}
            />
          )}
        </TabPanel>
        <TabPanel value={productsTab} index={4}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <h4 class="mb-0">
              <strong>FEL</strong>
            </h4>
            <div>
              <p className="mb-0">Filtrar por:</p>
              <div className="d-flex align-items-center flex-wrap">
                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sucursal}
                      onChange={changeStore}
                      className="sm-select"
                    >
                      {sucursals.map((sucursal) => (
                        <option value={sucursal.id_sucursal_bodega_ubicacion}>
                          {sucursal.nombre_sucursal}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sellerSelected}
                      onChange={changeSeller_sales}
                      className="sm-select"
                    >
                      <option value="">Todos los cajeros</option>
                      {sellers.map((seller) => (
                        <option value={seller.id_vendedor_web_app}>
                          {seller.nombre}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <DateRangePicker
                  onChange={(val) => handleDateChange(val)}
                  value={dateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
                <FormControl variant="outlined" className="px-1">
                  <Select
                    native
                    value={tipo_fel}
                    onChange={(e) => changeFelType(e)}
                    className="sm-select"
                  >
                    <option value="0">Facturas</option>
                    <option value="1">Notas de Crédito</option>
                    <option value="2">Notas de débito</option>
                    <option value="3">Facturas a Crédito</option>

                    <option value="4">Anulaciones</option>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            {selectedFel.length > 0 ? (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                onClick={() => {
                  document
                    .getElementById("descargar-reporteHistorialFel")
                    .click();
                }}
              >
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            ) : (
              <Button className="btn-cs1" variant="contained" color="primary">
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            )}

            <ReactHTMLTableToExcel
              id="descargar-reporteHistorialFel"
              className="buton-excel"
              table="historialFEL-reporte-table"
              filename={
                "Reporte fel desde el " +
                moment(dateRange[0]).format("DD-MM-YYYY") +
                " hasta " +
                moment(dateRange[1]).format("DD-MM-YYYY")
              }
              sheet="tablexls"
              buttonText="Descargar"
            />

            <HistorialFelReporte objeto={selectedFel}></HistorialFelReporte>

            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por número de operación"
                  style={{ width: "300px" }}
                  value={FELFilterText}
                  onChange={(event) => filterFEL(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {felHistoryLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2"
              title={"s"}
              columns={[
                {
                  name: "Tipo de operación",
                  selector: "tipo_operacion",
                  sortable: true,
                },
                {
                  name: "Identificador",
                  selector: "identificador",
                  sortable: true,
                },
                {
                  name: "Fecha/Hora",
                  selector: "fecha",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {moment(new Date(row.fecha))
                        .utcOffset(0)
                        .format("DD/MM/YYYY h:mm:ss a")}
                    </div>
                  ),
                },
                {
                  name: "Nombre",
                  selector: "nombre",
                  sortable: true,
                },
                {
                  name: "NIT",
                  selector: "nit",
                  sortable: true,
                },
                {
                  name: "Total",
                  selector: "total",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {formaterPriceFunction(row.total.toFixed(2) + "")}
                    </div>
                  ),
                },
                {
                  name: "Serie-FEL",
                  selector: "serie_fel",
                  sortable: true,
                },
                {
                  name: "Firma",
                  selector: "firma_fel",
                  sortable: true,
                  cell: (row) => (
                    <div
                      className="text-center w-100"
                      style={{ fontSize: "10.5px" }}
                    >
                      {row.firma_fel}
                    </div>
                  ),
                },
              ]}
              data={filteredFelHistory}
              pagination
              paginationPerPage={10}
              defaultSortField="fecha"
              defaultSortAsc={false}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              selectableRows
              onSelectedRowsChange={handleSelect}
              noDataComponent="No se encontraron resultados"
            />
          )}
        </TabPanel>
        <TabPanel value={productsTab} index={5}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <h4 class="mb-0">
              <strong>Historial de operaciones</strong>
            </h4>
            <div>
              <p className="mb-0">Filtrar por:</p>
              <div className="d-flex align-items-center flex-wrap">
                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sucursal}
                      onChange={changeStore}
                      className="sm-select"
                    >
                      {sucursals.map((sucursal) => (
                        <option value={sucursal.id_sucursal_bodega_ubicacion}>
                          {sucursal.nombre_sucursal}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sellerSelected}
                      onChange={changeSeller_sales}
                      className="sm-select"
                    >
                      <option value="">Todos los cajeros</option>
                      {sellers.map((seller) => (
                        <option value={seller.id_vendedor_web_app}>
                          {seller.nombre}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <DateRangePicker
                  onChange={(val) => handleDateChange(val)}
                  value={dateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° de facturas
              </p>
              {cuentasPorCobrarLoader ? (
                <div className="text-center">
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : (
                <h6 className="mb-0 secondary_color">
                  <strong>{cuentasPorCobrar.length || 0}</strong>
                </h6>
              )}
            </div>
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Ticket medio
              </p>
              {cuentasPorCobrarLoader ? (
                <div className="text-center">
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : (
                <h6 className="mb-2 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {cuentasPorCobrar.length > 0
                      ? formaterPriceFunction(
                          (
                            cuentasPorCobrar.reduce((a, b) => a + b.monto, 0) /
                            cuentasPorCobrar.length
                          ).toFixed(2) + ""
                        )
                      : "0"}
                  </strong>
                </h6>
              )}
            </div>
            <div className="col-md-3 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Valor monetario
              </p>
              {cuentasPorCobrarLoader ? (
                <div className="text-center">
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : (
                <h6 className="mb-2 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {formaterPriceFunction(
                      cuentasPorCobrar
                        .reduce((a, b) => a + b.monto, 0)
                        .toFixed(2) + ""
                    ) || 0}
                  </strong>
                </h6>
              )}
            </div>
            <div className="col-md-3 d-flex justify-content-end align-items-center" />
          </div>

          <div className="d-flex align-items-center mt-2 flex-wrap mb-2">
            <h4 className="mb-0">
              <strong>Cuentas por cobrar</strong>
            </h4>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            {selectedCreditInvoices.length > 0 ? (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                onClick={() => {
                  document.getElementById("descargar-reportePorCobrar").click();
                }}
              >
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            ) : (
              <Button className="btn-cs1" variant="contained" color="primary">
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            )}

            <ReactHTMLTableToExcel
              id="descargar-reportePorCobrar"
              className="buton-excel"
              table="historialPorCobrarOperaciones-reporte-table"
              filename={
                "Cuentas por cobrar desde el " +
                moment(dateRange[0]).format("DD-MM-YYYY") +
                " hasta " +
                moment(dateRange[1]).format("DD-MM-YYYY")
              }
              sheet="tablexls"
              buttonText="Descargar"
            />
            <HistorialPorCobrarReporte
              objeto={selectedCreditInvoices}
            ></HistorialPorCobrarReporte>
            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por número de facturación"
                  style={{ width: "300px" }}
                  value={cuentasPorCobrarFilterText}
                  onChange={(event) => filterCuentasPorCobrar(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {cuentasPorCobrarLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2"
              title={"s"}
              columns={[
                {
                  name: "N° Factura",
                  selector: "identificador",
                  sortable: true,
                },
                {
                  name: "Fecha / Hora",
                  selector: "fecha",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {moment(new Date(row.fecha))
                        .utcOffset(0)
                        .format("DD/MM/YYYY h:mm:ss a")}
                    </div>
                  ),
                },
                {
                  name: "Nombre del cliente",
                  selector: "nombre",
                  sortable: true,
                },
                { name: "NIT", selector: "nit", sortable: true },
                {
                  name: "Vencimiento",
                  selector: "fecha_vencimiento",
                  sortable: true,
                  cell: (row) => (
                    <div className="w-100 text-center">
                      {row.expiration_status === "warning" ? (
                        <span className="p-1 warning-span">
                          {moment(row.fecha_vencimiento)
                            .utcOffset(0)
                            .format("DD/MM/YYYY, h:mm:ss a")}
                        </span>
                      ) : (
                        ""
                      )}
                      {row.expiration_status === "danger" ? (
                        <span className="p-1 danger-span">
                          {moment(row.fecha_vencimiento)
                            .utcOffset(0)
                            .format("DD/MM/YYYY, h:mm:ss a")}
                        </span>
                      ) : (
                        ""
                      )}
                      {row.expiration_status === "success" ? (
                        <span className="p-1 success-span">
                          {moment(row.fecha_vencimiento)
                            .utcOffset(0)
                            .format("DD/MM/YYYY, h:mm:ss a")}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ),
                },

                {
                  name: "Monto",
                  selector: "monto",
                  sortable: true,
                  cell: (row) => (
                    <span>
                      {moneda}.{" "}
                      {formaterPriceFunction(row.monto.toFixed(2) + "")}{" "}
                    </span>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      <Button
                        className="btn-cs1"
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={() => openCuentaPorCobrarInvoice(row)}
                        size="small"
                      >
                        <span
                          style={{
                            marginBottom: "-4px",
                            fontSize: "12px",
                          }}
                        >
                          Ver{" "}
                          <span
                            className="d-none d-xl-inline"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            detalles
                          </span>
                        </span>
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={filteredCuentasPorCobrar}
              selectableRows
              pagination
              paginationPerPage={10}
              defaultSortField="fecha"
              defaultSortAsc={false}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se encontraron resultados"
              onSelectedRowsChange={handleSelect}
            />
          )}
        </TabPanel>
        <TabPanel value={productsTab} index={6}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <h4 class="mb-0">
              <strong>Historial de operaciones</strong>
            </h4>
            <div>
              <p className="mb-0">Filtrar por:</p>
              <div className="d-flex align-items-center flex-wrap">
                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sucursal}
                      onChange={changeStore}
                      className="sm-select"
                    >
                      {sucursals.map((sucursal) => (
                        <option value={sucursal.id_sucursal_bodega_ubicacion}>
                          {sucursal.nombre_sucursal}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!cajeroDisable && (
                  <FormControl variant="outlined" className="px-1">
                    <Select
                      native
                      value={sellerSelected}
                      onChange={changeSeller_sales}
                      className="sm-select"
                    >
                      <option value="">Todos los cajeros</option>
                      {sellers.map((seller) => (
                        <option value={seller.id_vendedor_web_app}>
                          {seller.nombre}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <DateRangePicker
                  onChange={(val) => handleDateChange(val)}
                  value={dateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                N° notas de débito
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>{notasDebito.length}</strong>
              </h6>
            </div>
            <div className="col-md-3 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Valor monetario
              </p>
              <h6 className="mb-2 secondary_color">
                <strong>
                  {moneda}.{" "}
                  {formaterPriceFunction(
                    notasDebito.reduce((a, b) => a + b.monto, 0).toFixed(2) + ""
                  )}
                </strong>
              </h6>
            </div>
            <div className="col-md-3 px-2" />
            <div className="col-md-3 d-flex justify-content-end align-items-center">
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                component={Link}
                to={`/app/operaciones_historial/nota_debito/crear`}
              >
                Realizar nota de débito
              </Button>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
            <h4 className="mb-0">
              <strong>Historial de notas de débito</strong>
            </h4>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            {selectedDebitNotes.length > 0 ? (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                onClick={() => {
                  document.getElementById("descargar-reporteDebito").click();
                }}
              >
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            ) : (
              <Button className="btn-cs1" variant="contained" color="primary">
                Descargar seleccionados
                <CloudDownloadIcon className="ml-2" />
              </Button>
            )}

            <ReactHTMLTableToExcel
              id="descargar-reporteDebito"
              className="buton-excel"
              table="historialDebitoOperaciones-reporte-table"
              filename={
                "Notas de débito desde el " +
                moment(dateRange[0]).format("DD-MM-YYYY") +
                " hasta " +
                moment(dateRange[1]).format("DD-MM-YYYY")
              }
              sheet="tablexls"
              buttonText="Descargar"
            />

            <HistorialNotaDebitoReporte
              objeto={selectedDebitNotes}
            ></HistorialNotaDebitoReporte>

            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por número de nota de débito"
                  style={{ width: "300px" }}
                  value={notasDebitoFilterText}
                  onChange={(event) => filterNotasDebito(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {notasDebitoLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2"
              title={"s"}
              columns={[
                {
                  name: "N° Nota de débito",
                  selector: "identificador",
                  sortable: true,
                  cell: (row) => (
                    <span className={row.fecha_anulacion ? "red-text" : ""}>
                      {row.identificador}
                    </span>
                  ),
                },
                {
                  name: "Fecha / Hora",
                  selector: "fecha",
                  sortable: true,
                  cell: (row) => (
                    <div
                      className={
                        row.fecha_anulacion
                          ? "red-text text-center w-100"
                          : "text-center w-100"
                      }
                    >
                      {moment(row.fecha)
                        .utcOffset(0)
                        .format("DD/MM/YYYY h:mm:ss a")}
                    </div>
                  ),
                },
                {
                  name: "Nombre del cliente",
                  selector: "cliente.nombre",
                  sortable: true,
                  cell: (row) => (
                    <span className={row.fecha_anulacion ? "red-text" : ""}>
                      {row.cliente.nombre}
                    </span>
                  ),
                },
                {
                  name: "Motivo",
                  selector: "motivo",
                  sortable: true,
                },

                {
                  name: "Monto ajuste",
                  selector: "monto",
                  sortable: true,
                  cell: (row) => (
                    <span className={row.fecha_anulacion ? "red-text" : ""}>
                      {moneda}.{" "}
                      {formaterPriceFunction(row.monto.toFixed(2) + "")}{" "}
                    </span>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      <Button
                        className="btn-cs1"
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowForwardIosIcon />}
                        component={Link}
                        to={`/app/operaciones_historial/nota_debito/${row.id_nota_debito_web_app}`}
                        size="small"
                      >
                        <span
                          style={{
                            marginBottom: "-4px",
                            fontSize: "12px",
                          }}
                        >
                          Ver{" "}
                          <span
                            className="d-none d-xl-inline"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            detalles
                          </span>
                        </span>
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={filteredNotasDebito}
              defaultSortField="fecha"
              defaultSortAsc={false}
              pagination
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se encontraron resultados"
              selectableRows
              onSelectedRowsChange={handleSelect}
            />
          )}
        </TabPanel>
        {ordenesTrabajo && (
          <TabPanel value={productsTab} index={7}>
            <WorkOrders
              orders={orders}
              ordersResumen={ordersResumen}
              // ordenes={
              //   orders
              //   // .filter((x) => x.id_factura)
              //   // .map((x) => ({ ...x, id_factura: x.id_factura.toString() }))
              // }
              sucursals={sucursals}
              sellers={sellers}
            />
          </TabPanel>
        )}
      </div>

      <Menu
        open={Boolean(productsMenu)}
        anchorEl={productsMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setProductsMenu(null)}
      >
        <MenuItem className="menuItem-border">Añadir individualmente</MenuItem>
        <MenuItem>Carga masiva de productos</MenuItem>
      </Menu>
    </div>
  );
}
