import { admin_base_url, axios } from "../../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("AdminToken") };
};

const getGlobalStats = async (data) => {
  return axios.post(`${admin_base_url}/web/global-stats`, qs.stringify(data), {
    headers: headers(),
  });
};

const getCompanyStats = async (data) => {
  return axios.post(
    `${admin_base_url}/web/companies-stats`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getEmpresasData = async () => {
  return axios.get(`${admin_base_url}/web/get-simplified-empresas`, {
    headers: headers(),
  });
};

export default {
  getGlobalStats,
  getEmpresasData,
  getCompanyStats,
};
