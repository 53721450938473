import { useEffect, useState } from "react";
import { getAlekoOrder, getAlekoPedido } from "../../../../../api/sales";
import API from "../../../../../api";

export const useOrder = (id, invoiceID) => {
  const [currentOrder, setCurrentOrder] = useState(null);
  const [invoice, setInvoice] = useState(null);
  useEffect(() => {
    getOrder();
    getInvoice();
  }, []);

  const getOrder = async () => {
    try {
      const id_bodega_sucursal = JSON.parse(
        localStorage.getItem("store")
      )?.id_sucursal_bodega_ubicacion;

      const { data } = await getAlekoOrder({
        id_pedido_item: id,
        id_bodega_sucursal: id_bodega_sucursal,
      });

      const parsedData = await data.data.data.map((item) =>
        !item.id_item ? { ...item, id_item: item.id_pedido_item } : item
      );

      const result = await parsedData.reduce((r, a) => {
        r[a.id_item] = r[a.id_item] || [];
        r[a.id_item].push(a);
        return r;
      }, Object.create(null));

      setCurrentOrder({
        ...data,
        data: result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoice = async () => {
    const response = await getAlekoPedido(id);
    const { data } = await API.sales.getSingleSale({
      id_pos_movimiento: invoiceID,
    });
    setInvoice(data.data);
  };

  return { currentOrder, invoice };
};
