import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FormControl, Select } from "@material-ui/core";
import { useSap } from "../CustomHooks/useSap";
const TopBar = (props) => {
  const { sucursals, sucursal, changeSucursal, cajeroDisable } = props;
  return (
    <div className="section-title mb-4">
      <HomeIcon className="d-none d-sm-block" />
      <ArrowForwardIosIcon
        style={{ color: "#333333", fontSize: "12px" }}
        className="mx-2 d-none d-sm-block"
      />
      <h4 className="mb-0">
        <strong>Descargas a SAP</strong>
      </h4>
      <ArrowForwardIosIcon
        style={{ color: "#333333", fontSize: "12px" }}
        className="mx-2"
      />
      <FormControl variant="outlined">
        <Select
          native
          value={sucursal}
          onChange={changeSucursal}
          disabled={cajeroDisable}
        >
          {sucursals.map((sucursal) => (
            <option value={sucursal.id_sucursal_bodega_ubicacion}>
              {sucursal.nombre_sucursal}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TopBar;
