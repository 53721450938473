import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import useInfinityScroll from "./utils/useInfinity";
import { calcularPorcentageBarLine } from "../../../../helpers/utilsFunctions";
import "./custom.css";
import { CircularProgress } from "@material-ui/core";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#33eaff",
  },
}))(LinearProgress);

const Categories = ({ summary, loader }) => {
  // const [page, setPage] = useState(0);
  const [categories, setCategories] = useState([]);

  // let { categories } = useInfinityScroll(page);
  // categories = categories.sort((a, b) => b.amount - a.amount);

  // const loader = useRef();

  // const handleObserver = useCallback((entries) => {
  //   const target = entries[0];
  //   if (target.isIntersecting) {
  //     setPage((prev) => prev + 10);
  //   }
  // }, []);

  // useEffect(() => {
  //   const option = {
  //     root: null,
  //     rootMargin: "20px",
  //     threshold: 0,
  //   };
  //   const observer = new IntersectionObserver(handleObserver, option);

  //   if (loader.current) observer.observe(loader.current);
  // }, [handleObserver]);

  // const porcentage = (num) => {
  //   return Math.round((num * 100) / categories[0].amount);
  // };

  useEffect(() => {
    if (summary) {
      setCategories(summary);
    }
  }, [summary]);

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-md-12 shadow-sm py-3">
          <h5 className="p-0 m-0">
            <strong>Categorías más vendidas</strong>
          </h5>
        </div>
        <div
          className="col-md-12 scroll"
          style={{ height: "calc(100vh - 130px - 55.31px)", overflow: "auto" }}
        >
          {loader ? (
            <div className="mx-auto text-center">
              <CircularProgress size={20} />
            </div>
          ) : categories.length === 0 ? (
            <p>Sin datos para mostrar</p>
          ) : (
            categories.map((category, index) => (
              <>
                <div className="row p-0 m-0">
                  <div className="col-md-1 border-right d-flex align-items-center justify-content-center">
                    {index + 1}
                  </div>
                  <div className="col-md-10 py-2">
                    <span>
                      <strong>{category.categoria}</strong>
                    </span>{" "}
                    <br />
                    <BorderLinearProgress
                      variant="determinate"
                      value={calcularPorcentageBarLine(category.porcentaje)}
                    />
                    <span>Q. {category.Total.toFixed(2)}</span>
                  </div>
                </div>
                <div
                  style={{
                    display: categories.length ? "block" : "none",
                    padding: "2px",
                  }}
                />
              </>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Categories;
