import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProductPersonalizationContext from "../../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { ContainerAddProduct } from "../AddProductClient/containerAddProductClient";
import Color from "../Color";
import AreasContainer from "../customization.areas";
import CustomizationPerspectives from "../customization.perspectives";
import {
  BigTitle,
  CancelButton,
  FlexContainer,
  GeneralContainer,
  Input,
  LeftScreen,
  RightScreen,
  VariantContainer,
} from "../styled/styled.components";
import Tallas from "../Tallas";
import SwitchButton from "../AddProductClient/SwitchButton";
import { useSizes } from "../utils/useSizes";
import { InputAdornment, TextField } from "@material-ui/core";
import CancelModal from "../CancelModal";

const Variant = ({
  variant,
  variants,
  currentTab,
  setCurrentTab,
  setVariants,
  index,
}) => {
  const history = useHistory();

  const {
    currentProductTab,
    deleteCustomizeProduct,
    deleteSavedVariant,
    check,
    setCheck,
    formularios,
    validation,
    handleForms,
    addProductWithDiscount,
    handleDisableDiscount,
    enable_customization,
    handleEnableCustomization,
  } = useContext(ProductPersonalizationContext);

  const [colors, setColors] = useState(variant.custom.variant);
  const [currentPerspectiveTab, setCurrentPerspectiveTab] = useState(0);

  const [points, setPoints] = useState([]);
  const [currentSize, setCurrentSize] = useState("");
  const [size] = useSizes(currentProductTab.id_new_item, currentSize);
  const currentForm = formularios.find(
    (form) => form.variantId === variant.variantId
  );

  useEffect(() => {
    setColors(variant.custom.variant);
  }, [variant]);

  const deleteVariant = (id) => {
    if (variants.length === 1) return;
    setVariants(variants.filter((item) => item.variantId !== id));
    deleteSavedVariant(variant.variantId);
    setCheck(check.filter((item) => item.id !== variant.variantId));
  };

  useEffect(() => {
    if (check.some((item) => item.id === variant.variantId)) {
      setCheck(
        check.map((item) =>
          item.id === variant.variantId
            ? {
                area: points.length,
                nombre: variant.nombre,
                id: variant.variantId,
                number: index + 1,
                id_new_item: currentProductTab.id_new_item,
              }
            : item
        )
      );
    } else {
      setCheck([
        ...check,
        {
          area: points.length,
          nombre: variant.nombre,
          id: variant.variantId,
          number: index + 1,
          id_new_item: currentProductTab.id_new_item,
        },
      ]);
    }
  }, [points]);

  const changeIncludeProduct = () => {
    if (!currentForm.enable_customization) {
      return;
    }
    if (currentForm.addProductWithDiscount) {
      variant.descuento_producto = 0;
      variant.addProductWithDiscount = 0;
      handleForms({
        variantId: currentForm.variantId,
        descuento_producto: 0,
      });
    } else {
      variant.addProductWithDiscount = 1;
    }
    handleForms({
      variantId: currentForm.variantId,
      addProductWithDiscount: !currentForm.addProductWithDiscount,
    });
  };

  const changeIncludePersonalization = () => {
    if (!currentForm.addProductWithDiscount) {
      return;
    }
    if (currentForm.addProductWithDiscount) {
      variant.descuento_producto = 0;
      handleForms({
        variantId: currentForm.variantId,
        descuento_producto: 0,
      });
    }
    handleForms({
      variantId: currentForm.variantId,
      enable_customization: !currentForm.enable_customization,
    });
  };
  const deleteProduct = () => {
    deleteCustomizeProduct(
      variant.id_new_item,
      history,
      setCurrentTab,
      currentTab
    );
    setCheck(
      check.filter((item) => item.id_new_item !== currentProductTab.id_new_item)
    );
  };

  return (
    <>
      <LeftScreen
        gridTemplateColumns="repeat(4,1fr)"
        alignContent="baseline"
        gridGap="20px"
        gridColumn="1/5"
      >
        <CancelModal
          deleteProduct={deleteProduct}
          cancelText="¿Seguro que deseas eliminar el producto?"
          text="Eliminar Producto"
        />

        <CustomizationPerspectives
          currentPerspectiveTab={currentPerspectiveTab}
          setCurrentPerspectiveTab={setCurrentPerspectiveTab}
          points={points}
          setPoints={setPoints}
          perspectives={variant?.custom?.perspectives}
          contentType={variant?.custom?.contentType}
          customType={variant?.custom?.customType}
          index={index}
          variantId={variant.variantId}
          variant={variant}
        />
      </LeftScreen>

      <RightScreen
        alignContent="baseline"
        gridGap="20px"
        padding="10px 0px"
        gridColumn="5/-1"
      >
        <FlexContainer gridColumn="1/-1">
          <div className="my-4">
            <BigTitle fontSize="24px" fontWeight="700" margin="auto">
              {variant.title}
            </BigTitle>
          </div>
        </FlexContainer>
        <VariantContainer
          alignItems="center"
          gridTemplateColumns="repeat(4,1fr)"
          gridColumn="1/-1"
        >
          <FlexContainer gridColumn="1/2" flexDirection="column">
            <BigTitle fontSize="16px" fontWeight="700">
              Variante {index + 1}
            </BigTitle>
            {variants.length > 1 && (
              <CancelButton
                margin="10px auto"
                whiteSpace="nowrap"
                onClick={() => deleteVariant(variant.variantId)}
              >
                Eliminar
              </CancelButton>
            )}
          </FlexContainer>
          {/* <FlexContainer gridColumn="2/-1"> */}
          {/* {colors.map((item, i) => {
              return (
                <Color
                  key={i}
                  color={item}
                  setColors={setColors}
                  colors={colors}
                  variant={variant}
                />
              );
            })}
            {validation && currentForm?.valor_color === "" && (
              <>
                <span className="text-danger w-100">Requerido</span>
              </>
            )} */}
          {/* </FlexContainer> */}
        </VariantContainer>

        {/* -------------------------------------------------------------- */}

        {/* <div className="col-md-12 border border-warning p-6">
          Moises
        </div> */}

        <GeneralContainer
          gridGap="0"
          alignItems="baseline"
          gridColumn="1/-1"
          gridTemplateColumns="repeat(5,1fr)"
          gridTemplateAreas='"a c c z x" "a f f z x"'
        >
          <BigTitle gridArea="a" margin="0" fontSize="16px" fontWeight="700">
            General
          </BigTitle>
          {/* Talla Flex*/}
          {variant?.custom?.general.map((item, i) => {
            return (
              <>
                {/* <BigTitle
                  gridArea="b"
                  margin="0"
                  fontSize="14px"
                  fontWeight="700"
                >
                  {item.title}
                </BigTitle> */}
                <Tallas
                  item={item}
                  variant={variant}
                  variants={variants}
                  index={index}
                  currentTab={currentTab}
                  setCurrentSize={setCurrentSize}
                />
              </>
            );
          })}
          <FlexContainer gridArea="z" flexDirection="col" flexWrap="wrap">
            <BigTitle margin="0" fontSize="14px" fontWeight="700">
              ¿Incluir producto?
            </BigTitle>
            <div className="ml-2">
              <SwitchButton
                // disabled={points.length}
                checked={currentForm?.addProductWithDiscount || false}
                handleClick={changeIncludeProduct}
              />
            </div>
          </FlexContainer>
          <FlexContainer
            flexDirection="row"
            //border="#ec5454 solid 1px"

            gridArea="x"
            position="static"
          >
            <BigTitle margin="0" fontSize="14px" fontWeight="700">
              Descuento
            </BigTitle>
            <TextField
              type="number"
              placeholder="ingrese descuento"
              disabled={!currentForm?.addProductWithDiscount || false}
              value={currentForm ? currentForm.descuento_producto || "" : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              onChange={(e) => {
                let value = e.target.value;
                value = value == "" ? 0 : value;
                variant.descuento_producto = parseFloat(value);
                handleForms({
                  variantId: variant.variantId,
                  descuento_producto: parseFloat(value),
                });
              }}
            />
          </FlexContainer>
          {/* <FlexContainer>
                  <strong>Cantidad disponible: </strong>
                  <br />
                  {size?.cantidad_talla}
                </FlexContainer> */}
        </GeneralContainer>

        <GeneralContainer
          gridGap="0"
          alignItems="baseline"
          gridColumn="1/-1"
          gridTemplateColumns="repeat(2,1fr)"
          gridTemplateAreas='"a b c" "a e f"'
        >
          <FlexContainer
            flexDirection="row"
            //flexWrap="wrap"
          >
            <BigTitle gridArea="b" margin="0" fontSize="14px" fontWeight="700">
              Incluir Personalización
            </BigTitle>
            <div className="ml-2">
              <SwitchButton
                disabled={points?.length}
                checked={currentForm?.enable_customization || false}
                handleClick={changeIncludePersonalization}
              />
            </div>
          </FlexContainer>
        </GeneralContainer>

        {/* -------------------------------------------------------------- */}

        <AreasContainer
          points={points}
          setPoints={setPoints}
          contentType={variant?.custom?.contentType}
          currentPerspective={
            variant?.custom?.perspectives[currentPerspectiveTab]
          }
          customType={variant?.custom?.customType}
          variant={variant}
        />
      </RightScreen>
    </>
  );
};

export default Variant;
