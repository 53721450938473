import React, { useContext, useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import CmiProductContext from "../../../context/CmiProductContext/CmiProductContext";
import { Tooltip } from "@material-ui/core";

const NuevoNivel = (props) => {
  const [listSubniveles, setListSubniveles] = useState([]);
  const { deleteCurrentLevel } = useContext(CmiProductContext);

  const {
    newNivel,
    setNewNivel,
    handleNewNivel,
    setCreateNivel,
    savedNiveles,
    setFirstClick,
    id_padre,
  } = props;

  const [price, setPrice] = useState("");
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (savedNiveles.length > 0) {
      // array de subniveles
      let arraySubniveles = [];

      // extraemos los subniveles
      savedNiveles.map((item) => {
        let { subniveles } = item;

        subniveles.map((sub) => {
          let newSubniveles = {
            ...sub,
            nombre: sub.subnivel,
            desde: sub.cantidad_desde,
            hasta: sub.cantidad_hasta,
            id_tipo_cliente: sub.id_tipo_cliente,
          };
          arraySubniveles.push(newSubniveles);
        });
      });

      setListSubniveles(arraySubniveles);
      console.log(arraySubniveles);
    }
  }, [savedNiveles]);

  return (
    <div className="col-md-12 d-flex justify-content-center align-items-center">
      <div className="col-md-4 d-flex justify-content-center align-items-center">
        <div className="col-md-2 btn_eliminar_nivel_precio">
          <IconButton color="secondary">
            <DeleteIcon
              style={{
                color: "white",
              }}
              onClick={(e) => {
                setCreateNivel(false);
                setFirstClick(true);
              }}
            />
          </IconButton>
        </div>
        <div className="col-md-10">
          <div className="row col-md-12 h-50 justify-content-between">
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Tipo de usuario</strong>
            </label>
            <Tooltip title="Seleccione el tipo de usuario">
              <InfoIcon
                style={{
                  color: " #828282",
                  fontSize: "1.2rem",
                }}
              />
            </Tooltip>
          </div>
          <div className="col-md-12 h-50 row justify-content-center align-items-center">
            <FormControl variant="outlined" className="w-100">
              <Select
                onChange={handleNewNivel}
                native
                name="user_tipe"
                required
                className="cs-select1 select-w100"
              >
                <option value="seleccionar">Seleccionar</option>
                {listSubniveles &&
                  listSubniveles.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.nombre} | {item.desde} - {item.hasta}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="col-md-4 d-flex justify-content-center align-items-center">
        <div className="col-md-12">
          <div className="row col-md-12 h-50 justify-content-between">
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Unidad de venta</strong>
            </label>
            <Tooltip title="Seleccione la unidad de venta">
              <InfoIcon
                style={{
                  color: " #828282",
                  fontSize: "1.2rem",
                }}
              />
            </Tooltip>
          </div>
          <div className="col-md-12 h-50 row justify-content-center align-items-center">
            <FormControl variant="outlined" className="w-100">
              <Select
                onChange={handleNewNivel}
                native
                name="unid_venta"
                required
                className="cs-select1 select-w100"
              >
                <option value="none">Seleccione</option>
                <option value="libras">Libras</option>
                {!id_padre && <option value="unidades">Unidades</option>}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Precio de venta</strong>
        </label>
        <TextField
          onChange={handleNewNivel}
          type="text"
          required
          className="w-100"
          name="precio_venta"
          placeholder="Ingrese cantidad"
          value={price}
          onChange={(event) => {
            const t = event.target.value;
            event.target.value =
              t.indexOf(".") >= 0
                ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
                : t;
            setPrice(formaterPriceFunction(event.target.value));
            handleNewNivel(event);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Q.</InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default NuevoNivel;
