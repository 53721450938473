import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import AlekoContext from "../../../context/ContextoAleko/AlekoContext";
import DeleteModal from "../Components/DeleteModal";

const ProductPersonalization = ({ item }) => {
  const { deleteProductPersonalization } = useContext(AlekoContext);

  console.log("parametrizacion ->", item);

  return (
    <>
      <div className="col-md-3 d-flex align-items-center flex-wrap my-2">
        <label style={{ fontSize: "0.8rem" }} className="w-100">
          <strong>Personalización</strong>
        </label>
        <span className="w-100 border-bottom">
          {" "}
          {item.nombre_personalizacion}
        </span>
      </div>
      <div className="col-md-3 d-flex align-items-center flex-wrap my-2">
        <label style={{ fontSize: "0.8rem" }} className="w-100 ">
          <strong>Precio/cm2</strong>
        </label>
        <span className="w-100 border-bottom">{item.precio_medida}</span>
      </div>
      <div className="col-md-3 d-flex align-items-center flex-wrap my-2">
        <label style={{ fontSize: "0.8rem" }} className="w-100">
          <strong>Precio mínimo (A cobrar)</strong>
        </label>
        <span className="w-100 border-bottom">
          Q.
          {" " + item.precio_minimo}
        </span>
      </div>
      <div className="col-md-2 d-flex align-items-center flex-wrap my-2">
        {/* <Button
          className="btn-cs1 delete-btn w-100 text-light"
          variant="contained"
          onClick={() =>
            deleteProductPersonalization(
              item.id_new_empresa,
              item.id_personalizacion_producto
            )
          }
        >
          Eliminar
        </Button> */}
        <DeleteModal
          deleteProduct={() =>
            deleteProductPersonalization(
              item.id_new_empresa,
              item.id_personalizacion_producto
            )
          }
          cancelText="¿Seguro que deseas eliminar la personalización?"
          text="Eliminar"
        />
      </div>
    </>
  );
};

export default ProductPersonalization;
