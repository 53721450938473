import React, { useState, useEffect, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CSVLink } from "react-csv";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import $ from "jquery";
import { isCmi } from "../../helpers/isCmi";
import { getMoneda } from "../../helpers/moneda";

// styles
import "./custom.css";

//herramienta reporte
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//reportes
import RCHistorialReporte from "../../reportes/rCHistorialReporte";
import RCAnulacionesReporte from "../../reportes/rCAnulacionesReporte";
import RCCreditoReporte from "../../reportes/rCCreditoReporte";
import RCAnticiposReporte from "../../reportes/rCAnticiposReporte";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import moment from "moment";
import "moment/locale/es";
import CorteModal from "./CorteModal";
import ModalAnulacion from "../historial/ModalAnulacion";
import { getUsuariosGerentes } from "../../api/profile";
import ModalDiaAnterior from "../historial/ModalAnulacion/ModalDiaAnterior";
import CamposErroneosModal from "../historial/ModalAnulacion/ModalErrorAnulacion";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";
import RetiroAutorizadoModal from "./RetiroAutorizadoModal";
import { getRetiroCaja } from "../../api/sales";
import CamposErroneosModalCorte from "../../components/ModalCorteAutorizacion/ModalErrorAnulacion";
moment.locale("es");

// components

export default function Users_all(props) {
  const moneda = getMoneda();
  let history = useHistory();

  //cajero disable
  const [cajeroDisable, setCajeroDisable] = useState(false); // variable que verifica si es cajero

  //cleap up - componentWillUnmount
  useEffect(() => {
    cajeroValidate();
    return () => {
      $("#corteSuccess").modal("hide");
    };
  }, []);

  //Valida al usuario de tipo cajero
  const cajeroValidate = () => {
    if (JSON.parse(localStorage.getItem("pms"))) {
      if (JSON.parse(localStorage.getItem("pms")).tipo_usuario === "CAJERO") {
        // si es usuario tipo cajero
        setCajeroDisable(true);
      }
    }
  };

  const { register, handleSubmit } = useForm();
  // states

  const [fecha, setFecha] = useState(new Date());
  const [sucursal, setSucursal] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [seller, setSeller] = useState("");
  const [sellers, setSellers] = useState([]);
  const [caja, setCaja] = useState("");
  const [cajas, setCajas] = useState([]);
  const [summaryInfo, setSummaryInfo] = useState({
    total_efectivo: 0,
    total_tarjeta: null,
    total_cambios: null,
    total_subtotal: null,
    total_gift_card: null,
    total_vat_amount: null,
    total_monto_total: null,
    total_anticipos: null,
    total_notas_credito: null,
    total_facturas: null,
  });
  const [cortes, setCortes] = useState([]);
  const [corte, setCorte] = useState("");
  const [loader, setLoader] = useState(true);
  const [sales, setSales] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [filterText, setFilterText] = useState([]);
  const [showCorteForm, setShowCorteForm] = useState(false);
  const [corteDeCaja_data, setCorteDeCaja_data] = useState({});
  const [pastDay, setPastDay] = useState(false);
  const [newCorteInfo, setNewCorteInfo] = useState("");
  const [newCorteSales, setNewCorteSales] = useState("");
  const [operationsTab, setOperationsTab] = useState(0);
  const [selectedAnulaciones, setSelectedAnulaciones] = useState([]);
  const [anulaciones, setAnulaciones] = useState([]);
  const [filteredAnulaciones, setFilteredAnulaciones] = useState([]);
  const [anulacionesFilterText, setAnulacionesFilterText] = useState("");
  const [notasCredito, setNotasCredito] = useState([]);
  const [filteredNotasCredito, setFilteredNotasCredito] = useState([]);
  const [anticipos, setAnticipos] = useState([]);
  const [selectedAnticipos, setSelectedAnticipos] = useState([]);
  const [notasCreditoFilterText, setNotasCreditoFilterText] = useState("");
  const [selectedNotasCredito, setSelectedNotasCredito] = useState([]);
  const [llevar, setLlevar] = useState({ total: 0, ventas: [] });
  const [comerLocal, setComerLocal] = useState({ total: 0, ventas: [] });
  const [domicilio, setDomicilio] = useState({ total: 0, ventas: [] });
  const [open, setOpen] = useState(false);
  // *********************
  let [retiros, setRetiros] = useState([]);
  const [editFondoCambio, setEditFondoCambio] = useState(false);
  const [nuevoFondo, setNuevoFondo] = useState("");
  const [mensajeNuevoFondo, setMensajeNuevoFondo] = useState("");
  const [dataFacturacion, setDataFacturacion] = useState({});
  const {
    activarCierre,
    setActivarCierre,
    idSucursal,
    retiroData,
    setRetiroData,
    setEfectivoDisponible,
    cantidadRetiro,
    setCantidadRetiro,
    setActivarModalCorte,
  } = useContext(CmiProductContext);

  const [fondosValue, setFondosValue] = useState("");

  useEffect(() => {
    if (summaryInfo) {
      setFondosValue(formaterPriceFunction(summaryInfo.total_cambios + ""));
    }
  }, [summaryInfo]);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    console.log("filteredNotasCredito", filteredNotasCredito);
  }, [filteredNotasCredito]);

  useEffect(() => {
    getSucursals();
  }, []);

  useEffect(() => {
    if (caja !== "") {
      setLoader(true);
      getCajaSummary();
      getSellerCortes();
    }
  }, [caja]);

  useEffect(() => {
    if ((caja !== "") & (seller !== "")) {
      getCajaSummary();
      getSellerCortes();
    }
  }, [fecha]);

  useEffect(() => {
    if (seller !== "") {
      setLoader(true);
      getCajaSummary();
      getSellerCortes();
    }
  }, [seller, activarCierre]);

  /* useEffect(() => {
        if (newCorteSales.length === 0) {

            if (cortes.length > 0) {
                setCorte(cortes[0].caja_corte_id);
                setSummaryInfo({
                    total_efectivo: cortes[0].total_efectivo,
                    total_tarjeta: cortes[0].total_tarjeta,
                    total_gift_card: cortes[0].total_gift_card,
                    total_cambios: cortes[0].total_cambios,
                    total_subtotal: cortes[0].total_subtotal,
                    total_vat_amount: cortes[0].total_vat_amount,
                    total_monto_total: cortes[0].total_monto_total,
                    total_anticipos: cortes[0].total_anticipos,
                    total_notas_credito: cortes[0].total_notas_credito,
                    total_facturas: cortes[0].total_facturas,
                    fecha: cortes[0].close_caja_fecha,
                    caja_corte_id: cortes[0].caja_corte_id,
                });
                setSales(cortes[0].ventas);
                setFilteredSales(cortes[0].ventas);
            } else {
                setSales(newCorteSales);
                setFilteredSales(newCorteSales);
                setSummaryInfo(newCorteInfo);
            }

        }
    }, [cortes]); */

  // set states
  const changeSucursal = (event) => {
    const sucursalName = event.target.value;
    setSucursal(sucursalName);
    getCajas(event.target.value);
  };

  const getSucursals = () => {
    API.sales.obtenerCanalesVentas();
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          setSucursals(response.data);
          setSucursal(
            idSucursal
              ? idSucursal
              : response.data[0].id_sucursal_bodega_ubicacion
          );
          getCajas(
            idSucursal
              ? idSucursal
              : response.data[0].id_sucursal_bodega_ubicacion
          );
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getCajas = (value) => {
    API.cajas
      .getCajas({ id_sucursal_bodega_ubicacion: value })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCajas(response.data);
          setCaja(
            idSucursal
              ? JSON.parse(localStorage.getItem("caja")).id_caja_web_app
              : response.data[0].id_caja_web_app
          );
          getSellers(
            idSucursal
              ? JSON.parse(localStorage.getItem("caja")).id_caja_web_app
              : response.data[0].id_caja_web_app
          );
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const handleRetiroData = async () => {
    const id_new_empresa = localStorage.getItem("id_empresa");
    setRetiroData({
      ...retiroData,
      id_caja_web_app: caja,
      id_sucursal_bodega_ubicacion: sucursal,
      id_vendedor_web_app: seller,
      id_new_empresa,
    });
    try {
      const { data } = await getRetiroCaja({
        id_caja_web_app: caja,
        id_sucursal_bodega_ubicacion: sucursal,
        id_vendedor_web_app: seller,
        id_new_empresa,
        retiro_fecha: moment(fecha).format("YYYY-MM-DD"),
      });

      setCantidadRetiro({
        total: data.retiroTotalbeforeCorte,
        cantidad: data.dataRetiroCajabeforeCorte.length,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCajaSummary = () => {
    setLoader(true);
    handleRetiroData();
    API.cajas
      .getOpenCajaValues({
        id_caja_web_app: caja,
        fecha: !activarCierre
          ? fecha
          : moment(fecha).subtract(1, "day").format("YYYY-MM-DD"),
        id_vendedor_web_app: seller,
        id_sucursal_bodega_ubicacion: sucursal,
        web: true,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setNewCorteInfo({
            total_efectivo: response.data.total_efectivo,
            total_tarjeta: response.data.total_tarjeta,
            total_gift_card: response.data.total_gift_card,
            total_cambios: response.data.total_cambios,
            total_subtotal: response.data.total_subtotal,
            total_vat_amount: response.data.total_vat_amount,
            total_monto_total: response.data.total_monto_total,
            total_anticipos: response.data.total_anticipos,
            total_notas_credito: response.data.total_notas_credito,
            total_facturas: response.data.total_facturas,
            fecha: response.data.close_caja_fecha,
            caja_corte_id: response.data.caja_corte_id,
          });

          setSummaryInfo({
            total_efectivo: response.data.total_efectivo,
            total_tarjeta: response.data.total_tarjeta,
            total_gift_card: response.data.total_gift_card,
            total_cambios: response.data.total_cambios,
            total_subtotal: response.data.total_subtotal,
            total_vat_amount: response.data.total_vat_amount,
            total_monto_total: response.data.total_monto_total,
            total_anticipos: response.data.total_anticipos,
            total_notas_credito: response.data.total_notas_credito,
            total_credito: response.data.total_credito,
            total_facturas: response.data.total_facturas,
            fecha: response.data.close_caja_fecha,
            caja_corte_id: response.data.caja_corte_id,
            check: true,
          });
          setNewCorteSales(response.data.ventas);
          setSales(response.data.ventas);
          if (!isCmi()) {
            const array_llevar = response.data.ventas.filter(
              (item) => item.tipo_venta === 0
            ).length;
            const total_llevar = response.data.ventas
              .filter((item) => item.tipo_venta === 0)
              .reduce((a, b) => a + b.monto_total, 0);
            const array_comerLocal = response.data.ventas.filter(
              (item) => item.tipo_venta === 1
            ).length;
            const total_comerLocal = response.data.ventas
              .filter((item) => item.tipo_venta === 1)
              .reduce((a, b) => a + b.monto_total, 0);

            const array_domicilio = response.data.ventas.filter(
              (item) => item.tipo_venta === 2
            ).length;
            const total_domicilio = response.data.ventas
              .filter((item) => item.tipo_venta === 2)
              .reduce((a, b) => a + b.monto_total, 0);
            setLlevar({
              ventas: array_llevar,
              total: total_llevar.toFixed(2),
            });
            setComerLocal({
              ventas: array_comerLocal,
              total: total_comerLocal.toFixed(2),
            });
            setDomicilio({
              ventas: array_domicilio,
              total: total_domicilio.toFixed(2),
            });
          }
          if (isCmi()) {
            const array_llevar = response.data.ventas.filter(
              (item) => item.tipo_venta === 3
            ).length;
            const total_llevar = response.data.ventas
              .filter((item) => item.tipo_venta === 3)
              .reduce((a, b) => a + b.monto_total, 0);
            const array_comerLocal = response.data.ventas.filter(
              (item) => item.tipo_venta === 4
            ).length;
            const total_comerLocal = response.data.ventas
              .filter((item) => item.tipo_venta === 4)
              .reduce((a, b) => a + b.monto_total, 0);

            const array_domicilio = response.data.ventas.filter(
              (item) => item.tipo_venta === 5
            ).length;
            const total_domicilio = response.data.ventas
              .filter((item) => item.tipo_venta === 5)
              .reduce((a, b) => a + b.monto_total, 0);
            setLlevar({
              ventas: array_llevar,
              total: total_llevar.toFixed(2),
            });
            setComerLocal({
              ventas: array_comerLocal,
              total: total_comerLocal.toFixed(2),
            });
            setDomicilio({
              ventas: array_domicilio,
              total: total_domicilio.toFixed(2),
            });
          }
          setFilteredSales(response.data.ventas);
          setAnulaciones(response.data.anulaciones);
          setFilteredAnulaciones(response.data.anulaciones);
          setNotasCredito(response.data.notasCredito);
          setFilteredNotasCredito(response.data.notasCredito);
          setAnticipos(response.data.anticipos);
          console.log("I ALREADY RECEIVED DATA TO SHOW ON NEW CORTES");
          setLoader(false);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
          setLoader(false);
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  const actualizarFondosCambios = async () => {
    setLoader(true);

    const data = {
      amount: parseFloat(fondosValue.replace(",", "")),
      id_caja_corte_web_app: caja,
    };

    try {
      const responseUpdated = await API.cajas.updateFondosCambios(data);

      const response = await responseUpdated.data;

      console.log(response);

      if (response.success) {
        console.log(response);
        getCajaSummary();
      } else {
        toast.error(response.message, {
          autoClose: 10000,
        });
      }

      /*setSummaryInfo({
            ...summaryInfo,
            total_fondo_cambios: nuevoFondo
        })*/

      setLoader(false);
    } catch (err) {
      if (typeof err.response.data !== "undefined") {
        let response = err.response.data;
        toast.error(response.message, { autoClose: 10000 });
      } else {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
      }
    }

    // enviar datos a la base de datos y setear el estado con el nuevo dato de fondos.
  };

  const getSellerCortes = () => {
    //console.log(moment(fecha).format("YYYY-MM-DD"))
    API.cajas
      .getSellerCortes({
        id_caja_web_app: caja,
        id_vendedor_web_app: seller,
        fecha: moment(fecha).format("YYYY-MM-DD"),
        web: true,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Cortes del vendedor: ", response.data);
          setCortes(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const changeCaja = (event) => {
    const cajaId = event.target.value;
    setCaja(cajaId);
    getSellers(cajaId);
  };

  const filterSales = (e) => {
    setFilterText(e.target.value);
    setFilteredSales(
      sales.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredSales(sales);
    }
  };

  const filterAnulaciones = (e) => {
    setAnulacionesFilterText(e.target.value);
    setFilteredAnulaciones(
      anulaciones.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredAnulaciones(anulaciones);
    }
  };

  const filterNotasCredito = (e) => {
    setNotasCreditoFilterText(e.target.value);
    setFilteredNotasCredito(
      notasCredito.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredNotasCredito(notasCredito);
    }
  };

  const toggleForm = () => {
    setShowCorteForm(!showCorteForm);
  };

  const create_corte = (data) => {
    setLoader(true);
    if (
      sales.length === 0 &&
      !summaryInfo.total_anticipos &&
      !summaryInfo.total_notas_credito
    ) {
      setLoader(false);
      toast.error(
        "No puede cerrar caja porque no existen transacciones en la fecha seleccionada.",
        {
          autoClose: 10000,
        }
      );
    } else {
      data.efectivo = parseFloat(data.efectivo.replace(",", ""));
      data.anticipos = parseFloat(data.anticipos.replace(",", ""));
      data.tarjeta = parseFloat(data.tarjeta.replace(",", ""));

      data.gift_card = summaryInfo.total_gift_card || 0;
      data.facturas_creditos = parseFloat(
        data.facturas_creditos.replace(",", "")
      );
      data.cambios = parseFloat(data.cambios.replace(",", ""));
      data.notas_credito = parseFloat(data.notas_credito.replace(",", ""));
      data.id_caja_web_app = caja;
      data.id_sucursal_bodega_ubicacion = sucursal;
      data.id_vendedor_web_app = seller;
      data.fecha = !activarCierre
        ? fecha
        : moment(fecha).subtract(1, "day").format("YYYY-MM-DD");
      data.cmi = isCmi();
      console.log("SENDING THIS", data);
      API.cajas
        .cerrarCaja(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            $("#corteSuccess").modal();
            console.log("Obtained this response:", response.data);
            setCorteDeCaja_data(response.data);
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
          setLoader(false);
          setOpen(false);
          setActivarModalCorte(false);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          if (typeof err.response.data !== "undefined") {
            let response = err.response.data;
            toast.error(response.message, { autoClose: 10000 });
          } else {
            let response = err.response;
            console.log(response);
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
          setLoader(false);
        });
    }
  };

  const changeSeller = (event) => {
    const sellerId = event.target.value;
    setSeller(sellerId);
  };

  const getSellers = (value) => {
    API.cajas
      .getVendedores({ id_caja_web_app: value })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSellers(response.data);
          console.log("prueba", JSON.parse(localStorage.getItem("seller")));
          setSeller(
            JSON.parse(localStorage.getItem("seller")).id_vendedor_web_app
          );
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
        setLoader(false);
      });
  };

  const setQueryFecha = (e) => {
    if (
      moment(e.target.value).startOf("day").isBefore(moment().startOf("day"))
    ) {
      setPastDay(true);
    } else {
      setPastDay(false);
    }

    if (e.target.value == moment().format("YYYY-MM-DD")) {
      setFecha(new Date());
    } else {
      setFecha(e.target.value);
    }
  };

  const backToHistory = () => {
    history.push(`/app/cortes_caja/historial`);
  };

  const gotoInvoice = () => {
    let currentSucursal = sucursals.find(
      (x) => x.id_sucursal_bodega_ubicacion == sucursal
    );
    let currentCaja = cajas.find((x) => x.id_caja_web_app == caja);
    let currentSeller = sellers.find((x) => x.id_vendedor_web_app == seller);
    let orders = corteDeCaja_data.ventas?.map((item) => item.orderItems);
    let flattenOrders = [].concat.apply([], orders);
    localStorage.setItem(
      "corte_details",
      JSON.stringify({
        sucursal: currentSucursal,
        caja: currentCaja,
        vendedor: currentSeller,
        corte_data: corteDeCaja_data,
        sales: sales,
        llevar,
        comerLocal,
        domicilio,
        total_gifcard: summaryInfo.total_gift_card,
        retiros: corteDeCaja_data.retiros,
        total_anticipos: summaryInfo.total_anticipos,
        ordenes: flattenOrders,
      })
    );

    history.push(`/app/cortes_caja/recibo`);
  };

  const salesAction = (identificador, nit, id_pos_movimiento) => {
    const newWindow = window.open(
      "/#/app/vender/resumen/" + id_pos_movimiento,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const changeCorte = (event) => {
    setLoader(true);
    setCorte(event.target.value);
    if (event.target.value !== "") {
      let currentCorte = cortes.find(
        (x) => x.caja_corte_id == event.target.value
      );
      setSummaryInfo({
        total_efectivo: currentCorte.total_efectivo,
        total_tarjeta: currentCorte.total_tarjeta,
        total_gift_card: currentCorte.total_gift_card,
        total_cambios: currentCorte.total_cambios,
        total_subtotal: currentCorte.total_subtotal,
        total_vat_amount: currentCorte.total_vat_amount,
        total_monto_total: currentCorte.total_monto_total,
        total_anticipos: currentCorte.total_anticipos,
        total_notas_credito: currentCorte.total_notas_credito,
        total_facturas: currentCorte.total_facturas,
        fecha: currentCorte.close_caja_fecha,
        caja_corte_id: currentCorte.caja_corte_id,
        check: true,
      });
      setSales(currentCorte.ventas);
      setFilteredSales(currentCorte.ventas);
      setAnulaciones(currentCorte.anulaciones);
      setFilteredAnulaciones(currentCorte.anulaciones);
      setNotasCredito(currentCorte.notasCredito);
      setFilteredNotasCredito(currentCorte.notasCredito);
      setAnticipos(currentCorte.anticipos);
    } else {
      setSales(newCorteSales);
      setFilteredSales(newCorteSales);
      setSummaryInfo(newCorteInfo);
    }
    setLoader(false);
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;

    let data = selectedRows;
    if (operationsTab === 0) {
      setSelectedSales(data);
    }

    if (operationsTab === 1) {
      setSelectedAnulaciones(data);
    }

    if (operationsTab === 2) {
      setSelectedNotasCredito(data);
    }

    if (operationsTab === 3) {
      setSelectedAnticipos(data);
    }
  };

  const handleOperationsTab = (event, newValue) => {
    setOperationsTab(newValue);
  };

  const getTotalEfectivo = () =>
    (
      Number(summaryInfo.total_efectivo) + Number(summaryInfo.total_cambios)
    ).toFixed(2);

  const validarFondoCambio = async (e) => {
    if (e) {
      console.log("fondos", fondosValue);
      if (e.key === "Enter") {
        if (fondosValue.length <= 0) {
          setMensajeNuevoFondo(
            'El campo "Nuevo Fondo para Cambios" no puede estar vacio'
          );
          $("#modalFondoParaCambio").modal();
          return null;
        } else {
          setMensajeNuevoFondo("");
        }

        if (isNaN(parseFloat(fondosValue.replace(",", "")))) {
          setMensajeNuevoFondo(
            'El campo "Nuevo Fondo para Cambios" debe ser númerico'
          );
          $("#modalFondoParaCambio").modal();
          return null;
        } else {
          setMensajeNuevoFondo("");
        }

        if (parseFloat(fondosValue.replace(",", "")) % 1 !== 0) {
          setMensajeNuevoFondo(
            'El campo "Nuevo Fondo para Cambios" debe ser un número entero'
          );
          $("#modalFondoParaCambio").modal();
          return null;
        } else {
          setMensajeNuevoFondo("");
        }

        setMensajeNuevoFondo("");
        setEditFondoCambio(false);
        $("#modalFondoParaCambio").modal();
      }

      if (e.key === "Escape") {
        setEditFondoCambio(false);
      }
    } else {
      if (fondosValue.length <= 0) {
        setMensajeNuevoFondo(
          'El campo "Nuevo Fondo para Cambios" no puede estar vacio'
        );
        $("#modalFondoParaCambio").modal();
        return null;
      } else {
        setMensajeNuevoFondo("");
      }

      if (isNaN(parseFloat(fondosValue.replace(",", "")))) {
        setMensajeNuevoFondo(
          'El campo "Nuevo Fondo para Cambios" debe ser númerico'
        );
        $("#modalFondoParaCambio").modal();
        return null;
      } else {
        setMensajeNuevoFondo("");
      }

      if (parseFloat(fondosValue.replace(",", "")) % 1 !== 0) {
        setMensajeNuevoFondo(
          'El campo "Nuevo Fondo para Cambios" debe ser un número entero'
        );
        $("#modalFondoParaCambio").modal();
        return null;
      } else {
        setMensajeNuevoFondo("");
      }

      setMensajeNuevoFondo("");
      setEditFondoCambio(false);
      $("#modalFondoParaCambio").modal();
    }
  };

  const guardarFondoCambio = () => {
    // TODO enviar nuevo fondo para actualizar
    $("#modalFondoParaCambio").modal("hide");
    setEditFondoCambio(false);
    actualizarFondosCambios();
  };

  const cancelarFondoCambio = () => {
    $("#modalFondoParaCambio").modal("hide");
    setEditFondoCambio(false);
    setMensajeNuevoFondo("");
    setNuevoFondo("");
    console.log(nuevoFondo);
  };

  const [gerentes, setGerentes] = useState([]);
  useEffect(() => {
    if (sucursal !== "") {
      const getGerentes = async () => {
        try {
          const { data } = await getUsuariosGerentes({
            id_sucursal_bodega_ubicacion: +sucursal,
          });
          setGerentes(data.data);
        } catch (error) {
          console.log(error);
        }
      };
      getGerentes();
    }
  }, [sucursal]);

  useEffect(() => {
    setEfectivoDisponible(Number(getTotalEfectivo() - cantidadRetiro.total));
  }, [summaryInfo]);
  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined">
          <Select
            native
            value={sucursal}
            onChange={changeSucursal}
            disabled={cajeroDisable}
          >
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined">
          <Select
            native
            value={caja}
            onChange={changeCaja}
            disabled={cajeroDisable}
          >
            {cajas.map((caja) => (
              <option value={caja.id_caja_web_app}>{caja.descripcion}</option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={seller} onChange={changeSeller}>
            {sellers.map((seller) => (
              <option value={seller.id_vendedor_web_app}>
                {seller.nombre}
              </option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={corte} onChange={changeCorte}>
            {!pastDay ? <option value="">Nuevo corte</option> : ""}

            {pastDay && cortes.length === 0 ? (
              <option value="">No hay cortes</option>
            ) : (
              ""
            )}
            {cortes.map((cierre) => (
              <option value={cierre.caja_corte_id}>
                {moment(cierre.close_caja_fecha).format("DD-MM-YYYY HH:mm:ss")}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex align-items-center flex-wrap">
              <div className="mb-2">
                <p className="mb-0">
                  <strong>Cierre de caja</strong>
                </p>
                <p className="mb-0">
                  Consulte y cierre las operaciones realizadas en su caja.
                </p>
              </div>

              <div className="mx-sm-2 mb-2">
                <p className="mb-0">Filtrar por:</p>
                <div className="d-flex align-items-center flex-wrap">
                  <TextField
                    type="date"
                    className="w-100"
                    value={
                      activarCierre
                        ? moment(fecha).subtract(1, "day").format("YYYY-MM-DD")
                        : moment(fecha).format("YYYY-MM-DD")
                    }
                    onChange={(event) => setQueryFecha(event)}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"),
                      step: "any",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ width: "370px" }}
            >
              {isCmi() && (
                <>
                  <ModalAnulacion
                    gerentes={gerentes}
                    check="retiro"
                    header="Retirar efectivo de caja"
                    warning="Debes seleccionar el gerente que recibirá este retiro en efectivo desde su caja. Este deberá indicar su contraseña de acceso para confirmar la recepcion del dinero"
                    label="Efectivo en caja"
                    placeholder="Ingrese la cantidad a retirar"
                    infotip="Selecciona el gerente que autorizó el retiro"
                  />
                  <CamposErroneosModal message="No se ha realizado el retiro." />
                  <CamposErroneosModalCorte />
                  <RetiroAutorizadoModal />
                </>
              )}
              &nbsp; &nbsp;
              {!pastDay && !isCmi() ? (
                <CorteModal
                  register={register}
                  handleSubmit={handleSubmit}
                  create_corte={create_corte}
                  pastDay={pastDay}
                  getTotalEfectivo={getTotalEfectivo}
                  summaryInfo={summaryInfo}
                  open={open}
                  setOpen={setOpen}
                  loader={loader}
                />
              ) : (
                ""
              )}
              {isCmi() && (
                <CorteModal
                  register={register}
                  handleSubmit={handleSubmit}
                  create_corte={create_corte}
                  pastDay={pastDay}
                  getTotalEfectivo={getTotalEfectivo}
                  summaryInfo={summaryInfo}
                  open={open}
                  setOpen={setOpen}
                  cantidadRetiro={cantidadRetiro}
                  gerentes={gerentes}
                  loader={loader}
                />
              )}
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row mt-2">
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{
                  fontSize: "0.85rem",
                  color: "black",
                }}
              >
                Facturación total
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    `${moneda}. ${
                      summaryInfo.total_monto_total
                        ? formaterPriceFunction(
                            summaryInfo.total_monto_total.toFixed(2) + ""
                          )
                        : "0.00"
                    }`
                  )}
                </strong>
              </h6>
            </div>
            {isCmi() ? (
              <div
                className="col-md-3 px-2"
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Total efectivo en caja
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {loader ? (
                      <div className="text-center">
                        <CircularProgress size={26} className="mx-auto" />
                      </div>
                    ) : (
                      `${moneda}. ${
                        getTotalEfectivo() - cantidadRetiro.total < 0
                          ? "0.00"
                          : formaterPriceFunction(
                              (
                                getTotalEfectivo() - cantidadRetiro.total
                              ).toFixed(2) + ""
                            )
                      }`
                    )}
                  </strong>
                </h6>
              </div>
            ) : (
              ""
            )}
            {isCmi() ? (
              ""
            ) : (
              <div
                className="col-md-3 px-2"
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Fondo para cambios
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {loader ? (
                      <div className="text-center">
                        <CircularProgress size={26} className="mx-auto" />
                      </div>
                    ) : (
                      `${moneda}. ${
                        summaryInfo.total_cambios
                          ? formaterPriceFunction(
                              summaryInfo.total_cambios.toFixed(2) + ""
                            )
                          : "0.00"
                      }`
                    )}
                  </strong>
                </h6>
              </div>
            )}
            <div className="col-md-3 px-2">
              <p
                className="mb-1"
                style={{
                  fontSize: "0.85rem",
                  color: "black",
                }}
              >
                Efectivo
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    `${moneda}. ${
                      summaryInfo.total_efectivo
                        ? formaterPriceFunction(
                            summaryInfo.total_efectivo.toFixed(2) + ""
                          )
                        : "0.00"
                    }`
                  )}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-3 px-2 "
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{
                  fontSize: "0.85rem",
                  color: "black",
                }}
              >
                Tarjeta de débito/créd.
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    `${moneda}. ${
                      summaryInfo.total_tarjeta
                        ? formaterPriceFunction(
                            parseFloat(summaryInfo.total_tarjeta).toFixed(2) +
                              ""
                          )
                        : "0.00"
                    }`
                  )}
                </strong>
              </h6>
            </div>

            <div
              style={{
                height: "12px",
                width: "100%",
                backgroundColor: "#E0E0E0;",
              }}
            />
            {/* second row */}
            {/* <div
                    className="col-md-3 px-2"
                    style={{ borderRight: "1px solid #E0E0E0" }}
                >
                    <p
                        className="mb-1"
                        style={{
                            fontSize: "0.85rem",
                            color: "black",
                        }}
                    >
                        Gift Card
                    </p>
                    <h6
                        className="mb-0 secondary_color"
                    >
                        <strong>
                          {loader ? (
                            <div className="text-center">
                                <CircularProgress size={26} className="mx-auto" />
                            </div>
                        ) : (
                            `{moneda}. ${summaryInfo.total_gift_card || 0}
                        </strong>
                        `)}
                    </h6>
                </div> */}
            <div
              className="col-md-3 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{
                  fontSize: "0.85rem",
                  color: "black",
                }}
              >
                Facturas a crédito
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    `${moneda}. ${
                      summaryInfo.total_credito
                        ? formaterPriceFunction(
                            summaryInfo.total_credito.toFixed(2) + ""
                          )
                        : "0.00"
                    }
                                        `
                  )}
                </strong>
              </h6>
            </div>
            <div className="col-md-3 px-2">
              <p
                className="mb-1"
                style={{
                  fontSize: "0.85rem",
                  color: "black",
                }}
              >
                Anticipos
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {" "}
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    `
                                    ${moneda}. ${
                      summaryInfo.total_anticipos
                        ? formaterPriceFunction(
                            summaryInfo.total_anticipos.toFixed(2) + ""
                          )
                        : "0.00"
                    }
                                        `
                  )}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-3 px-2 "
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{
                  fontSize: "0.85rem",
                  color: "black",
                }}
              >
                Notas de crédito
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    `
                                            ${moneda}.
                                    ${
                                      summaryInfo.total_notas_credito
                                        ? formaterPriceFunction(
                                            summaryInfo.total_notas_credito.toFixed(
                                              2
                                            ) + ""
                                          )
                                        : "0.00"
                                    }`
                  )}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-3 px-2 "
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{
                  fontSize: "0.85rem",
                  color: "black",
                }}
              >
                N° Ventas Consumo {!isCmi() ? "Local" : "Pickup"}
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {moneda}.
                  {`${formaterPriceFunction(
                    parseFloat(comerLocal.total).toFixed(2) + ""
                  )} | ${formaterPriceFunction(
                    parseFloat(comerLocal.ventas).toFixed(2) + ""
                  )}`}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-3 px-2 "
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{
                  fontSize: "0.85rem",
                  color: "black",
                }}
              >
                N° Ventas Por {!isCmi() ? "Domicilio" : "Entrega"}
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {moneda}.
                  {`${formaterPriceFunction(
                    parseFloat(domicilio.total).toFixed(2) + ""
                  )} | ${formaterPriceFunction(
                    parseFloat(domicilio.ventas).toFixed(2) + ""
                  )}`}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-3 px-2 "
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{
                  fontSize: "0.85rem",
                  color: "black",
                }}
              >
                N° Ventas {!isCmi() ? "Para Llevar" : "Presencial"}
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {moneda}.{" "}
                  {`${formaterPriceFunction(
                    parseFloat(llevar.total).toFixed(2) + ""
                  )} | ${formaterPriceFunction(
                    parseFloat(llevar.ventas).toFixed(2) + ""
                  )}`}
                </strong>
              </h6>
            </div>
            {isCmi() && (
              <div
                className="col-md-3 px-2 "
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Retiros de efectivo
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {`${formaterPriceFunction(
                      cantidadRetiro.total.toFixed(2) + ""
                    )} | ${formaterPriceFunction(
                      parseFloat(cantidadRetiro.cantidad).toFixed(2) + ""
                    )}`}
                  </strong>
                </h6>
              </div>
            )}

            {isCmi() ? (
              <>
                <div
                  className="col-md-3 pr-4 mt-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <div className="text-center border align-self-start float-right">
                    <Button
                      disabled={loader ? true : false}
                      className="btn-cs1 float-right"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        !editFondoCambio
                          ? setEditFondoCambio(true)
                          : validarFondoCambio(null)
                      }
                      size="small"
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                          fontSize: "12px",
                        }}
                      >
                        {!editFondoCambio ? "Editar" : "Guardar"}
                      </span>
                    </Button>
                  </div>

                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Fondo para cambios
                  </p>

                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : editFondoCambio ? (
                    <TextField
                      // InputProps={{
                      //     classes: {
                      //         underline: classes.textFieldUnderline,
                      //         input: classes.textField
                      //     }
                      // }}
                      onChange={(e) =>
                        setFondosValue(formaterPriceFunction(e.target.value))
                      }
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      placeholder={formaterPriceFunction(
                        summaryInfo.total_cambios + ""
                      )}
                      onKeyDown={(e) => validarFondoCambio(e)}
                      fullWidth
                      value={fondosValue}
                    />
                  ) : (
                    <h6 className="mb-0 secondary_color">
                      <strong>
                        {`${moneda}. ${
                          summaryInfo.total_cambios
                            ? formaterPriceFunction(
                                parseFloat(summaryInfo.total_cambios).toFixed(
                                  2
                                ) + ""
                              )
                            : "0.00"
                        }`}
                      </strong>
                    </h6>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            {isCmi() && (
              <div
                className="col-md-3 px-2"
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Vale
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {summaryInfo.total_gift_card
                      ? formaterPriceFunction(
                          summaryInfo.total_gift_card.toFixed(2) + ""
                        )
                      : "0.00"}
                  </strong>
                </h6>
              </div>
            )}
          </div>

          {showCorteForm ? (
            <form onSubmit={handleSubmit(create_corte)}>
              <div className="row mb-4">
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Efectivo en caja</strong>
                    </label>
                    <Tooltip title="Efectivo en caja">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    className="w-100"
                    placeholder="Ingrese la cantidad facturada en efectivo"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                    name="efectivo"
                    type="number"
                    onChange={(event) =>
                      event.target.value < 0
                        ? (event.target.value = -event.target.value)
                        : event.target.value
                    }
                    inputRef={register}
                    inputProps={{
                      step: "0.00001",
                    }}
                    defaultValue={isCmi() ? getTotalEfectivo() : 0}
                    required={true}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Tarjeta débito / crédito</strong>
                    </label>
                    <Tooltip title="Tarjeta débito / crédito">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    type="number"
                    inputProps={{
                      step: "0.00001",
                    }}
                    className="w-100"
                    placeholder="Ingrese la cantidad facturada con tarjeta"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                    name="tarjeta"
                    inputRef={register}
                    required={true}
                    onChange={(event) =>
                      event.target.value < 0
                        ? (event.target.value = -event.target.value)
                        : event.target.value
                    }
                    defaultValue={isCmi() ? summaryInfo.total_tarjeta : 0}
                  />
                </div>

                <TextField
                  type="hidden"
                  name="gift_card"
                  inputRef={register}
                  defaultValue={0}
                />

                {/* Second Row */}
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Fondos para cambios</strong>
                    </label>
                    <Tooltip title="Fondos para cambios">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    type="number"
                    inputProps={{
                      step: "0.00001",
                    }}
                    className="w-100"
                    placeholder="Ingrese la cantidad que quedará en la caja"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                    name="cambios"
                    inputRef={register}
                    required={true}
                    onChange={(event) =>
                      event.target.value < 0
                        ? (event.target.value = -event.target.value)
                        : event.target.value
                    }
                    defaultValue={isCmi() ? summaryInfo.total_cambios : 0}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Notas de crédito</strong>
                    </label>
                    <Tooltip title="Notas de crédito">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    type="number"
                    inputProps={{
                      step: "0.00001",
                    }}
                    className="w-100"
                    placeholder="Ingrese la cantidad por notas de crédito"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                    name="notas_credito"
                    inputRef={register}
                    required={true}
                    onChange={(event) =>
                      event.target.value < 0
                        ? (event.target.value = -event.target.value)
                        : event.target.value
                    }
                    defaultValue={isCmi() ? summaryInfo.total_notas_credito : 0}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Facturas a crédito</strong>
                    </label>
                    <Tooltip title="Facturas a crédito">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    type="number"
                    inputProps={{
                      step: "0.00001",
                    }}
                    className="w-100"
                    placeholder="Ingrese la cantidad por facturas a crédito"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                    name="facturas_creditos"
                    inputRef={register}
                    required={true}
                    onChange={(event) =>
                      event.target.value < 0
                        ? (event.target.value = -event.target.value)
                        : event.target.value
                    }
                    defaultValue={isCmi() ? summaryInfo.total_facturas : 0}
                  />
                </div>

                {/* Third Row */}
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Anticipos</strong>
                    </label>
                    <Tooltip title="Anticipos">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    className="w-100"
                    placeholder="Ingrese la cantidad por anticipos"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                      inputProps: {
                        step: "0.00001",
                      },
                    }}
                    name="anticipos"
                    inputRef={register}
                    required={true}
                    onChange={(event) =>
                      event.target.value < 0
                        ? (event.target.value = -event.target.value)
                        : event.target.value
                    }
                    defaultValue={isCmi() ? summaryInfo.total_anticipos : 0}
                  />
                </div>
                <div className="col-md-5 mt-3 d-flex align-items-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="defaultCheck1"
                      required={true}
                    />
                    <label
                      class="form-check-label ml-2"
                      for="defaultCheck1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <strong>
                        Doy fe que los datos ingresados son correctos y
                        concuerdan con los montos presentes al momento del
                        cierre de la caja.
                      </strong>
                    </label>
                  </div>
                </div>
                <div className="col-md-3 mt-3 d-flex align-items-center justify-content-end">
                  {!pastDay ? (
                    <Button
                      className="btn-cs1 delete-btn"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                        }}
                      >
                        Cerrar caja
                      </span>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          ) : (
            ""
          )}

          <Tabs
            className="mt-5"
            value={operationsTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleOperationsTab}
          >
            <Tab className="tab" label="Ventas" />
            <Tab className="tab" label="Anulaciones" />
            <Tab className="tab" label="Notas de crédito" />
            <Tab className="tab" label="Anticipos" />
          </Tabs>

          <TabPanel value={operationsTab} index={0} className="config-tab">
            <>
              <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
                <h4 className="mb-0">
                  <strong>Historial de ventas</strong>
                </h4>
              </div>

              <div class="row justify-content-between mt-3 mb-3">
                {selectedSales.length > 0 ? (
                  <>
                    <Button
                      className="ml-3"
                      style={{
                        background: "#fff",
                        border: "1px solid #0160E7",
                        boxShadow: "none",
                      }}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        document
                          .getElementById("descargar-reporteRCHistorial")
                          .click();
                      }}
                    >
                      <CloudDownloadIcon
                        className="ml-2"
                        style={{ color: "#0160E7" }}
                      />
                      &nbsp;
                      <span style={{ color: "#0160E7" }}>Descargar</span>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="ml-3"
                      variant="contained"
                      style={{
                        background: "#fff",
                        border: "1px solid #0160E7",
                        boxShadow: "none",
                      }}
                      size="small"
                    >
                      <CloudDownloadIcon
                        className="ml-2"
                        style={{ color: "#0160E7" }}
                      />{" "}
                      &nbsp;
                      <span style={{ color: "#0160E7" }}>Descargar</span>
                    </Button>
                  </>
                )}

                <ReactHTMLTableToExcel
                  id="descargar-reporteRCHistorial"
                  className="buton-excel"
                  table="RCHistorial-reporte-table"
                  filename={
                    "Ventas" + " del día " + moment(fecha).format("DD-MM-YYYY")
                  }
                  sheet="tablexls"
                  buttonText="Descargar"
                />

                <RCHistorialReporte objeto={selectedSales}></RCHistorialReporte>

                <div className="col-md-4">
                  <div class="input-group search-bar">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Buscar por número de operación"
                      value={filterText}
                      onChange={(event) => filterSales(event)}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <SearchIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="products-table user-table">
                <DataTable
                  className="datatable-cs datatable-cs-v2"
                  title={"s"}
                  columns={[
                    {
                      name: "N° Factura",
                      selector: "identificador",
                      sortable: true,
                    },
                    {
                      name: "Fecha / Hora",
                      selector: "fecha",
                      sortable: true,
                      cell: (row) => (
                        <div className="text-center w-100">
                          {moment(row.fecha)
                            .utcOffset(0)
                            .format("DD/MM/YYYY, h:mm:ss a")}
                        </div>
                      ),
                    },
                    {
                      name: "Nombre del cliente",
                      selector: "client.nombre",
                      sortable: true,
                    },
                    {
                      name: "NIT",
                      selector: "client.nit",
                      sortable: true,
                    },
                    {
                      name: "N° productos",
                      selector: "numero_productos",
                      sortable: true,
                      cell: (row) => (
                        <div className="text-center w-100">
                          {row.numero_productos}
                        </div>
                      ),
                    },

                    {
                      name: "Monto",
                      selector: "monto_total",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {formaterPriceFunction(row.monto_total + "")}{" "}
                        </span>
                      ),
                    },
                    {
                      name: "Acciones",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <Button
                            className="btn-cs1"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() =>
                              salesAction(
                                row.identificador,
                                row.client.nit,
                                row.id_pos_movimiento
                              )
                            }
                            size="small"
                          >
                            <span
                              style={{
                                marginBottom: "-4px",
                                fontSize: "12px",
                              }}
                            >
                              Ver{" "}
                              <span
                                className="d-none d-xl-inline"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                detalles
                              </span>
                            </span>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  data={filteredSales}
                  defaultSortField="fecha"
                  defaultSortAsc={false}
                  selectableRows
                  noDataComponent="No hay ventas pendientes"
                  onSelectedRowsChange={handleSelect}
                />
              </div>
            </>
          </TabPanel>

          <TabPanel value={operationsTab} index={1} className="config-tab">
            <>
              <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
                <h4 className="mb-0">
                  <strong>Historial de anulaciones</strong>
                </h4>

                {selectedAnulaciones.length > 0 ? (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      document
                        .getElementById("descargar-reporteRCAnulaciones")
                        .click();
                    }}
                  >
                    Descargar
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                ) : (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                  >
                    Descargar
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                )}
              </div>

              <ReactHTMLTableToExcel
                id="descargar-reporteRCAnulaciones"
                className="buton-excel"
                table="RCAnulaciones-reporte-table"
                filename={
                  "Anulaciones" +
                  " del día " +
                  moment(fecha).format("DD-MM-YYYY")
                }
                sheet="tablexls"
                buttonText="Descargar"
              />

              <RCAnulacionesReporte
                objeto={selectedAnulaciones}
              ></RCAnulacionesReporte>

              <div class="row justify-content-end mt-3 mb-3">
                <div className="col-md-4">
                  <div class="input-group search-bar">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Buscar por número de operación"
                      value={anulacionesFilterText}
                      onChange={(event) => filterAnulaciones(event)}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <SearchIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="products-table user-table">
                <DataTable
                  className="datatable-cs datatable-cs-v2"
                  title={"s"}
                  columns={[
                    {
                      name: "N° Factura",
                      selector: "identificador",
                      sortable: true,
                    },
                    {
                      name: "Fecha / Hora",
                      selector: "fecha",
                      sortable: true,
                      cell: (row) => (
                        <div className="text-center w-100">
                          {moment(row.fecha)
                            .utcOffset(0)
                            .format("DD/MM/YYYY, h:mm:ss a")}
                        </div>
                      ),
                    },
                    {
                      name: "Nombre del cliente",
                      selector: "client.nombre",
                      sortable: true,
                    },
                    {
                      name: "NIT",
                      selector: "client.nit",
                      sortable: true,
                    },
                    {
                      name: "N° productos",
                      selector: "numero_productos",
                      sortable: true,
                      cell: (row) => (
                        <div className="text-center w-100">
                          {row.numero_productos}
                        </div>
                      ),
                    },

                    {
                      name: "Monto",
                      selector: "monto_total",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {formaterPriceFunction(row.monto_total + "")}{" "}
                        </span>
                      ),
                    },
                    {
                      name: "Acciones",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <Button
                            className="btn-cs1"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() =>
                              salesAction(
                                row.identificador,
                                row.client.nit,
                                row.id_pos_movimiento
                              )
                            }
                            size="small"
                          >
                            <span
                              style={{
                                marginBottom: "-4px",
                                fontSize: "12px",
                              }}
                            >
                              Ver{" "}
                              <span
                                className="d-none d-xl-inline"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                detalles
                              </span>
                            </span>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  data={filteredAnulaciones}
                  defaultSortField="fecha"
                  defaultSortAsc={false}
                  selectableRows
                  noDataComponent="No hay anulaciones pendientes"
                  onSelectedRowsChange={handleSelect}
                />
              </div>
            </>
          </TabPanel>

          <TabPanel value={operationsTab} index={2} className="config-tab">
            <>
              <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
                <h4 className="mb-0">
                  <strong>Historial de notas de crédito</strong>
                </h4>

                {selectedNotasCredito.length > 0 ? (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      document
                        .getElementById("descargar-reporteRCCredito")
                        .click();
                    }}
                  >
                    Descargar
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                ) : (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                  >
                    Descargar
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                )}
              </div>

              <ReactHTMLTableToExcel
                id="descargar-reporteRCCredito"
                className="buton-excel"
                table="RCCredito-reporte-table"
                filename={
                  "Anulaciones" +
                  +" del día " +
                  moment(fecha).format("DD-MM-YYYY")
                }
                sheet="tablexls"
                buttonText="Descargar"
              />

              <RCCreditoReporte
                objeto={selectedNotasCredito}
              ></RCCreditoReporte>

              <div class="row justify-content-end mt-3 mb-3">
                <div className="col-md-4">
                  <div class="input-group search-bar">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Buscar por número de operación"
                      value={notasCreditoFilterText}
                      onChange={(event) => filterNotasCredito(event)}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <SearchIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="products-table user-table">
                <DataTable
                  className="datatable-cs datatable-cs-v2"
                  title={"s"}
                  columns={[
                    {
                      name: "N° Factura",
                      selector: "identificador",
                      sortable: true,
                    },
                    {
                      name: "Fecha / Hora",
                      selector: "fecha",
                      sortable: true,
                      cell: (row) => (
                        <div className="text-center w-100">
                          {moment(row.fecha)
                            .utcOffset(0)
                            .format("DD/MM/YYYY, h:mm:ss a")}
                        </div>
                      ),
                    },
                    {
                      name: "Nombre del cliente",
                      selector: "client.nombre",
                      sortable: true,
                    },
                    {
                      name: "NIT",
                      selector: "client.nit",
                      sortable: true,
                    },
                    {
                      name: "N° productos",
                      selector: "numero_productos",
                      sortable: true,
                      cell: (row) => (
                        <div className="text-center w-100">
                          {row.numero_productos}
                        </div>
                      ),
                    },

                    {
                      name: "Monto",
                      selector: "monto",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}. {formaterPriceFunction(row.monto + "")}{" "}
                        </span>
                      ),
                    },
                    {
                      name: "Acciones",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <Button
                            className="btn-cs1"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                            component={Link}
                            to={`/app/operaciones_historial/nota_credito/${row.id_nota_credito_web_app}`}
                            size="small"
                          >
                            <span
                              style={{
                                marginBottom: "-4px",
                                fontSize: "12px",
                              }}
                            >
                              Ver{" "}
                              <span
                                className="d-none d-xl-inline"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                detalles
                              </span>
                            </span>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  data={filteredNotasCredito}
                  defaultSortField="fecha"
                  defaultSortAsc={false}
                  selectableRows
                  noDataComponent="No hay notas de credito pendientes"
                  onSelectedRowsChange={handleSelect}
                />
              </div>
            </>
          </TabPanel>

          <TabPanel value={operationsTab} index={3} className="config-tab">
            <>
              <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
                <h4 className="mb-0">
                  <strong>Historial de anticipos</strong>
                </h4>

                {selectedAnticipos.length > 0 ? (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      document
                        .getElementById("descargar-reporteRCAnticipos")
                        .click();
                    }}
                  >
                    Descargar
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                ) : (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                  >
                    Descargar
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                )}
              </div>

              <ReactHTMLTableToExcel
                id="descargar-reporteRCAnticipos"
                className="buton-excel"
                table="RCAnticipo-reporte-table"
                filename={
                  "Anticipos" +
                  " del día " +
                  moment(fecha).utcOffset(0).format("DD-MM-YYYY")
                }
                sheet="tablexls"
                buttonText="Descargar"
              />

              <RCAnticiposReporte
                objeto={selectedAnticipos}
              ></RCAnticiposReporte>

              <div className="products-table user-table">
                <DataTable
                  className="datatable-cs datatable-cs-v2"
                  title={"s"}
                  columns={[
                    {
                      name: "N°",
                      selector: "nro_anticipo",
                      sortable: true,
                    },
                    {
                      name: "Fecha / Hora",
                      selector: "fecha",
                      sortable: true,
                      cell: (row) => (
                        <div className="text-center w-100">
                          {moment(row.fecha)
                            .utcOffset(0)
                            .format("DD/MM/YYYY, h:mm:ss a")}
                        </div>
                      ),
                    },
                    {
                      name: "Nombre del cliente",
                      selector: "client.nombre",
                      sortable: true,
                    },
                    {
                      name: "NIT",
                      selector: "client.nit",
                      sortable: true,
                    },
                    {
                      name: "Concepto",
                      selector: "concepto",
                      sortable: true,
                      cell: (row) => (
                        <div className="text-center w-100">{row.concepto}</div>
                      ),
                    },

                    {
                      name: "Monto",
                      selector: "monto",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}. {formaterPriceFunction(row.monto + "")}{" "}
                        </span>
                      ),
                    },
                    {
                      name: "Acciones",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <Button
                            className="btn-cs1"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() =>
                              history.push({
                                pathname: "/app/anticipo/resumen",
                                state: {
                                  id_cliente_acreedor_web_app:
                                    row.id_cliente_acreedor_web_app,
                                },
                              })
                            }
                          >
                            <span
                              style={{
                                marginBottom: "-4px",
                                fontSize: "12px",
                              }}
                            >
                              Ver{" "}
                              <span
                                className="d-none d-xl-inline"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                detalles
                              </span>
                            </span>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  data={anticipos}
                  defaultSortField="fecha"
                  defaultSortAsc={false}
                  selectableRows
                  noDataComponent="No hay anticipos pendientes"
                  onSelectedRowsChange={handleSelect}
                />
              </div>
            </>
          </TabPanel>
        </div>
      </div>
      {/* Modal */}
      <div class="modal fade" id="corteSuccess" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Se ha cerrado la <br />
                    caja con éxito
                  </strong>
                </h4>
              </div>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={backToHistory}
                >
                  Aceptar
                </Button>
                <Button
                  className="btn-cs1 brand2-btn"
                  variant="contained"
                  color="primary"
                  onClick={gotoInvoice}
                >
                  Ver recibo
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal fondos para cambios */}
      <div
        className="modal fade"
        id="modalFondoParaCambio"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>
                <strong>
                  {mensajeNuevoFondo.length > 0
                    ? mensajeNuevoFondo
                    : `¿Desea guardar el monto de ${moneda}.${formaterPriceFunction(
                        fondosValue + ""
                      )} como fondo para cambios? `}
                </strong>
              </h4>
              <div className="d-flex justify-content-between">
                <Button
                  disabled={mensajeNuevoFondo.length > 0 ? true : false}
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={guardarFondoCambio}
                >
                  Sí, estoy de acuerdo
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  onClick={cancelarFondoCambio}
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL PARA PREVISUALIZAR EL CORTE DE CAJA */}

      <div class="modal fade" id="corteCaja" tabindex="-1" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div className="modal-header">
              <div className="col-md-12 d-flex justify-content-center">
                <div className="col-md-3 h-50">
                  <img
                    src="/images/cloudLogo.png"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt="factura-imagen"
                  />
                </div>
                <div className={`col-md-9 text-center corte_cabecera`}>
                  <p className="mb-0">
                    <strong>CLOUD, S.A</strong>
                  </p>
                  <p className="mb-0">
                    <strong>CLOUD</strong>
                  </p>
                  <p className="mb-0">NIT: 7879036-0</p>
                  <p>
                    Vía 4 1-00, zona 4 Edificio Campus Tec 2, 6to Nivel Oficina
                    601. Teléfono 25033333
                  </p>
                  {/* {
                    felData.currency && 
                    <p className="mb-0" >
                        <strong>
                        { felData.currency } - { felData.currency }
                        </strong>
                    </p>
                } */}

                  <p className="mb-0">
                    <strong>Guatemala - Guatemala</strong>
                  </p>

                  {/* {felData.seriefel ||
                    felData.id_dte ||
                    felData.numerofel ? (
                <p className="mb-0">
                    <strong>**DOCUMENTO TRIBUTARIO ELECTRONICO**</strong>
                </p>
                ) : (
                    ""
                )} */}
                  <p className="mb-0">
                    <strong>**DOCUMENTO TRIBUTARIO ELECTRONICO**</strong>
                  </p>

                  {/* {felData.identificador ? (
                <p className="mb-0">
                    NUMERO DE AUTORIZACION: <br/> {felData.identificador}
                </p>
                ) : (
                    ""
                )} */}

                  <p className="mb-0">
                    NUMERO DE AUTORIZACION: <br /> 123456789
                  </p>

                  <p className="mb-0">
                    <strong>FACTURA</strong>
                  </p>

                  {/* {felData.seriefel ? (
                    <p className="mb-0">
                        <strong>
                            SERIE: "{felData.seriefel}"
                            <br/>
                            Número: "{felData.numerofel}"
                            </strong>
                    </p>
                ) : (
                    ""
                )} */}

                  <p className="mb-0">
                    <strong>
                      SERIE: "12356"
                      <br />
                      Número: "123456"
                    </strong>
                  </p>

                  {/*{felData.id_dte ? (
                <p className="mb-0">
                        <strong>Id. dte "{felData.id_dte}"</strong>
                </p>
                ) : (
                    ""
                )}*/}
                </div>
              </div>
            </div>
            <div class="modal-body">
              <div className="divider" />

              <div className="d-flex justify-content-between">
                <div className="corte_cabecera">
                  {/* <p className="mb-0">NIT: {felData.client.nit}</p>
                  <p className="mb-0">Nombre: {felData.client.nombre}</p> */}
                  {/*<p className="mb-0">
                      Dirección:{" "}
                      {felData.client.direccion || "No especificada."}
                  </p>*/}
                  <p className="mb-0">NIT: Client Nit</p>
                  <p className="mb-0">Nombre: Cliente name</p>
                </div>
              </div>

              <div className="divider" />

              <div className="flex align-items-center corte_cabecera">
                {/* <p className="mb-0">Cajero: {felData.vendedor.nombre}</p> */}
                <p className="mb-0">Cajero: Vendedor Nombre</p>

                {/* <p className="mb-0">
                  Fecha: {felData.fecha.split('T')[0].split('-')[2]+'/'+felData.fecha.split('T')[0].split('-')[1]+'/'+felData.fecha.split('T')[0].split('-')[0]+' '+felData.fecha.split('T')[1].split('.')[0]}
              </p> */}

                <p className="mb-0">Fecha: 12/12/12</p>

                {/*<p>
                  {moment(felData.fecha).local().format("L")}
              </p>*/}
              </div>

              <div className="divider" />

              <div className="row">
                <div className="col-2 text-center">
                  <strong>Cantidad</strong>
                </div>
                <div className="col-5 text-center">
                  <strong>Descripción</strong>
                </div>
                <div className="col-3 text-center">
                  <strong>Precio U.</strong>
                </div>
                <div className="col-2 text-center">
                  <strong>Monto</strong>
                </div>
              </div>

              <div className="divider" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
