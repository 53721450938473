import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { AdminProvider } from "./context/AdminContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./main.css";
import "./vendor/styles/component-custom-switch.css";
import "bootstrap/dist/js/bootstrap.js";
import "./responsive.css";
import { AlekoProvider } from "./context/ContextoAleko/AlekoState";
import { ColorPickerProvider } from "./context/ColorPickerContext/ColorPickerState";
import { ProductPersonalizationProvider } from "./context/PersonalizacionProducto/ProductPersonalizationState";
import { ProductDetailsProvider } from "./context/DetalleProductosAleko/ProductDetailsState";
import { MermaProvider } from "./context/MermaContext/MermaState";
import { CmiProductProvider } from "./context/CmiProductContext/CmiProductState";
import { CambioMasivoProvider } from "./context/CambioMasivoContext/CambioMasivoState";

ReactDOM.render(
  <CambioMasivoProvider>
    <UserProvider>
      <AlekoProvider>
        <ProductPersonalizationProvider>
          <ColorPickerProvider>
            <ProductDetailsProvider>
              <MermaProvider>
                <CmiProductProvider>
                  <AdminProvider>
                    <ThemeProvider theme={Themes.default}>
                      <CssBaseline />
                      <LayoutProvider>
                        <App />
                      </LayoutProvider>
                    </ThemeProvider>
                  </AdminProvider>
                </CmiProductProvider>
              </MermaProvider>
            </ProductDetailsProvider>
          </ColorPickerProvider>
        </ProductPersonalizationProvider>
      </AlekoProvider>
    </UserProvider>
  </CambioMasivoProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

///Practice
