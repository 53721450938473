import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@material-ui/core";
import { base_url_images } from "../../api";
import { toast } from "react-toastify";
import API from "../../api";

import {
  Menu as MenuIcon,
  NotificationsNone as NotificationsIcon,
  Notifications as NotificationsIconFull,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import { Link } from "react-router-dom";

// styles
import useStyles from "./styles";
import "./customStyle.css";

//icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/AdminContext";

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  let [notificationsMenu, setNotificationsMenu] = useState(null);
  let [profileMenu, setProfileMenu] = useState(null);
  let [notifications, setNotifications] = useState([]);
  const [profileImg, setProfileImg] = useState("/images/profile-logo.png");

  useEffect(() => {
    get_user();
  }, []);

  const get_user = () => {
    API.admin_user
      .getCurrentUser()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          if (response.data.url_imagen) {
            setProfileImg(base_url_images + response.data.url_imagen);
          }
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  return (
    <AppBar
      position="fixed"
      className={[classes.appBar, "navbar-wrapper", "admin-navbar"].join(" ")}
    >
      <Toolbar className="navbar">
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse
          )}
          style={{ marginLeft: "-6px" }}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon className="icon" />
          ) : (
            <MenuIcon className="icon" />
          )}
        </IconButton>

        <div className={classes.grow} />

        <div
          id="profile-menu-btn"
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              marginTop: "-7px",
              backgroundColor: "white",
            }}
            className="row mx-0 p-1"
          >
            <img className="w-100 h-100" src={profileImg} alt="profile" />
          </div>
        </div>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <MenuItem className="justify-content-end menuItem-border">
            <Link
              to="/admin/profile"
              style={{ color: "#4A4A4A" }}
              id="enterprise-profile-button"
            >
              Perfil de usuario
            </Link>
          </MenuItem>
          <MenuItem
            className="justify-content-end"
            onClick={() => signOut(userDispatch, props.history)}
          >
            Cerrar sesión
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
