import React from "react";
// Styled components

import { GridContainer } from "./styled/styled.components";

// Components

import Actions from "./confirm.order.actions";
import View from "./confirm.order.view";
const order = {
  orderID: "0000000000101012000",
  orderDate: "05/04/2021",
  orderQuotation: "AS52A4SD52362A4",
  subTotal: "1,100.78",
  iva: "220.16",
  total: "1,320.94",
  currency: "Q",
  customProducts: [
    {
      title: "Camisa",
      quantity: "5",
      size: "M",
      price: 500,
      perspectives: [
        {
          title: "Frente",
          image: "images/test2.png",
          points: [
            {
              title: "Pecho derecho",
              color: "000000",
              contentType: "Imagen",
              customType: "Bordado",
              dimensions: {
                width: "30",
                height: "30",
              },
              observations: "",
              image: "images/test2.png",
              x: 45,
              y: 48,
            },
          ],
        },
        {
          title: "Frente",
          image: "images/test2.png",
          points: [
            {
              title: "Pecho derecho",
              color: "#000000",
              contentType: "Imagen",
              customType: "Bordado",
              dimensions: {
                width: "30",
                height: "30",
              },
              observations: "customizar",
              image: "images/test2.png",
              x: 45,
              y: 48,
            },
          ],
        },
        {
          title: "Frente",
          image: "images/test1.png",
          points: [
            {
              title: "Pecho derecho",
              color: "000000",
              contentType: "Imagen",
              customType: "Bordado",
              dimensions: {
                width: "30",
                height: "30",
              },
              observations: "customizar",
              image: "images/test2.png",
              x: 45,
              y: 48,
            },
          ],
        },
      ],
    },
  ],
};

const ConfirmOrder = () => {
  return (
    <GridContainer>
      <Actions />
      <View order={order} />
    </GridContainer>
  );
};

export default ConfirmOrder;
