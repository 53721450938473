// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DataTable from "react-data-table-component";
import TextField from "@material-ui/core/TextField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { base_url_images } from "../../api";
import { isCmi } from "../../helpers/isCmi";
import { isAleko } from "../../helpers/isAleko";
import print from "print-js";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import "moment/locale/es";
import { getMoneda } from "../../helpers/moneda";
moment.locale("es");

// components

//data

let default_dates = [
  `${moment(new Date()).utcOffset(-6).format("YYYY-MM-DD")}T05:00:00.000Z`,
  `${moment(new Date())
    .utcOffset(-6)
    .add(1, "d")
    .format("YYYY-MM-DD")}T04:59:59.999Z`,
];
console.log(
  "Default dates",
  moment(new Date()).utcOffset(-6).format("YYYY-MM-DDTHH:mm:ss")
);

export default function Users_all(props) {
  const convertir = require("numero-a-letras");
  const moneda = getMoneda();

  // states
  const [submitLoader, setSubmitLoader] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [selectMonto, setSelectMonto] = useState("");
  const [totalDevolver, setTotalDevolver] = useState(0);
  const [dateRange, setDateRange] = useState(default_dates);
  const [loader, setLoader] = useState(true);
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [cajas, setCajas] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [caja, setCaja] = useState("");
  const [seller, setSeller] = useState("");
  const [selectedSale, setSelectedSale] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [selectedField, setSelectedField] = useState("");
  const [isGuatefactura, setIsGuatefactura] = useState(false);
  const [isCreditNote, setIsCreditNote] = useState(false);
  const [isGranContribuyente, setIsGranContribuyente] = useState(false);
  const [isFel, setIsFel] = useState(false);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getTotalCredito = () =>
    Number(
      invoiceData.orderItems.reduce((a, b) => a + b.total_devolver, 0)
    ).toFixed(2);

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#notaCreation").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  // invoiceData
  useEffect(() => {
    if (Object.values(invoiceData).length > 0) {
      gotoNotaInvoice();
    }
  }, [invoiceData]);

  //component did mount
  useEffect(() => {
    getSucursals();
    getServerDate();
  }, []);

  useEffect(() => {
    getSales(default_dates);
  }, [sucursal]);

  const showFactura = () => {
    print({
      printable: "creditForm",
      type: "html",
      targetStyles: ["*"],
      modalMessage: "Imprimiendo",
      // font_size: '8px',
      showModal: true,
      onLoadingEnd: () => {
        //setOpen(true);
      },
    });
  };

  // set states
  const changeSucursal = (event) => {
    const storeName = event.target.value;
    setSucursal(storeName);
  };

  const changeSelectMonto = (event) => {
    setSelectMonto(event.target.value);
  };

  const getServerDate = () => {
    API.dashboard
      .getDate()
      .then((res) => {
        let { date } = res.data.data;
        date = `${date}T05:00:00.000Z`;
        setDateRange([date, date]);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al obtener la fecha del servidor", {
          autoClose: 10000,
        });
      });
  };

  const calculate_total_devolver = (id) => {
    const product_index = selectedSale.orderItems.findIndex(
      (x) => x.id_new_item === id
    );
    const newData = selectedSale.orderItems;
    let value = parseFloat(document.getElementById("value-" + id).value);
    if (value < 0 || value > newData[product_index].cantidad) {
      toast.error(
        "Cantidad de devolución invalida. Debe ser mayor que 1 y menor que la cantidad total de producto.",
        { autoClose: 10000 }
      );
      document.getElementById("value-" + id).value = 0;
      value = 0;
    }

    newData[product_index].cantidad_devolucion = parseFloat(value);
    let qty = newData[product_index].cantidad_devolucion;
    if (newData[product_index].id_new_item_combo) {
      newData[product_index].total_devolucion =
        parseFloat(newData[product_index].precio).toFixed(2) * qty;
    } else {
      // newData[product_index].total_devolucion =
      //   parseFloat(
      //     newData[product_index].precio / newData[product_index].cantidad
      //   ).toFixed(2) * qty
      newData[product_index].total_devolucion =
        parseFloat(newData[product_index].precio).toFixed(2) * qty;
    }

    let total = 0;

    selectedSale.orderItems.map((product) => {
      total += product.total_devolucion || 0;
    });
    setTotalDevolver(parseFloat(total.toFixed(2)));
    let existingData = selectedSale;
    existingData.orderItems = newData;
    setSelectedSale(existingData);
  };

  const getSales = (dates) => {
    setLoader(true);

    if (sucursal !== "") {
      let data = {
        from_date: dates[0],
        to_date: dates[1],
        id_sucursal_bodega_ubicacion: sucursal,
      };
      API.sales
        .getNCApplicableSales(data)
        .then((res) => {
          let response = res.data;
          console.log("Sales received:", response.data);
          if (response.success) {
            response.data.map((sale) => {
              sale.orderItems.map((orderItem) => {
                if (orderItem.id_new_item_combo) {
                  orderItem.precio = parseFloat(
                    (orderItem.monto_moneda / orderItem.cantidad).toFixed(2)
                  );
                }
              });
            });
            setSales(response.data);
            setFilteredSales(response.data);
          }
          setLoader(false);
        })
        .catch((err) => {
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
          setLoader(false);
        });
    }
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
          getCajas(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getCajas = (value) => {
    API.cajas
      .getCajas({ id_sucursal_bodega_ubicacion: value }, true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCajas(response.data);
          getSellers(response.data[0].id_caja_web_app);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSellers = (value) => {
    API.cajas
      .getVendedores({ id_caja_web_app: value }, true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSellers(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const changeCaja = (event) => {
    const cajaId = event.target.value;
    setCaja(cajaId);
  };

  const changeSeller = (event) => {
    const sellerId = event.target.value;
    setSeller(sellerId);
  };

  const filterSales = (e) => {
    console.log("Filtering by", e.target.value);
    setFilterText(e.target.value);
    setFilteredSales(
      sales.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredSales(sales);
    }
  };

  const handleDateChange = (value) => {
    setDateRange(value);
    getSales(value);
  };

  const emitir_nota = (sale) => {
    console.log("sale ->", sale);

    let currentSale = {
      ...sale,
      orderItems: sale.orderItems.map((x) =>
        x.id_new_item_combo
          ? { ...x, total_devolucion: x.cantidad * x.precio }
          : { ...x, total_devolucion: 0 }
      ),
    };
    setTotalDevolver(
      currentSale.orderItems.reduce((a, b) => a + b.total_devolucion, 0)
    );
    setSelectedSale(currentSale);
    $("#notaCreation").modal();
  };

  const submit_form = () => {
    let validForm = true;
    setSubmitLoader(true);
    if (
      selectMonto === "0" &&
      typeof selectedSale.monto_total === "undefined"
    ) {
      validForm = false;
      toast.error("El monto total no puede ser cero.", {
        autoClose: 10000,
      });
    }
    if (motivo === "") {
      validForm = false;
      toast.error("Debe introducir un motivo para la nota de crédito.", {
        autoClose: 10000,
      });
    }
    if (selectMonto === "1" && totalDevolver === 0) {
      validForm = false;
      toast.error(
        "En una nota de crédito parcial debe escoger al menos 1 producto.",
        { autoClose: 10000 }
      );
    }
    if (validForm) {
      let orderItems = selectedSale.orderItems;

      console.log("orderItems ->", orderItems);

      let productos = [];
      if (orderItems.some((x) => x.cantidad_devolucion)) {
        orderItems = orderItems.filter((x) => x.cantidad_devolucion);
      }
      orderItems.map((item) => {
        productos.push({
          id_new_item: item.id_new_item,
          cantidad_devolucion: item.cantidad_devolucion,
          cantidad: item.cantidad,
          total_devolver: item.total_devolucion,
          precio_total: parseFloat(item.monto_moneda.toFixed(2)),
          precio: parseFloat(item.precio.toFixed(2)),
          id_new_item_combo: item.id_new_item_combo,
          nombre_producto: item.nombre,
        });
      });

      let data = {
        id_pos_movimiento: selectedSale.id_pos_movimiento,
        documento: selectedSale.documento,
        serie: selectedSale.serie,
        id_tipo_documento: selectedSale.id_tipo_documento_web_app,
        motivo: motivo,
        tipo_monto: selectMonto,
        monto:
          selectMonto === "0"
            ? selectedSale.monto_total.toString()
            : totalDevolver.toString(),
        productos: JSON.stringify(productos),
      };

      console.log("Submitting this:", data);

      API.sales
        .createNotaCredito(data)
        .then((res) => {
          let response = res.data;
          console.log(
            "Credit note submitted, received this data:",
            response.data
          );
          if (response.ok) {
            $("#notaCreation").modal("hide");
            // $("#save").modal();
            toast.success("Nota de crédito creada.", {
              autoClose: 10000,
            });

            console.log("invoicedata ->", response.data);
            setInvoiceData(response.data);
            getSales(dateRange);
          } else {
            toast.error(response.msg, { autoClose: 10000 });
          }
          setSubmitLoader(false);
        })
        .catch((err) => {
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
          setSubmitLoader(false);
        });
    }
  };

  const gotoSaleInvoice = (sale) => {
    let nit = sale.client.nit;
    const newWindow = window.open(
      "/#/app/vender/resumen/" + sale.id_pos_movimiento,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const gotoNotaInvoice = () => {
    localStorage.setItem("invoice_data", JSON.stringify(invoiceData));

    if (
      invoiceData.id_nota_credito_web_app != undefined ||
      invoiceData.id_nota_credito_web_app > 0
    ) {
      setIsCreditNote(true);
    }

    if (invoiceData?.certificador?.codigoSatGuatefactura != undefined) {
      setIsGuatefactura(true);
    }

    if (invoiceData.granContribuyente) {
      setIsGranContribuyente(true);
    }

    if (invoiceData.fechafel) {
      setIsFel(true);
    }

    showFactura();
    /* const newWindow = window.open(
      "/#/app/nota_credito/factura",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null; */
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="notaCreation" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="d-flex justify-content-end px-2 py-1">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="mt-">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Motivo de la nota</strong>
                </label>
                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Indique el motivo de la nota de crédito"
                  value={motivo}
                  onChange={(e) => setMotivo(e.target.value)}
                />
              </div>

              <div className="mt-1">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Monto</strong>
                </label>
                <FormControl variant="outlined" className="w-100">
                  <Select
                    native
                    value={selectMonto}
                    onChange={changeSelectMonto}
                    className="cs-select1 select-w100"
                  >
                    <option value="">Seleccione</option>
                    <option value="0">Total</option>
                    <option value="1">Parcial</option>
                  </Select>
                </FormControl>
              </div>

              {selectMonto !== "1" ? (
                <div className="mt-1">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Indique el monto</strong>
                  </label>
                  <TextField
                    disabled={selectMonto === "0" ? true : false}
                    type="text"
                    className="w-100"
                    placeholder=""
                    value={
                      selectMonto === "0"
                        ? formaterPriceFunction(selectedSale.monto_total + "")
                        : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              ) : (
                ""
              )}

              <div class="d-flex justify-content-between mt-2">
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                  onClick={() => gotoSaleInvoice(selectedSale)}
                >
                  Ver factura
                </Button>
                {selectMonto !== "1" ? (
                  <Button
                    disabled={selectMonto === "" || submitLoader ? true : false}
                    className="btn-cs1 delete-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => submit_form()}
                  >
                    Emitir nota
                  </Button>
                ) : (
                  ""
                )}
              </div>

              {selectMonto === "1" ? (
                <div>
                  <DataTable
                    className="datatable-cs datatable-cs-v2 datatable-h-80 mt-2"
                    title={"s"}
                    columns={[
                      {
                        name: "",
                        selector: "none",
                        cell: (row) => (
                          <div className="text-center w-100">
                            <img
                              className="product-img"
                              src={
                                row.imagen_1
                                  ? base_url_images + row.imagen_1
                                  : base_url_images +
                                    "images/placeholder-image.jpeg"
                              }
                              alt=""
                            />
                          </div>
                        ),
                      },
                      {
                        name: "Producto",
                        selector: "nombre",
                        sortable: true,
                        cell: (row) => (
                          <div className="w-100">
                            <div
                              className=""
                              style={{
                                width: "110px",
                                fontSize: "0.7rem",
                              }}
                            >
                              {row.nombre} <br />
                              <strong>{row.codigo}</strong>
                            </div>
                          </div>
                        ),
                      },
                      {
                        name: "Precio (IVA Includ.)",
                        selector: "precio",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}.{" "}
                            {formaterPriceFunction(row.precio.toFixed(2) + "")}
                          </span>
                        ),
                      },
                      {
                        name: "Cantidad",
                        selector: "cantidad",
                        sortable: true,
                        cell: (row) => <span>{row.cantidad}</span>,
                      },
                      {
                        name: "Precio Total",
                        selector: "precio",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}.{" "}
                            {isNaN(
                              parseFloat(row.precio * row.cantidad).toFixed(2)
                            )
                              ? ""
                              : formaterPriceFunction(
                                  parseFloat(row.precio * row.cantidad).toFixed(
                                    2
                                  ) + ""
                                )}
                          </span>
                        ),
                      },
                      {
                        name: "Cantidad - devolución",
                        selector: "action",
                        cell: (row) => (
                          <div className="d-flex align-items-center w-100">
                            <TextField
                              defaultValue={
                                row.id_new_item_combo
                                  ? row.cantidad
                                  : row.cantidad_devolucion
                              }
                              disabled={row.id_new_item_combo}
                              type="number"
                              style={{
                                width: "100%",
                              }}
                              id={"value-" + row.id_new_item}
                              onSelect={() => setSelectedField(row.id_new_item)}
                              onBlur={() => setSelectedField("")}
                              autoFocus={row.id_new_item === selectedField}
                              onKeyUp={(e) => {
                                calculate_total_devolver(row.id_new_item);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      className="mx-1 no-outline-buttons"
                                      onClick={() =>
                                        calculate_total_devolver(
                                          row.id_new_item
                                        )
                                      }
                                      size="small"
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      <SendIcon
                                        style={{
                                          fontSize: "8px",
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                inputProps: {
                                  step: "0.00001",
                                },
                              }}
                            />
                          </div>
                        ),
                      },
                      {
                        name: "Total a devolver",
                        selector: "total_devolver",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}.{" "}
                            {isNaN(row.total_devolucion)
                              ? ""
                              : formaterPriceFunction(
                                  parseFloat(row.total_devolucion).toFixed(2) +
                                    ""
                                )}
                          </span>
                        ),
                      },
                    ]}
                    data={selectedSale.orderItems}
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                  />
                  <div className="d-flex justify-content-end align-items-center flex-wrap">
                    <Button
                      className="btn-cs1 delete-btn"
                      variant="contained"
                      color="primary"
                      onClick={() => submit_form()}
                    >
                      Emitir nota
                    </Button>
                    <div className="px-4 py-2 d-flex flex-wrap bg_secondary_color">
                      <p className="mb-0 mx-5" style={{ color: "white" }}>
                        <strong>Total a devolver:</strong>
                      </p>
                      <p className="mb-0 mx-5" style={{ color: "white" }}>
                        <strong>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            parseFloat(totalDevolver).toFixed(2) + ""
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div class="modal fade" id="save" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Se ha emitido una nota de <br />
                    crédito correctamente
                  </strong>
                </h4>
              </div>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 brand2-btn"
                  variant="contained"
                  color="primary"
                  onClick={gotoNotaInvoice}
                >
                  Ver recibo
                </Button>
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/operaciones_historial"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Nota de crédito</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined">
          <Select
            native
            value={sucursal}
            onChange={changeSucursal}
            className="sm-select"
          >
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined" className="px-1">
          <Select native value={caja} onChange={changeCaja}>
            {cajas.map((caja) => (
              <option value={caja.id_caja_web_app}>{caja.descripcion}</option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined" className="px-1">
          <Select native value={seller} onChange={changeSeller}>
            {sellers.map((seller) => (
              <option value={seller.id_vendedor_web_app}>
                {seller.nombre}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* Nota de crédito */}
      {Object.keys(invoiceData).length > 0 ? (
        <div style={{ display: "none" }}>
          <div
            style={{
              border: isCmi() ? "" : "1px solid gray",
              width: isCmi() ? "col-md-12" : "1240px",
              overflowX: isCmi() ? "none" : "scroll",
              lineHeight: "normal",
            }}
            className="d-flex justify-content-center"
            id="creditForm"
          >
            {loader ? (
              <div className="text-center">
                <CircularProgress size={16} className="mx-auto" />
              </div>
            ) : (
              <div className="pdf-target px-4 py-3">
                {invoiceData.fecha_anulacion ? (
                  <div className="anulado-overlay">
                    <p style={{ color: "red" }}>NOTA ANULADA</p>
                  </div>
                ) : (
                  ""
                )}

                {/* {invoiceData?.certificador?.codigoSatGuatefactura ? (
                        <div className="col-md-12 mx-auto text-center">
                          <img
                            className="mb-0 img_factura"
                            src={`${
                              isGuatefactura
                                ? `/images/avicola_icon.jpeg`
                                : "/images/cloudLogo.png"
                            }`}
                            alt="factura-imagen"
                          />
                        </div>
                      ) : (
                        ""
                      )} */}
                {isAleko() ? (
                  <div className="col-12 d-flex justify-content-center">
                    <img
                      className="mb-0"
                      src={"/images/logo_aleko_v1.png"}
                      alt="factura-imagen"
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-12 mx-auto text-center">
                  <p className="mb-0">
                    <strong>{invoiceData.empresa.nombre}</strong>
                  </p>
                  <p className="mb-0">
                    <strong>NIT: {invoiceData.empresa.nit}</strong>
                  </p>
                  <p className="mb-0">{invoiceData.sucursal.nombre_sucursal}</p>
                  <p className="mb-0">
                    {invoiceData.sucursal.direccion_sucursal}
                  </p>
                </div>

                <br />

                <div className="text-center">
                  <p className="mb-0">
                    <strong>DOCUMENTO TRIBUTARIO ELECTRÓNICO</strong>
                  </p>
                  <p className="mb-0">
                    <strong style={{ color: isGuatefactura ? "" : "" }}>
                      NOTA DE CRÉDITO
                    </strong>
                  </p>
                </div>

                <p className="mb-0">
                  <strong>{isGuatefactura ? "SERIE: " : "SERIE FEL:"}</strong>
                  {invoiceData.felData.seriefel}
                </p>
                <p className="mb-0">
                  <strong>{isGuatefactura ? "NÚMERO: " : "NÚMERO FEL:"}</strong>
                  {invoiceData.felData.numerofel}
                </p>
                {isCreditNote ? (
                  <div className="col-12 px-0">
                    <p className="mb-0">
                      <strong>NÚMERO DE AUTORIZACIÓN</strong>
                    </p>
                    <p className="mb-0">{invoiceData.id_dte}</p>
                    <p>
                      <strong>FECHA DE EMISIÓN: </strong>

                      {invoiceData.datosCertificador.fecha_dte
                        ? invoiceData.datosCertificador.fecha_dte
                            .replace("-", "/")
                            .replace("-", "/")
                        : ""}
                      {/* {moment(invoiceData.datosCertificador.fecha_dte).utc().format("DD/MM/YYYY")}  */}
                    </p>
                  </div>
                ) : (
                  <p className="mb-3">
                    <strong>Fecha Autorizacion FEL: </strong>
                    {invoiceData.fecha_correcta
                      ? moment(invoiceData.fecha_correcta).format(
                          "DD/MM/YYYY, h:mm:ss a"
                        )
                      : moment(invoiceData.fecha).format(
                          "DD/MM/YYYY, h:mm:ss a"
                        )}
                  </p>
                )}

                <div className="col-md-12 px-0 mt-2">
                  <p className="mb-0">
                    <strong>NIT:</strong> {invoiceData.client.nit}
                  </p>
                  <p className="mb-0">
                    <strong> NOMBRE: </strong>{" "}
                    {invoiceData.certificador
                      ? invoiceData.certificador.nombre
                        ? invoiceData.certificador.nombre
                        : invoiceData.client.nombre
                      : invoiceData.client.nombre}
                  </p>
                  <p className="mb-0">
                    <strong>DIRECCIÓN: </strong>
                    {invoiceData.client.direccion || "No especificada"}
                  </p>
                </div>

                {/* <div className="divider" /> */}
                <hr style={{ borderTop: "3px dashed #bbb" }} />
                <div className="row">
                  <div className="col-2 text-center">Cantidad</div>
                  <div className="col-4 text-center">Descripción</div>
                  <div className="col-4 text-center">
                    {" "}
                    {isGuatefactura ? "Precio en Q." : "Precio"}
                  </div>
                  <div className="col-2 text-center">
                    <strong>{isGuatefactura ? "Total en Q." : "Total"}</strong>
                  </div>
                </div>
                {/* <div className="divider" /> */}
                <hr style={{ borderTop: "3px dashed #bbb" }} />
                {invoiceData.orderItems.map((product) => (
                  <div className="row">
                    <div className="col-2 text-center">
                      {product.cantidad_devolucion}
                    </div>
                    <div className="col-4 text-center">
                      {product.codigo} <br /> {product.nombre}
                    </div>
                    <div className="col-4 text-center">
                      {formaterPriceFunction(
                        parseFloat(product.precio).toFixed(2)
                      )}
                    </div>
                    <div className="col-2 text-center">
                      {formaterPriceFunction(
                        parseFloat(product.total_devolver).toFixed(2)
                      )}
                    </div>
                  </div>
                ))}
                {/* <div className="divider" /> */}
                <p className="mb-0 mt-4">
                  <strong>
                    {invoiceData.nivel_precio
                      ? `Nivel de precio: ${invoiceData.nivel_precio}`
                      : ""}
                  </strong>
                </p>
                <p className="mb-0 mt-4">
                  <strong>
                    {isGuatefactura ? "FACTURA ASOCIADA: " : ""}
                    {/* {invoiceData.invoice.firmafel} */}
                  </strong>
                </p>
                <p className="mb-0">
                  <strong>
                    {isGuatefactura ? "Serie: " : "Serie Factura FEL: "}
                  </strong>
                  {invoiceData.invoice.seriefel}
                </p>
                <p className="mb-0">
                  <strong>
                    {isGuatefactura ? "Número: " : "Número Factura FEL: "}
                  </strong>
                  {invoiceData.invoice.numerofel}
                </p>
                <p className="mb-0">
                  <strong>
                    {isGuatefactura
                      ? "Número de Autorización: "
                      : "Número de Autorización Factura FEL: "}
                  </strong>
                </p>
                <p className="mb-0">{invoiceData.invoice.id_dte}</p>
                <p className="mb-0">
                  <strong>{isGuatefactura ? "Fecha emisión: " : ""}</strong>
                  {/* {invoiceData.invoice.fecha.split("T")[0].split("-")[2] +
                          "/" +
                          invoiceData?.invoice?.fecha
                            ?.split("T")[0]
                            .split("-")[1] +
                          "/" +
                          invoiceData?.invoice?.fecha
                            .split("T")[0]
                            .split("-")[0]} */}
                  {moment(invoiceData.invoice.fecha).utc().format("DD/MM/YYYY")}
                </p>

                <hr style={{ borderTop: "3px dashed #bbb" }} />

                <div className="row">
                  <div
                    className="col-12 text-right"
                    style={{ paddingRight: "44px" }}
                  >
                    <strong>
                      {/* Total Nota de Credito {moneda} {getTotalCredito()} */}
                      TOTAL NOTA DE CRÉDITO {moneda} {invoiceData.monto}
                    </strong>
                  </div>
                </div>

                <div
                  className="col-12"
                  style={{
                    paddingLeft: isCmi() ? "" : "150px",
                    marginTop: "10px",
                  }}
                >
                  <label style={{ fontSize: "13px" }}>
                    <strong>TOTAL EN LETRAS:</strong>
                    {getTotalCredito().toString() === "1.00"
                      ? moneda == "L"
                        ? "UN LEMPIRA 00/100"
                        : "UN QUETZAL CON 00/100"
                      : ""}

                    {getTotalCredito().toString() !== "1.00" && (
                      <>
                        {convertir
                          .NumerosALetras(
                            isCmi() ? invoiceData.monto : invoiceData.monto
                          )
                          .replace(
                            "Pesos",
                            moneda == "L" ? "LEMPIRAS" : "QUETZALES CON"
                          )
                          .replace("M.N.", "")
                          .toLocaleUpperCase()}
                      </>
                    )}
                  </label>
                  <br />
                  <br />
                </div>

                {isGuatefactura ? (
                  isGranContribuyente ? (
                    <p className="text-center">
                      <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                      <br />
                      <strong>Agente de Retención del IVA</strong>
                    </p>
                  ) : (
                    <div className="col-12 my-2">
                      <p className="text-center">
                        <strong>NO GENERA DERECHO A CRÉDITO FISCAL</strong>
                      </p>
                    </div>
                  )
                ) : (
                  <>
                    <p className="text-center">
                      <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                      <br />
                      <strong>Agente de Retención del IVA</strong>
                    </p>
                  </>
                )}

                <hr style={{ borderTop: "3px dashed #bbb" }} />

                <p className="mb-0">
                  <strong>FECHA DE IMPRESIÓN:</strong>{" "}
                  {invoiceData.fecha_correcta
                    ? moment
                        .utc(invoiceData.fecha_correcta)
                        .format("DD/MM/YYYY, h:mm:ss a")
                    : moment
                        .utc(invoiceData.fecha)
                        .format("DD/MM/YYYY, h:mm:ss a")}
                </p>

                {/* {isGuatefactura ? (
                        ""
                      ) : (
                        <div className="text-center col-12 my-4">
                          <p className="mb-0">
                            <strong>NUMERO DE AUTORIZACIÓN</strong>
                          </p>
                          <p className="mb-0">
                            <strong>{invoiceData.id_dte}</strong>
                          </p>

                        </div>
                      )} */}

                <div
                  className={
                    isGuatefactura
                      ? "row mt-2 justify-content-center"
                      : "row mt-2 justify-content-center"
                  }
                >
                  <p>
                    <strong>CERTIFICADOR: </strong>{" "}
                    {invoiceData.serviceData.certificador}
                  </p>
                  <div className="col-4 text-center">
                    <strong>Nit: </strong>{" "}
                    {invoiceData.fel
                      ? invoiceData.fel.nit
                      : invoiceData.service_nit}
                  </div>
                </div>

                <div className="text-center mt-4">
                  <p className="mb-0">
                    <strong>Powered by</strong>
                  </p>
                  <p className="mb-0">
                    <a href="https://www.cloud.com.gt" className="href-link">
                      www.cloud.com.gt
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {/* Nota de crédito */}

      {/* write content here "whithout {}"*/}
      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="row">
            <div className="col-md-6">
              <h5 className="mb-0">
                <strong>Emitir nota de crédito</strong>
              </h5>
              <p className="mb-0">
                Para realizar la emisión de una nota de crédito, deberá disponer
                del número de la factura o bien puede realizar una búsqueda
                manual.
              </p>
            </div>

            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <Button
                className="btn-cs1 mx-2 delete-btn"
                variant="contained"
                color="primary"
                to={"/app/operaciones_historial"}
                component={Link}
                format={"dd-MM-y"}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-end mb-3 mt-2 flex-wrap">
            <div className="d-flex align-items-end flex-wrap">
              <div className="mr-2">
                <p className="mb-0">Buscar por:</p>
                <div className="d-flex align-items-center flex-wrap">
                  <DateRangePicker
                    onChange={(val) => handleDateChange(val)}
                    value={dateRange}
                    format={"dd-MM-y"}
                    className="date-picker-cs px-1"
                  />
                </div>
              </div>
            </div>

            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ingrese el número de facturación"
                  style={{ width: "300px" }}
                  value={filterText}
                  onChange={(event) => filterSales(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2"
              title={"s"}
              columns={[
                {
                  name: "N° Factura",
                  selector: "identificador",
                  sortable: true,
                },
                {
                  name: "Fecha / Hora",
                  selector: "fecha",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {moment(new Date(row.fecha))
                        .utcOffset(0)
                        .format("DD/MM/YYYY h:mm:ss a")}
                    </div>
                  ),
                },
                {
                  name: "Nombre del cliente",
                  selector: "client.nombre",
                  sortable: true,
                },
                {
                  name: "NIT",
                  selector: "client.nit",
                  sortable: true,
                },
                {
                  name: "N° productos",
                  selector: "orderItems",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {row.orderItems.length}
                    </div>
                  ),
                },
                {
                  name: "Monto",
                  selector: "monto_total",
                  sortable: true,
                  cell: (row) => (
                    <span>
                      {moneda}.{" "}
                      {formaterPriceFunction(
                        parseFloat(row.monto_total).toFixed(2) + ""
                      )}{" "}
                    </span>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      <Button
                        className="btn-cs1 delete-btn w-100"
                        variant="contained"
                        color="primary"
                        onClick={() => emitir_nota(row)}
                      >
                        Emitir nota
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={filteredSales}
              pagination
              paginationPerPage={10}
              defaultSortField="fecha"
              defaultSortAsc={false}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se ha encontrado ningún resultado"
            />
          )}
        </div>
      </div>
    </div>
  );
}
