import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import moment from "moment";
import "moment/locale/es";
import { getMoneda } from "../../helpers/moneda";
moment.locale("es");

const generatePdf = (name) => {
  const element = document.querySelector(".pdf-target");

  /* const options = {
		filename: name
	};

	return domToPdf(element, options, () => {
		// callback function
	}); */
  window.html2canvas = html2canvas;
  const pdf = new jsPDF("p", "pt", "a4");
  pdf.html(element, {
    callback: function (doc) {
      pdf.save(name);
    },
    html2canvas: { scale: 0.475 },
  });
};

const ref = React.createRef();

export default function Users_all(props) {
  const moneda = getMoneda();
  const [invoiceData, setInvoiceData] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!!localStorage.getItem("anticipo_data")) {
      let anticipo_data = JSON.parse(localStorage.getItem("anticipo_data"));
      console.log("Receiving this data", anticipo_data);
      setInvoiceData(anticipo_data);
      localStorage.removeItem("anticipo_data");
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(
        "Ha ocurrido un error, por favor regrese a la página anterior.",
        {
          autoClose: 10000,
        }
      );
    }
  }, []);

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Factura anticipo</strong>
        </h4>
      </div>
      {invoiceData ? (
        <div className="content-card card mb-3">
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() =>
                  generatePdf(
                    `Anticipo -  ${
                      invoiceData.client.nit.toLowerCase().includes("cf")
                        ? invoiceData.client.nombre_cliente_final
                        : invoiceData.client.nombre
                    } # ${invoiceData.anticipo.nro_anticipo}`
                  )
                }
              >
                Descargar PDF
              </button>
            </div>

            <div className="d-flex justify-content-center">
              <div
                style={{
                  border: "1px solid gray",
                  width: "1240px",
                  overflowX: "scroll",
                }}
                className="mt-4"
              >
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={16} className="mx-auto" />
                  </div>
                ) : (
                  <div className="pdf-target px-4 py-3" ref={ref}>
                    <div className="text-left">
                      <p className="mb-0">
                        <strong
                          className="secondary_color"
                          style={{ fontSize: "20px" }}
                        >
                          "{invoiceData.empresa.nombre}"
                        </strong>
                      </p>
                      <p className="mb-0">{invoiceData.empresa.ciudad}</p>
                    </div>

                    <div className="row justify-content-end mt-4">
                      <div className="col-6 text-left">
                        <p>
                          <strong style={{ fontSize: "20px" }}>
                            Recibo #{invoiceData.anticipo.nro_anticipo}
                          </strong>
                        </p>
                      </div>
                    </div>

                    <div className="row anticipo-invoice-container">
                      <div className="col-2 anticipo-invoice-left">
                        <div className="row">
                          <div className="col-12 text-right anticipo-invoice-text">
                            <p>
                              <strong>FECHA:</strong>
                            </p>
                          </div>
                          <div className="col-12 text-right anticipo-invoice-text">
                            <p>
                              <strong>RECIBIMOS DE:</strong>
                            </p>
                          </div>
                          <div className="col-12 text-right anticipo-invoice-text">
                            <p>
                              <strong>LA SUMA DE:</strong>
                            </p>
                          </div>
                          <div className="col-12 text-right anticipo-invoice-text">
                            <p>
                              <strong>CONCEPTO:</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-10 anticipo-invoice-right">
                        <div className="row">
                          <div className="col-6 text-left anticipo-invoice-text">
                            <p>
                              <strong>
                                {moment(invoiceData.anticipo.fecha).format(
                                  "DD/MM/YYYY"
                                )}
                              </strong>
                            </p>
                          </div>
                          <div className="col-6 text-center anticipo-invoice-text">
                            <p>
                              <strong
                                style={{ color: "red", fontSize: "20px" }}
                              >
                                Emitido
                              </strong>
                            </p>
                          </div>
                          <div className="col-12 text-left anticipo-invoice-text">
                            <p>
                              <strong>
                                {invoiceData.client.nit
                                  .toLowerCase()
                                  .includes("cf")
                                  ? invoiceData.client.nombre_cliente_final
                                  : invoiceData.client.nombre}
                              </strong>
                            </p>
                          </div>
                          <div className="col-12 text-left anticipo-invoice-text">
                            <p>
                              <strong>
                                {invoiceData.anticipo.monto.toFixed(2)}{" "}
                                {moneda !== "L" ? "Quetzales" : "Lempiras"}
                              </strong>
                            </p>
                          </div>
                          <div className="col-12 text-left anticipo-invoice-text">
                            <p>
                              <strong>{invoiceData.anticipo.concepto}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row anticipo-invoice-container">
                      <div className="col-2 anticipo-invoice-left"></div>
                      <div className="col-2 anticipo-invoice-right"></div>
                      <div className="col-4 anticipo-invoice-left-two text-right">
                        <p>
                          <strong>ANTICIPO</strong>
                        </p>
                      </div>
                      <div className="col-4 anticipo-invoice-right text-right">
                        <p>
                          <strong style={{ fontSize: "18px" }}>
                            {moneda}. {invoiceData.anticipo.monto.toFixed(2)}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-center">
                        <p>
                          <strong className="secondary_color">
                            Fecha:{" "}
                            {moment(invoiceData.anticipo.fecha)
                              .utcOffset(0)
                              .format("DD-MM-YYYY hh:mm:ss")}
                            {/* {moment(invoiceData.anticipo.fecha).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )} */}
                          </strong>
                        </p>
                      </div>
                      {/* <div className="col text-center"> DELETED SINCE DATABASE DOES NOT HAVE SELLER ASSOCIATED
										<p><strong className="secondary_color">Usuario: Usuario</strong></p>
									</div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
