import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import API from "../../../api";
import FormControl from "@material-ui/core/FormControl";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";

export default function Configurations(props) {
  const [configTab, setConfigTab] = useState(0);
  const [attentionChannels, setAttentionChannels] = useState([]);
  let history = useHistory();
  const moment = require("moment");
  const { register, handleSubmit } = useForm();

  //component did mount
  useEffect(() => {
    getAttentionChannels();
  }, []);

  // states

  const handleConfigTabs = (event, newValue) => {
    setConfigTab(newValue);
  };

  const updateAttentionElement = (row, event, type) => {
    let value = event.target.value;
    let existingAttentionChannels = [...attentionChannels];
    let index = existingAttentionChannels.findIndex(
      (x) => x.id_canal === row.id_canal
    );

    if (type === "descripcion") {
      existingAttentionChannels[index].descripcion = value;
    }
    if (type === "activo") {
      existingAttentionChannels[index].activo = parseInt(value);
    }
    setAttentionChannels(existingAttentionChannels);
  };

  const updateAttentionChannel = (id) => {
    let currentChannel = attentionChannels.find((x) => x.id_canal === id);
    API.attentionChannels
      .updateExisting({
        id_canal: id,
        descripcion: currentChannel.descripcion,
        activo: currentChannel.activo,
      })
      .then((res) => {
        let response = res.data;

        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log(
            "Ha ocurrido un error al actualizar los canales de atencion"
          );
          toast.error(
            "Ha ocurrido un error al actualizar los canales de atencion",
            { autoClose: 10000 }
          );
        }
      });
  };

  const getAttentionChannels = () => {
    API.attentionChannels
      .getAll()
      .then((res) => {
        let response = res.data;

        if (response.success) {
          setAttentionChannels(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log(
            "Ha ocurrido un error al obtener los canales de atencion"
          );
          toast.error(
            "Ha ocurrido un error al obtener los canales de atencion",
            { autoClose: 10000 }
          );
        }
      });
  };

  const createAttentionChannel = (data) => {
    API.attentionChannels
      .createNew(data)
      .then((res) => {
        let response = res.data;

        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          getAttentionChannels();
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log(
            "Ha ocurrido un error al obtener los canales de atencion"
          );
          toast.error(
            "Ha ocurrido un error al obtener los canales de atencion",
            { autoClose: 10000 }
          );
        }
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Configuración de cuenta</strong>
        </h4>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <h5 className="mb-0">
            <strong>Información de su cuenta</strong>
          </h5>
          <p className="mb-0">
            Consulte y actualice todos la información asociada a su cuenta.
          </p>

          <Tabs
            className="mt-2"
            value={configTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleConfigTabs}
          >
            <Tab className="tab" label="Canales de atención" />
          </Tabs>
        </div>
        <div className="card-body">
          <TabPanel value={configTab} index={0} className="config-tab">
            <form onSubmit={handleSubmit(createAttentionChannel)}>
              <div className="row mb-4">
                <div className="col-md-4 mt-3">
                  <TextField
                    className="w-100"
                    placeholder="Ingrese la descripción del canal"
                    inputRef={register}
                    variant="outlined"
                    name="descripcion"
                    required={true}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <FormControl variant="outlined" className="w-100 h-100">
                    <Select
                      name="activo"
                      native
                      inputRef={register}
                      className="cs-select1 select-w100 h-100"
                      required={true}
                    >
                      <option value="">Seleccione el estado del canal</option>
                      <option value="0">Deshabilitado</option>
                      <option value="1">Habilitado</option>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-4 my-auto text-right">
                  <Button
                    className="btn-cs1 mt-2 mx-1"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Añadir nuevo canal de atención
                  </Button>
                </div>
              </div>
            </form>

            <DataTable
              className="datatable-cs"
              title={"s0"}
              columns={[
                {
                  name: "Descripción",
                  selector: "descripcion",
                  sortable: true,
                  cell: (row) => (
                    <div
                      className="mx-auto"
                      style={{ height: "80% !important" }}
                    >
                      <TextField
                        defaultValue={row.descripcion}
                        className="w-100"
                        key={row.id_canal}
                        variant="outlined"
                        placeholder="Ingrese la descripción del canal"
                        onBlur={(e) =>
                          updateAttentionElement(row, e, "descripcion")
                        }
                      />
                    </div>
                  ),
                },
                {
                  name: "Fecha de creación",
                  selector: "fecha_creacion",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center">
                      {moment(row.fecha_creacion).format("DD/MM/YYYY HH:mm:ss")}
                    </div>
                  ),
                },
                {
                  name: "Activo",
                  selector: "activo",
                  sortable: true,
                  cell: (row) => (
                    <div className="mx-auto">
                      <Select
                        native
                        variant="outlined"
                        defaultValue={row.activo ? 1 : 0}
                        onChange={(e) =>
                          updateAttentionElement(row, e, "activo")
                        }
                      >
                        <option value="">Seleccione el estado del canal</option>
                        <option value="0">Deshabilitado</option>
                        <option value="1">Habilitado</option>
                      </Select>
                    </div>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="mx-auto">
                      <Button
                        className="btn-cs1 mt-1 mx-1"
                        variant="contained"
                        color="primary"
                        onClick={() => updateAttentionChannel(row.id_canal)}
                        type="button"
                      >
                        Actualizar
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={attentionChannels}
              pagination
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se encontraron canales de atención"
            />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
