import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";

const UsuariosReporte = ({ objeto }) => {
  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="usuarios-reporte-table">
        <thead>
          <tr>
            <th>Nombre de usuario</th>
            <th>Cargo</th>
            <th>Tienda</th>
            <th>Correo electronico</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            return (
              <tr key={index}>
                <td>{x.nombre}</td>
                <td>{x.tipo_nombre}</td>
                <td>{x.nombre_sucursal}</td>
                <td>{x.correo}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UsuariosReporte;
