import { base_url, axios } from "../api";
var qs = require("qs");
const moment = require("moment");

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getCajas = async (data, filter) => {
  if (filter) {
    data.filter = true;
  }
  return await axios.post(`${base_url}/web/get-caja`, qs.stringify(data), {
    headers: headers(),
  });
};

const getVendedores = async (data, filter) => {
  if (filter === true) {
    data.filter = true;
  }
  return await axios.post(
    `${base_url}/web/get-vendedores`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getCompanySellers = async (params) => {
  return await axios.get(`${base_url}/web/get-company-sellers`, {
    headers: headers(),
    params: params,
  });
};

const getCajaSummary = async (data) => {
  return await axios.post(
    `${base_url}/web/get-caja-values`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getSellerCortes = async (data) => {
  data.fecha = moment(data.fecha).format("YYYY-MM-DD");
  return await axios.post(
    `${base_url}/web/get-seller-cortes`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getOpenCajaValues = async (data) => {
  data.fecha = moment(data.fecha).format("YYYY-MM-DD");
  data.web = true;
  console.log(data);
  return await axios.post(
    `${base_url}/web/get-open-caja-values`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const cerrarCaja = async (data) => {
  data.fecha = moment(data.fecha).format("YYYY-MM-DD");
  data.web = true;
  return await axios.post(`${base_url}/web/close-caja`, qs.stringify(data), {
    headers: headers(),
  });
};

const getCorteZ = async (data) => {
  return await axios.post(`${base_url}/web/global-caja-history`, data, {
    headers: headers(),
  });
};

const cierresHistory = async (data) => {
  return await axios.post(`${base_url}/web/caja-history`, qs.stringify(data), {
    headers: headers(),
  });
};

const getCorteById = async (data) => {
  data.web = true;
  return await axios.post(
    `${base_url}/web/single-caja-history`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const updateFondosCambios = async (data) => {
  return await axios.post(
    `${base_url}/web/add-change-to-cash-box-closing`,
    qs.stringify(data),
    { headers: headers() }
  );
};

export const validarCorte = (data) =>
  axios.post(
    `${base_url}/web/cmi/validar-corte_caja_ayer`,
    qs.stringify(data),
    { headers: headers() }
  );
const obtenerVentasSap = (data) =>
  axios.post(`${base_url}/web/cmi/obtener-ventas-dia`, qs.stringify(data), {
    headers: headers(),
  });

const descargasSap = (data) =>
  axios.post(`${base_url}/web/cmi/descargar-ventas-dia`, qs.stringify(data), {
    headers: headers(),
  });

export default {
  getCajas,
  getVendedores,
  getCajaSummary,
  cerrarCaja,
  cierresHistory,
  getCorteById,
  getSellerCortes,
  getOpenCajaValues,
  getCompanySellers,
  updateFondosCambios,
  getCorteZ,
  obtenerVentasSap,
  descargasSap,
};
