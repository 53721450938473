import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { isCajero } from "../../helpers/isCajero";

//herramienta reporte
import ReactHTMLTableToExcel from "react-html-table-to-excel";

// styles
import "./custom.css";

//reportes
import TrasladosReporte from "../../reportes/trasladosReporte";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { isAdmin } from "../../helpers/isAdmin";
import { isAleko } from "../../helpers/isAleko";
import { isGerenteMercadeo } from "../../helpers/isGerenteMercadeo";
import { isCmi } from "../../helpers/isCmi";
import moment from "moment";

// components

//data

export default function Dashboard(props) {
  let history = useHistory();
  /* let default_dates = [
    moment(new Date()).utcOffset(-6).format('YYYY-MM-DD'),
    moment(new Date()).utcOffset(-6).format('YYYY-MM-DD'),
  ]; */
  let default_dates = [new Date(), new Date()];
  const aleko = isAleko();
  // states
  const [comentario, setComentario] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [dateRange, setDateRange] = useState(default_dates);
  const [translados, setTranslados] = useState([]);
  const [loader, setLoader] = useState(true);
  const [printLoader, setPrintLoader] = useState(false);
  const [transladosInfo, setTransladosInfo] = useState({
    total: "No hay información",
    salientes: "No hay información",
    entrantes: "No hay información",
  });
  const [filterText, setFilterText] = useState("");
  const [filteredTranslados, setFilteredTranslados] = useState([]);
  const [selectedTranslados, setSelectedTranslados] = useState([]);
  const [selectedTrasladoId, setSelectedTrasladoId] = useState("");

  useEffect(() => {
    return () => {
      $("#reject_traslado").modal("hide");
    };
  }, []);
  const mermaGuard = () => {
    const tipo_usuario = JSON.parse(localStorage.getItem("pms")).tipo_usuario;
    if (!isCmi()) return false;
    if (
      tipo_usuario === "ADMINISTRADOR" ||
      tipo_usuario === "GERENTE REGIONAL" ||
      tipo_usuario === "GERENTE MERCADEO"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    console.log(newValue);
    return newValue;
  };

  useEffect(() => {
    getServerDate();
    getSucursals();
  }, []);

  useEffect(() => {
    updateTrasladosStats(sucursal);
  }, [translados]);

  // set states
  const changeSucursal = (event) => {
    const sucursalName = event.target.value;
    setSucursal(sucursalName);
    updateTrasladosStats(sucursalName);
    queryTraslados(dateRange, event.target.value);
  };

  const transladoAction = (event, id) => {
    let newData = [...translados];
    if (event.target.value === "detalles") {
      history.push(`/app/traslados/details/${id}?id_sucursal=` + sucursal);
    }
    if (event.target.value === "aceptar") {
      acceptTraslado(id);
    }
    if (event.target.value === "rechazar") {
      setSelectedTrasladoId(id);
      $("#reject_traslado").modal();
    }
  };

  const getServerDate = () => {
    API.dashboard
      .getDate()
      .then((res) => {
        let { date } = res.data.data;
        date = `${date}T05:00:00.000Z`;
        setDateRange([date, date]);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al obtener la fecha del servidor", {
          autoClose: 10000,
        });
      });
  };

  const handleTabClick = (event, newValue) => {
    if (!isAleko() && !isCmi()) {
      if (newValue !== 4) {
        history.push({
          pathname: "/app/inventario",
          state: { selectedTab: newValue },
        });
      }
    } else {
      if (newValue !== 3) {
        if (newValue === 5 && isCmi()) newValue = 8;

        history.push({
          pathname: "/app/inventario",
          state: { selectedTab: newValue },
        });
      }
    }
  };

  const handleDateChange = (value) => {
    setLoader(true);
    setDateRange(value);
    queryTraslados(value, sucursal);
  };

  const queryTraslados = (dates, sucursalId) => {
    setLoader(true);
    // Get all traslados
    let data = {
      from_date: dates[0].toDateString(),
      to_date: dates[1].toDateString(),
      id_sucursal_bodega_ubicacion: sucursalId,
    };
    API.traslados
      .getAll(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log(response.data);
          setTranslados(response.data);
          setFilteredTranslados(response.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  const printAllTraslados = () => {
    setPrintLoader(true);
    document.getElementById("descargar-reporteTraslados").click();
    setPrintLoader(false);
  };

  const filterTranslados = (e) => {
    setFilterText(e.target.value);
    setFilteredTranslados(
      translados.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredTranslados(translados);
    }
  };
  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success && JSON.stringify(response.data) != "[]") {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
          updateTrasladosStats(response.data[0].id_sucursal_bodega_ubicacion);
          queryTraslados(
            dateRange,
            response.data[0].id_sucursal_bodega_ubicacion
          );
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const acceptTraslado = (id) => {
    API.traslados
      .accept({ id_traslado_web_app: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("El traslado fue aceptado", {
            autoClose: 10000,
          });
          queryTraslados(dateRange, sucursal);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const rejectTraslado = () => {
    API.traslados
      .reject({ id_traslado_web_app: selectedTrasladoId, comentario })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("El traslado fue rechazado", {
            autoClose: 10000,
          });
          $("#reject_traslado").modal("hide");
          queryTraslados(dateRange, sucursal);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const updateTrasladosStats = (id) => {
    let stats = transladosInfo;
    stats.total = translados.length;
    let from_current_sucursal = translados.filter(
      (x) => x.id_sucursal_origen == id
    );
    let to_current_sucursal = translados.filter(
      (x) => x.id_sucursal_destino == id
    );
    stats.salientes = from_current_sucursal.length;
    stats.entrantes = stats.total - stats.salientes;
    setTransladosInfo(stats);
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;
    setSelectedTranslados(selectedRows);
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={sucursal} onChange={changeSucursal}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="content-card card mt-3">
        <div
          className="d-flex justify-content-between align-items-center flex-wrap"
          style={{ boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.06)" }}
        >
          <Tabs
            className="mt-2"
            value={isAleko() || isCmi() ? 3 : 4}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabClick}
          >
            <Tab className="tab" label="Todos" style={{ minWidth: "120px" }} />
            {!aleko && !isCmi() && !isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Combos"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}
            {!isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Ingresos"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}
            {!isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Egresos"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Traslados"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Kardex"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!aleko && !isCmi() ? (
              <Tab
                className="tab"
                label="Recetas"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}
            {!aleko && !isCmi() ? (
              <Tab
                className="tab"
                label="Insumos"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!aleko && mermaGuard() ? (
              <Tab
                className="tab"
                label="Mermas"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!isCajero() && aleko ? (
              <Tab
                className="tab"
                label="Características"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}
          </Tabs>
        </div>

        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <div>
              <h4 class="mb-0">
                <strong>Traslados</strong>
              </h4>
              <p className="mb-0">
                Consulte, gestione y realice nuevos traslados.
              </p>
            </div>

            <div>
              <p className="mb-0">Fecha:</p>
              <div className="d-flex align-items-center flex-wrap">
                <DateRangePicker
                  onChange={(val) => handleDateChange(val)}
                  value={dateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
            {!isCmi() && (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                startIcon={<AddBoxIcon />}
                onClick={(e) => null}
                component={Link}
                to={"/app/traslados/new"}
              >
                <span style={{ marginBottom: "-4px" }}>Crear traslado</span>
              </Button>
            )}
          </div>

          <div className="row">
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de traslados
              </p>
              <h6 className="mb-0 secondary_color">
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>{numberFormater(transladosInfo.total)}</strong>
                )}
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Traslados salientes
              </p>
              <h6 className="mb-0 secondary_color">
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>{numberFormater(transladosInfo.salientes)}</strong>
                )}
              </h6>
            </div>
            <div className="col-md-2 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Traslado entrantes
              </p>
              <h6 className="mb-2 secondary_color">
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>{numberFormater(transladosInfo.entrantes)}</strong>
                )}
              </h6>
            </div>
            <div className="col-md-6 px-2 text-right">
              {/* {selectedTranslados.length > 0 ? (
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    document.getElementById("descargar-reporteEgresos").click();
                  }}
                >
                  Descargar
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              ) : (
                <Button className="btn-cs1" variant="contained" color="primary">
                  Descargar
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              )} */}
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                onClick={printAllTraslados}
                disabled={loader}
              >
                {loader ? "Descargando..." : "Descargar todos"}
                <CloudDownloadIcon className="ml-2" />
              </Button>
            </div>
          </div>

          <ReactHTMLTableToExcel
            id="descargar-reporteTraslados"
            className="buton-excel"
            table="traslados-reporte-table"
            filename={
              "Traslados desde el " +
              moment(dateRange[0]).format("DD-MM-YYYY") +
              " hasta el " +
              moment(dateRange[1]).format("DD-MM-YYYY")
            }
            sheet="tablexls"
            buttonText="Descargar"
          />

          <TrasladosReporte objeto={filteredTranslados}></TrasladosReporte>

          <div className="d-flex justify-content-end align-items-center mb-3 mt-2 flex-wrap">
            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por número de traslado"
                  style={{ width: "300px" }}
                  value={filterText}
                  onChange={(event) => filterTranslados(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div>
              {translados ? (
                <DataTable
                  className="datatable-cs datatable-cs-v2 datatable-h-80"
                  title={"s"}
                  columns={[
                    {
                      name: "N° de traslado",
                      selector: "identificador",
                      sortable: true,
                      cell: (row) => (
                        <div className="w-100 text-center">
                          {row.identificador}
                        </div>
                      ),
                    },
                    {
                      name: "Fecha / Hora",
                      selector: "fecha",
                      sortable: true,
                      cell: (row) => (
                        <div className="w-100 text-center">
                          {moment(row.fecha)
                            .utcOffset(0)
                            .format("DD/MM/YYYY HH:mm:ss a")}
                        </div>
                      ),
                    },
                    {
                      name: "Salida - Destino",
                      selector: "origin_sucursal",
                      sortable: true,
                      cell: (row) => (
                        <div className="w-100 text-center">
                          {row.origin_sucursal} - {row.destino_sucursal}
                        </div>
                      ),
                    },
                    {
                      name: "N° de productos",
                      selector: "total_productos",
                      sortable: true,
                      cell: (row) => (
                        <div className="w-100 text-center">
                          {numberFormater(row.total_productos)}
                        </div>
                      ),
                    },
                    {
                      name: "Estatus",
                      selector: "estado",
                      sortable: true,
                      cell: (row) => (
                        <div className="w-100 text-center">
                          {row.estado === "Pendiente" ? (
                            <span
                              className="p-2 warning-span"
                              style={{
                                color: "white",
                              }}
                            >
                              {row.estado}
                            </span>
                          ) : (
                            ""
                          )}
                          {row.estado === "Rechazado" ? (
                            <span
                              className="p-2 danger-span"
                              style={{
                                color: "white",
                              }}
                            >
                              {row.estado}
                            </span>
                          ) : (
                            ""
                          )}
                          {row.estado === "Aprobado" ? (
                            <span
                              className="p-2 success-span"
                              style={{
                                color: "white",
                              }}
                            >
                              {row.estado}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      ),
                    },
                    {
                      name: "Acciones",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          {isCmi() ? (
                            <FormControl variant="outlined">
                              <Select
                                native
                                onChange={(e) =>
                                  transladoAction(e, row.id_traslado_web_app)
                                }
                                className="cs-select"
                              >
                                <option value="">Acciones</option>
                                <option value="detalles">Ver detalles</option>
                                {row.origen &&
                                row.estado !== "Aprobado" &&
                                row.estado !== "Rechazado" &&
                                false ? (
                                  <>
                                    <option value="aceptar">
                                      Aceptar traslado
                                    </option>
                                    <option value="rechazar">
                                      Rechazar traslado
                                    </option>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Select>
                            </FormControl>
                          ) : (
                            <FormControl variant="outlined">
                              <Select
                                native
                                onChange={(e) =>
                                  transladoAction(e, row.id_traslado_web_app)
                                }
                                className="cs-select"
                              >
                                <option value="">Acciones</option>
                                <option value="detalles">Ver detalles</option>
                                {false ? (
                                  <>
                                    <option value="aceptar">
                                      Aceptar traslado
                                    </option>
                                    <option value="rechazar">
                                      Rechazar traslado
                                    </option>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Select>
                            </FormControl>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  data={filteredTranslados}
                  defaultSortField="fecha"
                  defaultSortAsc={false}
                  selectableRows
                  pagination
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  noDataComponent="No se ha encontrado ningún resultado"
                  onSelectedRowsChange={handleSelect}
                />
              ) : (
                <p className="text-center">No hay traslados para mostrar</p>
              )}
            </div>
          )}
        </div>
      </div>

      <div class="modal fade" id="reject_traslado" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea rechazar este traslado?</strong>
              </h4>

              <div class="d-flex justify-content-between flex-wrap">
                <FormControl variant="outlined" className="w-100">
                  <TextareaAutosize
                    name="comentario"
                    aria-label="minimum heigth"
                    placeholder="Indique el motivo de rechazo"
                    className="w-100 my-1"
                    maxLength={160}
                    minRows={3}
                    onChange={(e) => setComentario(e.target.value)}
                    style={{ minHeight: "40px" }}
                  />
                </FormControl>
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => rejectTraslado()}
                >
                  Sí, rechazar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
