import React from "react";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

// styles
import "../custom.css";
import { mdiCarLightHigh } from "@mdi/js";

const ShowEmpresasAsignadas = (props) => {
  const { empresas, handleDeleteEmpresaAsignaciones } = props;

  console.log("empresas ->", empresas);

  return (
    <div className="col-md-12 mh-100" style={{ height: "auto" }}>
      <div className="col-md-12 d-flex justify-content-between celda_empresas_cmi">
        <div className="col-md-1"></div>
        <div className="col-md-4 d-flex justify-content-start align-items-center">
          <p className="my-auto">
            <strong>Empresa</strong>
          </p>
        </div>
        <div className="col-md-4 d-flex justify-content-start align-items-center">
          <p className="my-auto">
            <strong>sucursal</strong>
          </p>
        </div>
        <div className="col-md-3 d-flex justify-content-start align-items-center">
          <p className="my-auto">
            <strong>caja</strong>
          </p>
        </div>
      </div>

      {empresas &&
        empresas.map((empresa) => (
          <div
            key={empresa.id_new_empresa}
            className="col-md-12 mh-100 d-flex justify-content-between celda_empresas_cmi"
          >
            <div className="col-md-1 mx-0 d-flex celda_empresas_cmi">
              <div className="my-auto">
                <div className="delete_nivel">
                  <DeleteOutlineOutlinedIcon
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={(e) =>
                      handleDeleteEmpresaAsignaciones(empresa.id_new_empresa)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <div className="w-100">
                <label className="my-auto" style={{ fontSize: "12px" }}>
                  {empresa.empresa}
                </label>
              </div>
            </div>

            <div className="col-md-4 d-flex justify-content-center align-items-center celda_empresas_cmi">
              <div className="w-100">
                {empresa.sucursals.map((itemSucursal) => (
                  <p className="my-auto" style={{ fontSize: "12px" }}>
                    {itemSucursal.nombre_sucursal}
                  </p>
                ))}
              </div>
            </div>

            <div className="col-md-3 mx-0 py-2 celda_empresas_cmi d-flex flex-column justify-content-center align-items-center">
              <div className="w-100">
                {empresa.sucursals.map((itemSucursal) =>
                  itemSucursal.cajas.map((itemCaja) => (
                    <p className="my-auto" style={{ fontSize: "12px" }}>
                      {itemCaja.descripcion}
                    </p>
                  ))
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ShowEmpresasAsignadas;
