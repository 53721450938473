import React, { useState, useEffect, useContext } from "react";

// Icons

import { Add, KeyboardReturnSharp } from "@material-ui/icons";

// Styled Components

import { GridContainer } from "./components/styled/styled.components";

// Components

import CustomizationActions from "./components/customization.actions";
import CustomizationProducts from "./components/customization.products";
import CustomizationTabs from "./components/customization.tabs";

import ProductPersonalizationContext from "../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { useHistory } from "react-router-dom";

const Customization = () => {
  const history = useHistory();
  // This handle product tabs

  const {
    products,
    getPersonalizationProducts,
    currentTab,
    setCurrentTab,
    clearState,
  } = useContext(ProductPersonalizationContext);

  const selectedProducts = JSON.parse(localStorage.getItem("selectedProducts"));

  useEffect(() => {
    if (!selectedProducts) {
      history.goBack();
    } else {
      getPersonalizationProducts(history);
    }
  }, []);

  return (
    <GridContainer>
      <CustomizationActions />
      <GridContainer
        gridColumn="1/-1"
        backgroundColor="#fff"
        borderRadius="10px"
      >
        {!products.length ? (
          <span></span>
        ) : (
          <>
            <CustomizationTabs
              products={products}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
            <CustomizationProducts
              products={products}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </>
        )}
      </GridContainer>
    </GridContainer>
  );
};

export default Customization;
