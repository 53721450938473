// eslint-disable-next-line
import React, { Fragment, useState, useEffect, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import $ from "jquery";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Autosuggest from "react-autosuggest";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { isCmi } from "../../helpers/isCmi";
import { isAleko } from "../../helpers/isAleko";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";

// components
import ShowEmpresasAsignadas from "./usersComponents/ShowEmpresasAsignadas";
import informesAleko from "../../api/informesAleko";
import FormRegister from "./userAlekoComponents/FormRegister";

export default function Users_all(props) {
  let history = useHistory();
  const [user, setUser] = useState({
    id_new_user: "",
    id_usuario: "",
    id_new_empresa: "",
    nombre: "",
    id_tipo_usuario_web_app: "",
    correo: "",
    id_sucursal: "",
    id_vendedor: "",
    serie: "",
    tipo_nombre: "",
    permisos: [0, 0, 0, 0, 0, 0, 0],
    caja: "",
    telefono: "",
  });

  const { register, handleSubmit, errors, watch, reset } = useForm();
  const password = useRef({});
  const [newUserForm, setNewUserForm] = useState(true);
  const [userId, setUserId] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [cajas, setCajas] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  password.current = watch("contrasena", "");
  const [currentUser, setCurrentUser] = useState(0);
  // cmi
  const [gteRegional, setGteRegional] = useState(null);
  const [asignacionesCmi, setAsignacionesCmi] = useState({});
  //state input
  const [buscarEmpresaCmi, setBuscarEmpresaCmi] = useState("");
  //sugerencias de busqueda
  const [suggestionsEmpresasCmi, setSuggestionsEmpresasCmi] = useState([]);
  // resultado de empresas
  const [empresasCmi, setEmpresasCmi] = useState([]);
  //empresa seleccionada
  const [selectedEmpresaCmi, setSelectedEmpresaCmi] = useState({});
  // sucursals cmi
  const [sucursalsSelectedEmpresaCmi, setSucursalsSelectedEmpresaCmi] =
    useState([]);
  // boxes empresacmi
  const [cajasSelectedEmpresaCmi, setCajasSelectedEmpresaCmi] = useState([]);
  const [actualSucursalValue, setActualSucursalValue] = useState("none");
  // asignaciones cmi
  const [empresasAsignadasCmi, setEmpresasAsignadasCmi] = useState([]);

  // cmi (editar usuario)
  const [isEditMode, setIsEditMode] = useState(false);

  //component will mount
  useEffect(() => {
    get_sucursals();
  }, []);

  // cmi
  useEffect(() => {
    if (Object.entries(selectedEmpresaCmi).length > 0) {
      buscarEmpresaParaAsignar();
    }
  }, [selectedEmpresaCmi]);

  useEffect(() => {
    if (
      user.id_tipo_usuario_web_app === 4 ||
      user.id_tipo_usuario_web_app === 5
    ) {
      setGteRegional(4);
      getAsignaciones();
    }

    if (user.id_usuario != "") {
      setIsEditMode(true);
    }
  }, [user]);

  const resetForm = (sucursalId) => {
    if (props.history.location.state) {
      setNewUserForm(false);
      let userData = props.history.location.state;

      console.log("Datos del usuario para editar ->", userData);

      if (typeof userData.permisos[0] !== "number")
        userData.permisos.map(
          (permiso, index) => (userData.permisos[index] = permiso.estado)
        );

      setUser(userData);

      if (isAleko() && userData.tipo_nombre.toLowerCase() === "cajero") {
        setCurrentUser(3);
      }

      reset({
        nombre_contacto: userData.nombre,
        correo_electronico: userData.correo,
        contacto_telefono: userData.telefono,
        serie: userData.serie,
        id_sucursal: initialLoad ? userData.id_sucursal : sucursalId,
        tipo_usuario: userData.id_tipo_usuario_web_app,
        permiso1: userData.permisos[0],
        permiso2: userData.permisos[1],
        permiso3: userData.permisos[2],
        permiso4: userData.permisos[3],
        permiso5: userData.permisos[4],
        permiso6: userData.permisos[5],
        permiso7: userData.permisos[6],
        id_usuario: userData.id_usuario,
        id_caja_web_app: userData.id_caja,
      });
      setUserId(userData.id_new_user);
      setLoader(false);
      if (initialLoad) setInitialLoad(false);
    } else {
      setLoader(false);
    }
  };
  // console.log("user", user);
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmDeletion").modal("hide");
      $("#confirmExit").modal("hide");
    };
  }, []);

  useEffect(() => {
    getUserTypes();
  }, []);

  // set states
  const get_sucursals = () => {
    API.sucursal
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("getting these sucursals", response.data);
          setSucursals(response.data);
          if (props.history.location.state) {
            get_cajas({
              id_sucursal_bodega_ubicacion:
                props.history.location.state.id_sucursal,
            });
          } else {
            get_cajas({
              id_sucursal_bodega_ubicacion:
                response.data[0].id_sucursal_bodega_ubicacion,
            });
          }
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const save_data = (data) => {
    if ((isCmi() && gteRegional === 4) || currentUser === 5) {
      if (empresasAsignadasCmi.length <= 0) {
        toast.warning("Debes asignar, al menos, una empresa", {
          autoClose: 10000,
        });
        return null;
      }

      let idsAsignacionesParaGerente = getNewObjetoToGerente();
      console.log("data a enviar->", idsAsignacionesParaGerente);

      data.gerente_empresas = JSON.stringify(idsAsignacionesParaGerente);
      data.id_sucursal = sucursals[0].id_sucursal_bodega_ubicacion;
      data.id_caja_web_app = cajas[0].id_caja_web_app;
    }

    setSubmitLoader(true);

    if (isAleko()) {
      let permisosCajeroAleko = user.permisos;
      data.permisos = permisosCajeroAleko;
    } else {
      let permisos = [0, 0, 0, 0, 0, 0, 0];

      permisos[0] = data.permiso1 ? 1 : 0;
      permisos[1] = data.permiso2 ? 1 : 0;
      permisos[2] = data.permiso3 ? 1 : 0;
      permisos[3] = data.permiso4 ? 1 : 0;
      permisos[4] = data.permiso5 ? 1 : 0;
      permisos[5] = data.permiso6 ? 1 : 0;
      permisos[6] = data.permiso7 ? 1 : 0;

      data.permisos = permisos;
    }

    console.log("data to save", data);

    if (newUserForm) {
      save_new_user(data);
    } else {
      update_user(data);
    }
  };

  const save_new_user = (data) => {
    API.users
      .add_user(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("Usuario agregado con éxito", { autoClose: 10000 });
          history.push(`/app/users`);
        } else {
          if (response.message) {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.error("Ocurrió un error.", {
              autoClose: 10000,
            });
          }
        }
        setSubmitLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        let data = response.data;
        console.log(response);
        if (data.message) {
          toast.error(data.message, { autoClose: 10000 });
        } else {
          toast.error("Ha ocurrido un error al crear el nuevo usuario", {
            autoClose: 10000,
          });
        }
        setSubmitLoader(false);
      });
  };

  const update_user = (data) => {
    API.users
      .update_user(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(
            "Usuario actualizado con éxito. Por favor recarga la pagina para recargar los permisos de acceso.",
            { autoClose: 10000 }
          );
          history.push(`/app/users`);
        } else {
          if (response.message) {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.error("Ocurrió un error.", {
              autoClose: 10000,
            });
          }
        }
        setSubmitLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error al actualizar los parametros", {
          autoClose: 10000,
        });
        setSubmitLoader(false);
      });
  };
  const get_cajas = (data) => {
    API.cajas
      .getCajas(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Receiving these cajas", response.data);
          setCajas(response.data);
          resetForm(data.id_sucursal_bodega_ubicacion);
        }
      })
      .catch((err) => {
        console.log("err", err);
        /* let response = err.response;
				console.log(response);
				if (typeof response.data !== 'undefined') {
					toast.error(response.data.message, {
						autoClose: 10000
					});
				} else {
					toast.error('Ha ocurrido un error', { autoClose: 10000 });
				} */
      });
  };

  const changeSucursal = (e) => {
    get_cajas({ id_sucursal_bodega_ubicacion: e.target.value });
  };

  const getUserTypes = () => {
    API.users
      .getUserTypes()
      .then((res) => {
        let response = res.data;
        if (response.success && JSON.stringify(response.data) !== "[]") {
          console.log("users ->", response.data);
          setUserTypes(response.data);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const deleteUser = (id) => {
    API.users
      .deleteUsers({
        ids_users: id,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success("Usuario eliminado", {
            autoClose: 10000,
          });
          $("#confirmDeletion").modal("hide");
          history.push("/app/users");
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  // validaciones CMI

  // maneja el valor del input solamente
  const handleChangeSearchEmpresas = (value) => {
    setBuscarEmpresaCmi(value);
  };

  // muestra las sugerencias
  const handleSearchEmpresasCmi = async (nombre_empresa) => {
    try {
      const res = await API.users.getEmpresasCmi(nombre_empresa);

      if (!res.data.ok) {
        toast.warning("Error al buscar empresas", { autoClose: 10000 });
        return null;
      }

      setSuggestionsEmpresasCmi(
        res.data.data.map((item) => ({
          nombre: item.nombre,
        }))
      );
      setEmpresasCmi(res.data.data);
    } catch (error) {
      setSuggestionsEmpresasCmi([]);
      setEmpresasCmi([]);
      console.log("error al obtener empresas cmi", error);
    }
  };

  // filtra las sucursales por empresa
  const filtrarEmpresaParaAsignar = (sugerencia) => {
    console.log("Lista de empresas ->", empresasCmi);

    let empresaFiltrada = empresasCmi.filter(
      (item) => item.nombre == sugerencia.nombre
    );

    console.log("empresas filtradas ->", empresaFiltrada);

    setSelectedEmpresaCmi(empresaFiltrada[0]);

    setAsignacionesCmi({
      ...asignacionesCmi,
      empresa: empresaFiltrada[0].nombre,
      id_new_empresa: empresaFiltrada[0].id_new_empresa,
    });
  };

  const buscarEmpresaParaAsignar = async () => {
    try {
      let response = await API.sucursal.getSucursalsAndCajas(
        selectedEmpresaCmi.id_new_empresa
      );

      let data = await response.data.data;
      if (!data.length) {
        toast.warn("La empresa seleccionada no posee sucursales", {
          autoClose: 10000,
        });
      }
      setSucursalsSelectedEmpresaCmi(data);
      console.log("sucursales por empresa ->", data);
    } catch (error) {
      toast.warning("error al buscar los datos de la empresa seleccionada", {
        autoClose: 10000,
      });
      console.log(
        "error al buscar los datos de la empresa seleccionada",
        error
      );
    }
  };

  const handleAsignacionesCmi = (event) => {
    const { name, value } = event.target;

    let arraySucursalsAndCajas = [];

    if (name == "id_sucursal") {
      console.log("valor sucursal ->", value);
      setActualSucursalValue(value);

      if (value == "all") {
        sucursalsSelectedEmpresaCmi.forEach((itemSucursal) => {
          arraySucursalsAndCajas.push(itemSucursal);
        });

        setAsignacionesCmi({
          ...asignacionesCmi,
          sucursals: arraySucursalsAndCajas,
        });
      } else if (value === "none") {
        setCajasSelectedEmpresaCmi([]);
      } else {
        // filtramos las cajas por sucursal
        sucursalsSelectedEmpresaCmi.forEach((itemSucursal) => {
          if (itemSucursal.id_sucursal_bodega_ubicacion == value) {
            setAsignacionesCmi({
              ...asignacionesCmi,
              sucursals: [itemSucursal],
            });
            setCajasSelectedEmpresaCmi(itemSucursal.cajas);
          }
        });

        console.log("sucursales ->", asignacionesCmi.sucursals);
      }
    }

    if (name == "id_caja_web_app") {
      let cajaASignada = asignacionesCmi.sucursals[0].cajas.filter(
        (itemCaja) => itemCaja.id_caja_web_app == value
      );

      let copiaSucursal = asignacionesCmi.sucursals[0];
      copiaSucursal.cajas = cajaASignada;

      setAsignacionesCmi({
        ...asignacionesCmi,
        sucursals: [copiaSucursal],
      });
    }
  };

  const handleChangeSelectTipoUsuarioGerente = (value) => {
    value = Number(value);

    let permisosCopy = user.permisos;

    if (value == 4) {
      permisosCopy[0] = 1;
      permisosCopy[1] = 1;
      permisosCopy[2] = 1;
      permisosCopy[3] = 0;
      permisosCopy[4] = 1;
      permisosCopy[5] = 1;
      permisosCopy[6] = 0;
    } else if (value == 5) {
      permisosCopy[0] = 1;
      permisosCopy[1] = 1;
      permisosCopy[2] = 0;
      permisosCopy[3] = 0;
      permisosCopy[4] = 0;
      permisosCopy[5] = 0;
      permisosCopy[6] = 0;
    } else {
      permisosCopy[0] = 0;
      permisosCopy[1] = 0;
      permisosCopy[2] = 0;
      permisosCopy[3] = 0;
      permisosCopy[4] = 0;
      permisosCopy[5] = 0;
      permisosCopy[6] = 0;
    }

    setGteRegional((state) => value);
    setUser((state) => ({ permisos: permisosCopy }));
    setCurrentUser(value);
    // console.log("user ->", user);
  };

  const asignarGteRegional = (e) => {
    let valid = true;

    empresasAsignadasCmi.map((item) => {
      if (item.id_new_empresa == asignacionesCmi.id_new_empresa) {
        valid = false;
      }
    });

    if (asignacionesCmi.sucursals == undefined) {
      toast.warning("Debes agregar, al menos, una sucursal", {
        autoClose: 10000,
      });

      valid = false;
    }

    console.log("asignacionesCmi ->", asignacionesCmi);

    if (valid) {
      setEmpresasAsignadasCmi([...empresasAsignadasCmi, asignacionesCmi]);
      setBuscarEmpresaCmi("");
      setSucursalsSelectedEmpresaCmi([]);
      setCajasSelectedEmpresaCmi([]);
      toast.success("Empresa asignada con éxito", { autoClose: 10000 });

      setAsignacionesCmi({
        id_sucursal: "none",
      });
    } else {
      toast.warning(
        "Ha ocurrido un error al asignar empresa, verifique que no este aignada anteriormente.",
        {
          autoClose: 10000,
        }
      );
      console.log("error al agregar");
    }
  };

  const getAsignaciones = async () => {
    // empresas asignadas
    let data_empresas = user.empresas_gerente;
    // info de asignaciones
    let asignaciones = user.gerente_empresas;

    // empresas
    let idsEmpresasAsignadas = [];
    let nombresEmpresas = [];

    // sucursales
    let idsSucursales = [];
    let sucursalesTemporales = [];

    // cajas
    let idsCajas = [];
    let cajasTemporales = [];

    // ---------------------- //
    let data = {};
    let listaasignaciones = [];

    // ---------------------- //

    // buscar nombres de la empresa
    data_empresas.forEach((item) => {
      nombresEmpresas.push(item.empresa_gerente[0].nombre);
    });
    nombresEmpresas = [...new Set(nombresEmpresas)];

    // extraemos los ids de las empresas asignadas
    asignaciones.forEach((itemAsig) => {
      idsEmpresasAsignadas.push(itemAsig.id_new_empresa);
    });
    idsEmpresasAsignadas = [...new Set(idsEmpresasAsignadas)];

    // extraemos los ides de las sucursales asignadas
    asignaciones.forEach((itemAsig) => {
      idsSucursales.push(itemAsig.id_sucursal_bodega_ubicacion);
    });
    idsSucursales = [...new Set(idsSucursales)];

    // extraemos los ids de las cajas asignadas
    asignaciones.forEach((itemCaja) => {
      idsCajas.push(itemCaja.id_caja_web_app);
    });
    idsCajas = [...new Set(idsCajas)];

    // traemos las sucursalesTemporales por cada empresa
    for (const codEmpresa of Object.values(idsEmpresasAsignadas)) {
      const sucursal = await getSucursalByEmpresa(codEmpresa);

      sucursal.forEach((itemSucursal) => {
        idsSucursales.forEach((idSucursal) => {
          if (idSucursal === itemSucursal.id_sucursal_bodega_ubicacion) {
            cajasTemporales = [];

            idsCajas.forEach((idbox) => {
              itemSucursal.cajas.forEach((itemCaja) => {
                if (parseInt(idbox) == parseInt(itemCaja.id_caja_web_app)) {
                  console.log("itemCaja ->", itemCaja);
                  cajasTemporales.push(itemCaja);
                }
              });
            });

            itemSucursal.cajas = cajasTemporales;
            sucursalesTemporales.push(itemSucursal);
          }
        });
      });
    }

    // ingresamos la data al array final con su estructura
    nombresEmpresas.forEach((empresa, index) => {
      data = {
        empresa: empresa,
        id_new_empresa: idsEmpresasAsignadas[index],
        sucursals: sucursalesTemporales.filter(
          (item) => item.id_new_empresa === idsEmpresasAsignadas[index]
        ),
        //sucursals: sucursalesTemporales.map((item) => item),
      };
      listaasignaciones.push(data);
      data = {};
    });
    console.log(listaasignaciones);
    setEmpresasAsignadasCmi(listaasignaciones);
  };

  const getSucursalByEmpresa = async (id_empresa) => {
    let data = null;

    try {
      const res = await API.sucursal.getSucursalsAndCajas(id_empresa);
      data = await res.data.data;

      if (data != null) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error al obtener las sucursales por empresa", error);
    }
  };

  const handleDeleteEmpresaAsignaciones = (identificador) => {
    let EliminarAsignacion = empresasAsignadasCmi.filter(
      (item) => item.id_new_empresa != identificador
    );
    setEmpresasAsignadasCmi(EliminarAsignacion);
  };

  const getNewObjetoToGerente = () => {
    let idsAsignacionesGerente = [];
    let data = {};

    empresasAsignadasCmi.forEach((itemEmpresa) => {
      // itemEmpresa.id_new_empresa
      itemEmpresa.sucursals.forEach((itemSucursal) => {
        // itemSucursal.id_sucursal_bodega_ubicacion
        itemSucursal.cajas.forEach((itemCajas) => {
          // itemCajas.id_caja_web_app
          data.id_new_empresa = itemEmpresa.id_new_empresa;
          data.id_sucursal = itemSucursal.id_sucursal_bodega_ubicacion;
          data.id_caja_web_app = itemCajas.id_caja_web_app;
          idsAsignacionesGerente.push(data);
          data = {};
        });
      });
    });

    return idsAsignacionesGerente;
  };

  const handleCheckPermisos = (name, value) => {
    let permisosCopy = user.permisos;

    if (name == "permiso1") {
      permisosCopy[0] = value == 0 ? 1 : value == 1 && 0;
      setUser({ ...user, permisos: permisosCopy });
    }
    if (name == "permiso2") {
      permisosCopy[1] = value == 0 ? 1 : value == 1 && 0;
      setUser({ ...user, permisos: permisosCopy });
    }

    if (name == "permiso3") {
      permisosCopy[2] = value == 0 ? 1 : value == 1 && 0;
      setUser({ ...user, permisos: permisosCopy });
    }
    if (name == "permiso4") {
      permisosCopy[3] = value == 0 ? 1 : value == 1 && 0;
      setUser({ ...user, permisos: permisosCopy });
    }

    if (name == "permiso5") {
      permisosCopy[4] = value == 0 ? 1 : value == 1 && 0;
      setUser({ ...user, permisos: permisosCopy });
    }

    if (name == "permiso6") {
      permisosCopy[5] = value == 0 ? 1 : value == 1 && 0;
      setUser({ ...user, permisos: permisosCopy });
    }
    if (name == "permiso7") {
      permisosCopy[6] = value == 0 ? 1 : value == 1 && 0;
      setUser({ ...user, permisos: permisosCopy });
    }
  };

  // validaciones aleko
  const handleTipeUserAleko = (e) => {
    let { name, value } = e.target;

    value = Number(value);
    let permisosCopy = user.permisos;

    if (value === 3) {
      permisosCopy[0] = 0;
      permisosCopy[1] = 1;
      permisosCopy[2] = 0;
      permisosCopy[3] = 0;
      permisosCopy[4] = 0;
      permisosCopy[5] = 0;
      permisosCopy[6] = 1;
    } else {
      permisosCopy[0] = 0;
      permisosCopy[1] = 0;
      permisosCopy[2] = 0;
      permisosCopy[3] = 0;
      permisosCopy[4] = 0;
      permisosCopy[5] = 0;
      permisosCopy[6] = 0;
    }

    setUser((state) => ({ ...state, permisos: permisosCopy }));
    setCurrentUser(value);
  };
  console.log(user.permisos);
  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmDeletion" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea eliminar este usuario?</strong>
              </h4>
              <p>Esta opción no podrá deshacerse.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => deleteUser(userId)}
                >
                  Sí, eliminar usuario.
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={"/app/users"}
                >
                  Sí
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Usuarios</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Creación de usuario</strong>
        </h4>
      </div>

      <form
        className="content-card card mt-3"
        onSubmit={handleSubmit(save_data)}
      >
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Agregar un nuevo usuario</strong>
              </h5>
              <p className="mb-0">
                Añade un nuevo usuario y asigna los permisos administrativos.
              </p>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-2 delete-btn"
                variant="contained"
                color="primary"
                data-toggle="modal"
                data-target="#confirmExit"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1_blue mx-2"
                variant="contained"
                color="primary"
              >
                {submitLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Guardar"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Nombre del usuario </strong>
                </label>
                <Tooltip title="Indique un nombre de usuario. Ejemplo: John Doe">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  defaultValue={user.nombre}
                  className="w-100"
                  placeholder="Ingrese un nombre de usuario"
                  name="nombre_contacto"
                  required={true}
                  inputRef={register}
                />
              )}
              <input type="hidden" name="id_usuario" ref={register} />
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Correo electrónico de acceso</strong>
                </label>
                <Tooltip title="Ingrese un correo electrónico válido.">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  defaultValue={user.correo}
                  type="email"
                  className="w-100"
                  placeholder="Ingrese el correo electrónico del usuario"
                  name="correo_electronico"
                  required={true}
                  inputRef={register}
                />
              )}
            </div>
            {isCmi() ? (
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Número de teléfono</strong>
                  </label>
                  <Tooltip title="Indique un número telefónico válido">
                    <InfoIcon
                      style={{ color: " #828282", fontSize: "1.2rem" }}
                    />
                  </Tooltip>
                </div>
                {loader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Número de teléfono "
                    inputRef={register}
                    required={true}
                    name="contacto_telefono"
                    defaultValue={user.telefono}
                  />
                )}
              </div>
            ) : (
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Sucursal</strong>
                  </label>
                  <Tooltip title="Seleccionar una sucursal.">
                    <InfoIcon
                      style={{ color: " #828282", fontSize: "1.2rem" }}
                    />
                  </Tooltip>
                </div>
                <FormControl variant="outlined" className="w-100">
                  {loader ? (
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      color="white"
                    />
                  ) : (
                    <Select
                      className="cs-select1 select-w100"
                      inputRef={register}
                      required={true}
                      name="id_sucursal"
                      native
                      defaultValue={user.id_sucursal}
                      onChange={(event) => changeSucursal(event)}
                    >
                      <option value="">Seleccione un sucursal</option>
                      {sucursals.map((sucursal) => (
                        <option value={sucursal.id_sucursal_bodega_ubicacion}>
                          {sucursal.nombre_sucursal}
                        </option>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </div>
            )}

            {/* Second Row */}
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Contraseña de acceso</strong>
                </label>
                <Tooltip title="Ingrese una contraseña de al menos 8 caracteres que incluya mayúsculas, minúsculas, números y caracteres especiales para mayor seguridad.">
                  <InfoIcon style={{ color: " #828282", fontSize: "1.2rem" }} />
                </Tooltip>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  type="password"
                  className="w-100"
                  placeholder="Asigne una contraseña de acceso"
                  inputRef={register}
                  required={newUserForm ? true : false}
                  name="contrasena"
                />
              )}
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Confirme contraseña </strong>
                </label>
              </div>
              {loader ? (
                <CircularProgress size={26} className="mx-auto" color="white" />
              ) : (
                <TextField
                  name="password_repeat"
                  type="password"
                  className="w-100"
                  placeholder="Ingrese nuevamente la contraseña"
                  inputRef={register({
                    validate: (value) =>
                      value === password.current ||
                      "Las contraseña no coinciden",
                  })}
                />
              )}
              {errors.password_repeat && (
                <strong style={{ color: "red" }}>
                  {errors.password_repeat.message}
                </strong>
              )}
            </div>

            {isCmi() && (
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Número de serie de facturación</strong>
                  </label>
                  <Tooltip title="Ingrese un número de serie que lo identifique.">
                    <InfoIcon
                      style={{ color: " #828282", fontSize: "1.2rem" }}
                    />
                  </Tooltip>
                </div>
                {loader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  <TextField
                    defaultValue={user.serie}
                    className="w-100"
                    placeholder="Asigne un número de serie para este usuario"
                    inputRef={register}
                    required={true}
                    name="serie"
                  />
                )}
              </div>
            )}

            {isCmi() ? (
              ""
            ) : (
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Número de teléfono</strong>
                  </label>
                  <Tooltip title="Indique un número telefónico válido">
                    <InfoIcon
                      style={{ color: " #828282", fontSize: "1.2rem" }}
                    />
                  </Tooltip>
                </div>
                {loader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Número de teléfono "
                    inputRef={register}
                    required={true}
                    name="contacto_telefono"
                    defaultValue={user.telefono}
                  />
                )}
              </div>
            )}

            {/* Third Row */}
            {isCmi() ? (
              ""
            ) : (
              <>
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Asignar número de serie </strong>
                    </label>
                    <Tooltip title="Ingrese un número de serie que lo identifique.">
                      <InfoIcon
                        style={{ color: " #828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>
                  {loader ? (
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      color="white"
                    />
                  ) : (
                    <TextField
                      defaultValue={user.serie}
                      className="w-100"
                      placeholder="Asigne un número de serie para este usuario"
                      inputRef={register}
                      required={true}
                      name="serie"
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Asignar caja</strong>
                    </label>
                    <Tooltip title="Seleccione la caja para el vendedor">
                      <InfoIcon
                        style={{ color: " #828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>
                  <FormControl variant="outlined" className="w-100">
                    {loader ? (
                      <CircularProgress
                        size={26}
                        className="mx-auto"
                        color="white"
                      />
                    ) : (
                      <Select
                        native
                        className="cs-select1 select-w100"
                        inputRef={register}
                        required={true}
                        name="id_caja_web_app"
                        defaultValue={user.id_caja}
                      >
                        <option value="">Seleccione una caja</option>
                        {cajas.map((caja) => (
                          <option value={caja.id_caja_web_app}>
                            {caja.descripcion}
                          </option>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </div>
              </>
            )}
          </div>

          {isCmi() ? (
            <>
              <h5 className="mb-0 mt-5">
                <strong>Asignaciones</strong>
              </h5>
              <div className="col-md-8 row px-0">
                <div className="col-md-6 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Tipos de usuarios</strong>
                    </label>
                    <Tooltip title="Seleccione el tipo de usuario al que le asignará roles de usuarios.">
                      <InfoIcon
                        style={{ color: " #828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>
                  <FormControl variant="outlined" className="w-100">
                    {loader ? (
                      <CircularProgress
                        size={26}
                        className="mx-auto"
                        color="white"
                      />
                    ) : (
                      <Select
                        native
                        className="cs-select1 select-w100"
                        inputRef={register}
                        onChange={(e) =>
                          handleChangeSelectTipoUsuarioGerente(e.target.value)
                        }
                        required={true}
                        name="tipo_usuario"
                      >
                        <option value="">Seleccione el tipo de usuario</option>
                        {userTypes.map((type) => (
                          <option value={type.id_usuario_tipo_web_app}>
                            {type.descripcion}
                          </option>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </div>
              </div>
              {gteRegional === 4 || currentUser === 5 ? (
                <>
                  <div className="col-md-12 mt-3 row px-0">
                    <div className="col-md-4">
                      <div className="d-flex justify-content-between align-items-center my-1">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Empresas</strong>
                        </label>
                        <Tooltip title="Seleccione la caja para el vendedor">
                          <InfoIcon
                            style={{ color: " #828282", fontSize: "1.2rem" }}
                          />
                        </Tooltip>
                      </div>
                      <div className="d-flex">
                        <Autosuggest
                          inputProps={{
                            placeholder: "Busque las empresas a asignar",
                            autoComplete: "abcd",
                            name: "empresasToSearchCmi",
                            style: {
                              width: "99%",
                            },
                            value: buscarEmpresaCmi,
                            onChange: (_event, { newValue }) => {
                              handleChangeSearchEmpresas(newValue);
                            },
                          }}
                          suggestions={suggestionsEmpresasCmi}
                          onSuggestionsFetchRequested={({ value }) => {
                            if (!value) {
                              setSuggestionsEmpresasCmi([]);
                              return;
                            }
                            handleSearchEmpresasCmi(value);
                          }}
                          onSuggestionsClearRequested={() => {
                            setSuggestionsEmpresasCmi([]);
                          }}
                          // maneja la seleccion de las sugerencias
                          onSuggestionSelected={(
                            event,
                            { suggestion, method }
                          ) => {
                            if (method === "enter") {
                              event.preventDefault();
                            }
                            filtrarEmpresaParaAsignar(suggestion);
                          }}
                          getSuggestionValue={(suggestion) => suggestion.nombre}
                          renderSuggestion={(suggestion) => (
                            <div>{suggestion.nombre}</div>
                          )}
                        />

                        {/* <input
                          type="text"
                          name="empresasToSearchCmi"
                          onChange={handleSearchEmpresasCmi}
                          className="form-control"
                          placeholder="Busque las empresas a asignar."

                          input-group-append
                        /> */}
                        <div className="input-group-append search-bar">
                          <span
                            className="input-group-text"
                            style={{ backgroundColor: "#0160E7" }}
                          >
                            <SearchIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mt-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Sucursal</strong>
                        </label>
                        <Tooltip title="Seleccionar una sucursal.">
                          <InfoIcon
                            style={{ color: " #828282", fontSize: "1.2rem" }}
                          />
                        </Tooltip>
                      </div>
                      <FormControl variant="outlined" className="w-100">
                        {loader ? (
                          <CircularProgress
                            size={26}
                            className="mx-auto"
                            color="white"
                          />
                        ) : (
                          <Select
                            className="cs-select1 select-w100"
                            name="id_sucursal"
                            id="id_sucursal_cmi"
                            native
                            value={actualSucursalValue}
                            onChange={(event) => handleAsignacionesCmi(event)}
                            disabled={!sucursalsSelectedEmpresaCmi.length}
                          >
                            <option selected="selected" value="none">
                              Seleccione sucursal
                            </option>
                            <option value="all">Seleccionar todo</option>
                            {sucursalsSelectedEmpresaCmi.map((sucursal) => (
                              <option
                                value={sucursal.id_sucursal_bodega_ubicacion}
                              >
                                {sucursal.nombre_sucursal}
                              </option>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </div>
                    <div className="col-md-3 mt-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Caja para facturación</strong>
                        </label>
                        <Tooltip title="Seleccione la caja para el vendedor">
                          <InfoIcon
                            style={{ color: " #828282", fontSize: "1.2rem" }}
                          />
                        </Tooltip>
                      </div>
                      <FormControl variant="outlined" className="w-100">
                        {loader ? (
                          <CircularProgress
                            size={26}
                            className="mx-auto"
                            color="white"
                          />
                        ) : (
                          <Select
                            native
                            className="cs-select1 select-w100"
                            name="id_caja_web_app"
                            value={asignacionesCmi.id_caja}
                            onChange={(e) => handleAsignacionesCmi(e)}
                            disabled={
                              cajasSelectedEmpresaCmi.length <= 0 ||
                              actualSucursalValue === "none"
                                ? true
                                : false
                            }
                          >
                            <option value="">Seleccione caja</option>
                            {cajasSelectedEmpresaCmi.map((caja) => (
                              <option value={caja.id_caja_web_app}>
                                {caja.descripcion}
                              </option>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </div>
                    <div className="col-md-2 mt-2 d-flex justify-content-center align-items-center">
                      <Button
                        className="btn-cs1_blue"
                        variant="contained"
                        color="primary"
                        onClick={asignarGteRegional}
                      >
                        {submitLoader ? (
                          <CircularProgress
                            size={26}
                            className="w-100 mx-auto"
                            color="white"
                          />
                        ) : (
                          "Asignar"
                        )}
                      </Button>
                    </div>
                  </div>
                  <div
                    className="mx-auto mh-100 mt-3 p-0"
                    style={{ height: "auto" }}
                  >
                    {empresasAsignadasCmi.length > 0 ? (
                      <ShowEmpresasAsignadas
                        empresas={empresasAsignadasCmi}
                        handleDeleteEmpresaAsignaciones={
                          handleDeleteEmpresaAsignaciones
                        }
                      />
                    ) : (
                      <img
                        src="images/NotEnterprise.svg"
                        alt="notEnterprise"
                        className="w-100 h-50"
                      />
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {isCmi() &&
            (gteRegional === 4 || currentUser === 5 ? (
              ""
            ) : (
              <div className="col-md-8 row px-0 mt-3">
                <div className="col-md-6 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Sucursal</strong>
                    </label>
                    <Tooltip title="Seleccionar una sucursal.">
                      <InfoIcon
                        style={{ color: "#828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>
                  <FormControl variant="outlined" className="w-100">
                    {loader ? (
                      <CircularProgress
                        size={26}
                        className="mx-auto"
                        color="white"
                      />
                    ) : (
                      <Select
                        className="cs-select1 select-w100"
                        inputRef={register}
                        required={true}
                        name="id_sucursal"
                        native
                        defaultValue={user.id_sucursal}
                        onChange={(event) => changeSucursal(event)}
                      >
                        <option value="">Seleccione un sucursal</option>
                        {sucursals.map((sucursal) => (
                          <option value={sucursal.id_sucursal_bodega_ubicacion}>
                            {sucursal.nombre_sucursal}
                          </option>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Asignar caja</strong>
                    </label>
                    <Tooltip title="Seleccione la caja para el vendedor">
                      <InfoIcon
                        style={{ color: " #828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>
                  <FormControl variant="outlined" className="w-100">
                    {loader ? (
                      <CircularProgress
                        size={26}
                        className="mx-auto"
                        color="white"
                      />
                    ) : (
                      <Select
                        native
                        className="cs-select1 select-w100"
                        inputRef={register}
                        required={true}
                        name="id_caja_web_app"
                        defaultValue={user.id_caja}
                      >
                        <option value="">Seleccione una caja</option>
                        {cajas.map((caja) => (
                          <option value={caja.id_caja_web_app}>
                            {caja.descripcion}
                          </option>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </div>
              </div>
            ))}

          <h5 className="mb-0 mt-5">
            <strong>Permisos</strong>
          </h5>
          <p className="mb-0">
            Personalice los permisos de los usuarios o asigne los permisos por
            defecto.
          </p>
          <hr />

          <div className="row mt-4">
            {isCmi() ? (
              ""
            ) : (
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Tipos de usuario</strong>
                  </label>
                  <Tooltip title="Seleccione el tipo de usuario al que le asignará roles de usuarios.">
                    <InfoIcon
                      style={{ color: " #828282", fontSize: "1.2rem" }}
                    />
                  </Tooltip>
                </div>
                <FormControl variant="outlined" className="w-100">
                  {loader ? (
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      color="white"
                    />
                  ) : (
                    <Select
                      native
                      className="cs-select1 select-w100"
                      inputRef={register}
                      required={true}
                      name="tipo_usuario"
                      onChange={handleTipeUserAleko}
                    >
                      <option value="">Seleccione el tipo de usuario</option>
                      {userTypes.map((type) => (
                        <option value={type.id_usuario_tipo_web_app}>
                          {type.descripcion}
                        </option>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </div>
            )}
            <div className="col-md-4 mt-3">
              <p className="mb-0" style={{ color: "#4F4F4F" }}>
                Puede asignar los permisos predeterminado para cada tipo de
                usuario, o personalizar los permisos para cada usuario.
              </p>
            </div>
            {isCmi() ? (
              ""
            ) : (
              <div className="col-md-4 mt-3 text-right">
                <Button
                  className="btn-cs1 mx-2 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmDeletion"
                >
                  Eliminar usuario
                </Button>
              </div>
            )}

            {/* Second row */}

            {isAleko() && currentUser === 3 ? (
              <FormRegister user={user} />
            ) : (
              <Fragment>
                <div className="col-md-4 mt-3">
                  <div className="row h-100">
                    <div className="col-8">
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        <strong>Dashboard</strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        El usuario podrá consultar las estadísticas y toda la
                        información del Dashboard
                      </p>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <label
                        class="toggle-control"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          ref={register}
                          name="permiso1"
                          defaultChecked={user.permisos[0]}
                          disabled={
                            (isCmi() && gteRegional === 4) || currentUser === 5
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleCheckPermisos("permiso1", user.permisos[0])
                          }
                          checked={user.permisos[0]}
                        />
                        <span class="control" />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="row h-100">
                    <div className="col-8">
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        <strong>Inventario</strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        El usuario podrá consultar los productos del inventario,
                        realizar ingresos y egresos
                      </p>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <label
                        class="toggle-control"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          ref={register}
                          name="permiso2"
                          defaultChecked={user.permisos[1]}
                          checked={user.permisos[1]}
                          onChange={(e) =>
                            handleCheckPermisos("permiso2", user.permisos[1])
                          }
                          disabled={
                            (isCmi() && gteRegional === 4) || currentUser === 5
                              ? true
                              : false
                          }
                        />
                        <span class="control" />
                      </label>
                    </div>
                  </div>
                </div>
                {/* Third row */}

                <div className="col-md-4 mt-3">
                  <div className="row h-100">
                    <div className="col-8">
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        <strong>Clientes</strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        El usuario podrá consultar la base de datos con los
                        clientes, liquidar anticipos y cancelar facturas a
                        crédito
                      </p>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <label
                        class="toggle-control"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          ref={register}
                          name="permiso3"
                          defaultChecked={user.permisos[2]}
                          disabled={
                            (isCmi() && gteRegional === 4) || currentUser === 5
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleCheckPermisos("permiso3", user.permisos[2])
                          }
                          checked={user.permisos[2]}
                        />
                        <span class="control" />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="row h-100">
                    <div className="col-8">
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        <strong>Traslados</strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        El usuario podrá emitir y administrar traslados
                        entrantes y salientes.
                      </p>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <label
                        class="toggle-control"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          ref={register}
                          name="permiso4"
                          defaultChecked={user.permisos[3]}
                          disabled={
                            (isCmi() && gteRegional === 4) || currentUser === 5
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleCheckPermisos("permiso4", user.permisos[3])
                          }
                        />
                        <span class="control" />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="row h-100">
                    <div className="col-8">
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        <strong>Usuarios</strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        El usuario podrá administrar y añadir nuevos usuarios en
                        su sistema Cloud
                      </p>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <label
                        class="toggle-control"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          ref={register}
                          name="permiso5"
                          defaultChecked={user.permisos[4]}
                          disabled={
                            (isCmi() && gteRegional === 4) || currentUser === 5
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleCheckPermisos("permiso5", user.permisos[4])
                          }
                          checked={user.permisos[4]}
                        />
                        <span class="control" />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="row h-100">
                    <div className="col-8">
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        <strong>Configuración</strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        El usuario podrá administrar la información de su
                        empresa, sucursales, formato de facturas...
                      </p>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <label
                        class="toggle-control"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          ref={register}
                          name="permiso6"
                          defaultChecked={user.permisos[5]}
                          checked={user.permisos[5]}
                          disabled={
                            (isCmi() && gteRegional === 4) || currentUser === 5
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleCheckPermisos("permiso6", user.permisos[5])
                          }
                        />
                        <span class="control" />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="row h-100">
                    <div className="col-8">
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        <strong>POS</strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: "0.85rem" }}>
                        El usuario podrá facturar, consultar operaciones y
                        cortes de caja.
                      </p>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <label
                        class="toggle-control"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          ref={register}
                          name="permiso7"
                          defaultChecked={user.permisos[6]}
                          disabled={
                            (isCmi() && gteRegional === 4) || currentUser === 5
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleCheckPermisos("permiso7", user.permisos[6])
                          }
                        />
                        <span class="control" />
                      </label>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>

        {isCmi() && isEditMode ? (
          <div className="col-md-12 mb-4">
            <div
              className="col-8 d-flex justify-content-between align-items-center"
              style={{
                backgroundColor: "#f2f3f3",
              }}
            >
              <div className="col-8 py-4">
                <h5 className="mb-0">
                  <strong>Eliminar Usuario</strong>
                </h5>
                <p className="mb-0">
                  Esta opción es irreversible, y le permitirá eliminar el acceso
                  del usuario al sistema.
                </p>
              </div>
              <div className="">
                <Button
                  className="btn-cs1 mx-2 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmDeletion"
                >
                  Eliminar usuario
                </Button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </form>
    </div>
  );
}
