import React, { useState, useEffect, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { base_url } from "../../api";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory, Link } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import DataTable from "react-data-table-component";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { CSVLink } from "react-csv";
import $ from "jquery";
import Autosuggest from "react-autosuggest";
import { isAleko } from "../../helpers/isAleko";
import { isCmi } from "../../helpers/isCmi";

// styles
import "./profile.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddBoxIcon from "@material-ui/icons/AddBox";
import HomeIcon from "@material-ui/icons/Home";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import Analytics from "./Analytics";
import { getMoneda } from "../../helpers/moneda";
import { formaterPriceFunction } from "../../helpers/formater";

var qs = require("qs");

// components

function TabPanel(props) {
  const { children, value, index, analytics, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tab-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={analytics ? 0 : 3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//data

const payment_periods = [
  {
    id: 1,
    option: "Pago único",
    value: "0",
  },
  {
    id: 2,
    option: "3 meses",
    value: "3",
  },
  {
    id: 3,
    option: "6 meses",
    value: "6",
  },
  {
    id: 4,
    option: "12 meses",
    value: "12",
  },
  {
    id: 5,
    option: "18 meses",
    value: "18",
  },
  {
    id: 6,
    option: "24 meses",
    value: "24",
  },
  {
    id: 7,
    option: "36 meses",
    value: "36",
  },
];

export default function Profile(props) {
  const moneda = getMoneda();
  const AutosuggestHighlightMatch = require("autosuggest-highlight/match");
  const AutosuggestHighlightParse = require("autosuggest-highlight/parse");
  const headers = () => {
    return { Authorization: "Bearer " + localStorage.getItem("token") };
  };
  let history = useHistory();
  const moment = require("moment");
  let default_dates = [
    new Date(new Date().setDate(new Date().getDate() - 7)),
    new Date(),
  ];
  const { register, handleSubmit } = useForm();
  // states

  const [dateRange, setDateRange] = useState(default_dates);
  const [loader, setLoader] = useState(false);
  const [operationsTab, setOperationsTab] = useState(0);
  const [opsOrderType, setOrderType] = useState("todo");
  const [client, setClient] = useState({});
  const [clientLoader, setClientLoader] = useState(true);
  const [salesLoader, setSalesLoader] = useState(true);
  const [advancesLoader, setAdvancesLoader] = useState(true);
  const [creditsLoader, setCreditsLoader] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [clientSales, setClientSales] = useState([]);
  const [filteredClientSales, setFilteredClientSales] = useState([]);
  const [salesFilterText, setSalesFilterText] = useState("");
  const [clientCredits, setClientCredits] = useState([]);
  const [filteredClientCredits, setFilteredClientCredits] = useState([]);
  const [creditsFilterText, setCreditsFilterText] = useState("");
  const [clientAdvances, setClientAdvances] = useState([]);
  const [filteredClientAdvances, setFilteredClientAdvances] = useState([]);
  const [completeClientAdvances, setCompleteClientAdvances] = useState([]);
  const [completeClientCredits, setCompleteClientCredits] = useState([]);
  const [advancesFilterText, setAdvancesFilterText] = useState("");
  const [allOperations, setAllOperations] = useState([]);
  const [filteredAllOperations, setFilteredAllOperations] = useState([]);
  const [allOpsFilterText, setAllOpsFilterText] = useState("");
  const [allOpsInfo, setAllOpsInfo] = useState({});
  const [allOperationsLoader, setAllOperationsLoader] = useState(true);
  const [clientAdvancesLoader, setClientAdvancesLoader] = useState(true);
  const [clientCreditsLoader, setClientCreditsLoader] = useState(true);
  const [creditNotes, setCreditNotes] = useState([]);
  const [cancelledSales, setCancelledSales] = useState([]);
  const [selectedOperations, setSelectedOperations] = useState([]);

  const [paidByGiftCard, setPaidByGiftCard] = useState(0);
  const [paidByCard, setPaidByCard] = useState(0);
  const [paidByCash, setPaidByCash] = useState(0);
  const [selectedMethods, setSelectedMethods] = useState([0]);
  const [periods, setPeriods] = useState(payment_periods[0].value);
  const [invoice_number, setInvoiceNumber] = useState("");
  const [giftcard_code, setGiftCardCode] = useState("");
  const [additional_invoice, setAdditionalInvoice] = useState("");
  const [additional_invoices, setAdditionalInvoices] = useState([]);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [saldarFacturaLoader, setSaldarFacturaLoader] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState({});
  const [selectedAdvances, setSelectedAdvances] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(0);
  const [allProducts, setAllProducts] = useState([]);
  const [productFilterText, setProductFilterText] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [salesCost, setSalesCost] = useState({
    total: 0,
    subtotal: 0,
    iva: 0,
  });
  const [suggestions, setSuggestions] = useState([]);
  const [debtPaymentType, setDebtPaymentType] = useState("");
  const [partialPayment, setPartialPayment] = useState(0);
  const [selectedDuesToPay, setSelectedDuesToPay] = useState([]);
  const [priceLevels, setPriceLevels] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [clearRows, setClearRows] = useState(false);

  const [nivelPrecio, setNivelPrecio] = useState([]);
  const [nivelPriceSelected, setNivelPriceSelected] = useState(1);
  const [habilitaClienteCF, setHabilitaClienteCF] = useState(false);
  const [add_credito_cmi, setAdd_credito_cmi] = useState(false);

  //price formater
  const [precioValue, setPrecioValue] = useState("");

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    // console.log(newValue)
    return newValue;
  };

  useEffect(() => {
    console.log("aqui", client.limite_credito);
    if (client.limite_credito) {
      setPrecioValue(formaterPriceFunction(client.limite_credito + ""));
    }
  }, [client]);

  const handleTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#saldar_factura").modal("hide");
      $("#liquidar_anticipo").modal("hide");
    };
  }, []);

  useEffect(() => {
    getPriceLevels();
    if (props.history.location.state) {
      if (props.history.location.state.id_cliente_web_app) {
        let id = props.history.location.state.id_cliente_web_app;
        getClient(true, id);
        getClientAdvances(id);
        getClientCredits(id);
      }
    } else {
      getClient(true, null);
    }
    getProducts();
    getNivelesCliente();
  }, []);

  useEffect(() => {
    console.log("Why am i being set?", allProducts);
  }, [allProducts]);

  // methods

  const handleProductTabs = (event, newValue) => {
    setOperationsTab(newValue);
  };

  const getClient = (initial_load, received_id) => {
    if (received_id) {
      queryAll(default_dates, received_id);
    }
    let nit = props.match.params.nit.toString();
    API.clients
      .searchClient({ search_text: nit })
      .then((res) => {
        let response = res.data;

        if (response.success) {
          console.log("Datos del cliente", response.data);

          if (response?.data?.dias_credito > 0) {
            setAdd_credito_cmi(true);
          }

          // if (response.data.email === null) {
          //   setHabilitaClienteCF(true);
          // } else {
          //   setHabilitaClienteCF(false);
          // }

          setNivelPriceSelected(response.data.id_nivel_precio);

          if (response.data.nit.toLowerCase() === "cf") {
            setHabilitaClienteCF(true);
            response.data.nombrecf = "Consumidor Final";
          }

          setClient(response.data);

          setPrecioValue(response.data.limite_credito);

          if (initial_load && !received_id) {
            queryAll(default_dates, response.data.id_cliente_web_app);
            getClientAdvances(response.data.id_cliente_web_app);
            getClientCredits(response.data.id_cliente_web_app);
          }
        } else {
          console.log("message error ->", response.message);

          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
        setClientLoader(false);
      })
      .catch((err) => {
        console.log("message error al buscar cliente ->", err);

        setClientLoader(false);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  //console.log({ habilitaClienteCF })

  const updateClient = (data) => {
    if (!isCmi()) {
      data.limit = data.limit.replace(",", "");
      data.client_id = client.id_cliente_web_app;
    }

    setSubmitLoader(true);

    data.nombre = habilitaClienteCF && isCmi() ? client.nombrecf : data.nombre;
    data.direccion = data.direccion ? data.direccion : null;
    //data.nombre_cliente_final =
    data.limit = !isCmi() ? Number(data.limit) : 0;
    data.limite_credito = isCmi() ? Number(data.limite_credito) : 0;
    data.client_id = client.id_cliente_web_app;
    data.id_cliente_web_app = client.id_cliente_web_app;
    data.id_new_empresa = client.id_new_empresa;
    data.client_code = habilitaClienteCF ? data.client_code : data.nit;
    data.diferenciacion = habilitaClienteCF
      ? "individual"
      : data.diferenciacion
      ? data.diferenciacion
      : "individual";

    data.id_nivel_precio = data.id_nivel_precio
      ? Number(data.id_nivel_precio)
      : 0;

    data.dias_credito = !isNaN(Number(data.dias_credito))
      ? Number(data.dias_credito)
      : 0;
    data.email =
      (habilitaClienteCF && isCmi()) || data.email === null ? null : data.email;
    data.telefono = data.telefono === "" ? null : data.telefono;

    console.log("data to send ->", data);
    API.clients
      .updateClient(data)
      .then((res) => {
        let response = res.data;

        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });

          // console.log("editar cliente ->", response);

          // setClient({
          //   nombre: data.nombre,
          //   direccion: data.direccion,
          //   dias_credito: data.dias_credito,
          //   limite_credito: data.limit,
          //   nit: data.nit,
          //   id_cliente_web_app: client.id_cliente_web_app,
          //   email: data.email,
          // })
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
        setSubmitLoader(false);
      })
      .catch((err) => {
        let response = err?.response ? err.response : err;
        console.log("error al editar ->", response);

        if (response?.data?.msg) {
          toast.error(response.data.msg, { autoClose: 10000 });
          setSubmitLoader(false);
        } else {
          toast.error("Ha ocurrido un error desconocido.", {
            autoClose: 10000,
          });
          setSubmitLoader(false);
        }
      });
  };

  const salesAction = (event, sale) => {
    if (event.target.value === "detalles") {
      localStorage.setItem(
        "fromExternal",
        JSON.stringify({
          client,
          identificador: sale.identificador,
        })
      );
      const newWindow = window.open(
        "/#/app/vender/resumen/" + sale.id_pos_movimiento,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const queryAll = (dates, id_cliente) => {
    setCreditsLoader(true);
    setAdvancesLoader(true);
    setSalesLoader(true);
    setAllOperationsLoader(true);
    axios
      .all([
        axios.post(
          `${base_url}/web/get-client-credits`,
          qs.stringify({
            from_date: moment(dates[0]).format("YYYY-MM-DD"),
            to_date: moment(dates[1]).format("YYYY-MM-DD"),
            id_cliente_web_app: id_cliente,
          }),
          { headers: headers() }
        ),

        axios.post(
          `${base_url}/web/get-client-advances`,
          qs.stringify({
            from_date: moment(dates[0]).format("YYYY-MM-DD"),
            to_date: moment(dates[1]).format("YYYY-MM-DD"),
            id_cliente_web_app: id_cliente,
          }),
          { headers: headers() }
        ),

        axios.post(
          `${base_url}/web/get-client-sales`,
          qs.stringify({
            from_date: moment(dates[0]).format("YYYY-MM-DD"),
            to_date: moment(dates[1]).format("YYYY-MM-DD"),
            id_cliente_web_app: id_cliente,
          }),
          { headers: headers() }
        ),

        axios.post(
          `${base_url}/web/get-client-credit-notes`,
          qs.stringify({
            from_date: moment(dates[0]).format("YYYY-MM-DD"),
            to_date: moment(dates[1]).format("YYYY-MM-DD"),
            id_cliente_web_app: id_cliente,
          }),
          { headers: headers() }
        ),

        axios.post(
          `${base_url}/web/get-client-cancelled-sales`,
          qs.stringify({
            from_date: moment(dates[0]).format("YYYY-MM-DD"),
            to_date: moment(dates[1]).format("YYYY-MM-DD"),
            id_cliente_web_app: id_cliente,
          }),
          { headers: headers() }
        ),
      ])
      .then(
        axios.spread((...responses) => {
          console.log("RECEIVED THESE RESPONSES", responses);
          //Process credits
          let rCredits = responses[0].data.data;
          setClientCredits(rCredits);
          setFilteredClientCredits(rCredits);
          setCreditsLoader(false);

          //Process advances
          let rAdvances = responses[1].data.data;
          setClientAdvances(rAdvances);
          setFilteredClientAdvances(rAdvances);
          setAdvancesLoader(false);
          //Process sales
          let rSales = responses[2].data.data;
          setClientSales(rSales);
          setFilteredClientSales(rSales);
          setSalesLoader(false);

          //Process credit notes
          let rCreditNotes = responses[3].data.data;
          setCreditNotes(rCreditNotes);

          let rCancelledSales = responses[4].data.data;
          setCancelledSales(rCancelledSales);

          initializeAllOperations(
            rCredits,
            rAdvances,
            rSales,
            rCreditNotes,
            rCancelledSales
          );
        })
      )
      .catch((errors) => {
        console.log("Errors in axios.all:", errors);
        toast.error("Ha ocurrido un error al recibir los datos", {
          autoClose: 10000,
        });
      });
  };

  const initializeAllOperations = (
    rCredits,
    rAdvances,
    rSales,
    rCreditNotes,
    rCancelledSales
  ) => {
    let all_operations = [];
    rSales.map((sale) => {
      all_operations.push({
        numero_operacion: sale.identificador,
        tipo_operacion: "Venta de producto",
        fecha: sale.fecha,
        numero_productos: sale.orderItems.length,
        monto: sale.monto_total,
      });
    });
    rAdvances.map((anticipo) => {
      all_operations.push({
        numero_operacion: anticipo.nro_anticipo,
        tipo_operacion: "Anticipo",
        fecha: anticipo.fecha,
        numero_productos: "N/A",
        monto: anticipo.monto,
      });
    });
    rCredits.map((credito) => {
      all_operations.push({
        numero_operacion: credito.identificador,
        tipo_operacion: "Factura de crédito",
        fecha: credito.fecha,
        numero_productos: credito.nro_productos,
        monto: credito.monto,
      });
    });
    rCreditNotes.map((nota) => {
      all_operations.push({
        numero_operacion: nota.identificador,
        tipo_operacion: "Nota de crédito",
        fecha: nota.fecha,
        numero_productos:
          nota.orderItems.length > 0 ? nota.orderItems.length : "Total",
        monto: nota.monto,
      });
    });
    rCancelledSales.map((sale) => {
      all_operations.push({
        numero_operacion: sale.identificador,
        tipo_operacion: "Anulación de venta",
        fecha: sale.fecha,
        numero_productos: sale.orderItems.length,
        monto: sale.monto_total,
      });
    });
    setAllOperations(all_operations);
    setFilteredAllOperations(all_operations);
    setAllOpsInfo({
      operaciones: all_operations.length,
      ventas: rSales.length,
      creditos: rCredits.length,
      anticipos: rAdvances.length,
      notas: rCreditNotes.length,
      anuladas: rCancelledSales.length,
    });
    setAllOperationsLoader(false);
  };

  const filterAllOperations = (e) => {
    if (e.target.value === "") {
      setAllOpsFilterText(e.target.value);
      setFilteredAllOperations(allOperations);
    } else {
      setAllOpsFilterText(e.target.value);
      setFilteredAllOperations(
        allOperations.filter((item) =>
          item.numero_operacion
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const filterSales = (e) => {
    setSalesFilterText(e.target.value);
    if (e.target.value === "") {
      setFilteredClientSales(clientSales);
    } else {
      setFilteredClientSales(
        clientSales.filter((item) =>
          item.identificador
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const filterCredits = (e) => {
    setCreditsFilterText(e.target.value);
    if (e.target.value === "") {
      setFilteredClientCredits(clientCredits);
    } else {
      setFilteredClientCredits(
        clientCredits.filter((item) =>
          item.identificador
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const filterAdvances = (e) => {
    setAdvancesFilterText(e.target.value);
    if (e.target.value === "") {
      setFilteredClientAdvances(clientAdvances);
    } else {
      setFilteredClientAdvances(
        clientAdvances.filter((item) =>
          item.nro_anticipo
            .toLowerCase()
            .toString()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const anticiposAction = (event, row) => {
    if (event.target.value === "detalles") {
      history.push({
        pathname: "/app/anticipo/resumen",
        state: {
          id_cliente_acreedor_web_app: row.id_cliente_acreedor_web_app,
        },
      });
    }
    if (event.target.value === "liquidar") {
      setSelectedAdvances([row]);
      $("#liquidar_anticipo").modal();
      setClearRows(false);
    }
  };

  const operationsActions = (element) => {
    if (
      element.tipo_operacion.toLowerCase().includes("venta") ||
      element.tipo_operacion.toLowerCase().includes("anulación")
    ) {
      const currentSale = clientSales.find(
        (x) => x.identificador === element.numero_operacion
      );
      const newWindow = window.open(
        "/#/app/vender/resumen/" + currentSale.id_pos_movimiento,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
    if (element.tipo_operacion.toLowerCase().includes("anticipo")) {
      let currentAdvance = clientAdvances.find(
        (x) => x.fecha === element.fecha
      );
      // THIS PRIOR LINE HAS TO CHANGE
      if (currentAdvance) {
        history.push({
          pathname: "/app/anticipo/resumen",
          state: {
            id_cliente_acreedor_web_app:
              currentAdvance.id_cliente_acreedor_web_app,
          },
        });
      } else {
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      }
    }
    if (element.tipo_operacion.toLowerCase().includes("nota")) {
      let currentNota = creditNotes.find(
        (x) => x.identificador === element.numero_operacion
      );
      history.push({
        pathname:
          "/app/operaciones_historial/nota_credito/" +
          currentNota.id_nota_credito_web_app,
      });
    }
  };

  const handleDateChange = (value) => {
    setDateRange(value);
    if (value) {
      let currentDates = [
        moment(value[0]).format("YYYY-MM-DD"),
        moment(value[1]).format("YYYY-MM-DD"),
      ];
      setCreditsLoader(true);
      setAdvancesLoader(true);
      setSalesLoader(true);
      setAllOperationsLoader(true);
      queryAll(currentDates, client.id_cliente_web_app);
    }
  };

  const classifyOps = (event) => {
    let value = event.target.value;

    if (value === "todo") {
      setFilteredAllOperations(allOperations);
    }
    if (value === "ventas") {
      setFilteredAllOperations(
        allOperations.filter((x) =>
          x.tipo_operacion.toLowerCase().includes("venta")
        )
      );
    }
    if (value === "creditos") {
      setFilteredAllOperations(
        allOperations.filter(
          (x) => x.tipo_operacion.toLowerCase() === "factura de crédito"
        )
      );
    }
    if (value === "anticipos") {
      setFilteredAllOperations(
        allOperations.filter((x) =>
          x.tipo_operacion.toLowerCase().includes("anticipo")
        )
      );
    }
    if (value === "notas") {
      setFilteredAllOperations(
        allOperations.filter(
          (x) => x.tipo_operacion.toLowerCase() === "nota de crédito"
        )
      );
    }
    if (value === "anulación") {
      setFilteredAllOperations(
        allOperations.filter((x) =>
          x.tipo_operacion.toLowerCase().includes("anulación")
        )
      );
    }
    setOrderType(value);
  };

  const getClientAdvances = (clientId) => {
    setClientAdvancesLoader(true);
    API.clients
      .getClientAllAnticipos({ id_cliente_web_app: clientId })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setClientAdvancesLoader(false);
          setCompleteClientAdvances(response.data);
        } else {
          toast.error(response.message, { autoClose: 10000 });
          setClientAdvancesLoader(false);
        }
      })
      .catch((err) => {
        setClientAdvancesLoader(false);
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getClientCredits = (clientId) => {
    API.clients
      .getClientAllDebts({ id_cliente_web_app: clientId })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setClientCreditsLoader(false);
          setCompleteClientCredits(response.data);
          setSelectedDuesToPay([]);
          setClearRows(true);
        } else {
          setClientCreditsLoader(false);
          toast.error(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        let response = err.response;
        setClientCreditsLoader(false);
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;
    setSelectedOperations(selectedRows);
  };

  const handleAdvancesSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;
    console.log(selectedRows);
    setSelectedAdvances(selectedRows);
  };

  const handleDuesSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;

    setSelectedDuesToPay(selectedRows);
  };

  const handleBoxClick = (x) => {
    let chosenMethods = [...selectedMethods];
    if (x === 4) {
      // If user clicks on the 'multiples' button
      if (chosenMethods.includes(4)) {
        // If multiples was previously selected
        $(".payment-method-box").each(function () {
          if ($(this).hasClass("active-box")) {
            $(this).removeClass("active-box");
          }
        });
        setSelectedMethods([0]);
      } else {
        // If user clicks multiples for the first time
        $(".payment-method-box").each(function () {
          if ($(this).hasClass("active-box")) {
            $(this).removeClass("active-box");
          }
        });
        let currentElement = "box-" + x;
        $(`#${currentElement}`).addClass("active-box");
        setSelectedMethods([4]);
      }
    } else {
      /* if (chosenMethods.includes(4)) {
                let currentElement = "box-" + x;
                if (chosenMethods.includes(x)) {
                    $(`#${currentElement}`).removeClass("active-box");
                    setSelectedMethods(
                        chosenMethods.filter((value) => value !== x)
                    );
                    switch (x) {
                        case 1:
                            setPaidByCash("");
                            break;
                        case 2:
                            setPaidByCard("");
                            break;
                        case 3:
                            setPaidByGiftCard("");
                            break;
                        default:
                            break;
                    }
                } else {
                    $(`#${currentElement}`).addClass("active-box");
                    chosenMethods.push(x);
                    setSelectedMethods(chosenMethods);
                }
            } else { */
      $(".payment-method-box").each(function () {
        if ($(this).hasClass("active-box")) {
          $(this).removeClass("active-box");
        }
      });
      let currentElement = "box-" + x;
      $(`#${currentElement}`).addClass("active-box");
      setSelectedMethods([x]);
      switch (x) {
        case 1:
          setPaidByCard(0);
          setPaidByGiftCard(0);
          break;
        case 2:
          setPaidByGiftCard(0);
          setPaidByCash(0);
          break;
        case 3:
          setPaidByCash(0);
          setPaidByCard(0);
          break;
        default:
          break;
      }
      /* } */
    }
  };

  const updateValue = (e) => {
    let target = e.target;
    let parsedValue = isNaN(parseFloat(target.value))
      ? 0
      : Math.abs(parseFloat(target.value));
    e.target.value = isNaN(parseFloat(target.value)) ? "" : target.value;
    if (target.name === "paidByCash") {
      setPaidByCash(parsedValue);
    }
    if (target.name === "paidByCard") {
      setPaidByCard(parsedValue);
    }
    if (target.name === "paidByGiftCard") {
      setPaidByGiftCard(parsedValue);
    }
    if (target.name === "partialPayment") {
      setPartialPayment(parsedValue);
    }
  };

  const changePeriods = (event) => {
    setPeriods(event.target.value);
  };

  const addNewAdditionalInvoice = () => {
    if (additional_invoice !== "") {
      let newArray = [...additional_invoices];
      newArray.push(additional_invoice);
      setAdditionalInvoices(newArray);
    }
  };

  const toggleCheck = (e) => {
    let target = e.target;
    if (target.name === "check1") {
      setCheck1(!check1);
    } else {
      setCheck2(!check2);
    }
  };

  const submitSaldar = () => {
    let invalidForm = false;
    let data = {
      id_cliente_deudor_web_app: selectedCredit.id_cliente_deudor_web_app,
      tarjeta: paidByCard,
      efectivo: paidByCash,
      gift_card: paidByGiftCard,
      gift_card_codigo: giftcard_code,
      recibo_numero: invoice_number,
      adicional_recibo: additional_invoices.join(","),
      cuotas: periods,
      cambios: 0,
    };
    let total_paid =
      parseFloat(paidByCard) +
      parseFloat(paidByCash) +
      parseFloat(paidByGiftCard);
    if (selectedCredit.monto < total_paid)
      data.cambios = parseFloat((total_paid - selectedCredit.monto).toFixed(2));

    if (total_paid === 0) {
      invalidForm = true;
      toast.error("No puede saldar una factura sin un monto pagado.", {
        autoClose: 10000,
      });
    }
    if (paidByCard > 0 && !invoice_number) {
      invalidForm = true;
      toast.error(
        "Al pagar con tarjeta debe introducir al menos un numero de recibo.",
        {
          autoClose: 10000,
        }
      );
    }

    if (!invalidForm) {
      API.clients
        .saldarFactura(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setSaldarFacturaLoader(false);
            toast.success(response.message, {
              autoClose: 10000,
            });
            getClientCredits(client.id_cliente_web_app);
            $("#saldar_factura").modal("hide");
          } else {
            setSaldarFacturaLoader(false);
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          setSaldarFacturaLoader(false);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        });
    }
  };

  const clientCreditsActions = (event, credit) => {
    if (event.target.value === "saldar") {
      setSelectedCredit(credit);
      $("#saldar_factura").modal();
    } else if (event.target.value === "detalles") {
      openCuentaPorCobrarInvoice(credit);
    }
  };

  const openCuentaPorCobrarInvoice = (cuenta) => {
    const newWindow = window.open(
      `/#/app/vender/recibo-credito/${cuenta.id_cliente_deudor_web_app}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const getProducts = () => {
    API.products
      .getAll({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Im obtaining these products:", response.data);
          setAllProducts(response.data);
        }
      })
      .catch((err) => {
        console.log("Ha ocurrido un error / Búsqueda de productos");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getSuggestions = () => {
    const inputValue = document
      .getElementById("product_query")
      .value.trim()
      .toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : allProducts
          .filter((product) =>
            product.codigo.toLowerCase().includes(inputValue)
          )
          .slice(0, 5);
  };

  const getSuggestionValue = (suggestion) => {
    let existingData = [...selectedProducts];
    if (suggestion.stock > 0) {
      suggestion.cantidad = 1;
      if (parseFloat(suggestion.descuento_precio) > 0.0) {
        suggestion.total = parseFloat(
          (suggestion.descuento_precio * suggestion.cantidad).toFixed(2)
        );
      } else {
        suggestion.total = parseFloat(
          (suggestion.precio_venta * suggestion.cantidad).toFixed(2)
        );
      }

      let auxArray = existingData.concat([suggestion]);
      let existingCost = salesCost;
      /* existingCost.subtotal = updateSubtotal(auxArray);
            existingCost.iva = updateIva(auxArray);
            existingCost.total = parseFloat(
                (existingCost.subtotal + existingCost.iva).toFixed(2)
            ); */
      let total = 0;
      auxArray.map((element) => {
        if (parseFloat(element.descuento_precio) > 0.0) {
          total += element.descuento_precio * element.cantidad;
        } else {
          total += element.precio_venta * element.cantidad;
        }
      });
      existingCost.total = parseFloat(total.toFixed(2));
      setSalesCost(existingCost);
      setSelectedProducts(auxArray);
    } else {
      toast.error(
        "No puede seleccionar ese producto porque no tiene existencia.",
        { autoClose: 10000 }
      );
    }
    return suggestion.codigo;
  };

  const renderSuggestion = (suggestion) => {
    const matches = AutosuggestHighlightMatch(
      suggestion.codigo,
      productFilterText
    );
    const parts = AutosuggestHighlightParse(suggestion.codigo, matches);

    return (
      <div>
        {parts.map((part, index) => {
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  const changeProductFilterText = (e, { newValue }) => {
    setProductFilterText(newValue);
  };

  const onSuggestionsFetchRequested = () => {
    setSuggestions(getSuggestions(productFilterText));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Escribe el nombre del producto",
    value: productFilterText,
    onChange: changeProductFilterText,
    id: "product_query",
  };

  const changeProductQuantity = (productId, operation, byInput) => {
    console.log("Im inside changeProduct");
    let currentIndex = selectedProducts.findIndex(
      (x) => x.id_new_item === productId
    );
    let auxArray = [...selectedProducts];
    let existingCost = salesCost;
    if (auxArray[currentIndex]) {
      if (!byInput) {
        if (operation === "sum") {
          if (
            auxArray[currentIndex].cantidad + 1 >
            auxArray[currentIndex].stock
          ) {
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
          } else {
            auxArray[currentIndex].cantidad++;
            auxArray[currentIndex].total =
              parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
                ? parseFloat(
                    (
                      auxArray[currentIndex].descuento_precio *
                      auxArray[currentIndex].cantidad
                    ).toFixed(2)
                  )
                : parseFloat(
                    (
                      auxArray[currentIndex].precio_venta *
                      auxArray[currentIndex].cantidad
                    ).toFixed(2)
                  );
            let total = 0;
            auxArray.map((element) => {
              if (parseFloat(element.descuento_precio) > 0.0) {
                total += element.descuento_precio * element.cantidad;
              } else {
                total += element.precio_venta * element.cantidad;
              }
            });
            existingCost.total = parseFloat(total.toFixed(2));
            setSalesCost(existingCost);
          }
        }

        if (operation === "sub") {
          if (
            auxArray[currentIndex].cantidad - 1 >
            auxArray[currentIndex].stock
          ) {
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
          } else {
            if (auxArray[currentIndex].cantidad !== 1) {
              auxArray[currentIndex].cantidad--;
              auxArray[currentIndex].total =
                parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
                  ? parseFloat(
                      (
                        auxArray[currentIndex].descuento_precio *
                        auxArray[currentIndex].cantidad
                      ).toFixed(2)
                    )
                  : parseFloat(
                      (
                        auxArray[currentIndex].precio_venta *
                        auxArray[currentIndex].cantidad
                      ).toFixed(2)
                    );
              let total = 0;
              auxArray.map((element) => {
                if (parseFloat(element.descuento_precio) > 0.0) {
                  total += element.descuento_precio * element.cantidad;
                } else {
                  total += element.precio_venta * element.cantidad;
                }
              });
              existingCost.total = parseFloat(total.toFixed(2));
              setSalesCost(existingCost);
            }
          }
        }
      } else {
        if (document.getElementById("value-" + productId)) {
          if (
            Math.abs(document.getElementById("value-" + productId).value) === 0
          )
            document.getElementById("value-" + productId).value = 1;
          if (
            Math.abs(document.getElementById("value-" + productId).value) >
            auxArray[currentIndex].stock
          ) {
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
          } else {
            auxArray[currentIndex].cantidad = Math.abs(
              document.getElementById("value-" + productId).value
            );
            auxArray[currentIndex].total =
              parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
                ? parseFloat(
                    (
                      auxArray[currentIndex].descuento_precio *
                      auxArray[currentIndex].cantidad
                    ).toFixed(2)
                  )
                : parseFloat(
                    (
                      auxArray[currentIndex].precio_venta *
                      auxArray[currentIndex].cantidad
                    ).toFixed(2)
                  );
            let total = 0;
            auxArray.map((element) => {
              if (parseFloat(element.descuento_precio) > 0.0) {
                total += element.descuento_precio * element.cantidad;
              } else {
                total += element.precio_venta * element.cantidad;
              }
            });
            existingCost.total = parseFloat(total.toFixed(2));
            setSalesCost(existingCost);
          }
        }
      }
      console.log("Im setting", auxArray);
      setSelectedProducts(auxArray);
    }
  };

  const removeProduct = (productId) => {
    let currentIndex = selectedProducts.findIndex(
      (x) => x.id_new_item === productId
    );
    if (currentIndex !== -1) {
      let auxArray = [...selectedProducts];
      auxArray = auxArray.filter((x) => x.id_new_item !== productId);
      setSelectedProducts(auxArray);
      let existingCost = salesCost;
      auxArray[currentIndex].total =
        parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
          ? parseFloat(
              (
                auxArray[currentIndex].descuento_precio *
                auxArray[currentIndex].cantidad
              ).toFixed(2)
            )
          : parseFloat(
              (
                auxArray[currentIndex].precio_venta *
                auxArray[currentIndex].cantidad
              ).toFixed(2)
            );
      let total = 0;
      auxArray.map((element) => {
        if (parseFloat(element.descuento_precio) > 0.0) {
          total += element.descuento_precio * element.cantidad;
        } else {
          total += element.precio_venta * element.cantidad;
        }
      });
      existingCost.total = parseFloat(total.toFixed(2));
      setSalesCost(existingCost);
    }
  };

  const getPrice = (product) => {
    let price;
    let discountPrice;
    let discount;
    let taxableAmount;
    let iva;
    price = product.precio_venta;
    discountPrice = product.descuento_precio;
    discount = price - discountPrice;
    if (parseInt(product.descuento_precio) > 0) {
      taxableAmount = (price - discount) / 1.12;
      iva = price - discount - taxableAmount;
    } else {
      taxableAmount = price / 1.12;
      iva = price - taxableAmount;
    }
    let total = taxableAmount + iva;
    return parseFloat(total.toFixed(2));
  };

  /* const updateIva = (array) => {
        let iva = 0;
        let price;
        let discountPrice;
        let discount;
        let taxableAmount;
        array.map((product) => {
            price = product.cantidad * product.precio_venta;
            discountPrice = product.cantidad * product.descuento_precio;
            discount = price - discountPrice;
            if (parseInt(product.descuento_precio) > 0) {
                taxableAmount = (price - discount) / 1.12;
                iva += price - discount - taxableAmount;
            } else {
                taxableAmount = (price) / 1.12;
                iva += price - taxableAmount;
            }

        });
        return parseFloat(iva.toFixed(2));
    }; */

  /*   const updateSubtotal = (array) => {
          let price;
          let discountPrice;
          let discount;
          let taxableAmount;
          let totalBill = 0;
          array.map((product) => {
              price = product.cantidad * product.precio_venta;
              discountPrice = product.cantidad * product.descuento_precio;
              if (parseInt(product.descuento_precio) > 0) {
                  discount = price - discountPrice;
                  taxableAmount = (price - discount) / 1.12;
              } else {
                  discount = price;
                  taxableAmount = (price) / 1.12;
              }
              totalBill += taxableAmount;
          });
          return parseFloat(totalBill.toFixed(2));
      }; */

  const liquidar_venta = () => {
    let validForm = true;
    setSubmitLoader(true);
    let data = {
      id_cliente_web_app: client.id_cliente_web_app,
      id_advances: selectedAdvances
        .map((x) => x.id_cliente_acreedor_web_app)
        .join(","),
      selectedProducts: JSON.stringify(selectedProducts),
      monto_total: salesCost.total,
    };

    if (selectedProducts.length === 0) {
      toast.error("Debe seleccionar al menos un producto.", {
        autoClose: 10000,
      });
      validForm = false;
    }
    console.log("sas");
    if (selectedAdvances.length <= 0) {
      toast.error("Debe seleccionar al menos un anticipo.", {
        autoClose: 10000,
      });
      validForm = false;
    }

    console.log("Sending this data", data);
    if (validForm) {
      API.clients
        .useAdvancesForSale(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success(response.message, { autoClose: 10000 });
            $("#liquidar_anticipo").modal("hide");
            getClientAdvances(client.id_cliente_web_app);
            queryAll(dateRange, client.id_cliente_web_app);
            let id = props.history.location.state.id_cliente_web_app;
            getClient(true, id);
            getClientAdvances(id);
            getClientCredits(id);
          } else {
            if (response.message) {
              toast.error(response.message, { autoClose: 10000 });
            } else {
              toast.error("Ha ocurrido un error", {
                autoClose: 10000,
              });
            }
          }
          setSubmitLoader(false);
        })
        .catch((err) => {
          setSubmitLoader(false);
          console.log("ERROR", err, err.response);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        });
    } else {
      setSubmitLoader(false);
    }
  };
  const getMonto = () => {
    return debtPaymentType === "total"
      ? selectedMethod === 1
        ? selectedAdvances
            .reduce((a, b) => a + b.monto_disponible, 0)
            .toFixed(2)
        : debtPaymentType === "parcial"
        ? partialPayment
        : selectedDuesToPay.length > 0
        ? selectedDuesToPay.reduce((a, b) => a + b.monto_deuda, 0)
        : "0"
      : partialPayment === 0
      ? ""
      : partialPayment;
  };
  // console.log("getMonto", getMonto());
  const liquidar_deudas = () => {
    setSubmitLoader(true);
    let validForm = true;
    let data = {
      id_cliente_web_app: client.id_cliente_web_app,
      id_advances: selectedAdvances
        .map((x) => x.id_cliente_acreedor_web_app)
        .join(","),
      id_debts: selectedDuesToPay
        .map((x) => x.id_cliente_deudor_web_app)
        .join(","),
      tipo: debtPaymentType,
      monto: getMonto(),
    };
    if (selectedDuesToPay.length === 0) {
      toast.error("Debe seleccionar al menos una deuda.", { autoClose: 10000 });
      validForm = false;
    }

    if (selectedAdvances.length === 0) {
      console.log(selectedAdvances);
      toast.error("Debe seleccionar al menos un anticipo.", {
        autoClose: 10000,
      });
      validForm = false;
    }
    if (data.tipo === "") {
      toast.error("Debe seleccionar un tipo de pago de deuda.", {
        autoClose: 10000,
      });
      validForm = false;
    }

    if (data.tipo === "parcial" && data.monto === 0) {
      toast.error("Por favor introduzca el monto a liquidar.", {
        autoClose: 10000,
      });
      validForm = false;
    }

    console.log("This is data", data);
    if (validForm) {
      data.monto = parseFloat(data.monto).toFixed(2);
      API.clients
        .useAdvancesForDebts(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success(response.message, { autoClose: 10000 });
            $("#liquidar_anticipo").modal("hide");
            getClientAdvances(client.id_cliente_web_app);
            getClientCredits(client.id_cliente_web_app);
            queryAll(dateRange, client.id_cliente_web_app);
          } else {
            if (response.message) {
              toast.error(response.message, { autoClose: 10000 });
            } else {
              toast.error("Ha ocurrido un error", {
                autoClose: 10000,
              });
            }
          }
          setSubmitLoader(false);
        })
        .catch((err) => {
          setSubmitLoader(false);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        });
    } else {
      setSubmitLoader(false);
    }
  };
  const getPriceLevels = () => {
    API.priceLevels
      .getAll()
      .then((res) => {
        let response = res.data;
        setPriceLevels(response.data);
        if (!response.success) {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error(
              "Ha ocurrido un error al cargar los niveles de precio del sistema",
              {
                autoClose: 10000,
              }
            );
          }
        }
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al cargar los niveles de precio del sistema",
          { autoClose: 10000 }
        );
      });
  };

  const getNivelesCliente = async () => {
    let id_new_empresa = window.localStorage.getItem("id_empresa");

    try {
      const response = await API.userConfig.getNiveles(id_new_empresa);
      console.log("Niveles", response.data.costumer);
      setNivelPrecio(response.data.costumer);
    } catch (error) {
      console.log("Error al buscar niveles", error);
      toast.error("Error al buscar niveles", { autoClose: 10000 });
    }
  };

  const evaluarNit = (event) => {
    const { name, value } = event.target;
    if (value.toLocaleLowerCase().substr(0, 2) == "cf") {
      setHabilitaClienteCF(true);
    } else {
      setHabilitaClienteCF(false);
    }
  };

  // console.log("selectedAdvances: ", selectedAdvances);
  // console.log("selectedDuesToPay: ", selectedDuesToPay);
  // console.log("partialPayment", partialPayment);

  return (
    <div id="dashboard-section" className="client-profile-container">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Clientes</strong>
        </h4>
        <FormControl variant="outlined"></FormControl>
      </div>

      <div className="content-card card mt-3">
        <form onSubmit={handleSubmit(updateClient)} id="profile_form">
          <div className="card-header">
            <div className="row">
              <div className="col-md-6">
                <h1>
                  <strong>Perfil del cliente</strong>
                </h1>
                <p>Consulte la información en específico de algún cliente</p>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
                <Button
                  className="btn-cs1 icon-btn-delete mx-1"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={"/app/clients"}
                >
                  <span style={{ marginBottom: "-4px" }}>Cancelar</span>
                </Button>
                <Button
                  className="btn-cs1_blue mx-1"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {submitLoader ? (
                    <div className="text-center">
                      <CircularProgress
                        size={18}
                        className="mx-auto"
                        style={{ color: "white" }}
                      />
                    </div>
                  ) : (
                    <span style={{ marginBottom: "-4px" }}>Guardar</span>
                  )}
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <Tabs
                className="mt-2"
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTab}
              >
                <Tab className="tab" label="General" />
                {/* {isAleko() && <Tab className="tab" label="Analiticas" />} */}
              </Tabs>
            </div>
          </div>
          <TabPanel value={selectedTab} index={0}>
            <div className="card-body">
              {clientLoader ? (
                <div className="text-center">
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    style={{ color: "black" }}
                  />
                </div>
              ) : (
                <>
                  {isCmi() && (
                    <div className="col-md-12 px-0 d-flex flex-wrap">
                      <div className="col-md-3 mt-3">
                        <div className="d-flex mb-0">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>
                              {moneda == "L" ? "RTN" : "NIT"} del cliente
                            </strong>
                          </label>
                          <Tooltip title="El NIT no debe llevar guión y el caracter alfabético debe estar en mayuscula. Ej. 123456789K">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>

                        <TextField
                          type="text"
                          className="w-100"
                          placeholder="Ingrese el NIT del cliente"
                          inputRef={register}
                          defaultValue={client.nit}
                          name="nit"
                          required={true}
                          onChange={evaluarNit}
                        />
                      </div>

                      <div className="col-md-3 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Nombre del cliente</strong>
                        </label>

                        <TextField
                          type="text"
                          className="w-100"
                          name="nombre"
                          inputRef={register}
                          defaultValue={
                            habilitaClienteCF ? client.nombrecf : client.nombre
                          }
                          placeholder="Ingrese el nombre del cliente"
                          required={true}
                          disabled={habilitaClienteCF ? true : false}
                        />
                      </div>

                      <div className="col-md-3 mt-3">
                        <div className="w-100">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Tipo de cliente</strong>
                          </label>
                        </div>

                        <Select
                          className="cs-select1 select-w100"
                          native
                          inputRef={register}
                          name="diferenciacion"
                          style={{ width: "100%" }}
                          variant="outlined"
                          required={habilitaClienteCF ? true : false}
                          disabled={habilitaClienteCF ? true : false}
                          defaultValue={client.diferenciacion.toLowerCase()}
                        >
                          <option value="">Seleccione</option>
                          <option
                            selected={habilitaClienteCF ? "selected" : ""}
                            value="individual"
                          >
                            Individual
                          </option>
                          <option value="restaurante">Restaurante</option>
                          <option value="cafeteria">Cafetería</option>
                          <option value="hotel">Hotel</option>
                        </Select>
                      </div>

                      <div className="col-md-3 mt-3">
                        <div className="w-100">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Nivel de cliente</strong>
                          </label>
                        </div>

                        <Select
                          className="cs-select1 select-w100"
                          native
                          inputRef={register}
                          name="id_nivel_precio"
                          style={{
                            width: "100%",
                          }}
                          variant="outlined"
                          required={habilitaClienteCF ? true : false}
                          //disabled={habilitaClienteCF ? true : false}
                        >
                          <option value="">Seleccione</option>
                          {nivelPrecio.map((level) => (
                            <option
                              key={level.id}
                              selected={
                                level.id === nivelPriceSelected ? true : false
                              }
                              value={level.id}
                            >
                              {level.nivel}
                            </option>
                          ))}
                        </Select>
                      </div>

                      {habilitaClienteCF && (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Nombre del cliente final</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ingrese del cliente final"
                            inputRef={register}
                            defaultValue={client.nombre_cliente_final}
                            name="nombre_cliente_final"
                          />
                        </div>
                      )}

                      {!habilitaClienteCF && (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Dirección fiscal (opcional)</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ingrese la dirección fiscal del cliente"
                            inputRef={register}
                            defaultValue={client.direccion}
                            name="direccion"
                          />
                        </div>
                      )}

                      {habilitaClienteCF && (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Código del cliente</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el código del cliente"
                            inputRef={register}
                            defaultValue={client.client_code}
                            name="client_code"
                          />
                        </div>
                      )}

                      {!habilitaClienteCF && (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Correo electrónico (opcional)</strong>
                          </label>

                          <TextField
                            type="email"
                            className="w-100"
                            placeholder="Ingrese el correo electrónico del cliente"
                            inputRef={register}
                            defaultValue={client.email}
                            name="email"
                          />
                        </div>
                      )}

                      <div className="col-md-4 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Teléfono</strong>
                        </label>

                        <TextField
                          type="number"
                          className="w-100"
                          placeholder="Ingrese número de teléfono"
                          inputRef={register}
                          defaultValue={client.telefono}
                          name="telefono"
                        />
                      </div>

                      <div className="col-md-12 mt-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-4">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <label>
                              <strong>Crédito</strong>
                            </label>
                            <label class="switch">
                              <input
                                type="checkbox"
                                ref={register}
                                name="add_credito"
                                defaultChecked={
                                  client.dias_credito > 0 ? true : false
                                }
                                onChange={(e) =>
                                  setAdd_credito_cmi(!add_credito_cmi)
                                }
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>
                          <div className="w-100 d-flex align-items-center">
                            <p>¿Desea otorgar crédito a este usuario?</p>
                          </div>
                        </div>

                        <div className="col-md-4 h-100">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Límite de crédito</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el límite de crédito del cliente"
                            defaultValue={client.limite_credito}
                            disabled={!add_credito_cmi}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                            }}
                            // onChange={(event) => {
                            //   setPriceValue(
                            //     formaterPriceFunction(event.target.value)
                            //   )
                            // }}
                            name="limite_credito"
                            inputRef={register}
                            required={isCmi() ? true : false}
                          />
                        </div>

                        <div className="col-md-4 h-100">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Días de crédito</strong>
                          </label>
                          <Select
                            className="cs-select1 select-w100"
                            native
                            inputRef={register}
                            required={isCmi() ? true : false}
                            disabled={!add_credito_cmi}
                            name="dias_credito"
                            style={{ width: "100%" }}
                            variant="outlined"
                            defaultValue={client.dias_credito}
                          >
                            <option value="">Seleccione un periodo</option>
                            <option value="0">0 días</option>
                            <option value="15">15 días</option>
                            <option value="30">30 días</option>
                            <option value="60">60 días</option>
                            <option value="90">90 días</option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  )}

                  {!isCmi() && (
                    <>
                      <div className="row my-2 justify-content-between align-items-center">
                        <div className="col-md-4 h-100">
                          <label style={{ fontSize: "1rem" }}>
                            <strong>Nombre del cliente</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el nombre del cliente"
                            inputRef={register}
                            defaultValue={client.nombre}
                            required={true}
                            name="nombre"
                          />
                        </div>

                        <div className="col-md-4 h-100">
                          <label style={{ fontSize: "1rem" }}>
                            <strong>NIT</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el NIT del cliente"
                            inputRef={register}
                            defaultValue={client.nit}
                            required={true}
                            name="nit"
                          />
                        </div>

                        <div className="col-md-4 h-100">
                          <label style={{ fontSize: "1rem" }}>
                            <strong>Dirección fiscal del cliente</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ingrese la dirección fiscal del cliente"
                            name="direccion"
                            inputRef={register}
                            defaultValue={client.direccion}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="row my-2 justify-content-start align-items-center">
                        <div className="col-md-4 h-100">
                          <label style={{ fontSize: "1rem" }}>
                            <strong>Correo electrónico</strong>
                          </label>

                          <TextField
                            type="email"
                            className="w-100"
                            placeholder="Ingrese el email del cliente"
                            inputRef={register}
                            defaultValue={client.email}
                            name="email"
                          />
                        </div>

                        <div className="col-md-4 h-100">
                          <label style={{ fontSize: "1rem" }}>
                            <strong>Límite de crédito</strong>
                          </label>

                          <TextField
                            value={precioValue}
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el límite de crédito del cliente"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                            }}
                            onChange={(event) => {
                              if (
                                event.target.value != 0 ||
                                event.target.value === ""
                              ) {
                                setPrecioValue(
                                  formaterPriceFunction(event.target.value)
                                );
                              }
                            }}
                            inputRef={register}
                            required={true}
                            name="limit"
                          />
                        </div>

                        {isCmi() ? (
                          ""
                        ) : (
                          <div className="col-md-4 h-100">
                            <p className="mb-2">
                              <strong>Días de crédito</strong>
                            </p>
                            <Select
                              native
                              inputRef={register}
                              defaultValue={client.dias_credito}
                              required={true}
                              name="dias_credito"
                              style={{ width: "100%" }}
                              variant="outlined"
                            >
                              <option value="">Seleccione un periodo</option>
                              <option value="0">0 días</option>
                              <option value="15">15 días</option>
                              <option value="30">30 días</option>
                              <option value="60">60 días</option>
                              <option value="90">90 días</option>
                            </Select>
                          </div>
                        )}

                       
                        {isCmi() ? (
                          <div className="col-md-4 mt-3">
                            <label style={{ fontSize: "0.8rem" }}>
                              <strong>Días de crédito</strong>
                            </label>

                            <TextField
                              type="number"
                              placeholder="Días de crédito"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    N°
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0 },
                              }}
                              name="dias_credito"
                              inputRef={register}
                              style={{ width: "100%" }}
                              required={true}
                              defaultValue={client.dias_credito}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="col-md-12 p-0">
              <div className="w-100">
                <h4>
                  <strong>Estadísticas</strong>
                </h4>
              </div>
              <div
                className="w-100 d-flex justify-content-center align-items-center rounded"
                style={{ background: "#faf9f9" }}
              >
                <div className="col-md-3 py-2 d-flex flex-column justify-content-center align-items-around border border-secondary rounded-left">
                  <p className="mb-0">
                    <strong>Valor de vida del cliente</strong>
                  </p>
                  <p className="mb-0">
                    {moneda}.{" "}
                    {client.life_value > 0
                      ? formaterPriceFunction(client.life_value.toFixed(2) + "")
                      : "0.00"}
                  </p>
                </div>
                <div className="col-md-3 py-2 d-flex flex-column justify-content-center align-items-around border border-secondary">
                  <p className="mb-0">
                    <strong>Ticket Promedio</strong>
                  </p>
                  <p className="mb-0">
                    {moneda}.{" "}
                    {client.half_ticket > 0
                      ? formaterPriceFunction(
                          client.half_ticket.toFixed(2) + ""
                        )
                      : "0.00"}
                  </p>
                </div>
                <div className="col-md-3 py-2 d-flex flex-column justify-content-center align-items-around border border-secondary">
                  <p className="mb-0">
                    <strong>Saldo Acreedor</strong>
                  </p>
                  <p className="mb-0">
                    {moneda}.{" "}
                    {client.saldo_acreedor != null
                      ? formaterPriceFunction(
                          client.saldo_acreedor.toFixed(2) + ""
                        )
                      : "0.00"}
                  </p>
                </div>
                <div className="col-md-3 py-2 d-flex flex-column justify-content-center align-items-around border border-secondary rounded-right">
                  <p className="mb-0">
                    <strong>Saldo Deudor</strong>
                  </p>
                  <p className="mb-0">
                    {moneda}.{" "}
                    {client.saldo_duedor != null
                      ? formaterPriceFunction(
                          client.saldo_duedor.toFixed(2) + ""
                        )
                      : "0.00"}
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>
        </form>
        <TabPanel value={selectedTab} index={0}>
          <div className="card-body">
            <div
              className="row mt-2 justify-content-between align-items-center"
              style={{ borderBottom: "1px solid #E0E0E0" }}
            >
              <div className="col-md-6">
                <h4>
                  <strong>Historial</strong>
                </h4>
                <p>Resumen de las transacciones del cliente</p>
              </div>
            </div>

            <div className="row p-0 client-profile-history-col">
              <div className="col-xl-6 p-0 pt-2" id="col-1">
                <div
                  className="row justify-content-center justify-content-md-between"
                  style={{ minHeight: "130px" }}
                >
                  <div className="col-xl-4 text-center my-auto">
                    <p>
                      <strong>Saldo acreedor (anticipos)</strong>
                    </p>
                    <h1 className="secondary_color">
                      <strong>
                        {moneda}.{" "}
                        {completeClientAdvances.length > 0
                          ? formaterPriceFunction(
                              completeClientAdvances
                                .reduce((a, b) => a + b.monto_disponible, 0)
                                .toFixed(2) + ""
                            )
                          : "0.00"}
                      </strong>
                    </h1>
                  </div>
                  <div className="col-xl-4 text-center my-auto p-0 col-1-button">
                    <Button
                      className=" mx-1"
                      style={{
                        backgroundColor: "#08BFE6",
                        color: "white",
                        textTransform: "none",
                        fontSize: "16px",
                      }}
                      variant="contained"
                      disabled={selectedAdvances.length === 0}
                      color="primary"
                      data-toggle="modal"
                      data-target="#liquidar_anticipo"
                    >
                      <span style={{ marginBottom: "-4px" }}>
                        {selectedAdvances.length === 0
                          ? "Seleccionar transacciones"
                          : "Liquidar seleccionados"}
                      </span>
                    </Button>
                  </div>
                  <div className="col-xl-4 text-center my-auto col-1-button">
                    <Button
                      className="btn-cs1_blue mx-1"
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "16px",
                      }}
                      component={Link}
                      to={
                        "/app/anticipo/crear?id_cliente=" +
                        client.id_cliente_web_app
                      }
                    >
                      <span style={{ marginBottom: "-4px" }}>
                        Crear anticipo
                      </span>
                    </Button>
                  </div>
                </div>
                {clientAdvancesLoader ? (
                  <div className="text-center">
                    <CircularProgress
                      size={18}
                      className="mx-auto"
                      style={{ color: "white" }}
                    />
                  </div>
                ) : (
                  <DataTable
                    className="datatable-cs datatable-cs-v2 mt-2"
                    title={"s5"}
                    columns={[
                      {
                        name: "N°",
                        selector: "nro_anticipo",
                        sortable: true,
                      },
                      {
                        name: "Fecha",
                        selector: "fecha",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {moment(row.fecha)
                              .utcOffset(0)
                              .format("DD/MM/YYYY, h:mm:ss a")}
                          </div>
                        ),
                      },
                      {
                        name: "Monto",
                        selector: "monto",
                        cell: (row) => (
                          <span>
                            {moneda}. {numberFormater(row.monto_disponible)}
                          </span>
                        ),
                        sortable: true,
                      },
                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="text-center w-100">
                            <FormControl variant="outlined">
                              <Select
                                native
                                onChange={(e) => anticiposAction(e, row)}
                                className="cs-select"
                              >
                                <option value="">Acciones</option>
                                <option value="detalles">Ver detalles</option>
                                <option value="liquidar">Liquidar</option>
                              </Select>
                            </FormControl>
                          </div>
                        ),
                      },
                    ]}
                    data={completeClientAdvances}
                    selectableRows
                    defaultSortField="fecha"
                    defaultSortAsc={false}
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se encontraron resultados"
                    onSelectedRowsChange={handleAdvancesSelect}
                  />
                )}
              </div>
              <div className="col-xl-6 p-0 pt-2" id="col-2">
                <div
                  className="row justify-content-center justify-content-md-between"
                  style={{ minHeight: "130px" }}
                >
                  <div className="col-xl-6 text-center my-auto">
                    <p>
                      <strong>Saldo deudor por facturas crédito</strong>
                    </p>
                    <h1 className="secondary_color">
                      <strong>
                        {moneda}.{" "}
                        {completeClientCredits.length > 0
                          ? formaterPriceFunction(
                              completeClientCredits
                                .reduce((a, b) => a + b.monto_deuda, 0)
                                .toFixed(2) + ""
                            )
                          : "0.00"}
                      </strong>
                    </h1>
                  </div>

                  {/* <div className="col-xl-4 text-center my-auto col-2-button">
                                    <Button
                                        className="btn-cs1 mx-1"
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        <span style={{ marginBottom: "-4px" }}>
                                            Saldar factura
                                        </span>
                                    </Button>
                                </div> */}
                </div>
                {clientCreditsLoader ? (
                  <div className="text-center">
                    <CircularProgress
                      size={18}
                      className="mx-auto"
                      style={{ color: "black" }}
                    />
                  </div>
                ) : (
                  <DataTable
                    className="datatable-cs datatable-cs-v2 mt-2"
                    title={"s1"}
                    columns={[
                      {
                        name: "N°",
                        selector: "identificador",
                        sortable: true,
                        cell: (row) => (
                          <div className="d-flex justify-content-between align-items-center flex-wrap h-100 w-100 p-0">
                            <div
                              className="h-100"
                              style={{
                                width: "15%",
                              }}
                            >
                              {row.estado === "Por vencer" ? (
                                <span className="p-2 warning-span"></span>
                              ) : (
                                ""
                              )}
                              {row.estado === "Vencida" ? (
                                <span className="p-2 danger-span"></span>
                              ) : (
                                ""
                              )}
                              {row.estado === "Pagada parcialmente" ? (
                                <span className="p-2 warning-span"></span>
                              ) : (
                                ""
                              )}
                              {row.estado === "Pagada" ? (
                                <span className="p-2 success-span"></span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="h-100" style={{ width: "85%" }}>
                              {row.identificador}
                            </div>
                          </div>
                        ),
                      },
                      {
                        name: "Fecha de vencimiento",
                        selector: "fecha_vencimiento",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {
                              // moment(row.fecha)
                              moment(row.fecha_vencimiento)
                                .utcOffset(0)
                                .format("DD/MM/YYYY")
                              // .format("DD/MM/YYYY, h:mm:ss a")
                            }
                          </div>
                        ),
                      },
                      {
                        name: "Monto",
                        selector: "monto",
                        cell: (row) => (
                          <span>
                            {moneda}. {numberFormater(row.monto_deuda)}
                          </span>
                        ),
                        sortable: true,
                      },
                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="text-center w-100">
                            <FormControl variant="outlined">
                              <Select
                                native
                                onChange={(e) => clientCreditsActions(e, row)}
                                className="cs-select"
                                value=""
                              >
                                <option value="">Acciones</option>
                                <option value="detalles">Ver detalles</option>
                                {row.estado !== "Pagada" ? (
                                  <option value="saldar">Saldar factura</option>
                                ) : (
                                  ""
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        ),
                      },
                    ]}
                    data={completeClientCredits}
                    defaultSortField="fecha"
                    defaultSortAsc={false}
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se encontraron resultados"
                  />
                )}
              </div>
            </div>

            <div
              className="row pt-3"
              style={{ borderTop: "1px solid #E0E0E0" }}
            >
              <div className="col-md-3 text-left" id="operation-history-title">
                <h4>
                  <strong>Historial de operaciones</strong>
                </h4>
              </div>
              <div className="col-md-4 text-center">
                <p className="mb-0">Filtrar por:</p>
                <div className="d-flex align-items-center flex-wrap justify-content-center">
                  <DateRangePicker
                    onChange={(val) => handleDateChange(val)}
                    value={dateRange}
                    format={"dd-MM-y"}
                    className="date-picker-cs px-1"
                  />
                </div>
              </div>
              <div className="col-md-5"></div>

              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <Tabs
                    className="mt-2"
                    value={operationsTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleProductTabs}
                  >
                    <Tab className="tab" label="Todos" />
                    <Tab className="tab" label="Ventas" />
                    <Tab className="tab" label="Facturas a crédito" />
                    <Tab className="tab" label="Anticipos de pago" />
                  </Tabs>
                </div>
              </div>

              <div className="col-12 w-100 p-0">
                <TabPanel value={operationsTab} index={0}>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <div className="row history-headlines">
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            N° Operaciones
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {allOperationsLoader ? (
                                <div className="text-center">
                                  <CircularProgress
                                    size={18}
                                    className="mx-auto"
                                    style={{
                                      color: "black",
                                    }}
                                  />
                                </div>
                              ) : (
                                numberFormater(allOpsInfo.operaciones)
                              )}
                            </strong>
                          </h4>
                        </div>
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            N° Ventas
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {allOperationsLoader ? (
                                <div className="text-center">
                                  <CircularProgress
                                    size={18}
                                    className="mx-auto"
                                    style={{
                                      color: "black",
                                    }}
                                  />
                                </div>
                              ) : (
                                numberFormater(allOpsInfo.ventas)
                              )}
                            </strong>
                          </h4>
                        </div>
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            N° Anulaciones
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {allOperationsLoader ? (
                                <div className="text-center">
                                  <CircularProgress
                                    size={18}
                                    className="mx-auto"
                                    style={{
                                      color: "black",
                                    }}
                                  />
                                </div>
                              ) : (
                                numberFormater(allOpsInfo.anuladas)
                              )}
                            </strong>
                          </h4>
                        </div>
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            N° Notas de crédito
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {allOperationsLoader ? (
                                <div className="text-center">
                                  <CircularProgress
                                    size={18}
                                    className="mx-auto"
                                    style={{
                                      color: "black",
                                    }}
                                  />
                                </div>
                              ) : (
                                numberFormater(allOpsInfo.notas)
                              )}
                            </strong>
                          </h4>
                        </div>
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            Facturas a crédito
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {allOperationsLoader ? (
                                <div className="text-center">
                                  <CircularProgress
                                    size={18}
                                    className="mx-auto"
                                    style={{
                                      color: "black",
                                    }}
                                  />
                                </div>
                              ) : (
                                numberFormater(allOpsInfo.creditos)
                              )}
                            </strong>
                          </h4>
                        </div>
                        <div className="col-md-2 px-2 mt-3">
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            Anticipos de pago
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {allOperationsLoader ? (
                                <div className="text-center">
                                  <CircularProgress
                                    size={18}
                                    className="mx-auto"
                                    style={{
                                      color: "black",
                                    }}
                                  />
                                </div>
                              ) : (
                                numberFormater(allOpsInfo.anticipos)
                              )}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 my-3">
                      <div className="row">
                        <div className="col-lg-3 my-auto text-center">
                          {selectedOperations.length > 0 ? (
                            <CSVLink
                              data={selectedOperations}
                              filename={
                                "Operaciones desde el " +
                                moment(dateRange[0]).format("DD-MM-YYYY") +
                                " hasta " +
                                moment(dateRange[1]).format("DD-MM-YYYY") +
                                ".csv"
                              }
                            >
                              <Button
                                className="btn-cs1 mt-2"
                                variant="contained"
                                color="primary"
                              >
                                Descargar
                                <CloudDownloadIcon className="ml-2" />
                              </Button>
                            </CSVLink>
                          ) : (
                            <Button
                              className="btn-cs1 mt-2"
                              variant="contained"
                              color="primary"
                            >
                              Descargar
                              <CloudDownloadIcon className="ml-2" />
                            </Button>
                          )}
                        </div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3 my-3 my-lg-auto">
                          <Select
                            native
                            value={opsOrderType}
                            onChange={(e) => classifyOps(e)}
                            style={{ width: "100%" }}
                            variant="outlined"
                          >
                            <option value="todo">Ver todos</option>
                            <option value="ventas">Ventas</option>
                            <option value="creditos">Créditos</option>
                            <option value="anticipos">Anticipos</option>
                            <option value="notas">Notas de crédito</option>
                            <option value="anulación">
                              Anulación de venta
                            </option>
                          </Select>
                        </div>
                        <div className="col-lg-3 p-0 my-auto">
                          <div className="input-group search-bar">
                            <input
                              type="text"
                              name="query"
                              value={allOpsFilterText}
                              onChange={(event) => filterAllOperations(event)}
                              className="form-control"
                              placeholder="Buscar por número de operación"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <SearchIcon />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      {allOperationsLoader ? (
                        <div className="text-center">
                          <CircularProgress
                            size={18}
                            className="mx-auto"
                            style={{ color: "black" }}
                          />
                        </div>
                      ) : (
                        <DataTable
                          className="datatable-cs datatable-cs-v2 mt-2"
                          title={"s"}
                          columns={[
                            {
                              name: "N° operación",
                              selector: "numero_operacion",
                              sortable: true,
                            },
                            {
                              name: "Tipo de operación",
                              selector: "tipo_operacion",
                              sortable: true,
                            },
                            {
                              name: "Fecha",
                              selector: "fecha",
                              sortable: true,
                              cell: (row) => (
                                <div className="text-center w-100">
                                  {moment(row.fecha)
                                    .utcOffset(0)
                                    .format("DD/MM/YYYY, h:mm:ss a")}
                                </div>
                              ),
                            },
                            {
                              name: "N de productos",
                              selector: "numero_productos",
                              sortable: true,

                              cell: (row) => (
                                <div className="text-center w-100">
                                  {numberFormater(row.numero_productos)}
                                </div>
                              ),
                            },
                            {
                              name: "Monto",
                              selector: "monto",
                              cell: (row) => (
                                <span>
                                  {moneda}. {numberFormater(row.monto)}
                                </span>
                              ),
                              sortable: true,
                            },
                            {
                              name: "",
                              selector: "action",
                              cell: (row) => (
                                <div className="text-center w-100">
                                  <Button
                                    className="btn-cs1"
                                    variant="contained"
                                    color="primary"
                                    endIcon={<ArrowForwardIosIcon />}
                                    size="small"
                                    onClick={() => operationsActions(row)}
                                  >
                                    <span
                                      style={{
                                        marginBottom: "-4px",
                                      }}
                                    >
                                      Ver detalles
                                    </span>
                                  </Button>
                                </div>
                              ),
                            },
                          ]}
                          data={filteredAllOperations}
                          selectableRows
                          defaultSortField="fecha"
                          defaultSortAsc={false}
                          pagination
                          paginationPerPage={10}
                          paginationIconFirstPage={null}
                          paginationIconLastPage={null}
                          paginationComponentOptions={{
                            rangeSeparatorText: "de",
                            noRowsPerPage: true,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: "All",
                          }}
                          noDataComponent="No se encontraron resultados"
                          onSelectedRowsChange={handleSelect}
                        />
                      )}
                    </div>
                  </div>
                </TabPanel>
              </div>
              <div className="col-12 w-100 p-0">
                <TabPanel value={operationsTab} index={1}>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <div className="row history-headlines">
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            N° Facturas
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {numberFormater(clientSales.length)}
                            </strong>
                          </h4>
                        </div>
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            Monto total
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {moneda}.{" "}
                              {clientSales.length > 0
                                ? formaterPriceFunction(
                                    clientSales
                                      .reduce((a, b) => a + b.monto_total, 0)
                                      .toFixed(2) + ""
                                  )
                                : "0.00"}
                            </strong>
                          </h4>
                        </div>
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            Ticket medio
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {moneda}.{" "}
                              {clientSales.length > 0
                                ? formaterPriceFunction(
                                    (
                                      clientSales.reduce(
                                        (a, b) => a + b.monto_total,
                                        0
                                      ) / clientSales.length
                                    ).toFixed(2) + ""
                                  )
                                : "0.00"}
                            </strong>
                          </h4>
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </div>

                    <div className="col-12 my-3">
                      <div className="row justify-content-center justify-content-lg-between">
                        <div className="col-lg-3">
                          <Select
                            native
                            value={""}
                            onChange={null}
                            style={{ width: "100%" }}
                            variant="outlined"
                          >
                            <option value="">Acciones</option>
                          </Select>
                        </div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3 p-0 my-auto">
                          <div className="input-group search-bar h-100">
                            <input
                              type="text"
                              value={salesFilterText}
                              onChange={(event) => filterSales(event)}
                              className="form-control"
                              placeholder="Buscar por número de facturación"
                            />
                            <div className="input-group-append h-100">
                              <span className="input-group-text">
                                <SearchIcon />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {creditsLoader ? (
                      <div className="text-center">
                        <CircularProgress
                          size={18}
                          className="mx-auto"
                          style={{ color: "black" }}
                        />
                      </div>
                    ) : (
                      <DataTable
                        className="datatable-cs datatable-cs-v2 mt-2"
                        title={"s2"}
                        columns={[
                          {
                            name: "N° Factura",
                            selector: "identificador",
                            sortable: true,
                          },
                          {
                            name: "Fecha / Hora",
                            selector: "fecha",
                            sortable: true,
                            cell: (row) => (
                              <div className="text-center w-100">
                                {moment(row.fecha)
                                  .utcOffset(0)
                                  .format("DD/MM/YYYY, h:mm:ss a")}
                              </div>
                            ),
                          },
                          {
                            name: "N° productos",
                            selector: "orderItems",
                            sortable: true,
                            cell: (row) => (
                              <div className="text-center w-100">
                                {row.orderItems.length}
                              </div>
                            ),
                          },
                          {
                            name: "Metodo de pago",
                            selector: "id_forma_pago",
                            sortable: true,
                            cell: (row) => (
                              <div className="w-100 text-center">
                                {row.id_forma_pago === 1 ? (
                                  <span>Efectivo</span>
                                ) : (
                                  ""
                                )}
                                {row.id_forma_pago === 2 ? (
                                  <span>Tarjeta de crédito/débito</span>
                                ) : (
                                  ""
                                )}
                                {row.id_forma_pago === 3 ? (
                                  <span>Gift Card</span>
                                ) : (
                                  ""
                                )}
                                {row.id_forma_pago === 4 ? (
                                  <span>Múltiple</span>
                                ) : (
                                  ""
                                )}
                                {row.id_forma_pago === 5 ? (
                                  <span>A credito</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            ),
                          },
                          {
                            name: "Monto",
                            selector: "monto_total",
                            cell: (row) => (
                              <span>
                                {moneda}.{" "}
                                {formaterPriceFunction(
                                  row.monto_total.toFixed(2) + ""
                                )}
                              </span>
                            ),
                            sortable: true,
                          },
                          {
                            name: "Acciones",
                            selector: "action",
                            cell: (row) => (
                              <div className="text-center w-100">
                                <FormControl variant="outlined">
                                  <Select
                                    native
                                    onChange={(e) => salesAction(e, row)}
                                    className="cs-select"
                                  >
                                    <option value="">Acciones</option>
                                    <option value="detalles">
                                      Ver detalles
                                    </option>
                                    <option value="emitir">
                                      Emitir nota de credito
                                    </option>
                                    <option value="rechazar">
                                      Anular venta
                                    </option>
                                  </Select>
                                </FormControl>
                              </div>
                            ),
                          },
                        ]}
                        data={filteredClientSales}
                        selectableRows
                        defaultSortField="fecha"
                        defaultSortAsc={false}
                        pagination
                        paginationPerPage={10}
                        paginationIconFirstPage={null}
                        paginationIconLastPage={null}
                        paginationComponentOptions={{
                          rangeSeparatorText: "de",
                          noRowsPerPage: true,
                          selectAllRowsItem: false,
                          selectAllRowsItemText: "All",
                        }}
                        noDataComponent="No se encontraron resultados"
                      />
                    )}
                  </div>
                </TabPanel>

                <TabPanel value={operationsTab} index={2}>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <div className="row history-headlines">
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            N° Facturas
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {numberFormater(clientCredits.length)}
                            </strong>
                          </h4>
                        </div>
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            Monto total
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {moneda}.{" "}
                              {clientCredits.length > 0
                                ? formaterPriceFunction(
                                    (
                                      clientCredits.reduce(
                                        (a, b) => a + b.monto,
                                        0
                                      ) / clientCredits.length
                                    ).toFixed(2) + ""
                                  )
                                : "0"}
                            </strong>
                          </h4>
                        </div>
                        <div className="col-md-8"></div>
                      </div>
                    </div>

                    <div className="col-12 my-3">
                      <div className="row justify-content-center justify-content-lg-between">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3 p-0 my-auto">
                          <div className="input-group search-bar h-100">
                            <input
                              type="text"
                              value={creditsFilterText}
                              onChange={(event) => filterCredits(event)}
                              className="form-control"
                              placeholder="Buscar por número de facturación"
                            />
                            <div className="input-group-append h-100">
                              <span className="input-group-text">
                                <SearchIcon />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <DataTable
                      className="datatable-cs datatable-cs-v2 mt-2"
                      title={"s3"}
                      columns={[
                        {
                          name: "N° Factura",
                          selector: "identificador",
                          sortable: true,
                        },
                        {
                          name: "Fecha / Hora",
                          selector: "fecha",
                          sortable: true,
                          cell: (row) => (
                            <div className="text-center w-100">
                              {moment(row.fecha)
                                .utcOffset(0)
                                .format("DD/MM/YYYY, h:mm:ss a")}
                            </div>
                          ),
                        },
                        {
                          name: "Vencimiento",
                          selector: "fecha_vencimiento",
                          sortable: true,
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.estado === "Por vencer" &&
                              moment(row.fecha_vencimiento).diff(
                                moment().subtract(15, "days"),
                                "days"
                              ) < 15 ? (
                                <span className="p-1 warning-span">
                                  {row.fecha_vencimiento}
                                </span>
                              ) : (
                                ""
                              )}
                              {row.estado === "Vencida" ? (
                                <span className="p-1 danger-span">
                                  {row.fecha_vencimiento}
                                </span>
                              ) : (
                                ""
                              )}
                              {row.estado === "Por vencer" &&
                              moment(row.fecha_vencimiento).diff(
                                moment().subtract(15, "days"),
                                "days"
                              ) > 15 ? (
                                <span className="p-1 success-span">
                                  {row.fecha_vencimiento}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          ),
                        },
                        {
                          name: "N° de productos",
                          selector: "nro_productos",
                          sortable: true,

                          cell: (row) => (
                            <div className="text-center w-100">
                              {numberFormater(row.numero_productos)}
                            </div>
                          ),
                        },
                        {
                          name: "Monto",
                          selector: "monto",
                          cell: (row) => (
                            <span>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                row.monto_deuda.toFixed(2) + ""
                              )}
                            </span>
                          ),
                          sortable: true,
                        },
                        {
                          name: "Acciones",
                          selector: "action",
                          cell: (row) => (
                            <div className="text-center w-100">
                              <FormControl variant="outlined">
                                <Select native value="" className="cs-select">
                                  <option value="">Acciones</option>
                                  <option value="detalles">Ver detalles</option>
                                  <option value="emitir">
                                    Emitir nota de credito
                                  </option>
                                  <option value="rechazar">Anular venta</option>
                                </Select>
                              </FormControl>
                            </div>
                          ),
                        },
                      ]}
                      data={filteredClientCredits}
                      selectableRows
                      defaultSortField="fecha"
                      defaultSortAsc={false}
                      pagination
                      paginationPerPage={10}
                      paginationIconFirstPage={null}
                      paginationIconLastPage={null}
                      paginationComponentOptions={{
                        rangeSeparatorText: "de",
                        noRowsPerPage: true,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: "All",
                      }}
                      noDataComponent="No se encontraron resultados"
                    />
                  </div>
                </TabPanel>

                <TabPanel value={operationsTab} index={3}>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <div className="row history-headlines">
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            N° Anticipos
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {numberFormater(clientAdvances.length)}
                            </strong>
                          </h4>
                        </div>
                        <div
                          className="col-md-2 px-2 mt-3"
                          style={{
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "1.25rem",
                              color: "black",
                            }}
                          >
                            Monto total
                          </p>
                          <h4 className="mb-0 secondary_color">
                            <strong>
                              {moneda}.{" "}
                              {clientAdvances.length > 0
                                ? formaterPriceFunction(
                                    (
                                      clientAdvances.reduce(
                                        (a, b) => a + b.monto,
                                        0
                                      ) / clientAdvances.length
                                    ).toFixed(2) + ""
                                  )
                                : "0.00"}
                            </strong>
                          </h4>
                        </div>
                        <div className="col-md-8"></div>
                      </div>
                    </div>

                    <div className="col-12 my-3">
                      <div className="row justify-content-center justify-content-lg-between">
                        <div className="col-lg-3">
                          <Select
                            native
                            value={""}
                            onChange={null}
                            style={{ width: "100%" }}
                            variant="outlined"
                          >
                            <option value="">Acciones</option>
                          </Select>
                        </div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3 p-0 my-auto">
                          <div className="input-group search-bar h-100">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Buscar por número de anticipo"
                              value={advancesFilterText}
                              onChange={(event) => filterAdvances(event)}
                            />
                            <div className="input-group-append h-100">
                              <span className="input-group-text">
                                <SearchIcon />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {advancesLoader ? (
                      <div className="text-center">
                        <CircularProgress
                          size={18}
                          className="mx-auto"
                          style={{ color: "black" }}
                        />
                      </div>
                    ) : (
                      <DataTable
                        className="datatable-cs datatable-cs-v2 mt-2"
                        title={"4s"}
                        columns={[
                          {
                            name: "N° anticipo",
                            selector: "nro_anticipo",
                            sortable: true,
                          },
                          {
                            name: "Concepto",
                            selector: "concepto",
                            sortable: true,
                          },
                          {
                            name: "Fecha / Hora",
                            selector: "fecha",
                            sortable: true,
                            cell: (row) => (
                              <div className="text-center w-100">
                                {moment(row.fecha)
                                  .utcOffset(0)
                                  .format("DD/MM/YYYY, h:mm:ss a")}
                              </div>
                            ),
                          },
                          {
                            name: "Monto",
                            selector: "monto",
                            cell: (row) => (
                              <span>
                                {moneda}.{" "}
                                {formaterPriceFunction(
                                  row.monto_disponible.toFixed(2) + ""
                                )}
                              </span>
                            ),
                            sortable: true,
                          },
                          {
                            name: "Acciones",
                            selector: "action",
                            cell: (row) => (
                              <div className="text-center w-100">
                                <FormControl variant="outlined">
                                  <Select
                                    native
                                    onChange={(e) => anticiposAction(e, row)}
                                    className="cs-select"
                                  >
                                    <option value="">Acciones</option>
                                    <option value="detalles">
                                      Ver detalles
                                    </option>
                                    <option value="liquidar">Liquidar</option>
                                    <option value="recibo">
                                      Descargar recibo
                                    </option>
                                  </Select>
                                </FormControl>
                              </div>
                            ),
                          },
                        ]}
                        data={filteredClientAdvances}
                        selectableRows
                        defaultSortField="fecha"
                        defaultSortAsc={false}
                        pagination
                        paginationPerPage={10}
                        paginationIconFirstPage={null}
                        paginationIconLastPage={null}
                        paginationComponentOptions={{
                          rangeSeparatorText: "de",
                          noRowsPerPage: true,
                          selectAllRowsItem: false,
                          selectAllRowsItemText: "All",
                        }}
                        noDataComponent="No se encontraron resultados"
                      />
                    )}
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
      <TabPanel value={selectedTab} index={1} analytics={true}>
        <Analytics />
      </TabPanel>

      {/* SALDAR FACTURA MODAL */}
      <div class="modal fade" id="saldar_factura" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content" style={{ minHeight: "450px" }}>
            <div class="d-flex justify-content-end px-2 py-1">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body card content-card mt-2 p-0">
              <div
                className="card-header saldar-factura-header"
                style={{ boxShadow: "none" }}
              >
                <div className="row h-100">
                  <div className="col-sm-8 d-flex align-items-center justify-content-center flex-wrap mb-3">
                    <div
                      className="payment-method-box"
                      id="box-1"
                      onClick={() => handleBoxClick(1)}
                    >
                      <LocalAtmIcon />
                      <p>Efectivo</p>
                    </div>
                    <div
                      className="payment-method-box"
                      id="box-2"
                      onClick={() => handleBoxClick(2)}
                    >
                      <CreditCardIcon />
                      <p>Tarjeta</p>
                    </div>
                    {/*  <div
                                            className="payment-method-box"
                                            id="box-3"
                                            onClick={() => handleBoxClick(3)}
                                        >
                                            <CardGiftcardIcon />
                                            <p>Gift card</p>
                                        </div> */}
                    <div
                      className="payment-method-box"
                      id="box-4"
                      onClick={() => handleBoxClick(4)}
                    >
                      <AddBoxIcon />
                      <p>Multiples</p>
                    </div>
                  </div>
                  <div className="col-sm-4 invoice-column text-center titles saldar-blue-box">
                    <p className="my-auto">Monto a saldar:</p>
                    <TextField
                      type="number"
                      className="w-75 mb-2"
                      variant="standard"
                      value={
                        selectedCredit.monto
                          ? selectedCredit.monto.toFixed(2)
                          : ""
                      }
                      placeholder="Monto a saldar"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                        inputProps: { min: 0 },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      {selectedMethods.includes(1) ||
                      selectedMethods.includes(4) ? (
                        <div className="col-md">
                          <p>
                            <strong>Efectivo</strong>
                          </p>
                          <TextField
                            type="number"
                            className="w-100 my-2"
                            variant="standard"
                            step="any"
                            value={paidByCash === 0 ? "" : paidByCash}
                            name="paidByCash"
                            onChange={(e) => updateValue(e)}
                            placeholder="Monto pagado en efectivo"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {selectedMethods.includes(2) ||
                      selectedMethods.includes(4) ? (
                        <div className="col-md">
                          <p>
                            <strong>Tarjeta de débito/crédito</strong>
                          </p>
                          <TextField
                            className="w-100 my-2"
                            variant="standard"
                            type="number"
                            value={paidByCard === 0 ? "" : paidByCard}
                            name="paidByCard"
                            onChange={(e) => updateValue(e)}
                            placeholder="Monto pagado por tarjeta de débito/crédito"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {selectedMethods.includes(3) ? (
                        <div className="col-md">
                          <p>
                            <strong>Gift card</strong>
                          </p>
                          <TextField
                            type="number"
                            className="w-100 my-2"
                            variant="standard"
                            value={paidByGiftCard === 0 ? "" : paidByGiftCard}
                            name="paidByGiftCard"
                            onChange={(e) => updateValue(e)}
                            placeholder="Monto pagado por Gift Card"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {!selectedMethods.includes(5) &&
                      selectedMethods[0] !== 1 &&
                      selectedMethods[0] !== 0 ? (
                        <>
                          <div className="col-12 mt-3">
                            <p class="text-left my-auto">
                              <strong>Verificación del pago</strong>
                            </p>
                          </div>

                          {selectedMethods.includes(2) ||
                          selectedMethods.includes(4) ? (
                            <>
                              <div className="col-md-6 my-auto">
                                <p>
                                  <strong>
                                    Número del recibo (Operación con tarjeta)
                                  </strong>
                                </p>
                                <TextField
                                  className="w-100"
                                  variant="standard"
                                  value={invoice_number}
                                  name="invoice_number"
                                  onChange={(e) =>
                                    setInvoiceNumber(e.target.value)
                                  }
                                  placeholder="Ingresar numero de recibo"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        N°
                                      </InputAdornment>
                                    ),
                                  }}
                                  disabled={!check1}
                                />
                              </div>
                              <div className="col-md-6 my-auto">
                                <p>
                                  <strong>Número de cuotas</strong>
                                </p>

                                <Select
                                  native
                                  value={periods}
                                  onChange={changePeriods}
                                  style={{
                                    width: "100%",
                                  }}
                                  variant="outlined"
                                >
                                  {payment_periods.map((period) => (
                                    <option value={period.value}>
                                      {period.option}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {selectedMethods.includes(3) ? (
                            <div className="col-md my-auto">
                              <p>
                                <strong>Ingrese el código de Gift Card</strong>
                              </p>
                              <TextField
                                className="w-100"
                                variant="standard"
                                value={giftcard_code}
                                onChange={(e) =>
                                  setGiftCardCode(e.target.value)
                                }
                                placeholder="     -      -      -"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {selectedMethods.includes(2) ||
                          selectedMethods.includes(4) ? (
                            <>
                              <div className="col-md-12">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="check1"
                                    checked={check1}
                                    onChange={toggleCheck}
                                  />
                                  <label
                                    class="form-check-label ml-2"
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    Ingresar manualmente
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 mt-5 pl-5">
                                <div className="row w-100 justify-content-center justify-content-md-start">
                                  <div className="col-md-1 text-center mb-2">
                                    <AddIcon
                                      className="bg_secondary_color"
                                      style={{
                                        fontSize: "30px",
                                        color: "white",
                                      }}
                                      onClick={addNewAdditionalInvoice}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <p className="mb-0">
                                      <strong>
                                        Número del recibo de pago para tarjeta
                                        adicional
                                      </strong>
                                    </p>
                                    <TextField
                                      className="w-100"
                                      variant="standard"
                                      value={additional_invoice}
                                      onChange={(e) =>
                                        setAdditionalInvoice(e.target.value)
                                      }
                                      placeholder="Ingrese el número del recibo emitido con una tarjeta adicional"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            N°
                                          </InputAdornment>
                                        ),
                                      }}
                                      disabled={!check2}
                                    />
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="check2"
                                        checked={check2}
                                        onChange={toggleCheck}
                                      />
                                      <label
                                        class="form-check-label ml-2"
                                        for="defaultCheck1"
                                        style={{
                                          fontSize: "0.8rem",
                                        }}
                                      >
                                        Ingresar manualmente
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row w-100 mt-2 justify-content-center justify-content-md-start">
                                  {additional_invoices.map((invoice) => (
                                    <div className="col-xl-4 px-1">
                                      <TextField
                                        className="w-100"
                                        variant="standard"
                                        disabled
                                        value={invoice}
                                        name="invoice_number"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              Recibo N°
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center px-5 py-3">
                <Button
                  className="btn-cs1 icon-btn-delete mx-1"
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  <span style={{ marginBottom: "-4px" }}>Cancelar</span>
                </Button>
                <Button
                  className="btn-cs1_blue mx-1"
                  variant="contained"
                  color="primary"
                  onClick={submitSaldar}
                >
                  {saldarFacturaLoader ? (
                    <div className="text-center">
                      <CircularProgress
                        size={18}
                        className="mx-auto"
                        style={{ color: "white" }}
                      />
                    </div>
                  ) : (
                    <span style={{ marginBottom: "-4px" }}>Guardar</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* LIQUIDAR ANTICIPOS MODAL */}

      <div
        class="modal fade"
        id="liquidar_anticipo"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div
            class="modal-content"
            style={{ minHeight: "500px", minWidth: "550px" }}
          >
            <div class="d-flex justify-content-end px-2 py-1">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body card content-card mt-2 p-0">
              <div
                className="card-header liquidar-anticipo-header"
                style={{ boxShadow: "none", height: "auto !important" }}
              >
                <div className="row">
                  <div className="col-sm-8 d-flex align-items-center justify-content-center flex-wrap mb-3">
                    <h3>Liquidación de anticipos</h3>
                    <DataTable
                      className="datatable-cs datatable-cs-v2 mt-2"
                      title={"s_anticipos"}
                      columns={[
                        {
                          name: "N°",
                          selector: "nro_anticipo",
                        },
                        {
                          name: "Monto",
                          selector: "monto",
                          cell: (row) => (
                            <span>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                row.monto_disponible.toFixed(2) + ""
                              )}
                            </span>
                          ),
                        },
                      ]}
                      data={selectedAdvances}
                      pagination
                      paginationPerPage={3}
                      paginationIconFirstPage={null}
                      paginationIconLastPage={null}
                      paginationComponentOptions={{
                        rangeSeparatorText: "de",
                        noRowsPerPage: true,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: "All",
                      }}
                      noDataComponent="No se han seleccionado anticipos"
                      onSelectedRowsChange={handleAdvancesSelect}
                    />
                  </div>
                  <div
                    className="col-sm-4 invoice-column text-center titles saldar-blue-box my-auto"
                    style={{ height: "138px" }}
                  >
                    <p className="my-auto">Monto a adjudicar:</p>
                    <TextField
                      type="number"
                      className="w-75 mb-2"
                      variant="standard"
                      value={
                        selectedMethod === 1
                          ? selectedAdvances
                              .reduce((a, b) => a + b.monto_disponible, 0)
                              .toFixed(2)
                          : debtPaymentType === "parcial"
                          ? partialPayment
                          : selectedDuesToPay.length > 0
                          ? selectedDuesToPay
                              .reduce((a, b) => a + b.monto_deuda, 0)
                              .toFixed(2)
                          : "0"
                      }
                      placeholder="Monto a saldar"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                        inputProps: { min: 0 },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body row facturacion-products-header w-100 mx-auto">
                {selectedMethod === 0 ? (
                  <>
                    <div
                      className="col-sm-6 d-flex justify-content-center align-items-center p-0"
                      style={{ backgroundColor: "#F2F2F2" }}
                    >
                      <Button
                        className="btn-cs1 mx-1 mt-2"
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectedMethod(1)}
                      >
                        <span style={{ marginBottom: "-4px" }}>
                          Nueva venta
                        </span>
                      </Button>
                    </div>
                    <div
                      className="col-sm-6 d-flex justify-content-center align-items-center p-0"
                      style={{ backgroundColor: "#F2F2F2" }}
                    >
                      <Button
                        className="btn-cs1 mx-1 mt-2"
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectedMethod(2)}
                      >
                        <span style={{ marginBottom: "-4px" }}>
                          Pagar saldo deudor
                        </span>
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {selectedMethod === 1 ? (
                  <>
                    <div className="col-sm-4 d-flex justify-content-center align-content-around barra-liquidacion px-0 py-4 flex-wrap">
                      <div class="input-group search-bar autosuggest-search-bar">
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                          }
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                        />
                        <div class="input-group-append">
                          <span class="input-group-text autosuggest-search-icon">
                            <SearchIcon />
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                        <Button
                          className="btn-cs1 icon-btn-delete mx-1 mb-2"
                          variant="contained"
                          color="primary"
                          onClick={() => setSelectedMethod(0)}
                        >
                          <span style={{ marginBottom: "-4px" }}>Cancelar</span>
                        </Button>
                        <Button
                          className="btn-cs1_blue mx-1 mb-2"
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={liquidar_venta}
                        >
                          {submitLoader ? (
                            <div className="text-center">
                              <CircularProgress
                                size={18}
                                className="mx-auto"
                                style={{ color: "white" }}
                              />
                            </div>
                          ) : (
                            <span style={{ marginBottom: "-4px" }}>Pagar</span>
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="col-sm-8 p-0">
                      <DataTable
                        className="datatable-cs datatable-cs-v2 mt-2 liquidar-anticipos-table"
                        title={"s_anticipos"}
                        columns={[
                          {
                            name: "Producto",
                            selector: "nro_anticipo",
                            cell: (row) => (
                              <div className="d-flex align-items-center">
                                <IconButton
                                  className="mx-1 no-outline-buttons"
                                  onClick={() => removeProduct(row.id_new_item)}
                                  size="small"
                                  style={{
                                    background: "none",
                                    color: "#EB5757",
                                    fontSize: "20px",
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>

                                <div>
                                  <small
                                    style={{
                                      fontSize: "11.4px",
                                    }}
                                  >
                                    {row.nombre}
                                  </small>
                                  <br />
                                  <small
                                    style={{
                                      fontSize: "11.4px",
                                    }}
                                  >
                                    {row.codigo}
                                  </small>
                                </div>
                              </div>
                            ),
                          },
                          {
                            name: "Precio",
                            selector: "total",
                            sortable: true,
                            cell: (row) => (
                              <div>
                                {moneda}.{" "}
                                {formaterPriceFunction(
                                  row.total.toFixed(2) + ""
                                )}
                              </div>
                            ),
                          },
                          {
                            name: "Cantidad",
                            selector: "cantidad",
                            sortable: true,
                            cell: (row) => (
                              <div
                                key={"quantity-section-" + row.id_new_item}
                                className="w-100 p-0 d-flex align-items-center justify-content-around"
                              >
                                <IconButton
                                  aria-label="delete"
                                  className="mx-1 no-outline-buttons bg_secondary_color"
                                  onClick={() =>
                                    changeProductQuantity(
                                      row.id_new_item,
                                      "sub",
                                      false
                                    )
                                  }
                                  size="small"
                                  style={{
                                    color: "white",
                                  }}
                                >
                                  <RemoveIcon />
                                </IconButton>
                                <TextField
                                  className="quantity-inputs"
                                  variant="outlined"
                                  defaultValue={Math.abs(row.cantidad)}
                                  type="number"
                                  id={"value-" + row.id_new_item}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter")
                                      changeProductQuantity(
                                        row.id_new_item,
                                        "none",
                                        true
                                      );
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          className="mx-1 no-outline-buttons"
                                          onClick={() =>
                                            changeProductQuantity(
                                              row.id_new_item,
                                              "none",
                                              true
                                            )
                                          }
                                          size="small"
                                          style={{
                                            color: "black",
                                          }}
                                        >
                                          <SendIcon
                                            style={{
                                              fontSize: "8px",
                                            }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    inputProps: {
                                      min: 1,
                                    },
                                  }}
                                />
                                <IconButton
                                  aria-label="delete"
                                  className="mx-1 no-outline-buttons bg_secondary_color"
                                  onClick={() =>
                                    changeProductQuantity(
                                      row.id_new_item,
                                      "sum",
                                      false
                                    )
                                  }
                                  size="small"
                                  style={{
                                    color: "white",
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </div>
                            ),
                          },
                        ]}
                        data={selectedProducts}
                        pagination
                        paginationPerPage={3}
                        paginationIconFirstPage={null}
                        paginationIconLastPage={null}
                        paginationComponentOptions={{
                          rangeSeparatorText: "de",
                          noRowsPerPage: true,
                          selectAllRowsItem: false,
                          selectAllRowsItemText: "All",
                        }}
                        noDataComponent="No se han seleccionado productos"
                      />
                      <div className="row p-0 invoice-column mx-1">
                        <div className="col-6 p-0 m-0 text-center titles my-auto">
                          {/* <p>SubTotal:</p>
                                                        <p>IVA (12%):</p> */}
                          <p>Total:</p>
                        </div>
                        <div className="col-6 p-0 m-0 text-center my-auto costs">
                          {/*   <p>{moneda}. {salesCost.subtotal.toFixed(2)}</p>
                                                        <p>{moneda}. {salesCost.iva.toFixed(2)}</p> */}
                          <p>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              salesCost.total.toFixed(2) + ""
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {selectedMethod === 2 ? (
                  <>
                    <div className="col-sm-4">
                      <div className="row h-100 justify-content-center align-content-between">
                        <div className="col-12">
                          <div className="d-flex justify-content-between align-items-center">
                            <label style={{ fontSize: "0.8rem" }}>
                              <strong>Tipo de pago</strong>
                            </label>
                          </div>

                          <FormControl variant="outlined" className="w-100">
                            <Select
                              inputRef={register}
                              native
                              value={debtPaymentType}
                              onChange={(e) =>
                                setDebtPaymentType(e.target.value)
                              }
                              className="cs-select1 select-w100"
                            >
                              <option value="">
                                Seleccione el tipo de pago
                              </option>
                              <option value="total">Total</option>
                              <option value="parcial">Parcial</option>
                            </Select>
                          </FormControl>

                          <TextField
                            type="number"
                            className="w-100 my-3"
                            placeholder="Ingrese la cantidad a pagar"
                            value={
                              debtPaymentType === "total"
                                ? selectedMethod === 1
                                  ? selectedAdvances
                                      .reduce(
                                        (a, b) => a + b.monto_disponible,
                                        0
                                      )
                                      .toFixed(2)
                                  : debtPaymentType === "parcial"
                                  ? partialPayment
                                  : selectedDuesToPay.length > 0
                                  ? selectedDuesToPay
                                      .reduce((a, b) => a + b.monto_deuda, 0)
                                      .toFixed(2)
                                  : "0"
                                : partialPayment === 0
                                ? ""
                                : partialPayment
                            }
                            name="partialPayment"
                            onChange={(e) => updateValue(e)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                                step: "0.00001",
                              },
                            }}
                            disabled={
                              debtPaymentType === "total" ? true : false
                            }
                          />
                        </div>
                        <div className="col-12">
                          <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                            <Button
                              className="btn-cs1 icon-btn-delete mx-1 mb-2"
                              variant="contained"
                              color="primary"
                              onClick={() => setSelectedMethod(0)}
                            >
                              <span style={{ marginBottom: "-4px" }}>
                                Cancelar
                              </span>
                            </Button>
                            <Button
                              className="btn-cs1 mx-1 mb-2"
                              variant="contained"
                              color="primary"
                              onClick={liquidar_deudas}
                            >
                              {submitLoader ? (
                                <div className="text-center">
                                  <CircularProgress
                                    size={18}
                                    className="mx-auto"
                                    style={{ color: "white" }}
                                  />
                                </div>
                              ) : (
                                <span style={{ marginBottom: "-4px" }}>
                                  Pagar
                                </span>
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-8 d-flex justify-content-end">
                      <div className="row p-0 invoice-column mx-1">
                        <div className="col-6 p-0 m-0 text-center titles my-auto">
                          <p>Total:</p>
                        </div>
                        <div className="col-6 p-0 m-0 text-center my-auto costs">
                          <p>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              selectedDuesToPay
                                .reduce((a, b) => a + b.monto_deuda, 0)
                                .toFixed(2) + ""
                            )}
                          </p>
                        </div>
                        <div className="col-12 p-0 w-100">
                          <DataTable
                            className="datatable-cs datatable-cs-v2 mt-2"
                            title={"s_anticipos"}
                            columns={[
                              {
                                name: "N°",
                                selector: "identificador",
                                sortable: true,
                                cell: (row) => (
                                  <div className="d-flex justify-content-between align-items-center flex-wrap h-100 w-100 p-0">
                                    <div
                                      className="h-100"
                                      style={{
                                        width: "15%",
                                      }}
                                    >
                                      {row.estado === "Por vencer" ? (
                                        <span className="p-2 warning-span"></span>
                                      ) : (
                                        ""
                                      )}
                                      {row.estado === "Vencida" ? (
                                        <span className="p-2 danger-span"></span>
                                      ) : (
                                        ""
                                      )}
                                      {row.estado === "Pagada parcialmente" ? (
                                        <span className="p-2 warning-span"></span>
                                      ) : (
                                        ""
                                      )}
                                      {row.estado === "Pagada" ? (
                                        <span className="p-2 success-span"></span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className="h-100"
                                      style={{ width: "85%" }}
                                    >
                                      {row.identificador}
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: "Fecha",
                                selector: "fecha_vencimiento",
                                sortable: true,
                                cell: (row) => (
                                  <div className="text-center w-100">
                                    {moment(row.fecha_vencimiento)
                                      .utcOffset(0)
                                      .format("DD/MM/YYYY, h:mm:ss a")}
                                  </div>
                                ),
                              },
                              {
                                name: "Monto",
                                selector: "monto",
                                cell: (row) => (
                                  <span>
                                    {moneda}.{" "}
                                    {formaterPriceFunction(
                                      row.monto_deuda.toFixed(2) + ""
                                    )}
                                  </span>
                                ),
                                sortable: true,
                              },
                            ]}
                            data={completeClientCredits.filter(
                              (x) => x.estado !== "Pagada"
                            )}
                            selectableRows
                            pagination
                            defaultSortField="fecha"
                            defaultSortAsc={false}
                            paginationPerPage={3}
                            paginationIconFirstPage={null}
                            paginationIconLastPage={null}
                            paginationComponentOptions={{
                              rangeSeparatorText: "de",
                              noRowsPerPage: true,
                              selectAllRowsItem: false,
                              selectAllRowsItemText: "All",
                            }}
                            noDataComponent="No hay deudas disponibles"
                            onSelectedRowsChange={handleDuesSelect}
                            clearSelectedRows={clearRows}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
