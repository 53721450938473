import React from "react";
import Employees from "./Employees";
import Markets from "./Markets";

const BottomSection = ({ actualSucursal, loader }) => {
  return (
    <div className="row mt-3">
      <div className="col-md-6">
        <Markets sucursal={actualSucursal} loader={loader} />
      </div>
      <div className="col-md-6">
        <Employees sucursal={actualSucursal} loader={loader} />
      </div>
    </div>
  );
};

export default BottomSection;
