// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import Egreso from "./Components/EgresoRecibo";
import print from "print-js";
// components

export default function Users_all(props) {
  const moment = require("moment");
  //cleap up - componentWillUnmount

  useEffect(() => {
    getEgreso();
  }, []);

  // states
  const [loader, setLoader] = useState(true);
  const [egreso, set_egreso] = useState({
    id_egreso_web_app: "",
    id_new_empresa: "",
    id_new_user: "",
    id_new_ubicacion: "",
    id_new_bodega: "",
    id_new_sucursal: "",
    id_new_motivo: "",
    id_new_tipo_documento: "",
    fecha_ingreso: "",
    serie: "",
    numero: "",
    operacion: "",
    motivo: "",
    documentType: "",
    usuario: "",
    total_productos: "",
    egresoDetalle: [
      {
        id_egreso_detalle_web_app: "",
        id_new_empresa: "",
        id_egreso_web_app: "",
        id_new_item: "",
        cantidad: "",
        precio: "",
        lote: "",
        fecha: "",
      },
    ],
  });
  const [companyName, setCompanyName] = useState(null);
  // set states

  //function

  const getEgreso = () => {
    let id = props.match.params.id.toString();
    API.egresos
      .getById({ id_egreso_web_app: id })
      .then((res) => {
        let response = res.data;
        console.log("obtained", response.data);
        if (response.success) {
          set_egreso(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  const generatePdf = (name) => {
    const element = document.querySelector(".invoice-target");

    window.html2canvas = html2canvas;
    const pdf = new jsPDF("l", "pt", "a4");
    pdf.html(element, {
      callback: function (doc) {
        pdf.save(name);
      },
      html2canvas: { scale: 0.5 },
    });
  };

  const printSale = () => {
    print({
      printable: "printform",
      type: "html",
      targetStyles: ["*"],
    });
  };

  useEffect(() => {
    if (egreso.id_egreso_web_app !== "" && companyName) {
      printSale();
    }
  }, [egreso, companyName]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await API.profile.getCompanyData();
        setCompanyName(data.data.nombre);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Detalles del egreso</strong>
        </h4>
      </div>

      <div className="text-right">
        <Button
          type="submit"
          className="btn-cs1 mx-2 my-auto"
          variant="contained"
          color="primary"
          to={"/app/inventario"}
          component={Link}
        >
          Volver
        </Button>
        <Button
          className="btn-cs1 mx-2 my-auto"
          variant="contained"
          color="primary"
          onClick={() => generatePdf(`Recibo egreso ${egreso.identificador}`)}
        >
          Descargar recibo
        </Button>

        <Button
          className="btn-cs1 mx-2 my-auto"
          variant="contained"
          color="primary"
          onClick={printSale}
          disabled={egreso.id_ingreso_web_app === "" && !companyName}
        >
          Imprimir
        </Button>
      </div>

      <form autocomplete="on" className="invoice-target">
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Detalles del egreso</strong>
                </h5>
                <p className="mb-0">
                  Consulte los detalles acerca de su egreso
                </p>
              </div>
            </div>
          </div>
          <div className="card-body">
            {loader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <div className="row mb-4">
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Motivo del egreso</strong>
                  </label>

                  <p>{egreso.motivo}</p>
                </div>
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Sucursal que egresará</strong>
                  </label>

                  <p>{egreso.nombre_sucursal}</p>
                </div>
                <div className="col-md-2 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Número de egreso</strong>
                  </label>
                  <p>{egreso.numero}</p>
                </div>
                <div className="col-md-2 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Fecha del egreso</strong>
                  </label>

                  <p>
                    {moment(new Date(egreso.fecha_ingreso)).format(
                      "YYYY-MM-DD"
                    )}
                  </p>
                </div>

                {/* Second Row */}
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Productos</strong>
                    </label>
                  </div>
                  <p className="mb-2">Listados de productos a egresar.</p>
                </div>

                <div className="col-md-8 mt-3">
                  {egreso.items.map((product, index) => (
                    <div className="row my-4">
                      <div className="col-md-6 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Código del producto</strong>
                        </label>

                        <TextField
                          disabled
                          value={product.codigo}
                          type="text"
                          className="w-100"
                          placeholder=""
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Cantidad a egresar</strong>
                        </label>
                        <TextField
                          disabled
                          value={
                            product.vendido_por === "mixto"
                              ? product.cantidad_peso
                              : product.cantidad
                          }
                          type="number"
                          className="w-100"
                          placeholder=""
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                N°
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Nombre del producto</strong>
                        </label>

                        <TextField
                          disabled
                          value={product.nombre}
                          type="text"
                          className="w-100"
                          placeholder="Nombre del producto"
                        />
                      </div>
                      {product.vendido_por === "mixto" && (
                        <div className="col-md-6 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Cantidad a egresar en libras</strong>
                          </label>

                          <TextField
                            disabled
                            value={product.cantidad}
                            type="text"
                            className="w-100"
                            placeholder="Nombre del producto"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  lbs
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <Egreso egreso={egreso} companyName={companyName} />
    </div>
  );
}
