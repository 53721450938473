import React from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const LocalLeves = (props) => {
  const { id, precio, unidad_venta, userType, deleteNivelesLocalStorage } =
    props;

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="col-md-12 d-flex justify-content-center align-items-center">
      <div className="col-md-4 d-flex justify-content-center align-items-center">
        <div className="col-md-2 btn_eliminar_nivel_precio">
          <IconButton color="secondary">
            <DeleteIcon
              style={{
                color: "white",
              }}
              onClick={(e) => deleteNivelesLocalStorage(id)}
            />
          </IconButton>
        </div>
        <div className="col-md-10">
          <div className="row col-md-12 h-50 justify-content-between m-0 p-0">
            <label style={{ fontSize: "0.8rem" }} className="w-100">
              <strong>Tipo de usuario</strong>
            </label>
          </div>
          <div className="col-md-12 h-50 row align-items-center">
            <label style={{ fontSize: "0.8rem" }}>{userType}</label>
          </div>
        </div>
      </div>
      <div className="col-md-4 d-flex justify-content-center align-items-center">
        <div className="col-md-12">
          <div className="row col-md-12 h-50 m-0 p-0">
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Unidad de venta</strong>
            </label>
          </div>
          <div className="col-md-12 h-50 row align-items-center">
            <label>{unidad_venta}</label>
          </div>
        </div>
      </div>
      <div className="col-md-4 d-flex justify-content-center align-items-center">
        <div className="col-md-12">
          <div className="row col-md-12 h-50 m-0 p-0">
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Precio de venta</strong>
            </label>
          </div>
          <div className="col-md-12 h-50 row align-items-center">
            <label>{formaterPriceFunction(precio + "")}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalLeves;
