import {
  Backdrop,
  Button,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState, useEffect, useContext } from "react";
import InfoIcon from "@material-ui/icons/Info";
import { ReportProblemOutlined } from "@material-ui/icons";
import ModalAutorizacion from "./ModalAutorizacion";
import CmiProductContext from "../../../context/CmiProductContext/CmiProductContext";
import { getMoneda } from "../../../helpers/moneda";
import { formaterPriceFunction } from "../../../helpers/formater";
const ModalAnulacion = ({
  gotoItemInvoice,
  submit_form,

  currentMovement,
  gerentes,
  crear_anulacion,
  row,
  setCurrentMovement,
  check,
  header,
  warning,
  label,
  placeholder,
  infotip,
  loading,
}) => {
  const {
    activarModal,
    setActivarModal,
    setMontoRetiro,
    setRetiroData,
    retiroData,
  } = useContext(CmiProductContext);
  const moneda = getMoneda();
  const { setMovimiento } = useContext(CmiProductContext);
  const [gerente, setGerente] = useState(null);
  const [motivo, setMotivo] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    // setMovimiento(row);
    if (check === "retiro") {
      setActivarModal(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (check === "retiro") {
      setActivarModal(false);
    }
  };
  const handleGerente = (e) => {
    const currentGerente = gerentes.find(
      (item) => item.user.id_new_user === +e.target.value
    );
    setGerente(currentGerente?.user);
    if (check === "retiro") {
      setRetiroData({
        ...retiroData,
        id_new_usuario_gerente: +e.target.value,
      });
    }
  };
  useEffect(() => {
    if (activarModal) {
      handleOpen();
    }
    return () => {
      setActivarModal(false);
    };
  }, [activarModal]);

  return (
    <div>
      {check === "anulacion" && (
        <Button
          className="btn-cs1 delete-btn w-100"
          variant="contained"
          color="primary"
          onClick={handleOpen}
          style={{
            width: "150px",
            height: "43px",
            boxShadow: "none",
          }}
        >
          <span style={{ fontSize: "14px" }}>Anular</span>
        </Button>
      )}
      {check === "retiro" && (
        <Button
          variant="outlined"
          color="primary"
          style={{
            width: "150px",
            height: "43px",
            boxShadow: "none",
          }}
          onClick={handleOpen}
        >
          <span
            style={{
              color: "#0160E7",
              fontSize: "14px",
            }}
          >
            Retiro de efectivo
          </span>
        </Button>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={check === "anulacion" ? open : activarModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={check === "anulacion" ? open : activarModal}>
          <div
            div
            className="card"
            style={{ maxWidth: "577px", border: "none" }}
          >
            <div className="card-title d-flex justify-content-between align-items-center border-bottom py-3">
              <strong style={{ marginLeft: "17px" }}>
                <span style={{ fontSize: "20px" }}>{header}</span>
              </strong>
              <IconButton
                type="button"
                onClick={handleClose}
                style={{ marginRight: "17px" }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-6">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>{label}</strong>
                  </label>
                  <TextField
                    type={check === "retiro" ? "number" : "text"}
                    InputProps={{
                      startAdornment: (
                        <>
                          {check === "retiro" && (
                            <InputAdornment position="start">
                              {moneda}.
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                    fullWidth
                    placeholder={placeholder}
                    value={motivo}
                    onChange={(e) => {
                      setMotivo(e.target.value);
                      if (check === "retiro") {
                        setMontoRetiro(parseFloat(e.target.value));
                        setRetiroData({
                          ...retiroData,
                          cantidad_retiro: parseFloat(e.target.value),
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Gerente</strong>
                    </label>
                    <Tooltip title={infotip}>
                      <InfoIcon
                        style={{ color: " #828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      native
                      style={{ height: "32px" }}
                      onChange={handleGerente}
                    >
                      <option value="">Seleccionar</option>
                      {Array.isArray(gerentes) &&
                        gerentes.map((gerente) => (
                          <option value={gerente.user.id_new_user}>
                            {gerente.user.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="col-md-12 mt-4 px-0">
                <div
                  className="row"
                  style={{
                    background: "#F2F2F2",
                    margin: "0px",
                    padding: "15px",
                    borderRadius: "8px",
                  }}
                >
                  <div className="col-1 d-flex align-items-center justify-content-center">
                    <span>
                      <ReportProblemOutlined
                        style={{ color: "#0160E7", fontSize: "30px" }}
                      />
                    </span>
                  </div>
                  <div className="col-11 d-flex align-items-center">
                    <span>{warning}</span>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center mt-2">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    marginRight: "10px",
                    height: "42px",
                    width: "150px",
                  }}
                  onClick={() =>
                    check === "anulacion"
                      ? gotoItemInvoice(currentMovement)
                      : handleClose()
                  }
                >
                  <span
                    style={{
                      color: true ? "#0160E7" : "#BDBDBD",
                      fontSize: "14px",
                    }}
                  >
                    {check === "retiro" ? "Cancelar" : "Ver factura"}
                  </span>
                </Button>
                &nbsp;
                {/* <Button
                  variant="contained"
                  style={{
                    background: true ? "#0160E7" : "#BDBDBD",
                    boxShadow: "none",
                    width: "150px",
                    height: "42px",
                  }}
                  onClick={() => submit_form(currentMovement)}
                >
                  <span style={{ color: "#fff", fontSize: "14px" }}>
                    Aceptar
                  </span>
                </Button> */}
                <ModalAutorizacion
                  gerente={gerente}
                  motivo={motivo}
                  currentMovement={row}
                  submit_form={submit_form}
                  check={check}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalAnulacion;
