import React from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo

export default function Error(props) {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Typography
        variant="h1"
        color="primary"
        className={classnames(classes.textRow, classes.errorCode)}
      >
        404
      </Typography>
      <Paper classes={{ root: classes.paperRoot }}>
        <div className={classes.logotype}>
          <img
            className={classes.logotypeIcon}
            src={"/images/cloudLogo404.png"}
            alt="logo"
          />
        </div>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          La página a la que llegaste no existe
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.history.goBack()}
          size="large"
          className={classes.backButton}
        >
          Volver
        </Button>
      </Paper>
    </Grid>
  );
}
