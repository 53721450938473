import { TextField } from "@material-ui/core";
import React, { useState } from "react";

const InputIdp = ({
  initialValue,
  id,
  changeProductQuantity,
  setCurrentCell,
  currentCell,
}) => {
  const [value, setValue] = useState(initialValue);
  const onChange = (e) => {
    const t = e.target.value;
    e.target.value =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
        : t;
    setValue(e.target.value);
    changeProductQuantity(id, "idp", false, e.target.value);
  };

  return (
    <TextField
      autoFocus={id === currentCell}
      onSelect={() => setCurrentCell(id)}
      value={value}
      onChange={onChange}
      type="number"
    />
  );
};

export default InputIdp;
