import React from "react";

//estilos
import "./reportes.css";

import { getMoneda } from "../helpers/moneda";

const ProductosSap = ({ objeto }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="productos-sap">
        <thead>
          <tr>
            <th>Código SAP</th>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Cantidad</th>
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            return (
              <tr key={index}>
                <td>{x.codigo_sap}</td>

                <td>{x.nombre}</td>
                <td>
                  {moneda}. {formaterPriceFunction(x.precio + "")}
                </td>
                <td>
                  {x.cantidad} {x.vendido_por === "unidades" ? `und. ` : "lbs."}
                </td>
                <td>
                  {moneda}. {formaterPriceFunction(x.total + "")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProductosSap;
