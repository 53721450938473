import React, { useContext, useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CheckCircleOutlined } from "@material-ui/icons";
import CambioMasivoContext from "../../../../context/CambioMasivoContext/CambioMasivoContext";

export default function PrecioActualizadoModal() {
  const { updated } = useContext(CambioMasivoContext);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (updated) {
      handleOpen();
    }
  }, [updated]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          className="card"
          style={{ width: "500px", border: "none", outline: "none" }}
        >
          <div className="card-title d-flex justify-content-end align-items-center border-bottom py-3">
            <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
            <CheckCircleOutlined
              className="color-css-text"
              style={{ fontSize: "60px" }}
            />

            <h4 className="text-center">
              <strong>Precios actualizados exitosamente.</strong>
            </h4>
            <p className="text-center">
              Se ha actualizado con éxito el precio del producto en todas las
              tiendas seleccionadas.
            </p>
          </div>
          <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-center">
            <Button
              variant="contained"
              id="back-css"
              style={{
                boxShadow: "none",
                width: "150px",
                height: "42px",
              }}
              onClick={handleClose}
            >
              <span style={{ color: "#fff", fontSize: "14px" }}>Aceptar</span>
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
