import React from "react";
import { numberFormater } from "../../../../../helpers/formater";
import { getMoneda } from "../../../../../helpers/moneda";
const getVista = (vista) => {
  switch (vista) {
    case 0:
      return "frontal";
    case 1:
      return "derecho";
    case 2:
      return "Izquierda";
    case 3:
      return "trasera";
    default:
      return "frontal";
  }
};
const Personalization = ({ variant, point }) => {
  const calcTotal = () => {
    const currentPersonalization = variant.custom.customType.find(
      (x) =>
        x.nombre_personalizacion === point.customType.nombre_personalizacion
    );

    const cm = point.dimensions.width * point.dimensions.height;
    const getDiscount = (num) => (num * point.descuento) / 100;
    if (
      cm * currentPersonalization.factor <
      currentPersonalization.precio_minimo
    ) {
      return (
        currentPersonalization.precio_minimo -
        getDiscount(currentPersonalization.precio_minimo)
      );
    } else {
      return (
        cm * currentPersonalization.factor -
        getDiscount(cm * currentPersonalization.factor)
      );
    }
  };
  return (
    <>
      <tbody>
        <tr>
          <td>
            {point.nombre_area} (Lado {getVista(point.vista)})
            <br />
            <span style={{ fontSize: "12px", color: "#bdc3c7" }}>
              {point.customType.nombre_personalizacion}
            </span>
          </td>
          <td>
            {point.dimensions.width * point.dimensions.height} <br />{" "}
            <span style={{ fontSize: "12px", color: "#bdc3c7" }}>/ cm2</span>
          </td>
          <td>{point.descuento || 0} (%)</td>
          <td>
            {getMoneda()}. {numberFormater(calcTotal() * variant.cantidad)}
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default Personalization;
