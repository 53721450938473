import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { base_url_images } from "../../api";
import Invoice_1 from "./dummyInvoice";
import Invoice_2 from "./dummyInvoice2";
import Invoice_3 from "./dummyInvoice3";
import { isCmi } from "../../helpers/isCmi";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";

// components

//data

export default function InvoiceConfig(props) {
  const { handleSubmit } = useForm();

  // states
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("todas");
  const [invoice_data, setInvoiceData] = useState({});
  const [invoicesLoader, setInvoicesLoader] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const factura_id = parseInt(props.match.params.id.toString());

  useEffect(() => {
    getSucursals();
    getFacturaInfo(factura_id);
  }, []);
  // set states
  const getSucursals = () => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          setSucursals(response.data);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error con las sucursales");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getFacturaInfo = (id) => {
    setInvoicesLoader(true);
    API.invoices
      .getFactura({ factura_id: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Factura data", response.data);
          setInvoiceData(response.data);
          if (response.data.facturaSucursales.length > 0) {
            setImage(
              base_url_images +
                response.data.facturaSucursales[0].imagen_factura
            );
            setSucursal(
              response.data.facturaSucursales[0].id_sucursal_bodega_ubicacion
            );
          }
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
        setInvoicesLoader(false);
      })
      .catch((err) => {
        setInvoicesLoader(false);
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const processFileInput = (event) => {
    if (typeof event.target.files[0] !== "undefined") {
      let image = URL.createObjectURL(event.target.files[0]);
      let image_file = event.target.files[0];
      setFile(image_file);
      setImage(image);
    }
  };

  const save = (data) => {
    setSubmitLoader(true);
    let selectedSucursales = [];
    const form_data = new FormData();
    if (sucursal === "todas" || sucursal === "") {
      console.log(sucursals);
      sucursals.map((sucursal) =>
        selectedSucursales.push(sucursal.id_sucursal_bodega_ubicacion)
      );
    } else {
      selectedSucursales.push(parseInt(sucursal));
    }
    form_data.append("id_sucursales", JSON.stringify(selectedSucursales));
    form_data.append("images", file);
    form_data.append("factura_id", parseInt(invoice_data.factura_id));
    API.invoices
      .setFactura(form_data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          getSucursals();
          getFacturaInfo(factura_id);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
        setSubmitLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
        setSubmitLoader(false);
      });
  };

  const changeSucursal = (event) => {
    setSucursal(event.target.value);
    if (event.target.value !== "todas") {
      let FacturaSucursal = invoice_data.facturaSucursales.find(
        (x) => x.id_sucursal_bodega_ubicacion === parseInt(event.target.value)
      );
      if (typeof FacturaSucursal !== "undefined") {
        setImage(base_url_images + FacturaSucursal.imagen_factura);
      } else {
        setImage("");
      }
    }
  };

  return (
    <div>
      {!isCmi() && (
        <div id="dashboard-section">
          <div className="section-title mb-4">
            <HomeIcon className="d-none d-sm-block" />
            <ArrowForwardIosIcon
              style={{ color: "#333333", fontSize: "12px" }}
              className="mx-2 d-none d-sm-block"
            />
            <h4 className="mb-0">
              <strong>Configuración de cuenta</strong>
            </h4>
            <ArrowForwardIosIcon
              style={{ color: "#333333", fontSize: "12px" }}
              className="mx-2 d-none d-sm-block"
            />
            <h4 className="mb-0">
              <strong>Formato de factura</strong>
            </h4>
          </div>

          <form
            className="content-card card mt-3"
            onSubmit={handleSubmit(save)}
          >
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h5 className="mb-0">
                  <strong>{invoice_data.nombre}</strong>
                </h5>
                <div>
                  <Button
                    className="btn-cs1 mx-1 mt-1 delete-btn"
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={"/app/config"}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    className="btn-cs1_blue mx-1 mt-1"
                    variant="contained"
                    color="primary"
                  >
                    {submitLoader ? (
                      <div className="text-center">
                        <CircularProgress
                          size={26}
                          className="mx-auto"
                          style={{ color: "white" }}
                        />
                      </div>
                    ) : (
                      "Guardar"
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <div className="row mx-0">
              {/* format here */}
              <div className="col-md-8 px-0">
                <div
                  className="card-body h-100 p-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {invoicesLoader ? (
                    <div className="text-center">
                      <CircularProgress
                        size={26}
                        className="mx-auto my-auto"
                        style={{ color: "black" }}
                      />
                    </div>
                  ) : (
                    <>
                      {invoice_data.factura_id === 4 ? (
                        <Invoice_1
                          texto_1={invoice_data.texto_1}
                          texto_2={invoice_data.texto_2}
                          imagen_factura={image}
                        />
                      ) : invoice_data.factura_id === 1003 ? (
                        <Invoice_2
                          texto_1={invoice_data.texto_1}
                          texto_2={invoice_data.texto_2}
                          imagen_factura={""}
                        />
                      ) : (
                        <Invoice_3
                          texto_1={invoice_data.texto_1}
                          texto_2={invoice_data.texto_2}
                          imagen_factura={image}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* format edit options */}
              <div
                className="col-md-4 px-0"
                style={{ boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.06)" }}
              >
                <div className="card-body">
                  <p className="mb-2">
                    Seleccione la sucursal a la que asignará este formato de
                    facturación.
                  </p>

                  <div className="d-flex justify-content-between align-items flex-wrap">
                    {invoicesLoader ? (
                      <div className="text-center mx-auto">
                        <CircularProgress
                          size={30}
                          className="mx-auto"
                          style={{ color: "black" }}
                        />
                      </div>
                    ) : (
                      <FormControl
                        variant="outlined"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          native
                          value={sucursal}
                          onChange={changeSucursal}
                          className="sm-select"
                        >
                          <option value="todas">Todas las sucursales</option>
                          {sucursals.map((sucursal) => (
                            <option
                              value={sucursal.id_sucursal_bodega_ubicacion}
                            >
                              {sucursal.nombre_sucursal}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>

                  <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                    <div>
                      <p className="mb-0">
                        <strong>Imagen</strong>
                      </p>
                      <p
                        className="mb-0 text-center"
                        style={{ color: "#828282" }}
                      >
                        Cargar una imagen al documento.
                      </p>
                    </div>
                    <div className="card" style={{ width: "130px" }}>
                      <div className="d-flex align-items-center">
                        <Button
                          type="submit"
                          className="btn-cs1 d-flex"
                          variant="contained"
                          color="primary"
                        >
                          <p className="mb-0" style={{ marginTop: "2px" }}>
                            Cargar
                          </p>
                          <CloudUploadIcon
                            className="mx-2"
                            style={{ color: "white6", fontSize: "30px" }}
                          />
                        </Button>
                      </div>
                      <input
                        type="file"
                        className="file-input-excel"
                        name="images"
                        accept="image/*"
                        onChange={(event) => processFileInput(event)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
