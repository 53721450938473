import React, { useState } from "react";

//styles
import "./reviewsBox.css";

//icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import CallMadeIcon from "@material-ui/icons/CallMade";

import Rating from "@material-ui/lab/Rating";

const ReviewsBox = ({ objeto }) => {
  const [open, setOpen] = useState(false);

  const renderMenssage = () => {
    let stringReturn = objeto.text;
    if (objeto.text.length >= 40) {
      if (open) {
        return stringReturn;
      } else {
        return stringReturn.slice(0, 40) + "...";
      }
    } else {
      return stringReturn;
    }
  };

  return (
    <div className="reviewsBox-container">
      <div className="reviewsBox-header">
        <div className="reviewsBox-user">
          <div>
            <img src={objeto.profile_photo_url}></img>
          </div>
          <div>
            <span>{objeto.author_name}</span>
            <Rating name="read-only" value={objeto.rating} readOnly />
          </div>
        </div>

        <span>{objeto.relative_time_description}</span>
      </div>

      <div>
        <span>{renderMenssage()} </span>
      </div>

      <div className="reviewsBox-footer">
        {objeto.text.length > 40 && (
          <div
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{open ? "Ocultar" : "Leer más"}</span>
            {!open && (
              <ExpandMoreIcon
                className="mr-1"
                style={{ fontSize: "16px", marginBottom: "3px" }}
              />
            )}
          </div>
        )}

        {/* <div>
                    <span>Responder</span>
                    <CallMadeIcon className="mr-1" style={{ fontSize: '16px', marginBottom: '3px' }} />
                </div> */}
      </div>
    </div>
  );
};

export default ReviewsBox;
