import React, { useEffect, useContext } from "react";

import { Close } from "@material-ui/icons";

import {
  AreaContainer,
  FlexContainer,
  BigTitle,
  CancelButton,
  Select,
  Input,
  UploadFile,
} from "../styled/styled.components";
import ProductPersonalizationContext from "../../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import {
  handleContent,
  handleCustom,
  handleHeight,
  handleObservations,
  handleSetFile,
  handleWidth,
} from "./utils/handleChanges";
import { InputAdornment, TextField } from "@material-ui/core";

const AreaContainerForm = ({
  item,
  points,
  contentType,
  customType,
  setPoints,
  index,
  variant,
  point,
}) => {
  const {
    formularios,
    handleForms,
    deleteFile,
    deleteArea,
    setCheck,
    validation,
  } = useContext(ProductPersonalizationContext);

  const deletePoint = (i) => {
    variant.points = points.filter((a) => a.color != i);
    setPoints(points.filter((a) => a.color != i));
    deleteArea(index, variant.variantId, points.length);
  };

  const currentForm = formularios.find(
    (item) => item.variantId === variant.variantId
  );

  const handleFile = (e) => {
    if (typeof e.target.files[0] !== "undefined") {
      let file = e.target.files[0];

      handleSetFile(file, variant, handleForms, index);
    }
  };

  // useEffect(() => {
  //   if (!currentForm) {
  //     const key_1 = `contenido_tipo_${index + 1}`;
  //     const key_2 = `medidas_ancho_${index + 1}`;
  //     const key_3 = `medidas_alto_${index + 1}`;
  //     const key_4 = `adjunto_${index + 1}`;
  //     const key_5 = `nombre_area_${index + 1}`;

  //     handleForms({
  //       variantId: variant.variantId,
  //       [key_1]: "",
  //       [key_2]: "",
  //       [key_3]: "",
  //       [key_4]: "",
  //       [key_5]: "",
  //     });
  //   }
  // }, [currentForm]);

  // useEffect(() => {
  //   if (!currentForm) {
  //     handleContent(contentType[0], variant, handleForms, index);
  //     handleCustom(
  //       customType[0].nombre_personalizacion,
  //       variant,
  //       handleForms,
  //       index,
  //       customType[0].id_new_personalizable
  //     );
  //   }
  // }, [currentForm]);
  console.log("current", currentForm);
  return (
    <AreaContainer
      gridTemplateColumns="repeat(4,1fr)"
      gridGap="0 20px"
      margin="20px auto"
      padding="20px 10px"
      borderBottom={`3px solid ${
        item.color.length < 7 ? "#" + item.color : item.color
      }`}
      key={item.color}
      gridTemplateAreas='"a b c d" "e f g h" "i i j x"'
      gridColumn="1/-1"
    >
      <BigTitle gridArea="a" margin="0" fontSize="14px" fontWeight="400">
        {currentForm ? currentForm[`nombre_area_${index + 1}`] : ""}
      </BigTitle>
      <CancelButton
        onClick={() => {
          deletePoint(item.color);
        }}
        margin="auto"
        gridArea="e"
      >
        Eliminar
      </CancelButton>
      <BigTitle grid Area="b" margin="0" fontSize="14px" fontWeight="700">
        Tipo de contenido
      </BigTitle>
      <Select
        value={currentForm ? currentForm[`contenido_tipo_${index + 1}`] : ""}
        onChange={(e) =>
          handleContent(e.target.value, variant, handleForms, index)
        }
        gridArea="f"
      >
        {contentType.map((item) => {
          return <option value={item}>{item}</option>;
        })}
      </Select>

      <BigTitle gridArea="c" margin="0" fontSize="14px" fontWeight="700">
        Tipo de personalización
      </BigTitle>
      <Select
        value={
          currentForm ? currentForm[`personalizacion_tipo_${index + 1}`] : ""
        }
        onChange={(e) => {
          const currentPersonalization = customType.find(
            (item) => item.nombre_personalizacion === e.target.value
          );
          point.customType = currentPersonalization;
          handleCustom(
            currentPersonalization.nombre_personalizacion,
            variant,
            handleForms,
            index,
            +e.target.value
          );
        }}
        gridArea="g"
      >
        {customType.map((item) => {
          return (
            <option value={item.nombre_personalizacion}>
              {item.nombre_personalizacion}
            </option>
          );
        })}
      </Select>
      <BigTitle gridArea="d" margin="0" fontSize="14px" fontWeight="700">
        Dimensiones
      </BigTitle>
      <FlexContainer gridArea="h">
        <FlexContainer flexWrap="wrap" alignItems="center">
          <span style={{ width: "100%" }}>Ancho:</span>
          <Input
            value={
              currentForm ? currentForm[`medidas_ancho_${index + 1}`] || "" : ""
            }
            style={{ width: "100%", paddingLeft: 0 }}
            type="number"
            onChange={(e) => {
              point.dimensions.width = parseFloat(e.target.value);
              handleWidth(
                parseFloat(e.target.value),
                variant,
                handleForms,
                index
              );
            }}
            placeholder="Valor en cm."
          />
          {validation && !currentForm[`medidas_ancho_${index + 1}`] && (
            <span className="text-danger">Requerido</span>
          )}
        </FlexContainer>

        <FlexContainer flexWrap="wrap" alignItems="center">
          <span style={{ width: "100%" }}>Alto:</span>
          <Input
            style={{ width: "100%", paddingLeft: 0 }}
            value={
              currentForm ? currentForm[`medidas_alto_${index + 1}`] || "" : ""
            }
            type="number"
            onChange={(e) => {
              point.dimensions.height = parseFloat(e.target.value);
              handleHeight(
                parseFloat(e.target.value),
                variant,
                handleForms,
                index
              );
            }}
            placeholder="Valor en cm."
          />
          {validation && !currentForm[`medidas_alto_${index + 1}`] && (
            <span className="text-danger">Requerido</span>
          )}
        </FlexContainer>
      </FlexContainer>

      <FlexContainer flexWrap="nowrap" flexDirection="column" gridArea="i">
        {currentForm[`contenido_tipo_${index + 1}`] == "Imagen" ? (
          <FlexContainer
            alignItems="center"
            borderBottom="1px solid rgba(130,130,130,1)"
            flexDirection="row"
          >
            <BigTitle margin="0" fontSize="14px" fontWeight="700">
              Listado de adjuntos
            </BigTitle>
            <Input
              name={item.color}
              id={item.color}
              type="file"
              onChange={handleFile}
              display="none"
            />
            <UploadFile
              margin="5px 0 5px auto"
              padding="8px 35px"
              htmlFor={item.color}
            >
              Cargar
            </UploadFile>
          </FlexContainer>
        ) : (
          <></>
        )}
        <FlexContainer margin="5px 0" alignItems="center" flexDirection="row">
          {currentForm && currentForm[`adjunto_${index + 1}`] && (
            <>
              <CancelButton padding="5px" borderRadius="50%" margin="0 10px">
                <Close
                  onClick={() => deleteFile(index, variant.variantId)}
                  fontSize="14px"
                />
              </CancelButton>
              <BigTitle margin="0" fontSize="14px" fontWeight="400">
                {currentForm[`adjunto_${index + 1}`].name}
              </BigTitle>
            </>
          )}
          {currentForm[`contenido_tipo_${index + 1}`] == "Imagen" ? (
            validation &&
            !currentForm[`adjunto_${index + 1}`] && (
              <span className="text-danger">Requerido</span>
            )
          ) : (
            <></>
          )}
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        flexWrap="nowrap"
        flexDirection="column"
        justifyContent="space-evenly"
        gridArea="j"
      >
        <BigTitle margin="0" fontSize="14px" fontWeight="700">
          Observaciones (Opcional)
        </BigTitle>
        <Input
          value={currentForm ? currentForm[`observaciones_${index + 1}`] : ""}
          onChange={(e) =>
            handleObservations(e.target.value, variant, handleForms, index)
          }
          placeholder="Escriba aquí las observaciones"
        />
      </FlexContainer>
      <FlexContainer
        flexWrap="nowrap"
        flexDirection="column"
        justifyContent="space-evenly"
        gridArea="x"
      >
        <BigTitle margin="0" fontSize="14px" fontWeight="700">
          Descuento
        </BigTitle>
        <TextField
          value={currentForm ? currentForm[`descuento_${index + 1}`] || "" : ""}
          onChange={(e) => {
            if (e.target.value > 100 || e.target.value < 0) {
              e.target.value = 0;
            }
            handleForms({
              variantId: variant.variantId,
              [`descuento_${index + 1}`]: parseFloat(e.target.value),
            });
            point.descuento = parseFloat(e.target.value);
          }}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          placeholder="Ingrese el descuento"
        />
      </FlexContainer>
    </AreaContainer>
  );
};

export default AreaContainerForm;
