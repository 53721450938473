import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import { Link, Redirect, Route } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import API from "../../api";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { CircularProgress } from "@material-ui/core";

// components
import CrearNewClienteModal from "./clientsComponents/CrearNewClienteModal";

//herramienta reporte
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//reporte
import ClientesReporte from "../../reportes/clientesReporte";
import ClientesAcreedoresReporte from "../../reportes/clientesAcreedoresReporte";
import ClientesDeudoresReporte from "../../reportes/clientesDeudoresReporte";

// helpers
import { isCmi } from "../../helpers/isCmi";
import { getMoneda } from "../../helpers/moneda";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

//data
export default function Users_all(props) {
  let history = useHistory();
  const moneda = getMoneda();
  // states
  const [sucursals, setSucursals] = useState([]);
  const [loader, setLoader] = useState(true);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [configTab, setConfigTab] = useState(0);
  const [clientFilterText, setClientFilterText] = useState("");
  const [deudorFilterText, setDeudorFilterText] = useState("");
  const [acreedorFilterText, setAcreedorFilterText] = useState("");
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedDeudoresClients, setSelectedDeudoresClients] = useState([]);
  const [selectedAcreedoresClients, setSelectedAcreedoresClients] = useState(
    []
  );

  //data
  const [clients, setClients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [clientsDeudores, setClientsDeudores] = useState([]);
  const [allClientsDeudores, setAllClientsDeudores] = useState([]);
  const [filteredDeudores, setFilteredDeudores] = useState([]);
  const [clientsAcreedores, setClientsAcreedores] = useState([]);
  const [allClientsAcreedores, setAllClientsAcreedores] = useState([]);
  const [filteredAcreedores, setFilteredAcreedores] = useState([]);
  const [clientsStats, setClientsStats] = useState({
    total_clientes: "No hay información",
    total_clientes_acreedores: "No hay información",
    total_clientes_deudores: "No hay información",
  });
  const [enterpriseInfo, setEnterpriseInfo] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageAcreedor, setCurrentPageAcreedor] = useState(1);
  const [currentPageDeudor, setCurrentPageDeudor] = useState(1);
  const [totalClients, setTotalClients] = useState(0);
  const [totalClientsDeudores, setTotalClientsDeudores] = useState(0);
  const [totalClientsDeudoresFiltro, setTotalClientsDeudoresFiltro] =
    useState(0);
  const [
    totalImporteClientsDeudoresFiltro,
    setTotalImporteClientsDeudoresFiltro,
  ] = useState(0);

  const [totalClientsAcreedores, setTotalClientsAcreedores] = useState(0);
  const [totalClientsAcreedoresFiltro, setTotalClientsAcreedoresFiltro] =
    useState(0);
  const [
    totalImporteClientsAcreedoresFiltro,
    setTotalImporteClientsAcreedoresFiltro,
  ] = useState(0);

  const [totalClientsDeudoresConsolidado, setTotalClientsDeudoresConsolidado] =
    useState(1);

  const [
    totalClientsAcreedoresConsolidado,
    setTotalClientsAcreedoresConsolidado,
  ] = useState(1);

  useEffect(() => {
    getClients();
    //getDeudores();
    //getAcreedores();
    get_sucursals();
    get_empresa_info();
  }, [currentPage]);

  useEffect(() => {
    getAcreedores();
  }, [currentPageAcreedor]);

  useEffect(() => {
    getDeudores();
  }, [currentPageDeudor]);

  const orderByAlphabet = (arr) => {
    arr.sort((itemOne, itemTwo) => {
      if (itemOne.nombre && itemTwo.nombre) {
        if (itemOne.nombre.toLowerCase() < itemTwo.nombre.toLowerCase()) {
          return -1;
        } else if (
          itemOne.nombre.toLowerCase() > itemTwo.nombre.toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      } else if (itemOne.nombre_cliente_final && itemTwo.nombre_cliente_final) {
        if (
          itemOne.nombre_cliente_final.toLowerCase() <
          itemTwo.nombre_cliente_final.toLowerCase()
        ) {
          return -1;
        } else if (
          itemOne.nombre_cliente_final.toLowerCase() >
          itemTwo.nombre_cliente_final.toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return arr;
  };

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    //console.log(newValue)
    return newValue;
  };
  // set states
  const handleConfigTabs = (event, newValue) => {
    setConfigTab(newValue);
  };

  const searchFilter = async (event) => {
    let text;
    if (!event) {
      text = filterClients;
    } else {
      text = event;
    }

    if (text != "") {
      setClientFilterText(text);
      setFilteredClients(
        clients.filter((item) =>
          item.nombre.toLowerCase().includes(text.toLowerCase())
        )
      );
    } else {
      setClientFilterText("");
    }

    /*console.log(query)   
    setLoader(true);
   
    const res = await API.clients.getAll(query,{
      pagination: true,
      page: currentPage,
      limit: 10,
      searchGeneral: query,
    });

    setClients(res.data.data.clients);
    setFilteredClients(res.data.data.clients);
    setTotalClients(res.data.data.total_clientes);

    setLoader(false);
    console.log("clientes ->", res.data.data.clients);
    */
  };

  const searchFilterDeudores = async (event) => {
    //setCurrentPage(1)
    let text;
    if (!event) {
      text = clientFilterText;
    } else {
      text = event.target.value;
    }
    setClientFilterText(text);
    getDeudores(text);
  };

  const normalize = (str) => {
    if (!str) return null;
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filterClients2 = async (event) => {
    let text;
    if (!event) {
      text = clientFilterText;
    } else {
      text = event.target.value;
    }

    setClientFilterText(text);

    //getClients(text)
  };

  const filterClients = async (event) => {
    let text;
    if (!event) {
      text = clientFilterText;
    } else {
      text = event.target.value;
    }
    setClientFilterText(text);

    if (isCmi()) {
      /*
      setFilteredClients(     
        clients.filter(
          (item) =>
            item.nombre.toLowerCase().includes(text.toLowerCase()) ||
            item.nit.toLowerCase().includes(text.toLowerCase()) ||
            item.client_code.toLowerCase().includes(text.toLowerCase()) 
        )
      );

      console.log("eeee")
      console.log(filteredClients.length)
      console.log(clientFilterText)

      if (filteredClients.length>10){

        getClients(text)
      }
      */
      getClients(text);
    } else {
      /*     
      console.log(clients)
      setFilteredClients(     
        clients.filter(
          (item) =>
            item.nombre.toLowerCase().includes(text.toLowerCase()) 
        )
      );
      */
      getClients(text);
    }
  };

  const filterDeudoresChange = (event) => {
    let text;
    if (!event) {
      text = clientFilterText;
    } else {
      text = event.target.value;
    }

    setDeudorFilterText(text);

    //getClients(text)
  };
  const filterDeudores = (event) => {
    /*
    setDeudorFilterText(e.target.value);
    setFilteredDeudores(
      removeDuplicates(
        clientsDeudores.filter((item) =>
          item.nombre.toLowerCase().includes(deudorFilterText.toLowerCase())
        )
      )
    );

    if (e.target.value === "") {
      setFilteredDeudores(removeDuplicates(clientsDeudores));
    }
    */

    //setCurrentPage(1)
    let text;
    if (!event) {
      text = deudorFilterText;
    } else {
      text = event.target.value;
    }
    setDeudorFilterText(text);
    getDeudores(text);
  };

  const filterAcreedorChange = (event) => {
    let text;
    if (!event) {
      text = acreedorFilterText;
    } else {
      text = event.target.value;
    }
    setAcreedorFilterText(text);
  };

  const filterAcreedor = (event) => {
    let text;
    if (!event) {
      text = acreedorFilterText;
    } else {
      text = event.target.value;
    }
    setAcreedorFilterText(text);
    getAcreedores(text);
    /*
    setAcreedorFilterText(e.target.value);
    setFilteredAcreedores(
      clientsAcreedores.filter((item) =>
        item.nombre.toLowerCase().includes(acreedorFilterText.toLowerCase())
      )
    );
    if (e.target.value === "") {
      setFilteredAcreedores(clientsAcreedores);
    }
    */
  };

  const getClients = (nombre) => {
    let filter = { clientFilterText };
    API.clients
      .getAll({
        pagination: true,
        page: currentPage,
        limit: 10,
        searchGeneral: nombre || filter.clientFilterText,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setClients(response.data.clients);
          console.log("Receiving this:", response.data.clients);
          setFilteredClients(response.data.clients);
          setTotalClients(response.data.total_clientes);

          let data = {
            total_clientes: response.data.total_clientes,
            total_clientes_deudores: response.data.total_clientes_deudores,
            total_clientes_acreedores: response.data.total_clientes_acreedores,
          };
          setClientsStats(data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error cliente", {
              autoClose: 10000,
            });
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const printAllClients = () => {
    setDownloadLoader(true);
    API.clients
      .getAll()
      .then((res) => {
        let response = res.data;
        setAllClients(response.data.clients);
        console.log("Clientes", res.data.data.data);
        document.getElementById("descargar-reporteTodosClientes").click();
        setDownloadLoader(false);
        setAllClients([]);
      })
      .catch((err) => {
        setDownloadLoader(false);
        toast.error("Ha ocurrido un error al traer los clientes", {
          autoClose: 1000,
        });
      });
  };

  const removeDuplicates = (arr) => {
    let newArr = arr.filter((x) => x.estado !== "Pagada");
    newArr = Array.from(new Set(newArr.map((a) => a.nit))).map((nit) => {
      return newArr.find((a) => a.nit === nit);
    });
    return newArr;
  };

  const getDeudores = (nombre) => {
    let filter = { deudorFilterText };
    API.clients
      .getAllDeudores({
        pagination: true,
        page: currentPageDeudor,
        limit: 10,
        nombre: nombre || filter.deudorFilterText,
      })
      .then((res) => {
        let data = res.data.data;
        setClientsDeudores(data.data);
        setFilteredDeudores(data.data);

        if (data.data.length > 0) {
          setTotalClientsDeudoresFiltro(data.data[0].totalFiltro);
          setTotalImporteClientsDeudoresFiltro(data.data[0].totalDeuda);
          setTotalClientsDeudores(data.data[0].total);

          setTotalClientsDeudoresConsolidado(data.data[0].total);
        } else {
          setTotalClientsDeudoresFiltro(0.0);
          setTotalImporteClientsDeudoresFiltro(0.0);
          setTotalClientsDeudores(0.0);
          setTotalClientsDeudoresConsolidado(0);
        }
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error deudor", { autoClose: 10000 });
      });
  };

  const printAllDeudores = () => {
    setDownloadLoader(true);
    API.clients
      .getAllDeudores({
        pagination: false,
        page: 1,
        limit: 1000000000000,
      })
      .then((res) => {
        let response = res.data.data;
        console.log("Deudores", response.data);
        setAllClientsDeudores(response.data);
        document.getElementById("descargar-reporteTodosDeudores").click();
        setAllClientsDeudores([]);
        setDownloadLoader(false);
      })
      .catch((err) => {
        setDownloadLoader(false);
        toast.error("Ha ocurrido un error al traer los clientes deudores", {
          autoClose: 1000,
        });
      });
  };

  const getAcreedores = (nombre) => {
    let filter = { acreedorFilterText };
    API.clients
      .getAllAcreedores({
        pagination: true,
        page: currentPageAcreedor,
        limit: 10,
        nombre: nombre || filter.acreedorFilterText,
      })
      .then((res) => {
        let data = res.data.data;

        setClientsAcreedores(data.data);
        setFilteredAcreedores(data.data);

        if (data.data.length > 0) {
          setTotalClientsAcreedoresFiltro(data.data[0].totalFiltro);
          setTotalImporteClientsAcreedoresFiltro(data.data[0].totalAcreedor);
          setTotalClientsAcreedores(data.data[0].total);
          setTotalClientsAcreedoresConsolidado(data.data[0].total);
        } else {
          setTotalClientsAcreedoresFiltro(0.0);
          setTotalImporteClientsAcreedoresFiltro(0.0);
          setTotalClientsAcreedores(0.0);
          setTotalClientsAcreedoresConsolidado(0);
        }
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error deudor", { autoClose: 10000 });
      });

    /*
    API.clients
      .getAllAcreedores()
      .then((res) => {
        let data = res.data.data;
        console.log("CLIENTES ACREEDORES", data);
        setClientsAcreedores(data);
        setFilteredAcreedores(data);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error acre", { autoClose: 10000 });
      });
      */
  };

  const printAllAcreedores = () => {
    setDownloadLoader(true);
    API.clients
      .getAllAcreedores({
        pagination: false,
        page: 1,
        limit: 1000000000000,
      })
      .then((res) => {
        let response = res.data.data;
        console.log("Acreedores", response.data);
        setAllClientsAcreedores(response.data);
        document.getElementById("descargar-reporteTodosAcreedores").click();
        setAllClientsAcreedores([]);
        setDownloadLoader(false);
      })
      .catch((err) => {
        setDownloadLoader(false);
        toast.error("Ha ocurrido un error al traer los clientes acreedores", {
          autoClose: 1000,
        });
      });
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;

    let users_ids = selectedRows;
    if (configTab === 0) {
      setSelectedClients(users_ids);
    }
    if (configTab === 1) {
      setSelectedDeudoresClients(users_ids);
    }
    if (configTab === 2) {
      setSelectedAcreedoresClients(users_ids);
    }
  };

  const get_sucursals = () => {
    API.sucursal
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSucursals(response.data);
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const get_empresa_info = () => {
    API.userConfig
      .getEmpresaInfo()
      .then((res) => {
        let response = res.data;
        if (response.success && JSON.stringify(response.data) !== "[]") {
          setEnterpriseInfo(response.data);
          console.log("ENTERPRISE INFO:", response.data);
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const gotoDetails = (nit, type, typeClient) => {
    let currentClient;
    if (!isCmi()) {
      switch (type) {
        case 1:
          currentClient =
            typeClient === "CF"
              ? clients.find((x) => parseInt(x.id_cliente_web_app) === nit)
              : clients.find((x) => x.nit === nit);
          break;
        case 2:
          currentClient =
            typeClient === "CF"
              ? clientsDeudores.find(
                  (x) => parseInt(x.id_cliente_web_app) === nit
                )
              : clientsDeudores.find((x) => x.nit === nit);
          break;
        case 3:
          currentClient =
            typeClient === "CF"
              ? clientsAcreedores.find(
                  (x) => parseInt(x.id_cliente_web_app) === nit
                )
              : clientsAcreedores.find((x) => x.nit === nit);
          break;

        default:
          break;
      }
    } else {
      switch (type) {
        case 1:
          currentClient = clients.find((x) => x.id_cliente_web_app === nit);
          break;
        case 2:
          currentClient = clientsDeudores.find(
            (x) => x.id_cliente_web_app === nit
          );
          break;
        case 3:
          currentClient = clientsAcreedores.find(
            (x) => x.id_cliente_web_app === nit
          );
          break;

        default:
          break;
      }
    }
    console.log(currentClient);

    history.push({
      pathname: `/app/clients/profile/${nit}`,
      state: {
        id_cliente_web_app: currentClient.id_cliente_web_app,
      },
    });
  };

  const saveNewClientCmi = async (data) => {
    setLoader(true);

    API.clients
      .add_new(data)
      .then((res) => {
        let response = res.data;
        if (isCmi()) {
          if (response?.data?.errors?.length > 0) {
            toast.error(response.data.errors[0].msg, { autoClose: 10000 });
            return;
          }

          if (res.status === 200) {
            toast.success("Cliente agregado exitosamente", {
              autoClose: 10000,
            });
            $("#modal_add_client").modal("hide");

            getClients();
          } else {
            toast.error("Error al crear cliente", { autoClose: 10000 });
          }
        } else {
          if (response.success) {
            toast.success("Cliente agregado exitosamente", {
              autoClose: 10000,
            });
            $("#modal_add_client").modal("hide");
          } else {
            toast.error(response.message, { autoClose: 10000 });
          }
        }
      })
      .catch((err) => {
        console.log("error al crear cliente ->", err.response);

        if (err?.response?.data?.msg) {
          toast.error(err.response.data.msg, { autoClose: 10000 });
        } else if (err.response?.data?.errors[0]?.msg) {
          toast.error(err.response?.data?.errors[0]?.msg, { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  if (
    JSON.parse(localStorage.getItem("pms")).tipo_usuario.toLowerCase() ===
    "gerente regional"
  ) {
    return (
      <Route
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/reviews",
            }}
          />
        )}
      />
    );
  }

  return (
    <>
      <div id="dashboard-section">
        <div className="section-title mb-4">
          <HomeIcon className="d-none d-sm-block" />
          <ArrowForwardIosIcon
            style={{ color: "#333333", fontSize: "12px" }}
            className="mx-2 d-none d-sm-block"
          />
          <h4 className="mb-0">
            <strong>Clientes</strong>
          </h4>
        </div>

        <div className="content-card card mt-3">
          <div className="card-header">
            <Tabs
              className=""
              value={configTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleConfigTabs}
            >
              <Tab className="tab" label="Todos" />
              <Tab className="tab" label="Clientes deudores" />
              <Tab className="tab" label="Clientes acreedores" />
            </Tabs>
          </div>
          <div className="card-body">
            <TabPanel value={configTab} index={0} className="config-tab">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <p className="mb-0">
                    <strong>Clientela</strong>
                  </p>
                  <p className="mb-0">Consulte y gestione a sus clientes</p>
                </div>

                {isCmi() ? (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxIcon />}
                    onClick={(e) => $("#modal_add_client").modal()}
                  >
                    <span style={{ marginBottom: "-4px" }}>Añadir cliente</span>
                  </Button>
                ) : (
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxIcon />}
                    onClick={(e) => null}
                    component={Link}
                    to={"/app/clients/new"}
                  >
                    <span style={{ marginBottom: "-4px" }}>Añadir cliente</span>
                  </Button>
                )}
              </div>

              <div className="row mt-2">
                <div
                  className="col-md-3 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Total de clientes
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>
                      {numberFormater(clientsStats.total_clientes)}
                    </strong>
                  </h6>
                </div>
                <div
                  className="col-md-3 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Clientes deudores
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>{numberFormater(totalClientsDeudores)}</strong>
                  </h6>
                </div>
                <div className="col-md-3 px-2">
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Clientes acreedores
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>
                      {numberFormater(totalClientsAcreedoresConsolidado)}
                    </strong>
                  </h6>
                </div>
                <div className="col-md-3 px-2 text-right">
                  {/* {selectedClients.length > 0 ? (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        document
                          .getElementById("descargar-reporteClientes")
                          .click();
                      }}
                    >
                      Descargar seleccionados
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  ) : (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                    >
                      Descargar seleccionados
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  )} */}
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    disabled={downloadLoader}
                    onClick={printAllClients}
                  >
                    {downloadLoader ? (
                      <>Descargando...</>
                    ) : (
                      <>Descargar todos</>
                    )}
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                </div>
              </div>

              {/* <ReactHTMLTableToExcel
                id="descargar-reporteClientes"
                className="buton-excel"
                filename={"Listado de clientes de " + enterpriseInfo.nombre}
                table="clientes-reporte-table"
                sheet="tablexls"
                buttonText="Descargar"
              />

              <ClientesReporte objeto={selectedClients}></ClientesReporte> */}

              <ReactHTMLTableToExcel
                id="descargar-reporteTodosClientes"
                className="buton-excel"
                filename={"Listado de clientes de " + enterpriseInfo.nombre}
                table="todos-clientes-reporte-table"
                sheet="tablexls"
                buttonText="Descargar"
              />

              <ClientesReporte objeto={allClients} all></ClientesReporte>

              <div class="row mt-3">
                <div className="col-md-4" />
                <div className="col-md-4" />
                <div className="col-md-4">
                  <div class="input-group search-bar">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={
                        isCmi()
                          ? "Buscar por nombre/nit/cod. cliente/tlf"
                          : "Buscar por nombre"
                      }
                      value={clientFilterText}
                      onChange={(event) => filterClients2(event)}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          //this.search()
                          filterClients(event);
                        }
                      }}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <SearchIcon onClick={() => filterClients()} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {loader ? (
                <div className="text-center">
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : isCmi() ? (
                <DataTable
                  className="datatable-cs datatable-cs-v2 mt-2"
                  title={"s"}
                  columns={[
                    {
                      name: "Nombre del cliente",
                      selector: "nombre",
                      cell: (row) => (
                        <p className="my-auto w-100">
                          {row.nit.toLowerCase() === "cf"
                            ? `CF(${row.nombre_cliente_final})`
                            : row.nombre}
                          <br />
                          <span style={{ color: "#b3b3b2" }}>
                            {row.nit.toLowerCase() === "cf"
                              ? `Cod. cliente: ${row.client_code}`
                              : `Nit: ${row.nit}`}
                          </span>
                        </p>
                      ),
                      sortable: true,
                    },
                    {
                      name: "Tipo de Cliente",
                      selector: "diferenciacion",
                      sortable: true,
                      width: "100px",
                    },
                    {
                      name: "Ticket medio",
                      selector: "half_ticket",
                      sortable: true,
                      cell: (row) => (
                        <span>
                          {moneda}. {row.half_ticket.toFixed(2)}
                        </span>
                      ),
                      width: "140px",
                    },
                    {
                      name: "Valor de vida",
                      selector: "life_value",
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            row.life_value.toFixed(2) + ""
                          )}
                        </span>
                      ),
                      sortable: true,
                      width: "140px",
                    },
                    {
                      name: "Saldo acreedor",
                      selector: "saldo_acreedor",
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {row.saldo_acreedor
                            ? numberFormater(row.saldo_acreedor)
                            : "0.00"}
                        </span>
                      ),
                      sortable: true,
                    },
                    {
                      name: "Saldo deudor",
                      selector: "saldo_deudor",
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {row.saldo_duedor
                            ? formaterPriceFunction(
                                row.saldo_duedor.toFixed(2) + ""
                              )
                            : "0.00"}
                        </span>
                      ),
                      sortable: true,
                    },
                    {
                      name: "Acciones",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <Button
                            className="btn-cs1 ver-detalles-btn-container"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() =>
                              gotoDetails(
                                isCmi()
                                  ? row.id_cliente_web_app
                                  : row.nit === "cf" || row.nit === "CF"
                                  ? row.id_cliente_web_app
                                  : row.nit,
                                1,
                                row.nit === "cf" || row.nit === "CF"
                                  ? "CF"
                                  : "not cf"
                              )
                            }
                            size="small"
                          >
                            <span
                              style={{
                                marginBottom: "-4px",
                                fontSize: "12px",
                              }}
                            >
                              Ver{" "}
                              <span
                                className="d-none d-xl-inline"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                detalles
                              </span>
                            </span>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  data={orderByAlphabet(filteredClients)}
                  selectableRows
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationDefaultPage={currentPage}
                  paginationTotalRows={totalClients}
                  onChangePage={(page) => setCurrentPage(page)}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  noDataComponent="No se ha encontrado ningún resultado"
                  onSelectedRowsChange={handleSelect}
                />
              ) : (
                <DataTable
                  className="datatable-cs datatable-cs-v2 mt-2"
                  title={"s"}
                  columns={[
                    {
                      name: "Nombre del cliente",
                      selector: "nombre",
                      sortable: true,
                    },
                    {
                      name: "NIT",
                      selector: "nit",
                      sortable: true,
                    },
                    {
                      name: "Saldo acreedor",
                      selector: "saldo_acreedor",
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {row.saldo_acreedor
                            ? formaterPriceFunction(
                                row.saldo_acreedor.toFixed(2) + ""
                              )
                            : "0"}
                        </span>
                      ),
                      sortable: true,
                    },
                    {
                      name: "Saldo deudor",
                      selector: "saldo_deudor",
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {row.saldo_duedor
                            ? formaterPriceFunction(
                                row.saldo_duedor.toFixed(2) + ""
                              )
                            : "0"}
                        </span>
                      ),
                      sortable: true,
                    },
                    {
                      name: "Acciones",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <Button
                            className="btn-cs1 ver-detalles-btn-container"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() =>
                              gotoDetails(
                                isCmi()
                                  ? row.id_cliente_web_app
                                  : row.nit === "cf" || row.nit === "CF"
                                  ? row.id_cliente_web_app
                                  : row.nit,
                                1,
                                row.nit === "cf" || row.nit === "CF"
                                  ? "CF"
                                  : "not cf"
                              )
                            }
                            size="small"
                          >
                            <span
                              style={{
                                marginBottom: "-4px",
                                fontSize: "12px",
                              }}
                            >
                              Ver{" "}
                              <span
                                className="d-none d-xl-inline"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                detalles
                              </span>
                            </span>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  data={filteredClients}
                  selectableRows
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationDefaultPage={currentPage}
                  paginationTotalRows={totalClients}
                  onChangePage={(page) => setCurrentPage(page)}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  noDataComponent="No se ha encontrado ningún resultado"
                  onSelectedRowsChange={handleSelect}
                />
              )}
            </TabPanel>
            <TabPanel value={configTab} index={1} className="config-tab">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <p className="mb-0">
                    <strong>Clientes deudores</strong>
                  </p>
                  <p className="mb-0">
                    Consulte y gestione a sus clientes con deuda
                  </p>
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className="col-md-3 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Total de clientes
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>{numberFormater(totalClientsDeudores)}</strong>
                  </h6>
                </div>
                <div
                  className="col-md-3 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Monto total adeudado
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>
                      {numberFormater(totalImporteClientsDeudoresFiltro)}
                    </strong>
                  </h6>
                </div>
                <div className="col-md-3 px-2" />
                <div className="col-md-3 px-2 text-right">
                  {/* {selectedDeudoresClients.length > 0 ? (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        document
                          .getElementById("descargar-reporteDeudores")
                          .click();
                      }}
                    >
                      Descargar seleccionados
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  ) : (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                    >
                      Descargar seleccionados
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  )} */}
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    disabled={downloadLoader}
                    onClick={printAllDeudores}
                  >
                    {downloadLoader ? (
                      <>Descargando...</>
                    ) : (
                      <>Descargar todos</>
                    )}
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                </div>
              </div>

              {/*  <ReactHTMLTableToExcel
                id="descargar-reporteDeudores"
                className="buton-excel"
                table="clientesDeudores-reporte-table"
                filename={
                  "Listado de clientes deudores de " + enterpriseInfo.nombre
                }
                sheet="tablexls"
                buttonText="Descargar"
              />

              <ClientesDeudoresReporte
                objeto={selectedDeudoresClients}
              ></ClientesDeudoresReporte> */}

              <ReactHTMLTableToExcel
                id="descargar-reporteTodosDeudores"
                className="buton-excel"
                table="todosClientesDeudores-reporte-table"
                filename={
                  "Listado de clientes deudores de " + enterpriseInfo.nombre
                }
                sheet="tablexls"
                buttonText="Descargar"
              />

              <ClientesDeudoresReporte
                objeto={allClientsDeudores}
                all
              ></ClientesDeudoresReporte>

              <div class="row mt-3">
                <div className="col-md-4" />
                <div className="col-md-4" />
                <div className="col-md-4">
                  <div class="input-group search-bar">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Buscar por nombre"
                      value={deudorFilterText}
                      onChange={(event) => filterDeudoresChange(event)}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          //this.search()
                          filterDeudores(event);
                        }
                      }}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <SearchIcon onClick={() => filterDeudores()} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {loader ? (
                <div className="text-center">
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : (
                <DataTable
                  className="datatable-cs datatable-cs-v2 mt-2"
                  title={"s"}
                  columns={[
                    {
                      name: "Nombre del cliente",
                      selector: "nombre",
                      sortable: true,
                    },
                    {
                      name: "NIT",
                      selector: "nit",
                      sortable: true,
                    },
                    {
                      name: "Saldo deudor",
                      selector: "saldo_deudor",
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            row.saldo_deudor.toFixed(2) + ""
                          )}
                        </span>
                      ),
                      sortable: true,
                    },
                    /*
                    {
                      name: "Estatus",
                      selector: "estado",
                      cell: (row) => (
                        <div className="w-100 text-center">
                          {row.estado === "Por vencer" ? (
                            <span className="p-1 toExpire-span">
                              {row.estado}
                            </span>
                          ) : (
                            ""
                          )}
                          {row.estado === "Vencida" ? (
                            <span className="p-1 expired-span">
                              {row.estado}
                            </span>
                          ) : (
                            ""
                          )}
                          {row.estado === "Pagada parcialmente" ? (
                            <span className="p-1 toExpire-span">
                              {row.estado}
                            </span>
                          ) : (
                            ""
                          )}
                          {row.estado === "Pagada" ? (
                            <span className="p-1 paid-span">{row.estado}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      ),
                      sortable: true,
                    },
                    {
                      name: "Vencimiento",
                      selector: "vencimiento",
                      sortable: true,
                      cell: (row) => (
                        <div className="text-center w-100">
                          {moment(row.vencimiento).format(
                            "DD/MM/YYYY, h:mm:ss a"
                          )}
                        </div>
                      ),
                    },
                    */
                    {
                      name: "",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <Button
                            className="btn-cs1"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() =>
                              gotoDetails(
                                isCmi()
                                  ? row.id_cliente_web_app
                                  : row.nit === "cf" || row.nit === "CF"
                                  ? row.id_cliente_web_app
                                  : row.nit,
                                //row.nit,
                                2,
                                row.nit === "cf" || row.nit === "CF"
                                  ? "CF"
                                  : "not cf"
                              )
                            }
                            size="small"
                          >
                            <span
                              style={{
                                marginBottom: "-4px",
                                fontSize: "12px",
                              }}
                            >
                              Ver{" "}
                              <span
                                className="d-none d-xl-inline"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                detalles
                              </span>
                            </span>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  data={filteredDeudores}
                  selectableRows
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationDefaultPage={currentPageDeudor}
                  paginationTotalRows={totalClientsDeudores}
                  onChangePage={(page) => setCurrentPageDeudor(page)}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  noDataComponent="No se ha encontrado ningún resultado"
                  onSelectedRowsChange={handleSelect}
                />
              )}
            </TabPanel>
            <TabPanel value={configTab} index={2} className="config-tab">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <p className="mb-0">
                    <strong>Clientes acreedores</strong>
                  </p>
                  <p className="mb-0">
                    Consulte y gestione a sus clientes que han realizados
                    anticipos de dinero
                  </p>
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className="col-md-3 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Total de clientes
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>{numberFormater(totalClientsAcreedores)}</strong>
                  </h6>
                </div>
                <div
                  className="col-md-3 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Monto total acreditado
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>
                      {numberFormater(totalImporteClientsAcreedoresFiltro)}
                    </strong>
                  </h6>
                </div>
                <div className="col-md-3 px-2" />
                <div className="col-md-3 px-2 text-right">
                  {/* {selectedAcreedoresClients.length > 0 ? (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        document
                          .getElementById("descargar-reporteAcreedores")
                          .click();
                      }}
                    >
                      Descargar seleccionados
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  ) : (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                    >
                      Descargar seleccionados
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  )} */}
                  <Button
                    className="btn-cs1"
                    variant="contained"
                    color="primary"
                    disabled={downloadLoader}
                    onClick={printAllAcreedores}
                  >
                    {downloadLoader ? (
                      <>Descargando...</>
                    ) : (
                      <>Descargar todos</>
                    )}
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                </div>
              </div>

              {/* <ReactHTMLTableToExcel
                id="descargar-reporteAcreedores"
                className="buton-excel"
                table="clientesAcreedores-reporte-table"
                filename={
                  "Listado de clientes acreedores de " + enterpriseInfo.nombre
                }
                sheet="tablexls"
                buttonText="Descargar"
              />

              <ClientesAcreedoresReporte
                objeto={selectedAcreedoresClients}
              ></ClientesAcreedoresReporte> */}

              <ReactHTMLTableToExcel
                id="descargar-reporteTodosAcreedores"
                className="buton-excel"
                table="todosClientesAcreedores-reporte-table"
                filename={
                  "Listado de clientes acreedores de " + enterpriseInfo.nombre
                }
                sheet="tablexls"
                buttonText="Descargar"
              />

              <ClientesAcreedoresReporte
                objeto={allClientsAcreedores}
                all
              ></ClientesAcreedoresReporte>

              <div class="row mt-3">
                <div className="col-md-4" />
                <div className="col-md-4" />
                <div className="col-md-4">
                  <div class="input-group search-bar">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Buscar por nombre"
                      value={acreedorFilterText}
                      onChange={(event) => filterAcreedorChange(event)}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          //this.search()
                          filterAcreedor(event);
                        }
                      }}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <SearchIcon onClick={() => filterAcreedor()} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {loader ? (
                <div className="text-center">
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : (
                <DataTable
                  className="datatable-cs datatable-cs-v2 mt-2"
                  title={"s"}
                  columns={[
                    {
                      name: "Nombre del cliente",
                      selector: "nombre",
                      sortable: true,
                      cell: (row) =>
                        row.nit.toLowerCase() === "cf"
                          ? `${
                              row.nombre_cliente_final
                                ? row.nombre_cliente_final
                                : row.nombre
                            }`
                          : row.nombre,
                    },
                    {
                      name: "NIT",
                      selector: "nit",
                      sortable: true,
                    },
                    {
                      name: "Saldo acreedor",
                      selector: "saldo_acreedor",
                      cell: (row) => (
                        <span>
                          {moneda}.{" "}
                          {row.saldo_acreedor
                            ? formaterPriceFunction(
                                row.saldo_acreedor.toFixed(2) + ""
                              )
                            : ""}
                        </span>
                      ),
                      sortable: true,
                    },
                    {
                      name: "N° anticipos",
                      selector: "no_anticipos",
                      cell: (row) => (
                        <div className="text-center w-100">
                          {numberFormater(row.no_anticipos)}
                        </div>
                      ),
                      sortable: true,
                    },
                    {
                      name: "",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <Button
                            className="btn-cs1"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() =>
                              gotoDetails(
                                isCmi()
                                  ? row.id_cliente_web_app
                                  : row.nit === "cf" || row.nit === "CF"
                                  ? row.id_cliente_web_app
                                  : row.nit,
                                3,
                                row.nit === "cf" || row.nit === "CF"
                                  ? "CF"
                                  : "not cf"
                              )
                            }
                            size="small"
                          >
                            <span
                              style={{
                                marginBottom: "-4px",
                                fontSize: "12px",
                              }}
                            >
                              Ver{" "}
                              <span
                                className="d-none d-xl-inline"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                detalles
                              </span>
                            </span>
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  data={filteredAcreedores}
                  selectableRows
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationDefaultPage={currentPageAcreedor}
                  paginationTotalRows={totalClientsAcreedoresFiltro}
                  onChangePage={(page) => setCurrentPageAcreedor(page)}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  noDataComponent="No se ha encontrado ningún resultado"
                  onSelectedRowsChange={handleSelect}
                />
              )}
            </TabPanel>
          </div>
        </div>
      </div>

      {/* Modal para agregar clientes */}
      <div
        className="modal fade bd-example-modal-lg"
        id="modal_add_client"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Registrar nuevo cliente</h5>
            </div>
            <div className="modal-body">
              <CrearNewClienteModal saveNewClientCmi={saveNewClientCmi} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
