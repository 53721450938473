import React, { useState, useEffect, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import API from "../../api";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import DataTable from "react-data-table-component";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import AddBoxIcon from "@material-ui/icons/AddBox";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// validaciones tipo de sala de ventas
import { isCmi } from "../../helpers/isCmi";
import { getMoneda } from "../../helpers/moneda";

// components
import CardEntrega from "./cajaVentasComponents/CardEntrega";
import Card_Domicilio from "./cajaVentasComponents/Card_Domicilio";
import CardEntregaCloud from "./cajaVentasComponents/CardEntregaCloud";
import { isAleko } from "../../helpers/isAleko";
import ProductPersonalizationContext from "../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { PhoneFormatGt } from "../../components/PhoneFormatGt";

//data

const payment_periods = [
  {
    id: 1,
    option: "Pago único",
    value: "0",
  },
  {
    id: 2,
    option: "3 meses",
    value: "3",
  },
  {
    id: 3,
    option: "6 meses",
    value: "6",
  },
  {
    id: 4,
    option: "12 meses",
    value: "12",
  },
  {
    id: 5,
    option: "18 meses",
    value: "18",
  },
  {
    id: 6,
    option: "24 meses",
    value: "24",
  },
  {
    id: 7,
    option: "36 meses",
    value: "36",
  },
];

export default function Sell(props) {
  const moneda = getMoneda();
  const [levelId, setLevelId] = useState(1);
  const AutosuggestHighlightMatch = require("autosuggest-highlight/match");
  const AutosuggestHighlightParse = require("autosuggest-highlight/parse");
  const [idPriceLevel, setIdPriceLevel] = useState("");
  const [priceLabel, setPriceLabel] = useState(null);
  let history = useHistory();
  const [clientesFacturacion, setClientesFacturacion] = useState([]);
  const [noOrden, setNoOrden] = useState(0);

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#cancelSale").modal("hide");
    };
  }, []);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    getPriceLevels();
    if (
      !!localStorage.getItem("selectedProducts") &&
      !!localStorage.getItem("salesCost") &&
      !!localStorage.getItem("pms")
    ) {
      setSelectedProducts(JSON.parse(localStorage.getItem("selectedProducts")));
      setSalesCost(JSON.parse(localStorage.getItem("salesCost")));

      if (
        JSON.parse(
          window.localStorage.getItem("pms")
        ).tipo_usuario.toLocaleLowerCase() === "cajero"
      ) {
        setIsCajero(true);
      } else {
        setIsCajero(true);
      }

      if (
        JSON.parse(
          window.localStorage.getItem("pms")
        ).tipo_usuario.toLocaleLowerCase() === "administrador"
      ) {
        setIsAdministrador(true);
      } else {
        setIsAdministrador(false);
      }

      if (!!localStorage.getItem("selectedProductsAleko") && isAleko()) {
        setSelectedProductsAleko(
          JSON.parse(localStorage.getItem("selectedProductsAleko"))
        );
      }

      setLoader(false);
    } else {
      toast.error("Ha ocurrido un error, por favor regrese al catalogo.", {
        autoClose: 10000,
      });
    }
    
    getsucursalprincipalretainers();
  }, []);

  // states
  const store = JSON.parse(localStorage.getItem("store"));
  // const typeSell = JSON.parse(localStorage.getItem("tipo_venta"));

  // directions
  const [clientDirections, setClientDirections] = useState([]);

  const caja = JSON.parse(localStorage.getItem("caja"));
  const seller = JSON.parse(localStorage.getItem("seller"));
  const currency = JSON.parse(localStorage.getItem("currency"));
  const attentionChannel = JSON.parse(localStorage.getItem("attentionChannel"));

  const [showBtns, setShowBtns] = useState(true);
  const [clientID, setClientID] = useState("");
  const [idClientWebApp, setIdClientWebApp] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientNameFinal, setClientNameFinal] = useState("");
  const [clientNit, setClientNit] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPriceLevel, setClientPriceLevel] = useState("");
  const [countTicket, setCountTicket] = useState("");
  const [halfTicket, setHalfTicket] = useState("");
  const [idEmpresa, setIdEmpresa] = useState("");
  const [lifeValue, setlifeValue] = useState("");
  const [limiteCredito, setLimiteCredito] = useState("");
  const [creditDays, setCreditDays] = useState("");
  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(false);
  const [userFound, setUserFound] = useState(false);
  const [enableCrearCliente, setEnableCrearCliente] = useState(true);
  const [periods, setPeriods] = useState(payment_periods[0].value);
  const [invoice_number, setInvoiceNumber] = useState("");
  const [giftcard_code, setGiftCardCode] = useState("");
  const [giftCard_fecha, setGiftCard_fecha] = useState("");
  const [additional_invoice, setAdditionalInvoice] = useState("");
  const [additional_invoices, setAdditionalInvoices] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsAleko, setSelectedProductsAleko] = useState([]);
  const [isPrincipalRetainer, setisPrincipalRetainer] = useState(false);
  const [salesCost, setSalesCost] = useState({
    total: 0,
    subtotal: 0,
    iva: 0,
  });

  const [copiaSalesCost, setCopiaSalesCost] = useState({ ...salesCost });
  const [paidAmount, setPaidAmount] = useState({
    paid: 0,
    change: 0,
  });
  const [paidByGiftCard, setPaidByGiftCard] = useState(0);
  const [paidByCard, setPaidByCard] = useState(0);
  const [paidByCash, setPaidByCash] = useState(0);
  const [paidByAnticipo, setPaidByAnticipo] = useState(0);
  const [selectedMethods, setSelectedMethods] = useState([0]);
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [clientCreationLoader, setClientCreationLoader] = useState(false);
  const [creditLimit, setCreditLimit] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [clients, setClients] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [taxFreeTicket, setTaxFreeTicket] = useState("");
  const [priceLevels, setPriceLevels] = useState([]);
  const [newForm, setNewForm] = useState({
    telefono_cliente: "",
    codigo_cliente: "",
  });
  const [typeSell, setTypesell] = useState("1");
  const [clientCode, setClientCode] = useState("");
  const [nivelPrecio, setNivelPrecio] = useState([]);
  const [idNivelPrecio, setIdNivelPrecio] = useState(1);
  const [isCajero, setIsCajero] = useState(false);
  const [isAdministrador, setIsAdministrador] = useState(false);
  const [client, setClient] = useState(null);
  /* Validación para monto exacto */
  const [cartRecalculated, setCartRecalculated] = useState(false);

  // metodos entrega cmi
  const [canalesVentas, setCanalesVentas] = useState([]);
  const [metodoEntrega, setMetodoEntrega] = useState("");
  const [pickup, setPickup] = useState("");
  const [numeroDirecciones, setNumeroDirecciones] = useState([]);
  const [reRender, setReRender] = useState(true);
  const [presencial, setPresencial] = useState("");
  const [descuentos, setDescuentos] = useState([]);
  const [actualCostoDescuento, setActualCostoDescuento] = useState(0);
  const [entrega, setEnrega] = useState([]);
  const [empresaInfo, setEmpresaInto] = useState({});
  const [saldoAnticipos, setSaldoAnticipos] = useState(0);
  const [valeCMI, setValeCMI] = useState({
    codigoVale: "",
    dateVale: "",
  });
  const [direcciones, setDirecciones] = useState([]);
  const [lastAddress, setLastAddress] = useState(null);
  const [deshabilitarUserSinDocument, setDeshabilitarUserSinDocument] =
    useState(false);
  const [clientType, setClientType] = useState("individual");
  const { id_new_pedido } = useContext(ProductPersonalizationContext);
  const [deuda, setDeuda] = useState([]);
  const [nivelCliente, setNivelCliente] = useState("1");
  const [subniveles, setSubniveles] = useState([]);

  // clients cmi
  const [isEditMode, setIsEditMode] = useState(false);

  // set states
  useEffect(() => {
    // buscarCardsDireccion()
    getEmpresaInfo();
    getDescuentos();
    getNivelesCliente();
  }, []);

  useEffect(() => {
    if (descuentos.length > 0) {
      updateTotalDescuentos();
      getCanalesVentas();
    }
  }, [descuentos]);

  useEffect(() => {
    setClientesFacturacion(clients);
  }, [clients]);

  // useEffect(() => {
  //   setCopiaSalesCost({ ...salesCost });
  //   if (descuentos.length > 0) {
  //     updateTotalDescuentos();
  //   }
  // }, [salesCost]);

  // useEffect(() => {

  // }, [clientNit]);

  const getNivelesCliente = async () => {
    let id_new_empresa = window.localStorage.getItem("id_empresa");

    try {
      const response = await API.userConfig.getNiveles(id_new_empresa);
      setNivelPrecio(response.data.costumer);
      setSubniveles(response?.data?.costumer[0]?.subniveles || []);
    } catch (error) {
      console.log("Error al buscar niveles", error);
      toast.error("Error al buscar niveles", { autoClose: 10000 });
    }
  };
  console.log("subniveles", subniveles);
  const changePeriods = (event) => {
    setPeriods(event.target.value);
  };

  const addNewAdditionalInvoice = () => {
    if (additional_invoice !== "") {
      let newArray = [...additional_invoices];
      newArray.push(additional_invoice);
      setAdditionalInvoices(newArray);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "nit":
        setClientNit(value);
        if (isCmi()) {
          if (value.toLowerCase() == "cf") {
            setDeshabilitarUserSinDocument(true);
            setClientName("Consumidor Final");
          } else {
            setDeshabilitarUserSinDocument(false);
            setClientName("");
            setClientNameFinal("");
          }
        }
        break;
      case "search_text":
        setSearchText(value);
        break;
      case "invoice_number":
        setInvoiceNumber(value);
        break;
      case "giftcard_code":
        setGiftCardCode(value);
        break;
      case "additional_invoice":
        setAdditionalInvoice(value);
        break;
      default:
      case "nombre_cliente":
        setClientName(value);
        break;
      case "nombre_cliente_final":
        setClientNameFinal(value);
      case "direccion_fiscal":
        setClientAddress(value);
        break;
      case "email":
        setClientEmail(value);
        break;
      case "taxFreeTicket":
        setTaxFreeTicket(value);
        break;
      case "client_code":
        setClientCode(value);
    }
  };

  const onBlurNIT = () => {
    if(clientNit) {
      getClients(clientNit);
    }
  }

  const handleNewChanges = (e) => {
    setNewForm({ ...newForm, [e.target.name]: e.target.value });
  };

  const handleBoxClick = (x) => {
    let chosenMethods = [...selectedMethods];
    if (x === 4) {
      // If user clicks on the 'multiples' button
      if (chosenMethods.includes(4)) {
        // If multiples was previously selected
        $(".payment-method-box").each(function () {
          if ($(this).hasClass("active-box")) {
            $(this).removeClass("active-box");
          }
        });
        setSelectedMethods([0]);
      } else {
        // If user clicks multiples for the first time
        $(".payment-method-box").each(function () {
          if ($(this).hasClass("active-box")) {
            $(this).removeClass("active-box");
          }
        });
        let currentElement = "box-" + x;
        $(`#${currentElement}`).addClass("active-box");
        setSelectedMethods([4]);
      }
    } else {
      /* if (chosenMethods.includes(4)) {
                let currentElement = "box-" + x;
                if (chosenMethods.includes(x)) {
                    $(`#${currentElement}`).removeClass("active-box");
                    setSelectedMethods(
                        chosenMethods.filter((value) => value !== x)
                    );
                    let existingData = paidAmount;
                    switch (x) {
                        case 1:
                            setPaidByCash("");
                            existingData.paid = paidByCard + paidByGiftCard;
                            break;
                        case 2:
                            setPaidByCard("");
                            existingData.paid = paidByCash + paidByGiftCard;
                            break;
                        case 3:
                            setPaidByGiftCard("");
                            existingData.paid = paidByCard + paidByCash;
                            break;
                        default:
                            break;
                    }
                    if (existingData.paid > salesCost.total) {
                        existingData.change =
                            existingData.paid - salesCost.total;
                        existingData.change =
                            Math.round(
                                (existingData.change + Number.EPSILON) * 100
                            ) / 100;
                    } else {
                        existingData.change = 0;
                    }
                    setPaidAmount(existingData);
                } else {
                    $(`#${currentElement}`).addClass("active-box");
                    chosenMethods.push(x);
                    setSelectedMethods(chosenMethods);
                }
            } else { */
      $(".payment-method-box").each(function () {
        if ($(this).hasClass("active-box")) {
          $(this).removeClass("active-box");
        }
      });
      let currentElement = "box-" + x;
      $(`#${currentElement}`).addClass("active-box");
      setSelectedMethods([x]);
      switch (x) {
        case 1:
          setPaidByCard(0);
          setPaidByGiftCard(0);
          break;
        case 2:
          setPaidByGiftCard(0);
          setPaidByCash(0);
          break;
        case 3:
          setPaidByCash(0);
          setPaidByCard(0);
          break;
        case 5:
          setPaidByCash(0);
          setPaidByCard(0);
          setPaidByGiftCard(0);
          break;
        default:
          break;
      }
      let existingData = paidAmount;
      existingData.paid = 0;
      existingData.change = 0;
      setPaidAmount(existingData);
    }
    /*  } */
  };

  const updateValue = (e) => {
    let target = e.target;
    const t = e.target.value;
    e.target.value =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
        : t;

    let existingData = paidAmount;
    let parsedValue = isNaN(parseFloat(target.value))
      ? 0
      : Math.abs(parseFloat(target.value));

    e.target.value = isNaN(parseFloat(target.value)) ? "" : target.value;

    if (target.name === "paidByCash") {
      setPaidByCash(parsedValue);
      existingData.paid =
        parseFloat(parsedValue) +
        parseFloat(paidByCard) +
        parseFloat(paidByGiftCard);
    }

    if (target.name === "paidByCard") {
      if (copiaSalesCost.total >= parseFloat(paidByCash) + parseFloat(parsedValue)){
        existingData.paid =
        parseFloat(paidByCash) +
        parseFloat(parsedValue) +
        parseFloat(paidByGiftCard);
        setPaidByCard(parsedValue);
      }else{
        toast.error("No pdebes ingresar una cantidad mayor en tarjeta al total")
      }
      
    }

    if (target.name === "paidByGiftCard") {
      setPaidByGiftCard(parsedValue);
      existingData.paid =
        parseFloat(paidByCash) +
        parseFloat(paidByCard) +
        parseFloat(parsedValue);
    }

    if (isCmi()) {
      if (existingData.paid > copiaSalesCost.total) {
        existingData.change = existingData.paid - copiaSalesCost.total;
        existingData.change =
          Math.round((existingData.change + Number.EPSILON) * 100) / 100;
      } else {
        existingData.change = 0;
      }
    } else {
      if (existingData.paid > salesCost.total) {
        existingData.change = existingData.paid - salesCost.total;
        existingData.change =
          Math.round((existingData.change + Number.EPSILON) * 100) / 100;
      } else {
        existingData.change = 0;
      }
    }
    setPaidAmount(existingData);
  };

  const toggleCheck = (e) => {
    let target = e.target;
    if (target.name === "check1") {
      setCheck1(!check1);
    } else {
      setCheck2(!check2);
    }
  };
  const getClientCredits = (clientId) => {
    API.clients
      .getClientAllDebts({ id_cliente_web_app: clientId })
      .then((res) => {
        let response = res.data;

        setDeuda(response.data);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getsucursalprincipalretainers = () => {
    API.sales
      .getsucursalprincipalretainers({
        idBodegaSucursal: store.id_sucursal_bodega_ubicacion,
      })
      .then((res) => {
        let response = res.data;
        ///console.log(response)
        setisPrincipalRetainer(response.data.grandes_retenedores);
      })
      .catch((err) => {});
  };

  console.log("clientDirections", clientDirections);

  const gotoResumen = (check) => {
    if (submitLoader) return;
    let valid = true;
    let descuento_metodo_entrega = 0;
    let adicional_por_metodo_entrega = 0;
    let descuentoEntregaFiltrado;
    let id_canala_venta;
    let codigoValeCMI = 0;
    let dateValeCMI = "";
    let objectDataToSend = {};
    let CardDirections = [];

    if (!clientName) {
      valid = false;
      toast.error("Debe seleccionar un cliente antes de continuar", {
        autoClose: 10000,
      });
    }
    if (selectedMethods.includes(5)) {
      console.log(deuda);
      if (
        creditLimit - deuda.reduce((a, b) => a + b.monto_deuda, 0) <
        salesCost.total
      ) {
        valid = false;
        toast.error("El límite de crédito no es suficiente para esta venta.", {
          autoClose: 10000,
        });
      }
    } else {
      if (check !== "anticipo") {
        if (isCmi()) {
          if (paidAmount.paid < copiaSalesCost.total.toFixed(2)) {
            valid = false;
            toast.error(
              "El monto pagado no puede ser menor que el total de la venta",
              { autoClose: 10000 }
            );
          }
        } else {
          if (paidAmount.paid < salesCost.total.toFixed(2)) {
            valid = false;
            toast.error(
              "El monto pagado no puede ser menor que el total de la venta",
              { autoClose: 10000 }
            );
          }
        }
      }
    }

    if (
      selectedMethods.includes(4) &&
      (invoice_number === "" || periods === "")
    ) {
      valid = false;
      if (!invoice_number) {
        toast.error(
          "Disculpe, debe ingresar los datos del número del recibo del pago con tarjeta, para continuar.",
          { autoClose: 10000 }
        );
      }

      if (!periods) {
        toast.error(
          "Debe completar los campos de la verificación del pago con tarjeta.",
          { autoClose: 10000 }
        );
      }
    }

    if (
      selectedMethods.includes(2) &&
      (invoice_number === "" || periods === "")
    ) {
      valid = false;

      if (!invoice_number) {
        toast.error(
          "Disculpe, debe ingresar los datos del número del recibo del pago con tarjeta, para continuar.",
          { autoClose: 10000 }
        );
      } else {
        toast.error(
          "Debe completar los campos de la verificación del pago con tarjeta.",
          { autoClose: 10000 }
        );
      }
    }

    if (!isCmi()) {
      if (selectedMethods.includes(3) && giftcard_code === "") {
        valid = false;
        toast.error("Debe introducir el código de la gift card", {
          autoClose: 10000,
        });
      }
    }

    for (let product of selectedProducts) {
      delete product.descripcion;
      if (!isCmi()) {
        delete product.vendido_por;
      }
      delete product.ubicacion;
      delete product.category;
    }

    // validar metodos de entrega
    if (isAleko() && metodoEntrega === "entrega") {
      let validateCard = true;

      // Validar targeta de direcciones
      if (direcciones.length > 0) {
        validateCard = direcciones.map((item) => {
          if (
            item.domicilio === "" ||
            item.cod_postal === "" ||
            item.city === ""
          ) {
            return false;
          } else if (isNaN(item.cod_postal)) {
            return false;
          } else {
            return true;
          }
        });
      } else {
        validateCard = false;
      }
      if (validateCard == false) {
        toast.warning("Debes llenar los datos para entrega", {
          autoClose: 10000,
        });
        return null;
      }
    }
    if (isCmi()) {
      if (!metodoEntrega) {
        valid = false;
        toast.warning("Debe escoger un canal de venta para continuar", {
          autoClose: 10000,
        });
      } else {
        if (metodoEntrega.toLowerCase() == "presencial") {
          // agregar el monto de descuento
          descuentoEntregaFiltrado = descuentos.filter(
            (item) => item.nombre.toLowerCase() == "entrega"
          );
          console.log("descuentoEntregaFiltrado", descuentoEntregaFiltrado);
          descuento_metodo_entrega =
            descuentoEntregaFiltrado[0].costo_descuento;
          let canalVenta = canalesVentas.filter(
            (item) => item.nombre.toLowerCase() === metodoEntrega.toLowerCase()
          );
          id_canala_venta = parseInt(canalVenta[0].codigo);
        }

        if (metodoEntrega.toLowerCase() == "pick-up") {
          descuentoEntregaFiltrado = descuentos.filter(
            (item) => item.nombre.toLowerCase() == "entrega"
          );
          descuento_metodo_entrega =
            descuentoEntregaFiltrado[0].costo_descuento;
          let canalVenta = canalesVentas.filter(
            (item) => item.nombre.toLowerCase() === metodoEntrega.toLowerCase()
          );
          id_canala_venta = parseInt(canalVenta[0].codigo);
        }

        if (metodoEntrega.toLowerCase() == "entrega") {
          let validateCard = true;

          // Validar targeta de direcciones
          if (direcciones.length > 0) {
            validateCard = direcciones.map((item) => {
              if (
                item.domicilio === "" ||
                item.cod_postal === "" ||
                item.city === ""
              ) {
                return false;
              } else if (isNaN(item.cod_postal)) {
                return false;
              } else {
                return true;
              }
            });
          } else {
            validateCard = false;
          }

          if (validateCard == false) {
            toast.warning("Debes llenar los datos para entrega", {
              autoClose: 10000,
            });
            return null;
          }

          CardDirections = [...direcciones];

          descuentoEntregaFiltrado = descuentos.filter(
            (item) => item.nombre.toLowerCase() == "entrega"
          );
          adicional_por_metodo_entrega =
            descuentoEntregaFiltrado[0].costo_adicional;

          let canalVenta = canalesVentas.filter(
            (item) => item.nombre.toLowerCase() === metodoEntrega.toLowerCase()
          );
          id_canala_venta = parseInt(canalVenta[0].codigo);
        }
      }

      if (selectedMethods[0] == 3) {
        if (valeCMI.codigoVale === "" || valeCMI.dateVale === "") {
          toast.error("Debe llenar todos los campos para metodo de pago vale", {
            autoClose: 10000,
          });
          return null;
        } else {
          codigoValeCMI = valeCMI.codigoVale;
          dateValeCMI = valeCMI.dateVale;
        }
      }
    }

    if (selectedMethods[0] == 0 && check !== "anticipo") {
      toast.warning("Debe escoger un tipo de pago", { autoClose: 10000 });
      return null;
    }

    if (
      check === "anticipo" &&
      (saldoAnticipos < copiaSalesCost.total ||
        saldoAnticipos < salesCost.total)
    ) {
      toast.error("El cliente no cuenta con suficiente saldo de anticipos", {
        autoClose: 10000,
      });
      setSubmitLoader(false);
      return;
    }

    if (isCmi()) {
      objectDataToSend = {
        ...newForm,
        id_vendedor_web_app: seller.id_vendedor_web_app,
        id_sucursal_bodega_ubicacion: store.id_sucursal_bodega_ubicacion,
        id_caja_web_app: caja.id_caja_web_app,
        id_client_web_app: clientID,
        // reemplazar por codigo de tipos de entrega
        tipo_venta: id_canala_venta,
        nombre: clientName,
        nombre_cliente_final: clientNameFinal !== "" ? clientNameFinal : null,
        direccion: clientAddress ? clientAddress : null,
        email: clientEmail,
        nit: clientNit,
        forma_pago: selectedMethods.includes(4) ? 4 : selectedMethods[0],
        efectivo: parseFloat(paidByCash).toFixed(2),
        tarjeta: parseFloat(paidByCard).toFixed(2),
        credito: 0,
        flag_honduras: moneda === "L",
        level_precio: idNivelPrecio,
        type_client: clientType,

        // metodo por gittcard

        gift_card: parseFloat(paidByGiftCard).toFixed(2),
        gift_card_fecha: giftCard_fecha,
        gift_card_codigo: giftcard_code,

        // Vale para cmi
        codigoValeCMI,
        dateValeCMI,

        // metodo de entrega

        recibo_numero: invoice_number,
        adicional_recibo: additional_invoices.join(","),
        cuotas: periods,
        selectedProducts: JSON.stringify(selectedProducts),
        moneda: parseInt(currency.id_moneda_web_app),
        documento_exento: taxFreeTicket,
        descuento_por_metodo_entrega: descuento_metodo_entrega,
        canal_venta: null,
        direcciones: JSON.stringify(CardDirections),
        // nivel_precio: idPriceLevel !== 1 ? idPriceLevel : null,
        // canal_venta: parseInt(attentionChannel.id_canal)
      };

      if (check === "anticipo") {
        objectDataToSend = {
          ...objectDataToSend,
          forma_pago: 6,
          total_venta_anticipo: copiaSalesCost.total,
        };
      }
    } else {
      objectDataToSend = {
        ...newForm,
        id_vendedor_web_app: seller.id_vendedor_web_app,
        id_sucursal_bodega_ubicacion: store.id_sucursal_bodega_ubicacion,
        id_caja_web_app: caja.id_caja_web_app,
        id_client_web_app: clientID,
        tipo_venta: typeSell,
        nombre: clientName,
        direccion: clientAddress ? clientAddress : null,
        email: clientEmail,
        nit: clientNit,
        forma_pago: selectedMethods.includes(4) ? 4 : selectedMethods[0],
        efectivo: parseFloat(paidByCash).toFixed(2),
        tarjeta: parseFloat(paidByCard).toFixed(2),

        credito: 0,
        gift_card: parseFloat(paidByGiftCard).toFixed(2),
        gift_card_fecha: giftCard_fecha,
        gift_card_codigo: giftcard_code,
        recibo_numero: invoice_number,
        adicional_recibo: additional_invoices.join(","),
        cuotas: periods,
        selectedProducts: JSON.stringify(selectedProducts),
        moneda: parseInt(currency.id_moneda_web_app),
        documento_exento: taxFreeTicket,
        canal_venta: parseInt(attentionChannel.id_canal),
      };
      if (isAleko()) {
        objectDataToSend = {
          ...objectDataToSend,
          aleko: isAleko(),
          id_pedido: id_new_pedido,
        };
      }
      if (check === "anticipo") {
        objectDataToSend = {
          ...objectDataToSend,
          forma_pago: 6,
          total_venta_anticipo: parseFloat(salesCost.total).toFixed(2),
        };
      }

      if (typeSell === "3") {
        if (newForm.telefono_cliente.trim() === "") {
          toast.warn(
            "En ventas por a domicilio, el número telefónico es obligatorio",
            { autoClose: 10000 }
          );
          return;
        }
        if (clientAddress.trim() === "") {
          toast.warn(
            "En ventas a domicilio, la dirección del cliente es obligatoria",
            { autoClose: 10000 }
          );
          return;
        }
      }
    }

    console.log("data to send ->", objectDataToSend);

    if (valid) {
      setSubmitLoader(true);
      objectDataToSend.id_direccion_entrega_cliente = "";
      //console.log(objectDataToSend)
      if (metodoEntrega.toLowerCase() == "entrega") {
        if (!direcciones.length) {
          throw new Error('Se debe elegir al menos una dirección de domicilio')
        }
        objectDataToSend.direccion_entrega_cliente = direcciones[0].domicilio;
        objectDataToSend.ciudad_entrega_cliente = direcciones[0].city;
        objectDataToSend.telefono_entrega_cliente = direcciones[0].cod_postal;
        objectDataToSend.observaciones_entrega_cliente =
          direcciones[0].observaciones;
        objectDataToSend.id_direccion_entrega_cliente = !isNaN(
          direcciones[0].id
        )
          ? direcciones[0].id
          : "";
      }
      objectDataToSend.isVIP = idNivelPrecio == 1 ? false : true;
      objectDataToSend.id_sub_nivel_precio = priceLabel?.id_sub_nivel;
      objectDataToSend.id_pos_movimiento_postfacturacion = localStorage.getItem('id_post_movimiento_posfactura')
      API.sales
        .createNew(objectDataToSend)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            console.log("Sale added, obtained this:", response.data);
            setSubmitLoader(false);
            let felObjeto = response.data.felData;
            felObjeto.nombre_cliente_final = response.data.nombre_cliente_final;
            console.log("felobjeto", felObjeto);
            localStorage.setItem("feldata", JSON.stringify(felObjeto));

            const toDelete = [
              "selectedProducts",
              "invoiceInfo",
              "clientInfo",
              "salesCost",
              "fel_data",
              "numero_cards_direcciones",
              "metodo_entrega",
            ];
            toDelete.map((value) => {
              localStorage.removeItem(value);
            });

            window.localStorage.setItem(
              "cod_contribuyente",
              JSON.stringify(response.data.codigoSatGuatefacturas)
            );

            window.localStorage.setItem(
              "granContribuyente",
              JSON.stringify(response.data.granContribuyente)
            );
            history.push({
              pathname:
                "/app/vender/resumen/" + response.data.id_pos_movimiento,
              state: {
                fel_data: response.data,
              },
            });
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
            setSubmitLoader(false);
          }
        })
        .catch((err) => {
          setSubmitLoader(false);
          let response = err.response;
          if (response !== undefined) {
            //console.log(response);
            if (typeof response.data !== undefined) {
              if (response.data.msg) {
                toast.error(response.data.msg, {
                  autoClose: 10000,
                });
              } else {
                toast.error(response.data.message, {
                  autoClose: 10000,
                });
              }
            } else {
              console.log("Ha ocurrido un error");
              toast.error("Ha ocurrido un error", {
                autoClose: 10000,
              });
            }
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        });
    }
  };
  console.log("direcciones", direcciones);
  const backToCatalogo = () => {
    history.push({
      pathname: "/app/vender",
    });
  };

  //Post facturacion
  const gotoPostResumen = (check) => {
    if (submitLoader) return;
    let valid = true;
    let descuento_metodo_entrega = 0;
    let adicional_por_metodo_entrega = 0;
    let descuentoEntregaFiltrado;
    let id_canala_venta;
    let codigoValeCMI = 0;
    let dateValeCMI = "";
    let objectDataToSend = {};
    let CardDirections = [];

    if (!clientName) {
      valid = false;
      toast.error("Debe seleccionar un cliente antes de continuar", {
        autoClose: 10000,
      });
    }
    
    for (let product of selectedProducts) {
      delete product.descripcion;
      if (!isCmi()) {
        delete product.vendido_por;
      }
      delete product.ubicacion;
      delete product.category;
    }

    objectDataToSend = {
      ...newForm,
      id_vendedor_web_app: seller.id_vendedor_web_app,
      id_sucursal_bodega_ubicacion: store.id_sucursal_bodega_ubicacion,
      id_caja_web_app: caja.id_caja_web_app,
      id_client_web_app: clientID,
      tipo_venta: id_canala_venta,
      nombre: clientName,
      nombre_cliente_final: clientNameFinal !== "" ? clientNameFinal : null,
      direccion: clientAddress ? clientAddress : null,
      email: clientEmail,
      nit: clientNit,
      forma_pago: selectedMethods.includes(4) ? 4 : selectedMethods[0],
      efectivo: parseFloat(paidByCash).toFixed(2),
      tarjeta: parseFloat(paidByCard).toFixed(2),
      credito: 0,
      flag_honduras: moneda === "L",
      level_precio: idNivelPrecio,
      type_client: clientType,
      // metodo por gittcard
      gift_card: parseFloat(paidByGiftCard).toFixed(2),
      gift_card_fecha: giftCard_fecha,
      gift_card_codigo: giftcard_code,
      codigoValeCMI,
      dateValeCMI,
      recibo_numero: invoice_number,
      adicional_recibo: additional_invoices.join(","),
      cuotas: periods,
      selectedProducts: JSON.stringify(selectedProducts),
      moneda: parseInt(currency.id_moneda_web_app),
      documento_exento: taxFreeTicket,
      descuento_por_metodo_entrega: descuento_metodo_entrega,
      canal_venta: null,
      direcciones: JSON.stringify(CardDirections),
    };
    
    if (valid) {
      setSubmitLoader(true);
      objectDataToSend.id_direccion_entrega_cliente = "";
      if (metodoEntrega.toLowerCase() == "entrega") {
        if (!direcciones.length) {
          throw new Error('Se debe elegir al menos una dirección de domicilio')
        }
        objectDataToSend.direccion_entrega_cliente = direcciones[0].domicilio;
        objectDataToSend.ciudad_entrega_cliente = direcciones[0].city;
        objectDataToSend.telefono_entrega_cliente = direcciones[0].cod_postal;
        objectDataToSend.observaciones_entrega_cliente =
          direcciones[0].observaciones;
        objectDataToSend.id_direccion_entrega_cliente = !isNaN(
          direcciones[0].id
        )
          ? direcciones[0].id
          : "";
      }
      objectDataToSend.isVIP = idNivelPrecio == 1 ? false : true;
      objectDataToSend.id_sub_nivel_precio = priceLabel?.id_sub_nivel;
      API.sales
        .createNewPrefacturacion(objectDataToSend)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setSubmitLoader(false);

            const toDelete = [
              "selectedProducts",
              "invoiceInfo",
              "clientInfo",
              "salesCost",
              "fel_data",
              "numero_cards_direcciones",
              "metodo_entrega",
            ];
            toDelete.map((value) => {
              localStorage.removeItem(value);
            });

            setNoOrden(response.data.id_pos_movimiento)
            $("#create_postFacturacion").modal("show");

          } else { 
            toast.error(response.message, {
              autoClose: 10000,
            });
            setSubmitLoader(false);
          }
        })
        .catch((err) => {
          setSubmitLoader(false);
          let response = err.response;
          if (response !== undefined) {
            if (typeof response.data !== undefined) {
              if (response.data.msg) {
                toast.error(response.data.msg, {
                  autoClose: 10000,
                });
              } else {
                toast.error(response.data.message, {
                  autoClose: 10000,
                });
              }
            } else {
              toast.error("Ha ocurrido un error", {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        });
    }
  };

  //Cerrar modal y refrescar para post Facturacion
  const handleChangePrefactura = () => {
    history.push("/app/vender");
    $("#create_postFacturacion").modal("hide");
  }

  const searchClient = () => {
    setLoader2(true);
    API.clients
      .searchClient({ search_text: searchText })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("This is the data:", response.data);
          setClientName(response.data.nombre);
          setClientNit(response.data.nit);
          setClientAddress(response.data.direccion);
          setClientEmail(response.data.email);
          setCreditDays(response.data.dias_credito);
          setCreditLimit(response.data.limite_credito);
          setClientPriceLevel(response.data.id_nivel_precio);
          setLoader2(false);
          setUserFound(true);
        } else {
          setUserFound(false);
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setUserFound(false);
        setLoader2(false);
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const resetClient = () => {
    setUserFound(false);
    setClient({ id_nivel_precio: 0, id_client_web_app: 0 });
    setCartRecalculated(false);
    setClientName("");
    setClientAddress("");
    setClientEmail("");
    setClientPriceLevel("");
    setCreditDays("");
    setCreditLimit("");
    setClientNameFinal("");
    setClientCode("");
    setIsEditMode(false);
    setDeshabilitarUserSinDocument(false);
    setNewForm({
      telefono_cliente: "",
      codigo_cliente: "",
    });
    setPaidByCash(0);
    setPaidAmount({ ...paidAmount, paid: 0, change: 0 });
    setPaidByCard(0);
    setPaidAmount({ ...paidAmount, paid: 0, change: 0 });
  };

  const houseKeeping = () => {
    const toDelete = [
      "selectedProducts",
      "invoiceInfo",
      "clientInfo",
      "salesCost",
      "seller",
      "store",
      "caja",
      "datosCliente",
      "id_post_movimiento_posfactura"
    ];
    toDelete.map((value) => {
      localStorage.removeItem(value);
    });
    history.push({
      pathname: "/app/dashboard",
    });
  };
  const submitClient = () => {
    setClientCreationLoader(true);
    let validForm = true;
    let emailFormat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ((clientEmail != "" && clientEmail != null) && !emailFormat.test(clientEmail)) {
      validForm = false;
      toast.error("El correo no tiene formato válido", {
        autoClose: 10000,
      });
    }

    if (clientNit === "") {
      validForm = false;
    }
    if (clientName === "") {
      validForm = false;
    }
    if (typeSell === "3") {
      if (clientAddress.trim() === "") {
        toast.warn(
          "En ventas a domicilio, la dirección del cliente es obligatoria",
          { autoClose: 10000 }
        );
        setClientCreationLoader(false);
        return;
      }

      if (newForm.telefono_cliente.trim() === "") {
        toast.warn(
          "En ventas a domicilio, el número telefónico es obligatorio",
          { autoClose: 10000 }
        );
        setClientCreationLoader(false);
        return;
      }
    }

    let data = {};

    if (isCmi()) {
      data = {
        nit: clientNit,
        nombre: clientName,
        nombre_cliente_final: clientNameFinal ? clientNameFinal : null,
        direccion: clientAddress === clientNameFinal ? null : clientNameFinal,
        email: clientEmail == "" ? null : clientEmail,
        limite_credito: 0,
        id_nivel_precio: deshabilitarUserSinDocument ? 1 : idNivelPrecio,
        dias_credito: 0,
        diferenciacion: deshabilitarUserSinDocument ? "individual" : clientType,
        client_code: deshabilitarUserSinDocument ? clientCode : clientNit,
        telefono:
          newForm.telefono_cliente !== "" ? newForm.telefono_cliente : null,
        //nivel_precio: clientPriceLevel,
        //limit: 0,
      };
    } else {
      data = {
        nombre: clientName,
        direccion: clientAddress,
        email: clientEmail,
        nit: clientNit,
        nivel_precio: clientPriceLevel,
        limit: 0,
        dias_credito: 0,
        telefono: newForm.telefono_cliente,
        diferenciacion: clientType,
      };
    }

    // {
    //   nombre: clientName,
    //   direccion: clientAddress,
    //   email: clientEmail,
    //   nit: clientNit,
    //   nivel_precio: clientPriceLevel,
    //   limit: 0,
    //   dias_credito: 0,
    //   telefono: newForm.telefono_cliente,
    //   diferenciacion: clientType,
    // }

    console.log("data to send ->", data);

    if (!!validForm) {
      API.clients
        .add_new(data)
        .then((res) => {
          let response = res.data;

          console.log("res ->", res);
          setClientID(response.id_cliente_web_app);
          if (isCmi()) {
            if (response?.data?.errors?.length > 0) {
              toast.error(response.data.errors[0].msg, { autoClose: 10000 });
              return;
            }

            if (res.status === 200) {
              toast.success("Cliente creado con éxito", {
                autoClose: 10000,
              });
              setShowBtns(false);
              //setIsEditMode(true);
              setClientDirections([
                {
                  direccion: "",
                  telefono: "",
                  observaciones: "",
                  ciudad: "",
                  new: true,
                  id_direccion_entrega_cliente: null,
                },
              ]);
              //setUserFound(true);
              recalculateInvoice(response.data.id_cliente_web_app);
            }

            setClientCreationLoader(false);
          } else {
            if (response.success) {
              toast.success("Cliente creado con éxito", {
                autoClose: 10000,
              });
              setShowBtns(false);
              //setIsEditMode(true);

              setClientDirections([
                {
                  direccion: "",
                  telefono: "",
                  observaciones: "",
                  ciudad: "",
                  new: true,
                  id_direccion_entrega_cliente: null,
                },
              ]);

              recalculateInvoice(response.data.id_cliente_web_app);
            } else {
              toast.error(response.message, { autoClose: 10000 });
            }
            setClientCreationLoader(false);
          }
          getClients(clientNit)
        })
        .catch((err) => {
          setClientCreationLoader(false);
          console.log(err.response);

          if (err?.response?.data?.errors.length > 0) {
            toast.error(err.response.data.errors[0].msg, { autoClose: 10000 });
          }
        });
    } else {
      setClientCreationLoader(false);
      toast.error(
        "Debe llenar todos los campos de cliente para poder realizar la creación.",
        { autoClose: 10000 }
      );
    }
  };

  const getSuggestions = (value = "") => {
    if (!Array.isArray(clientesFacturacion)) {
      //getClients(value);
      return [];
    }

    const inputValue = document
      .getElementById("client_query")
      .value.trim()
      .toLowerCase();
    const inputLength = inputValue.length;

    var dataResult =
      inputLength === 0
        ? []
        : //: clients
          clientesFacturacion
            .filter(function (client) {
              if (
                client.nombre.toLowerCase().includes(inputValue.toLowerCase())
              ) {
                return client;
              }

              if (client.nombre_cliente_final !== null) {
                if (
                  client.nombre_cliente_final
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                ) {
                  return client;
                }
              }

              if (String(client.telefono).includes(inputValue)) {
                return client;
              }

              if (client.nit.toLowerCase().includes(inputValue.toLowerCase())) {
                return client;
              }
              if (client.email !== null) {
                if (
                  client.email.toLowerCase().includes(inputValue.toLowerCase())
                ) {
                  return client;
                }
              }
              if (
                client.client_code
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              ) {
                return client;
              }
            })
            .slice(0, 10);

    if (dataResult.length == 0) {
      //getClients(value);
    }
    return dataResult;
  };
  const obtenerUltimaDireccion = async (nit) => {
    const id_new_empresa = localStorage.getItem("id_empresa");
    const { data } = await API.sales.getUltimaDireccion({
      id_new_empresa,
      nit,
    });
    if (data.data_convertido) {
      setLastAddress(data.data_convertido);
    } else {
      setLastAddress({
        domicilio: "",
        cod_postal: "",
        observaciones: "",
        city: "",
      });
    }
  };

  const getProductsPriceLevels = async (id, id_nivel) => {
    let id_local_empresa = localStorage.getItem("id_empresa");

    try {
      const { costumer } = await API.priceLevels.getTypeCostumer(
        id_local_empresa
      );

      var fecha1 = moment(new Date());
      var fecha2 = moment(client.fecha_inicio);
      var fecha3 = moment(client.fecha_inicio_sub_nivel);
      var cantidadDia = fecha1.diff(fecha2, "days");
      var cantidadDia2 = fecha1.diff(fecha3, "days");

      const { data, units, pounds } =
        await API.priceLevels.allProductsPriceLevel(
          id_local_empresa,
          localStorage.getItem("selectedProducts"),
          id
        );
      debugger;
      console.log(client, subniveles);
      var flag = false;
      var subnivel2 = null;
      try {
        console.log(cantidadDia, cantidadDia2);
        if (cantidadDia >= costumer[0].frecuencia) {
          if (cantidadDia2 >= costumer[0].frecuencia * 2) {
            var subnivel = subniveles?.find(
              (x) => x.id === client.id_sub_nivel_precio
            );
            var subnivel2 = subniveles?.find(
              (x) =>
                x.cantidad_hasta >= subnivel.cantidad_desde - 1 &&
                x.cantidad_desde <= subnivel.cantidad_desde - 1
            );
            console.log(subnivel2);
            flag = true;
            debugger;
          }
        }
      } catch ($e) {
        flag = false;
      }
      if (data.length) {
        setPriceLabel({
          ...data[0],
          nombre_nivel: data[0].nombre_nivel,
          id_sub_nivel: data[0].id_subnivel,
          units,
          pounds,
        });

        toast.success(
          "El precio total fue actualizado debido al nivel de precios del cliente.",
          { autoClose: 10000 }
        );
      } else {
        console.log("================================================2", flag);
        debugger;
        setPriceLabel({
          nombre_nivel: flag
            ? subnivel2
              ? subnivel2.id_subnivel
              : "Regular"
            : subniveles?.find((x) => x.id === client.id_sub_nivel_precio)
                ?.subnivel || "Regular",
          id_sub_nivel: flag ? (subnivel2 ? subnivel2.id_subnivel : 1) : 1,
          units,
          pounds,
        });
        setSelectedProducts(
          JSON.parse(localStorage.getItem("selectedProducts"))
        );

        setCopiaSalesCost(JSON.parse(window.localStorage.getItem("salesCost")));
      }
      console.log(data);
      debugger;
      const newArrProducts = () => {
        return JSON.parse(localStorage.getItem("selectedProducts")).map((p) => {
          const newPrice = data.find(
            (x) => x.id_new_item === p.id_new_item
          )?.precio;
          if (newPrice) {
            return { ...p, nivel_precio: newPrice, precio_venta: newPrice };
          }
          return p;
        });
      };

      // localStorage.setItem(
      //   "selectedProducts",
      //   JSON.stringify(selectedProducts)
      // );
      console.log(id_nivel);
      debugger;
      await recalculateInvoice(id, id_nivel, JSON.stringify(newArrProducts()));
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const getSuggestionValue = (suggestion) => {
    //setCartRecalculated(false);
    console.log("sugerencia ->", suggestion, "----------- SSSSS");
    //setClient(suggestion);
    /*if (isCmi()) {
      setIsEditMode(true);
    }

    if (suggestion.nit.toLowerCase() === "cf") {
      setDeshabilitarUserSinDocument(true);
    } else {
      setDeshabilitarUserSinDocument(false);
    }*/

    // ------------------------
    setNewForm({
      ...newForm,
      telefono_cliente: suggestion?.telefono ? suggestion.telefono : "",
    });

    setClientCode(suggestion.client_code);
    setClientNameFinal(suggestion.nombre_cliente_final);
    setClientDirections([]);
    // setClientName(
    //   suggestion.nit.toLowerCase() === 'cf'
    //     ? 'Consumidor Final'
    //     : suggestion.nombre
    // )
    setClientName(suggestion.nombre);
    setIdNivelPrecio(suggestion.id_nivel_precio);
    setClientNit(suggestion.nit);
    setClientAddress(suggestion.direccion);
    setClientEmail(suggestion.email);
    setCreditDays(suggestion.dias_credito);
    setClientPriceLevel(suggestion.id_nivel_precio);
    setCreditLimit(suggestion.limite_credito);
    setClientID(suggestion.id_cliente_web_app);
    setCountTicket(suggestion.count_ticket);
    setHalfTicket(suggestion.half_ticket);
    setIdClientWebApp(suggestion.id_cliente_web_app);
    /*
    setIdEmpresa(suggestion.price_level.id_nivel_precio);
    setIdPriceLevel(suggestion.price_level.id_nivel_precio);
    */

    setIdEmpresa(suggestion.id_nivel_precio);
    setIdPriceLevel(suggestion.id_nivel_precio);

    setlifeValue(suggestion.life_value);
    setLimiteCredito(suggestion.limite_credito);
    setPaidByCash(0);
    setPaidAmount({ ...paidAmount, paid: 0, change: 0 });
    setPaidByCard(0);
    setPaidAmount({ ...paidAmount, paid: 0, change: 0 });
    //setUserFound(true);
    // -------------------------
    // recalculateInvoice(
    //   suggestion.id_cliente_web_app,
    //   suggestion.price_level.id_nivel_precio
    // );
    // buscarCardsDireccion(suggestion);
    // getUserPriceLevels(suggestion.id_cliente_web_app);
    // setUserFound(true);
    // getClientCredits(suggestion.id_cliente_web_app);
    // getProductsPriceLevels(
    //   suggestion.id_cliente_web_app,
    //   suggestion.price_level.id_nivel_precio
    // );
    return "";
  };

  useEffect(() => {
    if (client && userFound) {
      recalculateInvoice(
        client.id_cliente_web_app,
        //client.price_level.id_nivel_precio
        client.id_nivel_precio
      );
      setCartRecalculated(false);
      buscarCardsDireccion(client);
      getUserPriceLevels(client.id_cliente_web_app);
      setUserFound(true);
      getClientCredits(client.id_cliente_web_app);
      getProductsPriceLevels(
        client.id_cliente_web_app,
        //client.price_level.id_nivel_precio
        client.id_nivel_precio
      );
    }
  }, [userFound, client]);

  useEffect(( ) => {

    if(localStorage.getItem('datosCliente')) {
      const datosCliente = JSON.parse(localStorage.getItem('datosCliente'));
      getSuggestionValue(datosCliente)
    }
  },[])

  const getUserPriceLevels = (clientId) => {
    API.sales
      .getPriceLevels({
        clientId: clientId,
        enterpriseId: localStorage.getItem("id_empresa"),
      })
      .then((res) => {
        let response = res.data;
        console.log("response.level", response);
        if (response.success) {
          console.log("niveles", response);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error(
              "Ha ocurrido un error al niveles de precio del cliente",
              {
                autoClose: 10000,
              }
            );
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Ha ocurrido un error al niveles de precio del cliente", {
          autoClose: 10000,
        });
      });
  };

  const renderSuggestion = (suggestion) => {
    try {
      const matches = AutosuggestHighlightMatch(suggestion.nombre, searchText);
      const parts = AutosuggestHighlightParse(
        suggestion.nombre_cliente_final
          ? suggestion.nombre_cliente_final
          : suggestion.nombre + " - " + suggestion.nit,
        matches
      );
      console.log("=================");
      console.log(parts);
      return (
        <div>
          {parts.map((part, index) => {
            const className = part.highlight
              ? "react-autosuggest__suggestion-match"
              : null;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </div>
      );
    } catch ($e) {
      console.error($e);
      return [];
    }
  };

  const changeFilterText = (e, { newValue }) => {
    setSearchText(newValue);
  };

  const onSuggestionsFetchRequested = (e = {}) => {
    //setSuggestions(getSuggestions(searchText));
    setSuggestions(getSuggestions(e.value));
  };

  const onSuggestionsClearRequested = () => {
    // if (userFound) {
    //   setNewForm({
    //     telefono_cliente: '',
    //     codigo_cliente: '',
    //   })
    // }
    setSuggestions([]);
  };

  const inputProps = {
    placeholder:
      moneda === "Q"
        ? "Buscar cliente por nombre, correo o NIT"
        : "Buscar cliente por nombre, correo o RTN",
    value: searchText,
    onChange: changeFilterText,
    id: "client_query",
    disabled: userFound,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const getClients = async (value = "") => {
    console.log("isLoaded", isLoaded);
    /*if (isLoaded) {
      return;
    }*/
    
    setIsLoaded(true);
    API.clients
      .getAll({
        searchGeneral: value || searchText,
        pagination: true,
        page: 1,
        limit: 10,
        validaNIT: 1,
        creaCliente: 1,
      })
      .then((res) => {
        resetClient();
        let response = res.data;
        if (response.success) {
          setClients(response.data.clients);
          getSuggestionValue(response.data.clients[0])
          if(!response.data.clients[0].id_cliente_web_app) {
            setEnableCrearCliente(true);
          }
          else {
            setEnableCrearCliente(false);
          }
          
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
            setEnableCrearCliente(true);
          } else {
            toast.error("Ha ocurrido un error al obtener el cliente", {
              autoClose: 10000,
            });
          }
        }
        setLoader(false);
        setIsLoaded(false);
      })
      .catch((err) => {
        resetClient();
        setLoader(false);
        setIsLoaded(false);
        setEnableCrearCliente(true);
        console.log(err);
        toast.error("Ha ocurrido un error al obtener el cliente", {
          autoClose: 10000,
        });
      });
  };
  const recalculateInvoice = async (id_cliente, id_nivel, prods) => {
    if (!prods) {
      const { data } = await API.clients.getClientAllAnticipos({
        id_cliente_web_app: id_cliente,
      });
      const anticipos_total = data.data.reduce(
        (a, b) => a + b.monto_disponible,
        0
      );
      setSaldoAnticipos(anticipos_total);
      return;
    }

    API.products
      .recalculateCart({
        products: prods || JSON.stringify(selectedProducts),
        id_cliente: id_cliente,
        id_caja: caja.id_caja_web_app,
        total: JSON.parse(localStorage.getItem("salesCost")).total,
        from_web: 1,
        isVIP: id_nivel == 1 || !id_nivel ? false : true,
      })
      .then((res) => {
        let response = res.data;
        console.log("Precio recalculado: ", response);
        if (response.success) {
          if (response.data.price_changed) {
            setSelectedProducts(response.data.products);
            console.log(
              "nuevos precios prods actualizados",
              response.data.products
            );
            buscarProductosActualizados(response.data.products);

            if (metodoEntrega === "entrega") {
              let descuentoActual = getDescuentoActual(metodoEntrega, false);
              setSalesCost((prev) => {
                return {
                  ...prev,
                  total: parseFloat(response.data.total) + descuentoActual,
                };
              });

              setCopiaSalesCost((prev) => {
                return {
                  ...prev,
                  total: parseFloat(response.data.total) + descuentoActual,
                };
              });
              // localStorage.setItem(
              //   "selectedProducts",
              //   JSON.stringify(response.data.products)
              // );
            } else {
              setSalesCost((prev) => {
                return {
                  ...prev,
                  total: parseFloat(response.data.total),
                };
              });
              setCopiaSalesCost((prev) => {
                return {
                  ...prev,
                  total: parseFloat(response.data.total),
                };
              });
              // localStorage.setItem(
              //   "selectedProducts",
              //   JSON.stringify(response.data.products)
              // );
            }
          }
          setCartRecalculated(true);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error al recalcular el carrito", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al recalcular el carrito", {
          autoClose: 10000,
        });
      });
  };

  // cmi
  const buscarProductosActualizados = (array) => {
    let totalStorage = getTotalEnLocalStorage();
    let extractedPrice = array.map((item) => item.total);
    let total = extractedPrice.reduce((acc, el) => acc + el, 0);

    if (totalStorage === total) {
      console.log(array);
      console.log("totales iguales", totalStorage, total);
    } else {
      console.log("totales diferentes", totalStorage, total);
      let costosLocal = JSON.parse(window.localStorage.getItem("salesCost"));

      setCopiaSalesCost({
        ...copiaSalesCost,
        total: parseFloat(total),
      });

      costosLocal.total = total;
      // window.localStorage.setItem("salesCost", JSON.stringify(costosLocal));
      updateTotalDescuentos();
    }
  };

  const getPriceLevels = () => {
    API.priceLevels
      .getAll()
      .then((res) => {
        let response = res.data;
        setPriceLevels(response.data);
        if (!response.success) {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error(
              "Ha ocurrido un error al cargar los niveles de precio del sistema",
              {
                autoClose: 10000,
              }
            );
          }
        }
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al cargar los niveles de precio del sistema",
          { autoClose: 10000 }
        );
      });
  };

  // metodos entrega cmi
  const getEmpresaInfo = () => {
    API.userConfig
      .getEmpresaInfo()
      .then((res) => {
        let response = res.data;

        if (response.success) {
          let respuesta = response.data;
          console.log("Empresa info: ", respuesta);
          setEmpresaInto(respuesta);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getCanalesVentas = async () => {
    try {
      const response = await API.sales.obtenerCanalesVentas();

      if (response?.data?.success) {
        console.log("Canales de venta", response.data.data);

        let canalDefecto = response.data.data.filter(
          (item) => item.nombre == "Presencial"
        );

        setCanalesVentas(response.data.data);

        // actualizar costo con metodo de entrega
        let descuentoActual = getDescuentoActual(canalDefecto[0].nombre);
        let preciototalActual = JSON.parse(
          window.localStorage.getItem("salesCost")
        );
        let totalStorage = getTotalEnLocalStorage();

        if (descuentoActual > 0) {
          if (salesCost.total > 0) {
            descuentoActual > 0 &&
              setCopiaSalesCost({
                ...copiaSalesCost,
                total: parseFloat(totalStorage),
              });

            preciototalActual.total = totalStorage;
          }
          window.localStorage.setItem(
            "salesCost",
            JSON.stringify(preciototalActual)
          );
          window.localStorage.removeItem("numero_cards_direcciones");
        }

        setMetodoEntrega("presencial");
        window.localStorage.setItem("metodo_entrega", "presencial");

        setActualCostoDescuento(descuentoActual);
      } else {
        toast.error("Error en canales de ventas *", { autoClose: 10000 });
        return null;
      }
    } catch (error) {
      console.log("Error al obtener canales de ventas #", error);
      toast.error("Error al obtener canales de ventas", { autoClose: 10000 });
    }
  };

  const getMetodoEntrega = () => {
    try {
      if (metodoEntrega) {
        let descuentoDefault = getDescuentoActual(metodoEntrega);
        setActualCostoDescuento(descuentoDefault);
      } else {
        console.log("Debe escoger un método entrega *");
        toast.error("Debe escoger un método entrega", { autoClose: 10000 });
      }
    } catch (error) {
      console.log("Debe escoger un método entrega #", error);
      toast.error("Debe escoger un método entrega", { autoClose: 10000 });
    }
  };

  const getDescuentos = async () => {
    try {
      let id_local_empresa = window.localStorage.getItem("id_empresa");

      if (id_local_empresa) {
        const res = await API.userConfig.getDescuentosCanalesVentas(
          id_local_empresa
        );
        console.log("descuentos", res.data.data);

        if (res?.data?.ok) {
          setDescuentos(res.data.data);
        } else {
          console.log("Error al obtener descuentos");
          toast.error("Error al obtener descuentos", { autoClose: 10000 });
        }
      } else {
        toast.error("No existe id de la empresa", { autoClose: 10000 });
      }
    } catch (error) {
      console.log("Error al obtener descuentos", error);
      toast.error("Error al obtener descuentos ", { autoClose: 10000 });
    }
  };

  const handleChangeMetodoEntrega = (event) => {
    let { value } = event.target;
    value = value.toLowerCase();
    if (value === "presencial" || value === "pick-up") {
      console.log("value", value);
      let descuentoActual = getDescuentoActual(value, false);
      console.log("bandera", descuentoActual);
      setActualCostoDescuento(descuentoActual);

      console.log("descuentoActual", descuentoActual);

      let preciototalActual = JSON.parse(
        window.localStorage.getItem("salesCost")
      );

      console.log("preciototalActual ->", preciototalActual);

      let total = getTotalEnLocalStorage();
      console.log("total", total);

      if (descuentoActual > 0) {
        // if (salesCost.total > 0) {
        //   descuentoActual > 0 &&
        //     setCopiaSalesCost({
        //       ...copiaSalesCost,
        //       total: parseFloat(total),
        //     });

        //   preciototalActual.total = total;
        // }
        // window.localStorage.setItem(
        //   "salesCost",
        //   JSON.stringify(preciototalActual)
        // );
        window.localStorage.removeItem("numero_cards_direcciones");
      }
    }

    if (value === "entrega") {
      let descuentoActual = getDescuentoActual(value, false);
      console.log("bandera2", descuentoActual);
      setActualCostoDescuento(descuentoActual);
      console.log("descuentoActual", descuentoActual);

      // precio actual guardado en localStorage
      let preciototalActual = JSON.parse(
        window.localStorage.getItem("salesCost")
      );

      console.log("preciototalActual ->", preciototalActual);

      // Este es el total de los productos reales
      let total = getTotalEnLocalStorage();

      console.log("total", total);

      if (descuentoActual > 0) {
        // setSalesCost({
        //   ...salesCost,
        //   total: total + descuentoActual,
        // });
        // setCopiaSalesCost({
        //   ...copiaSalesCost,
        //   total: total + descuentoActual,
        // });

        if (total + descuentoActual != preciototalActual.total) {
          preciototalActual.total = total + descuentoActual;
        }

        window.localStorage.setItem(
          "salesCost",
          JSON.stringify(preciototalActual)
        );
        window.localStorage.setItem(
          "numero_cards_direcciones",
          JSON.stringify([1])
        );
      }
    }

    setMetodoEntrega(value);
    window.localStorage.setItem("metodo_entrega", value);
  };

  const buscarCardsDireccion = async (cliente) => {
    API.directions
      .getAllDirections(cliente.id_cliente_web_app)
      .then((res) => {
        let response = res.data;
        console.log("directions", response);
        if (response.data.length > 0) {
          setClientDirections(response.data);
        } else {
          setClientDirections([
            {
              direccion: "",
              telefono: "",
              observaciones: "",
              ciudad: "",
              new: true,
              id_direccion_entrega_cliente: null,
            },
          ]);
        }
        setDirecciones([]);
        setReRender(!reRender);
      })
      .catch((err) => {
        setClientDirections([
          {
            direccion: "",
            telefono: "",
            observaciones: "",
            ciudad: "",
            new: true,
            id_direccion_entrega_cliente: null,
          },
        ]);
      });

    // let card_direcciones = JSON.parse(
    //   window.localStorage.getItem('numero_cards_direcciones')
    // )
    // const id_new_empresa = localStorage.getItem('id_empresa')
    // // let metodoEntregaSaved = window.localStorage.getItem('metodo_entrega')
    // if (nit) {
    //   const { data } = await API.sales.getUltimaDireccion({
    //     id_new_empresa,
    //     nit,
    //   })

    //   if (data.data_convertido?.length) {
    //     setNumeroDirecciones([1])
    //     setLastAddress(data.data_convertido[0])
    //   } else {
    //     setLastAddress({
    //       domicilio: '',
    //       cod_postal: '',
    //       observaciones: '',
    //       city: '',
    //     })
    //     setNumeroDirecciones([1])
    //   }

    // }

    // if (card_direcciones !== null) {
    //   setNumeroDirecciones(card_direcciones)
    // }
  };

  const addCardDireccion = () => {
    // if (clientDirections.filter((x) => x.new === true).length === 1) {
    //   toast.warning('Complete la direccion anterior', {
    //     autoClose: 10000,
    //   })
    //   return
    // }

    // let numeroCards = JSON.parse(
    //   window.localStorage.getItem('numero_cards_direcciones')
    // )

    if (clientDirections.length === 2) {
      console.log("entre2");
      toast.warning("Solo puedes agregar dos direcciones", {
        autoClose: 10000,
      });
      return null;
    }

    let newDirections = clientDirections.filter((x) => true);
    newDirections.push({
      direccion: "",
      telefono: "",
      observaciones: "",
      ciudad: "",
      new: true,
      id_direccion_entrega_cliente: null,
    });

    setClientDirections(newDirections);
    // numeroCards = [...numeroCards, 2]
    // setNumeroDirecciones(numeroCards)
    // window.localStorage.setItem(
    //   'numero_cards_direcciones',
    //   JSON.stringify(numeroCards)
    // )
  };

  const createDirecction = (objeto) => {
    API.directions
      .createDirecction(objeto)
      .then((res) => {
        let response = res.data;
        console.log("directions", response);

        ///console.log(response)
      })
      .catch((err) => {});
  };

  const removeCardDireccion = (numero) => {
    console.log(numero);

    console.log(
      clientDirections.filter((x, index) => {
        return index !== numero;
      })
    );
    let newArray = clientDirections.filter((x, index) => {
      return index !== numero;
    });
    setClientDirections(newArray);
    setReRender(!reRender);
    if (direcciones.length > 0) {
      if (direcciones[0].index === numero) {
        setDirecciones([]);
      }
    }
  };

  const updateTotalDescuentos = () => {
    let existingData = paidAmount;
    // traenis el descuento para metodo entrega
    let descuento;
    let adicional;

    if (metodoEntrega !== "") {
      let objDescuento = descuentos.filter(
        (item) => item.nombre.toLowerCase() == metodoEntrega.toLowerCase()
      );
      descuento = objDescuento[0].costo_descuento;
      adicional = objDescuento[0].costo_adicional;
    }

    console.log("valores a modificar", existingData);
    console.log("Valores staticos", salesCost);

    // paso 1 modificar el total segun el metodo de entrega
    if (
      metodoEntrega.toLowerCase() == "presencial" ||
      metodoEntrega.toLowerCase() == "pick-up"
    ) {
      if (descuento > 0) {
        setCopiaSalesCost({
          ...copiaSalesCost,
          total: parseFloat(salesCost.total),
        });
      }
      setActualCostoDescuento(
        getDescuentoActual(metodoEntrega.toLowerCase(), false)
      );
    }

    if (metodoEntrega.toLowerCase() == "entrega") {
      console.log(`metodo: ${metodoEntrega} - descuento: ${descuento}`);
      let costoTotalActual = JSON.parse(
        window.localStorage.getItem("salesCost")
      );
      if (adicional > 0) {
        // setSalesCost({...salesCost, total: salesCost.total + adicional})

        setCopiaSalesCost({
          ...copiaSalesCost,
          total: parseFloat(costoTotalActual.total),
        });
      }
      setActualCostoDescuento(
        getDescuentoActual(metodoEntrega.toLowerCase(), false)
      );
    }
  };

  const getTotalEnLocalStorage = () => {
    let productosSeleccionados = JSON.parse(
      window.localStorage.getItem("selectedProducts")
    );
    let precio = productosSeleccionados.map((item) => item.total);
    let total = precio.reduce((acc, el) => acc + el, 0);
    return total;
  };

  const getDescuentoActual = (entrega, costo = true) => {
    console.log("entrega", entrega, " - Costo", costo);

    let descuento = descuentos.filter(
      (item) => item.nombre.toLowerCase() == "entrega"
    );

    console.log("descuento actual", descuento[0].costo_descuento);

    if (costo) {
      return descuento[0].costo_descuento;
    } else {
      return descuento[0].costo_adicional;
    }
  };

  const handleExactAmountCash = (val) => {
    if (isCmi() && !userFound) {
      toast.error("Debe seleccionar un cliente antes de continuar");
      return;
    }

    if (isCmi() && !cartRecalculated) {
      toast.warn(
        "Por favor espere, se están obteniendo los precios de los productos."
      );
      return;
    }

    setPaidByCash(val);
    setPaidAmount({ ...paidAmount, paid: val });
  };

  const handleExactAmountCredit = (val) => {
    if (isCmi() && !userFound) {
      toast.error("Debe seleccionar un cliente antes de continuar");
      return;
    }

    if (isCmi() && !cartRecalculated) {
      toast.warn(
        "Por favor espere, se están obteniendo los precios de los productos."
      );
      return;
    }

    setPaidByCard(val);
    setPaidAmount({ ...paidAmount, paid: val });
  };

  const handleValeCMI = (event) => {
    let { name, value } = event.target;
    setValeCMI({
      ...valeCMI,
      [name]: value,
    });
  };

  const changeTypesell = (event) => {
    let existLocaltypeSell = JSON.parse(localStorage.getItem("tipo_venta"));
    if (existLocaltypeSell) {
      localStorage.removeItem("tipo_venta");
    }
    setTypesell(event.target.value);
    localStorage.setItem(
      "tipo_venta",
      JSON.stringify({
        tipo_venta: parseInt(event.target.value),
      })
    );
    if (event.target.value === "3") {
      setMetodoEntrega("entrega");
    } else {
      setMetodoEntrega("");
    }
  };

  const SendEditClient = async () => {
    setClientCreationLoader(true);

    const data = {
      client_code: clientCode,
      codigo_cliente: clientCode,
      client_id: clientID || idClientWebApp,
      nombre: clientName,
      nombre_cliente_final: clientNameFinal ? clientNameFinal : null,
      direccion: clientAddress,
      email: clientEmail ? clientEmail : null,
      nit: clientNit,
      nivel_precio: clientPriceLevel,
      type_client: clientType,
      id_nivel_precio: idNivelPrecio,
      diferenciacion: clientType,
      client_code: clientNit,
      telefono:
        newForm.telefono_cliente !== "" ? newForm.telefono_cliente : null,
      type_client: clientType,
      dias_credito: creditDays,
      count_ticket: countTicket,
      half_ticket: halfTicket,
      id_cliente_web_app: idClientWebApp,
      id_new_empresa: idEmpresa,
      life_value: lifeValue,
      limite_credito: limiteCredito,
      // saldo_acreedor: null,
      // saldo_duedor: null,
    };

    try {
      const res = await API.clients.updateClient(data);
      console.log("respuesta al editar usuario ->", res);

      if (res.data.success) {
        toast.success(res.data.message, { autoClose: 10000 });
        setIsEditMode(false);
        setClientCreationLoader(false);
      } else {
        toast.success("Error al actualizar cliente", { autoClose: 10000 });
        setIsEditMode(false);
        setClientCreationLoader(false);
      }
    } catch (error) {
      setClientCreationLoader(false);
      console.log("error editar client ->", error);
    }
  };

  useEffect(() => {
    if (metodoEntrega === "pick-up" || metodoEntrega === "presencial") {
      setCopiaSalesCost({
        ...salesCost,
        total: parseFloat(salesCost.total),
      });
    } else if (metodoEntrega === "entrega") {
      let adicional = getDescuentoActual(true, false);

      setCopiaSalesCost({
        ...copiaSalesCost,
        total: parseFloat(copiaSalesCost.total) + adicional,
      });
    }
  }, [metodoEntrega]);

  const getTotalProduct = (product) => {
    return Number((product?.cantidad * product?.precio_venta).toFixed(2));
  };

  useEffect(() => {
    const selectedProducts = JSON.parse(
      localStorage.getItem("selectedProducts")
    );
    let total = selectedProducts.reduce((a, b) => a + getTotalProduct(b), 0);
    total = total.toFixed(2);

    if (
      (metodoEntrega === "pick-up" || metodoEntrega === "presencial") &&
      Boolean(isCmi())
    ) {
      setCopiaSalesCost({
        ...salesCost,
        total: parseFloat(total),
      });
    } else if (metodoEntrega === "entrega" && Boolean(isCmi())) {
      let adicional = getDescuentoActual(true, false);

      setCopiaSalesCost({
        ...copiaSalesCost,
        total: parseFloat(total) + adicional,
      });
    }
  }, [metodoEntrega]);

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select disabled native>
            <option selected value={store.id_sucursal_bodega_ubicacion}>
              {store.nombre_sucursal}
            </option>
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select disabled native>
            <option selected value={caja.id_caja_web_app}>
              {caja.descripcion}
            </option>
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select disabled native>
            <option selected value={seller.id_vendedor_web_app}>
              {seller.nombre}
            </option>
          </Select>
        </FormControl>

        {/* <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        /> */}

        {/* <FormControl variant="outlined">
          <Select native disabled>
            <option selected value={currency.id_moneda_web_app}>
              {currency.descripcion.trim()}
            </option>
          </Select>
        </FormControl> */}

        {/* <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        /> */}

        {/* <FormControl variant="outlined">
          <Select native disabled>
            <option selected value={attentionChannel.id_canal}>
              {attentionChannel.descripcion.trim()}
            </option>
          </Select>
        </FormControl> */}
      </div>

      <div className="row">
        <div className="col-xl-6 mt-3">
          <div className="content-card card">
            <div className="card-header">
              <h4 className="mb-0">
                <strong>Datos del cliente</strong>
              </h4>
            </div>
            <div className="card-body">
              {isCmi() ? (
                <div className="contiene_tarjetas_entrega">
                  {canalesVentas.length > 0 &&
                    canalesVentas.map((item) => (
                      <CardEntrega
                        key={item.id_canal_cmi}
                        nombre={item.nombre}
                        metodoEntrega={metodoEntrega}
                        handleChangeMetodoEntrega={handleChangeMetodoEntrega}
                        clientSelected={userFound}
                      />
                    ))}
                </div>
              ) : (
                <CardEntregaCloud
                  typeSell={typeSell}
                  changeTypesell={changeTypesell}
                />
              )}

              <div
                className={`w-md-12 d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap`}
              >
                {/* validacion cmi */}
                {isCmi() ? (
                  ""
                ) : (
                  <div className="w-100 my-3">
                    <div className="d-flex mb-0">
                      <p className="mb-0 mr-1">
                        <strong>{moneda === "L" ? "RTN" : "NIT"}</strong>
                      </p>
                      <Tooltip
                        title={`El ${
                          moneda === "L" ? "RTN" : "NIT"
                        } no debe llevar guión y el caracter alfabético debe estar en mayuscula. Ej. 123456789K`}
                      >
                        <InfoIcon
                          style={{ color: " #828282", fontSize: "1.2rem" }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      className="w-100"
                      variant="standard"
                      name="nit"
                      value={clientNit}
                      onChange={handleInputChange}
                      onBlur={onBlurNIT}
                      placeholder={`Ingrese el ${
                        moneda === "L" ? "RTN" : "NIT"
                      } del cliente`}
                      disabled={userFound ? true : false}
                    />
                  </div>
                )}

                {/* validacion cmi */}
                {isCmi() ? (
                  <>
                    <div className="row w-50 mx-auto my-3">
                      <div className="w-100">
                        <p className="mb-0">
                          <strong>Nombre de cliente</strong>
                        </p>
                        <TextField
                          className="w-100"
                          variant="standard"
                          value={clientName}
                          onChange={handleInputChange}
                          name="nombre_cliente"
                          placeholder="Ingrese el nombre del cliente"
                          disabled={userFound ? true : false}
                        />
                      </div>
                    </div>
                    <div className="w-50 my-3 pl-2">
                      <div className="d-flex mb-0">
                        <p className="mb-0 mr-1">
                          {moneda === "L" ? (
                            <strong>RTN del cliente</strong>
                          ) : (
                            <strong>NIT del cliente</strong>
                          )}
                        </p>
                        <Tooltip
                          title={`El ${
                            moneda === "L" ? "RTN" : "NIT"
                          } no debe llevar guión y el caracter alfabético debe estar en mayuscula. Ej. 123456789K`}
                        >
                          <InfoIcon
                            style={{ color: " #828282", fontSize: "1.2rem" }}
                          />
                        </Tooltip>
                      </div>
                      <TextField
                        className="w-100"
                        variant="standard"
                        name="nit"
                        value={clientNit.toUpperCase()}
                        onChange={handleInputChange}
                        onBlur={onBlurNIT}
                        placeholder={
                          moneda === "L"
                            ? "Ingrese el RTN del cliente"
                            : "Ingrese el NIT del cliente"
                        }
                        disabled={userFound ? true : false}
                      />
                    </div>
                  </>
                ) : (
                  <div className="row w-100 mx-auto my-3">
                    <div className="w-100">
                      <p className="mb-0">
                        <strong>Nombre de cliente</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={clientName}
                        onChange={handleInputChange}
                        name="nombre_cliente"
                        placeholder="Ingrese el nombre del cliente"
                        disabled={userFound ? true : false}
                      />
                    </div>
                  </div>
                )}

                {/* Validacion cmi */}

                {isCmi() && deshabilitarUserSinDocument ? (
                  <div className="w-100 d-flex">
                    <div className="w-50">
                      <p className="mb-0">
                        <strong>Nombre del cliente final</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={clientNameFinal}
                        onChange={handleInputChange}
                        name="nombre_cliente_final"
                        placeholder="Ingrese el nombre del cliente final"
                        disabled={isEditMode ? true : false}
                      />
                    </div>
                    <div className="w-50 pl-2">
                      <p className="mb-0">
                        <strong>Código del cliente</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={clientCode}
                        onChange={handleInputChange}
                        name="client_code"
                        type="text"
                        placeholder="Ingrese el código del cliente"
                        disabled={isEditMode ? true : false}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {isCmi() ? (
                  <>
                    <div className="w-50 my-3 d-flex flex-column justify-content-between">
                      <div className="d-flex mb-2">
                        <p className="mb-0 mr-1">
                          <strong>Tipo de cliente</strong>
                        </p>
                        <Tooltip
                          title={`El ${
                            moneda === "L" ? "RTN" : "NIT"
                          } no debe llevar guión y el caracter alfabético debe estar en mayuscula. Ej. 123456789K`}
                        >
                          <InfoIcon
                            style={{ color: " #828282", fontSize: "1.2rem" }}
                          />
                        </Tooltip>
                      </div>
                      <div className="w-100 mb-0">
                        {/* <Select native fullWidth variant="standard">
                          <option value="">Individual</option>
                          <option value="">Juntado</option>
                        </Select> */}
                        <FormControl variant="outlined" className="w-100">
                          <Select
                            className="cs-select1 select-w100"
                            name="client_type"
                            native
                            onChange={(e) => setClientType(e.target.value)}
                            value={clientType}
                            disabled={
                              deshabilitarUserSinDocument || userFound
                                ? true
                                : false
                            }
                          >
                            <option value="individual">Individual</option>
                            <option value="restaurante">Restaurante</option>
                            <option value="cafeteria">Cafetería</option>
                            <option value="hotel">Hotel</option>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <Select className="w-100 form-control valid">
                        <option value="normal">Normal</option>
                        <option value="VIP">VIP</option>
                      </Select> */}
                    </div>
                    {!deshabilitarUserSinDocument ? (
                      <div className="w-50 my-3 pl-2">
                        <p className="mb-0">
                          <strong>
                            Dirección fiscal del cliente{" "}
                            {typeSell !== "3" && "(opcional)"}
                          </strong>
                        </p>
                        <TextField
                          className="w-100"
                          variant="standard"
                          value={clientAddress}
                          onChange={handleInputChange}
                          name="direccion_fiscal"
                          placeholder="Ingrese la dirección fiscal del cliente"
                          disabled={userFound ? true : false}
                        />
                      </div>
                    ) : (
                      <div className="w-50 my-3 pl-2">
                        <p className="mb-0">
                          <strong>
                            Número telefónico {typeSell !== "3" && "(opcional)"}
                          </strong>
                        </p>
                        <TextField
                          className="w-100"
                          variant="standard"
                          value={newForm.telefono_cliente}
                          onChange={handleNewChanges}
                          name="telefono_cliente"
                          type="text"
                          placeholder="Ingrese el número telefonico del cliente"
                          disabled={userFound}
                          // InputProps={{
                          //   inputComponent: PhoneFormatGt,
                          // }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-100 my-3">
                    <p className="mb-0">
                      <strong>
                        Dirección fiscal del cliente{" "}
                        {typeSell !== "3" && "(opcional)"}
                      </strong>
                    </p>
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={clientAddress}
                      onChange={handleInputChange}
                      name="direccion_fiscal"
                      placeholder="Ingrese la dirección fiscal del cliente"
                      disabled={userFound ? true : false}
                    />
                  </div>
                )}

                {isCmi() ? (
                  <div className="w-100 d-flex justify-content-between">
                    {!deshabilitarUserSinDocument && (
                      <div className="w-50 my-3">
                        <p className="mb-0">
                          <strong>Correo electrónico (opcional)</strong>
                        </p>
                        <TextField
                          className="w-100"
                          variant="standard"
                          value={clientEmail}
                          onChange={handleInputChange}
                          name="email"
                          type="email"
                          placeholder="Ingrese el correo electrónico del cliente"
                          disabled={userFound ? true : false}
                        />
                      </div>
                    )}

                    {!deshabilitarUserSinDocument && (
                      <div className="w-50 my-3 pl-2">
                        <p className="mb-0">
                          <strong>
                            Número telefónico {typeSell !== "3" && "(opcional)"}
                          </strong>
                        </p>
                        <TextField
                          className="w-100"
                          variant="standard"
                          value={newForm.telefono_cliente}
                          onChange={handleNewChanges}
                          name="telefono_cliente"
                          type="text"
                          placeholder="Ingrese el número telefonico del cliente"
                          disabled={userFound ? true : false}
                          // InputProps={{
                          //   inputComponent: PhoneFormatGt,
                          // }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>
                          Número telefónico {typeSell !== "3" && "(opcional)"}
                        </strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={newForm.telefono_cliente}
                        onChange={handleNewChanges}
                        name="telefono_cliente"
                        type="text"
                        placeholder="Ingrese el número telefonico del cliente"
                        InputProps={{
                          inputComponent: PhoneFormatGt,
                        }}
                      />
                    </div>

                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Correo electrónico (opcional)</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={clientEmail}
                        onChange={handleInputChange}
                        name="email"
                        type="email"
                        placeholder="Ingrese el correo electrónico del cliente"
                        disabled={userFound ? true : false}
                      />
                    </div>
                  </>
                )}

                {/* {clientNit.toLocaleLowerCase().includes('cf') && (
                  <div className="w-100 my-3">
                    <p className="mb-0">
                      <strong>Código del cliente</strong>
                    </p>
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={newForm.codigo_cliente}
                      onChange={handleNewChanges}
                      name="codigo_cliente"
                      type="text"
                      placeholder="Ingrese el código del cliente"
                    />
                  </div>
                )} */}
                <div className="w-100 px-0 d-flex justify-conten-between">
                  {isCmi() ? (
                    <>
                      <div className="col-md-6 px-0 h-100">
                        <div className="w-100 mx-0">
                          <p className="mb-0">
                            <strong>
                              Ticket de exención de IVA (opcional)
                            </strong>
                          </p>
                          <TextField
                            className="w-100"
                            variant="standard"
                            value={taxFreeTicket}
                            onChange={handleInputChange}
                            name="taxFreeTicket"
                            type="text"
                            placeholder="Ingrese el numero de comprobante del ticket."
                          />
                        </div>
                        {showBtns ? (
                          <div className="w-100">
                            {isEditMode ? null : ( // </Button> //   </span> //     )} //       "Editar cliente" //     ) : ( //       /> //         className="mx-auto" //         size={16} //       <CircularProgress //     {clientCreationLoader ? ( //   > //     }} //       fontWeight: 700, //       fontSize: "14px", //     style={{ //   <span // > //   onClick={SendEditClient} //   variant="contained" //   className="my-2 w-100 crearcliente-button bg_secondary_color" //   size="small" //   }} //     color: "white", //   style={{ // <Button
                              enableCrearCliente ?
                              <Button
                                style={{
                                  color: "white",
                                }}
                                size="small"
                                className="my-2 w-100 crearcliente-button bg_secondary_color"
                                variant="contained"
                                onClick={submitClient}
                                
                              >
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {clientCreationLoader ? (
                                    <CircularProgress
                                      size={16}
                                      className="mx-auto"
                                    />
                                  ) : (
                                    "Crear cliente"
                                  )}
                                </span>
                              </Button>
                              :
                              <Button
                                style={{
                                  color: "white",
                                }}
                                size="small"
                                className="my-2 w-100 crearcliente-button bg_secondary_color"
                                variant="contained"
                                onClick={SendEditClient}
                              >
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {clientCreationLoader ? (
                                    <CircularProgress
                                      size={16}
                                      className="mx-auto"
                                    />
                                  ) : (
                                    "Editar cliente"
                                  )}
                                </span>
                              </Button>
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6 h-100 px-0">
                        <div className="col-md-12 h-50 mx-0 d-flex justify-content-between align-items-center">
                          <p className="mb-0">
                            <strong>Nivel del cliente</strong>
                          </p>
                          <Tooltip title="Indique el nivel de precios que desea aplicar al cliente">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>
                        <div className="col-md-12 h-50 d-flex justify-content-center align-items-center">
                          <FormControl variant="outlined" className="w-100">
                            <Select
                              className="cs-select1 select-w100"
                              name="level_client"
                              autoWidth={true}
                              native
                              disabled={
                                deshabilitarUserSinDocument
                                  ? true
                                  : isAdministrador
                                  ? true
                                  : deshabilitarUserSinDocument || isCajero
                                  ? true
                                  : false
                              }
                              value={idNivelPrecio}
                              onChange={(e) => {
                                setIdNivelPrecio(e.target.value);
                              }}
                            >
                              <option value="1">Regular</option>
                              {nivelPrecio.map((level) => (
                                <option key={level.id} value={level.id}>
                                  {level.nivel}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          {/* <TextField
                            native
                            autoWidth={true}
                            style={{
                              width: '100%',
                            }}
                            variant="standard"
                            disabled
                            defaultValue="Normal"
                          /> */}
                        </div>
                        {/*<Select className="w-100 form-control valid">
                          <option value="normal">Normal</option>
                          <option value="VIP">VIP</option>
                      </Select>*/}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {isCmi() ? (
                  ""
                ) : (
                  <div className="w-50">
                    {enableCrearCliente ? <Button
                      style={{
                        color: "white",
                      }}
                      size="small"
                      className="my-2 w-75 crearcliente-button bg_secondary_color"
                      variant="contained"
                      onClick={submitClient}
                      
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        {clientCreationLoader ? (
                          <CircularProgress size={16} className="mx-auto" />
                        ) : (
                          "Crear cliente"
                        )}
                      </span>
                    </Button>
                    :
                    <Button
                      style={{
                        color: "white",
                      }}
                      size="small"
                      className="my-2 w-100 crearcliente-button bg_secondary_color"
                      variant="contained"
                      onClick={SendEditClient}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        {clientCreationLoader ? (
                          <CircularProgress
                            size={16}
                            className="mx-auto"
                          />
                        ) : (
                          "Editar cliente"
                        )}
                      </span>
                    </Button>  
                  }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row justify-content-between px-3 pt-3 pb-2">
            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIosIcon style={{ fontSize: "15px" }} />}
              onClick={backToCatalogo}
            >
              <span style={{ marginBottom: "-4px" }}>Volver</span>
            </Button>
            <Button
              className="btn-cs1 icon-btn-delete mx-1"
              variant="contained"
              color="primary"
              data-toggle="modal"
              data-target="#cancelSale"
            >
              <span style={{ marginBottom: "-4px" }}>Cancelar venta</span>
            </Button>
          </div>
          <div className="card content-card mt-1">
            {/* DATATABLE ORDER */}
            <div className="card-header p-0" id="facturacion-products-header">
              {!isAleko() ? (
                <DataTable
                  className="datatable-cs datatable-cs-v2 m-0"
                  title={"s"}
                  columns={[
                    {
                      name: "Producto",
                      selector: "id_new_item",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {row.nombre} <br />
                          {row.codigo}
                        </div>
                      ),
                    },
                    {
                      name: "Precio",
                      selector: "precio",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {moneda}.{" "}
                          {row.descuento_precio && !isAleko()
                            ? formaterPriceFunction(
                                row.descuento_precio.toFixed(2) + ""
                              )
                            : formaterPriceFunction(
                                row.precio_venta.toFixed(2) + ""
                              )}
                        </div>
                      ),
                    },
                    {
                      name: "Cantidad",
                      selector: "cantidad",
                      sortable: true,
                      cell: (row) => (
                        <div>{parseFloat(Math.abs(row.cantidad))}</div>
                      ),
                    },
                    {
                      name: "Total",
                      selector: "precio",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            parseFloat(row.total).toFixed(2) + ""
                          )}
                        </div>
                      ),
                    },
                  ]}
                  data={selectedProducts}
                  pagination
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  noDataComponent="No se ha encontrado ningún producto"
                />
              ) : (
                <DataTable
                  className="datatable-cs datatable-cs-v2 m-0"
                  title={"s"}
                  columns={[
                    {
                      name: "Producto",
                      selector: "id_new_item",
                      sortable: true,
                      cell: (row) => <div>{row.nombre}</div>,
                    },
                    {
                      name: "Precio",
                      selector: "precio",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            (row.valor_total / row.cantidad_producto).toFixed(
                              2
                            ) + ""
                          )}
                        </div>
                      ),
                    },
                    {
                      name: "Cantidad",
                      selector: "cantidad",
                      sortable: true,
                      cell: (row) => (
                        <div>{parseFloat(Math.abs(row.cantidad_producto))}</div>
                      ),
                    },
                    {
                      name: "Total",
                      selector: "precio",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            parseFloat(row.valor_total).toFixed(2) + ""
                          )}
                        </div>
                      ),
                    },
                  ]}
                  data={selectedProductsAleko}
                  pagination
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  noDataComponent="No se ha encontrado ningún producto"
                />
              )}
            </div>
            {/* DATATABLE ORDER */}

            <div className="card-header">
              <div className="d-flex align-items-center justify-content-center flex-wrap mb-3">
                <div
                  className="payment-method-box"
                  id="box-1"
                  onClick={() => {
                    if (metodoEntrega == "" && isCmi()) {
                      toast.warning("Debe agregar un metodo de entrega", {
                        autoClose: 10000,
                      });
                      return null;
                    }
                    handleBoxClick(1);
                  }}
                >
                  <LocalAtmIcon />
                  <p>Efectivo</p>
                </div>
                <div
                  className="payment-method-box"
                  id="box-2"
                  onClick={() => {
                    if (metodoEntrega == "" && isCmi()) {
                      toast.warning("Debe agregar un metodo de entrega", {
                        autoClose: 10000,
                      });
                      return null;
                    }
                    handleBoxClick(2);
                  }}
                >
                  <CreditCardIcon />
                  <p>Tarjeta</p>
                </div>

                {isCmi() ? (
                  <div
                    className="payment-method-box"
                    id="box-3"
                    onClick={() => {
                      if (metodoEntrega == "" && isCmi()) {
                        toast.warning("Debe agregar un metodo de entrega", {
                          autoClose: 10000,
                        });
                        return null;
                      }
                      handleBoxClick(3);
                    }}
                  >
                    <CardGiftcardIcon />
                    <p>Vale</p>
                  </div>
                ) : (
                  ""

                  /* <div
                    className="payment-method-box"
                    id="box-3"
                    onClick={() => {
                      if (metodoEntrega == "" && isCmi()) {
                        toast.warning("Debe agregar un metodo de entrega", {
                          autoClose: 10000,
                        });
                        return null;
                      }
                      handleBoxClick(3);
                    }}
                  >
                    <CardGiftcardIcon />
                    <p>Gift card</p>
                  </div> */
                )}

                <div
                  className="payment-method-box"
                  id="box-4"
                  onClick={() => {
                    if (metodoEntrega == "" && isCmi()) {
                      toast.warning("Debe agregar un metodo de entrega", {
                        autoClose: 10000,
                      });
                      return null;
                    }
                    handleBoxClick(4);
                  }}
                >
                  <AddBoxIcon />
                  <p>Múltiples</p>
                </div>
                <div
                  className="payment-method-box"
                  id="box-5"
                  onClick={() => {
                    if (metodoEntrega == "" && isCmi()) {
                      toast.warning("Debe agregar un metodo de entrega", {
                        autoClose: 10000,
                      });
                      return null;
                    }
                    handleBoxClick(5);
                  }}
                >
                  <AssignmentTurnedInIcon />
                  <p>A crédito</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-7">
                  <div className="row">
                    {selectedMethods.includes(1) ||
                    selectedMethods.includes(4) ? (
                      <div className="col-md-12">
                        {!selectedMethods.includes(4) && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                              handleExactAmountCash(
                                isCmi()
                                  ? Math.abs(
                                      parseFloat(copiaSalesCost.total)
                                    ).toFixed(2)
                                  : Math.abs(
                                      parseFloat(salesCost.total)
                                    ).toFixed(2)
                              )
                            }
                          >
                            Efectivo exacto
                          </Button>
                        )}

                        <p className="mt-2">
                          <strong>Efectivo</strong>
                        </p>
                        <TextField
                          type="number"
                          className="w-100 my-2"
                          variant="standard"
                          step="0.01"
                          value={paidByCash === 0 ? "" : paidByCash}
                          name="paidByCash"
                          onChange={(e) => updateValue(e)}
                          placeholder="Monto pagado en efectivo"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {moneda}.
                              </InputAdornment>
                            ),
                            // inputProps: { min: 0 },
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedMethods.includes(2) ||
                    selectedMethods.includes(4) ? (
                      <div className="col-md-12">
                        {!selectedMethods.includes(4) && (
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleExactAmountCredit(
                                isCmi()
                                  ? Math.abs(
                                      parseFloat(copiaSalesCost.total)
                                    ).toFixed(2)
                                  : Math.abs(
                                      parseFloat(salesCost.total)
                                    ).toFixed(2)
                              )
                            }
                          >
                            Monto exacto
                          </Button>
                        )}
                        <p className="mt-1">
                          <strong>Tarjeta de débito/crédito</strong>
                        </p>
                        <TextField
                          className="w-100 my-2"
                          variant="standard"
                          type="number"
                          value={paidByCard === 0 ? "" : paidByCard}
                          name="paidByCard"
                          onChange={(e) => updateValue(e)}
                          
                          placeholder="Monto pagado por tarjeta de débito/crédito"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {moneda}.
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, max: 10 },
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedMethods.includes(3) ? (
                      <div className="col-md-12">
                        <p>
                          {isCmi() ? (
                            <strong>Vale</strong>
                          ) : (
                            <strong>Gift card</strong>
                          )}
                        </p>
                        {isCmi() ? (
                          <>
                            <TextField
                              type="number"
                              className="w-100 my-2"
                              variant="standard"
                              value={paidByGiftCard === 0 ? "" : paidByGiftCard}
                              name="paidByGiftCard"
                              onChange={(e) => updateValue(e)}
                              placeholder="Monto pagado por Vale"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {moneda}.
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0 },
                              }}
                            />

                            <TextField
                              type="date"
                              className="w-100 my-2"
                              variant="standard"
                              value={valeCMI.dateVale}
                              name="dateVale"
                              onChange={handleValeCMI}
                              placeholder="Fecha"
                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       {moneda}.
                              //     </InputAdornment>
                              //   ),
                              // }}
                            />

                            <TextField
                              type="number"
                              className="w-100 my-2"
                              variant="standard"
                              value={valeCMI.codigoVale}
                              name="codigoVale"
                              onChange={handleValeCMI}
                              placeholder="Código"
                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       {moneda}.
                              //     </InputAdornment>
                              //   ),
                              //   inputProps: { min: 0 },
                              // }}
                            />
                          </>
                        ) : (
                          <TextField
                            type="number"
                            className="w-100 my-2"
                            variant="standard"
                            value={paidByGiftCard === 0 ? "" : paidByGiftCard}
                            name="paidByGiftCard"
                            onChange={(e) => updateValue(e)}
                            placeholder="Monto pagado por Gift Card"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                              inputProps: { min: 0 },
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedMethods.includes(5) ? (
                      <div
                        className="col-md-12 mt-3"
                        style={{
                          borderBottom: "1px solid #E0E0E0",
                        }}
                      >
                        <p>
                          <strong>Información del cliente</strong>
                        </p>
                        <p>
                          Este cliente cumple con los requisitos para optar por
                          crédito de:
                          <strong> {creditDays || 0} días</strong>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedMethods.includes(1) && (
                      <div className="col-md-12">
                        <p>
                          El cliente
                          {saldoAnticipos >=
                          (isCmi() ? copiaSalesCost.total : salesCost.total)
                            ? " "
                            : " no "}
                          cuenta con saldo de anticipos suficiente para pagar
                          esta venta
                        </p>
                        <p style={{ color: "black" }}>
                          Saldo de anticipos:{" "}
                          <strong>{saldoAnticipos.toFixed(2)}</strong>
                        </p>
                        <Button
                          onClick={() => {
                            gotoResumen("anticipo");
                          }}
                          variant="contained"
                          color="primary"
                          disabled={submitLoader}
                        >
                          <span
                            style={{
                              marginBottom: "-4px",
                              textTransform: "none",
                            }}
                          >
                            {submitLoader ? (
                              <CircularProgress size={16} className="mx-auto" />
                            ) : (
                              "Pagar"
                            )}
                          </span>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {loader ? (
                  <div className="my-auto mx-auto">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <div className="col-sm-5 invoice-column">
                    <div className="row">
                      <div className="col-12 h-75">
                        <div className="row p-0">
                          <div className="col-6 p-0 m-0 text-center titles my-auto">
                            {/*    <p>SubTotal:</p>
                                                <p>IVA (12%):</p> */}
                            <p>Total:</p>
                            <p>Pagado:</p>
                            <p>Vuelto:</p>
                          </div>
                          <div className="col-6 p-0 m-0 text-center my-auto">
                            {/*<p>{moneda}. {salesCost.subtotal}</p>
                            <p>{moneda}. {salesCost.iva}</p> */}
                            <p>
                              {moneda}.{" "}
                              {isCmi()
                                ? formaterPriceFunction(
                                    parseFloat(copiaSalesCost.total).toFixed(
                                      2
                                    ) + ""
                                  )
                                : formaterPriceFunction(
                                    parseFloat(salesCost.total).toFixed(2) + ""
                                  )}
                            </p>
                            <p>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                parseFloat(paidAmount.paid).toFixed(2) + ""
                              )}
                            </p>
                            <p>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                parseFloat(paidAmount.change).toFixed(2) + ""
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 h-25 d-flex justify-content-center w-100 m-0">
                        <Button
                          style={{
                            backgroundColor: "white",
                            color: "#1a2eab",
                          }}
                          className="my-2 w-75 facturar-button"
                          variant="contained"
                          onClick={gotoResumen}
                          disabled={submitLoader}
                        >
                          <span
                            style={{
                              marginBottom: "-4px",
                              textTransform: "none",
                            }}
                          >
                            {submitLoader ? (
                              <CircularProgress size={16} className="mx-auto" />
                            ) : (
                              "Factura Inmediata"
                            )}
                          </span>
                        </Button>
                      </div>
                      <div className="col-12 h-25 d-flex justify-content-center w-100 m-0">
                      <Button
                          style={{
                            backgroundColor: "white",
                            color: "#1a2eab",
                          }}
                          className="my-2 w-75 facturar-button"
                          variant="contained"
                          onClick={gotoPostResumen}
                          disabled={submitLoader}
                        >
                          <span
                            style={{
                              marginBottom: "-4px",
                              textTransform: "none",
                            }}
                          >
                            {submitLoader ? (
                              <CircularProgress size={16} className="mx-auto" />
                            ) : (
                              "Post-Facturar"
                            )}
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-12 h-25">
                  {true ? (
                    <>
                      {/* Metodos de entregas dinamicos */}
                      {/*metodoEntrega == "presencial" ? (
                        <div className="w-100 my-3">
                          <p className="mb-0">
                            <strong>Descuento por presencial</strong>
                          </p>
                          <label
                            style={{
                              marginTop: "5px",
                              marginRight: "5px",
                            }}
                          >
                            {moneda}.
                          </label>
                          <TextField
                            className="w-75"
                            variant="standard"
                            type="number"
                            name="presencial"
                            value={
                              metodoEntrega.toLowerCase() == "presencial"
                                ? actualCostoDescuento
                                : 0
                            }
                            placeholder={
                              metodoEntrega.toLowerCase() == "presencial"
                                ? actualCostoDescuento
                                : 0
                            }
                            disabled
                          />
                        </div>
                      ) : (
                        ""
                      )*/}

                      {metodoEntrega == "pick-up" ? (
                        <div className="w-100 my-3">
                          <p className="mb-0">
                            <strong>Descuento por pick-up</strong>
                          </p>
                          <label
                            style={{
                              marginTop: "5px",
                              marginRight: "5px",
                            }}
                          >
                            {moneda}.
                          </label>
                          <TextField
                            className="w-75"
                            variant="standard"
                            type="number"
                            name="pickup"
                            value={
                              metodoEntrega.toLowerCase() == "pick-up"
                                ? actualCostoDescuento
                                : 0
                            }
                            placeholder={
                              metodoEntrega.toLowerCase() == "pick-up"
                                ? actualCostoDescuento
                                : 0
                            }
                            disabled
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {metodoEntrega == "entrega" ? (
                        <div className="w-col-12 my-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <strong className="mb-0">Dirección de envio</strong>
                            {clientID !== "" && (
                              <p
                                className="mb-0"
                                onClick={addCardDireccion}
                                style={{
                                  color: "#0160e7",
                                  cursor: "pointer",
                                }}
                              >
                                Añadir nueva +
                              </p>
                            )}
                          </div>
                          <div className="col-md-12 mt-2 d-flex flex-wrap justify-content-start align-items-center">
                            {clientDirections.length > 0
                              ? clientDirections.map((item, index) => {
                                  return (
                                    <Card_Domicilio
                                      createDirecction={createDirecction}
                                      objeto={item}
                                      key={index}
                                      identificador={index}
                                      direccion={`${index + 1}_entrega`}
                                      removeCardDireccion={removeCardDireccion}
                                      reRender={reRender}
                                      direcciones={[...direcciones]}
                                      setDirecciones={setDirecciones}
                                      clientNit={clientNit}
                                      seller={seller}
                                      clientID={clientID}
                                      lastAddress={lastAddress}
                                    />
                                  );
                                })
                              : ""}
                          </div>
                          <div className="w-100 my-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="mb-0">
                                <strong>Adicional por entrega</strong>
                              </p>
                            </div>
                            <div className="w-100">
                              <label
                                style={{
                                  marginTop: "5px",
                                  marginRight: "5px",
                                }}
                              >
                                {moneda}.
                              </label>
                              <TextField
                                disabled
                                className="w-75"
                                variant="standard"
                                type="text"
                                name="entrega"
                                value={
                                  metodoEntrega.toLowerCase() == "entrega"
                                    ? actualCostoDescuento
                                    : 0
                                }
                                placeholder={
                                  metodoEntrega.toLowerCase() == "entrega"
                                    ? actualCostoDescuento
                                    : 0
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {!selectedMethods.includes(5) &&
          selectedMethods[0] !== 1 &&
          selectedMethods[0] !== 0 ? (
            <div className="row mt-2">
              <div className="col-12">
                <div className="content-card card">
                  {isCmi() ? (
                    ""
                  ) : (
                    <div className="card-header">
                      <h4>
                        <strong>Verificación del pago</strong>
                      </h4>
                    </div>
                  )}
                  <div className="card-body">
                    <div className="row">
                      {selectedMethods.includes(2) ||
                      selectedMethods.includes(4) ? (
                        <>
                          <div className="col-md-12 my-auto">
                            <p>
                              <strong>
                                Número del recibo (Operación con tarjeta)
                              </strong>
                            </p>
                            <TextField
                              className="w-100"
                              variant="standard"
                              value={invoice_number}
                              name="invoice_number"
                              onChange={handleInputChange}
                              placeholder="Ingrese el número del recibo emitido por el pto. de venta"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    N°
                                  </InputAdornment>
                                ),
                              }}
                              disabled={!check1}
                            />
                          </div>
                          <div className="col-md my-auto">
                            <p>
                              <strong>Número de cuotas</strong>
                            </p>

                            <Select
                              native
                              value={periods}
                              onChange={changePeriods}
                              style={{ width: "100%" }}
                              variant="outlined"
                            >
                              {payment_periods.map((period) => (
                                <option value={period.value}>
                                  {period.option}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {isCmi() ? (
                        ""
                      ) : selectedMethods.includes(3) ? (
                        <div className="col-md my-auto">
                          <p>
                            <strong>Ingrese el código de Gift Card</strong>
                          </p>
                          <TextField
                            className="w-100"
                            variant="standard"
                            value={giftcard_code}
                            name="giftcard_code"
                            onChange={handleInputChange}
                            placeholder="     -      -      -"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {selectedMethods.includes(2) ||
                      selectedMethods.includes(4) ? (
                        <>
                          <div className="col-md-12">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="check1"
                                checked={check1}
                                onChange={toggleCheck}
                              />
                              <label
                                class="form-check-label ml-2"
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                Ingresar manualmente
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12 mt-5">
                            <div className="row w-100 justify-content-center justify-content-md-start">
                              <div className="col-md-1 text-center mb-2">
                                <AddIcon
                                  className="bg_secondary_color"
                                  style={{
                                    fontSize: "30px",
                                    color: "white",
                                  }}
                                  onClick={addNewAdditionalInvoice}
                                />
                              </div>
                              <div className="col-md-11">
                                <p className="mb-0">
                                  <strong>
                                    Número del recibo de pago para tarjeta
                                    adicional
                                  </strong>
                                </p>
                                <TextField
                                  className="w-100"
                                  variant="standard"
                                  value={additional_invoice}
                                  name="additional_invoice"
                                  onChange={handleInputChange}
                                  placeholder="Ingrese el número del recibo emitido con una tarjeta adicional"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        N°
                                      </InputAdornment>
                                    ),
                                  }}
                                  disabled={!check2}
                                />
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="check2"
                                    checked={check2}
                                    onChange={toggleCheck}
                                  />
                                  <label
                                    class="form-check-label ml-2"
                                    for="defaultCheck1"
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    Ingresar manualmente
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row w-100 mt-2 justify-content-center justify-content-md-start">
                              {additional_invoices.map((invoice) => (
                                <div className="col-xl-4 px-1">
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    disabled
                                    value={invoice}
                                    name="invoice_number"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          Recibo N°
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* Modal */}
      <div className="modal fade" id="cancelSale" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row justify-content-center align-items-center p-3">
                <div className="col-md-12">
                  <CheckCircleIcon
                    className="d-block mx-auto"
                    style={{
                      color: "#08BFE6",
                      fontSize: "60",
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <h4 className="text-center">
                    <strong>
                      ¿Estás seguro de que quieres cancelar esta venta?
                    </strong>
                  </h4>
                </div>
              </div>
              <div className="col-md-8 mx-auto d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={houseKeeping}
                >
                  Sí, cancelar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*MODALES PARA ANULACION DE PRE FACTURA*/}
      

      <div className="modal fade" id="create_postFacturacion" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <label style={{ fontSize: "1rem" }}>
                  <strong>Postfacturacion guardada exitosamente con No. de Orden: {noOrden} </strong>
                </label>
                <div className="d-flex justify-content-between mt-2">
                  <Button className="btn-cs1 delete-btn" variant="contained" color="primary" 
                          onClick={handleChangePrefactura}> Cerrar </Button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
