import React, { useState, useEffect, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";
import { base_url_images, realImageURL } from "../../api";
import { CSVLink } from "react-csv";

//herramienta reporte
import ReactHTMLTableToExcel from "react-html-table-to-excel";

// styles
import "./custom.css";

//subPages
import ReportMerma from "./Components/reportMerma/reportMerma";
import Parametrizacion from "./Parametrizacion/Parametrizacion";
//reportes
import KardexReporte from "../../reportes/kardexReporte";
import InventarioTodosReporte from "../../reportes/inventarioTodosReporte";
import IngresosReporte from "../../reportes/ingresosReporte";
import EgresosReporte from "../../reportes/egresosReporte";
import RecetasReporte from "../../reportes/recetasReporte";
import InsumosReporte from "../../reportes/insumosReporte";
import CombosReporte from "../../reportes/combosReporte";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddBoxIcon from "@material-ui/icons/AddBox";
import moment from "moment";
import "moment/locale/es";
import AlekoContext from "../../context/ContextoAleko/AlekoContext";
import { getMoneda } from "../../helpers/moneda";
import { isCmi } from "../../helpers/isCmi";
import { isGerenteMercadeo } from "../../helpers/isGerenteMercadeo";
import { isAleko } from "../../helpers/isAleko";
import ColorPickerContext from "../../context/ColorPickerContext/ColorPickContext";
import { isCajero } from "../../helpers/isCajero";
import { Pagination } from "@material-ui/lab";
moment.locale("es");

// components
export default function Dashboard(props) {
  const moneda = getMoneda();
  let history = useHistory();
  let default_dates = [
    `${moment(new Date()).utcOffset(-6).format("YYYY-MM-DD")}T05:00:00.000Z`,
    `${moment(new Date())
      .utcOffset(-6)
      .add(1, "d")
      .format("YYYY-MM-DD")}T04:59:59.999Z`,
  ];
  console.log(
    "Default dates",
    moment(new Date()).utcOffset(-6).format("YYYY-MM-DDTHH:mm:ss")
  );

  const aleko = isAleko();

  const {
    productColor,
    setProductColor,
    productSize,
    setProductSize,
    setProductPersonalization,
    productPersonalization,
    deleteAleko,
    clearAlekoState,
  } = useContext(AlekoContext);

  const { showColorPicker, setShowColorPicker } =
    useContext(ColorPickerContext);

  //cajero disable
  const [cajeroDisable, setCajeroDisable] = useState(false); // variable que verifica si es cajero

  // states
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState(
    JSON.parse(localStorage.getItem("id_sucursal")) || ""
  );
  const [productsMenu, setProductsMenu] = useState(null);
  const [suppliesMenu, setSuppliesMenu] = useState(null);
  const [productsTab, setProductsTab] = useState();

  const [egresosDateRange, setEgresosDateRange] = useState(default_dates);
  const [ingresosDateRange, setIngresosDateRange] = useState(default_dates);
  /*     const [productsDateRange, setProductsDateRange] = useState(default_dates); */
  const [kardexDateRange, setKardexDateRange] = useState(default_dates);
  const [productsToPrint, setProductsToPrint] = useState([]);
  const [printLoader, setPrintLoader] = useState(false);
  const [productsLoader, setProductsLoader] = useState(true);
  const [consolidadoLoader, setConsolidadoLoader] = useState(true);
  const [ingresosLoader, setIngresosLoader] = useState(true);
  const [egresosLoader, setEgresosLoader] = useState(true);
  const [kardexLoader, setKardexLoader] = useState(true);
  const [combosLoader, setCombosLoader] = useState(true);
  const [suppliesLoader, setSuppliesLoader] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productFilterText, setProductFilterText] = useState("");
  const [suppliesFilterText, setSuppliesFilterText] = useState("");
  const [allCombos, setAllCombos] = useState([]);
  const [filteredCombos, setFilteredCombos] = useState([]);
  const [productsStats, setProductsStats] = useState({
    total: "No hay información",
    sin_stock: "No hay información",
    bajo_inventario: "No hay información",
  });
  const [allIngresos, setAllIngresos] = useState([]);
  const [ingresoStats, setIngresoStats] = useState({
    total: "No hay información",
    productos_ingresados: "No hay información",
  });
  const [filteredIngresos, setFilteredIngresos] = useState([]);
  const [ingresosFilterText, setIngresosFilterText] = useState("");
  const [combosFilterText, setCombosFilterText] = useState("");
  const [allEgresos, setAllEgresos] = useState([]);
  const [egresoStats, setEgresoStats] = useState({
    total: "No hay información",
    productos_egresados: "No hay información",
  });
  const [filteredEgresos, setFilteredEgresos] = useState([]);
  const [egresosFilterText, setEgresosFilterText] = useState("");

  const [categories, setCategories] = useState([
    {
      id_clasificacion1_web_app: "",
      descripcion: "Todas las categorias",
    },
  ]);
  const [recetasCategory, setRecetasCategory] = useState("");
  const [productCategory, setProductCategory] = useState("");

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCombos, setSelectedCombos] = useState([]);
  const [selectedIngresos, setSelectedIngresos] = useState([]);
  const [selectedEgresos, setSelectedEgresos] = useState([]);
  const [kardexFilter, setKardexFilter] = useState("");
  const [allKardex, setAllKardex] = useState([]);
  const [kardex, setKardex] = useState([]);
  const [allSupplies, setAllSupplies] = useState([]);
  const [filteredSupplies, setFilteredSupplies] = useState([]);
  const [selectedSupplies, setSelectedSupplies] = useState([]);
  const [supplies_stats, setSupplies_stats] = useState({
    total: 0,
    por_vencer: 0,
    sin_stock: 0,
  });
  const [recetas, setRecetas] = useState([]);
  const [recetasStats, setRecetasStats] = useState({
    total: 0,
    por_vencer: 0,
    sin_stock: 0,
  });
  const [filteredRecetas, setFilteredRecetas] = useState([]);
  const [recetasTab, setRecetasTab] = useState(false);
  const [filterRecetasText, setFilterRecetasText] = useState("");
  const [empresasCmi, setEmpresasCmi] = useState([]);
  const [currentEmpresa, setCurrentEmpresa] = useState();

  useEffect(() => {
    getServerDate();
    if (props.history.location.state) {
      setProductsTab(props.history.location.state.selectedTab);
    } else {
      setProductsTab(0);
    }
    cajeroValidate();
    getSucursals();
  }, []);

  useEffect(() => {
    return () => {
      clearAlekoState();
    };
  }, []);

  useEffect(() => {
    if (productsTab === 6) {
      setRecetasTab(true);
      return;
    }
    setRecetasTab(false);
  }, [productsTab]);

  useEffect(() => {
    filterProducts(null);
  }, [productCategory]);

  useEffect(() => {
    filtrarRecetas(null);
  }, [recetas, recetasCategory]);
  const searchProducts = (e = null) => {
    if (e) e.preventDefault();
    setCurrentPageSearch(1);
    getProducts(currentSucursal, 1);
  };
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  // set states
  const changeSucursal = (event) => {
    const sucursalName = event.target.value;
    getConsolidado(event.target.value);
    getProducts(event.target.value);
    getSupplies(event.target.value);
    getIngresos(ingresosDateRange, event.target.value);
    getEgresos(egresosDateRange, event.target.value);
    setSucursal(sucursalName);
    getKardexData(kardexDateRange, event.target.value);
    getCombos(event.target.value);
  };

  //merma condicional
  const mermaGuard = () => {
    const tipo_usuario = JSON.parse(localStorage.getItem("pms")).tipo_usuario;
    if (!isCmi()) return false;
    if (
      tipo_usuario === "ADMINISTRADOR" ||
      tipo_usuario === "GERENTE REGIONAL" ||
      tipo_usuario === "GERENTE MERCADEO"
    ) {
      return true;
    } else {
      return false;
    }
  };

  //Valida al usuario de tipo cajero
  const cajeroValidate = () => {
    if (JSON.parse(localStorage.getItem("pms"))) {
      if (JSON.parse(localStorage.getItem("pms")).tipo_usuario === "CAJERO") {
        // si es usuario tipo cajero
        setCajeroDisable(true);
      }
    }
  };

  const handleProductTabs = (event, newValue) => {
    if (!isAleko() && !isCmi()) {
      if (newValue !== 4) {
        setProductsTab(newValue);
      } else {
        history.push(`/app/traslados`);
      }
    } else {
      if (newValue !== 3) {
        setProductsTab(newValue);
      } else {
        history.push(`/app/traslados`);
      }
    }
  };

  const getServerDate = () => {
    API.dashboard
      .getDate()
      .then((res) => {
        let { date } = res.data.data;
        date = `${date}`;

        setIngresosDateRange([date, date]);
        setEgresosDateRange([date, date]);
        setKardexDateRange([date, date]);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al obtener la fecha del servidor", {
          autoClose: 10000,
        });
      });
  };

  const productAction = (e, id, aleko) => {
    if (e.target.value === "details") {
      if (!aleko) {
        history.push(`/app/inventario/product_details/${id}`);
      } else {
        history.push(`/app/inventario/product_personalizable_details/${id}`);
      }
    }

    if (e.target.value === "delete") deleteProduct(id);
    if (e.target.value === "egreso") createEgreso(id);
    if (e.target.value === "ingreso") createIngreso(id);
    if (e.target.value === "alekoDelete")
      deleteAleko(id, setFilteredProducts, filteredProducts);
  };

  const supplyAction = (e, id) => {
    if (e.target.value === "details")
      history.push(`/app/inventario/supply_details/${id}`);
    if (e.target.value === "egreso") createSupplyEgreso(id);
    if (e.target.value === "ingreso") createSupplyIngreso(id);
  };

  const operationName = (str) => {
    if (!str) return;
    let initialChar = str.substr(0, 1).toLowerCase();
    if (initialChar === "f") {
      return "Factura";
    }
    if (initialChar === "i") {
      return "Ingreso";
    }
    if (initialChar === "s") {
      return "Egreso";
    }
    if (initialChar === "t") {
      return "Traslado";
    }
    if (str.includes("NC")) {
      return "NC";
    }
    if (str.includes("ND")) {
      return "ND";
    }
  };

  const filterKardex = (event) => {
    let text;
    if (!event) {
      text = kardexFilter;
    } else {
      setKardexFilter(event.target.value);
      text = event.target.value;
    }

    if (text !== "") {
      setKardex(
        allKardex.filter((item) =>
          item.nombre_producto.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  };

  const filterProducts = (event) => {
    let text;
    if (!event) {
      text = productFilterText;
    } else {
      text = event.target.value;
    }

    setProductFilterText(text);
    if (text === "") {
      if (productCategory === "") {
        setFilteredProducts(allProducts);
      } else {
        setFilteredProducts(
          allProducts.filter(
            (item) => item.id_clasificacion1_web_app == productCategory
          )
        );
      }
    } else {
      if (productCategory === "") {
      } else {
        setFilteredProducts(
          allProducts.filter(
            (item) =>
              item.id_clasificacion1_web_app == productCategory &&
              (item.nombre.toLowerCase().includes(text.toLowerCase()) ||
                item.codigo.toLowerCase().includes(text.toLowerCase()))
          )
        );
      }
    }
  };

  const filtrarRecetas = (event) => {
    let text;
    if (!event) {
      text = filterRecetasText;
    } else {
      text = event.target.value;
    }

    setFilterRecetasText(text);
    if (text === "") {
      if (recetasCategory === "") {
        setFilteredRecetas(recetas);
      } else {
        setFilteredRecetas(
          recetas.filter(
            (item) => item.id_clasificacion1_web_app == recetasCategory
          )
        );
      }
    } else {
      if (recetasCategory === "") {
        setFilteredRecetas(
          recetas.filter(
            (item) =>
              item.nombre.toLowerCase().includes(text.toLowerCase()) ||
              item.codigo.toLowerCase().includes(text.toLowerCase())
          )
        );
      } else {
        setFilteredRecetas(
          recetas.filter(
            (item) =>
              item.id_clasificacion1_web_app == recetasCategory &&
              (item.nombre.toLowerCase().includes(text.toLowerCase()) ||
                item.codigo.toLowerCase().includes(text.toLowerCase()))
          )
        );
      }
    }
  };

  const filterCombos = (event) => {
    let text = event.target.value.toLowerCase();
    setCombosFilterText(text);
    setFilteredCombos(
      allCombos.filter(
        (item) =>
          item.nombre.toLowerCase().includes(text) ||
          item.codigo.toLowerCase().includes(text)
      )
    );
  };

  const filterSupplies = (event) => {
    let text = event.target.value.toLowerCase();
    setSuppliesFilterText(text);
    setFilteredSupplies(
      allSupplies.filter(
        (item) =>
          item.nombre.toLowerCase().includes(text) ||
          item.codigo.toLowerCase().includes(text)
      )
    );
  };

  const filterIngresos = (e) => {
    setIngresosFilterText(e.target.value);
    if (allIngresos) {
      setFilteredIngresos(
        allIngresos.filter((item) =>
          item.identificador
            .toLowerCase()
            .includes(ingresosFilterText.toLowerCase())
        )
      );
      if (e.target.value === "") {
        setFilteredIngresos(allIngresos);
      }
    }
  };

  const filterEgresos = (e) => {
    setEgresosFilterText(e.target.value);
    if (allEgresos) {
      setFilteredEgresos(
        allEgresos.filter((item) =>
          item.identificador
            .toLowerCase()
            .includes(egresosFilterText.toLowerCase())
        )
      );
      if (e.target.value === "") {
        setFilteredEgresos(allEgresos);
      }
    }
  };

  const getConsolidado = async (id_sucursal) => {
    setConsolidadoLoader(true);

    let items_total = 0;
    let items_sin_stock = 0;
    let items_por_vencer = 0;
    let items_bajo_inventario = 0;

    const responseSumary = await API.dashboard.getSummaryNew("", id_sucursal);

    if (responseSumary.data.success) {
      items_total = responseSumary.data.data.totalInventoryProduct;
      items_sin_stock = responseSumary.data.data.totalOutOfStockProduct;
      items_por_vencer = responseSumary.data.data.totalNearExpiry;
      items_bajo_inventario = responseSumary.data.data.totalLowInventoryProduct;
    }

    setProductsStats({
      total: items_total,
      sin_stock: items_sin_stock,
      por_vencer: items_por_vencer,
      bajo_inventario: items_bajo_inventario,
    });
    setConsolidadoLoader(false);
  };

  const getRecetas = async (id_sucursal, page = null) => {
    setProductsLoader(true);

    const query = {
      nombre: isNaN(productFilterText) ? productFilterText : "",
      codigo: isNaN(productFilterText) ? "" : productFilterText,
    };  

    API.products
      .getAll({
        id_sucursal_bodega_ubicacion: id_sucursal,
        id_clasificacion1_web_app: '330', // this is the category for recetas
        flag_compuesto: '1',
        pagination: true,
        page: page || currentPage,
        limit: 10,
        ...query,
      })
      .then((res) => {
        let response = res.data;
        response.data = response.data;

        if (response.success) {
          setPaginate(true);
          response.data.map((product) => {
            if (product.vencimiento_fecha === "")
              product.vencimiento_fecha = "N/A";
          });

          const filterRecetas = response.data;

          setRecetas(filterRecetas);

          let nearExp = [];

          let recetas_total = filterRecetas.length;
          let recetas_sin_stock = filterRecetas.filter((x) => x.stock == 0);
          let recetas_bajo_inventario = filterRecetas.filter(
            (x) => x.stock < x.stock_minimo
          );
          let recetas_nearExp = [];

          filterRecetas.map((product) => {
            if (
              product.flag_vencimiento &&
              moment(product.vencimiento_fecha).format("YYYY-MM-DD") >
                moment().format("YYYY-MM-DD") &&
              moment(product.vencimiento_fecha).format("YYYY-MM-DD") <
                moment().add(15, "days").format("YYYY-MM-DD")
            ) {
              nearExp.push(product);
            }
          });
          console.log("All recetas obtained", filterRecetas);

          setRecetasStats({
            total: recetas_total,
            sin_stock: recetas_sin_stock.length,
            por_vencer: recetas_nearExp.length,
            bajo_inventario: recetas_bajo_inventario.length,
          });

          setProductsLoader(false);
        }
      })
      .catch((err) => {
        setProductsLoader(false);
        let response = err.response;
        console.log(response);
        if (typeof response?.data !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("error", err);
          console.log("Ha ocurrido un error obteniendo recetas", response);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });


    setProductsLoader(false);
  }

  const getProducts = async (id_sucursal, page = null) => {
    setProductsLoader(true);

    var query = {
      nombre: isNaN(productFilterText) ? productFilterText : "",
      codigo: isNaN(productFilterText) ? "" : productFilterText,
    };

    if (isAleko()) {
      query.codigo = productFilterText ? productFilterText : "";
    }

    API.products
      .getAll({
        id_sucursal_bodega_ubicacion: id_sucursal,
        no_combos: 1,
        pagination: true,
        page: page || currentPage,
        limit: 10,
        ...query,
      })
      .then((res) => {
        let response = res.data;
        setTotalProducts(res.data.total);

        response.data = response.data;
        if (response.success) {
          setPaginate(true);
          response.data.map((product) => {
            if (product.vencimiento_fecha === "")
              product.vencimiento_fecha = "N/A";
          });

          setAllProducts(response.data);
          setFilteredProducts(response.data);

          let nearExp = [];

          response.data.map((product) => {
            if (
              product.flag_vencimiento &&
              moment(product.vencimiento_fecha).format("YYYY-MM-DD") >
                moment().format("YYYY-MM-DD") &&
              moment(product.vencimiento_fecha).format("YYYY-MM-DD") <
                moment().add(15, "days").format("YYYY-MM-DD")
            ) {
              nearExp.push(product);
            }
          });

          console.log("All products obtained", response.data);

          set_categories(response.data);

          setProductsLoader(false);
        }
      })
      .catch((err) => {
        setProductsLoader(false);
        let response = err.response;
        console.log(response);
        if (typeof response?.data !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("error", err);
          console.log("Ha ocurrido un error", response);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const printAllProducts = () => {
    setPrintLoader(true);
    API.products
      .getAll({
        id_sucursal_bodega_ubicacion: currentSucursal,
        pagination: true,
        page: 1,
        limit: 1000000000000,
      })
      .then((res) => {
        let response = res.data.data;
        setProductsToPrint(response);
        document.getElementById("descargar-reporteInventario-Todos").click();
        setProductsToPrint([]);
        setPrintLoader(false);
      })
      .catch((err) => {
        setPrintLoader(false);
        toast.error("Ha ocurrido un error al imprimir los productos", {
          autoClose: 10000,
        });
      });
  };

  const getCombos = (id_sucursal) => {
    setCombosLoader(true);
    API.products
      .getCombos({ id_sucursal_bodega_ubicacion: id_sucursal })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setAllCombos(response.data);
          setFilteredCombos(response.data);
          console.log("All combos obtained", response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setCombosLoader(false);
      })
      .catch((err) => {
        setCombosLoader(false);
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error", response);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSupplies = (id_sucursal) => {
    setProductsLoader(true);
    API.supplies
      .getAll({ id_sucursal_bodega_ubicacion: id_sucursal })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log(response.data.insumos);
          response.data.insumos.map((product) => {
            if (product.vencimiento_fecha === "")
              product.vencimiento_fecha = "N/A";
          });
          setSupplies_stats({
            total: response.data.total,
            por_vencer: response.data.por_vencer,
            sin_stock: response.data.sin_stock,
          });
          setAllSupplies(response.data.insumos);
          setFilteredSupplies(response.data.insumos);
          setSuppliesLoader(false);
        }
      })
      .catch((err) => {
        console.log("this is error", err);
        setSuppliesLoader(false);
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error", response);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getIngresos = (dates, sucursalId) => {
    setIngresosLoader(true);
    let data = {
      from_date: dates[0],
      to_date: dates[1],
      id_sucursal_bodega_ubicacion: sucursalId,
    };
    API.ingresos
      .getAll(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Obtaining these ingresos", response.data);
          setAllIngresos(response.data);
          setFilteredIngresos(response.data);
          let ingresadosAmount = 0;
          response.data.map((ingreso) => {
            ingresadosAmount += ingreso.detalle.length;
          });
          setIngresoStats({
            total: response.data.length,
            productos_ingresados: ingresadosAmount,
          });
        }
        setIngresosLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined")
          if (typeof response.data.message !== "undefined") {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error", response);
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        setIngresosLoader(false);
      });
  };

  const printAllIngresos = () => {
    setPrintLoader(true);
    document.getElementById("descargar-reporteIngresos").click();
    setPrintLoader(false);
  };

  const getEgresos = (dates, sucursalId) => {
    setEgresosLoader(true);
    let data = {
      from_date: dates[0],
      to_date: dates[1],
      id_sucursal_bodega_ubicacion: sucursalId,
    };
    API.egresos
      .getAll(data)
      .then((res) => {
        let response = res.data;
        console.log("Egresos obtained:", response.data);
        if (response.success) {
          setAllEgresos(response.data);
          setFilteredEgresos(response.data);
          let egresadosAmount = 0;
          response.data.map((egreso) => {
            egresadosAmount += egreso.detalle.length;
          });
          setEgresoStats({
            total: response.data.length,
            productos_egresados: egresadosAmount,
          });
        }
        setEgresosLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined")
          if (typeof response.data.message !== "undefined") {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error", response);
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        setEgresosLoader(false);
      });
  };

  const printAllEgresos = () => {
    setPrintLoader(true);
    document.getElementById("descargar-reporteEgresos").click();
    setPrintLoader(false);
  };

  const handleDateChange = (value, selector) => {
    let currentDates = [value[0], value[1]];
    currentDates[0] = moment(currentDates[0]).format("YYYY-MM-DD");
    currentDates[1] = moment(currentDates[1]).format("YYYY-MM-DD");

    if (selector === "ingresos") {
      setIngresosDateRange(value);
      setIngresosLoader(true);
      getIngresos(currentDates, sucursal);
    }
    if (selector === "egresos") {
      setEgresosDateRange(value);
      setEgresosLoader(true);
      getEgresos(currentDates, sucursal);
    }
    if (selector === "kardex") {
      setKardexDateRange(value);
      getKardexData(currentDates, sucursal);
    }
  };
  const getEmpresasCmi = async (id_user) => {
    let newEmpresa = [];

    try {
      const res = await API.users.getEmpresaPorUsuario(id_user);
      const data = res.data.data;

      // filtrar las empresas
      data.forEach((empresa) => newEmpresa.push(empresa.empresa));

      let filtradas = newEmpresa.reduce((acc, el) => {
        if (!acc.find((item) => item.id_new_empresa == el.id_new_empresa)) {
          acc.push(el);
        }
        return acc;
      }, []);

      // seteamos las empresas
      setEmpresasCmi(filtradas);
    } catch (error) {
      console.log("error al obtener empresas gerente ->", error);
    }
  };

  useEffect(() => {
    if (isGerenteMercadeo()) {
      let user = JSON.parse(window.localStorage.getItem("pms"));

      getEmpresasCmi(user.id_new_user);
    }
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSearch, setCurrentPageSearch] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [paginate, setPaginate] = useState(false);

  useEffect(() => {
    getProducts(currentSucursal);
    getRecetas(currentSucursal);
    setCurrentPageSearch(null);
  }, [currentPage]);

  const handleRealPagination = (e, value) => {};

  let currentSucursal = JSON.parse(
    localStorage.getItem("store")
  )?.id_sucursal_bodega_ubicacion;
  const getSucursals = () => {
    var currentTime = kardexDateRange;
    currentTime[0] = moment(currentTime[0]).format("YYYY-MM-DD");
    currentTime[1] = moment(currentTime[1]).format("YYYY-MM-DD");

    API.userConfig
      .getSucursals(isGerenteMercadeo() ? null : true, isAleko())
      .then((res) => {
        let response = res.data;

        if (response.success) {
          setSucursals(response.data);
          if (isCajero() && isAleko()) {
            setSucursal(currentSucursal);
            getConsolidado(currentSucursal);
            getProducts(currentSucursal);
            getRecetas(currentSucursal);
            getSupplies(currentSucursal);
            getCombos(currentSucursal);
            getIngresos(currentTime, currentSucursal);
            getEgresos(currentTime, currentSucursal);
            getKardexData(currentTime, currentSucursal);
          } else {
            getConsolidado(response.data[0].id_sucursal_bodega_ubicacion);
            getProducts(response.data[0].id_sucursal_bodega_ubicacion);
            getRecetas(response.data[0].id_sucursal_bodega_ubicacion);
            setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
            getSupplies(response.data[0].id_sucursal_bodega_ubicacion);
            getCombos(response.data[0].id_sucursal_bodega_ubicacion);
            getIngresos(
              currentTime,
              response.data[0].id_sucursal_bodega_ubicacion
            );
            getEgresos(
              currentTime,
              response.data[0].id_sucursal_bodega_ubicacion
            );
            getKardexData(
              currentTime,
              response.data[0].id_sucursal_bodega_ubicacion
            );
          }
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined")
          if (typeof response.data.message !== "undefined") {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error", response);
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
      });
  };

  const set_categories = (productsList) => {
    API.products
      .getProductAttributes({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          let categoriesArray = [];
          productsList.map((product) => {
            categoriesArray.push(product.id_clasificacion1_web_app);
          });
          let categoriesState = [];
          let categoriesSet = new Set(categoriesArray);
          Array.from(categoriesSet).map((id) => {
            categoriesState.push(
              response.data.categories.find(
                (x) => x.id_clasificacion1_web_app === id
              )
            );
          });
          setCategories(sortAlphabetically(categoriesState));
        }
      })
      .catch((err) => {
        let response = err?.response;
        console.log(response);
        if (typeof response?.data !== "undefined")
          if (typeof response?.data?.message !== "undefined") {
            toast.error(response?.data?.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error", response);
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
      });
  };

  const changeCategory = (event) => {
    const category = event.target.value;
    setProductCategory(category);
  };

  const changeRecetaCategory = (e) => {
    setRecetasCategory(e.target.value);
  };

  const deleteProduct = (id) => {
    API.products
      .deleteById({ id_new_item: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
          getProducts();
        } else {
          toast.error(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined")
          if (typeof response.data.message !== "undefined") {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error", response);
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
      });
  };

  const createEgreso = (id) => {
    let currentProduct = allProducts.find((x) => x.id_new_item === id);
    history.push({
      pathname: "/app/inventario/new_egreso",
      state: {
        products: [currentProduct],
      },
    });
  };

  const createIngreso = (id) => {
    let currentProduct = allProducts.find((x) => x.id_new_item === id);
    history.push({
      pathname: "/app/inventario/new_ingreso",
      state: {
        products: [currentProduct],
      },
    });
  };

  const createSupplyEgreso = (id) => {
    let currentSupply = allSupplies.find((x) => x.id_new_item === id);
    history.push({
      pathname: "/app/inventario/new_egreso",
      state: {
        products: [currentSupply],
      },
    });
  };

  const createSupplyIngreso = (id) => {
    let currentSupply = allSupplies.find((x) => x.id_new_item === id);
    history.push({
      pathname: "/app/inventario/new_ingreso",
      state: {
        products: [currentSupply],
      },
    });
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;
    let data = selectedRows;
    if (productsTab == 0 || productsTab === 6) {
      setSelectedProducts(data);
    }
    if (productsTab == 1) {
      setSelectedCombos(data);
    }

    if (productsTab == 2) {
      setSelectedIngresos(data);
    }
    if (productsTab == 3) {
      setSelectedEgresos(data);
    }
    if (productsTab == 7) {
      setSelectedSupplies(data);
    }
  };

  const getKardexData = (dates, id_sucursal) => {
    setKardexLoader(true);
    let data = {
      from_date: dates[0],
      to_date: dates[1],
      id_sucursal_bodega_ubicacion: id_sucursal,
    };
    API.historial_operaciones
      .get_kardex(data)
      .then((res) => {
        let response = res.data;
        console.log("Kardex obtained:", response.data);
        if (response.success) {
          let filterData = response.data;
          if (kardexFilter !== "") {
            filterData = filterData.filter((item) =>
              item.nombre_producto
                .toLowerCase()
                .includes(kardexFilter.toLowerCase())
            );
          }
          setKardex(
            filterData.map((x) => ({
              ...x,
              costo_unitario: x.costo_unitario || 0,
            }))
          );
          setAllKardex(response.data);
          setKardexLoader(false);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
          setKardexLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          let response = err.response;
          console.log(response);
          if (typeof response.data !== "undefined")
            if (typeof response.data.message !== "undefined") {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            } else {
              console.log("Ha ocurrido un error", response);
              toast.error("Ha ocurrido un error", { autoClose: 10000 });
            }
        }
        setKardexLoader(false);
      });
  };

  const sortAlphabetically = (array) => {
    let sortedArray = array.sort(function (a, b) {
      if (a.descripcion < b.descripcion) {
        return -1;
      }
      if (a.descripcion > b.descripcion) {
        return 1;
      }
      return 0;
    });
    return sortedArray;
  };
  const handleChangeEmpresa = async (e) => {
    setCurrentEmpresa(e.target.value);
    try {
      const { data } = await API.sucursal.getAll(
        parseInt(e.target.value),
        true
      );
      setSucursals(data.data);
      getConsolidado(data.data[0].id_sucursal_bodega_ubicacion);
      getProducts(data.data[0].id_sucursal_bodega_ubicacion);
      getRecetas(data.data[0].id_sucursal_bodega_ubicacion)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>
        {isGerenteMercadeo() && (
          <>
            <ArrowForwardIosIcon
              style={{ color: "#333333", fontSize: "12px" }}
              className="mx-2"
            />

            <FormControl variant="outlined">
              <Select
                onChange={handleChangeEmpresa}
                value={currentEmpresa}
                native
              >
                {empresasCmi.map((itemEmpresa) => (
                  <option value={itemEmpresa.id_new_empresa}>
                    {itemEmpresa.nombre}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select
            native
            value={sucursal}
            disabled={isAleko() ? false : cajeroDisable}
            onChange={changeSucursal}
          >
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="content-card card mt-3">
        <div
          className="d-flex justify-content-between align-items-center flex-wrap"
          style={{ boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.06)" }}
        >
          <Tabs
            className="mt-2"
            value={productsTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleProductTabs}
          >
            <Tab className="tab" label="Todos" style={{ minWidth: "120px" }} />

            {!aleko && !isCmi() && !isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Combos"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}
            {!isCajero() && !isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Ingresos"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}
            {!isCajero() && !isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Egresos"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!isCajero() && !isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Traslados"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!isCajero() && !isGerenteMercadeo() ? (
              <Tab
                className="tab"
                label="Kardex"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!aleko && !isCmi() ? (
              <Tab
                className="tab"
                label="Recetas"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}
            {!aleko && !isCmi() ? (
              <Tab
                className="tab"
                label="Insumos"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!aleko && mermaGuard() ? (
              <Tab
                className="tab"
                label="Mermas"
                value={8}
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}

            {!isCajero() && aleko ? (
              <Tab
                className="tab"
                label="Características"
                style={{ minWidth: "120px" }}
              />
            ) : (
              ""
            )}
          </Tabs>
        </div>

        <TabPanel value={productsTab} index={0}>
          <div className="row">
            <div className="px-2 col-md-12 d-flex align-items-center justify-content-between flex-wrap mb-3">
              <div>
                <h4
                  className="mb-0"
                  style={{ fontSize: "20px", color: "#333" }}
                >
                  <strong>Inventario de productos</strong>
                </h4>
                <p
                  className="mb-0 mt-1"
                  style={{ fontSize: "16px", color: "#333" }}
                >
                  Consulte y gestione su inventario.
                </p>
              </div>
              <div>
                {isGerenteMercadeo() && (
                  <Button
                    className="mr-3"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      history.push("/app/inventario/cambio-masivo-precios")
                    }
                  >
                    <span style={{ fontSize: "16px" }}>Cambiar precios</span>
                  </Button>
                )}
                {!isCmi() && (
                  <Button
                    className="btn-cs1 shadow-none"
                    variant="contained"
                    color="primary"
                    onClick={(e) => setProductsMenu(e.currentTarget)}
                    endIcon={<ExpandMoreIcon />}
                  >
                    <span style={{ fontSize: "16px" }}>Añadir</span>
                  </Button>
                )}
                {/* { isGerenteMercadeo() && (
                  <Button
                      className="btn-cs1 shadow-none"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        history.push("/app/inventario/carga-productos")
                      }
                    >
                    <span style={{ fontSize: "16px" }}>Añadir producto</span>
                  </Button>
                )} */}
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-2 px-2 justify-content-"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p className="mb-1" style={{ fontSize: "16px", color: "black" }}>
                Total de productos
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {consolidadoLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(productsStats.total)
                  )}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p className="mb-1" style={{ fontSize: "16px", color: "black" }}>
                Productos sin stock
              </p>
              <h6 className="mb-2 secondary_color">
                <strong>
                  {consolidadoLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(productsStats.sin_stock)
                  )}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p className="mb-1" style={{ fontSize: "16px", color: "black" }}>
                Productos a vencer
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {consolidadoLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(productsStats.por_vencer)
                  )}
                </strong>
              </h6>
            </div>
            <div className="col-md-3 px-2">
              <p className="mb-1" style={{ fontSize: "16px", color: "black" }}>
                Productos con bajo inventario
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {consolidadoLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(productsStats.bajo_inventario)
                  )}
                </strong>
              </h6>
            </div>
            <div className="col-md-3 px-2 text-right"></div>
          </div>

          <ReactHTMLTableToExcel
            id="descargar-reporteInventario-Todos"
            className="buton-excel"
            table="InventarioTodos-reporte-table"
            filename="Cloud-Reporte-Productos.xlsx"
            sheet="tablexls"
            buttonText="Descargar"
          />

          <InventarioTodosReporte
            objeto={productsToPrint}
          ></InventarioTodosReporte>

          <div className="row">
            <div className="px-2 col-md-12 d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
              <div>
                {/* {selectedProducts.length > 0 ? (
                  <Button
                    className=""
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      document
                        .getElementById("descargar-reporteInventario-Todos")
                        .click();
                    }}
                  >
                    <CloudDownloadIcon
                      className="mr-2"
                      style={{ fontSize: "16px" }}
                    />
                    <span style={{ fontSize: "14px" }}>
                      Descargar seleccionados
                    </span>
                  </Button>
                ) : (
                  <Button className="" variant="outlined" color="primary">
                    <CloudDownloadIcon
                      className="mr-2"
                      style={{ fontSize: "16px" }}
                    />
                    <span style={{ fontSize: "14px" }}>
                      Descargar seleccionados
                    </span>
                  </Button>
                )} */}
                <Button
                  className=""
                  variant="outlined"
                  color="primary"
                  disabled={printLoader}
                  onClick={printAllProducts}
                >
                  <CloudDownloadIcon
                    className="mr-2"
                    style={{ fontSize: "16px" }}
                  />
                  <span style={{ fontSize: "14px" }}>
                    {printLoader ? <>Descargando...</> : <>Descargar todos</>}
                  </span>
                </Button>
                <FormControl variant="outlined" className="ml-3" size="small">
                  <Select
                    native
                    value={productCategory}
                    onChange={changeCategory}
                    fullWidth
                    variant="outlined"
                    style={{ height: "36.5px" }}
                  >
                    <option value="">Todas las categorías</option>
                    {categories.map((category) => (
                      <option value={category?.id_clasificacion1_web_app}>
                        {category?.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div>
                <form className="input-group" onSubmit={searchProducts}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar por nombre o código"
                    style={{ width: "300px" }}
                    value={productFilterText}
                    autoFocus
                    onChange={(e) => filterProducts(e)}
                  />
                  <div
                    className="input-group-append J-btn"
                    onClick={() => searchProducts()}
                  >
                    <span className="input-group-text bg-secondary-color">
                      <SearchIcon style={{ color: "#fff" }} />
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {productsLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div className="row mt-1">
              {!filteredProducts ? (
                <p className="text-center">No hay productos para mostrar</p>
              ) : (
                <div className="col-md-12 px-2">
                  <DataTable
                    className="datatable-cs datatable-cs-v2 datatable-h-80"
                    title={"s"}
                    columns={[
                      {
                        name: "Nombre",
                        selector: "nombre",
                        sortable: true,
                        cell: (row) => (
                          <div className="d-flex w-100">
                            <div
                              style={{
                                height: "55px",
                                width: "55px",
                                border: "0.3px solid rgba(0, 0, 0, 0.7)",
                                borderRadius: "5px",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={
                                  row.imagen_1
                                    ? realImageURL(row.imagen_1)
                                    : base_url_images +
                                      "images/placeholder-image.jpeg"
                                }
                                // alt=""
                              />
                            </div>
                            <div
                              className="ml-2"
                              style={{
                                width: "150px",
                              }}
                            >
                              {row.nombre}
                              <br />
                              <strong>{row.codigo}</strong>
                            </div>
                          </div>
                        ),
                      },
                      {
                        name: "Vencimiento",
                        selector: "vencimiento_fecha",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {row.vencimiento_fecha === "N/A"
                              ? "N/A"
                              : moment(row.vencimiento_fecha).format(
                                  "DD-MM-YYYY"
                                )}
                          </span>
                        ),
                      },

                      {
                        name: "Categoría",
                        selector: "category",
                        sortable: true,
                      },
                      {
                        name: "Unidad",
                        selector: "vendido_por",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {row.id_padre === null ? row.vendido_por : "libras"}
                          </span>
                        ),
                      },
                      {
                        name: "Cantidad",
                        selector: "stock",
                        sortable: true,
                        cell: (row) => (
                          <div className="d-flex w-100 flex-wrap">
                            {" "}
                            <div className="d-flex justify-content-center w-100">
                              <span>
                                {formaterPriceFunction(row.stock + "")}
                              </span>
                            </div>
                            {isCmi() && (
                              <div className="d-flex justify-content-center w-100">
                                <span>
                                  {row.vendido_por !== "unidades"
                                    ? "/ libras"
                                    : "/ unidades"}
                                </span>
                              </div>
                            )}
                          </div>
                        ),
                      },

                      {
                        name: "Precio",
                        selector: "precio_venta",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}.{" "}
                            {row.descuento_precio > 0.0
                              ? formaterPriceFunction(
                                  (
                                    row.descuento_precio +
                                    (row.impuesto_idp || 0)
                                  ).toFixed(2) + ""
                                )
                              : formaterPriceFunction(
                                  (
                                    row.precio_venta + (row.impuesto_idp || 0)
                                  ).toFixed(2) + ""
                                )}
                          </span>
                        ),
                      },
                      {
                        name: "Acciones",
                        selector: "action",
                        omit: isGerenteMercadeo(),
                        cell: (row) => (
                          <div className="double-button d-flex justify-content-center align-items-center mx-auto">
                            <div className="d-block text-center w-50 mx-1">
                              <FormControl variant="outlined">
                                <Select
                                  native
                                  value=""
                                  onChange={(event) =>
                                    productAction(
                                      event,
                                      row.id_new_item
                                      // row.id_padre
                                      //   ? row.id_padre
                                      //   : row.id_new_item,
                                      // row.aleko
                                    )
                                  }
                                  className="cs-select"
                                  disabled={
                                    isCajero() && sucursal !== currentSucursal
                                  }
                                >
                                  <option value="">Acciones</option>
                                  <option value="details">Ver detalles</option>
                                  {!row.id_padre && !row.combo
                                    ? !isCmi() && (
                                        <>
                                          <option value="ingreso">
                                            Crear ingreso
                                          </option>
                                          <option value="egreso">
                                            Crear egreso
                                          </option>
                                        </>
                                      )
                                    : ""}
                                </Select>
                              </FormControl>
                            </div>
                            <div className="d-block text-center w-50 mx-1">
                              <CSVLink
                                data={[
                                  {
                                    nombre: row.nombre,
                                    descripcion: row.descripcion,
                                    codigo: row.codigo,
                                    costo_unitario: row.costo_unitario,
                                    descuento_precio: row.descuento_precio,
                                    precio_venta: row.precio_venta,
                                    stock: row.stock,
                                    stock_minimo: row.stock_minimo,
                                    rapida_venta: row.rapida_venta,
                                  },
                                ]}
                                filename={row.nombre + ".csv"}
                                target="_blank"
                              >
                                <Button
                                  className="btn-cs1"
                                  variant="contained"
                                  color="primary"
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    Excel
                                  </span>

                                  <CloudDownloadIcon
                                    className="ml-2"
                                    style={{ fontSize: "15px" }}
                                  />
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    data={filteredProducts}
                    selectableRows
                    defaultSortField="vencimiento_fecha"
                    paginationIconLastPage={null}
                    paginationIconFirstPage={null}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationPerPage={10}
                    paginationDefaultPage={currentPageSearch || currentPage}
                    paginationTotalRows={totalProducts}
                    onChangePage={(page) => setCurrentPage(page)}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se ha encontrado ningún resultado"
                    onSelectedRowsChange={handleSelect}
                  />
                </div>
              )}{" "}
            </div>
          )}
        </TabPanel>

        <TabPanel
          value={productsTab}
          index={isGerenteMercadeo() || isAleko() || isCmi() ? 9999 : 1}
        >
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <div>
              <h4 className="mb-0">
                <strong>Inventario de combos</strong>
              </h4>
              <p className="mb-0">Consulte y gestione sus combos.</p>
            </div>

            <Button
              className="btn-cs1"
              variant="contained"
              color="primary"
              component={Link}
              to={"/app/inventario/add_combo"}
            >
              Añadir combo
            </Button>
          </div>

          <div className="row">
            <div className="col-md-3 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de combos
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {combosLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(allCombos.length)
                  )}
                </strong>
              </h6>
            </div>

            <div className="col-md-6 px-2" />
            <div className="col-md-3 px-2 text-right">
              {selectedCombos.length > 0 ? (
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    document.getElementById("descargar-reporteCombos").click();
                  }}
                >
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              ) : (
                <Button className="btn-cs1" variant="contained" color="primary">
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              )}
            </div>
          </div>

          <ReactHTMLTableToExcel
            id="descargar-reporteCombos"
            className="buton-excel"
            table="combos-reporte-table"
            filename={"Todos los combos al " + moment().format("DD-MM-YYYY")}
            sheet="tablexls"
            buttonText="Descargar"
          />
          <CombosReporte objeto={selectedCombos}></CombosReporte>

          <div className="d-flex justify-content-end align-items-center mb-3 mt-2 flex-wrap">
            <div>
              <div className="input-group search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por nombre o código"
                  style={{ width: "300px" }}
                  value={combosFilterText}
                  autoFocus
                  onChange={(e) => filterCombos(e)}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {combosLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <>
              {!filteredCombos ? (
                <p className="text-center">No hay combos para mostrar</p>
              ) : (
                <>
                  <DataTable
                    className="datatable-cs datatable-cs-v2 datatable-h-80"
                    title={"s"}
                    columns={[
                      {
                        name: "Nombre",
                        selector: "nombre",
                        sortable: true,
                        cell: (row) => (
                          <div className="d-flex w-100">
                            <img
                              className="product-img"
                              src={
                                row.imagen_1
                                  ? realImageURL(row.imagen_1)
                                  : base_url_images +
                                    "images/placeholder-image.jpeg"
                              }
                              alt=""
                            />
                            <div
                              className="ml-2"
                              style={{
                                width: "150px",
                              }}
                            >
                              {row.nombre}
                              <br />
                              <strong>{row.codigo}</strong>
                            </div>
                          </div>
                        ),
                      },
                      {
                        name: "Existencia",
                        selector: "stock",
                        sortable: true,
                        cell: (row) => <span>{numberFormater(row.stock)}</span>,
                      },
                      {
                        name: "Precio",
                        selector: "precio_venta",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}.{" "}
                            {row.descuento_precio > 0.0
                              ? formaterPriceFunction(
                                  (
                                    row.descuento_precio +
                                    (row.impuesto_idp || 0)
                                  ).toFixed(2) + ""
                                )
                              : formaterPriceFunction(
                                  (
                                    row.precio_venta + (row.impuesto_idp || 0)
                                  ).toFixed(2) + ""
                                )}
                          </span>
                        ),
                      },
                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="d-flex justify-content-center align-items-center mx-auto">
                            <Button
                              className="btn-cs1"
                              variant="contained"
                              color="primary"
                              component={Link}
                              to={`/app/inventario/combo_details/${row.id_new_item}`}
                            >
                              <span style={{ fontSize: "12px" }}>Detalles</span>
                            </Button>
                          </div>
                        ),
                      },
                    ]}
                    data={filteredCombos}
                    selectableRows
                    defaultSortField="fecha"
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se ha encontrado ningún resultado"
                    onSelectedRowsChange={handleSelect}
                  />
                </>
              )}{" "}
            </>
          )}
        </TabPanel>

        <TabPanel
          value={productsTab}
          index={isAleko() || isCmi() ? 1 : isGerenteMercadeo() ? 9999 : 2}
        >
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <div>
              <h4 className="mb-0">
                <strong>Ingresos</strong>
              </h4>
              <p className="mb-0">
                Consulte, gestione y realice nuevos ingresos.
              </p>
            </div>

            <div>
              <p className="mb-0">Fecha:</p>
              <div className="d-flex align-items-center flex-wrap">
                <DateRangePicker
                  onChange={(val) => handleDateChange(val, "ingresos")}
                  value={ingresosDateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
            {!isCmi() && (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                startIcon={<AddBoxIcon />}
                onClick={(e) => null}
                component={Link}
                to={"/app/inventario/new_ingreso"}
              >
                <span style={{ marginBottom: "-4px" }}>Crear ingreso</span>
              </Button>
            )}
          </div>

          <div className="row">
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de ingresos
              </p>
              <h6 className="mb-0 secondary_color">
                {ingresosLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>{numberFormater(ingresoStats.total)}</strong>
                )}
              </h6>
            </div>
            <div className="col-md-2 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de productos ingresados
              </p>
              <h6 className="mb-2 secondary_color">
                {ingresosLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {numberFormater(ingresoStats.productos_ingresados)}
                  </strong>
                )}
              </h6>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
            <div>
              {/* {selectedIngresos.length > 0 ? (
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    document
                      .getElementById("descargar-reporteIngresos")
                      .click();
                  }}
                >
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              ) : (
                <Button className="btn-cs1" variant="contained" color="primary">
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              )} */}
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                disabled={ingresosLoader}
                onClick={printAllIngresos}
              >
                {ingresosLoader ? <>Descargando...</> : <>Descargar todos</>}
                <CloudDownloadIcon className="ml-2" />
              </Button>
            </div>

            <ReactHTMLTableToExcel
              id="descargar-reporteIngresos"
              className="buton-excel"
              table="ingresos-reporte-table"
              filename={
                "Ingresos desde el " +
                moment(ingresosDateRange[0]).format("DD-MM-YYYY") +
                " hasta el " +
                moment(ingresosDateRange[1]).format("DD-MM-YYYY")
              }
              sheet="tablexls"
              buttonText="Descargar"
            />

            <IngresosReporte objeto={filteredIngresos}></IngresosReporte>

            <div>
              <div className="input-group search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por número de ingreso"
                  style={{ width: "300px" }}
                  value={ingresosFilterText}
                  onChange={(event) => filterIngresos(event)}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {ingresosLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div id="table-css-v3">
              <DataTable
                className="datatable-cs datatable-cs-v2 datatable-h-80"
                title={"s"}
                columns={[
                  {
                    name: "N° de ingreso",
                    selector: "identificador",
                    sortable: true,
                  },
                  {
                    name: "Fecha / Hora",
                    selector: "fecha_ingreso",
                    sortable: true,
                    cell: (row) => (
                      <div className="text-center w-100">
                        {moment(row.fecha_ingreso)
                          .utcOffset(0)
                          .format("DD/MM/YYYY, h:mm:ss a")}
                      </div>
                    ),
                  },

                  {
                    name: "Motivo",
                    selector: "motivo",
                    sortable: true,
                  },
                  {
                    name: "N° de productos",
                    selector: "detalle.length",
                    sortable: true,
                    cell: (row) => (
                      <div className="text-center w-100">
                        {numberFormater(row.detalle.length)}
                      </div>
                    ),
                  },
                  {
                    name: "Acciones",
                    selector: "action",
                    cell: (row) => (
                      <div className="double-button d-flex justify-content-center align-items-center mx-auto">
                        <div className="d-block text-center w-50 mx-1">
                          <Button
                            className="btn-cs1"
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={`/app/inventario/ingreso_details/${row.id_ingreso_web_app}`}
                          >
                            <span style={{ fontSize: "12px" }}>Detalles</span>
                          </Button>
                        </div>
                        <div className="d-block text-center w-50 mx-1">
                          <CSVLink
                            data={[
                              {
                                identificador: row.identificador,
                                motivo: row.motivo,
                                numero_factura: row.numero_documento || "N/A",
                                numero_productos: row.total_productos,
                                fecha: moment(row.fecha_ingreso)
                                  .utcOffset(0)
                                  .format("DD-MM-YYYY HH:mm:ss a"),
                              },
                            ]}
                            filename={`Ingreso ${row.identificador}.csv`}
                            target="_blank"
                          >
                            <Button
                              className="btn-cs1"
                              variant="contained"
                              color="primary"
                            >
                              <span style={{ fontSize: "12px" }}>Excel</span>
                              <CloudDownloadIcon
                                className="ml-2"
                                style={{ fontSize: "15px" }}
                              />
                            </Button>
                          </CSVLink>
                        </div>
                      </div>
                    ),
                  },
                ]}
                data={filteredIngresos}
                defaultSortField="fecha_ingreso"
                defaultSortAsc={false}
                selectableRows
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se ha encontrado ningún ingreso"
                onSelectedRowsChange={handleSelect}
              />
            </div>
          )}
        </TabPanel>

        <TabPanel value={productsTab} index={isAleko() || isCmi() ? 2 : 3}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <div>
              <h4 className="mb-0">
                <strong>Egresos</strong>
              </h4>
              <p className="mb-0">
                Consulte, gestione y realice nuevos egresos.
              </p>
            </div>

            <div>
              <p className="mb-0">Fecha:</p>
              <div className="d-flex align-items-center flex-wrap">
                <DateRangePicker
                  onChange={(val) => handleDateChange(val, "egresos")}
                  value={egresosDateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
            {!isCmi() && (
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                startIcon={<AddBoxIcon />}
                onClick={(e) => null}
                component={Link}
                to={"/app/inventario/new_egreso"}
              >
                <span style={{ marginBottom: "-4px" }}>Crear egreso</span>
              </Button>
            )}
          </div>

          <div className="row">
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de egresos
              </p>
              <h6 className="mb-0 secondary_color">
                {egresosLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>{numberFormater(egresoStats.total)}</strong>
                )}
              </h6>
            </div>
            <div className="col-md-2 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de productos egresados
              </p>
              <h6 className="mb-2 secondary_color">
                {egresosLoader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <strong>
                    {numberFormater(egresoStats.productos_egresados)}
                  </strong>
                )}
              </h6>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
            <div>
              {/* {selectedEgresos.length > 0 ? (
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    document.getElementById("descargar-reporteEgresos").click();
                  }}
                >
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              ) : (
                <Button className="btn-cs1" variant="contained" color="primary">
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              )} */}
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                disabled={egresosLoader}
                onClick={printAllEgresos}
              >
                {egresosLoader ? <>Descargando...</> : <>Descargar todos</>}
                <CloudDownloadIcon className="ml-2" />
              </Button>
            </div>

            <EgresosReporte objeto={filteredEgresos}></EgresosReporte>

            <ReactHTMLTableToExcel
              id="descargar-reporteEgresos"
              className="buton-excel"
              table="egresos-reporte-table"
              filename={
                "Egresos desde el " +
                moment(egresosDateRange[0]).format("DD-MM-YYYY") +
                " hasta el " +
                moment(egresosDateRange[1]).format("DD-MM-YYYY")
              }
              sheet="tablexls"
              buttonText="Descargar"
            />

            <div>
              <div className="input-group search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por número de egreso"
                  style={{ width: "300px" }}
                  value={egresosFilterText}
                  onChange={(event) => filterEgresos(event)}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {egresosLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2 datatable-h-80"
              title={"s"}
              columns={[
                {
                  name: "N° de egreso",
                  selector: "identificador",
                  sortable: true,
                },
                {
                  name: "Fecha / Hora",
                  selector: "fecha_ingreso",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {moment(row.fecha_ingreso)
                        .utcOffset(0)
                        .format("DD/MM/YYYY, h:mm:ss a")}
                    </div>
                  ),
                },
                {
                  name: "Motivo",
                  selector: "motivo",
                  sortable: true,
                },
                {
                  name: "N° de productos",
                  selector: "detalle.length",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {numberFormater(row.detalle.length)}
                    </div>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="double-button d-flex justify-content-center align-items-center mx-auto">
                      <div className="d-block text-center w-50 mx-1">
                        <Button
                          className="btn-cs1"
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={`/app/inventario/egreso_details/${row.id_egreso_web_app}`}
                        >
                          <span style={{ fontSize: "12px" }}>Detalles</span>
                        </Button>
                      </div>
                      <div className="d-block text-center w-50 mx-1">
                        <CSVLink
                          data={[
                            {
                              identificador: row.identificador,
                              motivo: row.motivo,
                              numero_productos: row.total_productos,
                              fecha: moment(row.fecha_ingreso)
                                .utcOffset(0)
                                .format("DD-MM-YYYY HH:mm:ss a"),
                            },
                          ]}
                          filename={`Egreso ${row.identificador}.csv`}
                          target="_blank"
                        >
                          <Button
                            className="btn-cs1"
                            variant="contained"
                            color="primary"
                          >
                            <span style={{ fontSize: "12px" }}>Excel</span>
                            <CloudDownloadIcon
                              className="ml-2"
                              style={{ fontSize: "15px" }}
                            />
                          </Button>
                        </CSVLink>
                      </div>
                    </div>
                  ),
                },
              ]}
              data={filteredEgresos}
              defaultSortField="fecha_ingreso"
              defaultSortAsc={false}
              selectableRows
              pagination
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se ha encontrado ningún egreso"
              onSelectedRowsChange={handleSelect}
            />
          )}
        </TabPanel>

        <TabPanel value={productsTab} index={isAleko() || isCmi() ? 4 : 5}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <div>
              <h4 className="mb-0">
                <strong>Kardex de inventario</strong>
              </h4>
              <p className="mb-0">
                Consulte el histórico de movimientos de su producto.
              </p>
            </div>

            <div>
              <p className="mb-0">Fecha:</p>
              <div className="d-flex align-items-center flex-wrap">
                <DateRangePicker
                  onChange={(val) => handleDateChange(val, "kardex")}
                  value={kardexDateRange}
                  format={"dd-MM-y"}
                  className="date-picker-cs px-1"
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center mb-3 mt-2 flex-wrap">
            {/*  <div>
                            <div className="input-group search-bar">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar por nombre o código SKU"
                                    style={{ width: "300px" }}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <SearchIcon />
                                    </span>
                                </div>
                            </div>
                        </div> */}
            <div>
              {kardex.length > 0 ? (
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    document.getElementById("descargar-reporteKardex").click();
                  }}
                >
                  Descargar todo
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              ) : (
                <Button className="btn-cs1" variant="contained" color="primary">
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              )}

              <ReactHTMLTableToExcel
                id="descargar-reporteKardex"
                className="buton-excel red--button"
                table="kardex-reporte-table"
                filename="Cloud-Reporte-Kardex"
                sheet="tablexls"
                buttonText="Descargar"
              />

              <div style={{ marginTop: "20px" }}>
                <div className="input-group search-bar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar por nombre"
                    style={{ width: "300px" }}
                    value={kardexFilter}
                    autoFocus
                    onChange={(e) => filterKardex(e)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <SearchIcon />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {kardexLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div className="kardex-table-top scroll">
              {kardex.length > 0 ? (
                <div className="cells-wrapper">
                  <div className="cell-top">Entradas</div>
                  <div className="cell-top">Salidas</div>
                  <div className="cell-top">Existencias</div>
                </div>
              ) : (
                ""
              )}

              <KardexReporte objeto={kardex}></KardexReporte>

              <DataTable
                className="datatable-cs datatable-cs-v2 kardex-table"
                title={"s"}
                columns={[
                  {
                    name: "Fecha / Hora",
                    selector: "fecha",
                    sortable: true,
                    cell: (row) => (
                      <span>
                        {moment(row.fecha)
                          .utcOffset(0)
                          .format("DD-MM-YYYY h:mm:ss a")}
                      </span>
                    ),
                  },
                  {
                    name: "Producto",
                    selector: "nombre_producto",
                    sortable: true,
                    grow: 3,
                  },

                  {
                    name: "Identificador",
                    selector: "numero",
                    sortable: true,
                    grow: 2,
                    cell: (row) => <span>{`${row.serie}${row.numero}`}</span>,
                  },
                  {
                    name: "Operación",
                    selector: "serie",
                    sortable: true,
                    cell: (row) => <span>{operationName(row.serie)}</span>,
                  },
                  {
                    name: "Motivo",
                    selector: "tipo_operacion",
                    grow: 2,
                    sortable: true,
                    cell: (row) => (
                      <span>
                        {row.tipo_operacion.charAt(0).toUpperCase() +
                          row.tipo_operacion.toLowerCase().slice(1)}
                      </span>
                    ),
                  },
                  {
                    name: "Cantidad",
                    selector: "qty_entrada",
                    sortable: true,
                    cell: (row) =>
                      !row.id_new_motivo_ingreso
                        ? "-"
                        : `${numberFormater(row.cantidad)}`,
                  },
                  {
                    name: "Costo Unit.",
                    selector: "costo_unit_entrada",
                    sortable: true,
                    cell: (row) =>
                      !row.id_new_motivo_ingreso
                        ? "-"
                        : `${moneda}. ${formaterPriceFunction(
                            parseFloat(row.costo_unitario).toFixed(2) + ""
                          )}`,
                  },
                  {
                    name: "Total",
                    selector: "total_entrada",
                    sortable: false,
                    cell: (row) =>
                      !row.id_new_motivo_ingreso ? (
                        "-"
                      ) : (
                        <strong>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            (row.cantidad * row.costo_unitario).toFixed(5) + ""
                          )}
                        </strong>
                      ),
                  },
                  {
                    name: "Cantidad",
                    selector: "qty_salida",
                    sortable: true,
                    cell: (row) =>
                      !row.id_new_motivo_egres
                        ? "-"
                        : `${numberFormater(row.cantidad)}`,
                  },
                  {
                    name: "Costo Unit.",
                    selector: "costo_unit_salida",
                    sortable: true,
                    cell: (row) =>
                      !row.id_new_motivo_egres || row.id_padre
                        ? "-"
                        : `${moneda}. ${formaterPriceFunction(
                            row.costo_unitario.toFixed(2) + ""
                          )}`,
                  },
                  {
                    name: "Total",
                    selector: "total_salida",
                    sortable: true,
                    cell: (row) =>
                      !row.id_new_motivo_egres || row.id_padre ? (
                        "-"
                      ) : (
                        <strong>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            (row.costo_unitario * row.cantidad).toFixed(5) + ""
                          )}
                        </strong>
                      ),
                  },
                  {
                    name: "Cantidad",
                    selector: "saldo",
                    sortable: true,
                    cell: (row) =>
                      !row.saldo || row.id_padre
                        ? "-"
                        : `${formaterPriceFunction(row.saldo + "")}`,
                  },
                  {
                    name: "Costo Unit.",
                    selector: "costo_unitario",
                    sortable: true,
                    cell: (row) =>
                      !row.costo_unitario || row.id_padre
                        ? "-"
                        : `${moneda}. ${formaterPriceFunction(
                            parseFloat(row.costo_unitario).toFixed(2) + ""
                          )}`,
                  },
                  {
                    name: "Total",
                    selector: "saldo",
                    sortable: true,
                    grow: 2,
                    cell: (row) =>
                      !row.saldo || row.id_padre ? (
                        "-"
                      ) : (
                        <strong>
                          {moneda}.{" "}
                          {formaterPriceFunction(
                            (row.costo_unitario * row.saldo).toFixed(5) + ""
                          )}
                        </strong>
                      ),
                  },
                ]}
                data={kardex}
                pagination
                paginationPerPage={10}
                // defaultSortField="fecha"
                // defaultSortAsc={false}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se ha encontrado ningún resultado"
              />
            </div>
          )}
        </TabPanel>

        <TabPanel value={productsTab} index={isCmi() ? 9999 : 6}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <div>
              <h4 className="mb-0">
                <strong>Inventario de recetas</strong>
              </h4>
              <p className="mb-0">
                Consulte y gestione el inventario de recetas
              </p>
            </div>

            <Button
              className="btn-cs1"
              variant="contained"
              color="primary"
              onClick={(e) => setProductsMenu(e.currentTarget)}
            >
              Añadir receta
              <ExpandMoreIcon />
            </Button>
          </div>

          <div className="row">
            <div
              className="col-md-2 px-2 justify-content-"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de recetas
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {productsLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(recetasStats.total)
                  )}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Recetas no disponibles
              </p>
              <h6 className="mb-2 secondary_color">
                <strong>
                  {productsLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(recetasStats.sin_stock)
                  )}
                </strong>
              </h6>
            </div>

            <div className="col-md-3 px-2">
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Recetas con bajo inventario
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {productsLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(recetasStats.bajo_inventario)
                  )}
                </strong>
              </h6>
            </div>
            <div className="col-md-3 px-2 text-right">
              {selectedProducts.length > 0 ? (
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    document.getElementById("descargar-reporteRecetas").click();
                  }}
                >
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              ) : (
                <Button className="btn-cs1" variant="contained" color="primary">
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              )}
            </div>
          </div>

          <ReactHTMLTableToExcel
            id="descargar-reporteRecetas"
            className="buton-excel"
            table="recetas-reporte-table"
            filename={"Todos los productos al " + moment().format("DD-MM-YYYY")}
            sheet="tablexls"
            buttonText="Descargar"
          />

          <RecetasReporte objeto={selectedProducts}></RecetasReporte>

          <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
            <div>
              <FormControl variant="outlined" className="px-1">
                <Select
                  native
                  value={recetasCategory}
                  onChange={changeRecetaCategory}
                  style={{ width: "100%" }}
                  variant="outlined"
                  className="sm-select"
                >
                  <option value="">Todas las categorías</option>
                  {categories.map((category) => (
                    <option value={category?.id_clasificacion1_web_app}>
                      {category?.descripcion}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div>
              <div className="input-group search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por nombre o código"
                  style={{ width: "300px" }}
                  value={filterRecetasText}
                  autoFocus
                  onChange={(e) => filtrarRecetas(e)}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {productsLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <>
              {!filteredRecetas ? (
                <p className="text-center">No hay recetas para mostrar</p>
              ) : (
                <>
                  <DataTable
                    className="datatable-cs datatable-cs-v2 datatable-h-80"
                    title={"s"}
                    columns={[
                      {
                        name: "Nombre",
                        selector: "nombre",
                        sortable: true,
                        cell: (row) => (
                          <div className="d-flex w-100">
                            <img
                              className="product-img"
                              src={
                                row.imagen_1
                                  ? realImageURL(row.imagen_1)
                                  : base_url_images +
                                    "images/placeholder-image.jpeg"
                              }
                              alt=""
                            />
                            <div
                              className="ml-2"
                              style={{
                                width: "150px",
                              }}
                            >
                              {row.nombre}
                              <br />
                              <strong>{row.codigo}</strong>
                            </div>
                          </div>
                        ),
                      },
                      {
                        name: "Vencimiento",
                        selector: "vencimiento_fecha",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {row.vencimiento_fecha === "N/A"
                              ? "N/A"
                              : moment(row.vencimiento_fecha).format(
                                  "DD-MM-YYYY"
                                )}
                          </span>
                        ),
                      },
                      {
                        name: "Categoría",
                        selector: "category",
                        sortable: true,
                      },
                      {
                        name: "Cantidad",
                        selector: "stock",
                        sortable: true,
                        cell: (row) => "N/A",
                      },

                      {
                        name: "Precio",
                        selector: "precio_venta",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}.{" "}
                            {row.descuento_precio > 0.0
                              ? formaterPriceFunction(
                                  (
                                    row.descuento_precio +
                                    (row.impuesto_idp || 0)
                                  ).toFixed(2) + ""
                                )
                              : formaterPriceFunction(
                                  (
                                    row.precio_venta + (row.impuesto_idp || 0)
                                  ).toFixed(2) + ""
                                )}
                          </span>
                        ),
                      },
                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="double-button d-flex justify-content-center align-items-center mx-auto">
                            <div className="d-block text-center w-50 mx-1">
                              <FormControl variant="outlined">
                                <Select
                                  native
                                  value=""
                                  onChange={(event) =>
                                    productAction(
                                      event,
                                      row.id_new_item
                                      // row.id_padre
                                      //   ? row.id_padre
                                      //   : row.id_new_item
                                    )
                                  }
                                  className="cs-select"
                                >
                                  <option value="">Acciones</option>
                                  <option value="details">Ver detalles</option>

                                  <option
                                    value={
                                      row.category !== "Personalizable"
                                        ? "delete"
                                        : "alekoDelete"
                                    }
                                  >
                                    Eliminar
                                  </option>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="d-block text-center w-50 mx-1">
                              <CSVLink
                                data={[
                                  {
                                    nombre: row.nombre,
                                    descripcion: row.descripcion,
                                    codigo: row.codigo,
                                    costo_unitario: row.costo_unitario,
                                    descuento_precio: row.descuento_precio,
                                    precio_venta: row.precio_venta,
                                    stock: row.stock,
                                    stock_minimo: row.stock_minimo,
                                    rapida_venta: row.rapida_venta,
                                  },
                                ]}
                                filename={row.nombre + ".csv"}
                                target="_blank"
                              >
                                <Button
                                  className="btn-cs1"
                                  variant="contained"
                                  color="primary"
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    Excel
                                  </span>

                                  <CloudDownloadIcon
                                    className="ml-2"
                                    style={{ fontSize: "15px" }}
                                  />
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    data={filteredRecetas}
                    selectableRows
                    defaultSortField="vencimiento_fecha"
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se ha encontrado ningún resultado"
                    onSelectedRowsChange={handleSelect}
                  />
                </>
              )}{" "}
            </>
          )}
        </TabPanel>

        <TabPanel value={productsTab} index={isCmi() ? 7 : 7}>
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <div>
              <h4 className="mb-0">
                <strong>Insumos</strong>
              </h4>
              <p className="mb-0">
                Consulte, gestione y actualice sus insumos.
              </p>
            </div>

            <Button
              className="btn-cs1 shadow-none"
              variant="contained"
              color="primary"
              onClick={(e) => setSuppliesMenu(e.currentTarget)}
            >
              Añadir
              <ExpandMoreIcon />
            </Button>
          </div>

          <div className="row">
            <div
              className="col-md-2 px-2 justify-content-"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de insumos
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {suppliesLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(supplies_stats.total)
                  )}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Insumos sin stock
              </p>
              <h6 className="mb-2 secondary_color">
                <strong>
                  {suppliesLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(supplies_stats.sin_stock)
                  )}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Insumos próximos a vencer
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {suppliesLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(supplies_stats.por_vencer)
                  )}
                </strong>
              </h6>
            </div>
            <div className="col-md-3 px-2"></div>
            <div className="col-md-3 px-2 text-right">
              {selectedSupplies.length > 0 ? (
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    document.getElementById("descargar-reporteInsumos").click();
                  }}
                >
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              ) : (
                <Button className="btn-cs1" variant="contained" color="primary">
                  Descargar seleccionados
                  <CloudDownloadIcon className="ml-2" />
                </Button>
              )}
            </div>
          </div>

          <ReactHTMLTableToExcel
            id="descargar-reporteInsumos"
            className="buton-excel"
            table="insumos-reporte-table"
            filename={"Todos los insumos al " + moment().format("DD-MM-YYYY")}
            sheet="tablexls"
            buttonText="Descargar"
          />

          <InsumosReporte objeto={selectedSupplies}></InsumosReporte>

          <div className="d-flex justify-content-end align-items-center mb-3 mt-2 flex-wrap">
            <div>
              <div className="input-group search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por nombre o código"
                  style={{ width: "300px" }}
                  value={suppliesFilterText}
                  autoFocus
                  onChange={(e) => filterSupplies(e)}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {suppliesLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <>
              <DataTable
                className="datatable-cs datatable-cs-v2 datatable-h-80"
                title={"s"}
                columns={[
                  {
                    name: "Nombre",
                    selector: "nombre",
                    sortable: true,
                    cell: (row) => (
                      <div className="d-flex w-100">
                        <img
                          className="product-img"
                          src={
                            row.imagen_1
                              ? realImageURL(row.imagen_1)
                              : base_url_images +
                                "images/placeholder-image.jpeg"
                          }
                          alt=""
                        />
                        <div
                          className="ml-2"
                          style={{
                            width: "150px",
                          }}
                        >
                          {row.nombre}
                          <br />
                          <strong>{row.codigo}</strong>
                        </div>
                      </div>
                    ),
                  },
                  {
                    name: "Vencimiento",
                    selector: "vencimiento_fecha",
                    sortable: true,
                    cell: (row) => (
                      <span>
                        {row.vencimiento_fecha === "N/A"
                          ? "N/A"
                          : moment(row.vencimiento_fecha).format("DD-MM-YYYY")}
                      </span>
                    ),
                  },
                  {
                    name: "Inventario",
                    selector: "stock",
                    sortable: true,
                  },

                  {
                    name: "Unidad/Medida",
                    selector: "unidad",
                    sortable: true,
                  },
                  {
                    name: "Acciones",
                    selector: "action",
                    cell: (row) => (
                      <div className="d-block text-center mx-1">
                        <FormControl variant="outlined">
                          <Select
                            native
                            value=""
                            onChange={(event) =>
                              supplyAction(event, row.id_new_item)
                            }
                            className="cs-select"
                          >
                            <option value="">Acciones</option>
                            <option value="details">Ver detalles</option>
                            {!row.id_padre && !row.combo ? (
                              <>
                                <option value="ingreso">Crear ingreso</option>
                                <option value="egreso">Crear egreso</option>
                              </>
                            ) : (
                              ""
                            )}
                            <option value="delete">Eliminar</option>
                          </Select>
                        </FormControl>
                      </div>
                    ),
                  },
                ]}
                data={filteredSupplies}
                selectableRows
                defaultSortField="vencimiento_fecha"
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se ha encontrado ningún resultado"
                onSelectedRowsChange={handleSelect}
              />
            </>
          )}
        </TabPanel>

        <TabPanel
          value={productsTab}
          index={isGerenteMercadeo() ? 8 : mermaGuard() ? 8 : 8}
        >
          <ReportMerma></ReportMerma>
        </TabPanel>

        <TabPanel value={productsTab} index={aleko ? 5 : 9}>
          <Parametrizacion />
        </TabPanel>
      </div>

      <Menu
        open={Boolean(productsMenu)}
        anchorEl={productsMenu}
        style={{ width: "300px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setProductsMenu(null)}
      >
        <MenuItem
          className="menuItem-border"
          component={Link}
          to={"/app/inventario/add_product"}
        >
          {!recetasTab ? "Añadir producto" : "Añadir receta"}
        </MenuItem>
        {!recetasTab && (
          <MenuItem component={Link} to={"/app/inventario/carga_masiva"}>
            Carga masiva de productos
          </MenuItem>
        )}

        {/* <MenuItem component={Link} to={"/app/inventario/add_combo"}>
          Añadir combo
        </MenuItem> */}
      </Menu>

      <Menu
        open={Boolean(suppliesMenu)}
        anchorEl={suppliesMenu}
        getContentAnchorEl={null}
        style={{ width: "300px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setSuppliesMenu(null)}
      >
        <MenuItem
          className="menuItem-border"
          component={Link}
          to={"/app/inventario/add_supply"}
        >
          Añadir insumo individual
        </MenuItem>
        {/* <MenuItem component={Link} to={"/app/inventario/upload_supplies"}>
          Carga masiva de insumos
        </MenuItem> */}
      </Menu>
    </div>
  );
}
