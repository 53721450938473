import React from "react";

const markets = [
  { name: "Aleko", purchases: 80, color: "#33eaff" },
  { name: "CC Oakland", purchases: 10, color: "#fff200" },
  { name: "CC Miraflores", purchases: 40, color: "#3d3d3d" },
  { name: "Plaza futeca", purchases: 30, color: "#ff9800" },
  { name: "CC Pradera Concepción", purchases: 20, color: "#0160E7" },
  { name: "CC San Isidro", purchases: 10, color: "#5f27cd" },
];
const Markets = () => {
  const baseValue = markets
    .map((item) => item.purchases)
    .reduce((a, b) => a + b);
  const porcentage = (num) => {
    return Math.round((num * 100) / baseValue);
  };
  return (
    <>
      <div
        className="card shadow-sm"
        style={{
          border: "none",
          height: "100%",
          background: "rgb(251,251,251)",
        }}
      >
        <div
          className="section-title shadow-sm px-3 py-2"
          style={{ background: "rgb(245,245,245)" }}
        >
          <strong>Tiendas frecuentadas</strong>
          <select className="form-select ml-auto">
            <option>Últimos 30 días</option>
          </select>
        </div>
        <div className="card-body">
          <div className="d-flex mt-1">
            {markets.map((market) => (
              <div
                style={{
                  width: `${porcentage(market.purchases)}%`,
                  height: 20,
                  background: market.color,
                }}
              />
            ))}
          </div>
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {markets.map((item) => (
              <div className="d-flex align-items-center mr-2 py-1">
                <div
                  style={{
                    width: 10,
                    height: 10,
                    background: item.color,
                    borderRadius: "50%",
                  }}
                  className="mr-1"
                />
                {item.name} ({porcentage(item.purchases)}%)
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Markets;
