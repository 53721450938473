import React, { useState } from "react";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../api";

// styles
import useStyles from "./styles";
import "./custom.css";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

function Login(props) {
  var classes = useStyles();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [email, setEmail] = useState("");

  const sendMail = () => {
    setIsLoading(true);
    API.admin_user
      .sendEmailCode({
        correo: email,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setIsLoading(false);
          props.history.push({
            pathname: "/admin/forgot/verify",
            state: { userEmail: email },
          });
          toast.success("El código de recuperación ha sido enviado.", {
            autoClose: 10000,
          });
        } else {
          toast.warning(response.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  return (
    <div className="row h-100" style={{ marginRight: "-17.5px" }}>
      <div className="col-lg-6 ">
        <Button
          className="btn-cs1 brand2-btn m-4"
          variant="contained"
          color="primary"
          to={"/admin/membresia"}
          component={Link}
          style={{ paddingBottom: "0px" }}
        >
          <ArrowBackIosIcon
            className="mr-3"
            style={{ fontSize: "16px", marginBottom: "3px" }}
          />
          <strong>Volver</strong>
        </Button>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "85%" }}
        >
          <div className="row p-0 w-100 justify-content-center">
            <div className="col-8">
              <div className="content-card card">
                <div className="card-body ">
                  <h1 style={{ fontSize: "2.1rem" }} className="mb-1">
                    <strong>Recuperación de contraseña</strong>
                  </h1>
                  <p>
                    Revise su bandeja de entrada y/o sus correos no deseados y
                    siga las instrucciones.
                  </p>

                  <p className="mt-5 mb-0" style={{ color: "#0160E7" }}>
                    <strong>Correo electrónico</strong>
                  </p>

                  <TextField
                    id="email"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    margin="normal"
                    placeholder="Ingrese su correo electrónico"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <div className="d-flex">
                    {isLoading ? (
                      <CircularProgress size={26} className="mx-auto" />
                    ) : (
                      <Button
                        onClick={() => sendMail()}
                        variant="contained"
                        size="large"
                        style={{
                          backgroundColor: "#0160E7",
                          color: "white",
                          textTransform: "none",
                        }}
                        className="ml-auto mt-4"
                      >
                        <strong>Enviar correo</strong>
                        <img
                          src={"/images/RightArrow.png"}
                          className="ml-3 mr-2"
                          alt=""
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-6 logo-container recovery1 d-flex justify-content-center align-items-center ">
        <img
          style={{ width: "400px", height: "auto" }}
          src={"/images/admin-logo.png"}
        />
      </div>
    </div>
  );
}

export default withRouter(Login);
