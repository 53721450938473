import {
  CircularProgress,
  FormControl,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import SearchIcon from "@material-ui/icons/Search";
import DataTable from "react-data-table-component";
import { realImageURL, base_url_images } from "../../../../api";
import UpdatePriceModal from "./updateModal";
import CambioMasivoContext from "../../../../context/CambioMasivoContext/CambioMasivoContext";
import { useHistory } from "react-router-dom";
import { getMoneda } from "../../../../helpers/moneda";
const MainCard = () => {
  const {
    handleGetProducts,
    loading,
    products,
    handleSelectProducts,
    updated,
    productName,
    setProductName,
  } = useContext(CambioMasivoContext);
  const moneda = getMoneda();

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSearch = (e) => {
    if (e === "click") {
      if (productName.trim()) {
        handleGetProducts(productName);
      }
      return;
    }
    if (e.key === "Enter") {
      if (productName.trim()) {
        handleGetProducts(productName);
      }
    }
  };
  // Esto es para buscar los productos automaticamente sin presionar enter
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (productName.trim()) {
  //       handleGetProducts(productName);
  //     }
  //   }, 1000);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [productName]);
  // const handleChange = (row) => {
  //   history.push(`/app/inventario/product_details/${row.id_new_item}`);
  // };
  return (
    <div className="card mt-3">
      <div className="p-3">
        <div className="row">
          <div className="col-md-12">
            <Typography>
              <strong>Cambio masivo de precios</strong>
            </Typography>
            <Typography>
              Busque y actualice sus precios de forma masiva
            </Typography>
          </div>
        </div>
      </div>
      <div className="border-bottom" />
      <div className="p-3">
        <div className="d-flex justify-content-between">
          <UpdatePriceModal />

          <div>
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar por nombre"
                style={{ width: "300px" }}
                value={productName}
                onKeyPress={handleSearch}
                onChange={(e) => setProductName(e.target.value)}
              />
              <div
                className="input-group-append"
                style={{ cursor: "pointer" }}
                onClick={() => handleSearch("click")}
              >
                <span className="input-group-text bg-secondary-color">
                  <SearchIcon style={{ color: "#fff" }} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="table-css-v3">
        <DataTable
          className="datatable-cs datatable-cs-v2 datatable-h-80"
          columns={[
            {
              name: "Nombre del producto",
              selector: "nombre",
              sortable: true,
              cell: (row) => (
                <div className="d-flex w-100">
                  <div
                    style={{
                      height: "55px",
                      width: "55px",
                      border: "0.3px solid rgba(0, 0, 0, 0.7)",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={
                        row.imagen_1
                          ? realImageURL(row.imagen_1)
                          : base_url_images + "images/placeholder-image.jpeg"
                      }
                      alt={row.nombre}
                    />
                  </div>

                  <div
                    className="ml-2"
                    style={{
                      width: "150px",
                    }}
                  >
                    {row.nombre}
                    <br />
                    <strong>{row.codigo}</strong>
                  </div>
                </div>
              ),
            },
            {
              name: "Sucursal",
              selector: "ubicacion",
              sortable: true,
            },

            {
              name: "Cantidad",
              selector: "stock",
              sortable: true,
              cell: (row) => (
                <div className="d-flex w-100 flex-wrap">
                  {" "}
                  <div className="d-flex justify-content-center w-100">
                    <span>{formaterPriceFunction(row.stock + "")}</span>
                  </div>
                  <div className="d-flex justify-content-center w-100">
                    <span>
                      {row.vendido_por !== "unidades"
                        ? "/ libras"
                        : "/ unidades"}
                    </span>
                  </div>
                </div>
              ),
            },
            {
              name: "Precio",
              selector: "precio_venta",
              sortable: true,
              cell: (row) => (
                <div className="w-100 d-flex justify-content-center">
                  <span>
                    {moneda}. {formaterPriceFunction(row.precio_venta + "")}
                  </span>
                </div>
              ),
            },
            // {
            //   name: "Acciones",
            //   selector: "action",
            //   sortable: true,
            //   cell: (row) => (
            //     <FormControl variant="outlined" className="w-100" size="small">
            //       <Select native fullWidth onChange={() => handleChange(row)}>
            //         <option value="">Acciones</option>
            //         <option value="details">Ver detalles</option>
            //       </Select>
            //     </FormControl>
            //   ),
            // },
          ]}
          data={products}
          selectableRows
          defaultSortField="vencimiento_fecha"
          pagination
          paginationPerPage={10}
          paginationIconFirstPage={null}
          paginationIconLastPage={null}
          paginationComponentOptions={{
            rangeSeparatorText: "de",
            noRowsPerPage: true,
            selectAllRowsItem: false,
            selectAllRowsItemText: "All",
          }}
          noDataComponent={
            <div
              className="d-flex  align-items-center flex-wrap"
              style={{ height: "260px" }}
            >
              <div className="w-100 text-center">
                {loading ? (
                  <CircularProgress style={{ fontSize: "30px" }} />
                ) : (
                  <SearchIcon style={{ fontSize: "35px" }} />
                )}
                <br />
                Busque los productos a los que desea cambiarle el precio.
              </div>
            </div>
          }
          onSelectedRowsChange={handleSelectProducts}
          clearSelectedRows={updated}
        />
      </div>
    </div>
  );
};

export default MainCard;
