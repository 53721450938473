export const handleContent = (value, variant, handleForms, index) => {
  const name = `contenido_tipo_${index + 1}`;

  handleForms({
    variantId: variant.variantId,
    [name]: value,
  });
};

export const handleCustom = (value, variant, handleForms, index, id) => {
  const personalizacion_tipo = variant.custom.customType.find(
    (e) => e.nombre_personalizacion === value
  );
  console.log("Tipo", personalizacion_tipo);
  const name = `personalizacion_tipo_${index + 1}`;
  const detalle = `id_detalle_personalizable_${index + 1}`;
  const tipo_precio = `personalizacion_tipo_precio_${index + 1}`;
  const tipo_precio_minimo = `personalizacion_tipo_precio_minimo_${index + 1}`;
  const tipo_precio_valor = personalizacion_tipo.precio_medida || 1.5;
  const tipo_precio_minimo_valor = personalizacion_tipo.precio_minimo || 25;

  handleForms({
    variantId: variant.variantId,
    [name]: value,
    [detalle]: id,
    [tipo_precio]: tipo_precio_valor,
    [tipo_precio_minimo]: tipo_precio_minimo_valor,
  });
};

export const handleWidth = (value, variant, handleForms, index) => {
  const name = `medidas_ancho_${index + 1}`;
  handleForms({
    variantId: variant.variantId,
    [name]: value,
  });
};

export const handleHeight = (value, variant, handleForms, index) => {
  const name = `medidas_alto_${index + 1}`;

  handleForms({
    variantId: variant.variantId,
    [name]: value,
  });
};
export const handleSetFile = (file, variant, handleForms, index) => {
  const name = `adjunto_${index + 1}`;
  handleForms({
    variantId: variant.variantId,
    [name]: file,
  });
};
export const handleObservations = (value, variant, handleForms, index) => {
  const name = `observaciones_${index + 1}`;
  handleForms({
    variantId: variant.variantId,
    [name]: value,
  });
};
export const handleAreas = (value, variant, handleForms, index) => {
  const name = `nombre_area_${index + 1}`;
  handleForms({
    variantId: variant.variantId,
    [name]: value,
  });
};
export const handleCoordinates = (coordinates, variant, handleForms, index) => {
  const nameX = `coordenadas_x_personalizable_${index + 1}`;
  const nameY = `coordenadas_y_personalizable_${index + 1}`;
  handleForms({
    variantId: variant.variantId,
    [nameX]: coordinates.x,
    [nameY]: coordinates.y,
  });
};
