import React from "react";
import moment from "moment";
import { isAleko } from "../../../../helpers/isAleko";
const Egreso = ({ egreso, companyName }) => {
  return (
    <div style={{ display: "none" }}>
      <div id="printform">
        <div className="row">
          {isAleko() ? (
            <div className="col-12 d-flex justify-content-center">
              <img
                className="mb-0"
                src={"/images/logo_aleko_v1.png"}
                alt="factura-imagen"
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12">
            <h3>
              <strong>{companyName}</strong>
            </h3>
            <h3 className="text-center">
              <strong>Egreso #{egreso.numero}</strong>
            </h3>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between py-1">
                <span>
                  Fecha:{" "}
                  {moment(new Date(egreso.fecha_ingreso))
                    .utcOffset(0)
                    .format("YYYY-MM-DD")}
                </span>
              </div>

              <div className="col-md-12 d-flex justify-content-between py-1">
                <span>Motivo del egreso:</span>
                <span>
                  <b>{egreso.motivo}</b>
                </span>
              </div>

              <div className="col-md-12 d-flex justify-content-between py-1">
                <span>Sucursal de egreso:</span>
                <span>{egreso.nombre_sucursal}</span>
              </div>
              <br />
              <br />
              <br />
              {egreso?.items?.map((item, i) => (
                <>
                  <div className="col-md-12 d-flex justify-content-between py-1">
                    <span>CODIGO:</span>
                    <span>{item.codigo}</span>
                  </div>
                  <div className="col-md-12 d-flex justify-content-between py-1">
                    <span>NOMBRE:</span>
                    <span>{item.nombre}</span>
                  </div>
                  {item.vendido_por === "mixto" && (
                    <div className="col-md-12 d-flex justify-content-between py-1">
                      <span>CANTIDAD (UNIDADES):</span>
                      <span>{item.cantidad_peso} uds.</span>
                    </div>
                  )}
                  <div className="col-md-12 d-flex justify-content-between py-1">
                    <span>
                      CANTIDAD (
                      {item.vendido_por === "mixto" ||
                      item.vendido_por === "libras"
                        ? "LIBRAS"
                        : "UNIDADES"}
                      ):
                    </span>
                    <span>
                      {item.cantidad}
                      {item.vendido_por === "mixto" ||
                      item.vendido_por === "libras"
                        ? " lbs."
                        : " uds."}
                    </span>
                  </div>

                  <div className="col-md-12 border-bottom py-1" />
                </>
              ))}
              <div className="col-md-12 d-flex justify-content-between py-1">
                <span>Usuario: {egreso.usuario}</span>
              </div>
              {egreso.comentario && (
                <div className="col-md-12 d-flex flex-wrap py-1">
                  <span className="w-100">Detalle del egreso:</span>
                  <p className="w-100">
                    <b>{egreso.comentario}</b>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Egreso;
