// eslint-disable-next-line
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CustomPicker from "./CustomColorPicker/CustomPicker";
import API from "../../api";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import { base_url_images, realImageURL } from "../../api";
import IconButton from "@material-ui/core/IconButton";
import ProductColor from "./ProductColors/ProductColor";
import { CircularProgress, ClickAwayListener } from "@material-ui/core";
import { getMoneda } from "../../helpers/moneda";
// styles
import "./custom.css";
import "./input.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import AlekoContext from "../../context/ContextoAleko/AlekoContext";
import ColorPickerContext from "../../context/ColorPickerContext/ColorPickContext";
import ProductSize from "./ProductSizes/ProductSize";
import ProductPersonalization from "./ProductPersonalization/ProductPersonalization";

// Validaciones de salas
import { isAleko } from "../../helpers/isAleko";
import { isCmi } from "../../helpers/isCmi";

// components
import NuevoNivel from "./Components/NuevoNivel";
import LocalLeves from "./Components/LocalLeves";
import Merma_prodChild from "./Components/Merma";
import MermaContext from "../../context/MermaContext/MermaContext";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";
import { isGerenteMercadeo } from "../../helpers/isGerenteMercadeo";
import NumberFormat from "react-number-format";

const sortAlphabetically = (array, field) => {
  let sortedArray = array.sort(function (a, b) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
};

let sale_units = [
  {
    value: "15 Pack",
    nombre: "15 Pack",
  },
  {
    value: "6 Pack",
    nombre: "6 Pack",
  },
  {
    value: "Arroba",
    nombre: "Arroba",
  },
  {
    value: "Caja",
    nombre: "Caja",
  },
  {
    value: "Docena",
    nombre: "Docena",
  },
  {
    value: "Fardo",
    nombre: "Fardo",
  },
  {
    value: "Paquete",
    nombre: "Paquete",
  },
  {
    value: "Unidad",
    nombre: "Unidad",
  },
];
sale_units = sortAlphabetically(sale_units, "nombre");

const bills = [
  { flag_impuesto_honduras: "Exonerado", valor_impuesto_honduras: 0 },
  { flag_impuesto_honduras: "Exento", valor_impuesto_honduras: 0 },

  {
    flag_impuesto_honduras: "Gravado (15%)",
    valor_impuesto_honduras: 15,
  },
  {
    flag_impuesto_honduras: "Gravado (18%)",
    valor_impuesto_honduras: 18,
  },
];

export default function Users_all(props) {
  const moneda = getMoneda();
  let history = useHistory();
  const AutosuggestHighlightMatch = require("autosuggest-highlight/match");
  const AutosuggestHighlightParse = require("autosuggest-highlight/parse");
  const [unidadVenta, setUnidadVenta] = useState("");
  const {
    aleko,
    getAleko,
    colors,
    saveProductColor,
    productColor,
    setProductColor,
    clearProductColor,
    productSize,
    setProductSize,
    saveProductSize,
    clearProductSize,
    sizes,
    personalizations,
    saveProductPersonalization,
    setProductPersonalization,
    productPersonalization,
    clearProductPersonalization,
    createAlekoProduct,
    product,

    createBase,
    setCantidad,
    inputColorValidation,
    inputSizeValidation,
    inputPersonalizationValidation,
    cantidad,
    setInputColorValidation,
    setInputSizeValidation,
    setInputPersonalizationValidation,
  } = useContext(AlekoContext);
  const { showColorPicker, setShowColorPicker } =
    useContext(ColorPickerContext);
  const [alekoMode, setAlekoMode] = useState(false);

  const [imageAlekoInputs, setImageAlekoInput] = useState([
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
  ]);

  const [alekoFiles, setAlekoFiles] = useState([null, null, null, null]);
  const [storeId, setStoreId] = useState({});

  const [loader, setLoader] = useState(true);
  const [itemQuantity, setItemQuantity] = useState(1);
  const [categories, setCategories] = useState([]);
  const [empaques, setEmpaques] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [distribuidores, setDistribuidores] = useState([]);
  const [divisiones, setDivisiones] = useState([]);
  const [contenidos, setContenidos] = useState([]);
  const [familias, setFamilias] = useState([]);
  const { register, handleSubmit } = useForm({ shouldUnregister: false });
  const [selectedTab, setSelectedTab] = useState(0);
  const [foundSupply, setFoundSupply] = useState(null);
  const [imageInputs, setImageInput] = useState([
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
  ]);

  const [files, setFiles] = useState([null, null, null, null, null]);
  const [expire_lote, setExpire_lote] = useState(false);
  const [ubicaciones_ids, setUbicaciones_ids] = useState([]);
  const [empaquesFilterText, setEmpaquesFilterText] = useState("");
  const [categoriesFilterText, setCategoriesFilterText] = useState("");
  const [empaquesSuggestions, setEmpaquesSuggestions] = useState([]);
  const [categoriesSuggestions, setCategoriesSuggestions] = useState([]);
  const [discountPriceEnabled, setDiscountPriceEnabled] = useState(false);
  const [lowStockNotification, setLowStockNotification] = useState(false);
  const [productoCompuesto, setProductoCompuesto] = useState(false);
  const [suppliesSuggestions, setSuppliesSuggestions] = useState([]);
  const [allSupplies, setAllSupplies] = useState([]);
  const [supplyFilterText, setSupplyFilterText] = useState("");
  const [selected_products, setSelectedProducts] = useState([]);
  const [IDPCheck, setIDPCheck] = useState(false);
  const [freePriceCheck, setFreePriceCheck] = useState(false);
  const [IDPdata, setIDPdata] = useState([]);

  const {
    niveles_precio,
    setNivelesPrecio,
    newPrecioVenta,
    setNewPrecioVenta,
    activarPrecioVentas,
    setActiovarPrecioVenta,
    savedNiveles,
    setSavedNiveles,
    createSubProd,
    setCreateSubProd,
    createNivel,
    setCreateNivel,
    newNivel,
    setNewNivel,
    nivelesLocales,
    setNivelesLocales,
    firstClick,
    setFirstClick,
    createPriceLevels,
    clearCmiState,
    listSubniveles,
  } = useContext(CmiProductContext);
  const { handleChange, form, valorMerma, clearState } =
    useContext(MermaContext);

  // productos hijo
  const [parameters, setParameters] = useState(0);

  //priceFormater
  const [priceValue, setPriceValue] = useState("");
  const [descuentoValue, setDescuentoValue] = useState(0);
  const [costoValue, setCostoValue] = useState("");
  const [dataDescriptionproduct, setDatadescriptionProduct] = useState("");
  const [stock, setStock] = useState(0);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
      clearState();
      clearCmiState();
    };
  }, []);

  const handleClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    getParameters();
    window.localStorage.removeItem("newNivel");
  }, []);

  useEffect(() => {
    if (parameters > 0) {
      getNivelesCliente();
    }
  }, [parameters]);

  useEffect(() => {
    if (!discountPriceEnabled) {
      setDescuentoValue(0);
    }
  }, [discountPriceEnabled]);

  useEffect(() => {
    API.products
      .getProductAttributes({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCategories(response.data.categories);
          setEmpaques(response.data.empaques);
          setMarcas(response.data.marcas);
          setDistribuidores(response.data.distributors);
          setContenidos(response.data.contenidos);
          setFamilias(response.data.familias);
          getSupplies();
          getIDPdata();
          // setStoreId(response.data.divisions[0].id_linea_web_app);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
        setLoader(false);
      });

    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log("respuesta", response);
        if (response.success && JSON.stringify(response.data) != "[]") {
          setDivisiones(response.data);
          setStoreId(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  }, []);

  useEffect(() => {
    getAleko();
  }, []);

  const redirecttocreateIDP = () => {
    //console.log(window.location.origin)
    //window.location = window.location.origin + '/app/config'+'?addIDP=true'
    let noData = IDPdata.length <= 0;
    if (noData) {
      history.push({
        pathname: "/app/config",
        search: "?addIDP=true",
      });
    }
  };

  useEffect(() => {
    if (isGerenteMercadeo() && !history.location.state) {
      history.goBack();
    }
  }, []);
  const getIDPdata = () => {
    API.userConfig
      .getIDP()
      .then((res) => {
        //console.log(res)
        if (res.status === 200) {
          let data = res.data.data;
          //console.log(data)
          setIDPdata(data);
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("Ha ocurrido un error al obtener los datos", {
          autoClose: 10000,
        });
      });
  };

  const onSubmit = async (data) => {
    setLoader(true);
    let invalidForm = false;

    data.descripcion = dataDescriptionproduct;
    //convertir los datos de precio en formato numerico
    data.precio_venta = data.precio_venta
      ? data.precio_venta.replaceAll(",", "")
      : priceValue.replaceAll(",", "");
    data.costo_unitario = data.costo_unitario
      ? data.costo_unitario.replaceAll(",", "")
      : costoValue.replaceAll(",", "");
    data.descuento_precio = data.descuento_precio
      ? data.descuento_precio.replaceAll(",", "")
      : descuentoValue.toString().replaceAll(",", "");

    if (data.costo_unitario == 0 && !isCmi()) {
      toast.error("El costo del producto no puede ser 0", {
        autoClose: 10000,
      });
      setLoader(false);
      invalidForm = true;
      return;
    }

    if (descuentoValue) {
      if (discountPriceEnabled) {
        if (descuentoValue <= 0) {
          setLoader(false);
          invalidForm = true;
          toast.error("El precio de descuento no puede ser 0", {
            autoClose: 10000,
          });
        }

        if (
          data.descuento_precio &&
          parseFloat(data.descuento_precio) > parseFloat(data.precio_venta)
        ) {
          invalidForm = true;
          setLoader(false);
          toast.error(
            "El precio de descuento no puede ser mayor que el precio de venta.",
            {
              autoClose: 10000,
            }
          );
        }
      }
    }

    if (alekoMode) {
      data.id_linea_web_app = storeId;
      if (data.id_linea_web_app === "") {
        invalidForm = true;
        toast.error("Debe introducir la tienda", {
          autoClose: 10000,
        });
      }
      if (IDPCheck) {
        data.idp = 1;
      } else {
        data.idp = 0;
      }
      if (freePriceCheck) {
        data.precio_libre = 1;
      } else {
        data.precio_libre = 0;
      }

      if (!invalidForm) {
        data.costo_unitario = parseFloat(data.costo_unitario);
        if (data.descuento_precio) {
          data.descuento_precio = parseFloat(data.descuento_precio);
        } else {
          delete data.descuento_precio;
        }
        data.precio_venta = parseFloat(data.precio_venta);

        try {
          createAlekoProduct({ ...product, ...data }, alekoFiles, $("#save"));
        } catch (error) {
          console.log(error);
        }
      }

      return;
    }
    if (
      data.descuento_precio &&
      parseFloat(data.descuento_precio) > parseFloat(data.precio_venta)
    ) {
      invalidForm = true;
      toast.error(
        "El precio de descuento no puede ser mayor que el precio de venta.",
        {
          autoClose: 10000,
        }
      );
      setLoader(false);
    }
    if (parseFloat(data.precio_venta) < parseFloat(data.costo_unitario)) {
      invalidForm = true;
      toast.error(
        "El costo del producto no puede ser mayor que el precio de venta.",
        {
          autoClose: 10000,
        }
      );
      setLoader(false);
    }
    if (IDPCheck) {
      data.idp = 1;
    } else {
      data.idp = 0;
    }
    if (freePriceCheck) {
      data.precio_libre = 1;
    } else {
      data.precio_libre = 0;
    }

    if (productoCompuesto) {
      data.usa_insumo = 1;
      data.insumos = JSON.stringify(selected_products);

      if (data.codigo_receta === "") {
        invalidForm = true;
        toast.error("Debe introducir el código de la receta.", {
          autoClose: 10000,
        });
        setLoader(false);
      }
    }

    if (!invalidForm) {
      data.costo_unitario = parseFloat(data.costo_unitario);
      if (data.descuento_precio) {
        data.descuento_precio = parseFloat(data.descuento_precio);
      } else {
        delete data.descuento_precio;
      }
      data.precio_venta = parseFloat(data.precio_venta);
      data.cantidad_peso = parseFloat(Math.abs(data.cantidad_peso));

      if (isCmi()) {
        data.merma = form.merma;
        data.cantidad_merma = form.cantidad_merma;
        data.medida_merma = form.medida_merma;
        data.valor_merma = valorMerma;
        data.precio_venta = parseFloat(priceValue.replaceAll(",", ""));
        data.precio_descuento = parseFloat(
          descuentoValue.toString().replaceAll(",", "")
        );
        data.stock = Math.abs(data.cantidad_unidades);
        data.cmi = isCmi() ? true : false;
        if (unidadVenta === "libras") {
          data.stock = data.cantidad_peso;
          data.cantidad_peso = null;
        }
        if (unidadVenta === "unidades") {
          data.cantidad_peso = null;
        }
      } else {
        data.stock = data.stock ? data.stock.replaceAll(",", "") : 0.0;
        data.stock = parseFloat(data.stock);
      }

      data.stock_minimo = !data.stock_minimo ? 0 : data.stock_minimo;

      if (unidadVenta === "libras" && data.codigo.length !== 6) {
        toast.warn("El código SKU debe ser de 6 digitos", { autoClose: 10000 });
        setLoader(false);
        return;
      }

      if (isCmi() && moneda === "L") {
        data = { ...data, ...impuesto };
      }
      if (isGerenteMercadeo()) {
        data.sucursales = JSON.stringify(
          history.location.state.selectedSucursals
        );
        data.id_new_empresa = history.location.state.id_new_empresa;
        data.id_linea_web_app = divisiones[0].id_sucursal_bodega_ubicacion;
      }
      setLoader(true);
      API.products
        .createNew(data, files)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            createPriceLevels(response.data.id_new_item);
            $("#save").modal();
            setLoader(false);
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
            setLoader(false);
          }
        })
        .catch((err) => {
          let response = err.response;
          setLoader(false);
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            } else {
              toast.error("Ha ocurrido un error al crear el producto.", {
                autoClose: 10000,
              });
            }
          } else {
            toast.error(
              "Ha ocurrido un error a nivel de base de datos. Presiona F12 y revisa la consola para más información.",
              {
                autoClose: 10000,
              }
            );
          }
          console.log("Ha ocurrido un error", response, response.data);
        });
    }
  };
  const setImage = (e, img_index) => {
    if (typeof e.target.files[0] !== "undefined") {
      let image = URL.createObjectURL(e.target.files[0]);
      let image_file = e.target.files[0];
      let newData = [...imageInputs];
      let newData_2 = [...files];
      newData_2[img_index] = image_file;
      newData[img_index] = image;
      setFiles(newData_2);
      setImageInput(newData);
    }
  };
  const setAlekoImage = (e, img_index) => {
    if (typeof e.target.files[0] !== "undefined") {
      let image = URL.createObjectURL(e.target.files[0]);
      let image_file = e.target.files[0];
      let newData = [...imageAlekoInputs];
      let newData_2 = [...alekoFiles];
      newData_2[img_index] = image_file;
      newData[img_index] = image;
      setAlekoFiles(newData_2);
      setImageAlekoInput(newData);
    }
  };
  const deleteImage = (img_index) => {
    let newData = [...imageInputs];
    newData[img_index] = "/images/photo_main.png";
    setImageInput(newData);
  };
  const lote_expiredDate = (e) => {
    setExpire_lote(!expire_lote);
  };

  const getSuggestions = (type) => {
    if (type === "category") {
      const inputValue = document
        .getElementById("category_query")
        .value.trim()
        .toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0
        ? []
        : categories
            .filter((category) =>
              category.descripcion.toLowerCase().includes(inputValue)
            )
            .slice(0, 5);
    } else if (type === "empaque") {
      const inputValue = document
        .getElementById("empaque_query")
        .value.trim()
        .toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0
        ? []
        : empaques
            .filter((empaque) =>
              empaque.descripcion.toLowerCase().includes(inputValue)
            )
            .slice(0, 5);
    } else if (type === "insumo") {
      const inputValue = document
        .getElementById("supply_query")
        .value.trim()
        .toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0
        ? []
        : allSupplies
            .filter(function (insumo) {
              if (insumo.nombre.toLowerCase().includes(inputValue)) {
                return insumo;
              }
              if (insumo.codigo.toLowerCase().includes(inputValue)) {
                return insumo;
              }
            })
            .slice(0, 5);
    }
  };

  const getCategoriesSuggestionValue = (suggestion) => {
    document.getElementById("id_clasificacion1_web_app").value =
      suggestion.id_clasificacion1_web_app;
    return suggestion.descripcion;
  };

  const getEmpaquesSuggestionValue = (suggestion) => {
    document.getElementById("id_unidad_medida_web_app").value =
      suggestion.id_unidad_medida_web_app;
    return suggestion.descripcion;
  };

  const getSuppliesSuggestionValue = (suggestion) => {
    setFoundSupply(suggestion);
    return suggestion.nombre;
  };

  const renderSuggestion = (suggestion) => {
    const matches = AutosuggestHighlightMatch(
      suggestion.descripcion,
      categoriesFilterText
    );
    const parts = AutosuggestHighlightParse(suggestion.descripcion, matches);

    return (
      <div>
        {parts.map((part, index) => {
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  const renderSuppplySuggestion = (suggestion) => {
    const matches = AutosuggestHighlightMatch(
      suggestion.nombre,
      categoriesFilterText
    );

    const parts = AutosuggestHighlightParse(
      suggestion.nombre + " - " + suggestion.codigo,
      matches
    );

    return (
      <div>
        {parts.map((part, index) => {
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  const changeCategoryFilterText = (e, { newValue }) => {
    setCategoriesFilterText(newValue);
  };

  const changeEmpaquesFilterText = (e, { newValue }) => {
    setEmpaquesFilterText(newValue);
  };

  const changeSupplyFilterText = (e, { newValue }) => {
    setSupplyFilterText(newValue);
  };

  const onSuggestionsFetchRequested = (type) => {
    if (type === "category") {
      setCategoriesSuggestions(getSuggestions("category"));
    } else if (type === "empaque") {
      setEmpaquesSuggestions(getSuggestions("empaque"));
    } else if (type === "insumo") {
      setSuppliesSuggestions(getSuggestions("insumo"));
    }
  };

  const onSuggestionsClearRequested = (type) => {
    if (type === "category") {
      setCategoriesSuggestions([]);
    } else if (type === "empaque") {
      setEmpaquesSuggestions([]);
    } else if (type === "insumo") {
      setSuppliesSuggestions([]);
    }
  };

  const categoriesInputProps = {
    placeholder: "Escribe el nombre de la categoría",
    value: categoriesFilterText,
    onChange: changeCategoryFilterText,
    id: "category_query",
  };

  const empaquesInputProps = {
    placeholder: "Escribe el tipo de empaque",
    value: empaquesFilterText,
    onChange: changeEmpaquesFilterText,
    id: "empaque_query",
  };

  const suppliesInputProps = {
    placeholder: "Ingrese el SKU del insumo.",
    value: supplyFilterText,
    onChange: changeSupplyFilterText,
    id: "supply_query",
  };

  const handleDiscountCheck = () => {
    setDiscountPriceEnabled(!discountPriceEnabled);
  };

  const handleLowStockNotification = () => {
    setLowStockNotification(!lowStockNotification);
  };

  const handleTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getSupplies = () => {
    let id_sucursal_bodega_ubicacion = JSON.parse(
      localStorage.getItem("id_sucursal")
    );

    API.supplies
      .getAll({
        id_sucursal_bodega_ubicacion,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Im obtaining these insumos:", response.data);
          setAllSupplies(response.data.insumos);
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        console.log("Ha ocurrido un error / Búsqueda de insumos");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const unselectProduct = () => {
    setFoundSupply(null);
  };

  const selectProduct = () => {
    let already_selected = [...selected_products];
    let currentIndex = already_selected.findIndex(
      (x) => x.id_new_item === foundSupply.id_new_item
    );

    if (already_selected[currentIndex]) {
      toast.error("El insumo ya fue seleccionado para el producto.", {
        autoClose: 10000,
      });
      return;
    }

    already_selected.push({
      id_new_item: foundSupply.id_new_item,
      cantidad: parseFloat(itemQuantity),
      nombre: foundSupply.nombre,
      codigo: foundSupply.codigo,
      unidad: foundSupply.unidad,
    });
    setSelectedProducts(already_selected);
    setItemQuantity(1);
    setFoundSupply(null);
  };

  const remove_input = (i) => {
    setSelectedProducts(selected_products.filter((e, index) => index !== i));
  };

  // tipos de precios
  const handleChangePrecioUnico = (event) => {
    const { name, value } = event.target;
    setNewPrecioVenta({
      ...newPrecioVenta,
      [name]: +value,
    });
  };

  const handleNewNivel = (event) => {
    console.log(newNivel);
    const { name, value } = event.target;
    setNewNivel({
      ...newNivel,
      [name]: name === "precio_venta" ? value.replaceAll(",", "") : value,
    });
  };

  const getNivelesCliente = async () => {
    try {
      const response = await API.userConfig.getNiveles(parameters);
      console.log("Niveles", response.data.costumer);
      setSavedNiveles(response.data.costumer);
    } catch (error) {
      console.log("Error al buscar niveles", error);
      toast.error("Error al buscar niveles", { autoClose: 10000 });
    }
  };

  const savedNivel = () => {
    const user_tipe = listSubniveles?.find(
      (item) => item.id === +newNivel.user_tipe
    )?.subnivel;
    let valid = true;
    setCreateNivel(true);
    setFirstClick(false);
    if (!firstClick) {
      if (newNivel.user_tipe == "none") {
        toast.error("Debe escoger un tipo de usuario", { autoClose: 10000 });
        valid = false;
      }

      if (newNivel.unid_venta == "none") {
        toast.error("Debe escoger una unidad de venta", { autoClose: 10000 });
        valid = false;
      }

      if (parseFloat(newNivel.precio_venta.replaceAll(",", "")) <= 0) {
        toast.error("Debe escoger un precio de producto", { autoClose: 10000 });
        valid = false;
      }
      if (
        nivelesLocales?.some(
          (x) =>
            x.unid_venta === newNivel.unid_venta && x.user_tipe === user_tipe
        )
      ) {
        toast.error("Ya existe un nivel de precio con esas características", {
          autoClose: 10000,
        });
        valid = false;
      }
    }

    if (valid && !firstClick) {
      // agregar newNivel al data para enviar
      console.log(newNivel);
      let guardado = obtenerNivelesLocalStorage();
      newNivel.id_nivel = newNivel.user_tipe;
      newNivel.precio_venta = parseFloat(
        newNivel.precio_venta.replaceAll(",", "")
      );

      newNivel.user_tipe = user_tipe;
      if (guardado == null) {
        newNivel.id = 1;
        guardado = [newNivel];
      } else {
        newNivel.id = guardado.length + 1;
        guardado = [...guardado, newNivel];
      }
      window.localStorage.setItem("newNivel", JSON.stringify(guardado));
      toast.success("Guardado exitosamente", { autoClose: 10000 });
      setCreateNivel(false);
      setNewNivel({
        user_tipe: "none",
        unid_venta: "none",
        precio_venta: 0,
      });
      setNivelesLocales(guardado);
      setFirstClick(true);
    }
  };

  const obtenerNivelesLocalStorage = () =>
    JSON.parse(window.localStorage.getItem("newNivel"));

  const handleChageTiposPrecios = (event) => {
    const { name, value } = event.target;
    window.localStorage.setItem(name, value);
    setNivelesPrecio(value);
  };

  const deleteNivelesLocalStorage = (id) => {
    let guardado = obtenerNivelesLocalStorage();
    if (guardado != null) {
      let eliminado = guardado.filter((item) => item.id != id);
      window.localStorage.setItem("newNivel", JSON.stringify(eliminado));
      setNivelesLocales(eliminado);
    } else {
      setNivelesLocales([]);
    }
  };

  const handleColorChange = (e) => {
    setProductColor({ ...productColor, [e.target.name]: e.target.value });
  };
  const handleProductSizeChange = (e) => {
    setProductSize({ ...productSize, [e.target.name]: e.target.value });
  };
  const handleProductPersonalizationChange = (e) => {
    setProductPersonalization({
      ...productPersonalization,
      [e.target.name]: e.target.value,
    });
  };

  const getParameters = async () => {
    let respuesta = await API.userConfig.get_parameters();
    let response = await respuesta.data;

    if (response.success) {
      console.log("GETTING THIS PARAMETERS", response.data);
      if (response.data) setParameters(response.data.id_new_empresa);
    } else {
      if (response.message) {
        toast.error(response.message, { autoClose: 10000 });
      } else {
        toast.warning("Ha ocurrido un error al obtener los parametros", {
          autoClose: 10000,
        });
      }
    }
  };
  const [impuesto, setImpuesto] = useState(bills[0]);
  const handleImpuesto = (e) => {
    const currentBill = bills.find(
      (item) => item.flag_impuesto_honduras === e.target.value
    );
    setImpuesto(currentBill);
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div className="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardado.</p>

              <div className="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="save" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Su producto se ha <br />
                    guardado con éxito
                  </strong>
                </h4>
              </div>
              {isGerenteMercadeo() && (
                <ul>
                  {"En las siguientes sucursales: "}
                  {history?.location?.state?.nombres?.map((x) => (
                    <li style={{ listStyleType: "none" }}>
                      {x.nombre_sucursal}
                    </li>
                  ))}
                </ul>
              )}

              <div className="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Agregar producto</strong>
        </h4>
        {!isGerenteMercadeo() && (
          <select
            className="form-select"
            disabled={!isAleko()}
            onChange={(e) => setStoreId(e.target.value)}
            value={storeId}
          >
            {divisiones.map((division) => (
              <option selected value={division.id_sucursal_bodega_ubicacion}>
                {division.nombre_sucursal}
              </option>
            ))}
          </select>
        )}
        {isAleko() && (
          <div className="ml-auto">
            <h6
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
                margin: 0,
                marginBottom: 5,
              }}
            >
              <strong>
                {alekoMode ? "¿Producto regular?" : "¿Producto personalizable?"}
              </strong>
            </h6>
            <Button
              type="button"
              className="btn-cs1_blue"
              variant="contained"
              color="primary"
              onClick={() => {
                setAlekoMode(!alekoMode);
                createBase(+storeId);
              }}
            >
              Cambiar formato
            </Button>
          </div>
        )}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autocomplete="on"
        encType="multipart/form-data"
      >
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Crear nuevo producto</strong>
                </h5>
                <p className="mb-0">
                  Agregue manualmente un nuevo producto a su inventario.
                </p>
              </div>

              <div>
                <Button
                  type="submit"
                  className="btn-cs1_blue mx-1 mt-1"
                  variant="contained"
                  color="primary"
                  disabled={loader}
                >
                  {loader ? <CircularProgress size={26} /> : "Guardar"}
                </Button>
                <Button
                  className="btn-cs1 mx-1 mt-1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmExit"
                >
                  Cancelar
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <Tabs
                className="mt-2"
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTab}
              >
                <Tab className="tab" label="General" />
                {/*isCmi() && <Tab className="tab" label="Prod. Hijos" />*/}

                {alekoMode ? (
                  // <Tab className="tab" label="Características" />
                  <></>
                ) : (
                  !isCmi() &&
                  !isAleko() && <Tab className="tab" label="Insumos" />
                )}
                {isCmi() && <Tab className="tab" label="Mermas" />}
              </Tabs>
            </div>
          </div>
          <div className="card-body">
            <TabPanel value={selectedTab} index={0}>
              <div className="row mb-4">
                <div className="col-md-4 mt-3 ">
                  <div>
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Nombre del producto</strong>
                    </label>

                    <TextField
                      inputRef={register}
                      required
                      name="nombre"
                      type="text"
                      className="w-100"
                      inputProps={{ maxLength: 40 }}
                      placeholder="Ingrese el nombre de su producto"
                    />
                  </div>
                </div>

                {isCmi() ? (
                  <div className="col-md-4 mt-3 border">
                    <div className="row w-100 justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Vendido por</strong>
                      </label>
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </div>
                    <div className="w-100 row justify-content-start align-items-center border">
                      <FormControl variant="outlined" className="w-100">
                        <Select
                          inputRef={register}
                          onChange={(e) => setUnidadVenta(e.target.value)}
                          native
                          name="vendido_por"
                          required
                          className="cs-select1 select-w100"
                        >
                          <option value="">
                            Seleccionar la unidad de venta
                          </option>
                          <option value="libras">Libras</option>
                          <option value="unidades">Unidades</option>
                          <option value="mixto">Mixto</option>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                ) : isAleko() ? null : (
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Precio con descuento</strong>
                        </label>
                      </div>
                      <div className="custom-control custom-switch">
                        <input
                          inputRef={register}
                          type="checkbox"
                          checked={discountPriceEnabled}
                          onClick={handleDiscountCheck}
                          className="custom-control-input"
                          id={`customSwitchPrice`}
                        />
                        <label
                          className="custom-control-label"
                          for={`customSwitchPrice`}
                        />
                      </div>
                    </div>
                    <TextField
                      inputRef={register}
                      type="text"
                      className="w-100"
                      disabled={!discountPriceEnabled}
                      placeholder="Ingrese el precio promocional del producto"
                      name="descuento_precio"
                      value={descuentoValue}
                      onChange={(event) => {
                        setDescuentoValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                        inputProps: { min: 0, step: "0.00001" },
                      }}
                    />
                    <small>
                      El precio con descuento sustituirá el precio original de
                      venta, puede activar y desactivar esta opción en cualquier
                      momento.
                    </small>
                  </div>
                )}

                {isCmi() ? (
                  <div className="col-md-4 mt-3 border">
                    <div className="row w-100 justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Vendido por</strong>
                      </label>
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </div>
                    <div className="w-100 row justify-content-start align-items-center border">
                      <FormControl variant="outlined" className="w-100">
                        <Select
                          inputRef={register}
                          onChange={(e) => setUnidadVenta(e.target.value)}
                          native
                          name="vendido_por"
                          required
                          className="cs-select1 select-w100"
                        >
                          <option value="">
                            Seleccionar la unidad de venta
                          </option>
                          <option value="libras">Libras</option>
                          <option value="unidades">Unidades</option>
                          <option value="mixto">Mixto</option>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                ) : null}

                <div className="col-md-4 mt-3 ">
                  {isCmi() ? (
                    <div>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Código del producto</strong>
                      </label>
                      <TextField
                        inputRef={register}
                        type={unidadVenta === "libras" ? "number" : "text"}
                        required
                        className="w-100"
                        name="codigo"
                        onChange={(event) =>
                          event.target.value < 0
                            ? (event.target.value = -event.target.value)
                            : event.target.value
                        }
                        placeholder="Ingreso el SKU/Cod. barras"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">N°</InputAdornment>
                          ),
                          inputProps: { min: 0, step: "0.00001" },
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Precio de venta</strong>
                      </label>
                      <TextField
                        inputRef={register}
                        text="text"
                        required
                        className="w-100"
                        name="precio_venta"
                        value={priceValue}
                        onChange={(e) => {
                          const t = e.target.value;
                          e.target.value =
                            t.indexOf(".") >= 0
                              ? t.substr(0, t.indexOf(".")) +
                                t.substr(t.indexOf("."), 3)
                              : t;
                          setPriceValue(formaterPriceFunction(e.target.value));
                        }}
                        placeholder="Ingrese el precio de venta de su producto"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {moneda}.
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                </div>

                {/* Second Row */}
                <div className="col-md-4 mt-3 ">
                  <label style={{ fontSize: "0.8rem" }}>
                    {!isCmi() ? (
                      <strong>Descripción de producto (Opcional)</strong>
                    ) : (
                      <strong>Código SAP</strong>
                    )}
                  </label>
                  {isCmi() ? (
                    <div className="form-group">
                      <textarea
                        name="descripcion"
                        style={{ resize: "none", backgroundColor: "#f2f2f2" }}
                        class="form-control"
                        onChange={(e) => {
                          setDatadescriptionProduct(e.target.value);
                        }}
                        inputRef={register}
                        placeholder="Ingrese el Código SAP ejemplo: 29000430"
                        rows="3"
                        inputProps={{ maxLength: 120 }}
                      ></textarea>
                    </div>
                  ) : (
                    <TextField
                      name="descripcion"
                      onChange={(e) => {
                        setDatadescriptionProduct(e.target.value);
                      }}
                      inputRef={register}
                      inputProps={{ maxLength: 120 }}
                      type="text"
                      multiline
                      className="w-100"
                      placeholder="Ingrese una breve descripción del producto."
                    />
                  )}
                </div>
                {isCmi() ? (
                  <div className="col-md-8 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Tipo de precio</strong>
                    </label>
                    <div className="w-100 h-75 mx-auto row justify-content-around align-items-center">
                      <div
                        className={`tipos_precios ${
                          niveles_precio === "precio_unico"
                            ? "tipos_precios_active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="nivel_precio"
                          inputRef={register}
                          id="precio_unico"
                          checked={niveles_precio == "precio_unico"}
                          value="precio_unico"
                          onChange={(e) => handleChageTiposPrecios(e)}
                        />
                        <label htmlFor="precio_unico">Precio único</label>
                      </div>
                      <div
                        className={`tipos_precios ${
                          niveles_precio == "niveles_precio"
                            ? "tipos_precios_active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="nivel_precio"
                          id="niveles_precio"
                          checked={niveles_precio === "niveles_precio"}
                          inputRef={register}
                          value="niveles_precio"
                          onChange={(e) => handleChageTiposPrecios(e)}
                        />
                        <label htmlFor="niveles_precio">
                          Niveles de precio
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Código del producto</strong>
                      </label>
                      <Tooltip title="Ingrese el código de barras del producto que identifica al producto.">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      name="codigo"
                      inputRef={register}
                      type="text"
                      required
                      className="w-100"
                      placeholder="Ingrese el SKU / código de barras del producto"
                    />
                  </div>
                )}

                {niveles_precio.length > 0 && (
                  <div className="col-md-12 row justify-content-end">
                    {niveles_precio == "precio_unico" ? (
                      <Fragment>
                        <div className="col-md-2"></div>
                        <div className="col-md-6 row">
                          <div className="col-md-6">
                            <label style={{ fontSize: "0.8rem" }}>
                              <strong>Precio de venta</strong>
                            </label>
                            <TextField
                              type="text"
                              required
                              className="w-100"
                              name="precio_venta"
                              value={priceValue}
                              onChange={
                                (event) => {
                                  const t = event.target.value;
                                  event.target.value =
                                    t.indexOf(".") >= 0
                                      ? t.substr(0, t.indexOf(".")) +
                                        t.substr(t.indexOf("."), 3)
                                      : t;
                                  setPriceValue(
                                    formaterPriceFunction(event.target.value)
                                  );
                                  return handleChangePrecioUnico(event);
                                }

                                // event.target.value < 0
                                //     ? (event.target.value = -event
                                //         .target.value)
                                //     : event.target.value
                              }
                              defaultValue={newPrecioVenta.precio_venta}
                              placeholder="Ingrese el precio de venta del producto"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {moneda}.
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, step: "0.00001" },
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            {/* <div className="row justify-content-around">
                              <label style={{ fontSize: "0.8rem" }}>
                                <strong>Precio con descuento</strong>
                              </label>
                              <Tooltip
                                title="Precio con descuento"
                                className="mx-1"
                              >
                                <InfoIcon
                                  style={{
                                    color: " #828282",
                                    fontSize: "1.2rem",
                                  }}
                                />
                              </Tooltip>
                            </div> */}
                            {/* <TextField
                              type="number"
                              required
                              className="w-100"
                              defaultValue={
                                newPrecioVenta.precio_venta_descuento
                              }
                              disabled={
                                activarPrecioVentas == false ? true : false
                              }
                              value={descuentoValue}
                              name="precio_venta_descuento"
                              onChange={
                                (event) => {
                                  const t = event.target.value;
                                  event.target.value =
                                    t.indexOf(".") >= 0
                                      ? t.substr(0, t.indexOf(".")) +
                                        t.substr(t.indexOf("."), 3)
                                      : t;
                                  setDescuentoValue(
                                    formaterPriceFunction(event.target.value)
                                  );

                                  handleChange(event);
                                  handleChangePrecioUnico(event);
                                }
                              }
                              placeholder="Ingrese el precio promocional"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {moneda}.
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, step: "0.00001" },
                              }}
                            /> */}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-around col-md-2">
                          {/* <Button
                            style={{
                              width: "50%",
                              height: "50%",
                            }}
                            onClick={(e) => setActiovarPrecioVenta(false)}
                            variant="contained"
                            size="small"
                            color={
                              activarPrecioVentas == false ? "primary" : ""
                            }
                          >
                            No
                          </Button>
                          <Button
                            style={{
                              width: "50%",
                              height: "50%",
                            }}
                            onClick={(e) => setActiovarPrecioVenta(true)}
                            variant="contained"
                            size="small"
                            color={
                              activarPrecioVentas == false ? "" : "primary"
                            }
                          >
                            Sí
                          </Button> */}
                        </div>
                      </Fragment>
                    ) : (
                      ""
                    )}
                    {niveles_precio == "niveles_precio" ? (
                      <div className="col-md-12 row">
                        <div className="col-md-3 col-sm-2 d-flex justify-content-center align-items-center">
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={savedNivel}
                            size="small"
                            style={{
                              background: !createNivel ? "" : "#4cd137",
                            }}
                          >
                            {!createNivel ? "Nuevo Nivel" : "Guardar nivel"}
                          </Button>
                        </div>
                        <div className="col-md-9 p-0">
                          {createNivel && (
                            <NuevoNivel
                              setFirstClick={setFirstClick}
                              newNivel={newNivel}
                              setNewNivel={setNewNivel}
                              setCreateNivel={setCreateNivel}
                              handleNewNivel={handleNewNivel}
                              savedNiveles={savedNiveles}
                            />
                          )}
                          {nivelesLocales &&
                            nivelesLocales.map((item) => (
                              <LocalLeves
                                key={item.id}
                                id={item.id}
                                deleteNivelesLocalStorage={
                                  deleteNivelesLocalStorage
                                }
                                precio={item.precio_venta}
                                unidad_venta={item.unid_venta}
                                userType={item.user_tipe}
                              />
                            ))}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {isCmi() ? (
                  ""
                ) : (
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Unidad de venta</strong>
                      </label>
                      <Tooltip title="Seleccione el modo de venta. Ejemplo: Unidad, docena, etc.">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>

                    <FormControl variant="outlined" className="w-100">
                      <Select
                        inputRef={register}
                        native
                        name="vendido_por"
                        required
                        className="cs-select1 select-w100"
                      >
                        <option value="">Seleccione la unidad de venta</option>
                        {sale_units.map((unit) => (
                          <option value={unit.value}>{unit.nombre}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                {isCmi()
                  ? ""
                  : !isAleko() && (
                      <div className="col-md-4 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>IDP</strong>
                        </label>

                        <label
                          class="toggle-control mx-auto"
                          style={{ left: "-15px", height: "40px" }}
                        >
                          <input
                            type="checkbox"
                            value={IDPCheck}
                            onClick={() => setIDPCheck(!IDPCheck)}
                          />
                          <span class="control" />
                        </label>
                      </div>
                    )}
                {alekoMode && (
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Costo unitario</strong>
                      </label>
                      <Tooltip
                        title="Costo unitario del producto"
                        className="mx-1"
                      >
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>

                    <TextField
                      inputRef={register}
                      value={costoValue}
                      type="text"
                      name="costo_unitario"
                      className="w-100"
                      placeholder="Ingrese el costo del producto."
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        const t = event.target.value;
                        event.target.value =
                          t.indexOf(".") >= 0
                            ? t.substr(0, t.indexOf(".")) +
                              t.substr(t.indexOf("."), 3)
                            : t;
                        handleChange(event);
                        setCostoValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                    />
                  </div>
                )}
                {isCmi() ? (
                  ""
                ) : (
                  <div className="col-md-4 mt-3 ">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Precio libre</strong>
                    </label>

                    <label
                      class="toggle-control mx-auto"
                      style={{ left: "-15px", height: "40px" }}
                    >
                      <input
                        type="checkbox"
                        value={freePriceCheck}
                        onClick={() => setFreePriceCheck(!freePriceCheck)}
                      />
                      <span class="control" />
                    </label>
                  </div>
                )}

                {/* Third Row */}
                {!alekoMode && (
                  <>
                    <div className="col-md-12 mt-3">
                      <label style={{ fontSize: "0.8rem" }} className="mb-0">
                        <strong>Añadir atributos</strong>
                      </label>
                      <p style={{ fontSize: "0.8rem" }} className="mb-0">
                        Rellene la siguiente información de su producto
                      </p>
                    </div>

                    {/*  Forth Row */}
                    {isCmi() ? (
                      ""
                    ) : (
                      <div className="col-md-4 mt-3">
                        <div
                          className="input-group search-bar autosuggest-search-bar w-100"
                          style={{
                            marginLeft: "unset",
                            marginRight: "unset",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Autosuggest
                            suggestions={categoriesSuggestions}
                            onSuggestionsFetchRequested={() =>
                              onSuggestionsFetchRequested("category")
                            }
                            onSuggestionsClearRequested={() =>
                              onSuggestionsClearRequested("category")
                            }
                            getSuggestionValue={getCategoriesSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={categoriesInputProps}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text autosuggest-search-icon">
                              <SearchIcon />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {isCmi() ? (
                      ""
                    ) : (
                      <div className="col-md-4 mt-3 text-center">
                        <div
                          className="input-group search-bar autosuggest-search-bar w-100"
                          style={{
                            marginLeft: "unset",
                            marginRight: "unset",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Autosuggest
                            suggestions={empaquesSuggestions}
                            onSuggestionsFetchRequested={() =>
                              onSuggestionsFetchRequested("empaque")
                            }
                            onSuggestionsClearRequested={() =>
                              onSuggestionsClearRequested("empaque")
                            }
                            getSuggestionValue={getEmpaquesSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={empaquesInputProps}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text autosuggest-search-icon">
                              <SearchIcon />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-md-4 mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Categoría del producto</strong>
                        </label>
                        <Tooltip title="Seleccione una categoría que represente al producto. Ejemplo: Embutidos.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          inputRef={register}
                          native
                          id="id_clasificacion1_web_app"
                          name="id_clasificacion1_web_app"
                          required
                          className="cs-select1 select-w100"
                        >
                          <option value="">
                            Seleccione la categoría del producto
                          </option>
                          {categories.map((category) => (
                            <option value={category.id_clasificacion1_web_app}>
                              {category.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-md-4 mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Empaque del producto</strong>
                        </label>
                        <Tooltip title="Seleccione el tipo de empaque del producto. Ejemplo: caja, enlatado, botellas, etc.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          inputRef={register}
                          native
                          name="id_unidad_medida_web_app"
                          id="id_unidad_medida_web_app"
                          required
                          className="cs-select1 select-w100"
                        >
                          <option value="">Seleccione el empaque</option>
                          {empaques.map((empaque) => (
                            <option value={empaque.id_unidad_medida_web_app}>
                              {empaque.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-md-4 mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Marca del producto</strong>
                        </label>
                        <Tooltip title="Seleccione la marca del producto. Ejemplo: Adidas.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          inputRef={register}
                          native
                          required
                          name="id_clasificacion2_web_app"
                          className="cs-select1 select-w100"
                        >
                          <option value="">Seleccione la marca</option>
                          {marcas.map((marca) => (
                            <option value={marca.id_clasificacion2_web_app}>
                              {marca.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    {/*   Fifth Row */}
                    <div className="col-md-4 mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Proveedor del producto</strong>
                        </label>
                        <Tooltip title="Seleccione el distribuidor del producto. Ejemplo: Capri, Del Monte, etc.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          inputRef={register}
                          required
                          native
                          name="id_distribuidor_web_app"
                          className="cs-select1 select-w100"
                        >
                          <option value="">Seleccione el distribuidor</option>
                          {distribuidores.map((distribuidor) => (
                            <option
                              value={distribuidor.id_distribuidor_web_app}
                            >
                              {distribuidor.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {!isGerenteMercadeo() && (
                      <div className="col-md-4 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>División del producto</strong>
                          </label>
                          <Tooltip title="Seleccione la división a la que pertenece el producto. Ejemplo: Snacks, limpieza, etc.">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>
                        <FormControl variant="outlined" className="w-100">
                          <Select
                            inputRef={register}
                            native
                            required
                            name="id_linea_web_app"
                            className="cs-select1 select-w100"
                            onChange={(e) => setStoreId(e.target.value)}
                            value={storeId}
                          >
                            <option value="">Seleccione la división</option>
                            {divisiones.map((division) => (
                              <option
                                selected
                                value={division.id_sucursal_bodega_ubicacion}
                              >
                                {division.nombre_sucursal}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}

                    <div className="col-md-4 mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Contenido del producto</strong>
                        </label>
                        <Tooltip title="Seleccione el tipo de contenido. Ejemplo: 400g, 1lb, individual, etc.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          inputRef={register}
                          native
                          required
                          name="id_contenido_web_app"
                          className="cs-select1 select-w100"
                        >
                          <option value="">Seleccione el contenido</option>
                          {contenidos.map((contenido) => (
                            <option value={contenido.id_contenido_web_app}>
                              {contenido.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </>
                )}
                {alekoMode && (
                  <>
                    <div className="col-md-12 product-views">
                      <div
                        className="row py-3 mt-3"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                      >
                        <div className="col-md-3 text-center">Frente</div>
                        <div className="col-md-3 text-center">Atras</div>
                        <div className="col-md-3 text-center">Derecha</div>
                        <div className="col-md-3 text-center">Izquierda</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="row mt-3 py-3"
                        style={{ background: "rgb(249,249,249)" }}
                      >
                        <div className="col-md-3">
                          <span className="text-center product-views-title">
                            <strong>Frente</strong>
                          </span>
                          <div className="d-flex">
                            <div
                              className="w-100"
                              style={{ position: "relative" }}
                            >
                              <HighlightOffIcon
                                className="delete-image-icon"
                                onClick={() => deleteImage(0)}
                              />
                              <img
                                src={imageAlekoInputs[0]}
                                className="add_image_bck"
                                alt="img-input"
                              />
                              <input
                                accept="image/*"
                                name="images"
                                className="add_image_btn"
                                inputRef={register}
                                type="file"
                                onChange={(event) => setAlekoImage(event, 0)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <span className="text-center product-views-title">
                            <strong>Atras</strong>
                          </span>
                          <div className="d-flex" style={{ flex: "1" }}>
                            <div
                              className="w-100"
                              style={{ position: "relative" }}
                            >
                              <HighlightOffIcon
                                className="delete-image-icon"
                                onClick={() => deleteImage(0)}
                              />
                              <img
                                src={imageAlekoInputs[1]}
                                className="add_image_bck"
                                alt="img-input"
                              />
                              <input
                                accept="image/*"
                                name="images"
                                className="add_image_btn"
                                inputRef={register}
                                type="file"
                                onChange={(event) => setAlekoImage(event, 1)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <span className="text-center product-views-title">
                            <strong>Derecha</strong>
                          </span>
                          <div className="d-flex" style={{ flex: "1" }}>
                            <div
                              className="w-100"
                              style={{ position: "relative" }}
                            >
                              <HighlightOffIcon
                                className="delete-image-icon"
                                onClick={() => deleteImage(0)}
                              />
                              <img
                                src={imageAlekoInputs[2]}
                                className="add_image_bck"
                                alt="img-input"
                              />
                              <input
                                accept="image/*"
                                name="images"
                                className="add_image_btn"
                                inputRef={register}
                                type="file"
                                onChange={(event) => setAlekoImage(event, 2)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <span className="text-center product-views-title">
                            <strong>Izquierda</strong>
                          </span>
                          <div className="d-flex" style={{ flex: "1" }}>
                            <div
                              className="w-100"
                              style={{ position: "relative" }}
                            >
                              <HighlightOffIcon
                                className="delete-image-icon"
                                onClick={() => deleteImage(0)}
                              />
                              <img
                                src={imageAlekoInputs[3]}
                                className="add_image_bck"
                                alt="img-input"
                              />
                              <input
                                accept="image/*"
                                name="images"
                                className="add_image_btn"
                                inputRef={register}
                                type="file"
                                onChange={(event) => setAlekoImage(event, 3)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!alekoMode && (
                  <>
                    <div className="col-md-4 mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Familia del producto</strong>
                        </label>
                        <Tooltip title="Seleccione la familia del producto. Ejemplo: Comidas">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          inputRef={register}
                          native
                          required
                          name="id_familia_web_app"
                          className="cs-select1 select-w100"
                        >
                          <option value="">Seleccione la familia</option>
                          {familias.map((familia) => (
                            <option value={familia.id_familia_web_app}>
                              {familia.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {isCmi() && moneda === "L" && (
                      <div className="col-md-4 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Impuesto del producto</strong>
                          </label>
                          <Tooltip title="Seleccione el impuesto del producto. Ejemplo: Exonerado">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>

                        <FormControl variant="outlined" className="w-100">
                          <Select
                            required
                            native
                            className="cs-select1 select-w100"
                            value={impuesto.flag_impuesto_honduras}
                            onChange={handleImpuesto}
                          >
                            {bills.map((bill) => (
                              <option value={bill.flag_impuesto_honduras}>
                                {bill.flag_impuesto_honduras}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}

                    {IDPCheck ? (
                      <div
                        className="col-md-4 mt-3"
                        onClick={redirecttocreateIDP}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Impuesto IDP</strong>
                          </label>
                        </div>

                        <FormControl variant="outlined" className="w-100">
                          <Select
                            inputRef={register}
                            native
                            required
                            name="id_impuesto_idp"
                            className="cs-select1 select-w100"
                          >
                            <option value="">Seleccione el IDP</option>
                            {IDPdata.map((data) => (
                              <option value={data.id_producto_idp}>
                                {data.nombre}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    ) : null}
                    <div className="col-md-8 mt-3" />

                    {/* Sixth row */}
                    {/* Add images */}
                    <div className="col-md-6 mt-3">
                      <div className="d-flex">
                        <div className="d-flex" style={{ flex: "1" }}>
                          <div
                            className="w-100"
                            style={{ position: "relative" }}
                          >
                            <HighlightOffIcon
                              className="delete-image-icon"
                              onClick={() => deleteImage(0)}
                            />
                            <img
                              src={imageInputs[0]}
                              className="add_image_bck"
                              alt="img-input"
                            />
                            <input
                              accept="image/*"
                              name="images"
                              className="add_image_btn"
                              inputRef={register}
                              type="file"
                              onChange={(event) => setImage(event, 0)}
                            />
                          </div>
                        </div>
                        <div style={{ flex: "1" }}>
                          <div className="d-flex">
                            <div
                              style={{
                                position: "relative",
                                flex: "1",
                              }}
                            >
                              <HighlightOffIcon
                                className="delete-image-icon"
                                onClick={() => deleteImage(1)}
                              />
                              <img
                                src={imageInputs[1]}
                                className="add_image_bck"
                                alt="img-input"
                              />
                              <input
                                name="images"
                                accept="image/*"
                                className="add_image_btn"
                                inputRef={register}
                                type="file"
                                onChange={(event) => setImage(event, 1)}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                flex: "1",
                              }}
                            >
                              <HighlightOffIcon
                                className="delete-image-icon"
                                onClick={() => deleteImage(2)}
                              />
                              <img
                                src={imageInputs[2]}
                                className="add_image_bck"
                                alt="img-input"
                              />
                              <input
                                name="images"
                                accept="image/*"
                                className="add_image_btn"
                                inputRef={register}
                                type="file"
                                onChange={(event) => setImage(event, 2)}
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div
                              style={{
                                position: "relative",
                                flex: "1",
                              }}
                            >
                              <HighlightOffIcon
                                className="delete-image-icon"
                                onClick={() => deleteImage(3)}
                              />
                              <img
                                src={imageInputs[3]}
                                className="add_image_bck"
                                alt="img-input"
                              />
                              <input
                                name="images"
                                accept="image/*"
                                className="add_image_btn"
                                inputRef={register}
                                type="file"
                                onChange={(event) => setImage(event, 3)}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                flex: "1",
                              }}
                            >
                              <HighlightOffIcon
                                className="delete-image-icon"
                                onClick={() => deleteImage(4)}
                              />
                              <img
                                src={imageInputs[4]}
                                className="add_image_bck"
                                alt="img-input"
                              />
                              <input
                                name="images"
                                accept="image/*"
                                className="add_image_btn"
                                inputRef={register}
                                type="file"
                                onChange={(event) => setImage(event, 4)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mt-3">
                      <label style={{ fontSize: "0.9rem" }}>
                        <strong>Datos de inventario:</strong>
                      </label>

                      {isCmi() ? (
                        <div className="mt-2 row">
                          <div className="col-md-12 h-25">
                            <label style={{ fontSize: "0.8rem" }}>
                              <strong>Inventario</strong>
                            </label>
                          </div>
                          <div className="col-md-12 h-75 d-flex justify-content-between align-items-center">
                            {unidadVenta === "mixto" ||
                            unidadVenta === "libras" ||
                            !unidadVenta ? (
                              <>
                                <div
                                  className={`${
                                    unidadVenta === "libras"
                                      ? "w-100 flex-wrap"
                                      : "w-50"
                                  }justify-content-around h-100 d-flex flex-row align-items-center`}
                                >
                                  <div
                                    className={`w-90 h-25 d-flex ${
                                      unidadVenta === "mixto"
                                        ? "justify-content-center"
                                        : "justify-content-start"
                                    } align-items-center`}
                                  >
                                    <label style={{ fontSize: "0.8rem" }}>
                                      <strong>
                                        Cantidad (
                                        {unidadVenta === "libras"
                                          ? "Peso"
                                          : "Unidades"}
                                        )
                                      </strong>
                                    </label>
                                  </div>
                                  <TextField
                                    inputRef={register}
                                    type="number"
                                    name="cantidad_peso"
                                    required
                                    className="w-100"
                                    placeholder="Ingrese cantidad"
                                    InputProps={{
                                      inputProps: { min: 0, step: "0.00001" },
                                    }}
                                    onChange={(event) => {
                                      const t = event.target.value;
                                      event.target.value =
                                        t.indexOf(".") >= 0
                                          ? t.substr(0, t.indexOf(".")) +
                                            t.substr(t.indexOf("."), 3)
                                          : t;

                                      handleChange(event, unidadVenta);
                                      return event.target.value < 0
                                        ? (event.target.value =
                                            -event.target.value)
                                        : event.target.value;
                                    }}
                                  />
                                  <p className="mb-0">
                                    {unidadVenta === "libras"
                                      ? "/lbs."
                                      : "/uds"}
                                    .
                                  </p>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {unidadVenta === "unidades" ||
                            unidadVenta === "mixto" ||
                            !unidadVenta ? (
                              <div
                                className={`${
                                  unidadVenta === "unidades"
                                    ? "w-100 flex-wrap"
                                    : "w-50"
                                }justify-content-around h-100 d-flex flex-row align-items-center`}
                              >
                                <div
                                  className={`w-90 h-25 d-flex ${
                                    unidadVenta === "mixto"
                                      ? "justify-content-center"
                                      : "justify-content-start"
                                  } align-items-center`}
                                >
                                  <label style={{ fontSize: "0.8rem" }}>
                                    <strong>
                                      Cantidad (
                                      {unidadVenta === "mixto" ||
                                      unidadVenta === "libras" ||
                                      !unidadVenta
                                        ? "Peso"
                                        : "Unidades"}
                                      )
                                    </strong>
                                  </label>
                                </div>
                                <TextField
                                  inputRef={register}
                                  type="number"
                                  name="cantidad_unidades"
                                  required
                                  className="w-100"
                                  placeholder="Ingrese cantidad"
                                  InputProps={{
                                    inputProps: { min: 0, step: "0.00001" },
                                  }}
                                  onChange={(event) => {
                                    const t = event.target.value;
                                    event.target.value =
                                      t.indexOf(".") >= 0
                                        ? t.substr(0, t.indexOf(".")) +
                                          t.substr(t.indexOf("."), 3)
                                        : t;

                                    handleChange(event, unidadVenta);

                                    setStock(event.target.value);
                                    return event.target.value < 0
                                      ? (event.target.value =
                                          -event.target.value)
                                      : event.target.value;
                                  }}
                                />
                                <p className="mb-0">
                                  {unidadVenta === "mixto" ||
                                  unidadVenta === "libras"
                                    ? "/lbs."
                                    : "/uds."}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="mt-2">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Inventario</strong>
                          </label>

                          <NumberFormat
                            customInput={TextField}
                            inputRef={register}
                            name="stock"
                            className="w-100"
                            required
                            thousandsGroupStyle="thousand"
                            decimalSeparator="."
                            displayType="input"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            InputProps={{
                              inputProps: { min: 0, step: "0.00001" },
                            }}
                            placeholder="Ingrese el inventario de este producto"
                            // onChange={(event) =>
                            //   event.target.value < 0
                            //     ? (event.target.value = -event.target.value)
                            //     : event.target.value
                            // }
                          />

                          {/*<TextField
                            inputRef={register}
                            type="number"
                            name="stock"
                            required
                            className="w-100"
                            placeholder="Ingrese el inventario de este producto"
                            InputProps={{
                              inputProps: { min: 0, step: "0.00001" },
                            }}
                            onChange={(event) =>
                              event.target.value < 0
                                ? (event.target.value = -event.target.value)
                                : event.target.value
                            }
                          />*/}
                        </div>
                      )}

                      <div className="mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <label style={{ fontSize: "0.8rem" }}>
                              <strong>Notificaciones de bajo inventario</strong>
                            </label>
                            <Tooltip
                              title="Indique la cantidad mínima por la que el sistema le notificará que está bajo de inventario."
                              className="mx-1"
                            >
                              <InfoIcon
                                style={{
                                  color: " #828282",
                                  fontSize: "1.2rem",
                                }}
                              />
                            </Tooltip>
                          </div>
                          <div className="custom-control custom-switch">
                            <input
                              inputRef={register}
                              type="checkbox"
                              className="custom-control-input"
                              name="notificaciones_inventario"
                              checked={lowStockNotification}
                              onClick={handleLowStockNotification}
                              id={`customSwitchNotification`}
                            />
                            <label
                              className="custom-control-label"
                              for={`customSwitchNotification`}
                            />
                          </div>
                        </div>
                        <TextField
                          inputRef={register}
                          type="number"
                          className="w-100"
                          placeholder={`Ingrese la cantidad de inventario mínimo ${
                            unidadVenta === "unidades" ? "" : "en libras"
                          }`}
                          name="stock_minimo"
                          disabled={!lowStockNotification}
                          onChange={(event) => {
                            let value = event.target.value;
                            event.target.value =
                              value.indexOf(".") >= 0
                                ? value.substr(0, value.indexOf(".")) +
                                  value.substr(value.indexOf("."), 3)
                                : value;
                            return event.target.value < 0
                              ? (event.target.value = -event.target.value)
                              : event.target.value;
                          }}
                          InputProps={{
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                      </div>

                      <div className="mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Costo del producto</strong>
                          </label>
                          <Tooltip title="Indique el costo del producto.">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>
                        <TextField
                          inputRef={register}
                          value={costoValue}
                          type="text"
                          name="costo_unitario"
                          className="w-100"
                          placeholder="Ingrese el costo del producto."
                          required
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {moneda}.
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            const t = event.target.value;
                            event.target.value =
                              t.indexOf(".") >= 0
                                ? t.substr(0, t.indexOf(".")) +
                                  t.substr(t.indexOf("."), 3)
                                : t;
                            handleChange(event);
                            setCostoValue(
                              formaterPriceFunction(event.target.value)
                            );
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* Seventh row */}
                {!isAleko() && (
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Vencimiento del producto</strong>
                        </label>
                        <p className="mb-0" style={{ fontSize: "0.8rem" }}>
                          Indique si su producto tiene fecha de vencimiento.
                        </p>
                      </div>
                      <div className="custom-control custom-switch">
                        <input
                          inputRef={register}
                          type="checkbox"
                          className="custom-control-input"
                          name="vencimiento"
                          id={`customSwitchExpireDate`}
                          onChange={(event) => lote_expiredDate(event)}
                          checked={expire_lote}
                        />
                        <label
                          className="custom-control-label"
                          for={`customSwitchExpireDate`}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {alekoMode && (
                  <>
                    <div className="col-md-4 mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <TextField
                          placeholder="Inventario del producto"
                          label="Inventario"
                          value={cantidad}
                          onChange={(e) => setCantidad(+e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="col-md-8 mt-3">
                  {expire_lote ? (
                    <div className="row">
                      <div className="col-md-6 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Lote del producto</strong>
                        </label>

                        <TextField
                          inputRef={register}
                          type="text"
                          className="w-100"
                          name="lote"
                          placeholder="Ingrese número de lote del producto"
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <strong>Fecha de vencimiento</strong>
                          </label>
                          <Tooltip title="Indique la fecha de vencimiento del lote del producto.">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>
                        <TextField
                          inputRef={register}
                          type="date"
                          className="w-100"
                          name="vencimiento_fecha"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={!isCmi() ? 1 : null}>
              {!alekoMode ? (
                <div className="row mb-4">
                  <div className="col-md-6 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Producto compuesto</strong>
                        </label>
                        <p className="mb-0" style={{ fontSize: "0.8rem" }}>
                          Activa esta sección únicamente cuando su producto
                          contenga uno o más insumos de su almacén.
                        </p>
                      </div>
                      <div className="custom-control custom-switch">
                        <label
                          class="toggle-control mx-auto"
                          style={{ left: "-15px", height: "40px" }}
                        >
                          <input
                            type="checkbox"
                            checked={productoCompuesto}
                            onClick={() =>
                              setProductoCompuesto(!productoCompuesto)
                            }
                          />
                          <span class="control" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Código de la receta</strong>
                    </label>
                    <TextField
                      type="text"
                      className="w-100"
                      name="codigo_receta"
                      placeholder="Ingrese el código de la receta"
                      inputRef={register}
                    />
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Selección de insumos</strong>
                        </label>
                        <p className="mb-0" style={{ fontSize: "0.8rem" }}>
                          Seleccione los insumos requeridos para la creación de
                          este producto. Tenga en cuenta que los insumos deben
                          estar previamente registrados.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 my-auto">
                    <div
                      className="input-group search-bar autosuggest-search-bar mx-auto"
                      style={{ width: "fit-content" }}
                    >
                      <Autosuggest
                        suggestions={suppliesSuggestions}
                        onSuggestionsFetchRequested={() =>
                          onSuggestionsFetchRequested("insumo")
                        }
                        onSuggestionsClearRequested={() =>
                          onSuggestionsClearRequested("insumo")
                        }
                        getSuggestionValue={getSuppliesSuggestionValue}
                        renderSuggestion={renderSuppplySuggestion}
                        inputProps={suppliesInputProps}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text autosuggest-search-icon">
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 my-auto text-right">
                    <Button
                      type="submit"
                      className="btn-cs1_blue mx-1 mt-1"
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/app/inventario/add_supply"
                    >
                      Crear insumo
                    </Button>
                  </div>
                  {selected_products.length > 0 ? (
                    <div className="col-12">
                      {selected_products.map((input, index) => (
                        <div className="row justify-content-center">
                          <div className="col-md-1 mt-3 d-flex justify-content-center align-items-center">
                            <IconButton
                              className="btn-cs1 icon-btn-delete"
                              variant="contained"
                              color="primary"
                              onClick={() => remove_input(index)}
                            >
                              <DeleteIcon
                                style={{
                                  color: "white",
                                }}
                              />
                            </IconButton>
                          </div>

                          <div className="col-md-4 mt-3">
                            <p
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              <strong>{input.nombre}</strong>
                            </p>
                            <p
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              SKU: {input.codigo}
                            </p>
                          </div>

                          <div className="col-md-3 mt-3">
                            <p
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              <strong>Unidad/Medida</strong>
                            </p>
                            <p
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              {input.unidad}
                            </p>
                          </div>
                          <div className="col-md-4 mt-3">
                            <p
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              <strong>Cant. utilizada/producto</strong>
                            </p>
                            <p
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              {input.cantidad}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}

                  {foundSupply ? (
                    <>
                      <div className="col-md-3 d-flex justify-content-between">
                        <div className="d-flex align-items-end">
                          <Button
                            className="btn-cs1_blue mt-1"
                            variant="contained"
                            color="primary"
                            onClick={selectProduct}
                          >
                            Guardar
                          </Button>
                        </div>
                        <div className="d-flex align-items-end">
                          <Button
                            className="btn-cs1 mt-1 delete-btn"
                            variant="contained"
                            color="primary"
                            onClick={unselectProduct}
                          >
                            Cancelar
                          </Button>
                        </div>
                      </div>
                      <div
                        className="col-md-9 text-center my-auto p-5"
                        style={{ backgroundColor: "#F2F2F2" }}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <img
                              src={
                                foundSupply.imagen_1
                                  ? realImageURL(foundSupply.imagen_1)
                                  : base_url_images +
                                    "images/placeholder-image.jpeg"
                              }
                              className="combo-product-image"
                              alt="Cloud POS - Insumo"
                            />
                          </div>
                          <div className="col-lg-4">
                            <p>
                              <strong>{foundSupply.nombre}</strong>
                            </p>

                            <p>SKU: {foundSupply.codigo}</p>
                            <p>Stock: {foundSupply.stock}</p>
                            <p>Unidad/Medida: {foundSupply.unidad}</p>
                          </div>
                          <div className="col-lg-4">
                            <label style={{ fontSize: "0.8rem" }}>
                              <strong>Cant. utilizada por producto</strong>
                            </label>
                            <TextField
                              type="number"
                              className="w-100"
                              value={itemQuantity}
                              onChange={(event) =>
                                setItemQuantity(
                                  event.target.value < 0
                                    ? (event.target.value = -event.target.value)
                                    : event.target.value
                                )
                              }
                              placeholder="Ingrese la cantidad"
                              InputProps={{
                                inputProps: { min: 0, step: "0.00001" },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                !isCmi() && (
                  <>
                    <div className="row" style={{ position: "relative" }}>
                      <label className="col-lg-2">
                        <strong>Colores</strong>
                      </label>
                      <div className="col-lg-10">
                        <hr style={{ borderTop: "1px solid #a4b0be" }} />
                      </div>
                      {colors.map((item) => (
                        <ProductColor item={item} />
                      ))}
                      <div className="col-lg-1 text-center">
                        <div
                          className="border"
                          style={{
                            background:
                              productColor.codigo_hexa_color !== ""
                                ? productColor.codigo_hexa_color
                                : "rgb(242,242,242)",
                            borderRadius: "50%",
                            height: "70px",
                            width: "70px",
                            lineHeight: "70px",
                            cursor: "pointer",
                          }}
                          onClick={handleClick}
                        >
                          <span style={{ fontSize: 20 }}>+</span>
                        </div>
                        {productColor.codigo_hexa_color === "" &&
                          inputColorValidation && (
                            <span
                              className="text-danger"
                              style={{ fontSize: 13 }}
                            >
                              Requerido
                            </span>
                          )}
                      </div>
                      {showColorPicker && (
                        <ClickAwayListener onClickAway={handleClick}>
                          <div
                            className="card"
                            style={{
                              position: "absolute",
                              top: 70,
                              width: "241.96px",
                              flexWrap: "wrap",
                              zIndex: 10,
                            }}
                          >
                            <CustomPicker />
                          </div>
                        </ClickAwayListener>
                      )}

                      <div className="col-lg-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Nombre</strong>
                        </label>
                        <TextField
                          type="text"
                          className="w-100"
                          name="nombre_color"
                          value={productColor.nombre_color}
                          placeholder="Nombre el color"
                          onChange={handleColorChange}
                        />
                        {productColor.nombre_color.trim() === "" &&
                          inputColorValidation && (
                            <span className="text-danger">
                              Este campo es requerido
                            </span>
                          )}
                      </div>
                      <div className="col-lg-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Cantidad disponible</strong>
                        </label>
                        <TextField
                          type="number"
                          className="w-100"
                          name="cantidad_color"
                          onChange={handleColorChange}
                          value={
                            productColor.cantidad_color
                              ? productColor.cantidad_color
                              : ""
                          }
                          placeholder="Cantidad de este color"
                          InputProps={{
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                        {!productColor.cantidad_color &&
                          inputColorValidation && (
                            <span className="text-danger">
                              Este campo es requerido
                            </span>
                          )}
                      </div>
                      <div className="col-lg-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Precio extra</strong>
                        </label>
                        <TextField
                          type="number"
                          className="w-100"
                          value={productColor.precio_extra || ""}
                          onChange={handleColorChange}
                          name="precio_extra"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {moneda}.
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                      </div>
                      <div className="col-lg-2 d-flex align-items-center">
                        <Button
                          className="btn-cs1 delete-btn w-100 text-light"
                          variant="contained"
                          onClick={() => clearProductColor()}
                        >
                          Eliminar
                        </Button>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center">
                        <Button
                          variant="contained"
                          className="mt-2 w-100"
                          style={{
                            background: "#35baf6",
                            color: "white",
                            fontSize: 18,
                          }}
                          onClick={() =>
                            saveProductColor(productColor, setSelectedTab)
                          }
                        >
                          Añadir color
                        </Button>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <label className="col-lg-2">
                        <strong>Tallas</strong>
                      </label>
                      <div className="col-lg-10">
                        <hr style={{ borderTop: "1px solid #a4b0be" }} />
                      </div>
                      {sizes.map((item) => (
                        <ProductSize item={item} />
                      ))}
                      <div className="col-lg-4">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Talla</strong>
                        </label>
                        <TextField
                          type="text"
                          className="w-100"
                          name="nombre_talla"
                          value={productSize.nombre_talla}
                          onChange={handleProductSizeChange}
                          placeholder="Añada la talla"
                          InputProps={{
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                        {productSize.nombre_talla.trim() === "" &&
                          inputSizeValidation && (
                            <span className="text-danger">Campo requerido</span>
                          )}
                      </div>
                      <div className="col-lg-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Cantidad disponible</strong>
                        </label>
                        <TextField
                          type="number"
                          className="w-100"
                          name="cantidad_talla"
                          value={productSize.cantidad_talla || ""}
                          onChange={handleProductSizeChange}
                          placeholder="Cantidad"
                          InputProps={{
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                        {!productSize.cantidad_talla && inputSizeValidation && (
                          <span className="text-danger">Campo requerido</span>
                        )}
                      </div>
                      <div className="col-lg-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Precio extra</strong>
                        </label>
                        <TextField
                          type="number"
                          className="w-100"
                          value={productSize.precio_extra || ""}
                          onChange={handleProductSizeChange}
                          name="precio_extra"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {moneda}.
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                      </div>
                      <div className="col-lg-2 d-flex align-items-center">
                        <Button
                          className="btn-cs1 delete-btn w-100 text-light"
                          variant="contained"
                          onClick={() => clearProductSize()}
                        >
                          Eliminar
                        </Button>
                      </div>

                      <div className="col-lg-2 d-flex align-items-center">
                        <Button
                          variant="contained"
                          className="mt-2 w-100"
                          style={{
                            background: "#35baf6",
                            color: "white",
                            fontSize: 18,
                          }}
                          onClick={() => saveProductSize(productSize)}
                        >
                          Añadir
                        </Button>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <label className="col-lg-2">
                        <strong>Personalización</strong>
                      </label>
                      <div className="col-lg-10">
                        <hr style={{ borderTop: "1px solid #a4b0be" }} />
                      </div>
                      {personalizations.map((item) => (
                        <ProductPersonalization item={item} />
                      ))}
                      <div className="col-lg-4">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Nombre</strong>
                        </label>
                        <TextField
                          type="text"
                          className="w-100"
                          value={productPersonalization.nombre_personalizacion}
                          name="nombre_personalizacion"
                          onChange={handleProductPersonalizationChange}
                          placeholder="Tipo"
                          InputProps={{
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                        {productPersonalization.nombre_personalizacion.trim() ===
                          "" &&
                          inputPersonalizationValidation && (
                            <span className="text-danger">Campo requerido</span>
                          )}
                      </div>
                      <div className="col-lg-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Cantidad disponible</strong>
                        </label>
                        <TextField
                          type="number"
                          className="w-100"
                          name="cantidad_personalizacion"
                          onChange={handleProductPersonalizationChange}
                          value={
                            productPersonalization.cantidad_personalizacion ||
                            ""
                          }
                          placeholder="Cantidad"
                          InputProps={{
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                        {!productPersonalization.cantidad_personalizacion &&
                          inputPersonalizationValidation && (
                            <span className="text-danger">Campo requerido</span>
                          )}
                      </div>
                      <div className="col-lg-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Precio extra</strong>
                        </label>
                        <TextField
                          type="number"
                          className="w-100"
                          name="precio_extra"
                          value={productPersonalization.precio_extra || ""}
                          onChange={handleProductPersonalizationChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {moneda}.
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                      </div>
                      <div className="col-lg-2 d-flex align-items-center">
                        <Button
                          className="btn-cs1 delete-btn w-100 text-light"
                          variant="contained"
                          onClick={() => clearProductPersonalization()}
                        >
                          Eliminar
                        </Button>
                      </div>

                      <div className="col-lg-2 d-flex align-items-center">
                        <Button
                          variant="contained"
                          className="mt-2 w-100"
                          style={{
                            background: "#35baf6",
                            color: "white",
                            fontSize: 18,
                          }}
                          onClick={() =>
                            saveProductPersonalization(productPersonalization)
                          }
                        >
                          Añadir
                        </Button>
                      </div>
                    </div>
                  </>
                )
              )}
            </TabPanel>

            <TabPanel value={selectedTab} index={isCmi() ? 1 : null}>
              <Merma_prodChild unidadVenta={unidadVenta} stock={stock} />
            </TabPanel>
          </div>
        </div>
      </form>
    </div>
  );
}
