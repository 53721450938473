import React, { useContext, useState } from "react";
import ReactApexChart from "react-apexcharts";
import MermaContext from "../../../../../context/MermaContext/MermaContext";
import "./graphic.css";

const GraphicsMerma = () => {
  let { cantidadActual, mermaEstimada } = useContext(MermaContext);
  const cantidad_actual = cantidadActual - +mermaEstimada;

  const valores = {
    series: [cantidad_actual, +mermaEstimada],
    options: {
      chart: {
        type: "donut",
        width: 380,
      },
      labels: ["Producto apto para la venta", "Merma"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 380,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        position: "right",
        offsetY: 0,
        height: 230,
      },

      colors: ["#025de9", "#06c0e9"],
    },
  };

  // #025de9
  // #06c0e9

  return (
    <div>
      <div class="chart-wrap">
        <div id="chart">
          <ReactApexChart
            options={valores.options}
            series={valores.series}
            type="donut"
          />
        </div>
      </div>
    </div>
  );
};

export default GraphicsMerma;
