// eslint-disable-next-line
import React, { useState, useEffect, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DataTable from "react-data-table-component";
import TextField from "@material-ui/core/TextField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Backdrop, CircularProgress, IconButton } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import "moment/locale/es";
import { getMoneda } from "../../helpers/moneda";
import ModalAnulacion from "./ModalAnulacion";
import { getUsuariosGerentes } from "../../api/profile";
import { isCmi } from "../../helpers/isCmi";
import CamposErroneosModal from "./ModalAnulacion/ModalErrorAnulacion";
import ModalDiaAnterior from "./ModalAnulacion/ModalDiaAnterior";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";
moment.locale("es");

//data

export default function Users_all(props) {
  const moneda = getMoneda();
  let default_dates = [
    `${moment(new Date()).utcOffset(-6).format("YYYY-MM-DD")}T05:00:00.000Z`,
    `${moment(new Date())
      .utcOffset(-6)
      .add(1, "d")
      .format("YYYY-MM-DD")}T04:59:59.999Z`,
  ];
  console.log(
    "Default dates",
    moment(new Date()).utcOffset(-6).format("YYYY-MM-DDTHH:mm:ss")
  );

  // states
  const [sucursal, setSucursal] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [seller, setSeller] = useState("");
  const [sellers, setSellers] = useState([]);
  const [cajas, setCajas] = useState([]);
  const [caja, setCaja] = useState("");
  const [dateRange, setDateRange] = useState(default_dates);
  const [dateRageFormated, setDateRangeFormated] = useState(default_dates);
  let [motivo, setMotivo] = useState("");
  const [loader, setLoader] = useState(true);
  const [movements, setMovements] = useState([]);
  const [filteredMovements, setFilteredMovements] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [invoiceLoader, setInvoiceLoader] = useState(false);
  let [currentMovement, setCurrentMovement] = useState({});
  const { movimiento } = useContext(CmiProductContext);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#create_anulacion").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  useEffect(() => {
    getServerDate();
    getMovements(default_dates);
    getSucursals();
  }, []);

  useEffect(() => {
    getMovements(dateRageFormated);
  }, [sucursal]);

  // set states
  const changeSucursal = (event) => {
    const storeName = event.target.value;
    setSucursal(storeName);
    console.log("Sucursal", storeName);
  };

  const changeCaja = (event) => {
    const cajaId = event.target.value;
    setCaja(cajaId);
  };

  const changeSeller = (event) => {
    const sellerId = event.target.value;
    setSeller(sellerId);
  };

  const crear_anulacion = (element) => {
    if (!isCmi()) {
      $("#create_anulacion").modal();
    }

    setCurrentMovement(element);
  };
  const [loading, setLoading] = useState(false);

  const getServerDate = () => {
    API.dashboard
      .getDate()
      .then((res) => {
        let { date } = res.data.data;
        date = `${date}T05:00:00.000Z`;
        setDateRange([date, date]);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al obtener la fecha del servidor", {
          autoClose: 10000,
        });
      });
  };

  const submit_form = (movement, handleClose) => {
    setLoading(true);
    let data = {};
    let isSales = true;
    if (isCmi()) {
      motivo = movement.motivo;
    }
    if (movement.tipo_operacion.includes("Venta")) {
      data = {
        id_pos_movimiento: movement.id_pos_movimiento,
        documento: movement.documento,
        serie: movement.serie,
        id_tipo_documento: movement.id_tipo_documento_web_app,
        motivo,
        nro_productos: movement.orderItems.length,
        monto: movement.monto_total,
      };
    }
    if (movement.tipo_operacion.includes("crédito")) {
      data = {
        id_nota: movement.notacredito.id_nota_credito_web_app,
        motivo,
        tipo_operacion: "credito",
      };
      isSales = false;
    }
    if (movement.tipo_operacion.includes("débito")) {
      data = {
        id_nota: movement.notadebito.id_nota_debito_web_app,
        motivo,
        tipo_operacion: "debito",
      };
      isSales = false;
    }

    if (isSales) {
      API.sales
        .anularVenta(data)
        .then((res) => {
          let response = res.data;
          console.log("Venta anulada, recibiendo", response.data);
          if (response.success) {
            $("#create_anulacion").modal("hide");
            $("#save").modal();
            toast.success("La venta fue anulada.", {
              autoClose: 10000,
            });
            setCurrentMovement(movement);
            getMovements(dateRange);
            setMotivo("");
            setLoading(false);
            if (handleClose) {
              handleClose();
            }
          } else {
            toast.error(response.message, { autoClose: 10000 });
            setLoading(false);
            if (handleClose) {
              handleClose();
            }
          }

          setSubmitLoader(false);
        })
        .catch((err) => {
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
          setLoading(false);
          if (handleClose) {
            handleClose();
          }
          setSubmitLoader(false);
        });
    } else {
      API.sales
        .anularNota(data)
        .then((res) => {
          let response = res.data;
          console.log("Nota anulada, recibiendo", response.data);
          if (response.success) {
            $("#create_anulacion").modal("hide");
            $("#save_nota").modal();
            toast.success("La nota fue anulada.", {
              autoClose: 10000,
            });
            setCurrentMovement(movement);
            getMovements(dateRange);
            setMotivo("");
            setLoading(false);
            if (handleClose) {
              handleClose();
            }
          } else {
            toast.error(response.message, { autoClose: 10000 });
          }
          setSubmitLoader(false);
        })
        .catch((err) => {
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
          setSubmitLoader(false);
        });
    }
  };

  const getMovements = (dates) => {
    setLoader(true);
    let data = {
      from_date: dates[0],
      to_date: dates[1],
      id_sucursal_bodega_ubicacion: sucursal,
      filtroTipoDocumento: 'VE',
    };

    if (!data.id_sucursal_bodega_ubicacion) {
      return;
    }



    API.sales
      .getAnulacionesApplicableSales(data)
      .then((res) => {
        let response = res.data;
        console.log("Uncancelled movements received:", response.data);
        if (response.success) {
          setMovements(response.data);
          setFilteredMovements(response.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  const filterMovements = (e) => {
    setFilterText(e.target.value);
    setFilteredMovements(
      movements.filter((item) =>
        item.identificador.toLowerCase().includes(filterText.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredMovements(movements);
    }
  };

  const handleDateChange = (value) => {
    setDateRange(value);
    let currentDates = [
      moment(value[0]).format("YYYY-MM-DD"),
      moment(value[1]).format("YYYY-MM-DD"),
    ];
    setDateRangeFormated(currentDates);
    getMovements(currentDates);
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
          getCajas(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getCajas = (value) => {
    API.cajas
      .getCajas({ id_sucursal_bodega_ubicacion: value }, true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCajas(response.data);
          setCaja(response.data[0].id_caja_web_app);
          getSellers(response.data[0].id_caja_web_app);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSellers = (value) => {
    API.cajas
      .getVendedores({ id_caja_web_app: value }, true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSellers(response.data);
          setSeller(response.data[0].id_vendedor_web_app);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const gotoInvoice = (movement) => {
    const newWindow = window.open(
      "/#/app/vender/resumen/" + movement.id_pos_movimiento,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const gotoItemInvoice = (movement) => {
    console.log("current movement", movement);
    setInvoiceLoader(true);
    let nit = movement.client.nit;
    if (movement.tipo_operacion.includes("Venta")) {
      const newWindow = window.open(
        "/#/app/vender/resumen/" + movement.id_pos_movimiento,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    } else if (movement.tipo_operacion.includes("crédito")) {
      API.sales
        .getNotaCredito({
          id_nota: movement.notacredito.id_nota_credito_web_app,
        })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            localStorage.setItem("invoice_data", JSON.stringify(response.data));
            const newWindow = window.open(
              "/#/app/nota_credito/factura",
              "_blank",
              "noopener,noreferrer"
            );
            if (newWindow) newWindow.opener = null;
            setInvoiceLoader(false);
          } else {
            setInvoiceLoader(false);
            if (response.message) {
              toast.error(response.message, { autoClose: 10000 });
            } else {
              toast.error("Ha ocurrido un error", {
                autoClose: 10000,
              });
            }
          }
        })
        .catch((err) => {
          setInvoiceLoader(false);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        });
    } else if (movement.tipo_operacion.includes("débito")) {
      API.sales
        .getNotaDebito({ id_nota: movement.notadebito.id_nota_debito_web_app })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            localStorage.setItem("invoice_data", JSON.stringify(response.data));
            const newWindow = window.open(
              "/#/app/nota_debito/factura",
              "_blank",
              "noopener,noreferrer"
            );
            if (newWindow) newWindow.opener = null;
            setInvoiceLoader(false);
          } else {
            setInvoiceLoader(false);
            if (response.message) {
              toast.error(response.message, { autoClose: 10000 });
            } else {
              toast.error("Ha ocurrido un error", {
                autoClose: 10000,
              });
            }
          }
        })
        .catch((err) => {
          setInvoiceLoader(false);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        });
    }
  };
  const [gerentes, setGerentes] = useState([]);
  useEffect(() => {
    if (sucursal !== "") {
      const getGerentes = async () => {
        try {
          const { data } = await getUsuariosGerentes({
            id_sucursal_bodega_ubicacion: +sucursal,
          });
          setGerentes(data.data);
        } catch (error) {
          console.log(error);
        }
      };
      getGerentes();
    }
  }, [sucursal]);

  return (
    <div id="dashboard-section">
      {!isCmi() && (
        <>
          <div
            class="modal fade"
            id="create_anulacion"
            tabindex="-1"
            role="dialog"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="d-flex justify-content-end px-2 py-1">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Motivo de la anulación</strong>
                  </label>
                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Indique el motivo de la anulación"
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                  />

                  <div class="d-flex justify-content-between mt-2">
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                      data-dismiss="modal"
                      onClick={() => gotoItemInvoice(currentMovement)}
                    >
                      Ver factura
                    </Button>
                    <Button
                      className="btn-cs1 delete-btn"
                      variant="contained"
                      color="primary"
                      onClick={() => submit_form(currentMovement)}
                    >
                      Anular movimiento
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Modal */}
      <div class="modal fade" id="save" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="card">
              <div className="card-title d-flex justify-content-end align-items-center border-bottom py-3">
                <IconButton
                  data-dismiss="modal"
                  style={{ marginRight: "17px" }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="text-center">
                <CheckCircleIcon
                  className="text-dynamic-color"
                  style={{ fontSize: "60px" }}
                />

                <h4>
                  <strong>
                    Esta venta se ha <br />
                    anulado exitosamente
                    <br />
                  </strong>
                  <br />
                </h4>
              </div>

              <div class="border-top py-3 col-md-12 d-flex align-items-center justify-content-between">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    marginRight: "10px",
                    height: "42px",
                    width: "150px",
                  }}
                  onClick={() => gotoInvoice(currentMovement)}
                >
                  {invoiceLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <span
                      className="text-dynamic-color"
                      style={{ fontSize: "13px" }}
                    >
                      Ver recibo
                    </span>
                  )}
                </Button>
                <Button
                  variant="contained"
                  id="back-css"
                  style={{
                    boxShadow: "none",
                    width: "150px",
                    height: "42px",
                  }}
                  data-dismiss="modal"
                >
                  <span style={{ color: "#fff", fontSize: "13px" }}>
                    Aceptar
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="save_nota" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="card">
              <div className="card-title d-flex justify-content-end align-items-center border-bottom py-3">
                <IconButton
                  data-dismiss="modal"
                  style={{ marginRight: "17px" }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="text-center">
                <CheckCircleIcon
                  className="text-dynamic-color"
                  style={{ fontSize: "60px" }}
                />

                <h4>
                  <strong>
                    Esta nota se ha <br />
                    anulado exitosamente
                  </strong>
                </h4>
              </div>

              <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  <span style={{ color: "#fff", fontSize: "13px" }}>
                    Aceptar
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Anulaciones de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined">
          <Select native value={sucursal} onChange={changeSucursal}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined" className="px-1">
          <Select native value={caja} onChange={changeCaja}>
            {cajas.map((caja) => (
              <option value={caja.id_caja_web_app}>{caja.descripcion}</option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined" className="px-1">
          <Select native value={seller} onChange={changeSeller}>
            {sellers.map((seller) => (
              <option value={seller.id_vendedor_web_app}>
                {seller.nombre}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="row">
            <div className="col-md-6">
              <h5 className="mb-0">
                <strong>Anular movimiento</strong>
              </h5>
              <p className="mb-0">
                Para realizar la anulación de algun movimiento, deberá disponer
                del número de la factura o bien puede realizar una búsqueda
                manual por fecha.
              </p>
            </div>

            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <Button
                to={"/app/operaciones_historial"}
                component={Link}
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px", height: "42px", width: "150px" }}
              >
                <span
                  style={{
                    color: "#0160E7",
                    fontSize: "14px",
                  }}
                >
                  Cancelar
                </span>
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-end mb-3 mt-2 flex-wrap">
            <div className="d-flex align-items-end flex-wrap">
              <div className="mr-2">
                <p className="mb-0">Buscar por:</p>
                <div className="d-flex align-items-center flex-wrap">
                  <DateRangePicker
                    onChange={(val) => handleDateChange(val)}
                    value={dateRange}
                    format={"dd-MM-y"}
                    className="date-picker-cs px-1"
                  />
                </div>
              </div>
            </div>

            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ingrese el número de facturación"
                  style={{ width: "300px" }}
                  value={filterText}
                  onChange={(event) => filterMovements(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="table-css-v3">
          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2"
              title={"s"}
              columns={[
                {
                  name: "N° Factura",
                  selector: "identificador",
                  sortable: true,
                },
                {
                  name: "Fecha / Hora",
                  selector: "fecha",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {row.fecha.split("T")[0].split("-")[2] +
                        "/" +
                        row.fecha.split("T")[0].split("-")[1] +
                        "/" +
                        row.fecha.split("T")[0].split("-")[0] +
                        " " +
                        row.fecha.split("T")[1].split(".")[0]}
                    </div>
                  ),
                },
                {
                  name: "Tipo de operación",
                  selector: "tipo_operacion",
                  sortable: true,
                },
                {
                  name: "Nombre del cliente",
                  selector: "client.nombre",
                  sortable: true,
                },
                {
                  name: "NIT",
                  selector: "client.nit",
                  sortable: true,
                },
                {
                  name: "N° productos",
                  selector: "orderItems.length",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {row.orderItems.length}
                    </div>
                  ),
                },
                {
                  name: "Monto",
                  selector: "monto_total",
                  sortable: true,
                  cell: (row) => (
                    <span>
                      {" "}
                      {moneda}.{" "}
                      {row.notacredito
                        ? formaterPriceFunction(
                            parseFloat(row.notacredito.monto).toFixed(2) + ""
                          )
                        : ""}
                      {row.notadebito
                        ? formaterPriceFunction(
                            parseFloat(row.notadebito.monto).toFixed(2) + ""
                          )
                        : ""}
                      {!row.notadebito && !row.notacredito
                        ? formaterPriceFunction(
                            parseFloat(row.monto_total).toFixed(2) + ""
                          )
                        : ""}
                    </span>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      {!isCmi() ? (
                        <Button
                          className="btn-cs1 delete-btn w-100"
                          variant="contained"
                          color="primary"
                          onClick={() => crear_anulacion(row)}
                          style={{
                            width: "150px",
                            height: "43px",
                            boxShadow: "none",
                          }}
                        >
                          {submitLoader ? (
                            <div className="text-center">
                              <CircularProgress size={26} className="mx-auto" />
                            </div>
                          ) : (
                            <span style={{ fontSize: "14px" }}>Anular</span>
                          )}
                        </Button>
                      ) : (
                        <ModalAnulacion
                          setMotivo={setMotivo}
                          gotoItemInvoice={gotoItemInvoice}
                          submit_form={submit_form}
                          motivo={motivo}
                          currentMovement={row}
                          gerentes={gerentes}
                          crear_anulacion={crear_anulacion}
                          row={row}
                          setCurrentMovement={setCurrentMovement}
                          loading={loading}
                          check="anulacion"
                          header="Anulación de venta"
                          warning="Debes seleccionar el gerente que está autorizando esta anulación, este deberá confirmar ingresando su contraseña."
                          label="Motivo de la anulación"
                          placeholder="Ingrese el motivo de anulación"
                          infotip="Selecciona el gerente que autorizó la anulación"
                        />
                      )}
                    </div>
                  ),
                },
              ]}
              data={filteredMovements}
              defaultSortField="fecha"
              defaultSortAsc={false}
              pagination
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se ha encontrado ningún resultado"
            />
          )}
        </div>
      </div>
      <CamposErroneosModal message="No se ha anulado la venta." />
      <ModalDiaAnterior message="Anulación rechazada, no puedes anular ventas de días anteriores." />

      <Backdrop open={loading} style={{ color: "#fff", zIndex: 99999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
