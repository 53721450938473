import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../../api";
import { toast } from "react-toastify";

// styles
import "./custom.css";

export default function Users_all(props) {
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {};
  }, []);

  // states
  const { register, handleSubmit } = useForm();
  const [saveLoader, setSaveLoader] = useState(false);

  // set states

  const save = (data) => {
    setSaveLoader(true);
    let validForm = true;
    if (data.contrasena != data.confirmacion_contrasena) {
      validForm = false;
      toast.error("Las contraseñas deben coincidir ", { autoClose: 3000 });
      setSaveLoader(false);
    }

    if (validForm) {
      API.admin_user
        .register(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setSaveLoader(false);
            props.history.push("/admin/usuarios");
            toast.success(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.success(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          setSaveLoader(false);
          let response = err.response;
          console.log(err);
          console.log(response);
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    }
    console.log(data);
  };

  //function

  return (
    <div id="dashboard-section">
      <form className="content-card card mt-3" onSubmit={handleSubmit(save)}>
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Creación de usuario SuperCloud</strong>
              </h5>
              <p className="mb-0">
                Creación de un nuevo usuario administrativo
              </p>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-1 mt-1 delete-btn"
                variant="contained"
                color="primary"
                component={Link}
                to={"/admin/usuarios"}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1 mx-1 mt-1"
                variant="contained"
                color="primary"
              >
                {saveLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Guardar y crear"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Nombre</strong>
              </label>

              <TextField
                type="text"
                className="w-100"
                placeholder="Escriba el nombre del usuario"
                inputRef={register}
                name="nombre"
                required={true}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Teléfono</strong>
              </label>

              <TextField
                type="tel"
                className="w-100"
                placeholder="(         )         -            -"
                inputRef={register}
                name="telefono"
                required={true}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Cargo en la empresa</strong>
              </label>

              <TextField
                type="text"
                className="w-100"
                placeholder="Escriba el cargo del usuario"
                inputRef={register}
                name="cargo"
                required={true}
              />
            </div>

            {/* Second Row */}
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Correo electrónico </strong>
              </label>

              <TextField
                type="email"
                className="w-100"
                placeholder="Escriba el correo electrónico del usuario"
                inputRef={register}
                name="correo"
                required={true}
              />
            </div>

            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Ingrese nueva contraseña</strong>
              </label>

              <TextField
                type="password"
                className="w-100"
                placeholder="Introduzca su nueva contraseña"
                inputRef={register}
                name="contrasena"
                required={true}
              />
            </div>

            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Reingrese nueva contraseña </strong>
              </label>

              <TextField
                type="password"
                className="w-100"
                placeholder="Vuelva a introducir su nueva contraseña"
                inputRef={register}
                name="confirmacion_contrasena"
                required={true}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
