import { useState, useEffect, useCallback } from "react";

import axios from "axios";

function useInfinityScroll(page) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [employees, setEmployees] = useState([]);

  const sendQuery = useCallback(async () => {
    try {
      setLoading(true);

      let { data } = await axios.get(
        `https://jsonplaceholder.typicode.com/todos?_start=${page}&_limit=20`
      );
      data.forEach((element) => {
        element.amount = Math.random() * 50000;
        element.name = "Lorem ipsum";
        element.qty = Math.round(Math.random() * 2500);
      });

      setEmployees((prev) => [...prev, ...data]);

      setLoading(false);
    } catch (err) {
      setError(err);
    }
  }, [page]);

  useEffect(() => {
    sendQuery();
  }, [sendQuery, page]);

  return { loading, error, employees };
}

export default useInfinityScroll;
