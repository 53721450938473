import React, { useContext, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import "../../custom.css";

import { getMoneda } from "../../../../helpers/moneda";
import {
  ReportProblemOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { retirarEfectivo, validarAnulacion } from "../../../../api/sales";
import { toast } from "react-toastify";
import CmiProductContext from "../../../../context/CmiProductContext/CmiProductContext";
import moment from "moment";
const formaterPriceFunction = (value) => {
  return value
    .toFixed(2)
    .toString()

    .replace(/(?!\.)\D/g, "")
    .replace(/(?<=\..*)\./g, "")
    .replace(/(?<=\.\d\d).*/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function ModalAutorizacion({
  gerente,
  motivo,
  currentMovement,
  submit_form,
  check,
  loading: loader,
}) {
  const {
    setModalErrorCampos,
    setModalDiaAnterior,
    setRetiroAutorizado,
    efectivoDisponible,
  } = useContext(CmiProductContext);
  const moneda = getMoneda();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const {
    retiroAutorizado,
    montoRetiro,
    retiroData,
    setCantidadRetiro,
    cantidadRetiro,
  } = useContext(CmiProductContext);

  const [password, setPassword] = useState("");
  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const [visibility, setVisibility] = useState(false);

  const autorizarAnulacion = async () => {
    setLoading(true);
    if (loader) return;
    if (password.trim() === "") {
      toast.warn("Introduzca la contraseña", { autoClose: 10000 });
      return;
    }
    try {
      if (loading) return;
      const { data } = await validarAnulacion({
        correo_electronico: gerente.correo,
        contrasena: password,
      });

      setLoading(false);
      if (check === "anulacion") {
        if (
          moment(currentMovement.fecha).format("DD-MM-YYYY") !==
          moment(new Date()).format("DD-MM-YYYY")
        ) {
          if (process.env.REACT_APP_ENTORNO !== "production") {
            setModalDiaAnterior(true);
            return;
          }
        }
        submit_form({ ...currentMovement, motivo }, handleClose);
      } else {
        try {
          if (!retiroData.cantidad_retiro) {
            toast.warn("Ingresa un monto válido", {
              autoClose: 10000,
            });
            return;
          }
          if (efectivoDisponible < retiroData.cantidad_retiro) {
            toast.warn(
              "No tienes suficiente efectivo en caja para este retiro",
              { autoClose: 10000 }
            );
            return;
          }

          await retirarEfectivo({
            ...retiroData,
            retiro_fecha: moment(new Date()).format("YYYY-MM-DD"),
          });
          setLoading(false);
          setCantidadRetiro({
            total: cantidadRetiro.total + retiroData.cantidad_retiro,
            cantidad: cantidadRetiro.cantidad + 1,
          });
          setRetiroAutorizado(true);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalErrorCampos(true);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          background: gerente && motivo.trim() !== "" ? "#0160E7" : "#BDBDBD",
          boxShadow: "none",
          width: "150px",
          height: "42px",
        }}
        disabled={!gerente || motivo.trim() === ""}
        type="submit"
        onClick={handleOpen}
      >
        <span style={{ color: "#fff", fontSize: "14px" }}>Aceptar</span>
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="card" style={{ maxWidth: "577px", border: "none" }}>
            <div className="card-title d-flex justify-content-end align-items-center border-bottom py-3">
              <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
              <ReportProblemOutlined
                className="color-css-text"
                style={{ fontSize: "65px" }}
              />

              <h4 style={{ fontSize: "20px" }} className="mt-1">
                <strong>
                  <span className="color-css-text">{gerente?.nombre}</span>,
                  {check === "anulacion"
                    ? "confirme la anulación de venta N°"
                    : " para confirmar la recepción de"}{" "}
                  {check === "anulacion" && (
                    <span className="color-css-text">
                      {currentMovement?.id_pos_movimiento} por
                    </span>
                  )}{" "}
                  <span className="color-css-text">
                    {moneda}.
                    {check === "retiro"
                      ? formaterPriceFunction(parseFloat(motivo))
                      : formaterPriceFunction(
                          parseFloat(currentMovement?.monto_total)
                        )}
                  </span>
                </strong>
                <span>
                  <strong>
                    {" "}
                    {check === "retiro" && "Introduzca su contraseña."}
                  </strong>
                </span>
              </h4>
              <div className="w-100 my-2">
                <span className="w-100">
                  <strong>Introduzca su contraseña de acceso</strong>
                </span>
                <TextField
                  placeholder="Ingrese su contraseña de acceso"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setVisibility(!visibility)}>
                          {!visibility ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="outlined"
                  value={password}
                  onChange={handleChange}
                  type={!visibility ? "password" : "text"}
                />
              </div>
            </div>
            <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-between">
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px", height: "42px", width: "150px" }}
                onClick={handleClose}
              >
                <span
                  style={{
                    color: true ? "#0160E7" : "#BDBDBD",
                    fontSize: "14px",
                  }}
                >
                  Cancelar
                </span>
              </Button>
              <Button
                variant="contained"
                id="back-css"
                style={{
                  boxShadow: "none",
                  width: "150px",
                  height: "42px",
                }}
                onClick={autorizarAnulacion}
                endIcon={
                  loading && (
                    <CircularProgress size={16} style={{ color: "#fff" }} />
                  )
                }
              >
                <span style={{ color: "#fff", fontSize: "14px" }}>Aceptar</span>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
