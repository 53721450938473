import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Link, useHistory } from "react-router-dom";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { getMoneda } from "../../helpers/moneda";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CashRegister from "./CashRegister.png";
import Document from "./Document.png";
import moment from "moment";
import "moment/locale/es";
import { formaterPriceFunction } from "../../helpers/formater";
moment.locale("es");

//data

export default function Sell(props) {
  const moneda = getMoneda();
  // states
  const [invoiceData, setInvoiceData] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.id_cliente_acreedor_web_app) {
        let id = props.history.location.state.id_cliente_acreedor_web_app;
        getAnticipoData(id);
      } else {
        toast.error(
          "Ha ocurrido un error. Por favor vuelva a la página de creación de anticipos",
          { autoClose: 10000 }
        );
      }
    }
  }, []);

  const gotoInvoice = () => {
    localStorage.setItem("anticipo_data", JSON.stringify(invoiceData));
    const newWindow = window.open(
      "/#/app/anticipo/factura",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const getAnticipoData = (id) => {
    API.clients
      .getAnticipoById({ id_cliente_acreedor_web_app: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Im getting this anticipo data:", response.data);
          setInvoiceData(response.data);
          setLoader(false);
        } else {
          setLoader(false);
          toast.error(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Anticipo de pago</strong>
        </h4>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="d-flex justify-content-center justify-content-sm-between flex-wrap mb-2">
            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<img src={Document} alt="cash-register-icon" />}
            >
              <span style={{ marginBottom: "-4px" }} onClick={gotoInvoice}>
                Ver documento
              </span>
            </Button>

            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<img src={CashRegister} alt="cash-register-icon" />}
              component={Link}
              to={"/app/vender"}
            >
              <span style={{ marginBottom: "-4px" }}>Volver a la caja</span>
            </Button>
          </div>
          <div className="content-card card">
            <div className="card-header">
              <h4 className="mb-0">
                <strong>Resumen del anticipo de pago</strong>
              </h4>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Fecha / hora</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={16} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={moment(invoiceData.anticipo.fecha)
                        .utcOffset(0)
                        .format("DD-MM-YYYY hh:mm:ss")}
                      // value={moment(invoiceData.anticipo.fecha).format(
                      //   "DD-MM-YYYY HH:mm:ss"
                      // )}
                      name="fecha"
                      disabled
                    />
                  )}
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>NIT de cliente</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={16} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      name="nit"
                      value={invoiceData.client.nit}
                      disabled
                    />
                  )}
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Nombre de cliente</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={16} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={
                        invoiceData.client.nit.toLowerCase().includes("cf")
                          ? invoiceData.client.nombre_cliente_final
                            ? invoiceData.client.nombre_cliente_final
                            : invoiceData.client.nombre
                          : invoiceData.client.nombre
                      }
                      name="client_name"
                      disabled
                    />
                  )}
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Dirección fiscal del cliente</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={16} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={invoiceData.client.direccion || "No especificada"}
                      name="direccion_fiscal"
                      disabled
                    />
                  )}
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Correo electrónico</strong>
                  </p>
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={16} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={invoiceData.client.email || "No especificado"}
                      name="email"
                      disabled
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card content-card mt-2">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-5">
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={16} className="mx-auto" />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center flex-wrap mb-3">
                      <div
                        className={
                          invoiceData.anticipo.paymentMethod === 1
                            ? "active-box payment-method-box"
                            : "payment-method-box"
                        }
                        id="box-1"
                      >
                        <LocalAtmIcon />
                        <p>Efectivo</p>
                      </div>
                      <div
                        className={
                          invoiceData.anticipo.paymentMethod === 2
                            ? "active-box payment-method-box"
                            : "payment-method-box"
                        }
                        id="box-2"
                      >
                        <CreditCardIcon />
                        <p>Tarjeta</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-7 invoice-column py-2">
                  <div className="row h-25 text-left px-4">
                    <p>
                      <strong>Monto anticipado:</strong>
                    </p>
                  </div>
                  <div className="row h-75 justify-content-center w-100 m-0 mt-2 px-3 stubborn-input">
                    {loader ? (
                      <div className="text-center">
                        <CircularProgress size={16} className="mx-auto" />
                      </div>
                    ) : (
                      <TextField
                        style={{ color: "black" }}
                        className="w-100"
                        variant="outlined"
                        value={formaterPriceFunction(
                          invoiceData.anticipo.monto.toFixed(2) + ""
                        )}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {moneda}.
                            </InputAdornment>
                          ),
                        }}
                        disabled
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            <strong>Tarjeta de débito/crédito</strong>
                          </p>
                          {loader ? (
                            <div className="text-center">
                              <CircularProgress size={16} className="mx-auto" />
                            </div>
                          ) : (
                            <TextField
                              className="w-100"
                              variant="standard"
                              value={formaterPriceFunction(
                                invoiceData.anticipo.tarjeta.toFixed(2) + ""
                              )}
                              placeholder="Ingrese el monto del anticipo en tarjeta"
                              disabled
                            />
                          )}
                        </div>
                        <div className="col-md-6">
                          <p>
                            <strong>Efectivo</strong>
                          </p>
                          {loader ? (
                            <div className="text-center">
                              <CircularProgress size={16} className="mx-auto" />
                            </div>
                          ) : (
                            <TextField
                              className="w-100"
                              variant="standard"
                              value={formaterPriceFunction(
                                invoiceData.anticipo.efectivo.toFixed(2) + ""
                              )}
                              placeholder="Ingrese el monto del anticipo en efectivo"
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <p>
                        <strong>Concepto del anticipo</strong>
                      </p>
                      {loader ? (
                        <div className="text-center">
                          <CircularProgress size={16} className="mx-auto" />
                        </div>
                      ) : (
                        <TextField
                          className="w-100"
                          variant="standard"
                          value={invoiceData.anticipo.concepto}
                          placeholder="Ingrese el concepto del anticipo"
                          disabled
                        />
                      )}
                    </div>
                  </div>
                </div>
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={16} className="mx-auto" />
                  </div>
                ) : (
                  <>
                    {invoiceData.anticipo.tarjeta > 0 ? (
                      <div className="col-sm-12 mt-4">
                        <h4>
                          <strong>Verificación del pago</strong>
                        </h4>
                        <div className="row">
                          <div className="col-12 my-auto">
                            <div className="row">
                              <div className="col-xl-12 mb-2">
                                <p>
                                  <strong>
                                    Número del recibo (Operación con tarjeta)
                                  </strong>
                                </p>
                                <TextField
                                  className="w-100"
                                  variant="standard"
                                  value={invoiceData.anticipo.recibo_numero}
                                  placeholder="Ingrese el número del recibo"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        N°
                                      </InputAdornment>
                                    ),
                                  }}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-12 mt-5">
                            <div className="row w-100 justify-content-center justify-content-md-start">
                              <div className="col-xl-12">
                                <p className="mb-0">
                                  <strong>
                                    Número del recibo de pago para tarjeta
                                    adicional
                                  </strong>
                                </p>
                                <TextField
                                  className="w-100 mb-3"
                                  variant="standard"
                                  value={invoiceData.anticipo.adicional_recibo}
                                  placeholder="Ingrese el número del recibo de la tarjeta adicional"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        N°
                                      </InputAdornment>
                                    ),
                                  }}
                                  disabled
                                />
                              </div>
                              {/* <div className="col-xl-1 text-center my-auto bg_secondary_color">
                                                <AddIcon
                                                    style={{
                                                        fontSize: "30px",
                                                        color: "white",
                                                    }}
                                                    onClick={
                                                        addNewAdditionalInvoice
                                                    }
                                                />
                                            </div> */}
                            </div>
                            {/* <div className="row w-100 mt-2 justify-content-center justify-content-md-start">
                                            {additional_invoices.map(
                                                (invoice) => (
                                                    <div className="col-xl-4 px-1">
                                                        <TextField
                                                            className="w-100"
                                                            variant="standard"
                                                            disabled
                                                            value={invoice}
                                                            name="invoice_number"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        Recibo
                                                                        N°
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
