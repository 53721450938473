import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Image, Circle } from "react-konva";
import useImage from "use-image";
import { realImageURL } from "../../../../../../api";

const ProductPerspective = ({ item }) => {
  const stageWidth = 200 / 1.5;
  const stageHeight = 200 / 1.5;
  const circleWidth = 20;
  const circleHeight = 20;
  const stageRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(200 / 1.5);
  const [imageHeight, setImageHeight] = useState(200 / 1.5);
  const [image, status] = useImage(realImageURL(item.vista) || "noimage");

  const [X, setX] = useState(0);
  const [Y, setY] = useState(0);

  useEffect(() => {
    setX((stageWidth - imageWidth) * 0.5);
    setY((stageHeight - imageHeight) * 0.5);

    const imageAspectRatio = (ratio) => {
      setImageWidth(imageWidth * ratio);
      setImageHeight(imageHeight * ratio);
    };

    if (imageWidth > stageWidth) {
      imageAspectRatio(stageWidth / imageWidth);
    }

    if (imageHeight > stageHeight) {
      imageAspectRatio(stageHeight / imageHeight);
    }
  }, [imageWidth, imageHeight]);

  const colors = ["#08BFE6", "#0160E7", "#219653", "#fff200"];
  const entries = Object.entries(item)
    .filter((i) => i[0].includes("nombre_area"))
    .map((x) => x[1])
    .filter((x) => x);
  return (
    <Stage ref={stageRef} width={stageWidth} height={stageHeight}>
      <Layer>
        <Image
          image={image}
          x={X}
          y={Y}
          width={imageWidth}
          height={imageHeight}
          alt="Perspectiva Producto"
        />
      </Layer>
      <Layer>
        {entries.map((entry, index) => (
          <Circle
            key={index}
            x={+item[`coordenadas_x_personalizable_${index + 1}`] / 1.5}
            y={+item[`coordenadas_y_personalizable_${index + 1}`] / 1.5}
            fill={colors[index]}
            width={circleWidth}
            height={circleHeight}
          />
        ))}
      </Layer>
    </Stage>
  );
};

export default ProductPerspective;
