import React, { useContext, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ReportProblemOutlined } from "@material-ui/icons";
import { getMoneda } from "../../../../helpers/moneda";
import CambioMasivoContext from "../../../../context/CambioMasivoContext/CambioMasivoContext";

export default function UpdatePriceModal({}) {
  const moneda = getMoneda();
  const {
    selectedProducts,
    handleNewPrice,
    handleUpdateProducts,
    validation,
    disableValidation,
    loaderUpdate,
  } = useContext(CambioMasivoContext);

  const [display, setDisplay] = useState(false);
  const handleOpen = () => {
    setDisplay(true);
  };
  const handleClose = () => {
    setDisplay(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={
          selectedProducts.length
            ? "shadow-none bg-secondary-color text-white"
            : "shadow-none"
        }
        onClick={handleOpen}
        disabled={!selectedProducts.length}
      >
        <span style={{ fontSize: "16px" }}>Actualizar seleccionados</span>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={display}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={display}>
          <div
            className="card"
            style={{
              width: "500px",
              border: "none",
              outline: "none",
            }}
          >
            <div className="card-title d-flex justify-content-between align-items-center border-bottom py-3">
              <strong style={{ marginLeft: "17px" }}>
                <span style={{ fontSize: "20px" }}>
                  Confirmación de actualización de precio
                </span>
              </strong>
              <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body">
              <div className="row mt-2">
                <div className="col-md-12 d-flex flex-wrap">
                  <span
                    className="w-100"
                    style={{ fontWeight: 700, fontSize: "13px" }}
                  >
                    Nuevo precio de venta
                  </span>
                  <TextField
                    fullWidth
                    error={validation}
                    type="number"
                    helperText={validation && "Requerido"}
                    onChange={handleNewPrice}
                    onFocus={disableValidation}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleUpdateProducts(setDisplay);
                      }
                    }}
                  />
                </div>
                <div className="col-md-12 mt-4">
                  <div
                    className="row"
                    style={{
                      background: "#F2F2F2",
                      margin: "0px",
                      padding: "15px",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="col-1 d-flex align-items-center justify-content-center">
                      <span>
                        {loaderUpdate ? (
                          <CircularProgress
                            style={{ color: "#0160E7", fontSize: "30px" }}
                          />
                        ) : (
                          <ReportProblemOutlined
                            style={{ color: "#0160E7", fontSize: "30px" }}
                          />
                        )}
                      </span>
                    </div>
                    <div className="col-11 d-flex align-items-center">
                      <span className="p-0">
                        Esta actualización afectará el precio de los productos
                        seleccionados en todas las tiendas indicadas.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-end">
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "25px", height: "42px", width: "150px" }}
                onClick={handleClose}
                disabled={false}
              >
                <span
                  style={{
                    color: true ? "#0160E7" : "#BDBDBD",
                    fontSize: "14px",
                  }}
                >
                  Cancelar
                </span>
              </Button>
              <Button
                className="btn-cs1 shadow-none"
                variant="contained"
                color="primary"
                style={{ height: "42px", width: "150px" }}
                onClick={() => handleUpdateProducts(setDisplay)}
              >
                <span style={{ fontSize: "16px" }}>Actualizar</span>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
