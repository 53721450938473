import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
//eslint-disable-next-line
import $ from "jquery";
//eslint-disable-next-line
import { Link, useHistory } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import DataTable from "react-data-table-component";
import { CircularProgress } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import CashRegister from "./CashRegister.png";
import Document from "./Document.png";

// components

//data

export default function Sell(props) {
  let history = useHistory();

  useEffect(() => {
    if (props.history.location.state) {
      // This case is pending to be seen if it still exists
      if (typeof props.history.location.state.fromExternal !== "undefined") {
        let currentIdentifier = props.history.location.state.identificador;
        setClient(props.history.location.state.client);
        getSingleSale(currentIdentifier);
      } else {
        //Accessing from sell_step_1
        setInvoiceInfo(props.history.location.state.invoiceInfo);
        setInvoices(props.history.location.state.invoiceInfo.invoicesInfo);
        setClient(props.history.location.state.clientInfo);
        setSeller(props.history.location.state.seller);
        setFelData(props.history.location.state.fel_data);
        setLoader(false);
      }
    } else {
      if (!!localStorage.getItem("fromExternal")) {
        let external_data = JSON.parse(localStorage.getItem("fromExternal"));
        if (!!localStorage.getItem("noClient")) {
          API.clients
            .searchClient({ search_text: external_data.client }) // This has the client nit.
            .then((res) => {
              let response = res.data;
              if (response.success) {
                setClient(response.data);
              } else {
                if (response.message) {
                  toast.error(response.message, { autoClose: 10000 });
                } else {
                  toast.error("Ha ocurrido un error", {
                    autoClose: 10000,
                  });
                }
              }
            })
            .catch((err) => {
              toast.error("Ha ocurrido un error", { autoClose: 10000 });
            });
          localStorage.removeItem("noClient");
        } else {
          setClient(external_data.client);
        }
        setClient(external_data.client);
        getSingleSale(external_data.identificador);
        setFromExternal(true);
        localStorage.removeItem("fromExternal");
      } else {
        setLoader(false);
        toast.error(
          "Ha ocurrido un error al obtener los datos, por favor regrese al catalogo.",
          {
            autoClose: 10000,
          }
        );
      }
    }
  }, []);

  const moment = require("moment");
  // states
  const [seller, setSeller] = useState("");
  const [felData, setFelData] = useState("");
  //eslint-disable-next-line
  const [invoiceInfo, setInvoiceInfo] = useState({
    salesCost: {
      total: 0,
      subtotal: 0,
      iva: 0,
    },
    paidAmount: {
      paid: 0,
      change: 0,
    },
    paidByGiftCard: "",
    paidByCard: "",
    paidByCash: "",
    selectedProducts: [],
  });
  const [client, setClient] = useState({
    id_cliente_web_app: "",
    id_new_empresa: "",
    nombre: "",
    direccion: "",
    email: "",
    nit: "",
    limite_credito: "",
    dias_credito: 0,
  });
  const [invoices, setInvoices] = useState({
    recibo_numero: "",
    adicional_recibo: "",
    gift_card_codigo: "",
    cuotas: "",
  });
  const [loader, setLoader] = useState(true);
  const [fromExternal, setFromExternal] = useState(false);

  const backToCatalogo = () => {
    history.push({
      pathname: "/app/vender",
      state: {
        selectedProducts: invoiceInfo.selectedProducts,
      },
    });
  };

  const openInNewTab = () => {
    localStorage.setItem(
      "factura_data",
      JSON.stringify({
        fel_data: felData,
        selectedProducts: invoiceInfo.selectedProducts,
        seller: seller,
        client: client,
        invoiceInfo,
      })
    );
    const newWindow = window.open(
      "/#/app/vender/factura",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const getSingleSale = (identificador) => {
    API.sales
      .getSingleSale({ identificador: identificador })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Single sale obtained", response.data);
          setSeller({
            id_vendedor_web_app: response.data.vendedor.id_vendedor_web_app,
            nombre: response.data.vendedor.nombre,
          });
          let products = response.data.orderItems;
          setInvoiceInfo({
            salesCost: {
              total: response.data.monto_total,
              subtotal: response.data.subtotal,
              iva: response.data.vat_amount,
            },
            paidAmount: {
              paid:
                response.data.efectivo + response.data.tarjeta !== 0
                  ? parseFloat(
                      (response.data.efectivo + response.data.tarjeta).toFixed(
                        2
                      )
                    )
                  : response.data.monto_total,
              change: response.data.cambios,
            },
            paidByCard: response.data.tarjeta,
            paidByCash: response.data.efectivo,
            paidByGiftCard: 0,
            selectedProducts: products,
            flag_estado: response.data.flag_estado,
          });
          setInvoices({
            recibo_numero: response.data.recibo || "",
            adicional_recibo: response.data.adicional_recibos || [],
            gift_card_codigo: response.data.gift_card_code,
            cuotas: response.data.cuotas,
          });
          setFelData({
            numerofel: response.data.numerofel,
            seriefel: response.data.seriefel,
            fecha: response.data.fecha,
            identificador: response.data.identificador,
            id_dte: response.data.id_dte,
            service_certificador: response.data.service_certificador,
            service_nit: response.data.service_nit,
            service_id: response.data.service_id,
            id_sucursal_bodega_ubicacion:
              response.data.id_sucursal_bodega_ubicacion,
          });
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
        setLoader(false);
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Facturación</strong>
        </h4>
      </div>
      {loader ? (
        <div className="my-auto text-center">
          <CircularProgress size={50} className="mx-auto" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-xl-6">
              <div className="d-flex justify-content-center justify-content-sm-between flex-wrap mb-2">
                <Button
                  className="btn-cs1 mx-1 mb-1"
                  variant="contained"
                  color="primary"
                  startIcon={<img src={Document} alt="cash-register-icon" />}
                  onClick={openInNewTab}
                >
                  <span style={{ marginBottom: "-4px" }}>Ver documento</span>
                </Button>

                <Button
                  className="btn-cs1 mx-1 mb-1"
                  variant="contained"
                  color="primary"
                  startIcon={
                    <img src={CashRegister} alt="cash-register-icon" />
                  }
                  onClick={backToCatalogo}
                >
                  <span style={{ marginBottom: "-4px" }}>Volver a la caja</span>
                </Button>
              </div>
              <div className="content-card card">
                <div className="card-header">
                  <h4 className="mb-0">
                    <strong>Resumen de la compra</strong>
                  </h4>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Fecha / hora</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={
                          moment(felData.fecha).local().format("L") +
                          " / " +
                          moment(felData.fecha).local().format("LTS")
                        }
                        name="fecha"
                        disabled
                      />
                    </div>

                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>NIT de cliente</strong>
                      </p>

                      <TextField
                        className="w-100"
                        variant="standard"
                        name="nit"
                        value={client.nit}
                        disabled
                      />
                    </div>

                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Nombre de cliente</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={client.nombre}
                        name="client_name"
                        disabled
                      />
                    </div>
                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Dirección fiscal del cliente</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={client.direccion}
                        name="direccion_fiscal"
                        disabled
                      />
                    </div>
                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Correo electrónico</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={client.email}
                        name="email"
                        disabled
                      />
                    </div>
                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Vendedor</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={seller.nombre}
                        name="seller"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card content-card mt-2">
                <div
                  className="card-header p-0"
                  id="facturacion-products-header"
                >
                  <DataTable
                    className="datatable-cs datatable-cs-v2 m-0"
                    title={"s"}
                    columns={[
                      {
                        name: "Producto",
                        selector: "id_new_item",
                        sortable: true,
                        cell: (row) => (
                          <div>
                            {row.nombre} <br />
                            {row.codigo}
                          </div>
                        ),
                      },
                      {
                        name: "Precio",
                        selector: "precio",
                        sortable: true,
                        cell: (row) => (
                          <div>
                            Q.{" "}
                            {fromExternal
                              ? (row.precio / row.cantidad).toFixed(2)
                              : row.precio.toFixed(2)}
                          </div>
                        ),
                      },
                      {
                        name: "Cantidad",
                        selector: "cantidad",
                        sortable: true,
                      },
                      {
                        name: "Total",
                        selector: "precio",
                        sortable: true,
                        cell: (row) => (
                          <div>
                            Q.{" "}
                            {fromExternal
                              ? row.precio.toFixed(2)
                              : (row.precio * row.cantidad).toFixed(2)}
                          </div>
                        ),
                      },
                    ]}
                    data={invoiceInfo.selectedProducts}
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se ha encontrado ningún producto"
                  />
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-7">
                      <div className="row">
                        {invoiceInfo.paidByCard !== 0 ? (
                          <div
                            className="col-md-12 mb-2"
                            style={{
                              borderBottom: "1px solid #E0E0E0",
                            }}
                          >
                            <p>
                              <strong>Tarjeta de débito/crédito</strong>
                            </p>
                            <p>Q. {invoiceInfo.paidByCard}</p>
                          </div>
                        ) : (
                          ""
                        )}
                        {invoiceInfo.paidByCash !== 0 ? (
                          <div
                            className="col-md-12 mb-2"
                            style={{
                              borderBottom: "1px solid #E0E0E0",
                            }}
                          >
                            <p>
                              <strong>Efectivo</strong>
                            </p>
                            <p>Q. {invoiceInfo.paidByCash}</p>
                          </div>
                        ) : (
                          ""
                        )}
                        {invoiceInfo.paidByGiftCard !== 0 ? (
                          <div
                            className="col-md-12 mb-2"
                            style={{
                              borderBottom: "1px solid #E0E0E0",
                            }}
                          >
                            <p>
                              <strong>Método de pago seleccionado</strong>
                            </p>
                            <p>Q. {invoiceInfo.paidByGiftCard}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-sm-5 invoice-column">
                      <div className="row h-75">
                        <div className="col-6 p-0 m-0 text-center titles my-auto">
                          <p>SubTotal:</p>
                          {/* <p>IVA (12%):</p> */}
                          <p>Total:</p>
                          <p>Pagado:</p>
                          <p>Vuelto:</p>
                        </div>
                        <div className="col-6 p-0 m-0 text-center my-auto">
                          <p>Q. {invoiceInfo.salesCost.subtotal.toFixed(2)}</p>
                          {/* <p>
                                                        Q. {invoiceInfo.salesCost.iva.toFixed(2)}
                                                    </p> */}
                          <p>Q. {invoiceInfo.salesCost.total.toFixed(2)}</p>
                          <p>Q. {invoiceInfo.paidAmount.paid.toFixed(2)}</p>
                          <p>Q. {invoiceInfo.paidAmount.change.toFixed(2)}</p>
                        </div>
                      </div>
                      <div className="row h-25 justify-content-center w-100 m-0">
                        <Button
                          style={{
                            backgroundColor: "white",
                            color: "#1a2eab",
                          }}
                          className="my-2 w-75 facturar-button"
                          variant="contained"
                        >
                          <span
                            style={{
                              marginBottom: "-4px",
                              textTransform: "none",
                            }}
                          >
                            Pagado
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {invoices.recibo_numero !== "" || invoices.gift_card_codigo !== "" ? (
            <div className="row mt-3">
              <div className="col-12">
                <div className="content-card card">
                  <div className="card-header">
                    <h4>
                      <strong>Verificación del pago</strong>
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {invoices.recibo_numero !== "" ? (
                        <>
                          <div className="col-md my-auto">
                            <p>
                              <strong>
                                Número del recibo (Operación con tarjeta)
                              </strong>
                            </p>
                            <TextField
                              className="w-100"
                              variant="standard"
                              value={invoices.recibo_numero}
                              name="recibo_numero"
                              disabled
                              placeholder="Ingrese el número del recibo emitido por el pto. de venta"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    N°
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                              />
                              <label
                                class="form-check-label ml-2"
                                for="defaultCheck1"
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                Ingresar manualmente
                              </label>
                            </div>
                          </div>
                          <div className="col-md my-auto">
                            <p>
                              <strong>Número de cuotas</strong>
                            </p>

                            <Select
                              native
                              value={invoices.cuotas}
                              style={{ width: "100%" }}
                              variant="outlined"
                            >
                              <option value={invoices.cuotas}>
                                {invoices.cuotas === "0"
                                  ? "Pago único"
                                  : invoices.cuotas + " meses"}
                              </option>
                            </Select>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {invoices.gift_card_codigo !== "" ? (
                        <div className="col-md my-auto">
                          <p>
                            <strong>Ingrese el código de Gift Card</strong>
                          </p>
                          <TextField
                            className="w-100"
                            variant="standard"
                            value={invoices.gift_card_codigo}
                            name="giftcard_code"
                            placeholder="     -      -      -"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {invoices.adicional_recibo.length > 0 ? (
                        <div className="col-md-12 mt-5 pl-5">
                          <div className="row w-100 mt-2 justify-content-center justify-content-md-start">
                            {invoices.adicional_recibo.map((invoice) => (
                              <div className="col-xl-4 px-1">
                                <TextField
                                  className="w-100"
                                  variant="standard"
                                  disabled
                                  value={invoice}
                                  name="invoice_number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        Recibo N°
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
