import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const HistorialVentasReporte = ({ objeto }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table
        className="tableReporte"
        id="historialAnulacionOperaciones-reporte-table"
      >
        <thead>
          <tr>
            <th>N° anulación</th>
            <th>Fecha / Hora</th>
            <th>Nombre del cliente</th>
            <th>Motivo</th>
            <th>Monto</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            console.log(x);

            return (
              <tr key={index}>
                <td>{x.identificador}</td>
                <td>
                  {moment(new Date(x.fecha))
                    .utcOffset(0)
                    .format("DD/MM/YYYY h:mm:ss a")}{" "}
                </td>
                <td>{x.client.nombre}</td>
                <td>{x.motivo} </td>
                <td>
                  {moneda}.{" "}
                  {formaterPriceFunction(x.monto_total.toFixed(2) + "")}{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default HistorialVentasReporte;
