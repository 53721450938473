import React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { useAdminState } from "../context/AdminContext";
// context
import { useUserState } from "../context/UserContext";
import Admin_codeValidation from "../pages/admin/auth/confirm_code";
/* Admin pages */
import AdminLogin from "../pages/admin/auth/login";
import Admin_passwordRecovery from "../pages/admin/auth/password_recovery";
import Admin_resetPassword from "../pages/admin/auth/update_password";
// pages
import Error from "../pages/error";
import Login from "../pages/login";
/* Email PasswordRecovery */
import PasswordRecovery_email_step1 from "../pages/password_recovery_email/PasswordRecovery_email_step1";
import PasswordRecovery_email_step2 from "../pages/password_recovery_email/PasswordRecovery_email_step2";
/* General password recovery step */
import PasswordRecovery_step3 from "../pages/password_recovery_email/PasswordRecovery_step3";
import PasswordRecovery_verified from "../pages/password_recovery_email/PasswordRecovery_verified";
/* Phone PasswordRecovery */
import PasswordRecovery_phone_step1 from "../pages/password_recovery_phone/PasswordRecovery_phone_step1";
import PasswordRecovery_phone_step2 from "../pages/password_recovery_phone/PasswordRecovery_phone_step2";
import Register from "../pages/register";
import Payment from "../pages/register/Payment";
/* Subscription page */
import Subscriptions from "../pages/subscriptions/Subscriptions";
// components
import Layout from "./Layout";
import AdminLayout from "./Layout/AdminLayout";
import Toastr from "./Toastr/Toastr";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  var { isAdminAuthenticated } = useAdminState();
  return (
    <HashRouter>
      <Toastr />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/plans" component={Subscriptions} />
        <PublicRoute exact path="/register" component={Subscriptions} />
        <PublicRoute exact path="/register/:plan" component={Register} />
        <PrivateRoute exact path="/payment" component={Payment} />
        <PublicRoute exact path="/payments" component={Payment} />
        <PublicRoute
          path="/forgot/email-1"
          component={PasswordRecovery_email_step1}
        />
        <PublicRoute
          path="/forgot/email-2"
          component={PasswordRecovery_email_step2}
        />
        <PublicRoute
          path="/forgot/verified"
          component={PasswordRecovery_verified}
        />
        <PublicRoute path="/forgot/reset" component={PasswordRecovery_step3} />
        <PublicRoute
          path="/forgot/phone-1"
          component={PasswordRecovery_phone_step1}
        />
        <PublicRoute
          path="/forgot/phone-2"
          component={PasswordRecovery_phone_step2}
        />

        {/*  Admin */}
        <PublicRoute path="/admin/login" component={AdminLogin} />
        <PublicRoute
          exact
          path="/admin/forgot/email"
          component={Admin_passwordRecovery}
        />
        <PublicRoute
          exact
          path="/admin/forgot/verify"
          component={Admin_codeValidation}
        />
        <PublicRoute
          exact
          path="/admin/forgot/reset"
          component={Admin_resetPassword}
        />
        <Route
          exact
          path="/admin"
          render={() => <Redirect to="/admin/dashboard" />}
        />
        <AdminRoute path="/admin" component={AdminLayout} />

        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }

  function AdminRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAdminAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/admin/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }
}
