import { base_url, axios } from "../api";

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getAllDataMermas = async (search, type, start, end) => {
  var currentTime = new Date();
  let year = currentTime.getFullYear();
  var month = currentTime.getMonth() + 1;
  var day = currentTime.getDate();

  if (start && end) {
    console.log(
      start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getDate(),
      end
    );
  }

  return await axios.get(`${base_url}/web/waste-graph`, {
    headers: headers(),
    params: {
      year: year,
      month: month,
      product: search,
      type_request: type,
      day: day,
      start:
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1 < 10 ? 0 + "" : "") +
        (start.getMonth() + 1) +
        "-" +
        (start.getDate() < 10 ? 0 + "" : "") +
        start.getDate(),
      end:
        end.getFullYear() +
        "-" +
        (end.getMonth() + 1 < 10 ? 0 + "" : "") +
        (end.getMonth() + 1) +
        "-" +
        (end.getDate() < 10 ? 0 + "" : "") +
        end.getDate(),
    },
  });
};

export default {
  getAllDataMermas,
};
