import { TextField } from "@material-ui/core";
import React, { useState } from "react";

const ChildrenInput = ({
  initialValue,
  id,
  setCurrentCell,
  currentCell,
  handleAproxQty,
  realId,
}) => {
  const onChange = (e) => {
    handleAproxQty(realId, parseFloat(e.target.value));
  };

  return (
    <TextField
      autoFocus={id === currentCell}
      type="number"
      onSelect={() => setCurrentCell(id)}
      value={initialValue}
      onChange={onChange}
    />
  );
};

export default ChildrenInput;
