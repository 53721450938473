import {
  CLEAR_STATE,
  CREATE_ALEKO_ORDER,
  DELETE_PRODUCT,
  HANDLE_DELETE_AREA,
  HANDLE_DELETE_FILE,
  HANDLE_DELETE_VARIANT,
  HANDLE_FORMS,
  HANDLE_PERSONALIZATIONS,
  HANDLE_SAVE_POINTS,
  HANDLE_SAVE_VARIANTS,
  SAVE_SELECTED_FIELD,
  SET_CURRENT_PRODUCT,
  SET_ORDER,
  SET_VALIDATION_FALSE,
  SET_VALIDATION_TRUE,
  //-------------------
  DISABLE_DISCUNT,
  ENABLE_CUSTOMIZATION,
} from "./types";

export const ProductPersonalizationReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case HANDLE_SAVE_POINTS:
      const prevProduct = state.prevProducts.some(
        (item) => item.name === action.name
      );
      if (prevProduct) {
        return {
          ...state,
          product: {
            ...state.product,
            perspectives: action.payload,
          },
          prevProducts: state.prevProducts.map((item) =>
            item.name === action.name
              ? { ...item, perspectives: action.payload }
              : item
          ),
        };
      } else {
        return {
          ...state,
          product: {
            ...state.product,
            perspectives: action.payload,
          },
          prevProducts: [
            ...state.prevProducts,
            { name: action.name, perspectives: action.payload },
          ],
        };
      }

    case SAVE_SELECTED_FIELD:
      const prevField = state.selectedFields.some(
        (field) =>
          field.id === action.payload.id &&
          field.variant === action.payload.variant
      );
      if (!prevField) {
        return {
          ...state,
          selectedFields: [...state.selectedFields, action.payload],
        };
      }
      return {
        ...state,
        selectedFields: state.selectedFields.map((field) =>
          field.id === action.payload.id &&
          field.variant === action.payload.variant
            ? { ...field, ...action.payload }
            : field
        ),
      };
    case CREATE_ALEKO_ORDER:
      return {
        ...state,
        id_new_pedido: action.payload.id_new_pedido,
      };

    case CLEAR_STATE:
      return {
        id_new_pedido: null,
        product: {
          name: "",
          perspectives: [],
        },
        prevProducts: [],

        forms: [],
        savedVariants: [],
        validation: false,
        order: {
          firma: null,
          id_pedido: "",
          valido_hasta: new Date(),
        },
        personalizations: [],
        addProductWithDiscount: true,
        enable_customization: false,
      };

    case HANDLE_FORMS:
      const prevForm = state.forms.some(
        (item) => item.variantId === action.payload.variantId
      );
      if (prevForm) {
        return {
          ...state,
          forms: state.forms.map((form) =>
            form.variantId === action.payload.variantId
              ? { ...form, ...action.payload }
              : form
          ),
        };
      } else {
        return {
          ...state,
          forms: [...state.forms, action.payload],
        };
      }

    case HANDLE_SAVE_VARIANTS:
      const prevVariant = state.savedVariants.some(
        (item) => item.variantId === action.payload.variantId
      );
      if (prevVariant) {
        return {
          ...state,
          savedVariants: state.savedVariants.map((variant) =>
            variant.variantId === action.payload.variantId
              ? action.payload
              : variant
          ),
        };
      }
      return {
        ...state,
        savedVariants: [...state.savedVariants, action.payload],
      };

    case HANDLE_DELETE_FILE:
      let newForm = state.forms.find((form) => form.variantId === action.id);
      delete newForm[`adjunto_${action.payload + 1}`];
      return {
        ...state,
        forms: state.forms.map((form) =>
          form.variantId === action.id ? newForm : form
        ),
      };

    case HANDLE_DELETE_AREA:
      const currentForm = state.forms.find(
        (form) => form.variantId === action.id
      );

      if (action.payload === action.length - 1) {
        for (let key in currentForm) {
          if (key.includes(action.payload + 1) && !key.includes("variante")) {
            delete currentForm[key];
          }
        }
        return {
          ...state,
          forms: state.forms.map((form) =>
            form.variantId === action.id ? currentForm : form
          ),
        };
      }

      for (let key in currentForm) {
        if (key.includes(action.payload + 1) && !key.includes("variante")) {
          delete currentForm[key];
        }
      }

      let newerForm = {};
      for (let key in currentForm) {
        if (key.includes(action.payload + 2) && !key.includes("variante")) {
          const newKey = `${key.slice(0, -1)}${action.payload + 1}`;
          newerForm = {
            ...currentForm,
            ...newerForm,
            [newKey]: currentForm[key],
          };
        }
      }
      for (let key in newerForm) {
        if (key.includes(action.payload + 2) && !key.includes("variante")) {
          delete newerForm[key];
        }
      }

      return {
        ...state,
        forms: state.forms.map((form) =>
          form.variantId === action.id ? newerForm : form
        ),
      };

    case HANDLE_DELETE_VARIANT:
      return {
        ...state,
        forms: state.forms.filter((form) => form.variantId !== action.payload),
        savedVariants: state.savedVariants.filter(
          (variant) => variant.variantId !== action.payload
        ),
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        forms: state.forms.filter(
          (form) => form.id_new_item !== action.payload
        ),
        savedVariants: state.savedVariants.filter(
          (variant) => variant.id_new_item !== action.payload
        ),
      };

    case SET_VALIDATION_TRUE:
      return {
        ...state,
        validation: true,
      };
    case SET_VALIDATION_FALSE:
      return {
        ...state,
        validation: false,
      };

    case SET_ORDER:
      return {
        ...state,
        order: {
          ...state.order,
          [action.payload.name]: action.payload.value,
        },
      };
    case HANDLE_PERSONALIZATIONS:
      return {
        ...state,
        personalizations: action.payload,
      };

    case DISABLE_DISCUNT:
      return {
        ...state,
        addProductWithDiscount: !state.addProductWithDiscount,
      };

    case ENABLE_CUSTOMIZATION:
      return { ...state, enable_customization: !state.enable_customization };

    default:
      return state;
  }
};
