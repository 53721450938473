// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Select from "@material-ui/core/Select";
import DataTable from "react-data-table-component";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

// components

//data

export default function Users_all(props) {
  let history = useHistory();

  // states
  const [cajas, setCajas] = useState([]);
  const [sucursalLoader, setSucursalLoader] = useState(true);
  const [sucursal, setSucursal] = useState({
    codigo_sucursal: "",
    nombre_sucursal: "",
    direccion_sucursal: "",
    telefono_sucursal: "",
    id_sucursal_bodega_ubicacion: "",
  });
  const [priceLevels, setPriceLevels] = useState([]);

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
    };
  }, []);

  useEffect(() => {
    getPriceLevels();
    getSucursals();
  }, []);

  const getSucursals = () => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          let filteredSucursals = response.data.filter(
            (x) =>
              x.id_sucursal_bodega_ubicacion === parseInt(props.match.params.id)
          );
          console.log("Obtained data:", filteredSucursals[0]);
          setSucursal(filteredSucursals[0]);
          setCajas(filteredSucursals[0].cajas);
          setSucursalLoader(false);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getPriceLevels = () => {
    API.priceLevels
      .getAll()
      .then((res) => {
        let response = res.data;
        setPriceLevels(response.data);
        if (!response.success) {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error(
              "Ha ocurrido un error al cargar los niveles de precio del sistema",
              {
                autoClose: 10000,
              }
            );
          }
        }
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al cargar los niveles de precio del sistema",
          { autoClose: 10000 }
        );
      });
  };

  const updateCajaLevel = (caja) => {
    API.sucursal
      .updateCaja({
        id_sucursal_bodega_ubicacion: parseInt(props.match.params.id),
        id_caja_web_app: caja.id_caja_web_app,
        caja_nombre: caja.descripcion,
        nivel_precio: caja.id_nivel_precio,
      })
      .then((res) => {
        let response = res.data;

        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error(
              "Ha ocurrido un error al cargar los niveles de precio del sistema",
              {
                autoClose: 10000,
              }
            );
          }
        }
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al cargar los niveles de precio del sistema",
          { autoClose: 10000 }
        );
      });
  };

  const updateCajaElement = (caja, event) => {
    const parsedValue = parseInt(event.target.value);
    let existingCajas = [...cajas];
    let index = existingCajas.findIndex(
      (x) => x.id_caja_web_app === caja.id_caja_web_app
    );
    existingCajas[index].id_nivel_precio = parsedValue;
    setCajas(existingCajas);
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => props.history.goBack()}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Configuración de cuenta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Niveles de precios de cajas</strong>
        </h4>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          {sucursalLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <h5 className="mb-0">
                <strong>
                  Edite los niveles de precios de las cajas de la sucursal{" "}
                  {sucursal.nombre_sucursal}
                </strong>
              </h5>
            </div>
          )}
        </div>
        <div className="card-body">
          {sucursalLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div className="row mb-4">
              <DataTable
                className="datatable-cs"
                title={"s0"}
                columns={[
                  {
                    name: "Nombre",
                    selector: "descripcion",
                    sortable: true,
                  },
                  {
                    name: "Nivel de precio",
                    selector: "id_nivel_precio",
                    cell: (row) => (
                      <div className="mx-auto py-3">
                        <Select
                          native
                          variant="outlined"
                          defaultValue={row.id_nivel_precio}
                          onChange={(e) => updateCajaElement(row, e)}
                        >
                          <option value="">
                            Seleccione un nivel de precios
                          </option>
                          {priceLevels.map((level) => (
                            <option value={level.id_nivel_precio}>
                              {level.descripcion}
                            </option>
                          ))}
                        </Select>
                      </div>
                    ),
                  },
                  {
                    name: "Acción",
                    selector: "id_nivel_precio",
                    cell: (row) => (
                      <div className="mx-auto">
                        <Button
                          className="btn-cs1 mt-1 mx-1"
                          variant="contained"
                          color="primary"
                          onClick={() => updateCajaLevel(row)}
                          type="button"
                        >
                          Actualizar
                        </Button>
                      </div>
                    ),
                  },
                ]}
                data={cajas}
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se encontraron cajas"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
