import React, { useState, useEffect, useContext } from "react";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import formatRecibo from "../formats_pdf/format_printer";

//eslint-disable-next-line
import { Link, useHistory } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import DataTable from "react-data-table-component";
import { CircularProgress } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";

import CashRegister from "./CashRegister.png";
import Document from "./Document.png";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print";
import "moment/locale/es";
import print from "print-js";

// components

// validaciones
import { isCmi } from "../../helpers/isCmi";
import { getMoneda } from "../../helpers/moneda";
import FacturaHonduras from "./FacturaHonduras";
import ModalAlerta from "../../components/ModalAlert";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";
import { validarReimpresion } from "../../api/sales";
import { useParams } from "react-router";
import { getUsuariosGerentes } from "../../api/profile";
import { isAleko } from "../../helpers/isAleko";
//data
const orderItems = [
  {
    cantidad: 1,
    codigo: "SKU0002",
    descripcion: "GASEOSA POPULAR",
    documento: 148,
    fecha: "2021-02-26T15:37:48.000Z",
    id_movimiento_items: 725,
    id_new_empresa: 67,
    id_new_item: 453,
    id_pos_movimiento: 705,
    id_tipo_documento_web_app: 1,
    imagen_1: "images021-02-24T23-01-07.093Z-$img_1$descarga.jpg",
    nombre: "GASEOSA INKA",
    precio: 3.8,
    serie: "FA6772",
  },
];
const ref = React.createRef();

export default function Sell(props) {
  const params = useParams();
  const moneda = getMoneda();
  let history = useHistory();
  const moment = require("moment");
  // states
  let [felData, setFelData] = useState(null);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [clientName, setClientName] = useState(null);
  const [fromExternal, setFromExternal] = useState(false);
  const [isPrincipalRetainer, setisPrincipalRetainer] = useState(false);
  const [descuentos, setDescuentos] = useState([]);
  const [directions, setDirections] = useState({});
  const [gerente, setGerente] = useState(false);
  const [isCF, setIsCF] = useState(false);
  const convertir = require("numero-a-letras");

  const conversor = require("conversor-numero-a-letras-es-ar");
  let ClaseConversor = conversor.conversorNumerosALetras;
  let miConversor = new ClaseConversor();
  const { setActivarModalAlerta } = useContext(CmiProductContext);

  // config ti guatemala
  const [isGuatemala, setIsGuatemala] = useState(false);
  const [tipoContribuyente, setTipoContribuyente] = useState("");
  const [isGranContribuyente, setIsGranContribuyente] = useState(false);
  const [isExento, setIsExento] = useState(false);
  const [printCheck, setPrintCheck] = useState(false);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    getSingleSale(props.match.params.id);
    /*
        if (props.history.location.state) {
            // This case is pending to be seen if it still exists
            if (
                typeof props.history.location.state.fromExternal !==
                "undefined"
            ) {
                let currentIdentifier = props.history.location.state.identificador;
                getSingleSale(currentIdentifier)
            } else {
                //Accessing from sell_step_1
                let data  = props.history.location.state.fel_data
                if(data.efectivo !== data.monto_total){
                    data.cambios = data.efectivo - data.monto_total
                }else{
                    data.cambios = 0
                }
                console.log(data)
                
                //console.log(data.cambios)
                setFelData(data);
                //felData = data
                //console.log(props.history.location.state.fel_data)
            
                setLoader(false);
            }
        } else {
            if (props.match.params.id) {
                getSingleSale(props.match.params.id)
            } else {
                setLoader(false)
                toast.error(
                    "Ha ocurrido un error al obtener los datos, por favor regrese al catalogo.",
                    {
                        autoClose: 10000,
                    }
                );
            }
        }*/
    return () => {
      setFelData(null);
      setDescuentos([]);
    };
  }, []);

  useEffect(() => {
    //console.log('feldata', felData)

    const dataClient = JSON.parse(localStorage.getItem("feldata"));
    console.log("client", dataClient);
    if (dataClient) {
      //console.log('dataClient ->', dataClient)
      console.log("client", dataClient);
      setClientName(dataClient);
      //console.log('feldata22', JSON.parse(localStorage.getItem('feldata')))
    } else if (felData) {
      setClientName(felData.client);
    }
  }, [felData]);

  useEffect(() => {
    if (descuentos.length && isCmi() && gerente && printCheck) {
      printSale();
    }
  }, [descuentos, gerente, printCheck]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (comeback) => {
    setOpen(false);

    if (comeback) {
      window.localStorage.removeItem("granContribuyente");
      window.localStorage.removeItem("cod_contribuyente");
      window.localStorage.removeItem("datosCliente");
      window.localStorage.removeItem("id_post_movimiento_posfactura");
    }

    if (comeback) {
      history.push("/app/vender");
    }
  };

  const getDescuentos = async () => {
    try {
      let id_local_empresa = window.localStorage.getItem("id_empresa");

      if (id_local_empresa) {
        const res = await API.userConfig.getDescuentosCanalesVentas(
          id_local_empresa
        );

        if (res?.data?.ok) {
          console.log("Descuentos saved", res.data.data);
          setDescuentos(res.data.data);
        } else {
          console.log("Error al obtener descuentos");
          toast.error("Error al obtener descuentos", { autoClose: 10000 });
        }
      } else {
        toast.error("No existe id de la empresa", { autoClose: 10000 });
      }
    } catch (error) {
      console.log("Error al obtener descuentos", error);
      toast.error("Error al obtener descuentos ", { autoClose: 10000 });
    }
  };

  const backToCatalogo = () => {
    window.localStorage.removeItem("datosCliente");
    window.localStorage.removeItem("id_post_movimiento_posfactura");
    history.push({
      pathname: "/app/vender",
    });
  };

  const openInNewTab = () => {
    localStorage.setItem(
      "factura_data",
      JSON.stringify({
        fel_data: felData,
      })
    );
    //sconsole.log("Sending", felData)
    const newWindow = window.open(
      "/#/app/vender/factura",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const printSale = () => {
    print({
      printable: "printform",
      type: "html",
      targetStyles: ["*"],
      modalMessage: "Imprimiendo",
      showModal: true,
      // font_size:"8px",
      onLoadingEnd: function () {
        setTimeout(() => setOpen(true), 2000);
      },
    });
  };

  const getsucursalprincipalretainers = () => {
    API.sales
      .getsucursalprincipalretainers({
        idBodegaSucursal: felData.id_sucursal_bodega_ubicacion,
      })
      .then((res) => {
        let response = res.data;
        console.log("prueba", response);

        setisPrincipalRetainer(response.data.grandes_retenedores);
        setPrintCheck(true);
        if (!isCmi()) {
          printSale();
        }
      })
      .catch((err) => {
        if (!isCmi()) {
          printSale();
        }
        setPrintCheck(true);
      });
  };

  const getSingleSale = (id) => {
    API.sales
      .getSingleSale({ id_pos_movimiento: id })
      .then((res) => {
        let response = res.data;

        if (response.success) {
          let data = response.data;
          if (data.pago_method === "A CREDITO") {
            data.cambios = 0;
          }

          console.log("feldata ->", data);

          if (data.client.nit.toLowerCase() == "cf") {
            setIsCF(true);
          }

          if (data.granContribuyente) {
            setIsGranContribuyente(data.granContribuyente);
          }
          setFelData(data);
          felData = data;
          getsucursalprincipalretainers();

          // cmi
          getDescuentos();
          getDirecctions();

          // guatemala
          console.log(
            "guate2",
            data.service_certificador?.toLowerCase().includes("guatefacturas")
          );
          if (
            data.service_certificador?.toLowerCase().includes("guatefacturas")
          ) {
            setIsGuatemala(true);
          }

          // validar tipo contribuyente
          const tipo_contribuyente = JSON.parse(
            window.localStorage.getItem("cod_contribuyente")
          );

          const contribuyente = JSON.parse(
            window.localStorage.getItem("granContribuyente")
          );

          if (tipo_contribuyente != null) {
            setTipoContribuyente(tipo_contribuyente);
          }

          if (contribuyente) {
            setIsGranContribuyente(true);
          }

          if (data.documento_exento === null) {
            setIsExento(false);
          } else {
            setIsExento(true);
          }
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("error data", err);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
        setLoader(false);
      });
  };

  const getDescuentoActual = (codigo_entrega, descuento = true) => {
    let des = descuentos.filter((item) => item.codigo == 5);
    // console.log("des actual", des[0].costo_descuento);
    console.log("descuentos", descuentos);
    if (des.length === 0) return 0;

    if (descuento) {
      return des[0].costo_descuento;
    } else {
      return des[0].costo_adicional;
    }
  };

  const sumarMontos = () => {
    let montos = felData.orderItems.map(
      (product) => product.descuento_precio || product.precio
    );
    let sumaMontos = montos.reduce((acc, el) => acc + el, 0);

    let descuentos =
      felData.tipo_venta == 5
        ? getDescuentoActual(felData.tipo_venta, false)
        : getDescuentoActual(felData.tipo_venta);
    console.log("fel", felData.monto_total);
    return Number(felData.monto_total);
    return Number(sumaMontos) + Number(descuentos); /// last return
  };

  const getVuelto = () => {
    let vuelto = 0;
    let total = sumarMontos();

    let totalSinDescuento =
      getTotalIVA() - felData.tipo_venta == 5
        ? getDescuentoActual(felData.tipo_venta, false)
        : getDescuentoActual(felData.tipo_venta);

    let pago = (
      felData.pago_method !== "A CREDITO"
        ? felData.efectivo +
          felData.tarjeta +
          felData.gift_card +
          felData.credito
        : felData.credito
    ).toFixed(2);
    console.log("aaa", pago);
    if (pago > total - totalSinDescuento) {
      vuelto = pago - (total - totalSinDescuento);

      if (felData.tipo_venta !== 5) {
        return vuelto;
      }

      return vuelto - getDescuentoActual(felData.tipo_venta, false);
    }
    console.log("pase");
    return vuelto;
  };

  const getTotalFactura = () => {
    let total = sumarMontos();

    let des =
      felData.tipo_venta == 5
        ? getDescuentoActual(felData.tipo_venta, false)
        : getDescuentoActual(felData.tipo_venta);

    if (felData.tipo_venta == 5) return Number(total).toFixed(2);

    if (felData.tipo_venta != 5) {
      let totalObtenido = Number(total) - Number(des);
      return totalObtenido.toFixed(2);
    }
  };

  const getExentoIVA = () => {
    const IVA = 1.12;
    const porcentajeExento = 0.12;
    let subTotal = Number(getTotalFactura());
    return (subTotal / IVA) * porcentajeExento;
  };

  const getTotalIVA = () => {
    let subTotal = Number(getTotalFactura());
    let exento = getExentoIVA();
    return subTotal - exento;
  };

  const getMetodoEntrega = (codigo) => {
    let des = descuentos.filter((item) =>
      item.codigo === codigo ? item.nombre : ""
    );
    return des[0].nombre;
  };

  const getDirecctions = () => {
    if (felData.direcciones !== "") {
      let objDirecctions = JSON.parse(felData.direcciones);
      setDirections(objDirecctions[0]);
    }
  };

  const getCambioPorEfectivo = () => {
    console.log("descuento ", getDescuentoActual(felData.tipo_venta));
    let formaPago = felData.id_forma_pago;
    let efectivo = felData.efectivo;
    let totalPagar = getTotalIVA().toFixed(2);

    if (formaPago == 1) {
      if (efectivo > totalPagar) {
        return efectivo - totalPagar;
      } else {
        return 0;
      }
    }
  };

  const getTipoContribuyente = () => {
    const data =
      tipoContribuyente == "1"
        ? "Factura"
        : tipoContribuyente == "2"
        ? "Factura cambiaria"
        : tipoContribuyente == "3"
        ? "Factura pequeño contribuyente"
        : tipoContribuyente == "4"
        ? "Factura cambiaria pequeño contribuyente"
        : tipoContribuyente == "9"
        ? "Nota de débito"
        : tipoContribuyente == "10"
        ? "Nota de crédito"
        : "Factura";

    return data;
  };

  const comebackSell = (event) => {
    if (event.key === "Enter") {
      if (open) {
        handleClose(true);
      }
    }
  };

  useEffect(() => {
    if (felData && isCmi()) {
      const validarImpresion = async () => {
        const id_new_empresa = localStorage.getItem("id_empresa");
        const id_new_user = JSON.parse(localStorage.getItem("pms")).id_new_user;
        try {
          await validarReimpresion({
            id_document: params.id,
            type_document: "Venta",
            id_new_empresa,
            id_new_user,
          });
          setGerente(true);
        } catch (error) {
          console.log("error", error);
          const tipo_usuario = JSON.parse(
            localStorage.getItem("pms")
          ).tipo_usuario;
          if (tipo_usuario.toLowerCase().includes("administrador")) {
            setGerente(true);
          } else {
            setGerente(false);
          }
        }
      };
      validarImpresion();
    }
  }, [felData]);

  return (
    <div id="dashboard-section">
      <Dialog
        open={open}
        onClose={handleClose}
        onKeyPress={comebackSell}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"CLOUD"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea regresar a Caja Venta?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleClose(true)}
            color="primary"
          >
            Sí
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClose(false)}
            style={{ backgroundColor: "#ff3f3f", color: "white" }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: "none" }}>
        {felData ? (
          <div
            className="d-flex justify-content-center"
            id="printform"
            style={{ backgroundColor: "red", lineHeight: "normal" }}
          >
            {moneda !== "L" && (
              <div className="mt-4">
                <div ref={ref} style={{ backgroundColor: "white" }}>
                  <div className="row justify-content-center">
                    {felData.identificador.includes("Anulado") ? (
                      <div
                        style={{
                          marginTop: "100px",
                          position: "fixed",
                        }}
                      >
                        {/*<strong style={{ color: "red"}}>FACTURA ANULADA</strong>*/}
                        <img src="/images/anulado.png" width="300" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row justify-content-center">
                    <div className="row col-12 justify-content-center">
                      {
                        isCmi() ? "" : ""
                        // <div className="col-4">
                        //   <img
                        //     className="ml-5"
                        //     src={"/images/cloudLogo.png"}
                        //     style={{
                        //       width: "100%",
                        //       objectFit: "cover",
                        //     }}
                        //     alt="factura-imagen"
                        //   />
                        // </div>
                      }
                      {isAleko() ? (
                        <div className="col-12 ml-5 d-flex justify-content-center">
                          <img
                            className="mb-0"
                            src={"/images/logo_aleko_v1.png"}
                            alt="factura-imagen"
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-12 text-center mx-auto mb-0">
                        <p className={`mb-0 ${isCmi() ? "" : "ml-5"}`}>
                          <strong>{felData.empresa.nombre}</strong>
                        </p>
                        <p className={`mb-0 ${isCmi() ? "" : "ml-5"}`}>
                          <strong>
                            NIT:{" "}
                            {isGuatemala
                              ? felData.service_nit
                                ? felData.service_nit.slice(
                                    0,
                                    felData.service_nit.length - 1
                                  ) +
                                  "-" +
                                  felData.service_nit.slice(
                                    felData.service_nit.length - 1,
                                    felData.service_nit.length
                                  )
                                : ""
                              : felData.empresa.nit}
                          </strong>
                        </p>

                        {!isCmi() && (
                          <>
                            <p className="mb-0 text-center">
                              {`${felData.sucursal.nombre_sucursal}`}
                            </p>
                          </>
                        )}
                        {isGuatemala ? (
                          <>
                            <p className="mb-0 mx-auto">
                              {felData.sucursal.nombre_sucursal}
                            </p>
                            <p className="mb-0 ml-5">
                              {felData.sucursal.direccion_sucursal}
                            </p>
                          </>
                        ) : (
                          <p className="mb-0 ml-5">
                            {felData.sucursal.direccion_sucursal}
                          </p>
                        )}

                        {isCmi() ? (
                          ""
                        ) : (
                          <p className={`mb-0 ${isCmi() ? "" : "ml-5"}`}>
                            {felData.empresa.telefono}
                          </p>
                        )}
                        {isCmi() ? (
                          <>
                            {isGuatemala ? (
                              ""
                            ) : felData.service_certificador ? (
                              <label className="mb-0 text-center">
                                <strong> NÚMERO DE AUTORIZACIÓN </strong>
                              </label>
                            ) : null}
                            {/* {felData.service_id ? (
                              <p className="mb-0 text-center">
                                {felData.service_id}
                              </p>
                            ) : null} */}

                            <br />

                            <p className="text-center mb-0">
                              <strong>DOCUMENTO TRIBUTARIO ELECTRÓNICO</strong>
                            </p>

                            {isGuatemala ? (
                              <p
                                className="m-1 text-center text-uppercase"
                                style={{
                                  fontSize: "4px",
                                }}
                              >
                                <strong>
                                  {felData.identificador.includes("Anulado")
                                    ? "FACTURA ANULADA"
                                    : getTipoContribuyente()}
                                </strong>
                              </p>
                            ) : (
                              <p className="mb-0 text-center">
                                <strong>*** Factura Electrónica ***</strong>
                              </p>
                            )}

                            {felData.seriefel ? (
                              <p className="mb-0">
                                <strong>
                                  {isGuatemala ? "SERIE:" : "Serie FEL:"}
                                </strong>{" "}
                                {felData.seriefel}
                              </p>
                            ) : null}
                            {felData.numerofel ? (
                              <p className="mb-0">
                                <strong>
                                  {isGuatemala ? "NÚMERO:" : "NÚMERO FEL:"}
                                </strong>{" "}
                                {felData.numerofel}
                              </p>
                            ) : null}

                            {isGuatemala ? (
                              felData.service_certificador ? (
                                <label className="mb-0 text-center">
                                  <strong> NÚMERO DE AUTORIZACIÓN </strong>
                                </label>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            {isGuatemala ? (
                              felData.service_id ? (
                                <p className="text-center mb-0">
                                  {felData.service_id}
                                </p>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}

                            <hr
                              style={{
                                borderTop: "3px dashed #bbb",
                                marginBottom: 0,
                              }}
                            />

                            {/* {isGuatemala ? (
                              ''
                            ) : (
                              <p className="mb-0 text-center">
                                <strong>Control: </strong> PBX: 2503 - 3333
                              </p>
                            )} */}

                            {isGuatemala ? (
                              ""
                            ) : (
                              <p className="mb-0 text-center">
                                <strong>Dirección:</strong>
                                {" " + felData.client.direccion}
                              </p>
                            )}

                            {/* <p className="text-center mb-0">
                                <strong>Control: </strong> PBX: 2503 - 3333
                              </p> */}

                            {isGuatemala ? "" : ""}
                            {isGuatemala ? (
                              <div className="ml-2 text-center">
                                <p className="mb-0">
                                  <strong>FECHA DE EMISIÓN: </strong>
                                  {felData?.fecha?.split("T")[0].split("-")[2] +
                                    "/" +
                                    felData?.fecha
                                      ?.split("T")[0]
                                      .split("-")[1] +
                                    "/" +
                                    felData?.fecha?.split("T")[0].split("-")[0]}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}

                            <p className="mb-0 text-center">
                              <strong>NIT:</strong>
                              <br />
                              {" " + felData.client.nit}
                            </p>
                            <p className="mb-0 text-center">
                              <strong>NOMBRE:</strong>
                              <br />
                              {isCF && clientName?.nombre_cliente_final
                                ? clientName?.nombre_cliente_final
                                : clientName?.nombre
                                ? clientName.nombre
                                : " " + felData.client.nombre}
                            </p>
                            {isGuatemala ? (
                              <p className="mb-0 text-center">
                                <strong className="text-uppercase">
                                  Dirección:
                                </strong>
                                <br />
                                {" " + felData.client.direccion}
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <hr
                      style={{
                        borderTop: "3px dashed #bbb",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    />

                    <div className="col-12">
                      {isCmi() ? (
                        ""
                      ) : (
                        <>
                          <p className="text-center m-0 p-0">
                            <strong>DOCUMENTO TRIBUTARIO ELECTRONICO</strong>
                          </p>
                          <p className="text-center m-0 p-0">
                            <strong>*** Factura Electrónica ***</strong>
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="mx-auto">
                      {/*<p className="mb-0">
                      <strong>Factura:</strong> {felData.serie}
                </p>*/}
                      {isCmi() ? (
                        ""
                      ) : (
                        <>
                          {felData.seriefel ? (
                            <p className="mb-0">
                              <strong>Serie FEL:</strong> {felData.seriefel}
                            </p>
                          ) : null}
                          {felData.numerofel ? (
                            <p className="mb-0">
                              <strong>Número FEL:</strong> {felData.numerofel}
                            </p>
                          ) : null}
                        </>
                      )}
                      {isGuatemala ? (
                        ""
                      ) : (
                        <>
                          <p className="mb-0 text-center">
                            <strong>Fecha Autorización FEL:</strong>
                          </p>
                          <p className="mb-0 text-center">
                            {felData.fecha.split(".")[0]}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  {isCmi() ? (
                    ""
                  ) : (
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginTop: "5px" }}
                    >
                      <div className="ml-0">
                        <p className="mb-0">NOMBRE: {felData.client.nombre}</p>
                      </div>
                    </div>
                  )}
                  {isCmi() ? (
                    ""
                  ) : (
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="ml-0">
                        <p className="mb-0">NIT : {felData.client.nit}</p>
                        <p className="mb-4">DIR : {felData.client.direccion}</p>
                      </div>
                    </div>
                  )}
                  {isGuatemala ? (
                    ""
                  ) : (
                    <div className="ml-2 text-center">
                      <p className="mb-0">
                        Fecha de emisión :{" "}
                        {felData?.fecha?.split("T")[0].split("-")[2] +
                          "/" +
                          felData?.fecha?.split("T")[0].split("-")[1] +
                          "/" +
                          felData?.fecha?.split("T")[0].split("-")[0]}
                      </p>
                    </div>
                  )}
                  <hr
                    style={{ borderTop: "3px dashed #bbb", marginBottom: 3 }}
                  />

                  {isCmi() && !isGuatemala ? (
                    <div className="col-12 text-center p-0">
                      <p className="mb-0">
                        <strong className="mb-0">
                          Canal:{" "}
                          <label>
                            {felData.tipo_venta === 0
                              ? "Para Llevar"
                              : felData.tipo_venta === 1
                              ? "Consumo Local"
                              : felData.tipo_venta === 2
                              ? "A domicilio (Delivery)"
                              : felData.tipo_venta === 3
                              ? "Presencial"
                              : felData.tipo_venta === 4
                              ? "Pick-Up"
                              : "Entrega"}
                          </label>
                        </strong>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row mb-0">
                    <div className="col-2 text-center mb-0">
                      <strong className="mb-0">Cant</strong>
                    </div>
                    <div className="col-6 text-center mb-0">
                      <strong className="mb-0">Descripción</strong>
                    </div>
                    <div className="col-2 text-center mb-0">
                      <strong className="mb-0">
                        {isGuatemala
                          ? "Precio en Q."
                          : isCmi()
                          ? "Precio en U."
                          : "Precio"}
                      </strong>
                    </div>
                    <div className="col-2 text-center mb-0">
                      <strong className="mb-0">
                        {isGuatemala
                          ? "Total en Q."
                          : isCmi()
                          ? "Monto"
                          : "Total"}
                      </strong>
                    </div>
                  </div>

                  <hr style={{ borderTop: "3px dashed #bbb" }} />

                  {felData.orderItems.map((product) => (
                    <>
                      <div className="row mb-2">
                        <div className="col-2 text-center">
                          {product.cantidad}
                        </div>
                        <div className="col-6 text-center">
                          {product.codigo} <br /> {product.nombre}
                          {isAleko() &&
                          product.personalizacionItems.length > 0 ? (
                            <div className="my-2">
                              {product.personalizacionItems.map(
                                (item, index) => {
                                  return (
                                    <div key={index} className="mb-2">
                                      {item.tipo} {item.medidas_alto}X
                                      {item.medidas_ancho}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-2 text-center">
                          {product.descuento_precio
                            ? formaterPriceFunction(
                                product.descuento_precio.toFixed(2).toString()
                              )
                            : isAleko()
                            ? formaterPriceFunction(
                                product.precio_venta.toFixed(2).toString()
                              )
                            : formaterPriceFunction(
                                product.precio.toFixed(2).toString()
                              )}
                          {isAleko() &&
                          product.personalizacionItems.length > 0 ? (
                            <div className="my-2 mt-4">
                              {product.personalizacionItems.map(
                                (item, index) => {
                                  return (
                                    <div key={index} className="mb-2">
                                      {Math.max(item.precio).toFixed(2)}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-2 text-center">
                          {product.total
                            ? formaterPriceFunction(
                                product.total.toFixed(2) + ""
                              ) // Same logic as before
                            : formaterPriceFunction(
                                (product.precio * product.cantidad)
                                  .toFixed(2)
                                  .toString()
                              )}
                        </div>
                        {product.combo &&
                          product.items.map((item) => (
                            <div className="row col-12 mx-auto">
                              <div
                                className="col-2 text-center mb-0"
                                style={{
                                  paddingLeft: "5.5%",
                                }}
                              ></div>
                              <div className="col-6 pl-4 pt-2 text-center">
                                {item.nombre}
                                <p>Cant. total: {item.cantidad}</p>
                              </div>
                              {/* <div className="col-2 px-0 text-center pl-2">
                                {item.precio.toFixed(2)}
                              </div>
                              <div className="col-2 px-0 pl-5">
                                {item.precio.toFixed(2)}
                              </div> */}
                            </div>
                          ))}
                      </div>
                    </>
                  ))}

                  {/* {isCmi() && felData.tipo_venta != 5  ? (
                    <div className="row">
                      <div className="col-2 px-0 text-center">1</div>
                      <div className="col-6 text-center">Costo por entrega</div>
                      <div className="col-2 text-center">
                        {felData.tipo_venta == 5
                          ? getDescuentoActual(felData.tipo_venta, false)
                          : getDescuentoActual(felData.tipo_venta)}
                      </div>
                      <div className="col-2 text-center">
                        {felData.tipo_venta == 5
                          ? getDescuentoActual(felData.tipo_venta, false)
                          : getDescuentoActual(felData.tipo_venta)}
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* No se muestra para guatemala */}

                  {isCmi() ? (
                    <>
                      <hr
                        style={{
                          borderTop: "3px dashed #bbb",
                          marginBottom: 3,
                        }}
                      />

                      <div className="col-12 ">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                          <label>
                            {" "}
                            <strong> TOTAL: </strong>
                          </label>
                          <label>
                            {moneda}.
                            {felData.tipo_venta == 5
                              ? formaterPriceFunction(
                                  sumarMontos().toFixed(2).toString()
                                )
                              : formaterPriceFunction(
                                  (
                                    sumarMontos() +
                                    felData.orderItems.find(
                                      (x) => x.descuento_total
                                    )?.precio
                                  )
                                    .toFixed(2)
                                    .toString()
                                )}
                          </label>
                        </div>
                        {felData.tipo_venta != 5 ? (
                          <div className="col-12 mb-0 d-flex justify-content-between align-items-center">
                            <label>
                              <strong>
                                {felData.tipo_venta == 5
                                  ? `Costo por: ${
                                      felData.tipo_venta === 0
                                        ? "Para Llevar"
                                        : felData.tipo_venta === 1
                                        ? "Consumo Local"
                                        : felData.tipo_venta === 2
                                        ? "A domicilio (Delivery)"
                                        : felData.tipo_venta === 3
                                        ? "Presencial"
                                        : felData.tipo_venta === 4
                                        ? "Pick-Up"
                                        : "Entrega"
                                    }`
                                  : `DESCUENTO POR: ${
                                      felData.tipo_venta === 0
                                        ? "Para Llevar"
                                        : felData.tipo_venta === 1
                                        ? "Consumo Local"
                                        : felData.tipo_venta === 2
                                        ? "A domicilio (Delivery)"
                                        : felData.tipo_venta === 3
                                        ? "Presencial"
                                        : felData.tipo_venta === 4
                                        ? "Pick-Up"
                                        : "Entrega"
                                    }`}
                              </strong>
                            </label>
                            <label>
                              {moneda}.
                              {/* {felData.tipo_venta == 5
                                ? getDescuentoActual(
                                    felData.tipo_venta,
                                    false
                                  ).toFixed(2)
                                : getDescuentoActual(
                                    felData.tipo_venta
                                  ).toFixed(2)} */}
                              {felData.orderItems
                                .find((x) => x.descuento_total)
                                ?.precio.toFixed(2)}
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {isGuatemala ? (
                    ""
                  ) : isCmi() && !isGuatemala ? (
                    <div className="col-12 ml-2 d-flex justify-content-between align-items-center">
                      <label style={{ marginLeft: "5px" }}>
                        <strong>Total Items:</strong>
                      </label>
                      <label style={{ marginRight: "23px" }}>
                        {felData.orderItems.length}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  {isExento && isCmi() ? (
                    <>
                      <div
                        className="col-12 px-0 d-flex justify-content-between align-items-center"
                        style={{
                          paddingLeft: isCmi() ? "30px" : "150px",
                        }}
                      >
                        <label className="mb-0 ml-4">
                          <strong>Sub-Total</strong>
                        </label>
                        <label
                          style={{
                            marginLeft: isCmi() ? "" : "20px",
                          }}
                          className="mb-0 mr-4"
                        >
                          {
                            isCmi() ? (
                              <>
                                <strong>{moneda}. </strong>
                                {getTotalFactura()}
                              </>
                            ) : (
                              `${getTotalFactura()}`
                            )
                            /* felData.monto_total.toFixed(2) */
                          }
                        </label>
                      </div>
                      <div
                        className="col-12 px-0 d-flex justify-content-between align-items-center"
                        style={{
                          paddingLeft: isCmi() ? "30px" : "150px",
                        }}
                      >
                        <label className="mb-0 ml-4">
                          <strong>Monto Exento IVA(12%)</strong>
                        </label>
                        <label
                          style={{
                            marginLeft: isCmi() ? "" : "20px",
                          }}
                          className="mb-0 mr-4"
                        >
                          <strong>{moneda}. </strong>
                          {getExentoIVA().toFixed(2)}
                        </label>
                      </div>
                    </>
                  ) : null}

                  <div className="row">
                    <div
                      className="col-12 d-flex justify-content-between align-items-center"
                      style={{
                        paddingLeft: isCmi() ? "40px" : "150px",
                        paddingRight: isCmi() ? "43px" : "",
                      }}
                    >
                      <label>
                        <strong>TOTAL FACTURA:</strong>
                      </label>
                      <label
                        style={{
                          marginLeft: isCmi() ? "" : "20px",
                        }}
                      >
                        <strong>{moneda}. </strong>
                        {isCmi() && isExento
                          ? formaterPriceFunction(getTotalIVA().toFixed(2) + "")
                          : formaterPriceFunction(
                              sumarMontos().toFixed(2) + ""
                            )}
                      </label>
                    </div>

                    <div
                      className="col-12"
                      style={{ paddingLeft: isCmi() ? "40px" : "150px" }}
                    >
                      <label style={{ fontSize: "13px" }}>
                        <strong>TOTAL EN LETRAS: </strong>
                        {felData.monto_total.toFixed(2).toString() === "1.00" &&
                          "UN QUETZAL CON 00/100"}
                        {felData.monto_total.toFixed(2).toString() !==
                          "1.00" && (
                          <>
                            {convertir
                              .NumerosALetras(
                                isCmi()
                                  ? isExento
                                    ? getTotalIVA().toFixed(2)
                                    : sumarMontos().toFixed(2)
                                  : sumarMontos().toFixed(
                                      2
                                    ) /*felData.monto_total*/
                              )
                              .replace("Pesos", "QUETZALES CON")
                              .replace("M.N.", "")
                              .toLocaleUpperCase()}
                          </>
                        )}
                      </label>
                      <br />
                    </div>

                    {/* {isCmi() && isExento ? (
                      <div className="col-md-12 mx-auto text-center">
                        <label>
                          <strong>
                            ***Exento según Artículo 7, Decreto 27-92***
                          </strong>
                        </label>
                      </div>
                    ) : null}

                    {isGranContribuyente ? (
                      <div className="col-md-12 mx-auto text-center">
                        <label>
                          <strong>
                            ***Exento según Artículo 7, Decreto 27-92***
                          </strong>
                        </label>
                      </div>
                    ) : (
                      <div className="col-md-12 mx-auto text-center">
                        <label>
                          <strong>
                            ***NO GENERA DERECHO A CRÉDITO FISCAL***
                          </strong>
                        </label>
                      </div>
                    )} */}
                  </div>

                  {/* Se muestra para guatemala */}
                  {isGuatemala ? (
                    ""
                  ) : isCmi() && isGuatemala ? (
                    <div className="row col-12 d-flex justify-content-between align-items-center">
                      <label style={{ marginLeft: "25px" }}>Total Items:</label>
                      <label>{felData.orderItems.length}</label>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <div className="col-12" style={{ paddingRight: "30px" }}>
                      {felData.monto_idp > 0 ? (
                        <strong>
                          Impuesto IDP
                          <label style={{ marginLeft: "20px" }}>
                            Q {felData.monto_idp}
                          </label>
                        </strong>
                      ) : null}
                      {felData.monto_idp > 0 ? <br /> : null}
                      {/* {felData.monto_exento &&
                      felData.monto_exento > 0 &&
                      isPrincipalRetainer ? (
                        <strong>
                          Monto exento IVA({felData.empresa.iva}%)
                          <label style={{ marginLeft: "20px" }}>
                            Q {felData.monto_exento.toFixed(2)}
                          </label>
                        </strong>
                      ) : null} */}

                      {isCmi() ? (
                        felData.pago_method !== "TARJETA" ? (
                          <div className="col-12 ml-2 d-flex justify-content-between align-items-center">
                            <strong style={{ marginLeft: "5px" }}>
                              <label>
                                {felData.id_forma_pago === 6
                                  ? "Anticipo"
                                  : felData.id_forma_pago == 5
                                  ? "Venta A Crédito"
                                  : felData.id_forma_pago == 3
                                  ? "Vale"
                                  : "EFECTIVO:"}
                              </label>
                            </strong>
                            <strong>
                              <label style={{ marginRight: "5px" }}>
                                {moneda}.{" "}
                                {felData.id_forma_pago == 5
                                  ? formaterPriceFunction(
                                      sumarMontos().toFixed(2) + ""
                                    )
                                  : felData.pago_method !== "A CREDITO"
                                  ? felData.efectivo > 0
                                    ? formaterPriceFunction(
                                        felData.efectivo.toFixed(2) + ""
                                      )
                                    : felData.tarjeta > 0
                                    ? formaterPriceFunction(
                                        felData.tarjeta.toFixed(2) + ""
                                      )
                                    : felData.gift_card > 0
                                    ? formaterPriceFunction(
                                        felData.gift_card.toFixed(2) + ""
                                      )
                                    : formaterPriceFunction(
                                        felData.credito.toFixed(2) + ""
                                      )
                                  : felData.id_forma_pago === 6
                                  ? formaterPriceFunction(
                                      felData.credito.toFixed(2) + ""
                                    )
                                  : 0}
                              </label>
                            </strong>
                          </div>
                        ) : null
                      ) : felData.pago_method !== "TARJETA" ? (
                        <strong>
                          {felData.id_forma_pago === 6
                            ? "Anticipo"
                            : felData.id_forma_pago == 5
                            ? "Venta a crédito"
                            : felData.id_forma_pago == 3
                            ? "Vale"
                            : "Efectivo"}
                          <label style={{ marginLeft: "50px" }}>
                            {moneda}.{" "}
                            {felData.pago_method !== "A CREDITO"
                              ? felData.efectivo > 0
                                ? formaterPriceFunction(
                                    felData.efectivo.toFixed(2) + ""
                                  )
                                : felData.tarjeta > 0
                                ? formaterPriceFunction(
                                    felData.tarjeta.toFixed(2) + ""
                                  )
                                : felData.gift_card > 0
                                ? formaterPriceFunction(
                                    felData.gift_card.toFixed(2) + ""
                                  )
                                : formaterPriceFunction(
                                    felData.credito.toFixed(2) + ""
                                  )
                              : felData.id_forma_pago === 6
                              ? formaterPriceFunction(
                                  felData.credito.toFixed(2) + ""
                                )
                              : felData.credito
                              ? formaterPriceFunction(
                                  felData.credito.toFixed(2) + ""
                                )
                              : 0}
                          </label>
                        </strong>
                      ) : null}
              
                      {felData.tarjeta > 0 ? (
                        <div className="col-12 ml-2 align-items-center">
                          <label style={{ marginLeft: "-25px" }}>
                            <strong>Tarjeta</strong>
                          </label>
                          <label style={{ marginLeft: "50px" }}>
                            <strong>
                              {moneda}.{felData.tarjeta.toFixed(2)}
                            </strong>
                          </label>
                        </div>
                      ) : (
                        <></>
                      )}

                      {felData.gift_card > 0 && felData.id_forma_pago !== 3 ? (
                        <div className="col-12 ml-2 d-flex justify-content-between align-items-center">
                          <label style={{ marginLeft: "5px" }}>
                            <strong>Vale</strong>
                          </label>
                          <label style={{ marginRight: "5px" }}>
                            <strong>
                              {moneda}.{felData.gift_card.toFixed(2)}
                            </strong>
                          </label>
                        </div>
                      ) : (
                        <></>
                      )}
                      {isCmi() && felData.cambios ? (
                        <div className="col-12 ml-2 d-flex justify-content-between align-items-center">
                          <label style={{ marginLeft: "5px" }}>
                            <strong>CAMBIO: </strong>
                          </label>
                          <label style={{ marginRight: "5px" }}>
                            {moneda}.
                            {formaterPriceFunction(
                              felData.cambios.toFixed(2) + ""
                            )}
                            {/* {isGuatemala
                              ? isExento
                                ? getCambioPorEfectivo().toFixed(2)
                                : getVuelto().toFixed(2)
                              : getVuelto().toFixed(2)} */}
                          </label>
                        </div>
                      ) : null}
                      { felData.cambios > 0 ? (
                        <strong>
                          Vuelto
                          <label style={{ marginLeft: '50px' }}>
                            {moneda}.{' '}
                            {
                              //isCmi() ? getVuelto() : getVuelto()
                              felData.cambios.toFixed(2)
                            }
                          </label>
                        </strong>
                      ) : null }

                      {isCmi() ? (
                        ""
                      ) : (
                        <>
                          <br />
                          <strong>
                            Tipo entrega
                            <label style={{ marginLeft: "20px" }}>
                              {felData.tipo_venta === 0
                                ? "Para Llevar"
                                : felData.tipo_venta === 1
                                ? "Consumo Local"
                                : felData.tipo_venta === 2
                                ? "A domicilio (Delivery)"
                                : felData.tipo_venta === 3
                                ? "Presencial"
                                : felData.tipo_venta === 4
                                ? "Pick-Up"
                                : "Entrega"}
                            </label>
                          </strong>
                        </>
                      )}
                    </div>

                    {isGuatemala && (
                      <div
                        className="col-12"
                        style={{ paddingLeft: isCmi() ? "40px" : "150px" }}
                      >
                        <label style={{ fontSize: "13px" }}>
                          <strong>CANAL:</strong>
                          <>
                            {felData.tipo_venta === 0
                              ? " Para Llevar"
                              : felData.tipo_venta === 1
                              ? " Consumo Local"
                              : felData.tipo_venta === 2
                              ? " A domicilio (Delivery)"
                              : felData.tipo_venta === 3
                              ? " Presencial"
                              : felData.tipo_venta === 4
                              ? " Pick-Up"
                              : " Entrega"}
                          </>
                        </label>
                        <br />
                      </div>
                    )}
                    {felData.nivel_precio && (
                      <div
                        className="col-12 d-flex justify-content-between align-items-center"
                        style={{ paddingLeft: isCmi() ? "40px" : "150px" }}
                      >
                        <span>
                          <label>
                            <strong>NIVEL DE PRECIO:</strong>
                          </label>
                          &nbsp;{felData.nivel_precio}
                        </span>
                      </div>
                    )}
                    {isGuatemala && (
                      <div
                        className="col-12"
                        style={{ paddingLeft: isCmi() ? "40px" : "150px" }}
                      >
                        <label style={{ fontSize: "13px" }}>
                          <strong>VENDEDOR:</strong>
                          <>{" " + felData.vendedor.nombre}</>
                        </label>
                        <br />
                      </div>
                    )}

                    {isGuatemala && (
                      <div
                        className="col-12"
                        style={{ paddingLeft: isCmi() ? "40px" : "150px" }}
                      >
                        <label style={{ fontSize: "13px" }}>
                          <strong>CONTROL INTERNO:</strong>
                          <>
                            {felData.identificador.includes("Anulado")
                              ? " "
                              : ""}
                            {" " + felData.identificador}
                          </>
                        </label>
                        <br />
                      </div>
                    )}
                  </div>
                  {/* {isAleko() && felData.tipo_venta == 2 ? (
                    <>
                      <strong>
                        Domicilio:
                        <label>&nbsp;{`${directions.domicilio}`}</label>
                      </strong>
                      <br />
                      <strong>
                        Ciudad:
                        <label>&nbsp;{`${directions.city}`}</label>
                      </strong>
                      <br />
                      Número de teléfono:
                      <strong>
                        <label>&nbsp;{`${directions.cod_postal}`}</label>
                      </strong>
                      <br />
                      {directions.observaciones == "" ? (
                        ""
                      ) : (
                        <>
                          <strong>
                            Observaciones:
                            <label>&nbsp;{`${directions.observaciones}`}</label>
                          </strong>
                          <br />
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )} */}
                  {isCmi() ? (
                    felData.tipo_venta == 5 ? (
                      <>
                        <strong>
                          Domicilio:
                          <label>&nbsp;{`${directions.domicilio}`}</label>
                        </strong>
                        <br />
                        <strong>
                          Ciudad:
                          <label>&nbsp;{`${directions.city}`}</label>
                        </strong>
                        <br />
                        Número de teléfono:
                        <strong>
                          <label>&nbsp;{`${directions.cod_postal}`}</label>
                        </strong>
                        <br />
                        {directions.observaciones == "" ? (
                          ""
                        ) : (
                          <>
                            <strong>
                              Observaciones:
                              <label>
                                &nbsp;{`${directions.observaciones}`}
                              </label>
                            </strong>
                            <br />
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col-12">
                      {isCmi() || isGuatemala ? (
                        isGranContribuyente || isExento ? (
                          <div className="col-md-12 mx-auto text-center">
                            <p className="text-center">
                              <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                              <br />
                              <strong>Agente de Retención del IVA</strong>
                            </p>
                          </div>
                        ) : (
                          <div className="col-12 my-2">
                            <p className="text-center">
                              <strong>
                                NO GENERA DERECHO A CRÉDITO FISCAL
                              </strong>
                            </p>
                          </div>
                        )
                      ) : isGranContribuyente ? (
                        <div className="col-md-12 mx-auto text-center">
                          <p className="text-center">
                            <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                            <br />
                            <strong>AGENTE DE RETENCIÓN DEL IVA</strong>
                          </p>
                        </div>
                      ) : (
                        <div className="col-12 my-2">
                          <p className="text-center">
                            <strong>NO GENERA DERECHO A CRÉDITO FISCAL</strong>
                          </p>
                        </div>
                      )}

                      {/* {isPrincipalRetainer ||
                      isExento ||
                      isGranContribuyente ? (
                        <p className="text-center">
                          <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                          <br />
                          <strong>Agente de Retención del IVA</strong>
                        </p>
                      ) : (
                        <p className="text-center">
                          <strong>NO GENERA DERECHO A CRÉDITO FISCAL</strong>
                        </p>
                      )}

                      {/* {isGranContribuyente ? (
                        ''
                      ) : !isPrincipalRetainer ? (
                        <p className="text-center">
                          <strong>NO GENERA DERECHO A CRÉDITO FISCAL</strong>
                        </p>
                      ) : null} */}

                      {isCmi() ? (
                        ""
                      ) : (
                        <>
                          {felData.service_certificador ? (
                            <p className="text-center">
                              <strong>NÚMERO DE AUTORIZACIÓN</strong>
                            </p>
                          ) : null}
                          {felData.service_id ? (
                            <p className="text-center">
                              <strong>{felData.service_id}</strong>
                            </p>
                          ) : null}
                        </>
                      )}
                      {felData.service_id ? (
                        <p className="text-center">
                          <strong>CERTIFICADOR:</strong>{" "}
                          {/* {isGuatemala
                            ? 'Guatefacturas, Sociedad Anónima'
                            : felData.service_certificador}{' '} */}
                          {felData.service_certificador} <strong>NIT:</strong>{" "}
                          {felData.fel ? felData.fel.nit : felData.service_nit}
                        </p>
                      ) : null}
                      <p className="text-center" style={{ marginTop: "5px" }}>
                        <strong className="mb-0" style={{ marginTop: "5px" }}>
                          Powered by
                        </strong>
                      </p>
                      <p className="text-center">
                        <strong style={{ color: "blue" }}>
                          www.cloud.com.gt
                        </strong>
                      </p>
                      {isCmi() ? (
                        ""
                      ) : (
                        <p className="text-center">
                          <strong>PBX: 2503 - 3333</strong>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {moneda === "L" && (
              <FacturaHonduras
                felData={felData}
                ref={ref}
                moneda={moneda}
                descuentos={descuentos}
                convertir={convertir}
                isPrincipalRetainer={isPrincipalRetainer}
                directions={directions}
              />
            )}
          </div>
        ) : null}
      </div>
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Facturación</strong>
        </h4>
      </div>
      {loader && !felData ? (
        <div className="my-auto text-center">
          <CircularProgress size={50} className="mx-auto" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-xl-6">
              <div className="d-flex justify-content-center justify-content-sm-between flex-wrap mb-2">
                <Button
                  className="btn-cs1 mx-1 mb-1"
                  variant="contained"
                  color="primary"
                  startIcon={<img src={Document} alt="cash-register-icon" />}
                  onClick={() => {
                    if (!gerente && isCmi()) {
                      setActivarModalAlerta(true);
                      return;
                    }
                    printSale();
                  }}
                >
                  <span style={{ marginBottom: "-4px" }}>Ver documento</span>
                </Button>

                {!isAleko() && (
                  <Button
                    className="btn-cs1 mx-1 mb-1"
                    variant="contained"
                    color="primary"
                    startIcon={<PrintIcon />}
                    onClick={() => {
                      if (!gerente && isCmi()) {
                        setActivarModalAlerta(true);
                        return;
                      }
                      printSale();
                    }}
                  >
                    <span style={{ marginBottom: "-4px" }}>Imprimir</span>
                  </Button>
                )}

                <Button
                  className="btn-cs1 mx-1 mb-1"
                  variant="contained"
                  color="primary"
                  startIcon={
                    <img src={CashRegister} alt="cash-register-icon" />
                  }
                  onClick={backToCatalogo}
                >
                  <span style={{ marginBottom: "-4px" }}>Volver a la caja</span>
                </Button>
              </div>
              <div className="content-card card">
                <div className="card-header">
                  <h4 className="mb-0">
                    <strong>Resumen de la compra</strong>
                  </h4>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Fecha / hora</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={
                          felData?.fecha?.split("T")[0].split("-")[2] +
                          "/" +
                          felData?.fecha?.split("T")[0].split("-")[1] +
                          "/" +
                          felData?.fecha?.split("T")[0].split("-")[0] +
                          " / " +
                          felData?.fecha?.split("T")[1].split(".")[0]
                        }
                        name="fecha"
                        disabled
                      />
                    </div>

                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>NIT de cliente</strong>
                      </p>

                      <TextField
                        className="w-100"
                        variant="standard"
                        name="nit"
                        value={felData.client.nit}
                        disabled
                      />
                    </div>

                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Nombre de cliente</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={felData.client.nombre}
                        name="client_name"
                        disabled
                      />
                    </div>
                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Dirección fiscal del cliente</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={felData.client.direccion || "No especificada"}
                        name="direccion_fiscal"
                        disabled
                      />
                    </div>
                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Correo electrónico</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={felData.client.email || "No especificado"}
                        name="email"
                        disabled
                      />
                    </div>
                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Número de teléfono</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={felData.telefono_cliente || "No especificado"}
                        name="telefono_cliente"
                        disabled
                      />
                    </div>
                    {felData.client.nit.toLowerCase().includes("cf") && (
                      <div className="w-100 my-3">
                        <p className="mb-0">
                          <strong>Código del cliente</strong>
                        </p>
                        <TextField
                          className="w-100"
                          variant="standard"
                          value={felData.codigo_cliente || "No especificado"}
                          name="codigo_cliente"
                          disabled
                        />
                      </div>
                    )}

                    <div className="w-100 my-3">
                      <p className="mb-0">
                        <strong>Vendedor</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={felData.vendedor.nombre}
                        name="seller"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card content-card mt-2">
                <div
                  className="card-header p-0"
                  id="facturacion-products-header"
                >
                  <DataTable
                    className="datatable-cs datatable-cs-v2 m-0"
                    title={"s"}
                    columns={[
                      {
                        name: "Producto",
                        selector: "id_new_item",
                        sortable: true,
                        cell: (row) => (
                          <div>
                            {row.nombre} <br />
                            {row.codigo}
                          </div>
                        ),
                      },
                      {
                        name: "Precio",
                        selector: "precio",
                        sortable: true,
                        cell: (row) => (
                          <div>
                            {moneda}.{" "}
                            {formaterPriceFunction(row.precio.toFixed(2) + "")}
                          </div>
                        ),
                      },
                      {
                        name: "Cantidad",
                        selector: "cantidad",
                        sortable: true,
                      },
                      {
                        name: "Total",
                        selector: "precio",
                        sortable: true,
                        cell: (row) => (
                          <div>
                            {moneda}.{" "}
                            {isAleko()
                              ? formaterPriceFunction(
                                  parseFloat(row.precio * row.cantidad).toFixed(
                                    2
                                  ) + ""
                                )
                              : formaterPriceFunction(
                                  parseFloat(row.precio).toFixed(2) + ""
                                )}
                          </div>
                        ),
                      },
                    ]}
                    data={felData.orderItems}
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se ha encontrado ningún producto"
                  />
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-7">
                      <div className="row">
                        {felData.tarjeta !== 0 ? (
                          <div
                            className="col-md-12 mb-2"
                            style={{
                              borderBottom: "1px solid #E0E0E0",
                            }}
                          >
                            <p>
                              <strong>Tarjeta de débito/crédito</strong>
                            </p>
                            <p>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                felData.tarjeta.toFixed(2) + ""
                              )}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {felData.efectivo !== 0 ? (
                          <div
                            className="col-12 mb-2"
                            style={{
                              borderBottom: "1px solid #E0E0E0",
                            }}
                          >
                            <p>
                              <strong>Efectivo</strong>
                            </p>
                            <p>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                felData.efectivo.toFixed(2) + ""
                              )}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {felData.gift_card !== 0 ? (
                          <div
                            className="col-md-12 mb-2"
                            style={{
                              borderBottom: "1px solid #E0E0E0",
                            }}
                          >
                            <p>
                              <strong>Método de pago seleccionado</strong>
                            </p>
                            <p>
                              {moneda}.{" "}
                              {formaterPriceFunction(
                                felData.gift_card.toFixed(2) + ""
                              )}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-sm-5 invoice-column">
                      <div className="row h-75">
                        <div className="col-6 p-0 m-0 text-center titles my-auto">
                          {/* <p>SubTotal:</p> */}
                          {/* <p>IVA (12%):</p> */}
                          <p>Total:</p>
                          <p>Pagado:</p>
                          <p>Vuelto:</p>
                        </div>
                        <div className="col-6 p-0 m-0 text-center my-auto">
                          {/*  <p>
                                                        {moneda}.{" "}
                                                        {felData.subtotal.toFixed(2)}
                                                    </p> */}
                          {/* <p>
                                                        {moneda}. {felData.vat_amount.toFixed(2)}
                                                    </p> */}
                          <p>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              felData.monto_total.toFixed(2) + ""
                            )}
                          </p>
                          <p>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              (felData.pago_method !== "A CREDITO"
                                ? felData.efectivo +
                                  felData.tarjeta +
                                  felData.gift_card +
                                  felData.credito
                                : felData.id_forma_pago === 6
                                ? felData.credito
                                : 0
                              ).toFixed(2) + ""
                            )}
                          </p>
                          <p>
                            {moneda}.{" "}
                            {formaterPriceFunction(
                              felData.cambios.toFixed(2) + ""
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row h-25 justify-content-center w-100 m-0 pb-5">
                        <Button
                          style={{
                            backgroundColor: "white",
                            color: "#1a2eab",
                          }}
                          className="my-2 w-75 facturar-button"
                          variant="contained"
                        >
                          <span
                            style={{
                              marginBottom: "-4px",
                              textTransform: "none",
                            }}
                          >
                            Pagado
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {felData.recibo_numero !== "" || felData.gift_card_code !== "" ? (
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="content-card card">
                      <div className="card-header">
                        <h4>
                          <strong>Verificación del pago</strong>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          {felData.recibo_numero !== "" ? (
                            <>
                              <div className="col-md-12 my-auto">
                                <p>
                                  <strong>
                                    Número del recibo (Operación con tarjeta)
                                  </strong>
                                </p>
                                <TextField
                                  className="w-100"
                                  variant="standard"
                                  value={felData.recibo_numero}
                                  name="recibo_numero"
                                  disabled
                                  placeholder="Ingrese el número del recibo emitido por el pto. de venta"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        N°
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                  <label
                                    className="form-check-label ml-2"
                                    for="defaultCheck1"
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    Ingresar manualmente
                                  </label>
                                </div>
                              </div>
                              <div className="col-md my-auto">
                                <p>
                                  <strong>Número de cuotas</strong>
                                </p>

                                <Select
                                  native
                                  value={felData.cuotas}
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <option value={felData.cuotas}>
                                    {felData.cuotas === "0"
                                      ? "Pago único"
                                      : felData.cuotas + " meses"}
                                  </option>
                                </Select>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {felData.gift_card_code !== "" ? (
                            <div className="col-md my-auto">
                              <p>
                                <strong>Ingrese el código de Gift Card</strong>
                              </p>
                              <TextField
                                className="w-100"
                                variant="standard"
                                value={felData.gift_card_code}
                                name="giftcard_code"
                                placeholder="     -      -      -"
                              ></TextField>
                            </div>
                          ) : null}
                          {felData.receibosPagos?.length > 0 ? (
                            <div className="col-md-12 mt-5 pl-5">
                              <div className="row w-100 mt-2 justify-content-center justify-content-md-start">
                                {felData.receibosPagos.map((invoice) => (
                                  <div className="col-xl-4 px-1">
                                    <TextField
                                      className="w-100"
                                      variant="standard"
                                      disabled
                                      value={invoice.recibo_adicional}
                                      name="invoice_number"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            Recibo N°
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <ModalAlerta
            message="No puedes reimprimir la factura"
            bottomMessage="Consulte con un gerente de la tienda para realizar la reimpresión."
          />
        </>
      )}
    </div>
  );
}
