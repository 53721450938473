import React, { useState, useEffect, useRef, useContext } from "react";

import { Stage, Layer, Image, Circle } from "react-konva";
import useImage from "use-image";
import { realImageURL } from "../../../api";
import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { handleCoordinates } from "./AreaContainerForm/utils/handleChanges";
import Point from "./Point";

// Styled Components

import {
  GridContainer,
  FlexContainer,
  PerspectiveContainer,
  PerspectiveButton,
} from "./styled/styled.components";

const CustomizationView = ({
  perspectives,
  currentPerspectiveTab,
  setStageMiddle,
  points,
  stageRef,
  variant,
}) => {
  // This is the width of STAGE (canvas)
  const stageWidth = 200;
  // this is the height of STAGE (canvas)
  const stageHeight = 200;
  // This is the width and height of Circles (points)
  const circleWidth = 25;
  const circleHeight = 25;
  // This handle the perspective image

  const [image, status] = useImage(
    realImageURL(perspectives[currentPerspectiveTab].image) || "noimage"
  );

  const { handleForms, currentProductTab } = useContext(
    ProductPersonalizationContext
  );
  // This is the image resolution
  const [imageWidth, setImageWidth] = useState(200);
  const [imageHeight, setImageHeight] = useState(200);

  // This keeps image centered
  const [X, setX] = useState(0);
  const [Y, setY] = useState(0);

  // This handle click on STAGE (canvas) for add circles

  // This handle the image resolution
  // and keeps the image centered on canvas
  useEffect(() => {
    setX((stageWidth - imageWidth) * 0.5);
    setY((stageHeight - imageHeight) * 0.5);

    const imageAspectRatio = (ratio) => {
      setImageWidth(imageWidth * ratio);
      setImageHeight(imageHeight * ratio);
    };

    if (imageWidth > stageWidth) {
      imageAspectRatio(stageWidth / imageWidth);
    }

    if (imageHeight > stageHeight) {
      imageAspectRatio(stageHeight / imageHeight);
    }
  }, [imageWidth, imageHeight]);

  // This handle load of perspective image
  useEffect(() => {
    if (status == "loaded") {
      setImageWidth(image.width);
      setImageHeight(image.height);
      setStageMiddle(stageWidth / 2);
    }
  }, [status]);

  return (
    <FlexContainer
      gridColumn="1/-1"
      alignItems="center"
      flexWrap="nowrap"
      margin="auto"
    >
      <span>Der.</span>
      <Stage ref={stageRef} width={stageWidth} height={stageHeight}>
        <Layer>
          <Image
            image={image}
            x={X}
            y={Y}
            width={imageWidth}
            height={imageHeight}
            alt="Perspectiva Producto"
          />
        </Layer>
        <Layer>
          {points.map((item, i) => {
            return (
              <Point
                item={item}
                variant={variant}
                index={i}
                handleForms={handleForms}
              />
            );
          })}
        </Layer>
      </Stage>
      <span>Izq.</span>
    </FlexContainer>
  );
};

export default CustomizationView;
