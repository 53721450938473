import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const login = async (data) => {
  return await axios.post(`${base_url}/login`, qs.stringify(data));
};
const logoutOfAllDevices = async (formData) => {
  return await axios.post(`${base_url}/logout-of-all-devices`, formData);
};
const logout = async () => {
  return await axios.post(`${base_url}/logout`, {}, { headers: headers() });
};

const register = async (data) => {
  return await axios.post(`${base_url}/signup`, qs.stringify(data));
};

const sendEmailCode = async (data) => {
  return await axios.post(`${base_url}/send-email-code`, qs.stringify(data));
};

const verifyEmailCode = async (data) => {
  return await axios.post(`${base_url}/verify-email-code`, qs.stringify(data));
};

const sendOTP = async (data) => {
  return await axios.post(`${base_url}/send-otp`, qs.stringify(data));
};

const verifyOTP = async (data) => {
  return await axios.post(`${base_url}/verify-otp`, qs.stringify(data));
};

const emailChangePassword = async (data) => {
  return await axios.post(
    `${base_url}/update-email-password`,
    qs.stringify(data)
  );
};

const phoneChangePassword = async (data) => {
  return await axios.post(`${base_url}/update-password`, qs.stringify(data));
};

const getUserPermissions = async () => {
  return await axios.get(`${base_url}/web/get-user-permissions`, {
    headers: headers(),
  });
};

export default {
  login,
  logout,
  register,
  sendEmailCode,
  verifyEmailCode,
  sendOTP,
  verifyOTP,
  emailChangePassword,
  phoneChangePassword,
  getUserPermissions,
  logoutOfAllDevices,
};
