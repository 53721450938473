import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import $ from "jquery";
import FooterLinks from "../../components/FooterLinks/FooterLinks";
import HeaderNoSession from "../../components/HeaderNoSession/Header";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import useStyles from "./styles";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import API from "../../api";
import { toast } from "react-toastify";
import { useUserDispatch, autoLogin } from "../../context/UserContext";

// styles
import "./custom.css";
//

function Register(props) {
  let history = useHistory();
  var classes = useStyles();

  useEffect(() => {
    return () => {
      $("#passwordUpdated").modal("hide");
    };
  }, []);

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.userEmail) {
        setEmail(props.history.location.state.userEmail);
      }
      if (props.history.location.state.userPhone) {
        setPhone(props.history.location.state.userPhone);
      }
    }
  }, []);

  // Definitions
  var userDispatch = useUserDispatch();
  // states
  var [isLoading, setIsLoading] = useState(false);
  var [phone, setPhone] = useState(null);
  var [email, setEmail] = useState(null);
  var [token, setToken] = useState(null);
  var [newPassword, setNewPassword] = useState("");
  var [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);

  // methods

  const submitForm = () => {
    setIsLoading(true);
    if (phone) {
      API.auth
        .phoneChangePassword({
          contacto_telefono: phone,
          contrasena: newPassword,
          confirme_contrasena: newPasswordConfirmation,
        })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setIsLoading(false);
            setToken(response.data.token.authorization);
            $("#passwordUpdated").modal("show");
          } else {
            toast.warning(response.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        });
    }

    if (email) {
      API.auth
        .emailChangePassword({
          correo_electronico: email,
          contrasena: newPassword,
          confirme_contrasena: newPasswordConfirmation,
        })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setIsLoading(false);
            setToken(response.data.token.authorization);
            $("#passwordUpdated").modal("show");
          } else {
            toast.warning(response.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        });
    }
  };

  const handleClickShowPassword = (x) => {
    if (x === 1) setShowPassword(!showPassword);
    if (x === 2) setShowConfirmationPassword(!showConfirmationPassword);
  };
  const handleMouseDownPassword = (x) => {
    if (x === 1) setShowPassword(!showPassword);
    if (x === 2) setShowConfirmationPassword(!showConfirmationPassword);
  };

  const loginAutomatically = () => {
    autoLogin(userDispatch, token, history);
  };

  return (
    <div className="row h-100 w-100 p-0 m-0">
      <div className="row h-100 w-100 m-0 p-0 outer-container justify-content-center password-recovery">
        <HeaderNoSession />
        <div
          className="col-12 p-0 inner-container banner"
          style={{ marginTop: "66px" }}
        >
          <div className="row w-100 h-100 justify-content-around align-items-center">
            <div className="col-md-6">
              <h1 className="page-title">Recuperación de contraseña</h1>
            </div>

            <div className="col-md-6 align-items-center">
              <div className="d-flex justify-content-end align-items-center right-banner-text">
                <p className="mr-3 text-white my-auto">
                  <strong>¿Ya tienes cuenta?</strong>
                </p>
                <Button
                  variant="contained"
                  size="large"
                  component={Link}
                  to={"/login"}
                  style={{
                    backgroundColor: "#08BFE6",
                    color: "white",
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                >
                  Iniciar sesión
                </Button>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row justify-content-center">
              <div
                className="col-8 d-flex justify-content-around"
                id="steps-circles-col"
                role="tablist"
              >
                <div className="steps-circle-container">
                  <div className="steps-circle steps-circle-active">1</div>
                </div>
                <div className="steps-circle-container">
                  <div className="steps-circle steps-circle-active">2</div>
                </div>
                <div className="steps-circle-container">
                  <div className="steps-circle steps-circle-active">3</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4 p-0 content" style={{ marginTop: "8rem" }}>
          <React.Fragment>
            <div className="card content-card card-body p-5">
              <h1>
                <strong>Actualización de contraseña</strong>
              </h1>
              <p>Ingrese una nueva contraseña de acceso</p>

              <div className="row">
                <div className="col-md-12">
                  <p className="mt-3 mb-0" style={{ color: "#0160E7" }}>
                    <strong>Nueva contraseña</strong>
                  </p>
                  <TextField
                    id="password"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <VisibilityIcon
                            style={{
                              fontSize: "25px",
                              opacity: "0.5",
                            }}
                            onClick={() => handleClickShowPassword(1)}
                            onMouseDown={() => handleMouseDownPassword(1)}
                          />
                        </InputAdornment>
                      ),
                    }}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    margin="normal"
                    placeholder="Ingrese nueva contraseña"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                  />
                </div>

                <div className="col-md-12">
                  <p className="mt-3 mb-0" style={{ color: "#0160E7" }}>
                    <strong>Confirme contraseña</strong>
                  </p>
                  <TextField
                    id="confirmPassword"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <VisibilityIcon
                            style={{
                              fontSize: "25px",
                              opacity: "0.5",
                            }}
                            onClick={() => handleClickShowPassword(2)}
                            onMouseDown={() => handleMouseDownPassword(2)}
                          />
                        </InputAdornment>
                      ),
                    }}
                    value={newPasswordConfirmation}
                    onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                    margin="normal"
                    placeholder="Vuelva a ingresar su contraseña"
                    type={showConfirmationPassword ? "text" : "password"}
                    fullWidth
                  />
                </div>
              </div>

              {isLoading ? (
                <div className="row mt-5 justify-content-center">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                </div>
              ) : (
                <div className="row mt-5 justify-content-end">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <Button
                      disabled={
                        newPassword.length === 0 ||
                        newPasswordConfirmation.length === 0
                      }
                      variant="contained"
                      size="large"
                      fullWidth
                      endIcon={
                        <ArrowForwardIosIcon
                          style={{
                            fontSize: "16px",
                            marginLeft: "0",
                            padding: "0",
                          }}
                        />
                      }
                      style={{
                        background: "#0160E7",
                        color: "white",
                        height: "fit-content",
                        textTransform: "none",
                        fontSize: "18px",
                      }}
                      className="ml-auto"
                      onClick={submitForm}
                    >
                      Actualizar
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        </div>
        <FooterLinks />
      </div>
      <div
        className="modal fade"
        id="passwordUpdated"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row justify-content-center align-items-center p-3">
                <div className="col-md-12">
                  <CheckCircleIcon
                    className="d-block mx-auto"
                    style={{
                      color: "#08BFE6",
                      fontSize: "60",
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <h4 className="text-center">
                    <strong>
                      Su contraseña se ha actualizado correctamente
                    </strong>
                  </h4>
                </div>
              </div>
              <div className="col-md-8 mx-auto">
                <Button
                  className="btn-cs1 mx-auto"
                  variant="contained"
                  color="primary"
                  onClick={loginAutomatically}
                  fullWidth
                >
                  Ir al Dashboard
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Register);
