import { base_url, axios } from "../api";
var qs = require("qs");
const moment = require("moment");

const headers = () => {
  return {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
};

const getAll = async (data) => {
  return await axios.post(
    `${base_url}/web/get-clients-web`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getAllDeudores = async (data) => {
  console.log("deudor");
  console.log(
    await axios.post(`${base_url}/web/get-clients-deus`, qs.stringify(data), {
      headers: headers(),
    })
  );
  return await axios.post(
    `${base_url}/web/get-clients-deus`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getAllAcreedores = async (data) => {
  return await axios.post(
    `${base_url}/web/get-clients-advances`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const add_new = async (data) => {
  if (data.telefono === "" || data.telefono === undefined) {
    data.telefono = null;
  }

  return await axios.post(`${base_url}/web/add-client`, JSON.stringify(data), {
    headers: {
      Authorization: headers().Authorization,
      "Content-type": "application/json",
    },
  });
};

const searchClient = async (data) => {
  return await axios.post(`${base_url}/web/get-client`, qs.stringify(data), {
    headers: headers(),
  });
};

const deleteClients = async (data) => {
  return await axios.post(
    `${base_url}/web/delete-clients`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const updateClient = async (data) => {
  console.log("data ->", data);
  return await axios.put(`${base_url}/web/update-client`, qs.stringify(data), {
    headers: headers(),
  });
};

const getClientSales = async (data) => {
  data.from_date = moment(data.from_date).format("YYYY-MM-DD");
  data.to_date = moment(data.to_date).format("YYYY-MM-DD");
  return await axios.post(
    `${base_url}/web/get-client-sales`,
    qs.stringify(data),
    {
      headers: {
        Authorization: headers().Authorization,
        "Content-type": "application/json",
      },
    }
  );
};

const getClientCreditNotes = async (data) => {
  return await axios.post(
    `${base_url}/web/get-client-credit-notes`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const crearAnticipo = async (data) => {
  return await axios.post(
    `${base_url}/web/client-advance-payment`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getAnticipoById = async (data) => {
  data.id_cliente_acreedor_web_app = parseInt(data.id_cliente_acreedor_web_app);
  return await axios.post(
    `${base_url}/web/get-client-advance`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getClientAnticipos = async (data) => {
  return await axios.post(
    `${base_url}/web/get-client-advances`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getClientAllAnticipos = async (data) => {
  return await axios.post(
    `${base_url}/web/get-client-all-advances`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getClientHistory = async (data) => {
  return await axios.post(
    `${base_url}/web/get-client-history`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getClientDebts = async (data) => {
  return await axios.post(
    `${base_url}/web/get-client-credits`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getClientAllDebts = async (data) => {
  return await axios.post(
    `${base_url}/web/get-client-all-credits`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getClientCancelledSales = async (data) => {
  return await axios.post(
    `${base_url}/web/get-client-cancelled-sales`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const saldarFactura = async (data) => {
  return await axios.post(
    `${base_url}/web/saldar-factura`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getCuentaPorCobrarByPk = async (data) => {
  return await axios.post(
    `${base_url}/web/get-cuenta-por-cobrar`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const useAdvancesForSale = async (data) => {
  return await axios.post(
    `${base_url}/web/post-advance-sale`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const useAdvancesForDebts = async (data) => {
  return await axios.post(
    `${base_url}/web/post-advance-due`,
    qs.stringify(data),
    { headers: headers() }
  );
};

export default {
  getAll,
  getAllDeudores,
  getAllAcreedores,
  add_new,
  searchClient,
  deleteClients,
  updateClient,
  getClientSales,
  crearAnticipo,
  getClientAnticipos,
  getClientHistory,
  getClientDebts,
  getAnticipoById,
  getClientCreditNotes,
  getClientCancelledSales,
  getClientAllDebts,
  getClientAllAnticipos,
  saldarFactura,
  getCuentaPorCobrarByPk,
  useAdvancesForSale,
  useAdvancesForDebts,
};
