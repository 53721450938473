import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import "../custom.css";
import { isCmi } from "../../../helpers/isCmi";
import CloseIcon from "@material-ui/icons/Close";
import { getMoneda } from "../../../helpers/moneda";
import CmiProductContext from "../../../context/CmiProductContext/CmiProductContext";
import ModalCorteAutorizacion from "../../../components/ModalCorteAutorizacion";
import { isAdmin } from "../../../helpers/isAdmin";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CorteModal({
  register,
  handleSubmit,
  create_corte,
  pastDay,
  getTotalEfectivo,
  summaryInfo,
  open,
  setOpen,
  cantidadRetiro,
  gerentes,
  loader,
}) {
  const {
    activarCierre,
    setActivarCierre,
    activarCierreAutorizacion,
    setActivarCierreAutorizacion,
  } = useContext(CmiProductContext);
  const classes = useStyles();
  const moneda = getMoneda();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActivarCierre(false);
  };

  //priceFormater
  const [efectivoValue, setEfectivoValue] = useState("");
  const [tarjetaValue, setTarjetaValue] = useState("");
  const [fondosValue, setFondosValue] = useState("");
  const [creditoValue, setCreditoValue] = useState("");
  const [facturaValue, setFacturaValue] = useState("");
  const [anticipoValue, setAnticipoValue] = useState("");

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    setEfectivoValue(
      isCmi()
        ? formaterPriceFunction(summaryInfo.total_efectivo.toFixed(2) + "")
        : summaryInfo.total_efectivo
        ? formaterPriceFunction(summaryInfo.total_efectivo.toFixed(2) + "")
        : "0"
    );
    setTarjetaValue(
      formaterPriceFunction(summaryInfo.total_tarjeta + "") || "0"
    );
    setFondosValue(formaterPriceFunction(summaryInfo.total_cambios + ""));
    setCreditoValue(
      formaterPriceFunction(summaryInfo.total_notas_credito + "")
    );
    setFacturaValue(
      formaterPriceFunction(summaryInfo.total_credito + "") || "0"
    );
    setAnticipoValue(summaryInfo.total_anticipos + "");
  }, [summaryInfo]);

  useEffect(() => {
    // if (activarCierre && summaryInfo.check && isAdmin()) {
    //   handleOpen();
    // }
    if (activarCierreAutorizacion) {
      handleOpen();
    }
    return () => setActivarCierreAutorizacion(false);
  }, [activarCierre, summaryInfo, activarCierreAutorizacion]);

  return (
    <div>
      {!isCmi() ? (
        <Button
          className="btn-cs1"
          variant="contained"
          color="primary"
          onClick={handleOpen}
          style={{
            width: "150px",
            height: "43px",
            boxShadow: "none",
          }}
        >
          <span style={{ color: "#fff", fontSize: "14px" }}>
            Realizar corte
          </span>
        </Button>
      ) : (
        <ModalCorteAutorizacion
          gerentes={gerentes}
          loader={loader}
          header="Realizar corte de caja"
          warning="Debes seleccionar el gerente que autoriza este corte de caja. Este deberá indicar su contraseña de acceso para confirmar la recepcion del dinero"
          infotip="Selecciona el gerente que autorizó el corte de caja"
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="card" style={{ maxWidth: "800px", border: "none" }}>
            <div className="card-title d-flex justify-content-between align-items-center border-bottom py-3">
              <strong style={{ marginLeft: "17px" }}>
                <span style={{ fontSize: "20px" }}>Cerrar caja</span>
              </strong>
              <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <form onSubmit={handleSubmit(create_corte)}>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-md-4 mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Efectivo</strong>
                      </label>
                      <Tooltip title="Efectivo en caja">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      className="w-100"
                      placeholder="Ingrese la cantidad facturada en efectivo"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      name="efectivo"
                      type="text"
                      onChange={(event) => {
                        setEfectivoValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                      inputRef={register}
                      value={efectivoValue}
                      required={true}
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Tarjeta débito / crédito</strong>
                      </label>
                      <Tooltip title="Tarjeta débito / crédito">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="Ingrese la cantidad facturada con tarjeta"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      name="tarjeta"
                      inputRef={register}
                      required={true}
                      onChange={(event) => {
                        setTarjetaValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                      value={tarjetaValue}
                    />
                  </div>

                  <TextField
                    type="hidden"
                    name="gift_card"
                    inputRef={register}
                    defaultValue={0}
                  />

                  {/* Second Row */}
                  <div className="col-md-4 mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Fondos para cambios</strong>
                      </label>
                      <Tooltip title="Fondos para cambios">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="Ingrese la cantidad que quedará en la caja"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      name="cambios"
                      inputRef={register}
                      required={true}
                      onChange={(event) => {
                        setFondosValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                      value={fondosValue}
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Notas de crédito</strong>
                      </label>
                      <Tooltip title="Notas de crédito">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="Ingrese la cantidad por notas de crédito"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      name="notas_credito"
                      inputRef={register}
                      required={true}
                      onChange={(event) => {
                        setCreditoValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                      value={creditoValue}
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Facturas a crédito</strong>
                      </label>
                      <Tooltip title="Facturas a crédito">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      type="text"
                      className="w-100"
                      placeholder="Ingrese la cantidad por facturas a crédito"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      name="facturas_creditos"
                      inputRef={register}
                      required={true}
                      onChange={(event) => {
                        setFacturaValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                      value={facturaValue}
                    />
                  </div>

                  {/* Third Row */}
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Anticipos</strong>
                      </label>
                      <Tooltip title="Anticipos">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      className="w-100"
                      placeholder="Ingrese la cantidad por anticipos"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      name="anticipos"
                      inputRef={register}
                      required={true}
                      onChange={(event) =>
                        setAnticipoValue(
                          formaterPriceFunction(event.target.value)
                        )
                      }
                      value={anticipoValue}
                    />
                  </div>
                  <div className="col-md-12 d-flex align-items-center py-4">
                    <div
                      className="form-check d-flex align-items-center"
                      style={{ fontSize: "14px" }}
                    >
                      <input
                        className="form-check-input"
                        style={{ transform: "scale(1.5)" }}
                        type="checkbox"
                        id="defaultCheck1"
                        required={true}
                      />
                      &nbsp;
                      <label
                        className="form-check-label ml-2"
                        for="defaultCheck1"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Doy fe que los datos ingresados son correctos y
                        concuerdan con los montos presentes al momento del
                        cierre de la caja.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-end">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={handleClose}
                  disabled={loader}
                >
                  <span style={{ color: "#0160E7", fontSize: "14px" }}>
                    Cancelar
                  </span>
                </Button>
                &nbsp;
                <Button
                  variant="contained"
                  style={{ background: "#0160E7", boxShadow: "none" }}
                  type="submit"
                  disabled={loader}
                  endIcon={loader && <CircularProgress size={20} />}
                >
                  <span style={{ color: "#fff", fontSize: "14px" }}>
                    Cerrar caja
                  </span>
                </Button>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
