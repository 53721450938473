import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import API from "../../../api";
const BitacoraSap = () => {
  const [loading, setLoading] = useState(false);
  const [bitacoraData, setBitacoraData] = useState([]);
  const handleGetBitacoraSap = async () => {
    try {
      const { data } = await API.products.descargaBitacoraSap();
      setBitacoraData(data.data.bitacora);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetBitacoraSap();
  }, []);
  return (
    <>
      <div className="row">
        <div className="px-2 col-md-12 d-flex align-items-center justify-content-between flex-wrap mb-3">
          <div>
            <h4 className="mb-0" style={{ fontSize: "20px", color: "#333" }}>
              <strong>Bitácora de materiales SAP</strong>
            </h4>
            <p
              className="mb-0 mt-1"
              style={{ fontSize: "16px", color: "#333" }}
            >
              Consulte y gestione la bitácora de materiales.
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-12 px-2">
          <DataTable
            className="datatable-cs datatable-cs-v2 datatable-h-80"
            title={"s"}
            columns={[
              {
                name: "Fecha",
                selector: "fecha",
                sortable: true,
                cell: (row) => moment(row.fecha).format("DD/MM/YYYY hh:mm a"),
              },
              {
                name: "Sucursal",
                selector: "sucursal_nombre",
                sortable: true,
              },

              {
                name: "Mensaje",
                selector: "mensaje",
                sortable: true,
              },
            ]}
            data={bitacoraData}
            pagination
            paginationPerPage={10}
            paginationIconFirstPage={null}
            paginationIconLastPage={null}
            paginationComponentOptions={{
              rangeSeparatorText: "de",
              noRowsPerPage: true,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
            noDataComponent="No se ha encontrado ningún resultado"
          />
        </div>
      </div>
    </>
  );
};

export default BitacoraSap;
