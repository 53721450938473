import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getAllCategories = async () => {
  return await axios.get(`${base_url}/web/get-category`, {
    headers: headers(),
  });
};

const getDatasucursalesFEL = async (data) => {
  return await axios.post(
    `${base_url}/web/fel/sucursales-fel`,
    qs.stringify(data),
    { headers: headers() }
  );
};
const deleteSucursalFel = async (id) => {
  return await axios.get(`${base_url}/web/fel/eliminar-sucfel?idSucFel=` + id, {
    headers: headers(),
  });
};

const add_category = async (data) => {
  return await axios.post(`${base_url}/web/add-category`, qs.stringify(data), {
    headers: headers(),
  });
};

const saveoreditFELsucursal = async (data) => {
  return await axios.post(
    `${base_url}/web/fel/guardar-editar-sucfel`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getAllAttributes = async () => {
  return await axios.get(`${base_url}/web/get-product-attributes`, { headers });
};
const add_attribute = async (data) => {
  return await axios.post(`${base_url}/web/add-attribute`, qs.stringify(data), {
    headers: headers(),
  });
};

const get_parameters = async () => {
  return await axios.post(`${base_url}/web/get-parameters`, qs.stringify({}), {
    headers: headers(),
  });
};
const add_parameter = async (data) => {
  return await axios.post(
    `${base_url}/web/add-parameters`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getSucursals = async (filter, aleko) => {
  return await axios.get(`${base_url}/web/get-sucursals`, {
    headers: headers(),
    params:
      filter === true
        ? aleko
          ? { filter: true, aleko: true }
          : { filter: true }
        : {},
  });
};

const getEmpresaInfo = async () => {
  return await axios.get(`${base_url}/web/get-company-data`, {
    headers: headers(),
  });
};

const getClasses = async () => {
  return await axios.get(`${base_url}/web/clases`, { headers: headers() });
};

const getRegions = async () => {
  return await axios.get(`${base_url}/web/regions`, { headers: headers() });
};

const updateEmpresa = async (data) => {
  data.clase = parseInt(data.clase);
  data.id_moneda_web_app = parseInt(data.id_moneda_web_app);
  data.region = parseInt(data.region);
  data.tipo_negocio = parseInt(data.tipo_negocio);
  return await axios.put(
    `${base_url}/web/update-company-data`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const deleteSucursal = async (data) => {
  return await axios.post(
    `${base_url}/web/delete-sucursal`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getCurrencies = async () => {
  return await axios.get(`${base_url}/web/get-currencies`, {
    headers: headers(),
  });
};

const getUserTypes = async () => {
  return await axios.get(`${base_url}/web/get-user-types`, {
    headers: headers(),
  });
};

const add_IDP = async (data) => {
  //console.log(data)
  let headersimple = {
    Authorization: localStorage.getItem("token"),
  };
  return await axios.post(`${base_url}/web/impuestos/idp`, qs.stringify(data), {
    headers: headersimple,
  });
};

const getIDP = async () => {
  let headersimple = {
    Authorization: localStorage.getItem("token"),
  };
  //console.log(headers())
  return await axios.get(`${base_url}/web/todos-idp`, {
    headers: headersimple,
  });
};
const edit_IDP = async (data) => {
  console.log(data);
  let headersimple = {
    Authorization: localStorage.getItem("token"),
  };
  return await axios.put(`${base_url}/web/impuestos/idp`, qs.stringify(data), {
    headers: headersimple,
  });
};

const getBusinessTypes = async () => {
  return await axios.get(`${base_url}/web/business-types`, {
    headers: headers(),
  });
};

const getPeriods = async () => {
  return await axios.get(`${base_url}/web/get-periods`, { headers: headers() });
};
const deleteIDP = async (params) => {
  let headersimple = {
    Authorization: localStorage.getItem("token"),
  };
  return await axios.delete(
    `${base_url}/web/impuestos/idp/${params.id_producto_idp}`,
    { headers: headersimple }
  );
};

const getAllCanalesVentas = async () => {
  return axios.get(`${base_url}/web/attention-channels/allChannelsCMISala`);
};

const updateCostoDescuento = async (data) => {
  data.id_new_empresa = parseInt(data.id_new_empresa);
  data.id_canal_cmi = parseInt(data.id_canal_cmi);
  data.costo_adicional = parseInt(data.costo_adicional);
  data.costo_descuento = parseInt(data.costo_descuento);

  return await axios.post(
    `${base_url}/web/attention-channels/createChannelCMIEmpresa`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getDescuentosCanalesVentas = async (id) => {
  return await axios.get(
    `${base_url}/web/attention-channels/allChannelsByCompany?id_new_empresa=${id}`,
    { headers: headers() }
  );
};

// niveles de precios
const getNiveles = async (id) => {
  id = parseInt(id);
  return axios.get(
    `${base_url}/web/costumers/getTypeCostumer?id_cliente=${id}`,
    { headers: headers() }
  );
};

const createNivel = async (data) => {
  // console.log("Data para enviar", data)
  data.id_cliente = parseInt(data.id_cliente);
  data.por_defecto = parseInt(data.por_defecto);
  data.subniveles = JSON.stringify(data.subniveles);

  return await axios.post(
    `${base_url}/web/costumers/levelcostumer`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const crearSubnivel = async (data) => {
  data.subniveles = JSON.stringify(data.subniveles);
  return await axios.post(
    `${base_url}/web/costumers/addSublevels`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const updateSubnivel = async (data) => {
  let sendData = data;
  sendData.subniveles = JSON.stringify(sendData.subniveles);
  return await axios.post(
    `${base_url}/web/costumers/updateSublevels`,
    qs.stringify(sendData),
    { headers: headers() }
  );
};

const eliminarNivelesSubniveles = async (
  id_nivel,
  id_sublevel = "",
  typelevel
) => {
  console.log("tipo nivel", typelevel);
  id_nivel = parseInt(id_nivel);
  if (!isNaN(id_sublevel)) {
    id_sublevel = parseInt(id_sublevel);
  }

  switch (typelevel) {
    case "nivel":
      return axios.delete(
        `${base_url}/web/costumers/deleteLevelorSublevelcostumer?id_level=${id_nivel}&typelevel=${typelevel}`,
        { headers: headers() }
      );
      break;

    case "sublevel":
      return axios.delete(
        `${base_url}/web/costumers/deleteLevelorSublevelcostumer?id_level=${id_nivel}&id_sublevel=${id_sublevel}&typelevel=${typelevel}`,
        { headers: headers() }
      );
      break;

    default:
      return false;
      break;
  }
};

export default {
  getAllCategories,
  add_category,
  getAllAttributes,
  add_attribute,
  get_parameters,
  add_parameter,
  getSucursals,
  getEmpresaInfo,
  updateEmpresa,
  deleteSucursal,
  getClasses,
  saveoreditFELsucursal,
  getRegions,
  getCurrencies,
  getUserTypes,
  getBusinessTypes,
  getPeriods,
  add_IDP,
  edit_IDP,
  getIDP,
  deleteIDP,
  getDatasucursalesFEL,
  deleteSucursalFel,
  getAllCanalesVentas,
  updateCostoDescuento,
  getDescuentosCanalesVentas,
  getNiveles,
  createNivel,
  crearSubnivel,
  eliminarNivelesSubniveles,
  updateSubnivel,
};
