import React from "react";
import moment from "moment";
import { isAleko } from "../../../helpers/isAleko";

const Recibo = ({ traslado, companyName }) => {
  return (
    <div style={{ display: "none" }}>
      <div id="printform">
        <div className="row">
          {isAleko() ? (
            <div className="col-12 d-flex justify-content-center">
              <img
                className="mb-0"
                src={"/images/logo_aleko_v1.png"}
                alt="factura-imagen"
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12">
            <h3>
              <strong>{companyName}</strong>
            </h3>
            <h3 className="text-center">
              <strong>Traslado #{traslado.numero}</strong>
            </h3>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between py-1">
                <span>
                  Fecha:{" "}
                  {moment(new Date(traslado.fecha_origen))
                    .utcOffset(0)
                    .format("YYYY-MM-DD")}
                </span>
                <span>Usuario: {traslado.usuario}</span>
              </div>

              <div className="col-md-12 d-flex justify-content-between py-1">
                <span>Bodega de origen:</span>
                <span>{traslado.origin_sucursal}</span>
              </div>

              <div className="col-md-12 d-flex justify-content-between py-1">
                <span>Bodega de destino:</span>
                <span>{traslado.destino_sucursal}</span>
              </div>
              {traslado.comentario && (
                <div className="col-md-12 d-flex justify-content-between py-1 flex-wrap">
                  <span className="w-100">Comentario:</span>
                  <span className="w-100">{traslado.comentario}</span>
                </div>
              )}

              <br />
              <br />
              <br />
              {traslado.trasladoDetalles.map((item, i) => (
                <>
                  <div
                    className="col-md-12 d-flex justify-content-between py-1"
                    key={i}
                  >
                    <span>CODIGO:</span>
                    <span>{item.codigo}</span>
                  </div>
                  <div
                    className="col-md-12 d-flex justify-content-between py-1"
                    key={i}
                  >
                    <span>NOMBRE:</span>
                    <span>{item.nombre}</span>
                  </div>
                  <div
                    className="col-md-12 d-flex justify-content-between py-1"
                    key={i}
                  >
                    <span>CANTIDAD:</span>
                    <span>{item.cantidad}</span>
                  </div>
                  <br />
                  <br />
                </>
              ))}
              <div className="col-md-12 d-flex py-1">
                Recibe:
                <div
                  className="col-md-2 d-flex justify-content-left"
                  style={{ borderBottom: "1px solid black" }}
                ></div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recibo;
