import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const RankingCompradoresReporte = ({ objeto, isGerente }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="rankingCompradores-reporte-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Valor de vida del cliente</th>
            <th>Ticket promedio</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            //console.log("objeto",x)
            let valorVida = (
              <td>
                {moneda}.{" "}
                {formaterPriceFunction(
                  x?.value ? x.value + "" : x.life_value + ""
                )}
              </td>
            );

            if (isGerente) {
              valorVida = (
                <td style={{ textAlign: "right" }}>
                  {moneda + ". " + (x?.life_value ? x.life_value : "0.00")}
                </td>
              );
            }

            return (
              <tr key={index}>
                <td>{x.nombre}</td>
                {valorVida}
                <td style={{ textAlign: "right" }}>{x.half_ticket}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RankingCompradoresReporte;
