import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import print from "print-js";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";

// components
import { isCmi } from "../../helpers/isCmi";
import { getMoneda } from "../../helpers/moneda";

import moment from "moment";
import "moment/locale/es";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";
import { validarReimpresion } from "../../api/sales";
import { isAleko } from "../../helpers/isAleko";
moment.locale("es");
const generatePdf = (name) => {
  const element = document.querySelector(".pdf-target");

  /*const options = {
		filename: name
	};

	return domToPdf(element, options, () => {
		// callback function
	}); */

  window.html2canvas = html2canvas;
  const pdf = new jsPDF("p", "pt", "a4");
  pdf.html(element, {
    callback: function (doc) {
      pdf.save(name);
    },
    html2canvas: { scale: 0.475 },
  });
};

const ref = React.createRef();

export default function Users_all(props) {
  const moneda = getMoneda();
  const [invoiceData, setInvoiceData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const convertir = require("numero-a-letras");
  const [gerente, setGerente] = useState(false);
  const { setActivarModalAlerta } = useContext(CmiProductContext);
  const [isGranContribuyente, setIsGranContribuyente] = useState(false);
  const [isGuatefacturas, setIsGuatefacturas] = useState(false);
  const [isNotaDebito, setIsNotaDebito] = useState(false);

  useEffect(() => {
    console.log("test", invoiceData);
  }, [invoiceData]);

  useEffect(() => {
    if (localStorage.getItem("invoice_data")) {
      console.log("ok");
      let invoice_data = JSON.parse(localStorage.getItem("invoice_data"));

      console.log("Receiving this data", invoice_data);
      if (invoice_data.serviceData.certificador) {
        if (
          invoice_data.serviceData.certificador.toLowerCase() == "guatefactura"
        ) {
          setIsGuatefacturas(true);
        }
      }

      if (
        invoice_data.id_nota_debito_web_app != null ||
        invoice_data.id_nota_debito_web_app > 0
      ) {
        setIsNotaDebito(true);
      }

      if (invoice_data.granContribuyente) {
        setIsGranContribuyente(true);
      }

      setInvoiceData(invoice_data);
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(
        "Ha ocurrido un error, por favor regrese a la página anterior.",
        {
          autoClose: 10000,
        }
      );
    }
  }, []);

  useEffect(() => {
    if (invoiceData && gerente) {
      showFactura();
    }
  }, [invoiceData, gerente]);

  const showFactura = () => {
    print({
      printable: "debitoForm",
      type: "html",
      targetStyles: ["*"],
      modalMessage: "Imprimiendo",
      // font_size: '8px',
      showModal: true,
      onLoadingEnd: () => {
        setOpen(true);
      },
    });
  };

  const getTotalDebito = () => {
    return Number(
      invoiceData.orderItems.reduce((a, b) => a + b.total_devolver, 0)
    ).toFixed(2);
  };

  const handleClose = (close) => {
    if (close) {
      window.close();
    }

    if (!close) {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (invoiceData && isCmi()) {
      const validarImpresion = async () => {
        const id_new_empresa = localStorage.getItem("id_empresa");
        const id_new_user = JSON.parse(localStorage.getItem("pms")).id_new_user;
        try {
          await validarReimpresion({
            id_document: invoiceData.id_pos_movimiento,
            type_document: "ND",
            id_new_empresa,
            id_new_user,
          });
          setGerente(true);
        } catch (error) {
          const tipo_usuario = JSON.parse(
            localStorage.getItem("pms")
          ).tipo_usuario;
          if (tipo_usuario.toLowerCase().includes("administrador")) {
            setGerente(true);
          } else {
            setGerente(false);
            setActivarModalAlerta(true);
          }
        }
      };
      validarImpresion();
    }
  }, [invoiceData]);

  return (
    <div id="dashboard-section">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"CLOUD"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea regresar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color="primary">
            Sí
          </Button>
          <Button onClick={() => handleClose(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Factura Nota de Débito</strong>
        </h4>
      </div>
      {invoiceData ? (
        <div className="content-card card mb-3">
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() =>
                  generatePdf(
                    "Nota de débito " + invoiceData.felData.numerofel
                      ? invoiceData.felData.numerofel
                      : invoiceData.client.nit
                  )
                }
              >
                Descargar PDF
              </button>
            </div>

            <div className="d-flex justify-content-center">
              <div className="d-none">
                <div
                  // style={{ border: '1px solid gray', width: '1240px', overflowX: 'scroll' }}
                  style={{
                    width: "col-md-12",
                    overflowX: "none",
                    lineHeight: "normal",
                  }}
                  className="d-flex justify-content-center"
                  id="debitoForm"
                >
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={16} className="mx-auto" />
                    </div>
                  ) : (
                    <div className="pdf-target px-4 py-3" ref={ref}>
                      {invoiceData.fecha_anulacion ? (
                        <div className="anulado-overlay">
                          <p style={{ color: "red" }}>NOTA ANULADA</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {isAleko() ? (
                        <div className="col-12 d-flex justify-content-center">
                          <img
                            className="mb-0"
                            src={"/images/logo_aleko_v1.png"}
                            alt="factura-imagen"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-12 mx-auto text-center">
                        {/* <img
                          className="mb-0 img_factura"
                          src="/images/avicola_icon.jpeg"
                          alt="factura-imagen"
                        /> */}
                      </div>
                      <div className="text-center">
                        <p className="mb-0">
                          <strong>{invoiceData.empresa.nombre}</strong>
                        </p>
                        <p className="mb-0">
                          <strong>
                            NIT:{" "}
                            {invoiceData.invoice.service_nit
                              ? invoiceData.invoice.service_nit.slice(
                                  0,
                                  invoiceData.invoice.service_nit.length - 1
                                ) +
                                "-" +
                                invoiceData.invoice.service_nit.slice(
                                  invoiceData.invoice.service_nit.length - 1,
                                  invoiceData.invoice.service_nit.length
                                )
                              : invoiceData.client.nit}
                          </strong>
                        </p>
                        <p className="mb-0">
                          {invoiceData.sucursal.nombre_sucursal}
                        </p>
                        <p className="mb-0">
                          {invoiceData.sucursal.direccion_sucursal}
                        </p>
                      </div>

                      <br />

                      <div className="text-center">
                        <p className="mb-0">
                          <strong>DOCUMENTO TRIBUTARIO ELECTRÓNICO</strong>
                        </p>
                        <p className="mb-0">
                          <strong>NOTA DE DÉBITO</strong>
                        </p>
                      </div>

                      {invoiceData.felData.seriefel ? (
                        <p className="mb-0">
                          <strong>
                            {isGuatefacturas ? "SERIE: " : "SERIE FEL:"}{" "}
                          </strong>
                          {invoiceData.felData.seriefel}
                        </p>
                      ) : null}
                      {invoiceData.felData.numerofel ? (
                        <p className="mb-0">
                          <strong>
                            {isGuatefacturas ? "NÚMERO: " : "NUMERO FEL: "}
                          </strong>
                          {invoiceData.felData.numerofel}
                        </p>
                      ) : null}

                      {isNotaDebito ? (
                        <div className="col-12 px-0">
                          {invoiceData.id_dte ? (
                            <p className="mb-0">
                              <strong>NÚMERO DE AUTORIZACIÓN:</strong>
                            </p>
                          ) : null}
                          {invoiceData.id_dte ? (
                            <p className="mb-0">{invoiceData.id_dte}</p>
                          ) : null}
                          {invoiceData.datosCertificador.fechafel ? (
                            <p>
                              <strong>FECHA DE EMISIÓN: </strong>
                              {/* {moment(invoiceData.datosCertificador.fecha_dte).utc().format("MM/DD/YYYY")} */}
                              {invoiceData &&
                                invoiceData.datosCertificador.fechafel
                                  ?.replace("-", "/")
                                  .replace("-", "/")}
                              {invoiceData &&
                                invoiceData.datosCertificador.fecha_dte
                                  ?.replace("-", "/")
                                  .replace("-", "/")}
                            </p>
                          ) : null}
                        </div>
                      ) : (
                        <p className="mb-3">
                          <strong>Fecha Autorizacion FEL: </strong>
                          {invoiceData.fecha_correcta
                            ? moment(invoiceData.fecha_correcta).format(
                                "DD/MM/YYYY, h:mm:ss a"
                              )
                            : moment(invoiceData.fecha).format(
                                "DD/MM/YYYY, h:mm:ss a"
                              )}
                        </p>
                      )}

                      <div className="col-md-12 px-0 mt-2">
                        <p className="mb-0">
                          {" "}
                          <strong>NIT:</strong>
                          {invoiceData.client.nit}
                        </p>
                        <p className="mb-0">
                          <strong>NOMBRE:</strong>
                          {invoiceData.datosCertificador.nombre
                            ? invoiceData.datosCertificador.nombre
                            : invoiceData.client.nombre}
                        </p>
                        <p className="mb-0">
                          <strong>DIRECCIÓN: </strong>
                          {invoiceData.client.direccion || "No especificada"}
                        </p>
                      </div>

                      {/* <div className="divider" /> */}
                      <hr style={{ borderTop: "3px dashed #bbb" }} />
                      <div className="row">
                        <div className="col-2 text-center">Cantidad</div>
                        <div className="col-4 text-center">Descripción</div>
                        <div className="col-4 text-center">
                          {isGuatefacturas ? "Precio en Q." : "Precio"}
                        </div>
                        <div className="col-2 text-center">
                          <strong>
                            {isGuatefacturas ? "Total en Q." : "Total"}
                          </strong>
                        </div>
                      </div>
                      <hr style={{ borderTop: "3px dashed #bbb" }} />
                      {/* <div className="divider" /> */}

                      {invoiceData.orderItems.map((product) => (
                        <div className="row">
                          <div className="col-2 text-center">
                            {product.cantidad_devolucion}
                          </div>
                          <div className="col-4 text-center">
                            {product.codigo} <br /> {product.nombre}
                          </div>
                          <div className="col-4 text-center">
                            {Number(product.total_devolver).toFixed(2)}
                          </div>
                          <div className="col-2 text-center">
                            {(
                              product.total_devolver *
                              product.cantidad_devolucion
                            ).toFixed(2)}
                          </div>
                        </div>
                      ))}
                      {/* <div className="divider" /> */}
                      <p className="mb-0 mt-4">
                        <strong>
                          {invoiceData.nivel_precio
                            ? `Nivel de precio: ${invoiceData.nivel_precio}`
                            : ""}
                        </strong>
                      </p>
                      <p className="mb-0 mt-4">
                        <strong>
                          {isGuatefacturas ? "FACTURA ASOCIADA: " : ""}
                        </strong>
                      </p>

                      {invoiceData.invoice.seriefel ? (
                        <p className="mb-0">
                          <strong>
                            {isGuatefacturas
                              ? "Serie: "
                              : "Serie Factura FEL: "}
                          </strong>
                          {invoiceData.invoice.seriefel}
                        </p>
                      ) : null}
                      {invoiceData.invoice.numerofel ? (
                        <p className="mb-0">
                          <strong>
                            {isGuatefacturas
                              ? "Número: "
                              : "Número Factura FEL:"}
                          </strong>
                          {invoiceData.invoice.numerofel}
                        </p>
                      ) : null}
                      {invoiceData.invoice.id_dte ? (
                        <p className="mb-0">
                          <strong>
                            {isGuatefacturas
                              ? "Número de Autorización: "
                              : "Número de Autorización Factura FEL: "}
                          </strong>
                        </p>
                      ) : null}

                      {invoiceData.invoice.id_dte ? (
                        <p className="mb-0">{invoiceData.invoice.id_dte}</p>
                      ) : null}

                      <p>
                        <strong> Fecha de emisión: </strong>
                        {moment(invoiceData.invoice.fecha).format("DD/MM/YYYY")}
                      </p>

                      <hr style={{ borderTop: "3px dashed #bbb" }} />

                      <div className="row">
                        <div
                          className="col-12 text-right"
                          style={{ paddingRight: "44px" }}
                        >
                          <strong>
                            TOTAL NOTA DE DÉBITO Q {getTotalDebito()}
                          </strong>
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{ paddingLeft: isCmi() ? "" : "150px" }}
                      >
                        <label style={{ fontSize: "13px" }}>
                          <strong>TOTAL EN LETRAS:</strong>
                          {getTotalDebito().toString() === "1.00"
                            ? moneda == "L"
                              ? "UN QUETZAL CON 00/100"
                              : "UN LEMPIRA 00/100"
                            : ""}

                          {getTotalDebito().toString() !== "1.00" && (
                            <>
                              {convertir
                                .NumerosALetras(
                                  isCmi() ? getTotalDebito() : getTotalDebito()
                                )
                                .replace(
                                  "Pesos",
                                  moneda == "L" ? "LEMPIRAS" : "QUETZALES CON"
                                )
                                .replace("M.N.", "")
                                .toLocaleUpperCase()}
                            </>
                          )}
                        </label>
                        <br />
                        <br />
                      </div>

                      {isGuatefacturas ? (
                        isGranContribuyente ? (
                          <p className="text-center">
                            <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                            <br />
                            <strong>Agente de Retención del IVA</strong>
                          </p>
                        ) : (
                          <div className="col-12 my-2">
                            <p className="text-center">
                              <strong>
                                NO GENERA DERECHO A CRÉDITO FISCAL
                              </strong>
                            </p>
                          </div>
                        )
                      ) : (
                        <div className="col-12 my-2">
                          <p className="text-center">
                            ***SUJETO A PAGOS TRIMESTRALES***
                          </p>
                        </div>
                      )}

                      <hr style={{ borderTop: "3px dashed #bbb" }} />

                      <p className="mb-0">
                        <strong>FECHA DE IMPRESIÓN:</strong>{" "}
                        {moment(
                          invoiceData.fecha_correcta
                            ? invoiceData.fecha_correcta
                            : invoiceData.fecha
                        ).format("DD/MM/YYYY, h:mm:ss a")}
                      </p>

                      {!isGuatefacturas &&
                        (invoiceData.id_dte ? (
                          <div className="text-center col-12 my-4">
                            <p className="mb-0">
                              <strong>NUMERO DE AUTORIZACIÓN</strong>
                            </p>
                            <p className="mb-0">
                              <strong>{invoiceData.id_dte}</strong>
                            </p>
                          </div>
                        ) : null)}

                      {invoiceData.invoice.seriefel ? (
                        <div className="col-md-12 mt-2 d-flex justify-content-center align-items-center">
                          <p>
                            <strong>CERTIFICADOR: </strong>{" "}
                            {isGuatefacturas
                              ? invoiceData.invoice.service_certificador
                              : invoiceData.service_certificador}
                          </p>
                          <p className="ml-4">
                            <strong>NIT: </strong>{" "}
                            {invoiceData.fel
                              ? invoiceData.fel.nit
                              : invoiceData.service_nit}
                          </p>
                        </div>
                      ) : null}

                      <div className="text-center mt-4">
                        <p className="mb-0">
                          <strong>Powered by</strong>
                        </p>
                        <p className="mb-0">
                          <a
                            href="https://www.cloud.com.gt"
                            className="href-link"
                          >
                            www.cloud.com.gt
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
