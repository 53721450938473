import {
  Button,
  ClickAwayListener,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import CustomPicker from "../../../CustomColorPicker/CustomPicker";
import AlekoContext from "../../../../../context/ContextoAleko/AlekoContext";
import ColorPickerContext from "../../../../../context/ColorPickerContext/ColorPickContext";
import ProductDetailsContext from "../../../../../context/DetalleProductosAleko/ProductDetailsContext";
import { useParams } from "react-router-dom";

const ProductInputColor = () => {
  const { saveProductColor, inputColorValidation, setClearColor, clearColor } =
    useContext(ProductDetailsContext);
  const { setProductColor, productColor, clearProductColor } =
    useContext(AlekoContext);
  const params = useParams();
  const { showColorPicker, setShowColorPicker } =
    useContext(ColorPickerContext);

  const handleColorChange = (e) => {
    setProductColor({ ...productColor, [e.target.name]: e.target.value });
  };

  const handleClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    if (clearColor) {
      clearProductColor();
      setClearColor(false);
    }
  }, [clearColor]);

  return (
    <>
      <div className="col-lg-1 text-center">
        {showColorPicker && (
          <ClickAwayListener onClickAway={handleClick}>
            <div
              className="card"
              style={{
                position: "absolute",
                top: 70,
                width: "241.96px",
                flexWrap: "wrap",
                zIndex: 10,
              }}
            >
              <CustomPicker />
            </div>
          </ClickAwayListener>
        )}
        <div
          className="border"
          style={{
            background:
              productColor.codigo_hexa_color !== ""
                ? productColor.codigo_hexa_color
                : "rgb(242,242,242)",
            borderRadius: "50%",
            height: "70px",
            width: "70px",
            lineHeight: "70px",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <span style={{ fontSize: 20 }}>+</span>
        </div>
        {productColor.codigo_hexa_color === "" && inputColorValidation && (
          <span className="text-danger" style={{ fontSize: 13 }}>
            Requerido
          </span>
        )}
      </div>

      <div className="col-lg-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Nombre</strong>
        </label>
        <TextField
          type="text"
          className="w-100"
          name="nombre_color"
          value={productColor.nombre_color}
          placeholder="Nombre el color"
          onChange={handleColorChange}
        />
        {productColor.nombre_color.trim() === "" && inputColorValidation && (
          <span className="text-danger">Este campo es requerido</span>
        )}
      </div>
      <div className="col-lg-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Cantidad disponible</strong>
        </label>
        <TextField
          type="number"
          className="w-100"
          name="cantidad_color"
          onChange={handleColorChange}
          value={productColor.cantidad_color ? productColor.cantidad_color : ""}
          placeholder="Cantidad de este color"
          InputProps={{
            inputProps: { min: 0, step: "0.00001" },
          }}
        />
        {!productColor.cantidad_color && inputColorValidation && (
          <span className="text-danger">Este campo es requerido</span>
        )}
      </div>
      <div className="col-lg-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Precio extra</strong>
        </label>
        <TextField
          type="number"
          className="w-100"
          value={productColor.precio_extra || ""}
          onChange={handleColorChange}
          name="precio_extra"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Q.</InputAdornment>
            ),
            inputProps: { min: 0, step: "0.00001" },
          }}
        />
      </div>
      <div className="col-lg-2 d-flex align-items-center">
        <Button
          className="btn-cs1 delete-btn w-100 text-light"
          variant="contained"
          onClick={() => clearProductColor()}
        >
          Eliminar
        </Button>
      </div>
      <div className="col-lg-2 d-flex align-items-center">
        <Button
          variant="contained"
          className="mt-2 w-100"
          style={{
            background: "#35baf6",
            color: "white",
            fontSize: 18,
          }}
          onClick={() => {
            saveProductColor(productColor, params.id);
          }}
        >
          Añadir
        </Button>
      </div>
    </>
  );
};

export default ProductInputColor;
