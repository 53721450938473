import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
//eslint-disable-next-line
import $ from "jquery";
import FooterLinks from "../../components/FooterLinks/FooterLinks";
import HeaderNoSession from "../../components/HeaderNoSession/Header";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// styles
import "./custom.css";
//

function Register(props) {
  let history = useHistory();
  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.userEmail) {
        setEmail(props.history.location.state.userEmail);
      }
      if (props.history.location.state.userPhone) {
        setPhone(props.history.location.state.userPhone);
      }
    }
  }, []);
  // Definitions

  // states
  var [phone, setPhone] = useState(null);
  var [email, setEmail] = useState(null);

  // methods

  const newPasswordForm = () => {
    history.push({
      pathname: "/forgot/reset",
      state: {
        userPhone: phone,
        userEmail: email,
      },
    });
  };
  return (
    <div className="row h-100 w-100 p-0 m-0">
      <div className="row h-100 w-100 m-0 p-0 outer-container justify-content-center password-recovery ">
        <HeaderNoSession />
        <div
          className="col-12 p-0 inner-container banner"
          style={{ marginTop: "66px" }}
        >
          <div className="row w-100 h-100 justify-content-around align-items-center">
            <div className="col-md-6">
              <h1 className="page-title">Recuperación de contraseña</h1>
            </div>

            <div className="col-md-6 align-items-center">
              <div className="d-flex justify-content-end align-items-center right-banner-text">
                <p className="mr-3 text-white my-auto">
                  <strong>¿Ya tienes cuenta?</strong>
                </p>
                <Button
                  variant="contained"
                  size="large"
                  component={Link}
                  to={"/login"}
                  style={{
                    backgroundColor: "#08BFE6",
                    color: "white",
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                >
                  Iniciar sesión
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row justify-content-center">
              <div
                className="col-8 d-flex justify-content-around"
                id="steps-circles-col"
                role="tablist"
              >
                <div className="steps-circle-container">
                  <div className="steps-circle steps-circle-active">1</div>
                </div>
                <div className="steps-circle-container">
                  <div className="steps-circle steps-circle-active">2</div>
                </div>
                <div className="steps-circle-container">
                  <div className="steps-circle">3</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-4 p-0 content"
          id="PWRecovery-step2"
          style={{ marginTop: "10rem" }}
        >
          <React.Fragment>
            <div className="card content-card card-body p-5">
              <CheckCircleIcon
                style={{
                  color: "#08BFE6",
                  width: "65px",
                  height: "auto",
                  marginTop: "-85px",
                }}
                className="mx-auto"
              />
              <h1>
                <strong>Se ha verificado su identidad exitosamente.</strong>
              </h1>
              <p>Proceda a actualizar su contraseña.</p>

              <div className="row mt-5 justify-content-end">
                <div className="col-lg-6 d-flex justify-content-center">
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    endIcon={
                      <ArrowForwardIosIcon
                        style={{
                          fontSize: "16px",
                          marginLeft: "0",
                          padding: "0",
                        }}
                      />
                    }
                    style={{
                      background: "#0160E7",
                      color: "white",
                      height: "fit-content",
                      textTransform: "none",
                      fontSize: "18px",
                    }}
                    className="ml-auto"
                    onClick={newPasswordForm}
                  >
                    Actualizar contraseña
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
        <FooterLinks />
      </div>
    </div>
  );
}

export default withRouter(Register);
