import React from "react";

const CategorySelect = ({ categorySelectVars }) => {
  let { Select, productCategory, changeCategory, categories } =
    categorySelectVars;

  return (
    <div className="col-lg-6 mt-2 mx-auto" id="catalog-category-select">
      <Select
        native
        value={productCategory}
        onChange={changeCategory}
        style={{ width: "100%" }}
        variant="outlined"
      >
        <option value="">Todas las categorías</option>
        {categories.map((category) => (
          <option value={category.id_clasificacion1_web_app}>
            {category.descripcion}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default CategorySelect;
