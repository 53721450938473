import React, { useContext, useState, useEffect, useMemo } from "react";
import { useSizes } from "./utils/useSizes.js";
// Styled Components

import {
  RightScreen,
  GridContainer,
  ContinueButton,
  VariantButton,
} from "./styled/styled.components";

// Components

import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";

import Variant from "./Variant/index.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const CustomizationProducts = ({ products, currentTab, setCurrentTab }) => {
  const history = useHistory();
  const product = products[currentTab];

  const {
    setCurrentProductTab,
    saveVariant,
    savedVariants,
    handleForms,
    submitForms,
    addProductWithDiscount,
    setVariants,
    variants,
  } = useContext(ProductPersonalizationContext);

  useEffect(() => {
    setVariants([product]);
    saveVariant(product);
  }, [product]);

  useEffect(() => {
    const prevVariants = savedVariants.filter(
      (item) => item?.id_new_item === product?.id_new_item
    );
    if (prevVariants.length) {
      setVariants(prevVariants);
    }
  }, [product]);

  // This handle the perspective tabs
  useEffect(() => {
    setCurrentProductTab(product);
  }, [currentTab, product]);

  const createVariant = () => {
    if (variants.length === 4) {
      toast.error("No puedes crear mas de 4 variantes por producto", {
        autoClose: 10000,
      });
      return;
    }

    const variantId = Math.random().toString(16);

    const newVariant = {
      ...product,
      variantId,
      points: [],
    };

    setVariants([...variants, newVariant]);
    saveVariant(newVariant, `variante ${variants.length + 1}`);
  };

  return (
    <>
      <GridContainer padding="0 10px" gridGap="20px" gridColumn="1/-1">
        {variants.map((variant, index) => (
          <Variant
            variant={variant}
            index={index}
            variants={variants}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            perspectives={variant.custom?.perspectives}
            setVariants={setVariants}
            initialProduct={product}
          />
        ))}

        <RightScreen
          alignContent="space-between"
          gridTemplateColumns="repeat(6,1fr)"
          gridGap="20px"
          padding="10px 0px"
          gridColumn="5/-1"
        >
          <VariantButton color="black" onClick={() => createVariant()}>
            Añadir variante
          </VariantButton>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <ContinueButton onClick={() => submitForms(history)}>
            Continuar
          </ContinueButton>
        </RightScreen>
      </GridContainer>
    </>
  );
};

export default CustomizationProducts;
