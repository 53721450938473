import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import Update from "@material-ui/icons/Update";
import Cancel from "@material-ui/icons/Cancel";

import { toast } from "react-toastify";
import API from "../../../api";

const Card_Domicilio = (props) => {
  const {
    objeto,
    direccion,
    identificador,
    direcciones,
    setDirecciones,
    removeCardDireccion,
    clientNit,
    lastAddress,
    createDirecction,
    reRender,
    seller,
    clientID,
  } = props;

  const DataValidate = () => {
    if (
      new_card_direcciones.domicilio === "" ||
      new_card_direcciones.cod_postal === "" ||
      new_card_direcciones.city === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const [new_card_direcciones, setNew_Card_Direcciones] = useState({
    domicilio: "",
    cod_postal: "",
    observaciones: "",
    city: "",
    id: "",
  });

  useEffect(() => {
    setNew_Card_Direcciones({
      domicilio: objeto.direccion,
      cod_postal: objeto.telefono,
      observaciones: objeto.observaciones,
      city: objeto.ciudad,
      id: objeto.id_direccion_entrega_cliente
        ? objeto.id_direccion_entrega_cliente
        : null,
      index: identificador,
      new: objeto.new ? true : false,
    });
  }, [objeto, reRender]);

  const handleChangeInputCardEntrega = (event) => {
    const { name, value } = event.target;
    setNew_Card_Direcciones({
      ...new_card_direcciones,
      [name]: value,
    });
  };

  const clearDirection = () => {
    setNew_Card_Direcciones({
      domicilio: "",
      cod_postal: "",
      observaciones: "",
      city: "",
    });
  };

  const ValidarCard = () => {
    console.log(
      new_card_direcciones.domicilio,
      new_card_direcciones.cod_postal,
      new_card_direcciones.city
    );
    if (!DataValidate()) {
      toast.warning("Debes llenar todos lo campos para la entrega", {
        autoClose: 10000,
      });
      return null;
    }

    if (isNaN(new_card_direcciones.cod_postal)) {
      toast.warning("El número de teléfono no es válido", { autoClose: 10000 });
      return null;
    }
    toast.success("Dirección agregada correctamente", { autoClose: 10000 });

    if (direcciones.length < 3) {
      setDirecciones([new_card_direcciones]);
    } else {
      toast.success("Card ya registrada", { autoClose: 10000 });
    }
  };

  // const obtenerUltimaDireccion = async () => {
  //   console.log("Client nit ", clientNit);
  //   const response = await API.sales.getUltimaDireccion(clientNit);
  //   console.log(response);
  // };

  const updateCard = () => {
    if (!DataValidate()) {
      toast.warning("Debes llenar todos lo campos para la entrega", {
        autoClose: 10000,
      });
      return;
    }

    API.sales
      .updateDirection({
        direccion_entrega_cliente: new_card_direcciones.domicilio,
        ciudad_entrega_cliente: new_card_direcciones.city,
        telefono_entrega_cliente: new_card_direcciones.cod_postal,
        observaciones_entrega_cliente: new_card_direcciones.observaciones,
        id_direccion_entrega_cliente: new_card_direcciones.id,
      })
      .then((res) => {
        if (res.data.succes) {
          if (
            direcciones[0]?.id ===
            new_card_direcciones.id_direccion_entrega_cliente
          ) {
            setDirecciones([
              {
                domicilio: new_card_direcciones.domicilio,
                city: new_card_direcciones.city,
                cod_postal: new_card_direcciones.cod_postal,
                observaciones: new_card_direcciones.observaciones,
                id_direccion_entrega_cliente:
                  objeto.id_direccion_entrega_cliente,
                id: objeto.id_direccion_entrega_cliente,
                index: identificador,
              },
            ]);
          }

          toast.success("Dirección actualizada correctamente", {
            autoClose: 10000,
          });
        } else {
          toast.warning("Error al actualizar dirección", {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        toast.warning("Error al actualizar dirección", {
          autoClose: 10000,
        });
      });
  };

  const deleteCard = () => {
    if (!new_card_direcciones.id) {
      removeCardDireccion(identificador);
      return;
    }

    API.sales
      .deleteDirection({
        id_direccion_entrega_cliente: new_card_direcciones.id,
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          removeCardDireccion(identificador);
          toast.success("Dirección Eliminada correctamente", {
            autoClose: 10000,
          });
        } else {
          toast.warning("Error al eliminar direccion", {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        toast.warning("Error al eliminar direccion", {
          autoClose: 10000,
        });
      });
  };

  const createCard = () => {
    console.log(clientID);
    if (!DataValidate()) {
      toast.warning("Debes llenar todos lo campos para la entrega", {
        autoClose: 10000,
      });
      return;
    }

    API.sales
      .createDirection({
        direccion_entrega_cliente: new_card_direcciones.domicilio,
        ciudad_entrega_cliente: new_card_direcciones.city,
        telefono_entrega_cliente: new_card_direcciones.cod_postal,
        observaciones_entrega_cliente: new_card_direcciones.observaciones,
        id_cliente_web_app: clientID,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setNew_Card_Direcciones({
            domicilio: new_card_direcciones.domicilio,
            city: new_card_direcciones.city,
            cod_postal: new_card_direcciones.cod_postal,
            observaciones: new_card_direcciones.observaciones,
            id_direccion_entrega_cliente:
              res.data.data.id_direccion_entrega_cliente,
            id: res.data.data.id_direccion_entrega_cliente,
            index: identificador,
            new: false,
          });

          toast.success("Dirección creada correctamente", { autoClose: 10000 });
        } else {
          toast.warning("Error al crear direccion", {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        toast.warning("Error al crear direccion", {
          autoClose: 10000,
        });
      });
  };

  return (
    <div className="w-100 ml-2 mt-2 card_domicilio">
      <div className="card_domicilio_title">
        <Cancel
          fontSize="small"
          style={{ cursor: "pointer", color: "#af0e0e" }}
          onClick={deleteCard}
        />

        {new_card_direcciones.id && (
          <input
            id={direccion}
            name="direccion_envio_registrada"
            type="radio"
            style={{
              marginLeft: "5px",
            }}
            onChange={ValidarCard}
            className="radio_card_entrega"
            value={true}
            checked={direcciones[0]?.id === new_card_direcciones.id}
          />
        )}
        <label
          for={direccion}
          style={{
            fontSize: "12px",
            marginTop: "5px",
            marginLeft: "20px",
          }}
        >
          Dirección de envío registrada, de doble línea
        </label>
      </div>
      <div className="h-100">
        <div className="col-sm-12 card_domicilio_body">
          <TextField
            type="text"
            className="input_card_domicilio row-sm-6"
            variant="standard"
            name="domicilio"
            value={new_card_direcciones.domicilio}
            onChange={handleChangeInputCardEntrega}
            placeholder="Dirección"
          />
        </div>
        <div className="col-sm-12">
          <TextField
            type="text"
            className="input_card_domicilio"
            variant="standard"
            name="city"
            value={new_card_direcciones.city}
            onChange={handleChangeInputCardEntrega}
            placeholder="Ciudad"
          />
        </div>
        <div className="col-sm-6">
          <TextField
            type="text"
            className="input_card_domicilio"
            variant="standard"
            name="cod_postal"
            value={new_card_direcciones.cod_postal}
            onChange={handleChangeInputCardEntrega}
            placeholder="Número de telefono"
          />
        </div>
        <div className="col-sm-12">
          <TextField
            type="text"
            className="input_card_domicilio"
            variant="standard"
            name="observaciones"
            value={new_card_direcciones.observaciones}
            onChange={handleChangeInputCardEntrega}
            placeholder="Observaciones"
          />
        </div>
      </div>
      {/* Btns para guardar y eliminar la card */}
      <div className="col-md-6 h-50 row justify-content-around align-items-center">
        <DeleteIcon
          color="secondary"
          fontSize="small"
          style={{ cursor: "pointer" }}
          onClick={(e) => clearDirection()}
        />

        {!new_card_direcciones.new ? (
          <Update
            fontSize="small"
            style={{ cursor: "pointer", color: "#48b572" }}
            onClick={updateCard}
          />
        ) : (
          <DoneIcon
            fontSize="small"
            style={{ cursor: "pointer", color: "#48b572" }}
            onClick={createCard}
          />
        )}
      </div>
    </div>
  );
};

export default Card_Domicilio;
