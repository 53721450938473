export const GET_SUCURSALS = "GET_SUCURSALS";
export const HANDLE_CHANGE_SUCURSAL = "HANDLE_CHANGE_SUCURSAL";
export const SET_ENTERPRISES = "SET_ENTERPRISES";
export const SET_ENTERPRISE = "SET_ENTERPRISE";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_LOADER = "SET_LOADER";
export const SET_SELECTED_PRODUCTS = "SET_SELECTED_PRODUCTS";
export const SET_NEW_PRICE = "SET_NEW_PRICE";
export const SET_VALIDATION = "SET_VALIDATION";
export const SUCCESS_UPDATE = "SUCCES_UPDATE";
export const LOADER_UPDATE = "LOADER_UPDATE";
export const CLEAR_STATE = "CLEAR_STATE";
