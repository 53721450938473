import React from "react";

// required components

import TabsHandler from "./tabs/tabs.handler";

import CategorySelect from "./tabs/categorySelect";

// Aleko = false components
import AllTab from "./tabs/alltab";
import MostSaleTab from "./tabs/mostsaletab";
import LastSoldTab from "./tabs/lastsoldtab";

// Aleko = true components
import RegularProducts from "./tabs/regular.products.js";
import CustomProducts from "./tabs/custom.products.js";

const ListTab = ({ vars }) => {
  let {
    Tabs,
    currentTab,
    handleProductTabs,
    Tab,
    Select,
    productCategory,
    changeCategory,
    categories,
    TabPanel,
    CircularProgress,
    filterProducts,
    paginatedProducts,
    handleBoxClick,
    realImageURL,
    base_url_images,
    CheckCircleIcon,
    getPrice,
    filteredProducts,
    Pagination,
    page_0,
    maxPerPage,
    handlePagination,
    filteredMostSaleProducts,
    paginatedMostSaleProducts,
    page_1,
    filteredLastSoldProducts,
    aleko,
    allCustomProducts,
  } = vars;

  let tabsHandlerVars = {
    Tabs,
    Tab,
    currentTab,
    handleProductTabs,
    tabs: aleko
      ? [{ title: "Prod.regulares" }, { title: "Prod.Personalizables" }]
      : [
          { title: "Todos" },
          { title: "Más vendidos" },
          { title: "Últimos vendidos" },
        ],
  };

  let categorySelectVars = {
    Select,
    productCategory,
    changeCategory,
    categories,
  };

  let allTabVars = {
    TabPanel,
    currentTab,
    CircularProgress,
    filteredProducts,
    paginatedProducts,
    handleBoxClick,
    realImageURL,
    base_url_images,
    CheckCircleIcon,
    getPrice,
    Pagination,
    page_0,
    maxPerPage,
    handlePagination,
  };

  let allTabVarsCustom = {
    TabPanel,
    currentTab,
    CircularProgress,
    filteredProducts,
    paginatedProducts,
    handleBoxClick,
    realImageURL,
    base_url_images,
    CheckCircleIcon,
    getPrice,
    Pagination,
    page_0,
    maxPerPage,
    handlePagination,
    custom: true,
    allCustomProducts,
  };

  let mostSaleTabVars = {
    TabPanel,
    currentTab,
    CircularProgress,
    filteredMostSaleProducts,
    paginatedMostSaleProducts,
    handleBoxClick,
    realImageURL,
    base_url_images,
    CheckCircleIcon,
    getPrice,
    Pagination,
    page_1,
    maxPerPage,
    handlePagination,
  };

  let lastSoldTabVars = {
    TabPanel,
    currentTab,
    CircularProgress,
    filteredLastSoldProducts,
    handleBoxClick,
    realImageURL,
    base_url_images,
    CheckCircleIcon,
    getPrice,
    Pagination,
    page_1,
    maxPerPage,
    handlePagination,
  };

  return (
    <div className="col-12">
      <div className="row justify-content-between align-items-center flex-wrap">
        <TabsHandler tabsHandlerVars={tabsHandlerVars} />
        <CategorySelect categorySelectVars={categorySelectVars} />

        <div className="col-12" id="products-listing">
          {aleko ? (
            <React.Fragment>
              <AllTab allTabVars={allTabVars} />
              <CustomProducts allTabVars={allTabVarsCustom} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AllTab allTabVars={allTabVars} />
              <MostSaleTab mostSaleTabVars={mostSaleTabVars} />
              <LastSoldTab lastSoldTabVars={lastSoldTabVars} />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListTab;
