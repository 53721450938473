import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const getColors = async () => {
  return await axios.get(`${base_url}/web/get-company-colors`, {
    headers: headers(),
  });
};

const setColors = async (data) => {
  return await axios.post(
    `${base_url}/web/setup-personalization`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getCompanyData = async () => {
  return await axios.get(`${base_url}/web/get-company-data`, {
    headers: headers(),
  });
};

const updateCompanyLogo = async (file) => {
  const form_data = new FormData();
  form_data.append("images", file);
  return await axios.put(`${base_url}/web/update-company-logo`, form_data, {
    headers: headers(),
  });
};

export const getUsuariosGerentes = (data) =>
  axios.post(
    `${base_url}/web/cmi/obtener-usuarios-gerentes`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );

export default {
  getColors,
  setColors,
  getCompanyData,
  updateCompanyLogo,
};
