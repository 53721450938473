import React from "react";

const LastSoldTab = ({ lastSoldTabVars }) => {
  let {
    TabPanel,
    currentTab,
    CircularProgress,
    filteredLastSoldProducts,
    handleBoxClick,
    realImageURL,
    base_url_images,
    CheckCircleIcon,
    getPrice,
    Pagination,
    page_1,
    maxPerPage,
    handlePagination,
  } = lastSoldTabVars;

  return (
    <TabPanel value={currentTab} index={2}>
      <div className="text-center mt-3" id="friggin-loader_3">
        <CircularProgress size={30} className="mx-auto" />
      </div>

      <div
        className="d-none justify-content-start align-items-center flex-wrap products-catalog"
        id="products-catalog_3"
      >
        {JSON.stringify(filteredLastSoldProducts) !== "[]" ? (
          <>
            {filteredLastSoldProducts.map((product) => (
              <div
                className="product-container"
                onClick={() => handleBoxClick(product.id_new_item, true, false)}
                id={"product-" + product.id_new_item}
              >
                <div className="product-header">
                  <img
                    src={
                      product.imagen_1
                        ? realImageURL(product.imagen_1)
                        : base_url_images + "images/placeholder-image.jpeg"
                    }
                    alt="Cloud POS - Producto"
                  />
                  <CheckCircleIcon
                    style={{
                      color: "rgba(8, 191, 230, 0.6)",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      fontSize: "40px",
                      transform: "translate(-50%, -50%)",
                    }}
                    className="d-none"
                    id={"product-icon-" + product.id_new_item}
                  />
                </div>
                <div className="product-body">
                  <p className="product-title">
                    <strong>{product.nombre}</strong>
                    <br />
                    <span className="product-price">
                      Q. {getPrice(product)}
                    </span>
                    <span className="product-stock">
                      Stock: {product.stock}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mx-auto my-3">
            <p>No se encontraron productos</p>
          </div>
        )}
      </div>
      {JSON.stringify(filteredLastSoldProducts) !== "[]" ? (
        <div className="mx-auto my-3">
          <Pagination
            value={page_1}
            defaultPage={1}
            count={Math.ceil(filteredLastSoldProducts.length / maxPerPage)}
            variant="outlined"
            onChange={handlePagination}
            color="primary"
          />
        </div>
      ) : (
        ""
      )}
    </TabPanel>
  );
};

export default LastSoldTab;
