import React from "react";
import { withRouter } from "react-router-dom";
import "./custom.css";

// context

const openPolitica = () => {
  const newWindow = window.open(
    "https://cloud.com.gt/politica-de-privacidad-de-cloud-comercial",
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
};

const openTerminos = () => {
  const newWindow = window.open(
    "https://cloud.com.gt/contrato-de-suscripcion-a-servicios-de-software",
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
};

function FooterLinks(props) {
  return (
    <div className="col-lg-12 d-flex justify-content-center align-items-center flex-column ">
      <div className="row p-0 h-25 w-100 align-items-start justify-content-start mb-2">
        <div className="col-md-2 text-center p-0">
          <p className="gray-login-text" onClick={openPolitica}>
            Política de privacidad
          </p>
        </div>

        <div className="col-md-2 text-center p-0">
          <p className="gray-login-text" onClick={openTerminos}>
            Términos y condiciones
          </p>
        </div>

      </div>
    </div>
  );
}

export default withRouter(FooterLinks);
