import React, { useState, useEffect } from "react";

//elementos
import Button from "@material-ui/core/Button";

import { base_url_images, realImageURL } from "../../../../api";
import { getMoneda } from "../../../../helpers/moneda.js";
const ProductMerma = ({
  merma,
  loading,
  setMermaSelected,
  setSavedMermaSelected,
}) => {
  const moneda = getMoneda();
  return (
    <div className="productMerma-box">
      <div className="productMerma-elementSpecial">
        <div className="productMerma-element-img">
          {merma.product.imagen_1 && (
            <img
              src={
                merma.product.imagen_1
                  ? realImageURL(merma.product.imagen_1)
                  : base_url_images + "images/placeholder-image.jpeg"
              }
            ></img>
          )}
        </div>

        <div className="productMerma-text">
          <span>Nombre del producto</span>
          <span>{merma.product.nombre}</span>
        </div>
      </div>

      <div className="productMerma-element productMerma-line">
        <div className="productMerma-text">
          <span>Empresa / Sucursal</span>
          <span>{merma.empresa.nombre}</span>
        </div>
      </div>

      <div className="productMerma-element productMerma-line">
        <div className="productMerma-text">
          <span>Precio</span>
          <span>
            {moneda}.{" "}
            {merma.product.descuento_precio > 0.0
              ? (
                  merma.product.descuento_precio +
                  (merma.product.impuesto_idp || 0)
                ).toFixed(2)
              : (
                  merma.product.precio_venta + (merma.product.impuesto_idp || 0)
                ).toFixed(2)}
          </span>
        </div>
      </div>

      <div
        className="productMerma-element productMerma-line"
        style={{ minWidth: "200px" }}
      >
        <div className="productMerma-text">
          <span>Inventario</span>
          <span>
            {merma.product.vendido_por !== "unidades"
              ? Math.abs(merma.product.stock) + " lbs."
              : merma.product.stock + " / Unid."}
          </span>
        </div>
      </div>

      <div className="productMerma-element">
        <Button
          className="btn-cs1"
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => {
            setMermaSelected(merma);
            setSavedMermaSelected(merma);
          }}
        >
          Ver histórico
        </Button>
      </div>
    </div>
  );
};

export default ProductMerma;
