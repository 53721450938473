import React from "react";

// Icons

// Styled Component

import { GridContainer } from "./styled/styled.components";
import AreaContainerForm from "./AreaContainerForm";

const AreasContainer = (props) => {
  const {
    points,
    contentType,
    customType,
    currentPerspective,
    setPoints,
    variant,
  } = props;

  return (
    <>
      <GridContainer
        gridTemplateColumns="repeat(4,1fr)"
        maxHeight="500px"
        overflow="auto"
        gridColumn="1/-1"
      >
        {points.map((item, index) => {
          return (
            <AreaContainerForm
              key={index}
              index={index}
              item={item}
              contentType={contentType}
              customType={customType}
              currentPerspective={currentPerspective}
              setPoints={setPoints}
              points={points}
              variant={variant}
              point={item}
            />
          );
        })}
      </GridContainer>
    </>
  );
};
export default AreasContainer;
