import { Tab, Tabs } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import TabPanel from "../../../components/TabPanel";
import ProductDetailsContext from "../../../context/DetalleProductosAleko/ProductDetailsContext";
import Caracteristicas from "./Caracteristicas";
import General from "./General";

const AlekoProductDetails = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const {
    getAlekoProductColors,

    getProduct,
    getAlekoProductSizes,
    getAlekoProductPersonalizations,
    clearState,
  } = useContext(ProductDetailsContext);
  const handleTab = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const params = useParams();

  useEffect(() => {
    getProduct(params.id);
  }, []);

  useEffect(() => {
    getAlekoProductColors(params.id);
    getAlekoProductSizes(params.id);
    getAlekoProductPersonalizations(params.id);
    return () => clearState();
  }, []);

  return (
    <>
      <div className="content-card card">
        <div className="card-header shadow-sm">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <Tabs
              className="mt-2"
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTab}
            >
              <Tab className="tab" label="General" />
              <Tab className="tab" label="Caracteristicas" />
            </Tabs>
          </div>
          <TabPanel value={currentTab} index={0}>
            <General />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <Caracteristicas />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default AlekoProductDetails;
