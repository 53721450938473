import React, { useState, useEffect } from "react";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
//eslint-disable-next-line
import $ from "jquery";
// styles
import useStyles from "./styles";
import "./custom.css";

// logo
import ulBullet from "./ul-check.png";
import RightArrow from "./RightArrow.png";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import { toast } from "react-toastify";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  //eslint-disable-next-line
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  const openPolitica = () => {
    const newWindow = window.open(
      "https://cloud.com.gt/politica-de-privacidad-de-cloud-comercial",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const openTerminos = () => {
    const newWindow = window.open(
      "https://cloud.com.gt/contrato-de-suscripcion-a-servicios-de-software",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    if (!!localStorage.getItem("invalid_token")) {
      //toast.error(localStorage.getItem('invalid_token'), { autoClose: 10000 });
      localStorage.removeItem("invalid_token");
    }
  }, []);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    alert("Complete Cache Cleared");
  };
  return (
    <div className="row h-100 w-100">
      <div className="col-6 logo-container">
        <div className="row h-40" />
        <div className="row justify-content-center characteristics-list">
          <div className="col-12 d-flex justify-content-center">
            <div
              className="row"
              style={{
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              <div className="text-left" style={{ height: "fit-content" }}>
                <h4 style={{ fontWeight: "bold" }}>Características de Cloud</h4>
              </div>
              <div className="text-left">
                <ul className="p-0 ml-1">
                  <li>
                    <img src={ulBullet} className="mr-2" alt="" /> Facturación
                    electrónica.
                  </li>
                  <li>
                    <img src={ulBullet} className="mr-2" alt="" /> Manejo de
                    inventario.
                  </li>
                  <li>
                    <img src={ulBullet} className="mr-2" alt="" />{" "}
                    Administración de clientes.
                  </li>
                  <li>
                    <img src={ulBullet} className="mr-2" alt="" /> Reporte de
                    ventas
                  </li>
                  <li>
                    <img src={ulBullet} className="mr-2" alt="" /> Soporte vía
                    chat y teléfono
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 d-flex justify-content-center" />
        </div>
      </div>
      <div className="col-lg-6 d-flex justify-content-center align-items-center flex-column login-wrapper">
        <div className="row p-0 h-25 text-right w-100">
          <div className="col-12">
            <p className="mr-3 gray-login-text">¿No tiene cuenta?</p>
            <Button
              component={Link}
              to={"/register"}
              underline="none"
              variant="contained"
              onClick={(e) => null}
              size="large"
              style={{
                backgroundColor: "#08BFE6",
                color: "white",
                textTransform: "none",
              }}
            >
              Registrarse
            </Button>
          </div>
        </div>
        <div className="row p-0 w-100 justify-content-center">
          <div className="col-10 login-card-wrapper">
            <div className="content-card card">
              <div className="card-body">
                <React.Fragment>
                  <h1>
                    <strong>Iniciar sesión</strong>
                  </h1>

                  <p className="mt-3 mb-0" style={{ color: "0160E7" }}>
                    <strong>Correo electrónico</strong>
                  </p>

                  <TextField
                    id="email"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={loginValue}
                    onChange={(e) => setLoginValue(e.target.value)}
                    margin="normal"
                    placeholder="Introduzca la dirección de correo electrónico"
                    type="email"
                    fullWidth
                  />
                  <div className="row mt-3 px-3 justify-content-between">
                    <p className="my-auto">
                      <strong>Contraseña</strong>
                    </p>
                    <Link to="/forgot/email-1">
                      <p className="my-auto" style={{ color: "#0160E7" }}>
                        Olvidé mi contraseña
                      </p>
                    </Link>
                  </div>

                  <TextField
                    id="password"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={passwordValue}
                    onChange={(e) => setPasswordValue(e.target.value)}
                    margin="normal"
                    placeholder="Introduzca la contraseña"
                    type="password"
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        loginUser(
                          userDispatch,
                          loginValue,
                          passwordValue,
                          props.history,
                          setIsLoading,
                          setError
                        );
                    }}
                    fullWidth
                  />
                  <div className="d-flex">
                    {isLoading ? (
                      <CircularProgress size={26} className="mx-auto" />
                    ) : (
                      <Button
                        disabled={
                          loginValue.length === 0 || passwordValue.length === 0
                        }
                        onClick={() =>
                          loginUser(
                            userDispatch,
                            loginValue,
                            passwordValue,
                            props.history,
                            setIsLoading,
                            setError
                          )
                        }
                        variant="contained"
                        size="large"
                        style={{
                          backgroundColor: "#0160E7",
                          color: "white",
                          textTransform: "none",
                        }}
                        className="ml-auto"
                      >
                        Iniciar sesión{" "}
                        <img src={RightArrow} className="ml-3 mr-2" alt="" />
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>

        <div className="row p-0 h-25 w-100 align-items-end justify-content-center">
          <div className="col-sm-3 text-center p-0">
            <p className="gray-login-text" onClick={openPolitica}>
              Política de privacidad
            </p>
          </div>

          <div className="col-sm-3 text-center p-0">
            <p className="gray-login-text" onClick={openTerminos}>
              Términos y condiciones
            </p>
          </div>

        </div>
      </div>
    </div>
  );
}

export default withRouter(Login);
