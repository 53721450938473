// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link, useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";
import { base_url_images } from "../../api";
import { getMoneda } from "../../helpers/moneda";

// styles
import "./custom.css";

//icons
import InfoIcon from "@material-ui/icons/Info";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// components

//data
let product_data = {
  id_new_item: "",
  id_empresa_new: "",
  nombre: "",
  descripcion: "",
  descuento_precio: "",
  vendido_por: "",
  notificaciones_inventario: "",
  vencimiento_fecha: "",
  rapida_venta: "",
  codigo: "",
  imagen_1: "",
  imagen_2: "",
  imagen_3: "",
  imagen_4: "",
  imagen_5: "",
  id_linea_web_app: "",
  id_clasificacion1_web_app: "",
  id_unidad_medida_web_app: "",
  precio_venta: "",
  stock: "",
  notificacion_inventario: "",
  stock_minimo: "",
  flag_vencimiento: "",
  costo_unitario: "",
  existencia_inicial: "",
  id_clasificacion2_web_app: "",
  inventariado: "",
  id_distribuidor_web_app: "",
  id_contenido_web_app: "",
  usa_lote: "",
};

let combo_products = [
  {
    cantidad: 5,
    nombre: "Producto #1",
    precio_unitario: 5.59999,
    codigo: "SKU0001",
  },
  {
    cantidad: 6,
    nombre: "Producto #2",
    precio_unitario: 6.59999,
    codigo: "SKU0002",
  },
  {
    cantidad: 7,
    nombre: "Producto #3",
    precio_unitario: 7.59999,
    codigo: "SKU0003",
  },
];

export default function Users_all(props) {
  const moneda = getMoneda();
  let history = useHistory();
  // states
  const [files, setFiles] = useState([null, null, null, null, null]);
  const [imageInputs, setImageInputs] = useState([
    null,
    null,
    null,
    null,
    null,
  ]);
  const [loader, setLoader] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { register, handleSubmit } = useForm();
  const [combo, setCombo] = useState(product_data);
  const [discountPriceEnabled, setDiscountPriceEnabled] = useState(false);

  //priceFormater
  const [descuentoValue, setDescuentoValue] = useState("");

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  useEffect(() => {
    getCombo();
  }, []);

  useEffect(() => {
    if (combo) {
      if (combo.descuento_precio) setDiscountPriceEnabled(true);
      if (combo.id_new_item !== "") {
        setLoader(false);
      }
    }
  }, [combo]);

  // set states

  //function
  const setImage = (e, img_index) => {
    let image = URL.createObjectURL(e.target.files[0]);
    let image_file = e.target.files[0];
    let newData = [...imageInputs];
    let newData_2 = [...files];
    newData[img_index] = image;
    newData_2[img_index] = image_file;
    setImageInputs(newData);
    setFiles(newData_2);
  };

  const getCombo = () => {
    let id = props.match.params.id.toString();
    API.products
      .getCombo(id)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Combo obtained:", response.data);
          if (response.data.imagen_1 !== "") {
            response.data.imagen_1 = base_url_images + response.data.imagen_1;
          } else {
            response.data.imagen_1 = "/images/photo_main.png";
          }

          if (response.data.imagen_2 !== "") {
            response.data.imagen_2 = base_url_images + response.data.imagen_2;
          } else {
            response.data.imagen_2 = "/images/photo_main.png";
          }
          if (response.data.imagen_3 !== "") {
            response.data.imagen_3 = base_url_images + response.data.imagen_3;
          } else {
            response.data.imagen_3 = "/images/photo_main.png";
          }
          if (response.data.imagen_4 !== "") {
            response.data.imagen_4 = base_url_images + response.data.imagen_4;
          } else {
            response.data.imagen_4 = "/images/photo_main.png";
          }
          if (response.data.imagen_5 !== "") {
            response.data.imagen_5 = base_url_images + response.data.imagen_2;
          } else {
            response.data.imagen_5 = "/images/photo_main.png";
          }

          setCombo(response.data);
        } else {
          toast.error(response.message, { autoClose: 10000 });
          setLoader(false);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });

        setLoader(false);
      });
  };

  const onSubmit = (data) => {
    setSubmitLoader(true);
    let validForm = true;

    if (data.descuento_precio) {
      data.descuento_precio = parseFloat(data.descuento_precio);
    } else {
      delete data.descuento_precio;
    }

    if (validForm) {
      let form_data = {
        id_new_item: combo.id_new_item,
        nombre: data.nombre,
        descuento_precio: data.descuento_precio || 0,
        descripcion: data.descripcion,
        codigo: data.codigo,
      };
      API.products
        .updateCombo(form_data, files)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            $("#save").modal();
          } else {
            toast.warning(response.message, {
              autoClose: 10000,
            });
          }
          setSubmitLoader(false);
        })
        .catch((err) => {
          let response = err.response;
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
          console.log("errrror", err);
          console.log("Ha ocurrido un error", response);
          setSubmitLoader(false);
        });
    }
  };

  const handleDiscountCheck = () => {
    setDiscountPriceEnabled(!discountPriceEnabled);
  };

  const deleteImage = (img_index) => {
    let newData = [...imageInputs];
    newData[img_index] = "/images/photo_main.png";
    setImageInputs(newData);
  };

  return (
    <div id="dashboard-section">
      <div className="modal fade" id="confirmExit" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div className="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="save" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Su combo se ha <br />
                    guardado con éxito
                  </strong>
                </h4>
              </div>

              <div className="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <Button
          className="btn-cs1 mx-1 mt-1"
          variant="contained"
          color="primary"
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon className="mr-1" style={{ fontSize: "18px" }} />
          Volver
        </Button>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        autocomplete="on"
        encType="multipart/form-data"
      >
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Detalles del combo</strong>
                </h5>
                <p className="mb-0">Información detallada acerca del combo.</p>
              </div>

              <div>
                {/* <Button
                  className="btn-cs1 mt-1 mx-1"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {submitLoader ? (
                    <div className="text-center">
                      <CircularProgress
                        size={26}
                        className="mx-auto"
                        style={{ color: "white" }}
                      />
                    </div>
                  ) : (
                    "Actualizar"
                  )}
                </Button> */}
              </div>
            </div>
          </div>
          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div className="row p-3">
              <div className="col-md-4 mt-3 ">
                <div>
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Nombre del combo</strong>
                  </label>

                  <TextField
                    defaultValue={combo.nombre}
                    disabled
                    inputRef={register}
                    name="nombre"
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el nombre de su combo"
                  />
                </div>
              </div>
              <div className="col-md-4 mt-3 ">
                <div>
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Precio de venta</strong>
                  </label>
                  <TextField
                    defaultValue={formaterPriceFunction(
                      combo.precio_venta.toFixed(2) + ""
                    )}
                    disabled
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el precio de venta de su combo"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Precio con descuento</strong>
                    </label>
                    <Tooltip title="Precio con descuento" className="mx-1">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      inputRef={register}
                      type="checkbox"
                      className="custom-control-input"
                      name="descuento_toggle"
                      checked={discountPriceEnabled}
                      onClick={handleDiscountCheck}
                      id={`customSwitchPrice`}
                    />
                    <label
                      className="custom-control-label"
                      for={`customSwitchPrice`}
                    />
                  </div>
                </div>
                <TextField
                  value={formaterPriceFunction(
                    combo.descuento_precio.toFixed(2) + ""
                  )}
                  inputRef={register}
                  type="text"
                  name="descuento_precio"
                  className="w-100"
                  disabled={!discountPriceEnabled}
                  onChange={(event) => {
                    setDescuentoValue(
                      formaterPriceFunction(event.target.value)
                    );
                  }}
                  placeholder="Ingrese el precio promocional del combo"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {moneda}.
                      </InputAdornment>
                    ),
                  }}
                />
                <small>
                  El precio con descuento sustituirá el precio original de
                  venta, puede activar y desactivar esta opción en cualquier
                  momento.
                </small>
              </div>

              {/* Fifth row */}
              <div className="col-md-6 mt-3">
                <div className="d-flex">
                  <div className="d-flex" style={{ flex: "1" }}>
                    <div
                      className="w-100"
                      style={{
                        position: "relative",
                        textAlign: "center",
                      }}
                    >
                      <HighlightOffIcon
                        className="delete-image-icon"
                        onClick={() => deleteImage(0)}
                      />
                      <img
                        src={imageInputs[0] || combo.imagen_1}
                        className="add_image_bck"
                        alt="img-input"
                        style={{ height: "350px" }}
                      />
                      <input
                        accept="image/*"
                        className="add_image_btn"
                        type="file"
                        onChange={(event) => setImage(event, 0)}
                      />
                    </div>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div className="d-flex">
                      <div
                        style={{
                          position: "relative",
                          flex: "1",
                          textAlign: "center",
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(1)}
                        />
                        <img
                          src={imageInputs[1] || combo.imagen_2}
                          className="add_image_bck"
                          alt="img-input"
                          style={{
                            height: "175px",
                            width: "175px",
                          }}
                        />
                        <input
                          accept="image/*"
                          className="add_image_btn"
                          type="file"
                          onChange={(event) => setImage(event, 1)}
                        />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          flex: "1",
                          textAlign: "center",
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(2)}
                        />
                        <img
                          src={imageInputs[2] || combo.imagen_3}
                          className="add_image_bck"
                          alt="img-input"
                          style={{
                            height: "175px",
                            width: "175px",
                          }}
                        />
                        <input
                          accept="image/*"
                          className="add_image_btn"
                          type="file"
                          onChange={(event) => setImage(event, 2)}
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div
                        style={{
                          position: "relative",
                          flex: "1",
                          textAlign: "center",
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(3)}
                        />
                        <img
                          src={imageInputs[3] || combo.imagen_4}
                          className="add_image_bck"
                          alt="img-input"
                          style={{
                            height: "175px",
                            width: "175px",
                          }}
                        />
                        <input
                          accept="image/*"
                          className="add_image_btn"
                          type="file"
                          onChange={(event) => setImage(event, 3)}
                        />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          flex: "1",
                          textAlign: "center",
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(4)}
                        />
                        <img
                          src={imageInputs[4] || combo.imagen_5}
                          className="add_image_bck"
                          alt="img-input"
                          style={{
                            height: "175px",
                            width: "175px",
                          }}
                        />
                        <input
                          accept="image/*"
                          className="add_image_btn"
                          type="file"
                          onChange={(event) => setImage(event, 4)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <div className="mt-2">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Descripción de combo (Opcional)</strong>
                  </label>
                  <TextField
                    disabled
                    defaultValue={combo.descripcion}
                    name="descripcion"
                    inputRef={register}
                    type="text"
                    multiline
                    className="w-100"
                    placeholder="Ingrese una breve descripción del combo."
                  />
                </div>

                <div className="mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Código del combo</strong>
                    </label>
                    <Tooltip title="Código del combo">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    defaultValue={combo.codigo}
                    disabled
                    inputRef={register}
                    type="text"
                    name="codigo"
                    className="w-100"
                    placeholder="Ingrese el SKU del combo"
                  />
                </div>
              </div>

              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Productos del combo</strong>
                  </label>
                </div>
              </div>

              <div className="col-md-8" />

              {combo.combo_items.length > 0 ? (
                <div className="col-12">
                  {combo.combo_items.map((input, index) => (
                    <div className="row justify-content-center">
                      <div className="col-md-1 mt-3 d-flex justify-content-center align-items-center" />

                      <div className="col-md-4 mt-3">
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>{input.nombre}</strong>
                        </p>
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          SKU: {input.codigo}
                        </p>
                      </div>

                      <div className="col-md-3 mt-3">
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Cant. por combo</strong>
                        </p>
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {input.cantidad}
                        </p>
                      </div>
                      <div className="col-md-3 mt-3">
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Precio unitario</strong>
                        </p>
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {formaterPriceFunction(input.precio_unitario + "")}
                        </p>
                      </div>
                      <div className="col-md-1 mt-3" />
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
