import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const getAll = async () => {
  return await axios.get(`${base_url}/web/get-facturas`, {
    headers: headers(),
  });
};

const getFactura = async (data) => {
  return await axios.post(`${base_url}/web/get-factura`, qs.stringify(data), {
    headers: headers(),
  });
};

const getSucursalInvoice = async (data) => {
  return await axios.post(
    `${base_url}/web/get-factura-sucursal`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const setFavorite = async (data) => {
  return await axios.post(
    `${base_url}/web/favorite-factura`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const unsetFavorite = async (data) => {
  return await axios.post(
    `${base_url}/web/unfavorite-factura`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const setFactura = async (data) => {
  return await axios.post(`${base_url}/web/set-factura`, data, {
    headers: headers(),
  });
};

export default {
  getAll,
  getFactura,
  getSucursalInvoice,
  setFavorite,
  unsetFavorite,
  setFactura,
};
