import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import "../modal.css";

import { CheckCircleOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";

export default function CajaAperturadaModal({
  setApertura,
  setDisplay,
  closeParent,
  validarCorteCaja,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (setApertura()) {
      setOpen(true);
      setDisplay(false);
    } else {
      toast.error("Ha ocurrido un error al aperturar la caja", {
        autoClose: 10000,
      });
      setDisplay(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    closeParent();
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          background: validarCorteCaja ? "#0160E7" : "#BDBDBD",
          boxShadow: "none",
          width: "150px",
          height: "42px",
        }}
        disabled={!validarCorteCaja}
        type="submit"
        // onClick={setApertura}
        onClick={handleOpen}
      >
        <span style={{ color: "#fff", fontSize: "14px" }}>Aperturar caja</span>
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="card" style={{ width: "500px", border: "none" }}>
            <div className="card-title d-flex justify-content-end align-items-center border-bottom py-3">
              <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
              <CheckCircleOutlined
                className="color-css-text"
                style={{ fontSize: "60px" }}
              />

              <h4 className="text-center">
                <strong>
                  Su caja ha sido aperturada exitosamente, ya puedes empezar a
                  vender.
                </strong>
              </h4>
            </div>
            <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-center">
              <Button
                variant="contained"
                id="back-css"
                style={{
                  boxShadow: "none",
                  width: "150px",
                  height: "42px",
                }}
                onClick={handleClose}
              >
                <span style={{ color: "#fff", fontSize: "14px" }}>Aceptar</span>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
