import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import api from "../../../api";
import { getLocalParseToken } from "../../../helpers/handleLocalStorage";
import { getRangeTime } from "../../../helpers/handleTime";

const data = [
  {
    id: 1,
    name: "Ene",
    facturacion: 5000,
    ordenes: Math.round(Math.random() * 50),
    promedio: 4000,
  },
  {
    id: 2,
    name: "Feb",
    facturacion: 3000,
    ordenes: Math.round(Math.random() * 50),
    promedio: 2000,
  },
  {
    id: 3,
    name: "Mar",
    facturacion: 5500,
    ordenes: Math.round(Math.random() * 50),
    promedio: 3500,
  },
  {
    id: 4,
    name: "Abr",
    facturacion: 4000,
    ordenes: Math.round(Math.random() * 50),
    promedio: 3000,
  },
  {
    id: 5,
    name: "May",
    facturacion: 3500,
    ordenes: Math.round(Math.random() * 50),
    promedio: 3000,
  },
  {
    id: 6,
    name: "Jun",
    facturacion: 2000,
    ordenes: Math.round(Math.random() * 50),
    promedio: 1000,
  },
  {
    id: 7,
    name: "Jul",
    facturacion: 4000,
    ordenes: Math.round(Math.random() * 50),
    promedio: 3000,
  },
  {
    id: 8,
    name: "Ago",
    facturacion: 4500,
    ordenes: Math.round(Math.random() * 50),
    promedio: 3000,
  },
  {
    id: 9,
    name: "Sep",
    facturacion: 4000,
    ordenes: Math.round(Math.random() * 50),
    promedio: 3500,
  },
  {
    id: 10,
    name: "Oct",
    facturacion: 6500,
    ordenes: Math.round(Math.random() * 50),
    promedio: 3000,
  },
  {
    id: 11,
    name: "Nov",
    facturacion: 4000,
    ordenes: Math.round(Math.random() * 50),
    promedio: 3000,
  },
  {
    id: 12,
    name: "Dec",
    facturacion: 5000,
    ordenes: Math.round(Math.random() * 50),
    promedio: 4000,
  },
];

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="card">
        <div className="card-body border-none">
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            <div className="w-100">
              <span>
                <strong>Facturación bruta:</strong>
              </span>
            </div>{" "}
            <div className="w-100 d-flex align-items-center">
              <div
                style={{
                  height: 9,
                  width: 9,
                  borderRadius: "50%",
                  background: payload[0].fill,
                }}
                className="mr-1"
              />
              Q.{" " + payload[0].value}
            </div>
          </div>
          <div className="w-100">
            <span>
              <strong>Órdenes de trabajo</strong>
            </span>
            <div className="w-100 d-flex align-items-center">
              <div
                style={{
                  height: 9,
                  width: 9,
                  borderRadius: "50%",
                  background: payload[2].fill,
                }}
                className="mr-1"
              />
              N°{" " + payload[2].value}
            </div>
          </div>
          <div className="d-flex">
            <div className="w-100">
              <span>
                <strong>Promedio</strong>
              </span>
              <div className="w-100">Q.{" " + payload[1].value}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

function Sells(props) {
  const { sucursals, actualSucursal, setActualSucursal } = props;

  let default_dates = [
    new Date(new Date().setDate(new Date().getDate() - 7)),
    new Date(),
  ];
  const [dateRange, setDateRange] = useState(default_dates);
  const [sucursalesAleko, setSucursalesAleko] = useState([]);
  const [actualSucursalSellSection, setActualSucursalSellSection] = useState(
    {}
  );
  const [empleados, setEmpleados] = useState([]);
  const [actualEmpleadoSelected, setActualEmpleadoSelected] = useState({});
  const [
    actualEmpleadoSelectedSellSection,
    setActualEmpleadoSelectedSellSection,
  ] = useState({});
  const [facturacionGraphics, setFacturacionGraphics] = useState([]);

  const changeDate = (value) => {
    setDateRange(value);
  };

  useEffect(() => {
    if (sucursals) {
      setSucursalesAleko(sucursals);
      setActualSucursal(sucursals[0]);
    }
  }, [sucursals]);

  useEffect(() => {
    if (actualSucursal) {
      getEmpleados(actualSucursal.id_linea_web_app);
    }
  }, [actualSucursal]);

  useEffect(() => {
    if (actualEmpleadoSelected) {
      getDataFacturacionInformesAleko();
    }
  }, [actualEmpleadoSelected]);

  const getEmpleados = async (id) => {
    const response = await api.cajas.getCompanySellers({
      id_sucursal_bodega_ubicacion: id,
    });

    setEmpleados(response.data.data);
    setActualEmpleadoSelected(response.data.data[0]);
  };

  // TODO: Revisar los graficos de las facturaciones
  const getDataFacturacionInformesAleko = async () => {
    let id_empresa = getLocalParseToken("id_empresa");
    let curentTime = getRangeTime(dateRange);

    try {
      const response = await api.informesAleko.getFacturacionInformesAleko(
        id_empresa,
        actualSucursal.id_linea_web_app,
        curentTime[0],
        curentTime[1],
        actualEmpleadoSelected.id_vendedor_web_app
      );

      console.log("facturación ->", response);

      if (response.data.succes) {
        setFacturacionGraphics(response.data.data.graficaFacturacion);
      }
    } catch (error) {
      console.log("error al traer los datos de las facturaciones", error);
      // toast.error("Error al obtener datos de facturación");
    }
  };

  const handleSucursalSellSelected = (e) => {
    const { value } = e.target;
    const selectSucursal = sucursalesAleko.filter(
      (item) => item.id_linea_web_app === Number(value)
    );
    setActualSucursalSellSection(selectSucursal[0]);
  };

  const handleEmpleadoSellSelected = (e) => {
    const { value } = e.target;
    const selectEmpleado = empleados.filter(
      (item) => item.id_vendedor_web_app === Number(value)
    );
    setActualEmpleadoSelectedSellSection(selectEmpleado[0]);
  };

  const aplicarFiltros = async () => {
    let id_empresa = getLocalParseToken("id_empresa");
    let curentTime = getRangeTime(dateRange);

    try {
      const response = await api.informesAleko.getFacturacionInformesAleko(
        id_empresa,
        actualSucursalSellSection.id_linea_web_app,
        curentTime[0],
        curentTime[1],
        actualEmpleadoSelectedSellSection.id_vendedor_web_app
      );

      console.log("facturación con filtros ->", response);

      if (response.data.succes) {
        setFacturacionGraphics(response.data.data.graficaFacturacion);
      }
    } catch (error) {
      console.log("error al traer los datos de las facturaciones", error);
      // toast.error("Error al obtener datos de facturación");
    }
  };

  const clearFiltres = () => {
    setDateRange(default_dates);
    setSucursalesAleko(sucursals);
    setActualSucursal(sucursals[0]);
    getDataFacturacionInformesAleko();
  };

  // console.log("facturacionGraphics", facturacionGraphics);

  return (
    <>
      <div className="card mt-3 shadow" style={{ border: "none" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 border-bottom">
              {" "}
              <h5>
                {" "}
                <strong>Facturación</strong>{" "}
              </h5>
            </div>
            <div className="col-md-12 py-3">
              <div className="row">
                <div className="col-md-8">
                  <div className="section-title">
                    <select
                      className="form-select mx-1"
                      onChange={handleSucursalSellSelected}
                    >
                      {/* <option>todas las tiendas</option> */}
                      {sucursalesAleko.map((item) => (
                        <option value={item.id_linea_web_app}>
                          {item.descripcion}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-select mx-1"
                      onChange={handleEmpleadoSellSelected}
                    >
                      {empleados.map((item) => (
                        <option value={item.id_vendedor_web_app}>
                          {item.nombre}
                        </option>
                      ))}
                      {/* <option>todos los empleados</option> */}
                    </select>
                    <DateRangePicker
                      onChange={(value) => changeDate(value)}
                      value={dateRange}
                      format={"dd-MM-y"}
                      className="date-picker-cs px-1 my-2"
                    />
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0 m-0">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mr-2"
                    onClick={clearFiltres}
                  >
                    Limpiar filtros
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={aplicarFiltros}
                  >
                    Aplicar filtros
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-10">
              {facturacionGraphics.length ? (
                <ResponsiveContainer width="100%" height={400} type={3}>
                  <AreaChart
                    width={500}
                    height={300}
                    data={facturacionGraphics}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis dataKey="total" type="number" />
                    <YAxis
                      dataKey="ordenes"
                      orientation="right"
                      type="number"
                      yAxisId="id"
                    />

                    <Tooltip content={<CustomTooltip />} />
                    <Area
                      type="monotone"
                      dataKey="total"
                      stackId="1"
                      fill="#33eaff"
                      stroke="#33eaff"
                    />
                    <Area
                      type="monotone"
                      dataKey="promedio"
                      stackId="2"
                      fill="#651fff"
                      stroke="#03a9f4"
                    />
                    <Area
                      type="monotone"
                      dataKey="ordenes"
                      stackId="2"
                      fill="#651fff"
                      stroke="#03a9f4"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              ) : (
                <div className="">No hay datos</div>
              )}
            </div>
            {facturacionGraphics.length ? (
              <div className="col-md-2 d-flex align-items-center p-0">
                <div>
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    <div className="w-100">
                      <span>
                        <strong>Facturación bruta:</strong>
                      </span>
                    </div>{" "}
                    <div className="w-100 d-flex align-items-center">
                      <div
                        style={{
                          height: 9,
                          width: 9,
                          borderRadius: "50%",
                          background: "#33eaff",
                        }}
                        className="mr-1"
                      />
                      Q.
                      {" " +
                        facturacionGraphics
                          .map((item) => item.total)
                          .reduce((a, b) => a + b)}
                    </div>
                  </div>
                  <div className="w-100">
                    <span>
                      <strong>Órdenes de trabajo</strong>
                    </span>
                    <div className="w-100 d-flex align-items-center">
                      <div
                        style={{
                          height: 9,
                          width: 9,
                          borderRadius: "50%",
                          background: "#651fff",
                        }}
                        className="mr-1"
                      />
                      N°
                      {" " +
                        facturacionGraphics
                          .map((item) => item.ordenes)
                          .reduce((a, b) => a + b)}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="w-100">
                      <span>
                        <strong>Promedio</strong>
                      </span>
                      <div className="w-100">
                        Q.
                        {" " +
                          facturacionGraphics
                            .map((item) => item.promedio)
                            .reduce((a, b) => a + b)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="">No hay datos</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Sells;
