import React, { useState, useEffect, useContext } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import "../custom.css";

import { CheckCircleOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";
import CmiProductContext from "../../../context/CmiProductContext/CmiProductContext";
import { getMoneda } from "../../../helpers/moneda";
import { formaterPriceFunction } from "../../../helpers/formater";

export default function RetiroAutorizadoModal() {
  const moneda = getMoneda();
  const {
    retiroAutorizado,
    montoRetiro,
    setRetiroAutorizado,
    setActivarModal,
  } = useContext(CmiProductContext);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (retiroAutorizado) {
      handleOpen();
    }
    return () => {
      setRetiroAutorizado(false);
    };
  }, [retiroAutorizado]);

  const handleClose = () => {
    setOpen(false);
    setActivarModal(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="card" style={{ width: "500px", border: "none" }}>
            <div className="card-title d-flex justify-content-end align-items-center border-bottom py-3">
              <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
              <CheckCircleOutlined
                className="color-css-text"
                style={{ fontSize: "60px" }}
              />

              <h4 className="text-center my-1">
                <strong>
                  Se ha validado un retiro de{" "}
                  <span>
                    {moneda}. {formaterPriceFunction(parseFloat(montoRetiro))}{" "}
                  </span>
                  exitosamente
                </strong>
              </h4>
            </div>
            <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-between">
              <Button
                variant="outlined"
                color="primary"
                style={{
                  width: "150px",
                  height: "43px",
                  boxShadow: "none",
                }}
                onClick={handleClose}
              >
                <span
                  style={{
                    color: "#0160E7",
                    fontSize: "14px",
                  }}
                >
                  Cerrar
                </span>
              </Button>
              <Button
                variant="contained"
                id="back-css"
                style={{
                  boxShadow: "none",
                  width: "150px",
                  height: "42px",
                }}
                onClick={handleClose}
              >
                <span style={{ color: "#fff", fontSize: "14px" }}>Aceptar</span>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
