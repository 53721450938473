import React, { useContext } from "react";
import ProductDetailsContext from "../../../../../context/DetalleProductosAleko/ProductDetailsContext";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import ProductSize from "./ProductSize";

const ProductSizes = () => {
  const {
    productSize,

    sizes,
    inputSizeValidation,
    clearProductSize,
    setProductSize,
    saveProductSize,
  } = useContext(ProductDetailsContext);

  const handleProductSizeChange = (e) => {
    setProductSize({ ...productSize, [e.target.name]: e.target.value });
  };
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="row">
      <label className="col-lg-2">
        <strong>Tallas</strong>
      </label>
      <div className="col-lg-10">
        <hr style={{ borderTop: "1px solid #a4b0be" }} />
      </div>
      {sizes?.map((item) => (
        <ProductSize item={item} />
      ))}
      <div className="col-lg-4">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Talla</strong>
        </label>
        <TextField
          type="text"
          className="w-100"
          name="nombre_talla"
          value={productSize.nombre_talla}
          onChange={handleProductSizeChange}
          placeholder="Añada la talla"
          InputProps={{
            inputProps: { min: 0, step: "0.00001" },
          }}
        />
        {productSize.nombre_talla.trim() === "" && inputSizeValidation && (
          <span className="text-danger">Campo requerido</span>
        )}
      </div>
      <div className="col-lg-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Cantidad disponible</strong>
        </label>
        <TextField
          type="number"
          className="w-100"
          name="cantidad_talla"
          value={productSize.cantidad_talla || ""}
          onChange={handleProductSizeChange}
          placeholder="Cantidad"
          InputProps={{
            inputProps: { min: 0, step: "0.00001" },
          }}
        />
        {!productSize.cantidad_talla && inputSizeValidation && (
          <span className="text-danger">Campo requerido</span>
        )}
      </div>
      <div className="col-lg-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Precio extra</strong>
        </label>
        <TextField
          type="text"
          className="w-100"
          value={productSize.precio_extra + "" || ""}
          onChange={handleProductSizeChange}
          name="precio_extra"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Q.</InputAdornment>
            ),
          }}
        />
      </div>
      <div className="col-lg-2 d-flex align-items-center">
        <Button
          className="btn-cs1 delete-btn w-100 text-light"
          variant="contained"
          onClick={() => clearProductSize()}
        >
          Eliminar
        </Button>
      </div>

      <div className="col-lg-2 d-flex align-items-center">
        <Button
          variant="contained"
          className="mt-2 w-100"
          style={{
            background: "#35baf6",
            color: "white",
            fontSize: 18,
          }}
          onClick={() => saveProductSize(productSize)}
        >
          Añadir
        </Button>
      </div>
    </div>
  );
};

export default ProductSizes;
