import React, { useState, useEffect } from "react";
import Table, { Thead, Tbody, Tr, Th, Td } from "react-row-select-table";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import API from "../../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";

// components

//data

export default function Users_all(props) {
  let history = useHistory();

  //component did mount
  useEffect(() => {
    get_users(1);
  }, []);

  // states

  const [users, setUsers] = useState({
    paginatedData: [],
    page: "",
    perPage: "",
    total: "",
    lastPage: "",
  });
  const [loader, setLoader] = useState(true);
  const [search_text, setSearchText] = useState("");
  const [page, setPage] = useState(1);

  // set states

  const get_users = (page, search_text) => {
    setLoader(true);
    API.admin_user
      .getAdmins({
        page,
        search_text,
      })
      .then((res) => {
        let response = res.data;
        console.log("Getting this response", response.data);
        if (response.success) {
          setUsers(response.data);
        } else {
          toast.success(response.message, {
            autoClose: 10000,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    get_users(value, search_text);
  };

  const clearSearch = () => {
    setSearchText("");
    get_users(page);
  };

  return (
    <div id="dashboard-section">
      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Usuarios</strong>
              </h5>
              <p className="mb-0">Consulte y gestione los usuarios Cloud.</p>
            </div>

            <Button
              className="btn-cs1"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={(e) => null}
              component={Link}
              to={"/admin/usuarios/crear"}
            >
              <span style={{ marginBottom: "-4px" }}>Añadir usuario</span>
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div class="row mt-3">
            <div className="col-md-4" />
            <div className="col-md-4" />
            <div className="col-md-4">
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por nombre"
                  value={search_text}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") get_users(page, search_text);
                  }}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon onClick={() => get_users(page, search_text)} />
                  </span>
                  <span
                    className="input-group-text"
                    onClick={clearSearch}
                    style={{ backgroundColor: "red" }}
                  >
                    <ClearIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="products-table user-table hide-checkbox mt-3">
            {loader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <div>
                {users.paginatedData.length > 0 ? (
                  <Table>
                    <Tbody>
                      {users.paginatedData.map((user) => (
                        <Tr>
                          <Td>
                            <div
                              style={{
                                borderRight: "1px solid #BDBDBD",
                                marginLeft: "-25px",
                              }}
                            >
                              <strong>Nombre</strong>
                              <br />
                              {user.nombre}
                            </div>
                          </Td>

                          <Td>
                            <div style={{ borderRight: "1px solid #BDBDBD" }}>
                              <strong>Cargo</strong>
                              <br />
                              {user.cargo}
                            </div>
                          </Td>

                          <Td>
                            <div style={{ borderRight: "1px solid #BDBDBD" }}>
                              <strong>Correo electrónico</strong>
                              <br />
                              {user.correo}
                            </div>
                          </Td>

                          <Td>
                            <div className="text-center w-100">
                              <Button
                                className="brand2-btn"
                                variant="contained"
                                color="primary"
                                endIcon={<ArrowForwardIosIcon />}
                                onClick={() =>
                                  history.push({
                                    pathname: `/admin/usuarios/detalles`,
                                    state: user,
                                  })
                                }
                              >
                                <span
                                  style={{
                                    marginBottom: "-4px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Ver detalles
                                </span>
                              </Button>
                            </div>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <div className="text-center">
                    <p>No se han encontrado usuarios administradores</p>
                  </div>
                )}
                <div className="text-center d-flex justify-content-center pagination mt-2">
                  <Pagination
                    count={users.lastPage}
                    page={users.page}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
