import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";

// styles
import useStyles from "./styles";
import "./customStyle.css";

//icons

// context

export default function Header(props) {
  var classes = useStyles();

  // local
  return (
    <AppBar
      position="fixed"
      className={[classes.appBar, "navbar-wrapper"].join(" ")}
    >
      <Toolbar className="navbar">
        <div className="logoContainer">
          <img src="/images/logo.png" alt="icon" />
        </div>
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );
}
