import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Link, useHistory } from "react-router-dom";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { getMoneda } from "../../helpers/moneda";
import queryString from "query-string";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// components

export default function Sell(props) {
  const moneda = getMoneda();
  let history = useHistory();
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#cancelAnticipo").modal("hide");
    };
  }, []);

  useEffect(() => {
    console.log(props);
    getSucursals();
    handleBoxClick(1);

    let params = queryString.parse(props.location.search);
    if (params) {
      if (params.id_cliente) {
        setSearchText(params.id_cliente);
        searchText = params.id_cliente;
        searchClient();
      }
    }
    console.log(params);
  }, []);

  // states
  const [loader2, setLoader2] = useState(false);
  const [sucursal, setSucursal] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [seller, setSeller] = useState("");
  const [sellers, setSellers] = useState([]);
  const [caja, setCaja] = useState("");
  const [cajas, setCajas] = useState([]);

  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [clientName, setClientName] = useState("");
  const [invoice_number, setInvoiceNumber] = useState("");
  const [concepto_anticipo, setConceptoAnticipo] = useState("");
  const [monto_anticipo, setMontoAnticipo] = useState("");
  const [additional_invoice, setAdditionalInvoice] = useState("");
  const [additional_invoices, setAdditionalInvoices] = useState([]);
  const [anticipo_tarjeta, setAnticipoTarjeta] = useState("");
  const [anticipo_efectivo, setAnticipoEfectivo] = useState("");
  const [creditDays, setCreditDays] = useState("");

  const [clientNit, setClientNit] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [creditLimit, setCreditLimit] = useState(0);
  let [searchText, setSearchText] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [userFound, setUserFound] = useState(false);
  const [clientCreationLoader, setClientCreationLoader] = useState(false);
  const [clientCode, setClientCode] = useState("");

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // set states

  /*     const addNewAdditionalInvoice = () => {
        if (additional_invoice != "") {
            let newArray = additional_invoices;
            newArray.push(additional_invoice);
            setAdditionalInvoices(newArray);
            console.log(
                "Setting new additional invoices:",
                additional_invoices
            );
        }
    }; */

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "search_text":
        setSearchText(value);
        searchText = value;
        searchClient();
        break;
      case "client_name":
        setClientName(value);
        break;
      case "direccion_fiscal":
        setClientAddress(value);
        break;
      case "email":
        setClientEmail(value);
        break;
      case "nit":
        setClientNit(value);
        break;
      case "invoice_number":
        setInvoiceNumber(value);
        break;
      case "additional_invoice":
        setAdditionalInvoice(value);
        break;
      case "concepto_anticipo":
        setConceptoAnticipo(value);
        break;
      default:
        break;
    }
  };
  const handleMoneyChange = (event) => {
    const { target } = event;
    const name = target.name;
    let parsedValue = isNaN(parseFloat(target.value))
      ? 0
      : Math.abs(parseFloat(target.value));
    target.value = isNaN(parseFloat(target.value)) ? "" : target.value;

    switch (name) {
      case "anticipo_tarjeta":
        setAnticipoTarjeta(formaterPriceFunction(target.value));
        setMontoAnticipo(formaterPriceFunction(target.value));
        break;
      case "anticipo_efectivo":
        setMontoAnticipo(formaterPriceFunction(target.value));
        setAnticipoEfectivo(formaterPriceFunction(target.value));
        break;
      default:
        break;
    }
  };

  const handleBoxClick = (x) => {
    $(".payment-method-box").each(function () {
      if ($(this).hasClass("active-box")) {
        $(this).removeClass("active-box");
      }
    });
    let currentElement = "box-" + x;
    $(`#${currentElement}`).addClass("active-box");
    setPaymentMethod(parseInt(x));

    x === 2 ? setAnticipoEfectivo("") : setAnticipoTarjeta("");
    if (x === 2) {
      setCheck1(true);
    }
    setMontoAnticipo("");
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success && JSON.stringify(response.data) != "[]") {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
          getCajas(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const changeSucursal = (event) => {
    const sucursalName = event.target.value;
    setSucursal(sucursalName);
  };

  const getCajas = (value) => {
    API.cajas
      .getCajas({ id_sucursal_bodega_ubicacion: value }, true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCajas(response.data);
          setCaja(response.data[0].id_caja_web_app);
          getSellers(response.data[0].id_caja_web_app);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const changeCaja = (event) => {
    const cajaId = event.target.value;
    setCaja(cajaId);
  };

  const getSellers = (value) => {
    API.cajas
      .getVendedores({ id_caja_web_app: value }, true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSellers(response.data);
          setSeller(response.data[0].id_vendedor_web_app);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const changeSeller = (event) => {
    const sellerId = event.target.value;
    setSeller(sellerId);
  };

  const searchClient = () => {
    setLoader2(true);
    API.clients
      .searchClient({ search_text: searchText })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("This is the data:", response.data.nit);
          setClientName(
            response.data.nit.toLowerCase().includes("cf")
              ? response.data.nombre_cliente_final
                ? response.data.nombre_cliente_final
                : response.data.nombre
              : response.data.nombre
          );
          setClientCode(response.data.client_code);
          setClientNit(response.data.nit);
          setClientAddress(response.data.direccion);
          setClientEmail(response.data.email);
          setCreditDays(response.data.dias_credito);
          setCreditLimit(response.data.limite_credito);
          setLoader2(false);
          setUserFound(true);
        } else {
          setUserFound(false);
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setLoader2(false);
        setUserFound(false);
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const resetClient = () => {
    setClientName("");
    setClientNit("");
    setClientAddress("");
    setClientEmail("");
    setCreditDays("");
    setCreditLimit("");
    setUserFound(false);
  };

  const submitForm = () => {
    let validForm = true;
    console.log(anticipo_efectivo.replaceAll(",", ""));
    console.log(anticipo_tarjeta);

    let newMonto =
      anticipo_efectivo !== ""
        ? anticipo_efectivo.replaceAll(",", "")
        : anticipo_tarjeta.replaceAll(",", "");

    let data = {
      nombre: clientName,
      direccion: clientAddress,
      email: clientEmail,
      nit: clientNit,
      concepto: concepto_anticipo,
      monto: parseFloat(newMonto),
      efectivo: anticipo_efectivo.replaceAll(",", ""),
      tarjeta: anticipo_tarjeta.replaceAll(",", ""),
      forma_pago: paymentMethod,
      recibo_numero: invoice_number,
      adicional_recibo: additional_invoice,
      id_caja: caja,
    };

    let filterData = Object.entries(data).filter(
      (el) =>
        el[0] !== "tarjeta" &&
        el[0] !== "efectivo" &&
        el[0] !== "email" &&
        el[0] !== "adicional_recibo" &&
        el[0] !== "recibo_numero"
    );

    if (filterData.length) {
      let property = filterData.find((el) => !el[1]);

      if (property) {
        property = property[0];
        if (
          property === "direccion" &&
          clientNit.toLowerCase().includes("cf")
        ) {
          validForm = true;
        } else {
          validForm = false;
          toast.error(`El campo ${property} no puede estar vacío`, {
            autoClose: 10000,
          });
        }
      }
    }
    if (!data.efectivo && !data.tarjeta) {
      toast.error(`Debe añadir un monto`, {
        autoClose: 10000,
      });
      validForm = false;
    }
    if (data.forma_pago === 2 && !data.recibo_numero) {
      toast.error(`El número de recibo no puede estar vacio`, {
        autoClose: 10000,
      });
      validForm = false;
    }

    if (validForm) {
      console.log("Submitting this:", data);
      API.clients
        .crearAnticipo({
          nombre: clientName,
          direccion: clientAddress,
          email: clientEmail,
          nit: clientNit,
          concepto: concepto_anticipo,
          monto: parseFloat(newMonto),
          efectivo: anticipo_efectivo.replaceAll(",", ""),
          tarjeta: anticipo_tarjeta.replaceAll(",", ""),
          forma_pago: paymentMethod,
          recibo_numero: invoice_number,
          adicional_recibo: additional_invoice,
          client_code: clientCode,
          id_caja: caja,
        })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success(response.message, {
              autoClose: 10000,
            });
            history.push({
              pathname: "/app/anticipo/resumen",
              state: {
                id_cliente_acreedor_web_app:
                  response.data.id_cliente_acreedor_web_app,
              },
            });
          } else {
            toast.error(response.message, { autoClose: 10000 });
          }
        })
        .catch((err) => {
          console.log(err.response);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        });
    }
  };

  const toggleCheck = (e) => {
    let target = e.target;
    if (target.name === "check1") {
      setCheck1(!check1);
    } else {
      setCheck2(!check2);
    }
  };

  const submitClient = () => {
    setClientCreationLoader(true);
    let validForm = true;
    let emailFormat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailFormat.test(clientEmail)) {
      validForm = false;
      toast.error("El correo no tiene formato válido", {
        autoClose: 10000,
      });
    }

    if (clientNit === "") {
      validForm = false;
    }
    if (clientName === "") {
      validForm = false;
    }
    if (clientAddress === "") {
      validForm = false;
    }

    if (!!validForm) {
      API.clients
        .add_new({
          nombre: clientName,
          direccion: clientAddress,
          email: clientEmail,
          nit: clientNit,
          limit: 0,
          dias_credito: 0,
        })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success("Cliente creado con exito", {
              autoClose: 10000,
            });
            setUserFound(true);
          } else {
            toast.error(response.message, { autoClose: 10000 });
          }
          setClientCreationLoader(false);
        })
        .catch((err) => {
          setClientCreationLoader(false);
          console.log(err.response);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        });
    } else {
      setClientCreationLoader(false);
      toast.error(
        "Debe llenar todos los campos de cliente para poder realizar la creación.",
        { autoClose: 10000 }
      );
    }
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={sucursal} onChange={changeSucursal}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={caja} onChange={changeCaja}>
            {cajas.map((caja) => (
              <option value={caja.id_caja_web_app}>{caja.descripcion}</option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={seller} onChange={changeSeller}>
            {sellers.map((seller) => (
              <option value={seller.id_vendedor_web_app}>
                {seller.nombre}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="content-card card">
            <div className="card-header">
              <h4 className="mb-0">
                <strong>Datos del cliente</strong>
              </h4>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Buscar cliente</strong>
                  </p>
                  <div className="input-group search-bar">
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleInputChange}
                      name="search_text"
                      className="form-control"
                      placeholder="Buscar cliente por nombre, correo o NIT"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" onClick={searchClient}>
                        {loader2 ? (
                          <CircularProgress size={16} className="mx-auto" />
                        ) : (
                          <SearchIcon />
                        )}
                      </span>
                      <span
                        className="input-group-text"
                        onClick={resetClient}
                        style={{
                          backgroundColor: "red",
                        }}
                      >
                        <ClearIcon />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>NIT</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    type="text"
                    value={clientNit}
                    onChange={(e) => setClientNit(e.target.value)}
                    placeholder="Ingrese el NIT del cliente"
                    disabled={userFound ? true : false}
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Nombre de cliente</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    placeholder="Ingrese el nombre del cliente"
                    disabled={userFound ? true : false}
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Dirección fiscal del cliente</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={clientAddress}
                    onChange={(e) => setClientAddress(e.target.value)}
                    placeholder="Ingrese la dirección fiscal del cliente"
                    disabled={userFound ? true : false}
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Correo electrónico</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={clientEmail}
                    onChange={(e) => setClientEmail(e.target.value)}
                    type="email"
                    placeholder="Ingrese el correo electrónico del cliente"
                    disabled={userFound ? true : false}
                  />
                </div>
                <div className="w-25 mx-auto">
                  <Button
                    style={{
                      backgroundColor: "#1a2eab",
                      color: "white",
                    }}
                    className="my-2 w-75 crearcliente-button"
                    variant="contained"
                    onClick={submitClient}
                    disabled={userFound ? true : false}
                  >
                    <span
                      style={{
                        marginBottom: "-4px",
                        textTransform: "none",
                      }}
                    >
                      {clientCreationLoader ? (
                        <CircularProgress size={16} className="mx-auto" />
                      ) : (
                        "Crear cliente"
                      )}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row justify-content-between p-3">
            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIosIcon style={{ fontSize: "15px" }} />}
              component={Link}
              to={"/app/vender"}
            >
              <span style={{ marginBottom: "-4px" }}>Volver a la caja</span>
            </Button>
            <Button
              className="btn-cs1 icon-btn-delete mx-1"
              variant="contained"
              color="primary"
              data-toggle="modal"
              data-target="#cancelAnticipo"
            >
              <span style={{ marginBottom: "-4px" }}>Cancelar anticipo</span>
            </Button>
          </div>
          <div className="card content-card mt-2">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-5">
                  <div className="d-flex align-items-center justify-content-center flex-wrap mb-3">
                    <div
                      className="payment-method-box d-flex align-items-center justify-content-center"
                      id="box-1"
                      style={{ height: "90px" }}
                      onClick={() => handleBoxClick(1)}
                    >
                      <p>
                        {" "}
                        <LocalAtmIcon /> <br />
                        Efectivo
                      </p>
                    </div>
                    <div
                      className="payment-method-box d-flex align-items-center justify-content-center"
                      id="box-2"
                      style={{ height: "90px" }}
                      onClick={() => handleBoxClick(2)}
                    >
                      <p>
                        <CreditCardIcon /> <br />
                        Tarjeta o Depósito
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 invoice-column py-2">
                  <div className="row h-25 text-left px-4">
                    <p>
                      <strong>Monto a anticipar:</strong>
                    </p>
                  </div>
                  <div className="row h-75 justify-content-center w-100 m-0 mt-2 px-3 stubborn-input">
                    <TextField
                      type="text"
                      step="any"
                      style={{ color: "black" }}
                      className="w-100"
                      variant="outlined"
                      value={monto_anticipo}
                      placeholder="Ingrese el monto a anticipar"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-4 my-auto text-center">
                  <Button
                    className="btn-cs1 mx-1 mb-1"
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    <span
                      style={{
                        marginBottom: "-4px",
                        fontSize: "18px",
                      }}
                    >
                      Crear anticipo
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        {paymentMethod === 2 ? (
                          <div className="col-md">
                            <p>
                              <strong>Tarjeta de débito/crédito</strong>
                            </p>
                            <TextField
                              type="text"
                              step="any"
                              className="w-100"
                              variant="standard"
                              value={anticipo_tarjeta}
                              name="anticipo_tarjeta"
                              onChange={(e) => handleMoneyChange(e)}
                              placeholder="Ingrese el monto del anticipo en tarjeta"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {paymentMethod === 1 ? (
                          <div className="col-md">
                            <p>
                              <strong>Efectivo</strong>
                            </p>
                            <TextField
                              type="text"
                              step="any"
                              className="w-100"
                              variant="standard"
                              value={anticipo_efectivo}
                              name="anticipo_efectivo"
                              onChange={(e) => handleMoneyChange(e)}
                              placeholder="Ingrese el monto del anticipo en efectivo"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <p>
                        <strong>Concepto del anticipo</strong>
                      </p>
                      <TextField
                        className="w-100"
                        variant="standard"
                        value={concepto_anticipo}
                        name="concepto_anticipo"
                        onChange={handleInputChange}
                        placeholder="Ingrese el concepto del anticipo"
                      />
                    </div>
                  </div>
                </div>
                {paymentMethod === 2 ? (
                  <div className="col-sm-12 mt-2">
                    <h4>
                      <strong>Verificación del pago</strong>
                    </h4>
                    <div className="row">
                      <div className="col-12 my-auto">
                        <div className="row">
                          <div className="col-xl mb-2">
                            <p>
                              <strong>
                                Número del recibo (Operación con tarjeta)
                              </strong>
                            </p>
                            <TextField
                              className="w-100"
                              variant="standard"
                              value={invoice_number}
                              name="invoice_number"
                              onChange={handleInputChange}
                              disabled={!check1}
                              placeholder="Ingrese el número del recibo"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    N°
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="check1"
                                id="defaultCheck1"
                                checked={check1}
                                onChange={toggleCheck}
                              />
                              <label
                                class="form-check-label ml-2"
                                for="defaultCheck1"
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                Ingresar manualmente
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mt-5">
                        <div className="row w-100 justify-content-center justify-content-md-start">
                          <div className="col-xl">
                            <p className="mb-0">
                              <strong>
                                Número del recibo de pago para tarjeta adicional
                              </strong>
                            </p>
                            <TextField
                              className="w-100 mb-3"
                              variant="standard"
                              value={additional_invoice}
                              name="additional_invoice"
                              onChange={handleInputChange}
                              disabled={!check2}
                              placeholder="Ingrese el número del recibo de la tarjeta adicional"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    N°
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="check2"
                                id="defaultCheck2"
                                checked={check2}
                                onChange={toggleCheck}
                              />
                              <label
                                class="form-check-label ml-2"
                                for="defaultCheck1"
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                Ingresar manualmente
                              </label>
                            </div>
                          </div>
                          {/* <div className="col-xl-1 text-center my-auto bg_secondary_color">
                                                        <AddIcon
                                                            style={{
                                                                fontSize:
                                                                    "30px",
                                                                color: "white",
                                                            }}
                                                            onClick={
                                                                addNewAdditionalInvoice
                                                            }
                                                        />
                                                    </div> */}
                        </div>
                        <div className="row w-100 mt-2 justify-content-center justify-content-md-start">
                          {additional_invoices.map((invoice) => (
                            <div className="col-xl-4 px-1">
                              <TextField
                                className="w-100"
                                variant="standard"
                                disabled
                                value={invoice}
                                name="invoice_number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      Recibo N°
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="cancelAnticipo"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row justify-content-center align-items-center p-3">
                <div className="col-md-12">
                  <CheckCircleIcon
                    className="d-block mx-auto"
                    style={{
                      color: "#08BFE6",
                      fontSize: "60",
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <h4 className="text-center">
                    <strong>
                      ¿Estás seguro de que quieres cancelar este anticipo?
                    </strong>
                  </h4>
                </div>
              </div>
              <div className="col-md-8 mx-auto d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => props.history.goBack()}
                >
                  Sí, cancelar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
