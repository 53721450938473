import React, { useState, useEffect, useContext, useRef } from "react";
import $ from "jquery";
//eslint-disable-next-line
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import API from "../../api";
import { toast } from "react-toastify";
import "./custom.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ClearIcon from '@material-ui/icons/Clear';
import HomeIcon from "@material-ui/icons/Home";
import TablePreVenta from "../../reportes/TablePreVenta";

export default function PreVenta(props) {

  let history = useHistory();
  const [loader, setLoader] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [dataPrefactura, setDataPreFactura] = useState({});
  const [totalFilas, setTotalFilas] = useState(0);
  const [noOrden, setNoOrden] = useState(0);

  const recuperaPrefacturas = async () => {

    API.sales
      .getAllPrefacturas({
        searchGeneral: searchValue,
        id_new_empresa: localStorage.getItem('id_empresa')
      })
      .then(({ data }) => {
        if (data.success) {
          const datos = data.data
          setDataPreFactura(datos);
          setTotalFilas(datos.length);
        } else {

        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const recuperaItemsPrefacturas = async (id_orden, datosCliente) => {
    API.sales
      .getAllItemsPrefacturas({
        searchGeneral: searchValue,
        id_new_empresa: localStorage.getItem('id_empresa'),
        id_orden
      })
      .then(({ data }) => {
        if (data.success) {
          console.log(data);

          const itemJson = JSON.stringify(data.data);
          localStorage.setItem('selectedProducts', itemJson);

          const jsonSalesCost = {
            total: (data.totalVenta) ? data.totalVenta: 0,
            subtotal: 0,
            iva: 0
          }
          localStorage.setItem('salesCost', JSON.stringify(jsonSalesCost));
          localStorage.setItem('id_post_movimiento_posfactura', id_orden);
          localStorage.setItem('datosCliente', JSON.stringify(datosCliente));
          history.push("/app/vender");

        } else {

        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const cancelarPrefacturas = async () => {
    API.sales
      .anularPostVenta({
        id_pos_movimiento: noOrden,
        id_new_empresa: localStorage.getItem('id_empresa'),
        cancelaPrefactura: true,
        ventaPrefactura: false
      })
      .then(async (res) => {
        let response = res.data;
        if (response.success) {
          $("#create_anulacion").modal("hide");
          await recuperaPrefacturas(searchValue)
          toast.success("La post-facturacion fue cancelada exitosamente.", {
            autoClose: 10000,
          });
        } else {
          toast.error(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", {
            autoClose: 10000,
          });
        }
      });
  };

  const handleOpenModal = (id_orden) => {
    $("#create_anulacion").modal("show");
    setNoOrden(id_orden);
    console.log("openModal");
  }

  const handleReimprimir = (id_orden) => {
    history.push('/app/vender/resumen/' + id_orden);
  }

  const columnas = [
    {
      name: "No. Orden",
      selector: "No_Orden",
      sortable: true,
      maxWidth: '120px',
      center: true
    },
    {
      name: "Cliente",
      selector: "Cliente",
      sortable: true,
    },
    {
      name: "Dirección",
      selector: "Direccion",
      sortable: true,
    },
    {
      name: "Estado",
      selector: "Estado",
      sortable: true,
      width: "140px",
      center: true
    },
    {
      name: "Acciones",
      selector: "action",
      width: "225px",

      cell: (row) => (
        <div className="text-center w-100">
          {row.Estado === 'Facturado' ?
            <Button
              className="btn-cs1 ver-detalles-btn-container"
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => handleReimprimir(row.id_factura_asociada)}
              size="small"
            >
              <span
                style={{
                  marginBottom: "-4px",
                  fontSize: "12px",
                }}
              >
                <span
                  className="d-none d-xl-inline"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Re Imprimir
                </span>
              </span>
            </Button>
            :
            <>
              <Button
                className="mr-3 ver-detalles-btn-container"
                variant="contained"
                endIcon={<ClearIcon style={{ color: 'white' }} />}
                style={{ backgroundColor: '#EB5757', fontPalette: 'light' }}
                onClick={() => handleOpenModal(row.No_Orden)}
                size="small"
              >
                <span
                  style={{
                    marginBottom: "-4px",
                    fontSize: "12px",
                  }}
                >
                  <span
                    className="d-none d-xl-inline"
                    style={{
                      fontSize: "12px",
                      color: 'white'
                    }}
                  >
                    Cancelar
                  </span>
                </span>
              </Button>
              <Button
                className="btn-cs1 ver-detalles-btn-container"
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIosIcon />}
                onClick={() => recuperaItemsPrefacturas(row.No_Orden, row.datosCliente)}
                size="small"
              >
                <span
                  style={{
                    marginBottom: "-4px",
                    fontSize: "12px",
                  }}
                >
                  <span
                    className="d-none d-xl-inline"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Facturar
                  </span>
                </span>
              </Button>
            </>
          }
        </div>

      ),
    },
  ]

  useEffect(() => {
    const peticionInicial = async () => {
      await recuperaPrefacturas();
    }
    peticionInicial();
  }, []);

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Pedidos</strong>
        </h4>
      </div>
      <div className="content-card card">
        <div className="card-body">
          <TablePreVenta columnas={columnas}
            data={dataPrefactura}
            searchValue={searchValue}
            handleChangeSearchValue={handleChangeSearchValue}
            totalFilas={totalFilas}
            fnBusqueda = {recuperaPrefacturas}
            placeHolder = "Busqueda por nombre o NIT"
          ></TablePreVenta>
        </div>
      </div>

      {/*MODALES PARA ANULACION DE PRE FACTURA*/}
      <div
        class="modal fade"
        id="create_anulacion"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="d-flex justify-content-end px-2 py-1">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <label style={{ fontSize: "1rem" }}>
                <strong>¿Esta seguro que desea cancelar la prefacturacion {noOrden}?</strong>
              </label>
              <div class="d-flex justify-content-between mt-2">
                <Button className="btn-cs1 delete-btn" variant="contained" color="primary"
                  onClick={() => $("#create_anulacion").modal("hide")}> No </Button>
                <Button className="btn-cs1" variant="contained" color="primary" data-dismiss="modal"
                  onClick={cancelarPrefacturas}
                > Si, Cancelar </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
