import {
  GET_SUCURSALS,
  HANDLE_CHANGE_SUCURSAL,
  LOADER_UPDATE,
  SET_ENTERPRISE,
  SET_ENTERPRISES,
  SET_LOADER,
  SET_NEW_PRICE,
  SET_PRODUCTS,
  SET_SELECTED_PRODUCTS,
  SET_VALIDATION,
  SUCCESS_UPDATE,
  CLEAR_STATE,
} from "./types";

export const CambioMasivoReducer = (state, action) => {
  switch (action.type) {
    case GET_SUCURSALS:
      return {
        ...state,
        sucursals: action.payload,
        sucursal: action.payload[0].id_sucursal_bodega_ubicacion,
      };
    case HANDLE_CHANGE_SUCURSAL:
      return {
        ...state,
        sucursal: action.payload,
      };
    case SET_ENTERPRISES:
      return {
        ...state,
        enterprises: action.payload,
        enterprise: "Todas",
      };
    case SET_ENTERPRISE:
      return {
        ...state,

        enterprise: action.payload,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload.sort((a, b) =>
          a.ubicacion.localeCompare(b.ubicacion)
        ),
        loading: false,
      };

    case SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: action.payload,
      };
    case SET_NEW_PRICE:
      return {
        ...state,
        newPrice: action.payload,
      };
    case SET_LOADER:
      return {
        ...state,
        loading: true,
        products: [],
      };
    case SET_VALIDATION:
      return {
        ...state,
        validation: action.payload,
      };
    case SUCCESS_UPDATE:
      return {
        ...state,
        updated: action.payload,
      };
    case LOADER_UPDATE:
      return {
        ...state,
        loaderUpdate: action.payload,
      };
    case CLEAR_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
