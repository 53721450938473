import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
//eslint-disable-next-line
import { Link } from "react-router-dom";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DataTable from "react-data-table-component";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { formaterPriceFunction } from "../../helpers/formater";
import ReactApexChart from "react-apexcharts";
import { useHistory } from "react-router-dom";

// styles
import "./profile.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import { getMoneda } from "../../helpers/moneda";

// components

//data
const clients_data = {
  name: "Marcos Solís",
  nit: "1325365",
  direccion_fiscal: "Av. 5 Julio, Calle independencia, Guatemala, Guatemala",
};

const anual_stats = {
  tasa_recompra: 60,
  categorias_mas_compradas_names: ["Categoria 1", "Categoría 2", "Categoría 3"],
  categorias_mas_compradas_percentages: [40, 20, 40],
  valor_vida: 111256.66,
  ticket_promedio: 1256.66,
  notas_credito: 22,
  ventas_anuladas: 63,
  total_compras: 1163,
  dinero_gastado: [
    { month: "Ene", expense: 200 },
    { month: "Feb", expense: 300 },
    { month: "Mar", expense: 450 },
    { month: "Abr", expense: 150 },
    { month: "May", expense: 120 },
    { month: "Jun", expense: 290 },
    { month: "Jul", expense: 400 },
    { month: "Ago", expense: 600 },
    { month: "Sep", expense: 700 },
    { month: "Oct", expense: 100 },
    { month: "Nov", expense: 250 },
    { month: "Dic", expense: 290 },
  ],
};

const monthly_stats = {
  tasa_recompra: 60,
  categorias_mas_compradas_names: ["Categoria 1", "Categoría 2", "Categoría 3"],
  categorias_mas_compradas_percentages: [35, 35, 30],
  valor_vida: 111256.66,
  ticket_promedio: 1256.66,
  notas_credito: 22,
  ventas_anuladas: 63,
  total_compras: 1163,
  dinero_gastado: [
    { month: "1", expense: 200 },
    { month: "2", expense: 300 },
    { month: "3", expense: 450 },
    { month: "4", expense: 150 },
    { month: "5", expense: 120 },
    { month: "6", expense: 290 },
    { month: "7", expense: 400 },
    { month: "8", expense: 600 },
    { month: "9", expense: 700 },
    { month: "10", expense: 100 },
    { month: "11", expense: 250 },
    { month: "12", expense: 290 },
    { month: "13", expense: 120 },
    { month: "14", expense: 290 },
    { month: "15", expense: 400 },
    { month: "16", expense: 600 },
    { month: "17", expense: 700 },
    { month: "18", expense: 100 },
    { month: "19", expense: 250 },
    { month: "20", expense: 290 },
    { month: "21", expense: 210 },
    { month: "22", expense: 500 },
    { month: "23", expense: 140 },
    { month: "24", expense: 400 },
    { month: "25", expense: 235 },
    { month: "26", expense: 350 },
    { month: "27", expense: 400 },
    { month: "28", expense: 450 },
    { month: "29", expense: 500 },
    { month: "30", expense: 550 },
  ],
};

const weekly_stats = {
  tasa_recompra: 10,
  categorias_mas_compradas_names: ["Categoria 1", "Categoría 2", "Categoría 3"],
  categorias_mas_compradas_percentages: [30, 40, 30],
  valor_vida: 12325,
  ticket_promedio: 123,
  notas_credito: 12,
  ventas_anuladas: 12,
  total_compras: 11223,
  dinero_gastado: [
    { month: "Lun", expense: 200 },
    { month: "Mar", expense: 300 },
    { month: "Mie", expense: 450 },
    { month: "Jue", expense: 150 },
    { month: "Vie", expense: 120 },
    { month: "Sab", expense: 290 },
    { month: "Dom", expense: 400 },
  ],
};

const daily_stats = {
  tasa_recompra: 10,
  categorias_mas_compradas_names: ["Categoria 1", "Categoría 2", "Categoría 3"],
  categorias_mas_compradas_percentages: [30, 40, 30],
  valor_vida: 123123,
  ticket_promedio: 123123,
  notas_credito: 12,
  ventas_anuladas: 23,
  total_compras: 1231,
  dinero_gastado: [{ month: "Hoy", expense: 900 }],
};

const detailed_expenses = [
  {
    id: "13",
    numero_factura: "1012312321123",
    fecha: "10-10-2020",
    nombre_cliente: "Marcos Solis",
    nit: "1325256",
    numero_productos: 22,
    monto: "3202,05",
  },
  {
    id: "14",
    numero_factura: "012312321123",
    fecha: "15-10-2020",
    nombre_cliente: "Marcos Solis",
    nit: "9325256",
    numero_productos: 22,
    monto: "3202,05",
  },
  {
    id: "15",
    numero_factura: "012312321123",
    fecha: "10-10-2020",
    nombre_cliente: "Marcos Solis",
    nit: "1325256",
    numero_productos: 22,
    monto: "3202,05",
  },
  {
    id: "16",
    numero_factura: "012312321123",
    fecha: "10-10-2020",
    nombre_cliente: "Marcos Solis",
    nit: "1325256",
    numero_productos: 22,
    monto: "8500",
  },
];

export default function Profile(props) {
  const moneda = getMoneda();
  let history = useHistory();

  // states
  const [statisticsPeriod, setStatisticsPeriod] = useState("Mensual");
  const [currentStatistic, setCurrentStatistic] = useState(monthly_stats);

  // methods
  const handleStatisticsTabs = (period) => {
    setStatisticsPeriod(period);
    switch (period) {
      case "Diario":
        setCurrentStatistic(daily_stats);
        break;
      case "Semanal":
        setCurrentStatistic(weekly_stats);
        break;
      case "Mensual":
        setCurrentStatistic(monthly_stats);
        break;
      case "Anual":
        setCurrentStatistic(anual_stats);
        break;
      default:
        console.log("Invalid period:", period);
        break;
    }
  };

  const resourceAction = (e, id) => {
    if (e.target.value === "details") {
      history.push({
        pathname: "/app/facturacion",
        state: { id },
      });
    }

    if (e.target.value === "emitirNota")
      history.push({
        pathname: "/app/operaciones_historial/nota_credito/emitir",
      });
    if (e.target.value === "anularVenta")
      history.push({ pathname: "/app/operaciones_historial/crear_anulacion" });
  };

  return (
    <div id="dashboard-section" className="client-profile-container">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Clientes</strong>
        </h4>
        <FormControl variant="outlined" />
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="row">
            <div className="col-md-6">
              <h1>
                <strong>Perfil del cliente</strong>
              </h1>
              <p>Consulte la información en específico de algún cliente</p>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
              <Button
                className="btn-cs1 icon-btn-delete mx-1"
                variant="contained"
                color="primary"
              >
                <span style={{ marginBottom: "-4px" }}>Cancelar</span>
              </Button>
              <Button
                className="btn-cs1 mx-1"
                variant="contained"
                color="primary"
              >
                <span style={{ marginBottom: "-4px" }}>Guardar</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div
            className="row my-2 justify-content-between align-items-center"
            style={{ borderBottom: "1px solid #E0E0E0" }}
          >
            <div className="col-md-4 h-100">
              <p className="mb-2">
                <strong>Nombre del cliente</strong>
              </p>
              <p>{clients_data.name}</p>
            </div>

            <div className="col-md-4 h-100">
              <p className="mb-2">
                <strong>NIT</strong>
              </p>
              <p>{clients_data.nit}</p>
            </div>

            <div className="col-md-4 h-100">
              <p className="mb-2">
                <strong>Dirección fiscal del cliente</strong>
              </p>
              <p>{clients_data.direccion_fiscal}</p>
            </div>
          </div>

          <div
            className="row my-3 justify-content-between align-items-center"
            style={{ borderBottom: "1px solid #E0E0E0" }}
          >
            <div className="col-md-6">
              <h1>
                <strong>Estadísticas</strong>
              </h1>
              <p>Resumen estadístico de las interacciones con su cliente</p>
            </div>
            <div className="col-md-6 d-flex justify-content-end align-items-center flex-wrap">
              <ButtonGroup
                className="tab-buttons"
                color="primary"
                aria-label="outlined primary button group"
                size="small"
              >
                <Button
                  className={statisticsPeriod === "Anual" ? "active" : ""}
                  onClick={() => handleStatisticsTabs("Anual")}
                >
                  Anual
                </Button>
                <Button
                  className={statisticsPeriod === "Mensual" ? "active" : ""}
                  onClick={() => handleStatisticsTabs("Mensual")}
                >
                  Mensual
                </Button>
                <Button
                  className={statisticsPeriod === "Semanal" ? "active" : ""}
                  onClick={() => handleStatisticsTabs("Semanal")}
                >
                  Semanal
                </Button>
                <Button
                  className={statisticsPeriod === "Diario" ? "active" : ""}
                  onClick={() => handleStatisticsTabs("Diario")}
                >
                  Diario
                </Button>
              </ButtonGroup>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-5">
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  width={500}
                  height={300}
                  data={currentStatistic.dinero_gastado}
                  margin={{
                    top: 5,
                    bottom: 5,
                    left: -20,
                    right: 8,
                  }}
                >
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Bar dataKey="expense" barSize={10} fill="#08BFE6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="col-lg-7 p-0">
              <div className="row p-0 m-0">
                <div className="col-lg-6">
                  <ResponsiveContainer width="100%" height={250}>
                    <ReactApexChart
                      series={[currentStatistic.tasa_recompra]}
                      type="radialBar"
                      options={{
                        chart: {
                          type: "radialBar",
                        },
                        colors: ["#08BFE6"],
                        labels: ["Tasa de recompra"],
                        plotOptions: {
                          radialBar: {
                            hollow: {
                              size: "70%",
                              background: "#fff",
                              position: "front",
                              dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24,
                              },
                            },
                          },
                        },
                      }}
                    />
                  </ResponsiveContainer>
                </div>
                <div className="col-lg-6">
                  <ResponsiveContainer width="100%" height={250}>
                    <ReactApexChart
                      options={{
                        chart: {
                          type: "donut",
                        },
                        colors: ["#00F2E4", "#5B50A0", "#192DA6"],
                        labels: currentStatistic.categorias_mas_compradas_names,
                        legend: {
                          position: "bottom",
                        },
                      }}
                      series={
                        currentStatistic.categorias_mas_compradas_percentages
                      }
                      type="donut"
                    />
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-sm-6 align-items-center client-profile-stats-col">
                          <div className="client-profile-stats-text">
                            <p>
                              <strong>Valor de vida del cliente</strong>
                            </p>
                            <h1 className="secondary_color">
                              <strong>
                                {moneda}.
                                {formaterPriceFunction(
                                  currentStatistic.valor_vida.toFixed(2) + ""
                                )}
                              </strong>
                            </h1>
                          </div>
                        </div>
                        <div className="col-sm-6 align-items-center client-profile-stats-col">
                          <div className="client-profile-stats-text">
                            <p>
                              <strong>Ticket promedio</strong>
                            </p>
                            <h1 className="secondary_color">
                              <strong>
                                {moneda}.{" "}
                                {formaterPriceFunction(
                                  currentStatistic.ticket_promedio.toFixed(2) +
                                    ""
                                )}
                              </strong>
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-sm-4 align-items-center client-profile-stats-col">
                          <div className="client-profile-stats-text">
                            <p>
                              <strong>Notas de crédito</strong>
                            </p>
                            <h1 className="secondary_color">
                              <strong>
                                {formaterPriceFunction(
                                  currentStatistic.notas_credito.toFixed(2) + ""
                                )}
                              </strong>
                            </h1>
                          </div>
                        </div>
                        <div className="col-sm-4 align-items-center client-profile-stats-col">
                          <div className="client-profile-stats-text">
                            <p>
                              <strong>Ventas anuladas</strong>
                            </p>
                            <h1 className="secondary_color">
                              <strong>
                                {formaterPriceFunction(
                                  currentStatistic.ventas_anuladas.toFixed(2) +
                                    ""
                                )}
                              </strong>
                            </h1>
                          </div>
                        </div>
                        <div className="col-sm-4 align-items-center">
                          <div className="client-profile-stats-text">
                            <p>
                              <strong>Total de compras</strong>
                            </p>
                            <h1 className="secondary_color">
                              <strong>
                                {formaterPriceFunction(
                                  currentStatistic.total_compras.toFixed(2) + ""
                                )}
                              </strong>
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <DataTable
            className="datatable-cs datatable-cs-v2 mt-2"
            title={"s"}
            columns={[
              {
                name: "N de factura",
                selector: "numero_factura",
                sortable: true,
              },
              {
                name: "Fecha",
                selector: "fecha",
                sortable: true,
              },
              {
                name: "Nombre del cliente",
                selector: "nombre_cliente",
                sortable: true,
              },
              {
                name: "NIT",
                selector: "nit",
                sortable: true,
              },
              {
                name: "N de productos",
                selector: "numero_productos",
                sortable: true,
              },
              {
                name: "Monto",
                selector: "monto",
                cell: (row) => (
                  <span>
                    {moneda}. {row.monto}
                  </span>
                ),
                sortable: true,
              },
              {
                name: "Acciones",
                selector: "action",
                cell: (row) => (
                  <div className="text-center w-100">
                    <FormControl variant="outlined">
                      <Select
                        native
                        value=""
                        onChange={(event) => resourceAction(event, row.id)}
                        className="cs-select"
                      >
                        <option value="">Acciones</option>
                        <option value="details">Ver detalles</option>
                        <option value="emitirNota">Emitir Nota de Cred.</option>
                        <option value="anularVenta">Anular venta</option>
                      </Select>
                    </FormControl>
                  </div>
                ),
              },
            ]}
            data={detailed_expenses}
            selectableRows
            pagination
            paginationPerPage={10}
            paginationIconFirstPage={null}
            paginationIconLastPage={null}
            paginationComponentOptions={{
              rangeSeparatorText: "de",
              noRowsPerPage: true,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        </div>
      </div>
    </div>
  );
}
