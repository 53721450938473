import React, { useState, useEffect } from "react";

//Estilos
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange } from "react-date-range";
import Dialog from "@material-ui/core/Dialog";

const ModalDatePiker = ({ open, onClose, state, setState }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DateRange
        editableDateInputs={true}
        onChange={(item) => setState([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={state}
      />
    </Dialog>
  );
};

export default ModalDatePiker;
