// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import print from "print-js";
import { getMoneda } from "../../helpers/moneda";
import { isAleko } from "../../helpers/isAleko";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import Ingreso from "./Components/IngresoRecibo";

// components

//data

export default function Users_all(props) {
  const moneda = getMoneda();
  const moment = require("moment");

  const aleko = isAleko();

  const [companyName, setCompanyName] = useState(null);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // states
  const [ingreso, setIngreso] = useState({
    id_ingreso_web_app: "",
    id_new_empresa: "",
    id_new_user: "",
    id_new_ubicacion: "",
    id_new_bodega: "",
    id_new_sucursal: "",
    id_new_motivo: "",
    id_new_tipo_documento: "",
    fecha_ingreso: "",
    serie: "",
    numero: "",
    operacion: "",
    motivo: "",
    documentType: "",
    usuario: "",
    total_productos: "",
    items: [
      {
        id_ingreso_detalle_web_app: "",
        id_new_empresa: "",
        id_ingreso_web_app: "",
        id_new_item: "",
        cantidad: "",
        precio: "",
        lote: "",
        fecha: "",
      },
    ],
  });
  const [loader, setLoader] = useState(true);

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      const { data } = await API.profile.getCompanyData();
      setCompanyName(data.data.nombre);
    };
    getData();
  }, []);

  useEffect(() => {
    getIngreso();
  }, []);

  useEffect(() => {
    if (ingreso.id_ingreso_web_app !== "") {
      setLoader(false);
    }
  }, [ingreso]);
  //function

  const getIngreso = () => {
    let id = props.match.params.id.toString();
    API.ingresos
      .getById({ id_ingreso_web_app: id })
      .then((res) => {
        let response = res.data;
        console.log(response.data);
        if (response.success && JSON.stringify(response.data) !== "[]") {
          setIngreso(response.data);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
        setLoader(false);
      });
  };

  const generatePdf = (name) => {
    const element = document.querySelector(".invoice-target");

    window.html2canvas = html2canvas;
    const pdf = new jsPDF("l", "pt", "a4");
    pdf.html(element, {
      callback: function (doc) {
        pdf.save(name);
      },
      html2canvas: { scale: 0.5 },
    });
  };
  const printSale = () => {
    print({
      printable: "printform",
      type: "html",
      targetStyles: ["*"],
    });
  };

  useEffect(() => {
    if (ingreso.id_ingreso_web_app !== "" && companyName) {
      printSale();
    }
  }, [ingreso, companyName]);
  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Detalles del ingreso</strong>
        </h4>
      </div>

      <div className="text-right">
        <Button
          type="submit"
          className="btn-cs1 mt-1 mx-2"
          variant="contained"
          color="primary"
          to={"/app/inventario"}
          component={Link}
        >
          Volver
        </Button>

        <Button
          className="btn-cs1 mt-1 mx-2"
          variant="contained"
          color="primary"
          onClick={() => generatePdf(`Recibo ingreso ${ingreso.identificador}`)}
        >
          Descargar recibo
        </Button>

        <Button
          className="btn-cs1 mx-2 my-auto"
          variant="contained"
          color="primary"
          onClick={printSale}
          disabled={ingreso.id_ingreso_web_app === "" && !companyName}
        >
          Imprimir
        </Button>
      </div>

      <form>
        <div className="content-card card mt-3 invoice-target">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Detalles del ingreso</strong>
                </h5>
                <p>Consulte los detalles acerca de su ingreso.</p>
                <h5 className="mb-0">
                  <strong>Información de ingreso</strong>
                </h5>
              </div>
            </div>
          </div>
          <div className="card-body">
            {loader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <div className="row mb-4">
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Motivo del ingreso</strong>
                  </label>

                  <p>{ingreso.motivo}</p>
                </div>
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Sucursal que ingresará</strong>
                  </label>

                  <p>{ingreso.nombre_sucursal}</p>
                </div>
                <div className="col-md-2 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Número de ingreso</strong>
                  </label>

                  <p>{ingreso.numero}</p>
                </div>
                <div className="col-md-2 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Fecha del ingreso</strong>
                  </label>

                  <p>
                    {moment(new Date(ingreso.fecha_ingreso)).format(
                      "YYYY-MM-DD"
                    )}
                  </p>
                </div>

                {/* Second Row */}
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Número de serie</strong>
                  </label>

                  <p>{ingreso.serie}</p>
                </div>
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Tipo de documento</strong>
                  </label>

                  <p>{ingreso.documentType}</p>
                </div>

                {parseInt(ingreso.id_new_motivo) === 1 ? (
                  <div className="col-md-3 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Número de factura</strong>
                    </label>

                    <p>{ingreso.numero_documento}</p>
                  </div>
                ) : (
                  <div className="col-md-4 mt-3"></div>
                )}

                {/* Third Row */}
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Listado de productos</strong>
                  </label>

                  <p className="mb-2">Productos que ingresarán</p>
                </div>
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Comentario</strong>
                  </label>

                  <p className="mb-2">{ingreso.comentario}</p>
                </div>

                {/*  Forth Row */}

                <div className="col-md-12 mt-1">
                  {ingreso.items.map((product, index) => (
                    <div className="row my-4">
                      <div className="col-md-4 mt-3">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Código del producto</strong>
                        </label>

                        <TextField
                          disabled
                          value={product.codigo}
                          name={`product_code[${index}]`}
                          type="text"
                          className="w-100"
                        />
                      </div>

                      <div className="col-md-4 mt-3">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>
                            Cantidad a ingresar{" "}
                            {product.vendido_por === "libras" && "en libras"}
                          </strong>
                        </label>

                        <TextField
                          disabled
                          value={
                            product.vendido_por !== "mixto"
                              ? product.cantidad
                              : product.cantidad_peso
                          }
                          name={`product_qty[${index}]`}
                          type="number"
                          className="w-100"
                          placeholder="Indique la cantidad de productos a egresar"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                N°
                              </InputAdornment>
                            ),
                            inputProps: { min: 0, step: "0.00001" },
                          }}
                        />
                      </div>

                      <div className="col-md-4 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <strong>Precio (Actualización)</strong>
                          </label>
                        </div>
                        <TextField
                          disabled
                          value={formaterPriceFunction(
                            product.precio_venta + ""
                          )}
                          name={`update_price[${index}]`}
                          type="text"
                          className="w-100"
                          placeholder="Ingrese el nuevo precio"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {moneda}.
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div className="col-md-4 mt-3">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Nombre del producto</strong>
                        </label>

                        <TextField
                          disabled
                          value={product.nombre}
                          name={`product_name[${index}]`}
                          type="text"
                          className="w-100"
                        />
                      </div>
                      {product.vendido_por === "mixto" ? (
                        <div className="col-md-4 mt-3">
                          <label
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <strong>Cantidad a ingresar en libras</strong>
                          </label>

                          <TextField
                            disabled
                            value={product.cantidad}
                            type="text"
                            className="w-100"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  lbs
                                </InputAdornment>
                              ),
                              inputProps: { min: 0, step: "0.00001" },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-md-4 mt-3">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Lote (opcional)</strong>
                        </label>

                        <TextField
                          disabled
                          value={product.lote}
                          name={`product_lote[${index}]`}
                          type="text"
                          className="w-100"
                        />
                      </div>

                      <div className="col-md-4 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <strong>Fecha de vencimiento</strong>
                          </label>
                        </div>
                        <TextField
                          disabled
                          value={
                            product.vencimiento_fecha
                              ? moment(product.vencimiento_fecha).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                          name={`product_expirate_date[${index}]`}
                          type="date"
                          className="w-100"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <Ingreso ingreso={ingreso} companyName={companyName} />
    </div>
  );
}
