import { admin_base_url, axios } from "../../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("AdminToken") };
};

const getAll = async () => {
  return await axios.get(`${admin_base_url}/web/get-facturas`, {
    headers: headers(),
  });
};

const getFactura = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/get-factura`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const editFactura = async (data) => {
  return await axios.put(
    `${admin_base_url}/web/edit-factura`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const deleteInvoice = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/delete-factura`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const duplicateInvoice = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/duplicate-factura`,
    qs.stringify(data),
    { headers: headers() }
  );
};

export default {
  getAll,
  getFactura,
  editFactura,
  deleteInvoice,
  duplicateInvoice,
};
