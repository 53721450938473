import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { getMoneda } from "../../helpers/moneda";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { base_url_images } from "../../api";

// components
export default function Users_all(props) {
  const moneda = getMoneda();
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  useEffect(() => {
    getSucursals();
  }, []);

  // states
  const [loader, setLoader] = useState(false);
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");

  // set states
  const changeSucursal = (event) => {
    const sucursalName = event.target.value;
    setSucursal(sucursalName);
  };

  //function

  const processFileInput = (event) => {
    if (typeof event.target.files[0] !== "undefined") {
      setLoader(true);
      API.supplies
        .uploadXLS(event.target.files[0], sucursal)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success(response.message, {
              autoClose: 10000,
            });
            setLoader(false);
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          let response = err;
          console.log(response);
          toast.error("Ha ocurrido un error.", {
            autoClose: 10000,
          });

          console.log("Ha ocurrido un error", response);
        });
    }
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="save" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Su producto se ha <br />
                    guardado con éxito
                  </strong>
                </h4>
              </div>

              <div class="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Agregar insumos</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined">
          <Select native value={sucursal} onChange={changeSucursal}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <form>
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Carga masiva de insumos</strong>
                </h5>
                <p className="mb-0">
                  Agregue multiples insumos a la vez mediante la carga masiva.
                </p>
              </div>

              <div>
                <Button
                  className="btn-cs1 mx-1 mt-1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmExit"
                >
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress
                        size={26}
                        className="mx-auto"
                        style={{ color: "white" }}
                      />
                    </div>
                  ) : (
                    "Cancelar"
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-6 mt-3">
                <div
                  className="card shadow mb-4 download-excel"
                  onClick={(event) => null}
                >
                  <div className="p-2 d-flex align-items-center">
                    <CloudDownloadIcon
                      className="mx-2"
                      style={{ color: "#08BFE6", fontSize: "30px" }}
                    />
                    <a
                      href={
                        base_url_images +
                        "files/formato_carga_masiva_insumos.xlsx"
                      }
                      className="massive-load-button"
                    >
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Descargar la plantilla Excel base</strong>
                      </label>
                      <p style={{ fontSize: "0.75rem" }} className="mb-0">
                        Con esta plantilla evitará que surjan errores al subir
                        sus insumos.
                      </p>
                    </a>
                  </div>
                </div>

                <div className="card shadow mb-4">
                  <div className="p-2 d-flex align-items-center">
                    <CloudUploadIcon
                      className="mx-2"
                      style={{ color: "#08BFE6", fontSize: "30px" }}
                    />
                    <div>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Subir plantilla Excel</strong>
                      </label>
                      <p style={{ fontSize: "0.75rem" }} className="mb-0">
                        Cargue su plantilla con los datos de sus insumos.
                      </p>
                    </div>
                  </div>
                  <input
                    type="file"
                    className="file-input-excel"
                    name="images"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(event) => processFileInput(event)}
                  />
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <p>
                  Con esta plantilla Excel podrás cargar los datos de sus
                  insumos de forma rápida. Deberás rellenar cada una de las
                  columnas con la información solicitada.
                  <br />
                  <br />
                  Si tienes dudas al respecto, visite nuestra sección de ayuda.
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
