import React, { useReducer, useState } from "react";
import { toast } from "react-toastify";
import {
  createProductColor,
  createProductPersonalization,
  createProductSize,
  deleteColor,
  deletePersonalization,
  deleteSize,
  getAlekoProduct,
  getProductColor,
  getProductSizes,
  getProductStyles,
  updateAlekoProduct,
} from "../../api/products";
import ProductDetailsContext from "./ProductDetailsContext";
import { ProductDetailsReducer } from "./ProductDetailsReducers";
import {
  DELETE_PRODUCT_COLOR,
  GET_ALEKO_COLORS,
  GET_ALEKO_PRODUCT_PERSONALIZATIONS,
  GET_ALEKO_SIZES,
  SET_PRODUCT_COLOR,
  GET_PRODUCT,
  SET_PRODUCT_SIZE,
  DELETE_PRODUCT_SIZE,
  SET_PRODUCT_PERSONALIZATION,
  DELETE_PRODUCT_PERSONALIZATION,
  SET_FORM,
  CLEAR_STATE,
  HANDLE_FILE,
  DELETE_IMAGE,
} from "./types";

const ProductDetailsProvider = (props) => {
  const initialState = {
    product: { precio_venta: 10, descuento_precio: 0 },
    colors: [],
    sizes: [],
    personalizations: [],
    images: {},
  };
  const [state, dispatch] = useReducer(ProductDetailsReducer, initialState);
  const [inputColorValidation, setInputColorValidation] = useState(false);
  const id_bodega_sucursal = localStorage.getItem("id_sucursal");
  const [inputSizeValidation, setInputSizeValidation] = useState(false);
  const [inputPersonalizationValidation, setInputPersonalizationValidation] =
    useState(false);

  const [clearColor, setClearColor] = useState(false);

  const [productColor, setProductColor] = useState({
    nombre_color: "",
    codigo_hexa_color: "",
    cantidad_color: 0,
    precio_extra: 0,
  });

  const [productSize, setProductSize] = useState({
    nombre_talla: "",
    cantidad_talla: 0,
    precio_extra: 0,
  });

  const [productPersonalization, setProductPersonalization] = useState({
    nombre_personalizacion: "",
    cantidad_personalizacion: 0,
    precio_extra: 0,
  });

  const clearProductColor = () => {
    setProductColor({
      nombre_color: "",
      codigo_hexa_color: "",
      cantidad_color: 0,
      precio_extra: 0,
    });
    setInputColorValidation(false);
  };

  const clearProductSize = () => {
    setProductSize({ nombre_talla: "", cantidad_talla: "", precio_extra: 0 });
    setInputSizeValidation(false);
  };

  const getProduct = async (id) => {
    const { data } = await getAlekoProduct({ id_new_item: id });
    dispatch({ type: GET_PRODUCT, payload: data.data });
  };

  const getAlekoProductColors = async (id) => {
    const { data } = await getProductColor(id);
    dispatch({ type: GET_ALEKO_COLORS, payload: data.data });
  };

  const getAlekoProductSizes = async (id) => {
    const { data } = await getProductSizes(id);
    dispatch({ type: GET_ALEKO_SIZES, payload: data.data });
  };

  const getAlekoProductPersonalizations = async (id) => {
    const { data } = await getProductStyles(id);
    dispatch({ type: GET_ALEKO_PRODUCT_PERSONALIZATIONS, payload: data.data });
  };

  const saveProductColor = async (color, id) => {
    const prevColor = state.colors.some(
      (item) => item.codigo_hexa_color === color.codigo_hexa_color
    );
    if (prevColor) {
      toast.warn("El color ya existe", { autoClose: 10000 });
      return;
    }
    if (
      color.nombre_color.trim() === "" ||
      color.codigo_hexa_color === "" ||
      !color.cantidad_color
    ) {
      setInputColorValidation(true);
      return;
    }

    try {
      const color_data = new FormData();
      for (let key in color) {
        color_data.append(key, color[key]);
      }
      color_data.append("id_new_item", id);
      color_data.append("id_bodega_sucursal", id_bodega_sucursal);

      const { data } = await createProductColor(color_data);

      dispatch({
        type: SET_PRODUCT_COLOR,
        payload: data.data,
      });
      setInputColorValidation(false);
      setClearColor(true);
    } catch (error) {
      console.log(error);
    }
  };

  const saveProductSize = async (size) => {
    const prevSize = state.sizes.some(
      (item) => item.nombre_talla === size.nombre_talla
    );
    if (prevSize) return;

    if (size.nombre_talla.trim() === "" || !size.cantidad_talla) {
      setInputSizeValidation(true);
      return;
    }

    const size_data = new FormData();
    for (let key in size) {
      size_data.append(key, size[key]);
    }
    size_data.append("id_new_item", state.product.id_new_item);
    size_data.append("id_bodega_sucursal", id_bodega_sucursal);

    try {
      const { data } = await createProductSize(size_data);

      dispatch({
        type: SET_PRODUCT_SIZE,
        payload: data.data,
      });
      clearProductSize();
      setInputSizeValidation(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveProductPersonalization = async (personalization) => {
    const prevPersonalization = state.personalizations.some(
      (item) =>
        item.nombre_personalizacion === personalization.nombre_personalizacion
    );
    if (prevPersonalization) return;
    if (
      personalization.nombre_personalizacion.trim() === "" ||
      !personalization.cantidad_personalizacion
    ) {
      setInputPersonalizationValidation(true);
      return;
    }
    const personalization_data = new FormData();
    for (let key in personalization) {
      personalization_data.append(key, personalization[key]);
    }
    personalization_data.append("id_new_item", state.product.id_new_item);
    personalization_data.append("id_bodega_sucursal", id_bodega_sucursal);
    const { data } = await createProductPersonalization(personalization_data);

    dispatch({
      type: SET_PRODUCT_PERSONALIZATION,
      payload: data.data,
    });
    setInputPersonalizationValidation(false);
    clearProductPersonalization();
  };

  const deleteProductColor = async (id) => {
    try {
      await deleteColor(id);

      dispatch({ type: DELETE_PRODUCT_COLOR, payload: id });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteProductSize = async (id) => {
    try {
      await deleteSize(id);
      dispatch({
        type: DELETE_PRODUCT_SIZE,
        payload: id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteProductPersonalization = async (id) => {
    try {
      await deletePersonalization(id);
      dispatch({
        type: DELETE_PRODUCT_PERSONALIZATION,
        payload: id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const clearProductPersonalization = () => {
    setProductPersonalization({
      nombre_personalizacion: "",
      cantidad_personalizacion: 0,
      precio_extra: 0,
    });
    setInputPersonalizationValidation(false);
  };

  const handleForm = (e) => {
    dispatch({ type: SET_FORM, payload: e.target });
  };

  const clearState = () => {
    dispatch({ type: CLEAR_STATE });
    clearProductPersonalization();
    clearProductColor();
    clearProductSize();
  };
  const handleFiles = (e, index) => {
    e.persist();
    dispatch({ type: HANDLE_FILE, payload: e, index });
  };

  const deleteImage = (index) => {
    dispatch({ type: DELETE_IMAGE, payload: index });
  };

  const updateProduct = async () => {
    delete state.product.id_empresa_new;
    delete state.product.price_levels;
    delete state.product.missing_price_levels;

    let data = Object.entries(state.product).filter((item) => item[1]);
    const form_data = new FormData();
    let files = data.filter(
      (item) => item[0].includes("imagen") && typeof item[1] !== "string"
    );
    if (!files.length) {
      files = [null, null, null, null];
    } else {
      files = files.map((file) => {
        return new File(
          [file[1]],
          `$img_${file[0].slice(-1)}$${file[1].name}`,
          {
            type: file[1].type,
            lastModified: file[1].lastModified,
          }
        );
      });
    }
    console.log(files);
    data.map((item) =>
      item[0].includes("imagen") ? item : form_data.append(item[0], item[1])
    );
    files.map((item) => form_data.append("images", item));
    form_data.append("costo_unitario", null);
    form_data.append("id_unidad_medida_web_app", null);
    form_data.append("id_clasificacion2_web_app", null);
    form_data.append("id_distribuidor_web_app", null);
    form_data.append("id_contenido_web_app", null);
    form_data.append("id_familia_web_app", null);
    form_data.append("stock", undefined);
    form_data.append("stock_minimo", undefined);
    form_data.append("vencimiento", 0);
    form_data.append("idp", 0);
    form_data.append("flag_compuesto", 0);
    form_data.append("precio_libre", 0);
    try {
      await updateAlekoProduct(form_data);
    } catch (error) {
      console.log(error.message);
      console.log(error.response);
      console.log(error.response.message);
    }
  };

  return (
    <ProductDetailsContext.Provider
      value={{
        colors: state.colors,
        sizes: state.sizes,
        personalizations: state.personalizations,
        inputColorValidation,
        productColor,
        product: state.product,
        productSize,
        inputSizeValidation,
        productPersonalization,
        inputPersonalizationValidation,
        images: state.images,
        clearColor,
        setClearColor,
        getAlekoProductColors,
        getAlekoProductSizes,
        getAlekoProductPersonalizations,
        saveProductColor,
        setProductColor,
        setProductSize,
        setProductPersonalization,
        clearProductColor,
        deleteProductColor,
        getProduct,
        clearProductSize,
        saveProductSize,
        saveProductPersonalization,
        deleteProductSize,
        clearProductPersonalization,
        deleteProductPersonalization,
        handleForm,
        clearState,
        handleFiles,
        updateProduct,
        deleteImage,
      }}
    >
      {props.children}
    </ProductDetailsContext.Provider>
  );
};

export { ProductDetailsProvider };
