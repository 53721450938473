import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";

const EgresosReporte = ({ objeto }) => {
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="egresos-reporte-table">
        <thead>
          <tr>
            <th>N° de Egreso</th>
            <th>Fecha / Hora</th>
            <th>Motivo</th>
            <th>N° de Productos</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            console.log(x);

            return (
              <tr key={index}>
                <td>{x.identificador}</td>
                <td>
                  {moment(x.fecha_ingreso)
                    .utcOffset(0)
                    .format("DD/MM/YYYY, h:mm:ss a")}
                </td>
                <td>{x.motivo} </td>
                <td>{numberFormater(x.detalle.length)} </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EgresosReporte;
