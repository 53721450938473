import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DataTable from "react-data-table-component";
import TextField from "@material-ui/core/TextField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import API from "../../api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import "moment/locale/es";
import { getMoneda } from "../../helpers/moneda";
moment.locale("es");
// components

// data
/* let default_dates = [
  new Date(new Date().setDate(new Date().getDate() - 7)),
  new Date(),
] */

let default_dates = [
  `${moment(new Date()).utcOffset(-6).format("YYYY-MM-DD")}T05:00:00.000Z`,
  `${moment(new Date())
    .utcOffset(-6)
    .add(1, "d")
    .format("YYYY-MM-DD")}T04:59:59.999Z`,
];
console.log(
  "Default dates",
  moment(new Date()).utcOffset(-6).format("YYYY-MM-DDTHH:mm:ss")
);

export default function Users_all(props) {
  const { register, handleSubmit, reset } = useForm();
  const moneda = getMoneda();

  // states
  const [dateRange, setDateRange] = useState(default_dates);
  const [loader, setLoader] = useState(true);
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [cajas, setCajas] = useState([]);
  const [caja, setCaja] = useState("");
  const [sellers, setSellers] = useState([]);
  const [seller, setSeller] = useState("");
  const [movimientoId, setMovimientoId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [selectedSale, setSelectedSale] = useState({});

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#notaDebito").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  //invoiceData
  useEffect(() => {
    if (Object.values(invoiceData).length) {
      gotoNotaInvoice();
    }
  }, [invoiceData]);

  //component did mount
  useEffect(() => {
    getServerDate();
    getSales(default_dates);
    getSucursals();
  }, []);

  //filter sales by sucursal
  useEffect(() => {
    getSales(dateRange);
  }, [sucursal]);

  // set states
  const changeSucursal = (event) => {
    const storeId = event.target.value;
    setSucursal(storeId);
  };

  const emitir_nota = (sale) => {
    setMovimientoId(sale.id_pos_movimiento);
    setSelectedSale(sale);
    $("#notaDebito").modal();
  };

  const getServerDate = () => {
    API.dashboard
      .getDate()
      .then((res) => {
        let { date } = res.data.data;
        date = `${date}T05:00:00.000Z`;
        setDateRange([date, date]);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al obtener la fecha del servidor", {
          autoClose: 10000,
        });
      });
  };

  const getSales = (dates) => {
    setLoader(true);
    let data = {
      from_date: dates[0],
      to_date: dates[1],
      id_sucursal_bodega_ubicacion: sucursal,
    };
    API.sales
      .getNDApplicableSales(data)
      .then((res) => {
        let response = res.data;
        console.log("ND Applicable sales received received:", response.data);
        if (response.success) {
          setSales(response.data);
          setFilteredSales(response.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  const filterSales = (e) => {
    console.log("Filtering by", e.target.value);
    setFilterText(e.target.value);
    setFilteredSales(
      sales.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredSales(sales);
    }
  };

  const handleDateChange = (value) => {
    setDateRange(value);
    getSales(value);
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
          getCajas(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getCajas = (value) => {
    API.cajas
      .getCajas({ id_sucursal_bodega_ubicacion: value }, true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCajas(response.data);
          getSellers(response.data[0].id_caja_web_app);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSellers = (value) => {
    API.cajas
      .getVendedores({ id_caja_web_app: value }, true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSellers(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const changeCaja = (event) => {
    const cajaId = event.target.value;
    setCaja(cajaId);
  };

  const changeSeller = (event) => {
    const sellerId = event.target.value;
    setSeller(sellerId);
  };

  const submitNota = (data) => {
    const currentSale = sales.find((x) => x.id_pos_movimiento === movimientoId);
    data.id_pos_movimiento = currentSale.id_pos_movimiento;
    data.documento = currentSale.documento;
    data.serie = currentSale.serie;
    data.id_tipo_documento = currentSale.id_tipo_documento_web_app;
    console.log("Submitting this data:", data);
    setSubmitLoader(true);
    API.sales
      .createNotaDebito(data)
      .then((res) => {
        let response = res.data;
        console.log("Debit note submitted, received this data:", response.data);
        if (response.success) {
          $("#notaDebito").modal("hide");
          // $("#save").modal();
          setInvoiceData(response.data);
          getSales(dateRange);
          reset();
        } else {
          toast.error(response.message, { autoClose: 10000 });
        }
        setSubmitLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setSubmitLoader(false);
      });
  };

  const gotoNotaInvoice = () => {
    localStorage.setItem("invoice_data", JSON.stringify(invoiceData));
    const newWindow = window.open(
      "/#/app/nota_debito/factura",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const gotoSaleInvoice = (sale) => {
    const newWindow = window.open(
      "/#/app/vender/resumen/" + sale.id_pos_movimiento,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="notaDebito" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="d-flex justify-content-end px-2 py-1">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(submitNota)} className="modal-body">
              <div className="mt-1">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Motivo de la nota</strong>
                </label>
                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Indique el motivo de la nota de débito"
                  inputRef={register}
                  required={true}
                  name="motivo"
                />
              </div>

              <div className="mt-1">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Indique el monto</strong>
                </label>
                <TextField
                  type="number"
                  className="w-100"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {moneda}.
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register}
                  inputProps={{
                    step: "0.00001",
                  }}
                  name="monto"
                  defaultValue={0}
                  required={true}
                  onChange={(event) =>
                    event.target.value < 0
                      ? (event.target.value = -event.target.value)
                      : event.target.value
                  }
                />
              </div>

              <div class="d-flex aligm-items-center justify-content-between mt-2">
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  onClick={() => gotoSaleInvoice(selectedSale)}
                >
                  Ver factura
                </Button>
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {submitLoader ? (
                    <div className="text-center">
                      <CircularProgress
                        size={26}
                        className="mx-auto"
                        style={{ color: "white" }}
                      />
                    </div>
                  ) : (
                    "Emitir nota"
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div class="modal fade" id="save" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Se ha emitido una nota de <br />
                    débito correctamente
                  </strong>
                </h4>
              </div>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 brand2-btn"
                  variant="contained"
                  color="primary"
                  onClick={gotoNotaInvoice}
                >
                  Ver recibo
                </Button>
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/operaciones_historial"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Nota de débito</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined">
          <Select
            native
            value={sucursal}
            onChange={changeSucursal}
            className="sm-select"
          >
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined" className="px-1">
          <Select native value={caja} onChange={changeCaja}>
            {cajas.map((caja) => (
              <option value={caja.id_caja_web_app}>{caja.descripcion}</option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined" className="px-1">
          <Select native value={seller} onChange={changeSeller}>
            {sellers.map((seller) => (
              <option value={seller.id_vendedor_web_app}>
                {seller.nombre}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="row">
            <div className="col-md-6">
              <h5 className="mb-0">
                <strong>Emitir nota de débito</strong>
              </h5>
              <p className="mb-0">
                Para realizar la emisión de una nota de débito, deberá disponer
                del número de la factura o bien puede realizar una búsqueda
                manual.
              </p>
            </div>

            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <Button
                className="btn-cs1 mx-2 delete-btn"
                variant="contained"
                color="primary"
                data-dismiss="modal"
                aria-label="Close"
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-end mb-3 mt-2 flex-wrap">
            <div className="d-flex align-items-end flex-wrap">
              <div className="mr-2">
                <p className="mb-0">Buscar por:</p>
                <div className="d-flex align-items-center flex-wrap">
                  <DateRangePicker
                    onChange={(val) => handleDateChange(val)}
                    value={dateRange}
                    format={"dd-MM-y"}
                    className="date-picker-cs px-1"
                  />
                </div>
              </div>
              {/*  <Button
                                className="btn-cs1"
                                variant="contained"
                                color="primary"
                            >
                                Buscar por fecha
                            </Button> */}
            </div>

            <div>
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ingrese el número de facturación"
                  style={{ width: "300px" }}
                  value={filterText}
                  onChange={(event) => filterSales(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2"
              title={"s"}
              columns={[
                {
                  name: "N° Factura",
                  selector: "identificador",
                  sortable: true,
                },
                {
                  name: "Fecha / Hora",
                  selector: "fecha",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {moment(new Date(row.fecha))
                        .utcOffset(0)
                        .format("DD/MM/YYYY h:mm:ss a")}
                    </div>
                  ),
                },
                {
                  name: "Nombre del cliente",
                  selector: "client.nombre",
                  sortable: true,
                },
                {
                  name: "NIT",
                  selector: "client.nit",
                  sortable: true,
                },
                {
                  name: "N° productos",
                  selector: "orderItems",
                  sortable: true,
                  cell: (row) => (
                    <div className="text-center w-100">
                      {row.orderItems.length}
                    </div>
                  ),
                },
                {
                  name: "Monto",
                  selector: "monto_total",
                  sortable: true,
                  cell: (row) => (
                    <span>
                      {moneda}. {row.monto_total}{" "}
                    </span>
                  ),
                },
                {
                  name: "Acciones",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      <Button
                        className="btn-cs1 delete-btn w-100 emitir-nota-button"
                        variant="contained"
                        color="primary"
                        onClick={() => emitir_nota(row)}
                      >
                        Emitir nota
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={filteredSales}
              defaultSortField="fecha"
              defaultSortAsc={false}
              pagination
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se ha encontrado ningún resultado"
            />
          )}
        </div>
      </div>
    </div>
  );
}
