import React, { useState, useEffect, useCallback } from "react";

const useAlekoProducts = (tab, products) => {
  const [validation, setValidation] = useState(false);

  const checkIfAleko = useCallback(
    (products) => {
      const alekoProduct = products.some((product) => product.aleko);
      if (alekoProduct) {
        setValidation(true);
      }
      if (!alekoProduct) {
        setValidation(false);
      }
    },
    [tab, products]
  );

  useEffect(() => {
    checkIfAleko(products);
  }, [tab, products]);

  return [validation];
};

export default useAlekoProducts;
