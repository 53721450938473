import {
  Button,
  CircularProgress,
  FormControl,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import DataTable from "react-data-table-component";
import CambioMasivoContext from "../../../../context/CambioMasivoContext/CambioMasivoContext";
import Autosuggest from "react-autosuggest";
import { useSuggestions } from "../Hooks/useSuggestions";
import { useHistory } from "react-router";
const MainCard = () => {
  const history = useHistory();
  const { getEnterprises, enterprises } = useContext(CambioMasivoContext);
  const {
    renderSuggestion,
    getSuggestionValue,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    handleGetSucursals,
    handleSelectSucursals,
    suggestions,
    selectedSucursals,
    inputProps,
    sucursals,
    loading,
    id_empresa,
  } = useSuggestions(enterprises);

  useEffect(() => {
    let user = JSON.parse(window.localStorage.getItem("pms"));
    getEnterprises(user.id_new_user);
  }, []);

  return (
    <div className="card mt-3">
      <div className="p-3">
        <div className="row">
          <div className="col-md-12">
            <Typography>
              <strong>Carga de productos a toda la cadena</strong>
            </Typography>
            <Typography>
              Agregue productos a las sucursales seleccionadas
            </Typography>
          </div>
        </div>
      </div>
      <div className="border-bottom" />
      <div className="p-3">
        <div className="d-flex justify-content-between">
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedSucursals.length}
            className={
              selectedSucursals.length
                ? "shadow-none bg-secondary-color text-white"
                : "shadow-none"
            }
            style={{ width: "150px" }}
            onClick={() => {
              history.push({
                pathname: "/app/inventario/add_product",

                state: {
                  id_new_empresa: id_empresa,
                  selectedSucursals: selectedSucursals.map((x) => ({
                    id_sucursal_bodega_ubicacion:
                      x.id_sucursal_bodega_ubicacion,
                  })),
                  nombres: selectedSucursals,
                },
              });
            }}
          >
            <span style={{ fontSize: "16px" }}>Añadir</span>
          </Button>
          <div>
            <div
              className="input-group search-bar-carga autosuggest-search-bar w-100"
              style={{
                marginLeft: "unset",
                marginRight: "unset",
                flexWrap: "nowrap",
              }}
            >
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={handleGetSucursals}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
              <div className="input-group-append">
                <span className="input-group-text autosuggest-search-icon">
                  <SearchIcon style={{ color: "fff" }} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="table-css-v3">
        <DataTable
          className="datatable-cs datatable-cs-v2 datatable-h-80"
          columns={[
            {
              name: "Código",
              selector: "codigo_sucursal",
              sortable: true,
            },
            {
              name: "Nombre",
              selector: "nombre_sucursal",
              sortable: true,
            },
            {
              name: "Dirección",
              selector: "direccion_sucursal",
              sortable: true,
              cell: (row) => (
                <span>
                  {row.direccion_sucursal?.length > 25
                    ? row.direccion_sucursal?.slice(0, 25) + "..."
                    : row.direccion_sucursal}
                </span>
              ),
            },
            {
              name: "Teléfono",
              selector: "telefono_sucursal",
              sortable: true,
            },
            {
              name: "Ubicación/Bodega",
              selector: "descripcion_bodega",
              sortable: true,
            },
          ]}
          data={sucursals}
          selectableRows
          defaultSortField="vencimiento_fecha"
          pagination
          paginationPerPage={10}
          paginationIconFirstPage={null}
          paginationIconLastPage={null}
          paginationComponentOptions={{
            rangeSeparatorText: "de",
            noRowsPerPage: true,
            selectAllRowsItem: false,
            selectAllRowsItemText: "All",
          }}
          noDataComponent={
            <div
              className="d-flex  align-items-center flex-wrap"
              style={{ height: "260px" }}
            >
              <div className="w-100 text-center">
                {loading ? (
                  <CircularProgress style={{ fontSize: "30px" }} />
                ) : (
                  <SearchIcon style={{ fontSize: "35px" }} />
                )}
                <br />
                Busque las sucursales a las cuales desea añadir un producto.
              </div>
            </div>
          }
          onSelectedRowsChange={handleSelectSucursals}
          clearSelectedRows={loading}
        />
      </div>
    </div>
  );
};

export default MainCard;
