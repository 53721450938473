import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import AlekoContext from "../../../context/ContextoAleko/AlekoContext";
import DeleteModal from "../Components/DeleteModal";

const ProductColor = ({ item }) => {
  const { deleteProductColor } = useContext(AlekoContext);

  const handleDelete = async (idEmpresa, idPersonalizacion) => {
    deleteProductColor(idEmpresa, idPersonalizacion);
  };

  return (
    <>
      <div className="col-lg-1 text-center my-2">
        <div
          className="border"
          style={{
            background: item.codigo_hexadecimal,
            borderRadius: "50%",
            height: "70px",
            width: "70px",
            lineHeight: "70px",
            cursor: "pointer",
          }}
        />
      </div>

      <div className="col-lg-3 d-flex align-items-center flex-wrap my-2">
        <label style={{ fontSize: "0.8rem" }} className="w-100">
          <strong>Nombre</strong>
        </label>
        <span className="w-100 border-bottom"> {item.nombre_color}</span>
      </div>
      {/* <div className="col-lg-3 d-flex align-items-center flex-wrap my-2">
        <label style={{ fontSize: "0.8rem" }} className="w-100 ">
          <strong>Cantidad disponible</strong>
        </label>
        <span className="w-100 border-bottom">{item.cantidad_color}</span>
      </div>
      <div className="col-lg-3 d-flex align-items-center flex-wrap my-2">
        <label style={{ fontSize: "0.8rem" }} className="w-100">
          <strong>Precio extra:</strong>
        </label>
        <span className="w-100 border-bottom">
          Q.
          {" " + item.precio_extra}
        </span>
      </div> */}
      <div className="col-lg-2 d-flex align-items-center flex-wrap my-2">
        {/* <Button
          className="btn-cs1 delete-btn w-100 text-light"
          variant="contained"
          onClick={() =>
            handleDelete(
              item.id_new_empresa,
              item.id_colores_personalizados_producto
            )
          }
        >
          Eliminar
        </Button> */}
        <DeleteModal
          deleteProduct={() =>
            handleDelete(
              item.id_new_empresa,
              item.id_colores_personalizados_producto
            )
          }
          cancelText="¿Seguro que deseas eliminar el color?"
          text="Eliminar"
        />
      </div>
    </>
  );
};

export default ProductColor;
