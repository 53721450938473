import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getMoneda } from "../../helpers/moneda";

// components

//data
let stores = [
  {
    id: 1,
    value: "Todas las tiendas",
    option: "Todas las tiendas",
  },
  {
    id: 2,
    value: "San Antonio",
    option: "San Antonio",
  },
  {
    id: 3,
    value: "Plaza mayor",
    option: "Plaza mayor",
  },
  {
    id: 4,
    value: "Los hermanos Contreras",
    option: "Los hermanos Contreras",
  },
  {
    id: 5,
    value: "Supermercado La Esperanza",
    option: "Supermercado La Esperanza",
  },
];

let cajas = [
  {
    id: 1,
    value: "Todas las cajas",
    option: "Todas las cajas",
  },
  {
    id: 2,
    value: "Juan Fernández",
    option: "Juan Fernández",
  },
  {
    id: 3,
    value: "María Ordoñez",
    option: "María Ordoñez",
  },
  {
    id: 4,
    value: "Miguel Sánchez",
    option: "Miguel Sánchez",
  },
  {
    id: 5,
    value: "Wilson Mendez",
    option: "Wilson Mendez",
  },
];

let sellers = [
  {
    id: 1,
    value: "Todos los vendedores",
    option: "Todos los vendedores",
  },
  {
    id: 2,
    value: "Juan Fernández",
    option: "Juan Fernández",
  },
  {
    id: 3,
    value: "María Ordoñez",
    option: "María Ordoñez",
  },
  {
    id: 4,
    value: "Miguel Sánchez",
    option: "Miguel Sánchez",
  },
  {
    id: 5,
    value: "Wilson Mendez",
    option: "Wilson Mendez",
  },
];

export default function Sell(props) {
  const moneda = getMoneda();
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#cancelSale").modal("hide");
    };
  }, []);
  // states
  const [store, setStore] = useState(stores[0].value);
  const [caja, setCaja] = useState(cajas[0].value);
  const [seller, setSeller] = useState(sellers[0].value);
  const [nit, setNIT] = useState("");
  const [clientName, setClientName] = useState("");
  const [direccion_fiscal, setDireccionFiscal] = useState("");
  const [email, setEmail] = useState("");
  const [periods, setPeriods] = useState("");
  const [invoice_number, setInvoiceNumber] = useState("");
  const [giftcard_code, setGiftCardCode] = useState("");
  const [additional_invoice, setAdditionalInvoice] = useState("");
  const [additional_invoices, setAdditionalInvoices] = useState([]);

  // set states
  const changeStore = (event) => {
    const storeName = event.target.value;
    setStore(storeName);
    //make api call
  };

  const changePeriods = (event) => {
    setPeriods(event.target.value);
  };

  const changeCaja = (event) => {
    const cajaName = event.target.value;
    setCaja(cajaName);
    //make api call
  };

  const changeSeller = (event) => {
    const sellerName = event.target.value;
    setSeller(sellerName);
    //make api call
  };

  const addNewAdditionalInvoice = () => {
    if (additional_invoice != "") {
      let newArray = additional_invoices;
      newArray.push(additional_invoice);
      setAdditionalInvoices(newArray);
      console.log("Setting new additional invoices:", additional_invoices);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "client_name":
        setClientName(value);
        break;
      case "direccion_fiscal":
        setDireccionFiscal(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "nit":
        setNIT(value);
        break;
      case "invoice_number":
        setInvoiceNumber(value);
        break;
      case "giftcard_code":
        setGiftCardCode(value);
        break;
      case "additional_invoice":
        setAdditionalInvoice(value);
        break;
      default:
        break;
    }
  };

  const handleBoxClick = (x) => {
    $(".payment-method-box").each(function () {
      if ($(this).hasClass("active-box")) {
        $(this).removeClass("active-box");
      }
    });
    let currentElement = "box-" + x;
    $(`#${currentElement}`).addClass("active-box");
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={store} onChange={changeStore}>
            {stores.map((store) => (
              <option value={store.value}>{store.option}</option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={caja} onChange={changeCaja}>
            {cajas.map((caja) => (
              <option value={caja.value}>{caja.option}</option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={seller} onChange={changeSeller}>
            {sellers.map((seller) => (
              <option value={seller.value}>{seller.option}</option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="content-card card">
            <div className="card-header">
              <h4 className="mb-0">
                <strong>Datos del cliente</strong>
              </h4>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>NIT de cliente</strong>
                  </p>
                  <div className="input-group search-bar">
                    <input
                      type="text"
                      name="nit"
                      value={nit}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Ingrese el NIT del cliente"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <SearchIcon />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Nombre de cliente</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={clientName}
                    name="client_name"
                    onChange={handleInputChange}
                    placeholder="Ingrese el nombre del cliente"
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Dirección fiscal del cliente</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={direccion_fiscal}
                    name="direccion_fiscal"
                    onChange={handleInputChange}
                    placeholder="Ingrese la dirección fiscal del cliente"
                  />
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Correo electrónico</strong>
                  </p>
                  <TextField
                    className="w-100"
                    variant="standard"
                    value={email}
                    name="email"
                    onChange={handleInputChange}
                    placeholder="Ingrese el correo electrónico del cliente"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row justify-content-between p-3">
            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIosIcon style={{ fontSize: "15px" }} />}
              component={Link}
              to={"/app/vender"}
            >
              <span style={{ marginBottom: "-4px" }}>Seguir comprando</span>
            </Button>
            <Button
              className="btn-cs1 icon-btn-delete mx-1"
              variant="contained"
              color="primary"
              data-toggle="modal"
              data-target="#cancelSale"
            >
              <span style={{ marginBottom: "-4px" }}>Cancelar venta</span>
            </Button>
          </div>
          <div className="card content-card mt-2">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-center flex-wrap mb-3">
                <div
                  className="payment-method-box"
                  id="box-1"
                  onClick={() => handleBoxClick(1)}
                >
                  <LocalAtmIcon />
                  <p>Efectivo</p>
                </div>
                <div
                  className="payment-method-box"
                  id="box-2"
                  onClick={() => handleBoxClick(2)}
                >
                  <CreditCardIcon />
                  <p>Tarjeta</p>
                </div>
                <div
                  className="payment-method-box"
                  id="box-3"
                  onClick={() => handleBoxClick(3)}
                >
                  <CardGiftcardIcon />
                  <p>Gift card</p>
                </div>
                <div
                  className="payment-method-box"
                  id="box-4"
                  onClick={() => handleBoxClick(4)}
                >
                  <AddBoxIcon />
                  <p>Multiples</p>
                </div>
                <div
                  className="payment-method-box active-box"
                  id="box-5"
                  onClick={() => handleBoxClick(5)}
                >
                  <AssignmentTurnedInIcon />
                  <p>A crédito</p>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="row w-100 m-0">
                <div className="col-sm-7">
                  <div className="row">
                    <div
                      className="col-md-12 mt-3"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <p>
                        <strong>Información del cliente</strong>
                      </p>
                      <p>
                        Este cliente cumple con los requisitos para optar por
                        crédito de: <strong>30 días</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 invoice-column">
                  <div className="row h-75">
                    <div className="col-6 p-0 m-0 text-center titles my-auto">
                      <p>SubTotal:</p>
                      <p>IVA (12%):</p>
                      <p>Total:</p>
                      <p>Pagado:</p>
                      <p>Vuelto:</p>
                    </div>
                    <div className="col-6 p-0 m-0 text-center my-auto">
                      <p>{moneda}. 1,470.04</p>
                      <p>{moneda}. 176.4</p>
                      <p>{moneda}. 1,646.45 </p>
                      <p>{moneda}. 1,700.45</p>
                      <p>{moneda}. 54.00</p>
                    </div>
                  </div>
                  <div className="row h-25 justify-content-center w-100 m-0">
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "#1a2eab",
                        fontSize: "18px",
                      }}
                      className="my-2 w-75"
                      variant="contained"
                      component={Link}
                      to={"/app/vender/recibo-credito"}
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                          textTransform: "none",
                        }}
                      >
                        Facturar
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div className="modal fade" id="cancelSale" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row justify-content-center align-items-center p-3">
                <div className="col-md-12">
                  <CheckCircleIcon
                    className="d-block mx-auto"
                    style={{
                      color: "#08BFE6",
                      fontSize: "60",
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <h4 className="text-center">
                    <strong>
                      ¿Estás seguro de que quieres cancelar esta venta?
                    </strong>
                  </h4>
                </div>
              </div>
              <div className="col-md-8 mx-auto d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/dashboard"}
                  component={Link}
                >
                  Sí, cancelar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
