import React from "react";
import { withRouter, Link } from "react-router-dom";
import FooterLinks from "../../components/FooterLinks/FooterLinks";
import PlanCard from "../../components/PlanCard/PlanCard";
import HeaderNoSession from "../../components/HeaderNoSession/Header";
import { Button } from "@material-ui/core";

import "./custom.css";

function Plans(props) {
  return (
    <>
      <div className="row h-100 w-100 p-0 m-0">
        <div className="row h-100 w-100 m-0 p-0 outer-container justify-content-center">
          <HeaderNoSession />
          <div
            className="col-12 p-0 inner-container banner"
            style={{ marginTop: "66px" }}
          >
            <div className="row w-100 h-100 justify-content-around align-items-center">
              <div className="col-md-6">
                <h1 className="page-title">Planes de suscripción</h1>
              </div>

              <div className="col-md-6 align-items-center">
                <div className="d-flex justify-content-end align-items-center right-banner-text">
                  <p className="mr-3 text-white my-auto">
                    <strong>¿Ya tienes cuenta?</strong>
                  </p>
                  <Button
                    variant="contained"
                    size="large"
                    component={Link}
                    to={"/login"}
                    style={{
                      backgroundColor: "#08BFE6",
                      color: "white",
                      textTransform: "none",
                      fontSize: "16px",
                    }}
                  >
                    Iniciar sesión
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-8 p-0 content">
            <div className="row justify-content-between plans">
              <div className="col-lg-3 p-0">
                <PlanCard
                  planName="Gratis"
                  planPrice="0.00"
                  planPaymentTime="1 mes"
                  planCharacteristics={[
                    "GRATIS 1 licencia",
                    "Duración hasta 30 días",
                    "Facturación electrónica",
                    "Manejo de inventario",
                    "Administración de clientes",
                    "Reporte de ventas",
                    "Soporte vía chat y teléfono",
                  ]}
                  hasBanner={false}
                />
              </div>
              <div className="col-lg-3 p-0 middle-column">
                <PlanCard
                  planName="Anual"
                  planPrice="800.00"
                  planPaymentTime="año"
                  planCharacteristics={[
                    "Duración 12 meses (Renovable)",
                    "Facturación electrónica",
                    "Manejo de inventario",
                    "Administración de clientes",
                    "Reporte de ventas",
                    "Soporte vía chat y teléfono",
                  ]}
                  hasBanner={true}
                />
              </div>
              <div className="col-lg-3 p-0">
                <PlanCard
                  planName="Mensual"
                  planPrice="100.00"
                  planPaymentTime="mes"
                  planCharacteristics={[
                    "Duración: 1 mes",
                    "Facturación electrónica",
                    "Manejo de inventario",
                    "Administración de clientes",
                    "Reporte de ventas",
                    "Soporte vía chat y teléfono",
                  ]}
                  hasBanner={false}
                />
              </div>
            </div>
          </div>
          <FooterLinks />
        </div>
      </div>
    </>
  );
}

export default withRouter(Plans);
