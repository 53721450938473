import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../../api";
import { toast } from "react-toastify";
import Invoice_1 from "./dummyInvoice";
import Invoice_2 from "./dummyInvoice2";
import Invoice_3 from "./dummyInvoice3";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// styles
import "./custom.css";

//icons

import EditIcon from "@material-ui/icons/Edit";

// components

export default function Invoice_Format(props) {
  const { register, handleSubmit } = useForm();
  const moment = require("moment");
  const [loader, setLoader] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [texto1, setTexto1] = useState("");
  const [texto2, setTexto2] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    getFactura();
  }, []);

  useEffect(() => {
    if (invoice.nombre) {
      setLoader(false);
    }
  }, [invoice]);

  const getFactura = () => {
    setLoader(true);
    API.admin_invoices
      .getFactura({ factura_id: parseInt(props.match.params.id.toString()) })
      .then((res) => {
        let response = res.data;
        console.log(response.data);
        if (response.success) {
          setInvoice(response.data);
          setTexto1(response.data.texto_1);
          setTexto2(response.data.texto_2);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
          setLoader(false);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log("Ha ocurrido un error", err, response);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
        setLoader(false);
      });
  };

  const editInvoice = (data) => {
    data.factura_id = invoice.factura_id;
    setSubmitLoader(true);
    API.admin_invoices
      .editFactura(data)
      .then((res) => {
        let response = res.data;
        console.log(response.data);
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setSubmitLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log("Ha ocurrido un error", err, response);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
        setSubmitLoader(false);
      });
  };

  return (
    <form id="dashboard-section" onSubmit={handleSubmit(editInvoice)}>
      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            {loader ? (
              <div className="text-center">
                <CircularProgress
                  size={26}
                  className="mx-auto my-auto"
                  style={{ color: "black" }}
                />
              </div>
            ) : (
              <h5 className="mb-0">
                <TextField
                  type="text"
                  defaultValue={invoice.nombre}
                  name="nombre"
                  inputRef={register}
                  required={true}
                  className="w-100"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </h5>
            )}
            <div>
              <Button
                className="btn-cs1 mx-1 mt-1 delete-btn"
                variant="contained"
                color="primary"
                onClick={() => props.history.goBack()}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1 mx-1 mt-1"
                variant="contained"
                color="primary"
              >
                {submitLoader ? (
                  <div className="text-center">
                    <CircularProgress
                      size={26}
                      className="mx-auto my-auto"
                      style={{ color: "white" }}
                    />
                  </div>
                ) : (
                  "Guardar"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="row mx-0">
          {/* format here */}
          <div className="col-md-8 px-0">
            <div
              className="card-body h-100"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              {invoice.factura_id === 4 ? (
                <Invoice_1
                  texto_1={texto1}
                  texto_2={texto2}
                  imagen_factura={""}
                />
              ) : invoice.factura_id === 1003 ? (
                <Invoice_2
                  texto_1={texto1}
                  texto_2={texto2}
                  imagen_factura={""}
                />
              ) : (
                <Invoice_3
                  texto_1={texto1}
                  texto_2={texto2}
                  imagen_factura={""}
                />
              )}
            </div>
          </div>

          {/* format edit options */}
          <div
            className="col-md-4 px-0"
            style={{ boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.06)" }}
          >
            <div className="card-body">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Texto de pie de página 1</strong>
              </label>

              {loader ? (
                <div className="text-center">
                  <CircularProgress
                    size={26}
                    className="mx-auto my-auto"
                    style={{ color: "black" }}
                  />
                </div>
              ) : (
                <TextField
                  type="text"
                  defaultValue={invoice.texto_1}
                  inputRef={register}
                  name="texto_1"
                  onChange={(e) => setTexto1(e.target.value)}
                  className="w-100"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <label className="mt-2" style={{ fontSize: "0.8rem" }}>
                <strong>Texto de pie de página 2</strong>
              </label>
              {loader ? (
                <div className="text-center">
                  <CircularProgress
                    size={26}
                    className="mx-auto my-auto"
                    style={{ color: "black" }}
                  />
                </div>
              ) : (
                <TextField
                  type="text"
                  defaultValue={invoice.texto_2}
                  inputRef={register}
                  name="texto_2"
                  onChange={(e) => setTexto2(e.target.value)}
                  className="w-100"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
