import React, { useState, useEffect } from "react";
import API from "../../../api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";

// icons
import InfoIcon from "@material-ui/icons/Info";

// helpers
import { isCmi } from "../../../helpers/isCmi";
import { getMoneda } from "../../../helpers/moneda";

// styles
import "./custom.css";
import { CircularProgress } from "@material-ui/core";

const CrearNewClienteModal = (props) => {
  const { saveNewClientCmi, loader } = props;

  //priceFormater
  const [priceValue, setPriceValue] = useState("");
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const moneda = getMoneda();

  // para cmi
  const [nivelPrecio, setNivelPrecio] = useState([]);
  const [add_credito_cmi, setAdd_credito_cmi] = useState(false);
  const [habilitaClienteCF, setHabilitaClienteCF] = useState(false);
  const [newClient, setNewClient] = useState({ nit: "", nombre: "" });
  const [nitClient, setNitClient] = useState("");

  useEffect(() => {
    getNivelesCliente();
  }, []);

  const getNivelesCliente = async () => {
    let id_new_empresa = window.localStorage.getItem("id_empresa");

    try {
      console.log("precios", id_new_empresa);
      const response = await API.userConfig.getNiveles(id_new_empresa);
      console.log("precios", response);
      setNivelPrecio(response.data.costumer);
    } catch (error) {
      console.log("Error al buscar niveles", error);
      toast.error("Error al buscar niveles", { autoClose: 10000 });
    }
  };

  const evaluarNit = (event) => {
    const { name, value } = event.target;
    if (value.toLowerCase().substr(0, 2) == "cf") {
      setHabilitaClienteCF(true);
      setNitClient(value.toUpperCase());
    } else {
      setHabilitaClienteCF(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value.toLowerCase().substr(0, 2) == "cf") {
      setHabilitaClienteCF(true);
      setNewClient({ ...newClient, nit: value, nombre: "Consumidor Final" });
      return null;
    } else {
      setHabilitaClienteCF(false);
    }

    setNewClient({
      ...newClient,
      [name]: value,
    });
  };

  const saveClient = (data) => {
    data.nit = newClient.nit;
    data.nombre = newClient.nombre;

    if (data.limite_credito) {
      data.limite_credito = data.limite_credito.replace(",", "");
    }

    if (data.limite_credito === undefined) {
      data.limite_credito = 0;
    } else {
      data.limite_credito = parseInt(data.limite_credito);
    }

    if (habilitaClienteCF) {
      data.diferenciacion = "individual";
      // data.id_nivel_precio = 1;
    } else {
      data.client_code = isCmi() ? data.nit : null;
    }
    if (data.id_nivel_precio === "") {
      data.id_nivel_precio = 1;
    }

    if (data.telefono === undefined || data.telefono === "") {
      data.telefono = null;
    }

    if (data.dias_credito === undefined) {
      data.dias_credito = 0;
    } else {
      data.dias_credito = parseInt(data.dias_credito);
    }

    // if (habilitaClienteCF) {
    //   toast.warning('No puede crear cliente CF', { autoClose: 10000 })
    //   return null
    // }

    console.log("client to save ->", data);

    saveNewClientCmi(data);
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(saveClient)}
      className="col-md-12 px-0 d-flex flex-wrap"
    >
      <div className="col-md-3 mt-3">
        <div className="mb-0 d-flex justify-content-between">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>{moneda == "L" ? "RTN" : "NIT"}</strong>
          </label>
          {/* {!habilitaClienteCF && (
            <span style={{ color: "#5494ef", cursor: "pointer" }}>
              No posee
            </span>
          )} */}

          {/* <Tooltip title="El NIT no debe llevar guión y el caracter alfabético debe estar en mayuscula. Ej. 123456789K">
            <InfoIcon
              style={{
                color: ' #828282',
                fontSize: '1.2rem',
              }}
            />
          </Tooltip> */}
        </div>

        <TextField
          type="text"
          className="w-100"
          placeholder="Ingrese el NIT del cliente"
          //inputRef={register}
          name="nit"
          //defaultValue={nitClient.toUpperCase()}
          value={newClient.nit.toUpperCase()}
          required={true}
          onChange={(e) => {
            evaluarNit(e);
            handleChange(e);
          }}
        />
      </div>

      <div className="col-md-3 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Nombre y apellido</strong>
        </label>

        <TextField
          type="text"
          className="w-100"
          name="nombre"
          //inputRef={register}
          value={newClient.nombre}
          placeholder="Ingrese el nombre del cliente"
          required={true}
          disabled={habilitaClienteCF ? true : false}
          onChange={handleChange}
        />
      </div>

      <div className="col-md-3 mt-3">
        <div className="w-100">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Tipo de cliente</strong>
          </label>
        </div>

        <Select
          className="cs-select1 select-w100"
          native
          inputRef={register}
          name="diferenciacion"
          style={{ width: "100%" }}
          variant="outlined"
          required={habilitaClienteCF ? true : false}
          disabled={habilitaClienteCF ? true : false}
        >
          <option value="">Seleccione</option>
          <option
            selected={habilitaClienteCF ? "selected" : ""}
            value="individual"
          >
            Individual
          </option>
          <option value="restaurante">Restaurante</option>
          <option value="cafeteria">Cafetería</option>
          <option value="hotel">Hotel</option>
        </Select>
      </div>

      <div className="col-md-3 mt-3">
        <div className="w-100">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Nivel de cliente</strong>
          </label>
        </div>

        <Select
          className="cs-select1 select-w100"
          native
          inputRef={register}
          name="id_nivel_precio"
          style={{
            width: "100%",
          }}
          variant="outlined"
          required={false}
          //disabled={habilitaClienteCF ? true : false}
        >
          <option value="">Regular</option>
          {nivelPrecio.map((level) => (
            <option key={level.id} value={level.id}>
              {level.nivel}
            </option>
          ))}
        </Select>
      </div>

      {habilitaClienteCF && (
        <div className="col-md-4 mt-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Nombre del cliente final</strong>
          </label>

          <TextField
            type="text"
            className="w-100"
            placeholder="Ingrese el nombre del cliente final"
            inputRef={register}
            name="nombre_cliente_final"
          />
        </div>
      )}

      {!habilitaClienteCF && (
        <div className="col-md-4 mt-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Dirección fiscal (opcional)</strong>
          </label>

          <TextField
            type="text"
            className="w-100"
            placeholder="Ingrese la dirección fiscal del cliente"
            inputRef={register}
            name="direccion"
          />
        </div>
      )}

      {habilitaClienteCF && (
        <div className="col-md-4 mt-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Código del cliente</strong>
          </label>

          <TextField
            type="text"
            className="w-100"
            placeholder="Ingrese el código del cliente"
            inputRef={register}
            name="client_code"
          />
        </div>
      )}

      {!habilitaClienteCF && (
        <div className="col-md-4 mt-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Correo electrónico (opcional)</strong>
          </label>

          <TextField
            type="email"
            className="w-100"
            placeholder="Ingrese el correo electrónico del cliente"
            inputRef={register}
            name="email"
          />
        </div>
      )}

      <div className="col-md-4 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Teléfono (opcional)</strong>
        </label>

        <TextField
          type="number"
          className="w-100"
          placeholder="Ingrese número de teléfono"
          inputRef={register}
          name="telefono"
        />
      </div>

      <div className="col-md-12 mt-3 d-flex justify-content-between align-items-center">
        <div className="col-md-4">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <label>
              <strong>Crédito</strong>
            </label>
            <label class="switch">
              <input
                type="checkbox"
                ref={register}
                name="add_credito"
                defaultChecked={add_credito_cmi}
                onChange={(e) => setAdd_credito_cmi(!add_credito_cmi)}
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div className="w-100 d-flex align-items-center">
            <p>¿Desea otorgar crédito a este cliente?</p>
          </div>
        </div>

        <div className="col-md-4 h-100">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Límite de crédito</strong>
          </label>

          <TextField
            type="text"
            className="w-100"
            placeholder="Ingrese el límite de crédito del cliente"
            value={priceValue}
            disabled={!add_credito_cmi}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{moneda}.</InputAdornment>
              ),
            }}
            onChange={(event) => {
              setPriceValue(formaterPriceFunction(event.target.value));
            }}
            name="limite_credito"
            inputRef={register}
            required={isCmi() ? true : false}
          />
        </div>

        <div className="col-md-4 h-100">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Días de crédito</strong>
          </label>
          <Select
            className="cs-select1 select-w100"
            native
            inputRef={register}
            required={isCmi() ? true : false}
            disabled={!add_credito_cmi}
            name="dias_credito"
            style={{ width: "100%" }}
            variant="outlined"
          >
            <option value="">Seleccione un periodo</option>
            <option value="0">0 días</option>
            <option value="15">15 días</option>
            <option value="30">30 días</option>
            <option value="60">60 días</option>
            <option value="90">90 días</option>
          </Select>
        </div>
      </div>

      <div className="modal-footer col-md-12 px-0">
        <div className="w-100 d-flex justify-content-end align-items-center">
          <Button
            className="mr-2"
            variant="outlined"
            color="primary"
            data-dismiss="modal"
            disabled={loader}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            className="btn-cs1"
            variant="contained"
            color="primary"
            disabled={loader}
          >
            {loader ? <CircularProgress size={26} /> : "Crear cliente"}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CrearNewClienteModal;
