import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Pie } from "recharts";
import PieChart from "recharts/lib/chart/PieChart";
import Cell from "recharts/lib/component/Cell";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";

const datad = [
  { name: "Efectivo", value: 400 },
  { name: "Tarjeta", value: 300 },
  { name: "A crédito", value: 300 },
];
const COLORS = ["#33eaff", "#fff200", "#3d3d3d", "#0160e7", "#536dfe"];

const PieChartCustomers = (props) => {
  const { data, loader } = props;
  return (
    <>
      <div className="col-md-12 mt-3">
        <div
          className="card py-1 m-0 align-items-center shadow"
          style={{ height: "175px", border: "none" }}
        >
          <div className="row" style={{ height: "100%" }}>
            {loader ? (
              <div className="mx-auto text-center">
                <CircularProgress size={20} />
              </div>
            ) : data.length === 0 ? (
              <p>Sin datos para mostrar</p>
            ) : (
              <>
                <div className="col-md-5 d-flex align-items-center">
                  <div>
                    <strong className="w-100 ml-2">Método de pago</strong>
                    <div
                      className="d-flex w-100 ml-2"
                      style={{ flexWrap: "wrap" }}
                    >
                      {data &&
                        data.map((item, index) => (
                          <>
                            <div className="d-flex align-items-center w-100">
                              <div
                                className="mr-1"
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "50%",
                                  background: COLORS[index],
                                }}
                              />
                              <span>{item.name}</span>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <ResponsiveContainer>
                    <PieChart width={800} height={150}>
                      <Pie
                        data={data}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {data &&
                          data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PieChartCustomers;
