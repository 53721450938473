import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";
import { toast } from "react-toastify";
import domToPdf from "dom-to-pdf";

import { CircularProgress } from "@material-ui/core";

// styles
import "./custom.css";

//icons
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

// components

const orderItems = [
  {
    cantidad: 1,
    codigo: "SKU0002",
    descripcion: "GASEOSA POPULAR",
    documento: 148,
    fecha: "2021-02-26T15:37:48.000Z",
    id_movimiento_items: 725,
    id_new_empresa: 67,
    id_new_item: 453,
    id_pos_movimiento: 705,
    id_tipo_documento_web_app: 1,
    imagen_1: "images021-02-24T23-01-07.093Z-$img_1$descarga.jpg",
    nombre: "GASEOSA INKA",
    precio: 3.8,
    serie: "FA6772",
    combo: false,
  },
  {
    nombre: "COMBO #1",
    codigo: "CODIGO COMBO 1",
    descripcion: "DESCRIPCION COMBO 1",
    precio: 500.85,
    combo: true,
    cantidad: 2,
    items: [
      {
        nombre: "PRODUCTO DEL COMBO #1",
        codigo: "CODIGO 1",
        descripcion: "PRODUCTO 111",
        cantidad: 10,
      },
      {
        nombre: "PRODUCTO DEL COMBO #2",
        codigo: "CODIGO 2",
        descripcion: "PRODUCTO 222",
        cantidad: 2,
      },
    ],
  },
];

const ref = React.createRef();

export default function Users_all(props) {
  const generatePdf = (filename) => {
    const element = document.querySelector(".pdf-target");

    const options = {
      filename: filename,
    };

    return domToPdf(element, options, () => {
      // callback function
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div
          style={{
            border: "1px solid gray",
            width: "100%",
            overflowX: "scroll",
          }}
          className="mt-4"
        >
          <div
            className="pdf-target px-4 py-3"
            ref={ref}
            style={{ backgroundColor: "white" }}
          >
            {false ? (
              <div className="anulado-overlay">
                <p style={{ color: "red" }}>FACTURA ANULADA</p>
              </div>
            ) : (
              ""
            )}

            <div className="row">
              <div className="col-3 px-1">
                <img
                  src={
                    props.imagen_factura !== ""
                      ? props.imagen_factura
                      : "/images/cloudLogo.png"
                  }
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="col-7">
                <p className="mb-0">
                  <strong>CLOUD, S.A</strong>
                </p>
                <p className="mb-0">
                  <strong>CLOUD</strong>
                </p>
                <p className="mb-0">NIT: 7879036-0</p>
                <p>
                  Vía 4 1-00, zona 4 Edificio Campus Tec 2, 6to Nivel Oficina
                  601. Teléfono 25033333
                </p>

                <p className="mb-0">
                  <strong>DOCUMENTO TRIBUTARIO ELECTRONICO</strong>
                </p>
                <p className="mb-0">
                  <strong>SERIE "PRUEBAS" Número "3352972607"</strong>
                </p>
                <p className="mb-0">
                  <strong>
                    Id. dte "FE372F17-C7DA-4D3F-A11C-D649ECC723DD"
                  </strong>
                </p>
              </div>
              <div className="col-2">
                <p className="mb-0 text-right">Id. único: FA581725</p>
              </div>
            </div>

            <p className="ml-4">FECHA: {moment().local().format("L")}</p>

            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">Nombre: CLIENTE PRUEBA COFIDI 1</p>
                <p className="mb-0">Dirección: JIRON MANCO CAPAC 501-PARCONA</p>
              </div>

              <p className="mb-0">NIT: 27957212</p>
            </div>

            <div className="divider" />
            <div className="row">
              <div className="col-2 text-center">
                <strong>Cantidad</strong>
              </div>
              <div className="col-5 text-center">
                <strong>Descripción</strong>
              </div>
              <div className="col-3 text-center">
                <strong>Precio Unitario</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Total</strong>
              </div>
            </div>
            <div className="divider" />

            {orderItems.map((product) =>
              product.combo ? (
                <div className="row mt-1">
                  <div
                    className="col-2 text-center my-auto"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {product.cantidad}
                  </div>
                  <div
                    className="col-5 text-center my-auto"
                    style={{ fontSize: "0.9rem" }}
                  >
                    <div className="my-1">
                      <p style={{ fontSize: "0.9rem" }}>
                        {product.nombre} <br /> {product.codigo}
                      </p>
                    </div>
                    <div>
                      {product.items.map((combo_item) => (
                        <p style={{ fontSize: "0.7rem" }}>
                          {combo_item.nombre} <br /> Cant. total:{" "}
                          {combo_item.cantidad}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div
                    className="col-3 text-center my-auto"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {
                      product.total
                        ? product.precio.toFixed(2) // On this case, user comes from the sales process
                        : (product.precio / product.cantidad).toFixed(2) // On this case, user comes from wherever else
                    }
                  </div>
                  <div
                    className="col-2 text-center my-auto"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {product.total
                      ? product.total.toFixed(2) // Same logic as before
                      : product.precio.toFixed(2)}
                  </div>
                </div>
              ) : (
                <div className="row mt-1">
                  <div
                    className="col-2 text-center my-auto"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {product.cantidad}
                  </div>
                  <div
                    className="col-5 text-center my-auto"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {product.nombre} <br /> {product.codigo}
                  </div>
                  <div
                    className="col-3 text-center my-auto"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {
                      product.total
                        ? product.precio.toFixed(2) // On this case, user comes from the sales process
                        : (product.precio / product.cantidad).toFixed(2) // On this case, user comes from wherever else
                    }
                  </div>
                  <div
                    className="col-2 text-center my-auto"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {product.total
                      ? product.total.toFixed(2) // Same logic as before
                      : product.precio.toFixed(2)}
                  </div>
                </div>
              )
            )}

            <div className="divider" />

            <div className="row">
              <div className="col-4" />
              <div className="col-3" />
              <div
                className="col-5 text-right"
                style={{ paddingRight: "44px" }}
              >
                <strong>
                  Total-Factura Q{" "}
                  {orderItems[0].total
                    ? Number(
                        orderItems.reduce((a, b) => a + b.total, 0)
                      ).toFixed(2)
                    : Number(
                        orderItems.reduce((a, b) => a + b.precio, 0)
                      ).toFixed(2)}
                </strong>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="row">
                  <div className="col-7 text-right">Efectivo:</div>
                  <div className="col-5 text-right">3,8</div>
                </div>

                <div className="row">
                  <div className="col-7 text-right">Cambio:</div>
                  <div className="col-5 text-right">3,8</div>
                </div>

                <div className="row">
                  <div className="col-7 text-right">Tarjeta Credito</div>
                  <div className="col-5 text-right">0</div>
                </div>

                {/* <div className="row">
												<div className="col-7 text-right">Total Cupones:</div>
												<div className="col-5 text-right">{invoiceInfo.paidByGiftCard}</div>
											</div> */}
              </div>
            </div>

            <p className="mb-0 mt-2 text-center">
              REGIMEN SUJETO A RETENCION DEFINITIVA
            </p>
            <div className="divider" />

            <p className="mb-0">ATENDIO: CONTACTO PRUEBA COFIDI</p>
            <p className="mb-0">{moment().format("LLL")}</p>
            <p>Correlativo: 3352972607</p>

            <div className="row">
              <div className="col-3">FIRMA ELECTRONICA</div>
              <div className="col-7">
                <strong>4A11CA97-592F-488C-B18E-87CA73CAEE01</strong>
              </div>
            </div>

            <div className="row">
              <div className="col-3">CERTIFICADOR:</div>
              <div className="col-3">COFIDI</div>
              <div className="col-4">78790360</div>
            </div>

            <div className="divider" style={{ marginTop: "250px" }} />
            <p className="mb-0 text-center">{props.texto_1}</p>
            <div className="divider" />
            <p className="mb-0 text-center">{props.texto_2}</p>
            <div className="divider" />
          </div>
        </div>
      </div>
    </div>
  );
}
