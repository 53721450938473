import React, { useEffect, useState, useCallback } from "react";
import { getProductSizes } from "../../../../api/products";
export const useSizes = (id, size) => {
  const [sizes, setSizes] = useState([]);
  const getSizes = useCallback(async () => {
    const { data } = await getProductSizes(id);
    setSizes(data.data);
  }, [id, size]);

  useEffect(() => {
    getSizes();
  }, [id, size]);
  if (!sizes) {
    return "loading..";
  }

  const currentSize = sizes.find((item) => item.nombre_talla === size);
  return [currentSize];
};
