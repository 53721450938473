import React from "react";

import Rating from "@material-ui/lab/Rating";

import { isCmi } from "../../../helpers/isCmi";

//styles
import "./reviewsDetail.css";

const ReviewsDetail = ({ placeInfo }) => {
  const reviewCounter = (cantidad) => {
    let total = placeInfo.objeto.user_ratings_total
      ? placeInfo.objeto.user_ratings_total
      : 0;

    if (total > 0 && placeInfo.objeto.reviews) {
      let cantidadTotal = placeInfo.objeto.reviews.filter((x) => {
        if (cantidad === 5) {
          return x.rating > cantidad - 1 && x.rating <= cantidad;
        } else if (cantidad === 1) {
          return x.rating >= cantidad - 1 && x.rating <= cantidad;
        } else {
          return x.rating >= cantidad && x.rating < cantidad + 1;
        }
      });

      return (cantidadTotal.length * 100) / total + "%";
    } else {
      return "0%";
    }
  };

  return (
    <>
      {placeInfo.objeto !== null && (
        <div className="ReviewsDetail-container">
          <div className="ReviewsDetail-countI">
            <div className="reviewsBar-container">
              {" "}
              <span>5</span>{" "}
              <div className="reviewsBar">
                {" "}
                <div style={{ width: reviewCounter(5) }}></div>
              </div>{" "}
            </div>
            <div className="reviewsBar-container">
              {" "}
              <span>4</span>{" "}
              <div className="reviewsBar">
                {" "}
                <div style={{ width: reviewCounter(4) }}></div>
              </div>{" "}
            </div>
            <div className="reviewsBar-container">
              {" "}
              <span>3</span>{" "}
              <div className="reviewsBar">
                {" "}
                <div style={{ width: reviewCounter(3) }}></div>
              </div>{" "}
            </div>
            <div className="reviewsBar-container">
              {" "}
              <span>2</span>{" "}
              <div className="reviewsBar">
                {" "}
                <div style={{ width: reviewCounter(2) }}></div>
              </div>{" "}
            </div>
            <div className="reviewsBar-container">
              {" "}
              <span>1</span>{" "}
              <div className="reviewsBar">
                {" "}
                <div style={{ width: reviewCounter(1) }}></div>
              </div>{" "}
            </div>
          </div>

          <div className="ReviewsDetail-count">
            <span>{placeInfo.objeto.rating ? placeInfo.objeto.rating : 0}</span>

            <Rating
              name="read-only"
              value={placeInfo.objeto.rating ? placeInfo.objeto.rating : 0}
              readOnly
            />
          </div>

          <div className="ReviewsDetail-seeMore">
            {isCmi() && (
              <>
                <span>N° Calificaciones</span>

                <span>
                  {placeInfo.objeto.user_ratings_total
                    ? placeInfo.objeto.user_ratings_total
                    : 0}
                </span>
              </>
            )}

            <button
              onClick={() => {
                var win = window.open(placeInfo.objeto.url, "_blank");
              }}
            >
              <span>Ver en Maps</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewsDetail;
