import styled from "styled-components";

import { colors, device } from "./config.styled.components";

const Button = styled.button`
  display: ${(props) => props.display || "flex"};
  align-items: ${(props) => props.alignItems || "center"};

  font-size: ${(props) => props.fontSize || "14px"};
  font-weight: ${(props) => props.fontWeight || "500"};
  padding: ${(props) => props.padding || "14px 19px"};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || "5px"};
  white-space: ${(props) => props.whiteSpace || "nowrap"};

  box-shadow: ${(props) => props.boxShadow};
  margin: ${(props) => props.margin || "10px"};

  color: ${(props) => props.color || "rgba(255,255,255,1)"};
  box-sizing: border-box;
  grid-area: ${(props) => props.gridArea};
  justify-self: ${(props) => props.justifySelf};
  row-gap: ${(props) => props.rowGap};
  column-gap: ${(props) => props.columnGap};
`;

export const PerspectiveButton = styled(Button)`
  color: ${(props) => (props.active ? "#fff" : "#000")};
  background-color: ${(props) =>
    props.active ? colors.activePerspective : ""};
`;

export const BackButton = styled(Button)`
  background-color: rgba(1, 96, 231, 1);
`;

export const ButtonCotizador = styled(Button)`
  background-color: ${(props) =>
    props.backgroundColor || "rgba(254,254,255,255)"};
  color: ${(props) => props.color || "#000"};
  border: ${(props) => props.border || "#fff"} solid 1px;
`;

export const CancelButton = styled(Button)`
  background-color: ${(props) => props.backgroundColor || "rgba(235,87,87,1)"};
`;
export const ContinueButton = styled(Button)`
  background-color: ${(props) => props.backgroundColor || "rgba(8,191,230,1)"};
`;
export const VariantButton = styled(Button)`
  background-color: "white";
  border: 1px solid #5a5a5a;
`;

export const AddProduct = styled(Button)`
  background-color: ${(props) => props.backgroundColor || "transparent"};
  color: ${(props) => props.color || colors.blackColor};
  padding: 14px 50px;
  margin: 0;
`;

export const Product = styled(AddProduct)`
  border-bottom: ${(props) =>
    props.active ? `1px solid ${colors.activeColor}` : ""};
  color: ${(props) => (props.active ? colors.activeColor : "")};
  font-weight: ${(props) => (props.active ? "700" : "500")};
`;
