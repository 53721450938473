import {
  Box,
  Button,
  Card,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import DataTable from "react-data-table-component";
import TabPanel from "../../../components/TabPanel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  outline: "none",
};

export default function DescargasModal({ downloadedProducts }) {
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    if (
      downloadedProducts.agregados.length ||
      downloadedProducts.no_agregados.length
    ) {
      handleOpen();
    }
  }, [downloadedProducts]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={style}>
          <Box className="border-bottom p-2">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Productos a descargar
            </Typography>
          </Box>
          <Box className="border-bottom px-2">
            <Tabs
              className="mt-2"
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newTab) => setTab(newTab)}
            >
              <Tab
                style={{ textTransform: "none" }}
                label="Productos descargados"
              />
              <Tab
                style={{ textTransform: "none" }}
                label="Productos no descargados"
              />
            </Tabs>
          </Box>

          <TabPanel index={0} value={tab}>
            <DataTable
              className="datatable-cs datatable-cs-v2 "
              title={"s"}
              columns={[
                {
                  name: "Código SAP",
                  selector: "codigo_sap",
                  sortable: true,
                },

                {
                  name: "Nombre",
                  selector: "nombre",
                  sortable: true,
                },
                {
                  name: "Unidad",
                  selector: "unidad_medida",
                  sortable: true,
                },
                {
                  name: "Cantidad",
                  selector: "cantidad",
                  sortable: true,
                },
              ]}
              data={downloadedProducts.agregados}
              defaultSortField="vencimiento_fecha"
              pagination
              paginationPerPage={5}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se ha encontrado ningún resultado"
            />
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <DataTable
              className="datatable-cs datatable-cs-v2 "
              title={"s"}
              columns={[
                {
                  name: "Código SAP",
                  selector: "codigo_sap",
                  sortable: true,
                },

                {
                  name: "Nombre",
                  selector: "nombre",
                  sortable: true,
                },
                {
                  name: "Unidad",
                  selector: "unidad_medida",
                  sortable: true,
                },
                {
                  name: "Cantidad",
                  selector: "cantidad",
                  sortable: true,
                },
              ]}
              data={downloadedProducts.no_agregados}
              defaultSortField="vencimiento_fecha"
              pagination
              paginationPerPage={5}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se ha encontrado ningún resultado"
            />
          </TabPanel>

          <Box className="p-2 border-top d-flex justify-content-end">
            <Button
              variant="contained"
              color="primary"
              className="shadow-none"
              onClick={handleClose}
            >
              <span style={{ fontSize: "1rem" }}>Aceptar</span>
            </Button>
          </Box>
        </Card>
      </Modal>
    </div>
  );
}
