const sortAlphabetically = (array, field) => {
  let sortedArray = array.sort(function (a, b) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
};

const unidades = [
  {
    value: "15 Pack",
    nombre: "15 Pack",
  },
  {
    value: "6 Pack",
    nombre: "6 Pack",
  },
  {
    value: "Arroba",
    nombre: "Arroba",
  },
  {
    value: "Caja",
    nombre: "Caja",
  },
  {
    value: "Docena",
    nombre: "Docena",
  },
  {
    value: "Fardo",
    nombre: "Fardo",
  },
  {
    value: "Paquete",
    nombre: "Paquete",
  },
  {
    value: "Unidad",
    nombre: "Unidad",
  },
];
export const sale_units = sortAlphabetically(unidades, "nombre");
