import { Button } from "@material-ui/core";
import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { getMoneda } from "../../../../helpers/moneda";

const SalesTable = (props) => {
  const { filteredSales, handleSelect, formaterPriceFunction, salesAction } =
    props;
  const moneda = getMoneda();
  return (
    <DataTable
      className="datatable-cs datatable-cs-v2"
      title={"s"}
      columns={[
        {
          name: "N° Factura",
          selector: "identificador",
          sortable: true,
        },
        {
          name: "Fecha / Hora",
          selector: "fecha",
          sortable: true,
          cell: (row) => (
            <div className="text-center w-100">
              {moment(row.fecha).utcOffset(0).format("DD/MM/YYYY, h:mm:ss a")}
            </div>
          ),
        },
        {
          name: "Nombre del cliente",
          selector: "client.nombre",
          sortable: true,
        },
        {
          name: "NIT",
          selector: "client.nit",
          sortable: true,
        },
        {
          name: "N° productos",
          selector: "numero_productos",
          sortable: true,
          cell: (row) => (
            <div className="text-center w-100">{row.numero_productos}</div>
          ),
        },

        {
          name: "Monto",
          selector: "monto_total",
          sortable: true,
          cell: (row) => (
            <span>
              {moneda}. {formaterPriceFunction(row.monto_total + "")}{" "}
            </span>
          ),
        },
        {
          name: "Acciones",
          selector: "action",
          cell: (row) => (
            <div className="text-center w-100">
              <Button
                className="btn-cs1"
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIosIcon />}
                onClick={() =>
                  salesAction(
                    row.identificador,
                    row.client.nit,
                    row.id_pos_movimiento
                  )
                }
                size="small"
              >
                <span
                  style={{
                    marginBottom: "-4px",
                    fontSize: "12px",
                  }}
                >
                  Ver{" "}
                  <span
                    className="d-none d-xl-inline"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    detalles
                  </span>
                </span>
              </Button>
            </div>
          ),
        },
      ]}
      data={filteredSales}
      defaultSortField="fecha"
      defaultSortAsc={false}
      pagination
      paginationPerPage={10}
      paginationComponentOptions={{
        rangeSeparatorText: "de",
        noRowsPerPage: true,
        selectAllRowsItem: false,
        selectAllRowsItemText: "All",
      }}
      selectableRows
      noDataComponent="No hay ventas pendientes"
      onSelectedRowsChange={handleSelect}
    />
  );
};

export default SalesTable;
