import React, { useState, useEffect } from "react";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
// styles
import useStyles from "./styles";
import "./custom.css";

// context
import { useUserDispatch, loginUser } from "../../../context/AdminContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  //eslint-disable-next-line
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  useEffect(() => {
    if (!!localStorage.getItem("invalid_token")) {
      toast.error(localStorage.getItem("invalid_token"), { autoClose: 10000 });
      localStorage.removeItem("invalid_token");
    }
  }, []);

  return (
    <div className="row h-100 ">
      <div className="col-6 logo-container admin-banner d-flex justify-content-center align-items-center">
        <img
          style={{ width: "400px", height: "auto" }}
          src={"/images/admin-logo.png"}
        />
      </div>
      <div className="col-lg-6 d-flex justify-content-center align-items-center">
        <div className="row p-0 w-100 justify-content-center">
          <div className="col-8">
            <div className="content-card card">
              <div className="card-body ">
                <h1 style={{ fontSize: "2.1rem" }} className="mb-5">
                  <strong>Iniciar sesión</strong>
                </h1>

                <p className="mt-3 mb-0" style={{ color: "0160E7" }}>
                  <strong>Correo electrónico</strong>
                </p>

                <TextField
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={loginValue}
                  onChange={(e) => setLoginValue(e.target.value)}
                  margin="normal"
                  placeholder="Ingrese su correo electrónico"
                  type="email"
                  fullWidth
                />
                <div className="row mt-3 px-3 justify-content-between">
                  <p className="my-auto">
                    <strong>Contraseña</strong>
                  </p>
                  <Link to="/admin/forgot/email">
                    <p className="my-auto" style={{ color: "#0160E7" }}>
                      Olvidé mi contraseña
                    </p>
                  </Link>
                </div>

                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                  margin="normal"
                  placeholder="Ingrese su contraseña"
                  type="password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter")
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError
                      );
                  }}
                  fullWidth
                />
                <div className="d-flex">
                  {isLoading ? (
                    <CircularProgress size={26} className="mx-auto" />
                  ) : (
                    <Button
                      disabled={
                        loginValue.length === 0 || passwordValue.length === 0
                      }
                      onClick={() =>
                        loginUser(
                          userDispatch,
                          loginValue,
                          passwordValue,
                          props.history,
                          setIsLoading,
                          setError
                        )
                      }
                      variant="contained"
                      size="large"
                      style={{
                        backgroundColor: "#0160E7",
                        color: "white",
                        textTransform: "none",
                      }}
                      className="ml-auto mt-4"
                    >
                      <strong>Iniciar sesión</strong>
                      <img
                        src={"/images/RightArrow.png"}
                        className="ml-3 mr-2"
                        alt=""
                      />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Login);
