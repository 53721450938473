import React, { useReducer, useState } from "react";
import { ProductPersonalizationReducer } from "./ProductPersonalizationReducers";
import ProductPersonalizationContext from "./ProductPersonalizationContext";
import {
  SET_CURRENT_PRODUCT,
  CREATE_ALEKO_ORDER,
  HANDLE_FORMS,
  HANDLE_SAVE_VARIANTS,
  HANDLE_DELETE_FILE,
  HANDLE_DELETE_AREA,
  HANDLE_DELETE_VARIANT,
  DELETE_PRODUCT,
  SET_VALIDATION_TRUE,
  SET_VALIDATION_FALSE,
  SET_ORDER,
  CLEAR_STATE,
  HANDLE_PERSONALIZATIONS,
  //-----------------------
  DISABLE_DISCUNT,
  ENABLE_CUSTOMIZATION,
} from "./types";

import {
  crearPedidoAleko,
  getPerzonalizations,
  getProductColor,
  getProductSizes,
  getProductStyles,
  submitAlekoOrder,
} from "../../api/products";
import { toast } from "react-toastify";
import { getAlekoOrder, personalizarPedido } from "../../api/sales";
import { FormatColorResetRounded } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
const dummyCustomTypes = [
  {
    cantidad_personalizacion: 50,
    id_new_empresa: 1238,
    id_new_item: 3107,
    id_new_personalizable: 1017,
    nombre_personalizacion: "Bordado",
    precio_extra: 0,
    factor: 1.5,
    precio_minimo: 25,
  },
  {
    cantidad_personalizacion: 50,
    id_new_empresa: 1238,
    id_new_item: 3107,
    id_new_personalizable: 1017,
    nombre_personalizacion: "Vinil Textil",
    precio_extra: 0,
    factor: 0.2,
    precio_minimo: 25,
  },
  {
    cantidad_personalizacion: 50,
    id_new_empresa: 1238,
    id_new_item: 3107,
    id_new_personalizable: 1017,
    nombre_personalizacion: "Sublimación",
    precio_extra: 0,
    factor: 0.1,
    precio_minimo: 15,
  },

  {
    cantidad_personalizacion: 50,
    id_new_empresa: 1238,
    id_new_item: 3107,
    id_new_personalizable: 1017,
    nombre_personalizacion: "Transfer",
    precio_extra: 0,
    factor: 0.1,
    precio_minimo: 15,
  },
  {
    cantidad_personalizacion: 50,
    id_new_empresa: 1238,
    id_new_item: 3107,
    id_new_personalizable: 1017,
    nombre_personalizacion: "UV",
    precio_extra: 0,
    factor: 0.5,
    precio_minimo: 25,
  },
  {
    cantidad_personalizacion: 50,
    id_new_empresa: 1238,
    id_new_item: 3107,
    id_new_personalizable: 1017,
    nombre_personalizacion: "DTG",
    precio_extra: 0,
    factor: 0.8,
    precio_minimo: 35,
  },
];

const ProductPersonalizationProvider = (props) => {
  const initialState = {
    id_new_pedido: null,
    product: {
      name: "",
      perspectives: [],
    },
    prevProducts: [],

    forms: [],
    savedVariants: [],
    validation: false,
    order: {
      firma: null,
      id_pedido: "",
      valido_hasta: new Date(),
    },
    personalizations: [],
    addProductWithDiscount: true,
    enable_customization: false,
  };

  const [currentProductTab, setCurrentProductTab] = useState({});
  const [state, dispatch] = useReducer(
    ProductPersonalizationReducer,
    initialState
  );

  const [products, setProducts] = useState([]);

  const [selectedColors, setSelectedColors] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [variants, setVariants] = useState([]);
  const [check, setCheck] = useState([]);
  const [pedido, setPedido] = useState(null);
  const [idSucursal, set_id_sucursal] = useState("");
  const clearState = () => {
    setCurrentProductTab({});
    setSelectedColors([]);
    setProducts([]);
    setCurrentTab(0);
    setCheck([]);
    set_id_sucursal("");
    setVariants([]);
    setCurrentProductTab({});
    dispatch({ type: CLEAR_STATE });
  };

  const getPersonalizationProducts = async (redirect) => {
    const selectedProducts = JSON.parse(
      localStorage.getItem("selectedProducts")
    );
    if (
      products.length &&
      selectedProducts.length &&
      products.length === selectedProducts.length
    )
      return;
    const idEmpresa = window.localStorage.getItem("id_empresa");
    const data = await getPerzonalizations(idEmpresa);

    let validation = false;
    const currentPersonalizations = [];
    const fetchAllProducts = Promise.all(
      selectedProducts.map(async (product, index) => {
        const prevProduct = products.find(
          (item) => item.id_new_item === product.id_new_item
        );
        if (prevProduct) return prevProduct;
        // const coloresData = await getProductColor(product.id_new_item);
        // const tallasData = await getProductSizes(product.id_new_item);
        // const personalizationsData = await getProductStyles(
        //   product.id_new_item
        // );
        const colores = data.data.ColorItems.map((x) => ({
          ...x,
          codigo_hexa_color: x.codigo_hexadecimal,
          id_new_detalle_color: x.id_colores_personalizados_producto,
          precio_extra: 0,
        }));
        const tallas = data.data.tallaItems.map((x) => ({
          ...x,
          id_new_detalle_tallas: x.id_tallas_personalizadas_producto,
        }));
        const personalizations = data.data.PersonalizacionItems.map((x) => ({
          ...x,
          id_new_personalizable: x.id_personalizacion_producto,
          factor: x.precio_medida,
        }));
        currentPersonalizations.push(
          ...colores,
          ...tallas,
          ...personalizations
        );
        if (!colores.length || !tallas.length || !personalizations.length) {
          validation = true;
          return;
        }
        const variantId = Math.random().toString(16);

        handleForms({
          variantId,
          vista: product.imagen_1,
          id_producto: product.id_new_item,
          id_new_item: product.id_new_item,
          vista_index: 0,
          addProductWithDiscount: true,
          enable_customization: false,
          descuento_producto: 0,
          variante: "variante 1",
          valor_talla: "talla",
          id_talla: tallas[0].id_new_detalle_tallas,
          cantidad_producto: "",
          nombre: product.nombre,
          valor_color: "#fff",
          id_color: 1,
        });
        let initialProduct = {
          ...product,
          title: product.nombre,
          id_new_item: product.id_new_item,
          id: Math.random().toString(16),
          variante: 1,
          descuento_producto: 0,
          cantidad: 0,
          variantId,
          color: "",
          talla: tallas[0].nombre_talla,
          addProductWithDiscount: 1,
          custom: {
            contentType: ["Texto", "Imagen"],
            customType: personalizations,
            // customType: dummyCustomTypes,
            points: [],
            perspectives: [
              {
                title: "Frente",
                image: product.imagen_1,
              },
              {
                title: "Der.",
                image: product.imagen_3,
              },
              {
                title: "Izq.",
                image: product.imagen_4,
              },
              {
                title: "Trasera",
                image: product.imagen_2,
              },
            ],

            variant: colores,

            general: [
              {
                title: "Talla",
                options: tallas,
              },
            ],
          },
        };

        saveVariant(initialProduct);
        setVariants([initialProduct]);

        return initialProduct;
      })
    );
    if (validation) {
      redirect.push("/app/inventario");
      clearState();
      toast.error(
        "Faltan colores, tallas o personalizaciones por crear en la pestaña características",
        { autoClose: 10000 }
      );

      return;
    }
    fetchAllProducts.then((res) => {
      setProducts(res);
      setVariants([res[0]]);
      dispatch({
        type: HANDLE_PERSONALIZATIONS,
        payload: currentPersonalizations,
      });
    });
  };

  const setCurrentProduct = (item) => {
    dispatch({
      type: SET_CURRENT_PRODUCT,
      payload: { name: item.title, perspectives: item.custom.perspectives },
    });
  };

  const createVariant = (index) => {
    const lastIndex = products
      .map((item) => item.title)
      .lastIndexOf(products[index].title);
    setProducts([
      ...products,
      {
        ...products[index],
        id: Math.random().toString(16),
        variante: products[lastIndex].variante + 1,
        variantId: Math.random().toString(16),
      },
    ]);
  };

  /* DELETE */

  const deleteCustomizeProduct = (id, history, setCurrentTab, currentTab) => {
    let count = 0;
    products.map((product) => (product.id_new_item === id ? count++ : product));
    const filterProducts = products.filter(
      (product) => product.id_new_item !== id
    );

    console.log(
      "Count: ",
      count,
      "Products",
      products,
      "FilterP",
      filterProducts
    );
    console.log("Products de Context subidos al local storage", filterProducts);

    if (count === products.length) {
      history.push("/app/vender");
      setProducts([]);
      localStorage.setItem("selectedProducts", JSON.stringify(filterProducts));
      dispatch({ type: DELETE_PRODUCT, payload: id });
      clearState();
      return;
    } else if (currentTab === products.length - 1) {
      setCurrentTab((prev) => prev - 1);
    }

    setProducts(filterProducts);
    localStorage.setItem("selectedProducts", JSON.stringify(filterProducts));
    dispatch({ type: DELETE_PRODUCT, payload: id });
  };

  const createAlekoOrder = async (id_bodega_sucursal, history) => {
    const { data } = await crearPedidoAleko({
      id_bodega_sucursal,
    });
    dispatch({ type: CREATE_ALEKO_ORDER, payload: data });
    history.push("/app/vender/customization");
  };

  const handleForms = (form) => {
    dispatch({ type: HANDLE_FORMS, payload: form });
  };

  const saveVariant = (product, variante) => {
    dispatch({ type: HANDLE_SAVE_VARIANTS, payload: product });

    if (variante) {
      handleForms({
        variantId: product.variantId,
        vista: product.imagen_1,
        id_producto: product.id_new_item,
        id_new_item: product.id_new_item,
        vista_index: 0,
        addProductWithDiscount: true,
        enable_customization: false,
        descuento_producto: 0,
        variante: "variante 1",
        valor_talla: "talla",
        id_talla:
          product.custom.general[0].options[0]
            .id_tallas_personalizadas_producto,
        cantidad_producto: "",
        nombre: product.nombre,
        valor_color: "#fff",
        id_color: 1,
      });
    }
  };

  const deleteSavedVariant = (id) => {
    dispatch({ type: HANDLE_DELETE_VARIANT, payload: id });
  };

  const deleteFile = (index, variantId) => {
    dispatch({ type: HANDLE_DELETE_FILE, payload: index, id: variantId });
  };

  const deleteArea = (index, variantId, length) => {
    dispatch({
      type: HANDLE_DELETE_AREA,
      payload: index,
      id: variantId,
      length,
    });
  };

  const submitForms = async (history) => {
    const id_new_empresa = localStorage.getItem("id_empresa");
    state.forms = state.forms.filter((form) => form.id_new_item);
    state.forms = state.forms.sort((a, b) => a.id_producto - b.id_producto);

    // try {
    //   if (check.some((item) => !item.area)) {
    //     check.map((item) =>
    //       !item.area
    //         ? toast.warn(
    //             `Producto: ${item.nombre} variante ${item.number}, debes agregar por lo menos un area`,
    //             { autoClose: 10000 }
    //           )
    //         : item
    //     );
    //     return;
    //   }
    // } catch (error) {}
    if (currentTab !== products.length - 1) {
      setCurrentTab((prev) => prev + 1);
      return;
    }

    let missingFields = [];
    console.log("forms", state.forms);
    state.forms.map((item, index) => {
      for (let key in item) {
        if (
          item[`contenido_tipo_${index + 1}`] &&
          item[`contenido_tipo_${index + 1}`] == "Texto"
        ) {
          if (
            item[key]?.toString().trim() === "" &&
            !key.includes("observaciones") &&
            !key.includes("descuento") &&
            !key.includes("adjunto") &&
            !key.includes("color") &&
            !key.includes("talla") &&
            !key.includes("vista")
          ) {
            missingFields.push({
              name: item.nombre,
              missing: key.split("_").join(" "),
            });
          }
        } else if (
          item[key]?.toString().trim() === "" &&
          !key.includes("observaciones") &&
          !key.includes("descuento") &&
          !key.includes("adjunto") &&
          !key.includes("color") &&
          !key.includes("talla") &&
          !key.includes("vista")
        ) {
          missingFields.push({
            name: item.nombre,
            missing: key.split("_").join(" "),
          });
        }
      }
    });

    if (missingFields.length) {
      // missingFields.map((field) =>
      //   toast.error(`${field.name}, Campo requerido: ${field.missing}`, {
      //     autoClose: 10000,
      //   })
      // );
      console.log("============0", missingFields);
      toast.error("Campos requeridos", { autoClose: 10000 });
      dispatch({ type: SET_VALIDATION_TRUE });
      return;
    } else {
      dispatch({ type: SET_VALIDATION_FALSE });
    }
    toast.success("Pedido enviado satisfactoriamente", {
      autoClose: 10000,
    });
    let files = [];
    state.forms.map((item) => {
      // let data = Object.entries(item).filter((item) => item[1]);

      let files2 = Object.entries(item).filter(
        (item) => item[0].includes("adjunto") && typeof item[1] !== "string"
      );
      files = [
        ...files,
        ...files2.map((file) => {
          return new File(
            [file[1]],
            `$img_$${item.id_new_item}$${file[0]}$${item.variante
              .split(" ")
              .join("_")}$${file[1].name}`,
            {
              type: file[1].type,
              lastModified: file[1].lastModified,
            }
          );
        }),
      ];
    });

    // const result = state.forms.reduce((r, a, i) => {
    //   r[`pedido_${i + 1}`] = r[a.id_new_item] || [];
    //   r[`pedido_${i + 1}`].push(a);
    //   return r;
    // }, Object.create(null));

    // Object.entries(result).map((item) => {
    //   return form_data.append(item[0], JSON.stringify(item[1]));
    // });
    const form_data = new FormData();
    state.forms = state.forms.map((item) => {
      let arr = Object.entries(item).map((item) =>
        item[0].includes("adjunto") ? [[item[0]], ""] : item
      );

      return Object.fromEntries(arr);
    });
    console.log("formularios 3", state.forms);
    state.forms = state.forms.map((item) => {
      let arr = Object.entries(item).map((x) => {
        if (
          x[0].includes("descuento") &&
          !x[0].includes("descuento_producto")
        ) {
          return [
            `${x[0].slice(0, x[0].length - 1)}${Number(x[0].slice(-1)) + 1}`,
            x[1],
          ];
        } else {
          return [x[0], x[1]];
        }
      });
      let newObj = Object.fromEntries(arr);
      newObj.descuento_1 = newObj.descuento_producto;

      return newObj;
    });
    console.log("formularios 4", state.forms);
    state.forms.map((item, i) =>
      form_data.append(`pedido_${i + 1}`, JSON.stringify(item))
    );

    form_data.append("n_pedidos", state.forms.length);
    form_data.append("id_new_pedido", state.id_new_pedido);
    form_data.append(
      "id_sucursal_bodega_ubicacion",
      JSON.parse(localStorage.getItem("store"))?.id_sucursal_bodega_ubicacion
    );
    form_data.append("id_new_empresa", id_new_empresa);
    files.map((item) => form_data.append("images", item));

    try {
      const { data } = await personalizarPedido(form_data);
      // clearState();
      history.push(`/app/vender/confirmar/${data.id_pedido}`);
    } catch (error) {
      console.log(error);
    }
  };

  const setOrder = (data) => {
    dispatch({ type: SET_ORDER, payload: data });
  };
  // console.log(state.order.id_pedido);

  const submitOrder = async (history) => {
    if (!state.order.firma) {
      return toast.warn("Campo requerido: Firmar del cliente", {
        autoClose: 10000,
      });
    }
    try {
      const { data } = await submitAlekoOrder(state.order);
      if (data.succes) {
        history.push("/app/vender/procesar");
      } else {
        toast.error("Ha ocurrido un error al ordenar el pedido", {
          autoClose: 10000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisableDiscount = () => {
    dispatch({ type: DISABLE_DISCUNT });
  };

  const handleEnableCustomization = () => {
    dispatch({ type: ENABLE_CUSTOMIZATION });
  };

  return (
    <ProductPersonalizationContext.Provider
      value={{
        points: state.points,
        setCurrentProductTab,
        setCurrentProduct,
        currentTab,
        setCurrentTab,
        currentProductTab,
        createVariant,
        getPersonalizationProducts,
        prevProducts: state.prevProducts,
        title: state.product.name,
        products,
        setSelectedColors,
        selectedColors,
        deleteCustomizeProduct,
        createAlekoOrder,
        handleForms,
        saveVariant,
        deleteSavedVariant,
        savedVariants: state.savedVariants,
        formularios: state.forms,
        deleteFile,
        deleteArea,
        submitForms,
        check,
        setCheck,
        set_id_sucursal,
        validation: state.validation,
        id_pedido: state.id_new_pedido,
        id_new_pedido: state.order.id_pedido,
        setOrder,
        order: state.order,
        submitOrder,
        clearState,
        pedido,
        setPedido,
        //-------------------------
        addProductWithDiscount: state.addProductWithDiscount,
        handleDisableDiscount,
        enable_customization: state.enable_customization,
        handleEnableCustomization,
        variants,
        setVariants,
      }}
    >
      {props.children}
    </ProductPersonalizationContext.Provider>
  );
};

export { ProductPersonalizationProvider };
