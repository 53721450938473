import React from "react";
import MainCard from "./components/MainCard";
import TopBarCambioMasivo from "./components/TopBarCambioMasivo";
import "../custom.css";
import PrecioActualizadoModal from "./components/SuccesfulModal";

const CambioMasivoPrecios = () => {
  return (
    <>
      <TopBarCambioMasivo />
      <MainCard />
      <PrecioActualizadoModal />
    </>
  );
};

export default CambioMasivoPrecios;
