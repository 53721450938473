import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getAll = async (id_empresa) => {
  if (id_empresa) {
    return await axios.get(
      `${base_url}/web/get-sucursals?id_new_empresa=${id_empresa}`,
      {
        headers: headers(),
      }
    );
  } else {
    return await axios.get(`${base_url}/web/get-sucursals`, {
      headers: headers(),
    });
  }
};

const getSucursalsAndCajas = async (id) => {
  console.log("id_new_empresa -->", id);

  const data = {
    id_new_empresa: parseInt(id),
  };

  return axios.post(`${base_url}/web/get-sucursals-cmi`, qs.stringify(data), {
    headers: headers(),
  });
};

const createNew = async (data) => {
  return await axios.post(`${base_url}/web/add-sucursal`, qs.stringify(data), {
    headers: headers(),
  });
};

const deleteCaja = async (data) => {
  return await axios.post(`${base_url}/web/delete-caja`, qs.stringify(data), {
    headers: headers(),
  });
};

const addCaja = async (data) => {
  return await axios.post(`${base_url}/web/add-caja`, qs.stringify(data), {
    headers: headers(),
  });
};

const updateCaja = async (data) => {
  return await axios.put(`${base_url}/web/update-caja`, qs.stringify(data), {
    headers: headers(),
  });
};

const updateSucursal = async (data) => {
  return await axios.put(
    `${base_url}/web/update-sucursal`,
    qs.stringify(data),
    { headers: headers() }
  );
};

export default {
  getAll,
  createNew,
  addCaja,
  deleteCaja,
  updateSucursal,
  updateCaja,
  getSucursalsAndCajas,
  //getSucursalesGerenteCmi,
};
