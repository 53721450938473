import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { ReportProblemOutlined } from "@material-ui/icons";
import { CancelButton } from "../styled/styled.components";
import { useHistory } from "react-router-dom";

export default function DeleteModal({
  clearState,
  cancel,
  deleteProduct,
  ...props
}) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const cancelClick = () => {
    if (deleteProduct) {
      deleteProduct();
      handleClose();
      return;
    }
    history.push("/app/vender");
    localStorage.removeItem("selectedProducts");
    handleClose();
    clearState();
    if (cancel) {
      cancel();
    }
  };
  return (
    <div className={"w-100"}>
      {/* <CancelButton onClick={handleOpen} fontSize="16px" {...props}>
        {props.text || "Cancelar Venta"}
      </CancelButton> */}

      <Button
        onClick={handleOpen}
        fontSize="16px"
        {...props}
        className="btn-cs1 delete-btn w-100 text-light"
        variant="contained"
      >
        {props.text || "Cancelar Venta"}
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="card" style={{ width: "500px", border: "none" }}>
            <div className="card-title d-flex justify-content-end align-items-center border-bottom py-2">
              <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
              <div className="w-100 d-flex justify-content-center">
                <ReportProblemOutlined
                  className="color-css-text"
                  style={{ fontSize: "60px" }}
                />
              </div>

              <h4 className="text-center">
                {props.cancelText || (
                  <strong>¿Seguro que quieres cancelar?</strong>
                )}
              </h4>
              <p>Se perdera la información</p>
            </div>
            <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-between">
              <Button
                variant="outlined"
                color="primary"
                style={{
                  boxShadow: "none",
                  textTransform: "none",
                }}
                onClick={handleClose}
              >
                <span style={{ fontSize: "14px" }}>Cancelar</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  boxShadow: "none",
                  textTransform: "none",
                }}
                onClick={cancelClick}
              >
                <span style={{ fontSize: "14px" }}>Aceptar</span>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
