import React, { useContext } from "react";

import ProductDetailsContext from "../../../../context/DetalleProductosAleko/ProductDetailsContext";
import ProductInputColor from "./EditInputColor";
import ProductColor from "./ProductColor";
import ProductPersonalizations from "./ProductPersonalizations";

import ProductSizes from "./ProductSizes";
const Caracteristicas = () => {
  const { colors } = useContext(ProductDetailsContext);

  return (
    <div>
      <div className="row">
        <label className="col-lg-2">
          <strong>Colores</strong>
        </label>
        <div className="col-lg-10">
          <hr style={{ borderTop: "1px solid #a4b0be" }} />
        </div>
        {colors?.map((item) => (
          <ProductColor item={item} />
        ))}
        <ProductInputColor />
      </div>
      <ProductSizes />
      <ProductPersonalizations />
    </div>
  );
};

export default Caracteristicas;
