import { admin_base_url, axios, base_url } from "../../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("AdminToken") };
};

const getPlansDetails = async () => {
  return await axios.get(`${admin_base_url}/web/get-plans-details`, {
    headers: headers(),
  });
};

const getMembershipStats = async () => {
  return await axios.get(`${admin_base_url}/web/get-membership-statistics`, {
    headers: headers(),
  });
};

const stateMembership = async () => {
  return await axios.get(
    `${base_url}/web/membresias/todas-membresias-detalles`,
    {
      headers: headers(),
    }
  );
};

const getPlan = async (id) => {
  return await axios.get(`${admin_base_url}/web/get-plan?plan=${id}`, {
    headers: headers(),
  });
};

const updatePlan = async (data) => {
  return await axios.put(
    `${admin_base_url}/web/update-plan`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const createPlan = async (data) => {
  return await axios.post(
    `${admin_base_url}/web/create-plan`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getUsersWithMembership = async (id) => {
  return await axios.get(
    `${admin_base_url}/web/users-with-membership?plan=${id}`,
    {
      headers: headers(),
    }
  );
};

export default {
  getPlansDetails,
  getMembershipStats,
  getPlan,
  updatePlan,
  getUsersWithMembership,
  createPlan,
  stateMembership,
};
