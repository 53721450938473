import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CircularProgress } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import CashRegister from "../caja_venta/CashRegister.png";
import Document from "../caja_venta/Document.png";
import { getMoneda } from "../../helpers/moneda";
// components

//data

export default function Sell(props) {
  const moneda = getMoneda();
  let history = useHistory();

  useEffect(() => {
    getDebitNote();
  }, []);

  const moment = require("moment");
  const [nota_debito, set_notaDebito] = useState({
    invoice: {
      tarjeta: "",
      gift_card: "",
      efectivo: "",
    },
  });
  const [loader, setLoader] = useState(true);

  const backToCatalogo = () => {
    history.push({
      pathname: "/app/vender",
    });
  };

  const openInNewTab = () => {
    localStorage.setItem(
      "factura_data",
      JSON.stringify({
        fel_data: nota_debito.invoice,
        selectedProducts: nota_debito.orderItems,
        seller: {
          id_vendedor_web_app: nota_debito.vendedor.id_vendedor_web_app,
          nombre: nota_debito.vendedor.nombre,
        },
        client: nota_debito.client,
        invoiceInfo: {
          flag_estado: nota_debito.invoice.flag_estado,
          paidAmount: {
            paid: (
              nota_debito.invoice.tarjeta +
              nota_debito.invoice.efectivo +
              nota_debito.invoice.gift_card
            ).toFixed(2),
            change:
              nota_debito.invoice.tarjeta +
                nota_debito.invoice.efectivo +
                nota_debito.invoice.gift_card >
              nota_debito.invoice.monto_total
                ? nota_debito.invoice.tarjeta +
                  nota_debito.invoice.efectivo +
                  nota_debito.invoice.gift_card -
                  nota_debito.invoice.monto_total
                : 0,
          },
          paidByCard: nota_debito.invoice.tarjeta,
          paidByCash: nota_debito.invoice.efectivo,
          paidByGiftCard: nota_debito.invoice.gift_card,
          salesCost: {
            total: nota_debito.invoice.monto_total,
            subtotal: nota_debito.invoice.subtotal,
            iva: nota_debito.invoice.vat_amount,
          },
          selectedProducts: nota_debito.orderItems,
        },
      })
    );
    const newWindow = window.open(
      "/#/app/vender/factura",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const getDebitNote = () => {
    let id_nota = props.match.params.id.toString();
    API.sales
      .getNotaDebito({ id_nota })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          set_notaDebito(response.data);
          setLoader(false);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Facturación</strong>
        </h4>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="d-flex justify-content-center justify-content-sm-between flex-wrap mb-2">
            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<img src={Document} alt="cash-register-icon" />}
              onClick={openInNewTab}
            >
              <span style={{ marginBottom: "-4px" }}>Ver documento</span>
            </Button>

            <Button
              className="btn-cs1 mx-1 mb-1"
              variant="contained"
              color="primary"
              startIcon={<img src={CashRegister} alt="cash-register-icon" />}
              onClick={backToCatalogo}
            >
              <span style={{ marginBottom: "-4px" }}>Volver a la caja</span>
            </Button>
          </div>
          <div className="content-card card">
            <div className="card-header">
              <h4 className="mb-0">
                <strong>Resumen de la compra</strong>
              </h4>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Fecha / hora</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={
                        moment.utc(nota_debito.fecha).format("L") +
                        " / " +
                        moment.utc(nota_debito.fecha).format("LTS")
                      }
                      name="fecha"
                      disabled
                    />
                  )}
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>NIT de cliente</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      name="nit"
                      value={nota_debito.client.nit}
                      disabled
                    />
                  )}
                </div>

                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Nombre de cliente</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={nota_debito.client.nombre}
                      name="client_name"
                      disabled
                    />
                  )}
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Dirección fiscal del cliente</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={nota_debito.client.direccion || "No especificada"}
                      name="direccion_fiscal"
                      disabled
                    />
                  )}
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Correo electrónico</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={nota_debito.client.email || "No especificado"}
                      name="email"
                      disabled
                    />
                  )}
                </div>
                <div className="w-100 my-3">
                  <p className="mb-0">
                    <strong>Vendedor</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={nota_debito.vendedor.nombre}
                      name="seller"
                      disabled
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card content-card mt-2">
            <div className="card-header p-0" id="facturacion-products-header">
              {loader ? (
                <div className="my-auto text-center">
                  <CircularProgress size={25} className="mx-auto" />
                </div>
              ) : (
                <DataTable
                  className="datatable-cs datatable-cs-v2 m-0"
                  title={"s"}
                  columns={[
                    {
                      name: "Producto",
                      selector: "id_new_item",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {row.nombre} <br />
                          {row.codigo}
                        </div>
                      ),
                    },
                    {
                      name: "Precio",
                      selector: "precio",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {moneda}. {row.precio.toFixed(2)}
                        </div>
                      ),
                    },
                    {
                      name: "Cantidad",
                      selector: "cantidad",
                      sortable: true,
                    },
                    {
                      name: "Total",
                      selector: "total",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          {moneda}. {row.total.toFixed(2)}
                        </div>
                      ),
                    },
                  ]}
                  data={nota_debito.orderItems}
                  pagination
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  noDataComponent="No se ha encontrado ningún producto"
                />
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-7">
                  <div className="row">
                    {nota_debito.invoice.tarjeta !== 0 ? (
                      <div
                        className="col-md-12 mb-2"
                        style={{
                          borderBottom: "1px solid #E0E0E0",
                        }}
                      >
                        <p>
                          <strong>Tarjeta de débito/crédito</strong>
                        </p>
                        <p>
                          {loader ? (
                            <div className="my-auto text-center">
                              <CircularProgress size={25} className="mx-auto" />
                            </div>
                          ) : (
                            `${moneda}. ${nota_debito.invoice.tarjeta}`
                          )}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {nota_debito.invoice.efectivo !== 0 ? (
                      <div
                        className="col-md-12 mb-2"
                        style={{
                          borderBottom: "1px solid #E0E0E0",
                        }}
                      >
                        <p>
                          <strong>Efectivo</strong>
                        </p>
                        <p>
                          {loader ? (
                            <div className="my-auto text-center">
                              <CircularProgress size={25} className="mx-auto" />
                            </div>
                          ) : (
                            `${moneda}. ${nota_debito.invoice.efectivo}`
                          )}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {nota_debito.invoice.gift_card !== 0 ? (
                      <div
                        className="col-md-12 mb-2"
                        style={{
                          borderBottom: "1px solid #E0E0E0",
                        }}
                      >
                        <p>
                          <strong>Método de pago seleccionado</strong>
                        </p>
                        <p>
                          {loader ? (
                            <div className="my-auto text-center">
                              <CircularProgress size={25} className="mx-auto" />
                            </div>
                          ) : (
                            `${moneda}. ${nota_debito.invoice.gift_card}`
                          )}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-sm-5 invoice-column">
                  <div className="row h-75">
                    <div className="col-6 p-0 m-0 text-center titles my-auto">
                      <p>SubTotal:</p>
                      <p>IVA (12%):</p>
                      <p>Total:</p>
                      <p>Pagado:</p>
                      <p>Vuelto:</p>
                    </div>
                    <div className="col-6 p-0 m-0 text-center my-auto">
                      <p>
                        {loader ? (
                          <div className="my-auto text-center">
                            <CircularProgress size={25} className="mx-auto" />
                          </div>
                        ) : (
                          `${moneda}. ${nota_debito.invoice.subtotal.toFixed(
                            2
                          )}`
                        )}
                      </p>

                      <p>
                        {loader ? (
                          <div className="my-auto text-center">
                            <CircularProgress size={25} className="mx-auto" />
                          </div>
                        ) : (
                          `${moneda}. ${nota_debito.invoice.vat_amount.toFixed(
                            2
                          )}`
                        )}
                      </p>

                      <p>
                        {loader ? (
                          <div className="my-auto text-center">
                            <CircularProgress size={25} className="mx-auto" />
                          </div>
                        ) : (
                          `${moneda}. ${nota_debito.invoice.monto_total.toFixed(
                            2
                          )}`
                        )}
                      </p>

                      <p>
                        {loader ? (
                          <div className="my-auto text-center">
                            <CircularProgress size={25} className="mx-auto" />
                          </div>
                        ) : (
                          `${moneda}. ${(
                            nota_debito.invoice.tarjeta +
                            nota_debito.invoice.efectivo +
                            nota_debito.invoice.gift_card
                          ).toFixed(2)}`
                        )}
                      </p>

                      <p>
                        {loader ? (
                          <div className="my-auto text-center">
                            <CircularProgress size={25} className="mx-auto" />
                          </div>
                        ) : (
                          ` ${moneda}. ${
                            nota_debito.invoice.tarjeta +
                              nota_debito.invoice.efectivo +
                              nota_debito.invoice.gift_card >
                            nota_debito.invoice.monto_total
                              ? (
                                  nota_debito.invoice.tarjeta +
                                  nota_debito.invoice.efectivo +
                                  nota_debito.invoice.gift_card -
                                  nota_debito.invoice.monto_total
                                ).toFixed(2)
                              : (0).toFixed(2)
                          }`
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row h-25 justify-content-center w-100 m-0">
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "#1a2eab",
                      }}
                      className="my-2 w-75 facturar-button"
                      variant="contained"
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                          textTransform: "none",
                        }}
                      >
                        Pagado
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {invoices.recibo_numero !== '' ||
            invoices.gift_card_codigo !== ''
            ?
            <div className="row mt-3">
                <div className="col-12">
                    <div className="content-card card">
                        <div className="card-header">
                            <h4>
                                <strong>Verificación del pago</strong>
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {invoices.recibo_numero !== "" ? (
                                    <>
                                        <div className="col-md my-auto">
                                            <p>
                                                <strong>
                                                    Número del recibo (Operación
                                                    con tarjeta)
                                                </strong>
                                            </p>
                                            <TextField
                                                className="w-100"
                                                variant="standard"
                                                value={invoices.recibo_numero}
                                                name="recibo_numero"
                                                disabled
                                                placeholder="Ingrese el número del recibo emitido por el pto. de venta"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            N°
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="defaultCheck1"
                                                />
                                                <label
                                                    class="form-check-label ml-2"
                                                    for="defaultCheck1"
                                                    style={{
                                                        fontSize: "0.8rem",
                                                    }}
                                                >
                                                    Ingresar manualmente
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md my-auto">
                                            <p>
                                                <strong>
                                                    Número de cuotas
                                                </strong>
                                            </p>

                                            <Select
                                                native
                                                value={invoices.cuotas}
                                                style={{ width: "100%" }}
                                                variant="outlined"
                                            >
                                                <option value={invoices.cuotas}>
                                                    {invoices.cuotas === "0"
                                                        ? "Pago único"
                                                        : invoices.cuotas +
                                                          " meses"}
                                                </option>
                                            </Select>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}

                                {invoices.gift_card_codigo !== "" ? (
                                    <div className="col-md my-auto">
                                        <p>
                                            <strong>
                                                Ingrese el código de Gift Card
                                            </strong>
                                        </p>
                                        <TextField
                                            className="w-100"
                                            variant="standard"
                                            value={invoices.gift_card_codigo}
                                            name="giftcard_code"
                                            placeholder="     -      -      -"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {invoices.adicional_recibo.length > 0 ? (
                                    <div className="col-md-12 mt-5 pl-5">
                                        <div className="row w-100 mt-2 justify-content-center justify-content-md-start">
                                            {invoices.adicional_recibo.map(
                                                (invoice) => (
                                                    <div className="col-xl-4 px-1">
                                                        <TextField
                                                            className="w-100"
                                                            variant="standard"
                                                            disabled
                                                            value={invoice}
                                                            name="invoice_number"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        Recibo
                                                                        N°
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            :''} */}

      <div className="row mt-3">
        <div className="col-12">
          <div className="content-card card">
            <div className="card-header">
              <h4>
                <strong>Nota de débito</strong>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md my-auto">
                  <p>
                    <strong>Nota creada por</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={nota_debito.vendedor.nombre}
                    />
                  )}
                </div>
                <div className="col-md my-auto">
                  <p>
                    <strong>Motivo de nota de débito</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={nota_debito.motivo}
                    />
                  )}
                </div>

                <div className="col-md my-auto">
                  <p>
                    <strong>Monto ajustado</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={nota_debito.monto}
                    />
                  )}
                </div>

                <div className="col-md my-auto">
                  <p>
                    <strong>Monto total actualizado</strong>
                  </p>
                  {loader ? (
                    <div className="my-auto text-center">
                      <CircularProgress size={25} className="mx-auto" />
                    </div>
                  ) : (
                    <TextField
                      className="w-100"
                      variant="standard"
                      value={(
                        nota_debito.monto + nota_debito.invoice.monto_total
                      ).toFixed(5)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
