import React, { useReducer, useState } from "react";
import {
  addAlekoProduct,
  createBaseProduct,
  createProductColor,
  createProductPersonalization,
  createProductSize,
  deleteAlekoProduct,
  deleteColor,
  deletePersonalization,
  deleteSize,
} from "../../api/products";
import { isAleko } from "../../helpers/isAleko";
import AlekoContext from "./AlekoContext";
import { AlekoReducer } from "./AlekoReducers";
import {
  CLEAR_ALEKO_STATE,
  CREATE_BASE_PRODUCT,
  DELETE_PRODUCT_COLOR,
  DELETE_PRODUCT_PERSONALIZATION,
  DELETE_PRODUCT_SIZE,
  GET_ALEKO,
  SET_PRODUCT_COLOR,
  SET_PRODUCT_PERSONALIZATION,
  SET_PRODUCT_SIZE,
} from "./types";
import { toast } from "react-toastify";

const AlekoProvider = (props) => {
  const initialState = {
    alekoProduct: {},
    colors: [],
    sizes: [],
    personalizations: [],
    aleko: null,
  };

  const [state, dispatch] = useReducer(AlekoReducer, initialState);
  const [cantidad, setCantidad] = useState(0);
  const [inputColorValidation, setInputColorValidation] = useState(false);
  const [inputSizeValidation, setInputSizeValidation] = useState(false);
  const [inputPersonalizationValidation, setInputPersonalizationValidation] =
    useState(false);
  const id_bodega_sucursal = localStorage.getItem("id_sucursal");
  const id_new_empresa = window.localStorage.getItem("id_empresa");

  const [productColor, setProductColor] = useState({
    nombre_color: "",
    codigo_hexadecimal: "",
    // cantidad_color: 0,
    // precio_extra: 0,
  });
  const [productSize, setProductSize] = useState({
    nombre_talla: "",
    // cantidad_talla: 0,
    // precio_extra: 0,
  });

  const [productPersonalization, setProductPersonalization] = useState({
    nombre_personalizacion: "",
    precio_medida: 0,
    precio_minimo: 0,
  });
  const clearState = () => {
    dispatch({ type: CLEAR_ALEKO_STATE });
  };
  const getAleko = () => {
    const aleko = isAleko();

    if (aleko) {
      dispatch({ type: GET_ALEKO, payload: aleko });
    }
  };

  const createBase = async (id) => {
    if (state.alekoProduct.id_new_item) return;

    const { data } = await createBaseProduct({ id_linea_web_app: id });

    dispatch({
      type: CREATE_BASE_PRODUCT,
      payload: data.nuevoProductoPersonalizable,
    });
  };

  const deleteBase = async (id) => {
    await deleteAlekoProduct(id);
  };

  const clearProductColor = () => {
    setProductColor({
      nombre_color: "",
      codigo_hexadecimal: "",
      // cantidad_color: 0,
      // precio_extra: 0,
    });
    setInputColorValidation(false);
  };

  const saveProductColor = async (color) => {
    // if (!cantidad && !details) {
    //   toast.error("Campo inventario requerido", { autoClose: 10000 });
    //   setSelectedTab(0);
    //   return;
    // }

    const prevColor = state.colors.some(
      (item) => item.codigo_hexadecimal === color.codigo_hexadecimal
    );
    if (prevColor) {
      toast.warn("El color ya existe", { autoClose: 10000 });
      return;
    }

    if (color.nombre_color.trim() === "" || color.codigo_hexadecimal === "") {
      setInputColorValidation(true);
      return;
    }

    try {
      const color_data = new FormData();
      for (let key in color) {
        color_data.append(key, color[key]);
      }
      // color_data.append("id_new_item", state.alekoProduct.id_new_item);
      color_data.append("id_new_empresa", id_new_empresa);

      const { data } = await createProductColor(color_data);

      console.log("color creado ->", data);

      almacenarColores(data.data);

      setInputColorValidation(false);
      clearProductColor();
    } catch (error) {
      console.log("error al añadir color ->", error);
    }
  };

  const almacenarColores = (data) => {
    dispatch({
      type: SET_PRODUCT_COLOR,
      payload: data,
    });
  };

  const deleteProductColor = async (idempresa, idPersonalizacion) => {
    try {
      const res = await deleteColor(idempresa, idPersonalizacion);
      if (res.data.ok) {
        toast.success("Color eliminado", { autoClose: 10000 });
        dispatch({ type: DELETE_PRODUCT_COLOR, payload: idPersonalizacion });
      } else {
        toast.warning("Error al eliminar color", { autoClose: 10000 });
      }
    } catch (error) {
      console.log("error to delete color ->", error);
    }
  };

  const saveProductSize = async (size) => {
    // if (!cantidad) {
    //   toast.error("Campo inventario requerido", { autoClose: 10000 });
    //   return;
    // }
    const prevSize = state.sizes.some(
      (item) => item.nombre_talla === size.nombre_talla
    );
    if (prevSize) return;

    // if (size.nombre_talla.trim() === "" || !size.cantidad_talla) {
    //   setInputSizeValidation(true);
    //   return;
    // }

    const size_data = new FormData();
    for (let key in size) {
      size_data.append(key, size[key]);
    }
    size_data.append("id_new_item", state.alekoProduct.id_new_item);
    size_data.append("id_bodega_sucursal", id_bodega_sucursal);

    try {
      const { data } = await createProductSize(size_data);
      console.log("crear talla ->", data);

      almacenarSizes(data.data);

      clearProductSize();
      setInputSizeValidation(false);
    } catch (error) {
      console.log(error);
    }
  };

  const almacenarSizes = (data) => {
    dispatch({
      type: SET_PRODUCT_SIZE,
      payload: data,
    });
  };

  const deleteProductSize = async (idEmpresa, idPersonalizacion) => {
    try {
      const deleted = await deleteSize(idEmpresa, idPersonalizacion);

      console.log("deleted", deleted);

      if (!deleted.data.ok) {
        return toast.warning("Error en servidor al eliminar talla", {
          autoClose: 10000,
        });
      }

      if (deleted.data.ok) {
        toast.success("Talla eliminada exitosamente", { autoClose: 10000 });
      }

      dispatch({
        type: DELETE_PRODUCT_SIZE,
        payload: idPersonalizacion,
      });
    } catch (error) {
      console.log("error al eliminar talla ->", error);
    }
  };

  const saveProductPersonalization = async (personalization) => {
    // if (!cantidad) {
    //   toast.error("Campo inventario requerido", { autoClose: 10000 });
    //   return;
    // }
    const prevPersonalization = state.personalizations.some(
      (item) =>
        item.nombre_personalizacion === personalization.nombre_personalizacion
    );

    if (prevPersonalization) {
      return toast.warning("Tipo de personalizacion ya existente", {
        autoClose: 10000,
      });
    }

    if (personalization.nombre_personalizacion.trim() === "") {
      return toast.warning("Nombre de la personalizacion requerido", {
        autoClose: 10000,
      });
    }
    const personalization_data = new FormData();
    for (let key in personalization) {
      personalization_data.append(key, personalization[key]);
    }
    // personalization_data.append("id_new_item", state.alekoProduct.id_new_item);
    // personalization_data.append("id_bodega_sucursal", id_bodega_sucursal);

    const { data } = await createProductPersonalization(personalization_data);

    almacenarPersonalizacion(data.data);

    setInputPersonalizationValidation(false);
    clearProductPersonalization();
  };

  const almacenarPersonalizacion = (data) => {
    dispatch({
      type: SET_PRODUCT_PERSONALIZATION,
      payload: data,
    });
  };

  const deleteProductPersonalization = async (idEmpresa, idPersonalizaion) => {
    const deleted = await deletePersonalization(idEmpresa, idPersonalizaion);

    console.log("deleted ->", deleted);

    if (!deleted.data.ok) {
      return toast.warning("Error en servidor al eliminar personalización", {
        autoClose: 10000,
      });
    }

    if (deleted.data.ok) {
      toast.success("Personalización eliminada exitosamente", {
        autoClose: 10000,
      });
    }

    dispatch({
      type: DELETE_PRODUCT_PERSONALIZATION,
      payload: idPersonalizaion,
    });
  };

  const clearProductPersonalization = () => {
    setProductPersonalization({
      nombre_personalizacion: "",
      cantidad_personalizacion: 0,
      precio_extra: 0,
    });
    setInputPersonalizationValidation(false);
  };

  const clearProductSize = () => {
    setProductSize({ nombre_talla: "", cantidad_talla: "", precio_extra: 0 });
    setInputSizeValidation(false);
  };

  const createAlekoProduct = async (data, files, jq) => {
    if (files.includes(null)) {
      return toast.error("Debe agregar las respectivas imagenes", {
        autoClose: 10000,
      });
    }
    const colorQtyValidation = state.colors
      .map((item) => item.cantidad_color)
      .reduce((a, b) => a + b, 0);
    const sizeQtyValidation = state.sizes
      .map((item) => item.cantidad_talla)
      .reduce((a, b) => a + b, 0);
    const personalizationQtyValidation = state.personalizations
      .map((item) => item.cantidad_personalizacion)
      .reduce((a, b) => a + b, 0);
    if (colorQtyValidation > cantidad) {
      return toast.error(
        `La suma de las cantidades de colores debe ser igual a ${cantidad}`,
        { autoClose: 10000 }
      );
    }
    if (sizeQtyValidation > cantidad) {
      return toast.error(
        `La suma de las cantidades de tallas debe ser igual a ${cantidad}`,
        { autoClose: 10000 }
      );
    }
    if (personalizationQtyValidation > cantidad) {
      return toast.error(
        `La suma de las cantidades de personalizaciones debe ser igual a ${cantidad}`,
        { autoClose: 10000 }
      );
    }

    for (let key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }

    const form_data = new FormData();
    for (let key in data) {
      if (data[key]) {
        form_data.append(key, data[key]);
      }
    }

    if (data.vencimiento_fecha) {
      form_data.append("vencimiento", 1);
    } else {
      form_data.append("vencimiento", 0);
    }
    form_data.append("inventariado", 1);
    let count = 1;
    for (const file of files) {
      if (file) {
        let renamed_file = new File(
          [file],
          "$img_" + count.toString() + "$" + file.name,
          {
            type: file.type,
            lastModified: file.lastModified,
          }
        );
        form_data.append("images", renamed_file);
      }
      count++;
    }
    form_data.append("stock", cantidad);

    try {
      const { data } = await addAlekoProduct(form_data);
      if (data.succes) {
        setInputSizeValidation(false);
        setInputPersonalizationValidation(false);
        setInputColorValidation(false);
        dispatch({ type: CLEAR_ALEKO_STATE });
        console.log("modal");
        return jq.modal();
      } else {
        return toast.error(data.msg, { autoClose: 10000 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAleko = async (id, setAllProducts, allProducts) => {
    try {
      const { data } = await deleteAlekoProduct(id);
      if (data.success) {
        toast.success(data.msg, { autoClose: 10000 });
        setAllProducts(allProducts.filter((item) => item.id_new_item !== id));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const clearAlekoState = () => {
    dispatch({ type: CLEAR_ALEKO_STATE });
    setCantidad(0);
    setInputColorValidation(false);
    setInputSizeValidation(false);
    setInputPersonalizationValidation(false);
  };

  return (
    <AlekoContext.Provider
      value={{
        aleko: state.aleko,
        product: state.alekoProduct,
        colors: state.colors,
        sizes: state.sizes,
        personalizations: state.personalizations,
        getAleko,
        createBase,
        idAlekoProduct: state.alekoProduct.id_new_item,
        deleteBase,
        saveProductColor,
        deleteProductColor,
        productColor,
        almacenarColores,
        saveProductSize,
        setProductSize,
        setProductColor,
        clearProductColor,
        clearProductSize,
        productSize,
        almacenarSizes,
        deleteProductSize,
        saveProductPersonalization,
        setProductPersonalization,
        deleteProductPersonalization,
        clearProductPersonalization,
        productPersonalization,
        almacenarPersonalizacion,
        createAlekoProduct,
        clearAlekoState,
        setCantidad,
        clearState,
        cantidad,
        inputColorValidation,
        inputSizeValidation,
        inputPersonalizationValidation,
        deleteAleko,
        setInputColorValidation,
        setInputSizeValidation,
        setInputPersonalizationValidation,
      }}
    >
      {props.children}
    </AlekoContext.Provider>
  );
};

export { AlekoProvider };
