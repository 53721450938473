// eslint-disable-next-line
import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link, useHistory, useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { useForm } from "react-hook-form";
import API, { realImageURL } from "../../api";
import { toast } from "react-toastify";
import { base_url_images } from "../../api";
import { CSVLink } from "react-csv";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import DataTable from "react-data-table-component";

// styles
import "./custom.css";
import { makeStyles } from "@material-ui/core";
//icons
import InfoIcon from "@material-ui/icons/Info";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Insumos from "./InsumosDetalles";
import Merma_prodChild from "./Components/Merma";
import { isCmi } from "../../helpers/isCmi";
import { isAleko } from "../../helpers/isAleko";
import { getMoneda } from "../../helpers/moneda";
import MermaContext from "../../context/MermaContext/MermaContext";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";
import NuevoNivel from "./Components/NuevoNivel";
import LocalLeves from "./Components/LocalLeves";

// components

//data
let product_data = {
  id_new_item: "",
  id_empresa_new: "",
  nombre: "",
  descripcion: "",
  descuento_precio: 0,
  vendido_por: "",
  notificaciones_inventario: "",
  vencimiento_fecha: "",
  rapida_venta: "",
  codigo: "",
  imagen_1: "",
  imagen_2: "",
  imagen_3: "",
  imagen_4: "",
  imagen_5: "",
  id_linea_web_app: "",
  id_clasificacion1_web_app: "",
  id_unidad_medida_web_app: "",
  precio_venta: 0,
  stock: 0,
  notificacion_inventario: "",
  stock_minimo: 0,
  flag_vencimiento: "",
  costo_unitario: 0,
  existencia_inicial: "",
  id_clasificacion2_web_app: "",
  inventariado: "",
  id_distribuidor_web_app: "",
  id_contenido_web_app: "",
  usa_lote: "",
};

const useStyles = makeStyles((theme) => ({
  input: {
    color: "#000000",
  },
}));

const sortAlphabetically = (array, field) => {
  let sortedArray = array.sort(function (a, b) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
};

let sale_units = [
  {
    value: "15 Pack",
    nombre: "15 Pack",
  },
  {
    value: "6 Pack",
    nombre: "6 Pack",
  },
  {
    value: "Arroba",
    nombre: "Arroba",
  },
  {
    value: "Caja",
    nombre: "Caja",
  },
  {
    value: "Docena",
    nombre: "Docena",
  },
  {
    value: "Fardo",
    nombre: "Fardo",
  },
  {
    value: "Paquete",
    nombre: "Paquete",
  },
  {
    value: "Unidad",
    nombre: "Unidad",
  },
];

sale_units = sortAlphabetically(sale_units, "nombre");

const bills = [
  { flag_impuesto_honduras: "Exonerado", valor_impuesto_honduras: 0 },
  { flag_impuesto_honduras: "Exento", valor_impuesto_honduras: 0 },

  {
    flag_impuesto_honduras: "Gravado (15%)",
    valor_impuesto_honduras: 15,
  },
  {
    flag_impuesto_honduras: "Gravado (18%)",
    valor_impuesto_honduras: 18,
  },
];

export default function Users_all(props) {
  const moneda = getMoneda();
  let history = useHistory();
  const moment = require("moment");
  // states
  const [files, setFiles] = useState([null, null, null, null, null]);
  const [filesHijos, setFilesHijos] = useState([null]);
  const [precioVenta, setPrecioVenta] = useState(0);
  const modificarPorcionProducto = 1;
  const modificarPesoProbableProducto = 2;
  const [imageInputs, setImageInputs] = useState([
    null,
    null,
    null,
    null,
    null,
  ]);
  const [imageInputsHijos, setImageInputHijos] = useState([
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
    "/images/photo_main.png",
  ]);
  const [loader, setLoader] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { register, handleSubmit } = useForm({ shouldUnregister: false });
  const {
    register: registerhijos,
    handleSubmit: handleSubmithijos,
    reset: reset_productoshijos,
  } = useForm({ shouldUnregister: false });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset,
  } = useForm({ shouldUnregister: false });
  const {
    register: register_newPriceLevel,
    handleSubmit: handleSubmit_newPriceLevel,
    reset: reset_newPriceLevel,
  } = useForm({ shouldUnregister: false });
  const [selectedTab, setSelectedTab] = useState(0);
  const [product, setProduct] = useState(product_data);
  const [productExcelData, setProductExcelData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [empaques, setEmpaques] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [distribuidores, setDistribuidores] = useState([]);
  const [divisiones, setDivisiones] = useState([]);
  const [contenidos, setContenidos] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [discountPriceEnabled, setDiscountPriceEnabled] = useState(false);
  const [lowStockNotification, setLowStockNotification] = useState(false);
  const [expire_lote, setExpire_lote] = useState(false);
  const [IDPCheck, setIDPCheck] = useState(false);
  const [freePriceCheck, setFreePriceCheck] = useState(false);
  const [priceLevels, setPriceLevels] = useState([]);
  const [productPromotions, setProductPromotions] = useState([]);
  const [IDPdata, setIDPdata] = useState([]);
  const [productoCompuesto, setProductoCompuesto] = useState(false);
  let [productoHijos, setProductoHijos] = useState(false);
  let [productohijosData, setProductoHijosData] = useState([]);
  const [quantityProbabably, setquantityProbabably] = useState(0);

  const [validarStockHijos, setValidarStockHijos] = useState(false);

  const {
    niveles_precio,
    setNivelesPrecio,
    newPrecioVenta,
    setNewPrecioVenta,
    activarPrecioVentas,
    setActiovarPrecioVenta,
    savedNiveles,
    setSavedNiveles,
    createSubProd,
    setCreateSubProd,
    createNivel,
    setCreateNivel,
    newNivel,
    setNewNivel,
    nivelesLocales,
    setNivelesLocales,
    firstClick,
    setFirstClick,
    createPriceLevels,
    clearCmiState,
    getCurrentProductPriceLevels,
    listSubniveles,
    deleteCurrentLevel,
    storedLevels,
  } = useContext(CmiProductContext);
  const classes = useStyles();
  const { form, valorMerma, getMermaData, clearState, handleChange } =
    useContext(MermaContext);
  const params = useParams();
  const [parameters, setParameters] = useState(0);
  const [currentChild, setCurrentChild] = useState("");

  //price formater
  const [precioValue, setPrecioValue] = useState("");
  const [descuentoValue, setDescuentoValue] = useState("");

  const [costoValue, setCostoValue] = useState("");
  const [stock_minimo, setStock_minimo] = useState("");
  const [unidadVenta, setUnidadVenta] = useState("");
  const [descripcionCmi, setDescripcionCmi] = useState("");
  useEffect(() => {
    console.log("product ->", product);
    // setCostoValue(
    //   formaterPriceFunction(product.costo_unitario.toFixed(2) + "")
    // );
    setCostoValue(
      product?.costo_unitario ? product.costo_unitario.toFixed(2) : 0
    );
    setPrecioValue(formaterPriceFunction(product.precio_venta.toFixed(2) + ""));
    setDescuentoValue(formaterPriceFunction(product.descuento_precio + ""));
    setUnidadVenta(product.vendido_por);
  }, [product]);
  //
  const [precioNivel, setPrecioNivel] = useState("");
  const [precioPromo, setPrecioPromo] = useState("");
  const [pesoProbable, setPesoProbable] = useState("");
  const [porcionProducto, setPorcionProducto] = useState("");

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
      clearState();
      clearCmiState();
    };
  }, []);

  useEffect(() => {
    if (listSubniveles.length) {
      getCurrentProductPriceLevels(params.id);
    }
  }, [listSubniveles]);

  useEffect(() => {
    getProductAttributes();
    getProductohijos();
    getisProductFather();
  }, []);

  useEffect(() => {
    if (product) {
      getIDPdata();
      if (product.descuento_precio) {
        if (product.descuento_precio > 0) {
          setDiscountPriceEnabled(true);
          setActiovarPrecioVenta(true);
        }
      } else {
        setDiscountPriceEnabled(false);
        setActiovarPrecioVenta(false);
      }

      setStock_minimo(product.stock_minimo);
      if (product.notificacion_inventario) setLowStockNotification(true);
      if (product.idp) {
        //let datafilteredidp = IDPdata.filter(data=>)
        setIDPCheck(true);
      }
      if (product.precio_libre) setFreePriceCheck(true);
      if (product.id_new_item !== "") {
        setLoader(false);
      }
    }
  }, [product]);

  // set states

  //function
  const setImage = (e, img_index) => {
    let image = URL.createObjectURL(e.target.files[0]);
    let image_file = e.target.files[0];
    let newData = [...imageInputs];
    let newData_2 = [...files];
    newData[img_index] = image;
    newData_2[img_index] = image_file;
    setImageInputs(newData);
    setFiles(newData_2);
  };

  const setImageHijos = (e, img_index) => {
    let image = URL.createObjectURL(e.target.files[0]);
    let image_file = e.target.files[0];
    let newData = [...imageInputs];
    let newData_2 = [...files];
    newData[img_index] = image;
    newData_2[img_index] = image_file;
    setImageInputHijos(newData);
    setFilesHijos(newData_2);
  };

  const redirecttocreateIDP = () => {
    //console.log(window.location.origin)
    //window.location = window.location.origin + '/app/config'+'?addIDP=true'
    let noData = IDPdata.length <= 0;
    if (noData) {
      history.push({
        pathname: "/app/config",
        search: "?addIDP=true",
      });
    }
  };

  const getIDPdata = () => {
    API.userConfig
      .getIDP()
      .then((res) => {
        //console.log(res)
        if (res.status === 200) {
          let data = res.data.data;
          // console.log(data);
          setIDPdata(data);
        }
      })
      .catch((err) => {
        // console.log(err.response);
        toast.error("Ha ocurrido un error al obtener los datos", {
          autoClose: 10000,
        });
      });
  };

  useEffect(() => {
    getMermaData(params.id);
  }, []);

  useEffect(() => {
    // console.log("producto", product);
  }, [product]);

  useEffect(() => {
    if (!discountPriceEnabled) {
      setDescuentoValue(0);
    }
  }, [discountPriceEnabled]);

  const getProduct = () => {
    let id = props.match.params.id.toString();
    API.products
      .getById({ id_new_item: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          // console.log("Product obtained:", response.data);
          if (response.data.imagen_1 !== "") {
            response.data.imagen_1 = base_url_images + response.data.imagen_1;
          } else {
            response.data.imagen_1 = "/images/photo_main.png";
          }

          if (response.data.imagen_2 !== "") {
            response.data.imagen_2 = base_url_images + response.data.imagen_2;
          } else {
            response.data.imagen_2 = "/images/photo_main.png";
          }
          if (response.data.imagen_3 !== "") {
            response.data.imagen_3 = base_url_images + response.data.imagen_3;
          } else {
            response.data.imagen_3 = "/images/photo_main.png";
          }
          if (response.data.imagen_4 !== "") {
            response.data.imagen_4 = base_url_images + response.data.imagen_4;
          } else {
            response.data.imagen_4 = "/images/photo_main.png";
          }
          if (response.data.imagen_5 !== "") {
            response.data.imagen_5 = base_url_images + response.data.imagen_2;
          } else {
            response.data.imagen_5 = "/images/photo_main.png";
          }
          if (response.data.vencimiento_fecha) setExpire_lote(true);

          if (response.data.idp) setIDPCheck(true);

          if (response.data.precio_libre) setFreePriceCheck(true);
          console.log("Product ->", response.data);
          response.data.vencimiento_fecha = moment(
            response.data.vencimiento_fecha
          );
          setProduct(response.data);
          setDescuentoValue(
            formaterPriceFunction(response.data.descuento_precio + "")
          );
          setImpuesto({
            flag_impuesto_honduras: response.data.flag_impuesto_honduras,
            valor_impuesto_honduras: response.data.valor_impuesto_honduras,
          });

          setProductoCompuesto(response.data.flag_compuesto);
          setProductExcelData([
            {
              nombre: response.data.nombre,
              descripcion: response.data.descripcion,
              codigo: response.data.codigo,
              costo_unitario: response.data.costo_unitario,
              descuento_precio: response.data.descuento_precio,
              precio_venta: response.data.precio_venta,
              stock: response.data.stock,
              stock_minimo: response.data.stock_minimo,
              rapida_venta: response.data.rapida_venta,
            },
          ]);

          setPriceLevels(response.data.price_levels);
          setProductPromotions(response.data.promotions);
          setDescripcionCmi(response.data.descripcion);
          setValidarStockHijos(response.data.flag_stock_hijos);

          //getProductohijos()
        } else {
          toast.error(response.message, { autoClose: 10000 });
          setLoader(false);
        }
      })
      .catch((err) => {
        let response = err.response;
        // console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });

        setLoader(false);
      });
  };

  const getisProductFather = () => {
    let id = props.match.params.id.toString();
    productohijosData = [];
    API.products
      .getisProductFather(id)
      .then((res) => {
        let response = res.data;
        if (response.ok) {
          setCreateSubProd(response.padre_activo);
        } else {
          setCreateSubProd(false);
          toast.error("Ha ocurrido un error al obtener los datos", {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        // console.log(err.response);
        setCreateSubProd(false);
        toast.error("Ha ocurrido un error al obtener los datos", {
          autoClose: 10000,
        });
      });
  };

  const getProductohijos = () => {
    let id = props.match.params.id.toString();
    productohijosData = [];
    API.products
      .getProductsChild(id)
      .then((res) => {
        let response = res.data;
        // console.log(response);
        //console.log(res)
        if (response.ok) {
          let data = res.data.data;
          //console.log(data);
          productohijosData = data;
          setProductoHijosData(data);
        } else {
          productohijosData = [];
          toast.error("Ha ocurrido un error al obtener los datos", {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        // console.log(err.response);
        productohijosData = [];
        toast.error("Ha ocurrido un error al obtener los datos", {
          autoClose: 10000,
        });
      });
  };

  const onSubmitProductosHijos = (data) => {
    if (parseFloat(data.porcion_prod_child).toFixed(2) == 0) {
      toast.warn("La porción del producto hijo tiene que ser mayor a 0", {
        autoClose: 10000,
      });
      return;
    }

    if (parseFloat(data.peso_probable_prod_child).toFixed(2) == 0) {
      toast.warn("El peso probable del producto hijo tiene que ser mayor a 0", {
        autoClose: 10000,
      });
      return;
    }

    if (parseFloat(data.precio_producto_hijo).toFixed(2) == 0) {
      toast.warn("El precio del producto hijo tiene que ser mayor a 0", {
        autoClose: 10000,
      });
      return;
    }

    data.id_new_item = product.id_new_item;
    data.id_new_empresa = product.id_empresa_new;

    if (isNaN(data.peso_probable_prod_child)) {
      toast.warn("Peso Probable incorrecto");
      return;
    }
    //if (data.peso_probable_prod_child * quantityProbabably > product.stock) {
    if (quantityProbabably > product.stock) {
      toast.warn(
        "El producto hijo no puede tener mayor cantidad en libras que el padre",
        { autoClose: 10000 }
      );
      return;
    }
    API.products
      .createProductoshijos(data, filesHijos)
      .then((res) => {
        let response = res.data;
        if (response.ok) {
          reset_productoshijos();
          toast.success("Producto hijo creado", {
            autoClose: 10000,
          });
          setquantityProbabably(0);
          setProductoHijosData([]);
          setProductoHijosData(response.data);
          // console.log(response.data);
          productohijosData = response.data;
          //getProductohijos()
        } else {
          toast.error("Ha ocurrido un error", {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        if (response.data) {
          if (response.data.msg) {
            toast.error(response.data.msg, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", {
            autoClose: 10000,
          });
        }
        console.log("errrror", err);
        console.log("Ha ocurrido un error", response);
      });
  };

  const onSubmit = (data) => {
    console.log("hora-promocion", data);

    data.notificaciones_inventario = lowStockNotification ? 1 : 0;
    data.vendido_por = unidadVenta;
    data.stock_minimo = stock_minimo || product.stock_minimo;
    data.stock = product.stock;

    data.costo_unitario = !costoValue <= 0 ? costoValue.replaceAll(",", "") : 0;
    data.precio_venta = !precioValue <= 0 ? precioValue.replaceAll(",", "") : 0;

    data.descuento_precio =
      !descuentoValue <= 0 ? descuentoValue.replaceAll(",", "") : 0;

    if (data.costo_unitario == 0 && !isCmi()) {
      toast.error("El costo del producto no puede ser 0", {
        autoClose: 10000,
      });
      return;
    }

    setSubmitLoader(true);
    let validForm = true;

    if (descuentoValue) {
      if (discountPriceEnabled) {
        if (descuentoValue <= 0) {
          setSubmitLoader(false);
          validForm = false;
          toast.error("El precio de descuento no puede ser 0", {
            autoClose: 10000,
          });
        }

        if (
          data.descuento_precio &&
          parseFloat(data.descuento_precio) > parseFloat(data.precio_venta)
        ) {
          validForm = false;
          setSubmitLoader(false);
          toast.error(
            "El precio de descuento no puede ser mayor que el precio de venta.",
            {
              autoClose: 10000,
            }
          );
        }
      }
    }

    if (
      parseFloat(data.precio_venta) < parseFloat(data.costo_unitario) &&
      !product.id_padre
    ) {
      validForm = false;
      toast.error(
        "El costo del producto no puede ser mayor que el precio de venta.",
        {
          autoClose: 10000,
        }
      );
    }
    data.idp = IDPCheck ? 1 : 0;
    data.flag_compuesto = productoCompuesto ? 1 : 0;
    data.precio_libre = freePriceCheck ? 1 : 0;
    data.precio_venta = data.precio_venta;
    // if (precioVenta) {
    //   data.precio_venta = precioVenta;
    // }

    if (data.descuento_precio) {
      data.descuento_precio = parseFloat(data.descuento_precio);
      data.descripcion = descripcionCmi;
    } else {
      delete data.descuento_precio;
    }

    data.costo_unitario = parseFloat(data.costo_unitario);
    if (isCmi()) {
      data.merma = form.merma ? 1 : 0;
      data.cantidad_merma = form.cantidad_merma;
      data.medida_merma = form.medida_merma;
      data.valor_merma = valorMerma;

      data.stock = data.cantidad_unidades;
      data.validarStockHijos = validarStockHijos;
    }

    if (isCmi() && moneda === "L") {
      data = { ...data, ...impuesto };
    }
    // console.log("hora-promocion", data);
    if (validForm) {
      API.products
        .updateProduct(parseInt(product.id_new_item), data, files)
        .then((res) => {
          createPriceLevels(product.id_new_item);
          let response = res.data;
          if (response.success) {
            $("#save").modal();
          } else {
            toast.warning(response.message, {
              autoClose: 10000,
            });
          }
          setSubmitLoader(false);
        })
        .catch((err) => {
          let response = err.response;
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
          console.log("errrror", err);
          console.log("Ha ocurrido un error", response);
          setSubmitLoader(false);
        });
    }
  };

  const getProductAttributes = async () => {
    API.products
      .getProductAttributes({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCategories(response.data.categories);
          setEmpaques(response.data.empaques);
          setMarcas(response.data.marcas);
          setDistribuidores(response.data.distributors);

          setContenidos(response.data.contenidos);
          setFamilias(response.data.familias);
          getProduct();
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
    try {
      const { data } = await API.userConfig.getSucursals(true);
      setDivisiones(data.data);
    } catch (error) {}
  };

  const handleDiscountCheck = () => {
    setDiscountPriceEnabled(!discountPriceEnabled);
  };
  const handleIDPCheck = () => {
    setIDPCheck(!IDPCheck);
  };
  const handleFreePriceCheck = () => {
    setFreePriceCheck(!freePriceCheck);
  };
  const handleLowStockNotification = () => {
    setLowStockNotification(!lowStockNotification);
  };

  const deleteImageHijos = (img_index) => {
    let newData = [...imageInputsHijos];
    newData[img_index] = "/images/photo_main.png";
    setImageInputHijos(newData);
  };

  const lote_expiredDate = () => {
    let data = product;
    if (!expire_lote) {
      data.vencimiento_fecha = null;
      data.lote = null;
      data.flag_vencimiento = false;
    } else {
      data.flag_vencimiento = true;
      data.vencimiento_fecha = moment();
    }
    setProduct(data);
    setExpire_lote(!expire_lote);
  };

  const deleteImage = (img_index) => {
    let newData = [...imageInputs];
    newData[img_index] = "/images/photo_main.png";
    setImageInputs(newData);
  };

  const handleTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const updatePriceLevel = (index) => {
    API.priceLevels
      .updateOrCreate({
        id_new_item: product.id_new_item,
        nivel_precio: priceLevels[index].nivel_precio.id_nivel_precio,
        precio: parseFloat(priceLevels[index].precio),
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const updatePriceArray = (event, index) => {
    let parsedValue =
      event.target.value < 0
        ? (event.target.value = -event.target.value)
        : event.target.value;
    let existingPriceLevels = [...priceLevels];
    existingPriceLevels[index].precio = formaterPriceFunction(
      event.target.value
    );
    setPriceLevels(existingPriceLevels);
  };

  const createPromotion = (data) => {
    if (data.fecha_final < data.fecha_inicio) {
      toast.error(
        "La fecha final no puede estar antes de la fecha de inicio.",
        { autoClose: 10000 }
      );
      return;
    }
    let form_data = {
      fecha_inicio: new Date(data.fecha_inicio),
      fecha_final: new Date(data.fecha_final),
      id_new_item: product.id_new_item,
      precio: parseFloat(data.precio.replaceAll(",", "")),
      descripcion: data.descripcion,
    };
    API.products
      .createPromo(form_data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
          getProduct();
          reset();
        } else {
          toast.error(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const deletePromotion = (id) => {
    let form_data = {
      id_new_item: product.id_new_item,
      id_precio_vigencia: parseInt(id),
    };
    API.products
      .deletePromo(form_data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
          getProduct();
        } else {
          toast.error(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const updatePromotion = (element, event, type) => {
    let existingPromos = [...productPromotions];
    let index = existingPromos.findIndex(
      (x) => x.id_precio_vigencia === element.id_precio_vigencia
    );
    if (type === "fecha_inicio") {
      existingPromos[index].fecha_inicio = event.target.value;
    }
    if (type === "fecha_final") {
      existingPromos[index].fecha_final = event.target.value;
    }
    if (type === "precio") {
      let parsedValue = isNaN(parseFloat(event.target.value))
        ? 0
        : Math.abs(parseFloat(event.target.value));
      event.target.value = isNaN(parseFloat(event.target.value))
        ? ""
        : event.target.value;
      existingPromos[index].precio = parsedValue;
    }

    setProductPromotions(existingPromos);
  };

  const changeQuantityprobably = (event, identificadorCambio) => {
    setquantityProbabably(
      identificadorCambio === modificarPorcionProducto
        ? product.stock_libras *
            pesoProbable *
            parseFloat(event.target.value === "" ? 0 : event.target.value)
        : product.stock_libras *
            porcionProducto *
            parseFloat(event.target.value === "" ? 0 : event.target.value)
    );
  };

  const updatePromotionAPI = (id) => {
    let existingPromos = [...productPromotions];
    let index = existingPromos.findIndex((x) => x.id_precio_vigencia === id);
    const currentPromo = existingPromos[index];
    if (currentPromo.precio === 0) {
      toast.error("El precio de la promoción no puede ser 0", {
        autoClose: 10000,
      });
      return;
    }
    let data = {
      id_new_item: product.id_new_item,
      precio: currentPromo.precio,
      fecha_final: new Date(currentPromo.fecha_final),
      fecha_inicio: new Date(currentPromo.fecha_inicio),
      descripcion: currentPromo.descripcion,
      id_precio_vigencia: currentPromo.id_precio_vigencia,
    };
    API.products
      .updatePromo(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
        } else {
          toast.error(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
    getProduct();
  };

  const deleteProductHijos = (id) => {
    API.products
      .deleteProductsChild(id)
      .then((res) => {
        let response = res.data;
        //console.log(res)
        if (response.ok) {
          setProductoHijosData([]);
          setProductoHijosData(response.data);
          toast.success("Producto hijo Eliminado", { autoClose: 10000 });
        } else {
          toast.error("Ha ocurrido un error", {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const createPriceLevel = (data) => {
    console.log("Data arriving", data);
    if (parseFloat(data.precio) <= 0) {
      toast.error("El precio debe ser mayor a 0.", { autoClose: 10000 });
    }
    let form_data = {
      id_new_item: product.id_new_item,
      nivel_precio: data.id_nivel_precio,
      precio: parseFloat(data.precio.replaceAll(",", "")),
    };
    API.priceLevels
      .updateOrCreate(form_data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
          getProduct();
          reset_newPriceLevel();
        } else {
          toast.error(response.message, { autoClose: 10000 });
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        if (err.response) {
          if (err.response.data.message) {
            toast.error(err.response.data.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        }
        console.log("Ha ocurrido un error");
      });
  };
  const handleNewNivel = (event) => {
    const { name, value } = event.target;
    setNewNivel({
      ...newNivel,
      [name]: value,
    });
  };
  const obtenerNivelesLocalStorage = () =>
    JSON.parse(window.localStorage.getItem("newNivel"));

  const deleteNivelesLocalStorage = (id) => {
    let guardado = obtenerNivelesLocalStorage();
    deleteCurrentLevel(id);
    if (guardado != null) {
      let eliminado = guardado.filter((item) => item.id != id);
      window.localStorage.setItem("newNivel", JSON.stringify(eliminado));
      setNivelesLocales(eliminado);
    } else {
      setNivelesLocales([]);
    }
  };
  const savedNivel = () => {
    let valid = true;
    setCreateNivel(true);
    setFirstClick(false);
    const user_tipe = listSubniveles?.find(
      (item) => item.id === +newNivel.user_tipe
    )?.subnivel;
    if (!firstClick) {
      if (newNivel.user_tipe == "none") {
        toast.error("Debe escoger un tipo de usuario", { autoClose: 10000 });
        valid = false;
      }

      if (newNivel.unid_venta == "none") {
        toast.error("Debe escoger una unidad de venta", { autoClose: 10000 });
        valid = false;
      }

      if (newNivel.precio_venta <= 0) {
        toast.error("Debe escoger un precio de producto", { autoClose: 10000 });
        valid = false;
      }

      if (
        nivelesLocales?.some(
          (x) =>
            x.unid_venta === newNivel.unid_venta && x.user_tipe === user_tipe
        )
      ) {
        toast.error("Ya existe un nivel de precio con esas características", {
          autoClose: 10000,
        });
        valid = false;
      }
    }

    if (valid && !firstClick) {
      // agregar newNivel al data para enviar
      console.log(newNivel);
      let guardado = obtenerNivelesLocalStorage();
      newNivel.id_nivel = newNivel.user_tipe;

      newNivel.user_tipe = user_tipe;
      if (guardado == null) {
        newNivel.id = 1;
        guardado = [newNivel];
      } else {
        newNivel.id = guardado.length + 1;
        guardado = [...guardado, newNivel];
      }
      window.localStorage.setItem("newNivel", JSON.stringify(guardado));
      toast.success("Guardado exitosamente", { autoClose: 10000 });
      setCreateNivel(false);
      setNewNivel({
        user_tipe: "none",
        unid_venta: "none",
        precio_venta: 0,
      });
      setNivelesLocales(guardado);
      setFirstClick(true);
    }
  };
  const handleChageTiposPrecios = (event) => {
    const { name, value } = event.target;
    window.localStorage.setItem(name, value);
    setNivelesPrecio(value);
  };

  useEffect(() => {
    if (product.precio_venta) {
      setNivelesPrecio("precio_unico");
    }
    if (storedLevels.length) {
      setNivelesPrecio("niveles_precio");
    }
  }, [product, storedLevels]);

  useEffect(() => {}, []);

  const handleChangePrecioUnico = (event) => {
    const { name, value } = event.target;
    setNewPrecioVenta({
      ...newPrecioVenta,
      [name]: +value,
    });
  };

  const getNivelesCliente = async () => {
    try {
      const response = await API.userConfig.getNiveles(parameters);

      setSavedNiveles(response.data.costumer);
    } catch (error) {
      console.log("Error al buscar niveles", error);
      toast.error("Error al buscar niveles", { autoClose: 10000 });
    }
  };

  const getParameters = async () => {
    let respuesta = await API.userConfig.get_parameters();
    let response = await respuesta.data;

    if (response.success) {
      if (response.data) setParameters(response.data.id_new_empresa);
    } else {
      if (response.message) {
        toast.error(response.message, { autoClose: 10000 });
      } else {
        toast.warning("Ha ocurrido un error al obtener los parametros", {
          autoClose: 10000,
        });
      }
    }
  };

  useEffect(() => {
    getParameters();
    window.localStorage.removeItem("newNivel");
  }, []);
  useEffect(() => {
    if (parameters > 0) {
      getNivelesCliente();
    }
  }, [parameters]);

  const [impuesto, setImpuesto] = useState(bills[0]);
  const handleImpuesto = (e) => {
    const currentBill = bills.find(
      (item) => item.flag_impuesto_honduras === e.target.value
    );
    setImpuesto(currentBill);
  };
  const handleUpdateChild = async (prodData) => {
    const { data, peso_probable_prod_child, porcion_prod_child, id_new_item } =
      prodData;

    try {
      if (
        peso_probable_prod_child *
          (product.stock_libras * porcion_prod_child).toFixed(2) >
        product.stock
      ) {
        toast.warn(
          "El producto hijo no puede tener mayor cantidad en libras que el padre",
          { autoClose: 10000 }
        );

        return;
      }

      const { data } = await API.products.editarHijo(prodData);

      console.log("precio probable actualizado ->", data);

      if (data.success || data.ok) {
        toast.success(data.msg, { autoClose: 10000 });
      } else {
        toast.warn(data.msg, { autoClose: 10000 });
      }

      getProductohijos();

      setCurrentChild("");
      setPesoProbable("");
    } catch (error) {
      console.log("error al actualizar precio probable ->", error);
      setCurrentChild("");
      setPesoProbable("");
    }
  };

  return (
    <div id="dashboard-section">
      <div className="modal fade" id="confirmExit" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div className="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="save" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  {selectedTab === 2 || selectedTab === 1 ? (
                    <strong>Se han actualizado las mermas del producto</strong>
                  ) : (
                    <strong>
                      Su producto se ha <br />
                      guardado con éxito
                    </strong>
                  )}
                </h4>
              </div>

              <div className="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <Button
          className="btn-cs1 mx-1 mt-1"
          variant="contained"
          color="primary"
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon className="mr-1" style={{ fontSize: "18px" }} />
          Volver
        </Button>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <Tabs
              className="mt-2"
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTab}
            >
              <Tab className="tab" label="General" />

              {isCmi() === false || isAleko() === false ? (
                <Tab className="tab" label="Niveles de precio" />
              ) : null}

              {isCmi() === false || isAleko() === false ? (
                <Tab className="tab" label="Promociones" />
              ) : null}

              {isCmi() && !product.id_padre && unidadVenta == "mixto"
                ? !product.combo && <Tab className="tab" label="Prod. Hijos" />
                : ""}
              {!isCmi() && !isAleko() ? (
                <Tab value={6} className="tab" label="Insumos" />
              ) : (
                ""
              )}
              {isCmi() && <Tab value={7} className="tab" label="Mermas" />}
            </Tabs>
          </div>
        </div>
        {loader ? (
          <div className="text-center">
            <CircularProgress size={26} className="mx-auto" />
          </div>
        ) : (
          <TabPanel value={selectedTab} index={0}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="on"
              encType="multipart/form-data"
            >
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <h5 className="mb-0">
                    <strong>Detalles del producto</strong>
                  </h5>
                  <p className="mb-0">
                    Información detallada acerca del producto.
                  </p>
                </div>

                <div>
                  {productExcelData.length > 0 ? (
                    <CSVLink
                      data={productExcelData}
                      filename={productExcelData[0].nombre + ".csv"}
                    >
                      <Button
                        type="button"
                        className="btn-cs1 brand2-btn mt-1 mx-1"
                        variant="contained"
                        color="primary"
                      >
                        <CloudDownloadIcon className="mr-2" />
                        Descargar Excel
                      </Button>
                    </CSVLink>
                  ) : (
                    <Button
                      type="button"
                      className="btn-cs1 brand2-btn mt-1 mx-1"
                      variant="contained"
                      color="primary"
                    >
                      <CloudDownloadIcon className="mr-2" />
                      Descargar Excel
                    </Button>
                  )}
                  <Button
                    className="btn-cs1 mt-1 mx-1"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {submitLoader ? (
                      <div className="text-center">
                        <CircularProgress
                          size={26}
                          className="mx-auto"
                          style={{ color: "white" }}
                        />
                      </div>
                    ) : (
                      "Actualizar"
                    )}
                  </Button>
                </div>
              </div>
              <div className="row p-3">
                <div className="col-md-4 mt-3 ">
                  <div>
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Nombre del producto</strong>
                    </label>

                    <TextField
                      defaultValue={product.nombre}
                      inputRef={register}
                      name="nombre"
                      type="text"
                      className="w-100"
                      placeholder="Ingrese el nombre de su producto"
                    />
                  </div>
                </div>
                {!isCmi() && (
                  <div className="col-md-4 mt-3 ">
                    <div>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Precio de venta</strong>
                      </label>
                      <TextField
                        value={precioValue}
                        inputRef={register}
                        name="precio_venta"
                        type="text"
                        className="w-100"
                        onChange={(event) => {
                          const t = event.target.value;
                          event.target.value =
                            t.indexOf(".") >= 0
                              ? t.substr(0, t.indexOf(".")) +
                                t.substr(t.indexOf("."), 3)
                              : t;
                          setPrecioVenta(parseFloat(event.target.value));

                          setPrecioValue(
                            formaterPriceFunction(event.target.value)
                          );
                        }}
                        placeholder="Ingrese el precio de venta de su producto"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {moneda}.
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                )}
                {!isCmi() && (
                  <div className="col-md-4 mt-3 ">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Descripción de producto (Opcional)</strong>
                    </label>
                    <TextField
                      defaultValue={product.descripcion}
                      name="descripcion"
                      inputRef={register}
                      type="text"
                      multiline
                      className="w-100"
                      placeholder="Ingrese una breve descripción del producto."
                    />
                  </div>
                )}

                {/* Second Row */}
                {!isCmi() && !isAleko() && (
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Precio con descuento</strong>
                        </label>
                        <Tooltip title="Precio con descuento" className="mx-1">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>
                      <div className="custom-control custom-switch">
                        <input
                          inputRef={register}
                          type="checkbox"
                          className="custom-control-input"
                          name="descuento_toggle"
                          checked={discountPriceEnabled}
                          onClick={handleDiscountCheck}
                          id={`customSwitchPrice`}
                        />
                        <label
                          className="custom-control-label"
                          for={`customSwitchPrice`}
                        />
                      </div>
                    </div>
                    <TextField
                      value={descuentoValue}
                      inputRef={register}
                      type="text"
                      name="descuento_precio"
                      className="w-100"
                      disabled={!discountPriceEnabled}
                      placeholder="Ingrese el precio promocional del producto"
                      onChange={(event) => {
                        setDescuentoValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small>
                      El precio con descuento sustituirá el precio original de
                      venta, puede activar y desactivar esta opción en cualquier
                      momento.
                    </small>
                  </div>
                )}

                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Código del producto</strong>
                    </label>
                    <Tooltip title="Código del producto">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    defaultValue={product.codigo}
                    inputRef={register}
                    type="text"
                    name="codigo"
                    className="w-100"
                    placeholder="Ingrese el SKU / código de barras del producto"
                  />
                </div>

                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>
                        {!isCmi() ? "Unidad de venta" : "Vendido por"}
                      </strong>
                    </label>
                    <Tooltip
                      title={!isCmi() ? "Unidad de venta" : "Vendido por"}
                    >
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select
                      inputRef={register}
                      onChange={(e) => {
                        setUnidadVenta(e.target.value);
                        if (isCmi()) {
                          toast.warning(
                            "Se cambio la unidad de venta actualiza tu inventario"
                          );
                        }
                      }}
                      native
                      name="vendido_por"
                      disabled
                      defaultValue={product.vendido_por}
                      className="cs-select1 select-w100"
                    >
                      {!isCmi() &&
                        sale_units.map((unit) => (
                          <option value={unit.value}>{unit.nombre}</option>
                        ))}
                      {isCmi() && (
                        <>
                          <option value="">
                            Seleccionar la unidad de venta
                          </option>
                          <option value="libras">Libras</option>
                          <option value="unidades">Unidades</option>
                          <option value="mixto">Mixto</option>
                        </>
                      )}
                    </Select>
                  </FormControl>
                </div>

                {isCmi() && (
                  <div className="col-md-4 mt-3 ">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Código SAP</strong>
                    </label>
                    <div className="form-group">
                      <textarea
                        style={{ resize: "none", backgroundColor: "#f2f2f2" }}
                        class="form-control"
                        defaultValue={product.descripcion}
                        onChange={(e) => setDescripcionCmi(e.target.value)}
                        name="descripcion"
                        inputRef={register}
                        placeholder="Ingrese el Código SAP ejemplo: 29000430"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                )}

                {isCmi() && (
                  <div className="col-md-8 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Tipo de precio</strong>
                    </label>
                    <div className="w-100 h-75 mx-auto row justify-content-around align-items-center">
                      <div
                        className={`tipos_precios ${
                          niveles_precio == "precio_unico"
                            ? "tipos_precios_active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="nivel_precio"
                          inputRef={register}
                          id="precio_unico"
                          value="precio_unico"
                          checked={niveles_precio === "precio_unico"}
                          onChange={(e) => handleChageTiposPrecios(e)}
                        />
                        <label htmlFor="precio_unico">Precio único</label>
                      </div>
                      <div
                        className={`tipos_precios ${
                          niveles_precio == "niveles_precio"
                            ? "tipos_precios_active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="nivel_precio"
                          id="niveles_precio"
                          inputRef={register}
                          value="niveles_precio"
                          checked={niveles_precio === "niveles_precio"}
                          onChange={(e) => handleChageTiposPrecios(e)}
                        />
                        <label htmlFor="niveles_precio">
                          Niveles de precio
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                {niveles_precio.length > 0 && (
                  <div className="col-md-12 row justify-content-end">
                    {niveles_precio == "precio_unico" ? (
                      <>
                        <div className="col-md-2"></div>
                        <div className="col-md-6 row">
                          <div className="col-md-6">
                            <label style={{ fontSize: "0.8rem" }}>
                              <strong>Precio de venta</strong>
                            </label>
                            <TextField
                              value={precioValue}
                              type="text"
                              required
                              className="w-100"
                              name="precio_venta"
                              inputRef={register}
                              onChange={
                                (event) => {
                                  const t = event.target.value;
                                  event.target.value =
                                    t.indexOf(".") >= 0
                                      ? t.substr(0, t.indexOf(".")) +
                                        t.substr(t.indexOf("."), 3)
                                      : t;
                                  handleChange(event);

                                  setPrecioValue(
                                    formaterPriceFunction(event.target.value)
                                  );
                                  return handleChangePrecioUnico(event);
                                }

                                // event.target.value < 0
                                //     ? (event.target.value = -event
                                //         .target.value)
                                //     : event.target.value
                              }
                              defaultValue={(
                                product?.precio_venta + product.impuesto_idp ||
                                0
                              ).toFixed(2)}
                              placeholder="Ingrese el precio de venta del producto"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {moneda}.
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, step: "0.00001" },
                              }}
                            />
                          </div>
                          {isCmi() ? (
                            <div className="col-md-6">
                              <div className="row justify-content-around">
                                <label style={{ fontSize: "0.8rem" }}>
                                  <strong>Precio con descuento</strong>
                                </label>
                                <Tooltip
                                  title="Precio con descuento"
                                  className="mx-1"
                                >
                                  <InfoIcon
                                    style={{
                                      color: " #828282",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              <TextField
                                type="text"
                                required
                                className="w-100"
                                value={descuentoValue}
                                disabled={!activarPrecioVentas ? true : false}
                                inputRef={register}
                                name="descuento_precio"
                                onChange={
                                  (event) => {
                                    handleChange(event);
                                    handleChangePrecioUnico(event);

                                    setDescuentoValue(
                                      formaterPriceFunction(event.target.value)
                                    );
                                  }

                                  // event.target.value < 0
                                  //     ? (event.target.value = -event
                                  //         .target.value)
                                  //     : event.target.value
                                }
                                placeholder="Ingrese el precio promocional"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {moneda}.
                                    </InputAdornment>
                                  ),
                                  inputProps: { min: 0, step: "0.00001" },
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                        {isCmi() ? (
                          <div className="d-flex align-items-center justify-content-around col-md-2">
                            <Button
                              style={{
                                width: "50%",
                                height: "50%",
                              }}
                              onClick={(e) => {
                                setActiovarPrecioVenta(false);
                                setDescuentoValue(0);
                              }}
                              variant="contained"
                              size="small"
                              color={
                                activarPrecioVentas == false ? "primary" : ""
                              }
                            >
                              No
                            </Button>
                            <Button
                              style={{
                                width: "50%",
                                height: "50%",
                              }}
                              onClick={(e) => setActiovarPrecioVenta(true)}
                              variant="contained"
                              size="small"
                              color={
                                activarPrecioVentas == false ? "" : "primary"
                              }
                            >
                              Sí
                            </Button>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      ""
                    )}
                    {niveles_precio == "niveles_precio" ? (
                      <div className="col-md-12 row">
                        <div className="col-md-3 col-sm-2 d-flex justify-content-center align-items-center">
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={savedNivel}
                            size="small"
                            style={{
                              background: !createNivel ? "" : "#4cd137",
                            }}
                          >
                            {!createNivel ? "Nuevo Nivel" : "Guardar nivel"}
                          </Button>
                        </div>
                        <div className="col-md-9">
                          {createNivel && (
                            <NuevoNivel
                              setFirstClick={setFirstClick}
                              newNivel={newNivel}
                              setNewNivel={setNewNivel}
                              setCreateNivel={setCreateNivel}
                              handleNewNivel={handleNewNivel}
                              savedNiveles={savedNiveles}
                              id_padre={product.id_padre}
                            />
                          )}
                          {nivelesLocales &&
                            nivelesLocales.map((item) => (
                              <LocalLeves
                                key={item.id}
                                id={item.id}
                                deleteNivelesLocalStorage={
                                  deleteNivelesLocalStorage
                                }
                                precio={item.precio_venta}
                                unidad_venta={item.unid_venta}
                                userType={item.user_tipe}
                              />
                            ))}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {!isCmi() && (
                  <div className="col-md-4 mt-3">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>IDP</strong>
                    </label>

                    <label
                      class="toggle-control mx-auto"
                      style={{ left: "-15px", height: "40px" }}
                    >
                      <input
                        type="checkbox"
                        name="idp"
                        checked={IDPCheck}
                        onClick={handleIDPCheck}
                      />
                      <span class="control" />
                    </label>
                  </div>
                )}
                <div className="col-md-4 mt-3">
                  {!isCmi() && (
                    <>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Precio libre</strong>
                      </label>

                      <label
                        class="toggle-control mx-auto"
                        style={{ left: "-15px", height: "40px" }}
                      >
                        <input
                          type="checkbox"
                          name="precio_libre"
                          checked={freePriceCheck}
                          onClick={handleFreePriceCheck}
                        />
                        <span class="control" />
                      </label>
                    </>
                  )}
                </div>
                <div className="col-md-4 mt-3" />

                {/*  Third row */}

                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Categoría del producto</strong>
                    </label>
                    <Tooltip title="Categoría del producto">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select
                      inputRef={register}
                      native
                      name="id_clasificacion1_web_app"
                      className="cs-select1 select-w100"
                      defaultValue={product.id_clasificacion1_web_app}
                    >
                      <option value="">
                        Seleccione la categoría del producto
                      </option>
                      {categories.map((category) => (
                        <option value={category.id_clasificacion1_web_app}>
                          {category.descripcion}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Empaque del producto</strong>
                    </label>
                    <Tooltip title="Empaque del producto">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select
                      inputRef={register}
                      native
                      defaultValue={product.id_unidad_medida_web_app}
                      name="id_unidad_medida_web_app"
                      className="cs-select1 select-w100"
                    >
                      <option value="">Seleccione el empaque</option>
                      {empaques.map((empaque) => (
                        <option value={empaque.id_unidad_medida_web_app}>
                          {empaque.descripcion}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Marca del producto</strong>
                    </label>
                    <Tooltip title="Marca del producto">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select
                      inputRef={register}
                      native
                      name="id_clasificacion2_web_app"
                      className="cs-select1 select-w100"
                      defaultValue={product.id_clasificacion2_web_app}
                    >
                      <option value="">Seleccione la marca</option>
                      {marcas.map((marca) => (
                        <option value={marca.id_clasificacion2_web_app}>
                          {marca.descripcion}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                {/* Fourth row */}

                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Proveedor del producto</strong>
                    </label>
                    <Tooltip title="Distribuidor  del producto">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select
                      inputRef={register}
                      native
                      name="id_distribuidor_web_app"
                      defaultValue={product.id_distribuidor_web_app}
                      className="cs-select1 select-w100"
                    >
                      <option value="">Seleccione el distribuidor</option>
                      {distribuidores.map((distribuidor) => (
                        <option value={distribuidor.id_distribuidor_web_app}>
                          {distribuidor.descripcion}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>División del producto</strong>
                    </label>
                    <Tooltip title="División del producto">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select
                      inputRef={register}
                      native
                      name="id_linea_web_app"
                      className="cs-select1 select-w100"
                      defaultValue={
                        product.id_linea_web_app ||
                        divisiones[0].id_sucursal_bodega_ubicacion
                      }
                    >
                      <option value="">Seleccione la división</option>
                      {divisiones.map((division) => (
                        <option value={division.id_sucursal_bodega_ubicacion}>
                          {division.nombre_sucursal}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Contenido del producto</strong>
                    </label>
                    <Tooltip title="Contenido del producto">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select
                      inputRef={register}
                      native
                      name="id_contenido_web_app"
                      defaultValue={product.id_contenido_web_app}
                      className="cs-select1 select-w100"
                    >
                      <option value="">Seleccione el contenido</option>
                      {contenidos.map((contenido) => (
                        <option value={contenido.id_contenido_web_app}>
                          {contenido.descripcion}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Familia del producto</strong>
                    </label>
                    <Tooltip title="Seleccione la familia del producto. Ejemplo: Comidas">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <FormControl variant="outlined" className="w-100">
                    <Select
                      inputRef={register}
                      native
                      required
                      name="id_familia_web_app"
                      className="cs-select1 select-w100"
                      defaultValue={product.id_familia_web_app}
                    >
                      <option value="">Seleccione la familia</option>
                      {familias.map((familia) => (
                        <option value={familia.id_familia_web_app}>
                          {familia.descripcion}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {isCmi() && moneda === "L" && (
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Impuesto del producto</strong>
                      </label>
                      <Tooltip title="Seleccione el impuesto del producto. Ejemplo: Exonerado">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>

                    <FormControl variant="outlined" className="w-100">
                      <Select
                        required
                        native
                        className="cs-select1 select-w100"
                        value={impuesto.flag_impuesto_honduras}
                        onChange={handleImpuesto}
                      >
                        {bills.map((bill) => (
                          <option value={bill.flag_impuesto_honduras}>
                            {bill.flag_impuesto_honduras}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                {IDPCheck ? (
                  <div className="col-md-4 mt-3" onClick={redirecttocreateIDP}>
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Impuesto IDP</strong>
                      </label>
                    </div>

                    <FormControl variant="outlined" className="w-100">
                      <Select
                        inputRef={register}
                        native
                        required
                        name="id_producto_idp"
                        className="cs-select1 select-w100"
                        defaultValue={product.idImpuestoIDP}
                      >
                        <option value="">Seleccione el IDP</option>
                        {IDPdata.map((data) => (
                          <option value={data.id_producto_idp}>
                            {data.nombre}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : null}

                <div className="col-md-8 mt-3" />

                {/* Fifth row */}
                <div className="col-md-6 mt-3">
                  <div className="d-flex">
                    <div className="d-flex" style={{ flex: "1" }}>
                      <div
                        className="w-100"
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(0)}
                        />
                        <img
                          src={imageInputs[0] || product.imagen_1}
                          className="add_image_bck"
                          alt="img-input"
                          style={{ height: "350px" }}
                        />
                        <input
                          accept="image/*"
                          className="add_image_btn"
                          type="file"
                          onChange={(event) => setImage(event, 0)}
                        />
                      </div>
                    </div>
                    <div style={{ flex: "1" }}>
                      <div className="d-flex">
                        <div
                          style={{
                            position: "relative",
                            flex: "1",
                            textAlign: "center",
                          }}
                        >
                          <HighlightOffIcon
                            className="delete-image-icon"
                            onClick={() => deleteImage(1)}
                          />
                          <img
                            src={imageInputs[1] || product.imagen_2}
                            className="add_image_bck"
                            alt="img-input"
                            style={{
                              height: "175px",
                              width: "175px",
                            }}
                          />
                          <input
                            accept="image/*"
                            className="add_image_btn"
                            type="file"
                            onChange={(event) => setImage(event, 1)}
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            flex: "1",
                            textAlign: "center",
                          }}
                        >
                          <HighlightOffIcon
                            className="delete-image-icon"
                            onClick={() => deleteImage(2)}
                          />
                          <img
                            src={imageInputs[2] || product.imagen_3}
                            className="add_image_bck"
                            alt="img-input"
                            style={{
                              height: "175px",
                              width: "175px",
                            }}
                          />
                          <input
                            accept="image/*"
                            className="add_image_btn"
                            type="file"
                            onChange={(event) => setImage(event, 2)}
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div
                          style={{
                            position: "relative",
                            flex: "1",
                            textAlign: "center",
                          }}
                        >
                          <HighlightOffIcon
                            className="delete-image-icon"
                            onClick={() => deleteImage(3)}
                          />
                          <img
                            src={imageInputs[3] || product.imagen_4}
                            className="add_image_bck"
                            alt="img-input"
                            style={{
                              height: "175px",
                              width: "175px",
                            }}
                          />
                          <input
                            accept="image/*"
                            className="add_image_btn"
                            type="file"
                            onChange={(event) => setImage(event, 3)}
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            flex: "1",
                            textAlign: "center",
                          }}
                        >
                          <HighlightOffIcon
                            className="delete-image-icon"
                            onClick={() => deleteImage(4)}
                          />
                          <img
                            src={imageInputs[4] || product.imagen_5}
                            className="add_image_bck"
                            alt="img-input"
                            style={{
                              height: "175px",
                              width: "175px",
                            }}
                          />
                          <input
                            accept="image/*"
                            className="add_image_btn"
                            type="file"
                            onChange={(event) => setImage(event, 4)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mt-3">
                  <label style={{ fontSize: "0.9rem" }}>
                    <strong>Datos de inventario:</strong>
                  </label>
                  {!isCmi() && (
                    <div className="mt-2">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Inventario</strong>
                      </label>

                      <TextField
                        defaultValue={product.stock}
                        inputRef={register}
                        type="number"
                        disabled
                        name="stock"
                        className="w-100"
                        placeholder="Ingrese el inventario de este producto"
                        onChange={(event) => {
                          const t = event.target.value;
                          event.target.value =
                            t.indexOf(".") >= 0
                              ? t.substr(0, t.indexOf(".")) +
                                t.substr(t.indexOf("."), 3)
                              : t;
                          return event.target.value < 0
                            ? (event.target.value = -event.target.value)
                            : event.target.value;
                        }}
                        InputProps={{
                          inputProps: { min: 0, step: "0.00001" },
                        }}
                      />
                    </div>
                  )}
                  {isCmi() && (
                    <div className="mt-2 row">
                      <div className="col-12 h-25">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Inventario</strong>
                        </label>
                      </div>

                      <div className="col-12 h-75 d-flex justify-content-between align-items-center">
                        {unidadVenta === "mixto" ||
                        !unidadVenta ||
                        unidadVenta === "libras" ? (
                          <div
                            className={`${
                              unidadVenta === "libras"
                                ? "w-100 flex-wrap"
                                : "w-50 flex-wrap"
                            }justify-content-around h-100 d-flex flex-row align-items-center`}
                          >
                            <div
                              className={`w-90 h-25 d-flex ${
                                unidadVenta === "mixto"
                                  ? "justify-content-center"
                                  : "justify-content-start"
                              } align-items-center`}
                            >
                              <label style={{ fontSize: "0.8rem" }}>
                                <strong>
                                  Cantidad (
                                  {unidadVenta === "libras"
                                    ? "Libras"
                                    : "Unidades"}
                                  )
                                </strong>
                              </label>
                            </div>
                            <span className="w-100 border-bottom ml-2">
                              {unidadVenta === "mixto"
                                ? formaterPriceFunction(
                                    product.stock_libras.toFixed(2)
                                  )
                                : product.vendido_por === "libras"
                                ? formaterPriceFunction(
                                    product.stock.toFixed(2)
                                  )
                                : formaterPriceFunction(
                                    product.stock_libras.toFixed(2)
                                  )}
                            </span>

                            <p className="mb-0">
                              {unidadVenta === "libras" ? "/lbs." : "/uds."}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {unidadVenta === "mixto" ||
                        !unidadVenta ||
                        unidadVenta === "unidades" ? (
                          <div
                            className={`${
                              unidadVenta === "unidades"
                                ? "w-100 flex-wrap"
                                : "w-50 flex-wrap"
                            }justify-content-around h-100 d-flex flex-row align-items-center`}
                          >
                            <div
                              className={`w-90 h-25 d-flex ${
                                unidadVenta === "mixto"
                                  ? "justify-content-center"
                                  : "justify-content-start"
                              } align-items-center`}
                            >
                              <label style={{ fontSize: "0.8rem" }}>
                                <strong>
                                  Cantidad (
                                  {unidadVenta === "unidades"
                                    ? "Unidades"
                                    : "Peso"}
                                  )
                                </strong>
                              </label>
                            </div>
                            <span className="w-100 border-bottom">
                              {unidadVenta === "mixto"
                                ? formaterPriceFunction(
                                    product.stock.toFixed(2)
                                  )
                                : product.vendido_por === "unidades"
                                ? formaterPriceFunction(
                                    product.stock.toFixed(2)
                                  )
                                : 0}
                            </span>

                            <p className="mb-0">
                              {unidadVenta === "unidades" ? "/uds." : "/lbs."}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}

                  <div className="mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Notificaciones de bajo inventario</strong>
                        </label>
                        <Tooltip
                          title="Notificaciones de bajo inventario"
                          className="mx-1"
                        >
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>
                      <div className="custom-control custom-switch">
                        <input
                          checked={lowStockNotification}
                          inputRef={register}
                          type="checkbox"
                          name="notificaciones_inventario"
                          className="custom-control-input"
                          id={`customSwitchNotification`}
                          OnChecked={lowStockNotification}
                          onClick={handleLowStockNotification}
                        />
                        <label
                          className="custom-control-label"
                          for={`customSwitchNotification`}
                        />
                      </div>
                    </div>
                    <TextField
                      inputRef={register}
                      type="number"
                      name="stock_minimo"
                      value={stock_minimo}
                      disabled={!lowStockNotification}
                      className="w-100"
                      placeholder={`Ingrese la cantidad de inventario mínimo ${
                        unidadVenta === "libras" || "mixto" ? " en libras" : ""
                      }`}
                      onChange={(event) => {
                        const t = event.target.value;
                        event.target.value =
                          t.indexOf(".") >= 0
                            ? t.substr(0, t.indexOf(".")) +
                              t.substr(t.indexOf("."), 3)
                            : t;
                        setStock_minimo(event.target.value);
                      }}
                      defaultValue={product.stock_minimo || ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">N°</InputAdornment>
                        ),
                        inputProps: { min: 0, step: "0.00001" },
                      }}
                    />
                  </div>

                  <div
                    className="mt-3"
                    style={{ display: product.id_padre ? "none" : "block" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Costo del producto</strong>
                      </label>
                      <Tooltip title="Costo del producto">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      value={costoValue}
                      inputRef={register}
                      type="text"
                      disabled={product.id_padre}
                      name="costo_unitario"
                      className="w-100"
                      placeholder="Ingrese el costo del producto."
                      defaultValue={
                        product.costo_unitario
                          ? product.costo_unitario.toFixed(5)
                          : "0.00"
                      }
                      onChange={(event) => {
                        const t = event.target.value;
                        event.target.value =
                          t.indexOf(".") >= 0
                            ? t.substr(0, t.indexOf(".")) +
                              t.substr(t.indexOf("."), 3)
                            : t;
                        setCostoValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {moneda}.
                          </InputAdornment>
                        ),
                        inputProps: { min: 0, step: "0.00001" },
                      }}
                    />
                  </div>
                </div>

                {/* Sixth Row */}
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Vencimiento del producto</strong>
                      </label>
                      <p className="mb-0" style={{ fontSize: "0.8rem" }}>
                        Indique si su producto tiene fecha de vencimiento.
                      </p>
                    </div>
                    <div className="custom-control custom-switch">
                      <input
                        inputRef={register}
                        type="checkbox"
                        name="vencimiento"
                        className="custom-control-input"
                        id={`customSwitchExpireDate`}
                        onClick={() => lote_expiredDate()}
                        checked={product.flag_vencimiento}
                      />
                      <label
                        className="custom-control-label"
                        for={`customSwitchExpireDate`}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-8 mt-3">
                  {product.flag_vencimiento ? (
                    <div className="row">
                      <div className="col-md-6 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Lote del producto</strong>
                        </label>

                        <TextField
                          inputRef={register}
                          type="text"
                          name="lote"
                          className="w-100"
                          placeholder="Ingrese número de lote del producto"
                          defaultValue={product.lote}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <strong>Fecha de vencimiento</strong>
                          </label>
                          <Tooltip title="Fecha de vencimiento del lote">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>
                        <TextField
                          inputRef={register}
                          type="date"
                          name="vencimiento_fecha"
                          className="w-100"
                          defaultValue={moment(
                            new Date(product.vencimiento_fecha)
                          ).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {isCmi() ? (
                  <div className="col-md-4 mt-2">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Considerar el 100% peso</strong>
                    </label>

                    <label
                      class="toggle-control ml-3"
                      style={{ left: "-15px", height: "40px" }}
                    >
                      <input
                        type="checkbox"
                        value={validarStockHijos}
                        onChange={() =>
                          setValidarStockHijos(!validarStockHijos)
                        }
                        checked={validarStockHijos}
                      />
                      <span class="control" />
                    </label>
                  </div>
                ) : null}
              </div>
            </form>
          </TabPanel>
        )}

        <TabPanel value={selectedTab} index={!isCmi() ? 1 : null}>
          <form
            className="row mb-4"
            onSubmit={handleSubmit_newPriceLevel(createPriceLevel)}
            autoComplete="on"
          >
            <div className="col-md-4">
              <FormControl variant="outlined" className="w-100">
                <Select
                  inputRef={register_newPriceLevel}
                  required
                  native
                  name="id_nivel_precio"
                  className="cs-select1 select-w100"
                >
                  <option value="">Seleccione el nivel de precio</option>

                  {product.missing_price_levels
                    ? product.missing_price_levels.map((level) => (
                        <option value={level.id_nivel_precio}>
                          {level.descripcion}
                        </option>
                      ))
                    : ""}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-4">
              <TextField
                value={precioNivel}
                type="text"
                className="w-100"
                placeholder="Ingrese el precio."
                name="precio"
                required
                inputRef={register_newPriceLevel}
                onChange={(event) => {
                  setPrecioNivel(formaterPriceFunction(event.target.value));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{moneda}.</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col-md-4">
              <Button
                className="btn-cs1 mt-1 mx-1"
                variant="contained"
                color="primary"
                type="submit"
              >
                Crear nivel de precio
              </Button>
            </div>
          </form>
          <div className="row">
            {priceLevels.map((level, index) => (
              <>
                <div className="col-md-4 mt-3">
                  <p>{level.nivel_precio.descripcion}</p>
                </div>
                <div className="col-md-4 mt-3">
                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el nivel de precio del producto."
                    defaultValue={formaterPriceFunction(level.precio + "")}
                    onChange={(event) => updatePriceArray(event, index)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col-md-4 mt-3 ">
                  <Button
                    className="btn-cs1 mt-1 mx-1"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      level.nivel_precio.id_nivel_precio === 1
                        ? null
                        : updatePriceLevel(index)
                    }
                    type="button"
                  >
                    {level.nivel_precio.id_nivel_precio === 1
                      ? "Por defecto"
                      : "Actualizar"}
                  </Button>
                </div>
              </>
            ))}
          </div>
        </TabPanel>

        <TabPanel value={selectedTab} index={!isCmi() ? 2 : 99}>
          <form
            className="row mb-4"
            onSubmit={handleSubmit2(createPromotion)}
            autoComplete="on"
            encType="multipart/form-data"
          >
            <div className="col-md-4">
              <p>Cree una nueva promoción para este producto</p>
            </div>
            <div className="col-md-5"></div>
            <div className="col-md-3 text-right">
              <Button
                className="btn-cs1 mt-1 mx-1"
                variant="contained"
                color="primary"
                type="submit"
              >
                Crear promoción
              </Button>
            </div>

            <div className="col-md-3">
              <div>
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Fecha de inicio</strong>
                </label>

                <TextField
                  inputRef={register2}
                  required={true}
                  name="fecha_inicio"
                  type="datetime-local"
                  className="w-100"
                  inputProps={{
                    step: "any",
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Fecha de fin</strong>
                </label>

                <TextField
                  inputRef={register2}
                  required={true}
                  name="fecha_final"
                  type="datetime-local"
                  className="w-100"
                  inputProps={{
                    step: "any",
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Precio</strong>
                </label>

                <TextField
                  inputRef={register2}
                  required={true}
                  value={precioPromo}
                  type="text"
                  name="precio"
                  className="w-100"
                  placeholder="Ingrese el precio promocional."
                  onChange={(event) => {
                    setPrecioPromo(formaterPriceFunction(event.target.value));
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {moneda}.
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Descripcion</strong>
                </label>

                <TextField
                  inputRef={register2}
                  required={true}
                  type="text"
                  name="descripcion"
                  className="w-100"
                />
              </div>
            </div>
          </form>
          <DataTable
            className="datatable-cs"
            title={"s0"}
            columns={[
              {
                name: "Descripción",
                selector: "descripcion",
                sortable: true,
              },
              {
                name: "Fecha de inicio",
                selector: "fecha_inicio",
                cell: (row) => (
                  <div className="mx-auto py-3">
                    <TextField
                      type="datetime-local"
                      className="w-100"
                      defaultValue={moment(row.fecha_inicio).format(
                        "YYYY-MM-DDTHH:mm:ss"
                      )}
                      inputProps={{
                        step: "any",
                      }}
                      onChange={(e) => updatePromotion(row, e, "fecha_inicio")}
                    />
                  </div>
                ),
              },
              {
                name: "Fecha final",
                selector: "fecha_final",
                cell: (row) => (
                  <div className="mx-auto py-3">
                    <TextField
                      type="datetime-local"
                      className="w-100"
                      defaultValue={moment(row.fecha_final).format(
                        "YYYY-MM-DDTHH:mm:ss"
                      )}
                      inputProps={{
                        min: moment(row.fecha_inicio).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        ),
                        step: "any",
                      }}
                      onChange={(e) => updatePromotion(row, e, "fecha_final")}
                    />
                  </div>
                ),
              },
              {
                name: "Precio",
                selector: "precio",
                cell: (row) => (
                  <div className="mx-auto py-3">
                    <TextField
                      defaultValue={row.precio}
                      type="number"
                      className="w-100"
                      onChange={(e) => updatePromotion(row, e, "precio")}
                      placeholder="Ingrese el precio de la promocion"
                      InputProps={{
                        inputProps: { min: 0, step: "0.01" },
                      }}
                    />
                  </div>
                ),
              },
              {
                name: "",
                selector: "",
                cell: (row) => (
                  <div className="mx-auto w-100 d-flex justify-content-center align-items-center">
                    <div className="w-50 text-center">
                      <button
                        className="btn-cs1 btn-small btn btn-primary mt-1 mx-1"
                        onClick={() =>
                          updatePromotionAPI(row.id_precio_vigencia)
                        }
                        type="button"
                        style={{ fontWeight: 700, fontSize: "0.75rem" }}
                      >
                        Actualizar
                      </button>
                    </div>
                    <div className="w-50 text-center">
                      <button
                        className="btn-cs1 btn-small text-white btn icon-btn-delete mt-1 mx-1"
                        onClick={() => deletePromotion(row.id_precio_vigencia)}
                        type="button"
                        style={{ fontWeight: 700, fontSize: "0.75rem" }}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                ),
              },
            ]}
            data={productPromotions}
            pagination
            paginationPerPage={10}
            paginationIconFirstPage={null}
            paginationIconLastPage={null}
            paginationComponentOptions={{
              rangeSeparatorText: "de",
              noRowsPerPage: true,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
            noDataComponent="No se encontraron promociones"
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={!isCmi() ? 6 : 6}>
          {!isCmi() && !isAleko() && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="on"
              encType="multipart/form-data"
            >
              <Insumos
                productoCompuesto={productoCompuesto}
                setProductoCompuesto={setProductoCompuesto}
                product={product}
                files={files}
                submitLoader={submitLoader}
              />
            </form>
          )}
        </TabPanel>

        <TabPanel
          value={selectedTab}
          index={
            !isCmi() ? null : product.id_padre || product.combo ? 99999 : 1
          }
        >
          {/* Productos Hijos */}
          <form
            onSubmit={handleSubmithijos(onSubmitProductosHijos)}
            autoComplete="on"
          >
            {isCmi() && validarStockHijos ? (
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="col-md-10">
                  <div className="">
                    <div className="col-md-10">
                      <Tooltip title="Importante">
                        <InfoIcon
                          style={{
                            color: " #D10303",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>

                      <label style={{ fontSize: "0.9rem", color: "red" }}>
                        <strong>
                          {" "}
                          Al crear o actualizar los productos hijos se
                          considerará como límite el peso del producto padre.{" "}
                        </strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="col-md-6">
                <div className="">
                  <div className="col-md-6">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Creación de Prod. hijos</strong>
                    </label>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-around">
                    {!createSubProd ? (
                      <Button
                        style={{
                          width: "50%",
                          height: "25%",
                        }}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={(e) => setCreateSubProd(false)}
                      >
                        No
                      </Button>
                    ) : (
                      <Button
                        style={{
                          width: "50%",
                          height: "25%",
                        }}
                        variant="contained"
                        size="small"
                        onClick={(e) => setCreateSubProd(false)}
                      >
                        No
                      </Button>
                    )}
                    {createSubProd ? (
                      <Button
                        style={{
                          width: "50%",
                          height: "25%",
                        }}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={(e) => setCreateSubProd(true)}
                      >
                        Sí
                      </Button>
                    ) : (
                      <Button
                        style={{
                          width: "50%",
                          height: "25%",
                        }}
                        variant="contained"
                        size="small"
                        onClick={(e) => setCreateSubProd(true)}
                      >
                        Si
                      </Button>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <p style={{ fontSize: "0.8rem" }} className="mb-0">
                    Puede crear productos hijos que deriven del producto
                    principal, de esa forma se podrá facturar porciones del
                    producto principal.
                  </p>
                </div>
              </div>

              {createSubProd ? (
                <div className="col-md-6 pl-5">
                  <div className="col-md-6 d-flex justify-content-start align-items-center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      startIcon={<AddIcon />}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      Crear
                    </Button>
                  </div>
                </div>
              ) : null}

              {/* Formulario para crear subniveles */}
              {createSubProd ? (
                <div
                  className="col-md-12 d-flex row justify-center align-items-center"
                  style={{ marginTop: "5px" }}
                >
                  <div className="col-1" />
                  <div className="col-md-1">
                    <div className="row justify-content-center  align-items-center position-relative">
                      <HighlightOffIcon
                        className="delete-image-icon"
                        onClick={() => deleteImageHijos(0)}
                      />
                      <img
                        src={imageInputsHijos[0]}
                        className="add_image_prodHijo"
                        alt="img-input"
                      />
                      <input
                        accept="image/*"
                        name="images"
                        className="add_image_btn"
                        inputRef={registerhijos}
                        onChange={(event) => setImageHijos(event, 0)}
                        type="file"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Nombre del producto hijo</strong>
                    </label>
                    <TextField
                      type="text"
                      className="w-100"
                      name="name_prod_child"
                      required
                      placeholder="Ingrese el nombre del producto hijo"
                      inputRef={registerhijos}
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>
                          Código del subproducto
                          {/* (Extensión) */}
                        </strong>
                      </label>
                      <Tooltip title="Escriba el código del subproducto.">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      required
                      type="text"
                      className="w-100"
                      name="codigo_prod_child"
                      placeholder="Ingrese código"
                      inputRef={registerhijos}
                    />
                  </div>

                  <div className="col-md-2">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Porción por producto</strong>
                      </label>
                      <Tooltip title="Escriba la porción que se extraerá del Producto Padre">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      type="number"
                      className="w-100"
                      required
                      name="porcion_prod_child"
                      placeholder="Ingrese piezas"
                      onKeyUp={(e) =>
                        changeQuantityprobably(e, modificarPorcionProducto)
                      }
                      onChange={(e) => setPorcionProducto(e.target.value)}
                      inputRef={registerhijos}
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Peso Probable en libras</strong>
                      </label>
                      <Tooltip title="Escriba el Peso Probable en libras">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      type="text"
                      className="w-100"
                      required
                      name="peso_probable_prod_child"
                      placeholder="Ingrese el peso probable"
                      onChange={(e) => setPesoProbable(e.target.value)}
                      onKeyUp={(e) =>
                        changeQuantityprobably(e, modificarPesoProbableProducto)
                      }
                      inputRef={registerhijos}
                    />
                  </div>
                  <div
                    className="col-md-2"
                    style={{
                      background: "#F2F2F2",
                      height: "100px",
                      outline: "10px solid black;",
                    }}
                  >
                    <div
                      className="w-100 d-flex justify-content-between align-items-center"
                      style={{ marginTop: "12%" }}
                    >
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Cantidad Probable</strong>
                      </label>
                      <Tooltip title="Estas son las unidades disponibles del producto hijo según las porciones del producto padre">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <span className="w-100 border-bottom">
                      {formaterPriceFunction(quantityProbabably.toFixed(2)) +
                        " / unid."}
                    </span>
                  </div>
                  <div className="col-md-2" />
                  <div className="col-md-2">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Precio del producto</strong>
                      </label>
                      <Tooltip title="Escriba el precio del subproducto.">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      required
                      type="text"
                      className="w-100"
                      name="precio_producto_hijo"
                      placeholder="Ingrese el precio"
                      inputRef={registerhijos}
                    />
                  </div>
                </div>
              ) : null}

              {productohijosData.length > 0 ? (
                <div
                  className="col-md-12 d-flex row justify-content-center align-items-center"
                  style={{ marginTop: "5px" }}
                >
                  <label style={{ fontSize: "15px" }}>
                    <strong>Productos hijos creados</strong>
                  </label>
                </div>
              ) : null}
              {productohijosData.map((data, index) => (
                <div
                  className="col-md-12 d-flex row justify-center align-items-center"
                  style={{ marginTop: "5px" }}
                >
                  <div className="col-md-1 h-100 d-flex justify-content-center align-items-center">
                    <div className="btn_eliminar_prod_hijo">
                      <IconButton
                        color="secondary"
                        onClick={(e) =>
                          deleteProductHijos(data.id_new_item_hijo)
                        }
                      >
                        <DeleteIcon
                          style={{
                            color: "white",
                          }}
                        />
                      </IconButton>
                    </div>
                    {/*<div className="btn_eliminar_prod_hijo" style={{marginLeft:'10px'}}>
                          <IconButton  onClick={(e)=>deleteProductHijos(data.id_new_item_hijo)}>
                              <EditIcon style={{
                                  color: 'white'
                              }} />
                          </IconButton>
                            </div>*/}
                  </div>
                  <div className="col-md-1">
                    <div className="row justify-content-center  align-items-center position-relative">
                      <img
                        src={
                          data.imagen !== ""
                            ? realImageURL(data.imagen)
                            : base_url_images + "images/placeholder-image.jpeg"
                        }
                        className="add_image_prodHijo"
                        alt="img-input"
                      />
                      <input
                        disabled
                        accept="image/*"
                        name="images"
                        className="add_image_btn"
                        type="file"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Nombre del producto hijo</strong>
                    </label>
                    <TextField
                      type="text"
                      defaultValue={data.name_prod_child}
                      className="w-100"
                      required
                      disabled
                      placeholder="Ingrese el nombre del producto hijo"
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>
                          Código del subproducto
                          {/* del subproducto (Extensión) */}
                        </strong>
                      </label>
                    </div>
                    <TextField
                      required
                      type="text"
                      defaultValue={data.codigo_prod_child}
                      disabled
                      className="w-100"
                      placeholder="Ingrese código"
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Porción por producto</strong>
                      </label>
                    </div>
                    <TextField
                      type="number"
                      className="w-100"
                      defaultValue={data.porcion_prod_child}
                      disabled
                      placeholder="Ingrese código"
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Peso Probable en libras</strong>
                      </label>
                    </div>
                    <TextField
                      type="number"
                      className="w-100"
                      defaultValue={data.peso_probable_prod_child}
                      onFocus={() => setCurrentChild(data.codigo_prod_child)}
                      // onBlur={() => {
                      //   setCurrentChild("");
                      //   setPesoProbable("");
                      // }}
                      onChange={(e) => setPesoProbable(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {currentChild === data.codigo_prod_child ? (
                              <IconButton
                                onClick={() => {
                                  handleUpdateChild({
                                    ...data,
                                    peso_probable_prod_child: pesoProbable,
                                    id_new_item: product.id_new_item,
                                  });
                                }}
                              >
                                <CheckCircleIcon style={{ color: "#00c853" }} />
                              </IconButton>
                            ) : (
                              "lbs"
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                      name="peso_probable_prod_child"
                      placeholder="Ingrese el peso probable"
                    />
                  </div>
                  <div
                    className="col-md-2"
                    style={{
                      background: "#F2F2F2",
                      height: "100px",
                      outline: "10px solid black;",
                    }}
                  >
                    <div
                      className="w-100 d-flex justify-content-between align-items-center"
                      style={{ marginTop: "12%" }}
                    >
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Cantidad Probable</strong>
                      </label>
                      <Tooltip title="Estas son las unidades disponibles del producto hijo según las porciones del producto padre">
                        <InfoIcon
                          style={{
                            color: " #828282",
                            fontSize: "1.2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <span className="w-100 border-bottom">
                      {(
                        product.stock_libras *
                        data.porcion_prod_child *
                        data.peso_probable_prod_child
                      ).toFixed(2) + " / unid."}
                    </span>
                  </div>
                  <div className="col-md-2" />
                  <div className="col-md-2">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Precio</strong>
                    </label>
                    <TextField
                      type="text"
                      defaultValue={data.precio}
                      className="w-100"
                      required
                      disabled
                      placeholder="Ingrese el nombre del producto hijo"
                    />
                  </div>
                </div>
              ))}
            </div>
          </form>
        </TabPanel>

        <TabPanel
          value={selectedTab}
          index={product.id_padre || product.combo ? 7 : 7}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="on"
            encType="multipart/form-data"
          >
            {isCmi() && (
              <Merma_prodChild
                submitLoader={submitLoader}
                unidadVenta={unidadVenta}
                stock={product.stock}
              />
            )}
          </form>
        </TabPanel>
      </div>
    </div>
  );
}
