import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import API from "../../../api";

export const useSap = () => {
  const [sucursals, setSucursals] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [cajeroDisable, setCajeroDisable] = useState(false);
  const [fecha, setFecha] = useState(new Date());
  const [filteredSales, setFilteredSales] = useState([]);
  const [sales, setSales] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [loader, setLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const getSucursals = useCallback(async () => {
    try {
      const { data } = await API.userConfig.getSucursals(true);
      setSucursals(data.data);
      setSucursal(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const obtenerVentasSap = useCallback(async (id_sucursal, date) => {
    setLoader(true);
    try {
      const { data } = await API.cajas.obtenerVentasSap({
        fecha: date,
        id_sucursal_bodega_ubicacion: id_sucursal,
      });
      setSales(data.data.facturas);
      setFilteredSales(data.data.facturas);
      setProducts(data.data.productos);
      setFilteredProducts(data.data.productos);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }, []);
  const changeSucursal = (e) => {
    setSucursal(e.target.value);
  };
  useEffect(() => {
    getSucursals();
    cajeroValidate();

    return () => {
      setSucursals([]);
    };
  }, []);
  useEffect(() => {
    if (sucursal) {
      obtenerVentasSap(sucursal.id_sucursal_bodega_ubicacion, fecha);
    }
    return () => {
      setSales([]);
      setFilteredSales([]);
    };
  }, [sucursal, fecha]);
  //Valida al usuario de tipo cajero
  const cajeroValidate = () => {
    if (JSON.parse(localStorage.getItem("pms"))) {
      if (JSON.parse(localStorage.getItem("pms")).tipo_usuario === "CAJERO") {
        // si es usuario tipo cajero
        setCajeroDisable(true);
      }
    }
  };
  const handleFecha = (e) => {
    setFecha(e.target.value);
  };

  const handleSelect = (info) => {
    let { selectedRows } = info;
    setSelectedSales(selectedRows);
  };
  const handleSelectProducts = (info) => {
    let { selectedRows } = info;
    setSelectedProducts(selectedRows);
  };

  const filterSales = (e) => {
    setFilteredSales(
      sales.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredSales(sales);
    }
  };
  const filterProducts = (e) => {
    setFilteredProducts(
      products.filter(
        (item) =>
          item.nombre.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.codigo_sap.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredProducts(products);
    }
  };

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const salesAction = (identificador, nit, id_pos_movimiento) => {
    const newWindow = window.open(
      "/#/app/vender/resumen/" + id_pos_movimiento,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const downloadSap = async () => {
    try {
      const { data } = await API.cajas.descargasSap({
        fecha,
        id_sucursal_bodega_ubicacion: sucursal.id_sucursal_bodega_ubicacion,
      });
      if (data.succes) {
        toast.success("Datos insertados correctamente", { autoClose: 10000 });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return {
    sucursals,
    sucursal,
    changeSucursal,
    cajeroDisable,
    fecha,
    handleFecha,
    filteredSales,
    handleSelect,
    selectedSales,
    filterSales,
    loader,
    formaterPriceFunction,
    salesAction,
    filteredProducts,
    filterProducts,
    handleSelectProducts,
    selectedProducts,
    downloadSap,
  };
};
