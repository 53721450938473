import React from "react";

//estilos
import "./reportes.css";

//Material UI
import SearchIcon from "@material-ui/icons/Search";
import DataTable from "react-data-table-component";

const TablePreVenta = ({ columnas = [], data, searchValue = '', handleChangeSearchValue, totalFilas, fnBusqueda, placeHolder= '' }) => {

  return (
    <div className="mt-2">
      <div class="mt-1">
        {/*Boton de buscar*/}
        <div className="col-md-12 row" style={{ paddingRight: 0, margin: 0 }}>
          <div className="col-md-4" style={{ paddingRight: 0, paddingLeft: 0, margin: 0 }}>
            <h4 style={{ fontSize: "20px", color: "#333" }}>
              <strong>
                Ordenes de pedidos
              </strong>
            </h4>
            Consulte y gestione su ventas guardadas que no han sido facturadas.
          </div>
          <div className="col-md-4" />
          <div className="col-md-4" />
        </div>
        {/*Tabla de reporte */}
        <div className="col-md-12 row" style={{ paddingRight: 0, margin: 0 }}>
        <div className="col-md-4"/>
        <div className="col-md-4"/>
        <div className="input-group search-bar col-md-4" style={{ paddingRight: 0, margin: 0 }}>
          <input
            type="text"
            className="form-control"
            value= {searchValue}
            onChange={handleChangeSearchValue}
            placeholder={placeHolder}
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <SearchIcon onClick={fnBusqueda} />
            </span>
          </div>
        </div>
      </div>

      <DataTable
        className="datatable-cs datatable-cs-v2 mt-4"
        columns={columnas}
        data={data}
        selectableRows={false}
        highlightOnHover
        pagination
        paginationPerPage={10}
        paginationIconFirstPage={null}
        paginationIconLastPage={null}
        paginationDefaultPage={1}
        paginationTotalRows={totalFilas}
        onChangePage={(page) => console.log(page)}
        paginationComponentOptions={{
          rangeSeparatorText: "de",
          noRowsPerPage: true,
          selectAllRowsItem: false,
          selectAllRowsItemText: "All",
        }}
        noDataComponent="No se ha encontrado ningún resultado"
        onSelectedRowsChange={() => console.log("HAndlechange")}
      />
    </div>
    </div >
  );
};

export default TablePreVenta;
