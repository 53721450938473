import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import print from "print-js";
import { getMoneda } from "../../helpers/moneda";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";

// components

import moment from "moment";
import "moment/locale/es";
import { isCmi } from "../../helpers/isCmi";
import { validarReimpresion } from "../../api/sales";
import ModalAlerta from "../../components/ModalAlert";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";
import { formaterPriceFunction } from "../../helpers/formater";
import { isAleko } from "../../helpers/isAleko";
moment.locale("es");

const generatePdf = (name) => {
  const element = document.querySelector(".pdf-target");

  /* const options = {
		filename: name
	};

	return domToPdf(element, options, () => {
		// callback function
	}); */

  window.html2canvas = html2canvas;
  const pdf = new jsPDF("p", "pt", "a4");
  pdf.html(element, {
    callback: function (doc) {
      pdf.save(name);
    },
    html2canvas: { scale: 0.475 },
  });
};

const ref = React.createRef();

export default function Users_all(props) {
  const moneda = getMoneda();
  const [invoiceData, setInvoiceData] = useState(null);
  const [isCreditNote, setIsCreditNote] = useState(false);
  const [isGuatefactura, setIsGuatefactura] = useState(false);
  const [isGranContribuyente, setIsGranContribuyente] = useState(false);
  const [tipoContribuyente, setTipoContribuyente] = useState("");
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [gerente, setGerente] = useState(false);
  const { setActivarModalAlerta } = useContext(CmiProductContext);

  useEffect(() => {
    console.log("test", invoiceData);
  }, [invoiceData]);

  const convertir = require("numero-a-letras");

  useEffect(() => {
    if (!!localStorage.getItem("invoice_data")) {
      let invoice_data = JSON.parse(localStorage.getItem("invoice_data"));
      // let selectProducts = JSON.parse(window.localStorage.getItem())

      setInvoiceData(invoice_data);

      console.log("invoice_data ->", invoice_data);

      if (
        invoice_data.id_nota_credito_web_app != undefined ||
        invoice_data.id_nota_credito_web_app > 0
      ) {
        setIsCreditNote(true);
      }

      if (invoice_data?.certificador?.codigoSatGuatefactura != undefined) {
        setTipoContribuyente(invoice_data.certificador.codigoSatGuatefactura);
        setIsGuatefactura(true);
      }

      if (invoice_data.granContribuyente) {
        setIsGranContribuyente(true);
      }

      //localStorage.removeItem('invoice_data');
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(
        "Ha ocurrido un error, por favor regrese a la página anterior.",
        {
          autoClose: 10000,
        }
      );
    }
  }, []);

  useEffect(() => {
    if (invoiceData && gerente) {
      showFactura();
    }
  }, [invoiceData, , gerente]);

  const showFactura = () => {
    print({
      printable: "creditForm",
      type: "html",
      targetStyles: ["*"],
      modalMessage: "Imprimiendo",
      // font_size: '8px',
      showModal: true,
      onLoadingEnd: () => {
        setOpen(true);
      },
    });
  };

  const getTotalCredito = () =>
    Number(
      invoiceData.orderItems.reduce((a, b) => a + b.total_devolver, 0)
    ).toFixed(2);

  const handleClose = (close) => {
    if (close) {
      window.close();
    }

    if (!close) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (invoiceData && isCmi()) {
      const validarImpresion = async () => {
        const id_new_empresa = localStorage.getItem("id_empresa");
        const id_new_user = JSON.parse(localStorage.getItem("pms")).id_new_user;
        try {
          await validarReimpresion({
            id_document: invoiceData.id_pos_movimiento,
            type_document: "NC",
            id_new_empresa,
            id_new_user,
          });
          setGerente(true);
        } catch (error) {
          const tipo_usuario = JSON.parse(
            localStorage.getItem("pms")
          ).tipo_usuario;
          if (tipo_usuario.toLowerCase().includes("administrador")) {
            setGerente(true);
          } else {
            setGerente(false);
            setActivarModalAlerta(true);
          }
        }
      };
      validarImpresion();
    }
  }, [invoiceData]);

  const getTipoContribuyente = () => {
    const data =
      tipoContribuyente == "1"
        ? "Factura cambiaria"
        : tipoContribuyente == "2"
        ? "Factura cambiaria"
        : tipoContribuyente == "3"
        ? "Factura pequeño contribuyente"
        : tipoContribuyente == "4"
        ? "Factura cambiaria pequeño contribuyente"
        : tipoContribuyente == "9"
        ? "Nota de débito"
        : tipoContribuyente == "10"
        ? "Nota de crédito"
        : "Factura";

    return data;
  };

  return (
    <div id="dashboard-section">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"CLOUD"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea regresar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color="primary">
            Sí
          </Button>
          <Button onClick={() => handleClose(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Factura Nota de Crédito</strong>
        </h4>
      </div>
      {invoiceData ? (
        <div className="content-card card mb-3">
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() =>
                  generatePdf(
                    "Nota de crédito " + invoiceData.felData.numerofel
                  )
                }
              >
                Descargar PDF
              </button>
            </div>

            <div className="d-flex justify-content-center">
              <div style={{ display: "none" }}>
                <div
                  style={{
                    border: isCmi() ? "" : "1px solid gray",
                    width: isCmi() ? "col-md-12" : "1240px",
                    overflowX: isCmi() ? "none" : "scroll",
                    lineHeight: "normal",
                  }}
                  className="d-flex justify-content-center"
                  id="creditForm"
                >
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={16} className="mx-auto" />
                    </div>
                  ) : (
                    <div className="pdf-target px-4 py-3" ref={ref}>
                      {invoiceData.fecha_anulacion ? (
                        <div className="anulado-overlay">
                          <p style={{ color: "red" }}>NOTA ANULADA</p>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* {invoiceData?.certificador?.codigoSatGuatefactura ? (
                        <div className="col-md-12 mx-auto text-center">
                          <img
                            className="mb-0 img_factura"
                            src={`${
                              isGuatefactura
                                ? `/images/avicola_icon.jpeg`
                                : "/images/cloudLogo.png"
                            }`}
                            alt="factura-imagen"
                          />
                        </div>
                      ) : (
                        ""
                      )} */}
                      {isAleko() ? (
                        <div className="col-12 d-flex justify-content-center">
                          <img
                            className="mb-0"
                            src={"/images/logo_aleko_v1.png"}
                            alt="factura-imagen"
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-md-12 mx-auto text-center">
                        <p className="mb-0">
                          <strong>{invoiceData.empresa.nombre}</strong>
                        </p>
                        <p className="mb-0">
                          <strong>NIT: {invoiceData.empresa.nit}</strong>
                        </p>
                        <p className="mb-0">
                          {invoiceData.sucursal.nombre_sucursal}
                        </p>
                        <p className="mb-0">
                          {invoiceData.sucursal.direccion_sucursal}
                        </p>
                      </div>

                      <br />

                      <div className="text-center">
                        <p className="mb-0">
                          <strong>DOCUMENTO TRIBUTARIO ELECTRÓNICO</strong>
                        </p>
                        <p className="mb-0">
                          <strong style={{ color: isGuatefactura ? "" : "" }}>
                            NOTA DE CRÉDITO
                          </strong>
                        </p>
                      </div>

                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "SERIE: " : "SERIE FEL:"}
                        </strong>
                        {invoiceData.felData.seriefel}
                      </p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "NÚMERO: " : "NÚMERO FEL:"}
                        </strong>
                        {invoiceData.felData.numerofel}
                      </p>
                      {isCreditNote ? (
                        <div className="col-12 px-0">
                          <p className="mb-0">
                            <strong>NÚMERO DE AUTORIZACIÓN</strong>
                          </p>
                          <p className="mb-0">{invoiceData.id_dte}</p>
                          <p>
                            <strong>FECHA DE EMISIÓN: </strong>

                            {invoiceData.datosCertificador.fecha_dte
                              ? invoiceData.datosCertificador.fecha_dte
                                  .replace("-", "/")
                                  .replace("-", "/")
                              : ""}
                            {/* {moment(invoiceData.datosCertificador.fecha_dte).utc().format("DD/MM/YYYY")}  */}
                          </p>
                        </div>
                      ) : (
                        <p className="mb-3">
                          <strong>Fecha Autorizacion FEL: </strong>
                          {invoiceData.fecha_correcta
                            ? moment(invoiceData.fecha_correcta).format(
                                "DD/MM/YYYY, h:mm:ss a"
                              )
                            : moment(invoiceData.fecha).format(
                                "DD/MM/YYYY, h:mm:ss a"
                              )}
                        </p>
                      )}

                      <div className="col-md-12 px-0 mt-2">
                        <p className="mb-0">
                          <strong>NIT:</strong> {invoiceData.client.nit}
                        </p>
                        <p className="mb-0">
                          <strong> NOMBRE: </strong>{" "}
                          {invoiceData.certificador
                            ? invoiceData.certificador.nombre
                              ? invoiceData.certificador.nombre
                              : invoiceData.client.nombre
                            : invoiceData.client.nombre}
                        </p>
                        <p className="mb-0">
                          <strong>DIRECCIÓN: </strong>
                          {invoiceData.client.direccion || "No especificada"}
                        </p>
                      </div>

                      {/* <div className="divider" /> */}
                      <hr style={{ borderTop: "3px dashed #bbb" }} />
                      <div className="row">
                        <div className="col-2 text-center">Cantidad</div>
                        <div className="col-4 text-center">Descripción</div>
                        <div className="col-4 text-center">
                          {" "}
                          {isGuatefactura ? "Precio en Q." : "Precio"}
                        </div>
                        <div className="col-2 text-center">
                          <strong>
                            {isGuatefactura ? "Total en Q." : "Total"}
                          </strong>
                        </div>
                      </div>
                      {/* <div className="divider" /> */}
                      <hr style={{ borderTop: "3px dashed #bbb" }} />
                      {invoiceData.orderItems.map((product) => (
                        <div className="row">
                          <div className="col-2 text-center">
                            {product.cantidad_devolucion}
                          </div>
                          <div className="col-4 text-center">
                            {product.codigo} <br /> {product.nombre}
                          </div>
                          <div className="col-4 text-center">
                            {formaterPriceFunction(
                              parseFloat(product.precio).toFixed(2)
                            )}
                          </div>
                          <div className="col-2 text-center">
                            {formaterPriceFunction(
                              parseFloat(product.total_devolver).toFixed(2)
                            )}
                          </div>
                        </div>
                      ))}
                      {/* <div className="divider" /> */}
                      <p className="mb-0 mt-4">
                        <strong>
                          {invoiceData.nivel_precio
                            ? `Nivel de precio: ${invoiceData.nivel_precio}`
                            : ""}
                        </strong>
                      </p>
                      <p className="mb-0 mt-4">
                        <strong>
                          {isGuatefactura ? "FACTURA ASOCIADA: " : ""}
                          {/* {invoiceData.invoice.firmafel} */}
                        </strong>
                      </p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "Serie: " : "Serie Factura FEL: "}
                        </strong>
                        {invoiceData.invoice.seriefel}
                      </p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "Número: " : "Número Factura FEL: "}
                        </strong>
                        {invoiceData.invoice.numerofel}
                      </p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura
                            ? "Número de Autorización: "
                            : "Número de Autorización Factura FEL: "}
                        </strong>
                      </p>
                      <p className="mb-0">{invoiceData.invoice.id_dte}</p>
                      <p className="mb-0">
                        <strong>
                          {isGuatefactura ? "Fecha emisión: " : ""}
                        </strong>
                        {/* {invoiceData.invoice.fecha.split("T")[0].split("-")[2] +
                          "/" +
                          invoiceData?.invoice?.fecha
                            ?.split("T")[0]
                            .split("-")[1] +
                          "/" +
                          invoiceData?.invoice?.fecha
                            .split("T")[0]
                            .split("-")[0]} */}
                        {moment(invoiceData.invoice.fecha)
                          .utc()
                          .format("DD/MM/YYYY")}
                      </p>

                      <hr style={{ borderTop: "3px dashed #bbb" }} />

                      <div className="row">
                        <div
                          className="col-12 text-right"
                          style={{ paddingRight: "44px" }}
                        >
                          <strong>
                            {/* Total Nota de Credito {moneda} {getTotalCredito()} */}
                            TOTAL NOTA DE CRÉDITO {moneda} {invoiceData.monto}
                          </strong>
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          paddingLeft: isCmi() ? "" : "150px",
                          marginTop: "10px",
                        }}
                      >
                        <label style={{ fontSize: "13px" }}>
                          <strong>TOTAL EN LETRAS:</strong>
                          {getTotalCredito().toString() === "1.00"
                            ? moneda == "L"
                              ? "UN LEMPIRA 00/100"
                              : "UN QUETZAL CON 00/100"
                            : ""}

                          {getTotalCredito().toString() !== "1.00" && (
                            <>
                              {convertir
                                .NumerosALetras(
                                  isCmi()
                                    ? invoiceData.monto
                                    : invoiceData.monto
                                )
                                .replace(
                                  "Pesos",
                                  moneda == "L" ? "LEMPIRAS" : "QUETZALES CON"
                                )
                                .replace("M.N.", "")
                                .toLocaleUpperCase()}
                            </>
                          )}
                        </label>
                        <br />
                        <br />
                      </div>

                      {isGuatefactura ? (
                        isGranContribuyente ? (
                          <p className="text-center">
                            <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                            <br />
                            <strong>Agente de Retención del IVA</strong>
                          </p>
                        ) : (
                          <div className="col-12 my-2">
                            <p className="text-center">
                              <strong>
                                NO GENERA DERECHO A CRÉDITO FISCAL
                              </strong>
                            </p>
                          </div>
                        )
                      ) : (
                        <>
                          <p className="text-center">
                            <strong>SUJETO A PAGOS TRIMESTRALES ISR</strong>
                            <br />
                            <strong>Agente de Retención del IVA</strong>
                          </p>
                        </>
                      )}

                      <hr style={{ borderTop: "3px dashed #bbb" }} />

                      <p className="mb-0">
                        <strong>FECHA DE IMPRESIÓN:</strong>{" "}
                        {invoiceData.fecha_correcta
                          ? moment
                              .utc(invoiceData.fecha_correcta)
                              .format("DD/MM/YYYY, h:mm:ss a")
                          : moment
                              .utc(invoiceData.fecha)
                              .format("DD/MM/YYYY, h:mm:ss a")}
                      </p>

                      {/* {isGuatefactura ? (
                        ""
                      ) : (
                        <div className="text-center col-12 my-4">
                          <p className="mb-0">
                            <strong>NUMERO DE AUTORIZACIÓN</strong>
                          </p>
                          <p className="mb-0">
                            <strong>{invoiceData.id_dte}</strong>
                          </p>

                        </div>
                      )} */}

                      <div
                        className={
                          isGuatefactura
                            ? "row mt-2 justify-content-center"
                            : "row mt-2 justify-content-center"
                        }
                      >
                        <p>
                          <strong>CERTIFICADOR: </strong>{" "}
                          {invoiceData.serviceData.certificador}
                        </p>
                        <div className="col-4 text-center">
                          <strong>Nit: </strong>{" "}
                          {invoiceData.fel
                            ? invoiceData.fel.nit
                            : invoiceData.service_nit}
                        </div>
                      </div>

                      <div className="text-center mt-4">
                        <p className="mb-0">
                          <strong>Powered by</strong>
                        </p>
                        <p className="mb-0">
                          <a
                            href="https://www.cloud.com.gt"
                            className="href-link"
                          >
                            www.cloud.com.gt
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {isCmi() && (
        <ModalAlerta
          message="No puedes reimprimir la factura"
          bottomMessage="Consulte con un gerente de la tienda para realizar la reimpresión."
        />
      )}
    </div>
  );
}
