import { GET_COLORS, SAVE_COLOR } from "./types";

export const ColorPickerReducer = (state, action) => {
  switch (action.type) {
    case SAVE_COLOR:
      return {
        ...state,
        colors: [...state.colors, action.payload],
      };
    case GET_COLORS:
      return {
        ...state,
        colors: action.payload,
      };

    default:
      return state;
  }
};
