import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";

import UpdateIcon from "@material-ui/icons/Update";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Pagination } from "@material-ui/lab";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { toast } from "react-toastify";

import { CircularProgress } from "@material-ui/core";

import { Redirect } from "react-router-dom";

//estilos
import "./reviews.css";

//componentes
import ReviewsBox from "./components/reviewsBox";
import ReviewsDetail from "./components/reviewsDetail";

//api
import API, { base_url_images } from "../../api";
import { isCmi } from "../../helpers/isCmi";

const AllReviews = () => {
  const [sucursalSelected, setSucursalSelected] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [loading, setLoading] = useState(false);

  // gerente regional cmi}
  const [isGerente, setIsGerente] = useState(false);
  const [empresasGerenteCmi, setEmpresasGerenteCmi] = useState([]);
  const [sucursalesGerenteCmi, setSucursalesGerenteCmi] = useState([]);

  const [placeInfo, setPlaceInfo] = useState({ objeto: null, status: "ready" });

  //paginacion

  useEffect(() => {
    if (isCmi()) {
      const user = JSON.parse(window.localStorage.getItem("pms"));

      if (user.tipo_usuario.toLowerCase() === "gerente regional") {
        setIsGerente(true);
        getEmpresasGerenteCmi(user.id_new_user);
      }

      getData();
    }
  }, []);

  const getData = () => {
    getSucursalsData();
  };

  useEffect(() => {
    refreshPlace();
  }, [sucursalSelected]);

  const refreshPlace = () => {
    if (sucursalSelected === "") {
      setPlaceInfo({ objeto: null, status: "ready" });
    } else {
      getSucursalPlace();
    }
  };

  const getSucursalsData = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        setSucursals(res.data.data);
      })
      .catch((err) => {
        let response = err.response;
        if (!response) {
          toast.error(
            "Ha ocurrido un error al obtener los productos por vencer.",
            { autoClose: 10000 }
          );
          return;
        }
        console.log(response);
        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error con las sucursales");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSucursalPlace = () => {
    setPlaceInfo({ objeto: null, status: "loading" });

    API.reviews
      .getAllDataPlace("ChIJISed7IxfKowRk_BMoIbEimk")
      .then((res) => {
        console.log("place info ->", res.data.result);
        setPlaceInfo({ objeto: res.data.result, status: "ready" });
      })
      .catch((err) => {
        setSucursalSelected("");
        setPlaceInfo({ objeto: null, status: "ready" });
        let response = err.response;
        if (!response) {
          toast.error("Ha ocurrido un error al obtener la informacion.", {
            autoClose: 10000,
          });
          return;
        }
        console.log(response);
        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error con las sucursales");
          toast.error("Ha ocurrido un error al obtener la informacion", {
            autoClose: 10000,
          });
        }
      });
  };

  const redirectCmi = () => {
    if (!isCmi()) {
      return <Redirect to="/app/dashboard" />;
    }
  };

  // gerente regional cmi
  const getEmpresasGerenteCmi = async (id_user) => {
    let newEmpresa = [];

    try {
      const res = await API.users.getEmpresaPorUsuario(id_user);
      const data = await res.data.data;

      // filtrar las empresas
      data.forEach((empresa) => newEmpresa.push(empresa.empresa));

      let filtradas = newEmpresa.reduce((acc, el) => {
        if (!acc.find((item) => item.id_new_empresa == el.id_new_empresa)) {
          acc.push(el);
        }
        return acc;
      }, []);

      // seteamos las empresas
      setEmpresasGerenteCmi(filtradas);

      // buscamos las sucursales de esa empresa
      await handleChangeEmpresaGteCmi(filtradas[0].id_new_empresa);
    } catch (error) {
      console.log("error al obtener empresas gerente ->", error);
    }
  };

  const handleChangeEmpresaGteCmi = async (event) => {
    let idToSearch = "";

    if (event?.target == undefined) {
      idToSearch = event;
    } else {
      const { name, value } = event.target;
      idToSearch = value;
    }

    try {
      // sucursales
      const res = await API.sucursal.getAll(parseInt(idToSearch), true);
      const data = await res.data.data;
      setSucursals(data);
      setSucursalesGerenteCmi(data);
      console.log("sucursales ->", data);

      //buscamos datos de la primera sucursal
      setSucursalSelected(data[0].nombre_sucursal);
    } catch (error) {
      console.log("error al traer sucursales para gerente ->", error);
    }
  };

  return (
    <>
      {redirectCmi()}
      <div className="content-card card mt-3">
        <div className="reviews-header">
          <div>
            <h5 className="mb-0">
              <strong>Calificaciones y comentarios de clientes</strong>
            </h5>
            <p className="mb-0" style={{ fontSize: "1.2rem" }}>
              Consulte la opiniones de sus clientes que han compartido en cada
              sucursal.
            </p>
          </div>

          <div>
            <Button
              onClick={() => {
                refreshPlace();
              }}
              className="btn-cs1 mx-3"
              variant="contained"
              color="primary"
              style={{ paddingBottom: "0px" }}
            >
              <UpdateIcon
                className="mr-1"
                style={{ fontSize: "16px", marginBottom: "3px" }}
              />
              <strong>Actualizar</strong>
            </Button>
          </div>
        </div>

        <div
          style={{ width: "100%", height: "1px", background: "#e5e5e5" }}
        ></div>

        <div className="reviewsSelect">
          <h6 className="mb-0">
            <strong>Tienda a consultar</strong>
          </h6>
          {isGerente ? (
            <>
              <FormControl variant="outlined">
                <Select native onChange={handleChangeEmpresaGteCmi}>
                  {empresasGerenteCmi.map((itemEmpresa) => (
                    <option value={itemEmpresa.id_new_empresa}>
                      {itemEmpresa.nombre}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <ArrowForwardIosIcon
                style={{ color: "#333333", fontSize: "12px" }}
                className="mx-2"
              />

              <FormControl variant="outlined">
                <Select
                  value={sucursalSelected}
                  onChange={(e) => {
                    setSucursalSelected(e.target.value);
                  }}
                  native
                  style={{ width: "200px" }}
                >
                  <option value="">Seleccionar sucursal</option>

                  {sucursals.map((x, index) => {
                    return (
                      <option value={x.nombre_sucursal}>
                        {" "}
                        {x.nombre_sucursal}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>

              {/* <FormControl variant="outlined">
                <Select native>
                  {sucursals.map((sucursal) => (
                    <option value={sucursal.id_sucursal_bodega_ubicacion}>
                      {sucursal.nombre_sucursal}
                    </option>
                  ))}
                </Select>
              </FormControl> */}
            </>
          ) : (
            <FormControl variant="outlined">
              <Select
                value={sucursalSelected}
                onChange={(e) => {
                  setSucursalSelected(e.target.value);
                }}
                native
                style={{ width: "200px" }}
              >
                <option value="">Seleccionar sucursal</option>

                {sucursals.map((x, index) => {
                  return (
                    <option value={x.nombre_sucursal}>
                      {" "}
                      {x.nombre_sucursal}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </div>

        {placeInfo.status === "ready" ? (
          <>
            {placeInfo.objeto !== null && (
              <>
                <ReviewsDetail placeInfo={placeInfo}></ReviewsDetail>

                <div className="reviewsContainer">
                  <h6 className="mb-0">
                    <strong>Comentarios de clientes</strong>
                  </h6>

                  {placeInfo.objeto.reviews && (
                    <div className="reviewsBox">
                      {placeInfo.objeto.reviews.map((x, index) => {
                        return <ReviewsBox objeto={x} key={index}></ReviewsBox>;
                      })}
                    </div>
                  )}
                </div>

                <div
                  className="text-center d-flex justify-content-center pagination mt-2"
                  style={{ margin: "24px 0" }}
                >
                  <Pagination count={1} page={1} />
                </div>
              </>
            )}
          </>
        ) : (
          <CircularProgress
            style={{ margin: "40px 0" }}
            size={26}
            className="mx-auto"
          />
        )}
      </div>
    </>
  );
};

export default AllReviews;
