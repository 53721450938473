import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import ProductDetailsContext from "../../../../../context/DetalleProductosAleko/ProductDetailsContext";

const ProductColor = ({ item }) => {
  const { deleteProductColor } = useContext(ProductDetailsContext);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <>
      <div className="col-lg-1 text-center my-2">
        <div
          className="border"
          style={{
            background: item.codigo_hexa_color,
            borderRadius: "50%",
            height: "70px",
            width: "70px",
            lineHeight: "70px",
            cursor: "pointer",
          }}
        />
      </div>

      <div className="col-lg-3 d-flex align-items-center flex-wrap my-2">
        <label style={{ fontSize: "0.8rem" }} className="w-100">
          <strong>Nombre</strong>
        </label>
        <span className="w-100 border-bottom"> {item.nombre_color}</span>
      </div>
      <div className="col-lg-3 d-flex align-items-center flex-wrap my-2">
        <label style={{ fontSize: "0.8rem" }} className="w-100 ">
          <strong>Cantidad disponible</strong>
        </label>
        <span className="w-100 border-bottom">{item.cantidad_color}</span>
      </div>
      <div className="col-lg-3 d-flex align-items-center flex-wrap my-2">
        <label style={{ fontSize: "0.8rem" }} className="w-100">
          <strong>Precio extra:</strong>
        </label>
        <span className="w-100 border-bottom">
          Q.
          {" " + formaterPriceFunction(item.precio_extra + "")}
        </span>
      </div>
      <div className="col-lg-2 d-flex align-items-center flex-wrap my-2">
        <Button
          className="btn-cs1 delete-btn w-100 text-light"
          variant="contained"
          onClick={() => deleteProductColor(item.id_new_detalle_color)}
        >
          Eliminar
        </Button>
      </div>
    </>
  );
};

export default ProductColor;
