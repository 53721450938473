import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from "../../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import DataTable from "react-data-table-component";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// styles
import "./custom.css";

//icons

// components

//data
let formatos = [
  {
    id: "1",
    name: "formato 1",
    date: "2020-10-10",
    isFavorite: false,
  },
  {
    id: "22",
    name: "formato 2",
    date: "2020-10-11",
    isFavorite: false,
  },
  {
    id: "3",
    name: "formato test",
    date: "2020-10-11",
    isFavorite: true,
  },
];

export default function Users_all(props) {
  let history = useHistory();
  const moment = require("moment");

  //component did mount
  useEffect(() => {
    getInvoices();
  }, []);

  // states
  const [invoicesLoader, setInvoicesLoader] = useState(true);
  const [allInvoices, setAllInvoices] = useState([]);

  const getInvoices = () => {
    setInvoicesLoader(true);
    API.admin_invoices
      .getAll()
      .then((res) => {
        let response = res.data;
        console.log(response.data);
        if (response.success) {
          setAllInvoices(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setInvoicesLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log("Ha ocurrido un error", err, response);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
        setInvoicesLoader(false);
      });
  };

  const formatosAction = (event, id) => {
    if (event.target.value === "ver") {
      history.push(`/admin/formatos/${id}`);
    }
    if (event.target.value === "eliminar") {
      deleteInvoice(id);
    }
    if (event.target.value === "duplicar") {
      duplicateInvoice(id);
    }
  };

  const deleteInvoice = (id) => {
    API.admin_invoices
      .deleteInvoice({ factura_id: id })
      .then((res) => {
        let response = res.data;
        console.log(response.data);
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          getInvoices();
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log("Ha ocurrido un error", err, response);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const duplicateInvoice = (id) => {
    API.admin_invoices
      .duplicateInvoice({ factura_id: id })
      .then((res) => {
        let response = res.data;
        console.log(response.data);
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          getInvoices();
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log("Ha ocurrido un error", err, response);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  return (
    <div id="dashboard-section">
      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Galería de plantillas de facturas</strong>
              </h5>
              {/* <p className="mb-0">Cree, edite o elimine formatos de facturación..</p> */}
            </div>

            {/* <Button
							className="btn-cs1"
							variant="contained"
							color="primary"
							startIcon={<AddIcon />}
							onClick={(e) => null}
							component={Link}
							to={'/admin/usuarios/crear'}
						>
							<span style={{ marginBottom: '-4px' }}>Añadir usuario</span>
						</Button> */}
          </div>
        </div>
        <div className="card-body">
          <div className="products-table user-table hide-checkbox mt-1">
            {invoicesLoader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <div>
                <DataTable
                  className="datatable-cs datatable-cs-v2 datatable-h-80"
                  title={"s"}
                  columns={[
                    {
                      name: "Nombre del formato",
                      selector: "nombre",
                      sortable: true,
                    },
                    {
                      name: "Fecha / Hora",
                      selector: "fecha",
                      sortable: true,
                      cell: (row) => (
                        <div className="w-100 text-center">
                          {moment(row.fecha).format("L")}{" "}
                          {moment(row.fecha).format("LTS")}
                        </div>
                      ),
                    },

                    {
                      name: "Acciones",
                      selector: "action",
                      cell: (row) => (
                        <div className="text-center w-100">
                          <FormControl variant="outlined">
                            <Select
                              native
                              value=""
                              onChange={(e) =>
                                formatosAction(e, row.factura_id)
                              }
                              className="cs-select"
                            >
                              <option value="">Acciones</option>
                              <option value="ver">Ver</option>
                              <option value="eliminar">Eliminar</option>
                              <option value="duplicar">Duplicar</option>
                            </Select>
                          </FormControl>
                        </div>
                      ),
                    },
                  ]}
                  data={allInvoices}
                  defaultSortField="fecha"
                  defaultSortAsc={false}
                  pagination
                  paginationPerPage={10}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  noDataComponent="No se ha encontrado ningún resultado"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
