import React, { useState, useEffect, useContext, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import DataTable from "react-data-table-component";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import TabPanel from "../../components/TabPanel";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Pagination } from "@material-ui/lab";
import API from "../../api";
import { toast } from "react-toastify";
import { base_url_images, realImageURL } from "../../api";
// styles
import "./custom.css";

// validaciones de sala de ventas
import { isCmi } from "../../helpers/isCmi";
import { isAdmin } from "../../helpers/isAdmin";
import { isAleko } from "../../helpers/isAleko";

// moneda
import { getMoneda } from "../../helpers/moneda";

//icons
import LocalMallIcon from "@material-ui/icons/LocalMall";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import useAlekoProducts from "./utils/getAlekoProducts";
import WithAleko from "../../hoc/WithAleko";
import ProductPersonalizationContext from "../../context/PersonalizacionProducto/ProductPersonalizationContext";
import InputIdp from "./InputIdp";
import ChildrenInput from "./ProdHijoInput";

// components

import ModalApertura from "./ModalApertura";
import { existeApertura, getRetiroCaja } from "../../api/sales";
import { AssignmentReturnTwoTone, ErrorOutlineSharp } from "@material-ui/icons";
import moment from "moment";
import { validarCorte } from "../../api/cajas";
import ModalLimite from "./LimiteMaximo";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";

//data

export default function Sell(props) {
  // Moneda
  const refQty = useRef(null);
  const searchInputRef = useRef(null);

  const moneda = getMoneda();
  // states
  const [currentCell, setCurrentCell] = useState("");
  const aleko = localStorage.getItem("aleko");
  const {
    createAlekoOrder,
    set_id_sucursal,
    clearState,
    setCheck,
    deleteCustomizeProduct,
    check,
    products,
  } = useContext(ProductPersonalizationContext);
  const [store, setStore] = useState("");
  const [weightedProductFilter, setWeightedProductFilter] = useState("");
  const [caja, setCaja] = useState("");
  const [seller, setSeller] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [productCategory, setProductCategory] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [page_0, setPage_0] = useState(0);
  const [page_1, setPage_1] = useState(0);
  const [page_2, setPage_2] = useState(0);
  const [page_3, setPage_3] = useState(0);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [mostSaleProducts, setMostSaleProducts] = useState([]);
  const [filteredMostSaleProducts, setFilteredMostSaleProducts] = useState([]);
  const [paginatedMostSaleProducts, setPaginatedMostSaleProducts] = useState(
    []
  );
  const [lastSoldProducts, setLastSoldProducts] = useState([]);
  const [filteredLastSoldProducts, setFilteredLastSoldProducts] = useState([]);
  const [paginatedLastSoldProducts, setPaginatedLastSoldProducts] = useState(
    []
  );
  const [alekoProducts, setAlekoProducts] = useState([]);
  const [filteredAlekoProducts, setFilteredAlekoProducts] = useState([]);
  const [paginatedAlekoProducts, setPaginatedAlekoProducts] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [categories, setCategories] = useState([
    {
      id_clasificacion1_web_app: "",
      descripcion: "Todas las categorias",
    },
  ]);
  const [weightedProduct, setWeightedProduct] = useState({});
  const [typeSell, setTypesell] = useState("1");
  const [controlFocus, setControlFocus] = useState(false);

  const [salesCost, setSalesCost] = useState({
    total: 0,
    subtotal: 0,
    iva: 0,
  });
  const [sucursals, setSucursals] = useState([
    {
      id_sucursal_bodega_ubicacion: "",
      nombre_sucursal: "",
    },
  ]);
  const [cajas, setCajas] = useState([
    {
      id_caja_web_app: "",
      descripcion: "",
    },
  ]);
  const [sellers, setSellers] = useState([
    {
      id_vendedor_web_app: "",
      nombre: "",
    },
  ]);
  const [empresaInfo, setEmpresaInfo] = useState({
    monedas: [],
  });

  console.log("Products in Context", products);

  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    console.log(newValue);
    return newValue;
  };

  const [currency, setCurrency] = useState("");
  const [readingBarcode, setReadingBarcode] = useState(false);
  const [selected_product, setSelected_product] = useState({});
  const [quantity_addition, setQuantity_addition] = useState("");
  const [attentionChannels, setAttentionChannels] = useState([]);
  const [attentionChannel, setAttentionChannel] = useState("");
  const maxPerPage = 9;
  const [alekoValidation] = useAlekoProducts(currentTab, selectedProducts);
  const [aproxWeight, setAproxWeight] = useState("");
  const [open, setOpen] = React.useState(false);
  const [habilitarSucursalsParaCajero, setHabilitarSucursalsParaCajero] =
    useState(false);
  const { setModalDiaAnterior } = useContext(CmiProductContext);
  const [totalProducts, setTotalProducts] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [aperturaInfo, setAperturaInfo] = useState(null);
  const [limite, setLimite] = useState(false);
  const [paginate, setPaginate] = useState(false);
  let history = useHistory();

  // validación de cajero para cmi
  useEffect(() => {
    if (isCmi() && sellers.id_vendedor_web_app != "") {
      let user = JSON.parse(window.localStorage.getItem("pms"));

      if (user?.tipo_usuario) {
        if (user.tipo_usuario.toLowerCase() == "cajero") {
          setHabilitarSucursalsParaCajero(true);
        }
      }
    }
  }, [sellers]);

  //cleap up - componentWillUnmount
  useEffect(() => {
    localStorage.setItem(
      "tipo_venta",
      JSON.stringify({
        tipo_venta: parseInt(typeSell),
      })
    );
    return () => {
      $("#cancelSale").modal("hide");
      $("#weightScaleModal").modal("hide");
    };
  }, []);

  useEffect(() => {
    if (caja !== "") {
      getAllProducts(store, caja);
      getMostSaleProducts(store, caja);
      getLastSoldProducts(store, caja);
      if (isAleko()) {
        getAlekoProducts(store, caja);
      }
    } else {
      setFilteredProducts([]);
    }
  }, [caja]);

  // Filter by category
  useEffect(() => {
    handleProductsFiltering();
  }, [productCategory]);

  useEffect(() => {
    getExistingProducts();
    // clearState();
  }, [allProducts]);

  useEffect(() => {
    handlePagination(null, 1);

    if (cajas.length === 0) {
      setFilteredProducts([]);
    } else {
      if (filteredProducts.length === 1 && readingBarcode) {
        handleBoxClick(filteredProducts[0].id_new_item, true, false);
        setReadingBarcode(false);
        setFilterText("");
      }
    }
  }, [filteredProducts]);

  useEffect(() => {
    handlePagination(null, 1);
  }, [filteredMostSaleProducts]);

  useEffect(() => {
    handlePagination(null, 1);
  }, [filteredLastSoldProducts]);

  useEffect(() => {
    handlePagination(null, 1);
  }, [filteredAlekoProducts]);

  useEffect(() => {
    nuclearUpdate(filteredProducts);
    selectedProducts.map((product) => {
      handlePaginatedSelected(product.id_new_item);
    });
    if (!isLoading) {
      $("#friggin-loader").removeClass("d-block").addClass("d-none");
      $("#products-catalog").removeClass("d-none").addClass("d-flex");
    }
  }, [paginatedProducts]);

  useEffect(() => {
    nuclearUpdate(selectedProducts);
    selectedProducts.map((product) => {
      handlePaginatedSelected(product.id_new_item);
    });
  }, [selectedProducts]);

  useEffect(() => {
    nuclearUpdate(filteredMostSaleProducts);
    selectedProducts.map((product) => {
      handlePaginatedSelected(product.id_new_item);
    });
    $("#friggin-loader_2").removeClass("d-block").addClass("d-none");
    $("#products-catalog_2").removeClass("d-none").addClass("d-flex");
  }, [paginatedMostSaleProducts]);

  useEffect(() => {
    nuclearUpdate(filteredLastSoldProducts);
    selectedProducts.map((product) => {
      handlePaginatedSelected(product.id_new_item);
    });
    $("#friggin-loader_3").removeClass("d-block").addClass("d-none");
    $("#products-catalog_3").removeClass("d-none").addClass("d-flex");
  }, [paginatedLastSoldProducts]);

  useEffect(() => {
    nuclearUpdate(filteredAlekoProducts);
    selectedProducts.map((product) => {
      handlePaginatedSelected(product.id_new_item);
    });
    $("#friggin-loader_4").removeClass("d-block").addClass("d-none");
    $("#products-catalog_4").removeClass("d-none").addClass("d-flex");
  }, [paginatedAlekoProducts]);

  useEffect(() => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          // ordenamos segun la sucursal
          let sucursales_ordenadas = response.data;

          setSucursals(sucursales_ordenadas);
          setStore(sucursales_ordenadas[0].id_sucursal_bodega_ubicacion);
          getCajas(sucursales_ordenadas[0].id_sucursal_bodega_ubicacion);
          localStorage.setItem(
            "store",
            JSON.stringify({
              id_sucursal_bodega_ubicacion:
                sucursales_ordenadas[0].id_sucursal_bodega_ubicacion,
              nombre_sucursal: sucursales_ordenadas[0].nombre_sucursal,
            })
          );
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error al obtener las sucursales");
          //toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });

    getEmpresaInfo();
    getAttentionChannels();
  }, []);

  // console.log("store: ", store);

  useEffect(() => {
    if (weightedProduct?.check) {
      addWeightedProduct();
    }
  }, [weightedProduct]);

  useEffect(() => {
    switch (currentTab) {
      case 0:
        if (JSON.stringify(allProducts) !== "[]") {
          paginatedProducts.map((product) => {
            if (selectedProducts.includes(product))
              handlePaginatedSelected(product.id_new_item, false);
          });
        }
        break;
      case 1:
        if (JSON.stringify(mostSaleProducts) !== "[]") {
          paginatedMostSaleProducts.map((product) => {
            if (selectedProducts.includes(product))
              handlePaginatedSelected(product.id_new_item, false);
          });
        }
        break;
      case 2:
        if (JSON.stringify(lastSoldProducts) !== "[]") {
          paginatedLastSoldProducts.map((product) => {
            if (selectedProducts.includes(product))
              handlePaginatedSelected(product.id_new_item, false);
          });
        }
      case 3:
        if (JSON.stringify(alekoProducts) !== "[]") {
          paginatedAlekoProducts.map((product) => {
            if (selectedProducts.includes(product))
              handlePaginatedSelected(product.id_new_item, false);
          });
        }

        break;
      default:
        break;
    }
    handlePagination(null, 1);
  }, [currentTab]);

  const changeTypesell = (event) => {
    let existLocaltypeSell = JSON.parse(localStorage.getItem("tipo_venta"));
    if (existLocaltypeSell) {
      localStorage.removeItem("tipo_venta");
    }
    setTypesell(event.target.value);
    localStorage.setItem(
      "tipo_venta",
      JSON.stringify({
        tipo_venta: parseInt(event.target.value),
      })
    );
  };

  const getAllProducts = (id_sucursal, id_caja) => {
    setIsLoading(true);
    $("#friggin-loader").addClass("d-block");
    $("#products-catalog").removeClass("d-flex").addClass("d-none");
    API.products
      .getAll({
        id_sucursal_bodega_ubicacion: id_sucursal,
        id_caja: id_caja,
        nombre: filterText,
        pagination: true,
        page: 1,
        limit: 9,
      })
      .then((res) => {
        let response = res.data;
        setTotalProducts(res.data.total);
        if (response.success) {
          setPaginate(true);
          response.data = response.data.map((item) => ({
            ...item,
            cantidad_exacta: 0,
          }));
          setAllProducts(
            response.data.map((item) =>
              item.idp
                ? {
                    ...item,
                    precio_venta: item.precio_venta + item.impuesto_idp,
                    descuento_precio:
                      item.descuento_precio > 0
                        ? item.descuento_precio + item.impuesto_idp
                        : item.descuento_precio,
                  }
                : item
            )
          );
          setFilteredProducts(
            response.data.map((item) =>
              item.idp
                ? {
                    ...item,
                    precio_venta: item.precio_venta + item.impuesto_idp,
                    descuento_precio:
                      item.descuento_precio > 0
                        ? item.descuento_precio + item.impuesto_idp
                        : item.descuento_precio,
                  }
                : item
            )
          );
          set_categories(response.data);
        }
        setIsLoading(false);
        $("#friggin-loader").addClass("d-none");
        console.log("Changed gears from getAllProducts", filteredProducts);
        $("#products-catalog").addClass("d-flex");
      })
      .catch((err) => {
        console.log("Error", err, err.response);
        if (!err.response) {
          setIsLoading(false);
          return toast.error(err.message, { autoClose: 10000 });
        }
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error al obtener todos los productos");
          //toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        $("#friggin-loader").addClass("d-none");
        $("#products-catalog").addClass("d-flex");
        setIsLoading(false);
      });
  };

  const getMostSaleProducts = (id_sucursal, id_caja) => {
    $("#friggin-loader_2").addClass("d-block");
    $("#products-catalog_2").removeClass("d-flex").addClass("d-none");
    API.products
      .getMostSale({
        id_sucursal_bodega_ubicacion: id_sucursal,
        id_caja: id_caja,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setMostSaleProducts(response.data);
          setFilteredMostSaleProducts(response.data);
        }
        $("#friggin-loader_2").addClass("d-none");
        $("#products-catalog_2").addClass("d-flex");
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error los productos mas vendidos");
          // toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        $("#friggin-loader_2").addClass("d-none");
        $("#products-catalog_2").addClass("d-flex");
      });
  };

  const getLastSoldProducts = (id_sucursal, id_caja) => {
    $("#friggin-loader_3").addClass("d-block");
    $("#products-catalog_3").removeClass("d-flex").addClass("d-none");
    API.products
      .getLastSold({
        id_sucursal_bodega_ubicacion: id_sucursal,
        id_caja: id_caja,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setLastSoldProducts(response.data);
          setFilteredLastSoldProducts(response.data);
        }
        $("#friggin-loader_3").addClass("d-none");
        $("#products-catalog_3").addClass("d-flex");
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined") {
          if (response.data.message)
            toast.error(response.data.message, {
              autoClose: 10000,
            });
        } else {
          console.log(
            "Ha ocurrido un error al obtener los ultimos productos vendidos"
          );
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        $("#friggin-loader_3").addClass("d-none");
        $("#products-catalog_3").addClass("d-flex");
      });
  };

  const getAlekoProducts = (id_sucursal, id_caja) => {
    $("#friggin-loader_4").addClass("d-block");
    $("#products-catalog_4").removeClass("d-flex").addClass("d-none");
    API.products
      .getAll({
        id_sucursal_bodega_ubicacion: id_sucursal,
        id_caja: id_caja,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          const alekoProducts = response.data.filter((item) => item.aleko);
          setAlekoProducts(alekoProducts);
          setFilteredAlekoProducts(alekoProducts);
        }
        $("#friggin-loader_4").addClass("d-none");
        $("#products-catalog_4").addClass("d-flex");
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (!err.response) {
          setIsLoading(false);
          return toast.error(err.message, { autoClose: 10000 });
        }
        if (typeof response.data !== "undefined") {
          if (response.data.message)
            toast.error(response.data.message, {
              autoClose: 10000,
            });
        } else {
          console.log(
            "Ha ocurrido un error al obtener los ultimos productos vendidos"
          );
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        $("#friggin-loader_4").addClass("d-none");
        $("#products-catalog_4").addClass("d-flex");
      });
  };

  // set states
  const changeStore = (event) => {
    let parsedValue = parseInt(event.target.value);
    setStore(parsedValue);
    setCaja("");
    setSeller("");
    getCajas(parseInt(parsedValue));
    let currentSucursal = sucursals.find(
      (x) => x.id_sucursal_bodega_ubicacion === parsedValue
    );
    localStorage.setItem(
      "store",
      JSON.stringify({
        id_sucursal_bodega_ubicacion:
          currentSucursal.id_sucursal_bodega_ubicacion,
        nombre_sucursal: currentSucursal.nombre_sucursal,
      })
    );
  };

  const changeCaja = (event) => {
    let parsedValue = parseInt(event.target.value);
    setCaja(parsedValue);
    let currentCaja = cajas.find((x) => x.id_caja_web_app === parsedValue);
    getSellers(parsedValue);
    localStorage.setItem(
      "caja",
      JSON.stringify({
        id_caja_web_app: parsedValue,
        descripcion: currentCaja.descripcion,
      })
    );
  };

  const changeAttentionChannel = (event) => {
    let parsedValue = parseInt(event.target.value);
    setAttentionChannel(parsedValue);
    const currentChannel = attentionChannels.find(
      (x) => x.id_canal === parsedValue
    );
    localStorage.setItem(
      "attentionChannel",
      JSON.stringify({
        id_canal: parsedValue,
        descripcion: currentChannel.descripcion,
      })
    );
  };

  const getCajas = (value) => {
    console.log("Obtaining cajas with this sucursal", value);
    API.cajas
      .getCajas({ id_sucursal_bodega_ubicacion: value }, true)
      .then((res) => {
        let response = res.data;
        console.log("respuesta", response);
        if (response.success) {
          if (response.data.length > 0) {
            setCajas(response.data);
            getSellers(response.data[0].id_caja_web_app);
            localStorage.setItem(
              "caja",
              JSON.stringify({
                id_caja_web_app: response.data[0].id_caja_web_app,
                descripcion: response.data[0].descripcion,
              })
            );
            console.log(response.data);
            setCaja(response.data[0].id_caja_web_app);
            setStore(response.data[0].id_sucursal_bodega_ubicacion);
            // setSeller(response.data[0].id_vendedor);
          } else {
            setFilteredProducts([]);
            setCajas([]);
            toast.warning("No hay productos para esta sucursal", {
              autoClose: 10000,
            });
          }
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error al obtener las cajas");
          //toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const changeSeller = (event) => {
    let parsedValue = parseInt(event.target.value);
    setSeller(parsedValue);
    let currentSeller = sellers.find(
      (x) => x.id_vendedor_web_app === parsedValue
    );
    localStorage.setItem(
      "seller",
      JSON.stringify({
        id_vendedor_web_app: currentSeller.id_vendedor_web_app,
        nombre: currentSeller.nombre,
      })
    );
  };

  const getSellers = (value) => {
    API.cajas
      .getVendedores({ id_caja_web_app: value }, true)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSellers(response.data);
          setSeller(response.data[0].id_vendedor_web_app);
          if (response.data.length > 0) {
            localStorage.setItem(
              "seller",
              JSON.stringify({
                id_vendedor_web_app: response.data[0].id_vendedor_web_app,
                nombre: response.data[0].nombre,
              })
            );
          }
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setSeller([]);
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error al obtener los vendedores");
        //toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getEmpresaInfo = () => {
    API.userConfig
      .getEmpresaInfo({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Company data:", response.data);
          setEmpresaInfo(response.data);
          setCurrency(response.data.id_moneda_web_app);
          localStorage.setItem(
            "currency",
            JSON.stringify({
              id_moneda_web_app: response.data.monedas[0].id_moneda_web_app,
              descripcion: response.data.monedas[0].descripcion.trim(),
            })
          );
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);

        console.log("Ha ocurrido un error", err.response);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const changeCurrency = (event) => {
    let parsedValue = parseInt(event.target.value);
    setCurrency(parsedValue);
    let currentCurrency = empresaInfo.monedas.find(
      (x) => x.id_moneda_web_app === parsedValue
    );
    localStorage.setItem(
      "currency",
      JSON.stringify({
        id_moneda_web_app: currentCurrency.id_moneda_web_app,
        descripcion: currentCurrency.descripcion.trim(),
      })
    );
  };

  const changeCategory = (event) => {
    const category = event.target.value;
    setProductCategory(category);
  };

  const handleProductTabs = (event, newValue) => {
    if (currentTab !== newValue) {
      switch (newValue) {
        case 0:
          if ($("#friggin-loader").hasClass("d-none")) {
            $("#friggin-loader").removeClass("d-none").addClass("d-block");
          }

          if ($("#products-catalog").hasClass("d-flex")) {
            $("#products-catalog").removeClass("d-flex").addClass("d-none");
          }
          break;
        case 1:
          if ($("#friggin-loader_2").hasClass("d-none")) {
            $("#friggin-loader_2").removeClass("d-none").addClass("d-block");
          }

          if ($("#products-catalog_2").hasClass("d-flex")) {
            $("#products-catalog_2").removeClass("d-flex").addClass("d-none");
          }
          break;
        case 2:
          if ($("#friggin-loader_3").hasClass("d-none")) {
            $("#friggin-loader_3").removeClass("d-none").addClass("d-block");
          }

          if ($("#products-catalog_3").hasClass("d-flex")) {
            $("#products-catalog_3").removeClass("d-flex").addClass("d-none");
          }
          break;
        case 3:
          if ($("#friggin-loader_4").hasClass("d-none")) {
            $("#friggin-loader_4").removeClass("d-none").addClass("d-block");
          }

          if ($("#products-catalog_4").hasClass("d-flex")) {
            $("#products-catalog_4").removeClass("d-flex").addClass("d-none");
          }
          break;

        default:
          break;
      }
      setCurrentTab(newValue);
    }
  };

  const handlePaginatedSelected = (productId) => {
    let currentElement = $(`#product-${productId}`);
    currentElement.addClass("product-selected");
    $(`#product-${productId} > .product-body`).addClass("product-selected");
    $(`#product-icon-${productId}`).removeClass("d-none");
  };

  const handleBoxClick = (productId, setLocals, toDelete) => {
    console.log("click");
    if (isCmi()) {
      if (!aperturaInfo) return;
      if (limite) {
        setModalDiaAnterior(true);
        return;
      }
      if (!aperturaInfo.ok) {
        handleOpen();
        return;
      }
    }

    let currentElement = $(`#product-${productId}`);
    const productsAndAleko = [
      ...filteredProducts,
      ...allProducts,
      ...mostSaleProducts,
      ...lastSoldProducts,
    ];
    let currentProduct = productsAndAleko.find(
      (x) => x.id_new_item === productId
    );
    if (selectedProducts.length) {
      let idpCheck = selectedProducts.some((product) => product.idp);
      let regularCheck = selectedProducts.some((product) => !product.idp);
      let alekoCheck = selectedProducts.some((product) => product.aleko);

      if (
        (idpCheck && !currentProduct?.idp) ||
        (regularCheck && currentProduct?.idp)
      ) {
        return toast.error(
          "No es posible vender productos normales e IDP en una misma factura"
        );
      }

      if (
        (alekoCheck && !currentProduct?.aleko) ||
        (!alekoCheck && currentProduct?.aleko)
      ) {
        return toast.error(
          "No es posible vender productos normales y Aleko en una misma factura"
        );
      }
    }

    if (!currentProduct) return;
    if (currentProduct.stock === 0 || currentProduct.stock < 0) {
      toast.error(
        "No puede seleccionar este producto porque no tiene existencia.",
        { autoClose: 10000 }
      );
    } else {
      if (currentElement.hasClass("product-selected") && toDelete) {
        currentElement.removeClass("product-selected");
        $(`#product-${productId} > .product-body`).removeClass(
          "product-selected"
        );
        $(`#product-icon-${productId}`).addClass("d-none");
      } else {
        let found_item = selectedProducts.find(
          (x) => x.id_new_item === productId
        );
        if (found_item && !toDelete) {
          setSelected_product(found_item);

          $("#addQuantityModal").modal();
          setControlFocus(true);
        }
        currentElement.addClass("product-selected");
        $(`#product-${productId} > .product-body`).addClass("product-selected");
        $(`#product-icon-${productId}`).removeClass("d-none");
      }
      selectNewProduct(productId, setLocals, toDelete);
    }
  };

  const selectNewProduct = (productId, setLocals, toDelete) => {
    let existingProduct = selectedProducts.find(
      (x) => x.id_new_item === productId
    );

    console.log("pruebas2", existingProduct);

    //If product is already on the list, remove it
    if (existingProduct) {
      if (toDelete) {
        let auxArray = [...selectedProducts];
        auxArray = auxArray.filter((x) => x.id_new_item !== productId);
        if (setLocals) {
          setSelectedProducts(auxArray);
          localStorage.setItem("selectedProducts", JSON.stringify(auxArray));
        }
        // set sale costs
        let existingCost = salesCost;
        /* existingCost.subtotal = updateSubtotal(auxArray);
                existingCost.iva = updateIva(auxArray);
                existingCost.total = parseFloat(
                    (existingCost.subtotal + existingCost.iva).toFixed(2)
                ); */
        let total = 0;
        auxArray.map((element) => {
          if (parseFloat(element.descuento_precio) > 0.0) {
            total += element.descuento_precio * element.cantidad;
          } else {
            total += element.precio_venta * element.cantidad;
          }
        });
        existingCost.total = parseFloat(total.toFixed(2));
        if (setLocals) {
          setSalesCost(existingCost);
          localStorage.setItem("salesCost", JSON.stringify(existingCost));
        }
      }
    } else {
      // If product is not on the list, add it
      let currentProduct = [
        ...filteredProducts,
        ...allProducts,
        ...mostSaleProducts,
        ...lastSoldProducts,
      ].find((x) => x.id_new_item === productId);
      currentProduct.cantidad = 1;

      if (parseFloat(currentProduct.descuento_precio) > 0.0) {
        currentProduct.total = currentProduct.descuento_precio;
      } else {
        currentProduct.total = currentProduct.precio_venta;
      }

      if (currentProduct) {
        let newArray = [...selectedProducts];
        newArray.push(currentProduct);
        if (setLocals) {
          setSelectedProducts(newArray);
          localStorage.setItem("selectedProducts", JSON.stringify(newArray));
        }
        console.log("pruebas3", newArray);
        // set sales cost
        let existingCost = salesCost;
        /*                 existingCost.subtotal = updateSubtotal(newArray);
                                existingCost.iva = updateIva(newArray); */

        let total = 0;
        newArray.map((element) => {
          if (parseFloat(element.descuento_precio) > 0.0) {
            total += element.descuento_precio * element.cantidad;
          } else {
            total += element.precio_venta * element.cantidad;
          }
        });
        existingCost.total = parseFloat(total.toFixed(2));
        console.log("pruebas3", existingCost);
        if (setLocals) {
          setSalesCost(existingCost);
          localStorage.setItem("salesCost", JSON.stringify(existingCost));
        }
      }
    }
  };

  const changeProductQuantity = (productId, operation, byInput, val) => {
    let currentIndex = selectedProducts.findIndex(
      (x) => x.id_new_item === productId
    );
    let auxArray = [...selectedProducts];
    let existingCost = salesCost;

    console.log("pruebas", auxArray);
    console.log("pruebas", existingCost);
    if (auxArray[currentIndex]) {
      if (!byInput) {
        if (operation === "idp") {
          val = parseFloat(val);
          let denominador =
            auxArray[currentIndex].descuento_precio > 0
              ? auxArray[currentIndex].descuento_precio
              : auxArray[currentIndex].precio_venta;

          auxArray[currentIndex].cantidad = (val / denominador).toFixed(2);

          if (auxArray[currentIndex].cantidad > auxArray[currentIndex].stock) {
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
            return;
          }
          auxArray[currentIndex].total = isNaN(val) ? 0 : val;
          existingCost.total = auxArray
            .map((item) => item.total)
            .reduce((a, b) => a + b, 0);

          setSalesCost(existingCost);
          localStorage.setItem("salesCost", JSON.stringify(existingCost));

          setSelectedProducts(auxArray);
          localStorage.setItem("selectedProducts", JSON.stringify(auxArray));

          return;
        }
        if (operation === "sum") {
          if (
            auxArray[currentIndex].cantidad + 1 >
            auxArray[currentIndex].stock
          ) {
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
          } else {
            auxArray[currentIndex].cantidad++;
            auxArray[currentIndex].total =
              parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
                ? auxArray[currentIndex].descuento_precio *
                  auxArray[currentIndex].cantidad
                : auxArray[currentIndex].precio_venta *
                  auxArray[currentIndex].cantidad;
            if (auxArray[currentIndex].idp) {
              auxArray[currentIndex].total +=
                auxArray[currentIndex].cantidad *
                auxArray[currentIndex].impuesto_idp;
            }
            auxArray[currentIndex].total = parseFloat(
              auxArray[currentIndex].total.toFixed(2)
            );
            /* existingCost.subtotal = updateSubtotal(auxArray);
                        existingCost.iva = updateIva(auxArray); */

            let total = 0;
            auxArray.map((element) => {
              if (parseFloat(element.descuento_precio) > 0.0) {
                total += element.descuento_precio * element.cantidad;
              } else {
                total += element.precio_venta * element.cantidad;
              }
            });
            existingCost.total = parseFloat(total.toFixed(2));
            setSalesCost(existingCost);
            localStorage.setItem("salesCost", JSON.stringify(existingCost));
          }
        }

        if (operation === "sub") {
          if (
            auxArray[currentIndex].cantidad - 1 >
            auxArray[currentIndex].stock
          ) {
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
          } else {
            if (auxArray[currentIndex].cantidad !== 1) {
              auxArray[currentIndex].cantidad--;
              auxArray[currentIndex].total =
                parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
                  ? auxArray[currentIndex].descuento_precio *
                    auxArray[currentIndex].cantidad
                  : auxArray[currentIndex].precio_venta *
                    auxArray[currentIndex].cantidad;

              auxArray[currentIndex].total = parseFloat(
                auxArray[currentIndex].total.toFixed(2)
              );
              /* existingCost.subtotal = updateSubtotal(auxArray);
                            existingCost.iva = updateIva(auxArray); */
              let total = 0;
              auxArray.map((element) => {
                if (parseFloat(element.descuento_precio) > 0.0) {
                  total += element.descuento_precio * element.cantidad;
                } else {
                  total += element.precio_venta * element.cantidad;
                }
              });
              existingCost.total = parseFloat(total.toFixed(2));
              setSalesCost(existingCost);
              localStorage.setItem("salesCost", JSON.stringify(existingCost));
            }
          }
        }
      } else {
        if (document.getElementById("value-" + productId)) {
          if (
            Math.abs(document.getElementById("value-" + productId).value) === 0
          )
            document.getElementById("value-" + productId).value = 1;
          if (
            Math.abs(document.getElementById("value-" + productId).value) >
            auxArray[currentIndex].stock
          ) {
            console.log(
              document.getElementById("value-" + productId).value,
              "is in fact greater than",
              auxArray[currentIndex].stock
            );
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
          } else {
            auxArray[currentIndex].cantidad = Math.abs(
              document.getElementById("value-" + productId).value
            );
            auxArray[currentIndex].total =
              parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
                ? auxArray[currentIndex].descuento_precio *
                  auxArray[currentIndex].cantidad
                : auxArray[currentIndex].precio_venta *
                  auxArray[currentIndex].cantidad;

            auxArray[currentIndex].total = parseFloat(
              auxArray[currentIndex].total.toFixed(2)
            );
            /* existingCost.subtotal = updateSubtotal(auxArray);
                        existingCost.iva = updateIva(auxArray); */

            let total = 0;
            auxArray.map((element) => {
              if (parseFloat(element.descuento_precio) > 0.0) {
                total += element.descuento_precio * element.cantidad;
              } else {
                total += element.precio_venta * element.cantidad;
              }
            });
            existingCost.total = parseFloat(total.toFixed(2));

            setSalesCost(existingCost);
            localStorage.setItem("salesCost", JSON.stringify(existingCost));
          }
        }
      }
      setSelectedProducts(auxArray);
      localStorage.setItem("selectedProducts", JSON.stringify(auxArray));
    }
  };

  const removeProduct = (productId) => {
    let currentIndex = selectedProducts.findIndex(
      (x) => x.id_new_item === productId
    );

    if (currentIndex !== -1) {
      let auxArray = [...selectedProducts];
      auxArray = auxArray.filter((x) => x.id_new_item !== productId);
      setSelectedProducts(auxArray);
      handleBoxClick(productId, true, true);
      localStorage.setItem("selectedProducts", JSON.stringify(auxArray));
    }
  };

  const handlePagination = (event, value) => {
    let data;
    let filteredData;
    switch (currentTab) {
      case 0:
        data = [...filteredProducts];
        filteredData = data.slice(
          maxPerPage * (value - 1),
          maxPerPage * (value - 1) + maxPerPage
        );
        if (JSON.stringify(data) !== "[]") {
          $("#friggin-loader").addClass("d-block");
          $("#products-catalog").addClass("d-none");
        }
        setPaginatedProducts(filteredData);
        setPage_0(value);
        break;
      case 1:
        data = [...filteredMostSaleProducts];
        filteredData = data.slice(
          maxPerPage * (value - 1),
          maxPerPage * (value - 1) + maxPerPage
        );
        if (JSON.stringify(data) !== "[]") {
          $("#friggin-loader_2").addClass("d-block");
          $("#products-catalog_2").addClass("d-none");
        }
        setPaginatedMostSaleProducts(filteredData);
        setPage_1(value);
        break;
      case 2:
        data = [...filteredLastSoldProducts];
        filteredData = data.slice(
          maxPerPage * (value - 1),
          maxPerPage * (value - 1) + maxPerPage
        );
        if (JSON.stringify(data) !== "[]") {
          $("#friggin-loader_3").addClass("d-block");
          $("#products-catalog_3").addClass("d-none");
        }
        setPaginatedLastSoldProducts(filteredData);
        setPage_2(value);
        break;
      case 3:
        data = [...filteredAlekoProducts];
        filteredData = data.slice(
          maxPerPage * (value - 1),
          maxPerPage * (value - 1) + maxPerPage
        );
        if (JSON.stringify(data) !== "[]") {
          $("#friggin-loader_4").addClass("d-block");
          $("#products-catalog_4").addClass("d-none");
        }
        setPaginatedAlekoProducts(filteredData);
        setPage_3(value);
        break;
      default:
        break;
    }
  };

  const gotoPayment = () => {
    if (isCmi()) {
      if (!aperturaInfo) return;
      if (limite) {
        setModalDiaAnterior(true);
        return;
      }
      if (!aperturaInfo.ok) {
        handleOpen();
        return;
      }
    }
    if (selectedProducts.length > 0) {
      if (alekoValidation) {
        createAlekoOrder(store, history);
        // history.push("/app/vender/customization");
      } else {
        history.push({
          pathname: "/app/vender/procesar",
        });
      }
    } else {
      toast.error("Debe escoger al menos 1 producto.", { autoClose: 10000 });
    }
  };

  const getExistingProducts = () => {
    if (isAleko()) {
      clearState();
    }
    if (!!localStorage.getItem("selectedProducts")) {
      let products = JSON.parse(localStorage.getItem("selectedProducts"));

      console.log("Obtaining from local storage", products);
      setSelectedProducts(products);
    }
    if (!!localStorage.getItem("salesCost")) {
      setSalesCost(JSON.parse(localStorage.getItem("salesCost")));
    }
  };

  const handleProductsFiltering = (no_query, readerInput) => {
    let query;
    if (no_query) {
      query = "";
    } else {
      query = filterText;
    }
    if (query.length === 13) {
      searchWeightedProduct(query, true);

      return;
    }
    if (readerInput) {
      filterProducts(readerInput, 1);
      setReadingBarcode(true);
    } else {
      filterProducts(query, 1);
    }
  };
  useEffect(() => {
    if (filterText.length === 13) {
      searchWeightedProduct(filterText, true);
    }
  }, [filterText]);

  const filterProducts = async (query, page = null) => {
    let queryObj = {};

    if (isNaN(+query)) {
      queryObj.nombre = query;
    } else {
      queryObj.codigo = query;
    }

    if (isAleko() && isNaN(+query)) {
      queryObj.nombre = query;
      queryObj.codigo = query;
    }

    const { data } = await API.products.getAll({
      id_sucursal_bodega_ubicacion: store,
      id_caja: caja,
      pagination: true,
      page: page || currentPage,
      limit: 9,
      ...queryObj,
    });

    setFilteredProducts(data.data);
    setTotalProducts(data.total);
    if (query) {
      setFilteredMostSaleProducts(
        mostSaleProducts.filter(
          (item) =>
            item.nombre.toLowerCase().includes(query.toLowerCase()) ||
            item.codigo.toLowerCase().includes(query.toLowerCase())
        )
      );
      setFilteredLastSoldProducts(
        lastSoldProducts.filter(
          (item) =>
            item.nombre.toLowerCase().includes(query.toLowerCase()) ||
            item.codigo.toLowerCase().includes(query.toLowerCase())
        )
      );
      setFilteredAlekoProducts(
        alekoProducts.filter(
          (item) =>
            item.nombre.toLowerCase().includes(query.toLowerCase()) ||
            item.codigo.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
    if (!query) {
      setFilteredMostSaleProducts(mostSaleProducts);
      setFilteredLastSoldProducts(lastSoldProducts);
      setFilteredAlekoProducts(alekoProducts);
    }
    return;
    if (query === "") {
      if (productCategory === "") {
        setFilteredProducts(allProducts);
        setFilteredMostSaleProducts(mostSaleProducts);
        setFilteredLastSoldProducts(lastSoldProducts);
        setFilteredAlekoProducts(alekoProducts);
      } else {
        setFilteredProducts(
          allProducts.filter(
            (item) => item.id_clasificacion1_web_app == productCategory
          )
        );
        setFilteredMostSaleProducts(
          mostSaleProducts.filter(
            (item) => item.id_clasificacion1_web_app == productCategory
          )
        );
        setFilteredLastSoldProducts(
          lastSoldProducts.filter(
            (item) => item.id_clasificacion1_web_app == productCategory
          )
        );
        setFilteredAlekoProducts(
          alekoProducts.filter(
            (item) => item.id_clasificacion1_web_app == productCategory
          )
        );
      }
    } else {
      if (productCategory === "") {
        setFilteredProducts(
          allProducts.filter(
            (item) =>
              item.nombre.toLowerCase().includes(query.toLowerCase()) ||
              item.codigo.toLowerCase().includes(query.toLowerCase())
          )
        );
        setFilteredMostSaleProducts(
          mostSaleProducts.filter(
            (item) =>
              item.nombre.toLowerCase().includes(query.toLowerCase()) ||
              item.codigo.toLowerCase().includes(query.toLowerCase())
          )
        );
        setFilteredLastSoldProducts(
          lastSoldProducts.filter(
            (item) =>
              item.nombre.toLowerCase().includes(query.toLowerCase()) ||
              item.codigo.toLowerCase().includes(query.toLowerCase())
          )
        );
        setFilteredAlekoProducts(
          alekoProducts.filter(
            (item) =>
              item.nombre.toLowerCase().includes(query.toLowerCase()) ||
              item.codigo.toLowerCase().includes(query.toLowerCase())
          )
        );
      } else {
        setFilteredProducts(
          allProducts.filter(
            (item) =>
              item.id_clasificacion1_web_app == productCategory &&
              (item.nombre.toLowerCase().includes(query.toLowerCase()) ||
                item.codigo.toLowerCase().includes(query.toLowerCase()))
          )
        );
        setFilteredMostSaleProducts(
          mostSaleProducts.filter(
            (item) =>
              item.id_clasificacion1_web_app == productCategory &&
              (item.nombre.toLowerCase().includes(query.toLowerCase()) ||
                item.codigo.toLowerCase().includes(query.toLowerCase()))
          )
        );
        setFilteredLastSoldProducts(
          lastSoldProducts.filter(
            (item) =>
              item.id_clasificacion1_web_app == productCategory &&
              (item.nombre.toLowerCase().includes(query.toLowerCase()) ||
                item.codigo.toLowerCase().includes(query.toLowerCase()))
          )
        );
        setFilteredAlekoProducts(
          alekoProducts.filter(
            (item) =>
              item.id_clasificacion1_web_app == productCategory &&
              (item.nombre.toLowerCase().includes(query.toLowerCase()) ||
                item.codigo.toLowerCase().includes(query.toLowerCase()))
          )
        );
      }
    }
  };

  const updateFilterText = (e) => {
    let currentValue = filterText;
    console.log("YO", e.target.value.length);
    if (
      Math.abs(currentValue.length - e.target.value.length) > 1 &&
      e.target.value.length > 1
    ) {
      console.log("READING BARCODE");

      handleProductsFiltering(true, e.target.value);
    }
    setFilterText(e.target.value);
  };

  const updateWeightedProductFilter = (e) => {
    let currentValue = weightedProductFilter;
    if (
      Math.abs(currentValue.length - e.target.value.length) > 1 &&
      e.target.value.length > 1
    ) {
      console.log("READING BARCODE");

      searchWeightedProduct(e.target.value);
    }
    setWeightedProductFilter(e.target.value);
  };

  const searchWeightedProduct = (value, check) => {
    API.products
      .getFromBarcode({
        codigo_barras: value || weightedProductFilter,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setWeightedProduct({ ...response.data, check });

          setFilterText("");
        } else {
          toast.error(response.message, { autoClose: 10000 });
        }
      })

      .catch((err) => {
        console.log(err, err.response);
        let response = err.response;
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, { autoClose: 10000 });
          } else {
            toast.error(
              "Ha ocurrido un error al buscar el producto con codigo de barras",
              { autoClose: 10000 }
            );
          }
        }
      });
  };

  const houseKeeping = () => {
    const toDelete = ["selectedProducts", "salesCost","id_post_movimiento_posfactura","datosCliente"];
    toDelete.map((value) => {
      localStorage.removeItem(value);
    });
    selectedProducts.map((product) =>
      handleBoxClick(product.id_new_item, true, true)
    );
    setSelectedProducts([]);
    $("#cancelSale").modal("hide");
  };

  const updateIva = (array) => {
    let iva = 0;
    let price;
    let discountPrice;
    let discount;
    let taxableAmount;
    array.map((product) => {
      price = product.cantidad * product.precio_venta;
      discountPrice = product.cantidad * product.descuento_precio;
      discount = price - discountPrice;
      if (parseInt(product.descuento_precio) > 0) {
        taxableAmount = (price - discount) / 1.12;
        iva += price - discount - taxableAmount;
      } else {
        taxableAmount = price / 1.12;
        iva += price - taxableAmount;
      }
    });
    return parseFloat(iva.toFixed(2));
  };

  const updateSubtotal = (array) => {
    let price;
    let discountPrice;
    let discount;
    let taxableAmount;
    let totalBill = 0;
    array.map((product) => {
      price = product.cantidad * product.precio_venta;
      discountPrice = product.cantidad * product.descuento_precio;
      if (parseInt(product.descuento_precio) > 0) {
        discount = price - discountPrice;
        taxableAmount = (price - discount) / 1.12;
      } else {
        discount = price;
        taxableAmount = price / 1.12;
      }
      totalBill += taxableAmount;
    });
    return parseFloat(totalBill.toFixed(2));
  };

  const getPrice = (product) => {
    let price;
    let discountPrice;
    let discount;
    let taxableAmount;
    let iva;
    price = product.precio_venta;
    discountPrice = product.descuento_precio;
    discount = price - discountPrice;
    if (parseInt(product.descuento_precio) > 0) {
      taxableAmount = (price - discount) / 1.12;
      iva = price - discount - taxableAmount;
    } else {
      taxableAmount = price / 1.12;
      iva = price - taxableAmount;
    }
    let total = taxableAmount + iva;
    return parseFloat(total.toFixed(2));
  };

  const set_categories = (productsList) => {
    API.products
      .getProductAttributes({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          let categoriesArray = [];
          productsList.map((product) => {
            if (product.id_clasificacion1_web_app)
              categoriesArray.push(product.id_clasificacion1_web_app);
          });
          let categoriesState = [];
          let categoriesSet = new Set(categoriesArray);

          Array.from(categoriesSet).map((id) => {
            categoriesState.push(
              response.data.categories.find(
                (x) => x.id_clasificacion1_web_app === id
              )
            );
          });
          setCategories(sortAlphabetically(categoriesState));
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("Ha ocurrido un error al fijar las categorias");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const nuclearUpdate = (array) => {
    array.map((product) => {
      let currentElement = $(`#product-${product.id_new_item}`);
      if (currentElement.hasClass("product-selected")) {
        currentElement.removeClass("product-selected");
        $(`#product-${product.id_new_item} > .product-body`).removeClass(
          "product-selected"
        );
        $(`#product-icon-${product.id_new_item}`).addClass("d-none");
      }
    });
  };

  const resetSearch = () => {
    setFilterText("");
    handleProductsFiltering(true);
  };

  const sortAlphabetically = (array) => {
    let sortedArray = array.sort(function (a, b) {
      if (a.descripcion < b.descripcion) {
        return -1;
      }
      if (a.descripcion > b.descripcion) {
        return 1;
      }
      return 0;
    });
    return sortedArray;
  };

  const addWeightedProduct = () => {
    let existingProduct = selectedProducts.find(
      (x) => x.id_new_item === weightedProduct.id_new_item
    );
    let existingCost = salesCost;
    let auxArray = [...selectedProducts];

    if (existingProduct) {
      let currentIndex = selectedProducts.findIndex(
        (x) => x.id_new_item === weightedProduct.id_new_item
      );

      if (
        auxArray[currentIndex].cantidad + weightedProduct.peso >
        auxArray[currentIndex].stock
      ) {
        toast.error(
          "No puede agregar esa cantidad porque no hay suficiente en el inventario",
          { autoClose: 10000 }
        );
      } else {
        auxArray[currentIndex].cantidad = parseFloat(
          (weightedProduct.peso + auxArray[currentIndex].cantidad).toFixed(5)
        );
        auxArray[currentIndex].total =
          parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
            ? auxArray[currentIndex].descuento_precio *
              auxArray[currentIndex].cantidad
            : auxArray[currentIndex].precio_venta *
              auxArray[currentIndex].cantidad;

        auxArray[currentIndex].total = parseFloat(
          auxArray[currentIndex].total.toFixed(2)
        );

        setSelectedProducts(auxArray);
        localStorage.setItem("selectedProducts", JSON.stringify(auxArray));

        let total = 0;
        auxArray.map((element) => {
          if (parseFloat(element.descuento_precio) > 0.0) {
            total += element.descuento_precio * element.cantidad;
          } else {
            total += element.precio_venta * element.cantidad;
          }
        });
        existingCost.total = parseFloat(total.toFixed(2));
        setSalesCost(existingCost);
        localStorage.setItem("salesCost", JSON.stringify(existingCost));
      }
      $("#weightScaleModal").modal("hide");
      setWeightedProductFilter("");
      setWeightedProduct({});
      return;
    }

    if (!weightedProduct.nombre) {
      toast.error("No se tiene seleccionado ningun producto pesado.", {
        autoClose: 10000,
      });
      return;
    }

    let currentProduct = weightedProduct;
    currentProduct.cantidad = weightedProduct.peso;

    if (parseFloat(currentProduct.descuento_precio) > 0.0) {
      currentProduct.total = parseFloat(
        (currentProduct.descuento_precio * currentProduct.cantidad).toFixed(2)
      );
    } else {
      currentProduct.total = parseFloat(
        (currentProduct.precio_venta * currentProduct.cantidad).toFixed(2)
      );
    }

    if (currentProduct) {
      if (currentProduct.stock < weightedProduct.peso) {
        toast.error(
          "No puede agregar esa cantidad porque no hay suficiente en el inventario",
          { autoClose: 10000 }
        );
        return;
      }

      auxArray.push({ ...currentProduct, pesado: true });
      setSelectedProducts(auxArray);
      localStorage.setItem("selectedProducts", JSON.stringify(auxArray));

      // set sales cost
      let total = 0;
      auxArray.map((element) => {
        if (parseFloat(element.descuento_precio) > 0.0) {
          total += parseFloat(
            (element.descuento_precio * element.cantidad).toFixed(2)
          );
        } else {
          total += parseFloat(
            (element.precio_venta * element.cantidad).toFixed(2)
          );
        }
      });
      existingCost.total = parseFloat(total.toFixed(2));
      setSalesCost(existingCost);
      localStorage.setItem("salesCost", JSON.stringify(existingCost));
      $("#weightScaleModal").modal("hide");
      setWeightedProductFilter("");
      setWeightedProduct({});
    } else {
      toast.error("El producto no esta en el catalogo actual.", {
        autoClose: 10000,
      });
    }
  };

  const addProductQuantity = () => {
    let auxArray = [...selectedProducts];
    let existingCost = salesCost;
    let found_item = selectedProducts.find(
      (x) => x.id_new_item === selected_product.id_new_item
    );
    let found_index = selectedProducts.findIndex(
      (x) => x.id_new_item === selected_product.id_new_item
    );

    if (!found_item) {
      toast.error("El producto no ha sido encontrado.", { autoClose: 10000 });
      return;
    }

    if (
      parseFloat(quantity_addition) +
        parseFloat(auxArray[found_index].cantidad) >
      auxArray[found_index].stock
    ) {
      toast.error(
        "No puede agregar esa cantidad porque no hay suficiente en el inventario",
        { autoClose: 10000 }
      );
      return;
    }
    auxArray[found_index].cantidad = Number(
      (
        Number(quantity_addition) + Number(auxArray[found_index].cantidad)
      ).toFixed(5)
    );
    auxArray[found_index].total =
      parseFloat(auxArray[found_index].descuento_precio) > 0.0
        ? auxArray[found_index].descuento_precio *
          auxArray[found_index].cantidad
        : auxArray[found_index].precio_venta * auxArray[found_index].cantidad;

    auxArray[found_index].total = parseFloat(
      auxArray[found_index].total.toFixed(2)
    );
    /* existingCost.subtotal = updateSubtotal(auxArray);
        existingCost.iva = updateIva(auxArray); */

    let total = 0;
    auxArray.map((element) => {
      if (parseFloat(element.descuento_precio) > 0.0) {
        total += element.descuento_precio * element.cantidad;
      } else {
        total += element.precio_venta * element.cantidad;
      }
    });
    existingCost.total = parseFloat(total.toFixed(2));

    setSalesCost(existingCost);
    localStorage.setItem("salesCost", JSON.stringify(existingCost));
    setSelectedProducts(auxArray);
    localStorage.setItem("selectedProducts", JSON.stringify(auxArray));

    setQuantity_addition("");
    $("#addQuantityModal").modal("hide");
  };

  const getAttentionChannels = () => {
    API.attentionChannels
      .getAll(1)
      .then((res) => {
        let response = res.data;

        if (response.success) {
          setAttentionChannels(response.data);
          localStorage.setItem(
            "attentionChannel",
            JSON.stringify({
              id_canal: response.data[0].id_canal,
              descripcion: response.data[0].descripcion,
            })
          );
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log(
            "Ha ocurrido un error al obtener los canales de atencion"
          );
          toast.error(
            "Ha ocurrido un error al obtener los canales de atencion",
            { autoClose: 10000 }
          );
        }
      });
  };

  const updateFreePrice = (event, item) => {
    let existingSelectedProducts = [...selectedProducts];
    let index = existingSelectedProducts.findIndex(
      (x) => x.id_new_item === item.id_new_item
    );
    if (index === -1) {
      toast.error("El producto no existe en la lista.", { autoClose: 10000 });
      return;
    }
    let existingOriginalProducts = [...allProducts];
    let original_index = existingOriginalProducts.findIndex(
      (x) => x.id_new_item === item.id_new_item
    );

    let parsedValue = isNaN(parseFloat(event.target.value))
      ? 0
      : Math.abs(parseFloat(event.target.value));

    if (parsedValue < existingSelectedProducts[index].costo_unitario) {
      toast.error(
        `Disculpe, el precio libre de [${
          existingSelectedProducts[index].nombre || "Producto"
        }] supera 
al costo del producto.`,
        { autoClose: 10000 }
      );
      document.getElementById(`free-price-${item.id_new_item}`).value =
        existingSelectedProducts[index].descuento_precio > 0
          ? existingSelectedProducts[index].descuento_precio
          : existingSelectedProducts[index].precio_venta;
      existingSelectedProducts[index].precio_modificado = 0;
      existingSelectedProducts[index].descuento_precio =
        existingOriginalProducts[original_index].descuento_precio;
      existingSelectedProducts[index].precio_venta =
        existingOriginalProducts[original_index].precio_venta;
      setSelectedProducts(existingSelectedProducts);
      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(existingSelectedProducts)
      );

      updateProductTotal(
        existingSelectedProducts[index].id_new_item,
        document.getElementById(`free-price-${item.id_new_item}`).value
      );
      return;
    }

    if (parsedValue <= 0) {
      toast.error("El precio debe ser mayor a 0", { autoClose: 10000 });
      document.getElementById(`free-price-${item.id_new_item}`).value =
        existingSelectedProducts[index].descuento_precio > 0
          ? existingSelectedProducts[index].descuento_precio
          : existingSelectedProducts[index].precio_venta;
      existingSelectedProducts[index].precio_modificado = 0;
      existingSelectedProducts[index].descuento_precio =
        existingOriginalProducts[original_index].descuento_precio;
      existingSelectedProducts[index].precio_venta =
        existingOriginalProducts[original_index].precio_venta;
      setSelectedProducts(existingSelectedProducts);
      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(existingSelectedProducts)
      );

      updateProductTotal(
        existingSelectedProducts[index].id_new_item,
        document.getElementById(`free-price-${item.id_new_item}`).value
      );
      return;
    }
    event.target.value = isNaN(parseFloat(event.target.value))
      ? ""
      : event.target.value;

    existingSelectedProducts[index].descuento_precio = parsedValue;
    existingSelectedProducts[index].precio_modificado = 1;
    setSelectedProducts(existingSelectedProducts);
    localStorage.setItem(
      "selectedProducts",
      JSON.stringify(existingSelectedProducts)
    );

    updateProductTotal(
      existingSelectedProducts[index].id_new_item,
      parsedValue
    );
  };

  const updateProductTotal = (id, price) => {
    let existingSelectedProducts = [...selectedProducts];
    let existingCost = salesCost;
    let index = existingSelectedProducts.findIndex((x) => x.id_new_item === id);

    existingSelectedProducts[index].total =
      price * existingSelectedProducts[index].cantidad;

    existingSelectedProducts[index].total = parseFloat(
      existingSelectedProducts[index].total.toFixed(2)
    );

    let total = 0;
    existingSelectedProducts.map((element) => {
      if (parseFloat(element.descuento_precio) > 0.0) {
        total += element.descuento_precio * element.cantidad;
      } else {
        total += element.precio_venta * element.cantidad;
      }
    });
    setSelectedProducts(existingSelectedProducts);
    localStorage.setItem(
      "selectedProducts",
      JSON.stringify(existingSelectedProducts)
    );

    existingCost.total = parseFloat(total.toFixed(2));
    setSalesCost(existingCost);
    localStorage.setItem("salesCost", JSON.stringify(existingCost));
  };

  const getTotalEnLocalStorage = () => {
    let productosSeleccionados = JSON.parse(
      window.localStorage.getItem("selectedProducts")
    );
    if (!productosSeleccionados?.length) return;
    let precio = productosSeleccionados.map((item) => item.total);
    let total = precio.reduce((acc, el) => acc + el, 0);
    return total;
  };

  const handleAproxQty = (id, value) => {
    const newSelectedProducts = selectedProducts.map((product) =>
      product.id_new_item === id
        ? { ...product, cantidad_exacta: value }
        : product
    );
    setSelectedProducts(newSelectedProducts);
    localStorage.setItem(
      "selectedProducts",
      JSON.stringify(newSelectedProducts)
    );
  };

  const handleChildrenQty = (qty, id, idp) => {
    const t = qty;
    qty =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), idp ? 6 : 3)
        : t;
    //const cantidad = isNaN(+qty) ? 0 : parseFloat(Math.abs(qty));
    const cantidad = isNaN(+qty) ? 0 : qty;

    const currentProduct = selectedProducts.find(
      (product) => product.id_new_item === id
    );

    if (cantidad > currentProduct.stock) {
      const price =
        currentProduct.descuento_precio || currentProduct.precio_venta;
      const total = price * 0;
      const newSelectedProducts = selectedProducts.map((product) =>
        product.id_new_item === id
          ? { ...product, cantidad: 0, total }
          : product
      );
      setSelectedProducts(newSelectedProducts);
      localStorage.setItem(
        "salesCost",
        JSON.stringify({
          ...salesCost,
          total: newSelectedProducts.reduce((a, b) => a + b.total, 0),
        })
      );
      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(newSelectedProducts)
      );

      return toast.error("No hay suficiente stock ", {
        autoClose: 10000,
      });
    }

    const price =
      currentProduct.descuento_precio || currentProduct.precio_venta;
    let total = price * cantidad;
    if (total) {
      total = Number(total.toFixed(2));
    }

    const newSelectedProducts = selectedProducts.map((product) =>
      product.id_new_item === id ? { ...product, total, cantidad } : product
    );
    setSelectedProducts(newSelectedProducts);

    localStorage.setItem(
      "salesCost",
      JSON.stringify({
        ...salesCost,
        total: newSelectedProducts.reduce((a, b) => a + b.total, 0),
      })
    );

    localStorage.setItem(
      "selectedProducts",
      JSON.stringify(newSelectedProducts)
    );

    let auxArray = newSelectedProducts;
    console.log("gola array", auxArray);
    let total2 = 0;
    auxArray.map((element) => {
      if (parseFloat(element.descuento_precio) > 0.0) {
        let total = Number(
          (element.descuento_precio * element.cantidad).toFixed(2)
        );
        total2 += total;
      } else {
        let total = Number(
          (element.precio_venta * element.cantidad).toFixed(2)
        );
        total2 += total;
      }
    });

    let existingCost = salesCost;
    existingCost.total = parseFloat(total2.toFixed(2));
    console.log("gola3", existingCost);
    setSalesCost(existingCost);
    localStorage.setItem("salesCost", JSON.stringify(existingCost));
  };

  const orderBySucursal = (data) => {
    // extraemos la sucursal guardada
    let id_sucursal_local = window.localStorage.getItem("id_sucursal");
    let sucursal_filtrada = data.filter((item) =>
      item.id_sucursal_bodega_ubicacion == Number(id_sucursal_local)
        ? item.id_sucursal_bodega_ubicacion
        : ""
    );

    // onjetos que son diferentes a la extraidata
    let sucursales_diferentes = data.filter(
      (item) => item.id_sucursal_bodega_ubicacion != Number(id_sucursal_local)
    );

    let sucursales_ordenadas = [...sucursal_filtrada, ...sucursales_diferentes];
    return sucursales_ordenadas;
  };
  const [fondoCambios, setFondoCambios] = useState("");
  const getRetiro = async () => {
    const id_new_empresa = localStorage.getItem("id_empresa");
    const { data } = await getRetiroCaja({
      id_caja_web_app: caja,
      id_sucursal_bodega_ubicacion: store,
      id_vendedor_web_app: seller,
      id_new_empresa,
      retiro_fecha: moment(new Date()).format("YYYY-MM-DD"),
    });
    return data.dataRetiroCaja.reduce((a, b) => a + b.cantidad_retiro, 0);
  };
  useEffect(() => {
    const getSummary = async () => {
      if (caja !== "" && seller !== "" && store !== "" && isCmi()) {
        const { data } = await API.cajas.getOpenCajaValues({
          id_caja_web_app: caja,
          fecha: new Date(),
          id_vendedor_web_app: seller,
          id_sucursal_bodega_ubicacion: store,
        });
        setFondoCambios(formaterPriceFunction(data.data.total_cambios + ""));
        const retiros = await getRetiro();

        if (
          data.data.total_efectivo + data.data.total_cambios - retiros >=
          2500
        ) {
          setLimite(true);
        } else {
          setLimite(false);
        }
      }
    };
    getSummary();
  }, [caja, seller, store]);

  const [validarCorteCaja, setValidarCorteCaja] = useState(false);
  useEffect(() => {
    if (caja !== "" && seller !== "" && store !== "") {
      const validarDiaAnterior = async () => {
        const id_new_empresa = localStorage.getItem("id_empresa");
        try {
          const { data } = await validarCorte({
            id_new_empresa,
            id_sucursal_bodega_ubicacion: store,
            id_vendedor_web_app: seller,
            id_caja_web_app: caja,
            date_data: moment(new Date()).format("YYYY-MM-DD"),
          });
          setValidarCorteCaja(data.ok);
        } catch (error) {
          if (error.response) {
            const { data } = await API.cajas.getOpenCajaValues({
              id_caja_web_app: caja,
              fecha: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
              id_vendedor_web_app: seller,
              id_sucursal_bodega_ubicacion: store,
            });
            if (!data.data.total_monto_total) {
              setValidarCorteCaja(true);
            } else {
              setValidarCorteCaja(error.response.data.ok);
            }
          } else {
            console.log(error);
          }
        }
      };
      const getApertura = async () => {
        const id_new_empresa = localStorage.getItem("id_empresa");
        const id_new_user = JSON.parse(localStorage.getItem("pms")).id_new_user;
        try {
          const { data } = await existeApertura({
            id_new_user,
            id_new_empresa,
            apertura_fecha: moment(new Date()).format("YYYY-MM-DD"),
          });
          setAperturaInfo({ ok: data.ok });
        } catch (error) {
          console.log(error.message);

          setAperturaInfo({
            ok: error.response.data.ok,
            codigo_operacion: error.response.data.codigo_operacion,
          });
        }
      };
      validarDiaAnterior();
      getApertura();
    }
  }, [caja, seller, store]);

  useEffect(() => {
    set_id_sucursal(store);
  }, [store]);
  const [currentPage, setCurrentPage] = useState(1);
  const handleRealPagination = (e, value) => {
    setCurrentPage(value);
  };

  const handleGetPaginatedProducts = async (page) => {
    try {
      //paginacion por busqueda

      const { data } = await API.products.getAll({
        id_sucursal_bodega_ubicacion: store,
        id_caja: caja,
        pagination: true,
        page: page,
        nombre: filterText,
        limit: 9,
      });
      setFilteredProducts(data.data);
      setTotalProducts(data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (paginate) {
      handleGetPaginatedProducts(currentPage);
    }
  }, [currentPage]);
  const getEmptyRows = (prods) => {
    if (prods) {
      let empty = 9 - prods.length;
      let arr = [];
      for (let i = 0; i < empty; i++) {
        arr.push(i);
      }
      if (!prods.length) {
        return [];
      }
      return arr;
    } else {
      let empty = 9 - filteredProducts.length;
      let arr = [];
      for (let i = 0; i < empty; i++) {
        arr.push(i);
      }
      if (!filteredProducts.length) {
        return [];
      }
      return arr;
    }
  };
  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select
            native
            disabled={habilitarSucursalsParaCajero ? true : false}
            value={store}
            onChange={changeStore}
          >
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={caja} onChange={changeCaja}>
            {cajas.map((caja) => (
              <option value={caja.id_caja_web_app}>{caja.descripcion}</option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select
            native
            disabled={habilitarSucursalsParaCajero ? true : false}
            value={seller}
            onChange={changeSeller}
          >
            {sellers.map((seller) => (
              <option value={seller.id_vendedor_web_app}>
                {seller.nombre}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        /> */}
        {false && (
          <FormControl variant="outlined">
            <Select native value={currency} onChange={changeCurrency}>
              {empresaInfo.monedas.map((currency) => (
                <option value={currency.id_moneda_web_app}>
                  {currency.descripcion.trim()}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        {/* <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        /> */}
        {false && (
          <FormControl variant="outlined">
            <Select
              native
              value={attentionChannel}
              onChange={changeAttentionChannel}
            >
              {attentionChannels.map((channel) => (
                <option value={channel.id_canal}>
                  {channel.descripcion.trim()}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
      </div>

      <div className="row">
        {/* Left half */}
        <div className="col-xl-6">
          <div className="card content-card mt-2">
            <div className="card-header p-0" />
            <div className="card-body">
              <div className="row justify-content-center">
                {!isAleko() && (
                  <div className="col-md-3 text-center p-0">
                    <Button
                      variant="contained"
                      color="primary"
                      data-toggle="modal"
                      data-target="#weightScaleModal"
                      startIcon={
                        <img src="/images/weightScale.png" alt="icon" />
                      }
                    >
                      <span style={{ fontSize: "74%" }}> Por peso</span>
                    </Button>
                  </div>
                )}
                <div className={isAleko() ? "col-md-12" : "col-md-9"}>
                  <div className="input-group search-bar">
                    <input
                      type="text"
                      name="codigo_producto"
                      value={filterText}
                      ref={searchInputRef}
                      onChange={(e) => updateFilterText(e)}
                      autoFocus
                      className="form-control"
                      placeholder="Ingrese el nombre o código del producto."
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleProductsFiltering();
                          handleProductsFiltering(true, e.target.value);
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{ backgroundColor: "#0160E7" }}
                      >
                        <SearchIcon onClick={() => handleProductsFiltering()} />
                      </span>
                      <span
                        className="input-group-text"
                        style={{ backgroundColor: "red" }}
                      >
                        <ClearIcon onClick={resetSearch} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row justify-content-between align-items-center flex-wrap">
                    <div
                      className={aleko ? "col-12 mx-auto" : "col-8 mx-auto"}
                      style={{ minWidth: "530px" }}
                    >
                      <Tabs
                        className="mt-2"
                        value={currentTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={handleProductTabs}
                      >
                        <Tab className="tab" label="Todos" />
                        <Tab className="tab" label="Más vendidos" />
                        <Tab className="tab" label="Últimos vendidos" />

                        {/* {aleko && (
                          <Tab className="tab" label="Personalizable" />
                        )} */}
                      </Tabs>
                    </div>

                    <div
                      className="col-lg-6 mt-2 mx-auto"
                      id="catalog-category-select"
                    >
                      {/* <Select
                        native
                        value={productCategory}
                        onChange={changeCategory}
                        style={{ width: "100%" }}
                        variant="outlined"
                      >
                        <option value="">Todas las categorías</option>
                        {categories.map((category) => (
                          <option value={category?.id_clasificacion1_web_app}>
                            {category?.descripcion}
                          </option>
                        ))}
                      </Select> */}
                    </div>
                    <div className="col-12" id="products-listing">
                      <TabPanel value={currentTab} index={0}>
                        {caja !== "" ? (
                          <div className="text-center mt-3" id="friggin-loader">
                            <CircularProgress size={30} className="mx-auto" />
                          </div>
                        ) : (
                          <div className="text-center mt-3">
                            <div className="mx-auto my-3">
                              <p>
                                No se encontraron cajas asignadas a esta
                                sucursal
                              </p>
                            </div>
                          </div>
                        )}

                        <div
                          className="d-none justify-content-center align-items-center flex-wrap products-catalog mt-1"
                          id="products-catalog"
                        >
                          {JSON.stringify(filteredProducts) !== "[]" ? (
                            <div className="d-flex flex-wrap justify-content-center">
                              {filteredProducts.map((product) => (
                                <div
                                  className="product-container"
                                  style={{
                                    //width: "175px",
                                    width: "250px",
                                    height: "150px",
                                    margin: "5px",
                                  }}
                                  onClick={() =>
                                    handleBoxClick(
                                      product.id_new_item,
                                      true,
                                      false
                                    )
                                  }
                                  id={"product-" + product.id_new_item}
                                >
                                  <div
                                    className="product-header"
                                    id="product-header"
                                  >
                                    {product.promocion ? (
                                      <Tooltip title="Este producto tiene un descuento temporal">
                                        <QueryBuilderIcon
                                          style={{
                                            color: "#333333",
                                            fontSize: "12px",
                                          }}
                                          className="mx-2 product-discount-icon"
                                        />
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                    {!product.stock ? (
                                      // && !product.stock_libras
                                      <>
                                        <div
                                          style={{
                                            background: "black",
                                            opacity: "0.5",
                                            left: 0,
                                            right: 0,
                                            position: "absolute",
                                            height: "100%",
                                          }}
                                        />
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            color: "white",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Fuera de stock
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    <img
                                      src={
                                        product.imagen_1
                                          ? realImageURL(product.imagen_1)
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt="Cloud POS - Producto"
                                    />

                                    <CheckCircleIcon
                                      style={{
                                        color: "rgba(8, 191, 230, 0.6)",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        fontSize: "40px",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                      className="d-none"
                                      id={"product-icon-" + product.id_new_item}
                                    />
                                  </div>
                                  <div
                                    className="product-body"
                                    id="product-body"
                                  >
                                    <p className="product-title">
                                      <strong>{product.nombre}</strong>
                                      <br />
                                      <span className="product-price">
                                        {moneda}.{" "}
                                        {formaterPriceFunction(
                                          getPrice(product).toFixed(2) + ""
                                        )}
                                      </span>
                                      <span className="product-stock">
                                        <br />
                                        {product.flag_compuesto
                                          ? ""
                                          : !product.id_padre && !product.stock
                                          ? `Stock: ${numberFormater(
                                              product.stock
                                            )}`
                                          : `Stock: ${numberFormater(
                                              product.stock
                                            )} ${
                                              isCmi() &&
                                              product.vendido_por !== "unidades"
                                                ? "lbs"
                                                : ""
                                            }`}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              ))}
                              {getEmptyRows().map((x) => (
                                <div
                                  style={{
                                    width: "250px",
                                    height: "150px",
                                    margin: "5px",
                                  }}
                                ></div>
                              ))}
                            </div>
                          ) : (
                            <div className="mx-auto my-3">
                              <p>No se encontraron productos</p>
                            </div>
                          )}
                        </div>

                        {filteredProducts.length ? (
                          <div className="mx-auto my-3">
                            <Pagination
                              value={currentPage}
                              defaultPage={currentPage}
                              count={Math.ceil(totalProducts / maxPerPage)}
                              variant="outlined"
                              onChange={handleRealPagination}
                              color="primary"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </TabPanel>

                      <TabPanel value={currentTab} index={1}>
                        <div className="text-center mt-3" id="friggin-loader_2">
                          <CircularProgress size={30} className="mx-auto" />
                        </div>

                        <div
                          className="d-none justify-content-center align-items-center flex-wrap products-catalog mt-1"
                          id="products-catalog_2"
                        >
                          {JSON.stringify(filteredMostSaleProducts) !== "[]" ? (
                            <div className="d-flex flex-wrap justify-content-center">
                              {paginatedMostSaleProducts.map((product) => (
                                <div
                                  className="product-container"
                                  style={{
                                    width: "175px",
                                    height: "150px",
                                    margin: "5px",
                                  }}
                                  onClick={() =>
                                    handleBoxClick(
                                      product.id_new_item,
                                      true,
                                      false
                                    )
                                  }
                                  id={"product-" + product.id_new_item}
                                >
                                  <div className="product-header">
                                    <img
                                      src={
                                        product.imagen_1
                                          ? realImageURL(product.imagen_1)
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt="Cloud POS - Producto"
                                    />
                                    <CheckCircleIcon
                                      style={{
                                        color: "rgba(8, 191, 230, 0.6)",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        fontSize: "40px",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                      className="d-none"
                                      id={"product-icon-" + product.id_new_item}
                                    />
                                  </div>
                                  <div className="product-body">
                                    <p className="product-title">
                                      <strong>{product.nombre}</strong>
                                      <br />
                                      <span className="product-price">
                                        {moneda}.{" "}
                                        {formaterPriceFunction(
                                          getPrice(product).toFixed(2) + ""
                                        )}
                                      </span>
                                      <span className="product-stock">
                                        Stock:{" "}
                                        {product.stock
                                          ? product.stock
                                          : product.stock}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              ))}
                              {getEmptyRows(paginatedMostSaleProducts).map(
                                (x) => (
                                  <div
                                    style={{
                                      width: "250px",
                                      height: "150px",
                                      margin: "5px",
                                    }}
                                  ></div>
                                )
                              )}
                            </div>
                          ) : (
                            <div className="mx-auto my-3">
                              <p>No se encontraron productos</p>
                            </div>
                          )}
                        </div>
                        {JSON.stringify(filteredMostSaleProducts) !== "[]" ? (
                          <div className="mx-auto my-3">
                            <Pagination
                              value={page_1}
                              defaultPage={1}
                              count={Math.ceil(
                                filteredMostSaleProducts.length / maxPerPage
                              )}
                              variant="outlined"
                              onChange={handlePagination}
                              color="primary"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </TabPanel>

                      <TabPanel value={currentTab} index={2}>
                        <div className="text-center mt-3" id="friggin-loader_3">
                          <CircularProgress size={30} className="mx-auto" />
                        </div>

                        <div
                          className="d-none justify-content-center align-items-center flex-wrap products-catalog mt-1"
                          id="products-catalog_3"
                        >
                          {JSON.stringify(filteredLastSoldProducts) !== "[]" ? (
                            <div className="d-flex flex-wrap justify-content-center">
                              {filteredLastSoldProducts.map((product) => (
                                <div
                                  className="product-container"
                                  style={{
                                    width: "175px",
                                    height: "150px",
                                    margin: "5px",
                                  }}
                                  onClick={() =>
                                    handleBoxClick(
                                      product.id_new_item,
                                      true,
                                      false
                                    )
                                  }
                                  id={"product-" + product.id_new_item}
                                >
                                  <div className="product-header">
                                    <img
                                      src={
                                        product.imagen_1
                                          ? realImageURL(product.imagen_1)
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt="Cloud POS - Producto"
                                    />
                                    <CheckCircleIcon
                                      style={{
                                        color: "rgba(8, 191, 230, 0.6)",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        fontSize: "40px",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                      className="d-none"
                                      id={"product-icon-" + product.id_new_item}
                                    />
                                  </div>
                                  <div className="product-body">
                                    <p className="product-title">
                                      <strong>{product.nombre}</strong>
                                      <br />
                                      <span className="product-price">
                                        {moneda}.{" "}
                                        {formaterPriceFunction(
                                          getPrice(product).toFixed(2) + ""
                                        )}
                                      </span>
                                      <span className="product-stock">
                                        Stock: {product.stock}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              ))}
                              {getEmptyRows(filteredLastSoldProducts).map(
                                (x) => (
                                  <div
                                    style={{
                                      width: "250px",
                                      height: "150px",
                                      margin: "5px",
                                    }}
                                  ></div>
                                )
                              )}
                            </div>
                          ) : (
                            <div className="mx-auto my-3">
                              <p>No se encontraron productos</p>
                            </div>
                          )}
                        </div>
                        {JSON.stringify(filteredLastSoldProducts) !== "[]" ? (
                          <div className="mx-auto my-3">
                            <Pagination
                              value={page_3}
                              defaultPage={1}
                              count={Math.ceil(
                                filteredLastSoldProducts.length / maxPerPage
                              )}
                              variant="outlined"
                              onChange={handlePagination}
                              color="primary"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </TabPanel>

                      <TabPanel value={currentTab} index={3}>
                        <div className="text-center mt-3" id="friggin-loader_4">
                          <CircularProgress size={30} className="mx-auto" />
                        </div>

                        <div
                          className="d-none justify-content-center align-items-center flex-wrap products-catalog mt-1"
                          id="products-catalog_4"
                        >
                          {JSON.stringify(filteredAlekoProducts) !== "[]" ? (
                            <div className="d-flex flex-wrap justify-content-center">
                              {paginatedAlekoProducts.map((product) => (
                                <div
                                  className="product-container"
                                  style={{
                                    width: "175px",
                                    height: "150px",
                                    margin: "5px",
                                  }}
                                  onClick={() =>
                                    handleBoxClick(
                                      product.id_new_item,
                                      true,
                                      false
                                    )
                                  }
                                  id={"product-" + product.id_new_item}
                                >
                                  <div className="product-header">
                                    <img
                                      src={
                                        product.imagen_1
                                          ? realImageURL(product.imagen_1)
                                          : base_url_images +
                                            "images/placeholder-image.jpeg"
                                      }
                                      alt="Cloud POS - Producto"
                                    />
                                    <CheckCircleIcon
                                      style={{
                                        color: "rgba(8, 191, 230, 0.6)",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        fontSize: "40px",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                      className="d-none"
                                      id={"product-icon-" + product.id_new_item}
                                    />
                                  </div>
                                  <div
                                    className="product-body"
                                    id="product-body"
                                  >
                                    <p className="product-title">
                                      <strong>{product.nombre}</strong>
                                      <br />
                                      <span className="product-price">
                                        {moneda}.{" "}
                                        {formaterPriceFunction(
                                          getPrice(product).toFixed(2) + ""
                                        )}
                                      </span>
                                      <span className="product-stock">
                                        Stock: {product.stock}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="mx-auto my-3">
                              <p>No se encontraron productos</p>
                            </div>
                          )}
                        </div>
                        {JSON.stringify(filteredAlekoProducts) !== "[]" ? (
                          <div className="mx-auto my-3">
                            <Pagination
                              value={page_1}
                              defaultPage={1}
                              count={Math.ceil(
                                filteredAlekoProducts?.length / maxPerPage
                              )}
                              variant="outlined"
                              onChange={handlePagination}
                              color="primary"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </TabPanel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Right half */}
        <div className="col-xl-6">
          <div className="card content-card mt-2">
            <div className="row invoice-column m-0 p-3" id="invoice-column">
              <div className="col-sm-5 catalog-invoice">
                <div className="row h-100 justify-content-around align-items-center">
                  <div className="col-6 p-0 pr-2">
                    <button
                      id="cancelar-boton"
                      data-toggle="modal"
                      data-target="#cancelSale"
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-6 p-0">
                    <button id="pay-button" onClick={gotoPayment}>
                      {alekoValidation || isCmi() ? "Continuar" : "Pagar"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-7">
                <div className="row h-100">
                  {/* <h6 style={{ marginLeft: "50px", fontWeight: "bold" }}>
                    Tipo de Venta
                  </h6>
                  <RadioGroup
                    style={{ marginLeft: "40px" }}
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={typeSell}
                    onChange={changeTypesell}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio style={{ color: "white" }} />}
                      label="Para llevar"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio style={{ color: "white" }} />}
                      label="Consumo Local"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio style={{ color: "white" }} />}
                      label="A Domicilio"
                    />
                  </RadioGroup> */}
                  <div className="col-12 p-0 m-0 text-center titles my-auto">
                    {/* <p>SubTotal:</p>
                                              <p>IVA (12%):</p> */}
                    {!selectedProducts.some((item) => item.aleko) && (
                      <p
                        className="text-right m-0"
                        style={{ fontSize: "15px", fontFamily: "Open Sans" }}
                      >
                        <Tooltip title="Este costo incluye el descuento de cada producto.">
                          <InfoIcon
                            style={{
                              color: " #FFF",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                        Total: {moneda}.{" "}
                        {formaterPriceFunction(salesCost.total.toFixed(2) + "")}
                      </p>
                    )}
                  </div>
                  {/* <div className="col-6 p-0 m-0 text-center my-auto costs"> */}
                  {/*   <p>{moneda}. {salesCost.subtotal.toFixed(2)}</p>
                                              <p>{moneda}. {salesCost.iva.toFixed(2)}</p> */}
                  <p style={{ marginTop: "30px" }}></p>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card content-card">
            <div
              // className="card-header p-0"
              // key="facturacion-products-header"
              // id="facturacion-products-header"
              className={
                !selectedProducts.length
                  ? "d-flex align-items-center justify-content-center"
                  : ""
              }
              id={!isCmi() ? "table-head-prueba" : "table-head-prueba_V2"}
              style={{ height: !selectedProducts.length ? "590px" : "" }}
            >
              {selectedProducts.length > 0 ? (
                <DataTable
                  className="datatable-cs datatable-cs-v2 m-0"
                  title={"s"}
                  customStyles={{
                    headCells: {
                      style: {
                        background: "#333333",
                      },
                    },
                  }}
                  columns={[
                    {
                      name: "Producto",
                      selector: "nombre",
                      grow: 2,
                      sortable: true,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <IconButton
                            className="mx-1 no-outline-buttons"
                            onClick={() => {
                              removeProduct(row.id_new_item);
                              if (/* row.aleko*/ false) {
                                deleteCustomizeProduct(
                                  row.id_new_item,
                                  history
                                  // setCurrentTab,
                                  // currentTab
                                );
                                setCheck(
                                  check.filter(
                                    (item) =>
                                      item.id_new_item !== row.id_new_item
                                  )
                                );
                              }
                            }}
                            size="small"
                            style={{
                              background: "none",
                              color: "#EB5757",
                              fontSize: "20px",
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>

                          <div>
                            <small
                              style={{
                                fontSize: "11.4px",
                              }}
                            >
                              {row.nombre}
                            </small>
                            <br />
                            <small
                              style={{
                                fontSize: "11.4px",
                              }}
                            >
                              {row.codigo}
                            </small>
                          </div>
                        </div>
                      ),
                    },
                    {
                      name: "Precio",
                      selector: "precio_venta",
                      sortable: true,
                      cell: (row) =>
                        row.precio_libre ? (
                          <TextField
                            variant="outlined"
                            type="number"
                            defaultValue={
                              row.descuento_precio > 0.0
                                ? row.descuento_precio.toFixed(2)
                                : row.precio_venta?.toFixed(2)
                            }
                            onBlur={(e) => updateFreePrice(e, row)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") updateFreePrice(e, row);
                            }}
                            id={"free-price-" + row.id_new_item}
                          />
                        ) : (
                          <div>
                            {moneda}.{" "}
                            {row.descuento_precio > 0.0
                              ? formaterPriceFunction(
                                  row.descuento_precio.toFixed(2) + ""
                                )
                              : formaterPriceFunction(
                                  row.precio_venta?.toFixed(2) + ""
                                )}
                          </div>
                        ),
                    },
                    {
                      name: selectedProducts.some((item) => item.id_padre)
                        ? "Cantidad / peso"
                        : "Cantidad",
                      selector: "cantidad",
                      sortable: true,
                      grow: 2,
                      omit: selectedProducts.some((product) => product.aleko),
                      cell: (row) => (
                        <div
                          key={"quantity-section-" + row.id_new_item}
                          className="w-100 p-0 d-flex align-items-center justify-content-around"
                        >
                          {!row.idp && (
                            <IconButton
                              aria-label="delete"
                              className={
                                row.pesado
                                  ? "mx-1 no-outline-buttons disabled-catalog-button"
                                  : "mx-1 no-outline-buttons bg_secondary_color"
                              }
                              key={"minusbutton-" + row.id_new_item}
                              onClick={() =>
                                changeProductQuantity(
                                  row.id_new_item,
                                  "sub",
                                  false
                                )
                              }
                              disabled={row.pesado}
                              size="small"
                              style={{
                                color: "white",
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          )}

                          <TextField
                            className="w-50 quantity-inputs"
                            style={{ textAlign: "center" }}
                            variant="outlined"
                            ref={refQty}
                            defaultValue={
                              row.idp
                                ? Math.abs(row.cantidad).toFixed(5)
                                : row.cantidad
                            }
                            onChange={(e) =>
                              handleChildrenQty(
                                e.target.value,
                                row.id_new_item,
                                row.idp
                              )
                            }
                            autoFocus={currentCell === `${row.id_new_item}2`}
                            onSelect={() => {
                              setCurrentCell(`${row.id_new_item}2`);
                            }}
                            disabled={row.pesado || row.idp}
                            type="number"
                            id={"value-" + row.id_new_item}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                changeProductQuantity(
                                  row.id_new_item,
                                  "none",
                                  true
                                );
                                setCurrentCell("");
                                refQty.current.blur();
                                searchInputRef.current.focus();
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{
                                    display: "none",
                                  }}
                                >
                                  <IconButton
                                    className="mx-1 no-outline-buttons"
                                    onClick={() =>
                                      changeProductQuantity(
                                        row.id_new_item,
                                        "none",
                                        true
                                      )
                                    }
                                    size="small"
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    <SendIcon
                                      style={{
                                        fontSize: "8px",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 1,
                              },
                            }}
                          />
                          {!row.idp && (
                            <IconButton
                              aria-label="delete"
                              key={"plusbutton-" + row.id_new_item}
                              className={
                                row.pesado
                                  ? "mx-1 no-outline-buttons disabled-catalog-button"
                                  : "mx-1 no-outline-buttons bg_secondary_color"
                              }
                              onClick={() =>
                                changeProductQuantity(
                                  row.id_new_item,
                                  "sum",
                                  false
                                )
                              }
                              disabled={row.pesado ? true : false}
                              size="small"
                              style={{
                                color: "white",
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          )}
                        </div>
                      ),
                    },
                    {
                      name: "Total",
                      selector: "total",
                      sortable: true,
                      omit: selectedProducts.some((product) => product.aleko),
                      cell: (row) =>
                        !row.idp ? (
                          <div>
                            {moneda}.{" "}
                            {formaterPriceFunction(row.total.toFixed(2) + "")}
                          </div>
                        ) : (
                          <div>
                            <InputIdp
                              setCurrentCell={setCurrentCell}
                              currentCell={currentCell}
                              initialValue={row.total}
                              id={row.id_new_item}
                              changeProductQuantity={changeProductQuantity}
                            />
                          </div>
                        ),
                    },
                    {
                      name: "Cantidad exacta",
                      selector: "cantidad_exacta",
                      sortable: true,
                      omit: true,
                      // omit: !selectedProducts.some(
                      //   (product) => product.id_padre
                      // ),
                      cell: (row) => (
                        <div>
                          {row.id_padre && (
                            <ChildrenInput
                              setCurrentCell={setCurrentCell}
                              currentCell={currentCell}
                              initialValue={row.cantidad_exacta}
                              id={`${row.id_new_item}1`}
                              realId={row.id_new_item}
                              setAproxWeight={setAproxWeight}
                              handleAproxQty={handleAproxQty}
                            />
                          )}
                        </div>
                      ),
                    },
                  ]}
                  data={selectedProducts}
                  pagination
                  paginationPerPage={6}
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                />
              ) : (
                <div className="text-center">
                  <LocalMallIcon style={{ fontSize: "35px" }} />
                  <p className="text-center mt-2">
                    Agrega productos al carrito
                  </p>
                </div>
              )}
            </div>
          </div>
          <div
            className="row"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <div className="col-md-3 px-1 mt-2">
              <div
                className="card card-body catalog-option align-items-between"
                style={{
                  height: "134px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{ fontSize: "90%", fontWeight: 700 }}
                  className="text-center"
                >
                  Notas de crédito
                </p>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={"/app/operaciones_historial/nota_credito/emitir"}
                >
                  <span style={{ fontSize: "90%", fontWeight: 700 }}>
                    Crear
                  </span>
                </Button>
              </div>
            </div>
            <div className="col-md-3 px-1 mt-2">
              <div
                className="card card-body catalog-option align-items-between"
                style={{
                  height: "134px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{ fontSize: "90%", fontWeight: 700 }}
                  className="text-center"
                >
                  Anulaciones de ventas
                </p>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component={Link}
                  to={"/app/operaciones_historial/crear_anulacion"}
                >
                  <span style={{ fontSize: "90%", fontWeight: 700 }}>
                    Anular
                  </span>
                </Button>
              </div>
            </div>
            <div className="col-md-3 px-1 mt-2">
              <div
                className="card card-body catalog-option align-items-between"
                style={{
                  height: "134px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{ fontSize: "90%", fontWeight: 700 }}
                  className="text-center"
                >
                  Anticipos de pagos
                </p>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component={Link}
                  to={"/app/anticipo/crear"}
                >
                  <span style={{ fontSize: "90%", fontWeight: 700 }}>
                    {" "}
                    Crear
                  </span>
                </Button>
              </div>
            </div>

            <div className="col-md-3 px-1 mt-2">
              <div
                className="card card-body catalog-option "
                style={{
                  height: "134px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{ fontSize: "90%", fontWeight: 700 }}
                  className="text-center"
                >
                  Cerrar caja
                </p>

                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component={Link}
                  to={"/app/cortes_caja/realizar_corte"}
                >
                  <span style={{ fontSize: "90%", fontWeight: 700 }}>
                    Realizar
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="cancelSale" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>
                <strong>¿Seguro que desea cancelar la compra?</strong>
              </h4>

              <div className="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={houseKeeping}
                >
                  Sí, cancelar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* WEIGHT SCALE MODAL START */}
      <div
        className="modal fade"
        id="weightScaleModal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>
                <strong>Añade productos basados en su peso</strong>
              </h4>

              <div
                className="row justify-content-center align-items-center px-0 py-5 text-center mb-5"
                style={{ backgroundColor: "#F2F2F2" }}
              >
                {weightedProduct.nombre ? (
                  <>
                    <div className="col-md-3">
                      <img
                        src={
                          weightedProduct.imagen_1
                            ? realImageURL(weightedProduct.imagen_1)
                            : base_url_images + "images/placeholder-image.jpeg"
                        }
                        className="weighted-product-image"
                        alt="Cloud POS - Producto"
                      />
                    </div>
                    <div className="col-md-6">
                      <p>
                        <strong>{weightedProduct.nombre}</strong>
                      </p>
                      <p>SKU: {weightedProduct.codigo}</p>
                    </div>
                    <div className="col-md-3">
                      <p>
                        Peso: {weightedProduct.peso}{" "}
                        {weightedProduct?.unidad?.descripcion}
                      </p>
                      <p>
                        Precio: {moneda}.{" "}
                        {formaterPriceFunction(
                          weightedProduct.precio_pesado.toFixed(2) + ""
                        )}
                      </p>
                    </div>
                  </>
                ) : (
                  "Escanea o ingresa manualmente el código del producto"
                )}
              </div>

              <div className="input-group search-bar">
                <input
                  type="text"
                  name="codigo_producto"
                  value={weightedProductFilter}
                  onChange={(e) => updateWeightedProductFilter(e)}
                  autoFocus
                  className="form-control"
                  placeholder="Ingrese el código del producto."
                  onKeyDown={(e) => {
                    if (e.key === "Enter") searchWeightedProduct();
                  }}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#0160E7" }}
                  >
                    <SearchIcon onClick={() => searchWeightedProduct(null)} />
                  </span>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-4">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Cancelar
                </Button>
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={addWeightedProduct}
                >
                  Agregar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* WEIGHT SCALE MODAL END */}

      {/* ADD QUANTITY MODAL START */}
      <div
        className="modal fade"
        id="addQuantityModal"
        tabIndex="-1"
        role="dialog"
      >
        <ModalApertura
          handleClose={handleClose}
          open={open}
          fondoCambios={fondoCambios}
          setFondoCambios={setFondoCambios}
          caja={caja}
          store={store}
          codigo={aperturaInfo?.codigo_operacion}
          setAperturaInfo={setAperturaInfo}
          validarCorteCaja={validarCorteCaja}
        />
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h5>
                <strong>¿Desea agregar más {selected_product.nombre}?</strong>
              </h5>

              <TextField
                className="w-100"
                variant="standard"
                type="number"
                value={quantity_addition}
                onChange={(event) =>
                  setQuantity_addition(
                    event.target.value < 0
                      ? -event.target.value
                      : event.target.value
                  )
                }
                autoFocus
                placeholder="Ingrese la cantidad"
                InputProps={{
                  inputProps: { min: 0, step: "0.00001" },
                }}
              />

              <div className="d-flex justify-content-between mt-4">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Cancelar
                </Button>
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={addProductQuantity}
                >
                  Agregar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  ADD QUANTITY MODAL END */}
      <ModalLimite store={store} />
    </div>
  );
}
