import React from "react";
import BottomSection from "./BottomSection";
import TopSection from "./Section1";

const Analytics = () => {
  return (
    <>
      <TopSection />
      <BottomSection />
    </>
  );
};

export default Analytics;
