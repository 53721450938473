import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const getAll = async () => {
  return await axios.get(`${base_url}/web/price-level/all`, {
    headers: headers(),
  });
};

const createNew = async (data) => {
  return await axios.post(
    `${base_url}/web/price-level/create`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const updateOrCreate = async (data) => {
  return await axios.put(
    `${base_url}/web/update-product-price-level`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const allProductsPriceLevel = (id_new_empresa, items, id_client) =>
  axios
    .post(
      `${base_url}/web/allPriceLevelCMIEnterprise`,
      qs.stringify({
        id_new_empresa,
        items,
        id_client,
      }),
      {
        headers: headers(),
      }
    )
    .then(({ data }) => data);
const getTypeCostumer = (id_new_empresa) =>
  axios
    .get(
      `${base_url}/web/costumers/getTypeCostumer?id_cliente=${id_new_empresa}`,
      {
        headers: headers(),
      }
    )
    .then(({ data }) => data);
export default {
  getAll,
  createNew,
  updateOrCreate,
  allProductsPriceLevel,
  getTypeCostumer,
};
