import styled from "styled-components";

import { colors, device } from "./config.styled.components";

export const Input = styled.input`
  disabled: ${(props) => props.disabled};
  display: ${(props) => props.display};
  padding: ${(props) => props.padding || "5px 10px"};
  color: ${(props) => props.color || colors.blackColor};
  border: ${(p) => p.border || "none"};
  border-bottom: ${(props) => props.border || `1px solid ${colors.greyColor}`};
  outline: ${(p) => p.outline || "none"};
  width: ${(p) => p.width};
  max-width: ${(p) => p.minWidth};
  transition: all 300ms;

  &:focus {
    border-bottom: 1px solid blue;
  }
`;

export const Select = styled.select`
  padding: ${(props) => props.padding || "5px 10px"};
  color: ${(props) => props.color || colors.blackColor};
  border: ${(p) => p.border || "none"};
  border-bottom: ${(props) => props.border || `1px solid ${colors.greyColor}`};
  outline: ${(p) => p.outline || "none"};
  transition: all 300ms;
  margin: auto 0;

  grid-area: ${(props) => props.gridArea};
  align-self: ${(props) => props.alignSelf};

  &:focus {
    border-bottom: 1px solid blue;
  }
`;

export const UploadFile = styled.label`
  display: ${(props) => props.display || "flex"};
  align-items: ${(props) => props.alignItems || "center"};

  background-color: ${(props) => props.backgroundColor || "rgba(1,96,231,1)"};
  font-size: ${(props) => props.fontSize || "14px"};
  font-weight: ${(props) => props.fontWeight || "500"};
  padding: ${(props) => props.padding || "14px 19px"};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || "5px"};
  white-space: ${(props) => props.whiteSpace};

  margin: ${(props) => props.margin || "10px"};

  color: ${(props) => props.color || "rgba(255,255,255,1)"};
  box-sizing: border-box;
`;

export const PointInput = styled(Input)``;
