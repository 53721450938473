import React from "react";

const TabsHandler = ({ tabsHandlerVars }) => {
  let { Tabs, Tab, currentTab, handleProductTabs, tabs } = tabsHandlerVars;

  return (
    <div className="col-8 mx-auto" style={{ minWidth: "530px" }}>
      <Tabs
        className="mt-2"
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleProductTabs}
      >
        {tabs.map((item, i) => {
          return <Tab className="tab" label={item.title} />;
        })}
      </Tabs>
    </div>
  );
};

export default TabsHandler;
