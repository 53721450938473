import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import API from "../../api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import $ from "jquery";
import Select from "@material-ui/core/Select";
import FooterLinks from "../../components/FooterLinks/FooterLinks";
import HeaderNoSession from "../../components/HeaderNoSession/Header";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useStyles from "./styles";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useUserDispatch } from "../../context/UserContext";

// styles
import "./custom.css";
//

function Register(props) {
  const { register, handleSubmit } = useForm();
  var classes = useStyles();
  var userDispatch = useUserDispatch();
  // Definitions

  let plans = [
    {
      id: 1,
      value: "gratis",
      option: "Gratis",
      price: 0,
      costPerLicense: 0,
      paymentFrequency: "N/A",
    },
    {
      id: 2,
      value: "mensual",
      option: "Mensual",
      price: 800,
      costPerLicense: 50,
      paymentFrequency: "Mensual",
    },
    {
      id: 3,
      value: "anual",
      option: "Anual",
      price: 600,
      costPerLicense: 60,
      paymentFrequency: "Mensual",
    },
  ];

  // cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#paymentApproved").modal("hide");
    };
  }, []);

  useEffect ( () => {
    localStorage.clear();
  },[])

  // states
  //eslint-disable-next-line
  var [isLoading, setIsLoading] = useState(false);
  var [plan, setPlan] = useState(
    props.match.params.plan.toString().toLowerCase()
  );
  var [cantLicencias, setCantLicencias] = useState("1");
  var [costoTotal, setCostoTotal] = useState(
    plans.find(
      (selectedPlan) =>
        selectedPlan.value === props.match.params.plan.toString().toLowerCase()
    ).price
  );
  var [frecuenciaPago, setFrecuenciaPago] = useState(
    plans.find(
      (selectedPlan) =>
        selectedPlan.value === props.match.params.plan.toString().toLowerCase()
    ).paymentFrequency
  );

  // set states

  const signUp = (data) => {
    let validForm = true;
    if (data.password.length > 20) {
      validForm = false;
      toast.error("La longitud maxima de la contraseña es 20 caracteres.", {
        autoClose: 10000,
      });
    }
    if (data.password !== data.confirmPassword) {
      validForm = false;
      toast.error("Las contraseñas no coinciden.", {
        autoClose: 10000,
      });
    }
    if (validForm) {

      const objRegister = {
        correo_electronico: data.correo,
        contrasena: data.password,
        confirme_contrasena: data.confirmPassword,
        nombre_comercial: data.nombreComercial,
        direccion_comercial: data.direccionComercial,
        negocio: data.giroNegocio,
        nombre_contacto: data.nombreContacto,
        contacto_telefono: data.telefonoContacto,
        empresa_telefono: data.telefono,
      }
      
      setIsLoading(true);
      API.auth
        .register({
          correo_electronico: data.correo,
          contrasena: data.password,
          confirme_contrasena: data.confirmPassword,
          nombre_comercial: data.nombreComercial,
          direccion_comercial: data.direccionComercial,
          negocio: data.giroNegocio,
          nombre_contacto: data.nombreContacto,
          contacto_telefono: data.telefonoContacto,
          empresa_telefono: data.telefono,
          esValidacion: true,
        })
        .then((res) => {
          localStorage.clear();
          localStorage.setItem("dataRegister", JSON.stringify(objRegister));
          //userDispatch({ type: "LOGIN_SUCCESS" });
          props.history.push("/payments");
        })
        .catch((err) => {
          setIsLoading(false);
          let response = err?.response;
          console.log(err);
          console.log(response);
          if (response?.data) {
            if (response?.data?.message) {
              toast.error(response?.data?.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    }
  };

  return (
    <div className="row h-100 w-100 p-0 m-0">
      <div className="row h-100 w-100 m-0 p-0 outer-container justify-content-center register">
        <HeaderNoSession />
        <div
          className="col-12 p-0 inner-container banner"
          style={{ marginTop: "66px" }}
        >
          <div className="row w-100 h-100 justify-content-around align-items-center">
            <div className="col-md-6">
              <h1 className="page-title">Creación de cuenta Cloud</h1>
            </div>

            <div className="col-md-6 align-items-center">
              <div className="d-flex justify-content-end align-items-center right-banner-text">
                <p className="mr-3 text-white my-auto">
                  <strong>¿Ya tienes cuenta?</strong>
                </p>
                <Button
                  variant="contained"
                  size="large"
                  component={Link}
                  to={"/login"}
                  style={{
                    backgroundColor: "#08BFE6",
                    color: "white",
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                >
                  Iniciar sesión
                </Button>
              </div>
            </div>
          </div>
        </div>

        <form className="col-10 p-0 content" onSubmit={handleSubmit(signUp)}>
          <React.Fragment>
            <div className="card content-card card-body register-card p-3">
              <h1>
                <strong>Registro</strong>
              </h1>

              <div className="row">
                <div className="col-lg-4">
                  <p className="mt-3 mb-0">
                    <strong>Nombre comercial:*</strong>
                  </p>
                  <TextField
                    id="nombre_comercial"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="nombreComercial"
                    inputRef={register}
                    required={true}
                    margin="normal"
                    placeholder="Ingrese su nombre comercial"
                    type="text"
                    fullWidth
                  />
                </div>

                <div className="col-lg-4">
                  <p className="mt-3 mb-0">
                    <strong>Dirección comercial:*</strong>
                  </p>
                  <TextField
                    id="direccion_comercial"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="direccionComercial"
                    inputRef={register}
                    required={true}
                    margin="normal"
                    placeholder="Ingrese su dirección comercial"
                    type="text"
                    fullWidth
                  />
                </div>

                <div className="col-lg-4">
                  <p className="mt-3 mb-0">
                    <strong>Giro del negocio:*</strong>
                  </p>
                  <TextField
                    id="giro_del_negocio"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="giroNegocio"
                    inputRef={register}
                    required={true}
                    margin="normal"
                    placeholder="Ingrese el giro del negocio"
                    type="text"
                    fullWidth
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-4">
                  <p className="mt-3 mb-0">
                    <strong>Número de teléfono:*</strong>
                  </p>
                  <TextField
                    id="telefono"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="telefono"
                    inputRef={register}
                    required={true}
                    margin="normal"
                    placeholder="(    )   -   -"
                    type="tel"
                    fullWidth
                  />
                </div>
                <div className="col-lg-4">
                  <p className="mt-3 mb-0">
                    <strong>Nombre de contacto:*</strong>
                  </p>
                  <TextField
                    id="nombre_contacto"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="nombreContacto"
                    inputRef={register}
                    required={true}
                    margin="normal"
                    placeholder="Ingrese su nombre"
                    type="text"
                    fullWidth
                  />
                </div>
                <div className="col-lg-4">
                  <p className="mt-3 mb-0">
                    <strong>Teléfono de contacto:*</strong>
                  </p>
                  <TextField
                    id="telefono_contacto"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="telefonoContacto"
                    inputRef={register}
                    required={true}
                    margin="normal"
                    placeholder="(    )   -   -"
                    type="text"
                    fullWidth
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-4">
                  <p className="mt-3 mb-0">
                    <strong>Correo electrónico:*</strong>
                  </p>
                  <TextField
                    id="correo_electronico"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="correo"
                    inputRef={register}
                    required={true}
                    margin="normal"
                    placeholder="Ingrese su correo electrónico"
                    type="email"
                    fullWidth
                  />
                </div>

                <div className="col-lg-4">
                  <p className="mt-3 mb-0">
                    <strong>Contraseña:*</strong>
                  </p>
                  <TextField
                    id="password"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="password"
                    inputRef={register}
                    required={true}
                    margin="normal"
                    placeholder="Ingrese la contraseña"
                    type="password"
                    fullWidth
                  />
                </div>
                <div className="col-lg-4">
                  <p className="mt-3 mb-0">
                    <strong>Confirme contraseña</strong>
                  </p>
                  <TextField
                    id="password_confirm"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="confirmPassword"
                    inputRef={register}
                    required={true}
                    margin="normal"
                    placeholder="Confirme la contraseña"
                    type="password"
                    fullWidth
                  />
                </div>
              </div>

              <div className="row mt-5 justify-content-start">
                <div className="col-lg-4">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="agreement"
                        inputRef={register}
                        required={true}
                        style={{ color: "#09BEE6" }}
                      />
                    }
                    label={
                      <>
                        He leído y acepto los{" "}
                        <a
                          href="https://cloud.com.gt/politica-de-privacidad-de-cloud-comercial"
                          className="signUp-links"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          términos, condiciones y política de privacidad
                        </a>
                        , así como el{" "}
                        <a
                          href="https://cloud.com.gt/contrato-de-suscripcion-a-servicios-de-software"
                          className="signUp-links"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          contrato de suscripción.
                        </a>
                      </>
                    }
                  />
                </div>
                <div className="col-lg-4 d-flex justify-content-center">
                  {isLoading ? (
                    <CircularProgress size={26} className="mx-auto" />
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth
                      endIcon={
                        <ArrowForwardIosIcon
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                      style={{
                        color: "white",
                        height: "fit-content",
                        textTransform: "none",
                      }}
                      className="ml-auto gradient_bg_vanilla"
                      /* data-toggle="modal"
                                            data-target="#paymentApproved" */
                      type="submit"
                    >
                      Pagar y registrarme
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        </form>
        <FooterLinks />
      </div>
      {/* Modal */}
    </div>
  );
}

export default withRouter(Register);
