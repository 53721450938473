// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useHistory } from "react-router-dom";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// components

//data

export default function Users_all(props) {
  let history = useHistory();

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  //componentdidmount - code to run on component mount
  useEffect(() => {
    if (!props.history.location.state) {
      history.push("/app/inventario/new_egreso");
    } else {
      set_egreso(props.history.location.state.data);
    }
  }, []);

  // states
  const [egreso, set_egreso] = useState({});

  // set states

  //function
  const save_data = () => {
    //save data
    $("#save").modal();
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div class="modal fade" id="save" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Su producto se ha <br />
                    guardado con éxito
                  </strong>
                </h4>
              </div>

              <div class="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>CONFIRMAR EGRESO</strong>
        </h4>
      </div>

      {/* write content here "whithout {}"*/}
      <form>
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Creación de Egreso</strong>
                </h5>
                <p className="mb-0">
                  Registre una orden de egreso de sus productos.
                </p>
              </div>

              <div>
                <Button
                  className="btn-cs1_blue mx-1 mt-1"
                  variant="contained"
                  color="primary"
                  onClick={() => save_data()}
                >
                  Guardar
                </Button>
                <Button
                  className="btn-cs1 mx-1 mt-1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmExit"
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <p>
              <strong>Confirmación de egreso</strong>
            </p>

            <div className="row mb-4">
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Motivo del egreso</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    name="motivo_egreso"
                    native
                    value={egreso.motivo_egreso}
                    onChange={null}
                    className="cs-select1 select-w100"
                    disabled
                  >
                    <option value="">Motivo del egreso</option>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Sucursal que egresará</strong>
                  </label>
                  <Tooltip title="Sucursal que egresará">
                    <InfoIcon
                      style={{ color: " #828282", fontSize: "1.2rem" }}
                    />
                  </Tooltip>
                </div>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    name="sucursal"
                    native
                    value={egreso.sucursal}
                    onChange={null}
                    className="cs-select1 select-w100"
                    disabled
                  >
                    <option value="">Seleccione sucursal</option>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-2 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Número de egreso</strong>
                </label>

                <TextField
                  name="n_egreso"
                  type="text"
                  className="w-100"
                  placeholder="Ingrese el número de egreso"
                  disabled
                  value={egreso.n_egreso}
                />
              </div>
              <div className="col-md-2 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Fecha del egreso</strong>
                </label>

                <TextField
                  disabled
                  value={egreso.date}
                  name="date"
                  type="date"
                  className="w-100"
                />
              </div>

              {/* Second Row */}
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Agregar productos</strong>
                  </label>
                  <Tooltip title="Sucursal que egresará">
                    <InfoIcon
                      style={{ color: " #828282", fontSize: "1.2rem" }}
                    />
                  </Tooltip>
                </div>
                <p className="mb-2">
                  Agregue los productos, e ingrese las cantidades que egresarán.
                </p>
                {/* 	<Button
									type="submit"
									className="btn-cs1 mx-1 mt-1"
									variant="contained"
									color="primary"
									to={'/app/inventario/new_egreso'}
									component={Link}
								>
									Añadir más productos
								</Button> */}
              </div>

              <div className="col-md-8 mt-3">
                {egreso.product_code &&
                  egreso.product_code.map((code, index) => (
                    <div className="row">
                      <div className="col-md-6 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Código del producto</strong>
                        </label>

                        <TextField
                          value={code}
                          type="text"
                          className="w-100"
                          placeholder="Ingrese SKU / código de barras del producto"
                          disabled
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Cantidad a egresar</strong>
                          </label>
                          <Tooltip title="Cantidad a egresar">
                            <InfoIcon
                              style={{ color: " #828282", fontSize: "1.2rem" }}
                            />
                          </Tooltip>
                        </div>
                        <TextField
                          disabled
                          value={egreso.product_qty[index]}
                          type="number"
                          className="w-100"
                          placeholder="Indique la cantidad de productos a egresar"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                N°
                              </InputAdornment>
                            ),
                            inputProps: { min: 0 },
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
