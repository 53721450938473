import React, { useRef, useState, useEffect, useContext } from "react";
// Styled Components

import {
  GridContainer,
  FlexContainer,
  BigTitle,
  Input,
  ContinueButton,
  BackButton,
} from "./styled/styled.components";

import Table from "./confirm.order.table";
import Pdf from "react-to-pdf";
import OrderPdf from "./OrderPdf";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import print from "print-js";
import "react-datepicker/dist/react-datepicker.css";
import PdfDocument from "./PdfDocument";
import { useParams } from "react-router";
import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { getAlekoOrder } from "../../../api/sales";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import API from "../../../api";
import { useHistory } from "react-router-dom";
import { stringify } from "qs";

// Components

const View = () => {
  const params = useParams();
  const history = useHistory();

  const [startDate, setStartDate] = useState(new Date());
  const [iva, setIva] = useState(0);
  const [currentOrder, setCurrentOrder] = useState({
    codigo_pedido: "",
    data: [],
    id_pedido: null,
  });

  const { setOrder, order } = useContext(ProductPersonalizationContext);

  //Date Picker - Español
  registerLocale("es", es);

  useEffect(() => {
    const getOrder = async () => {
      const id_bodega_sucursal = JSON.parse(
        localStorage.getItem("store")
      )?.id_sucursal_bodega_ubicacion;
      if (!id_bodega_sucursal) {
        history.push("/app/vender");
      }
      setOrder({ name: "id_pedido", value: params.id });
      try {
        const { data } = await getAlekoOrder({
          id_pedido_item: params.id,
          id_bodega_sucursal: id_bodega_sucursal,
        });
        localStorage.setItem(
          "selectedProductsAleko",
          JSON.stringify(data.data.data)
        );
        const parsedData = data.data.data.map((item) =>
          !item.id_item ? { ...item, id_item: item.id_pedido_item } : item
        );
        const result = parsedData.reduce((r, a) => {
          r[a.id_item] = r[a.id_item] || [];
          r[a.id_item].push(a);
          return r;
        }, Object.create(null));
        setCurrentOrder({
          ...data,
          data: result,
        });
      } catch (error) {
        console.log(error);
      }
    };
    const getData = async () => {
      const { data } = await API.profile.getCompanyData();
      setIva(data.data.iva);
    };
    getData();
    getOrder();
  }, []);

  const Title = () => {
    return (
      <FlexContainer
        gridColumn="1/-1"
        padding="12px 24px"
        boxShadow="0px 2px 12px rgba(0, 0, 0, 0.06)"
        justifyContent="space-between"
      >
        <BigTitle fontSize="25px" fontWeight="700">
          Confirmación de orden
        </BigTitle>
        <BigTitle fontSize="18px" fontWeight="700">
          N° {currentOrder.codigo_pedido}
        </BigTitle>
      </FlexContainer>
    );
  };

  const Data = () => {
    const printSale = () => {
      print({
        printable: "printform",
        type: "html",
        targetStyles: ["*"],
      });
    };
    const send = () => {
      // This should send info?
    };
    return (
      <>
        <FlexContainer
          rowGap="20px"
          margin="10px 0"
          gridColumn="1/-1"
          justifyContent="space-between"
        >
          <FlexContainer flexDirection="column">
            <BigTitle fontSize="14px" fontWeight="700">
              Fecha
            </BigTitle>
            <BigTitle fontSize="14px" fontWeight="400">
              {moment(new Date()).format("DD/MM/YYYY")}
            </BigTitle>
          </FlexContainer>
          <FlexContainer flexDirection="column">
            <BigTitle fontSize="14px" fontWeight="700">
              N° de cotización
            </BigTitle>
            <BigTitle fontSize="14px" fontWeight="400">
              {currentOrder.id_pedido}
            </BigTitle>
          </FlexContainer>
          <FlexContainer flexDirection="column">
            <BigTitle fontSize="14px" fontWeight="700">
              Válido hasta:
            </BigTitle>
            <DatePicker
              selected={order.valido_hasta}
              onChange={(date) =>
                setOrder({ name: "valido_hasta", value: date })
              }
              locale="es"
            />
          </FlexContainer>
          {/* <FlexContainer flexDirection="column">
            <BigTitle fontSize="14px" fontWeight="700">
              Enviar por correo
            </BigTitle>
            <Input
              width="100%"
              maxWidth="220px"
              placeholder="Escribe el correo electrónico"
            />
          </FlexContainer> */}
          <FlexContainer flexDirection="row">
            {/* <ContinueButton onClick={send}>Enviar</ContinueButton> */}
            <BackButton onClick={printSale}>Imprimir</BackButton>
          </FlexContainer>
        </FlexContainer>
      </>
    );
  };

  return (
    <GridContainer
      backgroundColor="rgba(255,255,255,1)"
      borderRadius="10px"
      boxShadow="8px 8px 20px rgba(0, 0, 0, 0.06)"
      gridColumn="1/-1"
    >
      <Title />
      <GridContainer gridColumn="1/-1" padding="24px">
        <BigTitle fontSize="18px" fontWeight="700">
          Cotización
        </BigTitle>
        <Data />
        {currentOrder.id_pedido && (
          <Table currentOrder={currentOrder} iva={iva} />
        )}
      </GridContainer>
    </GridContainer>
  );
};

export default View;
