import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const HistorialPorCobrarReporte = ({ objeto }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table
        className="tableReporte"
        id="historialPorCobrarOperaciones-reporte-table"
      >
        <thead>
          <tr>
            <th>N° Factura</th>
            <th> Fecha / Hora</th>
            <th>Nombre del cliente</th>
            <th>NIT</th>
            <th>Vencimiento</th>
            <th>Monto</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            console.log(x);

            return (
              <tr key={index}>
                <td>{x.identificador}</td>
                <td>
                  {moment(new Date(x.fecha))
                    .utcOffset(0)
                    .format("DD/MM/YYYY h:mm:ss a")}{" "}
                </td>
                <td>{x.nombre}</td>
                <td>{x.nit} </td>
                <td>
                  {moment(x.fecha_vencimiento)
                    .utcOffset(0)
                    .format("DD/MM/YYYY, h:mm:ss a")}
                </td>
                <td>
                  {moneda}. {formaterPriceFunction(x.monto.toFixed(2) + "")}{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default HistorialPorCobrarReporte;
