import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// eslint-disable-next-line

import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import IconButton from "@material-ui/core/IconButton";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";
// styles
import "./custom.css";
import CloseIcon from "@material-ui/icons/Close";

//icons

import DeleteIcon from "@material-ui/icons/Delete";
import { isCmi } from "../../helpers/isCmi";
import { isAdmin } from "../../helpers/isAdmin";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SucursalModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefreshAndClose = () => {
    setOpen(false);
    setCaja_list([]);
    setSucursal({
      codigo_sucursal: "",
      nombre_sucursal: "",
      direccion_sucursal: "",
      telefono_sucursal: "",
      id_sucursal_bodega_ubicacion: "",
    });
    props.getSucursals();
  };

  let history = useHistory();

  const { register, handleSubmit } = useForm();

  // states
  const [caja_list, setCaja_list] = useState([]);
  const [edition, setEdition] = useState(false);
  const [sucursalLoader, setSucursalLoader] = useState(true);
  const [sucursal, setSucursal] = useState({
    codigo_sucursal: "",
    nombre_sucursal: "",
    direccion_sucursal: "",
    telefono_sucursal: "",
    id_sucursal_bodega_ubicacion: "",
  });

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
    };
  }, []);

  //function
  const add_caja = () => {
    let caja_name = $("#caja-input").val();

    if (caja_name !== "") {
      if (!!edition) {
        let data = {
          caja_nombre: caja_name,
          id_sucursal_bodega_ubicacion: sucursal.id_sucursal_bodega_ubicacion,
        };
        addCaja_api(data);
      } else {
        setCaja_list(
          caja_list.concat([
            {
              descripcion: caja_name,
            },
          ])
        );
        $("#caja-input").val("");
      }
    }
  };

  const send_sucursal = (data) => {
    if (!!edition) {
      setSucursalLoader(true);
      data.id_sucursal_bodega_ubicacion = sucursal.id_sucursal_bodega_ubicacion;
      API.sucursal
        .updateSucursal(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success("Sucursal actualizada.", {
              autoClose: 10000,
            });

            console.log("Obtained this data:", response.data);
            setSucursal(response.data);
            history.push({
              pathname: `/app/config`,
              state: { selectedTab: 2 },
            });
          } else {
            if (response.message) {
              toast.warning(response.message, {
                autoClose: 10000,
              });
            } else {
              toast.warning("Ocurrió un error.", {
                autoClose: 10000,
              });
            }
          }
          setSucursalLoader(false);
        })
        .catch((err) => {
          setSucursalLoader(false);
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          } else {
            toast.error("Ocurrió un error.", {
              autoClose: 10000,
            });
          }
        });
    } else {
      let aux = [];
      caja_list.map((caja) => {
        aux.push(caja.descripcion);
      });
      data.cajas = aux.join(",");
      if (caja_list.length > 0) {
        setSucursalLoader(true);
        API.sucursal
          .createNew(data)
          .then((res) => {
            let response = res.data;
            if (response.success) {
              toast.success("Sucursal añadida con éxito", {
                autoClose: 10000,
              });
              handleRefreshAndClose();
              /*history.push({
                pathname: `/app/config`,
                state: { selectedTab: 2 },
              });*/
            } else {
              if (response.message) {
                toast.warning(response.message, {
                  autoClose: 10000,
                });
              } else {
                toast.warning("Ocurrió un error.", {
                  autoClose: 10000,
                });
              }
            }
            setSucursalLoader(false);
          })
          .catch((err) => {
            setSucursalLoader(false);
            let response = err.response;
            console.log(response);
            console.log("Ha ocurrido un error");
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            } else {
              toast.error("Ocurrió un error.", {
                autoClose: 10000,
              });
            }
          });
      } else {
        toast.error("Debe introducir al menos una caja.", {
          autoClose: 10000,
        });
      }
    }
  };

  const getSucursals = (id) => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          let filteredSucursals = response.data.filter(
            (x) => x.id_sucursal_bodega_ubicacion === id
          );
          console.log("Obtained data:", filteredSucursals[0]);
          setSucursal(filteredSucursals[0]);
          let cajas = [];
          filteredSucursals[0].cajas.map((caja) => {
            cajas.push({
              descripcion: caja.descripcion,
              id_caja_web_app: caja.id_caja_web_app,
            });
          });
          setCaja_list(cajas);
          setSucursalLoader(false);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const deleteCaja = (id, i) => {
    if (!!edition) {
      if (caja_list.length > 1) {
        API.sucursal
          .deleteCaja({ id_caja_web_app: id })
          .then((res) => {
            let response = res.data;
            console.log(response);
            if (response.success) {
              setCaja_list(
                caja_list.filter((caja) => caja.id_caja_web_app !== id)
              );
            } else {
              toast.warning(response.message, {
                autoClose: 10000,
              });
            }
          })
          .catch((err) => {
            let response = err.response.data;
            console.log(response);
            if (typeof response.message !== "undefined") {
              toast.error(response.message, {
                autoClose: 10000,
              });
            } else {
              console.log("Ha ocurrido un error");
              toast.error("Ha ocurrido un error", { autoClose: 10000 });
            }
          });
      } else {
        toast.error("No puedes eliminar todas las cajas", {
          autoClose: 10000,
        });
      }
    } else {
      setCaja_list(caja_list.filter((caja, index) => index !== i));
    }
  };

  const updateCaja = (event, id) => {
    if (event.target.value !== "") {
      let data = {
        id_caja_web_app: id,
        id_sucursal_bodega_ubicacion: sucursal.id_sucursal_bodega_ubicacion,
        caja_nombre: event.target.value,
      };
      API.sucursal
        .updateCaja(data)
        .then((res) => {
          let response = res.data;
          console.log(response);
          if (response.success) {
            let cajas = [];
            response.data.map((caja) => {
              cajas.push({
                descripcion: caja.descripcion,
                id_caja_web_app: caja.id_caja_web_app,
              });
            });
            setCaja_list(cajas);
          } else {
            toast.warning(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    }
  };

  const addCaja_api = (data) => {
    API.sucursal
      .addCaja(data)
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success && response.data) {
          let cajas = [];
          response.data.map((caja) => {
            cajas.push({
              descripcion: caja.descripcion,
              id_caja_web_app: caja.id_caja_web_app,
            });
          });
          setCaja_list(cajas);
          $("#caja-input").val("");
        } else {
          if (response.message) {
            toast.warning(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al agregar la caja", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("Ha ocurrido un error.", {
          autoClose: 10000,
        });
      });
  };

  return (
    <>
      <Button
        variant="contained"
        className="mr-2"
        id="modal-button"
        style={{ boxShadow: "none" }}
        onClick={handleOpen}
      >
        <span
          style={{ fontSize: "14px", fontWeight: 700, color: "#fff" }}
          className="mr-2"
        >
          +
        </span>

        <span style={{ fontSize: "14px", color: "#fff" }}> Añadir nueva </span>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div id="dashboard-section">
            {/* Modal */}
            <div
              class="modal fade"
              id="confirmExit"
              tabindex="-1"
              role="dialog"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <h4>
                      <strong>¿Seguro que desea salir?</strong>
                    </h4>
                    <p>Perderá toda la información que no se ha guardardo.</p>

                    <div class="d-flex justify-content-between">
                      <Button
                        className="btn-cs1 delete-btn"
                        variant="contained"
                        color="primary"
                        onClick={() => history.goBack()}
                      >
                        Sí, salir sin guardar
                      </Button>
                      <Button
                        className="btn-cs1 "
                        variant="contained"
                        color="primary"
                        data-dismiss="modal"
                      >
                        Volver
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="content-card card mt-3"
              style={{ maxWidth: "800px" }}
            >
              <form onSubmit={handleSubmit(send_sucursal)} id="sucursal_form">
                <div
                  className="card-header border-bottom"
                  style={{ boxShadow: "none" }}
                >
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <h5 className="mb-0">
                      <strong>Agregar nueva sucursal</strong>
                    </h5>

                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="card-body pb-0">
                  <div className="row mb-4">
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Código</strong>
                      </label>

                      <TextField
                        className="w-100"
                        placeholder="Ingrese el código de su sucursal"
                        inputRef={register}
                        name="codigo"
                        required={true}
                        defaultValue={sucursal.codigo_sucursal}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Nombre de la sucursal</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="Ingrese el nombre de la sucursal"
                        inputRef={register}
                        name="nombre"
                        required={true}
                        defaultValue={sucursal.nombre_sucursal}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Dirección de la sucursal</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="Ingrese la dirección de la sucursal"
                        inputRef={register}
                        name="direccion"
                        required={true}
                        defaultValue={sucursal.direccion_sucursal}
                      />
                    </div>

                    {/* Second Row */}
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Teléfono de la sucursal</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="(          )            -              -"
                        inputRef={register}
                        name="telefono"
                        required={true}
                        defaultValue={sucursal.telefono_sucursal}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Código de establecimiento</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="Ingrese el código del establecimiento"
                        inputRef={register}
                        name="establecimiento_codigo"
                        required={true}
                        defaultValue={sucursal.descripcion_ubicacion}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Ubicación o bodega</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="Ingrese la ubicación o bodega"
                        inputRef={register}
                        name="bodega_ubicacion"
                        required={true}
                        defaultValue={sucursal.descripcion_bodega}
                      />
                    </div>
                    {isCmi() && isAdmin() && (
                      <>
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Código de la zona</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Código de la zona"
                            inputRef={register}
                            name="codigo_zona"
                            defaultValue={sucursal.codigo_zona}
                          />
                        </div>
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Código de ubicacion</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Código de la ubicación"
                            inputRef={register}
                            name="codigo_ubicacion"
                            defaultValue={sucursal.codigo_ubicacion}
                          />
                        </div>
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Ubicación del la sucursal SAP</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ubicación del la sucursal SAP"
                            inputRef={register}
                            name="ubicacion_sap"
                            defaultValue={sucursal.ubicacion_sap}
                          />
                        </div>
                      </>
                    )}

                    {/* Third Row */}
                  </div>
                </div>
                <div
                  className="row"
                  style={{ background: "#F8F8F8", margin: "0 0.4px" }}
                >
                  <div className="col-md-4 mt-3 pl-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <label style={{ fontSize: "0.8rem" }} className="pl-2">
                        <strong>Creación de caja</strong>
                      </label>
                    </div>
                    <p className="pl-2">
                      Puedes crear cajas para luego asignarla a sus vendedores
                    </p>
                  </div>
                  <div className="col-md-8 mt-3 pr-4">
                    <label style={{ fontSize: "0.8rem" }} className="w-100">
                      <strong>Nombre de caja</strong>
                    </label>
                    <TextField
                      style={{ width: "65%" }}
                      placeholder="Nombre de caja"
                      id="caja-input"
                      inputRef={register}
                      name="caja_nombre"
                    />
                    <Button
                      variant="contained"
                      className="ml-4"
                      style={{
                        background: "#0160E7",
                        boxShadow: "none",
                        width: "30%",
                      }}
                      onClick={add_caja}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#fff",
                        }}
                        className="mr-2"
                      >
                        +
                      </span>

                      <span style={{ fontSize: "14px", color: "#fff" }}>
                        {" "}
                        Añadir nueva{" "}
                      </span>
                    </Button>

                    {caja_list.map((caja, index) => (
                      <>
                        <div
                          className="d-flex align-items-center pl-2 mb-2 flex-wrap"
                          key={index}
                        >
                          <div style={{ width: "14%" }}>
                            <IconButton
                              className="btn-cs1 icon-btn-delete"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                deleteCaja(caja.id_caja_web_app, index)
                              }
                            >
                              <DeleteIcon
                                style={{
                                  color: "white",
                                }}
                              />
                            </IconButton>
                          </div>
                          <div style={{ width: "86%" }}>
                            <label
                              style={{ fontSize: "0.8rem" }}
                              className="w-100"
                            >
                              <strong>Nombre de caja</strong>
                            </label>
                            {!!edition ? (
                              <TextField
                                className="w-100"
                                placeholder="Nombre de caja"
                                id="caja_descripcion"
                                inputRef={register}
                                name="caja_descripcion"
                                required={true}
                                defaultValue={caja.descripcion}
                                onBlur={(e) =>
                                  updateCaja(e, caja.id_caja_web_app)
                                }
                              />
                            ) : (
                              <TextField
                                type="text"
                                className="w-100"
                                defaultValue={caja.descripcion}
                                disabled
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
                <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={handleClose}
                  >
                    <span style={{ color: "#0160E7", fontSize: "14px" }}>
                      Cancelar
                    </span>
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    style={{ background: "#0160E7", boxShadow: "none" }}
                    type="submit"
                  >
                    <span style={{ color: "#fff", fontSize: "14px" }}>
                      Guardar
                    </span>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
