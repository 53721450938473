import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getAll = async (activo) => {
  if (activo) {
    return await axios.get(
      `${base_url}/web/attention-channels/all?activo=${activo}`,
      {
        headers: headers(),
      }
    );
  } else {
    return await axios.get(`${base_url}/web/attention-channels/all`, {
      headers: headers(),
    });
  }
};

const createNew = async (data) => {
  return await axios.post(
    `${base_url}/web/attention-channels/create`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const updateExisting = async (data) => {
  return await axios.put(
    `${base_url}/web/attention-channels/update`,
    qs.stringify(data),
    { headers: headers() }
  );
};

export default {
  getAll,
  createNew,
  updateExisting,
};
