import React from "react";
import PieChart from "recharts/lib/chart/PieChart";
import Cell from "recharts/lib/component/Cell";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import Pie from "recharts/lib/polar/Pie";
const data = [
  { name: "Camisas", value: 500 },
  { name: "Vaso termico", value: 400 },
  { name: "Gorra", value: 300 },
  { name: "Otros", value: 1100 },
];
const COLORS = ["#33eaff", "#fff200", "#27ae60 ", "#3d3d3d"];
const Products = () => {
  return (
    <div className="card shadow-sm" style={{ border: "none" }}>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <div>
              <strong className="w-100 ml-2">Productos más comprados</strong>
              <div className="d-flex w-100 ml-2" style={{ flexWrap: "wrap" }}>
                {data.map((item, index) => (
                  <>
                    <div className="d-flex align-items-center w-100 py-2">
                      <div
                        className="mr-1"
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          background: COLORS[index],
                        }}
                      />
                      <span>{item.name}</span>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <ResponsiveContainer>
              <PieChart width={800} height={150}>
                <Pie
                  data={data}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
