import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const CombosReporte = ({ objeto }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="combos-reporte-table">
        <thead>
          <tr>
            <th>Nombre / Codigo</th>
            <th>Existencia</th>
            <th>Precio</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            return (
              <tr key={index}>
                <td>
                  {x.nombre} / {x.codigo}
                </td>
                <td>{numberFormater(x.stock)}</td>
                <td>
                  {moneda}.{" "}
                  {x.descuento_precio > 0.0
                    ? formaterPriceFunction(
                        (x.descuento_precio + (x.impuesto_idp || 0)).toFixed(
                          2
                        ) + ""
                      )
                    : formaterPriceFunction(
                        (x.precio_venta + (x.impuesto_idp || 0)).toFixed(2) + ""
                      )}{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CombosReporte;
