import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import CloseIcon from "@material-ui/icons/Close";
import DataTable from "react-data-table-component";
import { Link, NavLink } from "react-router-dom";
import { Link as MaterialLink } from "@material-ui/core";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import { isCmi } from "../../helpers/isCmi";

import { CSVLink } from "react-csv";

// styles
import "./custom.css";

//icons

import HomeIcon from "@material-ui/icons/Home";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

// components
import NewNivel from "./configComponents/NewNivel";
import ShowNiveles from "./configComponents/ShowNiveles";
import GuateFacturaForm from "./Guatefacturas";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import EmpresaModal from "./EmpresaModal";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import SucursalModal from "./SucursalModal";
import { isGerenteRegional } from "../../helpers/isGerenteRegional";

//data
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const satProviders = [
  {
    id_prov_sat: 1,
    name_provider: "DOCUTEC",
  },
  {
    id_prov_sat: 2,
    name_provider: "INFILE",
  },
  {
    id_prov_sat: 3,
    name_provider: "DIGIFACT",
  },
  {
    id_prov_sat: 4,
    name_provider: "GUATEFACTURAS",
  },
]

export default function User_conifg(props) {
  let history = useHistory();
  const queryString = require("query-string");
  const { register, handleSubmit } = useForm();
  const [open, setOpen] = React.useState(false);
  const [isProviderFEL, setIsproviderFEL] = useState(false);
  const [IdProveedorSatFEL, setIdProveedorSatFEL] = useState(0);
  const {
    register: priceLevelInput,
    handleSubmit: handlePriceForm,
    reset,
  } = useForm();
  const moment = require("moment");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      $("#edit-attr").modal("hide");
      $("edit-category").modal("hide");
    };
  }, []);

  // states
  const [allInvoices, setAllInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedSucursales, setSelectedSucursales] = useState([]);
  const [configTab, setConfigTab] = useState(0);
  const [classes, setClasses] = useState([]);
  const [regions, setRegions] = useState([]);
  const [empresaInfoLoader, setEmpresaInfoLoader] = useState(true);
  const [parametersLoader, setParametersLoader] = useState(true);
  const [invoicesLoader, setInvoicesLoader] = useState(true);
  const [IDPdata, setIDPdata] = useState([]);
  const [isEdit, setisEdit] = useState(false);
  const [numberIddpEdit, setnumberIddpEdit] = useState(0);
  const [dataCertificateFEL, setdataCertificateFEL] = useState([]);
  const [alldataCertificateFEL, setalldataCertificateFEL] = useState([]);
  const [alldataCertificatenoFEL, setalldataCertificatenoFEL] = useState([]);
  const [editFEL, setEditFel] = useState(false);
  const [dataidp, setdataIDP] = useState({
    id_producto_idp: 0,
    impuesto_cant: 0,
    nombre: "",
    nombre_corto: "",
  });
  const [parameters, setParameters] = useState({
    id_proveedor_sat_fel: "",
    id_new_empresa: "",
    id_prov_sat: "",
    fe_llave_firma: "",
    fe_llave_certificacion: "",
    fe_cod_cliente: "",
    fe_alias_cliente: "",
    fe_nombre_emisor: "",
    fe_nit_emisor: "",
    fe_cod_pais: "",
    fe_direccion_emisor: "",
    fe_pais_emisor: "",
    fe_departamento_emisor: "",
    fe_municipio_emisor: "",
    fe_cod_moneda: "",
    fe_usuario: "",
    username: "",
    password: "",
    clientid: "",
  });
  const [sucursals, setSucursals] = useState([]);
  const [empresaInfo, setEmpresaInfo] = useState({
    codigo_establecimiento: "",
    negocio: "",
    nombre: "",
    direccion: "",
    telefono: "",
    iva: 0,
    nit: "",
    id_moneda_web_app: "",
    dispositivo: "",
    regimen_isr: "",
    tipo_negocio: "",
    region: "",
    flag_fel: "",
    direccion_ws_sincronizador: "",
    dias_mantener_pedidos: "",
    pagos_comision_cloud: 0,
    pagos_comision_visanet: 0,
    pagos_correo_contabilidad: "",
  });
  const [ivaValue, setIvaValue] = useState("");
  const [priceLevels, setPriceLevels] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [regionsLoaded, setRegionsLoaded] = useState(false);
  const [classesLoaded, setClassesLoaded] = useState(false);
  const [sucursalsfilterFEL, setsucursalfilterFEL] = useState([]);
  const [dataEditFEL, setdataEditiFEL] = useState(null);
  const [providerSatSelected, setProviderSatSelected] = useState("");
  const [sucursalSatSelected, setSucursalSatSelected] = useState("");
  const [businessTypesLoaded, setBusinessTypesLoaded] = useState(false);
  const [grandes_retenedores, setGrandesRetenedores] = useState(false);

  // ************************* cmi *********************************
  const [canalesVentas, setCanalesVentas] = useState({});

  // niveles de precios
  const [isCredito, setIsCredito] = useState(false);
  const [toggleBtnCrearNivel, setToggleBtnCrearNivel] = useState(false);
  const [createNivel, setCreateNivel] = useState(false);
  const [nuevoNivel, setNuevoNivel] = useState({
    id_cliente: "",
    nivel: "",
    condicionante: "none",
    frecuencia: 0,
    por_defecto: 0,
    subniveles: null,
  });
  const [sendingNivel, setSendingNivel] = useState(false);
  const [niveles, setNiveles] = useState([]);
  const [nuevoSubNivel, setNuevoSubNivel] = useState({
    nombre: "",
    cantidad_desde: 0,
    cantidad_hasta: 0,
  });

  const [entorno_produccion, setEntornoProduccion] = useState(false);
  const [currentMoneda, setCurrentMoneda] = useState("");

  // set states

  useEffect(() => {
    getBusinessTypes();
    if (props.history.location.state) {
      //console.log(props.history.location.state)
      console.log("yo", props.history.location.state);
      setConfigTab(props.history.location.state.selectedTab);
    } else {
      setConfigTab(0);
    }
    getParameters();
    getSucursals();
    getInvoices();
    getClasses();
    getRegions();
    getPriceLevels();
    getIDPdata();
    const parsedquery = queryString.parse(props.location.search);
    if (parsedquery) {
      if (parsedquery.addIDP === "true") {
        setisEdit(false);
        setIsproviderFEL(false);
        setConfigTab(1);
        handleClickOpen();
      }
    }
  }, []);

  useEffect(() => {
    if (currentMoneda === "Q") {
      setIvaValue("12");
    }
  }, [currentMoneda]);

  useEffect(() => {
    getCanalesVentas();
  }, []);

  useEffect(() => {
    if (businessTypesLoaded && classesLoaded && regionsLoaded) {
      getEmpresaInfo();
    }
  }, [businessTypesLoaded, classesLoaded, regionsLoaded]);

  const handleConfigTabs = (event, newValue) => {
    setConfigTab(newValue);
  };
  const EditIDPdata = () => {
    if (dataidp.nombre_corto === "") {
      toast.warning("Agrega un nombre corto al IDP", {
        autoClose: 10000,
      });
    } else if (dataidp.nombre === "") {
      toast.warning("Agrega un nombre al IDP", {
        autoClose: 10000,
      });
    } else {
      API.userConfig
        .edit_IDP(dataidp)
        .then((res) => {
          let response = res.data;
          if (res.status === 200) {
            toast.success("IDP Editado con Éxito", {
              autoClose: 10000,
            });
            getIDPdata();
            //handleClose()
          } else {
            if (response.message) {
              toast.warning(response.message, {
                autoClose: 10000,
              });
            } else {
              toast.warning("Ocurrió un error.", {
                autoClose: 10000,
              });
            }
          }
        })
        .catch((err) => {
          let response = err.response;
          //console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error al actualizar los parametros", {
            autoClose: 10000,
          });
        });
    }
  };

  const addIDPdata = () => {
    if (dataidp.nombre_corto === "") {
      toast.warning("Agrega un nombre corto al IDP", {
        autoClose: 10000,
      });
    } else if (dataidp.nombre === "") {
      toast.warning("Agrega un nombre al IDP", {
        autoClose: 10000,
      });
    } else {
      API.userConfig
        .add_IDP(dataidp)
        .then((res) => {
          let response = res.data;
          if (res.status === 200) {
            toast.success("IDP Agregado con Éxito", {
              autoClose: 10000,
            });
            getIDPdata();
            //handleClose()
          } else {
            if (response.message) {
              toast.warning(response.message, {
                autoClose: 10000,
              });
            } else {
              toast.warning("Ocurrió un error.", {
                autoClose: 10000,
              });
            }
          }
        })
        .catch((err) => {
          let response = err.response;
          //console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error al actualizar los parametros", {
            autoClose: 10000,
          });
        });
    }
  };
  const getIDPdata = () => {
    API.userConfig
      .getIDP()
      .then((res) => {
        //console.log(res)
        if (res.status === 200) {
          let data = res.data.data;
          //console.log(data)
          setIDPdata(data);
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("Ha ocurrido un error al obtener los datos", {
          autoClose: 10000,
        });
      });
  };
  const resourceAction = (event, id) => {
    if (event.target.value === "edit") {
      history.push({
        pathname: "/app/config/surcursal/new",
        state: { id },
      });
    }
    if (event.target.value === "delete") {
      deleteSucursal(id);
    }
    if (event.target.value === "cajas") {
      history.push({
        pathname: `/app/config/surcursal/${id}/cajas`,
      });
    }
  };

  const resourceActionFEL = (event, row) => {
    console.log(row);
    if (event.target.value === "edit") {
      //console.log(row)
      setdataEditiFEL(null);
      let sucursalFelarray = [
        {
          id_sucursal: row.id_sucursal,
          name_sucursal: row.sucursal,
        },
      ];
      setsucursalfilterFEL(sucursalFelarray);
      setProviderSatSelected(row.id_prov_sat.toString());
      setSucursalSatSelected(row.id_sucursal.toString());
      let filterdataFEL = alldataCertificateFEL.filter(
        (data) => data.datosFel.id_sucursal === row.id_sucursal
      );
      filterdataFEL[0].datosFel.grandes_retenedores !== undefined
        ? setGrandesRetenedores(filterdataFEL[0].datosFel.grandes_retenedores)
        : setGrandesRetenedores(false);
      setdataEditiFEL(filterdataFEL[0].datosFel);
      setEditFel(true);
      openmodalproviderFEL();
    } else if (event.target.value === "add") {
      console.log(row);
      let sucursalFelarray = [
        {
          id_sucursal: row.id_sucursal,
          name_sucursal: row.sucursal,
        },
      ];
      setsucursalfilterFEL(sucursalFelarray);
      setSucursalSatSelected(row.id_sucursal.toString());
      setdataEditiFEL(null);
      setIdProveedorSatFEL(row.id_proveedor_sat_fel);
      setEditFel(false);
      openmodalproviderFEL();
    } else if (event.target.value === "delete") {
      deleteSucursalFel(row.id_proveedor_sat_fel);
    }
  };

  /*
	const addFELnewSucursal = () =>{

	}*/

  const resourceActionIDP = (event, id) => {
    if (event.target.value === "edit") {
      setnumberIddpEdit(id.id_producto_idp);
      setdataIDP(id);
      openmodaleditorsave("edit");
    }
    if (event.target.value === "delete") {
      deleteIDP(id.id_producto_idp);
    }
  };

  const getParameters = () => {
    API.userConfig
      .get_parameters()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          console.log("GETTING THIS", response.data);
          if (response.data) setParameters(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener los parametros", {
              autoClose: 10000,
            });
          }
        }
        setParametersLoader(false);
      })
      .catch((err) => {
        console.log(err.response);
        setParametersLoader(false);
        toast.error("Ha ocurrido un error al obtener los parametros", {
          autoClose: 10000,
        });
      });
  };

  const save_parameters = (data) => {
    setParametersLoader(true);
    data.id_new_empresa = empresaInfo.id_new_empresa;
    if (editFEL) {
      data.id_prov_sat = dataEditFEL.id_prov_sat;
      data.id_sucursal = dataEditFEL.id_sucursal;
      data.id_proveedor_sat_fel = dataEditFEL.id_proveedor_sat_fel;
    } else {
      data.id_proveedor_sat_fel = IdProveedorSatFEL;
      console.log(data);
      data.id_prov_sat = parseInt(data.id_prov_sat);
      data.id_sucursal = parseInt(data.id_sucursal);
    }

    if (data.id_prov_sat !== 1) {
      data.grandes_retenedores = grandes_retenedores;
    }

    if (data.id_prov_sat === 4) {
      data.entorno_produccion = 0;
    }

    API.userConfig
      .saveoreditFELsucursal(data)
      .then((res) => {
        let response = res.data;
        if (response.ok) {
          toast.success(response.msg, {
            autoClose: 10000,
          });
          setParametersLoader(false);
          getSucursalFEL(empresaInfo.id_new_empresa);
          handleClose();
        } else {
          toast.error(response.msg, {
            autoClose: 10000,
          });
          setParametersLoader(false);
        }
      })
      .catch((err) => {
        setParametersLoader(false);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error al actualizar los parametros", {
          autoClose: 10000,
        });
      });
    //setParametersLoader(true);
    /*
		API.userConfig
			.add_parameter(data)
			.then((res) => {
				let response = res.data;
				if (response.success) {
					toast.success('Parametros de adición actualizados con éxito', {
						autoClose: 10000
					});
					getParameters();
				} else {
					if (response.message) {
						toast.warning(response.message, {
							autoClose: 10000
						});
					} else {
						toast.warning('Ocurrió un error.', {
							autoClose: 10000
						});
					}
				}
			})
			.catch((err) => {
				let response = err.response;
				console.log(response);
				console.log('Ha ocurrido un error');
				toast.error('Ha ocurrido un error al actualizar los parametros', { autoClose: 10000 });
			});*/
  };

  const deleteIDP = (id) => {
    API.userConfig
      .deleteIDP({ id_producto_idp: id })
      .then((res) => {
        let response = res.data;

        if (res.status === 200) {
          //getIDPdata()
        } else {
          if (response.message) {
            toast.warning(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.warning("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        let response = err.response;
        //console.log(response);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const openmodalproviderFEL = () => {
    setIsproviderFEL(true);
    handleClickOpen();
  };

  const openmodaleditorsave = (status) => {
    setIsproviderFEL(false);
    if (status === "save") {
      setdataIDP({
        id_producto_idp: 0,
        impuesto_cant: 0,
        nombre: "",
        nombre_corto: "",
      });
      setisEdit(false);
      handleClickOpen();
    } else {
      setisEdit(true);
      handleClickOpen();
    }
  };
  const handleChangeimpuestocant = (event) => {
    console.log(event.target.value);
    dataidp.impuesto_cant = event.target.value;
  };

  const handleChangenombre = (event) => {
    console.log(event.target.value);
    dataidp.nombre = event.target.value;
  };

  const handleChangenombrecorto = (event) => {
    console.log(event.target.value);
    dataidp.nombre_corto = event.target.value;
  };

  const getClasses = () => {
    API.userConfig
      .getClasses()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setClasses(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las clases", {
              autoClose: 10000,
            });
          }
        }
        setClassesLoaded(true);
      })
      .catch((err) => {
        setClassesLoaded(true);
        console.log(err.response);
        toast.error("Ha ocurrido un error al obtener las clases", {
          autoClose: 10000,
        });
      });
  };

  const getRegions = () => {
    API.userConfig
      .getRegions()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setRegions(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las clases", {
              autoClose: 10000,
            });
          }
        }
        setRegionsLoaded(true);
      })
      .catch((err) => {
        setRegionsLoaded(true);
        console.log(err.response);
        toast.error("Ha ocurrido un error al obtener las clases", {
          autoClose: 10000,
        });
      });
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success && JSON.stringify(response.data) !== "[]") {
          setSucursals(response.data);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getEmpresaInfo = () => {
    API.userConfig
      .getEmpresaInfo({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Company data:", response.data);
          setEmpresaInfo(response.data);
          getSucursalFEL(response.data.id_new_empresa);
          const moneda = response.data.monedas.find(
            (item) => item.id_moneda_web_app === response.data.id_moneda_web_app
          );
          setCurrentMoneda(moneda.simbolo.trim());
          localStorage.setItem("moneda", moneda.simbolo.trim());
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
        setEmpresaInfoLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);

        console.log("Ha ocurrido un error", err.response);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const getInvoices = () => {
    setInvoicesLoader(true);
    API.invoices
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setAllInvoices(response.data);
          setFilteredInvoices(response.data);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
        setInvoicesLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
    setInvoicesLoader(false);
  };

  const getCanalesVentas = async () => {
    try {
      const res = await API.userConfig.getAllCanalesVentas();
      console.log("Canales Ventas", res.data.data);
      setCanalesVentas(res.data.data);

      let des = res.data.data.filter(
        (item) => item.nombre.toLowerCase() == "pick-up"
      );
      let descuento = des[0].costo_descuento;

      console.log("descuento -> ", descuento);

      setEmpresaInfo({
        ...empresaInfo,
        valor_entrega_domicilio: descuento,
      });
    } catch (error) {
      console.log("Error al obtener canales ventas", error);
      toast.error("Error al obtener canales ventas", { autoClose: 10000 });
    }
  };

  const enviar_valor_descuento = async (descuento) => {
    try {
      let canalFiltrado = canalesVentas.filter(
        (item) => item.id_canal_cmi === 6
      );
      let canal = canalFiltrado[0].id_canal_cmi;
      // id empresa
      const data = {
        id_new_empresa: empresaInfo.id_new_empresa,
        id_canal_cmi: canal,
        costo_adicional: 0,
        costo_descuento: descuento,
      };

      const res = await API.userConfig.updateCostoDescuento(data);
      console.log("actualizar descuento", res);

      if (res?.data?.ok) {
        toast.success("Descuento actualizado correctamente", {
          autoClose: 10000,
        });

        const descuentoGuardado =
          await API.userConfig.getDescuentosCanalesVentas(
            empresaInfo.id_new_empresa
          );
        // if (descuentoGuardado?.data?.ok) {
        //   console.log(
        //     "descuentoGuardado => ",
        //     descuentoGuardado.data.allAttentionChannels[0].costo_descuento
        //   );
        // } else {
        //   console.log("Error en descuentoGuardado", descuentoGuardado);
        // }
      } else {
        toast.error("Error al actualizar descuento", { autoClose: 10000 });
      }
    } catch (error) {
      console.log("Ha ocurrido un error al actualizar descuento", error);
      toast.error("Ha ocurrido un error al actualizar descuento", {
        autoClose: 10000,
      });
    }
  };

  const update_empresa = (data) => {
    console.log("Data para actualizar la empresa", data);
    if (currentMoneda === "L") {
      data.iva = 1;
    }
    setEmpresaInfoLoader(true);
    API.userConfig
      .updateEmpresa(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });

          getEmpresaInfo();
          if (isCmi()) {
            enviar_valor_descuento(
              data.valor_entrega_domicilio ||
                empresaInfo.valor_entrega_domicilio
            );
          }
        } else {
          if (response.message) {
            toast.warning(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.warning("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);

        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const formatosAction = (event, id) => {
    if (event.target.value === "ver") {
      history.push(`/app/formatos/${id}`);
    }

    if (event.target.value === "favorite") {
      addInvoiceToFavorites(id);
    }
    if (event.target.value === "unfavorite") {
      removeInvoiceFromFavorites(id);
    }
  };

  const displayFormats = (event) => {
    if (event.target.value === "favoritos") {
      let favoritos = allInvoices.filter(
        (formato) => formato.favorita === true
      );
      setFilteredInvoices(favoritos);
    } else if (event.target.value === "") {
      setFilteredInvoices(allInvoices);
    }
  };

  const deleteSucursal = (id) => {
    API.userConfig
      .deleteSucursal({ id_sucursal_bodega_ubicacion: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          getSucursals();
        } else {
          if (response.message) {
            toast.warning(response.message, {
              autoClose: 10000,
            });
          } else {
            toast.warning("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;
    let stores = [];

    selectedRows.map((row) =>
      stores.push({
        codigo_sucursal: row.codigo_sucursal,
        nombre_sucursal: row.nombre_sucursal,
        direccion_sucursal: row.direccion_sucursal,
        telefono_sucursal: row.telefono_sucursal,
        descripcion_ubicacion: row.descripcion_ubicacion,
      })
    );
    setSelectedSucursales(stores);
  };

  const addInvoiceToFavorites = (id) => {
    setInvoicesLoader(true);
    API.invoices
      .setFavorite({ factura_id: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          getInvoices();
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
        setInvoicesLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
    setInvoicesLoader(false);
  };

  const getSucursalFEL = (id) => {
    API.userConfig
      .getDatasucursalesFEL({ id_new_empresa: id })
      .then((res) => {
        let response = res.data;
        let dataShowinTable = [];
        let datanoFEL = response.sucursalesSinFel;
        let dataFEL = response.sucursalesConFel;
        datanoFEL.forEach((element) => {
          dataShowinTable.push({
            id_sucursal: element.datosSucursal.id_sucursal_bodega_ubicacion,
            id_prov_sat: element.datosSucursal.id_prov_sat,
            name_provider: element.nombreProveedor,
            sucursal: element.datosSucursal.nombre_sucursal,
            direccion: element.datosSucursal.direccion_sucursal,
            estado: "INACTIVO",
          });
        });
        setalldataCertificatenoFEL(datanoFEL);
        dataFEL.forEach((element) => {
          dataShowinTable.push({
            id_proveedor_sat_fel: element.datosFel.id_proveedor_sat_fel,
            id_sucursal: element.datosFel.id_sucursal,
            id_prov_sat: element.datosFel.id_prov_sat,
            name_provider: element.nombreProveedor,
            sucursal: element.nombreSucursal,
            direccion: element.datosFel.fe_direccion_emisor,
            estado: "ACTIVO",
          });
        });
        console.log(datanoFEL);
        console.log(dataFEL);
        setalldataCertificateFEL(dataFEL);
        setdataCertificateFEL(dataShowinTable);
      })
      .catch((err) => {
        console.log("Ha ocurrido un error", err);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const deleteSucursalFel = (id) => {
    API.userConfig
      .deleteSucursalFel(id)
      .then((res) => {
        let data = res.data;
        if (data.ok) {
          getSucursalFEL(empresaInfo.id_new_empresa);
          toast.success(data.msg, {
            autoClose: 10000,
          });
        } else {
          toast.error(data.msg, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        console.log("Ha ocurrido un error", err);
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
  };

  const removeInvoiceFromFavorites = (id) => {
    setInvoicesLoader(true);
    API.invoices
      .unsetFavorite({ factura_id: id })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          getInvoices();
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
        setInvoicesLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", {
          autoClose: 10000,
        });
      });
    setInvoicesLoader(false);
  };

  const getPriceLevels = () => {
    API.priceLevels
      .getAll()
      .then((res) => {
        let response = res.data;

        if (response.success) {
          setPriceLevels(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error al obtener los niveles de precio");
          toast.error("Ha ocurrido un error al obtener los niveles de precio", {
            autoClose: 10000,
          });
        }
      });
  };

  const updatePriceLevelElement = (row, event, type) => {
    let value = event.target.value;
    let existingPriceLevels = [...priceLevels];
    let index = existingPriceLevels.findIndex(
      (x) => x.id_nivel_precio === row.id_nivel_precio
    );
    if (type === "descripcion") existingPriceLevels[index].descripcion = value;
    if (type === "suspendido")
      existingPriceLevels[index].suspendida = parseInt(value);

    setPriceLevels(existingPriceLevels);
  };

  const createPriceLevel = (data) => {
    API.priceLevels
      .createNew({
        descripcion: data.descripcion,
        suspendido: parseInt(data.suspendido),
      })
      .then((res) => {
        let response = res.data;

        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          getPriceLevels();
          reset();
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log(
            "Ha ocurrido un error al actualizar los niveles de precio"
          );
          toast.error(
            "Ha ocurrido un error al actualizar los niveles de precio",
            { autoClose: 10000 }
          );
        }
      });
  };

  const changeValueSucursalFEL = (event) => {
    setSucursalSatSelected(event.target.value);
  };

  const changeValueproviderSAT = (event) => {
    setProviderSatSelected(event.target.value);
  };

  const updatePriceLevel = (id) => {
    let currentLevel = priceLevels.find((x) => x.id_nivel_precio === id);
    API.priceLevels
      .createNew({
        id_nivel_precio: id,
        descripcion: currentLevel.descripcion,
        suspendido: parseInt(currentLevel.suspendida),
      })
      .then((res) => {
        let response = res.data;

        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          getPriceLevels();
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log(
            "Ha ocurrido un error al actualizar los niveles de precio"
          );
          toast.error(
            "Ha ocurrido un error al actualizar los niveles de precio",
            { autoClose: 10000 }
          );
        }
      });
  };

  const getBusinessTypes = () => {
    API.userConfig
      .getBusinessTypes()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setBusinessTypes(response.data);
          console.log("Business types, response", response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            console.log("response", response);
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
        setBusinessTypesLoaded(true);
      })
      .catch((err) => {
        console.log("Error", err, err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  // niveles de precios cmi
  const getNivelesCliente = async () => {
    setSendingNivel(true);

    try {
      const response = await API.userConfig.getNiveles(
        parameters.id_new_empresa
      );
      console.log("Niveles", response.data.costumer);
      setNiveles(response.data.costumer);
      setSendingNivel(false);
    } catch (error) {
      console.log("Error al buscar niveles", error);
      toast.error("Error al buscar niveles", { autoClose: 10000 });
      setSendingNivel(false);
    }
  };

  const toggleNewNivel = () => {
    setCreateNivel(true);
  };

  const handleChangeNewNivel = (event) => {
    const { name, value } = event.target;
    setNuevoNivel({
      ...nuevoNivel,
      [name]: value,
    });
  };

  const handleClickNewNivel = async (event) => {
    const regex = /^[0-9]*$/;

    if (nuevoNivel.nivel === "" || regex.test(nuevoNivel.nivel)) {
      toast.warning("Agrega un nombre válido al nivel de precios", {
        autoClose: 10000,
      });
      return null;
    }

    if (isNaN(nuevoNivel.frecuencia)) {
      toast.warning("Frecuencia de compra debe ser numerico", {
        autoClose: 10000,
      });
      return null;
    }

    if (!Number.isInteger(Number(nuevoNivel.frecuencia))) {
      toast.warning("Frecuencia de compra debe ser un número entero", {
        autoClose: 10000,
      });
      return null;
    }

    nuevoNivel.id_cliente = parameters.id_new_empresa;

    setSendingNivel(true);

    console.log("Nuevo nivel", nuevoNivel);

    try {
      const response = await API.userConfig.createNivel(nuevoNivel);

      console.log("Creación de nivel", response);

      if (response?.data?.ok) {
        setSendingNivel(false);

        setNuevoNivel({
          id_cliente: "",
          nivel: "",
          condicionante: "none",
          frecuencia: 0,
          por_defecto: 0,
          subniveles: null,
        });

        setCreateNivel(false);
        toast.success(response.data.msg, { autoClose: 10000 });
        await getNivelesCliente();
      } else {
        toast.error("Error al crear el nivel", { autoClose: 10000 });
        setSendingNivel(false);
        return null;
      }
    } catch (error) {
      console.log("Error en la peticion al crear Nivel", error);

      toast.error("Error en la peticion al crear Nivel", { autoClose: 10000 });
      setSendingNivel(false);
      // setCreateNivel(false)
      return null;
    }
  };

  const handleChangeNewSubNivel = (event) => {
    const { name, value } = event.target;

    setNuevoSubNivel({
      ...nuevoSubNivel,
      [name]: value,
    });
  };

  const handleEliminarNivel = async (id_nivel) => {
    console.log("id nivel", id_nivel);

    setSendingNivel(true);

    try {
      const response = await API.userConfig.eliminarNivelesSubniveles(
        id_nivel,
        "",
        "nivel"
      );

      console.log("Eliminar nivel", response);

      if (response?.data?.ok) {
        toast.success(response.data.msg, {
          autoClose: 10000,
        });

        await getNivelesCliente();
        setSendingNivel(false);
      } else {
        toast.error(response.data.msg, {
          autoClose: 10000,
        });
        setSendingNivel(false);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("Error al eliminar nivel", {
        autoClose: 10000,
      });
      setSendingNivel(false);
    }
  };

  const handleClickNewSubNivel = async (identificador) => {
    const regex = /^[0-9]*$/;

    if (nuevoSubNivel.nombre === "" || regex.test(nuevoSubNivel.nombre)) {
      toast.warning("Agrega un nombre válido al subnivel de precios", {
        autoClose: 10000,
      });
      return null;
    }
    // crear la estructura para el subnivel
    const subNivel = {
      id_level: identificador,
      subniveles: [nuevoSubNivel],
    };

    setSendingNivel(true);

    try {
      const response = await API.userConfig.crearSubnivel(subNivel);

      if (response?.data?.ok) {
        toast.success(response.data.msg, { autoClose: 10000 });

        await getNivelesCliente();

        setSendingNivel(false);
      } else {
        toast.error("No se pudo guardar el subnivel", { autoClose: 10000 });
        setSendingNivel(false);
        return null;
      }

      /* Enviar nuevo sub nivel */
      console.log("nuevos subNiveles", response);
    } catch (error) {
      console.log("error al crear el subnivel", error);
      toast.error("Ha ocurrido un error", { autoClose: 10000 });
      setSendingNivel(false);
    }
  };

  const handleUpdateSubNivel = async (data, id_nivel) => {
    console.log(data);
    console.log(id_nivel);
    if (
      isNaN(data.cantidad_desde) ||
      isNaN(data.cantidad_hasta) ||
      parseFloat(data.cantidad_desde) > parseFloat(data.cantidad_hasta)
    ) {
      toast.warn("Error en cantidades");
      return;
    }
    console.log(niveles);

    let newNiveles = niveles.filter((x) => x.id === id_nivel)[0];
    console.log(newNiveles);
    newNiveles.subniveles.map((x) => {
      if (x.id === data.id) {
        x.cantidad_desde = data.cantidad_desde;
        x.cantidad_hasta = data.cantidad_hasta;
      }
    });

    try {
      const response = await API.userConfig.updateSubnivel(newNiveles);

      if (response?.data?.ok) {
        await getNivelesCliente();
        toast.success(response.data.msg, { autoClose: 10000 });
      } else {
        toast.error("Error al actualizar el nivel", { autoClose: 10000 });
        return null;
      }
    } catch (error) {
      console.log("Error en la peticion al actualizar Nivel", error);

      toast.error("Error en la peticion al actualizar Nivel", {
        autoClose: 10000,
      });
      return null;
    }
  };

  const handleEliminarSubNivel = async (level, sublevel) => {
    setSendingNivel(true);

    try {
      const response = await API.userConfig.eliminarNivelesSubniveles(
        level,
        sublevel,
        "sublevel"
      );
      console.log("Eliminar sub nivel", response);

      if (response?.data?.ok) {
        toast.success(response.data.msg, { autoClose: 10000 });
        await getNivelesCliente();
        setSendingNivel(false);
      } else {
        toast.success("Ocurrió un error al eliminar subnivel", {
          autoClose: 10000,
        });
        setSendingNivel(false);
      }
    } catch (error) {
      console.log("Error al eliminar subnivel", error);
      toast.error("Ha ocurrido un error", { autoClose: 10000 });
    }
  };

  const handleMoneda = (e) => {
    const moneda = empresaInfo.monedas.find(
      (item) => item.id_moneda_web_app === +e.target.value
    );
    setCurrentMoneda(moneda.simbolo.trim() || "Q");
  };

  return (
    <div id="dashboard-section">
      <Dialog onClose={handleClose} open={open}>
        {!isProviderFEL ? (
          <form>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {!isEdit ? "Agregar IDP" : "Editar IDP"}
            </DialogTitle>
            <DialogContent dividers>
              <div className={classes.root}>
                <div>
                  <TextField
                    label="Valor del Impuesto"
                    id="margin-none"
                    margin="normal"
                    type="number"
                    onChange={handleChangeimpuestocant}
                    defaultValue={dataidp.impuesto_cant}
                    className={classes.textField}
                  />
                  <TextField
                    label="Nombre"
                    id="margin-dense"
                    onChange={handleChangenombre}
                    defaultValue={dataidp.nombre}
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    label="Nombre corto"
                    id="margin-normal"
                    onChange={handleChangenombrecorto}
                    defaultValue={dataidp.nombre_corto}
                    className={classes.textField}
                    margin="normal"
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => (!isEdit ? addIDPdata() : EditIDPdata())}
                color="primary"
              >
                {!isEdit ? "Guardar" : "Editar"}
              </Button>
            </DialogActions>
          </form>
        ) : (
          <div style={{ width: "100%" }}>
            <DialogTitle onClose={handleClose}>
              Configurar Proveedor FEL
            </DialogTitle>
            <form onSubmit={handleSubmit(save_parameters)} id="parameters_form">
              <DialogContent dividers>
                <div class="row justify-content-center">
                  <div class="col-lg-5" style={{ marginRight: "20px" }}>
                    <div class="row justify-content-center">
                      <Select
                        name="id_prov_sat"
                        defaultValue={providerSatSelected}
                        native
                        inputRef={register}
                        className="cs-select1 select-w100"
                        disabled={editFEL} 
                        required={true}
                        onChange={changeValueproviderSAT}
                      >
                        <option value="">Seleccione un proveedor FEL</option>
                        {/* {ProviderSat.map((data) => {
                          return (
                            <option value={data.id_prov_sat}>
                              {data.name_provider}
                            </option>
                          );
                        })} */}
                        {/* we're only integrating with INFILE option for the moment */}
                        <option value={satProviders[1].id_prov_sat}>
                          {satProviders[1].name_provider}
                        </option>
                      </Select>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="row justify-content-center">
                      <Select
                        name="id_sucursal"
                        inputRef={register}
                        defaultValue={sucursalSatSelected}
                        native
                        className="cs-select1 select-w100"
                        required={true}
                        onChange={changeValueSucursalFEL}
                        disabled={editFEL}
                      >
                        <option value="">Seleccione una sucursal</option>
                        {sucursalsfilterFEL.map((data) => {
                          return (
                            <option value={data.id_sucursal}>
                              {data.name_sucursal}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>

                  {sucursalSatSelected !== "" && providerSatSelected !== "" ? (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {providerSatSelected === "1" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Alias del emisor (Usuario)</strong>
                          </label>

                          <TextField
                            name="fe_username"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.fe_username : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el nombre de usuario del emisor"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "2" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Prefijo WS</strong>
                          </label>

                          <TextField
                            name="fe_alias_cliente"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.fe_alias_cliente : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el Prefijo WS"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "2" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Token Signer / llave firma</strong>
                          </label>

                          <TextField
                            name="fe_llave_firma"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.fe_llave_firma : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el Token Signer / llave firma"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "2" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Llave WS / llave cerificacion</strong>
                          </label>

                          <TextField
                            name="fe_llave_certificacion"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL
                                ? dataEditFEL.fe_llave_certificacion
                                : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese la Llave WS / llave cerificacion"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "1" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Contraseña del usuario </strong>
                          </label>

                          <TextField
                            defaultValue={
                              dataEditFEL ? dataEditFEL.password : ""
                            }
                            className="w-100"
                            placeholder="Ingrese la contraseña del usuario"
                            inputRef={register}
                            name="password"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "1" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Id del Cliente </strong>
                          </label>

                          <TextField
                            defaultValue={
                              dataEditFEL ? dataEditFEL.clientid : ""
                            }
                            className="w-100"
                            placeholder="Ingrese el ID del Cliente"
                            inputRef={register}
                            name="clientid"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected !== "4" && (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Nombre del emisor</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el nombre del emisor"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.fe_nombre_emisor : ""
                            }
                            name="fe_nombre_emisor"
                            required={true}
                          />
                        </div>
                      )}
                      {providerSatSelected !== "4" && (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>NIT del emisor</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el NIT  del emisor"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.fe_nit_emisor : ""
                            }
                            name="fe_nit_emisor"
                            required={true}
                          />
                        </div>
                      )}
                      {providerSatSelected === "2" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>País del emisor</strong>
                          </label>

                          <TextField
                            type="text"
                            className="w-100"
                            placeholder="Escriba el Pais"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.fe_pais_emisor : ""
                            }
                            name="fe_pais_emisor"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "2" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Departamento del emisor</strong>
                          </label>

                          <TextField
                            inputRef={register}
                            name="fe_departamento_emisor"
                            defaultValue={
                              dataEditFEL
                                ? dataEditFEL.fe_departamento_emisor
                                : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el departamento del emisor"
                            required={true}
                          />
                        </div>
                      ) : null}

                      {providerSatSelected === "2" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Municipio del emisor</strong>
                          </label>

                          <TextField
                            className="w-100"
                            placeholder="Ingrese el municipio del emisor"
                            inputRef={register}
                            name="fe_municipio_emisor"
                            defaultValue={
                              dataEditFEL ? dataEditFEL.fe_municipio_emisor : ""
                            }
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected !== "4" && (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Dirección del emisor</strong>
                          </label>

                          <TextField
                            name="fe_direccion_emisor"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.fe_direccion_emisor : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese la dirección del emisor"
                            required={true}
                          />
                        </div>
                      )}
                      {providerSatSelected === "2" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Código del Establecimiento</strong>
                          </label>

                          <TextField
                            name="codigo_establecimiento"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL
                                ? dataEditFEL.codigo_establecimiento
                                : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el código del establecimiento"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "2" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Código del Escenario</strong>
                          </label>

                          <TextField
                            name="codigo_escenario"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.codigo_escenario : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el código del escenario"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "2" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Tipo Frase</strong>
                          </label>

                          <TextField
                            name="tipo_frase"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.tipo_frase : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el tipo frase"
                            required={true}
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "2" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Tipo Frase IVA * No obligatorio</strong>
                          </label>

                          <TextField
                            name="tipo_frase_iva"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL ? dataEditFEL.tipo_frase_iva : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el tipo frase iva"
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "2" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>
                              Código Escenario IVA * No obligatorio
                            </strong>
                          </label>

                          <TextField
                            name="codigo_escenario_iva"
                            inputRef={register}
                            defaultValue={
                              dataEditFEL
                                ? dataEditFEL.codigo_escenario_iva
                                : ""
                            }
                            type="text"
                            className="w-100"
                            placeholder="Ingrese el Código Escenario IVA"
                          />
                        </div>
                      ) : null}
                      {providerSatSelected === "2" ||
                      providerSatSelected === "3" ? (
                        <div className="col-md-4 mt-3">
                          <label style={{ fontSize: "0.8rem" }}>
                            <strong>Grandes Retenedores</strong>
                          </label>
                          <label
                            class="toggle-control mx-auto"
                            style={{ left: "-15px", height: "40px" }}
                          >
                            <input
                              type="checkbox"
                              onClick={() =>
                                setGrandesRetenedores(!grandes_retenedores)
                              }
                              value={grandes_retenedores}
                              defaultChecked={
                                dataEditFEL
                                  ? dataEditFEL.grandes_retenedores
                                  : false
                              }
                            />
                            <span class="control" />
                          </label>
                        </div>
                      ) : null}
                      {providerSatSelected === "4" && (
                        <GuateFacturaForm
                          register={register}
                          dataEditFEL={dataEditFEL}
                          grandes_retenedores={grandes_retenedores}
                          setGrandesRetenedores={setGrandesRetenedores}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              </DialogContent>
              {sucursalSatSelected !== "" && providerSatSelected !== "" ? (
                <DialogActions>
                  {!parametersLoader ? (
                    <Button autoFocus color="primary" type="submit">
                      {!editFEL ? "Guardar" : "Editar"}
                    </Button>
                  ) : (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  )}
                </DialogActions>
              ) : null}
            </form>
          </div>
        )}
      </Dialog>
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Configuración de cuenta</strong>
        </h4>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <h5 className="mb-0">
            <strong>Información de su cuenta</strong>
          </h5>
          <p className="mb-0">
            Consulte y actualice todos la información asociada a su cuenta.
          </p>

          <Tabs
            className="mt-2"
            value={configTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleConfigTabs}
          >
            <Tab className="tab" label="Parámetros de adición" />
            {!isGerenteRegional() && <Tab className="tab" label="Empresas" />}
            <Tab className="tab" label="Sucursales" />
            {/*Inicio se pasa a false para quitar el tab de Formato de factura - Implementacion a futuro*/}
            {false && <Tab className="tab" label="Formato de factura" />}
            {/*Fin se pasa a false para quitar el tab de Formato de factura - Implementacion a futuro*/}
            {!isCmi() && <Tab className="tab" label="Impuestos IDP" />}
          </Tabs>
        </div>
        <div className="card-body p-0">
          <TabPanel value={configTab} index={0} className="config-tab">
            {parametersLoader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <div>
                {/*	<form onSubmit={handleSubmit(save_parameters)} id="parameters_form">
									<Button type="submit" className="btn-cs1_blue my-1" variant="contained" color="primary">
										{parametersLoader ? (
											<CircularProgress size={26} className="mx-auto" color="white" />
										) : (
											'Guardar'
										)}
									</Button>
									<div className="row mb-4">
										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Código del emisor </strong>
											</label>

											<TextField
												defaultValue={parameters.fe_cod_cliente}
												className="w-100"
												placeholder="Ingrese el código del emisor"
												inputRef={register}
												name="codigo"
												required={true}
											/>
										</div>
										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Nombre del emisor</strong>
											</label>

											<TextField
												type="text"
												className="w-100"
												placeholder="Ingrese el nombre del emisor"
												inputRef={register}
												defaultValue={parameters.fe_nombre_emisor}
												name="nombre"
												required={true}
											/>
										</div>
										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>NIT del emisor</strong>
											</label>

											<TextField
												type="text"
												className="w-100"
												placeholder="Ingrese el NIT  del emisor"
												inputRef={register}
												defaultValue={parameters.fe_nit_emisor}
												name="nit"
												required={true}
											/>
										</div>

										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Código de país</strong>
											</label>

											<FormControl variant="outlined" className="w-100">
												<Select
													name="codigo_pais"
													native
													inputRef={register}
													defaultValue={parameters.fe_cod_pais}
													className="cs-select1 select-w100"
													required={true}
												>
													<option value="">Seleccione el país</option>
													<option value="GT">Guatemala</option>
													<option value="GT">El Salvador</option>
													<option value="GT">Honduras</option>
													<option value="GT">Nicaragua</option>
													<option value="GT">Costa Rica</option>
													<option value="GT">Estados Unidos</option>
													<option value="GT">Panamá</option>
													<option value="GT">Suramerica</option>
													<option value="GT">México</option>
													<option value="GT">El caribe</option>
													<option value="GT">Otro</option>
												</Select>
											</FormControl>
										</div>
										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>País del emisor</strong>
											</label>

											<FormControl variant="outlined" className="w-100">
												<Select
													name="pais"
													native
													inputRef={register}
													defaultValue={parameters.fe_pais_emisor.toString().trim()}
													className="cs-select1 select-w100"
													required={true}
												>
													<option value="" id="AR">
														Seleccione el país
													</option>
													<option value="Argentina" id="AR">
														Argentina
													</option>
													<option value="Bolivia" id="BO">
														Bolivia
													</option>
													<option value="Brasil" id="BR">
														Brasil
													</option>
													<option value="Chile" id="CL">
														Chile
													</option>
													<option value="Colombia" id="CO">
														Colombia
													</option>
													<option value="Costa Rica" id="CR">
														Costa Rica
													</option>
													<option value="Cuba" id="CU">
														Cuba
													</option>
													<option value="Dinamarca" id="DK">
														Dinamarca
													</option>
													<option value="Dominica" id="DM">
														Dominica
													</option>
													<option value="Ecuador" id="EC">
														Ecuador
													</option>
													<option value="El Salvador" id="SV">
														El Salvador
													</option>
													<option value="España" id="ES">
														España
													</option>
													<option value="Estados Unidos" id="US">
														Estados Unidos
													</option>
													<option value="Guatemala" id="GT">
														Guatemala
													</option>
													<option value="Guayana" id="GY">
														Guayana
													</option>
													<option value="Haití" id="HT">
														Haití
													</option>
													<option value="Honduras" id="HN">
														Honduras
													</option>
													<option value="México" id="MX">
														México
													</option>
													<option value="Nicaragua" id="NI">
														Nicaragua
													</option>
													<option value="Panamá" id="PA">
														Panamá
													</option>
													<option value="Paraguay" id="PY">
														Paraguay
													</option>
													<option value="Perú" id="PE">
														Perú
													</option>
													<option value="Puerto Rico" id="PR">
														Puerto Rico
													</option>
													<option value="República Dominicana" id="DO">
														República Dominicana
													</option>
													<option value="Trinidad y Tobago" id="TT">
														Trinidad y Tobago
													</option>
													<option value="Uruguay" id="UY">
														Uruguay
													</option>
													<option value="Venezuela" id="VE">
														Venezuela
													</option>
												</Select>
											</FormControl>
										</div>
										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Departamento del emisor</strong>
											</label>

											<TextField
												inputRef={register}
												name="departamento"
												defaultValue={parameters.fe_departamento_emisor}
												type="text"
												className="w-100"
												placeholder="Ingrese el departamento del emisor"
												name="departamento"
												required={true}
											/>
										</div>

										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Municipio del emisor</strong>
											</label>

											<TextField
												className="w-100"
												placeholder="Ingrese el municipio del emisor"
												inputRef={register}
												name="municipio"
												defaultValue={parameters.fe_municipio_emisor}
												required={true}
											/>
										</div>
										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Dirección del emisor</strong>
											</label>

											<TextField
												name="direccion"
												inputRef={register}
												defaultValue={parameters.fe_direccion_emisor}
												type="text"
												className="w-100"
												placeholder="Ingrese la dirección del emisor"
												required={true}
											/>
										</div>
										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Alias del emisor (Usuario)</strong>
											</label>

											<TextField
												name="alias"
												inputRef={register}
												defaultValue={parameters.fe_alias_cliente}
												type="text"
												className="w-100"
												placeholder="Ingrese el nombre de usuario del emisor"
												required={true}
											/>
										</div>


										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Proveedor FEL</strong>
											</label>

											<FormControl variant="outlined" className="w-100">
												<Select
													name="id_proveedor_fel"
													native
													className="cs-select1 select-w100"
													inputRef={register}
													defaultValue={parameters.id_prov_sat.toString().trim()}
													required={true}
												>
													<option value="">Seleccione</option>
													<option value="0">Ninguno</option>
													<option value="2">INFILE</option>
													<option value="3">COFIDI</option>
													<option value="1">DOCUTEC</option>
												</Select>
											</FormControl>
										</div>

										{/* <div className="col-md-2 mt-3">
												<label style={{ fontSize: '0.8rem' }}>
													<strong>Código de la moneda</strong>
												</label>
												<FormControl variant="outlined" className="w-100">
													<Select
														name="codigo_moneda"
														native
														className="cs-select1 select-w100"
														inputRef={register}
														defaultValue={parameters.fe_cod_moneda.toString().trim()}
														required={true}
													>
														<option value="" id="none">
															Seleccione un código
													</option>
														<option value="LEM" id="lempira">
															Lempira
													</option>
														<option value="PES" id="PESOS">
															Pesos
													</option>
														<option value="QUE" id="QUETZAL">
															Quetzal
													</option>
														<option value="US" id="dolar">
															Dolar
													</option>
													</Select>
												</FormControl>
											</div> 

										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Llave - Firma</strong>
											</label>

											<TextField
												type="text"
												className="w-100"
												placeholder="Ingrese la Llave - Firma"
												name="llave_firma"
												inputRef={register}
												defaultValue={parameters.fe_llave_firma}
												required={true}
											/>
										</div>
										<div className="col-md-4 mt-3">
											<label style={{ fontSize: '0.8rem' }}>
												<strong>Llave – Certificación</strong>
											</label>

											<TextField
												name="llave_certificacion"
												type="text"
												className="w-100"
												placeholder="Ingrese la Llave - Certificación"
												inputRef={register}
												defaultValue={parameters.fe_llave_certificacion}
												required={true}
											/>
										</div>
									</div>
										</form>*/}
                <div
                  className="d-flex justify-content-start align-items-center"
                  style={{ height: "52px" }}
                >
                  <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                    Listado de proveedores FEL
                  </span>
                </div>
                {/* <div className="d-flex justify-content-end align-items-center flex-wrap my-2">
                  	<Button
												className="btn-cs1 mt-2 mx-1"
												variant="contained"
												color="primary"
												onClick={()=>openmodalproviderFEL()}

											>
												Agregar
										</Button>
                </div> */}
                <div id="table-css-v3">
                  <DataTable
                    className="datatable-cs"
                    title={"s0"}
                    columns={[
                      {
                        name: "Proveedor FEL",
                        selector: "name_provider",
                        sortable: true,
                      },
                      {
                        name: "Sucursal",
                        selector: "sucursal",
                        sortable: true,
                      },
                      {
                        name: "Dirección",
                        selector: "direccion",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {row.direccion?.length > 25
                              ? row.direccion?.slice(0, 25) + "..."
                              : row.direccion}
                          </span>
                        ),
                      },
                      {
                        name: "Estado",
                        selector: "estado",
                        sortable: true,
                      },

                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <FormControl variant="outlined" className="px-1">
                              <Select
                                native
                                value=""
                                onChange={(e) => resourceActionFEL(e, row)}
                                className="sm-select"
                              >
                                <option value="">Seleccionar</option>
                                {row.estado !== "ACTIVO" ? (
                                  <option value="add">Agregar</option>
                                ) : null}
                                {row.id_prov_sat === 0 ? (
                                  <option value="add">Agregar</option>
                                ) : null}
                                {row.estado === "ACTIVO" &&
                                row.id_prov_sat !== 0 ? (
                                  <option value="edit">Editar</option>
                                ) : null}
                                {row.estado === "ACTIVO" &&
                                row.id_prov_sat !== 0 ? (
                                  <option value="delete">Eliminar</option>
                                ) : null}
                              </Select>
                            </FormControl>
                          </div>
                        ),
                      },
                    ]}
                    data={dataCertificateFEL}
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="espere un momento"
                    onSelectedRowsChange={handleSelect}
                  />
                </div>
              </div>
            )}
          </TabPanel>

          <TabPanel value={configTab} index={!isGerenteRegional() ? 1 : 9999}>
            {empresaInfoLoader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <div>
                <form onSubmit={handleSubmit(update_empresa)} id="empresa_form">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      Empresa
                    </span>
                    <Button
                      variant="contained"
                      className="mr-2"
                      id="modal-button"
                      style={{ boxShadow: "none" }}
                      type="submit"
                    >
                      <span style={{ fontSize: "14px", color: "#fff" }}>
                        {" "}
                        Guardar{" "}
                      </span>
                    </Button>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Código</strong>
                      </label>

                      <TextField
                        className="w-100"
                        placeholder="Ingrese el código de su empresa"
                        name="codigo"
                        inputRef={register}
                        defaultValue={empresaInfo.codigo}
                        required={true}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Nombre de la empresa</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="Ingrese el nombre de la empresa"
                        name="nombre"
                        inputRef={register}
                        defaultValue={empresaInfo.nombre}
                        required={true}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Dirección de la empresa</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="Ingrese la dirección de la empresa"
                        required={true}
                        name="direccion"
                        inputRef={register}
                        defaultValue={empresaInfo.direccion}
                      />
                    </div>

                    {isCmi() ? (
                      <div className="col-md-4 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>Costo Entrega Domicilio</strong>
                        </label>

                        <TextField
                          type="text"
                          className="w-100"
                          placeholder="Ingrese la dirección de la empresa"
                          required={true}
                          name="valor_entrega_domicilio"
                          inputRef={register}
                          defaultValue={empresaInfo.valor_entrega_domicilio}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>
                          {currentMoneda !== "L" ? "NIT" : "CAI"} de la empresa
                        </strong>
                      </label>

                      <TextField
                        className="w-100"
                        placeholder="Ingrese el NIT de su empresa"
                        name="nit"
                        inputRef={register}
                        defaultValue={empresaInfo.nit}
                        required={true}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Teléfono de la empresa</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="(       )        -           -"
                        name="telefono"
                        inputRef={register}
                        defaultValue={empresaInfo.telefono}
                        required={true}
                      />
                    </div>
                    {currentMoneda !== "L" && (
                      <div className="col-md-2 mt-3">
                        <label style={{ fontSize: "0.8rem" }}>
                          <strong>IVA (%)</strong>
                        </label>

                        <TextField
                          type="text"
                          className="w-100"
                          placeholder="Ingrese el IVA"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          value={ivaValue}
                          onChange={(e) => {
                            setIvaValue(e.target.value);
                          }}
                          name="iva"
                          inputRef={register}
                          required={true}
                        />
                      </div>
                    )}

                    <div
                      className={
                        currentMoneda !== "L"
                          ? "col-md-2 mt-3"
                          : "col-md-4 mt-3"
                      }
                    >
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Moneda</strong>
                      </label>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          native
                          className="cs-select1 select-w100"
                          name="id_moneda_web_app"
                          inputRef={register}
                          defaultValue={empresaInfo.id_moneda_web_app}
                          onChange={handleMoneda}
                          required={true}
                        >
                          <option value="">Seleccione</option>
                          {empresaInfo.monedas.map((moneda) => (
                            <option value={moneda.id_moneda_web_app}>
                              {moneda.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Código del establecimiento</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="Ingrese el código del establecimiento"
                        name="codigo_establecimiento"
                        inputRef={register}
                        defaultValue={empresaInfo.codigo_establecimiento}
                        required={true}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Dispositivo</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="Ingrese el dispositivo"
                        name="dispositivo"
                        inputRef={register}
                        defaultValue={empresaInfo.dispositivo}
                        required={true}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Régimen de ISR</strong>
                      </label>

                      <TextField
                        type="text"
                        className="w-100"
                        placeholder="Ingrese el régimen de ISR de la empresa"
                        name="regimen_isr"
                        inputRef={register}
                        defaultValue={empresaInfo.regimen_isr}
                        required={true}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Tipo de negocio</strong>
                      </label>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          native
                          className="cs-select1 select-w100"
                          name="tipo_negocio"
                          inputRef={register}
                          defaultValue={empresaInfo.tipo_negocio}
                          required={true}
                        >
                          <option value="">
                            Seleccione el tipo de negocio
                          </option>
                          {businessTypes.map((business) => (
                            <option value={business.id_tipo_empresa_web_app}>
                              {business.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Región</strong>
                      </label>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          native
                          className="cs-select1 select-w100"
                          name="region"
                          inputRef={register}
                          required={true}
                          defaultValue={empresaInfo.region}
                        >
                          <option value="">Seleccione la región</option>
                          {regions.map((region) => (
                            <option value={region.id_region_web_app}>
                              {region.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Clase</strong>
                      </label>

                      <FormControl variant="outlined" className="w-100">
                        <Select
                          native
                          className="cs-select1 select-w100"
                          name="clase"
                          defaultValue={empresaInfo.id_new_grupo1}
                          inputRef={register}
                          required={true}
                        >
                          <option value="">Seleccione la clase</option>
                          {classes.map((clase) => (
                            <option value={clase.id_new_grupo1}>
                              {clase.descripcion}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Límite de días para conservar pedidos</strong>
                      </label>

                      <TextField
                        type="number"
                        className="w-100"
                        placeholder="Cantidad de dias para que los pedidos se depuren"
                        name="dias_mantener_pedidos"
                        inputRef={register}
                        defaultValue={empresaInfo.dias_mantener_pedidos}
                        required={false}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Direccion WS Sincronizador</strong>
                      </label>

                      <TextField
                      type="text"
                      className="w-100"
                      placeholder="No existe un WS sincronizador ERP"
                      name="direccion_ws_sincronizador"
                      inputRef={register}
                      defaultValue={empresaInfo.direccion_ws_sincronizador}
                      required={false}
                      disabled = {true}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Correo contabilidad</strong>
                      </label>

                      <TextField
                      type="text"
                      className="w-100"
                      placeholder="No existe un WS sincronizador ERP"
                      name="direccion_ws_sincronizador"
                      inputRef={register}
                      defaultValue={empresaInfo.pagos_correo_contabilidad}
                      required={false}
                      disabled = {true}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>% Comision</strong>
                      </label>

                      <TextField
                      type="text"
                      className="w-100"
                      placeholder="No existe un WS sincronizador ERP"
                      name="direccion_ws_sincronizador"
                      inputRef={register}
                      defaultValue={empresaInfo.pagos_comision_cloud+empresaInfo.pagos_comision_visanet}
                      required={false}
                      disabled = {true}
                      />
                    </div>
                  </div>
                </form>

                {/* <label>IMPUESTO IDP</label> */}
                <div className="d-flex justify-content-end align-items-center flex-wrap my-2">
                  {/*<Button
											className="btn-cs1 mt-2 mx-1"
											variant="contained"
											color="primary"
											onClick={()=>openmodaleditorsave('save')}
											
										>
											Agregar
										</Button>*/}
                  {/* <EmpresaModal
                    classes={classes}
                    update_empresa={update_empresa}
                    register={register}
                    empresaInfo={empresaInfo}
                    enviar_valor_descuento={enviar_valor_descuento}
                    getEmpresaInfo={getEmpresaInfo}
                    setEmpresaInfoLoader={setEmpresaInfoLoader}
                    handleSubmit={handleSubmit}
                    businessTypes={businessTypes}
                    regions={regions}
                  /> */}
                </div>
              </div>
            )}
          </TabPanel>

          <TabPanel
            value={configTab}
            index={isGerenteRegional() ? 1 : 2}
            className="p-0-mui"
          >
            <div className="d-flex justify-content-end align-items-center flex-wrap my-2">
              <div>
                {selectedSucursales.length > 0 ? (
                  <CSVLink
                    data={selectedSucursales}
                    filename={"Listado de sucursales.csv"}
                  >
                    <Button variant="outlined" color="primary">
                      <span style={{ fontSize: "14px" }}> Descargar</span>

                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  </CSVLink>
                ) : (
                  <Button variant="outlined" color="primary">
                    <span style={{ fontSize: "14px" }}> Descargar</span>
                    <CloudDownloadIcon className="ml-2" />
                  </Button>
                )}
                &nbsp; &nbsp;
                {/* <Button
                  variant="contained"
                  className="mr-2"
                  style={{ background: "#0160E7", boxShadow: "none" }}
                  component={Link}
                  to={"/app/config/surcursal/new"}
                >
                  <span
                    style={{ fontSize: "14px", fontWeight: 700, color: "#fff" }}
                    className="mr-2"
                  >
                    +
                  </span>

                  <span style={{ fontSize: "14px", color: "#fff" }}>
                    {" "}
                    Añadir nueva{" "}
                  </span>
                </Button> */}
                <SucursalModal getSucursals = {getSucursals}/>
              </div>
            </div>
            <div id="table-css-v3">
              <DataTable
                className="datatable-cs"
                title={"s0"}
                columns={[
                  {
                    name: "Código",
                    selector: "codigo_sucursal",
                    sortable: true,
                  },
                  {
                    name: "Nombre",
                    selector: "nombre_sucursal",
                    sortable: true,
                  },
                  {
                    name: "Dirección",
                    selector: "direccion_sucursal",
                    sortable: true,
                    cell: (row) => (
                      <span>
                        {row.direccion_sucursal?.length > 25
                          ? row.direccion_sucursal?.slice(0, 25) + "..."
                          : row.direccion_sucursal}
                      </span>
                    ),
                  },
                  {
                    name: "Teléfono",
                    selector: "telefono_sucursal",
                    sortable: true,
                  },
                  {
                    name: "Ubicación/Bodega",
                    selector: "descripcion_bodega",
                    sortable: true,
                  },
                  {
                    name: "Acciones",
                    selector: "action",
                    cell: (row) => (
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <FormControl variant="outlined" className="px-1">
                          <Select
                            native
                            value=""
                            onChange={(e) =>
                              resourceAction(
                                e,
                                row.id_sucursal_bodega_ubicacion
                              )
                            }
                            className="sm-select"
                          >
                            <option value="">Seleccionar</option>
                            <option value="edit">Editar sucursal</option>
                            <option value="cajas">Editar cajas</option>
                          </Select>
                        </FormControl>
                      </div>
                    ),
                  },
                ]}
                data={sucursals}
                selectableRows
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se encontraron sucursales"
                onSelectedRowsChange={handleSelect}
              />
            </div>
          </TabPanel>
          <TabPanel
            value={configTab}
            index={null}
            className="config-tab"
          >
            <div
              className="d-flex align-items-center flex-wrap"
              style={{ height: "52px" }}
            >
              <span className="mb-0 ml-1">Mostrar:</span>
              &nbsp;
              <FormControl variant="outlined">
                <Select
                  native
                  onChange={(event) => displayFormats(event)}
                  className="sm-select"
                >
                  <option value="">Todos</option>
                  <option value="favoritos">Solo favoritos</option>
                </Select>
              </FormControl>
            </div>

            <div>
              {invoicesLoader ? (
                <div className="text-center">
                  <CircularProgress size={26} className="mx-auto" />
                </div>
              ) : (
                <div id="table-css-v3">
                  <DataTable
                    className="datatable-cs"
                    title={"s1"}
                    columns={[
                      {
                        name: "Nombre del formato",
                        selector: "nombre",
                        sortable: true,
                        cell: (row) => (
                          <div className="w-100">
                            {row.favorita ? (
                              <StarIcon
                                className="pl-1 ml-2"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  removeInvoiceFromFavorites(row.factura_id)
                                }
                              />
                            ) : (
                              <StarBorderIcon
                                className="pl-1 ml-2"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  addInvoiceToFavorites(row.factura_id)
                                }
                              />
                            )}

                            <span className="ml-5">{row.nombre}</span>
                          </div>
                        ),
                      },
                      {
                        name: "Fecha / Hora",
                        selector: "fecha",
                        sortable: true,
                        cell: (row) => (
                          <div className="w-100 text-center">
                            {moment(row.fecha).format("DD/MM/YYYY HH:mm:ss a")}
                          </div>
                        ),
                      },

                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="text-center w-100">
                            <MaterialLink
                              component={Link}
                              to={`/app/formatos/${row.factura_id}`}
                            >
                              {" "}
                              <span style={{ color: "#0160e7" }}>
                                Ver factura
                              </span>
                              <span style={{ color: "#0160e7" }}>
                                <ArrowForwardIos
                                  className="ml-1"
                                  style={{ fontSize: "14px" }}
                                />
                              </span>
                            </MaterialLink>
                          </div>
                        ),
                      },
                    ]}
                    data={filteredInvoices}
                    pagination
                    paginationPerPage={10}
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se ha encontrado ningún resultado"
                  />
                </div>
              )}
            </div>
          </TabPanel>

          <TabPanel value={configTab} index={3} className="config-tab">
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ height: "52px" }}
            >
              <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                Listado de productos FEL
              </span>
            </div>
            <div id="table-css-v3">
              <DataTable
                className="datatable-cs"
                title={"s0"}
                columns={[
                  {
                    name: "Valor del Impuesto",
                    selector: "impuesto_cant",
                    sortable: true,
                  },
                  {
                    name: "Nombre",
                    selector: "nombre",
                    sortable: true,
                  },
                  {
                    name: "Nombre corto",
                    selector: "nombre_corto",
                    sortable: true,
                  },

                  {
                    name: "Acciones",
                    selector: "action",
                    cell: (row) => (
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <FormControl variant="outlined" className="px-1">
                          <Select
                            native
                            value=""
                            onChange={(e) => resourceActionIDP(e, row)}
                            className="sm-select"
                          >
                            <option value="">Seleccionar</option>
                            <option value="edit">Editar</option>
                            <option value="delete">Eliminar</option>
                          </Select>
                        </FormControl>
                      </div>
                    ),
                  },
                ]}
                data={IDPdata}
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="no hay datos IDP"
                onSelectedRowsChange={handleSelect}
              />
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
