import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Image, Circle } from "react-konva";
import useImage from "use-image";
import { realImageURL } from "../../../../api";
const ConfirmationImage = ({ item }) => {
  const stageWidth = 200;
  const stageHeight = 200;
  const circleWidth = 20;
  const circleHeight = 20;
  const stageRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(200);
  const [imageHeight, setImageHeight] = useState(200);
  const [image, status] = useImage(realImageURL(item.vista) || "noimage");

  const [X, setX] = useState(0);
  const [Y, setY] = useState(0);

  useEffect(() => {
    setX((stageWidth - imageWidth) * 0.5);
    setY((stageHeight - imageHeight) * 0.5);

    const imageAspectRatio = (ratio) => {
      setImageWidth(imageWidth * ratio);
      setImageHeight(imageHeight * ratio);
    };

    if (imageWidth > stageWidth) {
      imageAspectRatio(stageWidth / imageWidth);
    }

    if (imageHeight > stageHeight) {
      imageAspectRatio(stageHeight / imageHeight);
    }
  }, [imageWidth, imageHeight]);

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const colors = ["#08BFE6", "#0160E7", "#219653", "#fff200"];
  const entries = Object.entries(item)
    .filter((i) => i[0].includes("nombre_area"))
    .map((x) => x[1])
    .filter((x) => x);
  return (
    <Stage ref={stageRef} width={stageWidth} height={stageHeight}>
      <Layer>
        <Image
          image={image}
          x={X}
          y={Y}
          width={imageWidth}
          height={imageHeight}
          alt="Perspectiva Producto"
        />
      </Layer>
      <Layer>
        {entries.map((entry, index) => (
          <Circle
            key={index}
            x={+item[`coordenadas_x_personalizable_${index + 1}`]}
            y={+item[`coordenadas_y_personalizable_${index + 1}`]}
            fill={colors[index]}
            width={circleWidth}
            height={circleHeight}
          />
        ))}
        {/* {item.coordenadas_x_personalizable_1 && (
          <Circle
            x={+item.coordenadas_x_personalizable_1 / 1.5}
            y={+item.coordenadas_y_personalizable_1 / 1.5}
            fill={colors[0]}
            width={circleWidth}
            height={circleHeight}
          />
        )}
        {item.coordenadas_x_personalizable_2 && (
          <Circle
            x={+item.coordenadas_x_personalizable_2 / 1.5}
            y={+item.coordenadas_y_personalizable_2 / 1.5}
            fill={colors[1]}
            width={circleWidth}
            height={circleHeight}
          />
        )}
        {item.coordenadas_x_personalizable_3 && (
          <Circle
            x={+item.coordenadas_x_personalizable_3 / 1.5}
            y={+item.coordenadas_y_personalizable_3 / 1.5}
            fill={colors[2]}
            width={circleWidth}
            height={circleHeight}
          />
        )}
        {item.coordenadas_x_personalizable_4 && (
          <Circle
            x={+item.coordenadas_x_personalizable_4 / 1.5}
            y={+item.coordenadas_y_personalizable_4 / 1.5}
            fill={colors[3]}
            width={circleWidth}
            height={circleHeight}
          />
        )} */}
      </Layer>
    </Stage>
  );
};

export default ConfirmationImage;
