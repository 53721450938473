import React, { useContext, useEffect } from "react";
import { Circle } from "react-konva";

import { handleCoordinates } from "../AreaContainerForm/utils/handleChanges";

const Point = ({ item, variant, index, handleForms }) => {
  const circleWidth = 25;
  const circleHeight = 25;
  useEffect(() => {
    handleCoordinates({ x: item.x, y: item.y }, variant, handleForms, index);
  }, []);
  return (
    <Circle
      key={item.color}
      x={item.x}
      y={item.y}
      draggable={true}
      onDragEnd={(e) => {
        item.x = e.target._lastPos.x;
        item.y = e.target._lastPos.y;
        handleCoordinates(
          { x: item.x, y: item.y },
          variant,
          handleForms,
          index
        );
      }}
      fill={item.color}
      width={circleWidth}
      height={circleHeight}
    />
  );
};

export default Point;
