import React from "react";
import { isCmi } from "../../../helpers/isCmi";
import moment from "moment";

const FacturaHonduras = ({
  felData,
  ref,
  moneda,
  descuentos,
  convertir,
  directions,
  isPrincipalRetainer,
}) => {
  const getDescuentoActual = (codigo_entrega, descuento = true) => {
    let des = descuentos.filter((item) => item.codigo == codigo_entrega);
    // console.log("des actual", des[0].costo_descuento);

    if (des.length === 0) return 0;

    if (descuento) {
      return des[0].costo_descuento.toFixed(2);
    } else {
      return des[0].costo_adicional.toFixed(2);
    }
  };
  const sumarMontos = () => {
    let montos = felData.orderItems.map((product) => product.precio);
    let sumaMontos = montos.reduce((acc, el) => acc + el, 0);

    let descuentos =
      felData.tipo_venta == 5
        ? getDescuentoActual(felData.tipo_venta, false)
        : getDescuentoActual(felData.tipo_venta);

    return Number(sumaMontos) + Number(descuentos);
  };

  const getVuelto = () => {
    let vuelto = 0;
    let total = sumarMontos();

    let totalSinDescuento =
      sumarMontos() - felData.tipo_venta == 5
        ? getDescuentoActual(felData.tipo_venta, false)
        : getDescuentoActual(felData.tipo_venta);

    let pago = (
      felData.pago_method !== "A CREDITO"
        ? felData.efectivo +
          felData.tarjeta +
          felData.gift_card +
          felData.credito
        : 0
    ).toFixed(2);

    if (pago > total - totalSinDescuento) {
      vuelto = pago - (total - totalSinDescuento);
      return vuelto;
    }
    return vuelto;
  };

  const getTotalFactura = () => {
    let total = sumarMontos();

    let des =
      felData.tipo_venta === 5
        ? getDescuentoActual(felData.tipo_venta, false)
        : getDescuentoActual(felData.tipo_venta);

    if (felData.tipo_venta === 5) return Number(total).toFixed(2);

    if (felData.tipo_venta !== 5) {
      let totalObtenido = Number(total) - Number(des);
      return totalObtenido.toFixed(2);
    }
  };

  const getSubtotales = (payload) => {
    return felData.orderItems
      .filter((product) => product.flag_impuesto_honduras?.includes(payload))
      .reduce((a, b) => a + b.precio, 0)
      .toFixed(2);
  };
  return (
    <div className="mt-4">
      <div ref={ref} style={{ backgroundColor: "white" }}>
        <div className="row justify-content-center">
          {felData.identificador.includes("Anulado") ? (
            <div
              style={{
                marginTop: "500px",
                position: "fixed",
              }}
            >
              {/*<strong style={{ color: "red"}}>FACTURA ANULADA</strong>*/}
              <img src="/images/anulado.png" width="300" />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row justify-content-center">
          <div className="row col-12 justify-content-center">
            {isCmi() ? (
              ""
            ) : (
              <div className="col-4">
                <img
                  className="ml-5"
                  src={"/images/cloudLogo.png"}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt="factura-imagen"
                />
              </div>
            )}
            {isCmi() ? (
              <div className="col-12 ml-5 text-center">
                <img
                  className="mb-0 img_factura"
                  src={"/images/cloudLogo.png"}
                  alt="factura-imagen"
                />
              </div>
            ) : (
              ""
            )}

            <div className="col-12 text-center mx-auto">
              <p className={`mb-0 ${isCmi() ? "" : "ml-5"}`}>
                <strong>
                  Corporación Americana de Muestra de Facturación S.A. de C.V.
                  CORPOAMEN
                </strong>
              </p>

              <p className="mb-0 ml-5">
                {felData.empresa.direccion} - Honduras
              </p>

              {isCmi() ? (
                ""
              ) : (
                <p className={`mb-0 ${isCmi() ? "" : "ml-5"}`}>
                  {felData.empresa.telefono}
                </p>
              )}
              {isCmi() ? (
                <>
                  {felData.service_certificador ? (
                    <label className="mb-0 text-center">
                      NÚMERO DE AUTORIZACIÓN
                    </label>
                  ) : null}
                  {felData.service_id ? (
                    <p className="mb-0 text-center">{felData.service_id}</p>
                  ) : null}
                  <p className="mb-0 text-center">
                    R.T.N. {felData.empresa.nit}
                  </p>
                  <p className="mb-0 text-center">
                    {felData.empresa.cuenta_correo}
                  </p>
                  <p className="mb-0 text-center"> {felData.empresa.nombre}</p>
                  <p className="mb-0 text-center">{felData.empresa.telefono}</p>
                  <p className="text-center">www.comercial.hn</p>
                  <p className="mb-0">
                    CAI # <br />
                    {felData.empresa.nit}
                  </p>
                  <br />
                  <p className="mb-0">Original: Cliente</p>
                  <p className="p-0 m-0">Copia: Obligado tributario emisor</p>
                  <br />
                  FACTURA ORIGINAL
                  {felData.seriefel ? (
                    <p className="mb-0">
                      <strong>Serie FEL:</strong> {felData.seriefel}
                    </p>
                  ) : null}
                  {felData.numerofel ? (
                    <p className="mb-0">
                      <strong>Numero FEL:</strong> {felData.numerofel}
                    </p>
                  ) : null}
                  <hr style={{ borderTop: "3px dashed #bbb" }} />
                  <p className="mb-0 text-left">
                    Factura: XXXX-01-{felData.numeracion_correlativo}
                  </p>
                  <p className="mb-0 text-left">
                    FECHA:{" "}
                    {moment(felData.fecha).utcOffset(0).format("DD/MM/YYYY")}
                    &nbsp;HORA: &nbsp;
                    {moment(felData.fecha).utcOffset(0).format("h:mm:ss a")}
                  </p>
                  <p className="mb-0 text-left">
                    R.T.N.
                    {felData.client.nit}
                  </p>
                  <p className="mb-0 text-left">
                    <strong>Cajero:</strong>
                    {felData.vendedor.nombre}
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <hr style={{ borderTop: "3px dashed #bbb" }} />
          <div className="col-12">
            {isCmi() ? (
              ""
            ) : (
              <>
                <p className="text-center m-0 p-0">
                  <strong>DOCUMENTO TRIBUTARIO ELECTRONICO</strong>
                </p>
                <p className="text-center m-0 p-0">
                  <strong>*** Factura Electrónica ***</strong>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="mx-auto">
            {/*<p className="mb-0">
                      <strong>Factura:</strong> {felData.serie}
                </p>*/}
            {isCmi() ? (
              ""
            ) : (
              <>
                {felData.seriefel ? (
                  <p className="mb-0">
                    <strong>Serie FEL:</strong> {felData.seriefel}
                  </p>
                ) : null}
                {felData.numerofel ? (
                  <p className="mb-0">
                    <strong>Numero FEL:</strong> {felData.numerofel}
                  </p>
                ) : null}
              </>
            )}
          </div>
        </div>
        {isCmi() ? (
          ""
        ) : (
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "5px" }}
          >
            <div className="ml-0">
              <p className="mb-0">NOMBRE: {felData.client.nombre}</p>
            </div>
          </div>
        )}
        {isCmi() ? (
          ""
        ) : (
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "10px" }}
          >
            <div className="ml-0">
              <p className="mb-0">NIT : {felData.client.nit}</p>
              <p className="mb-4">DIR : {felData.client.direccion}</p>
            </div>
          </div>
        )}
        <hr style={{ borderTop: "3px dashed #bbb" }} />
        <div className="col-12 text-center">
          <p>Cantidad * Precio &nbsp; &nbsp; / &nbsp;Descripcion</p>
        </div>
        <br />

        {felData.orderItems.map((product) => (
          <>
            <div className="row m-0 p-0">
              <div className="col-6 d-flex flex-wrap m-0 p-0">
                <span className="w-100">
                  Código Barra.....: {product.codigo}
                </span>

                <span className="w-100">
                  {product.cantidad.toFixed(3)} X L{product.precio.toFixed(3)}
                </span>
                <span className="w-100">{product.nombre}</span>
              </div>
              <div className="col-6 m-0 p-0">
                <p className="text-left m-0">
                  ({product.valor_impuesto_honduras}%)
                </p>
                <p className="text-right m-0">
                  L{(product.precio * product.cantidad).toFixed(2)}{" "}
                  {product?.flag_impuesto_honduras?.substr(0, 1)}
                </p>
              </div>
            </div>
          </>
        ))}
        {isCmi() ? (
          <>
            <div className="col-12 p-0 m-0">
              Costo por entrega {moneda}
              {felData.tipo_venta == 5
                ? getDescuentoActual(felData.tipo_venta, false)
                : getDescuentoActual(felData.tipo_venta)}
            </div>
          </>
        ) : (
          ""
        )}

        <hr style={{ borderTop: "3px dashed #bbb" }} />
        <div className="col-12 p-0">
          <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span>Subtotal exento</span>
            <span className="text-right">L{getSubtotales("Exento")}</span>
          </div>
          <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span>Subtotal exonerado</span>
            <span>L{getSubtotales("Exonerado")}</span>
          </div>
          <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span> Subtotal tasa (15%)</span>
            <span>L{getSubtotales("15")}</span>
          </div>

          <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span> Subtotal tasa (18%)</span>
            <span>L{getSubtotales("18")}</span>
          </div>
          <br />

          <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span>
              {felData.tipo_venta === 5 ? "Costo por entrega" : "Descuento"}
            </span>
            <span>
              {" "}
              {moneda}.
              {felData.tipo_venta === 5
                ? getDescuentoActual(felData.tipo_venta, false)
                : getDescuentoActual(felData.tipo_venta)}
            </span>
          </div>
          {/* <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span>Rebaja</span>
            <span>LX.XX</span>
          </div> */}
          <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span>Tasa 0%</span>
            <span>L0,00</span>
          </div>
          <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span>Impuesto 15%</span>
            <span>L{(getSubtotales("15") * 0.15).toFixed(2)}</span>
          </div>
          <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span>Impuesto 18%</span>
            <span>L{(getSubtotales("18") * 0.18).toFixed(2)}</span>
          </div>
          <div className="col-12 d-flex justify-content-between align-items-center p-0">
            <span>Total</span>
            <span>
              {" "}
              {isCmi()
                ? `${getTotalFactura()}`
                : felData.monto_total.toFixed(2)}
            </span>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between align-items-center p-0">
          <span>Pagado</span>
          <span>
            {" "}
            {(felData.pago_method !== "A CREDITO"
              ? felData.efectivo +
                felData.tarjeta +
                felData.gift_card +
                felData.credito
              : felData.id_forma_pago === 6
              ? felData.credito
              : 0
            ).toFixed(2)}
          </span>
        </div>
        <div className="col-12 d-flex justify-content-between align-items-center p-0">
          <span>Cambio</span>
          <span>
            {" "}
            {moneda}.{getVuelto().toFixed(2)}
          </span>
        </div>
        <br />
        <div className="col-12 p-0">
          {felData.monto_total.toFixed(2).toString() === "1.00" &&
            "UN LEMPIRA 00/100"}
          {felData.monto_total.toFixed(2).toString() !== "1.00" && (
            <>
              {convertir
                .NumerosALetras(
                  isCmi() ? getTotalFactura() : felData.monto_total
                )
                .replace("Pesos", "LEMPIRAS")
                .replace("M.N.", "")
                .toLocaleUpperCase()}
            </>
          )}
        </div>

        <br />
        <div className="col-12 d-flex justify-content-center">
          <span>(G)= Gravado &nbsp; &nbsp; (E)= Exento</span>
        </div>
        <div className="col-12 d-flex p-0 m-0">
          <span>Número correlativo de la orden de compra exenta</span>
        </div>
        <div className="col-12 d-flex p-0 m-0">
          <span>
            Número de identificacion de registro de la secretaria del estato en
            el despacho de agricultura
          </span>
        </div>
        <br />
        <div className="col-12 p-0 m-0">
          <p className="text-center"> Rango Desde : XXXX-XXX-01-XXXXXXXXX</p>
        </div>
        <div className="col-12 p-0 m-0">
          <p className="text-center"> Rango Hasta : XXXX-XXX-01-XXXXXXXXX</p>
        </div>
        <div className="col-12 p-0 m-0">
          <p className="text-center"> Fecha limite de emisión: DD/MM/AA</p>
        </div>
        <div className="col-12 p-0 m-0">
          <p className="text-center"> COMPAÑIA DE PRUEBA</p>
        </div>
        <div className="col-12 p-0 m-0">
          <p className="text-center"> Slogan de la compañía</p>
        </div>
        <div className="col-12 p-0 m-0">
          <p className="text-center">
            LA FACTURA ES BENEFICIO DE TODOS EXIJALA
          </p>
        </div>

        {/* <div className="row">
          <div className="col-12">
            {!isPrincipalRetainer ? (
              <p className="text-center">
                <strong>NO GENERA DERECHO A CRÉDITO FISCAL</strong>
              </p>
            ) : null}
            {isCmi() ? (
              ""
            ) : (
              <>
                {felData.service_certificador ? (
                  <p className="text-center">
                    <strong>NÚMERO DE AUTORIZACIÓN</strong>
                  </p>
                ) : null}
                {felData.service_id ? (
                  <p className="text-center">
                    <strong>{felData.service_id}</strong>
                  </p>
                ) : null}
              </>
            )}
            <br />
            {felData.service_id ? (
              <p className="text-center">
                <strong>Certificador</strong> {felData.service_certificador}{" "}
                <strong>Nit</strong> {felData.service_nit}
              </p>
            ) : null}
            <p className="text-center" style={{ marginTop: "10px" }}>
              <strong>Powered by</strong>
            </p>
            <p className="text-center">
              <strong style={{ color: "blue" }}>www.cloud.com.gt</strong>
            </p>
            {isCmi() ? (
              ""
            ) : (
              <p className="text-center">
                <strong>PBX: 2503 - 3333</strong>
              </p>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FacturaHonduras;
