import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// Validaciones
import { isCmi } from "../../helpers/isCmi";
import { getMoneda } from "../../helpers/moneda";
import { Height } from "@material-ui/icons";

// components

export default function Users_all(props) {
  const moneda = getMoneda();
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  useEffect(() => {
    getSucursals();
    getPriceLevels();
  }, []);

  // states
  const { register, handleSubmit, setValue } = useForm();
  const [sucursal, setSucursal] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [priceLevels, setPriceLevels] = useState([]);
  // para cmi
  const [nivelPrecio, setNivelPrecio] = useState([]);
  const [add_credito_cmi, setAdd_credito_cmi] = useState(false);

  //priceFormater
  const [priceValue, setPriceValue] = useState("");
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    getNivelesCliente();
  }, []);

  // set states
  const changeSucursal = (event) => {
    const sucursalName = event.target.value;
    setSucursal(sucursalName);
  };

  const save_user = (data) => {
    data.limit = parseFloat(data.limit.replace(",", ""));
    data.dias_credito = parseInt(data.dias_credito);
    data.nivel_precio = parseInt(data.nivel_precio);

    data.client_code = data.nit;
    data.limite_credito = data.id_nivel_precio = parseInt(data.nivel_precio);
    data.diferenciacion = "";
    data.telefono = null;

    console.log("data to send", data);

    //   {
    //     "nit":"987897646k",
    //     "nombre":"Jose",
    //     "direccion":"av. geranios",
    //     "email":"bacventas1@hotmail.com",
    //     "limite_credito":1000,
    //     "id_nivel_precio":1,
    //     "dias_credito":0,
    //     "diferenciacion":"restaurante",
    //     "client_code":"987897646k",
    //     "telefono":null
    // }

    setSaveLoader(true);
    API.clients
      .add_new(data)
      .then((res) => {
        let response = res.data;

        console.log("creacion cliente cloud ->", res);

        if (res.status === 200) {
          $("#save").modal();
          setSaveLoader(false);
        }

        // if (response.success) {
        //   $('#save').modal()
        //   setSaveLoader(false)
        // } else {
        //   toast.error(response.message, { autoClose: 10000 })
        //   setSaveLoader(false)
        // }
      })
      .catch((err) => {
        setSaveLoader(false);
        console.log("err ->", err.response);

        if (err?.response?.data?.errors[0]?.msg) {
          toast.error(err.response.data.errors[0].msg, { autoClose: 10000 });
        }
      });
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success && JSON.stringify(response.data) != "[]") {
          setSucursals(response.data);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getPriceLevels = () => {
    API.priceLevels
      .getAll()
      .then((res) => {
        let response = res.data;
        setPriceLevels(response.data);
        if (!response.success) {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error(
              "Ha ocurrido un error al cargar los niveles de precio del sistema",
              {
                autoClose: 10000,
              }
            );
          }
        }
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al cargar los niveles de precio del sistema",
          { autoClose: 10000 }
        );
      });
  };

  const getNivelesCliente = async () => {
    let id_new_empresa = window.localStorage.getItem("id_empresa");

    try {
      const response = await API.userConfig.getNiveles(id_new_empresa);
      console.log("Niveles", response.data.costumer);
      setNivelPrecio(response.data.costumer);
    } catch (error) {
      console.log("Error al buscar niveles", error);
      toast.error("Error al buscar niveles", { autoClose: 10000 });
    }
  };

  const getClients = (value = "") => {
    return new Promise((resolve, reject) => {
      API.clients
        .getAll({
          searchGeneral: value,
          pagination: true,
          page: 1,
          limit: 10,
          validaNIT: 1,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject("Ha ocurrido un error al validar el NIT, intente más tarde");
        });
    });
  };


  const onBlurNIT = async (event) => {

    if(event.target.value) {
      const nit = event.target.value.replace('-','').replace('/','');
      const data = await getClients(nit);
      const nitValidado = data?.data?.clients?.length > 0 ? data.data.clients[0] : null;

      if(nitValidado) {
        if(nitValidado.id_cliente_web_app) {
          toast.error("El NIT ya se encuentra registrado.");
          setValue('nit', '');
          setValue('nombre', '');
          return;
        }
        setValue('nit', nitValidado.nit);
        setValue('nombre', nitValidado.nombre);
      }
      else {
        setValue('nit', '');
        setValue('nombre', '');
        toast.error(data?.message || data);
      }
    }    
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardado.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => props.history.goBack()}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="save" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Un nuevo cliente ha <br />
                    sido creado
                  </strong>
                </h4>
              </div>

              <div class="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={"/app/clients"}
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Clientes</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Registrar nuevo cliente</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined">
          <Select native value={sucursal} onChange={changeSucursal}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <form
        className="content-card card mt-3"
        onSubmit={handleSubmit(save_user)}
      >
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Registrar nuevo cliente</strong>
              </h5>
              <p className="mb-0">Creación de un nuevo cliente</p>
            </div>

            <div>
              <Button
                type="submit"
                className="btn-cs1_blue mx-1 mt-1"
                variant="contained"
                color="primary"
              >
                {saveLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Guardar"
                )}
              </Button>
              <Button
                className="btn-cs1 mx-1 mt-1 delete-btn"
                variant="contained"
                color="primary"
                data-toggle="modal"
                data-target="#confirmExit"
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            {isCmi() ? (
              ""
            ) : (
              <div className="col-md-4 mt-3">
                <div className="d-flex mb-0">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>NIT del cliente</strong>
                  </label>
                  <Tooltip title="El NIT no debe llevar guión y el caracter alfabético debe estar en mayuscula. Ej. 123456789K">
                    <InfoIcon
                      style={{
                        color: " #828282",
                        fontSize: "1.2rem",
                      }}
                    />
                  </Tooltip>
                </div>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese el NIT del cliente"
                  inputRef={register}
                  name="nit"
                  required={true}
                  onBlur={onBlurNIT}
                />
              </div>
            )}

            <div className={`${isCmi() ? "col-md-3 mt-3" : "col-md-4 mt-3"}`}>
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Nombre del cliente</strong>
              </label>

              <TextField
                name="nombre"
                type="text"
                className="w-100"
                placeholder="Ingrese el nombre del cliente"
                inputRef={register}
                required={true}
              />
            </div>

            {isCmi() ? (
              <>
                <div className="col-md-3 mt-3">
                  <div className="d-flex mb-0">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>
                        {moneda == "L" ? "RTN" : "NIT"} del cliente
                      </strong>
                    </label>
                    <Tooltip title="El NIT no debe llevar guión y el caracter alfabético debe estar en mayuscula. Ej. 123456789K">
                      <InfoIcon
                        style={{
                          color: " #828282",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el NIT del cliente"
                    inputRef={register}
                    name={moneda == "L" ? "rtn" : "nit"}
                    required={isCmi() ? true : false}
                    onBlur={onBlurNIT}
                  />
                </div>

                <div className="col-md-3 mt-3">
                  <div className="w-100">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Tipo de cliente</strong>
                    </label>
                  </div>

                  <Select
                    native
                    inputRef={register}
                    name="tipo_cliente"
                    style={{ width: "100%" }}
                    variant="outlined"
                    required={isCmi() ? true : false}
                  >
                    <option value="">Seleccione</option>
                    <option value="restaurante">Restaurante</option>
                    <option value="cafeteria">Cafetería</option>
                    <option value="individual">Individual</option>
                  </Select>
                </div>

                <div className="col-md-3 mt-3">
                  <div className="w-100">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Nivel de cliente</strong>
                    </label>
                  </div>

                  <Select
                    native
                    inputRef={register}
                    name="nivel_precio"
                    style={{
                      width: "100%",
                    }}
                    variant="outlined"
                    required={isCmi() ? true : false}
                  >
                    <option value="">Seleccione</option>
                    {nivelPrecio.map((level) => (
                      <option value={level.nivel}>{level.nivel}</option>
                    ))}
                  </Select>
                </div>

                <div className="col-md-6 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Nombre del cliente final</strong>
                  </label>

                  <TextField
                    name="nombre_final_cliente"
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el nombre del cliente"
                    inputRef={register}
                    required={isCmi() ? true : false}
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Código del cliente final</strong>
                  </label>

                  <TextField
                    name="codigo_final_cliente"
                    type="text"
                    className="w-100"
                    placeholder="Ej. nombredelcliente@gmail.com"
                    inputRef={register}
                    required={isCmi() ? true : false}
                  />
                </div>
              </>
            ) : (
              ""
            )}

            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Dirección fiscal (opcional)</strong>
              </label>

              <TextField
                type="text"
                className="w-100"
                placeholder="Ingrese la dirección fiscal del cliente"
                inputRef={register}
                name="direccion"
              />
            </div>

            {/* Second Row */}
            <div className="col-md-4 mt-3">
              <label style={{ fontSize: "0.8rem" }}>
                <strong>Correo electrónico (opcional)</strong>
              </label>

              <TextField
                type="email"
                className="w-100"
                placeholder="Ingrese el correo electrónico del cliente"
                inputRef={register}
                name="email"
              />
            </div>

            {isCmi() ? (
              <>
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Teléfono</strong>
                  </label>

                  <TextField
                    type="number"
                    className="w-100"
                    placeholder="Ingrese número de teléfono"
                    inputRef={register}
                    name="numero_telefono"
                  />
                </div>
              </>
            ) : (
              ""
            )}

            {isCmi() ? (
              /* Nueva asignacion de credito para clientes cmi */
              <div className="col-md-12 mt-3 d-flex justify-content-between align-items-center">
                <div className="col-md-4 row">
                  <div className="w-75">
                    <label>
                      <strong>Crédito</strong>
                    </label>
                    <p>¿Desea otorgar crédito a este usuario?</p>
                  </div>
                  <div className="w-25 d-flex align-items-center">
                    <label
                      class="toggle-control"
                      style={{ left: "-15px", height: "40px" }}
                    >
                      <input
                        type="checkbox"
                        ref={register}
                        name="add_credito"
                        defaultChecked={add_credito_cmi}
                        onChange={(e) => setAdd_credito_cmi(!add_credito_cmi)}
                      />
                      <span class="control" />
                    </label>
                  </div>
                </div>

                <div className="col-md-4 h-100">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Límite de crédito</strong>
                  </label>

                  <TextField
                    value={priceValue}
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el límite de crédito del cliente"
                    disabled={!add_credito_cmi}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      if (
                        event.target.value != 0 ||
                        event.target.value === ""
                      ) {
                        setPriceValue(
                          formaterPriceFunction(event.target.value)
                        );
                      }
                    }}
                    name="limit"
                    inputRef={register}
                    required={isCmi() ? true : false}
                  />
                </div>

                <div className="col-md-4 h-100">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Días de crédito</strong>
                  </label>
                  <Select
                    native
                    inputRef={register}
                    required={isCmi() ? true : false}
                    disabled={!add_credito_cmi}
                    name="dias_credito"
                    style={{ width: "100%" }}
                    variant="outlined"
                  >
                    <option value="">Seleccione un periodo</option>
                    <option value="0">0 días</option>
                    <option value="15">15 días</option>
                    <option value="30">30 días</option>
                    <option value="60">60 días</option>
                    <option value="90">90 días</option>
                  </Select>
                </div>
              </div>
            ) : (
              ""
            )}

            {!isCmi() && (
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Límite de crédito</strong>
                </label>

                <TextField
                  value={priceValue}
                  type="text"
                  className="w-100"
                  placeholder="Ingrese el límite de crédito del cliente"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {moneda}.
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    if (event.target.value != 0 || event.target.value === "") {
                      setPriceValue(formaterPriceFunction(event.target.value));
                    }
                  }}
                  name="limit"
                  inputRef={register}
                  required={true}
                />
              </div>
            )}

            {isCmi() ? (
              ""
            ) : (
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Días de crédito</strong>
                </label>
                <Select
                  native
                  inputRef={register}
                  required={true}
                  name="dias_credito"
                  style={{ width: "100%" }}
                  variant="outlined"
                >
                  <option value="">Seleccione un periodo</option>
                  <option value="0">0 días</option>
                  <option value="15">15 días</option>
                  <option value="30">30 días</option>
                  <option value="60">60 días</option>
                  <option value="90">90 días</option>
                </Select>
              </div>
            )}

            {isCmi()
              ? ""
              : /* <div className="col-md-4 mt-3">
                <label style={{ fontSize: '0.8rem' }}>
                  <strong>Días de crédito</strong>
                </label>

                <TextField
                  type="number"
                  placeholder="Días de crédito"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">N°</InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  name="dias_credito"
                  inputRef={register}
                  style={{ width: '100%' }}
                  required={true}
                />
              </div> */
                ""}
          </div>
        </div>
      </form>
    </div>
  );
}
