import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import IconButton from "@material-ui/core/IconButton";
import { toast } from "react-toastify";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

// components

//data

export default function Users_all(props) {
  let dataSucursal;

  if (props.history.location.state) {
    dataSucursal = props.history.location.state;
  }

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {};
  }, []);

  // states
  const [caja_list, setCaja_list] = useState([
    "Caja Principal",
    "Caja alterna 2",
  ]);
  const { register, handleSubmit } = useForm();
  const [saveLoader, setSaveLoader] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [sucursalLoader, setSucursalLoader] = useState(true);
  const [sucursal, setSucursal] = useState({
    codigo_sucursal: "011202",
    nombre_sucursal: "Floristería Don Luis",
    direccion_sucursal: "Calle 3 con calle 2, barrio El Dorado",
    telefono_sucursal: "(212) 4124-213",
    id_sucursal_bodega_ubicacion: "123",
    establecimiento_codigo: "012022013",
  });

  // set states

  const save = (data) => {
    data.fecha_promocion = dateRange;
    let filledForm = true;
    console.log(data);
    setSaveLoader(true);

    for (var key in data) {
      if (data[key] == "") {
        filledForm = false;
      }
    }

    if (!filledForm) {
      toast.error("Falta campos por rellenar", { autoClose: 3000 });
      setSaveLoader(false);
    } else {
      //api call
      setSaveLoader(false);
    }
  };
  const add_caja = () => {
    let caja_name = $("#caja-input").val();

    if (caja_name !== "") {
      setCaja_list(caja_list.concat([caja_name]));
      $("#caja-input").val("");
    }
  };
  const remove_caja = (i) => {
    let currentCaja = caja_list.filter((caja, index) => index === i);
    currentCaja = currentCaja[0];
    setCaja_list(caja_list.filter((caja, index) => index !== i));
  };

  //function

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <h4 className="mb-0">
          <strong>Detalle del cliente</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Detalles de sucursal</strong>
        </h4>
      </div>

      {/* write content here "whithout {}"*/}
      <form className="content-card card mt-3" onSubmit={handleSubmit(save)}>
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Sucursal: Coorporación Los olivos</strong>
              </h5>
              <p className="mb-0">
                Consulte y gestione la información de la sucursal
              </p>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-1 mt-1 delete-btn"
                variant="contained"
                color="primary"
                onClick={() => props.history.goBack()}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1 mx-1 mt-1"
                variant="contained"
                color="primary"
              >
                {saveLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Guardar"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(save)} id="sucursal_form">
            <div className="row mb-4">
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Código</strong>
                </label>

                <TextField
                  className="w-100"
                  placeholder="Ingrese el código de su sucursal"
                  inputRef={register}
                  name="codigo"
                  required={true}
                  defaultValue={sucursal.codigo_sucursal}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Nombre de la sucursal</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese el nombre de la sucursal"
                  inputRef={register}
                  name="nombre"
                  required={true}
                  defaultValue={sucursal.nombre_sucursal}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Dirección de la sucursal</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese la dirección de la sucursal"
                  inputRef={register}
                  name="direccion"
                  required={true}
                  defaultValue={sucursal.direccion_sucursal}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              {/* Second Row */}
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Teléfono de la sucursal</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="(          )            -              -"
                  inputRef={register}
                  name="telefono"
                  required={true}
                  defaultValue={sucursal.telefono_sucursal}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Código de establecimiento</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese el código del establecimiento"
                  inputRef={register}
                  name="establecimiento_codigo"
                  required={true}
                  defaultValue={sucursal.establecimiento_codigo}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Ubicación o bodega</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese la ubicación o bodega"
                  inputRef={register}
                  name="bodega_ubicacion"
                  required={true}
                  value={sucursal.id_sucursal_bodega_ubicacion}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              {/* Third Row */}
              <div className="col-md-4 mt-3" />
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Creación de caja</strong>
                  </label>
                </div>
                <p>Puedes crear cajas para luego asignarla a sus vendedores</p>
                <div className="d-flex justify-content-end align-items-end">
                  <TextField
                    className="w-100"
                    placeholder="Nombre de caja"
                    id="caja-input"
                    inputRef={register}
                    required={true}
                  />
                  <IconButton
                    className="mx-1 mt-1 icon-btn-primary"
                    onClick={() => add_caja()}
                    size="small"
                  >
                    <AddIcon style={{ color: "white" }} />
                  </IconButton>
                </div>
              </div>

              <div className="col-md-4 mt-3">
                {caja_list.map((caja, index) => (
                  <div>
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Nombre de caja</strong>
                    </label>

                    <div
                      className="d-flex align-items-center pl-2 mb-2"
                      style={{
                        boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      <span
                        style={{
                          flex: "1",
                          color: "black",
                        }}
                      >
                        {caja}
                      </span>
                      <IconButton
                        className="btn-cs1 icon-btn-delete"
                        variant="contained"
                        color="primary"
                        onClick={() => remove_caja(index)}
                        size="small"
                      >
                        <DeleteIcon
                          style={{
                            color: "white",
                          }}
                        />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>
      </form>
    </div>
  );
}
