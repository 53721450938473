import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  LocalDrinkSharp,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import EqualizerIcon from "@material-ui/icons/Equalizer";

// styles
import useStyles from "./styles";
import "./custom.css";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { isAleko } from "../../helpers/isAleko";
import { isCmi } from "../../helpers/isCmi";
import { isCajero } from "../../helpers/isCajero";
import { isAdmin } from "../../helpers/isAdmin";
import { useLocation } from "react-router-dom";
let structure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <img src="/images/dashboard-icon.png" alt="icon" />,
  },
  {
    id: 1,
    label: "Historial de operaciones",
    link: "/app/operaciones_historial",
    icon: <img src="/images/historial-icon.png" alt="icon" />,
  },
  {
    id: 2,
    label: "Caja - Vender",
    link: "/app/vender",
    icon: <img src="/images/caja-icon.png" alt="icon" />,
  },
  {
    id: 3,
    label: "Pedidos",
    link: "/app/pre-vender",
    icon: <img src="/images/caja-icon.png" alt="icon" />,
  },
  {
    id: 4,
    label: "Cortes de caja",
    link: "/app/cortes_caja/historial",
    icon: <img src="/images/cortes-icon.png" alt="icon" />,
    children: [
      {
        label: "Realizar corte de caja",
        link: "/app/cortes_caja/realizar_corte",
      },

      {
        label: "Historial de cortes de caja",
        link: "/app/cortes_caja/historial",
      },
    ],
  },
  {
    id: 5,
    label: "Inventario",
    link: "/app/inventario",
    icon: <img src="/images/inventory-icon.png" alt="icon" />,
  },
  {
    id: 6,
    label: "Clientes",
    link: "/app/clients",
    icon: <img src="/images/clients-icon.png" alt="icon" />,
  },
  {
    id: 7,
    label: "Traslados",
    link: "/app/traslados",
    icon: <img src="/images/traslado-icon.png" alt="icon" />,
  },
  {
    id: 8,
    label: "Usuarios",
    link: "/app/users",
    icon: <img src="/images/users-icon.png" alt="icon" />,
  },
  // {
  //   id: 9,
  //   label: "Informes",
  //   link: "/app/informes",
  //   icon: <EqualizerIcon />,
  // },
  {
    id: 10,
    label: "Configuración",
    link: "/app/config",
    icon: <img src="/images/config-icon.png" alt="icon" />,
  },
  {
    id: 11,
    label: "Soporte",
    link: "https://meet.google.com/tdm-rder-zzt",
    icon: <img src="/images/help-icon.png" alt="icon" />,
  },

];

function Sidebar(props) {
  var classes = useStyles();
  var theme = useTheme();
  const location = useLocation();
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  const localSap = () => {
    if (isCmi()) {
      structure[4].children = [
        {
          label: "Realizar corte de caja",
          link: "/app/cortes_caja/realizar_corte",
        },
        { label: "Descargas a SAP", link: "/app/descargas_sap" },
        {
          label: "Historial de cortes de caja",
          link: "/app/cortes_caja/historial",
        },
      ];
    } else {
      structure[4].children = [
        {
          label: "Realizar corte de caja",
          link: "/app/cortes_caja/realizar_corte",
        },

        {
          label: "Historial de cortes de caja",
          link: "/app/cortes_caja/historial",
        },
      ];
    }
    if (isCmi() && isAdmin()) {
      structure[5].children = [
        {
          label: "Inventario",
          link: "/app/inventario",
        },
        {
          label: "Descargas de inventario",
          link: "/app/inventario/descargas-inventario",
        },
      ];
    } else {
      structure[5].children = null;
    }
  };
  useEffect(() => {
    localSap();
  }, []);
  useEffect(() => {
    localSap();
  }, [location]);

  useEffect(() => {
    localSap();
  }, []);
  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const checkUserPermission = (element) => {
    if (element.label === "Usuarios" && props.permissions.usuarios) return true;

    if (element.label === "Configuración" && props.permissions.configuracion)
      return true;
    if (element.label === "Traslados" && props.permissions.traslados)
      return true;
    if (element.label === "Clientes" && props.permissions.clientes) return true;
    if (element.label === "Inventario" && props.permissions.inventario)
      return true;
    if (element.label === "Cortes de caja" && props.permissions.pos)
      return true;
    if (element.label === "Historial de operaciones" && props.permissions.pos)
      return true;
    if (element.label === "Dashboard" && props.permissions.dashboard)
      return true;
    if (element.label === "Caja - Vender" && props.permissions.pos) return true;
    if (element.label === "Informes") return isAleko() && !isCajero();
    if (element.label === "Pedidos" && props.permissions.pos) return true;
    if (element.label === "Soporte") return true;
    return false;
  };

  return (
    <Drawer
      id="sidebar"
      // class="style_bar"
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) =>
          checkUserPermission(link) ? (
            <SidebarLink
              key={link.id}
              location={props.history.location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ) : (
            ""
          )
        )}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
