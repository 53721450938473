import React, { useState } from "react";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

// componentes
import SubNivel_clientes from "./SubNivel_clientes";
import NewSubnivel from "./NewSubnivel";

// styles
import "../custom.css";

const Nivel_clientes = (props) => {
  const [crearNuevoSubNivel, setCrearNuevoSubNivel] = useState(false);

  const {
    id_nivel,
    name,
    condicionante,
    frecuencia,
    predeterminado,
    subniveles,

    handleEliminarNivel,

    nuevoSubNivel,
    handleChangeNewSubNivel,
    handleClickNewSubNivel,
    handleEliminarSubNivel,
    handleUpdateSubNivel,
  } = props;

  const handleNewSubNivel = async (identificador) => {
    if (!crearNuevoSubNivel) {
      setCrearNuevoSubNivel(true);
    } else {
      handleClickNewSubNivel(identificador);
      setCrearNuevoSubNivel(false);
    }
  };

  console.log(subniveles);

  return (
    <div className="col-12 mx-auto mt-2" style={{ paddingBottom: "20px" }}>
      <div className="d-flex justify-content-between align-items-center nivel">
        <div className="col-sm-1">
          <div className="delete_nivel">
            <DeleteOutlineOutlinedIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={(e) => handleEliminarNivel(id_nivel)}
            />
          </div>
        </div>
        <div className="col-3">
          <p className="mb-0 nivel_titles">
            <strong>Nombre del nivel</strong>
          </p>
          <p className="mb-0 nivel_titles">{name}</p>
        </div>
        <div className="col-3 d-flex justify-content-between align-items-center">
          <div className="w-100 mx-auto row justify-content-between align-items-center">
            <p className="mb-0 nivel_titles">
              <strong>Condicionante de permanencia</strong>
            </p>
            <ErrorIcon
              color="action"
              style={{
                width: "12px",
                height: "12px",
              }}
            />
          </div>
          <p>{condicionante}</p>
        </div>
        <div className="col-2">
          <div className="w-100 mx-auto row justify-content-between align-items-center">
            <p className="mb-0 nivel_titles">
              <strong>Frecuencia (días)</strong>
            </p>
          </div>
          <div className="w-100 mx-auto row justify-content-between align-items-center">
            <p className="mb-0 nivel_titles">{frecuencia}</p>
          </div>
        </div>
        <div class="col-3 contiene_radio_default">
          <div className="w-100 d-flex align-items-center justify-content-center">
            <label class="mt-2" for="nivel_default">
              <strong>
                {predeterminado ? "Predeterminado" : "No predeterminado"}
              </strong>
            </label>
          </div>
        </div>
      </div>

      {subniveles.length > 0 &&
        subniveles.map((item) => (
          <SubNivel_clientes
            key={item.id}
            item={item}
            id_nivel={id_nivel}
            id_sub_nivel={item.id}
            id_tipo_cliente={item.id_tipo_cliente}
            handleEliminarSubNivel={handleEliminarSubNivel}
            name={item.subnivel}
            cantidad_desde={item.cantidad_desde}
            cantidad_hasta={item.cantidad_hasta}
            handleUpdateSubNivel={handleUpdateSubNivel}
          />
        ))}

      {/* Subniveles */}
      {crearNuevoSubNivel && (
        <NewSubnivel
          id_nivel={id_nivel}
          setCrearNuevoSubNivel={setCrearNuevoSubNivel}
          handleChangeNewSubNivel={handleChangeNewSubNivel}
          nuevoSubNivel={nuevoSubNivel}
          handleClickNewSubNivel={handleClickNewSubNivel}
        />
      )}

      {/* boton para crear sub nivel */}

      {subniveles.length < 3 && (
        <div className="col-12 mx-auto mt-3" style={{ height: "75px" }}>
          <div className="row col-md-9 justify-content-center align-items-center h-75 border_punteado">
            <Button
              color="primary"
              startIcon={<AddIcon color="primary" />}
              onClick={(e) => handleNewSubNivel(id_nivel)}
            >
              {crearNuevoSubNivel ? "Guardar" : "Crear subnivel"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nivel_clientes;
