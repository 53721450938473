import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import API from "../../../api";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";

// components

//data

export default function CreateClient(props) {
  let history = useHistory();
  const { register, handleSubmit } = useForm({ shouldUnregister: false });
  const moment = require("moment");

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {};
  }, []);

  //component did mount
  useEffect(() => {
    setParametersLoader(false);
    setEmpresaInfoLoader(false);
    getClasses();
    getRegions();
    getCurrencies();
  }, []);

  // states
  const [selectedTab, setSelectedTab] = useState(0);
  const [country, setCountry] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const [classes, setClasses] = useState([]);
  const [regions, setRegions] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [empresaInfoLoader, setEmpresaInfoLoader] = useState(true);
  const [parametersLoader, setParametersLoader] = useState(true);
  const [parameters, setParameters] = useState({
    id_new_empresa: "",
    fe_llave_firma: "",
    fe_llave_certificacion: "",
    fe_cod_cliente: "",
    fe_alias_cliente: "",
    fe_nombre_emisor: "",
    fe_nit_emisor: "",
    fe_cod_pais: "",
    fe_direccion_emisor: "",
    fe_pais_emisor: "",
    fe_departamento_emisor: "",
    fe_municipio_emisor: "",
    usuario: "",
    clientid: "",
    password: "",
  });
  const [empresaInfo, setEmpresaInfo] = useState({
    codigo_establecimiento: "",
    nombre: "",
    direccion: "",
    telefono: "",
    iva: "",
    tipo_negocio: "",
    region: "",
    regimen_isr: "",
  });

  // set states
  const handleselectedTabs = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const save = (data) => {
    let filledForm = true;

    if (!filledForm) {
      toast.error("Falta campos por llenar", { autoClose: 5000 });
    } else {
      submitClient(data);
    }
  };

  const getClasses = () => {
    API.userConfig
      .getClasses()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setClasses(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las clases", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las clases", {
          autoClose: 10000,
        });
      });
  };

  const getRegions = () => {
    API.userConfig
      .getRegions()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setRegions(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las clases", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las clases", {
          autoClose: 10000,
        });
      });
  };

  const getCurrencies = () => {
    API.userConfig
      .getCurrencies()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setCurrencies(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las monedas", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las monedas", {
          autoClose: 10000,
        });
      });
  };

  const submitClient = (data) => {
    API.admin_clients
      .createNew(data)
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
          props.history.push(
            "/admin/clients/membership/" + response.data.id_new_empresa
          );
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las clases", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) console.log(err.response.data);
        toast.error("Ha ocurrido un error al crear la empresa", {
          autoClose: 10000,
        });
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Crear un nuevo cliente</strong>
        </h4>
      </div>

      <form className="content-card card mt-3" onSubmit={handleSubmit(save)}>
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                <strong>Creación de cliente</strong>
              </h5>
              <p className="mb-0">Agregue manualmente un nuevo cliente.</p>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-1 mt-1 delete-btn"
                variant="contained"
                color="primary"
                component={Link}
                to={"/admin/dashboard"}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1 mx-1 mt-1"
                variant="contained"
                color="primary"
              >
                {saveLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Guardar y continuar"
                )}
              </Button>
            </div>
          </div>

          <Tabs
            className="mt-2"
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleselectedTabs}
          >
            {/*<Tab className="tab" label="Parámetros de adición" />*/}
            <Tab className="tab" label="Empresas" />
            <Tab className="tab" label="Usuario administrador" />
          </Tabs>
        </div>
        <div className="card-body">
          {/*<TabPanel value={selectedTab} index={0} className="config-tab">
						<div className="row mb-4">
							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Código del emisor </strong>
								</label>

								<TextField
									defaultValue={parameters.fe_cod_cliente}
									className="w-100"
									placeholder="Ingrese el código del emisor"
									inputRef={register}
									name="fe_cod_cliente"
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_cod_cliente: event.target.value })}
								/>
							</div>
							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Nombre del emisor</strong>
								</label>

								<TextField
									type="text"
									className="w-100"
									placeholder="Ingrese el nombre del emisor"
									inputRef={register}
									defaultValue={parameters.fe_nombre_emisor}
									name="fe_nombre_emisor"
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_nombre_emisor: event.target.value })}
								/>
							</div>
							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>NIT del emisor</strong>
								</label>

								<TextField
									type="text"
									className="w-100"
									placeholder="Ingrese el NIT  del emisor"
									inputRef={register}
									defaultValue={parameters.fe_nit_emisor}
									name="fe_nit_emisor"
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_nit_emisor: event.target.value })}
								/>
							</div>

							
							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Código de país</strong>
								</label>

								<FormControl variant="outlined" className="w-100">
									<Select
										name="fe_cod_pais"
										native
										inputRef={register}
										defaultValue={parameters.fe_cod_pais}
										className="cs-select1 select-w100"
										required={true}
										onChange={(event) =>
											setParameters({ ...parameters, fe_cod_pais: event.target.value })}
									>
										<option value="">Seleccione el país</option>
										<option value="GT">Guatemala</option>
										<option value="GT">El Salvador</option>
										<option value="GT">Honduras</option>
										<option value="GT">Nicaragua</option>
										<option value="GT">Costa Rica</option>
										<option value="GT">Estados Unidos</option>
										<option value="GT">Panamá</option>
										<option value="GT">Suramerica</option>
										<option value="GT">México</option>
										<option value="GT">El caribe</option>
										<option value="GT">Otro</option>
									</Select>
								</FormControl>
							</div>
							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>País del emisor</strong>
								</label>

								<FormControl variant="outlined" className="w-100">
									<Select
										name="fe_pais_emisor"
										native
										inputRef={register}
										defaultValue={parameters.fe_pais_emisor}
										className="cs-select1 select-w100"
										required={true}
										onChange={(event) =>
											setParameters({ ...parameters, fe_pais_emisor: event.target.value })}
									>
										<option value="" id="AR">
											Seleccione el país
										</option>
										<option value="Argentina" id="AR">
											Argentina
										</option>
										<option value="Bolivia" id="BO">
											Bolivia
										</option>
										<option value="Brasil" id="BR">
											Brasil
										</option>
										<option value="Chile" id="CL">
											Chile
										</option>
										<option value="Colombia" id="CO">
											Colombia
										</option>
										<option value="Costa Rica" id="CR">
											Costa Rica
										</option>
										<option value="Cuba" id="CU">
											Cuba
										</option>
										<option value="Dinamarca" id="DK">
											Dinamarca
										</option>
										<option value="Dominica" id="DM">
											Dominica
										</option>
										<option value="Ecuador" id="EC">
											Ecuador
										</option>
										<option value="El Salvador" id="SV">
											El Salvador
										</option>
										<option value="España" id="ES">
											España
										</option>
										<option value="Estados Unidos" id="US">
											Estados Unidos
										</option>
										<option value="Guatemala" id="GT">
											Guatemala
										</option>
										<option value="Guayana" id="GY">
											Guayana
										</option>
										<option value="Haití" id="HT">
											Haití
										</option>
										<option value="Honduras" id="HN">
											Honduras
										</option>
										<option value="México" id="MX">
											México
										</option>
										<option value="Nicaragua" id="NI">
											Nicaragua
										</option>
										<option value="Panamá" id="PA">
											Panamá
										</option>
										<option value="Paraguay" id="PY">
											Paraguay
										</option>
										<option value="Perú" id="PE">
											Perú
										</option>
										<option value="Puerto Rico" id="PR">
											Puerto Rico
										</option>
										<option value="República Dominicana" id="DO">
											República Dominicana
										</option>
										<option value="Trinidad y Tobago" id="TT">
											Trinidad y Tobago
										</option>
										<option value="Uruguay" id="UY">
											Uruguay
										</option>
										<option value="Venezuela" id="VE">
											Venezuela
										</option>
									</Select>
								</FormControl>
							</div>
							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Departamento del emisor</strong>
								</label>

								<TextField
									inputRef={register}
									name="fe_departamento_emisor"
									defaultValue={parameters.fe_departamento_emisor}
									type="text"
									className="w-100"
									placeholder="Ingrese el departamento del emisor"
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_departamento_emisor: event.target.value })}
								/>
							</div>

							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Municipio del emisor</strong>
								</label>

								<TextField
									className="w-100"
									placeholder="Ingrese el municipio del emisor"
									inputRef={register}
									name="fe_municipio_emisor"
									defaultValue={parameters.fe_municipio_emisor}
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_municipio_emisor: event.target.value })}
								/>
							</div>
							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Dirección del emisor</strong>
								</label>

								<TextField
									name="fe_direccion_emisor"
									inputRef={register}
									defaultValue={parameters.fe_direccion_emisor}
									type="text"
									className="w-100"
									placeholder="Ingrese la dirección del emisor"
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_direccion_emisor: event.target.value })}
								/>
							</div>
							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Alias del emisor (Usuario)</strong>
								</label>

								<TextField
									name="fe_alias_cliente"
									inputRef={register}
									defaultValue={parameters.fe_alias_cliente}
									type="text"
									className="w-100"
									placeholder="Ingrese el nombre de usuario del emisor"
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_alias_cliente: event.target.value })}
								/>
							</div>


							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Proveedor FEL</strong>
								</label>

								<FormControl variant="outlined" className="w-100">
									<Select
										name="id_prov_sat"
										native
										className="cs-select1 select-w100"
										inputRef={register}
										defaultValue={parameters.id_prov_sat}
										required={true}
										onChange={(event) =>
											setParameters({ ...parameters, id_prov_sat: event.target.value })}
									>
										<option value="">Seleccione</option>
										<option value="0">Ninguno</option>
										<option value="2">INFILE</option>
										<option value="3">DIGIFACT</option>
										<option value="1">DOCUTEC</option>
									</Select>
								</FormControl>
							</div>

							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Llave - Firma</strong>
								</label>

								<TextField
									type="text"
									className="w-100"
									placeholder="Ingrese la Llave - Firma"
									name="fe_llave_firma"
									inputRef={register}
									defaultValue={parameters.fe_llave_firma}
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_llave_firma: event.target.value })}
								/>
							</div>
							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>Llave – Certificación</strong>
								</label>

								<TextField
									name="fe_llave_certificacion"
									type="text"
									className="w-100"
									placeholder="Ingrese la Llave - Certificación"
									inputRef={register}
									defaultValue={parameters.fe_llave_certificacion}
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_llave_certificacion: event.target.value })}
								/>
							</div>

							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>FEL – Usuario</strong>
								</label>

								<TextField
									name="username"
									type="text"
									className="w-100"
									placeholder="Ingrese el usuario FEL"
									inputRef={register}
									defaultValue={parameters.usuario}
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, usuario: event.target.value })}
								/>
							</div>

							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>FEL – ID cliente</strong>
								</label>

								<TextField
									name="clientid"
									type="text"
									className="w-100"
									placeholder="Ingrese el ID del cliente FEL"
									inputRef={register}
									defaultValue={parameters.clientid}
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, clientid: event.target.value })}
								/>
							</div>

							<div className="col-md-4 mt-3">
								<label style={{ fontSize: '0.8rem' }}>
									<strong>FEL – Clave</strong>
								</label>

								<TextField
									name="apipassword"
									type="text"
									className="w-100"
									placeholder="Ingrese la clave del usuario FEL"
									inputRef={register}
									defaultValue={parameters.password}
									required={true}
									onChange={(event) =>
										setParameters({ ...parameters, fe_llave_certificacion: event.target.value })}
								/>
							</div>
						</div>
					</TabPanel>*/}

          <TabPanel value={selectedTab} index={0} className="config-tab">
            <div className="row mb-4">
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Código</strong>
                </label>

                <TextField
                  className="w-100"
                  placeholder="Ingrese el código de su empresa"
                  name="code"
                  inputRef={register}
                  defaultValue={empresaInfo.codigo}
                  required={true}
                  onChange={(event) =>
                    setEmpresaInfo({
                      ...empresaInfo,
                      codigo: event.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Nombre de la empresa</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese el nombre de la empresa"
                  name="name"
                  inputRef={register}
                  defaultValue={empresaInfo.nombre}
                  required={true}
                  onChange={(event) =>
                    setEmpresaInfo({
                      ...empresaInfo,
                      nombre: event.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Dirección de la empresa</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese la dirección de la empresa"
                  required={true}
                  name="address"
                  inputRef={register}
                  defaultValue={empresaInfo.direccion}
                  onChange={(event) =>
                    setEmpresaInfo({
                      ...empresaInfo,
                      direccion: event.target.value,
                    })
                  }
                />
              </div>

              {/* Second Row */}
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>NIT de la empresa</strong>
                </label>

                <TextField
                  className="w-100"
                  placeholder="Ingrese el NIT de su empresa"
                  name="nit"
                  inputRef={register}
                  defaultValue={empresaInfo.nit}
                  required={true}
                  onChange={(event) =>
                    setEmpresaInfo({ ...empresaInfo, nit: event.target.value })
                  }
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Teléfono de la empresa</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="(       )        -           -"
                  name="phone"
                  inputRef={register}
                  defaultValue={empresaInfo.telefono}
                  required={true}
                  onChange={(event) =>
                    setEmpresaInfo({
                      ...empresaInfo,
                      telefono: event.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-2 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>IVA (%)</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese el IVA"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  name="iva"
                  inputRef={register}
                  defaultValue={empresaInfo.iva}
                  required={true}
                  onChange={(event) =>
                    setEmpresaInfo({ ...empresaInfo, iva: event.target.value })
                  }
                />
              </div>

              <div className="col-md-2 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Moneda</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    native
                    className="cs-select1 select-w100"
                    name="currency"
                    inputRef={register}
                    defaultValue={empresaInfo.id_moneda_web_app}
                    required={true}
                    onChange={(event) =>
                      setEmpresaInfo({
                        ...empresaInfo,
                        id_moneda_web_app: event.target.value,
                      })
                    }
                  >
                    <option value="">Seleccione</option>
                    {currencies.map((currency) => (
                      <option value={currency.id_moneda_web_app}>
                        {currency.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* Third Row */}
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Código del establecimiento</strong>
                </label>

                <TextField
                  className="w-100"
                  placeholder="Ingrese el código del establecimiento"
                  name="establishment_code"
                  inputRef={register}
                  defaultValue={empresaInfo.codigo_establecimiento}
                  required={true}
                  onChange={(event) =>
                    setEmpresaInfo({
                      ...empresaInfo,
                      codigo_establecimiento: event.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Dispositivo</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese el dispositivo"
                  name="device"
                  defaultValue={empresaInfo.dispositivo}
                  inputRef={register}
                  required={true}
                  onChange={(event) =>
                    setParameters({
                      ...empresaInfo,
                      dispositivo: event.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Régimen de ISR</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Ingrese el régimen de ISR de la empresa"
                  name="isr_regime"
                  defaultValue={empresaInfo.regimen_isr}
                  required={true}
                  inputRef={register}
                  onChange={(event) =>
                    setEmpresaInfo({
                      ...empresaInfo,
                      regimen_isr: event.target.value,
                    })
                  }
                />
              </div>

              {/* Forth Row */}
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Tipo de negocio</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    native
                    className="cs-select1 select-w100"
                    name="type"
                    inputRef={register}
                    defaultValue={empresaInfo.tipo_negocio}
                    required={true}
                    onChange={(event) =>
                      setEmpresaInfo({
                        ...empresaInfo,
                        tipo_negocio: event.target.value,
                      })
                    }
                  >
                    <option value="">Seleccione el tipo de negocio</option>
                    <option value="1">ABARROTERIA Y MISCELANEA</option>
                    <option value="2">TELECOMUNICACIONES E INFORMATICA</option>
                    <option value="3">COOPERATIVAS</option>
                    <option value="4">
                      ASESORIAS Y SERVICIOS PROFESIONALES
                    </option>
                    <option value="5">FARMACEUTICOS</option>
                    <option value="6">TELEFONIA, CELULARES Y ACCESORIOS</option>
                    <option value="7">FERRETERIA Y VENTA DE MATERIALES</option>
                    <option value="8">
                      INSTITUCIONES DE CREDITO Y SERVICIOS FINANCIEROS
                    </option>
                    <option value="9">OTROS</option>
                    <option value="10">LIBRERÍA Y PAPELERIA</option>
                    <option value="11">ROPA Y TEXTILES</option>
                    <option value="13">
                      SERVICIOS, REPUESTOS Y ACCESORIOS AUTOMOTRICES Y VEHICULOS
                    </option>
                    <option value="14">AGROSERVICIOS</option>
                    <option value="15">EDUCATIVO</option>
                    <option value="16">ZAPATERIA</option>
                    <option value="17">RESTAURANTE</option>
                    <option value="18">SERVICIOS DE CORREO</option>
                    <option value="19">ASOCIACIONES</option>
                    <option value="20">HOGAR Y ELECTRODOMESTICOS</option>
                    <option value="21">
                      GASOLINERAS Y ESTACION DE SERVICIOS
                    </option>
                    <option value="22">TRANSPORTES</option>
                    <option value="23">HOGAR Y ELECTRODOMESTICOS</option>
                    <option value="24">SALON BELLEZA</option>
                    <option value="25">PANADERIA</option>
                    <option value="26">EQUIPO INDUSTRIAL Y MAQUINARIA</option>
                    <option value="27">VIDRIERIA</option>
                    <option value="28">SERIGRAFIA</option>
                    <option value="29">MUEBLERIAS Y CARPINTERIAS</option>
                    <option value="30">JOYERIA Y RELOJERIA</option>
                    <option value="31">INMOBILIARIA</option>
                    <option value="32">HELADERIA</option>
                    <option value="33">CONSTRUCTORA</option>
                    <option value="34">CARNICERIA</option>
                    <option value="35">VETERINARIA Y ESTETICA CANINA</option>
                    <option value="36">VENTA DE GAS PROPANO</option>
                    <option value="37">SOCIEDAD</option>
                    <option value="38">
                      PURIFICADORA Y VENTA DE AGUA PURA
                    </option>
                    <option value="39">HOTELERIA</option>
                    <option value="40">FABRICACION Y VENTA DE PINTURAS</option>
                    <option value="41">EQUIPO DE AUDIO</option>
                    <option value="42">BOLETOS AEREOS</option>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Región</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    native
                    className="cs-select1 select-w100"
                    name="region"
                    inputRef={register}
                    required={true}
                    defaultValue={empresaInfo.region}
                    onChange={(event) =>
                      setEmpresaInfo({
                        ...empresaInfo,
                        region: event.target.value,
                      })
                    }
                  >
                    <option value="">Seleccione la región</option>
                    {regions.map((region) => (
                      <option value={region.id_region_web_app}>
                        {region.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Clase</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    native
                    className="cs-select1 select-w100"
                    name="class_id"
                    inputRef={register}
                    required={true}
                  >
                    <option value="">Seleccione la clase</option>
                    {classes.map((clase) => (
                      <option value={clase.id_new_grupo1}>
                        {clase.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={selectedTab} index={1} className="config-tab">
            <div className="row mb-4">
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Correo electrónico</strong>
                </label>

                <TextField
                  className="w-100"
                  placeholder="Ingrese el correo del usuario administrador"
                  name="email"
                  inputRef={register}
                  required={true}
                  type="email"
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Contraseña</strong>
                </label>

                <TextField
                  type="password"
                  className="w-100"
                  placeholder="Ingrese la contraseña del usuario"
                  name="password"
                  inputRef={register}
                  required={true}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Confirme la contraseña</strong>
                </label>

                <TextField
                  type="password"
                  className="w-100"
                  placeholder="Confirme la contraseña"
                  name="password_confirmation"
                  inputRef={register}
                  required={true}
                />
              </div>
            </div>
          </TabPanel>
        </div>
      </form>
    </div>
  );
}
