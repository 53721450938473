import React, { useContext } from "react";

import {
  FlexContainer,
  ActionsContainer,
  BackButton,
  ContinueButton,
} from "./styled/styled.components";
import { ArrowBackIos } from "@material-ui/icons";
import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { useHistory, useParams } from "react-router";
import { cancelAlekoOrder } from "../../../api/sales";
import CancelModal from "./CancelModal";

const Actions = () => {
  const history = useHistory();
  const params = useParams();
  const { submitOrder, clearState } = useContext(ProductPersonalizationContext);
  const back = () => {
    history.push("/app/vender/customization");
  };

  const cancel = async () => {
    try {
      const { data } = await cancelAlekoOrder({ id_pedido: params.id });
      if (data.succes) {
        history.push("/app/vender");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const order = () => {
    submitOrder(history);
  };

  return (
    <ActionsContainer gridColumn="1/-1">
      <BackButton onClick={back}>
        <ArrowBackIos
          style={{
            fontSize: "14px",
            color: "white",
          }}
        />
        Volver
      </BackButton>
      <FlexContainer>
        <CancelModal
          clearState={clearState}
          cancel={cancel}
          cancelText="¿Seguro que quieres cancelar la orden?"
          text="Cancelar Orden"
        />

        <ContinueButton onClick={order}>Ordenar</ContinueButton>
      </FlexContainer>
    </ActionsContainer>
  );
};
export default Actions;
