import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const get_anulaciones = async (data) => {
  return await axios.post(
    `${base_url}/web/get-anulaciones-venta`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const get_fel_history = async (data) => {
  return await axios.post(
    `${base_url}/web/get-fel-history`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const get_cuentas_por_cobrar = async (data) => {
  return await axios.post(`${base_url}/web/cuentas_por_cobrar`, {
    headers: headers(),
  });
};

const get_graphs_data = async (data) => {
  return await axios.get(
    `${base_url}/web/get-global-operations?id_sucursal_bodega_ubicacion=${data.id_sucursal_bodega_ubicacion}`,
    { headers: headers() }
  );
};

const get_numeric_stats = async (data) => {
  data.web = true;
  return await axios.post(
    `${base_url}/web/get-statistics`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const get_kardex = async (data) => {
  return await axios.post(`${base_url}/web/get-kardexes`, qs.stringify(data), {
    headers: headers(),
  });
};

export default {
  get_anulaciones,
  get_fel_history,
  get_cuentas_por_cobrar,
  get_graphs_data,
  get_numeric_stats,
  get_kardex,
};
