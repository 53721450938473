export const isGerenteMercadeo = () => {
  if (JSON.parse(localStorage.getItem("pms"))) {
    const tipo_usuario = JSON.parse(localStorage.getItem("pms")).tipo_usuario;
    return (
      tipo_usuario.toLowerCase() === "gerente mercadeo" ||
      tipo_usuario.toLowerCase() === "gerente regional"
    );
  } else {
    return false;
  }
};
