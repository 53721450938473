import React, { useContext, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import "./custom.css";

import {
  CheckCircleOutlined,
  HighlightOffOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import CmiProductContext from "../../../context/CmiProductContext/CmiProductContext";
import { useHistory } from "react-router";

export default function ModalLimite({ store }) {
  const history = useHistory();
  const {
    modalDiaAnterior: open,
    setModalDiaAnterior: setOpen,
    setActivarModal,
    setIdSucursal,
  } = useContext(CmiProductContext);

  const handleClose = (check) => {
    setOpen(false);
    if (check) {
      history.push("/app/cortes_caja/realizar_corte");
      setActivarModal(true);
      setIdSucursal(store);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="card" style={{ width: "500px", border: "none" }}>
            <div className="card-title d-flex justify-content-end align-items-center border-bottom py-3">
              <IconButton
                onClick={() => handleClose(false)}
                style={{ marginRight: "17px" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
              <div className="w-100 d-flex justify-content-center">
                <ReportProblemOutlined
                  className="color-css-text"
                  style={{ fontSize: "60px" }}
                />
              </div>

              <h4 className="text-center">
                <strong>Límite máximo alcanzado</strong>
              </h4>
              <br />
              <p>
                Su caja ha llegado al nivel máximo de efectivo. Por favor
                realice un retiro de efectivo a bóveda.
              </p>
            </div>
            <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-between">
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px", height: "42px", width: "150px" }}
                onClick={() => handleClose(false)}
                disabled={false}
              >
                <span
                  style={{
                    color: true ? "#0160E7" : "#BDBDBD",
                    fontSize: "14px",
                  }}
                >
                  Cancelar
                </span>
              </Button>
              <Button
                variant="contained"
                id="back-css"
                style={{
                  boxShadow: "none",
                  width: "150px",
                  height: "42px",
                }}
                onClick={() => handleClose(true)}
              >
                <span style={{ color: "#fff", fontSize: "14px" }}>
                  Iniciar retiro
                </span>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
