import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputAdornment from "@material-ui/core/InputAdornment";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import API from "../../../api";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";

// styles
import "./custom.css";

//icons
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

// components

//data

const example_data = {
  empresas: [
    {
      id_new_empresa: 40,
      nombre: "QA Empresa",
      color: "#e30e8a",
    },
    {
      id_new_empresa: 41,
      nombre: "QA Empresa",
      color: "#a13ad8",
    },
    {
      id_new_empresa: 1004,
      nombre: "Demo",
      color: "#ace36",
    },
    {
      id_new_empresa: 1005,
      nombre: "Demo",
      color: "#f68e97",
    },
    {
      id_new_empresa: 1006,
      nombre: "QA tester 2",
      color: "#47eaa9",
    },
    {
      id_new_empresa: 1007,
      nombre: "QA tester 2",
      color: "#a2d17b",
    },
    {
      id_new_empresa: 1008,
      nombre: "Empresa QA2",
      color: "#7a829c",
    },
    {
      id_new_empresa: 1009,
      nombre: "Empresa QA2",
      color: "#ba41ba",
    },
    {
      id_new_empresa: 1010,
      nombre: "Empresa QA2",
      color: "#a2be90",
    },
    {
      id_new_empresa: 1011,
      nombre: "Empresa QA2",
      color: "#8ecac0",
    },
    {
      id_new_empresa: 1012,
      nombre: "ALEJANDRO AGUILAR",
      color: "#e39c12",
    },
    {
      id_new_empresa: 1013,
      nombre: "Empresa QA2",
      color: "#cbde1c",
    },
    {
      id_new_empresa: 1014,
      nombre: "Empresa",
      color: "#3a8e43",
    },
    {
      id_new_empresa: 1015,
      nombre: "Empresa",
      color: "#579dfc",
    },
    {
      id_new_empresa: 1016,
      nombre: "Empresa",
      color: "#f42a8e",
    },
    {
      id_new_empresa: 1017,
      nombre: "Empresa QA2",
      color: "#908b87",
    },
    {
      id_new_empresa: 1018,
      nombre: "Empresa",
      color: "#f856a6",
    },
    {
      id_new_empresa: 1019,
      nombre: "ALEJANDRO AGUILAR",
      color: "#8e24b5",
    },
    {
      id_new_empresa: 1020,
      nombre: "Empresa",
      color: "#600937",
    },
    {
      id_new_empresa: 1021,
      nombre: "Empresa QA2",
      color: "#641218",
    },
    {
      id_new_empresa: 1022,
      nombre: "ALEJANDRO AGUILAR",
      color: "#9c3518",
    },
    {
      id_new_empresa: 1023,
      nombre: "ALEJANDRO AGUILAR",
      color: "#8297c2",
    },
    {
      id_new_empresa: 1024,
      nombre: "ALEJANDRO2",
      color: "#890144",
    },
    {
      id_new_empresa: 1025,
      nombre: "ALEJANDRO3",
      color: "#9b3188",
    },
    {
      id_new_empresa: 1026,
      nombre: "ALEJANDRO4",
      color: "#486062",
    },
    {
      id_new_empresa: 1027,
      nombre: "ALEJANDRON",
      color: "#427da8",
    },
    {
      id_new_empresa: 1028,
      nombre: "Alejandro Aguilar",
      color: "#88ea54",
    },
    {
      id_new_empresa: 1029,
      nombre: "alejandro definitivo",
      color: "#b5c3b0",
    },
    {
      id_new_empresa: 1030,
      nombre: "Empresa QA2",
      color: "#d420ee",
    },
    {
      id_new_empresa: 1033,
      nombre: "Pruebas INFILE",
      color: "#f86eb4",
    },
    {
      id_new_empresa: 1034,
      nombre: "My Business",
      color: "#200f69",
    },
    {
      id_new_empresa: 1035,
      nombre: "My Business",
      color: "#85e926",
    },
    {
      id_new_empresa: 1036,
      nombre: "My Business",
      color: "#6e1495",
    },
    {
      id_new_empresa: 1037,
      nombre: "My Business",
      color: "#c32df7",
    },
    {
      id_new_empresa: 1038,
      nombre: "My Business",
      color: "#60466b",
    },
    {
      id_new_empresa: 1039,
      nombre: "My Business",
      color: "#c83238",
    },
    {
      id_new_empresa: 1040,
      nombre: "My Business",
      color: "#2f097a",
    },
    {
      id_new_empresa: 1041,
      nombre: "My Business",
      color: "#cfe109",
    },
    {
      id_new_empresa: 1042,
      nombre: "My Business",
      color: "#f5efdf",
    },
    {
      id_new_empresa: 1043,
      nombre: "My Business",
      color: "#39813f",
    },
    {
      id_new_empresa: 1044,
      nombre: "PRUEBA 3",
      color: "#7e66bc",
    },
    {
      id_new_empresa: 1045,
      nombre: "Pruebas 03",
      color: "#2267a6",
    },
    {
      id_new_empresa: 1046,
      nombre: "Pedro Siccha",
      color: "#9b626a",
    },
    {
      id_new_empresa: 1047,
      nombre: "Milu Accesorios",
      color: "#fd1001",
    },
    {
      id_new_empresa: 2044,
      nombre: "Empresa de prueba",
      color: "#fe3596",
    },
    {
      id_new_empresa: 3046,
      nombre: "EMPRESA 22-04",
      color: "#ea5dfc",
    },
    {
      id_new_empresa: 3047,
      nombre: "EMPRESA 23-04",
      color: "#5faf40",
    },
    {
      id_new_empresa: 3048,
      nombre: "EMPRESA 24-04",
      color: "#cb892c",
    },
    {
      id_new_empresa: 3053,
      nombre: "Empresa QA 3",
      color: "#b999b9",
    },
  ],
  dataByPeriod: [
    {
      name: "1",
      "QA Empresa": 2,
      Demo: 0,
      "QA tester 2": 5,
      "Empresa QA2": 0,
      "ALEJANDRO AGUILAR": 5,
      Empresa: 0,
      ALEJANDRO2: 6,
      ALEJANDRO3: 0,
      ALEJANDRO4: 0,
      ALEJANDRON: 0,
      "Alejandro Aguilar": 6,
      "alejandro definitivo": 0,
      "Pruebas INFILE": 0,
      "My Business": 6,
      "PRUEBA 3": 0,
      "Pruebas 03": 0,
      "Pedro Siccha": 0,
      "Milu Accesorios": 0,
      "Empresa de prueba": 0,
      "EMPRESA 22-04": 0,
      "EMPRESA 23-04": 0,
      "EMPRESA 24-04": 0,
      "Empresa QA 3": 6,
    },
    {
      name: "6",
      "QA Empresa": 0,
      Demo: 6,
      "QA tester 2": 0,
      "Empresa QA2": 0,
      "ALEJANDRO AGUILAR": 0,
      Empresa: 0,
      ALEJANDRO2: 0,
      ALEJANDRO3: 612,
      ALEJANDRO4: 0,
      ALEJANDRON: 5,
      "Alejandro Aguilar": 0,
      "alejandro definitivo": 0,
      "Pruebas INFILE": 0,
      "My Business": 123,
      "PRUEBA 3": 0,
      "Pruebas 03": 0,
      "Pedro Siccha": 312,
      "Milu Accesorios": 0,
      "Empresa de prueba": 0,
      "EMPRESA 22-04": 0,
      "EMPRESA 23-04": 0,
      "EMPRESA 24-04": 0,
      "Empresa QA 3": 0,
    },
    {
      name: "11",
      "QA Empresa": 0,
      Demo: 0,
      "QA tester 2": 0,
      "Empresa QA2": 0,
      "ALEJANDRO AGUILAR": 0,
      Empresa: 0,
      ALEJANDRO2: 0,
      ALEJANDRO3: 0,
      ALEJANDRO4: 0,
      ALEJANDRON: 0,
      "Alejandro Aguilar": 0,
      "alejandro definitivo": 0,
      "Pruebas INFILE": 0,
      "My Business": 0,
      "PRUEBA 3": 0,
      "Pruebas 03": 0,
      "Pedro Siccha": 0,
      "Milu Accesorios": 0,
      "Empresa de prueba": 0,
      "EMPRESA 22-04": 0,
      "EMPRESA 23-04": 0,
      "EMPRESA 24-04": 0,
      "Empresa QA 3": 0,
    },
    {
      name: "16",
      "QA Empresa": 0,
      Demo: 0,
      "QA tester 2": 0,
      "Empresa QA2": 0,
      "ALEJANDRO AGUILAR": 0,
      Empresa: 0,
      ALEJANDRO2: 0,
      ALEJANDRO3: 0,
      ALEJANDRO4: 0,
      ALEJANDRON: 0,
      "Alejandro Aguilar": 0,
      "alejandro definitivo": 0,
      "Pruebas INFILE": 0,
      "My Business": 0,
      "PRUEBA 3": 0,
      "Pruebas 03": 0,
      "Pedro Siccha": 0,
      "Milu Accesorios": 0,
      "Empresa de prueba": 0,
      "EMPRESA 22-04": 0,
      "EMPRESA 23-04": 0,
      "EMPRESA 24-04": 0,
      "Empresa QA 3": 0,
    },
    {
      name: "21",
      "QA Empresa": 0,
      Demo: 0,
      "QA tester 2": 0,
      "Empresa QA2": 0,
      "ALEJANDRO AGUILAR": 0,
      Empresa: 0,
      ALEJANDRO2: 0,
      ALEJANDRO3: 0,
      ALEJANDRO4: 0,
      ALEJANDRON: 0,
      "Alejandro Aguilar": 0,
      "alejandro definitivo": 0,
      "Pruebas INFILE": 0,
      "My Business": 0,
      "PRUEBA 3": 0,
      "Pruebas 03": 0,
      "Pedro Siccha": 0,
      "Milu Accesorios": 0,
      "Empresa de prueba": 0,
      "EMPRESA 22-04": 0,
      "EMPRESA 23-04": 0,
      "EMPRESA 24-04": 0,
      "Empresa QA 3": 0,
    },
    {
      name: "26",
      "QA Empresa": 0,
      Demo: 0,
      "QA tester 2": 0,
      "Empresa QA2": 0,
      "ALEJANDRO AGUILAR": 0,
      Empresa: 0,
      ALEJANDRO2: 0,
      ALEJANDRO3: 0,
      ALEJANDRO4: 0,
      ALEJANDRON: 0,
      "Alejandro Aguilar": 0,
      "alejandro definitivo": 0,
      "Pruebas INFILE": 0,
      "My Business": 0,
      "PRUEBA 3": 0,
      "Pruebas 03": 0,
      "Pedro Siccha": 0,
      "Milu Accesorios": 0,
      "Empresa de prueba": 0,
      "EMPRESA 22-04": 0,
      "EMPRESA 23-04": 0,
      "EMPRESA 24-04": 0,
      "Empresa QA 3": 0,
    },
  ],
};

let table_data = [
  {
    id: 1,
    nombre: "Coorporación la bonanza",
    transacciones: 800,
    items_creado: 150,
  },
  {
    id: 2,
    nombre: "Coorporación la bonanza",
    transacciones: 800,
    items_creado: 150,
  },
  {
    id: 3,
    nombre: "Coorporación la bonanza",
    transacciones: 800,
    items_creado: 150,
  },
  {
    id: 4,
    nombre: "Coorporación la bonanza",
    transacciones: 800,
    items_creado: 150,
  },

  {
    id: 5,
    nombre: "Coorporación la bonanza",
    transacciones: 800,
    items_creado: 150,
  },
];

export default function User_conifg(props) {
  let history = useHistory();
  const moment = require("moment");
  let default_dates = [
    new Date(new Date().setDate(new Date().getDate() - 7)),
    new Date(),
  ];

  const [loader, setLoader] = useState(true);
  const [globalStats, setGlobalStats] = useState({});
  const [region, setRegion] = useState("");
  const [companies, setCompanies] = useState([]);
  const [business, setBusiness] = useState("");
  const [client, setClient] = useState("");
  const [displayItems, setDisplayItems] = useState(true);
  const [displayTranstactions, setDisplayTranstactions] = useState(true);
  const [displayClients, setDisplayClients] = useState(true);
  const [type, setType] = useState(4);
  const [regions, setRegions] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyStats, setCompanyStats] = useState([]);
  const [loader_two, setLoader_two] = useState(true);

  //cleap up - componentWillUnmount
  useEffect(() => {
    getStats();
    return () => {};
  }, []);

  useEffect(() => {
    getStats();
  }, [type]);

  useEffect(() => {
    getBusinessTypes();
    getRegions();
    getSimplifiedEmpresas();
    getCompanyStats();
  }, []);

  const getStats = () => {
    setLoader(true);
    API.admin_stats
      .getGlobalStats({
        tipo: type,
        id_new_empresa: client,
        negocio: business,
        region,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setGlobalStats(response.data);
          console.log("This is what im obtaining, response", response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            console.log("response", response);
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("Error", err, err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getCompanyStats = () => {
    setLoader_two(true);
    API.admin_stats
      .getCompanyStats({
        id_new_empresa: client,
        negocio: business,
        region,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCompanyStats(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            console.log("response", response);
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
        setLoader_two(false);
      })
      .catch((err) => {
        setLoader_two(false);
        console.log("Error", err, err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getBusinessTypes = () => {
    API.userConfig
      .getBusinessTypes()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setBusinessTypes(response.data);
          console.log("Business types, response", response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            console.log("response", response);
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log("Error", err, err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getRegions = () => {
    API.userConfig
      .getRegions()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setRegions(response.data);
          console.log("Regions response", response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            console.log("response", response);
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log("Error", err, err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getSimplifiedEmpresas = () => {
    API.admin_stats
      .getEmpresasData()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setCompanies(response.data);
          console.log("Companies response", response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            console.log("response", response);
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log("Error", err, err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const changeTypeDate = (typeDate) => {
    setType(typeDate);
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <h4 className="mb-0">
          <strong>Estadísticas Globales</strong>
        </h4>
      </div>

      <form className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <p className="mb-0">Filtros:</p>
              <div className="d-flex flex-wrap align-items-center">
                <FormControl variant="outlined" className="px-1">
                  <Select
                    native
                    className="sm-select"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                  >
                    <option value="">Todas las regiones</option>
                    {regions.map((region) => (
                      <option value={region.id_region_web_app}>
                        {region.descripcion.trim()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className="px-1">
                  <Select
                    native
                    className="sm-select"
                    value={business}
                    onChange={(e) => setBusiness(e.target.value)}
                  >
                    <option value="">Todos los tipos de negocio</option>
                    {businessTypes.map((business) => (
                      <option value={business.id_tipo_empresa_web_app}>
                        {business.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className="px-1">
                  <Select
                    native
                    className="sm-select"
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                  >
                    <option value="">Todos los clientes</option>
                    {companies.map((company) => (
                      <option value={company.id_new_empresa}>
                        {company.nombre}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  onClick={getStats}
                >
                  Enviar
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body pt-4">
          <div className="">
            <div className="d-flex align-items-center">
              <ButtonGroup
                className="tab-buttons my-2"
                color="primary"
                aria-label="outlined primary button group"
                size="small"
              >
                <Button
                  style={{ fontSize: "15px" }}
                  className={type === 4 ? "active" : ""}
                  onClick={() => changeTypeDate(4)}
                >
                  Año
                </Button>
                <Button
                  style={{ fontSize: "15px" }}
                  className={type === 3 ? "active" : ""}
                  onClick={() => changeTypeDate(3)}
                >
                  Mes
                </Button>
                <Button
                  style={{ fontSize: "15px" }}
                  className={type === 2 ? "active" : ""}
                  onClick={() => changeTypeDate(2)}
                >
                  Semana
                </Button>
              </ButtonGroup>
            </div>

            <div className="d-flex align-items-center my-2">
              <div className="stat-legend">
                <strong>Clientes por cliente</strong>
                <ButtonGroup
                  color="primary"
                  size="small"
                  style={{ margin: "0 10px" }}
                >
                  <Button
                    className={
                      !displayClients ? "legend_enable" : "legend_disable"
                    }
                    onClick={() => setDisplayClients(false)}
                  >
                    Ocultar
                  </Button>
                  <Button
                    className={
                      displayClients ? "legend_enable" : "legend_disable"
                    }
                    onClick={() => setDisplayClients(true)}
                  >
                    Mostrar
                  </Button>
                </ButtonGroup>
              </div>
              <div className="stat-legend">
                <div
                  className="stat-color"
                  style={{ backgroundColor: "#1A2EAB" }}
                />
                <strong>Items creados</strong>
                <ButtonGroup
                  color="primary"
                  size="small"
                  style={{ margin: "0 10px" }}
                >
                  <Button
                    className={
                      !displayItems ? "legend_enable" : "legend_disable"
                    }
                    onClick={() => setDisplayItems(false)}
                  >
                    Ocultar
                  </Button>
                  <Button
                    className={
                      displayItems ? "legend_enable" : "legend_disable"
                    }
                    onClick={() => setDisplayItems(true)}
                  >
                    Mostrar
                  </Button>
                </ButtonGroup>
              </div>
              <div className="stat-legend">
                <div
                  className="stat-color"
                  style={{ backgroundColor: "#08BFE6" }}
                />
                <strong>Transacciones</strong>
                <ButtonGroup
                  color="primary"
                  size="small"
                  style={{ margin: "0 10px" }}
                >
                  <Button
                    className={
                      !displayTranstactions ? "legend_enable" : "legend_disable"
                    }
                    onClick={() => setDisplayTranstactions(false)}
                  >
                    Ocultar
                  </Button>
                  <Button
                    className={
                      displayTranstactions ? "legend_enable" : "legend_disable"
                    }
                    onClick={() => setDisplayTranstactions(true)}
                  >
                    Mostrar
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>

          {loader ? (
            <div className="my-auto text-center">
              <CircularProgress size={25} className="mx-auto" />
            </div>
          ) : (
            <>
              {globalStats.empresas.length > 0 ? (
                <ResponsiveContainer width="100%" height={323}>
                  <ComposedChart
                    width={500}
                    height={300}
                    data={globalStats.dataByPeriod}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={(text) => `${text}`} />
                    <Tooltip />

                    {displayItems ? (
                      <Bar
                        dataKey="Items creados"
                        fill="#1A2EAB"
                        barSize={10}
                      />
                    ) : (
                      ""
                    )}
                    {displayTranstactions ? (
                      <Bar
                        dataKey="Transacciones"
                        fill="#1FE3D8"
                        barSize={10}
                      />
                    ) : (
                      ""
                    )}
                    {displayClients
                      ? globalStats.empresas.map((empresa) => (
                          <Line
                            dataKey={empresa.nombre}
                            stroke={empresa.color}
                            strokeWidth={2}
                          />
                        ))
                      : ""}
                  </ComposedChart>
                </ResponsiveContainer>
              ) : (
                <div className="row justify-content-center">
                  <h5>No hay datos</h5>
                </div>
              )}

              <div className="d-flex align-items-center flex-wrap">
                {globalStats.empresas.map((stat) => (
                  <div className="stat-legend mx-2 my-2">
                    <div
                      className="stat-color"
                      style={{ backgroundColor: `${stat.color}` }}
                    />
                    <strong>{stat.nombre}</strong>
                  </div>
                ))}
              </div>
            </>
          )}

          <div className="d-flex align-items-center justify-content-end my-3">
            <CSVLink
              data={companyStats}
              filename={"Clientes " + moment().format("DD-MM-YYYY") + ".csv"}
            >
              <Button className="btn-cs1" variant="contained" color="primary">
                Descargar reporte
                <CloudDownloadIcon className="ml-2" />
              </Button>
            </CSVLink>
          </div>

          {loader_two ? (
            <div className="my-auto text-center">
              <CircularProgress size={25} className="mx-auto" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2 mt-2"
              title={"s"}
              columns={[
                {
                  name: "Nombre del cliente",
                  selector: "nombre",
                  cell: (row) => (
                    <div className="w-100 text-center">{row.nombre}</div>
                  ),
                  sortable: true,
                },

                {
                  name: "Transacciones",
                  selector: "invoices_amount",
                  cell: (row) => (
                    <div className="w-100 text-center">
                      {row.invoices_amount}
                    </div>
                  ),
                  sortable: true,
                },
                {
                  name: "Items creados",
                  selector: "items_amount",
                  cell: (row) => (
                    <div className="w-100 text-center">{row.items_amount}</div>
                  ),
                  sortable: true,
                },

                {
                  name: "",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      <Button
                        className="brand2-btn"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          history.push({
                            pathname: `/admin/clients/profile/${row.id_new_empresa}`,
                            state: row,
                          })
                        }
                      >
                        <span
                          style={{ marginBottom: "-4px", fontSize: "12px" }}
                        >
                          Ver detalles
                        </span>
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={companyStats}
              pagination
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se ha encontrado ningún resultado"
            />
          )}
        </div>
      </form>
    </div>
  );
}
