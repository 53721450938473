import { Button, CircularProgress, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";

import moment from "moment";

import RCHistorialReporte from "../../../reportes/rCHistorialReporte";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";
import SalesTable from "./Tables/SalesTable";
import TabPanel from "./SapTabPanel";
import ProductsTable from "./Tables/ProductsTable";
import ProductosSap from "../../../reportes/productosSap";
const CardMain = (props) => {
  const {
    fecha,
    selectedSales,
    filterSales,
    selectedProducts,
    filterProducts,
    loader,
  } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabs = (event, newValue) => {
    setCurrentTab(newValue);
  };
  return (
    <>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
          <h4 className="mb-0">
            <strong>Historial de ventas</strong>
          </h4>
        </div>
        <div class="row justify-content-between mt-3 mb-3">
          {selectedSales.length > 0 || selectedProducts.length > 0 ? (
            <>
              <Button
                className="ml-3"
                style={{
                  background: "#fff",
                  border: "1px solid #0160E7",
                  boxShadow: "none",
                }}
                variant="contained"
                size="small"
                onClick={() => {
                  if (currentTab === 0) {
                    document
                      .getElementById("descargar-reporteRCHistorial")
                      .click();
                  }
                  if (currentTab === 1) {
                    document.getElementById("descargar-productos-sap").click();
                  }
                }}
              >
                <CloudDownloadIcon
                  className="ml-2"
                  style={{ color: "#0160E7" }}
                />
                &nbsp;
                <span style={{ color: "#0160E7" }}>Descargar</span>
              </Button>
            </>
          ) : (
            <>
              <Button
                className="ml-3"
                variant="contained"
                style={{
                  background: "#fff",
                  border: "1px solid #0160E7",
                  boxShadow: "none",
                }}
                size="small"
              >
                <CloudDownloadIcon
                  className="ml-2"
                  style={{ color: "#0160E7" }}
                />{" "}
                &nbsp;
                <span style={{ color: "#0160E7" }}>Descargar</span>
              </Button>
            </>
          )}

          <div className="col-md-4">
            <div class="input-group search-bar">
              <input
                type="text"
                class="form-control"
                placeholder={
                  currentTab === 0
                    ? "Buscar por número de operación"
                    : "Buscar por código SAP o nombre de producto"
                }
                onChange={(event) => {
                  if (currentTab === 0) {
                    filterSales(event);
                  }
                  if (currentTab === 1) {
                    filterProducts(event);
                  }
                }}
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <SearchIcon />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="products-table user-table">
          {loader ? (
            <div className="d-flex w-100 justify-content-center">
              <CircularProgress size={27} />
            </div>
          ) : (
            <>
              <Tabs
                className="mt-2"
                value={currentTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabs}
              >
                <Tab className="tab" label="Ventas" />
                <Tab className="tab" label="Productos" />
              </Tabs>
              <TabPanel value={currentTab} index={0} className="mt-3">
                <ReactHTMLTableToExcel
                  id="descargar-reporteRCHistorial"
                  className="buton-excel"
                  table="RCHistorial-reporte-table"
                  filename={
                    "Ventas" + " del día " + moment(fecha).format("DD-MM-YYYY")
                  }
                  sheet="tablexls"
                  buttonText="Descargar"
                />
                <RCHistorialReporte objeto={selectedSales}></RCHistorialReporte>
                <SalesTable {...props} />
              </TabPanel>
              <TabPanel value={currentTab} index={1} className="mt-3">
                <ReactHTMLTableToExcel
                  id="descargar-productos-sap"
                  className="buton-excel"
                  table="productos-sap"
                  filename={
                    "Productos" +
                    " del día " +
                    moment(fecha).format("DD-MM-YYYY")
                  }
                  sheet="tablexls"
                  buttonText="Descargar"
                />
                <ProductosSap objeto={selectedProducts} />
                <ProductsTable {...props} />
              </TabPanel>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CardMain;
