import { ExpandMore } from "@material-ui/icons";
import React, { useContext, useEffect } from "react";
import ProductPersonalizationContext from "../../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import { numberFormater } from "../../../../helpers/formater";
import { getMoneda } from "../../../../helpers/moneda";
import Product from "./components/Product";
const getTotalProduct = (variant) => {
  let total =
    variant?.cantidad * (variant?.descuento_precio || variant?.precio_venta);

  if (!variant?.addProductWithDiscount) {
    total = 0;
  }
  const precioExtraColor = variant?.custom?.variant?.find(
    (x) => x?.codigo_hexa_color === variant?.color
  );
  if (precioExtraColor) {
    total = total + precioExtraColor.precio_extra;
  }

  return total - total * (variant?.descuento_producto / 100);
};
const calcTotal = (variant, point) => {
  const currentPersonalization = variant?.custom.customType.find(
    (x) => x.nombre_personalizacion === point.customType.nombre_personalizacion
  );

  const cm = point.dimensions.width * point.dimensions.height;
  const getDiscount = (num) => (num * point.descuento || 0) / 100;
  if (
    cm * currentPersonalization.factor <
    currentPersonalization.precio_minimo
  ) {
    return (
      currentPersonalization.precio_minimo -
      getDiscount(currentPersonalization.precio_minimo)
    );
  } else {
    return (
      cm * currentPersonalization.factor -
      getDiscount(cm * currentPersonalization.factor)
    );
  }
};
const ModalTableCotizacion = ({ setTotalCotizacion }) => {
  let { savedVariants: variants } = useContext(ProductPersonalizationContext);

  variants = variants.map((x) => {
    try {
      let filterArr = variants.filter(
        (variant) =>
          x.color === variant?.color &&
          x.cantidad === variant?.cantidad &&
          x.talla === variant?.talla &&
          x?.id_new_item === variant?.id_new_item
      );

      if (!filterArr.map((c) => c.points)[0]) return [];

      filterArr = filterArr.reduce((a, b) => [...a, ...b.points], []);

      return {
        ...x,
        points: filterArr,
      };
    } catch (error) {
      console.log(error);
    }
  });

  variants = variants.filter(
    (variant, index, self) =>
      index ===
      self.findIndex(
        (x) =>
          x?.color === variant?.color &&
          x?.cantidad === variant?.cantidad &&
          x?.talla === variant?.talla &&
          x?.id_new_item === variant?.id_new_item
      )
  );

  const getSubtotal = () => {
    try {
      const prodTotal = variants.reduce((a, b) => a + getTotalProduct(b), 0);
      const pointsTotal = variants
        .map((x) => {
          const pointTotal = x.points.reduce(
            (a, b) => a + calcTotal(x, b) * x.cantidad,
            0
          );
          return pointTotal;
        })
        .reduce((a, b) => a + b, 0);
      return prodTotal + pointsTotal;
    } catch (error) {}
  };
  const subtotal = getSubtotal();

  useEffect(() => {
    setTotalCotizacion(subtotal);
  }, [subtotal]);
  return (
    <div className="col-md-12 p-0" style={{ height: "478px" }}>
      <div style={{ height: "100%" }} className="table-responsive">
        <table className="col-md-12 table">
          <thead className="col-md-12" style={{ background: "#bdc3c7" }}>
            <tr>
              <th className="col-md-6" scope="col">
                Producto
              </th>
              <th className="col-md-2" scope="col">
                Cant.
              </th>
              <th className="col-md-2" scope="col">
                Desc.(%)
              </th>
              <th className="col-md-2" scope="col">
                Precio Total.
              </th>
            </tr>
          </thead>
          {variants?.map((variant) => (
            <Product variant={variant} />
          ))}
          <thead className="col-md-12" style={{ background: "#ecf0f1" }}>
            <tr>
              <th className="col-md-2" scope="col">
                <bold>Total</bold>
              </th>
              <th className="col-md-2" scope="col"></th>
              <th className="col-md-2" scope="col"></th>
              <th className="col-md-6" scope="col">
                {getMoneda()}. {numberFormater(subtotal)}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default ModalTableCotizacion;
