import React, { useState, useEffect, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import DataTable from "react-data-table-component";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { CSVLink, CSVDownload } from "react-csv";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import { getMoneda } from "../../helpers/moneda";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";

import moment from "moment";
import "moment/locale/es";
import { isCmi } from "../../helpers/isCmi";
import { getRetiroCaja } from "../../api/sales";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";
moment.locale("es");

// components

export default function Users_all(props) {
  const moneda = getMoneda();
  let history = useHistory();
  //cleap up - componentWillUnmount
  // states

  const [sucursal, setSucursal] = useState("");
  const [seller, setSeller] = useState("");
  const [caja, setCaja] = useState({
    descripcion: null,
  });

  const [corteDeCaja, setCorteDeCaja] = useState({
    total_efectivo: null,
    total_tarjeta: null,
    total_cambios: null,
    total_monto_total: null,
    total_anticipos: null,
    total_notas_credito: null,
    total_facturas: null,
    fecha: "",
    check: true,
  });
  const [loader, setLoader] = useState(true);
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [filterText, setFilterText] = useState([]);
  const [operationsTab, setOperationsTab] = useState(0);
  const [selectedAnulaciones, setSelectedAnulaciones] = useState([]);
  const [anulaciones, setAnulaciones] = useState([]);
  const [filteredAnulaciones, setFilteredAnulaciones] = useState([]);
  const [anulacionesFilterText, setAnulacionesFilterText] = useState("");
  const [notasCredito, setNotasCredito] = useState([]);
  const [filteredNotasCredito, setFilteredNotasCredito] = useState([]);
  const [anticipos, setAnticipos] = useState([]);
  const [filteredAnticipos, setFilteredAnticipos] = useState([]);
  const [selectedAnticipos, setSelectedAnticipos] = useState([]);
  const [notasCreditoFilterText, setNotasCreditoFilterText] = useState("");
  const [selectedNotasCredito, setSelectedNotasCredito] = useState([]);
  const [llevar, setLlevar] = useState({ total: 0, ventas: [] });
  const [comerLocal, setComerLocal] = useState({ total: 0, ventas: [] });
  const [domicilio, setDomicilio] = useState({ total: 0, ventas: [] });
  const { setCantidadRetiro, cantidadRetiro } = useContext(CmiProductContext);

  useEffect(() => {
    getCorte();
  }, []);

  // set states

  const getCorte = () => {
    let id = props.match.params.id.toString();
    console.log("Getting with this id", id);
    API.cajas
      .getCorteById({ id_caja_corte_web_app: id, cmi: isCmi() })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          if (
            response.data.fondo_cambios === 0 ||
            response.data.fondo_cambios === null
          ) {
            response.data.fondo_cambios = response.data.total_cambios;
          }
          console.log("Obtained this data:", response.data);
          setCantidadRetiro({
            total: response.data.retiro.reduce(
              (a, b) => a + b.cantidad_retiro,
              0
            ),
            cantidad: response.data.retiro.length,
            retiros: response.data.retiro,
          });
          setCorteDeCaja({ ...response.data, check: true });
          getSucursals({
            id_sucursal_bodega_ubicacion:
              response.data.id_sucursal_bodega_ubicacion,
            id_caja_web_app: response.data.id_caja_web_app,
            id_vendedor_web_app: response.data.id_vendedor,
          });

          setSales(response.data.ventas);
          if (!isCmi()) {
            const array_llevar = response.data.ventas.filter(
              (item) => item.tipo_venta === 0
            ).length;
            const total_llevar = response.data.ventas
              .filter((item) => item.tipo_venta === 0)
              .reduce((a, b) => a + b.monto_total, 0);
            const array_comerLocal = response.data.ventas.filter(
              (item) => item.tipo_venta === 1
            ).length;
            const total_comerLocal = response.data.ventas
              .filter((item) => item.tipo_venta === 1)
              .reduce((a, b) => a + b.monto_total, 0);

            const array_domicilio = response.data.ventas.filter(
              (item) => item.tipo_venta === 2
            ).length;
            const total_domicilio = response.data.ventas
              .filter((item) => item.tipo_venta === 2)
              .reduce((a, b) => a + b.monto_total, 0);
            setLlevar({
              ventas: array_llevar,
              total: total_llevar.toFixed(2),
            });
            setComerLocal({
              ventas: array_comerLocal,
              total: total_comerLocal.toFixed(2),
            });
            setDomicilio({
              ventas: array_domicilio,
              total: total_domicilio.toFixed(2),
            });
          }
          if (isCmi()) {
            const array_llevar = response.data.ventas.filter(
              (item) => item.tipo_venta === 3
            ).length;
            const total_llevar = response.data.ventas
              .filter((item) => item.tipo_venta === 3)
              .reduce((a, b) => a + b.monto_total, 0);
            const array_comerLocal = response.data.ventas.filter(
              (item) => item.tipo_venta === 4
            ).length;
            const total_comerLocal = response.data.ventas
              .filter((item) => item.tipo_venta === 4)
              .reduce((a, b) => a + b.monto_total, 0);

            const array_domicilio = response.data.ventas.filter(
              (item) => item.tipo_venta === 5
            ).length;
            const total_domicilio = response.data.ventas
              .filter((item) => item.tipo_venta === 5)
              .reduce((a, b) => a + b.monto_total, 0);
            setLlevar({
              ventas: array_llevar,
              total: total_llevar.toFixed(2),
            });
            setComerLocal({
              ventas: array_comerLocal,
              total: total_comerLocal.toFixed(2),
            });
            setDomicilio({
              ventas: array_domicilio,
              total: total_domicilio.toFixed(2),
            });
          }
          setFilteredSales(response.data.ventas);
          setAnulaciones(response.data.anulaciones);
          setFilteredAnulaciones(response.data.anulaciones);
          setNotasCredito(response.data.notasCredito);
          setFilteredNotasCredito(response.data.notasCredito);
          setAnticipos(response.data.anticipos);
          setFilteredAnticipos(response.data.anticipos);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);

        toast.error("Ha ocurrido un error", { autoClose: 10000 });

        setLoader(false);
      });
  };

  const filterSales = (e) => {
    setFilterText(e.target.value);
    setFilteredSales(
      sales.filter((item) => item.identificador.includes(e.target.value))
    );

    if (e.target.value === "") {
      setFilteredSales(sales);
    }
  };

  const gotoInvoice = () => {
    let orders = corteDeCaja?.ventas?.map((item) => item.orderItems);
    let flattenOrders = [].concat.apply([], orders);

    localStorage.setItem(
      "corte_details",
      JSON.stringify({
        sucursal,
        caja,
        vendedor: seller,
        corte_data: corteDeCaja,
        sales: corteDeCaja.ventas,
        llevar,
        comerLocal,
        domicilio,
        retiros: corteDeCaja.retiro,
        total_gifcard: corteDeCaja.total_gifcard
          ? corteDeCaja.total_gifcard.toFixed(2)
          : 0,
        ordenes: flattenOrders,
        total_anticipos: corteDeCaja.total_anticipos
          ? corteDeCaja.total_anticipos
          : 0,
      })
    );
    const newWindow = window.open(
      "/#/app/cortes_caja/recibo",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const getSucursals = (data) => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success && JSON.stringify(response.data) != "[]") {
          let currentSucursal = response.data.find(
            (x) =>
              x.id_sucursal_bodega_ubicacion ===
              data.id_sucursal_bodega_ubicacion
          );
          setSucursal(currentSucursal);
          getCajas(data);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getCajas = (data) => {
    API.cajas
      .getCajas({
        id_sucursal_bodega_ubicacion: data.id_sucursal_bodega_ubicacion,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          let currentCaja = response.data.find(
            (x) => x.id_caja_web_app === data.id_caja_web_app
          );
          setCaja(currentCaja);
          getSellers(data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSellers = (data) => {
    API.cajas
      .getVendedores({ id_caja_web_app: data.id_caja_web_app })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          let currentSeller = response.data.find(
            (x) => x.id_vendedor_web_app === data.id_vendedor_web_app
          );
          setSeller(currentSeller);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;
    let data = [];
    if (operationsTab === 0) {
      selectedRows.map((row) =>
        data.push({
          identificador: row.identificador,
          fecha: moment(row.fecha).format("DD-MM-YYYY HH:mm:ss a"),
          nombre_cliente: row.client.nombre,
          nit_cliente: row.client.nit,
          monto_total: row.monto_total,
          numero_productos: row.numero_productos,
        })
      );
      setSelectedSales(data);
    }

    if (operationsTab === 1) {
      selectedRows.map((row) =>
        data.push({
          identificador: row.identificador,
          fecha: moment(row.fecha).utcOffset(0).format("DD-MM-YYYY HH:mm:ss a"),
          nombre_cliente: row.client.nombre,
          nit_cliente: row.client.nit,
          monto_total: row.monto_total,
          numero_productos: row.numero_productos,
        })
      );
      setSelectedAnulaciones(data);
    }

    if (operationsTab === 2) {
      selectedRows.map((row) =>
        data.push({
          identificador: row.identificador,
          fecha: moment(row.fecha).utcOffset(0).format("DD-MM-YYYY HH:mm:ss a"),
          nombre_cliente: row.client.nombre,
          nit_cliente: row.client.nit,
          monto_total: row.monto,
          numero_productos: row.numero_productos,
        })
      );
      setSelectedNotasCredito(data);
    }

    if (operationsTab === 3) {
      selectedRows.map((row) =>
        data.push({
          numero_anticipo: row.nro_anticipo,
          fecha: moment(row.fecha).utcOffset(0).format("DD-MM-YYYY HH:mm:ss a"),
          nombre_cliente: row.client.nombre,
          nit_cliente: row.client.nit,
          monto_total: row.monto,
          concepto: row.concepto,
        })
      );
      setSelectedAnticipos(data);
    }
  };

  const handleOperationsTab = (event, newValue) => {
    setOperationsTab(newValue);
  };

  const filterAnulaciones = (e) => {
    setAnulacionesFilterText(e.target.value);
    setFilteredAnulaciones(
      anulaciones.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredAnulaciones(anulaciones);
    }
  };

  const filterNotasCredito = (e) => {
    setNotasCreditoFilterText(e.target.value);
    setFilteredNotasCredito(
      notasCredito.filter((item) =>
        item.identificador.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredNotasCredito(notasCredito);
    }
  };

  const salesAction = (identificador, nit, id_pos_movimiento) => {
    const newWindow = window.open(
      "/#/app/vender/resumen/" + id_pos_movimiento,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const handleRetiroData = async () => {
    const id_new_empresa = localStorage.getItem("id_empresa");

    try {
      const { data } = await getRetiroCaja({
        id_caja_web_app: caja.id_caja_web_app,
        id_sucursal_bodega_ubicacion: corteDeCaja.id_sucursal_bodega_ubicacion,
        id_vendedor_web_app: corteDeCaja.id_vendedor,
        id_new_empresa,
        retiro_fecha: moment(corteDeCaja.fecha).format("YYYY-MM-DD"),
      });

      // setCantidadRetiro({
      //   total: data.dataRetiroCaja.reduce((a, b) => a + b.cantidad_retiro, 0),
      //   cantidad: data.dataRetiroCaja.length,
      //   retiros: data.dataRetiroCaja,
      // });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (corteDeCaja.check && caja.descripcion) {
  //     handleRetiroData();
  //   }
  // }, [corteDeCaja, caja]);

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined">
          <Select native disabled>
            <option value="">{sucursal.nombre_sucursal}</option>
          </Select>
        </FormControl>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <FormControl variant="outlined">
          <Select native disabled>
            <option value="">{caja.descripcion}</option>
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native disabled>
            <option value="">{seller?.nombre}</option>
          </Select>
        </FormControl>
      </div>

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex align-items-center flex-wrap">
              <div className="mb-2">
                <p className="mb-0">
                  <strong>Cierre de caja</strong>
                </p>
                <p className="mb-0">
                  Consulte y cierre las operaciones realizadas en su caja.
                </p>
              </div>

              <div className="mx-sm-2 mb-2">
                <p className="mb-0">Fecha</p>
                {loader ? (
                  <div className="text-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                ) : (
                  <div className="d-flex align-items-center flex-wrap">
                    <TextField
                      type="date"
                      className="w-100"
                      value={moment(corteDeCaja.fecha).format("YYYY-MM-DD")}
                      disabled
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {loader ? (
          <div className="text-center">
            <CircularProgress size={26} className="mx-auto" />
          </div>
        ) : (
          <div className="card-body">
            <div className="row mt-2">
              <div
                className="col-md-3 px-2"
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Facturación total
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {corteDeCaja.monto_total
                      ? corteDeCaja.monto_total.toFixed(2)
                      : 0}
                  </strong>
                </h6>
              </div>
              <div
                className="col-md-3 px-2"
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Fondo para cambios
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {corteDeCaja.total_cambios
                      ? corteDeCaja.total_cambios.toFixed(2)
                      : 0}
                  </strong>
                </h6>
              </div>
              <div className="col-md-3 px-2">
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Efectivo
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {corteDeCaja.total_efectivo
                      ? corteDeCaja.total_efectivo.toFixed(2)
                      : 0}
                  </strong>
                </h6>
              </div>
              <div
                className="col-md-3 px-2 "
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Tarjeta de débito/créd.
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {corteDeCaja.total_tarjetas
                      ? corteDeCaja.total_tarjetas.toFixed(2)
                      : 0}
                  </strong>
                </h6>
              </div>

              <div
                style={{
                  height: "12px",
                  width: "100%",
                  backgroundColor: "#E0E0E0;",
                }}
              />
              {/* second row */}

              <div
                className="col-md-3 px-2"
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Facturas a crédito
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {corteDeCaja.total_facturas_credito
                      ? corteDeCaja.total_facturas_credito.toFixed(2)
                      : 0}
                  </strong>
                </h6>
              </div>
              <div className="col-md-3 px-2">
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Anticipos
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {corteDeCaja.total_anticipos
                      ? corteDeCaja.total_anticipos.toFixed(2)
                      : 0}
                  </strong>
                </h6>
              </div>
              <div
                className="col-md-3 px-2 "
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  Notas de crédito
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{" "}
                    {corteDeCaja.total_notas_credito
                      ? corteDeCaja.total_notas_credito.toFixed(2)
                      : 0}
                  </strong>
                </h6>
              </div>
              <div
                className="col-md-3 px-2 "
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  N° Ventas Por {!isCmi() ? "Domicilio" : "Entrega"}
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{`${domicilio.total} | ${domicilio.ventas}`}
                  </strong>
                </h6>
              </div>
              <div
                className="col-md-3 px-2 "
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  N° Ventas {!isCmi() ? "Para Llevar" : "Presencial"}
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}. {`${llevar.total} | ${llevar.ventas}`}
                  </strong>
                </h6>
              </div>
              <div
                className="col-md-3 px-2 "
                style={{ borderRight: "1px solid #E0E0E0" }}
              >
                <p
                  className="mb-1"
                  style={{
                    fontSize: "0.85rem",
                    color: "black",
                  }}
                >
                  N° Ventas {!isCmi() ? "Consumo Local" : "por Pick-up"}
                </p>
                <h6 className="mb-0 secondary_color">
                  <strong>
                    {moneda}.{`${comerLocal.total} | ${comerLocal.ventas}`}
                  </strong>
                </h6>
              </div>
              {isCmi() && (
                <div
                  className="col-md-3 px-2 "
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Retiros de efectivo
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>
                      {moneda}.{" "}
                      {`${cantidadRetiro.total.toFixed(2)} | ${
                        cantidadRetiro.cantidad
                      }`}
                    </strong>
                  </h6>
                </div>
              )}
              {isCmi() && (
                <div
                  className="col-md-3 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "0.85rem",
                      color: "black",
                    }}
                  >
                    Vale
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>
                      {moneda}.{" "}
                      {corteDeCaja.total_gifcard
                        ? corteDeCaja.total_gifcard.toFixed(2)
                        : 0}
                    </strong>
                  </h6>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
              <h4 className="mb-0">
                <strong>Historial de ventas</strong>
              </h4>

              <Button
                className="btn-cs1 mt-2"
                variant="contained"
                color="primary"
                onClick={gotoInvoice}
              >
                Recibo
              </Button>
            </div>

            <Tabs
              className="mt-5"
              value={operationsTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleOperationsTab}
            >
              <Tab className="tab" label="Ventas" />
              <Tab className="tab" label="Anulaciones" />
              <Tab className="tab" label="Notas de crédito" />
              <Tab className="tab" label="Anticipos" />
            </Tabs>
            <TabPanel value={operationsTab} index={0} className="config-tab">
              <>
                <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
                  <h4 className="mb-0">
                    <strong>Historial de ventas</strong>
                  </h4>

                  {selectedSales.length > 0 ? (
                    <CSVLink
                      data={selectedSales}
                      filename={
                        ("Ventas de la caja " + corteDeCaja.caja,
                        " del día " +
                          moment(corteDeCaja.fecha).format("DD-MM-YYYY") +
                          ".csv")
                      }
                    >
                      <Button
                        className="btn-cs1"
                        variant="contained"
                        color="primary"
                      >
                        Descargar
                        <CloudDownloadIcon className="ml-2" />
                      </Button>
                    </CSVLink>
                  ) : (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                    >
                      Descargar
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  )}
                </div>

                <div class="row justify-content-end mt-3 mb-3">
                  <div className="col-md-4">
                    <div class="input-group search-bar">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar por número de operación"
                        value={filterText}
                        onChange={(event) => filterSales(event)}
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="products-table user-table">
                  <DataTable
                    className="datatable-cs datatable-cs-v2"
                    title={"s"}
                    columns={[
                      {
                        name: "N° Factura",
                        selector: "identificador",
                        sortable: true,
                      },
                      {
                        name: "Fecha / Hora",
                        selector: "fecha",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {moment(row.fecha)
                              .utcOffset(0)
                              .format("DD/MM/YYYY, h:mm:ss a")}
                          </div>
                        ),
                      },
                      {
                        name: "Nombre del cliente",
                        selector: "client.nombre",
                        sortable: true,
                      },
                      {
                        name: "NIT",
                        selector: "client.nit",
                        sortable: true,
                      },
                      {
                        name: "N° productos",
                        selector: "numero_productos",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {row.numero_productos}
                          </div>
                        ),
                      },

                      {
                        name: "Monto",
                        selector: "monto_total",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}. {row.monto_total}{" "}
                          </span>
                        ),
                      },
                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="text-center w-100">
                            <Button
                              className="btn-cs1"
                              variant="contained"
                              color="primary"
                              endIcon={<ArrowForwardIosIcon />}
                              onClick={() =>
                                salesAction(
                                  row.identificador,
                                  row.client.nit,
                                  row.id_pos_movimiento
                                )
                              }
                              size="small"
                            >
                              <span
                                style={{
                                  marginBottom: "-4px",
                                  fontSize: "12px",
                                }}
                              >
                                Ver{" "}
                                <span
                                  className="d-none d-xl-inline"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  detalles
                                </span>
                              </span>
                            </Button>
                          </div>
                        ),
                      },
                    ]}
                    data={filteredSales}
                    defaultSortField="fecha"
                    defaultSortAsc={false}
                    selectableRows
                    noDataComponent="No hay ventas pendientes"
                    onSelectedRowsChange={handleSelect}
                  />
                </div>
              </>
            </TabPanel>

            <TabPanel value={operationsTab} index={1} className="config-tab">
              <>
                <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
                  <h4 className="mb-0">
                    <strong>Historial de anulaciones</strong>
                  </h4>

                  {selectedAnulaciones.length > 0 ? (
                    <CSVLink
                      data={selectedAnulaciones}
                      filename={
                        ("Anulaciones de la caja " + corteDeCaja.caja,
                        " del día " +
                          moment(corteDeCaja.fecha).format("DD-MM-YYYY") +
                          ".csv")
                      }
                    >
                      <Button
                        className="btn-cs1"
                        variant="contained"
                        color="primary"
                      >
                        Descargar
                        <CloudDownloadIcon className="ml-2" />
                      </Button>
                    </CSVLink>
                  ) : (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                    >
                      Descargar
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  )}
                </div>

                <div class="row justify-content-end mt-3 mb-3">
                  <div className="col-md-4">
                    <div class="input-group search-bar">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar por número de operación"
                        value={anulacionesFilterText}
                        onChange={(event) => filterAnulaciones(event)}
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="products-table user-table">
                  <DataTable
                    className="datatable-cs datatable-cs-v2"
                    title={"s"}
                    columns={[
                      {
                        name: "N° Factura",
                        selector: "identificador",
                        sortable: true,
                      },
                      {
                        name: "Fecha / Hora",
                        selector: "fecha",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {moment(row.fecha)
                              .utcOffset(0)
                              .format("DD/MM/YYYY, h:mm:ss a")}
                          </div>
                        ),
                      },
                      {
                        name: "Nombre del cliente",
                        selector: "client.nombre",
                        sortable: true,
                      },
                      {
                        name: "NIT",
                        selector: "client.nit",
                        sortable: true,
                      },
                      {
                        name: "N° productos",
                        selector: "numero_productos",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {row.numero_productos}
                          </div>
                        ),
                      },

                      {
                        name: "Monto",
                        selector: "monto_total",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}. {row.monto_total}{" "}
                          </span>
                        ),
                      },
                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="text-center w-100">
                            <Button
                              className="btn-cs1"
                              variant="contained"
                              color="primary"
                              endIcon={<ArrowForwardIosIcon />}
                              onClick={() =>
                                salesAction(
                                  row.identificador,
                                  row.client.nit,
                                  row.id_pos_movimiento
                                )
                              }
                              size="small"
                            >
                              <span
                                style={{
                                  marginBottom: "-4px",
                                  fontSize: "12px",
                                }}
                              >
                                Ver{" "}
                                <span
                                  className="d-none d-xl-inline"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  detalles
                                </span>
                              </span>
                            </Button>
                          </div>
                        ),
                      },
                    ]}
                    data={filteredAnulaciones}
                    defaultSortField="fecha"
                    defaultSortAsc={false}
                    selectableRows
                    noDataComponent="No hay anulaciones pendientes"
                    onSelectedRowsChange={handleSelect}
                  />
                </div>
              </>
            </TabPanel>

            <TabPanel value={operationsTab} index={2} className="config-tab">
              <>
                <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
                  <h4 className="mb-0">
                    <strong>Historial de notas de crédito</strong>
                  </h4>

                  {selectedNotasCredito.length > 0 ? (
                    <CSVLink
                      data={selectedNotasCredito}
                      filename={
                        ("Anulaciones de la caja " + corteDeCaja.caja,
                        " del día " +
                          moment(corteDeCaja.fecha).format("DD-MM-YYYY") +
                          ".csv")
                      }
                    >
                      <Button
                        className="btn-cs1"
                        variant="contained"
                        color="primary"
                      >
                        Descargar
                        <CloudDownloadIcon className="ml-2" />
                      </Button>
                    </CSVLink>
                  ) : (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                    >
                      Descargar
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  )}
                </div>

                <div class="row justify-content-end mt-3 mb-3">
                  <div className="col-md-4">
                    <div class="input-group search-bar">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar por número de operación"
                        value={notasCreditoFilterText}
                        onChange={(event) => filterNotasCredito(event)}
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="products-table user-table">
                  <DataTable
                    className="datatable-cs datatable-cs-v2"
                    title={"s"}
                    columns={[
                      {
                        name: "N° Factura",
                        selector: "identificador",
                        sortable: true,
                      },
                      {
                        name: "Fecha / Hora",
                        selector: "fecha",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {moment(row.fecha)
                              .utcOffset(0)
                              .format("DD/MM/YYYY, h:mm:ss a")}
                          </div>
                        ),
                      },
                      {
                        name: "Nombre del cliente",
                        selector: "client.nombre",
                        sortable: true,
                      },
                      {
                        name: "NIT",
                        selector: "client.nit",
                        sortable: true,
                      },
                      {
                        name: "N° productos",
                        selector: "numero_productos",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {row.numero_productos}
                          </div>
                        ),
                      },

                      {
                        name: "Monto",
                        selector: "monto",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}. {row.monto}{" "}
                          </span>
                        ),
                      },
                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="text-center w-100">
                            <Button
                              className="btn-cs1"
                              variant="contained"
                              color="primary"
                              endIcon={<ArrowForwardIosIcon />}
                              component={Link}
                              to={`/app/operaciones_historial/nota_credito/${row.id_nota_credito_web_app}`}
                              size="small"
                            >
                              <span
                                style={{
                                  marginBottom: "-4px",
                                  fontSize: "12px",
                                }}
                              >
                                Ver{" "}
                                <span
                                  className="d-none d-xl-inline"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  detalles
                                </span>
                              </span>
                            </Button>
                          </div>
                        ),
                      },
                    ]}
                    data={filteredNotasCredito}
                    defaultSortField="fecha"
                    defaultSortAsc={false}
                    selectableRows
                    noDataComponent="No hay notas de credito pendientes"
                    onSelectedRowsChange={handleSelect}
                  />
                </div>
              </>
            </TabPanel>

            <TabPanel value={operationsTab} index={3} className="config-tab">
              <>
                <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap mb-2">
                  <h4 className="mb-0">
                    <strong>Historial de anticipos</strong>
                  </h4>

                  {selectedAnticipos.length > 0 ? (
                    <CSVLink
                      data={selectedAnticipos}
                      filename={
                        ("Anticipos de la caja " + corteDeCaja.caja,
                        " del día " +
                          moment(corteDeCaja.fecha).format("DD-MM-YYYY") +
                          ".csv")
                      }
                    >
                      <Button
                        className="btn-cs1"
                        variant="contained"
                        color="primary"
                      >
                        Descargar
                        <CloudDownloadIcon className="ml-2" />
                      </Button>
                    </CSVLink>
                  ) : (
                    <Button
                      className="btn-cs1"
                      variant="contained"
                      color="primary"
                    >
                      Descargar
                      <CloudDownloadIcon className="ml-2" />
                    </Button>
                  )}
                </div>

                <div className="products-table user-table">
                  <DataTable
                    className="datatable-cs datatable-cs-v2"
                    title={"s"}
                    columns={[
                      {
                        name: "N°",
                        selector: "nro_anticipo",
                        sortable: true,
                      },
                      {
                        name: "Fecha / Hora",
                        selector: "fecha",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {moment(row.fecha)
                              .utcOffset(0)
                              .format("DD/MM/YYYY, h:mm:ss a")}
                          </div>
                        ),
                      },
                      {
                        name: "Nombre del cliente",
                        selector: "client.nombre",
                        sortable: true,
                      },
                      {
                        name: "NIT",
                        selector: "client.nit",
                        sortable: true,
                      },
                      {
                        name: "Concepto",
                        selector: "concepto",
                        sortable: true,
                        cell: (row) => (
                          <div className="text-center w-100">
                            {row.concepto}
                          </div>
                        ),
                      },

                      {
                        name: "Monto",
                        selector: "monto",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}. {row.monto}{" "}
                          </span>
                        ),
                      },
                      {
                        name: "Acciones",
                        selector: "action",
                        cell: (row) => (
                          <div className="text-center w-100">
                            <Button
                              className="btn-cs1"
                              variant="contained"
                              color="primary"
                              endIcon={<ArrowForwardIosIcon />}
                              onClick={() =>
                                history.push({
                                  pathname: "/app/anticipo/resumen",
                                  state: {
                                    id_cliente_acreedor_web_app:
                                      row.id_cliente_acreedor_web_app,
                                  },
                                })
                              }
                            >
                              <span
                                style={{
                                  marginBottom: "-4px",
                                  fontSize: "12px",
                                }}
                              >
                                Ver{" "}
                                <span
                                  className="d-none d-xl-inline"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  detalles
                                </span>
                              </span>
                            </Button>
                          </div>
                        ),
                      },
                    ]}
                    data={filteredAnticipos}
                    defaultSortField="fecha"
                    defaultSortAsc={false}
                    selectableRows
                    noDataComponent="No hay anticipos pendientes"
                    onSelectedRowsChange={handleSelect}
                  />
                </div>
              </>
            </TabPanel>
          </div>
        )}
      </div>
      {/* Modal */}
    </div>
  );
}
