import {
  Backdrop,
  Button,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState, useEffect, useContext } from "react";
import InfoIcon from "@material-ui/icons/Info";
import { ReportProblemOutlined } from "@material-ui/icons";
import ModalAutorizacion from "./ModalAutorizacion";
import CmiProductContext from "../../context/CmiProductContext/CmiProductContext";
import { getMoneda } from "../../helpers/moneda";

const ModalCorteAutorizacion = ({
  submit_form,

  gerentes,

  row,

  check,
  header,
  warning,

  infotip,
}) => {
  const {
    activarModalCorte,
    setActivarModalCorte,

    setRetiroData,
    retiroData,
  } = useContext(CmiProductContext);
  const moneda = getMoneda();
  const { setMovimiento } = useContext(CmiProductContext);
  const [gerente, setGerente] = useState(null);
  const [motivo, setMotivo] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    // setMovimiento(row);

    setActivarModalCorte(true);
  };

  const handleClose = () => {
    setOpen(false);

    setActivarModalCorte(false);
  };
  const handleGerente = (e) => {
    const currentGerente = gerentes.find(
      (item) => item.user.id_new_user === +e.target.value
    );
    setGerente(currentGerente?.user);
  };
  useEffect(() => {
    if (activarModalCorte) {
      handleOpen();
    } else {
      handleClose();
    }
    return () => {
      setActivarModalCorte(false);
    };
  }, [activarModalCorte]);

  return (
    <div>
      <Button
        className="btn-cs1"
        color="primary"
        style={{
          width: "150px",
          height: "43px",
          boxShadow: "none",
        }}
        onClick={handleOpen}
      >
        <span
          style={{
            color: "#fff",
            fontSize: "14px",
          }}
        >
          Realizar corte
        </span>
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={activarModalCorte}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={activarModalCorte}>
          <div
            div
            className="card"
            style={{ maxWidth: "577px", border: "none" }}
          >
            <div className="card-title d-flex justify-content-between align-items-center border-bottom py-3">
              <strong style={{ marginLeft: "17px" }}>
                <span style={{ fontSize: "20px" }}>{header}</span>
              </strong>
              <IconButton
                type="button"
                onClick={handleClose}
                style={{ marginRight: "17px" }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Gerente</strong>
                    </label>
                    <Tooltip title={infotip}>
                      <InfoIcon
                        style={{ color: " #828282", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  </div>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      native
                      style={{ height: "32px" }}
                      onChange={handleGerente}
                    >
                      <option value="">Seleccionar</option>
                      {gerentes?.map((gerente) => (
                        <option value={gerente.user.id_new_user}>
                          {gerente.user.nombre}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="col-md-12 mt-4 px-0">
                <div
                  className="row"
                  style={{
                    background: "#F2F2F2",
                    margin: "0px",
                    padding: "15px",
                    borderRadius: "8px",
                  }}
                >
                  <div className="col-1 d-flex align-items-center justify-content-center">
                    <span>
                      <ReportProblemOutlined
                        style={{ color: "#0160E7", fontSize: "30px" }}
                      />
                    </span>
                  </div>
                  <div className="col-11 d-flex align-items-center">
                    <span>{warning}</span>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center mt-2">
                &nbsp;
                {/* <Button
                  variant="contained"
                  style={{
                    background: true ? "#0160E7" : "#BDBDBD",
                    boxShadow: "none",
                    width: "150px",
                    height: "42px",
                  }}
                  onClick={() => submit_form(currentMovement)}
                >
                  <span style={{ color: "#fff", fontSize: "14px" }}>
                    Aceptar
                  </span>
                </Button> */}
                <ModalAutorizacion
                  gerente={gerente}
                  motivo={motivo}
                  currentMovement={row}
                  submit_form={submit_form}
                  check={check}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalCorteAutorizacion;
