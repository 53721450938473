// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useForm } from "react-hook-form";
import API from "../../api";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { isAleko } from "../../helpers/isAleko";
import { isCmi } from "../../helpers/isCmi";
import { Box, CircularProgress } from "@material-ui/core";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import { getMoneda } from "../../helpers/moneda";
import { TextareaAutosize } from "@material-ui/core";

// components

let ingreso_types = [
  {
    value: "producto",
    descripcion: "Ingreso de productos",
  },
  {
    value: "insumo",
    descripcion: "Ingreso de insumos",
  },
];

export default function Users_all(props) {
  const moneda = getMoneda();
  const aleko = isAleko();

  const [idIngreso, setIdIngreso] = useState(null);
  const moment = require("moment");
  const AutosuggestHighlightMatch = require("autosuggest-highlight/match");
  const AutosuggestHighlightParse = require("autosuggest-highlight/parse");
  // states
  const [product_inputs, setProduct_inputs] = useState([]);
  const { register, handleSubmit } = useForm();
  const [productsForm, setProductsForm] = useState(false);
  const [sucursals, setSucursals] = useState([]);
  const [ingresosMotives, setIngresosMotives] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allSupplies, setAllSupplies] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [currentMotivo, setCurrentMotivo] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const [ingresoType, setIngresoType] = useState(ingreso_types[0].value);
  const [comentario, setComentario] = useState("");
  const [loader, setLoader] = useState(false);
  // This is another loader state
  //for the 
  const [isLoaded, setIsLoaded] = useState(false);

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  //component did mount
  useEffect(() => {
    getSucursals();
    get_ingresosMotives();
    get_documentTypes();
    getProducts();
    getSupplies();
  }, []);

  useEffect(() => { 
    if (props.history.location.state) {
      displayProductsForm();

      setProduct_inputs(
        [
          {
            codigo: props.history.location.state.products[0]?.codigo,
            cantidad: 1,
            cantidad_peso:
              props.history.location.state.products[0]?.vendido_por === "mixto"
                ? 1
                : 0,
            vendido_por: props.history.location.state.products[0]?.vendido_por,
            cantidad_pieza: 0,
            lote: props.history.location.state.products[0]?.lote,
            fecha: props.history.location.state.products[0]?.vencimiento_fecha,
            costo_unitario:
              props.history.location.state.products[0]?.costo_unitario,
            precio_actualizado:
              props.history.location.state.products[0]?.precio_venta,
            flag_insumo: props.history.location.state.products[0]?.flag_insumo,
            nombre: props.history.location.state.products[0]?.nombre,
          },
        ]
      );
      if (props.history.location.state.products[0]?.flag_insumo) {
        setIngresoType("insumo");
      } /* else { // Producto is the default state
                setIngresoType('producto')
            } */
    }
  }, [props.history.location.state])

  useEffect(() => {
    if (ingresoType !== "") {
      switch (ingresoType) {
        case "producto":
          setSelectedElements(allProducts);
          break;
        case "insumo":
          setSelectedElements(allSupplies);
          break;

        default:
          break;
      }
    }
  }, [allProducts, allSupplies]);

  useEffect(() => {
    if (ingresoType !== "") {
      switch (ingresoType) {
        case "producto":
          setSelectedElements(allProducts);
          break;
        case "insumo":
          setSelectedElements(allSupplies);
          break;

        default:
          break;
      }
    }

    // if (Array.isArray(allProducts) & Array.isArray(allSupplies)) {
    //   // If they were already loaded
    //   setProduct_inputs([]);
    // }
  }, [ingresoType]);

  // set states
  /*  const changeSucursal = (event) => {
         const sucursalName = event.target.value;
         setSucursal(sucursalName);
     }; */

  //function
  const add_inputs = () => {
    setProduct_inputs(
      product_inputs.concat([
        {
          codigo: "",
          cantidad: "",
          cantidad_peso: "",
          cantidad_piezas: "",
          lote: "",
          vencimiento_fecha: "",
          costo_unitario: "",
          precio_actualizado: "",
          flag_insumo: "",
        },
      ])
    );
  };
  const remove_input = (i) => {
    console.log(i);
    setProduct_inputs(product_inputs.filter((e, index) => index !== i));
  };
  const onSubmit = (data) => {
    let checkData = false;
    setLoader(true);
    for (let key in data) {
      if (data[key] === "") {
        toast.error("Debe llenar todos los campos", {
          autoClose: 10000,
        });
        setLoader(false);
        checkData = true;

        break;
      }
    }

    if (!checkData) {
      let formData = {
        id_new_user: data.id_new_user,
        numero_factura: data.numero_factura,
        fecha_ingreso: new Date(),
        id_new_motivo: data.id_new_motivo,
        id_new_tipo_documento: data.id_tipo_documento_web_app,
        id_sucursal_bodega_ubicacion: data.id_sucursal_bodega_ubicacion,
        products: [],
      };
      if (!data.product_code) {
        toast.error("Debe agregar un producto", {
          autoClose: 10000,
        });
        setLoader(false);
        return;
      }
      var errorsProducts = false;
      data.product_code.forEach((element, i) => {
        let raw_data = {
          codigo: data.product_code[i],
          cantidad: data.product_qty[i],
          lote: data.product_lote[i],
          vencimiento_fecha: data.fecha_vencimiento[i],
          cantidad_peso: data.product_weight[i],
          cantidad_piezas: data.product_piezas[i],
          precio_costo: data.flag_insumo[i] == "false" ? data.product_costo[i] : 0 ,
        };

        var flagInsumo = false;
        if (data.flag_insumo[i] == "true") {
          flagInsumo = true;
        } else {
          flagInsumo = false;
        }

        if (!flagInsumo) {
          raw_data.precio = data.precio_actualizado[i];
        }
        if (!raw_data.cantidad && !raw_data.cantidad_peso) {
          errorsProducts = true;
        }
        formData.products.push(raw_data);
      });
      if (errorsProducts) {
        toast.error("Uno o mas productos no tienen cantidad o cantidad peso", {
          autoClose: 10000,
        });
        setLoader(false);
        return;
      }
      let codeMismatch = false;
      let products_inputted = formData.products;

      let allUserProducts = selectedElements;
      let inputted_products_codes = [];
      products_inputted.map((product) => {
        inputted_products_codes.push(product.codigo);
      });
      let all_product_codes = [];

      for (let product of allUserProducts) {
        all_product_codes.push(product.codigo);
      }
      for (let product of inputted_products_codes) {
        if (!all_product_codes.includes(product)) {
          codeMismatch = true;
          console.log(product, " is not included in ");
          console.log(all_product_codes);
        }
      }

      if (codeMismatch) {
        setLoader(false);
        toast.error(
          "Al menos uno de los códigos que introdujo no fue encontrado en su inventario.",
          {
            autoClose: 10000,
          }
        );
      } // this else case has to be updated when get-products is fixed and i know what data is returns
      else {
        for (let product of products_inputted) {
          let product_fromDB = allUserProducts.find(
            (x) => x.codigo === product.codigo
          );
          product.id_new_item = product_fromDB.id_new_item;
          product.cantidad = parseFloat(product.cantidad);
          product.cantidad_peso = parseFloat(product.cantidad_peso);
          product.cantidad_piezas = parseFloat(product.cantidad_piezas);
        }
        formData.products = JSON.stringify(products_inputted);
        formData.comentario = comentario;
        submitIngreso(formData);
      }
    }
  };

  const displayProductsForm = () => {
    setProductsForm(!productsForm);
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        setLoader(false);
        let response = res.data;
        console.log(response);
        if (response.success) {
          setSucursals(response.data);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        let response = err.response.data;
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };
  const get_ingresosMotives = () => {
    API.ingresos
      .getMotives()
      .then((res) => {
        let response = res.data;
        if (response.success && JSON.stringify(response.data) !== "[]") {
          setIngresosMotives(response.data);
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };
  const get_documentTypes = () => {
    API.documentTypes
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success && JSON.stringify(response.data) !== "[]") {
          setDocumentTypes(response.data);
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };
  let currentSucursal = JSON.parse(
    localStorage.getItem("store")
  )?.id_sucursal_bodega_ubicacion;
  const submitIngreso = (data) => {
    API.ingresos
      .add_new(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setIdIngreso(response.ingreso.id_ingreso_web_app);
          $("#save").modal();
        }
      })
      .catch((err) => {
        console.log(err);
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setLoader(false);
      });
  };

  const getProducts = async (value = "") => {
    if (isLoaded) {
      return;
    }
    setIsLoaded(true);
    API.products
      .getAll({
        nombre: value || filterText,
        id_sucursal_bodega_ubicacion: currentSucursal,
        no_combos: 1,
        pagination: true,
        page: 1,
        limit: 10,
      })
      .then((res) => {
        let response = res.data;

        if (response.success) {
          setAllProducts(response.data);
        }
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getSupplies = () => {
    API.supplies
      .getAll({
        id_sucursal_bodega_ubicacion: currentSucursal,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Im obtaining these insumos:", response.data);
          setAllSupplies(response.data.insumos);
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        console.log("Ha ocurrido un error / Búsqueda de insumos");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getSuggestions = (value = "") => {
    if (!Array.isArray(selectedElements)) {
      getProducts(value);
      return [];
    }

    const inputValue = document
      .getElementById("product_query")
      .value.trim()
      .toLowerCase();
    const inputLength = inputValue.length;

    var dataProduct =
      inputLength === 0
        ? []
        : selectedElements
            .filter(
              (product) =>
                product.nombre.toLowerCase().includes(inputValue) ||
                product.codigo.toLowerCase().includes(inputValue)
            )
            .filter((product) => !product.combo)
            .filter((product) => !product.id_padre)
            .slice(0, 10);

    if (dataProduct.length == 0) {
      getProducts(value);
    }
    return dataProduct;
  };

  const getSuggestionValue = (suggestion) => {
    let existingData = [...product_inputs];
    if (product_inputs.some((x) => suggestion.codigo === x.codigo)) {
      return suggestion.nombre;
    }
    setProduct_inputs(
      existingData.concat([
        {
          codigo: suggestion.codigo,
          nombre: suggestion.nombre,
          cantidad: "",
          cantidad_peso: "",
          cantidad_piezas: 0,
          lote: suggestion.lote,
          costo_unitario: suggestion.costo_unitario,
          vencimiento_fecha: suggestion.vencimiento_fecha,
          flag_insumo: suggestion.flag_insumo,
          vendido_por: suggestion.vendido_por,
        },
      ])
    );
    return suggestion.nombre;
  };

  const renderSuggestion = (suggestion) => {
    const matches = AutosuggestHighlightMatch(suggestion.nombre, filterText);
    const parts = AutosuggestHighlightParse(suggestion.nombre, matches);

    return (
      <div>
        {parts.map((part, index) => {
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  const changeFilterText = (e, { newValue }) => {
    setFilterText(newValue);
  };

  const onSuggestionsFetchRequested = (e = {}) => {
    setSuggestions(getSuggestions(e.value));
  };

  const onSuggestionsClearRequested = () => {
    console.log("===========3");
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Escribe el nombre del producto/insumo",
    value: filterText,
    onChange: changeFilterText,
    id: "product_query",
  };

  const handleInputChange = (name, index, event) => {
    let value = event.target.value;

    if (name === "codigo") {
      let existingData = [...product_inputs];
      existingData[index].codigo = value;
      setProduct_inputs(existingData);
    }
    if (name === "cantidad") {
      event.target.value =
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
            value.substr(value.indexOf("."), 3)
          : value;
      let parsedValue = isNaN(parseFloat(event.target.value))
        ? ""
        : Math.abs(parseFloat(event.target.value));
      event.target.value = isNaN(parseFloat(event.target.value))
        ? ""
        : event.target.value;
      let existingData = [...product_inputs];
      existingData[index].cantidad = parsedValue;
      setProduct_inputs(existingData);
    }
    if (name === "lote") {
      let existingData = [...product_inputs];
      existingData[index].lote = value;
      setProduct_inputs(existingData);
    }
    if (name === "costo") {
      event.target.value =
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
            value.substr(value.indexOf("."), 3)
          : value;
      let parsedValue = isNaN(parseFloat(event.target.value))
        ? 0
        : Math.abs(parseFloat(event.target.value));
      event.target.value = isNaN(parseFloat(event.target.value))
        ? ""
        : event.target.value;
      let existingData = [...product_inputs];
      existingData[index].costo_unitario = parsedValue;
      setProduct_inputs(existingData);
    }
    if (name === "precio_actualizado") {
      event.target.value =
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
            value.substr(value.indexOf("."), 3)
          : value;
      let parsedValue = isNaN(parseFloat(event.target.value))
        ? 0
        : Math.abs(parseFloat(event.target.value));
      event.target.value = isNaN(parseFloat(event.target.value))
        ? ""
        : event.target.value;

      let existingData = [...product_inputs];
      existingData[index].precio_actualizado = parsedValue;
      setProduct_inputs(existingData);
    }
    if (name === "fecha") {
      let existingData = [...product_inputs];
      existingData[index].vencimiento_fecha = value;
      setProduct_inputs(existingData);
    }
    if (name === "cantidad_peso") {
      event.target.value =
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
            value.substr(value.indexOf("."), 3)
          : value;
      let parsedValue = isNaN(parseFloat(event.target.value))
        ? ""
        : Math.abs(parseFloat(event.target.value));
      event.target.value = isNaN(parseFloat(event.target.value))
        ? ""
        : event.target.value;
      let existingData = [...product_inputs];
      existingData[index].cantidad_peso = parsedValue;
      setProduct_inputs(existingData);
    }
    if (name === "cantidad_piezas") {
      event.target.value =
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
            value.substr(value.indexOf("."), 3)
          : value;
      let parsedValue = isNaN(parseFloat(event.target.value))
        ? 0
        : Math.abs(parseFloat(event.target.value));
      event.target.value = isNaN(parseFloat(event.target.value))
        ? ""
        : event.target.value;
      let existingData = [...product_inputs];
      existingData[index].cantidad_piezas = parsedValue;
      setProduct_inputs(existingData);
    }
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="save" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Su ingreso se ha <br />
                    realizado con éxito
                  </strong>
                </h4>
              </div>

              <div class="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={
                    idIngreso
                      ? `/app/inventario/ingreso_details/${idIngreso}`
                      : "/app/inventario"
                  }
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Crear Ingreso</strong>
        </h4>
        {/*  <ArrowForwardIosIcon
                    style={{ color: "#333333", fontSize: "12px" }}
                    className="mx-2"
                />
                <FormControl variant="outlined">
                    <Select native value={sucursal} onChange={changeSucursal}>
                        {sucursals.map((sucursal) => (
                            <option
                                value={sucursal.id_sucursal_bodega_ubicacion}
                            >
                                {sucursal.nombre_sucursal}
                            </option>
                        ))}
                    </Select>
                </FormControl> */}
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autocomplete="on">
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Creación de Ingreso</strong>
                </h5>
                <p className="mb-0">
                  Registre una orden de ingreso de sus productos/insumos.
                </p>
              </div>

              <div>
                <Button
                  type="submit"
                  className="btn-cs1_blue mx-1 mt-1"
                  variant="contained"
                  color="primary"
                  disabled={loader}
                >
                  {loader ? <CircularProgress /> : "Guardar"}
                </Button>
                <Button
                  className="btn-cs1 mx-1 mt-1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmExit"
                  disabled={loader}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Tipo de ingreso</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    native
                    className="cs-select1 select-w100"
                    value={ingresoType}
                    onChange={(e) => setIngresoType(e.target.value)}
                    required={true}
                  >
                    <option value="">Seleccione el tipo de ingreso</option>
                    {ingreso_types.map((type) => (
                      <option value={type.value}>{type.descripcion}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Motivo del ingreso</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    inputRef={register}
                    name="id_new_motivo"
                    native
                    className="cs-select1 select-w100"
                    onChange={(e) => setCurrentMotivo(e.target.value)}
                    required={true}
                  >
                    <option value="">Motivo del ingreso</option>
                    {ingresosMotives.map((motive) => (
                      <option value={motive.id_motivo_web_app}>
                        {motive.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Sucursal que ingresará</strong>
                  </label>
                  <Tooltip title="Indique la sucursal que recibirá el ingreso.">
                    <InfoIcon
                      style={{
                        color: " #828282",
                        fontSize: "1.2rem",
                      }}
                    />
                  </Tooltip>
                </div>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    inputRef={register}
                    name="id_sucursal_bodega_ubicacion"
                    native
                    className="cs-select1 select-w100"
                    required={true}
                  >
                    <option value="">Seleccione sucursal</option>
                    {sucursals.map((sucursal) => (
                      <option value={sucursal.id_sucursal_bodega_ubicacion}>
                        {sucursal.nombre_sucursal}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Fecha del ingreso</strong>
                </label>

                <TextField
                  inputRef={register}
                  name="fecha_ingreso"
                  type="text"
                  className="w-100"
                  required={true}
                  value={moment(new Date()).format("DD/MM/YYYY hh:mm a")}
                />
              </div>

              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Tipo de documento</strong>
                  </label>
                  <Tooltip title="Seleccione le tipo de documento. Ejemplo: Factura, etc.">
                    <InfoIcon
                      style={{
                        color: " #828282",
                        fontSize: "1.2rem",
                      }}
                    />
                  </Tooltip>
                </div>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    inputRef={register}
                    name="id_tipo_documento_web_app"
                    native
                    className="cs-select1 select-w100"
                    required={true}
                  >
                    <option value="">Seleccione el tipo de documento</option>
                    {documentTypes.map((type) => (
                      <option value={type.id_tipo_documento_web_app}>
                        {type.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Comentario (opcional)</strong>
                  </label>
                  <Tooltip title="Indique el detalle del ingreso">
                    <InfoIcon
                      style={{
                        color: " #828282",
                        fontSize: "1.2rem",
                      }}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100">
                  <TextareaAutosize
                    inputRef={register}
                    name="comentario"
                    value={comentario}
                    onChange={(e) => setComentario(e.target.value)}
                    aria-label="minimum heigth"
                    maxLength={180}
                    minRows={3}
                    fullWidth
                    placeholder="Indique el detalle del ingreso"
                  />
                </FormControl>
                {comentario.length === 180 && (
                  <span className="w-100 text-danger">
                    Máximo de caracteres alcanzado
                  </span>
                )}
              </div>

              {/* Second Row */}

              {parseInt(currentMotivo) === 1 ? (
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Número de factura</strong>
                  </label>

                  <TextField
                    inputRef={register}
                    name="numero_factura"
                    type="text"
                    className="w-100"
                    required={true}
                  />
                </div>
              ) : (
                <div className="col-md-4 mt-3"></div>
              )}
              <div className="col-4 mt-3" />
              <div className="col-4 mt-3" />

              {/* Third Row */}
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>
                      {ingresoType === "producto" ? "Agregar productos" : ""}
                      {ingresoType === "insumo" ? "Agregar insumos" : ""}
                    </strong>
                  </label>
                </div>
                <p className="mb-2">
                  {ingresoType === "producto"
                    ? "Agregue los productos, e ingrese las cantidades que ingresarán."
                    : ""}
                  {ingresoType === "insumo"
                    ? "Agregue los insumos, e ingrese las cantidades que ingresarán."
                    : ""}
                </p>
              </div>

              <div className="col-md-4 mt-3">
                <Link to={"/app/inventario/add_product"} className="link-card">
                  <div className="card shadow">
                    <div className="p-2 d-flex align-items-center">
                      <AddCircleIcon
                        className="mx-1 secondary_color"
                        style={{
                          fontSize: "30px",
                        }}
                      />
                      <div>
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>
                            {ingresoType === "producto" ? "Producto nuevo" : ""}

                            {ingresoType === "insumo" ? "Insumo nuevo" : ""}
                          </strong>
                        </label>
                        <p
                          style={{
                            fontSize: "0.75rem",
                          }}
                          className="mb-0"
                        >
                          {ingresoType === "producto"
                            ? "Agregar un producto nuevo a su inventario"
                            : ""}

                          {ingresoType === "insumo"
                            ? "Agregar un insumo nuevo a su inventario"
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 mt-3">
                <div
                  className="card shadow"
                  onClick={() => displayProductsForm()}
                >
                  <div className="p-2 d-flex align-items-center card-hover">
                    <AssignmentIcon
                      className="mx-1 secondary_color"
                      style={{ fontSize: "30px" }}
                    />
                    <div>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>
                          {ingresoType === "producto"
                            ? "Producto existente"
                            : ""}

                          {ingresoType === "insumo" ? "Insumo existente" : ""}
                        </strong>
                      </label>
                      <p style={{ fontSize: "0.75rem" }} className="mb-0">
                        {ingresoType === "producto"
                          ? "Actualice el inventario de productos existentes"
                          : ""}

                        {ingresoType === "insumo"
                          ? "Actualice el inventario de insumos existentes"
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*  Forth Row */}
              {productsForm ? (
                <div className="col-md-12 mt-3">
                  <div className="d-flex justify-content-between">
                    <IconButton
                      className="mx-1 mt-1 icon-btn-primary"
                      onClick={() => add_inputs()}
                    >
                      <AddIcon style={{ color: "white" }} />
                    </IconButton>
                    <div>
                      <div
                        class="input-group search-bar autosuggest-search-bar"
                        style={{ width: "100%" }}
                      >
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                          }
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                        />
                        <div class="input-group-append">
                          <span class="input-group-text autosuggest-search-icon">
                            <SearchIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {productsForm ? (
                <div className="col-md-12 mt-3">
                  {product_inputs.map((input, index) => (
                    <div className="row">
                      <div className="col-md-1 mt-3 d-flex justify-content-center align-items-center">
                        <IconButton
                          className="btn-cs1 icon-btn-delete"
                          variant="contained"
                          color="primary"
                          onClick={() => remove_input(index)}
                        >
                          <DeleteIcon
                            style={{
                              color: "white",
                            }}
                          />
                        </IconButton>
                      </div>

                      <div className="col-md-4 mt-3">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>
                            {ingresoType === "producto"
                              ? "Código del producto"
                              : ""}

                            {ingresoType === "insumo"
                              ? "Código del insumo"
                              : ""}
                          </strong>
                        </label>

                        <TextField
                          inputRef={register}
                          name={`product_code[${index}]`}
                          value={input.codigo}
                          onChange={(event) =>
                            handleInputChange("codigo", index, event)
                          }
                          type="text"
                          className="w-100"
                          placeholder="Ingrese el código SKU del producto/insumo"
                        />
                      </div>

                      <div
                        className={
                          input.vendido_por === "mixto"
                            ? "col-md-4 mt-3"
                            : "col-md-3 mt-3"
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <strong>
                              Cantidad a ingresar en{" "}
                              {isCmi() && input.vendido_por === "mixto"
                                ? "libras"
                                : input.vendido_por}
                            </strong>
                          </label>
                          <Tooltip title="Indique la cantidad que ingresará al inventario.">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>

                        <TextField
                          inputRef={register}
                          name={`product_qty[${index}]`}
                          value={input.cantidad}
                          onChange={(event) =>
                            handleInputChange("cantidad", index, event)
                          }
                          type="number"
                          className="w-100"
                          placeholder="Indique la cantidad a ingresar"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                N°
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      {ingresoType === "producto" ? (
                        <div
                          className={
                            input.vendido_por === "mixto"
                              ? "col-md-3 mt-3"
                              : "col-md-4 mt-3"
                          }
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <label
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                <strong>¿Actualizar precio? (Opcional)</strong>
                              </label>
                              <Tooltip
                                title="Active la opción 'Si' para actualizar el precio del registro existente."
                                className="mx-1"
                              >
                                <InfoIcon
                                  style={{
                                    color: "#828282",
                                    fontSize: "1.2rem",
                                  }}
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <TextField
                            inputRef={register}
                            name={`precio_actualizado[${index}]`}
                            type="number"
                            className="w-100"
                            value={
                              input.precio_actualizado === 0
                                ? ""
                                : input.precio_actualizado
                            }
                            onChange={(event) =>
                              handleInputChange(
                                "precio_actualizado",
                                index,
                                event
                              )
                            }
                            placeholder="Ingrese el nuevo precio"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {moneda}.
                                </InputAdornment>
                              ),
                              inputProps: { min: 0, step: "0.00001" },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-md-1 mt-3"></div>
                      <div className="col-md-4 mt-3">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Nombre del producto</strong>
                        </label>

                        <TextField
                          value={input.nombre}
                          type="text"
                          className="w-100"
                          placeholder="Nombre del producto"
                        />
                      </div>
                      <div
                        className={
                          input.vendido_por === "mixto"
                            ? "col-md-4 mt-3"
                            : "col-md-3"
                        }
                        style={{
                          display:
                            input.vendido_por === "mixto" &&
                            isCmi() &&
                            ingresoType === "producto"
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <strong>Cantidad a ingresar en unidades</strong>
                          </label>
                          <Tooltip title="Indique la cantidad que ingresará al inventario.">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>

                        <TextField
                          inputRef={register}
                          name={`product_weight[${index}]`}
                          onChange={(event) =>
                            handleInputChange("cantidad_peso", index, event)
                          }
                          value={input.cantidad_peso}
                          type="number"
                          className="w-100"
                          placeholder="Indique la cantidad a ingresar"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                N°
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      {ingresoType === "producto" ? (
                        <div className={"col-md-3 mt-3"}>
                          <label
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <strong>
                              {ingresoType === "producto"
                                ? "Coste del producto"
                                : ""}
                              {ingresoType === "insumo"
                                ? "Coste del insumo"
                                : ""}
                            </strong>
                          </label>

                          <TextField
                            inputRef={register}
                            name={`product_costo[${index}]`}
                            value={
                              input.costo_unitario === 0
                                ? ""
                                : input.costo_unitario
                            }
                            onChange={(event) =>
                              handleInputChange("costo", index, event)
                            }
                            type="number"
                            className="w-100"
                            placeholder="Indique el coste del producto/insumo"
                            InputProps={{
                              inputProps: { min: 0, step: "0.01" },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {input.vendido_por === "mixto" && (
                        <div className="col-1 mt-3"></div>
                      )}

                      <div
                        className="col-md-4 mt-3"
                        style={{ display: aleko ? "none" : "block" }}
                      >
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Lote (opcional)</strong>
                        </label>

                        <TextField
                          inputRef={register}
                          name={`product_lote[${index}]`}
                          type="text"
                          value={input.lote}
                          onChange={(event) =>
                            handleInputChange("lote", index, event)
                          }
                          className="w-100"
                          placeholder="Indique el lote de su producto/insumo"
                        />
                      </div>

                      {ingresoType === "insumo" ? (
                        <>
                          <div className="col-md-1 mt-3"></div>
                          <div className="col-md-1 mt-3"></div>
                        </>
                      ) : (
                        ""
                      )}

                      <>
                        {input.vendido_por !== "mixto" && (
                          <div className="col-1 mt-3"></div>
                        )}
                        <div
                          className={
                            ingresoType === "producto"
                              ? "col-md-4 mt-3"
                              : "col-md-3 mt-3"
                          }
                          style={{ display: aleko ? "none" : "block" }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <label
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              <strong>Fecha de vencimiento (opcional)</strong>
                            </label>
                            <Tooltip title="Indique la fecha de vencimiento del lote del producto/insumo a ingresar.">
                              <InfoIcon
                                style={{
                                  color: " #828282",
                                  fontSize: "1.2rem",
                                }}
                              />
                            </Tooltip>
                          </div>
                          <TextField
                            inputRef={register}
                            name={`fecha_vencimiento[${index}]`}
                            type="date"
                            value={input.vencimiento_fecha}
                            onChange={(event) =>
                              handleInputChange("fecha", index, event)
                            }
                            className="w-100"
                          />
                          <TextField
                            inputRef={register}
                            name={`flag_insumo[${index}]`}
                            type="hidden"
                            className="d-none"
                            value={input.flag_insumo}
                          />
                        </div>
                      </>

                      <div
                        className={"col-md-4 mt-3"}
                        // style={{
                        //   display:
                        //     input.vendido_por === "mixto" &&
                        //     isCmi() &&
                        //     ingresoType === "producto"
                        //       ? "block"
                        //       : "none",
                        // }}
                        style={{ display: "none" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            <strong>Cantidad a ingresar en piezas</strong>
                          </label>
                          <Tooltip title="Indique la cantidad que ingresará al inventario.">
                            <InfoIcon
                              style={{
                                color: " #828282",
                                fontSize: "1.2rem",
                              }}
                            />
                          </Tooltip>
                        </div>
                        <TextField
                          inputRef={register}
                          name={`product_piezas[${index}]`}
                          onChange={(event) =>
                            handleInputChange("cantidad_piezas", index, event)
                          }
                          value={input.cantidad_piezas}
                          type="number"
                          className="w-100"
                          placeholder="Indique la cantidad a ingresar en piezas"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                N°
                              </InputAdornment>
                            ),
                            inputProps: {
                              min: 0,
                              step: "0.00001",
                            },
                          }}
                        />
                      </div>
                      <div className="col-md-12 py-1"></div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
