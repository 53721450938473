import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box } from "@material-ui/core";

//icons

// styles
import useStyles from "./styles";

// components
import Header from "../Header/AdminHeader";
import Sidebar from "../Sidebar/adminSidebar";

/* Dashboard */
import Dashboard from "../../pages/admin/dashboard/dashboard";

/* Clients */
import Clients_create from "../../pages/admin/clients/new";
import Clients_details from "../../pages/admin/clients/details";
import Clients_membership from "../../pages/admin/clients/membership_payment";

/* Membresias */
import Membresias_all from "../../pages/admin/membresia/all";
import Membresias_create from "../../pages/admin/membresia/new";
import Membresias_details from "../../pages/admin/membresia/details";

/* Usuarios cloud */
import cloudUSers_all from "../../pages/admin/cloudUsers/all";
import cloudUsers_create from "../../pages/admin/cloudUsers/new";
import cloudUsers_details from "../../pages/admin/cloudUsers/details";

/* formatos de facturas */
import formatos_all from "../../pages/admin/formatos/all";
import view_format from "../../pages/admin/formatos/viewFormat";

/* Sucursales */
import Sucursales_create from "../../pages/admin/sucursales/new";
import Sucursales_details from "../../pages/admin/sucursales/details";

/* Configurations */

import Configurations from "../../pages/admin/configurations/all";

/* Profile */
import profile from "../../pages/admin/profile/profile";

/* Users */
import Users_new from "../../pages/admin/users/new";
import Users_details from "../../pages/admin/users/details";

/* Global stats */
import global_stats from "../../pages/admin/globalStats/globalStats";

// context
import { useLayoutState } from "../../context/LayoutContext";

function AdminLayout(props) {
  const moment = require("moment");
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar />

      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <Switch>
          {/* Dashboard routes */}
          <Route exact path="/admin/dashboard" component={Dashboard} />

          {/* Membresias routes */}
          <Route exact path="/admin/membresia" component={Membresias_all} />
          <Route
            exact
            path="/admin/membresia/new"
            component={Membresias_create}
          />
          <Route
            exact
            path="/admin/membresia/detalles/:id"
            component={Membresias_details}
          />

          {/* Cloud users routes */}
          <Route exact path="/admin/usuarios" component={cloudUSers_all} />
          <Route
            exact
            path="/admin/usuarios/crear"
            component={cloudUsers_create}
          />
          <Route
            exact
            path="/admin/usuarios/detalles"
            component={cloudUsers_details}
          />

          {/* Formato de facturas */}
          <Route exact path="/admin/formatos" component={formatos_all} />
          <Route exact path="/admin/formatos/:id" component={view_format} />

          {/* Clients routes */}
          <Route exact path="/admin/clients/new" component={Clients_create} />
          <Route
            exact
            path="/admin/clients/membership/:id"
            component={Clients_membership}
          />
          <Route
            exact
            path="/admin/clients/profile/:id"
            component={Clients_details}
          />

          {/* Sucursales routes */}
          <Route
            exact
            path="/admin/sucursal/new/:id"
            component={Sucursales_create}
          />
          <Route
            exact
            path="/admin/sucursales/detalles"
            component={Sucursales_details}
          />

          {/* Profile Route */}
          <Route exact path="/admin/profile" component={profile} />

          {/* Configurations Route */}
          <Route
            exact
            path="/admin/configuraciones"
            component={Configurations}
          />

          {/* Users route */}
          <Route exact path="/admin/users/new/:id" component={Users_new} />
          <Route
            exact
            path="/admin/usuarios/detalles"
            component={Users_details}
          />

          {/* Global stats */}
          <Route exact path="/admin/stats" component={global_stats} />
        </Switch>

        <Box
          mt={5}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <div>
            <p style={{ color: "#828282" }}>
              Cloud App | Copyright {moment().get("year")}
            </p>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default withRouter(AdminLayout);
