import React, { useState, useEffect, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import API from "../../../api";
import { toast } from "react-toastify";
import { base_url_images, realImageURL } from "../../../api";
import { CSVLink } from "react-csv";
import HomeIcon from "@material-ui/icons/Home";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

//herramienta reporte
import ReactHTMLTableToExcel from "react-html-table-to-excel";

// styles
import "./custom.css";

//subPages

//reportes

import InventarioTodosReporte from "../../../reportes/inventarioTodosReporte";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import moment from "moment";
import "moment/locale/es";

import { isCmi } from "../../../helpers/isCmi";
import { isGerenteMercadeo } from "../../../helpers/isGerenteMercadeo";

import { isCajero } from "../../../helpers/isCajero";
import { getMoneda } from "../../../helpers/moneda";
import DescargasModal from "./DescargasModal";
import BitacoraSap from "./BitacoraSap";
import SyncIcon from "@material-ui/icons/Sync";
import DescargasMaterialesModal from "./DescargaMaterialesModal";
const formaterPriceFunction = (value) => {
  return value
    .replace(/(?!\.)\D/g, "")
    .replace(/(?<=\..*)\./g, "")
    .replace(/(?<=\.\d\d).*/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const numberFormater = (value) => {
  let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
  return newValue;
};
const sortAlphabetically = (array) => {
  let sortedArray = array.sort(function (a, b) {
    if (a.descripcion < b.descripcion) {
      return -1;
    }
    if (a.descripcion > b.descripcion) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
};
const DescargarInventario = () => {
  const [loader_1, setLoader1] = useState(false);
  const [loader_2, setLoader2] = useState(false);
  const history = useHistory();
  const moneda = getMoneda();
  const [selectedProducts, setSelectedProducts] = useState([]);
  let currentSucursal = JSON.parse(
    localStorage.getItem("store")
  )?.id_sucursal_bodega_ubicacion;
  const [productCategory, setProductCategory] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [productsLoader, setProductsLoader] = useState(false);
  const [productsStats, setProductsStats] = useState({});
  const [productsMenu, setProductsMenu] = useState(null);
  const [sucursal, setSucursal] = useState("");
  const [sucursals, setSucursals] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productFilterText, setProductFilterText] = useState("");
  const [categories, setCategories] = useState([
    {
      id_clasificacion1_web_app: "",
      descripcion: "Todas las categorias",
    },
  ]);
  const [productsTab, setProductsTab] = useState(0);
  const [downloadedProducts, setDownloadedProducts] = useState({
    agregados: [],
    no_agregados: [],
  });
  const [empresasCmi, setEmpresasCmi] = useState([]);
  const [currentEmpresa, setCurrentEmpresa] = useState(null);
  const productAction = (e, id, aleko) => {
    if (e.target.value === "details") {
      if (!aleko) {
        history.push(`/app/inventario/product_details/${id}`);
      } else {
        history.push(`/app/inventario/product_personalizable_details/${id}`);
      }
    }

    // if (e.target.value === "delete") deleteProduct(id);
    // if (e.target.value === "egreso") createEgreso(id);
    // if (e.target.value === "ingreso") createIngreso(id);
    // if (e.target.value === "alekoDelete")
    //   deleteAleko(id, setFilteredProducts, filteredProducts);
  };
  const handleSelect = (info) => {
    let { allSelected, selectedCount, selectedRows } = info;

    let data = selectedRows;

    setSelectedProducts(data);
  };
  const changeCategory = (event) => {
    const category = event.target.value;
    setProductCategory(category);
  };
  const changeSucursal = (event) => {
    const sucursalName = event.target.value;
    getProducts(event.target.value);
  };
  const handleProductTabs = (event, newValue) => {
    setProductsTab(newValue);
  };
  const set_categories = (productsList) => {
    API.products
      .getProductAttributes({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          let categoriesArray = [];
          productsList.map((product) => {
            categoriesArray.push(product.id_clasificacion1_web_app);
          });
          let categoriesState = [];
          let categoriesSet = new Set(categoriesArray);
          Array.from(categoriesSet).map((id) => {
            categoriesState.push(
              response.data.categories.find(
                (x) => x.id_clasificacion1_web_app === id
              )
            );
          });
          setCategories(sortAlphabetically(categoriesState));
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined")
          if (typeof response.data.message !== "undefined") {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error", response);
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
      });
  };
  const searchProducts = (e = null) => {
    if (e) e.preventDefault();
    getProducts(currentSucursal, 1);
  };
  const getProducts = (id_sucursal, page = null) => {
    // setProductsLoader(true);
    var query = {
      nombre: isNaN(productFilterText) ? productFilterText : "",
      codigo: isNaN(productFilterText) ? "" : productFilterText,
    };
    API.products
      .getAll({
        id_sucursal_bodega_ubicacion: id_sucursal,
        no_combos: 1,
        pagination: true,
        page: page || currentPage,
        nombre: productFilterText,
        limit: 10,
        ...query,
      })
      .then((res) => {
        let response = res.data;
        setTotalProducts(res.data.total);
        response.data = response.data;
        if (response.success) {
          response.data.map((product) => {
            if (product.vencimiento_fecha === "")
              product.vencimiento_fecha = "N/A";
          });

          setAllProducts(response.data);
          setFilteredProducts(response.data);
          const filterRecetas = response.data.filter(
            (item) => item.flag_compuesto
          );

          let total = response.data.length;
          let sin_stock = response.data.filter((x) => x.stock == 0);
          let bajo_inventario = response.data.filter(
            (x) => x.stock < x.stock_minimo
          );
          let nearExp = [];

          response.data.map((product) => {
            if (
              product.flag_vencimiento &&
              moment(product.vencimiento_fecha).format("YYYY-MM-DD") >
                moment().format("YYYY-MM-DD") &&
              moment(product.vencimiento_fecha).format("YYYY-MM-DD") <
                moment().add(15, "days").format("YYYY-MM-DD")
            ) {
              nearExp.push(product);
            }
          });
          filterRecetas.map((product) => {
            if (
              product.flag_vencimiento &&
              moment(product.vencimiento_fecha).format("YYYY-MM-DD") >
                moment().format("YYYY-MM-DD") &&
              moment(product.vencimiento_fecha).format("YYYY-MM-DD") <
                moment().add(15, "days").format("YYYY-MM-DD")
            ) {
              nearExp.push(product);
            }
          });

          setProductsStats({
            total: total,
            sin_stock: sin_stock.length,
            por_vencer: nearExp.length,
            bajo_inventario: bajo_inventario.length,
          });

          set_categories(response.data);

          setProductsLoader(false);
        }
      })
      .catch((err) => {
        setProductsLoader(false);
        let response = err.response;
        console.log(response);
        if (typeof response?.data !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          console.log("error", err);
          console.log("Ha ocurrido un error", response);
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };
  const getSucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;

        if (response.success) {
          setSucursals(response.data);

          getProducts(response.data[0].id_sucursal_bodega_ubicacion);
          setSucursal(response.data[0].id_sucursal_bodega_ubicacion);
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined")
          if (typeof response.data.message !== "undefined") {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error", response);
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
      });
  };

  const filterProducts = (event) => {
    let text;
    if (!event) {
      text = productFilterText;
    } else {
      text = event.target.value;
    }

    setProductFilterText(text);
    if (text === "") {
      if (productCategory === "") {
        setFilteredProducts(allProducts);
      } else {
        setFilteredProducts(
          allProducts.filter(
            (item) => item.id_clasificacion1_web_app == productCategory
          )
        );
      }
    } else {
      if (productCategory === "") {
        // setFilteredProducts(
        //   allProducts.filter(
        //     (item) =>
        //       item.nombre.toLowerCase().includes(text.toLowerCase()) ||
        //       item.codigo.toLowerCase().includes(text.toLowerCase())
        //   )
        // );
      } else {
        setFilteredProducts(
          allProducts.filter(
            (item) =>
              item.id_clasificacion1_web_app == productCategory &&
              (item.nombre.toLowerCase().includes(text.toLowerCase()) ||
                item.codigo.toLowerCase().includes(text.toLowerCase()))
          )
        );
      }
    }
  };
  useEffect(() => {
    getSucursals();
  }, []);
  useEffect(() => {
    filterProducts(null);
  }, [productCategory]);
  const [productosInventario, setProductosInventario] = useState([]);
  const [materialesInventario, setMaterialesInventario] = useState([]);
  const handleDescargaSap = async () => {
    if (loader_1) return;
    setLoader1(true);
    try {
      const { data } = await API.products.descargaMaterialesSap(
        isGerenteMercadeo()
          ? {
              id_sucursal_bodega_ubicacion: sucursal,
              id_new_empresa: currentEmpresa,
            }
          : null
      );
      toast.success("Descargas de materiales realizadas con exito", {
        autoClose: 10000,
      });
      setMaterialesInventario(data?.data?.no_agregados || []);
      getSucursals();
    } catch (error) {
      if (error.response && error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message, {
          autoClose: 10000,
        });
      } else {
        toast.error(error?.message, {
          autoClose: 10000,
        });
      }
    }
    setLoader1(false);
  };

  const handleDescargaInventario = async (arr) => {
    if (loader_2) return;
    setLoader2(true);
    try {
      let form = new FormData();
      if (Array.isArray(arr)) {
        if (arr.length) {
          form.append(
            "productos",
            JSON.stringify(
              arr.map((x) =>
                x.id_padre == null
                  ? { id_new_item: x.id_new_item }
                  : { id_new_item: x.id_padre }
              )
            )
          );
        } else {
          return;
        }
      }

      if (isGerenteMercadeo()) {
        form.append("id_sucursal_bodega_ubicacion", sucursal);
        form.append("id_new_empresa", currentEmpresa);
      }
      const { data } = await API.products.descargasInventario(form);
      toast.success("Descargas al inventario realizadas con exito", {
        autoClose: 10000,
      });
      setProductosInventario(data?.data?.no_agregados || []);
      setDownloadedProducts({
        agregados: data?.data?.agregados || [],
        no_agregados: data?.data?.no_agregados || [],
      });
      getSucursals();
    } catch (error) {
      if (error.response && error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message, {
          autoClose: 10000,
        });
      } else {
        toast.error(error?.message, {
          autoClose: 10000,
        });
      }
    }
    setLoader2(false);
  };
  const getEmpresasCmi = async (id_user) => {
    let newEmpresa = [];

    try {
      const res = await API.users.getEmpresaPorUsuario(id_user);
      const data = res.data.data;

      // filtrar las empresas
      data.forEach((empresa) => newEmpresa.push(empresa.empresa));

      let filtradas = newEmpresa.reduce((acc, el) => {
        if (!acc.find((item) => item.id_new_empresa == el.id_new_empresa)) {
          acc.push(el);
        }
        return acc;
      }, []);

      // seteamos las empresas
      setEmpresasCmi(filtradas);
      setCurrentEmpresa(filtradas[0].id_new_empresa);
    } catch (error) {
      console.log("error al obtener empresas gerente ->", error);
    }
  };
  useEffect(() => {
    if (isGerenteMercadeo()) {
      let user = JSON.parse(window.localStorage.getItem("pms"));

      getEmpresasCmi(user.id_new_user);
    }
  }, []);
  const handleChangeEmpresa = async (e) => {
    setCurrentEmpresa(e.target.value);
    try {
      const { data } = await API.sucursal.getAll(
        parseInt(e.target.value),
        true
      );
      setSucursals(data.data);
      getProducts(data.data[0].id_sucursal_bodega_ubicacion);
    } catch (error) {
      console.log(error);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSearch, setCurrentPageSearch] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);

  useEffect(() => {
    getProducts(currentSucursal);
    setCurrentPageSearch(null);
  }, [currentPage]);
  return (
    <div id="dashboard-section">
      <DescargasModal
        products={productosInventario}
        downloadedProducts={downloadedProducts}
      />
      <DescargasMaterialesModal products={materialesInventario} />
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>
        {isGerenteMercadeo() && (
          <>
            <ArrowForwardIosIcon
              style={{ color: "#333333", fontSize: "12px" }}
              className="mx-2"
            />

            <FormControl variant="outlined">
              <Select
                onChange={handleChangeEmpresa}
                value={currentEmpresa}
                native
              >
                {empresasCmi.map((itemEmpresa) => (
                  <option value={itemEmpresa.id_new_empresa}>
                    {itemEmpresa.nombre}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={sucursal} onChange={changeSucursal}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="content-card card mt-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap shadow-sm">
          <Tabs
            className="mt-2"
            value={productsTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleProductTabs}
          >
            <Tab className="tab" label="Todos" />
            <Tab className="tab" label="Historial" />
          </Tabs>
        </div>
      </div>
      <div className="bg-white">
        <TabPanel index={0} value={productsTab}>
          <div className="row">
            <div className="px-2 col-md-12 d-flex align-items-center justify-content-between flex-wrap mb-3">
              <div>
                <h4
                  className="mb-0"
                  style={{ fontSize: "20px", color: "#333" }}
                >
                  <strong>Inventario de productos</strong>
                </h4>
                <p
                  className="mb-0 mt-1"
                  style={{ fontSize: "16px", color: "#333" }}
                >
                  Consulte y gestione su inventario.
                </p>
              </div>
              <div>
                <div className="d-flex">
                  <Button
                    className="btn-cs1 shadow-none"
                    variant="contained"
                    color="primary"
                    endIcon={
                      loader_2 ? (
                        <CircularProgress size={20} style={{ color: "#fff" }} />
                      ) : (
                        <CloudDownloadIcon />
                      )
                    }
                    onClick={() => handleDescargaInventario()}
                  >
                    Descargar inventario
                  </Button>
                  <Button
                    className="btn-cs1 shadow-none ml-2"
                    variant="contained"
                    color="primary"
                    endIcon={
                      loader_1 ? (
                        <CircularProgress size={20} style={{ color: "#fff" }} />
                      ) : (
                        <CloudDownloadIcon />
                      )
                    }
                    style={{ textTransform: "none", fontSize: "1rem" }}
                    onClick={() => handleDescargaSap()}
                  >
                    Descarga de materiales
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-2 px-2 justify-content-"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p className="mb-1" style={{ fontSize: "16px", color: "black" }}>
                Total de productos
              </p>
              <h6 className="mb-0 secondary_color">
                <strong>
                  {productsLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(productsStats.total)
                  )}
                </strong>
              </h6>
            </div>
            <div
              className="col-md-2 px-2"
              // style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p className="mb-1" style={{ fontSize: "16px", color: "black" }}>
                Productos sin stock
              </p>
              <h6 className="mb-2 secondary_color">
                <strong>
                  {productsLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    numberFormater(productsStats.sin_stock)
                  )}
                </strong>
              </h6>
            </div>

            <div className="col-md-3 px-2 text-right"></div>
          </div>

          <ReactHTMLTableToExcel
            id="descargar-reporteInventario-Todos"
            className="buton-excel"
            table="InventarioTodos-reporte-table"
            filename="Cloud-Reporte-Productos.xlsx"
            sheet="tablexls"
            buttonText="Descargar"
          />

          <InventarioTodosReporte
            objeto={selectedProducts}
          ></InventarioTodosReporte>

          <div className="row">
            <div className="px-2 col-md-12 d-flex justify-content-between align-items-center mb-3 mt-2 flex-wrap">
              <div>
                {selectedProducts.length > 0 ? (
                  <Button
                    className=""
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      document
                        .getElementById("descargar-reporteInventario-Todos")
                        .click();
                    }}
                  >
                    <CloudDownloadIcon
                      className="mr-2"
                      style={{ fontSize: "16px" }}
                    />
                    <span style={{ fontSize: "14px" }}>
                      Descargar seleccionados
                    </span>
                  </Button>
                ) : (
                  <Button className="" variant="outlined" color="primary">
                    <CloudDownloadIcon
                      className="mr-2"
                      style={{ fontSize: "16px" }}
                    />
                    <span style={{ fontSize: "14px" }}>
                      Descargar seleccionados
                    </span>
                  </Button>
                )}

                <FormControl variant="outlined" className="ml-3" size="small">
                  <Select
                    native
                    value={productCategory}
                    onChange={changeCategory}
                    fullWidth
                    variant="outlined"
                    style={{ height: "36.5px" }}
                  >
                    <option value="">Todas las categorías</option>
                    {categories.map((category) =>
                      category ? (
                        <option value={category.id_clasificacion1_web_app}>
                          {category.descripcion}
                        </option>
                      ) : (
                        ""
                      )
                    )}
                  </Select>
                </FormControl>
              </div>

              <div>
                <form className="input-group " onSubmit={searchProducts}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar por nombre o código"
                    style={{ width: "300px" }}
                    value={productFilterText}
                    autoFocus
                    onChange={(e) => filterProducts(e)}
                  />
                  <div
                    className="input-group-append J-btn"
                    onClick={() => searchProducts()}
                  >
                    <span className="input-group-text bg-secondary-color">
                      <SearchIcon style={{ color: "#fff" }} />
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {productsLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div className="row mt-1">
              {!filteredProducts ? (
                <p className="text-center">No hay productos para mostrar</p>
              ) : (
                <div className="col-md-12 px-2">
                  <DataTable
                    className="datatable-cs datatable-cs-v2 datatable-h-80"
                    title={"s"}
                    columns={[
                      {
                        name: "Nombre",
                        selector: "nombre",
                        sortable: true,
                        cell: (row) => (
                          <div className="d-flex w-100">
                            <div
                              style={{
                                height: "55px",
                                width: "55px",
                                border: "0.3px solid rgba(0, 0, 0, 0.7)",
                                borderRadius: "5px",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={
                                  row.imagen_1
                                    ? realImageURL(row.imagen_1)
                                    : base_url_images +
                                      "images/placeholder-image.jpeg"
                                }
                                // alt=""
                              />
                            </div>
                            <div
                              className="ml-2"
                              style={{
                                width: "150px",
                              }}
                            >
                              {row.nombre}
                              <br />
                              <strong>{row.codigo}</strong>
                            </div>
                          </div>
                        ),
                      },
                      {
                        name: "Vencimiento",
                        selector: "vencimiento_fecha",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {row.vencimiento_fecha === "N/A"
                              ? "N/A"
                              : moment(row.vencimiento_fecha).format(
                                  "DD-MM-YYYY"
                                )}
                          </span>
                        ),
                      },

                      {
                        name: "Categoría",
                        selector: "category",
                        sortable: true,
                      },
                      {
                        name: "Unidad",
                        selector: "vendido_por",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {row.id_padre === null ? row.vendido_por : "libras"}
                          </span>
                        ),
                      },
                      {
                        name: "Cantidad",
                        selector: "stock",
                        sortable: true,
                        cell: (row) => (
                          <div className="d-flex w-100 flex-wrap">
                            {" "}
                            <div className="d-flex justify-content-center w-100">
                              <span>
                                {formaterPriceFunction(row.stock + "")}
                              </span>
                            </div>
                            {isCmi() && (
                              <div className="d-flex justify-content-center w-100">
                                <span>
                                  {row.vendido_por !== "unidades"
                                    ? "/ libras"
                                    : "/ unidades"}
                                </span>
                              </div>
                            )}
                          </div>
                        ),
                      },

                      {
                        name: "Precio",
                        selector: "precio_venta",
                        sortable: true,
                        cell: (row) => (
                          <span>
                            {moneda}.{" "}
                            {row.descuento_precio > 0.0
                              ? formaterPriceFunction(
                                  (
                                    row.descuento_precio +
                                    (row.impuesto_idp || 0)
                                  ).toFixed(2) + ""
                                )
                              : formaterPriceFunction(
                                  (
                                    row.precio_venta + (row.impuesto_idp || 0)
                                  ).toFixed(2) + ""
                                )}
                          </span>
                        ),
                      },
                      {
                        name: "Descarga manual",
                        selector: "action",

                        cell: (row) => (
                          <div className="w-100 d-flex justify-content-center">
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              endIcon={<SyncIcon />}
                              onClick={() => handleDescargaInventario([row])}
                            >
                              <span style={{ fontSize: "0.75rem" }}>
                                Actualizar inventario
                              </span>
                            </Button>
                          </div>
                        ),
                      },
                    ]}
                    data={filteredProducts}
                    selectableRows
                    defaultSortField="vencimiento_fecha"
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationPerPage={10}
                    paginationDefaultPage={currentPage || currentPageSearch}
                    paginationTotalRows={totalProducts}
                    onChangePage={(page) => setCurrentPage(page)}
                    paginationComponentOptions={{
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: "All",
                    }}
                    noDataComponent="No se ha encontrado ningún resultado"
                    onSelectedRowsChange={handleSelect}
                  />
                </div>
              )}{" "}
            </div>
          )}
        </TabPanel>
      </div>
      <div className="bg-white">
        <TabPanel index={1} value={productsTab}>
          <BitacoraSap />
        </TabPanel>
      </div>
    </div>
  );
};

export default DescargarInventario;
