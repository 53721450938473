// eslint-disable-next-line
import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import Autosuggest from "react-autosuggest";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import { isCmi } from "../../helpers/isCmi";

// components

//data

let egreso_types = [
  {
    value: "producto",
    descripcion: "Egreso de productos",
  },
  {
    value: "insumo",
    descripcion: "Egreso de insumos",
  },
];

export default function Users_all(props) {
  const [idEgreso, setIdEgreso] = useState(null);

  const moment = require("moment");
  const AutosuggestHighlightMatch = require("autosuggest-highlight/match");
  const AutosuggestHighlightParse = require("autosuggest-highlight/parse");
  // states
  const [products_inputted, setproducts_inputted] = useState([]);
  const { register, handleSubmit } = useForm();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [sucursals, setSucursals] = useState([]);
  const [egresosMotives, setEgresosMotives] = useState([]);
  const [allProducts, setAllProducts] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const [egresoType, setEgresoType] = useState(egreso_types[0].value);
  const [allSupplies, setAllSupplies] = useState(null);
  const [comentario, setComentario] = useState("");
  // set states

  let history = useHistory();

  useEffect(() => {
    get_sucursals();
    get_egresosMotives();
    getProducts();
    getSupplies();

    if (props.history.location.state) {
      let existingData = [...products_inputted];
      setproducts_inputted(
        existingData.concat([
          {
            codigo: props.history.location.state.products[0].codigo,
            cantidad_peso:
              props.history.location.state.products[0].vendido_por === "mixto"
                ? 1
                : 0,
            vendido_por: props.history.location.state.products[0].vendido_por,
            cantidad: 1,
            cantidad_peso: 0,
            cantidad_piezas: 0,
            nombre: props.history.location.state.products[0].nombre,
          },
        ])
      );
      if (props.history.location.state.products[0]?.flag_insumo) {
        setEgresoType("insumo");
      }
    }
  }, []);

  useEffect(() => {
    if (egresoType !== "") {
      switch (egresoType) {
        case "producto":
          setSelectedElements(allProducts);
          break;
        case "insumo":
          setSelectedElements(allSupplies);
          break;

        default:
          break;
      }
    }
  }, [allProducts, allSupplies]);

  useEffect(() => {
    if (egresoType !== "") {
      switch (egresoType) {
        case "producto":
          setSelectedElements(allProducts);
          break;
        case "insumo":
          setSelectedElements(allSupplies);
          break;

        default:
          break;
      }
    }

    if (Array.isArray(allProducts) & Array.isArray(allSupplies)) {
      // If they were already loaded
      setproducts_inputted([]);
    }
  }, [egresoType]);

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
    };
  }, []);

  console.log(selectedElements);

  //function
  const add_inputs = () => {
    setproducts_inputted(
      products_inputted.concat([
        {
          codigo: "",
          cantidad: "",
          cantidad_peso: "",
          cantidad_piezas: "",
        },
      ])
    );
  };
  const remove_input = (i) => {
    console.log("This is products inputted", products_inputted);
    setproducts_inputted(products_inputted.filter((e, index) => index !== i));
  };

  const onSubmit = (data) => {
    let products = [];
    setSubmitLoader(true);
    if (!data.product_code) {
      toast.error("Debe agregar un producto", {
        autoClose: 10000,
      });
      setSubmitLoader(false);
      return;
    }
    var errorsProducts = false;
    if (data.product_code) {
      data.product_code.forEach((element, i) => {
        let raw_data = {
          codigo: data.product_code[i],
          cantidad: data.product_qty[i],
          cantidad_peso: data.product_weight[i],
          cantidad_piezas: data.product_piezas[i],
        };
        products.push(raw_data);
        if (!raw_data.cantidad && !raw_data.cantidad_peso) {
          errorsProducts = true;
        }
      });
    }
    if (errorsProducts) {
      toast.error("Uno o mas productos no tienen cantidad o cantidad peso", {
        autoClose: 10000,
      });
      setSubmitLoader(false);
      return;
    }

    data.fecha_egreso = new Date();
    data.products = products;

    let codeMismatch = false;
    let products_inputted = products;
    let inputted_products_codes = [];
    products_inputted.map((product) => {
      inputted_products_codes.push(product.codigo);
    });
    let all_product_codes = [];

    // for (let product of selectedElements) {
    //   all_product_codes.push(product.codigo);
    // }
    selectedElements.map((product) => all_product_codes.push(product.codigo));

    for (let product of inputted_products_codes) {
      if (!all_product_codes.includes(product)) {
        codeMismatch = true;
        console.log(product, " is not included in ", all_product_codes);
      }
    }

    if (codeMismatch) {
      setSubmitLoader(false);
      toast.error(
        "Al menos uno de los códigos de productos que introdujo no fue encontrado en sus productos.",
        {
          autoClose: 10000,
        }
      );
    } else {
      for (let product of products_inputted) {
        let product_fromDB = selectedElements.find(
          (x) => x.codigo === product.codigo
        );
        product.id_new_item = product_fromDB.id_new_item;
        product.cantidad = parseFloat(product.cantidad);
        product.precio = product_fromDB.costo_unitario;
        product.cantidad_peso = parseFloat(product.cantidad_peso);
        product.cantidad_piezas = parseFloat(product.cantidad_piezas);
      }
      data.products = JSON.stringify(products_inputted);
      data.comentario = comentario;
      submitEgreso(data);
    }
    console.log(data);
  };

  const get_sucursals = () => {
    API.userConfig
      .getSucursals(true)
      .then((res) => {
        let response = res.data;
        if (response.success && JSON.stringify(response.data) !== "[]") {
          setSucursals(response.data);
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const get_egresosMotives = () => {
    API.egresos
      .getMotives()
      .then((res) => {
        let response = res.data;
        if (response.success && JSON.stringify(response.data) !== "[]") {
          setEgresosMotives(response.data);
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const submitEgreso = (data) => {
    console.log("Data being submitted:", data);
    API.egresos
      .add_new(data)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          history.push(
            `/app/inventario/egreso_details/${response.egreso.id_egreso_web_app}`
          );
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setSubmitLoader(false);
      })
      .catch((err) => {
        setSubmitLoader(false);
        console.log(err);
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };
  let currentSucursal = JSON.parse(
    localStorage.getItem("store")
  )?.id_sucursal_bodega_ubicacion;
  const [isLoaded, setIsLoaded] = useState(false);

  const getProducts = (value = "") => {
    if (isLoaded) {
      return;
    }
    setIsLoaded(true);
    API.products
      .getAll({
        nombre: value || filterText,
        id_sucursal_bodega_ubicacion: currentSucursal,
        no_combos: 1,
        pagination: true,
        page: 1,
        limit: 10,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setAllProducts(response.data);
        }
        setIsLoaded(false);
      })
      .catch((err) => {
        console.log("Ha ocurrido un error / Búsqueda de productos");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
        setIsLoaded(false);
      });
  };

  const getSupplies = () => {
    API.supplies
      .getAll({
        id_sucursal_bodega_ubicacion: currentSucursal,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Im obtaining these insumos:", response.data);
          setAllSupplies(response.data.insumos);
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        console.log("Ha ocurrido un error / Búsqueda de insumos");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getSuggestions = (value = "") => {
    if (!Array.isArray(selectedElements)) {
      getProducts(value);
      return [];
    }
    const inputValue = document
      .getElementById("product_query")
      .value.trim()
      .toLowerCase();
    const inputLength = inputValue.length;

    var dataProduct =
      inputLength === 0
        ? []
        : selectedElements
            .filter(
              (product) =>
                product.nombre.toLowerCase().includes(inputValue) ||
                product.codigo.toLowerCase().includes(inputValue)
            )
            .filter((product) => !product.combo)
            .filter((product) => !product.id_padre)
            .slice(0, 10);

    if (dataProduct.length == 0) {
      getProducts(value);
    }
    return dataProduct;
  };

  const getSuggestionValue = (suggestion) => {
    let existingData = [...products_inputted];
    if (products_inputted.some((x) => suggestion.codigo === x.codigo)) {
      return suggestion.nombre;
    }

    setproducts_inputted(
      existingData.concat([
        {
          codigo: suggestion.codigo,
          cantidad: 1,
          cantidad_peso: 0,
          cantidad_piezas: 0,
          vendido_por: suggestion.vendido_por,
          nombre: suggestion.nombre,
        },
      ])
    );
    return suggestion.nombre;
  };

  const renderSuggestion = (suggestion) => {
    const matches = AutosuggestHighlightMatch(suggestion.nombre, filterText);
    const parts = AutosuggestHighlightParse(suggestion.nombre, matches);

    return (
      <div>
        {parts.map((part, index) => {
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  const changeFilterText = (e, { newValue }) => {
    setFilterText(newValue);
  };

  const onSuggestionsFetchRequested = (e = {}) => {
    setSuggestions(getSuggestions(e.value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Escribe el nombre del producto/insumo",
    value: filterText,
    onChange: changeFilterText,
    id: "product_query",
  };

  const handleInputChange = (name, index, event) => {
    let value = event.target.value;
    if (name === "codigo") {
      let existingData = [...products_inputted];
      existingData[index].codigo = value;
      setproducts_inputted(existingData);
    }
    if (name === "cantidad_peso") {
      value =
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
            value.substr(value.indexOf("."), 3)
          : value;
      let existingData = [...products_inputted];
      existingData[index].cantidad_peso = value;
      setproducts_inputted(existingData);
    }
    if (name === "cantidad_piezas") {
      value =
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
            value.substr(value.indexOf("."), 3)
          : value;
      let existingData = [...products_inputted];
      existingData[index].cantidad_piezas = value;
      setproducts_inputted(existingData);
    }
    if (name === "cantidad") {
      value =
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
            value.substr(value.indexOf("."), 3)
          : value;
      let existingData = [...products_inputted];
      existingData[index].cantidad = value;
      setproducts_inputted(existingData);
    }
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/inventario"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Inventario</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Crear Egreso</strong>
        </h4>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autocomplete="on">
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Creación de Egreso</strong>
                </h5>
                <p className="mb-0">
                  Registre una orden de egreso de sus productos.
                </p>
              </div>

              <div>
                <Button
                  type="submit"
                  className="btn-cs1_blue mx-1 mt-1"
                  variant="contained"
                  color="primary"
                  disabled={submitLoader}
                >
                  {submitLoader ? (
                    <div className="text-center">
                      <CircularProgress
                        size={26}
                        className="mx-auto"
                        style={{ color: "white" }}
                      />
                    </div>
                  ) : (
                    "Guardar"
                  )}
                </Button>
                <Button
                  className="btn-cs1 mx-1 mt-1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmExit"
                  disabled={submitLoader}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Tipo de egreso</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    native
                    className="cs-select1 select-w100"
                    value={egresoType}
                    onChange={(e) => setEgresoType(e.target.value)}
                    required={true}
                  >
                    <option value="">Seleccione el tipo de egreso</option>
                    {egreso_types.map((type) => (
                      <option value={type.value}>{type.descripcion}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Motivo del egreso</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    inputRef={register}
                    name="id_new_motivo"
                    native
                    className="cs-select1 select-w100"
                  >
                    <option value="">Motivo del egreso</option>
                    {egresosMotives.map((motive) => (
                      <option value={motive.id_motivo_web_app}>
                        {motive.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-3 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Sucursal que egresará</strong>
                  </label>
                  <Tooltip title="Seleccione la sucursal de la que egresará el producto.">
                    <InfoIcon
                      style={{
                        color: " #828282",
                        fontSize: "1.2rem",
                      }}
                    />
                  </Tooltip>
                </div>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    inputRef={register}
                    name="id_sucursal_bodega_ubicacion"
                    native
                    className="cs-select1 select-w100"
                  >
                    <option value="">Seleccione sucursal</option>
                    {sucursals.map((sucursal) => (
                      <option value={sucursal.id_sucursal_bodega_ubicacion}>
                        {sucursal.nombre_sucursal}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-3 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Comentario (opcional)</strong>
                  </label>
                  <Tooltip title="Indique el detalle del egreso">
                    <InfoIcon
                      style={{
                        color: " #828282",
                        fontSize: "1.2rem",
                      }}
                    />
                  </Tooltip>
                </div>
                <FormControl variant="outlined" className="w-100">
                  <TextareaAutosize
                    inputRef={register}
                    name="comentario"
                    value={comentario}
                    onChange={(e) => setComentario(e.target.value)}
                    aria-label="minimum heigth"
                    maxLength={180}
                    fullWidth
                    minRows={3}
                    placeholder="Indique el detalle del egreso"
                  />
                  {comentario.length === 180 && (
                    <span className="w-100 text-danger">
                      Máximo de caracteres alcanzado
                    </span>
                  )}
                </FormControl>
              </div>
              {/* <div className="col-md-2 mt-3">
                                <label style={{ fontSize: "0.8rem" }}>
                                    <strong>Número de egreso</strong>
                                </label>

                                <TextField
                                    inputRef={register}
                                    name="numero_egreso"
                                    type="text"
                                    className="w-100"
                                    placeholder="Ingrese el número de egreso"
                                />
                            </div> */}
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Fecha del egreso</strong>
                </label>

                <TextField
                  inputRef={register}
                  name="fecha_egreso"
                  type="text"
                  required={true}
                  defaultValue={moment(new Date()).format("DD/MM/YYYY hh:mm a")}
                  className="w-100"
                />
              </div>
              <div className="col-9" />

              {/* Second Row */}
              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Agregar productos</strong>
                  </label>
                </div>
                <p className="mb-2">
                  Agregue los productos, e ingrese las cantidades que egresarán.
                </p>
                <div className="row">
                  <div className="col-xl-2 p-0 my-auto text-center">
                    <IconButton
                      className="mx-1 mt-1 icon-btn-primary"
                      onClick={() => add_inputs()}
                    >
                      <AddIcon style={{ color: "white" }} />
                    </IconButton>
                  </div>
                  <div className="col-xl-10 p-0 my-auto py-2">
                    <div
                      class="input-group search-bar autosuggest-search-bar"
                      style={{ width: "100%" }}
                    >
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                      />
                      <div class="input-group-append">
                        <span class="input-group-text autosuggest-search-icon">
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8 mt-3">
                {products_inputted.map((input, index) => (
                  <div className="row">
                    <div className="col-md-1 mt-3 d-flex justify-content-center align-items-center">
                      <IconButton
                        className="btn-cs1 icon-btn-delete"
                        variant="contained"
                        color="primary"
                        onClick={() => remove_input(index)}
                      >
                        <DeleteIcon
                          style={{
                            color: "white",
                          }}
                        />
                      </IconButton>
                    </div>

                    <div
                      className={
                        input.vendido_por === "mixto"
                          ? "col-md-3 mt-3"
                          : "col-md-6 mt-3"
                      }
                    >
                      <label
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        <strong>Código del producto</strong>
                      </label>
                      <TextField
                        inputRef={register}
                        name={`product_code[${index}]`}
                        onChange={(event) =>
                          handleInputChange("codigo", index, event)
                        }
                        type="text"
                        className="w-100"
                        value={input.codigo}
                        placeholder="Ingrese SKU / código de barras del producto"
                      />
                    </div>

                    <div
                      className={`${
                        input.vendido_por === "mixto" ? "col-md-4" : "col-md-5"
                      } mt-3`}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>
                            Cantidad a egresar{" "}
                            {input.vendido_por === "libras"
                              ? "libras"
                              : "unidades"}
                          </strong>
                        </label>
                        <Tooltip title="Indique la cantidad de productos que saldrán de su inventario.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <TextField
                        inputRef={register}
                        name={`product_qty[${index}]`}
                        value={input.cantidad}
                        onChange={(event) =>
                          handleInputChange("cantidad", index, event)
                        }
                        type="number"
                        className="w-100"
                        placeholder="Indique la cantidad de productos a egresar"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">N°</InputAdornment>
                          ),
                          inputProps: {
                            min: 1,
                            step: "0.00001",
                          },
                        }}
                      />
                    </div>

                    <div
                      className="col-md-4 mt-3"
                      style={{
                        display:
                          input.vendido_por === "mixto" && isCmi()
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Cantidad a egresar en libras</strong>
                        </label>
                        <Tooltip title="Indique la cantidad de productos que saldrán de su inventario.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <TextField
                        inputRef={register}
                        name={`product_weight[${index}]`}
                        value={input.cantidad_peso}
                        onChange={(event) =>
                          handleInputChange("cantidad_peso", index, event)
                        }
                        type="number"
                        className="w-100"
                        placeholder="Indique la cantidad en libras a egresar"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">N°</InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            step: "0.00001",
                          },
                        }}
                      />
                    </div>

                    <div className="col-md-1 mt-3" />

                    <div
                      className={`${
                        input.vendido_por === "mixto" ? "col-md-3" : "col-md-6"
                      } mt-3`}
                    >
                      <label
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        <strong>Nombre del producto</strong>
                      </label>
                      <TextField
                        type="text"
                        className="w-100"
                        value={input.nombre}
                        placeholder="Nombre del producto"
                      />
                    </div>

                    <div
                      className="col-md-3 mt-3"
                      // style={{
                      //   display:
                      //     input.vendido_por === "mixto" && isCmi()
                      //       ? "block"
                      //       : "none",
                      // }}
                      style={{ display: "none" }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Cantidad a egresar en piezas</strong>
                        </label>
                        <Tooltip title="Indique la cantidad de productos que saldrán de su inventario.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <TextField
                        inputRef={register}
                        name={`product_piezas[${index}]`}
                        value={input.cantidad_piezas}
                        onChange={(event) =>
                          handleInputChange("cantidad_piezas", index, event)
                        }
                        type="number"
                        className="w-100"
                        placeholder="Indique la cantidad en piezas a egresar"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">N°</InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            step: "0.00001",
                          },
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
