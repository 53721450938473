import React, { useContext, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Select,
  TextField,
} from "@material-ui/core";
import { isCmi } from "../../../helpers/isCmi";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import API from "../../../api";
import "../custom.css";
import moment from "moment";
import { CalendarToday, ReportProblemOutlined } from "@material-ui/icons";
import { getMoneda } from "../../../helpers/moneda";
import { Link } from "react-router-dom";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import "./modal.css";
import ModalFondo from "../Fondo cambios";
import { aperturarCaja } from "../../../api/sales";
import CajaAperturadaModal from "./CajaAperturadaModal";
import CmiProductContext from "../../../context/CmiProductContext/CmiProductContext";

export default function ModalApertura({
  open,
  handleClose,
  fondoCambios,
  setFondoCambios,
  caja,
  codigo,
  setAperturaInfo,
  store,
  validarCorteCaja,
}) {
  const moneda = getMoneda();
  const { setActivarCierre, setIdSucursal } = useContext(CmiProductContext);
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const setApertura = async () => {
    const id_new_empresa = localStorage.getItem("id_empresa");
    const id_new_user = JSON.parse(localStorage.getItem("pms")).id_new_user;

    try {
      const { data } = await aperturarCaja({
        id_new_empresa,
        id_new_user,
        id_sucursal_bodega_ubicacion: store,
        codigo_operacion: codigo,
        apertura_fecha: moment(new Date()).format("YYYY-MM-DD"),
        fondo_cambios: fondoCambios,
      });
      if (data.ok) {
        setAperturaInfo({ ok: data.ok });
        return true;
      } else {
        setAperturaInfo({ ok: false });
        return false;
      }
    } catch (error) {
      console.log(error);
      setAperturaInfo({ ok: false });
    }
  };
  const [display, setDisplay] = useState(true);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className="card"
            style={{
              width: "800px",
              border: "none",
              display: display ? "block" : "none",
            }}
          >
            <div className="card-title d-flex justify-content-between align-items-center border-bottom py-3">
              <strong style={{ marginLeft: "17px" }}>
                <span style={{ fontSize: "20px" }}>Apertura de caja</span>
              </strong>
              <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body">
              <span style={{ fontWeight: 700 }}>
                Iniciar nuevo ciclo de ventas para empezar a vender
              </span>
              <div className="row mt-3">
                <div className="col-md-4">
                  <span style={{ fontWeight: 700, fontSize: "13px" }}>
                    N° de operación
                  </span>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">N°</InputAdornment>
                      ),
                    }}
                    value={codigo}
                  />
                </div>
                <div className="col-md-4">
                  <span style={{ fontWeight: 700, fontSize: "13px" }}>
                    Fecha de apertura
                  </span>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarToday />
                        </InputAdornment>
                      ),
                    }}
                    value={moment(new Date()).format("DD-MM-YYYY")}
                  />
                </div>
                <div className="col-md-4">
                  <div className="d-flex justify-content-between">
                    <span style={{ fontWeight: 700, fontSize: "13px" }}>
                      Fondo para cambios
                    </span>
                    <ModalFondo fondoCambios={fondoCambios} caja={caja} />
                  </div>

                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {moneda}.
                        </InputAdornment>
                      ),
                    }}
                    value={fondoCambios}
                    onChange={(e) =>
                      setFondoCambios(formaterPriceFunction(e.target.value))
                    }
                  />
                </div>
                {!validarCorteCaja && (
                  <div className="col-md-12 mt-4">
                    <div
                      className="row"
                      style={{
                        background: "#F2F2F2",
                        margin: "0px",
                        padding: "15px",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="col-1 d-flex align-items-center justify-content-center">
                        <span>
                          <ReportProblemOutlined
                            style={{ color: "#0160E7", fontSize: "30px" }}
                          />
                        </span>
                      </div>
                      <div className="col-8 d-flex align-items-center">
                        <span className="p-0">
                          Tienes un ciclo de ventas abierto correspondiente al
                          día {+moment(new Date()).format("DD") - 1} del mes en
                          curso. Por favor cierra el ciclo para iniciar uno
                          nuevo
                        </span>
                      </div>

                      <div className="col-3 d-flex align-items-center justify-content-center">
                        <Link
                          to="/app/cortes_caja/realizar_corte"
                          onClick={() => {
                            setActivarCierre(true);
                            setIdSucursal(store);
                          }}
                        >
                          <span
                            style={{
                              color: "#0160E7",
                              textDecoration: "none",
                            }}
                          >
                            Cerrar caja &nbsp; &nbsp;
                            <ArrowForwardIos />
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-end">
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px", height: "42px", width: "150px" }}
                onClick={handleClose}
                disabled={false}
              >
                <span
                  style={{
                    color: true ? "#0160E7" : "#BDBDBD",
                    fontSize: "14px",
                  }}
                >
                  Cancelar
                </span>
              </Button>
              &nbsp;
              {/* <Button
                variant="contained"
                style={{
                  background: true ? "#0160E7" : "#BDBDBD",
                  boxShadow: "none",
                  width: "150px",
                  height: "42px",
                }}
                disabled={false}
                type="submit"
                onClick={setApertura}
              >
                <span style={{ color: "#fff", fontSize: "14px" }}>
                  Aperturar caja
                </span>
              </Button> */}
              <CajaAperturadaModal
                setApertura={setApertura}
                setDisplay={setDisplay}
                closeParent={handleClose}
                validarCorteCaja={validarCorteCaja}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
