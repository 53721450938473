import React, { useContext, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import "../../custom.css";

import { CheckCircleOutlined, ReportProblemOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";
import CmiProductContext from "../../../../context/CmiProductContext/CmiProductContext";

export default function CamposErroneosModal({ message }) {
  const { modalErrorCampos: open, setModalErrorCampos: setOpen } =
    useContext(CmiProductContext);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="card" style={{ width: "500px", border: "none" }}>
            <div className="card-title d-flex justify-content-end align-items-center border-bottom py-3">
              <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
              <div className="w-100 d-flex justify-content-center">
                <ReportProblemOutlined
                  className="color-css-text"
                  style={{ fontSize: "60px" }}
                />
              </div>

              <h4 className="text-center">
                <strong>{message}</strong>
              </h4>
              <br />
              <p>Verifique la contraseña o campos ingresados</p>
            </div>
            <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-center">
              <Button
                variant="contained"
                id="back-css"
                style={{
                  boxShadow: "none",
                  width: "150px",
                  height: "42px",
                }}
                onClick={handleClose}
              >
                <span style={{ color: "#fff", fontSize: "14px" }}>Aceptar</span>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
