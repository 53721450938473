import React, { useState, useEffect } from "react";

//estilos
import "./reportMerma.css";

//api
import API, { base_url_images } from "../../../../api";

//componentes
import ProductMerma from "./productMerma";
import TimeFilter from "../../../../components/timeFilter/timeFilter";
import Leyenda from "../../../../components/leyenda/leyenda";
import ModalDatePiker from "../../../../components/modalDatePiker/modalDatePiker";

//elementos
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

//Icons
import SearchIcon from "@material-ui/icons/Search";
import { getMoneda } from "../../../../helpers/moneda";

const ReportMerma = () => {
  const moneda = getMoneda();
  const [search, setSearch] = useState("");
  const [filterSelected, setFilterSelected] = useState("year");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  //objetos
  const [mermasData, setMermasData] = useState([]);
  const [mermaSelected, setMermaSelected] = useState(null);
  const [savedMermaSelected, setSavedMermaSelected] = useState(null);

  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (
      filterSelected === "custom" &&
      dateState[0].startDate &&
      dateState[0].endDate &&
      dateState[0].startDate !== dateState[0].endDate
    ) {
      setOpen(false);
      if (search !== "") {
        getData(dateState[0].startDate, dateState[0].endDate);
      }
    }
  }, [dateState]);

  useEffect(() => {
    if (
      (filterSelected === "custom" &&
        dateState[0].startDate &&
        dateState[0].endDate) ||
      filterSelected !== "custom"
    ) {
      if (search !== "") {
        getData(dateState[0].startDate, dateState[0].endDate);
      }
    }
  }, [filterSelected]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const searchEvent = () => {
    if (search !== "" && !loading) {
      if (
        (filterSelected === "custom" &&
          dateState[0].startDate &&
          dateState[0].endDate) ||
        filterSelected !== "custom"
      ) {
        getData(dateState[0].startDate, dateState[0].endDate);
      }
    }
  };

  const leyendaLista = [
    {
      label: `Mermas estimadas (${moneda}.)`,
      color: "#0160E7",
    },
    {
      label: `Ventas (${moneda}.)`,
      color: "#08BFE6",
    },
  ];

  const getData = (start, end) => {
    setLoading(true);
    setMermaSelected(null);
    setMermasData([]);

    let startDate;
    let endDate;

    if (start && end) {
      startDate = start;
      endDate = end;
    } else {
      let fecha = new Date();
      fecha.setDate(fecha.getDate() - 7);
      startDate = fecha;
      endDate = fecha;
    }

    API.reporteMermas
      .getAllDataMermas(search, filterSelected, startDate, endDate)
      .then((res) => {
        setMermasData(res.data.data);
        setLoading(false);

        if (savedMermaSelected) {
          let savedMerma = res.data.data.filter(
            (x) =>
              x.empresa.nombre === savedMermaSelected.empresa.nombre &&
              x.sucursal.nombre_sucursal ===
                savedMermaSelected.sucursal.nombre_sucursal
          );
          setMermaSelected(savedMerma[0]);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        let response = err.response;
        if (response.data) {
          toast.error(
            "Fecha inicio y fin no puede ser mayor a la fecha de hoy",
            { autoClose: 10000 }
          );
          return;
        }
        if (!response) {
          toast.error("Ha ocurrido un error al obtener las mermas.", {
            autoClose: 10000,
          });
          return;
        }

        if (typeof response.data.message !== "undefined") {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const CustomTooltip = ({ active, payload }) => {
    let value1 = 0;
    let value2 = 0;
    if (payload.length > 0) {
      value1 = payload[1].value;
      value2 = payload[0].value;
    }

    if (active) {
      return (
        <div className="card">
          <div className="card-body border-none">
            <div className="w-100">
              <span>
                <strong>Mermas estimadas ({moneda}.)</strong>
              </span>
              <div className="w-100 d-flex align-items-center">
                <div
                  style={{
                    height: 9,
                    width: 9,
                    borderRadius: "50%",
                    background: "#0160E7",
                  }}
                  className="mr-1"
                />
                {moneda}.{" " + value1}
              </div>
            </div>

            <div className="w-100">
              <span>
                <strong>Ventas ({moneda}.)</strong>
              </span>
              <div className="w-100 d-flex align-items-center">
                <div
                  style={{
                    height: 9,
                    width: 9,
                    borderRadius: "50%",
                    background: "#08BFE6",
                  }}
                  className="mr-1"
                />
                {moneda}.{" " + value2}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderGrafica = () => {
    if (mermaSelected) {
      return (
        <>
          <div className="reporteMerma-headerTable">
            <span>Ventas ({moneda}.)</span>
            <span>Mermas estimadas ({moneda}.)</span>
          </div>

          <ResponsiveContainer width="100%" height={250}>
            <LineChart
              data={mermaSelected.results}
              margin={{
                top: 30,
                right: 40,
                left: 0,
                bottom: 0,
              }}
            >
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis yAxisId="1" tickFormatter={(text) => `${text} .`} />
              <YAxis
                yAxisId="2"
                dataKey="mermas"
                orientation="right"
                type="number"
                yAxisId="2"
              />
              <Tooltip />
              <Line
                yAxisId="1"
                type="monotone"
                dataKey="subtotal"
                stroke="#08BFE6"
                activeDot={{ r: 8 }}
              />
              <Line
                yAxisId="2"
                type="monotone"
                dataKey="mermas"
                stroke="#0160E7"
              />
            </LineChart>
          </ResponsiveContainer>

          <Leyenda lista={leyendaLista}></Leyenda>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
        <div>
          <h4 className="mb-0">
            <strong>Histórico de mermas</strong>
          </h4>
          <p className="mb-0">
            Consulte y visualice la relación ventas - mermas
          </p>
        </div>

        <div>
          <div className="input-group search-bar">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar producto"
              style={{ width: "300px" }}
              value={search}
              autoFocus
              disabled={loading}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div
              className="input-group-append"
              onClick={() => {
                searchEvent();
              }}
            >
              <span className="input-group-text">
                <SearchIcon />
              </span>
            </div>
          </div>
        </div>
      </div>

      {!loading && (
        <>
          <div className="productMermContenedor">
            {mermasData.map((x, index) => {
              return (
                <ProductMerma
                  setMermaSelected={setMermaSelected}
                  setSavedMermaSelected={setSavedMermaSelected}
                  merma={x}
                  loading={loading}
                  key={index}
                ></ProductMerma>
              );
            })}
          </div>
        </>
      )}

      <TimeFilter
        loading={loading}
        openModal={handleClickOpen}
        selected={filterSelected}
        changeSelected={setFilterSelected}
      ></TimeFilter>

      {loading ? (
        <div className="text-center">
          <CircularProgress size={26} className="mx-auto" />
        </div>
      ) : (
        <>{renderGrafica()}</>
      )}

      <ModalDatePiker
        state={dateState}
        setState={setDateState}
        open={open}
        onClose={handleClose}
      ></ModalDatePiker>
    </div>
  );
};

export default ReportMerma;
