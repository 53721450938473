//in this file , we make reference to all apis calls coming from the api folder
import auth from "./api/auth";
import cajas from "./api/cajas";
import clients from "./api/clients";
import dashboard from "./api/dashboard";
import documentTypes from "./api/documentTypes";
import egresos from "./api/egresos";
import historial_operaciones from "./api/historial_operaciones";
import ingresos from "./api/ingresos";
import invoices from "./api/invoices";
import notifications from "./api/notifications";
import products from "./api/products";
import profile from "./api/profile";
import sales from "./api/sales";
import subscriptions from "./api/subscriptions";
import sucursal from "./api/sucursals";
import supplies from "./api/supplies";
import traslados from "./api/traslados";
import userConfig from "./api/userConfig";
import users from "./api/users";
import priceLevels from "./api/priceLevels";
import attentionChannels from "./api/attentionChannels";
import reporteMermas from "./api/reporteMermas";
import directions from "./api/directions";
import informesAleko from "./api/informesAleko";

//Super Admin
import admin_invoices from "./api/admin/invoices";
import admin_user from "./api/admin/user";
import admin_clients from "./api/admin/clients";
import admin_memberships from "./api/admin/memberships";
import admin_stats from "./api/admin/stats";

//googleApi
import reviews from "./api/reviews";

const axios_package = require("axios");

export const base_url = process.env.REACT_APP_BASE_URL;
export const base_url_images = process.env.REACT_APP_BASE_URL_IMAGES;
export const admin_base_url = process.env.REACT_APP_ADMIN_BASE_URL;

axios_package.interceptors.response.use(
  undefined,
  function axiosRetryInterceptor(err) {
    switch (err.response.status) {
      case 401:
        if (!!localStorage.getItem("token")) {
          localStorage.removeItem("token");
        } else if (!!localStorage.getItem("AdminToken")) {
          localStorage.removeItem("AdminToken");
        }
        localStorage.setItem("invalid_token", err.response.data.message);
        window.location.reload();
        break;

      default:
        break;
    }
    return Promise.reject(err);
  }
);

export default {
  auth,
  dashboard,
  clients,
  userConfig,
  traslados,
  products,
  users,
  ingresos,
  egresos,
  historial_operaciones,
  sucursal,
  sales,
  cajas,
  documentTypes,
  notifications,
  invoices,
  profile,
  subscriptions,
  supplies,
  priceLevels,
  attentionChannels,
  admin_user,
  admin_invoices,
  admin_clients,
  admin_memberships,
  admin_stats,
  reviews,
  reporteMermas,
  directions,
  informesAleko,
};

export const axios = axios_package;

export const realImageURL = (url) => {
  if (!url) return null;
  return base_url_images + url;
};
