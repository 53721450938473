import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import API from "../../../api";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";

// components

export default function Users_all(props) {
  // states
  const { register, handleSubmit } = useForm();
  const [saveLoader, setSaveLoader] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [membresia, setMembresia] = useState();
  const [clientFilterText, setClientFilterText] = useState("");
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [loader, setLoader] = useState(true);
  const [usersLoader, setUsersLoader] = useState(true);
  const [periods, setPeriods] = useState([]);
  let [promotion_active, setPromotionActive] = useState(false);

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {};
  }, []);

  //component did mount
  useEffect(() => {
    getPeriods();
    if (!Number.isInteger(parseInt(props.match.params.id))) {
      toast.error("Falta el parametro ID para buscar el plan.", {
        autoClose: 10000,
      });
      props.history.goBack();
    }
  }, []);

  useEffect(() => {
    if (periods.length > 0) {
      getMembership();
      getUsers();
    }
  }, [periods]);

  const save = (data) => {
    data.promotion_is_active = promotion_active;
    setSaveLoader(true);
    API.admin_memberships
      .updatePlan({
        id_planes: parseInt(props.match.params.id),
        descripcion: data.descripcion,
        numero_mes: parseInt(data.numero_mes),
        periodo: parseInt(data.periodo),
        monto: parseFloat(data.monto),
        promotion_is_active: data.promotion_is_active,
        precio_promocional:
          data.precio_promocional !== undefined ? data.precio_promocional : 0,
        fecha_promocion_inicio:
          data.fecha_promocion_inicio !== undefined
            ? data.fecha_promocion_inicio
            : "",
        fecha_promocion_final:
          data.fecha_promocion_final !== undefined
            ? data.fecha_promocion_final
            : "",
      })
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          toast.success(response.message, { autoClose: 10000 });
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al actualizar el plan", {
              autoClose: 10000,
            });
          }
        }
        setSaveLoader(false);
      })
      .catch((err) => {
        setSaveLoader(false);
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al actualizar el plan", {
          autoClose: 10000,
        });
      });
  };

  //function
  const filterClients = (e) => {
    setClientFilterText(e.target.value);
    setFilteredClients(
      clients.filter((item) =>
        item.nombre.toLowerCase().includes(clientFilterText.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredClients(clients);
    }
  };

  const getMembership = () => {
    API.admin_memberships
      .getPlan(props.match.params.id)
      .then((res) => {
        let response = res.data;

        if (response.success) {
          if (response.data.promotion_is_active) {
            setPromotionActive(true);
            promotion_active = true;
            response.data.fecha_promocion_inicio =
              response.data.fecha_promocion_inicio.split("T")[0];
            response.data.fecha_promocion_final =
              response.data.fecha_promocion_final.split("T")[0];
          }
          console.log(response.data);
          setMembresia(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning(
              "Ha ocurrido un error al obtener detalles de los planes",
              { autoClose: 10000 }
            );
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener detalles de los planes", {
          autoClose: 10000,
        });
      });
  };

  const getUsers = () => {
    API.admin_memberships
      .getUsersWithMembership(props.match.params.id)
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setClients(response.data);
          setFilteredClients(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning(
              "Ha ocurrido un error al obtener detalles de los planes",
              { autoClose: 10000 }
            );
          }
        }
        setUsersLoader(false);
      })
      .catch((err) => {
        setUsersLoader(false);
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener detalles de los planes", {
          autoClose: 10000,
        });
      });
  };

  const ChangePromotionState = () => {
    setPromotionActive(!promotion_active);
    membresia.promotion_is_active = !promotion_active;
  };

  const getPeriods = () => {
    API.userConfig
      .getPeriods()
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          setPeriods(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener los periodos", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener los periodos", {
          autoClose: 10000,
        });
      });
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <Button
          className="btn-cs1 mx-1 mt-1"
          variant="contained"
          color="primary"
          to={"/admin/membresia"}
          component={Link}
        >
          <ArrowBackIosIcon className="mr-1" style={{ fontSize: "16px" }} />
          Volver
        </Button>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Membresías</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Detalle de membresía</strong>
        </h4>
      </div>

      {/* write content here "whithout {}"*/}
      <form className="content-card card mt-3" onSubmit={handleSubmit(save)}>
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h5 className="mb-0">
                {loader ? (
                  <div className="my-auto">
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      color="white"
                    />
                  </div>
                ) : (
                  <strong>{membresia.descripcion}</strong>
                )}
              </h5>
            </div>

            <div>
              <Button
                className="btn-cs1 mx-1 mt-1 delete-btn"
                variant="contained"
                color="primary"
                component={Link}
                to={"/admin/membresia"}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="btn-cs1 mx-1 mt-1"
                variant="contained"
                color="primary"
              >
                {saveLoader ? (
                  <CircularProgress
                    size={26}
                    className="mx-auto"
                    color="white"
                  />
                ) : (
                  "Guardar"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" color="white" />
            </div>
          ) : (
            <div className="row mb-4">
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Nombre de la membresía</strong>
                </label>

                <TextField
                  type="text"
                  className="w-100"
                  placeholder="Asigne un nombre para la membresía"
                  inputRef={register}
                  name="descripcion"
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={membresia.descripcion}
                />
              </div>
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Asignar frecuencia de pago (en meses)</strong>
                </label>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    name="periodo"
                    native
                    inputRef={register}
                    className="cs-select1 select-w100"
                    required={true}
                    defaultValue={membresia.periodo}
                  >
                    <option value="">Seleccione la frecuencia de pago</option>
                    {periods.map((period) => (
                      <option value={period.id_periodo}>
                        {period.descripcion}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Precio de la membresía</strong>
                </label>

                <TextField
                  type="number"
                  className="w-100"
                  placeholder="0"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Q.</InputAdornment>
                    ),

                    inputProps: { min: 0, step: "0.01" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register}
                  required={true}
                  name="monto"
                  defaultValue={membresia.monto}
                />
              </div>

              {/* Second Row */}
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Duracion (en meses):</strong>
                </label>

                <TextField
                  type="number"
                  className="w-100"
                  placeholder="Introduzca la duración de la suscripción"
                  inputRef={register}
                  name="numero_mes"
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon style={{ color: "#0160E7" }} />
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={membresia.numero_mes}
                />
              </div>
              <div className="col-md-3 mt-3">
                <label style={{ fontSize: "0.8rem" }}>
                  <strong>Promoción</strong>
                </label>

                <label
                  class="toggle-control mx-auto"
                  style={{ left: "-15px", height: "40px" }}
                >
                  <input
                    type="checkbox"
                    checked={promotion_active}
                    onChange={ChangePromotionState}
                  />
                  <span class="control" />
                </label>
              </div>
              {promotion_active ? (
                <div className="col-md-3 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Precio Promocional</strong>
                  </label>

                  <TextField
                    type="number"
                    className="w-100"
                    placeholder="0"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EditIcon style={{ color: "#0160E7" }} />
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">Q.</InputAdornment>
                      ),
                      inputProps: { min: 0, step: "0.01" },
                    }}
                    inputRef={register}
                    required={true}
                    name="precio_promocional"
                    defaultValue={
                      membresia.precio_promocional !== undefined
                        ? membresia.precio_promocional
                        : ""
                    }
                  />
                </div>
              ) : null}
              {promotion_active ? (
                <div className="col-md-3 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Inicio de la Promoción</strong>
                  </label>

                  <TextField
                    inputRef={register}
                    type="date"
                    className="w-100"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EditIcon style={{ color: "#0160E7" }} />
                        </InputAdornment>
                      ),
                    }}
                    required={true}
                    name="fecha_promocion_inicio"
                    defaultValue={
                      membresia.fecha_promocion_inicio !== undefined
                        ? membresia.fecha_promocion_inicio
                        : ""
                    }
                  />
                </div>
              ) : null}
              {promotion_active ? (
                <div className="col-md-3 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Fin de la Promoción</strong>
                  </label>

                  <TextField
                    inputRef={register}
                    type="date"
                    className="w-100"
                    required={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EditIcon style={{ color: "#0160E7" }} />
                        </InputAdornment>
                      ),
                    }}
                    name="fecha_promocion_final"
                    defaultValue={
                      membresia.fecha_promocion_final !== undefined
                        ? membresia.fecha_promocion_final
                        : ""
                    }
                  />
                </div>
              ) : null}
            </div>
          )}

          <div className="row mt-2 mb-3">
            <div
              className="col-md-2 px-2"
              style={{ borderRight: "1px solid #E0E0E0" }}
            >
              <p
                className="mb-1"
                style={{ fontSize: "0.85rem", color: "black" }}
              >
                Total de clientes
              </p>
              <h6 className="mb-0" style={{ color: "#0160E7" }}>
                <strong>
                  {usersLoader ? (
                    <div className="text-center">
                      <CircularProgress
                        size={26}
                        className="mx-auto"
                        color="white"
                      />
                    </div>
                  ) : (
                    clients.length
                  )}
                </strong>
              </h6>
            </div>

            <div className="col-md-5" />
            <div className="col-md-5">
              <div class="input-group search-bar">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar cliente por nombre"
                  value={clientFilterText}
                  onChange={(event) => filterClients(event)}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {usersLoader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" color="white" />
            </div>
          ) : (
            <DataTable
              className="datatable-cs datatable-cs-v2 mt-2 sucursal-table"
              title={"s"}
              columns={[
                {
                  name: "Nombre del cliente",
                  selector: "nombre",
                  sortable: true,
                },
                { name: "NIT", selector: "nit", sortable: true },
                {
                  name: "Dirección Principal",
                  selector: "direccion",
                  sortable: true,
                },
                {
                  name: "Sucursales",
                  selector: "numero_sucursales",
                  cell: (row) => (
                    <div className="w-100 text-center">
                      {row.numero_sucursales}
                    </div>
                  ),
                  sortable: true,
                },
                {
                  name: "N° Usuarios",
                  selector: "numero_usuarios",
                  cell: (row) => (
                    <div className="w-100 text-center">
                      {row.numero_usuarios}
                    </div>
                  ),
                  sortable: true,
                },
                {
                  name: "Facturación",
                  selector: "fecha_inicio",
                  sortable: true,
                  cell: (row) => (
                    <div className="w-100 text-center">
                      <span className="p-2 warning-span">
                        {moment(row.fecha_inicio).format("DD/MM/YYYY")}
                      </span>
                    </div>
                  ),
                },
                {
                  name: "Vencimiento",
                  selector: "estado_membresia",
                  sortable: true,
                  cell: (row) => (
                    <div className="w-100 text-center">
                      {row.estado_membresia === "Por vencer" ? (
                        <span className="p-2 warning-span">
                          {moment(row.fecha_final).format("DD/MM/YYYY")}
                        </span>
                      ) : (
                        ""
                      )}
                      {row.estado_membresia === "Vencida" ? (
                        <span className="p-2 danger-span">
                          {moment(row.fecha_final).format("DD/MM/YYYY")}
                        </span>
                      ) : (
                        ""
                      )}
                      {row.estado_membresia === "Vigente" ? (
                        <span className="p-2 success-span">
                          {moment(row.fecha_final).format("DD/MM/YYYY")}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ),
                },
                {
                  name: "",
                  selector: "action",
                  cell: (row) => (
                    <div className="text-center w-100">
                      <Button
                        className="brand2-btn"
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowForwardIosIcon />}
                        component={Link}
                        to={`/admin/clients/profile/${row.id_new_empresa}`}
                        size="small"
                      >
                        <span
                          style={{ marginBottom: "-4px", fontSize: "12px" }}
                        >
                          Ver detalles
                        </span>
                      </Button>
                    </div>
                  ),
                },
              ]}
              data={filteredClients}
              pagination
              pagination
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se ha encontrado ningún resultado"
            />
          )}
        </div>
      </form>
    </div>
  );
}
