import React from "react";
import ProductPerspective from "./ProductPerspective";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button } from "@material-ui/core";
import { saveAs } from "file-saver";
import { getMoneda } from "../../../../../helpers/moneda";
import ImgDownload from "../../../../CajaVentaAleko/components/image.download";
import { realImageURL } from "../../../../../api";

// const products = [
//   {
//     cantidad: 5,
//     nombre: "Camisa blanca personalizada (Algodón)",
//     talla: "M",
//     precio: 850.33,
//     perspectives: [
//       {
//         perspective: "frente",
//         image:
//           "https://sc01.alicdn.com/kf/HTB1KE5DXyrxK1RkHFCcq6AQCVXag/234259482/HTB1KE5DXyrxK1RkHFCcq6AQCVXag.jpg_.webp",

//         areas: [
//           {
//             nombre: "Frente (Bordado)",
//             ancho: 10,
//             alto: 10,
//             file: "https://fedequinas.org/wp-content/uploads/2020/10/NEGRA-ATRAS-scaled.jpg",
//             x: 10,
//             y: 50,
//             color: "#54b0bf",
//             tipo: "imagen",
//           },
//         ],
//       },
//       {
//         perspective: "Atras",
//         image:
//           "https://fedequinas.org/wp-content/uploads/2020/10/NEGRA-ATRAS-scaled.jpg",
//         color: "#54b0bf",
//         areas: [],
//       },
//     ],
//   },
//   {
//     cantidad: 5,
//     nombre: "Camisa blanca personalizada (Algodón)",
//     talla: "M",
//     precio: 850.33,
//     perspectives: [
//       {
//         perspective: "frente",
//         image:
//           "https://sc01.alicdn.com/kf/HTB1KE5DXyrxK1RkHFCcq6AQCVXag/234259482/HTB1KE5DXyrxK1RkHFCcq6AQCVXag.jpg_.webp",

//         areas: [
//           {
//             nombre: "Frente (Bordado)",
//             ancho: 10,
//             alto: 10,
//             file: "https://apitest.cloudpos.gt/images/2021-07-27T17-45-58.478Z-$img_1$Camisa-adelante.JPG",
//             x: 10,
//             y: 50,
//             color: "#54b0bf",
//             tipo: "imagen",
//           },
//         ],
//       },
//       {
//         perspective: "Atras",
//         image:
//           "https://fedequinas.org/wp-content/uploads/2020/10/NEGRA-ATRAS-scaled.jpg",
//         color: "#54b0bf",
//         areas: [],
//       },
//     ],
//   },
// ];

const ProductsDetail = ({ currentOrder, firma }) => {
  const moneda = getMoneda();
  const download = (uri, name) => {
    const format = uri.split(".")[uri.split(".").length - 1];
    saveAs(uri, `${name.split(" ").join("-")}.${format}`);
  };
  const products = Object.entries(currentOrder.data).map((item) => item[1]);
  const colors = ["#08BFE6", "#0160E7", "#219653", "#fff200"];
  return (
    <div className="row">
      <div className="col-md-1">
        <h6>Cant.</h6>
      </div>
      <div className="col-md-5">
        <h6>Nombre del producto</h6>
      </div>
      <div className="col-md-2">
        <h6>Talla</h6>
      </div>
      <div className="col-md-2">
        <h6>Color</h6>
      </div>
      <div className="col-md-2">
        <h6>Precio</h6>
      </div>
      <div className="col-md-12">
        <hr />
      </div>
      <div className="col-md-12">
        <br />
      </div>

      {products.map((item) => (
        <>
          <div className="col-md-1">
            <h6> {item[0].cantidad_producto} unit.</h6>
          </div>
          <div className="col-md-5">
            <h6> {item[0].nombre}</h6>
          </div>
          <div className="col-md-2">
            <h6>{item[0].valor_talla}</h6>
          </div>
          <div className="col-md-2">
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: "50%",
                background: item[0].valor_color,
              }}
            />
          </div>
          <div className="col-md-2">
            <h6>
              {" "}
              {moneda}.{" "}
              {Math.max(...item.map((item) => item.valor_total)).toFixed(2)}
            </h6>
          </div>
          <div className="col-md-4">
            <div className="row">
              {item.map((perspective, i) => (
                <div className="col-md-6r">
                  <ProductPerspective
                    item={perspective}
                    key={i}
                    perspectives={item?.perspectives}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-8">
            <div className="row p-0 m-0">
              {item.map((itemA) => {
                const variante = itemA.variante.split(" ").join("_");
                const images = Object.entries(itemA)
                  .filter((img) => img[0].includes("images"))
                  .map((imgB) => imgB[1])
                  .filter((x) => x.includes(variante));

                const entries = Object.entries(itemA)
                  .filter((i) => i[0].includes("nombre_area"))
                  .map((x) => x[1])
                  .filter((x) => x);
                return entries.map((area, index) => (
                  <>
                    <div className="col-md-3 d-flex flex-wrap">
                      <div className="w-100 d-flex">
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            background: colors[index],
                          }}
                          className="pr-1"
                        />
                        {itemA[`nombre_area_${index + 1}`]}
                      </div>
                      <div className="w-100">
                        <ImgDownload
                          width="50px"
                          height="50px"
                          uri={realImageURL(images[index])}
                        />
                      </div>
                      <div className="w-100">
                        Observaciones <br />
                        {itemA[`observaciones_${index + 1}`]
                          ? itemA[`observaciones_${index + 1}`]
                          : "Ninguna"}
                      </div>
                    </div>
                    <div className="col-md-3">
                      Tipo <br />
                      {itemA[`personalizacion_tipo_${index + 1}`]}
                    </div>
                    <div className="col-md-3">
                      Dimensiones: <br /> Ancho:{" "}
                      {itemA[`medidas_ancho_${index + 1}`]} cm <br />
                      Alto: {itemA[`medidas_alto_${index + 1}`]} cm
                    </div>
                    <div className="col-md-3"></div>
                  </>
                ));
              })}
            </div>
          </div>
        </>
      ))}

      <div className="col-md-12 d-flex justify-content-center align-items-center mt-5">
        <div
          style={{ height: "200px", width: "60%" }}
          className="border text-center"
        >
          <div>Firma del cliente</div>
          {firma && <img src={firma} />}
        </div>
      </div>
    </div>
  );
};

export default ProductsDetail;
