import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import {
  FlexContainer,
  BigTitle,
  CancelButton,
  ContinueButton,
  ButtonCotizador,
} from "./styled/styled.components";
import { useHistory } from "react-router-dom";
import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ModalTableCotizacion from "./CotizacionComponents/ModalTableCotizacion";
import { getMoneda } from "../../../helpers/moneda";
import CancelModal from "./CancelModal";

const CustomizationActions = () => {
  const { submitForms, id_pedido, clearState } = useContext(
    ProductPersonalizationContext
  );
  const [totalCotizacion, setTotalCotizacion] = useState(0.0);
  const history = useHistory();
  useEffect(() => {
    if (!id_pedido) {
      history.push("/app/vender");
    }
  }, []);

  const continueClick = () => {
    submitForms(history);
  };

  return (
    <>
      <FlexContainer gridColumn="1/-1">
        <BigTitle fontSize="25px">Personalizacion de productos</BigTitle>
        <FlexContainer margin="0 0 0 auto">
          <ButtonCotizador
            border="#7e8ad1"
            color="#7e8ad1"
            onClick={(e) => $("#modal_show_cotization").modal()}
          >
            <span>
              <ShoppingCartIcon color="#7e8ad1" />
            </span>{" "}
            Cotizador ({getMoneda()}. {totalCotizacion})
          </ButtonCotizador>
          <CancelModal clearState={clearState} />
          <ContinueButton onClick={continueClick} fontSize="16px">
            Continuar
          </ContinueButton>
        </FlexContainer>
      </FlexContainer>

      {/* Modal para ver cotizacion */}
      <div
        className="modal fade bd-example-modal-lg"
        id="modal_show_cotization"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <ModalTableCotizacion setTotalCotizacion={setTotalCotizacion} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomizationActions;
