import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import $ from "jquery";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DeleteIcon from "@material-ui/icons/Delete";
import Cards from "react-credit-cards";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import { BlockPicker } from "react-color";
import { CircularProgress } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";
import { base_url_images } from "../../api";
import { useForm } from "react-hook-form";

// styles
import "./custom.css";
import "react-credit-cards/es/styles-compiled.css";

// components

//data

const default_colors = ["#1A5D9D", "#0160E7"];

var enterprise_info = {
  name: "Nombre de la empresa",
  address:
    " Av. 7 con calle 6 y calle 7. Zona Industrial, Guatemala, Guatemala",
  employees: {
    registeredUsers: 12,
    tenderos: 8,
    admins: 4,
  },
  paymentMethods: [
    {
      id: "1",
      cardNumber: "5899415824532371",
      name: "Alejandro Aguilar",
      expiry: "02/11",
      cvc: "137",
      renovacionAutomatica: true,
    },
    {
      id: "2",
      cardNumber: "5899416933710429",
      name: "Alejandro Piña",
      expiry: "05/10",
      cvc: "139",
      renovacionAutomatica: false,
    },
  ],
};

const ERROR_MESSAGES = {
  emptyCardNumber: "El número de la tarjeta es inválido",
  invalidCardNumber: "El número de la tarjeta es inválido",
  emptyExpiryDate: "La fecha de expiración es inválida",
  monthOutOfRange: "El mes de expiración debe estar entre 01 y 12",
  yearOutOfRange: "El año de expiración no puede estar en el pasado",
  dateOutOfRange: "La fecha de expiración no puede estar en el pasado",
  invalidExpiryDate: "La fecha de expiración es inválida",
  emptyCVC: "El código de seguridad es inválido",
  invalidCVC: "El código de seguridad es inválido",
};

export default function EnterpriseInfo(props) {
  const moment = require("moment");
  const { register, handleSubmit } = useForm();
  // states
  const [tab, setTab] = useState(0);
  const [renovacionAutomatica, setRenovacionAutomatica] = useState(false);
  const [new_renovacionAutomatica, setNewRenovacionAutomatica] =
    useState(false);
  const [guardarMetodo, setGuardarMetodo] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [cvc, setCVC] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [displayAddSection, setDisplayAddSection] = useState(false);
  const [displayEditSection, setDisplayEditSection] = useState(false);
  const [cardNumberDisplay, setCardNumberDisplay] = useState("");
  const [nameDisplay, setNameDisplay] = useState("");
  const [expiryDisplay, setExpiryDisplay] = useState("");
  const [paymentIndex, setPaymentIndex] = useState(0);
  const [currentCardNumber, setCurrentCardNumber] = useState("");
  const [currentCardName, setCurrentCardName] = useState("");
  const [currentCVC, setCurrentCVC] = useState("");
  const [currentExpiry, setCurrentExpiry] = useState("");
  const [currentRenovacion, setCurrentRenovacion] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(null);
  const [secondaryColor, setSecondaryColor] = useState(null);
  const [profilePic, setProfilePic] = useState(
    base_url_images + "images/placeholder-image.jpeg"
  );
  const [companyData, setCompanyData] = useState({});
  const [loader, setLoader] = useState(true);
  const [usersLoader, setUsersLoader] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState({
    plan: {
      descripcion: "",
      numero_mes: "",
    },
  });
  const [users, setUsers] = useState([]);
  const [graphSeries, setGraphSeries] = useState([]);
  const [subscriptionDates, setSubscriptionsDates] = useState({
    days: "",
    hours: "",
    minutes: "",
  });

  const {
    meta,
    getCardNumberProps,
    wrapperProps,
    getCardImageProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  });

  // clean up modal shizzle wizzle
  useEffect(() => {
    return () => {
      $("#deleteModal").modal("hide");
      $("#primary-color-modal").modal("hide");
      $("#secondary-color-modal").modal("hide");
      $("#confirmCancellation").modal("hide");
    };
  }, []);

  useEffect(() => {
    if (subscriptionData.fecha_final !== "") {
      const interval = setInterval(() => {
        updateRemainingTime(subscriptionData.fecha_final);
      }, 60000); // 60K MS = 1 second
      return () => clearInterval(interval);
    }
  }, [subscriptionData]);

  useEffect(() => {
    getCompanyData();
    get_users();
    userSubscriptions();
  }, []);

  const handleTabs = (event, newValue) => {
    setTab(newValue);
  };

  const switchRenovacion = () => {
    setRenovacionAutomatica(!renovacionAutomatica);
    let newValue = !renovacionAutomatica;
    console.log("newValue is", newValue);
    API.subscriptions
      .updateAutoRenewal({
        id_movimiento_planes: subscriptionData.id_movimiento_planes,
        auto_renewal: newValue ? 1 : 0,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          /* toast.success(response.message, {
						autoClose: 10000,
					}); */
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const switchNewRenovacion = () => {
    setNewRenovacionAutomatica(!new_renovacionAutomatica);
  };

  const switchGuardar = () => {
    setGuardarMetodo(!guardarMetodo);
  };

  const switchAddDisplay = () => {
    setDisplayAddSection(!displayAddSection);
    setTimeout(() => {
      if (displayAddSection) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }, 100);
  };

  const setCurrentMethodValues = (id) => {
    let currentMethod = subscriptionData.cards.find(
      (x) => x.id_suscripcion_tarjeta === id
    );
    setCurrentCardNumber(currentMethod.card_number);
    setCurrentCardName(currentMethod.card_name);
    setCurrentExpiry(currentMethod.fecha_exipartion);
    setCurrentCVC(currentMethod.cvc);
  };

  const switchEditDisplay = () => {
    setDisplayEditSection(!displayEditSection);
    setCurrentMethodValues(
      subscriptionData.cards[paymentIndex].id_suscripcion_tarjeta
    );
    setTimeout(() => {
      if (displayEditSection) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }, 100);
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "cvc":
        setCVC(value);
        break;
      case "expiry":
        setExpiry(value);
        break;
      case "name":
        setName(value);
        break;
      case "number":
        setNumber(value);
        break;
      default:
        break;
    }
  };

  const editMethod = (e) => {
    const { name, value, checked } = e.target;
    switch (name) {
      case "cvc":
        setCurrentCVC(value);
        break;
      case "expiry":
        setCurrentExpiry(value);
        break;
      case "name":
        setCurrentCardName(value);
        break;
      case "number":
        setCurrentCardNumber(value);
        break;
      case "renovacion":
        setCurrentRenovacion(checked);
        break;
      default:
        break;
    }
  };

  const selectPaymentMethod = () => {
    API.subscriptions
      .selectDefaultCard({
        id_suscripcion_tarjeta:
          subscriptionData.cards[paymentIndex].id_suscripcion_tarjeta,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          userSubscriptions();
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const deletePaymentMethod = () => {
    API.subscriptions
      .deleteCard({
        id_suscripcion_tarjeta:
          subscriptionData.cards[paymentIndex].id_suscripcion_tarjeta,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          changePaymentMethod("forward");
          userSubscriptions();
          $("#deleteModal").modal("hide");
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const ShowPaymentMethods = () => {
    if (subscriptionData.cards.length > 0) {
      setCardNumberDisplay(
        "************".concat(
          subscriptionData.cards[paymentIndex].ultimos_digitos
        )
      );
      setNameDisplay(subscriptionData.cards[paymentIndex].card_name);
      setExpiryDisplay(subscriptionData.cards[paymentIndex].fecha_exipartion);
      return (
        <div
          className="col-12 my-auto"
          style={{ position: "relative", marginLeft: "-25px" }}
        >
          <ArrowBackIosIcon
            style={{
              color: "#000",
              fontSize: "25px",
              position: "absolute",
              left: "10%",
              bottom: "45%",
              zIndex: "99",
            }}
            onClick={() => changePaymentMethod("back")}
          />
          <Cards
            cvc={"***"}
            expiry={expiryDisplay}
            name={nameDisplay}
            preview={true}
            number={cardNumberDisplay}
          />

          <ArrowForwardIosIcon
            style={{
              color: "#000",
              fontSize: "25px",
              position: "absolute",
              right: "7%",
              bottom: "45%",
            }}
            onClick={() => changePaymentMethod("forward")}
          />

          <div className="row">
            <div className="col-4 d-flex justify-content-end p-0">
              <div
                className={
                  subscriptionData.cards[paymentIndex].tarjeta_default
                    ? "on-card-icon green-background"
                    : "on-card-icon"
                }
              >
                {/* <EditIcon
									style={{
										color: '#FFFFFF',
										fontSize: '25px'
									}}
									onClick={switchEditDisplay}
								/> */}
                <CheckIcon
                  style={{
                    color: "#FFFFFF",
                    fontSize: "25px",
                  }}
                  onClick={
                    subscriptionData.cards[paymentIndex].tarjeta_default
                      ? null
                      : selectPaymentMethod
                  }
                />
              </div>
            </div>

            <div className="col-4 d-flex justify-content-center p-0">
              <div className="on-card-icon">
                <DeleteIcon
                  style={{
                    color: "#FFFFFF",
                    fontSize: "25px",
                  }}
                  data-toggle="modal"
                  data-target="#deleteModal"
                />
              </div>
            </div>
            <div className="col-4 d-flex justify-content-start p-0">
              <div className="on-card-icon">
                <AddIcon
                  style={{
                    color: "#FFFFFF",
                    fontSize: "25px",
                  }}
                  onClick={switchAddDisplay}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  const changePaymentMethod = (direction) => {
    if (direction === "forward") {
      if (typeof subscriptionData.cards[paymentIndex + 1] === "undefined") {
        //Value does not exist, go back to first
        setCardNumberDisplay(
          "************".concat(subscriptionData.cards[0].ultimos_digitos)
        );
        setNameDisplay(subscriptionData.cards[0].card_name);
        setExpiryDisplay(subscriptionData.cards[0].fecha_exipartion);
        setExpiryDisplay(subscriptionData.cards[0].cvc);
        setCurrentMethodValues(
          subscriptionData.cards[0].id_suscripcion_tarjeta
        );
        setPaymentIndex(0);
      } else {
        // Value exists, show next
        setCardNumberDisplay(
          "************".concat(
            subscriptionData.cards[paymentIndex + 1].ultimos_digitos
          )
        );
        setNameDisplay(subscriptionData.cards[paymentIndex + 1].card_name);
        setExpiryDisplay(
          subscriptionData.cards[paymentIndex + 1].fecha_exipartion
        );
        setExpiryDisplay(subscriptionData.cards[paymentIndex + 1].cvc);
        setCurrentMethodValues(
          subscriptionData.cards[paymentIndex + 1].id_suscripcion_tarjeta
        );
        setPaymentIndex(paymentIndex + 1);
      }
    } else {
      if (typeof subscriptionData.cards[paymentIndex - 1] === "undefined") {
        setCardNumberDisplay(
          "************".concat(
            subscriptionData.cards[subscriptionData.cards.length - 1]
              .ultimos_digitos
          )
        );
        setNameDisplay(
          subscriptionData.cards[subscriptionData.cards.length - 1].card_name
        );
        setExpiryDisplay(
          subscriptionData.cards[subscriptionData.cards.length - 1]
            .fecha_exipartion
        );
        setExpiryDisplay(
          subscriptionData.cards[subscriptionData.cards.length - 1].cvc
        );
        setCurrentMethodValues(
          subscriptionData.cards[subscriptionData.cards.length - 1]
            .id_suscripcion_tarjeta
        );
        setPaymentIndex(subscriptionData.cards.length - 1);
      } else {
        setCardNumberDisplay(
          "************".concat(
            subscriptionData.cards[paymentIndex - 1].ultimos_digitos
          )
        );
        setNameDisplay(subscriptionData.cards[paymentIndex - 1].card_name);
        setExpiryDisplay(
          subscriptionData.cards[paymentIndex - 1].fecha_exipartion
        );
        setExpiryDisplay(subscriptionData.cards[paymentIndex - 1].cvc);
        setExpiryDisplay(
          subscriptionData.cards[paymentIndex - 1].id_suscripcion_tarjeta
        );
        setCurrentMethodValues(
          subscriptionData.cards[paymentIndex - 1].id_suscripcion_tarjeta
        );
        setPaymentIndex(paymentIndex - 1);
      }
    }
  };

  const handlePrimaryColorChange = (color, event) => {
    setPrimaryColor(color.hex);
  };

  const handleSecondaryColorChange = (color, event) => {
    setSecondaryColor(color.hex);
  };

  const updateColors = () => {
    let data = {
      color_principal: primaryColor.slice(1),
      color_secundario: secondaryColor.slice(1),
    };
    console.log("Sending", data);
    API.profile
      .setColors(data)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          localStorage.setItem("color_principal", primaryColor);
          localStorage.setItem("color_secundario", secondaryColor);
          document.documentElement.style.setProperty(
            "--primary_color",
            primaryColor
          );
          document.documentElement.style.setProperty(
            "--secondary_color",
            secondaryColor
          );
          $("#primary-color-modal").modal("hide");
          $("#secondary-color-modal").modal("hide");
          toast.success(response.message, {
            autoClose: 10000,
          });
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getCompanyData = () => {
    setLoader(true);
    API.profile
      .getCompanyData()
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          setCompanyData(response.data);
          if (response.data.logo) {
            setProfilePic(base_url_images + response.data.logo);
          }
          if (response.data.color_principal) {
            setPrimaryColor("#" + response.data.color_principal);
          } else {
            setPrimaryColor(default_colors[0]);
          }
          if (response.data.color_secundario) {
            setSecondaryColor("#" + response.data.color_secundario);
          } else {
            setSecondaryColor(default_colors[1]);
          }
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        setPrimaryColor(default_colors[0]);
        setSecondaryColor(default_colors[1]);
        setLoader(false);
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error con las sucursales");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const handlePictureChange = (e) => {
    if (typeof e.target.files[0] !== "undefined") {
      setProfilePic(URL.createObjectURL(e.target.files[0]));
      let image_file = e.target.files[0];
      update_picture(image_file);
    }
  };

  const update_picture = (file) => {
    API.profile
      .updateCompanyLogo(file)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          window.location.reload();
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const userSubscriptions = () => {
    API.subscriptions
      .getUserSubscriptions()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSubscriptionData(response.data);
          console.log("RECEIVING THIS DATA", response.data);
          updateRemainingTime(response.data.fecha_final);
          setRenovacionAutomatica(response.data.pago_concurrente);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };
  const updateRemainingTime = (fecha_final) => {
    var duration = moment.duration(moment(fecha_final).diff());
    //Get Days and subtract from duration
    var days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, "days"));

    //Get hours and subtract from duration
    var hours = duration.hours();
    duration.subtract(moment.duration(hours, "hours"));

    //Get Minutes and subtract from duration
    var minutes = duration.minutes();
    duration.subtract(moment.duration(minutes, "minutes"));

    setSubscriptionsDates({
      days,
      hours,
      minutes,
    });
  };

  const cancelSubscription = (data) => {
    console.log("DATA", data);
    API.subscriptions
      .cancelSubscription({
        // correo_electronico: companyData.cuenta_correo,
        correo_electronico: data.email,
        contrasena: data.contrasena,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          $("#confirmCancellation").modal("hide");
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const renewSubscription = () => {
    let newValue = renovacionAutomatica;
    console.log("newValue is", newValue);

    API.subscriptions
      .renewSubscription({
        id_suscripcion_tarjeta:
          subscriptionData.cards[0].id_suscripcion_tarjeta,
        auto_renewal: newValue ? 1 : 0,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          toast.success(response.message, {
            autoClose: 10000,
          });
          $("#confirmCancellation").modal("hide");
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response.data) {
          if (response.data.message) {
            toast.error(response.data.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const submitPaymentMethod = () => {
    let validForm = true;

    for (var key in meta.erroredInputs) {
      if (typeof meta.erroredInputs[key] !== "undefined") {
        validForm = false;
      }
    }
    if (!validForm)
      toast.error("Hay uno o mas errores en los datos de la tarjeta.", {
        autoClose: 10000,
      });

    if (name === "") {
      validForm = false;
      toast.error("Debe introducir el nombre del tarjetahabiente", {
        autoClose: 10000,
      });
    }

    if (validForm) {
      API.subscriptions
        .addNewCard({
          card_holder_name: name,
          card_number: number,
          expiry: expiry.replace(" / ", "/"),
          cvv: cvc,
        })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            toast.success(response.message, {
              autoClose: 10000,
            });
            switchAddDisplay();
            userSubscriptions();
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          let response = err.response;
          console.log(err);
          console.log(response);
          if (response.data) {
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            }
          } else {
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    }
  };

  const get_users = () => {
    setLoader(true);

    API.users
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          //console.log(response.data)
          setUsers(response.data);
          let graphData = [
            response.data.filter(
              (user) => user.tipo_nombre.toLowerCase() === "cajero"
            ).length,
            response.data.filter(
              (user) => user.tipo_nombre.toLowerCase() === "administrador"
            ).length,
            response.data.filter(
              (user) => user.tipo_nombre.toLowerCase() === "soporte"
            ).length,
          ];
          setGraphSeries(graphData);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
        setUsersLoader(false);
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        setUsersLoader(false);
      });
  };

  return (
    <div
      id="dashboard-section"
      style={{
        paddingRight: "1rem",
        paddingLeft: "2rem",
        paddingTop: "2rem",
      }}
    >
      <div className="content-card card mt-3">
        <div className="card-header">
          {loader ? (
            <div className="text-center">
              <CircularProgress size={26} className="mx-auto" />
            </div>
          ) : (
            <div className="row justify-content-start">
              <div className="col-xl-7">
                <div
                  className="row"
                  id="profile-tab1-row1"
                  style={{ padding: "0 20px" }}
                >
                  <div className="col-md-4" id="picture-column">
                    <div className="picture-container">
                      <img
                        src={profilePic}
                        alt="Icono de perfil"
                        className="img-responsive enterprise-profile-picture"
                      />
                      <div className="EditIcon">
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          onChange={(e) => handlePictureChange(e)}
                        />
                        <label htmlFor="raised-button-file">
                          <EditIcon
                            style={{
                              color: "#FFFFFF",
                              fontSize: "25px",
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8" id="information-column">
                    <h5 className="mb-2">
                      <strong>Perfil de empresa</strong>
                    </h5>
                    <p className="mb-0">{companyData.nombre}</p>
                    <p className="mb-0">{companyData.direccion}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5">
                <div
                  className="row justify-self-stretch"
                  id="profile-tab1-row2"
                  style={{ padding: "0 20px" }}
                >
                  <div className={tab === 0 ? "col-8" : "col-12"}>
                    <h5 className="mb-2" style={{ marginLeft: "-15px" }}>
                      <strong>Branding</strong>
                    </h5>
                    <div className="row">
                      <div className="col-md-4 p-0">
                        <p>
                          <strong>Color principal</strong>
                        </p>

                        <div
                          className="profile-color-box"
                          style={{
                            backgroundColor: primaryColor,
                          }}
                        >
                          <div className="EditIcon">
                            <EditIcon
                              style={{
                                color: "#FFFFFF",
                                fontSize: "16px",
                              }}
                              data-toggle="modal"
                              data-target="#primary-color-modal"
                            />
                          </div>
                        </div>
                        <p className="mt-1">{primaryColor}</p>
                      </div>
                      <div className="col-md-4  p-0">
                        <p>
                          <strong>Color secundario</strong>
                        </p>

                        <div
                          className="profile-color-box"
                          style={{
                            backgroundColor: secondaryColor,
                          }}
                        >
                          <div className="EditIcon">
                            <EditIcon
                              style={{
                                color: "#FFFFFF",
                                fontSize: "16px",
                              }}
                              data-toggle="modal"
                              data-target="#secondary-color-modal"
                            />
                          </div>
                        </div>
                        <p className="mt-1">{secondaryColor}</p>
                      </div>
                      <div className="col-md-4  p-0" />
                    </div>
                  </div>
                  {tab === 0 ? (
                    <div className="col-md-4 p-0" id="button-column">
                      <Button
                        variant="contained"
                        size="large"
                        className="bg_secondary_color"
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: "16px",
                        }}
                        onClick={() => setTab(1)}
                      >
                        Editar perfil
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}

          <Tabs
            className="mt-2"
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabs}
          >
            <Tab className="tab" label="N° de empleados" />
            <Tab className="tab" label="Membresia" />
          </Tabs>
        </div>
        <div className="card-body">
          <TabPanel value={tab} index={0} className="profile-tab">
            <div className="row mb-4">
              <div className="row w-100">
                <div className="col-xl-3 text-center">
                  <p>Usuarios registrados</p>
                  {usersLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <h1 className="secondary_color">{users.length}</h1>
                  )}
                </div>
                <div className="col-xl-3 text-center">
                  <p>Vendedores</p>
                  {usersLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <h1 className="secondary_color">
                      {
                        users.filter(
                          (user) => user.tipo_nombre.toLowerCase() === "cajero"
                        ).length
                      }
                    </h1>
                  )}
                </div>
                <div className="col-xl-3 text-center">
                  <p>Usuarios administradores</p>
                  {usersLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <h1 className="secondary_color">
                      {
                        users.filter(
                          (user) =>
                            user.tipo_nombre.toLowerCase() === "administrador"
                        ).length
                      }
                    </h1>
                  )}
                </div>
                <div className="col-xl-3 text-center">
                  <p>Usuarios de soporte</p>
                  {usersLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <h1 className="secondary_color">
                      {
                        users.filter(
                          (user) => user.tipo_nombre.toLowerCase() === "soporte"
                        ).length
                      }
                    </h1>
                  )}
                </div>
              </div>
              <div className="row w-100">
                <div className="col-xl-12" id="profile-stats-column">
                  {usersLoader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={{
                        plotOptions: {
                          pie: {
                            donut: {
                              size: "60%",
                            },
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 1200,
                            options: {
                              chart: {
                                width: "100%",
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                        colors: ["#08BFE6", "#1A2EAB", "#1AFFAB"],
                        labels: [
                          "Usuarios vendedores",
                          "Usuarios administradores",
                          "Usuarios de soporte",
                        ],
                        legend: {
                          position: "right",
                        },
                      }}
                      series={graphSeries}
                      height="100%"
                      width="50%"
                      type="donut"
                    />
                  )}
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={tab} index={1} className="profile-tab">
            <div className="row mb-4 p-4 justify-content-center">
              <div className="row w-100">
                <div
                  className={
                    subscriptionData.fecha_final ? "col-xl-7" : "col-xl"
                  }
                  id="profile-tab2-col1"
                >
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress size={26} className="mx-auto" />
                    </div>
                  ) : (
                    <div className="row">
                      <div
                        className="col-12 gradient_bg"
                        id="membership-header"
                      >
                        {subscriptionData.fecha_final ? (
                          <div className="row">
                            {/* Header left half */}
                            <div className="col-xl-6">
                              <div className="row ">
                                <div className="p-4 text-left">
                                  <p>
                                    <strong>Membresía</strong>
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <h1 className="mr-1 my-auto">
                                      {subscriptionData.plan.descripcion}
                                    </h1>
                                    {/* <EditIcon
																	style={{
																		color: '#FFFFFF',
																		fontSize: '20px'
																	}}
																/> */}
                                  </div>
                                </div>
                                <div className="p-4 text-center">
                                  <p>
                                    <strong>Facturación</strong>
                                  </p>
                                  {subscriptionData.plan.numero_mes === 1 ? (
                                    <h1>Mensual</h1>
                                  ) : (
                                    ""
                                  )}
                                  {subscriptionData.plan.numero_mes === 3 ? (
                                    <h1>Trimestral</h1>
                                  ) : (
                                    ""
                                  )}
                                  {subscriptionData.plan.numero_mes === 4 ? (
                                    <h1>Cuatrimestral</h1>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {subscriptionData.fecha_inicio ? (
                                <div className="row px-4 py-2">
                                  <p className="text-center">
                                    <strong>Última facturación: </strong>
                                    {moment(
                                      subscriptionData.fecha_inicio
                                    ).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {/* Header right half */}
                            {subscriptionData.fecha_inicio ? (
                              <div className="col-xl-6">
                                <div className="row pb-1 p-4">
                                  <p>
                                    <strong>Tiempo restante</strong>
                                  </p>
                                </div>
                                <div className="row">
                                  <div
                                    className="col-4 mb-2 remaining-time"
                                    style={{
                                      borderRight: "1px solid white",
                                    }}
                                  >
                                    <p>Días</p>
                                    <h1>{subscriptionDates.days}</h1>
                                  </div>

                                  <div
                                    className="col-4 mb-2 remaining-time"
                                    style={{
                                      borderRight: "1px solid white",
                                    }}
                                  >
                                    <p>Horas</p>
                                    <h1>{subscriptionDates.hours}</h1>
                                  </div>

                                  <div
                                    className="col-4 mb-2 remaining-time"
                                    style={{
                                      paddingRight: "0",
                                    }}
                                  >
                                    <p>Minutos</p>
                                    <h1>{subscriptionDates.minutes}</h1>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* //fix Se elimina renovacion automatica
                      subscriptionData.fecha_inicio ? (
                        <div
                          className="col-12 mt-3 pb-3"
                          style={{
                            borderBottom: "1px solid #E0E0E0",
                          }}
                        >
                          <div className="row">
                            <div className="col-md-6 ">
                              <p className="mb-0">
                                <strong>Próxima facturación:</strong>
                              </p>
                              <p className="mb-0">
                                {moment(subscriptionData.fecha_final).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                              <p className="mr-2 my-auto">
                                <strong>Renovación automática:</strong>
                              </p>

                              <label
                                className="toggle-control my-auto"
                                style={{
                                  left: "-15px",
                                  height: "40px",
                                  marginLeft: "40px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={renovacionAutomatica}
                                  onChange={switchRenovacion}
                                />
                                <span class="control" />
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )*/}
                      {/* <div
												className="col-12 mt-3"
												style={{
													borderBottom: '1px solid #E0E0E0'
												}}
											>
												<div className="row">
													<p>
														<strong>Descargar histórico de facturación:</strong>
													</p>
												</div>
												<div className="row justify-content-between mb-3">
													<DateRangePicker
														onChange={setDateRange}
														value={dateRange}
														format={'dd-MM-y'}
														className="date-picker-cs px-1"
													/>
													<Button className="btn-cs1" variant="contained" color="primary">
														Descargar
												</Button>
												</div>
											</div> */}
                      <div
                        className="col-12 mt-3 d-flex justify-content-start align-items-center"
                        style={{ marginLeft: "-15px" }}
                      >
                        {subscriptionData.fecha_final ? (
                          <Button
                            className="btn-cs1 mx-2 delete-btn"
                            variant="contained"
                            color="primary"
                            startIcon={
                              <DeleteIcon
                                style={{
                                  fontSize: "20px",
                                }}
                              />
                            }
                            data-toggle="modal"
                            data-target="#confirmCancellation"
                          >
                            Dar de baja
                          </Button>
                        ) : (
                          ""
                        )}
                        {subscriptionData.fecha_final ? (
                          <Button
                            className="btn-cs1 mx-2"
                            variant="contained"
                            color="primary"
                            onClick={renewSubscription}
                          >
                            Renovar suscripcion
                          </Button>
                        ) : (
                          ""
                        )}
                        {!subscriptionData.fecha_final ? (
                          <Button
                            className="btn-cs1 mx-auto"
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/payment"
                          >
                            Pagar suscripción
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {subscriptionData.fecha_final ? (
                  <div className="col-xl-5" id="profile-tab2-col2">
                    <div className="row">
                      <div className="col-12">
                        <p>
                          <strong>Método de pago</strong>
                        </p>
                        <p>
                          Su información está protegida por nuestro sistema y no
                          será compartida con terceros.
                        </p>
                      </div>

                      <ShowPaymentMethods />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
      {/* Add card */}
      <div className={displayAddSection ? "content-card card mt-3" : "d-none"}>
        <div className="card-body">
          <div className="row w-100 mb-4 px-2 py-2">
            <div className="col-12">
              <p style={{ fontSize: "16px" }}>
                <strong>Agregar método de pago</strong>
              </p>
            </div>
            <div
              className="col-12 pb-3"
              style={{ borderBottom: "1px solid #E0E0E0" }}
            >
              <div className="row">
                <div className="col-md-6 text-center mb-3 my-auto">
                  <TextField
                    style={{ width: "293px" }}
                    variant="outlined"
                    value={name}
                    name="name"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    placeholder="Nombre del tarjetahabiente"
                  />
                  <div className="d-block my-auto">
                    <PaymentInputsWrapper {...wrapperProps}>
                      <svg
                        {...getCardImageProps({
                          images,
                        })}
                      />
                      <input
                        {...getCardNumberProps({
                          onChange: handleInputChange,
                          onFocus: handleInputFocus,
                        })}
                        name="number"
                        value={number}
                      />
                      <input
                        {...getExpiryDateProps({
                          onChange: handleInputChange,
                          onFocus: handleInputFocus,
                        })}
                        name="expiry"
                        value={expiry}
                      />
                      <input
                        {...getCVCProps({
                          onChange: handleInputChange,
                          onFocus: handleInputFocus,
                        })}
                        name="cvc"
                        value={cvc}
                      />
                    </PaymentInputsWrapper>
                  </div>
                </div>
                <div className="col-md-6">
                  <Cards
                    cvc={cvc}
                    expiry={expiry}
                    focused={focus}
                    name={name}
                    number={number}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mt-3 pt-4">
              <div className="row justify-content-between">
                <div className="col-xl-3 my-auto text-center">
                  <Button
                    className="btn-cs1 mx-2 delete-btn my-auto"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={switchAddDisplay}
                  >
                    Cancelar
                  </Button>
                </div>
                {/* <div className="col-xl-6 my-2">
									<div className="row">
										<div className="col-md-6 d-flex justify-content-center card-switches-column">
											<p className="mr-4 my-auto">
												<strong>Renovación automática</strong>
											</p>
											<label
												class="toggle-control my-auto"
												style={{
													left: '-15px',
													height: '40px'
												}}
											>
												<input
													type="checkbox"
													value={new_renovacionAutomatica}
													onChange={switchNewRenovacion}
												/>
												<span class="control" />
											</label>
										</div>
										<div className="col-md-6 d-flex justify-content-center card-switches-column">
											<p className="mr-4 my-auto">
												<strong>Guardar método de pago</strong>
											</p>
											<label
												class="toggle-control my-auto"
												style={{
													left: '-15px',
													height: '40px'
												}}
											>
												<input type="checkbox" value={guardarMetodo} onChange={switchGuardar} />
												<span class="control" />
											</label>
										</div>
									</div>
								</div> */}
                <div className="col-xl-3 my-auto">
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    style={{
                      backgroundColor: "#08BFE6",
                      color: "white",
                      textTransform: "none",
                      fontSize: "16px",
                    }}
                    onClick={submitPaymentMethod}
                  >
                    Guardar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit card */}
      {/* <div className={displayEditSection ? 'content-card card mt-3' : 'd-none'}>
				<div className="card-body">
					<div className="row w-100 mb-4 px-2 py-2">
						<div className="col-12">
							<p style={{ fontSize: '16px' }}>
								<strong>Editar método de pago</strong>
							</p>
						</div>
						<div className="col-12 pb-3" style={{ borderBottom: '1px solid #E0E0E0' }}>
							<div className="row text-center mb-3 my-auto justify-content-around">
								<TextField
									style={{ width: '293px' }}
									variant="outlined"
									value={currentCardName}
									name="name"
									placeholder="Nombre del tarjetahabiente"
									onChange={editMethod}
								/>
								<div className="d-block my-auto">
									<PaymentInputsWrapper {...wrapperProps}>
										<svg
											{...getCardImageProps({
												images
											})}
										/>
										<input
											{...getCardNumberProps({
												onChange: editMethod
											})}
											name="number"
											value={currentCardNumber}
										/>
										<input
											{...getExpiryDateProps({
												onChange: editMethod
											})}
											name="expiry"
											value={currentExpiry}
										/>
										<input
											{...getCVCProps({
												onChange: editMethod
											})}
											name="cvc"
											value={currentCVC}
										/>
									</PaymentInputsWrapper>
								</div>
							</div>
						</div>
						<div className="col-12 mt-3 pt-4">
							<div className="row">
								<div className="col-xl-3 my-auto text-center">
									<Button
										className="btn-cs1 mx-2 delete-btn my-auto"
										variant="contained"
										color="primary"
										fullWidth
										onClick={switchEditDisplay}
									>
										Cancelar
									</Button>
								</div>
								<div className="col-xl-6 my-2">
									<div className="row">
										<div className="col-md-12 d-flex justify-content-center card-switches-column">
											<p className="mr-4 my-auto">
												<strong>Renovación automática</strong>
											</p>
											<label
												class="toggle-control my-auto"
												style={{
													left: '-15px',
													height: '40px'
												}}
											>
												<input
													type="checkbox"
													checked={currentRenovacion}
													value={currentRenovacion}
													name="renovacion"
													onClick={editMethod}
												/>
												<span class="control" />
											</label>
										</div>
									</div>
								</div>
								<div className="col-xl-3 my-auto">
									<Button
										variant="contained"
										size="large"
										fullWidth
										style={{
											backgroundColor: '#08BFE6',
											color: 'white',
											textTransform: 'none',
											fontSize: '16px'
										}}
									
									>
										Guardar
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
      {/* Delete modal */}
      <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row justify-content-center align-items-center p-3">
                <div className="col-md-12">
                  <h4 className="text-center">
                    <strong>
                      Está seguro que desea eliminar ese método de pago?
                    </strong>
                  </h4>
                </div>
              </div>
              <div className="col-md-12 mx-auto">
                <div className="row justify-content-between">
                  <Button
                    className="btn-cs1 icon-btn-delete mx-auto"
                    variant="contained"
                    color="primary"
                    onClick={deletePaymentMethod}
                  >
                    Sí
                  </Button>
                  <Button
                    className="btn-cs1 mx-auto"
                    variant="contained"
                    color="primary"
                    data-dismiss="modal"
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Color picker modals */}
      <div
        className="modal fade"
        id="primary-color-modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row justify-content-center align-items-center p-3">
                <div className="col-md-12 text-center">
                  <h1>Escoge el color nuevo</h1>
                  {loader ? (
                    <CircularProgress size={26} className="mx-auto" />
                  ) : (
                    <BlockPicker
                      color={primaryColor}
                      triangle={"hide"}
                      className="mx-auto"
                      colors={[
                        "#F47373",
                        "#37D67A",
                        "#2CCCE4",
                        "#555555",
                        "#dce775",
                        "#ff8a65",
                        "#ba68c8",
                        "#1a2eab",
                        "#0160e7",
                        "#1a5d9d",
                      ]}
                      onChange={handlePrimaryColorChange}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-8 mx-auto">
                <Button
                  className="btn-cs1 mx-auto"
                  variant="contained"
                  color="primary"
                  fullWidth
                  data-dismiss="modal"
                  onClick={updateColors}
                >
                  Guardar cambios
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="secondary-color-modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row justify-content-center align-items-center p-3">
                <div className="col-md-12 text-center">
                  <h1>Escoge el color nuevo</h1>
                  {loader ? (
                    <CircularProgress size={26} className="mx-auto" />
                  ) : (
                    <BlockPicker
                      color={secondaryColor}
                      triangle={"hide"}
                      className="mx-auto"
                      colors={[
                        "#F47373",
                        "#37D67A",
                        "#2CCCE4",
                        "#555555",
                        "#dce775",
                        "#ff8a65",
                        "#ba68c8",
                        "#1a2eab",
                        "#0160e7",
                        "#1a5d9d",
                      ]}
                      onChange={handleSecondaryColorChange}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-8 mx-auto">
                <Button
                  className="btn-cs1 mx-auto"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={updateColors}
                >
                  Guardar cambios
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="confirmCancellation"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <form
                className="row justify-content-center align-items-center p-3"
                onSubmit={handleSubmit(cancelSubscription)}
              >
                <div className="col-md-12">
                  <h4 className="text-center">
                    <strong>
                      Confirme su contraseña para proceder con la cancelación de
                      la suscripción
                    </strong>
                  </h4>
                </div>
                <div className="col-md-12 mb-3">
                  <TextField
                    type="email"
                    className="w-100"
                    placeholder="Introduzca correo electrónico"
                    inputRef={register}
                    required={true}
                    name="email"
                  />
                </div>
                <div className="col-md-12">
                  <TextField
                    type="password"
                    className="w-100"
                    placeholder="Introduzca la contraseña"
                    inputRef={register}
                    required={true}
                    name="contrasena"
                  />
                </div>
                <div className="col-md-6 mt-3 mx-auto text-center">
                  <Button
                    className="btn-cs1 delete-btn my-auto"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Dar de baja
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
