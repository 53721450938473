import React from "react";
import Main from "./components/Main";
import TopBar from "./components/TopBar";
import { useSap } from "./CustomHooks/useSap";
const DescargasSap = () => {
  const props = useSap();
  return (
    <>
      <TopBar {...props} />
      <Main {...props} />
    </>
  );
};

export default DescargasSap;
