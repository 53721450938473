import React from "react";

const FormRegister = (props) => {
  const { user } = props;

  console.log("usuario", user.permisos[1]);

  //   const handleCheckPermisosAleko = (name, value) => {};

  return (
    <div className="row px-3">
      <div className="col-md-4 mt-3">
        <div className="row h-100">
          <div className="col-8">
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              <strong>Dashboard</strong>
            </p>
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              El usuario podrá consultar las estadísticas y toda la información
              del Dashboard
            </p>
          </div>
          <div className="col-4 d-flex align-items-center">
            <label
              class="toggle-control"
              style={{ left: "-15px", height: "40px" }}
            >
              <input
                type="checkbox"
                name="permiso1"
                defaultChecked={user.permisos[0]}
                disabled={true}
                checked={user.permisos[0]}
              />
              <span class="control" />
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-4 mt-3">
        <div className="row h-100">
          <div className="col-8">
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              <strong>Inventario</strong>
            </p>
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              El usuario podrá consultar los productos del inventario, realizar
              ingresos y egresos
            </p>
          </div>
          <div className="col-4 d-flex align-items-center">
            <label
              class="toggle-control"
              style={{ left: "-15px", height: "40px" }}
            >
              <input
                type="checkbox"
                name="permiso2"
                // defaultChecked={user.permisos[1]}
                checked={user.permisos[1]}
                disabled={true}
              />
              <span class="control" />
            </label>
          </div>
        </div>
      </div>
      {/* Third row */}

      <div className="col-md-4 mt-3">
        <div className="row h-100">
          <div className="col-8">
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              <strong>Clientes</strong>
            </p>
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              El usuario podrá consultar la base de datos con los clientes,
              liquidar anticipos y cancelar facturas a crédito
            </p>
          </div>
          <div className="col-4 d-flex align-items-center">
            <label
              class="toggle-control"
              style={{ left: "-15px", height: "40px" }}
            >
              <input
                type="checkbox"
                name="permiso3"
                // defaultChecked={user.permisos[2]}
                disabled={true}
                checked={user.permisos[2]}
              />
              <span class="control" />
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-4 mt-3">
        <div className="row h-100">
          <div className="col-8">
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              <strong>Traslados</strong>
            </p>
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              El usuario podrá emitir y administrar traslados entrantes y
              salientes.
            </p>
          </div>
          <div className="col-4 d-flex align-items-center">
            <label
              class="toggle-control"
              style={{ left: "-15px", height: "40px" }}
            >
              <input
                type="checkbox"
                name="permiso4"
                // defaultChecked={user.permisos[3]}
                disabled={true}
                checked={user.permisos[3]}
              />
              <span class="control" />
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-4 mt-3">
        <div className="row h-100">
          <div className="col-8">
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              <strong>Usuarios</strong>
            </p>
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              El usuario podrá administrar y añadir nuevos usuarios en su
              sistema Cloud
            </p>
          </div>
          <div className="col-4 d-flex align-items-center">
            <label
              class="toggle-control"
              style={{ left: "-15px", height: "40px" }}
            >
              <input
                type="checkbox"
                name="permiso5"
                // defaultChecked={user.permisos[4]}
                disabled={true}
                checked={user.permisos[4]}
              />
              <span class="control" />
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-4 mt-3">
        <div className="row h-100">
          <div className="col-8">
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              <strong>Configuración</strong>
            </p>
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              El usuario podrá administrar la información de su empresa,
              sucursales, formato de facturas...
            </p>
          </div>
          <div className="col-4 d-flex align-items-center">
            <label
              class="toggle-control"
              style={{ left: "-15px", height: "40px" }}
            >
              <input
                type="checkbox"
                name="permiso6"
                // defaultChecked={user.permisos[5]}
                checked={user.permisos[5]}
                disabled={true}
              />
              <span class="control" />
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-4 mt-3">
        <div className="row h-100">
          <div className="col-8">
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              <strong>POS</strong>
            </p>
            <p className="mb-0" style={{ fontSize: "0.85rem" }}>
              El usuario podrá facturar, consultar operaciones y cortes de caja.
            </p>
          </div>
          <div className="col-4 d-flex align-items-center">
            <label
              class="toggle-control"
              style={{ left: "-15px", height: "40px" }}
            >
              <input
                type="checkbox"
                name="permiso7"
                // defaultChecked={user.permisos[6]}
                checked={user.permisos[6]}
                disabled={true}
              />
              <span class="control" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormRegister;
