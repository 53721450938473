import { useState, useEffect, useCallback } from "react";

import axios from "axios";

function useInfinityScroll(page) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [clients, setClients] = useState([]);

  const sendQuery = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `https://jsonplaceholder.typicode.com/todos?_start=${page}&_limit=20`
      );

      setClients((prev) => [...prev, ...data]);

      setLoading(false);
    } catch (err) {
      setError(err);
    }
  }, [page]);

  useEffect(() => {
    sendQuery();
  }, [sendQuery, page]);

  return { loading, error, clients };
}

export default useInfinityScroll;
