import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import DataTable from "react-data-table-component";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import TabPanel from "../../components/TabPanel";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Pagination } from "@material-ui/lab";
import API from "../../api";
import { toast } from "react-toastify";
import { base_url_images, realImageURL } from "../../api";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";

// components

import InvoiceActions from "./invoiceactions";

//data

// Pestañas del catalogo

import ListTab from "./listtab/listtab";

export default function Sell(props) {
  const [customProducts, setCustomProducts] = useState([]);
  // states
  const [store, setStore] = useState("");
  const [caja, setCaja] = useState("");
  const [seller, setSeller] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [productCategory, setProductCategory] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [page_0, setPage_0] = useState(0);
  const [page_1, setPage_1] = useState(0);
  const [page_2, setPage_2] = useState(0);
  // TODO Custom products froms db
  const [allCustomProducts, setAllCustomProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [mostSaleProducts, setMostSaleProducts] = useState([]);
  const [filteredMostSaleProducts, setFilteredMostSaleProducts] = useState([]);
  const [paginatedMostSaleProducts, setPaginatedMostSaleProducts] = useState(
    []
  );
  const [lastSoldProducts, setLastSoldProducts] = useState([]);
  const [filteredLastSoldProducts, setFilteredLastSoldProducts] = useState([]);
  const [paginatedLastSoldProducts, setPaginatedLastSoldProducts] = useState(
    []
  );

  const [filterText, setFilterText] = useState("");
  const [categories, setCategories] = useState([
    {
      id_clasificacion1_web_app: "",
      descripcion: "Todas las categorias",
    },
  ]);

  const [salesCost, setSalesCost] = useState({
    total: 0,
    subtotal: 0,
    iva: 0,
  });
  const [sucursals, setSucursals] = useState([
    {
      id_sucursal_bodega_ubicacion: "",
      nombre_sucursal: "",
    },
  ]);
  const [cajas, setCajas] = useState([
    {
      id_caja_web_app: "",
      descripcion: "",
    },
  ]);
  const [sellers, setSellers] = useState([
    {
      id_vendedor_web_app: "",
      nombre: "",
    },
  ]);
  const maxPerPage = 9;

  let history = useHistory();
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#cancelSale").modal("hide");
    };
  }, []);

  // Filter by category
  useEffect(() => {
    filterProducts();
  }, [productCategory]);

  useEffect(() => {
    getExistingProducts();
  }, [allProducts]);

  useEffect(() => {
    handlePagination(null, 1);
  }, [filteredProducts]);

  useEffect(() => {
    handlePagination(null, 1);
  }, [filteredMostSaleProducts]);

  useEffect(() => {
    handlePagination(null, 1);
  }, [filteredLastSoldProducts]);

  useEffect(() => {
    nuclearUpdate(filteredProducts);
    selectedProducts.map((product) => {
      handlePaginatedSelected(product.id_new_item);
    });
    if (!isLoading) {
      $("#friggin-loader").removeClass("d-block").addClass("d-none");
      $("#products-catalog").removeClass("d-none").addClass("d-flex");
    }
  }, [paginatedProducts]);

  useEffect(() => {
    nuclearUpdate(selectedProducts);
    selectedProducts.map((product) => {
      handlePaginatedSelected(product.id_new_item);
    });
  }, [selectedProducts]);

  useEffect(() => {
    nuclearUpdate(filteredMostSaleProducts);
    selectedProducts.map((product) => {
      handlePaginatedSelected(product.id_new_item);
    });
    $("#friggin-loader_2").removeClass("d-block").addClass("d-none");
    $("#products-catalog_2").removeClass("d-none").addClass("d-flex");
  }, [paginatedMostSaleProducts]);

  useEffect(() => {
    nuclearUpdate(filteredLastSoldProducts);
    selectedProducts.map((product) => {
      handlePaginatedSelected(product.id_new_item);
    });
    $("#friggin-loader_3").removeClass("d-block").addClass("d-none");
    $("#products-catalog_3").removeClass("d-none").addClass("d-flex");
  }, [paginatedLastSoldProducts]);

  useEffect(() => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSucursals(response.data);
          setStore(response.data[0].id_sucursal_bodega_ubicacion);
          getAllProducts(response.data[0].id_sucursal_bodega_ubicacion);
          getMostSaleProducts(response.data[0].id_sucursal_bodega_ubicacion);
          getLastSoldProducts(response.data[0].id_sucursal_bodega_ubicacion);
          getCajas(response.data[0].id_sucursal_bodega_ubicacion);
          localStorage.setItem(
            "store",
            JSON.stringify({
              id_sucursal_bodega_ubicacion:
                response.data[0].id_sucursal_bodega_ubicacion,
              nombre_sucursal: response.data[0].nombre_sucursal,
            })
          );
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error al obtener las sucursales");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  }, []);

  useEffect(() => {
    switch (currentTab) {
      case 0:
        if (JSON.stringify(allProducts) !== "[]") {
          paginatedProducts.map((product) => {
            if (selectedProducts.includes(product))
              handlePaginatedSelected(product.id_new_item, false);
          });
        }
        break;
      case 1:
        if (JSON.stringify(mostSaleProducts) !== "[]") {
          paginatedMostSaleProducts.map((product) => {
            if (selectedProducts.includes(product))
              handlePaginatedSelected(product.id_new_item, false);
          });
        }
        break;
      case 2:
        if (JSON.stringify(lastSoldProducts) !== "[]") {
          paginatedLastSoldProducts.map((product) => {
            if (selectedProducts.includes(product))
              handlePaginatedSelected(product.id_new_item, false);
          });
        }
        break;
      default:
        break;
    }
    handlePagination(null, 1);
  }, [currentTab]);

  const getAllProducts = (id_sucursal) => {
    setIsLoading(true);
    $("#friggin-loader").addClass("d-block");
    $("#products-catalog").removeClass("d-flex").addClass("d-none");
    API.products
      .getAll({ id_sucursal_bodega_ubicacion: id_sucursal })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Receiving these products", response.data);
          setAllProducts(response.data);
          // TODO change this for db data.
          let test = [
            {
              producto_personalizado: true,
              custom: {
                variant: ["Color1", "Color2", "Color3"],
                general: [
                  { title: "Talla", options: ["talla1", "talla2", "talla3"] },
                  { title: "Material", options: ["mat1", "mat2", "mat3"] },
                ],
              },
              id_new_item: 100,
              id_empresa_new: 41,
              nombre: "Sueter",
              descripcion: "Refresco sabor a cola negra de un litro.",
              descuento_precio: 0,
              vendido_por: "Unidad",
              notificaciones_inventario: 1,
              vencimiento_fecha: "2021-11-30",
              rapida_venta: 1,
              codigo: "7591127123626",
              imagen_1: "images/sueter.png",
              imagen_2: "",
              imagen_3: "",
              imagen_4: "",
              imagen_5: "",
              id_linea_web_app: 1,
              id_clasificacion1_web_app: 5,
              id_unidad_medida_web_app: 39,
              precio_venta: 25.0,
              stock: 38,
              notificacion_inventario: null,
              stock_minimo: 10,
              flag_vencimiento: true,
              idp: null,
              costo_unitario: 2.55,
              existencia_inicial: null,
              id_clasificacion2_web_app: 3,
              inventariado: 1,
              id_distribuidor_web_app: 4,
              id_contenido_web_app: 17,
              usa_lote: 1,
              fecha: "2021-03-17T11:47:17.000Z",
              impuesto_idp: 0,
              lote: "1",
              category: "EXTRAS",
              ubicacion: "QA Empresa",
            },
            {
              producto_personalizado: true,
              custom: {
                variant: ["Color1", "Color2", "Color3"],
                general: [
                  { title: "Talla", options: ["talla1", "talla2", "talla3"] },
                  { title: "Material", options: ["mat1", "mat2", "mat3"] },
                ],
              },
              id_new_item: 110,
              id_empresa_new: 41,
              nombre: "Gorra",
              descripcion: "Refresco sabor a cola negra de un litro.",
              descuento_precio: 0,
              vendido_por: "Unidad",
              notificaciones_inventario: 1,
              vencimiento_fecha: "2021-11-30",
              rapida_venta: 1,
              codigo: "7591127123626",
              imagen_1: "images/gorra.png",
              imagen_2: "",
              imagen_3: "",
              imagen_4: "",
              imagen_5: "",
              id_linea_web_app: 1,
              id_clasificacion1_web_app: 5,
              id_unidad_medida_web_app: 39,
              precio_venta: 18.45,
              stock: 38,
              notificacion_inventario: null,
              stock_minimo: 10,
              flag_vencimiento: true,
              idp: null,
              costo_unitario: 2.55,
              existencia_inicial: null,
              id_clasificacion2_web_app: 3,
              inventariado: 1,
              id_distribuidor_web_app: 4,
              id_contenido_web_app: 17,
              usa_lote: 1,
              fecha: "2021-03-17T11:47:17.000Z",
              impuesto_idp: 0,
              lote: "1",
              category: "EXTRAS",
              ubicacion: "QA Empresa",
            },
          ];
          setAllCustomProducts(test);
          setFilteredProducts(response.data);
          set_categories(response.data);
        }
        setIsLoading(false);
        $("#friggin-loader").addClass("d-none");
        console.log("Changed gears from getAllProducts", filteredProducts);
        $("#products-catalog").addClass("d-flex");
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error al obtener todos los productos");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        $("#friggin-loader").addClass("d-none");
        $("#products-catalog").addClass("d-flex");
        setIsLoading(false);
      });
  };

  const getMostSaleProducts = (id_sucursal) => {
    $("#friggin-loader_2").addClass("d-block");
    $("#products-catalog_2").removeClass("d-flex").addClass("d-none");
    API.products
      .getMostSale({ id_sucursal_bodega_ubicacion: id_sucursal })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setMostSaleProducts(response.data);
          setFilteredMostSaleProducts(response.data);
        }
        $("#friggin-loader_2").addClass("d-none");
        $("#products-catalog_2").addClass("d-flex");
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error los productos mas vendidos");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        $("#friggin-loader_2").addClass("d-none");
        $("#products-catalog_2").addClass("d-flex");
      });
  };

  const getLastSoldProducts = (id_sucursal) => {
    $("#friggin-loader_3").addClass("d-block");
    $("#products-catalog_3").removeClass("d-flex").addClass("d-none");
    API.products
      .getLastSold({ id_sucursal_bodega_ubicacion: id_sucursal })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setLastSoldProducts(response.data);
          setFilteredLastSoldProducts(response.data);
        }
        $("#friggin-loader_3").addClass("d-none");
        $("#products-catalog_3").addClass("d-flex");
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        if (typeof response.data !== "undefined") {
          if (response.data.message)
            toast.error(response.data.message, {
              autoClose: 10000,
            });
        } else {
          console.log(
            "Ha ocurrido un error al obtener los ultimos productos vendidos"
          );
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
        $("#friggin-loader_3").addClass("d-none");
        $("#products-catalog_3").addClass("d-flex");
      });
  };

  // set states
  const changeStore = (event) => {
    let parsedValue = parseInt(event.target.value);
    setStore(parsedValue);
    setCaja("");
    setSeller("");
    getCajas(parseInt(parsedValue));
    getAllProducts(parsedValue);
    getMostSaleProducts(parsedValue);
    getLastSoldProducts(parsedValue);
    let currentSucursal = sucursals.find(
      (x) => x.id_sucursal_bodega_ubicacion === parsedValue
    );
    localStorage.setItem(
      "store",
      JSON.stringify({
        id_sucursal_bodega_ubicacion:
          currentSucursal.id_sucursal_bodega_ubicacion,
        nombre_sucursal: currentSucursal.nombre_sucursal,
      })
    );
  };

  const changeCaja = (event) => {
    let parsedValue = parseInt(event.target.value);
    setCaja(parsedValue);
    localStorage.setItem("caja", parsedValue);
  };

  const getCajas = (value) => {
    console.log("Obtaining cajas with this sucursal", value);
    API.cajas
      .getCajas({ id_sucursal_bodega_ubicacion: value })
      .then((res) => {
        let response = res.data;

        if (response.success) {
          setCajas(response.data);
          if (response.data.length > 0) {
            getSellers(response.data[0].id_caja_web_app);
            localStorage.setItem(
              "caja",
              JSON.stringify({
                id_caja_web_app: response.data[0].id_caja_web_app,
                descripcion: response.data[0].descripcion,
              })
            );
          }
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data !== "undefined") {
          let response = err.response.data;
          toast.error(response.message, { autoClose: 10000 });
        } else {
          let response = err.response;
          console.log(response);
          console.log("Ha ocurrido un error al obtener las cajas");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const changeSeller = (event) => {
    let parsedValue = parseInt(event.target.value);
    setSeller(parsedValue);
    let currentSeller = sellers.find(
      (x) => x.id_vendedor_web_app === parsedValue
    );
    localStorage.setItem(
      "seller",
      JSON.stringify({
        id_vendedor_web_app: currentSeller.id_vendedor_web_app,
        nombre: currentSeller.nombre,
      })
    );
  };

  const getSellers = (value) => {
    API.cajas
      .getVendedores({ id_caja_web_app: value })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setSellers(response.data);
          if (response.data.length > 0) {
            localStorage.setItem(
              "seller",
              JSON.stringify({
                id_vendedor_web_app: response.data[0].id_vendedor_web_app,
                nombre: response.data[0].nombre,
              })
            );
          }
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error al obtener los vendedores");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const changeCategory = (event) => {
    const category = event.target.value;
    setProductCategory(category);
  };

  const handleProductTabs = (event, newValue) => {
    if (currentTab !== newValue) {
      switch (newValue) {
        case 0:
          if ($("#friggin-loader").hasClass("d-none")) {
            $("#friggin-loader").removeClass("d-none").addClass("d-block");
          }

          if ($("#products-catalog").hasClass("d-flex")) {
            $("#products-catalog").removeClass("d-flex").addClass("d-none");
          }
          break;
        case 1:
          if ($("#friggin-loader_2").hasClass("d-none")) {
            $("#friggin-loader_2").removeClass("d-none").addClass("d-block");
          }

          if ($("#products-catalog_2").hasClass("d-flex")) {
            $("#products-catalog_2").removeClass("d-flex").addClass("d-none");
          }
          break;
        case 2:
          if ($("#friggin-loader_3").hasClass("d-none")) {
            $("#friggin-loader_3").removeClass("d-none").addClass("d-block");
          }

          if ($("#products-catalog_3").hasClass("d-flex")) {
            $("#products-catalog_3").removeClass("d-flex").addClass("d-none");
          }
          break;
        default:
          break;
      }
      setCurrentTab(newValue);
    }
  };

  const handlePaginatedSelected = (productId) => {
    let currentElement = $(`#product-${productId}`);
    currentElement.addClass("product-selected");
    $(`#product-${productId} > .product-body`).addClass("product-selected");
    $(`#product-icon-${productId}`).removeClass("d-none");
  };

  const handleBoxClick = (productId, setLocals, toDelete, customProduct) => {
    let currentElement = $(`#product-${productId}`);
    let currentProduct = allProducts.find((x) => x.id_new_item === productId);
    if (currentProduct.stock === 0 || currentProduct.stock < 0) {
      toast.error(
        "No puede seleccionar este producto porque no tiene existencia.",
        { autoClose: 10000 }
      );
    } else {
      if (currentElement.hasClass("product-selected") && toDelete) {
        currentElement.removeClass("product-selected");
        $(`#product-${productId} > .product-body`).removeClass(
          "product-selected"
        );
        $(`#product-icon-${productId}`).addClass("d-none");
      } else {
        currentElement.addClass("product-selected");
        $(`#product-${productId} > .product-body`).addClass("product-selected");
        $(`#product-icon-${productId}`).removeClass("d-none");
      }
      selectNewProduct(productId, setLocals, toDelete, customProduct);
    }
  };

  const selectNewProduct = (productId, setLocals, toDelete, customProduct) => {
    let existingProduct = selectedProducts.find(
      (x) => x.id_new_item === productId
    );
    //If product is already on the list, remove it
    if (existingProduct) {
      if (toDelete) {
        let auxArray = [...selectedProducts];
        auxArray = auxArray.filter((x) => x.id_new_item !== productId);
        if (setLocals) {
          setSelectedProducts(auxArray);
          localStorage.setItem("selectedProducts", JSON.stringify(auxArray));
        }
        // set sale costs
        let existingCost = salesCost;
        /* existingCost.subtotal = updateSubtotal(auxArray);
                existingCost.iva = updateIva(auxArray);
                existingCost.total = parseFloat(
                    (existingCost.subtotal + existingCost.iva).toFixed(2)
                ); */
        let total = 0;
        auxArray.map((element) => {
          if (parseFloat(element.descuento_precio) > 0.0) {
            total += element.descuento_precio * element.cantidad;
          } else {
            total += element.precio_venta * element.cantidad;
          }
          if (element.idp) {
            total += element.cantidad * parseFloat(element.impuesto_idp);
          }
        });
        existingCost.total = parseFloat(total.toFixed(2));
        if (setLocals) {
          setSalesCost(existingCost);
          localStorage.setItem("salesCost", JSON.stringify(existingCost));
        }
      }
    } else {
      // If product is not on the list, add it
      let currentProduct = allProducts.find((x) => x.id_new_item === productId);
      currentProduct.cantidad = 1;

      if (parseFloat(currentProduct.descuento_precio) > 0.0) {
        currentProduct.total = currentProduct.descuento_precio;
      } else {
        currentProduct.total = currentProduct.precio_venta;
      }
      if (currentProduct.idp) {
        currentProduct.total += currentProduct.impuesto_idp;
      }

      // This code save the product custom environment for use
      // in Custom product component
      if (customProduct) {
        // TODO custom es undefined ya que no existe el valor en la db
        // verificar cuando se cambie.
        setCustomProducts([
          ...customProducts,
          {
            productId: currentProduct.id_new_item,
            custom: currentProduct.custom,
          },
        ]);

        console.log(customProducts);
      }

      if (currentProduct) {
        let newArray = [...selectedProducts];
        newArray.push(currentProduct);
        if (setLocals) {
          setSelectedProducts(newArray);
          localStorage.setItem("selectedProducts", JSON.stringify(newArray));
        }

        // set sales cost
        let existingCost = salesCost;
        /*                 existingCost.subtotal = updateSubtotal(newArray);
                existingCost.iva = updateIva(newArray); */
        let total = 0;
        newArray.map((element) => {
          if (parseFloat(element.descuento_precio) > 0.0) {
            total += element.descuento_precio;
          } else {
            total += element.precio_venta;
          }
          if (element.idp) {
            total += element.impuesto_idp * element.cantidad;
          }
        });
        existingCost.total = parseFloat(total.toFixed(2));
        if (setLocals) {
          setSalesCost(existingCost);
          localStorage.setItem("salesCost", JSON.stringify(existingCost));
        }
      }
    }
  };

  const changeProductQuantity = (productId, operation, byInput) => {
    let currentIndex = selectedProducts.findIndex(
      (x) => x.id_new_item === productId
    );
    let auxArray = [...selectedProducts];
    let existingCost = salesCost;
    if (auxArray[currentIndex]) {
      if (!byInput) {
        if (operation === "sum") {
          if (
            auxArray[currentIndex].cantidad + 1 >
            auxArray[currentIndex].stock
          ) {
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
          } else {
            auxArray[currentIndex].cantidad++;
            auxArray[currentIndex].total =
              parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
                ? auxArray[currentIndex].descuento_precio *
                  auxArray[currentIndex].cantidad
                : auxArray[currentIndex].precio_venta *
                  auxArray[currentIndex].cantidad;
            if (auxArray[currentIndex].idp) {
              auxArray[currentIndex].total +=
                auxArray[currentIndex].cantidad *
                auxArray[currentIndex].impuesto_idp;
            }
            auxArray[currentIndex].total = parseFloat(
              auxArray[currentIndex].total.toFixed(2)
            );
            /* existingCost.subtotal = updateSubtotal(auxArray);
                        existingCost.iva = updateIva(auxArray); */

            let total = 0;
            auxArray.map((element) => {
              if (parseFloat(element.descuento_precio) > 0.0) {
                total += element.descuento_precio * element.cantidad;
              } else {
                total += element.precio_venta * element.cantidad;
              }
              if (element.idp) {
                total += element.cantidad * parseFloat(element.impuesto_idp);
              }
            });
            existingCost.total = parseFloat(total.toFixed(2));
            setSalesCost(existingCost);
            localStorage.setItem("salesCost", JSON.stringify(existingCost));
          }
        }

        if (operation === "sub") {
          if (
            auxArray[currentIndex].cantidad - 1 >
            auxArray[currentIndex].stock
          ) {
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
          } else {
            if (auxArray[currentIndex].cantidad !== 1) {
              auxArray[currentIndex].cantidad--;
              auxArray[currentIndex].total =
                parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
                  ? auxArray[currentIndex].descuento_precio *
                    auxArray[currentIndex].cantidad
                  : auxArray[currentIndex].precio_venta *
                    auxArray[currentIndex].cantidad;
              if (auxArray[currentIndex].idp) {
                auxArray[currentIndex].total +=
                  auxArray[currentIndex].cantidad *
                  auxArray[currentIndex].impuesto_idp;
              }
              auxArray[currentIndex].total = parseFloat(
                auxArray[currentIndex].total.toFixed(2)
              );
              /* existingCost.subtotal = updateSubtotal(auxArray);
                            existingCost.iva = updateIva(auxArray); */
              let total = 0;
              auxArray.map((element) => {
                if (parseFloat(element.descuento_precio) > 0.0) {
                  total += element.descuento_precio * element.cantidad;
                } else {
                  total += element.precio_venta * element.cantidad;
                }
                if (element.idp) {
                  total += element.cantidad * parseFloat(element.impuesto_idp);
                }
              });
              existingCost.total = parseFloat(total.toFixed(2));
              setSalesCost(existingCost);
              localStorage.setItem("salesCost", JSON.stringify(existingCost));
            }
          }
        }
      } else {
        if (document.getElementById("value-" + productId)) {
          if (
            Math.abs(document.getElementById("value-" + productId).value) === 0
          )
            document.getElementById("value-" + productId).value = 1;
          if (
            Math.abs(document.getElementById("value-" + productId).value) >
            auxArray[currentIndex].stock
          ) {
            console.log(
              document.getElementById("value-" + productId).value,
              "is in fact greater than",
              auxArray[currentIndex].stock
            );
            toast.error(
              "No puede seleccionar esa cantidad porque no hay suficiente en el inventario",
              { autoClose: 10000 }
            );
          } else {
            auxArray[currentIndex].cantidad = Math.abs(
              document.getElementById("value-" + productId).value
            );
            auxArray[currentIndex].total =
              parseFloat(auxArray[currentIndex].descuento_precio) > 0.0
                ? auxArray[currentIndex].descuento_precio *
                  auxArray[currentIndex].cantidad
                : auxArray[currentIndex].precio_venta *
                  auxArray[currentIndex].cantidad;
            if (auxArray[currentIndex].idp) {
              auxArray[currentIndex].total +=
                auxArray[currentIndex].cantidad *
                auxArray[currentIndex].impuesto_idp;
            }
            auxArray[currentIndex].total = parseFloat(
              auxArray[currentIndex].total.toFixed(2)
            );
            /* existingCost.subtotal = updateSubtotal(auxArray);
                        existingCost.iva = updateIva(auxArray); */

            let total = 0;
            auxArray.map((element) => {
              if (parseFloat(element.descuento_precio) > 0.0) {
                total += element.descuento_precio * element.cantidad;
              } else {
                total += element.precio_venta * element.cantidad;
              }
              if (element.idp) {
                total += element.cantidad * element.impuesto_idp;
              }
            });
            existingCost.total = parseFloat(total.toFixed(2));

            setSalesCost(existingCost);
            localStorage.setItem("salesCost", JSON.stringify(existingCost));
          }
        }
      }
      setSelectedProducts(auxArray);
      localStorage.setItem("selectedProducts", JSON.stringify(auxArray));
    }
  };

  const removeProduct = (productId) => {
    let currentIndex = selectedProducts.findIndex(
      (x) => x.id_new_item === productId
    );
    if (currentIndex !== -1) {
      let auxArray = [...selectedProducts];
      auxArray = auxArray.filter((x) => x.id_new_item !== productId);
      setSelectedProducts(auxArray);
      handleBoxClick(productId, true, true);
      localStorage.setItem("selectedProducts", JSON.stringify(auxArray));
    }
  };

  const handlePagination = (event, value) => {
    let data;
    let filteredData;
    switch (currentTab) {
      case 0:
        data = [...filteredProducts];
        filteredData = data.slice(
          maxPerPage * (value - 1),
          maxPerPage * (value - 1) + maxPerPage
        );
        if (JSON.stringify(data) !== "[]") {
          $("#friggin-loader").addClass("d-block");
          $("#products-catalog").addClass("d-none");
        }
        setPaginatedProducts(filteredData);
        setPage_0(value);
        break;
      case 1:
        data = [...filteredMostSaleProducts];
        filteredData = data.slice(
          maxPerPage * (value - 1),
          maxPerPage * (value - 1) + maxPerPage
        );
        if (JSON.stringify(data) !== "[]") {
          $("#friggin-loader_2").addClass("d-block");
          $("#products-catalog_2").addClass("d-none");
        }
        setPaginatedMostSaleProducts(filteredData);
        setPage_1(value);
        break;
      case 2:
        data = [...filteredLastSoldProducts];
        filteredData = data.slice(
          maxPerPage * (value - 1),
          maxPerPage * (value - 1) + maxPerPage
        );
        if (JSON.stringify(data) !== "[]") {
          $("#friggin-loader_3").addClass("d-block");
          $("#products-catalog_3").addClass("d-none");
        }
        setPaginatedLastSoldProducts(filteredData);
        setPage_2(value);
        break;
      default:
        break;
    }
  };

  const gotoPayment = () => {
    if (selectedProducts.length > 0) {
      history.push({
        pathname: "/app/vender/procesar",
      });
    } else {
      toast.error("Debe escoger al menos 1 producto.", { autoClose: 10000 });
    }
  };

  const getExistingProducts = () => {
    if (!!localStorage.getItem("selectedProducts")) {
      let products = JSON.parse(localStorage.getItem("selectedProducts"));
      console.log("Obtaining from local storage", products);
      setSelectedProducts(products);
    }
    if (!!localStorage.getItem("salesCost")) {
      setSalesCost(JSON.parse(localStorage.getItem("salesCost")));
    }
  };

  const filterProducts = (no_query, manualInput) => {
    let query;
    if (no_query) {
      query = "";
    } else {
      query = filterText;
    }
    if (manualInput) query = manualInput;
    if (query === "") {
      if (productCategory === "") {
        setFilteredProducts(allProducts);
        setFilteredMostSaleProducts(mostSaleProducts);
        setFilteredLastSoldProducts(lastSoldProducts);
      } else {
        setFilteredProducts(
          allProducts.filter(
            (item) => item.id_clasificacion1_web_app == productCategory
          )
        );
        setFilteredMostSaleProducts(
          mostSaleProducts.filter(
            (item) => item.id_clasificacion1_web_app == productCategory
          )
        );
        setFilteredLastSoldProducts(
          lastSoldProducts.filter(
            (item) => item.id_clasificacion1_web_app == productCategory
          )
        );
      }
    } else {
      if (productCategory === "") {
        setFilteredProducts(
          allProducts.filter(
            (item) =>
              item.nombre.toLowerCase().includes(query.toLowerCase()) ||
              item.codigo.toLowerCase().includes(query.toLowerCase())
          )
        );
        setFilteredMostSaleProducts(
          mostSaleProducts.filter(
            (item) =>
              item.nombre.toLowerCase().includes(query.toLowerCase()) ||
              item.codigo.toLowerCase().includes(query.toLowerCase())
          )
        );
        setFilteredLastSoldProducts(
          lastSoldProducts.filter(
            (item) =>
              item.nombre.toLowerCase().includes(query.toLowerCase()) ||
              item.codigo.toLowerCase().includes(query.toLowerCase())
          )
        );
      } else {
        setFilteredProducts(
          allProducts.filter(
            (item) =>
              item.id_clasificacion1_web_app == productCategory &&
              (item.nombre.toLowerCase().includes(query.toLowerCase()) ||
                item.codigo.toLowerCase().includes(query.toLowerCase()))
          )
        );
        setFilteredMostSaleProducts(
          mostSaleProducts.filter(
            (item) =>
              item.id_clasificacion1_web_app == productCategory &&
              (item.nombre.toLowerCase().includes(query.toLowerCase()) ||
                item.codigo.toLowerCase().includes(query.toLowerCase()))
          )
        );
        setFilteredLastSoldProducts(
          lastSoldProducts.filter(
            (item) =>
              item.id_clasificacion1_web_app == productCategory &&
              (item.nombre.toLowerCase().includes(query.toLowerCase()) ||
                item.codigo.toLowerCase().includes(query.toLowerCase()))
          )
        );
      }
    }
  };

  const updateFilterText = (e) => {
    let currentValue = filterText;
    if (
      Math.abs(currentValue.length - e.target.value.length) > 1 &&
      e.target.value.length > 0
    ) {
      console.log("READING BARCODE");
      filterProducts(true, e.target.value);
    }
    setFilterText(e.target.value);
  };

  const houseKeeping = () => {
    const toDelete = ["selectedProducts", "salesCost"];
    toDelete.map((value) => {
      localStorage.removeItem(value);
    });
    selectedProducts.map((product) =>
      handleBoxClick(product.id_new_item, true, true)
    );
    setSelectedProducts([]);
    $("#cancelSale").modal("hide");
  };

  const updateIva = (array) => {
    let iva = 0;
    let price;
    let discountPrice;
    let discount;
    let taxableAmount;
    array.map((product) => {
      price = product.cantidad * product.precio_venta;
      discountPrice = product.cantidad * product.descuento_precio;
      discount = price - discountPrice;
      if (parseInt(product.descuento_precio) > 0) {
        taxableAmount = (price - discount) / 1.12;
        iva += price - discount - taxableAmount;
      } else {
        taxableAmount = price / 1.12;
        iva += price - taxableAmount;
      }
    });
    return parseFloat(iva.toFixed(2));
  };

  const updateSubtotal = (array) => {
    let price;
    let discountPrice;
    let discount;
    let taxableAmount;
    let totalBill = 0;
    array.map((product) => {
      price = product.cantidad * product.precio_venta;
      discountPrice = product.cantidad * product.descuento_precio;
      if (parseInt(product.descuento_precio) > 0) {
        discount = price - discountPrice;
        taxableAmount = (price - discount) / 1.12;
      } else {
        discount = price;
        taxableAmount = price / 1.12;
      }
      totalBill += taxableAmount;
    });
    return parseFloat(totalBill.toFixed(2));
  };

  const getPrice = (product) => {
    let price;
    let discountPrice;
    let discount;
    let taxableAmount;
    let iva;
    price = product.precio_venta;
    discountPrice = product.descuento_precio;
    discount = price - discountPrice;
    if (parseInt(product.descuento_precio) > 0) {
      taxableAmount = (price - discount) / 1.12;
      iva = price - discount - taxableAmount;
    } else {
      taxableAmount = price / 1.12;
      iva = price - taxableAmount;
    }
    let total = taxableAmount + iva;
    return parseFloat(total.toFixed(2));
  };

  const set_categories = (productsList) => {
    API.products
      .getProductAttributes({})
      .then((res) => {
        let response = res.data;
        if (response.success && JSON.stringify(response.data) !== "[]") {
          let categoriesArray = [];
          productsList.map((product) => {
            categoriesArray.push(product.id_clasificacion1_web_app);
          });
          let categoriesState = [];
          let categoriesSet = new Set(categoriesArray);

          Array.from(categoriesSet).map((id) => {
            categoriesState.push(
              response.data.categories.find(
                (x) => x.id_clasificacion1_web_app === id
              )
            );
          });
          setCategories(sortAlphabetically(categoriesState));
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("Ha ocurrido un error al fijar las categorias");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const nuclearUpdate = (array) => {
    array.map((product) => {
      let currentElement = $(`#product-${product.id_new_item}`);
      if (currentElement.hasClass("product-selected")) {
        currentElement.removeClass("product-selected");
        $(`#product-${product.id_new_item} > .product-body`).removeClass(
          "product-selected"
        );
        $(`#product-icon-${product.id_new_item}`).addClass("d-none");
      }
    });
  };

  const resetSearch = () => {
    setFilterText("");
    filterProducts(true);
  };

  const sortAlphabetically = (array) => {
    let sortedArray = array.sort(function (a, b) {
      if (a.descripcion < b.descripcion) {
        return -1;
      }
      if (a.descripcion > b.descripcion) {
        return 1;
      }
      return 0;
    });
    return sortedArray;
  };

  return (
    <div id="dashboard-section">
      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Caja de venta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={store} onChange={changeStore}>
            {sucursals.map((sucursal) => (
              <option value={sucursal.id_sucursal_bodega_ubicacion}>
                {sucursal.nombre_sucursal}
              </option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={caja} onChange={changeCaja}>
            {cajas.map((caja) => (
              <option value={caja.id_caja_web_app}>{caja.descripcion}</option>
            ))}
          </Select>
        </FormControl>

        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />

        <FormControl variant="outlined">
          <Select native value={seller} onChange={changeSeller}>
            {sellers.map((seller) => (
              <option value={seller.id_vendedor_web_app}>
                {seller.nombre}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="row">
        {/* Left half */}
        <div className="col-xl-6">
          <div className="card content-card mt-2">
            <div className="card-header p-0" />
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="input-group search-bar">
                    <input
                      type="text"
                      name="codigo_producto"
                      value={filterText}
                      onChange={(e) => updateFilterText(e)}
                      autoFocus
                      className="form-control"
                      placeholder="Ingrese el nombre o código del producto."
                      onKeyDown={(e) => {
                        if (e.key === "Enter") filterProducts();
                      }}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{ backgroundColor: "#0160E7" }}
                      >
                        <SearchIcon onClick={() => filterProducts()} />
                      </span>
                      <span
                        className="input-group-text"
                        style={{ backgroundColor: "red" }}
                      >
                        <ClearIcon onClick={resetSearch} />
                      </span>
                    </div>
                  </div>
                </div>
                {/* TODO
				Hacer que aleko sea una variable dinamica

				if true
				prod.regulares/prod.personalizables
				else
				Todo/Mas vendidos/Vendidos reciente
			      */}
                <ListTab
                  vars={{
                    Tabs,
                    currentTab,
                    handleProductTabs,
                    Tab,
                    Select,
                    productCategory,
                    changeCategory,
                    categories,
                    TabPanel,
                    CircularProgress,
                    filterProducts,
                    paginatedProducts,
                    handleBoxClick,
                    realImageURL,
                    base_url_images,
                    CheckCircleIcon,
                    getPrice,
                    filteredProducts,
                    Pagination,
                    page_0,
                    maxPerPage,
                    handlePagination,
                    filteredMostSaleProducts,
                    paginatedMostSaleProducts,
                    page_1,
                    filteredLastSoldProducts,
                    aleko: true,
                    allCustomProducts,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Right half */}
        <div className="col-xl-6">
          <div className="card content-card mt-2">
            <div
              className="card-header p-0"
              key="facturacion-products-header"
              id="facturacion-products-header"
            >
              {selectedProducts.length > 0 ? (
                <DataTable
                  className="datatable-cs datatable-cs-v2 m-0"
                  title={"s"}
                  columns={[
                    {
                      name: "Producto",
                      selector: "producto",
                      sortable: true,
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <IconButton
                            className="mx-1 no-outline-buttons"
                            onClick={() => removeProduct(row.id_new_item)}
                            size="small"
                            style={{
                              background: "none",
                              color: "#EB5757",
                              fontSize: "20px",
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>

                          <div>
                            <small
                              style={{
                                fontSize: "11.4px",
                              }}
                            >
                              {row.nombre}
                            </small>
                            <br />
                            <small
                              style={{
                                fontSize: "11.4px",
                              }}
                            >
                              {row.codigo}
                            </small>
                          </div>
                        </div>
                      ),
                    },
                    {
                      name: "Precio",
                      selector: "precio_venta",
                      sortable: true,
                      cell: (row) => (
                        <div>
                          Q.{" "}
                          {parseFloat(row.descuento_precio > 0.0)
                            ? row.descuento_precio.toFixed(2)
                            : row.precio_venta.toFixed(2)}
                        </div>
                      ),
                    },
                    {
                      name: "Cantidad",
                      selector: "cantidad",
                      sortable: true,
                      cell: (row) => (
                        <div
                          key={"quantity-section-" + row.id_new_item}
                          className="w-100 p-0 d-flex align-items-center justify-content-around"
                        >
                          <IconButton
                            aria-label="delete"
                            className="mx-1 no-outline-buttons bg_secondary_color"
                            key={"minusbutton-" + row.id_new_item}
                            onClick={() =>
                              changeProductQuantity(
                                row.id_new_item,
                                "sub",
                                false
                              )
                            }
                            size="small"
                            style={{
                              color: "white",
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <TextField
                            className="w-50 quantity-inputs"
                            variant="outlined"
                            defaultValue={Math.abs(row.cantidad)}
                            type="number"
                            id={"value-" + row.id_new_item}
                            onKeyDown={(e) => {
                              if (e.key === "Enter")
                                changeProductQuantity(
                                  row.id_new_item,
                                  "none",
                                  true
                                );
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    className="mx-1 no-outline-buttons"
                                    onClick={() =>
                                      changeProductQuantity(
                                        row.id_new_item,
                                        "none",
                                        true
                                      )
                                    }
                                    size="small"
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    <SendIcon
                                      style={{
                                        fontSize: "8px",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 1,
                              },
                            }}
                          />
                          <IconButton
                            aria-label="delete"
                            key={"plusbutton-" + row.id_new_item}
                            className="mx-1 no-outline-buttons bg_secondary_color"
                            onClick={() =>
                              changeProductQuantity(
                                row.id_new_item,
                                "sum",
                                false
                              )
                            }
                            size="small"
                            style={{
                              color: "white",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      ),
                    },
                    {
                      name: "Total",
                      selector: "total",
                      sortable: true,
                      cell: (row) => <div>Q. {row.total.toFixed(2)}</div>,
                    },
                  ]}
                  data={selectedProducts}
                  pagination
                  paginationIconFirstPage={null}
                  paginationIconLastPage={null}
                  paginationComponentOptions={{
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                />
              ) : (
                <p className="text-center mt-2">
                  Los productos seleccionados aparecerán aqui
                </p>
              )}
            </div>

            {/* 
		      TODO
			Hacer que aleko sea una variable dinamica
			segun el tipo de permiso.

			el resto son variables necesarias del
			componente

			Si aleko es "true", 
			el boton que envia 
			a /app/vender/procesar
			mostrara Continuar
			si no mostrara pagar.

		      */}
            <InvoiceActions
              vars={{
                IconButton,
                DeleteIcon,
                gotoPayment,
                CreditCardIcon,
                Tooltip,
                InfoIcon,
                salesCost,
                aleko: true,
              }}
            />

            <div className="card-body mt-2" id="catalog-options-menu">
              <div className="row justify-content-center">
                <div className="col-12 d-flex justify-content-between align-items-center flex-wrap">
                  <div className="card card-body catalog-option">
                    <p>Notas de crédito</p>
                    <Button
                      className="btn-cs1 mx-1 mb-1 link-button"
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={"/app/operaciones_historial/nota_credito/emitir"}
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                        }}
                      >
                        Crear <br />
                        nota
                      </span>
                    </Button>
                  </div>
                  <div className="card card-body catalog-option">
                    <p>Anulaciones de ventas</p>
                    <Button
                      className="btn-cs1 mx-1 mb-1 link-button"
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={"/app/operaciones_historial/crear_anulacion"}
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                        }}
                      >
                        Anular <br />
                        venta
                      </span>
                    </Button>
                  </div>
                  <div className="card card-body catalog-option">
                    <p>Anticipos de pagos</p>
                    <Button
                      className="btn-cs1 mx-1 mb-1 link-button"
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={"/app/anticipo/crear"}
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                        }}
                      >
                        Crear <br />
                        anticipo
                      </span>
                    </Button>
                  </div>
                  <div className="card card-body catalog-option">
                    <p>Cerrar caja</p>
                    <Button
                      className="btn-cs1 mx-1 mb-1 link-button"
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={"/app/cortes_caja/realizar_corte"}
                    >
                      <span
                        style={{
                          marginBottom: "-4px",
                        }}
                      >
                        Realizar <br />
                        corte
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="cancelSale" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>
                <strong>¿Seguro que desea cancelar la compra?</strong>
              </h4>

              <div className="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={houseKeeping}
                >
                  Sí, cancelar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
