import { base_url, axios } from "../api";
var qs = require("qs");
const moment = require("moment");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const getAll = async (data) => {
  return await axios.post(`${base_url}/web/get-egresos`, qs.stringify(data), {
    headers: headers(),
  });
};

const getById = async (data) => {
  return await axios.post(`${base_url}/web/get-egreso`, qs.stringify(data), {
    headers: headers(),
  });
};

const add_new = async (data) => {
  data.fecha_egreso = moment(data.fecha_egreso).toDate();
  return await axios.post(
    `${base_url}/web/add-egreso`,
    qs.stringify(data, { arrayFormat: "comma", encode: false }),
    { headers: headers() }
  );
};

const getMotives = async () => {
  return await axios.get(`${base_url}/web/get-egreso-motives`, {
    headers: headers(),
  });
};

export default {
  getAll,
  getMotives,
  add_new,
  getById,
};
