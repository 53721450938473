import React, { useReducer, useState } from "react";
import API, { getMerma } from "../../api/products";

import MermaContext from "./MermaContext";

const MermaProvider = (props) => {
  const initialState = {
    merma: 0,
    cantidad_merma: 0,
    precio_venta_descuento: 0,
    precio_venta: 0,
    medida_merma: "%",
    valor_merma: 0,
    cantidad_peso: 0,
    costo_unitario: 0,
    cantidad_unidades: 0,
  };
  const [form, setForm] = useState(initialState);
  const handleChange = (e, vendido_por) => {
    if (vendido_por && vendido_por === "mixto") {
      if (e.target.name === "cantidad_unidades") {
        setForm({ ...form, cantidad_peso: e.target.value });
        return;
      }
      if (e.target.name === "cantidad_peso") {
        setForm({ ...form, cantidad_unidades: e.target.value });
        return;
      }
    }

    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const mermaEstimada =
    form.medida_merma === "%"
      ? form.cantidad_peso * (form.cantidad_merma / 100)
      : form.cantidad_merma;

  const valorMerma =
    form.medida_merma === "%"
      ? form.cantidad_peso * (form.cantidad_merma / 100) * form.costo_unitario
      : (form.precio_venta_descuento || form.precio_venta) *
        form.cantidad_merma;

  const cantidadActual =
    form.medida_merma === "%" ? form.cantidad_peso : form.cantidad_unidades;

  const getMermaData = async (id) => {
    try {
      const { data } = await getMerma({ id_new_item: id });
      if (!data.data.merma_data) {
        setForm({
          ...initialState,
          cantidad_unidades: data.data.stock_libras,
          cantidad_peso: data.data.stock,
          precio_venta: data.data.precio_descuento || data.data.precio_venta,
          costo_unitario: data.data.costo_unitario,
        });

        return;
      }
      const { cantidad_merma, medida_merma } = data.data.merma_data;
      const {
        stock: cantidad_peso,
        stock_libras: cantidad_unidades,
        precio_venta,
        descuento_precio: precio_venta_descuento,
      } = data.data;
      setForm({
        ...form,
        cantidad_merma,
        medida_merma,
        cantidad_unidades,
        cantidad_peso,
        precio_venta,
        precio_venta_descuento,
        merma: Boolean(data.data.merma_data),
        costo_unitario: data.data.costo_unitario,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const clearState = () => setForm(initialState);

  return (
    <MermaContext.Provider
      value={{
        form,
        setForm,
        handleChange,
        getMermaData,
        clearState,
        mermaEstimada,
        valorMerma,
        cantidadActual,
      }}
    >
      {props.children}
    </MermaContext.Provider>
  );
};

export { MermaProvider };
