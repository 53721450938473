import { Box, Gridider, Grid, Typography } from "@material-ui/core";
import React from "react";
import moment from "moment";
import { getMoneda } from "../../helpers/moneda";

const CorteDeCajaPdf = ({
  sucursal,
  caja,
  cmi,
  seller,
  corte_data,
  isCorteZ,
  sales,
  getFormaPago,
  formaterPriceFunction,
  comerLocal,
  domicilio,
  llevar,
  products,
  retiros,
  totalRetiros,
  totalGiftCard,
  totalAnticipos,
  getTotalesCobrados,
  getSubtotales,
}) => {
  const moneda = getMoneda();
  return (
    <Box style={{ display: "none", width: "100%" }}>
      <Box style={{ width: "100%" }} id="print-form">
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid xs={12}>
            {!cmi && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
                  {sucursal.nombre_sucursal} <br />
                  {sucursal.direccion_sucursal} <br />
                  {caja.descripcion} <br />
                  {!isCorteZ ? "Usuario: " : ""}
                  {seller.nombre} <br />
                  CORTE DE CAJA {corte_data.id_caja_corte_web_app}
                </Typography>
              </Box>
            )}
            {cmi && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
                  {sucursal.nombre_sucursal} <br />
                  {!isCorteZ ? `Vendedor: ${seller?.nombre}` : <></>} <br />
                  {sucursal.direccion_sucursal} <br />
                  FACTURA <br />
                  CORTE DE FACTURACIÓN DIARIA {isCorteZ ? "Z" : "Y"}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid xs={12} className="mt-2">
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={2}>
            <Typography>Vendedor</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography>Serie</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography>Documento</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography>Fecha</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>Cliente</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography>Forma de pago</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography style={{ textAlign: "end" }}>Total</Typography>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          {sales.map((sale) => (
            <>
              <Grid xs={2}>
                <Typography>{sale.vendedor}</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography>{sale.serie}</Typography>
              </Grid>

              <Grid xs={2}>
                <Typography>{sale.documento}</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography>
                  {moment(sale.fecha).utcOffset(0).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography>
                  {sale.client.nombre_cliente_final || sale.client.nombre}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography className="text-right">
                  {getFormaPago(sale.id_forma_pago)}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography className="text-right">
                  {moneda}.{" "}
                  {formaterPriceFunction(parseFloat(sale.monto_total))}
                </Typography>
              </Grid>
            </>
          ))}
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={6}>
            <Typography style={{ fontWeight: "bold" }} className="text-right">
              GRAN TOTAL
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography style={{ fontWeight: "bold" }} className="text-right">
              {moneda}.{" "}
              {formaterPriceFunction(
                parseFloat(sales.reduce((a, b) => a + b.monto_total, 0))
              )}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={12} className="mb-1">
            <Typography>{corte_data.fecha}</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography style={{ fontWeight: "bold" }}>
              FACTURAS EMITIDAS
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography style={{ fontWeight: "bold" }}>
              {sales.length}
            </Typography>
          </Grid>
          <Grid xs={5}>
            <Typography style={{ fontWeight: "bold" }}>
              FACTURAS ANULADAS
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography style={{ fontWeight: "bold" }}>
              {corte_data.ventasAnuladas}
            </Typography>
          </Grid>
          <Grid xs={5}>
            <Typography style={{ fontWeight: "bold" }}>
              {!cmi ? (
                <span>N° DE VENTAS CONSUMO LOCAL </span>
              ) : (
                <span>N° DE VENTAS PICKUP</span>
              )}
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography style={{ fontWeight: "bold" }}>
              {comerLocal.ventas}
            </Typography>
          </Grid>
          <Grid xs={5}>
            <Typography style={{ fontWeight: "bold" }}>
              {!cmi ? (
                <strong>N° DE VENTAS A DOMICILIO </strong>
              ) : (
                <strong>N° DE VENTAS ENTREGA</strong>
              )}
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography style={{ fontWeight: "bold" }}>
              {domicilio.ventas}
            </Typography>
          </Grid>
          <Grid xs={5}>
            <Typography style={{ fontWeight: "bold" }}>
              {!cmi ? (
                <strong>N° DE VENTAS PARA LLEVAR </strong>
              ) : (
                <strong>N° DE VENTAS POR PRESENCIAL</strong>
              )}
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography style={{ fontWeight: "bold" }}>
              {llevar.ventas}
            </Typography>
          </Grid>

          <Grid xs={5}>
            <Typography style={{ fontWeight: "bold" }}>
              <strong>N° DE NOTAS DE DEBITO</strong>
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>
              {" "}
              <strong>{corte_data.no_notas_debito}</strong>
            </Typography>
          </Grid>
          <Grid xs={6}></Grid>
          {cmi && !isCorteZ ? (
            <>
              <Grid xs={4}>
                <Typography style={{ fontWeight: "bold" }}>
                  <strong>FONDO PARA CAMBIOS</strong>
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography style={{ fontWeight: "bold", textAlign: "right" }}>
                  {" "}
                  {moneda}.{" "}
                  {formaterPriceFunction(parseFloat(corte_data.total_cambios))}
                </Typography>
              </Grid>
            </>
          ) : (
            ""
          )}
          <Grid xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              RESUMEN DE PRODUCTOS VENDIDOS
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={6}>
            <Typography>Item</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography>Total Unidades</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className="text-right">Monto</Typography>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          {products.map((product) => (
            <>
              <Grid xs={6}>
                <Typography>{product.nombre}</Typography>
              </Grid>
              <Grid xs={3}>
                <Typography>{product.cantidad}</Typography>
              </Grid>
              <Grid xs={3}>
                <Typography className="text-right">
                  {formaterPriceFunction(parseFloat(product.monto_moneda))}
                </Typography>
              </Grid>
            </>
          ))}
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={6}></Grid>
          <Grid xs={3}>
            <Typography style={{ fontWeight: "bold" }}>TOTAL</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className="text-right" style={{ fontWeight: "bold" }}>
              {moneda}.{" "}
              {formaterPriceFunction(
                parseFloat(products.reduce((a, b) => a + b.monto_moneda, 0))
              )}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              RESUMEN DE RETIROS DE EFECTIVO
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={3}>
            <Typography>N° de retiro</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography>Nombre</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className="text-right">Fecha</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className="text-right">Monto</Typography>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>

          {!retiros.length ? <Grid xs={12}>Sin datos.</Grid> : ""}
          {retiros.map((retiro, index) => (
            <>
              <Grid xs={3}>
                <Typography>{index + 1}</Typography>
              </Grid>
              <Grid xs={3}>
                <Typography>
                  {retiro.nombre_usuario
                    ? retiro.nombre_usuario
                    : retiro.nombre}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography className="text-center">
                  {" "}
                  {moment(retiro.retiro_fecha)
                    .utcOffset(0)
                    .format("DD/MM/YYYY h:mm:ss a")}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography className="text-center">
                  {" "}
                  {retiro.cantidad_retiro.toFixed(2)}
                </Typography>
              </Grid>
            </>
          ))}
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={6}></Grid>
          <Grid xs={3}>
            <Typography style={{ fontWeight: "bold" }}>TOTAL</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className="text-right" style={{ fontWeight: "bold" }}>
              {moneda}. {formaterPriceFunction(parseFloat(totalRetiros))}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={12}>
            <Typography style={{ fontWeight: "bold" }}>ANULACIONES</Typography>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          <Grid xs={2}>Vendedor</Grid>
          <Grid xs={2}>Serie</Grid>
          <Grid xs={2}>Documento</Grid>
          <Grid xs={2}>Fecha</Grid>
          <Grid xs={1}>Cliente</Grid>
          <Grid xs={2}>Forma pago</Grid>
          <Grid xs={1} className="text-right">
            Total
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom"></Box>
          </Grid>
          {!corte_data?.anulaciones?.length ? (
            <Grid xs={12}>Sin datos.</Grid>
          ) : (
            ""
          )}

          {corte_data?.anulaciones?.map((sale) => (
            <>
              <Grid xs={2}>
                <span>{sale.vendedor}</span>
              </Grid>
              <Grid xs={2}>{sale.serie}</Grid>
              <Grid xs={2}>{sale.documento}</Grid>
              <Grid xs={2}>
                {moment(sale.fecha).utcOffset(0).format("DD/MM/YYYY")}
              </Grid>
              <Grid xs={1}>{sale.client.nombre}</Grid>
              <Grid xs={2}>{getFormaPago(sale.id_forma_pago)}</Grid>
              <Grid xs={1} className="text-right">
                {moneda}. {formaterPriceFunction(parseFloat(sale.monto_total))}
              </Grid>
            </>
          ))}
          <Grid xs={12}>
            <Box className="w-100 border-bottom mb-2"></Box>
          </Grid>
          <Grid xs={6}>
            <Typography style={{ fontWeight: "bold" }}>
              {!cmi ? "RESUMEN FORMA DE PAGO" : "TOTALES COBRADOS"}
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography style={{ fontWeight: "bold" }}>
              TOTAL EFECTIVO EN CAJA
            </Typography>
          </Grid>
          <Grid xs={2} className="text-right">
            <strong>
              {moneda}.{" "}
              {formaterPriceFunction(
                parseFloat(
                  corte_data.total_efectivo +
                    corte_data.total_cambios -
                    totalRetiros
                )
              )}
            </strong>
          </Grid>
          <Grid xs={6}></Grid>
          <Grid xs={4}>
            <Typography style={{ fontWeight: "bold" }}>
              TOTAL CRÉDITO
            </Typography>
          </Grid>
          <Grid xs={2} className="text-right">
            <strong>
              {moneda}.{" "}
              {formaterPriceFunction(
                parseFloat(corte_data.total_facturas_credito)
              )}{" "}
            </strong>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom mb-2"></Box>
          </Grid>
          <Grid xs={6}></Grid>
          <Grid xs={6}>
            <Grid container direction="row">
              <Grid xs={6}>
                <b>TOTAL EFECTIVO</b>
              </Grid>
              <Grid xs={6} className="text-right">
                {" "}
                <strong>
                  {moneda}.{" "}
                  {formaterPriceFunction(parseFloat(corte_data.total_efectivo))}
                </strong>
              </Grid>
              <Grid xs={6}>
                <b>TOTAL TARJETAS</b>
              </Grid>
              <Grid xs={6} className="text-right">
                <strong>
                  {moneda}.{" "}
                  {formaterPriceFunction(
                    parseFloat(corte_data.total_tarjetas)
                  ) || "0.00"}
                </strong>
              </Grid>
              <Grid xs={6}>
                <b>TOTAL VALE</b>
              </Grid>
              <Grid xs={6} className="text-right">
                <strong>
                  {moneda}. {formaterPriceFunction(parseFloat(totalGiftCard))}
                </strong>
              </Grid>
              <Grid xs={6}>
                <b>TOTAL ANTICIPO</b>
              </Grid>
              <Grid xs={6} className="text-right">
                <strong>
                  {moneda}. {formaterPriceFunction(parseFloat(totalAnticipos))}
                </strong>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom mb-2"></Box>
          </Grid>
          <Grid xs={6}></Grid>
          <Grid xs={6}>
            <Box className="d-flex justify-content-between w-100">
              <span>
                <strong>GRAN TOTAL</strong>
              </span>
              <span>
                <strong>
                  {moneda}.{" "}
                  {formaterPriceFunction(parseFloat(getTotalesCobrados()))}
                </strong>
              </span>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom mb-2"></Box>
          </Grid>
          <Grid xs={12}>
            <strong>{!cmi ? "RESUMEN" : "TOTALES FACTURADOS"}</strong>
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 border-bottom mb-2"></Box>
          </Grid>
          <Grid xs={6}></Grid>
          <Grid xs={6}>
            <Grid container direction="row">
              <Grid xs={6}>
                {" "}
                <strong>VENTAS BRUTAS</strong>
              </Grid>
              <Grid xs={6} className="text-right">
                <strong>
                  {moneda}.{" "}
                  {formaterPriceFunction(
                    parseFloat(sales.reduce((a, b) => a + b.monto_total, 0))
                  )}
                </strong>
              </Grid>
              <Grid xs={6}>
                {" "}
                <strong>VENTAS NETAS</strong>
              </Grid>
              <Grid xs={6} className="text-right">
                <strong>
                  {moneda}.{" "}
                  {formaterPriceFunction(
                    parseFloat(
                      sales.reduce((a, b) => a + b.monto_total, 0) -
                        sales.reduce((a, b) => a + b.monto_total, 0) *
                          (corte_data.iva / 100)
                    )
                  )}
                </strong>
              </Grid>
              {moneda !== "L" ? (
                <>
                  <Grid xs={6}>
                    <strong>IVA({corte_data.iva}%)</strong>
                  </Grid>
                  <Grid xs={6} className="text-right">
                    <strong>
                      {moneda}.{" "}
                      {formaterPriceFunction(
                        parseFloat(
                          (corte_data.iva / 100) *
                            sales.reduce((a, b) => a + b.monto_total, 0)
                        )
                      )}
                    </strong>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid xs={6}>
                    <strong>TOTAL EXENTO</strong>
                  </Grid>
                  <Grid xs={6} className="text-right">
                    <strong>L{getSubtotales("Exento")}</strong>
                  </Grid>
                  <Grid xs={6}>
                    <strong>TOTAL EXONERADO</strong>
                  </Grid>
                  <Grid xs={6} className="text-right">
                    <strong>L{getSubtotales("Exonerado")}</strong>
                  </Grid>

                  <Grid xs={6}>
                    <strong>ISV 15%</strong>
                  </Grid>
                  <Grid xs={6} className="text-right">
                    <strong>L{getSubtotales("15%")}</strong>
                  </Grid>
                  <Grid xs={6}>
                    <strong>ISV 18%</strong>
                  </Grid>
                  <Grid xs={6} className="text-right">
                    <strong>L{getSubtotales("18%")}</strong>
                  </Grid>
                </>
              )}
              {!cmi && !isCorteZ && (
                <>
                  <Grid xs={6}>
                    <strong>IDP</strong>
                  </Grid>
                  <Grid xs={6} className="text-right">
                    <strong>
                      {moneda}.{" "}
                      {formaterPriceFunction(parseFloat(corte_data.monto_idp))}
                    </strong>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid xs={12}>
            <span>
              <strong>Realizado por: </strong>
            </span>
            {corte_data.usuario_corte}
          </Grid>
          <Grid xs={12}>
            <Box className="w-100 d-flex justify-content-between mt-5">
              <Box style={{ width: "200px" }} className="d-flex flex-wrap">
                <hr
                  className="w-100"
                  style={{ borderTop: "1px solid black" }}
                />
                Jefe de sala
              </Box>
              <Box style={{ width: "200px" }} className="d-flex flex-wrap">
                <hr
                  className="w-100"
                  style={{ borderTop: "1px solid black" }}
                />
                Cajero
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CorteDeCajaPdf;
