import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};
const alekoHeaders = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: token };
};

const getAll = async (data) => {
  return await axios.post(
    `${base_url}/web/get-all-products`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getExpired = async () => {
  return await axios.get(`${base_url}/web/get-expired-products`, {
    headers: headers(),
  });
};

const getNearExpiry = async () => {
  return await axios.get(`${base_url}/web/get-near-expiry-products`, {
    headers: headers(),
  });
};

const getLowStock = async () => {
  return await axios.get(`${base_url}/web/get-low-stock-products`, {
    headers: headers(),
  });
};

const getMostSale = async (data) => {
  return await axios.post(
    `${base_url}/web/best-selling-products`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getLastSold = async (data) => {
  return await axios.post(
    `${base_url}/web/last-sold-products`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const createNew = async (data, files) => {
  const form_data = new FormData();
  for (var key in data) {
    form_data.append(key, data[key]);
  }

  if (typeof data.stock_minimo !== "undefined") {
    form_data.append("notificaciones_inventario", 1);
  }

  if (data.vencimiento_fecha) {
    form_data.append("vencimiento", 1);
  } else {
    form_data.append("vencimiento", 0);
  }

  form_data.append("inventariado", 1);
  let count = 1;
  for (const file of files) {
    if (file) {
      let renamed_file = new File(
        [file],
        "$img_" + count.toString() + "$" + file.name,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );
      form_data.append("images", renamed_file);
    }
    count++;
  }

  return await axios.post(`${base_url}/web/add-product`, form_data, {
    headers: headers(),
  });
};

const getisProductFather = async (id) => {
  return await axios.get(
    `${base_url}/web/cmi/padre-activo/${id}`,
    {},
    {
      headers: headers(),
    }
  );
};

const updateProduct = async (id, data, files) => {
  const form_data = new FormData();
  var isExistNoty = 0;
  for (var key in data) {
    console.log("Appending", data[key], " to ", key);
    form_data.append(key, data[key]);
    if (key == "notificaciones_inventario") {
      isExistNoty = 1;
    }
  }
  if (typeof data.stock_minimo !== "undefined" && !isExistNoty) {
    form_data.append("notificaciones_inventario", 1);
  }

  form_data.append("id_new_item", id);

  if (data.vencimiento_fecha) {
    form_data.append("vencimiento", 1);
  } else {
    form_data.append("vencimiento", 0);
  }

  form_data.append("inventariado", 1);
  let count = 1;
  let filesUploaded = false;
  const arr = [];
  for (const file of files) {
    if (file) {
      let renamed_file = new File(
        [file],
        "$img_" + count.toString() + "$" + file.name,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );
      form_data.append("images", renamed_file);
      arr.push(renamed_file);
      filesUploaded = true;
    }
    count++;
  }
  console.log(arr);
  if (!filesUploaded) {
    form_data.append("images", null);
    form_data.append("images", null);
    form_data.append("images", null);
    form_data.append("images", null);
    form_data.append("images", null);
  }
  return await axios.put(`${base_url}/web/update-product`, form_data, {
    headers: headers(),
  });
};

const createCombo = async (data, files) => {
  const form_data = new FormData();
  for (var key in data) {
    form_data.append(key, data[key]);
  }

  let count = 1;
  for (const file of files) {
    if (file) {
      let renamed_file = new File(
        [file],
        "$img_" + count.toString() + "$" + file.name,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );
      form_data.append("images", renamed_file);
    }
    count++;
  }

  return await axios.post(`${base_url}/web/add-combo`, form_data, {
    headers: headers(),
  });
};

const updateCombo = async (data, files) => {
  const form_data = new FormData();
  for (var key in data) {
    form_data.append(key, data[key]);
  }

  let count = 1;
  let filesUploaded = false;

  for (const file of files) {
    if (file) {
      let renamed_file = new File(
        [file],
        "$img_" + count.toString() + "$" + file.name,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );
      form_data.append("images", renamed_file);
      filesUploaded = true;
    }
    count++;
  }
  if (!filesUploaded) {
    form_data.append("images", null);
    form_data.append("images", null);
    form_data.append("images", null);
    form_data.append("images", null);
    form_data.append("images", null);
  }

  return await axios.put(`${base_url}/web/update-combo`, form_data, {
    headers: headers(),
  });
};

const recalculateCart = async (data) => {
  return await axios.post(
    `${base_url}/web/price-level/recalculate-cart`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const getCombos = async (data) => {
  return await axios.post(`${base_url}/web/get-combos`, qs.stringify(data), {
    headers: headers(),
  });
};

const getCombo = async (id) => {
  return await axios.get(`${base_url}/web/get-combos/${id}`, {
    headers: headers(),
  });
};

const uploadXLS = async (file, sucursalId, id_new_empresa) => {
  const form_data = new FormData();
  form_data.append("images", file);
  form_data.append("id_sucursal_bodega_ubicacion", sucursalId);
  if (id_new_empresa)
    form_data.append("id_new_empresa", parseInt(id_new_empresa));
  return await axios.post(`${base_url}/web/add-products`, form_data, {
    headers: headers(),
  });
};

const getProductAttributes = async () => {
  return await axios.get(`${base_url}/web/get-product-attributes`, {
    headers: headers(),
  });
};

const getById = async (data) => {
  return await axios.post(`${base_url}/web/get-product`, qs.stringify(data), {
    headers: headers(),
  });
};

export const getMerma = (data) =>
  axios.post(`${base_url}/web/get-product`, qs.stringify(data), {
    headers: headers(),
  });

const getFromBarcode = async (data) => {
  return await axios.post(
    `${base_url}/web/get-product-by-barcode`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const deleteById = async (data) => {
  return await axios.post(
    `${base_url}/web/delete-product`,
    qs.stringify(data),
    { headers: headers() }
  );
};

const updatePromo = async (data) => {
  return await axios.put(
    `${base_url}/web/update-promotion`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const createPromo = async (data) => {
  return await axios.post(
    `${base_url}/web/create-promotion`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const deletePromo = async (data) => {
  return await axios.post(
    `${base_url}/web/delete-promotion`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
};

const getProductsChild = async (id) => {
  return await axios.get(
    `${base_url}/web/cmi/todos-hijos/${id}`,

    { headers: headers() }
  );
};

const deleteProductsChild = async (id) => {
  return await axios.get(
    `${base_url}/web/cmi/desactivar-producto-hijo/${id}`,
    {},
    { headers: headers() }
  );
};

const createProductoshijos = async (data, files) => {
  const form_data = new FormData();
  for (var key in data) {
    form_data.append(key, data[key]);
  }

  let count = 1;
  for (const file of files) {
    if (file) {
      let renamed_file = new File(
        [file],
        "$img_" + count.toString() + "$" + file.name,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );
      form_data.append("images", renamed_file);
    }
    count++;
  }

  console.log(form_data);
  return await axios.post(`${base_url}/web/cmi/add-hijos`, form_data, {
    headers: headers(),
  });
};

export const createBaseProduct = (data) =>
  axios.post(`${base_url}/web/aleko/producto`, qs.stringify(data), {
    headers: alekoHeaders(),
  });

export const deleteAlekoProduct = (id) =>
  axios.delete(`${base_url}/web/aleko/producto/${id}`);

export const addAlekoProduct = (data) =>
  axios.post(`${base_url}/web/aleko/producto-guardar`, data, {
    headers: alekoHeaders(),
  });

// ********************************************************************

// export const createProductColor = (data) =>
//   axios.post(`${base_url}/web/aleko/producto/colores`, data, {
//     headers: alekoHeaders(),
//   });
export const createProductColor = (data) =>
  axios.post(
    `${base_url}/web/aleko/producto/personalizacionglobal?tipo=color`,
    data,
    {
      headers: alekoHeaders(),
    }
  );

export const getPerzonalizations = async (id_emrpesa) =>
  axios.get(
    `${base_url}/web/aleko/producto/getpersonalizaciongeneral?id_new_empresa=${id_emrpesa}`
  );

// export const deleteColor = (id) =>
//   axios.delete(`${base_url}/web/aleko/producto/colores/${id}`, {
//     headers: alekoHeaders(),
//   });

export const deleteColor = (idEmpresa, idPersonalizacion) =>
  axios.post(
    `${base_url}/web/aleko/producto/deletepersonalizaciongeneral?tipo=color&id_new_empresa=${idEmpresa}&id_personalizacion=${idPersonalizacion}`,
    {
      headers: alekoHeaders(),
    }
  );

// export const createProductSize = (data) =>
//   axios.post(`${base_url}/web/aleko/producto/talla`, data, {
//     headers: alekoHeaders(),
//   });

export const createProductSize = (data) =>
  axios.post(
    `${base_url}/web/aleko/producto/personalizacionglobal?tipo=talla`,
    data,
    {
      headers: alekoHeaders(),
    }
  );

// export const deleteSize = (id) =>
//   axios.delete(`${base_url}/web/aleko/producto/talla/${id}`, {
//     headers: alekoHeaders(),
//   });

export const deleteSize = (idEmpresa, idPersonalizacion) =>
  axios.post(
    `${base_url}/web/aleko/producto/deletepersonalizaciongeneral?tipo=talla&id_new_empresa=${idEmpresa}&id_personalizacion=${idPersonalizacion}`,
    {
      headers: alekoHeaders(),
    }
  );

// export const createProductPersonalization = (data) =>
//   axios.post(`${base_url}/web/aleko/producto/personalizacion`, data, {
//     headers: alekoHeaders(),
//   });

export const createProductPersonalization = (data) =>
  axios.post(
    `${base_url}/web/aleko/producto/personalizacionglobal?tipo=personalizacion`,
    data,
    {
      headers: alekoHeaders(),
    }
  );

// export const deletePersonalization = (id) =>
//   axios.delete(`${base_url}/web/aleko/producto/personalizacion/${id}`, {
//     headers: alekoHeaders(),
//   });

export const deletePersonalization = (idEmpresa, idPersonalizacion) =>
  axios.post(
    `${base_url}/web/aleko/producto/deletepersonalizaciongeneral?tipo=personalizacion&id_new_empresa=${idEmpresa}&id_personalizacion=${idPersonalizacion}`,
    {
      headers: alekoHeaders(),
    }
  );

// "http://localhost:8081/api/web/aleko/producto/deletepersonalizaciongeneral?tipo=personalizacion&id_new_empresa=1204&id_personalizacion=1"

// ***********************************************************************

export const getProductColor = (id) =>
  axios.get(`${base_url}/web/aleko/producto/colores/${id}`);

export const getProductSizes = (id) =>
  axios.get(`${base_url}/web/aleko/producto/tallas/${id}`);

export const getProductStyles = (id) =>
  axios.get(`${base_url}/web/aleko/producto/personalizaciones/${id}`);

export const crearPedidoAleko = (data) =>
  axios.post(
    `${base_url}/web/aleko/pedidos/inicio-pedido`,
    qs.stringify(data),
    { headers: alekoHeaders() }
  );

export const getAlekoProduct = (data) =>
  axios.post(`${base_url}/web/get-product`, qs.stringify(data), {
    headers: headers(),
  });

export const updateAlekoProduct = (data) =>
  axios.put(`${base_url}/web/update-product`, data, {
    headers: headers(),
  });

export const createPriceLevel = (data) =>
  axios.post(
    `${base_url}/web/price-level/createLevelPriceCMI`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );

export const getProductPriceLevels = (id_empresa, id_item) =>
  axios.get(
    `${base_url}/web/price-level/allPriceLevelCMI?id_new_empresa=${id_empresa}&id_new_item=${id_item}`,
    { headers: headers() }
  );

export const deletePriceLevel = (id) =>
  axios.delete(
    `${base_url}/web/price-level/deletePriceLevelCMI?id_level_price=${id}`,
    { headers: headers() }
  );

export const submitAlekoOrder = (data) =>
  axios.post(`${base_url}/web/aleko/pedidos/personalizar-pedido/firma`, data, {
    headers: alekoHeaders(),
  });

const editarHijo = (data) =>
  axios.post(`${base_url}/web/cmi/editar_hijo`, qs.stringify(data), {
    headers: headers(),
  });

const actualizacionMasiva = (data) =>
  axios.post(
    `${base_url}/web/cmi/actualizar-productos-gerente-mercadeo`,
    qs.stringify(data),
    { headers: headers() }
  );
const descargasInventario = (data) =>
  axios.post(`${base_url}/web/cmi/descarga-inventario-materiales`, data, {
    headers: headers(),
  });
const descargaMaterialesSap = (data) =>
  axios.post(
    `${base_url}/web/cmi/descarga-materiales-sap`,
    qs.stringify(data),
    {
      headers: headers(),
    }
  );
const descargaBitacoraSap = (data) =>
  axios.post(`${base_url}/web/cmi/obtener-bitacora-sap`, data, {
    headers: headers(),
  });
export default {
  getAll,
  getExpired,
  getLowStock,
  getMostSale,
  createNew,
  getProductAttributes,
  getNearExpiry,
  getById,
  deleteById,
  getLastSold,
  getProductsChild,
  updateProduct,
  uploadXLS,
  getFromBarcode,
  createCombo,
  getCombos,
  getCombo,
  updateCombo,
  recalculateCart,
  updatePromo,
  createPromo,
  deletePromo,
  createProductoshijos,
  deleteProductsChild,
  getisProductFather,
  editarHijo,
  actualizacionMasiva,
  descargasInventario,
  descargaMaterialesSap,
  descargaBitacoraSap,
};
