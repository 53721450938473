import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import API from "../../../api";
import "../custom.css";

import { getMoneda } from "../../../helpers/moneda";

export default function ModalFondo({ fondoCambios, caja }) {
  const moneda = getMoneda();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const updateFondo = async () => {
    try {
      const { data } = await API.cajas.updateFondosCambios({
        amount: fondoCambios,
        id_caja_corte_web_app: caja,
      });
      if (data.success) {
        handleClose();
        toast.success("El monto de fondo para cambios ha sido actualizado", {
          autoClose: 10000,
        });
      } else {
        toast.error("Ha ocurrido un error actualizando el fondo para cambios", {
          autoClose: 10000,
        });
      }
    } catch (error) {
      toast.error(error, { autoClose: 10000 });
    }
  };
  return (
    <div>
      <span
        onClick={handleOpen}
        style={{
          color: "#0160E7",
          cursor: "pointer",
        }}
      >
        cambiar
      </span>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="card" style={{ width: "500px", border: "none" }}>
            <div className="card-title d-flex justify-content-between align-items-center border-bottom py-3">
              <strong style={{ marginLeft: "17px" }}>
                <span style={{ fontSize: "20px" }}>Fondo para cambios</span>
              </strong>
              <IconButton onClick={handleClose} style={{ marginRight: "17px" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body">
              <h4>
                <strong>
                  ¿Desea guardar el monto de {moneda}.{fondoCambios} como fondo
                  para cambios?
                </strong>
              </h4>
            </div>
            <div className="border-top py-3 col-md-12 d-flex align-items-center justify-content-end">
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px", height: "42px", width: "150px" }}
                onClick={handleClose}
              >
                <span
                  style={{
                    color: "#0160E7",
                    fontSize: "14px",
                  }}
                >
                  Cancelar
                </span>
              </Button>
              &nbsp;
              <Button
                variant="contained"
                style={{
                  background: "#0160E7",
                  boxShadow: "none",
                  width: "150px",
                  height: "42px",
                }}
                onClick={updateFondo}
              >
                <span style={{ color: "#fff", fontSize: "14px" }}>Guardar</span>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
