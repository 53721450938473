import React from "react";
import API from "../api";
import { toast } from "react-toastify";

let UserStateContext = React.createContext();
let UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAdminAuthenticated: true };
    case "LOGIN_FAILURE":
      return { ...state, isAdminAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAdminAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AdminProvider({ children }) {
  let [state, dispatch] = React.useReducer(userReducer, {
    isAdminAuthenticated: !!localStorage.getItem("adminToken"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useAdminState() {
  let context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useAdminState must be used within a AdminProvider");
  }

  let forcedContext;

  if (localStorage.getItem("AdminToken")) {
    forcedContext = { isAdminAuthenticated: true };
  } else {
    return context;
  }

  return forcedContext;
}

function useUserDispatch() {
  let context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a AdminProvider");
  }
  return context;
}

export {
  AdminProvider,
  useAdminState,
  useUserDispatch,
  loginUser,
  signOut,
  autoLogin,
};

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  API.admin_user
    .login({
      correo: login,
      contrasena: password,
    })
    .then((res) => {
      let response = res.data;
      if (response.success) {
        localStorage.setItem("AdminToken", response.data.token.authorization);
        setError(null);
        setIsLoading(false);
        toast.success("¡Ha iniciado sesión correctamente!", {
          autoClose: 3000,
        });
        (function one() {
          if (!!localStorage.getItem("AdminToken")) {
            dispatch({ type: "LOGIN_SUCCESS" });
            if (!!localStorage.getItem("token"))
              // Allows only one session, either normal user or admin
              localStorage.removeItem("token");
            history.push("/admin/dashboard");
          } else {
            console.log("timeout");
            setTimeout(one, 30);
          }
        })();
      }
    })
    .catch((err) => {
      let response = err.response;
      if (err.response.status === 418) {
        toast(<LogoutOfAllDevices id={err.response.data.data} />);
        setError(true);
        setIsLoading(false);
        return;
      }
      setError(true);
      setIsLoading(false);
      if (response.data) {
        if (response.data.message) {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        }
      } else {
        toast.error("Ha ocurrido un error.", {
          autoClose: 10000,
        });
      }
      dispatch({ type: "LOGIN_FAILURE" });
    });
}

function autoLogin(dispatch, token, history) {
  localStorage.setItem("adminToken", token);
  dispatch({ type: "LOGIN_SUCCESS" });
  history.push("/admin/dashboard");
}

function signOut(dispatch, history) {
  const toDelete = ["AdminToken"];
  API.admin_user
    .logout()
    .then((res) => {
      let response = res.data;
      if (response.success) {
        toDelete.map((value) => {
          localStorage.removeItem(value);
        });
        dispatch({ type: "SIGN_OUT_SUCCESS" });
        history.push("/admin/login");
      } else {
        toast.error(response.message, {
          autoClose: 10000,
        });
      }
    })
    .catch((err) => {
      console.log(err, err.response);

      let response = err.response;
      if (typeof response.data !== "undefined") {
        if (response.data.message) {
          toast.error(response.data.message, {
            autoClose: 10000,
          });
        }
      } else {
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      }
    });
}

function LogoutOfAllDevices({ id }) {
  const handleSubmit = () => {
    API.admin_user
      .logoutOfAllDevices({
        id_superadmin: id,
      })
      .then((res) => {
        console.log(res);
        toast("Se ha cerrado sesión de todos los dispositivos");
      })
      .catch((err) => {
        console.log(err, err.response);
      });
  };
  return (
    <div>
      Ya hay una sesión activa, si deseas cerrar sesión en los demás
      dispositivos,{" "}
      <button className="btn btn-primary" onClick={() => handleSubmit()}>
        Clic aquí
      </button>
    </div>
  );
}
