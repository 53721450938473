import { Visibility } from "@material-ui/icons";
import React from "react";

// estilos
import "../custom.css";

const CardEntrega = (props) => {
  const { nombre, metodoEntrega, cheked, handleChangeMetodoEntrega } = props;

  return (
    <div
      className={`card_entrega ${
        metodoEntrega.toLowerCase() == nombre.toLowerCase() ? "card_active" : ""
      }`}
    >
      <input
        className="radio_card_entrega"
        id={nombre.toLowerCase()}
        name="metodo_entrega"
        type="radio"
        value={nombre.toLowerCase()}
        onClick={handleChangeMetodoEntrega}
        checked={
          metodoEntrega.toLowerCase() == nombre.toLowerCase() ? true : false
        }
        disabled={props.clientSelected}
      />
      <label
        className="label_card_entrega"
        disabled={nombre.toLowerCase() == "entrega" ? true : false}
        for={nombre.toLowerCase()}
      >
        {/* {nombre.charAt(0).toUppercase() + nombre.slice(0, 1)} */}
        {nombre}
      </label>
    </div>
  );
};

export default CardEntrega;
