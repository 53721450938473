import { base_url, axios } from "../api";

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getAllDataPlace = async (id) => {
  return await axios.get(`${base_url}/web/cmi/getPlaces/` + id, {
    headers: headers(),
  });
};

export default {
  getAllDataPlace,
};
