import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import BestOffer from "./RectangleStar.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./custom.css";

function PlanCard(props) {
  const renderBanner = () => {
    if (props.hasBanner) {
      return <img src={BestOffer} alt="Best Offer Banner" />;
    } else {
      return "";
    }
  };
  return (
    <div className="card content-card plan-card">
      <div className="card-header">
        <div className="row w-100 p-0 m-0 plan-card-header-container">
          <div className="plan-card-header text-white text-center">
            <p className="my-auto">{props.planName}</p>
            {renderBanner()}
          </div>
        </div>
        <div className="row w-100 m-0 justify-content-center align-items-center plan-price-header">
          <p className="plan-price">
            Q. <span>{props.planPrice}</span> / {props.planPaymentTime}
          </p>
        </div>
      </div>
      <div className="card-body plan-card-body text-left mx-auto">
        <ul>
          {props.planCharacteristics.map((characteristic) => (
            <li className="d-flex justify-content-start" key={characteristic}>
              <CheckCircleIcon style={{ color: "#08BFE6" }} />
              <p className="ml-1">{characteristic}</p>
            </li>
          ))}
        </ul>
        <Button
          variant="contained"
          component={Link}
          to={"/register/" + props.planName}
          size="large"
          style={{
            color: "white",
            textTransform: "none",
            paddingRight: "30px",
            paddingLeft: "30px",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
          }}
          className="d-block mx-auto gradient_bg_vanilla"
        >
          Suscribirse
        </Button>
      </div>
    </div>
  );
}
export default withRouter(PlanCard);
