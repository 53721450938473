export const saveActualOrder = (item) => {
  window.localStorage.setItem("actualorder", JSON.stringify(item));
};

export const getActualOrder = () => {
  return JSON.parse(window.localStorage.getItem("actualorder"));
};

export const destryActualOrder = () => {
  window.localStorage.removeItem("actualorder");
};

export const getIdEmpresa = () =>
  JSON.parse(window.localStorage.getItem("id_empresa"));
