// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import API from "../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import Autosuggest from "react-autosuggest";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

// components

//data

var currentDateTime = moment().format("LL");

export default function Users_all(props) {
  const AutosuggestHighlightMatch = require("autosuggest-highlight/match");
  const AutosuggestHighlightParse = require("autosuggest-highlight/parse");
  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
      $("#save").modal("hide");
    };
  }, []);

  //DEBO PASAR PARAMETROS DE ID_UBICACION_BODEGA AL FORMULARIO DE TRASLADO Y REVISAR TODOS LOS SELECT QUE FUNCIONEN

  useEffect(() => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          setSucursals(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
    // getProducts();
  }, []);

  // states
  const [product_inputs, setProduct_inputs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sucursals, setSucursals] = useState([]);
  const [sucursal_origen, setSucursalOrigen] = useState("");
  const [sucursal_destino, setSucursalDestino] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [idTraslado, setIdTraslado] = useState(null);
  // set states

  //function
  const add_inputs = () => {
    let existingData = [...product_inputs];
    setProduct_inputs(
      existingData.concat([
        {
          codigo: "",
          cantidad: "",
        },
      ])
    );
  };
  const remove_input = (i) => {
    console.log(i);
    setProduct_inputs(product_inputs.filter((e, index) => index !== i));
  };

  const submitForm = () => {
    let validInputs = true;

    if (sucursal_origen === "" || sucursal_destino === "") {
      validInputs = false;
      toast.error("Debe completar todos los campos.", {
        autoClose: 10000,
      });
    }

    if (product_inputs.length === 0) {
      validInputs = false;
      toast.error(
        "Debe introducir al menos un producto para hacer un traslado.",
        {
          autoClose: 10000,
        }
      );
    }

    if (validInputs) {
      setLoader(true);
      let codeMismatch = false;
      let productsData = product_inputs;

      if (allProducts.length === 0) {
        toast.error("No puede crear un traslado porque no tiene productos.", {
          autoClose: 10000,
        });
        setLoader(false);
      } else {
        let product_details = allProducts;
        let product_inputs_codes = [];
        product_inputs.map((product) => {
          product_inputs_codes.push(product.codigo);
        });
        console.log("All products:", product_details);
        console.log("Product inputs:", product_inputs_codes);
        let all_product_codes = [];

        for (let product of product_details) {
          all_product_codes.push(product.codigo);
        }
        for (let product of product_inputs_codes) {
          if (!all_product_codes.includes(product)) {
            codeMismatch = true;
            console.log(product, " is not included in ", all_product_codes);
          }
        }

        submitTraslado(productsData);
        setLoader(false);
      }
    }
  };

  const submitTraslado = (productsData) => {
    let invalidForm = false;
    let data = {
      id_new_ubicacion_origen: parseInt(sucursal_origen),
      id_new_ubicacion_destino: parseInt(sucursal_destino),
      products: JSON.stringify(productsData),
    };
    if (data.id_new_ubicacion_origen === data.id_new_ubicacion_destino) {
      invalidForm = true;
      toast.error("No se puede trasladar a la misma ubicación.", {
        autoClose: 10000,
      });
    }
    let equal_code = false;
    product_inputs.map((x) => {
      let checkCode = product_inputs.filter((y) => y.codigo === x.codigo);
      if (checkCode.length > 1) {
        equal_code = true;
      }
      return x;
    });
    if (equal_code) {
      return toast.error("No puedes asignar dos productos con el mismo código");
    }
    if (!invalidForm) {
      API.traslados
        .createNew(data)
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setIdTraslado(response.data.id_traslado_web_app);
            $("#save").modal("show");
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
          setLoader(false);
        })
        .catch((err) => {
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
          setLoader(false);
        });
    }
  };
  const handleInputChange = (name, index, event) => {
    let value = event.target.value;
    if (name === "codigo") {
      let existingData = [...product_inputs];
      existingData[index].codigo = value;
      setProduct_inputs(existingData);
    } else {
      event.target.value =
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
            value.substr(value.indexOf("."), 3)
          : value;
      let existingData = [...product_inputs];
      existingData[index].cantidad = value;
      setProduct_inputs(existingData);
    }
  };

  const handleSelectChange = (event) => {
    let target = event.target;
    if (target.name === "sucursal_origen") setSucursalOrigen(target.value);
    if (target.name === "sucursal_destino") setSucursalDestino(target.value);
  };
  const [isLoaded, setIsLoaded] = useState(false);
  let currentSucursal = JSON.parse(
    localStorage.getItem("store")
  )?.id_sucursal_bodega_ubicacion;
  const getProducts = (value = "") => {
    if (isLoaded) {
      return;
    }
    setIsLoaded(true);
    API.products
      .getAll({
        nombre: value || filterText,
        id_sucursal_bodega_ubicacion: currentSucursal,
        no_combos: 1,
        pagination: true,
        page: 1,
        limit: 10,
      })
      .then((res) => {
        let response = res.data;
        if (response.success && JSON.stringify(response.data) !== "[]") {
          setAllProducts(response.data);
        }
        setIsLoaded(false);
      })
      .catch((err) => {
        console.log("Ha ocurrido un error / Búsqueda de productos");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
        setIsLoaded(false);
      });
  };

  const getSuggestions = (value = "") => {
    if (!Array.isArray(allProducts)) {
      getProducts(value);
      return [];
    }
    const inputValue = document
      .getElementById("product_query")
      .value.trim()
      .toLowerCase();
    const inputLength = inputValue.length;
    var dataProduct =
      inputLength === 0
        ? []
        : allProducts
            .filter(
              (product) =>
                product.nombre.toLowerCase().includes(inputValue) ||
                product.codigo.toLowerCase().includes(inputValue)
            )
            .filter((product) => !product.combo)
            .filter((product) => !product.id_padre)
            .slice(0, 10);

    if (dataProduct.length == 0) {
      getProducts(value);
    }
    return dataProduct;
  };

  const getSuggestionValue = (suggestion) => {
    console.log(suggestion);
    let existingData = [...product_inputs];
    if (product_inputs.some((x) => suggestion.codigo === x.codigo)) {
      return suggestion.nombre;
    }
    setProduct_inputs(
      existingData.concat([
        {
          codigo: suggestion.codigo,
          cantidad: 1,
          nombre: suggestion.nombre,
          id_new_item: suggestion.id_new_item,
          precio: suggestion.costo_unitario,
          cantidad_peso: suggestion.cantidad_peso,
        },
      ])
    );
    return suggestion.nombre;
  };

  const renderSuggestion = (suggestion) => {
    const matches = AutosuggestHighlightMatch(suggestion.nombre, filterText);
    const parts = AutosuggestHighlightParse(suggestion.nombre, matches);

    return (
      <div>
        {parts.map((part, index) => {
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  const changeFilterText = (e, { newValue }) => {
    setFilterText(newValue);
  };

  const onSuggestionsFetchRequested = (e = {}) => {
    setSuggestions(getSuggestions(e.value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Escribe el nombre del producto",
    value: filterText,
    onChange: changeFilterText,
    id: "product_query",
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  to={"/app/traslados"}
                  component={Link}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="save" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="text-center">
                <CheckCircleIcon className="checkIcon" />

                <h4>
                  <strong>
                    Su traslado se ha <br />
                    registrado con éxito
                  </strong>
                </h4>
              </div>

              <div class="d-flex justify-content-center">
                <Button
                  className="btn-cs1"
                  variant="contained"
                  color="primary"
                  to={
                    idTraslado
                      ? `/app/traslados/details/${idTraslado}`
                      : "/app/traslados"
                  }
                  component={Link}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Traslados</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Crear Traslado</strong>
        </h4>
      </div>

      {/* write content here "whithout {}"*/}
      <form>
        <div className="content-card card mt-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h5 className="mb-0">
                  <strong>Creación de Traslado</strong>
                </h5>
                <p className="mb-0">Registre una orden de traslado.</p>
              </div>

              <div>
                <Button
                  className="btn-cs1_blue mx-1 mt-1"
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  {loader ? (
                    <CircularProgress
                      size={26}
                      className="mx-auto"
                      style={{ color: "white" }}
                    />
                  ) : (
                    "Guardar"
                  )}
                </Button>
                <Button
                  className="btn-cs1 mx-1 mt-1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmExit"
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-6"></div>
              <div className="col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <p className="my-auto">Fecha actual: {currentDateTime}</p>
                </div>
                <Tooltip title="La fecha al momento de guardar será la fecha del traslado.">
                  <InfoIcon
                    style={{
                      color: " #828282",
                      fontSize: "1.2rem",
                    }}
                  />
                </Tooltip>
              </div>
              <div className="col-md-6 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Origen</strong>
                  </label>
                  <Tooltip title="Sucursal de origen de los productos.">
                    <InfoIcon
                      style={{
                        color: " #828282",
                        fontSize: "1.2rem",
                      }}
                    />
                  </Tooltip>
                </div>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    native
                    value={sucursal_origen}
                    onChange={(e) => handleSelectChange(e)}
                    className="cs-select1 select-w100"
                    name="sucursal_origen"
                  >
                    <option value="">Seleccione el origen del traslado</option>
                    {sucursals.map((sucursal) => {
                      if (
                        sucursal.id_sucursal_bodega_ubicacion ==
                        localStorage.getItem("id_sucursal")
                      ) {
                        return (
                          <option value={sucursal.id_sucursal_bodega_ubicacion}>
                            {sucursal.nombre_sucursal}
                          </option>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Destino</strong>
                  </label>
                  <Tooltip title="Sucursal de destino de los productos.">
                    <InfoIcon
                      style={{
                        color: " #828282",
                        fontSize: "1.2rem",
                      }}
                    />
                  </Tooltip>
                </div>

                <FormControl variant="outlined" className="w-100">
                  <Select
                    native
                    value={sucursal_destino}
                    onChange={(e) => handleSelectChange(e)}
                    className="cs-select1 select-w100"
                    name="sucursal_destino"
                  >
                    <option value="">Seleccione el destino del traslado</option>
                    {sucursals.map((sucursal) => {
                      if (
                        sucursal.id_sucursal_bodega_ubicacion !=
                        localStorage.getItem("id_sucursal")
                      ) {
                        return (
                          <option value={sucursal.id_sucursal_bodega_ubicacion}>
                            {sucursal.nombre_sucursal}
                          </option>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </div>

              {/* Second Row */}

              <div className="col-md-4 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Agregar productos</strong>
                  </label>
                </div>
                <p className="mb-2">
                  Agregue los productos, e ingrese las cantidades que se
                  trasladarán.
                </p>
                <div className="row">
                  <div className="col-xl-2 p-0 my-auto text-center">
                    <IconButton
                      className="mx-1 mt-1 icon-btn-primary"
                      onClick={() => add_inputs()}
                    >
                      <AddIcon style={{ color: "white" }} />
                    </IconButton>
                  </div>
                  <div className="col-xl-10 p-0 my-auto py-2">
                    <div
                      class="input-group search-bar autosuggest-search-bar"
                      style={{ width: "100%" }}
                    >
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                      />
                      <div class="input-group-append">
                        <span class="input-group-text autosuggest-search-icon">
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8 mt-3">
                {product_inputs.map((input, index) => (
                  <div className="row">
                    <div className="col-md-1 mt-3 d-flex justify-content-center align-items-center">
                      <IconButton
                        className="btn-cs1 icon-btn-delete"
                        variant="contained"
                        color="primary"
                        onClick={() => remove_input(index)}
                      >
                        <DeleteIcon style={{ color: "white" }} />
                      </IconButton>
                    </div>

                    <div className="col-md-6 mt-3">
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Código del producto</strong>
                      </label>

                      <TextField
                        type="text"
                        value={input.codigo}
                        onChange={(event) =>
                          handleInputChange("codigo", index, event)
                        }
                        className="w-100"
                        placeholder="Ingrese SKU / código de barras del producto"
                      />
                    </div>

                    <div className="col-md-5 mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Cantidad a trasladar</strong>
                        </label>
                        <Tooltip title="Indique la cantidad de productos a trasladar.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>
                      <TextField
                        type="number"
                        className="w-100"
                        value={input.cantidad}
                        onChange={(event) =>
                          handleInputChange("cantidad", index, event)
                        }
                        placeholder="Indique la cantidad de productos a trasladar"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">N°</InputAdornment>
                          ),
                          inputProps: { min: 0, step: "0.00001" },
                        }}
                      />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-5 mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          <strong>Nombre del producto</strong>
                        </label>
                        <Tooltip title="Indique la cantidad de productos a trasladar.">
                          <InfoIcon
                            style={{
                              color: " #828282",
                              fontSize: "1.2rem",
                            }}
                          />
                        </Tooltip>
                      </div>
                      <TextField
                        type="text"
                        className="w-100"
                        value={input.nombre}
                        placeholder="Nombre del producto"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
