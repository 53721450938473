import { Button, InputAdornment, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import ProductDetailsContext from "../../../../../context/DetalleProductosAleko/ProductDetailsContext";
import ProductPersonalization from "./ProductPersonalization";

const ProductPersonalizations = () => {
  const {
    personalizations,
    productPersonalization,
    inputPersonalizationValidation,
    setProductPersonalization,
    clearProductPersonalization,
    saveProductPersonalization,
  } = useContext(ProductDetailsContext);
  const handleProductPersonalizationChange = (e) => {
    setProductPersonalization({
      ...productPersonalization,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="row">
      <label className="col-lg-2">
        <strong>Personalización</strong>
      </label>
      <div className="col-lg-10">
        <hr style={{ borderTop: "1px solid #a4b0be" }} />
      </div>
      {personalizations?.map((item) => (
        <ProductPersonalization item={item} />
      ))}

      <div className="col-lg-4">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Nombre</strong>
        </label>
        <TextField
          type="text"
          className="w-100"
          value={productPersonalization.nombre_personalizacion}
          name="nombre_personalizacion"
          onChange={handleProductPersonalizationChange}
          placeholder="Tipo"
          InputProps={{
            inputProps: { min: 0, step: "0.00001" },
          }}
        />
        {productPersonalization.nombre_personalizacion.trim() === "" &&
          inputPersonalizationValidation && (
            <span className="text-danger">Campo requerido</span>
          )}
      </div>
      <div className="col-lg-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Cantidad disponible</strong>
        </label>
        <TextField
          type="number"
          className="w-100"
          name="cantidad_personalizacion"
          onChange={handleProductPersonalizationChange}
          value={productPersonalization.cantidad_personalizacion || ""}
          placeholder="Cantidad"
          InputProps={{
            inputProps: { min: 0, step: "0.00001" },
          }}
        />
        {!productPersonalization.cantidad_personalizacion &&
          inputPersonalizationValidation && (
            <span className="text-danger">Campo requerido</span>
          )}
      </div>
      <div className="col-lg-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Precio extra</strong>
        </label>
        <TextField
          type="number"
          className="w-100"
          name="precio_extra"
          value={productPersonalization.precio_extra || ""}
          onChange={handleProductPersonalizationChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Q.</InputAdornment>
            ),
            inputProps: { min: 0, step: "0.00001" },
          }}
        />
      </div>
      <div className="col-lg-2 d-flex align-items-center">
        <Button
          className="btn-cs1 delete-btn w-100 text-light"
          variant="contained"
          onClick={() => clearProductPersonalization()}
        >
          Eliminar
        </Button>
      </div>

      <div className="col-lg-2 d-flex align-items-center">
        <Button
          variant="contained"
          className="mt-2 w-100"
          style={{
            background: "#35baf6",
            color: "white",
            fontSize: 18,
          }}
          onClick={() => saveProductPersonalization(productPersonalization)}
        >
          Añadir
        </Button>
      </div>
    </div>
  );
};

export default ProductPersonalizations;
