import { base_url, axios } from "../api";
var qs = require("qs");
const moment = require("moment");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const getAll = async () => {
  return await axios.get(`${base_url}/web/get-document-type`, {
    headers: headers(),
  });
};

export default {
  getAll,
};
