export const GET_ALEKO_COLORS = "GET_ALEKO_COLORS";
export const GET_ALEKO_SIZES = "GET_ALEKO_SIZES";
export const GET_ALEKO_PRODUCT_PERSONALIZATIONS =
  "GET_ALEKO_PRODUCT_PERSONALIZATIONS";
export const SET_PRODUCT_COLOR = "SET_PRODUCT_COLOR";
export const DELETE_PRODUCT_COLOR = "DELETE_PRODUCT_COLOR";
export const GET_PRODUCT = "GET_PRODUCT";
export const SET_PRODUCT_SIZE = "SET_PRODUCT_SIZE";
export const DELETE_PRODUCT_SIZE = "DELETE_PRODUCT_SIZE";
export const SET_PRODUCT_PERSONALIZATION = "SET_PRODUCT_PERSONALIZATION";
export const DELETE_PRODUCT_PERSONALIZATION = "DELETE_PRODUCT_PERSONALIZATION";
export const SET_FORM = "SET_FORM";
export const CLEAR_STATE = "CLEAR_STATE";
export const HANDLE_FILE = "HANDLE_FILE";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_IMAGE = "DELETE_IMAGE";
