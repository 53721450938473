import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DataTable from "react-data-table-component";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import MuiDialogActions from "@material-ui/core/DialogActions";
import { useHistory } from "react-router-dom";
import API from "../../../api";
import { toast } from "react-toastify";

import TabPanel from "../../../components/TabPanel";

// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SearchIcon from "@material-ui/icons/Search";
import { CircularProgress } from "@material-ui/core";
import { SignalCellularNull } from "@material-ui/icons";

// components

//data
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
export default function Users_all(props) {
  const moment = require("moment");

  // states
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [expiredFilterText, setExpiredFilterText] = useState("");
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [expiredClients, setExpiredClients] = useState([]);
  const [filteredExpiredClients, setFilteredExpiredClients] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isOnlineSelected, setIsonlineSelected] = useState(null);
  const [isActiveMembershipSelected, setIsActiveMembershipSelected] =
    useState(null);
  const [plans, setPlans] = useState([]);
  const [dataMembership, setDatamembership] = useState([]);
  const [nameCompany, setNamecompany] = useState("");
  const [classes, setClasses] = useState([]);
  const [allDatamembership, setallDatamembership] = useState([]);
  const [numberMembershipActive, setnumberMembershipActive] = useState(0);
  const [numberMembershipNoActive, setnumberMembershipNoActive] = useState(0);
  const [numberMembershipOnline, setnumberMembershipOnline] = useState(0);
  const [numberMembershipOffline, setnumberMembershipOffline] = useState(0);
  const [numberMembershipAllUsers, setnumberMembershipAllUsers] = useState(0);
  const [allUsersEachCompany, setallUsersEachCompany] = useState([]);

  useEffect(() => {
    getClients();
    getPlans();
    getStatemembership();
  }, []);

  // set states

  const handleSelectedTabs = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const filterClients = (e) => {
    setFilterText(e.target.value);
    setFilteredClients(
      clients.filter((item) =>
        item.nombre.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredClients(clients);
    }
  };

  const filterExpiredClients = (e) => {
    setExpiredFilterText(e.target.value);
    setFilteredExpiredClients(
      expiredClients.filter((item) =>
        item.nombre.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    if (e.target.value === "") {
      setFilteredClients(clients);
    }
  };

  const filterByStateMembresia = (event) => {
    if (event.target.value === "0") {
      setIsonlineSelected(null);
      setIsActiveMembershipSelected(null);
      setDatamembership(allDatamembership);
    } else if (event.target.value == "1") {
      let filterdata = allDatamembership.filter(
        (data) => data.estadoMembresia === true
      );
      setIsonlineSelected(null);
      setIsActiveMembershipSelected(true);
      setDatamembership(filterdata);
    } else if (event.target.value == "2") {
      setIsonlineSelected(null);
      setIsActiveMembershipSelected(true);
      let filterdata = allDatamembership.filter(
        (data) => data.estadoMembresia === false
      );
      setDatamembership(filterdata);
    } else if (event.target.value == "3") {
      let filterdata = allDatamembership.filter(
        (data) => data.usuariosEnLinea.length > 0
      );
      filterdata = filterdata.filter((data) => data.estadoMembresia);
      setIsonlineSelected(true);
      setIsActiveMembershipSelected(null);

      setDatamembership(filterdata);
    } else if (event.target.value == "4") {
      setIsonlineSelected(false);
      setIsActiveMembershipSelected(null);
      let filterdata = allDatamembership.filter(
        (data) => data.usuariosFueraLinea.length > 0
      );
      filterdata = filterdata.filter((data) => data.estadoMembresia);
      setDatamembership(filterdata);
    }
  };

  const filterByMembresia = (event) => {
    let data = selectedTab === 0 ? clients : expiredClients;
    let newData;
    if (event.target.value !== "") {
      newData = data.filter(function (client) {
        if (
          client.plan_info &&
          client.plan_info.id_planes == event.target.value
        ) {
          return client;
        }
      });
    }
    if (selectedTab === 0) {
      setFilteredClients(event.target.value !== "" ? newData : clients);
    }
    if (selectedTab === 1) {
      setFilteredExpiredClients(
        event.target.value !== "" ? newData : expiredClients
      );
    }
    setFilterText("");
    setExpiredFilterText("");
  };

  const getStatemembership = () => {
    API.admin_memberships
      .stateMembership()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Clients obtained", response.data);
          let filterMembershipActive = response.data.filter(
            (data) => data.estadoMembresia === true
          );
          let filterMembershipNoActive = response.data.filter(
            (data) => data.estadoMembresia === false
          );
          let filterMembershipOnline = response.data.filter(
            (data) => data.usuariosEnLinea.length > 0
          );
          let filterMembershipOffline = response.data.filter(
            (data) => data.usuariosFueraLinea.length > 0
          );
          let numberonline = 0;
          let numberoffline = 0;
          filterMembershipOnline.forEach((data) => {
            numberonline += data.usuariosEnLinea.length;
          });
          filterMembershipOffline.forEach((data) => {
            numberoffline += data.usuariosFueraLinea.length;
          });
          setnumberMembershipActive(filterMembershipActive.length);
          setnumberMembershipNoActive(filterMembershipNoActive.length);
          setnumberMembershipOnline(numberonline);
          setnumberMembershipOffline(numberoffline);
          setnumberMembershipAllUsers(numberonline + numberoffline);
          setDatamembership(response.data);
          setallDatamembership(response.data);
        } else {
          toast.error("Ha ocurrido un error", {
            autoClose: 10000,
          });
          console.log(response);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("Error", err, err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getClients = () => {
    API.admin_clients
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Clients obtained", response.data);
          setClients(response.data);
          setFilteredClients(response.data);
          if (response.data.length > 0) {
            let expired_clients = response.data.filter(
              (client) => client.estado_membresia === "Vencida"
            );
            setExpiredClients(expired_clients);
            setFilteredExpiredClients(expired_clients);
          } else {
            setExpiredClients([]);
            setFilteredExpiredClients([]);
          }
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            console.log("response", response);
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("Error", err, err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const getPlans = () => {
    API.subscriptions
      .getAll()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          console.log("Plans obtained", response.data);
          setPlans(response.data);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            console.log("plans response", response);
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log("Error", err, err.response);
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  const handleClickOpen = (row) => {
    console.log(row);
    let result = [];
    let UsersOnline = row.usuariosEnLinea;
    let UsersOffline = row.usuariosFueraLinea;
    setNamecompany(row.nombreEmpresa);

    UsersOnline.forEach((element) => {
      result.push({
        nombre: element.nombre,
        serie: element.serie,
        estado: "EN LÍNEA",
        correo: element.correo,
      });
    });

    UsersOffline.forEach((element) => {
      result.push({
        nombre: element.nombre,
        serie: element.serie,
        estado: "FUERA DE LÍNEA",
        correo: element.correo,
      });
    });

    setallUsersEachCompany(result);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div id="dashboard-section">
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Detalles de la Empresa {nameCompany}
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <DataTable
              className="datatable-cs datatable-cs-v2 mt-2"
              title={"s"}
              columns={[
                {
                  name: "Nombre del usuario",
                  selector: "nombre",
                  sortable: true,
                },

                {
                  name: "Estado",
                  selector: "estado",
                },
                {
                  name: "Email",
                  selector: "correo",
                },
                {
                  name: "Serie",
                  selector: "serie",
                },
              ]}
              data={allUsersEachCompany}
              pagination
              paginationPerPage={10}
              paginationIconFirstPage={null}
              paginationIconLastPage={null}
              paginationComponentOptions={{
                rangeSeparatorText: "de",
                noRowsPerPage: true,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
              noDataComponent="No se ha encontrado ningún resultado"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <div className="section-title mb-4" />

      <div className="content-card card mt-3">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <h5 className="mb-0">
              <strong>Panel de control</strong>
            </h5>

            <Button
              className="btn-cs1"
              variant="contained"
              color="primary"
              component={Link}
              to={"/admin/clients/new"}
            >
              <span style={{ marginBottom: "-4px" }}>Añadir cliente</span>
            </Button>
          </div>

          <Tabs
            className=""
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleSelectedTabs}
          >
            <Tab className="tab" label="Todas las Membresias" />
            <Tab className="tab" label="Vencidas" />
            <Tab className="tab" label="Datos de Membresías" />
          </Tabs>
        </div>
        {loader ? (
          <div className="my-auto text-center">
            <CircularProgress size={25} className="mx-auto" />
          </div>
        ) : (
          <div className="card-body">
            <TabPanel value={selectedTab} index={0}>
              <div class="row mt-3">
                <div className="col-md-4" style={{ alignSelf: "center" }}>
                  <FormControl variant="outlined">
                    <Select
                      native
                      onChange={(event) => filterByMembresia(event)}
                      className="cs-select membresia-select"
                    >
                      <option value="">Todas las membresías</option>
                      {plans.map((plan) => (
                        <option value={plan.id_planes}>
                          {plan.descripcion}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-4" />
                <div className="col-md-4">
                  <div class="input-group search-bar">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Buscar cliente por nombre"
                      value={filterText}
                      onChange={(event) => filterClients(event)}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <SearchIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <DataTable
                className="datatable-cs datatable-cs-v2 mt-2"
                title={"s"}
                columns={[
                  {
                    name: "Nombre del cliente",
                    selector: "nombre",
                    sortable: true,
                  },
                  { name: "NIT", selector: "nit", sortable: true },
                  {
                    name: "Dirección Principal",
                    selector: "direccion",
                    sortable: true,
                  },
                  {
                    name: "Sucursales",
                    selector: "numero_sucursales",
                    cell: (row) => (
                      <div className="w-100 text-center">
                        {row.numero_sucursales}
                      </div>
                    ),
                    sortable: true,
                  },
                  {
                    name: "N° Usuarios",
                    selector: "numero_usuarios",
                    cell: (row) => (
                      <div className="w-100 text-center">
                        {row.numero_usuarios}
                      </div>
                    ),
                    sortable: true,
                  },
                  {
                    name: "Membresía",
                    selector: "plan_info",
                    sortable: true,
                    cell: (row) => (
                      <div className="w-100 text-center">
                        {row.plan_info
                          ? row.plan_info.descripcion
                          : "Desconocido"}
                      </div>
                    ),
                  },
                  {
                    name: "Vencimiento",
                    selector: "estado_membresia",
                    sortable: true,
                    cell: (row) => (
                      <div className="w-100 text-center">
                        {row.estado_membresia === "Por vencer" ? (
                          <span className="p-2 warning-span">
                            {moment(row.fecha_vencimiento).format("DD/MM/YYYY")}
                          </span>
                        ) : (
                          ""
                        )}
                        {row.estado_membresia === "Vencida" ? (
                          <span className="p-2 danger-span">
                            {moment(row.fecha_vencimiento).format("DD/MM/YYYY")}
                          </span>
                        ) : (
                          ""
                        )}
                        {row.estado_membresia === "Vigente" ? (
                          <span className="p-2 success-span">
                            {moment(row.fecha_vencimiento).format("DD/MM/YYYY")}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "",
                    selector: "action",
                    cell: (row) => (
                      <div className="text-center w-100">
                        <Button
                          className="brand2-btn"
                          variant="contained"
                          color="primary"
                          endIcon={<ArrowForwardIosIcon />}
                          component={Link}
                          to={`/admin/clients/profile/${row.id_new_empresa}`}
                          size="small"
                        >
                          <span
                            style={{ marginBottom: "-4px", fontSize: "12px" }}
                          >
                            Ver detalles
                          </span>
                        </Button>
                      </div>
                    ),
                  },
                ]}
                data={filteredClients}
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se ha encontrado ningún resultado"
              />
            </TabPanel>

            <TabPanel value={selectedTab} index={1}>
              <div class="row mt-3">
                <div className="col-md-4" style={{ alignSelf: "center" }}>
                  <FormControl variant="outlined">
                    <Select
                      native
                      onChange={(event) => filterByMembresia(event)}
                      className="cs-select membresia-select"
                    >
                      <option value="">Todas las membresías</option>
                      {plans.map((plan) => (
                        <option value={plan.id_planes}>
                          {plan.descripcion}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-4" />
                <div className="col-md-4">
                  <div class="input-group search-bar">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Buscar cliente por nombre"
                      value={expiredFilterText}
                      onChange={(event) => filterExpiredClients(event)}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <SearchIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <DataTable
                className="datatable-cs datatable-cs-v2 mt-2"
                title={"s"}
                columns={[
                  {
                    name: "Nombre del cliente",
                    selector: "nombre",
                    sortable: true,
                  },
                  { name: "NIT", selector: "nit", sortable: true },
                  {
                    name: "Dirección Principal",
                    selector: "direccion",
                    sortable: true,
                  },
                  {
                    name: "Sucursales",
                    selector: "numero_sucursales",
                    cell: (row) => (
                      <div className="w-100 text-center">
                        {row.numero_sucursales}
                      </div>
                    ),
                    sortable: true,
                  },
                  {
                    name: "N° Usuarios",
                    selector: "numero_usuarios",
                    cell: (row) => (
                      <div className="w-100 text-center">
                        {row.numero_usuarios}
                      </div>
                    ),
                    sortable: true,
                  },
                  {
                    name: "Membresía",
                    selector: "plan_info",
                    sortable: true,
                    cell: (row) => (
                      <div className="w-100 text-center">
                        {row.plan_info
                          ? row.plan_info.descripcion
                          : "Desconocido"}
                      </div>
                    ),
                  },
                  {
                    name: "Vencimiento",
                    selector: "estado_membresia",
                    sortable: true,
                    cell: (row) => (
                      <div className="w-100 text-center">
                        {row.estado_membresia === "Por vencer" ? (
                          <span className="p-2 warning-span">
                            {moment(row.fecha_vencimiento).format("DD/MM/YYYY")}
                          </span>
                        ) : (
                          ""
                        )}
                        {row.estado_membresia === "Vencida" ? (
                          <span className="p-2 danger-span">
                            {moment(row.fecha_vencimiento).format("DD/MM/YYYY")}
                          </span>
                        ) : (
                          ""
                        )}
                        {row.estado_membresia === "Vigente" ? (
                          <span className="p-2 success-span">
                            {moment(row.fecha_vencimiento).format("DD/MM/YYYY")}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "",
                    selector: "action",
                    cell: (row) => (
                      <div className="text-center w-100">
                        <Button
                          className="brand2-btn"
                          variant="contained"
                          color="primary"
                          endIcon={<ArrowForwardIosIcon />}
                          component={Link}
                          to={`/admin/clients/profile/${row.nit}`}
                          size="small"
                        >
                          <span
                            style={{ marginBottom: "-4px", fontSize: "12px" }}
                          >
                            Ver detalles
                          </span>
                        </Button>
                      </div>
                    ),
                  },
                ]}
                data={filteredExpiredClients}
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se ha encontrado ningún resultado"
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <div className="row">
                <div
                  className="col-md-2 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{ fontSize: "0.85rem", color: "black" }}
                  >
                    Membresias Activas
                  </p>
                  <h6 className="mb-2 secondary_color">
                    <strong>{numberMembershipActive}</strong>
                  </h6>
                </div>
                <div
                  className="col-md-2 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{ fontSize: "0.85rem", color: "black" }}
                  >
                    Membresias Inactivas
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>{numberMembershipNoActive}</strong>
                  </h6>
                </div>
                <div className="col-md-2 px-2">
                  <p
                    className="mb-1"
                    style={{ fontSize: "0.85rem", color: "black" }}
                  >
                    Usuarios En Línea
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>{numberMembershipOnline}</strong>
                  </h6>
                </div>
                <div
                  className="col-md-2 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{ fontSize: "0.85rem", color: "black" }}
                  >
                    Usuario Fuera de Línea
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>{numberMembershipOffline}</strong>
                  </h6>
                </div>
                <div
                  className="col-md-2 px-2"
                  style={{ borderRight: "1px solid #E0E0E0" }}
                >
                  <p
                    className="mb-1"
                    style={{ fontSize: "0.85rem", color: "black" }}
                  >
                    Todos los usuarios
                  </p>
                  <h6 className="mb-0 secondary_color">
                    <strong>{numberMembershipAllUsers}</strong>
                  </h6>
                </div>
              </div>
              <div class="row mt-3">
                <div className="col-md-4" style={{ alignSelf: "center" }}>
                  <FormControl variant="outlined">
                    <Select
                      native
                      onChange={(event) => filterByStateMembresia(event)}
                      className="cs-select membresia-select"
                    >
                      <option value="0">Todas las membresías</option>
                      <option value="1">Membresias Activas</option>
                      <option value="2">Membresias No Activas</option>
                      <option value="3">Usuarios En Línea</option>
                      <option value="4">Usuarios Fuera de Línea</option>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-4" />
              </div>

              <DataTable
                className="datatable-cs datatable-cs-v2 mt-2"
                title={"s"}
                columns={
                  isOnlineSelected === null &&
                  isActiveMembershipSelected === null
                    ? [
                        {
                          name: "Nombre del cliente",
                          selector: "nombreEmpresa",
                          sortable: true,
                        },

                        {
                          name: "Estado de la Membresia",
                          selector: "estadoMembresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.estadoMembresia === true
                                ? "ACTIVO"
                                : "INACTIVO"}
                            </div>
                          ),
                          sortable: true,
                        },
                        {
                          name: "N° Usuarios En Línea",
                          selector: "estadoMebresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.usuariosEnLinea.length}
                            </div>
                          ),
                          sortable: true,
                        },
                        {
                          name: "N° Usuarios Fuera de Linea",
                          selector: "estadoMebresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.usuariosFueraLinea.length}
                            </div>
                          ),
                          sortable: true,
                        },
                        {
                          name: "",
                          selector: "action",
                          cell: (row) => (
                            <div className="text-center w-100">
                              <Button
                                className="brand2-btn"
                                variant="contained"
                                color="primary"
                                onClick={() => handleClickOpen(row)}
                                endIcon={<ArrowForwardIosIcon />}
                                size="small"
                              >
                                <span
                                  style={{
                                    marginBottom: "-4px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Ver detalles
                                </span>
                              </Button>
                            </div>
                          ),
                        },
                      ]
                    : isOnlineSelected === true &&
                      isActiveMembershipSelected === null
                    ? [
                        {
                          name: "Nombre del cliente",
                          selector: "nombreEmpresa",
                          sortable: true,
                        },

                        {
                          name: "Estado de la Membresia",
                          selector: "estadoMembresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.estadoMembresia === true
                                ? "ACTIVO"
                                : "INACTIVO"}
                            </div>
                          ),
                          sortable: true,
                        },
                        {
                          name: "N° Usuarios En Línea",
                          selector: "estadoMebresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.usuariosEnLinea.length}
                            </div>
                          ),
                          sortable: true,
                        },

                        {
                          name: "",
                          selector: "action",
                          cell: (row) => (
                            <div className="text-center w-100">
                              <Button
                                className="brand2-btn"
                                variant="contained"
                                color="primary"
                                onClick={() => handleClickOpen(row)}
                                endIcon={<ArrowForwardIosIcon />}
                                size="small"
                              >
                                <span
                                  style={{
                                    marginBottom: "-4px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Ver detalles
                                </span>
                              </Button>
                            </div>
                          ),
                        },
                      ]
                    : isOnlineSelected === false &&
                      isActiveMembershipSelected === null
                    ? [
                        {
                          name: "Nombre del cliente",
                          selector: "nombreEmpresa",
                          sortable: true,
                        },

                        {
                          name: "Estado de la Membresia",
                          selector: "estadoMembresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.estadoMembresia === true
                                ? "ACTIVO"
                                : "INACTIVO"}
                            </div>
                          ),
                          sortable: true,
                        },
                        {
                          name: "N° Usuarios Fuera de Linea",
                          selector: "estadoMebresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.usuariosFueraLinea.length}
                            </div>
                          ),
                          sortable: true,
                        },

                        {
                          name: "",
                          selector: "action",
                          cell: (row) => (
                            <div className="text-center w-100">
                              <Button
                                className="brand2-btn"
                                variant="contained"
                                color="primary"
                                onClick={() => handleClickOpen(row)}
                                endIcon={<ArrowForwardIosIcon />}
                                size="small"
                              >
                                <span
                                  style={{
                                    marginBottom: "-4px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Ver detalles
                                </span>
                              </Button>
                            </div>
                          ),
                        },
                      ]
                    : isOnlineSelected === false &&
                      isActiveMembershipSelected === null
                    ? [
                        {
                          name: "Nombre del cliente",
                          selector: "nombreEmpresa",
                          sortable: true,
                        },

                        {
                          name: "Estado de la Membresia",
                          selector: "estadoMembresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.estadoMembresia === true
                                ? "ACTIVO"
                                : "INACTIVO"}
                            </div>
                          ),
                          sortable: true,
                        },
                        {
                          name: "N° Usuarios Fuera de Linea",
                          selector: "estadoMebresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.usuariosFueraLinea.length}
                            </div>
                          ),
                          sortable: true,
                        },

                        {
                          name: "",
                          selector: "action",
                          cell: (row) => (
                            <div className="text-center w-100">
                              <Button
                                className="brand2-btn"
                                variant="contained"
                                color="primary"
                                onClick={() => handleClickOpen(row)}
                                endIcon={<ArrowForwardIosIcon />}
                                size="small"
                              >
                                <span
                                  style={{
                                    marginBottom: "-4px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Ver detalles
                                </span>
                              </Button>
                            </div>
                          ),
                        },
                      ]
                    : isActiveMembershipSelected && isOnlineSelected === null
                    ? [
                        {
                          name: "Nombre del cliente",
                          selector: "nombreEmpresa",
                          sortable: true,
                        },

                        {
                          name: "Estado de la Membresia",
                          selector: "estadoMembresia",
                          cell: (row) => (
                            <div className="w-100 text-center">
                              {row.estadoMembresia === true
                                ? "ACTIVO"
                                : "INACTIVO"}
                            </div>
                          ),
                          sortable: true,
                        },

                        {
                          name: "",
                          selector: "action",
                          cell: (row) => (
                            <div className="text-center w-100">
                              <Button
                                className="brand2-btn"
                                variant="contained"
                                color="primary"
                                onClick={() => handleClickOpen(row)}
                                endIcon={<ArrowForwardIosIcon />}
                                size="small"
                              >
                                <span
                                  style={{
                                    marginBottom: "-4px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Ver detalles
                                </span>
                              </Button>
                            </div>
                          ),
                        },
                      ]
                    : ""
                }
                data={dataMembership}
                pagination
                paginationPerPage={10}
                paginationIconFirstPage={null}
                paginationIconLastPage={null}
                paginationComponentOptions={{
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noDataComponent="No se ha encontrado ningún resultado"
              />
            </TabPanel>
          </div>
        )}
      </div>
    </div>
  );
}
