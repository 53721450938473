import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";
import { toast } from "react-toastify";
import domToPdf from "dom-to-pdf";

import { CircularProgress } from "@material-ui/core";

// styles
import "./custom.css";

//icons
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

// components

const orderItems = [
  {
    cantidad: 1,
    codigo: "SKU0002",
    descripcion: "GASEOSA POPULAR",
    documento: 148,
    fecha: "2021-02-26T15:37:48.000Z",
    id_movimiento_items: 725,
    id_new_empresa: 67,
    id_new_item: 453,
    id_pos_movimiento: 705,
    id_tipo_documento_web_app: 1,
    imagen_1: "images021-02-24T23-01-07.093Z-$img_1$descarga.jpg",
    nombre: "GASEOSA INKA",
    precio: 3.8,
    serie: "FA6772",
  },
];

const ref = React.createRef();

export default function Invoice_2(props) {
  var convertir = require("numero-a-letras");

  const generatePdf = (filename) => {
    const element = document.querySelector(".pdf-target");

    const options = {
      filename: filename,
    };

    return domToPdf(element, options, () => {
      // callback function
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div
          style={{
            border: "1px solid gray",
            width: "100%",
            overflowX: "scroll",
          }}
          className="mt-4"
        >
          <div
            className="pdf-target px-4 py-3"
            ref={ref}
            style={{ backgroundColor: "white" }}
          >
            {false ? (
              <div className="anulado-overlay">
                <p style={{ color: "red" }}>FACTURA ANULADA</p>
              </div>
            ) : (
              ""
            )}

            <div className="row">
              <div className="col-3 px-1">
                <img
                  src={
                    props.imagen_factura !== ""
                      ? props.imagen_factura
                      : "/images/cloudLogo.png"
                  }
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="col-7">
                <p className="mb-0">
                  <strong>CLOUD, S.A</strong>
                </p>
                <p className="mb-0">
                  <strong>CLOUD</strong>
                </p>
                <p className="mb-0">
                  <strong>NIT: 7879036-0</strong>
                </p>
                <p>
                  Vía 4 1-00, zona 4 Edificio Campus Tec 2, 6to Nivel Oficina
                  601. Teléfono 25033333
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="text-center">
                  <strong>DOCUMENTO TRIBUTARIO ELECTRONICO</strong>
                </p>
                <p className="text-center">
                  <strong>*** Factura Electrónica ***</strong>
                </p>
              </div>
            </div>

            <p className="ml-4">FECHA: {moment().local().format("L")}</p>

            <div className="d-flex justify-content-between">
              <div class="ml-3">
                <p className="mb-0">
                  <strong>Serie FEL:</strong> 3EFC4A4A
                </p>
                <p className="mb-0">
                  <strong>Numero FEL:</strong> 18237692
                </p>
                <p className="mb-0">
                  <strong>Fecha Autorización:</strong>
                </p>
                <p className="mb-0">2021-03-02T11:50:34</p>
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: "5px" }}
            >
              <div class="ml-0">
                <p className="mb-0">NOMBRE: CONSUMIDOR FINAL</p>
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: "10px" }}
            >
              <div class="ml-5">
                <p className="mb-0">NIT : CF</p>
                <p className="mb-0">DIR : CIUDAD</p>
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: "10px" }}
            >
              <div class="ml-1">
                <p className="mb-0">FECHA 2/03/2021</p>
              </div>
            </div>

            <div className="divider" />
            <div className="row">
              <div className="col-2 text-center">
                <strong>Cantidad</strong>
              </div>
              <div className="col-5 text-center">
                <strong>Descripción</strong>
              </div>
              <div className="col-3 text-center">
                <strong>Precio Unitario</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Total</strong>
              </div>
            </div>
            <div className="divider" />

            {orderItems.map((product) => (
              <div className="row">
                <div className="col-2 text-center">{product.cantidad}</div>
                <div className="col-5 text-center">
                  {product.descripcion} <br /> {product.codigo}
                </div>
                <div className="col-3 text-center">
                  {
                    product.total
                      ? product.precio.toFixed(2) // On this case, user comes from the sales process
                      : (product.precio / product.cantidad).toFixed(2) // On this case, user comes from wherever else
                  }
                </div>
                <div className="col-2 text-center">
                  {product.total
                    ? product.total.toFixed(2) // Same logic as before
                    : product.precio.toFixed(2)}
                </div>
              </div>
            ))}

            <div className="divider" />

            <div className="row">
              <div className="col-4" />
              <div className="col-3" />
              <div
                className="col-5 text-right"
                style={{ paddingRight: "44px" }}
              >
                <strong>
                  Total-Factura Q{" "}
                  {orderItems[0].total
                    ? Number(
                        orderItems.reduce((a, b) => a + b.total, 0)
                      ).toFixed(2)
                    : Number(
                        orderItems.reduce((a, b) => a + b.precio, 0)
                      ).toFixed(2)}
                </strong>
              </div>
            </div>

            <div className="row">
              <div className="col-6 text-right">
                <div className="row">
                  <div className="col-7 text-left">
                    {orderItems[0].total
                      ? Number(
                          orderItems.reduce((a, b) => a + b.total, 0)
                        ).toFixed(2)
                      : Number(
                          orderItems.reduce((a, b) => a + b.precio, 0)
                        ).toFixed(2) <= 1
                      ? convertir
                          .NumerosALetras(2)
                          .toUpperCase()
                          .replace("PESO", "QUETZAL")
                      : convertir
                          .NumerosALetras(2)
                          .toUpperCase()
                          .replace("PESOS", "QUETZALES")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-7 text-right">Efectivo:</div>
                  <div className="col-5 text-right">3,8</div>
                </div>

                <div className="row">
                  <div className="col-7 text-right">Cambio:</div>
                  <div className="col-5 text-right">3,8</div>
                </div>

                <div className="row">
                  <div className="col-7 text-right">Tarjeta Credito</div>
                  <div className="col-5 text-right">0</div>
                </div>
                <div className="row">
                  <div className="col-7 text-right">Otros</div>
                  <div className="col-5 text-right">0</div>
                </div>
                <div className="row">
                  <div className="col-7 text-right">Correlativo Interno</div>
                  <div className="col-5 text-right">
                    B1<label style={{ marginLeft: "50px" }}>618</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-7 text-right">Vendedor</div>
                  <div className="col-5 text-right">Turno Mañana 1</div>
                </div>

                {/* <div className="row">
												<div className="col-7 text-right">Total Cupones:</div>
												<div className="col-5 text-right">{invoiceInfo.paidByGiftCard}</div>
											</div> */}
              </div>
            </div>

            <p className="mb-0 mt-2 text-center">SUJETO A PAGOS TRIMESTRALES</p>
            <p className="mb-0">
              {moment().format("L")} {moment().format("LTS")}
            </p>
            <p>
              <strong>Observaciones:</strong>
            </p>

            <div className="row">
              <div className="col-12">
                <p className="text-center">
                  <strong>CLOUD, S.A</strong>
                </p>
                <p className="text-center">
                  <strong>3EFC4A4A-0116-48FC-B758-2349179682C4</strong>
                </p>
                <p className="text-center">
                  <strong>Certificador</strong> DOCUTEC <strong>Nit</strong>{" "}
                  10495692-5
                </p>
              </div>
            </div>

            {props.texto_1 ? (
              <>
                <p className="mb-0 text-center">
                  <strong>{props.texto_1}</strong>
                </p>
              </>
            ) : (
              ""
            )}

            {props.texto_2 ? (
              <>
                <p className="mb-0 text-center">
                  <strong>{props.texto_2}</strong>
                </p>
              </>
            ) : (
              ""
            )}
            <p className="mb-0 text-center">
              <strong>PBX: 2503 - 3333</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
