import React, { useState } from "react";
import API from "../../../../api";
export const useSuggestions = (enterprises) => {
  const AutosuggestHighlightMatch = require("autosuggest-highlight/match");
  const AutosuggestHighlightParse = require("autosuggest-highlight/parse");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSucursals, setSelectedSucursals] = useState([]);
  const [id_empresa, setIdEmpresa] = useState("");
  const onSuggestionsFetchRequested = () => {
    const inputValue = document
      .getElementById("enterprise_query")
      .value.trim()
      .toLowerCase();
    setSuggestions(
      enterprises.filter((enterprise) =>
        enterprise.nombre.toLowerCase().includes(inputValue)
      )
    );
  };
  const [sucursals, setSucursals] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const handleChange = (e, { newValue }) => {
    setEnterpriseName(newValue);
  };
  const inputProps = {
    placeholder: "Escribe el nombre de la empresa",
    value: enterpriseName,
    onChange: handleChange,
    id: "enterprise_query",
  };
  const getSuggestionValue = (suggestion) => {
    return suggestion.nombre;
  };

  const handleGetSucursals = async (e, { suggestion }) => {
    e.preventDefault();

    const id_empresa = suggestion.id_new_empresa;
    setIdEmpresa(id_empresa);
    setLoading(true);
    setSucursals([]);

    try {
      const { data } = await API.sucursal.getAll(id_empresa);
      setSucursals(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSucursals([]);
    }
  };

  const renderSuggestion = (suggestion) => {
    const matches = AutosuggestHighlightMatch(
      suggestion.nombre,
      enterpriseName
    );
    const parts = AutosuggestHighlightParse(suggestion.nombre, matches);

    return (
      <div className="p-0 m-0">
        {parts.map((part, index) => {
          const className = part.highlight ? "font-weight-bold" : null;

          return (
            <span key={index} className={className}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };
  const handleSelectSucursals = (info) => {
    let { selectedRows } = info;
    setSelectedSucursals(selectedRows);
  };
  return {
    suggestions,
    renderSuggestion,
    getSuggestionValue,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    handleGetSucursals,
    handleSelectSucursals,
    selectedSucursals,
    inputProps,
    sucursals,
    loading,
    id_empresa,
  };
};
