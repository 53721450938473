import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";
import { getMoneda } from "../helpers/moneda";

const HistorialFelReporte = ({ objeto }) => {
  const moneda = getMoneda();
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberFormater = (value) => {
    let newValue = formaterPriceFunction(parseFloat(value).toFixed(2) + "");
    return newValue;
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="historialFEL-reporte-table">
        <thead>
          <tr>
            <th>Tipo de operación</th>
            <th> Identificador</th>
            <th> Fecha / Hora</th>
            <th>Nombre</th>
            <th>NIT</th>
            <th>Total</th>
            <th>Serie FEL</th>
            <th>Firma</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            console.log(x);

            return (
              <tr key={index}>
                <td>{x.tipo_operacion}</td>
                <td>{x.identificador}</td>
                <td>
                  {moment(new Date(x.fecha))
                    .utcOffset(0)
                    .format("DD/MM/YYYY h:mm:ss a")}
                </td>
                <td>{x.nombre}</td>
                <td>{x.nit}</td>
                <td>
                  {moneda}. {formaterPriceFunction(x.total.toFixed(2) + "")}
                </td>
                <td>{x.serie_fel}</td>
                <td>{x.firma_fel}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default HistorialFelReporte;
