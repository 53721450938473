import styled from "styled-components";

import { colors, device } from "./config.styled.components";

export const BigTitle = styled.h1`
  color: ${(props) => props.color || colors.blackColor};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  margin: ${(props) => props.margin || "auto 0"};
  grid-column: ${(props) => props.gridColumn};

  grid-area: ${(props) => props.gridArea};
`;
