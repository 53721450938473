import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
//eslint-disable-next-line
import $ from "jquery";
import FooterLinks from "../../components/FooterLinks/FooterLinks";
import HeaderNoSession from "../../components/HeaderNoSession/Header";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { CircularProgress, Button } from "@material-ui/core";
import ReactCodeInput from "react-code-input";
import API from "../../api";
import { toast } from "react-toastify";
// styles
import "./custom.css";

const codeInputProps = {
  className: ReactCodeInput,
  inputStyle: {
    margin: "15px",
    MozAppearance: "textfield",
    width: "50px",
    borderRadius: "3px",
    height: "50px",
    fontSize: "20px",
    paddingLeft: "7px",
    backgroundColor: "#F2F2F2",
    color: "black",
    border: "1px solid #0160E7",
    textAlign: "center",
  },
  inputStyleInvalid: {
    margin: "15px",
    MozAppearance: "textfield",
    width: "50px",
    height: "50px",
    borderRadius: "3px",
    fontSize: "20px",
    paddingLeft: "7px",
    backgroundColor: "#F2F2F2",
    color: "red",
    border: "1px solid red",
    textAlign: "center",
  },
};

//

function Register(props) {
  let history = useHistory();
  useEffect(() => {
    if (props.history.location.state) {
      setEmail(props.history.location.state.userEmail);
    }
  }, []);

  // Definitions

  // states
  //eslint-disable-next-line
  var [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  var [error, setError] = useState(null);
  var [code, setCode] = useState("");
  var [email, setEmail] = useState("");

  // methods

  const submitForm = () => {
    setIsLoading(true);
    API.auth
      .verifyEmailCode({
        correo_electronico: email,
        code: code,
      })
      .then((res) => {
        let response = res.data;
        if (response.success) {
          setIsLoading(false);
          history.push({
            pathname: "/forgot/verified",
            state: { userEmail: email },
          });
          toast.success("Tu identidad ha sido confirmada.", {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const resendCode = () => {
    console.log("Email is:", email);
    if (email === "") {
      toast.error("Ha ocurrido un error.");
    } else {
      setIsLoading(true);
      API.auth
        .sendEmailCode({
          correo_electronico: email,
        })
        .then((res) => {
          let response = res.data;
          if (response.success) {
            setIsLoading(false);
            toast.success("El código de recuperación ha sido reenviado.", {
              autoClose: 10000,
            });
          } else {
            toast.warning(response.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", {
              autoClose: 10000,
            });
          }
        });
    }
  };

  return (
    <div className="row h-100 w-100 p-0 m-0">
      <div className="row h-100 w-100 m-0 p-0 outer-container justify-content-center password-recovery ">
        <HeaderNoSession />
        <div
          className="col-12 p-0 inner-container banner"
          style={{ marginTop: "66px" }}
        >
          <div className="row w-100 h-100 justify-content-around align-items-center">
            <div className="col-md-6">
              <h1 className="page-title">Recuperación de contraseña</h1>
            </div>

            <div className="col-md-6 align-items-center">
              <div className="d-flex justify-content-end align-items-center right-banner-text">
                <p className="mr-3 text-white my-auto">
                  <strong>¿Ya tienes cuenta?</strong>
                </p>
                <Button
                  variant="contained"
                  size="large"
                  component={Link}
                  to={"/login"}
                  style={{
                    backgroundColor: "#08BFE6",
                    color: "white",
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                >
                  Iniciar sesión
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row justify-content-center">
              <div
                className="col-8 d-flex justify-content-around"
                id="steps-circles-col"
                role="tablist"
              >
                <div className="steps-circle-container">
                  <div className="steps-circle steps-circle-active">1</div>
                </div>
                <div className="steps-circle-container">
                  <div className="steps-circle steps-circle-active">2</div>
                </div>
                <div className="steps-circle-container">
                  <div className="steps-circle">3</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-4 p-0 content"
          id="PWRecovery-step2"
          style={{ marginTop: "8rem" }}
        >
          <React.Fragment>
            <div className="card content-card card-body p-5">
              <h1>
                <strong>Introduce el código de recuperación</strong>
              </h1>
              <p>
                Hemos enviado un código a tu dirección de correo electrónico.
              </p>

              <div className="row p-0">
                <div className="col-md-12 text-center p-0">
                  <ReactCodeInput
                    type="text"
                    fields={4}
                    {...codeInputProps}
                    value={code}
                    onChange={(value) => setCode(value)}
                  />
                </div>
              </div>

              {isLoading ? (
                <div className="row mt-5 justify-content-center">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <CircularProgress size={26} className="mx-auto" />
                  </div>
                </div>
              ) : (
                <div className="row mt-5 justify-content-end">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth
                      endIcon={
                        <ArrowForwardIosIcon
                          style={{
                            fontSize: "16px",
                            marginLeft: "0",
                            padding: "0",
                          }}
                        />
                      }
                      style={{
                        background: "#0160E7",
                        color: "white",
                        height: "fit-content",
                        textTransform: "none",
                        fontSize: "18px",
                      }}
                      className="ml-auto"
                      onClick={submitForm}
                    >
                      Validar código
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <p className="text-center mt-4 mb-4">
              <strong>¿No ha recibido ningún código?</strong>
            </p>
            <p
              className="text-center mt-0"
              onClick={resendCode}
              id="resendCode-text"
            >
              Haga clic aqui para reenviar su código.
            </p>
          </React.Fragment>
        </div>
        <FooterLinks />
      </div>
    </div>
  );
}

export default withRouter(Register);
