import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../../../api";
import { getLocalParseToken } from "../../../../helpers/handleLocalStorage";
import { calcularPorcentageBarLine } from "../../../../helpers/utilsFunctions";
import useInfinityScroll from "./utils/useInfinity";
import { CircularProgress } from "@material-ui/core";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#33eaff",
  },
}))(LinearProgress);

const BestSellers = ({ actualSucursal, loader }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (actualSucursal) {
      getProductos();
    }
  }, [actualSucursal]);

  const getProductos = async () => {
    let idEmpresa = getLocalParseToken("id_empresa");
    const response = await api.informesAleko.getInformesProductos(
      idEmpresa,
      actualSucursal.id_linea_web_app
    );
    setProducts(response.data.data.grafica_productos);
    // console.log(
    //   calcularPorcentageBarLine(
    //     response.data.data.grafica_productos[0].Total,
    //     response.data.data.grafica_productos.length
    //   )
    // );
  };

  // const [page, setPage] = useState(0);
  // let { products } = useInfinityScroll(page);
  // products = products.sort((a, b) => b.qty - a.qty);

  // const loader = useRef(null);

  // const handleObserver = useCallback((entries) => {
  //   const target = entries[0];
  //   if (target.isIntersecting) {
  //     setPage((prev) => prev + 10);
  //   }
  // }, []);

  // useEffect(() => {
  //   const option = {
  //     root: null,
  //     rootMargin: "20px",
  //     threshold: 0,
  //   };
  //   const observer = new IntersectionObserver(handleObserver, option);

  //   if (loader.current) observer.observe(loader.current);
  // }, [handleObserver]);

  return (
    <div
      className="card shadow"
      style={{ height: "calc(100vh - 130px)", border: "none" }}
    >
      <div className="py-2 d-flex align-items-center px-2">
        {" "}
        <div>
          <h5 className="m-0">
            <strong>Productos mas vendidos</strong>
          </h5>
        </div>
        {/* <div className="ml-auto">
          <div className="section-title">
            <select className="form-select">
              <option value="">Últimos 30 días</option>
            </select>
          </div>
        </div> */}
      </div>
      <div className="table-responsive scroll" style={{ height: "100%" }}>
        <table className="table mb-0">
          <thead style={{ background: "#0160E7" }}>
            <tr className="text-light w-100">
              <th scope="col">Producto</th>
              <th scope="col">Monto</th>
              <th scope="col">Categoría</th>
              <th scope="col">Cant. Vendida</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr className="mx-auto text-center">
                <td colSpan={4}>
                  <CircularProgress size={20} />
                </td>
              </tr>
            ) : products.length === 0 ? (
              <tr className="mx-auto text-center">
                <td colSpan={4}>Sin datos pora mostrar</td>
              </tr>
            ) : (
              products.map((product) => (
                <>
                  <tr className="bg-white">
                    <td scope="row">{product.nombre}</td>
                    <td>Q. {product.Monto.toFixed(2)}</td>
                    <td>{product.categoria}</td>
                    <td>{product.Total}</td>
                  </tr>
                  {/* <BorderLinearProgress
                        variant="determinate"
                        value={calcularPorcentageBarLine(product.Total)}
                      /> */}
                </>
              ))
            )}
            {/* <div
              style={{
                display: products.length ? "block" : "none",
                padding: "1px",
              }}
              ref={loader}
            /> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BestSellers;
