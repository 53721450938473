import React from "react";
import BestSellers from "./BestSellers";
import Categories from "./Categories";

const Products = ({ actualSucursal, summary, loader }) => {
  return (
    <div className="row mt-3">
      <div className="col-md-4">
        <div className="card shadow" style={{ border: "none" }}>
          <Categories summary={summary} loader={loader} />
        </div>
      </div>
      <div className="col-md-8">
        <div className="card shadow" style={{ border: "none" }}>
          <BestSellers actualSucursal={actualSucursal} loader={loader} />
        </div>
      </div>
    </div>
  );
};

export default Products;
