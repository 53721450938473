import { base_url, axios } from "../api";
var qs = require("qs");
const moment = require("moment");

const headers = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const getAll = async (data) => {
  return await axios.post(`${base_url}/web/get-ingresos`, qs.stringify(data), {
    headers: headers(),
  });
};

const getById = async (data) => {
  return await axios.post(`${base_url}/web/get-ingreso`, qs.stringify(data), {
    headers: headers(),
  });
};

const getMotives = async () => {
  return await axios.get(`${base_url}/web/get-ingreso-motives`, {
    headers: headers(),
  });
};

const add_new = async (data) => {
  data.fecha_ingreso = moment(data.fecha_ingreso).toDate();
  console.log("Submitting this data to add-ingreso:", data);
  return await axios.post(`${base_url}/web/add-ingreso`, qs.stringify(data), {
    headers: headers(),
  });
};

export default {
  getAll,
  getMotives,
  getById,
  add_new,
};
