import { base_url, axios } from "../api";
var qs = require("qs");

const headers = () => {
  let token = localStorage.getItem("token");
  if (!localStorage.getItem("token")) {
    token = localStorage.getItem("AdminToken");
  }
  return { Authorization: "Bearer " + token };
};

const getAll = async (data) => {
  return await axios.post(`${base_url}/web/get-users`, qs.stringify(data), {
    headers: headers(),
  });
};

const add_user = async (data) => {
  var form_data = new FormData();

  for (var key in data) {
    form_data.append(key, data[key]);
  }

  return await axios.post(`${base_url}/web/add-user`, form_data, {
    headers: headers(),
  });
};

const update_user = async (data) => {
  var form_data = new FormData();

  for (var key in data) {
    form_data.append(key, data[key]);
  }

  return await axios.put(`${base_url}/web/update-user`, form_data, {
    headers: headers(),
  });
};

const deleteUsers = async (data) => {
  return await axios.post(`${base_url}/web/delete-users`, qs.stringify(data), {
    headers: headers(),
  });
};

const getUserTypes = async () => {
  return await axios.get(`${base_url}/web/get-user-types`, {
    headers: headers(),
  });
};

//cmi
const getEmpresasCmi = async (value) => {
  return await axios.post(
    `${base_url}/web/cmi/obtener-empresas-cmi`,
    qs.stringify(value),
    {
      headers: headers(),
    }
  );
};

const getEmpresaPorUsuario = async (id) => {
  const identificador = parseInt(id);

  return await axios.post(
    `${base_url}/web/cmi/obtener-empresas-por-usuarios-gerentes`,
    qs.stringify({ id_new_user: identificador }),
    {
      headers: headers(),
    }
  );
};

export default {
  getAll,
  add_user,
  update_user,
  deleteUsers,
  getUserTypes,
  getEmpresasCmi,
  getEmpresaPorUsuario,
};
