import React, { useState, useEffect, useContext } from "react";

// Icons
import { Close } from "@material-ui/icons";

// Styled Components

import {
  GridContainer,
  FlexContainer,
  BigTitle,
  ColorContainer,
  CancelButton,
  ContinueButton,
  PointInput,
  ColorDiv,
} from "./styled/styled.components";
import ProductPersonalizationContext from "../../../context/PersonalizacionProducto/ProductPersonalizationContext";
import {
  handleAreas,
  handleContent,
  handleCustom,
} from "./AreaContainerForm/utils/handleChanges";
import { toast } from "react-toastify";

// Components

const CustomizationPoints = ({
  perspectives,
  currentPerspectiveTab,
  stageMiddle,
  addPoint,
  points,
  setPoints,
  index,
  variantId,
  customType,
  contentType,
  variant,
  handleEnableCustomization,
}) => {
  // This handle currentPerspective
  let currentPerspective = perspectives[currentPerspectiveTab]
    ? perspectives[currentPerspectiveTab]
    : {};

  const {
    deleteArea,
    currentProductTab,
    handleForms,
    savedVariants,
    formularios,
    validation,
    enable_customization,
  } = useContext(ProductPersonalizationContext);

  const currentForm = formularios.find(
    (item) => item.variantId === variant.variantId
  );

  const currentVariant = savedVariants.find(
    (variant) => variant.variantId === variantId
  );

  useEffect(() => {
    if (!currentVariant) {
      variant.points = [];
      setPoints([]);
    } else {
      variant.points = currentVariant?.points || [];
      setPoints(currentVariant?.points || []);
    }
  }, [currentPerspective]);

  // this keeps json updated
  useEffect(() => {
    if (currentVariant?.points) {
      currentVariant.points = points;
    }
  }, [points]);

  // this keeps json input title updated
  const handleValue = (e, i) => {
    let newArr = [...points];
    newArr[i].title = e.target.value;
    variant.points = newArr;
    setPoints(newArr);
    handleAreas(e.target.value, currentVariant, handleForms, i);

    // handleSavePoints(perspectives, currentProductTab.title);
  };

  // this delete circle

  const deletePoint = (i, ind) => {
    variant.points = points.filter((a) => a.color != i);
    setPoints(points.filter((a) => a.color != i));
    deleteArea(ind, variant.variantId, points.length);
  };

  return (
    <FlexContainer
      gridColumn="1/-1"
      justifyContent="center"
      alignItems="start"
      flexDirection="column"
      flexWrap="wrap"
      padding="0 15px"
      margin="auto"
    >
      {currentForm?.enable_customization && (
        <>
          <BigTitle fontSize="16px" fontWeight="700" margin="5px auto">
            Creación de área de personalización
          </BigTitle>
          <ContinueButton
            padding="15px 35px"
            onClick={() => {
              if (!currentForm.enable_customization) {
                toast.warning("Debes habilitar la personalización", {
                  autoclose: 10000,
                });
                return;
              }

              addPoint();
              if (!addPoint()) {
                return;
              }
              const key_1 = `contenido_tipo_${points.length + 1}`;
              const key_2 = `medidas_ancho_${points.length + 1}`;
              const key_3 = `medidas_alto_${points.length + 1}`;
              const key_4 = `adjunto_${points.length + 1}`;
              const key_5 = `nombre_area_${points.length + 1}`;
              const key_6 = `descuento_${points.length + 1}`;
              const key_7 = `personalizacion_tipo_${points.length + 1}`;
              const key_8 = `personalizacion_tipo_precio_${points.length + 1}`;
              const key_9 = `personalizacion_tipo_precio_minimo_${
                points.length + 1
              }`;

              handleForms({
                variantId,
                [key_1]: "Texto",
                [key_2]: "",
                [key_3]: "",
                [key_4]: "",
                [key_5]: "",
                [key_6]: 0,
                [key_7]: customType[0].nombre_personalizacion,
                [key_8]: customType[0].precio_medida,
                [key_9]: customType[0].precio_minimo,
              });
            }}
          >
            Crear
          </ContinueButton>
        </>
      )}
      <FlexContainer
        maxHeight="300px"
        overflow="auto"
        flexDirection="column"
        flexFlow="column"
        margin="auto"
      >
        {points?.map((item, i) => {
          return (
            <FlexContainer key={item.color} margin="10px 0" flexWrap="wrap">
              <ColorDiv backgroundColor={item.color} />

              <PointInput
                key={i}
                defaultValue={item.title}
                value={currentForm ? currentForm[`nombre_area_${i + 1}`] : ""}
                onChange={(e) => {
                  handleValue(e, i);
                  item.nombre_area = e.target.value;
                }}
              />

              <CancelButton
                margin="0 10px"
                padding="5px"
                onClick={() => {
                  deletePoint(item.color, i);
                }}
              >
                <Close />
              </CancelButton>
              {validation && currentForm[`nombre_area_${i + 1}`] === "" && (
                <span className="text-danger w-100">Requerido</span>
              )}
            </FlexContainer>
          );
        })}
      </FlexContainer>
    </FlexContainer>
  );
};

export default CustomizationPoints;
