import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import classnames from "classnames";
import { Box, CircularProgress } from "@material-ui/core";
import API from "../../api";
import { toast } from "react-toastify";

//icons

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";

/* users components */
import UserAll from "../../pages/users/all";
import UserNew from "../../pages/users/new";

/* Cortes de cajas components */
import CortesCajas_historial from "../../pages/cortes_caja/historial";
import CortesCajas_realizarCorte from "../../pages/cortes_caja/realizar_corte";
import CortesCajas_detallesCorte from "../../pages/cortes_caja/details";

/* User Config components */
import UserConfig from "../../pages/config/userConfig";
import NewSucursal from "../../pages/config/new_sucursal";
import EditCajas from "../../pages/config/edit_cajas";

/* Clients components */
import ClientsAll from "../../pages/clients/all";
import ClientNew from "../../pages/clients/new";
import ClientProfile from "../../pages/clients/profile";
import ClientProfile_extended from "../../pages/clients/profile_extended";

/* Caja de venta components */
import Sell_step_1 from "../../pages/caja_venta/sell_step_1";
import Sell_step_2 from "../../pages/caja_venta/sell_step_2";
import Sell_step_3 from "../../pages/caja_venta/sell_step_3";
import Sell_step_3_recibo_credito from "../../pages/caja_venta/sell_step_3_recibo_credito";
import Anticipo_1 from "../../pages/caja_venta/anticipo";
import Anticipo_2 from "../../pages/caja_venta/anticipo_2";
import Facturacion from "../../pages/caja_venta/facturacion";
import Catalogo from "../../pages/caja_venta/catalogo";

/* Copia Caja de venta components */

import Sell_step_1_dev from "../../pages/CajaVentaAleko/sell_step_1";
import Sell_step_2_dev from "../../pages/CajaVentaAleko/sell_step_2";
import Sell_step_3_dev from "../../pages/CajaVentaAleko/sell_step_3";
import Sell_step_3_recibo_credito_dev from "../../pages/CajaVentaAleko/sell_step_3_recibo_credito";
import Anticipo_1_dev from "../../pages/CajaVentaAleko/anticipo";
import Anticipo_2_dev from "../../pages/CajaVentaAleko/anticipo_2";
import Facturacion_dev from "../../pages/CajaVentaAleko/facturacion";
import Catalogo_dev from "../../pages/CajaVentaAleko/catalogo";
import ConfirmOrder from "../../pages/CajaVentaAleko/components/confirm.order.js";
import PdfDocument from "../../pages/CajaVentaAleko/components/PdfDocument/index";
import CustomizationProducts from "../../pages/CajaVentaAleko/customization";

/* Historial components */
import HistorialOperations from "../../pages/historial/historial";
import Anulacion_new from "../../pages/historial/crear_anulacion";
import Anulacion_resumen from "../../pages/historial/resumen_anulacion";
import nota_credito_emitir from "../../pages/historial/nota_credito_emitir";
import nota_credito_resumen from "../../pages/historial/resumen_nota_credito";
import nota_debito_resumen from "../../pages/historial/resumen_nota_debito";
import nota_debito_crear from "../../pages/historial/nota_debito_crear";

/* Inventore components */
import Inventory from "../../pages/inventory/all";
import Inventory_newEgreso from "../../pages/inventory/create_egreso";
import Inventory_confirmEgreso from "../../pages/inventory/confirm_egreso";
import Egreso_details from "../../pages/inventory/egreso_details";
import Inventory_newIngreso from "../../pages/inventory/create_ingreso";
import Ingreso_details from "../../pages/inventory/ingreso_details";
import create_singleProduct from "../../pages/inventory/new_singleProduct";
import create_combo from "../../pages/inventory/new_combo";
import create_supply from "../../pages/inventory/new_supply";
import upload_supplies from "../../pages/inventory/carga_masiva_insumos";
import supply_details from "../../pages/inventory/supply_details";
import carga_masiva from "../../pages/inventory/carga_masiva";
import product_details from "../../pages/inventory/product_details";
import combo_details from "../../pages/inventory/combo_details";
import AlekoProductDetails from "../../pages/inventory/DetalleProductosAleko";
/* Translados components */
import Translados from "../../pages/translados/all";
import Translados_new from "../../pages/translados/new";
import Translados_details from "../../pages/translados/details";

/*Enterprise profile */
import EnterpriseProfile from "../../pages/enterprise_profile/profile";

/* Formats components */
import salesInvoice from "../../pages/formats_pdf/sale_invoice01";
import dummyInvoice from "../../pages/formats_pdf/dummyInvoice";
import anticipoInvoice from "../../pages/formats_pdf/anticipoInvoice";
import corteDeCajaInvoice from "../../pages/formats_pdf/corteDeCajaInvoice";
import NCInvoice from "../../pages/formats_pdf/NCInvoice";
import NDInvoice from "../../pages/formats_pdf/NDInvoice";
import viewFormat from "../../pages/formats_pdf/viewFormat";

/* Reviews */
import AllReviews from "../../pages/reviews/allReviews";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { isAleko } from "../../helpers/isAleko";
import Informes from "../../pages/InformesAleko";
import WithAleko from "../../hoc/WithAleko";
import OrderDetails from "../../pages/historial/components/OrderDetails";
import DescargasSap from "../../pages/cortes_caja/descargas_sap";
import CambioMasivoPrecios from "../../pages/inventory/CambioMasivo";
import { isGerenteMercadeo } from "../../helpers/isGerenteMercadeo";
import CargaMasivaMercadeo from "../../pages/inventory/CargaMasivaMercadeo";
import DescargarInventario from "../../pages/inventory/DescargarInventario";
import PreVenta from "../../pages/caja_pre_venta/preVenta";

function DashboardRoute({ permissions, inDebt, component, ...rest }) {
  if (!permissions.dashboard) {
    toast.error("No tiene permiso para acceder a la sección de Dashboard.", {
      autoClose: 3000,
    });
  }
  if (inDebt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          !permissions.dashboard ? (
            <Redirect
              to={{
                pathname: "/app/vender",
              }}
            />
          ) : (
            <Dashboard />
          )
        }
      />
    );
  }
}
function TrasladosRoute({ permissions, inDebt, component, ...rest }) {
  if (!permissions.traslados) {
    toast.error("No tiene permiso para acceder a la sección de traslados.", {
      autoClose: 3000,
    });
  }
  if (inDebt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          !permissions.traslados ? (
            <Redirect
              to={{
                pathname: "/app/vender",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

function InventoryRoute({ permissions, inDebt, component, ...rest }) {
  if (!permissions.inventario) {
    toast.error("No tiene permiso para acceder a la sección de inventario.", {
      autoClose: 3000,
    });
  }
  if (inDebt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          !permissions.inventario ? (
            <Redirect
              to={{
                pathname: "/app/vender",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
function CajaRoute({ inDebt, component, ...rest }) {
  if (inDebt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return <Catalogo />;
  }
}
function CajaRoutePreVenta({ inDebt, component, ...rest }) {
  if (inDebt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return <PreVenta />;
  }
}
function POSRoute({ permissions, inDebt, component, ...rest }) {
  if (!permissions.pos) {
    toast.error("No tiene permiso para acceder a la sección de POS.", {
      autoClose: 3000,
    });
  }
  if (inDebt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          !permissions.pos ? (
            <Redirect
              to={{
                pathname: "/app/vender",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
function CambioMasivoRoute({ permissions, inDebt, component, ...rest }) {
  if (!isGerenteMercadeo()) {
    toast.error("No tiene permiso para acceder a la sección de POS.", {
      autoClose: 3000,
    });
  }
  if (inDebt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          !isGerenteMercadeo() ? (
            <Redirect
              to={{
                pathname: "/app/vender",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
function UsersRoute({ permissions, inDebt, component, ...rest }) {
  if (!permissions.usuarios) {
    toast.error("No tiene permiso para acceder a la sección de usuarios", {
      autoClose: 3000,
    });
  }
  if (inDebt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          !permissions.usuarios ? (
            <Redirect
              to={{
                pathname: "/app/vender",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
function ConfigurationRoute({
  permissions,
  inDebt,
  props,
  component,
  ...rest
}) {
  if (!permissions.configuracion) {
    toast.error(
      "No tiene permiso para acceder a la sección de configuración.",
      {
        autoClose: 3000,
      }
    );
  }
  if (
    inDebt &&
    permissions.configuracion &&
    props.history.location.pathname !== "/app/profile"
  ) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          !permissions.configuracion ? (
            <Redirect
              to={{
                pathname: "/app/vender",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

function ClientRoute({ permissions, inDebt, component, ...rest }) {
  if (!permissions.clientes) {
    toast.error("No tiene permiso para acceder a la sección de clientes.", {
      autoClose: 3000,
    });
  }
  if (inDebt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/app/profile",
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          !permissions.clientes ? (
            <Redirect
              to={{
                pathname: "/app/vender",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
function Layout(props) {
  const moment = require("moment");

  var classes = useStyles();
  const default_colors = ["#1A5D9D", "#0160E7"];

  // global
  const layoutState = useLayoutState();
  const [pageLoading, setPageLoading] = useState(true);
  let currentPermissions;
  if (!!localStorage.getItem("pms")) {
    currentPermissions = JSON.parse(localStorage.getItem("pms"));
  } else {
    currentPermissions = {
      dashboard: true,
      inventario: true,
      clientes: true,
      traslados: true,
      usuarios: true,
      configuracion: true,
      pos: true,
    };
  }
  const [permissions, setPermissions] = useState(currentPermissions);
  const [sucursals, setSucursals] = useState([]);
  const [cajas, setCajas] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [inDebt, setInDebt] = useState(false);

  const getUserPermissions = () => {
    API.auth
      .getUserPermissions()
      .then((res) => {
        let response = res.data;
        console.log("Obtained permissions", response.data);
        if (response.success) {
          if (!!localStorage.getItem("pms")) {
            // Only change state if permissions are different to whats already stored
            if (JSON.stringify(response.data) !== localStorage.getItem("pms")) {
              setPermissions(response.data);
              localStorage.setItem("pms", JSON.stringify(response.data));
            }
          } else {
            setPermissions(response.data);
            localStorage.setItem("pms", JSON.stringify(response.data));
          }
        } else {
          console.log("Ocurrio un error al pedir los permisos al servidor");
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log(
          "Ocurrio un error al obtener los permisos del usuario, catch"
        );
      });
  };


  useEffect(() => {
    if (!!localStorage.getItem("color_principal")) {
      document.documentElement.style.setProperty(
        "--primary_color",
        "#" + localStorage.getItem("color_principal")
      );
    }

    if (!!localStorage.getItem("color_secundario")) {
      document.documentElement.style.setProperty(
        "--secondary_color",
        "#" + localStorage.getItem("color_secundario")
      );
    }
    /* getSucursals(); */
    getSucursals2();
    getUserSubscription();
    getUserPermissions();
    getCompanyColors();
    
  }, []);

  const getCompanyColors = () => {
    API.profile
      .getColors()
      .then((res) => {
        let response = res.data;
        if (response.success) {
          let corrected_color;
          if (response.data.color_principal) {
            corrected_color = "#" + response.data.color_principal;
            localStorage.setItem("color_principal", corrected_color);
            document.documentElement.style.setProperty(
              "--primary_color",
              corrected_color
            );
          } else {
            localStorage.setItem("color_principal", default_colors[0]);
            document.documentElement.style.setProperty(
              "--primary_color",
              default_colors[0]
            );
          }
          if (response.data.color_secundario) {
            corrected_color = "#" + response.data.color_secundario;
            localStorage.setItem("color_secundario", corrected_color);
            document.documentElement.style.setProperty(
              "--secondary_color",
              corrected_color
            );
          } else {
            localStorage.setItem("color_secundario", default_colors[1]);
            document.documentElement.style.setProperty(
              "--secondary_color",
              default_colors[1]
            );
          }
        } else {
          console.log("Ocurrio un error al pedir los colores de la empresa");
        }
        setPageLoading(false);
      })
      .catch((err) => {
        setPageLoading(false);
        let response = err.response;
        console.log(response);
        console.log(
          "Ocurrio un error al obtener los colores de la empresa, catch"
        );
      });
  };

  const getUserSubscription = () => {
    API.subscriptions
      .getUserSubscriptions()
      .then((res) => {
        let response = res.data;
        if (!response.success) {
          setInDebt(true);
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(err);
        console.log(response);
        if (response?.data) {
          if (response.data?.message) {
            toast.error(response?.message, {
              autoClose: 10000,
            });
          }
        } else {
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSucursals = () => {
    API.userConfig
      .getSucursals({})
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.success) {
          if (!!localStorage.getItem("tipo")) {
            let tipo_usuario = parseInt(localStorage.getItem("tipo"));
            if (tipo_usuario === 1) {
              setSucursals(response.data);
              getCajas(response.data[0].id_sucursal_bodega_ubicacion);
            } else {
              let sucursal_usuario = parseInt(
                localStorage.getItem("id_sucursal")
              );
              response.data.filter(
                (x) => x.id_sucursal_bodega_ubicacion === sucursal_usuario
              );
              setSucursals(response.data);
              getCajas(response.data[0].id_sucursal_bodega_ubicacion);
            }
          } else {
            setSucursals(response.data);
            getCajas(response.data[0].id_sucursal_bodega_ubicacion);
          }
        } else {
          toast.warning(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response.data;
        console.log(response);
        if (typeof response.message !== "undefined") {
          toast.error(response.message, {
            autoClose: 10000,
          });
        } else {
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSucursals2 = async () => {
    try {
      const { data } = await API.userConfig.getSucursals(true);
      localStorage.setItem(
        "store",
        JSON.stringify({
          id_sucursal_bodega_ubicacion:
            data.data[0].id_sucursal_bodega_ubicacion,
          nombre_sucursal: data.data[0].nombre_sucursal,
        })
      );
      getCajas(data.data[0].id_sucursal_bodega_ubicacion, true);
    } catch (error) {
      console.log(error);
    }
  };

  const getCajas = (value, check) => {
    API.cajas
      .getCajas({ id_sucursal_bodega_ubicacion: value }, check)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          localStorage.setItem(
            "caja",
            JSON.stringify({
              id_caja_web_app: response.data[0].id_caja_web_app,
              descripcion: response.data[0].descripcion,
            })
          );
          setCajas(response.data);
          getSellers(response.data[0].id_caja_web_app, true);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        if (typeof err?.response?.data !== "undefined") {
          let response = err?.response?.data;
          toast.error(response?.message, { autoClose: 10000 });
        } else {
          let response = err?.response;
          console.log(response);
          console.log("Ha ocurrido un error");
          toast.error("Ha ocurrido un error", { autoClose: 10000 });
        }
      });
  };

  const getSellers = (value, check) => {
    API.cajas
      .getVendedores({ id_caja_web_app: value }, check)
      .then((res) => {
        let response = res.data;
        if (response.success) {
          localStorage.setItem(
            "seller",
            JSON.stringify({
              id_vendedor_web_app: response.data[0].id_vendedor_web_app,
              nombre: response.data[0].nombre,
            })
          );
          setSellers(response.data);
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        let response = err.response;
        console.log(response);
        console.log("Ha ocurrido un error");
        toast.error("Ha ocurrido un error", { autoClose: 10000 });
      });
  };

  return (
    <div className={classes.root}>
      {pageLoading ? (
        <CircularProgress
          size={30}
          className="d-block mx-auto"
          style={{
            color: "black",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : (
        <>
          <Header history={props.history} />
          <Sidebar permissions={permissions} />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              {/* <Route path="/app/dashboard" component={Dashboard} /> */}
              <DashboardRoute
                permissions={permissions}
                inDebt={inDebt}
                path="/app/dashboard"
                component={Dashboard}
              />
              {/* Users routes */}
              <UsersRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/users"
                component={UserAll}
              />
              <UsersRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/users/new"
                component={UserNew}
              />
              {/* Cortes de cajas routes */}
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/cortes_caja/historial"
                component={CortesCajas_historial}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/descargas_sap"
                component={DescargasSap}
              />
              <CambioMasivoRoute
                inDebt={inDebt}
                exact
                path="/app/inventario/cambio-masivo-precios"
                component={CambioMasivoPrecios}
              />
              <CambioMasivoRoute
                inDebt={inDebt}
                exact
                path="/app/inventario/carga-productos"
                component={CargaMasivaMercadeo}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/cortes_caja/realizar_corte"
                component={CortesCajas_realizarCorte}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/cortes_caja/details/:id"
                component={CortesCajas_detallesCorte}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/cortes_caja/recibo"
                component={corteDeCajaInvoice}
              />
              {/* User config routes */}
              <ConfigurationRoute
                props={props}
                inDebt={inDebt}
                permissions={permissions}
                exact
                path="/app/config"
                component={UserConfig}
              />
              <ConfigurationRoute
                props={props}
                inDebt={inDebt}
                permissions={permissions}
                exact
                path="/app/config/surcursal/new"
                component={NewSucursal}
              />
              <ConfigurationRoute
                props={props}
                inDebt={inDebt}
                permissions={permissions}
                exact
                path="/app/config/surcursal/:id/cajas"
                component={EditCajas}
              />
              <ConfigurationRoute
                props={props}
                inDebt={inDebt}
                permissions={permissions}
                exact
                path="/app/profile"
                component={EnterpriseProfile}
              />
              {/* Clients routes */}
              <ClientRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/clients"
                component={ClientsAll}
              />{" "}
              {/*  MISSING */}
              <ClientRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/clients/new"
                component={ClientNew}
              />{" "}
              {/*  MISSING */}
              <ClientRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/reviews"
                component={AllReviews}
              />{" "}
              {/*  MISSING */}
              <ClientRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/clients/profile_alternative"
                component={ClientProfile}
              />{" "}
              {/*  MISSING */}
              <ClientRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/clients/profile/:nit"
                component={ClientProfile_extended}
              />{" "}
              {/*  MISSING */}
              {/* Caja de venta */}
              <Route
                exact
                path="/app/vender/procesar"
                component={Sell_step_1}
              />
              <Route
                exact
                path="/app/vender/resumen/:id"
                component={Sell_step_2}
              />
              <Route exact path="/app/vender/credito" component={Sell_step_3} />
              <Route
                exact
                path="/app/vender/recibo-credito/:id"
                component={Sell_step_3_recibo_credito}
              />
              <Route exact path="/app/anticipo/crear" component={Anticipo_1} />
              <Route
                exact
                path="/app/anticipo/resumen"
                component={Anticipo_2}
              />
              <Route exact path="/app/facturacion" component={Facturacion} />
              <CajaRoute
                exact
                permissions={permissions}
                inDebt={inDebt}
                path="/app/vender"
                component={Catalogo}
              />
              <Route
                exact
                path="/app/vender/factura"
                component={salesInvoice}
              />
              <Route exact path="/app/dummyinvoice" component={dummyInvoice} />
              {/* Copia Caja de venta */}
              <Route
                exact
                path="/app/vender/procesardev"
                component={Sell_step_1_dev}
              />
              <Route
                exact
                path="/app/vender/resumen"
                component={Sell_step_2_dev}
              />
              <Route
                exact
                path="/app/vender/credito"
                component={Sell_step_3_dev}
              />
              <Route
                exact
                path="/app/vender/recibo-credito/:id"
                component={Sell_step_3_recibo_credito_dev}
              />
              <Route
                exact
                path="/app/anticipo/crear"
                component={Anticipo_1_dev}
              />
              <Route
                exact
                path="/app/anticipo/resumen"
                component={Anticipo_2_dev}
              />
              <Route
                exact
                path="/app/facturacion"
                component={Facturacion_dev}
              />
              <CajaRoute exact path="/app/venderdev" component={Catalogo_dev} />
              <Route
                exact
                path="/app/vender/confirmar/:id"
                component={ConfirmOrder}
              />
              <Route
                exact
                path="/app/inventario/product_personalizable_details/:id"
                component={AlekoProductDetails}
              />
              <Route
                exact
                path="/app/vender/customization"
                render={() => (
                  <WithAleko>
                    <CustomizationProducts />
                  </WithAleko>
                )}
              />
              <Route
                exact
                path="/app/inventario/descargas-inventario"
                render={() => <DescargarInventario />}
              />
              <Route
                exact
                path="/app/operaciones_historial/orden_aleko/:id"
                component={OrderDetails}
              />
              {/*  Historial */}
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/operaciones_historial"
                component={HistorialOperations}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/operaciones_historial/crear_anulacion"
                component={Anulacion_new}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/operaciones_historial/resumen_anulacion/:id"
                component={Anulacion_resumen}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/operaciones_historial/nota_credito/emitir"
                component={nota_credito_emitir}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/operaciones_historial/nota_credito/:id"
                component={nota_credito_resumen}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/operaciones_historial/nota_debito/crear"
                component={nota_debito_crear}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/operaciones_historial/nota_debito/:id"
                component={nota_debito_resumen}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/nota_credito/factura"
                component={NCInvoice}
              />
              <POSRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/nota_debito/factura"
                component={NDInvoice}
              />
              {/* Inventory */}
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario"
                component={Inventory}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/new_egreso"
                component={Inventory_newEgreso}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/confirm_egreso"
                component={Inventory_confirmEgreso}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/egreso_details/:id"
                component={Egreso_details}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/new_ingreso"
                component={Inventory_newIngreso}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/ingreso_details/:id"
                component={Ingreso_details}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/add_product"
                component={create_singleProduct}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/add_combo"
                component={create_combo}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/add_supply"
                component={create_supply}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/upload_supplies"
                component={upload_supplies}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/supply_details/:id"
                component={supply_details}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/carga_masiva"
                component={carga_masiva}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/product_details/:id"
                component={product_details}
              />
              <InventoryRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/inventario/combo_details/:id"
                component={combo_details}
              />
              {/*  Traslados */}
              <TrasladosRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/traslados"
                component={Translados}
              />
              <TrasladosRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/traslados/new"
                component={Translados_new}
              />
              <TrasladosRoute
                permissions={permissions}
                inDebt={inDebt}
                exact
                path="/app/traslados/details/:id"
                component={Translados_details}
              />
              {/* Formats */}
              <Route
                exact
                path="/app/anticipo/factura"
                component={anticipoInvoice}
              />
              <Route exact path="/app/formatos/:id" component={viewFormat} />
              <Route exact path="/app/informes" component={Informes} />
              <CajaRoutePreVenta
                exact
                permissions={permissions}
                inDebt={inDebt}
                path="/app/pre-vender"
                component={PreVenta}
              />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <p style={{ color: "#828282" }}>
                  Cloud POS | Copyright {moment().get("year")}
                </p>
              </div>
            </Box>
          </div>
        </>
      )}
    </div>
  );
}

export default withRouter(Layout);
