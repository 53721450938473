import React from "react";

//estilos
import "./reportes.css";

import moment from "moment";

const InsumosReporte = ({ objeto }) => {
  const formaterPriceFunction = (value) => {
    return value
      .replace(/(?!\.)\D/g, "")
      .replace(/(?<=\..*)\./g, "")
      .replace(/(?<=\.\d\d).*/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div style={{ display: "none" }}>
      <table className="tableReporte" id="insumos-reporte-table">
        <thead>
          <tr>
            <th>Nombre / Codigo</th>
            <th>Vencimiento</th>
            <th>Inventario</th>
            <th>Unidad / Medida</th>
          </tr>
        </thead>

        <tbody>
          {objeto.map((x, index) => {
            console.log(x);

            return (
              <tr key={index}>
                <td>
                  {" "}
                  {x.nombre} / {x.codigo}
                </td>
                <td>
                  {x.vencimiento_fecha === "N/A"
                    ? "N/A"
                    : moment(x.vencimiento_fecha).format("DD-MM-YYYY")}{" "}
                </td>
                <td>{x.stock} </td>
                <td> {x.unidad}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default InsumosReporte;
