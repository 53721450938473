// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { useForm } from "react-hook-form";
import API from "../../../api";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// styles
import "./custom.css";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

// components

//data

export default function Users_all(props) {
  let history = useHistory();
  const { register, handleSubmit } = useForm();

  // states
  const [caja_list, setCaja_list] = useState([]);
  const [edition, setEdition] = useState(false);
  const [sucursalLoader, setSucursalLoader] = useState(true);
  const [sucursal, setSucursal] = useState({
    codigo_sucursal: "",
    nombre_sucursal: "",
    direccion_sucursal: "",
    telefono_sucursal: "",
    id_sucursal_bodega_ubicacion: "",
  });

  //cleap up - componentWillUnmount
  useEffect(() => {
    return () => {
      $("#confirmExit").modal("hide");
    };
  }, []);

  useEffect(() => {
    if (typeof props.history.location.state !== "undefined") {
      getSucursals(props.history.location.state.id);
      setEdition(true);
    } else {
      setSucursalLoader(false);
    }
  }, []);

  //function
  const add_caja = () => {
    let caja_name = $("#caja-input").val();

    if (caja_name !== "") {
      if (!!edition) {
        let data = {
          caja_nombre: caja_name,
          id_sucursal_bodega_ubicacion: sucursal.id_sucursal_bodega_ubicacion,
        };
        addCaja_api(data);
      } else {
        setCaja_list(
          caja_list.concat([
            {
              descripcion: caja_name,
            },
          ])
        );
        $("#caja-input").val("");
      }
    }
  };

  const send_sucursal = (data) => {
    console.log(data);
    let validForm = true;
    if (validForm) {
      if (!!edition) {
        setSucursalLoader(true);
        data.id_sucursal_bodega_ubicacion =
          sucursal.id_sucursal_bodega_ubicacion;
        API.sucursal
          .updateSucursal(data)
          .then((res) => {
            let response = res.data;
            if (response.success) {
              toast.success("Sucursal actualizada.", {
                autoClose: 10000,
              });

              console.log("Obtained this data:", response.data);
              setSucursal(response.data);
              props.history.goBack();
            } else {
              if (response.message) {
                toast.error(response.message, {
                  autoClose: 10000,
                });
              } else {
                toast.error("Ocurrió un error.", {
                  autoClose: 10000,
                });
              }
            }
            setSucursalLoader(false);
          })
          .catch((err) => {
            setSucursalLoader(false);
            let response = err.response;
            console.log(response);
            console.log("Ha ocurrido un error");
            if (response.data.message) {
              toast.error(response.data.message, {
                autoClose: 10000,
              });
            } else {
              toast.error("Ocurrió un error.", {
                autoClose: 10000,
              });
            }
          });
      } else {
        let aux = [];
        caja_list.map((caja) => {
          aux.push(caja.descripcion);
        });
        data.cajas = aux.join(",");
        data.id_new_empresa = props.match.params.id;
        if (caja_list.length > 0) {
          setSucursalLoader(true);
          API.sucursal
            .createNew(data)
            .then((res) => {
              let response = res.data;
              if (response.success) {
                toast.success("Sucursal añadida con éxito", {
                  autoClose: 10000,
                });
                props.history.goBack();
              } else {
                if (response.message) {
                  toast.error(response.message, {
                    autoClose: 10000,
                  });
                } else {
                  toast.error("Ocurrió un error.", {
                    autoClose: 10000,
                  });
                }
              }
              setSucursalLoader(false);
            })
            .catch((err) => {
              setSucursalLoader(false);
              let response = err.response;
              console.log(response);
              console.log("Ha ocurrido un error");
              if (response.data.message) {
                toast.error(response.data.message, {
                  autoClose: 10000,
                });
              } else {
                toast.error("Ocurrió un error.", {
                  autoClose: 10000,
                });
              }
            });
        } else {
          toast.error("Debe introducir al menos una caja.", {
            autoClose: 10000,
          });
        }
      }
    }
  };

  const getSucursals = (id) => {
    API.admin_clients
      .getClientStores({ id_new_empresa: props.match.params.id })
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success) {
          let filteredSucursals = response.data.filter(
            (x) => x.id_sucursal_bodega_ubicacion === id
          );
          console.log("Obtained data:", filteredSucursals[0]);
          setSucursal(filteredSucursals[0]);
          let cajas = [];
          filteredSucursals[0].cajas.map((caja) => {
            cajas.push({
              descripcion: caja.descripcion,
              id_caja_web_app: caja.id_caja_web_app,
            });
          });
          setCaja_list(cajas);
          setSucursalLoader(false);
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.warning("Ha ocurrido un error al obtener las sucursales", {
              autoClose: 10000,
            });
          }
        }
        setSucursalLoader(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error("Ha ocurrido un error al obtener las sucursales", {
          autoClose: 10000,
        });
        setSucursalLoader(false);
      });
  };

  const deleteCaja = (id, i) => {
    if (!!edition) {
      if (caja_list.length > 1) {
        API.sucursal
          .deleteCaja({ id_caja_web_app: id })
          .then((res) => {
            let response = res.data;
            console.log(response);
            if (response.success) {
              setCaja_list(
                caja_list.filter((caja) => caja.id_caja_web_app !== id)
              );
            } else {
              toast.error(response.message, {
                autoClose: 10000,
              });
            }
          })
          .catch((err) => {
            let response = err.response.data;
            console.log(response);
            if (typeof response.message !== "undefined") {
              toast.error(response.message, {
                autoClose: 10000,
              });
            } else {
              console.log("Ha ocurrido un error");
              toast.error("Ha ocurrido un error", { autoClose: 10000 });
            }
          });
      } else {
        toast.error("No puedes eliminar todas las cajas", { autoClose: 10000 });
      }
    } else {
      setCaja_list(caja_list.filter((caja, index) => index !== i));
    }
  };

  const updateCaja = (event, id) => {
    if (event.target.value !== "") {
      let data = {
        id_caja_web_app: id,
        id_sucursal_bodega_ubicacion: sucursal.id_sucursal_bodega_ubicacion,
        caja_nombre: event.target.value,
        id_new_empresa: parseInt(props.match.params.id),
      };
      API.sucursal
        .updateCaja(data)
        .then((res) => {
          let response = res.data;
          console.log(response);
          if (response.success) {
            let cajas = [];
            response.data.map((caja) => {
              cajas.push({
                descripcion: caja.descripcion,
                id_caja_web_app: caja.id_caja_web_app,
              });
            });
            setCaja_list(cajas);
          } else {
            toast.error(response.message, {
              autoClose: 10000,
            });
          }
        })
        .catch((err) => {
          let response = err.response.data;
          console.log(response);
          if (typeof response.message !== "undefined") {
            toast.error(response.message, {
              autoClose: 10000,
            });
          } else {
            console.log("Ha ocurrido un error");
            toast.error("Ha ocurrido un error", { autoClose: 10000 });
          }
        });
    }
  };

  const addCaja_api = (data) => {
    data.id_new_empresa = parseInt(props.match.params.id);
    API.sucursal
      .addCaja(data)
      .then((res) => {
        let response = res.data;
        console.log(res);
        if (response.success && response.data) {
          let cajas = [];
          response.data.map((caja) => {
            cajas.push({
              descripcion: caja.descripcion,
              id_caja_web_app: caja.id_caja_web_app,
            });
          });
          setCaja_list(cajas);
          $("#caja-input").val("");
        } else {
          if (response.message) {
            toast.error(response.message, { autoClose: 10000 });
          } else {
            toast.error("Ha ocurrido un error al agregar la caja", {
              autoClose: 10000,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("Ha ocurrido un error.", {
          autoClose: 10000,
        });
      });
  };

  return (
    <div id="dashboard-section">
      {/* Modal */}
      <div class="modal fade" id="confirmExit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>
                <strong>¿Seguro que desea salir?</strong>
              </h4>
              <p>Perderá toda la información que no se ha guardardo.</p>

              <div class="d-flex justify-content-between">
                <Button
                  className="btn-cs1 delete-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => props.history.goBack()}
                >
                  Sí, salir sin guardar
                </Button>
                <Button
                  className="btn-cs1 "
                  variant="contained"
                  color="primary"
                  data-dismiss="modal"
                >
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-title mb-4">
        <HomeIcon className="d-none d-sm-block" />
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2 d-none d-sm-block"
        />
        <h4 className="mb-0">
          <strong>Configuración de cuenta</strong>
        </h4>
        <ArrowForwardIosIcon
          style={{ color: "#333333", fontSize: "12px" }}
          className="mx-2"
        />
        <h4 className="mb-0">
          <strong>Creación de sucursal</strong>
        </h4>
      </div>

      <div className="content-card card mt-3">
        <form onSubmit={handleSubmit(send_sucursal)} id="sucursal_form">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <h5 className="mb-0">
                <strong>Agregar nueva sucursal</strong>
              </h5>

              <div>
                <Button
                  className="btn-cs1 mx-1 mt-1 delete-btn"
                  variant="contained"
                  color="primary"
                  data-toggle="modal"
                  data-target="#confirmExit"
                >
                  Cancelar
                </Button>
                <Button
                  className="btn-cs1 mx-1 mt-1"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {edition ? "Actualizar sucursal" : "Guardar sucursal"}
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            {sucursalLoader ? (
              <div className="text-center">
                <CircularProgress size={26} className="mx-auto" />
              </div>
            ) : (
              <div className="row mb-4">
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Código</strong>
                  </label>

                  <TextField
                    className="w-100"
                    placeholder="Ingrese el código de su sucursal"
                    inputRef={register}
                    name="codigo"
                    required={true}
                    defaultValue={sucursal.codigo_sucursal}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Nombre de la sucursal</strong>
                  </label>

                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el nombre de la sucursal"
                    inputRef={register}
                    name="nombre"
                    required={true}
                    defaultValue={sucursal.nombre_sucursal}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Dirección de la sucursal</strong>
                  </label>

                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Ingrese la dirección de la sucursal"
                    inputRef={register}
                    name="direccion"
                    required={true}
                    defaultValue={sucursal.direccion_sucursal}
                  />
                </div>

                {/* Second Row */}
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Teléfono de la sucursal</strong>
                  </label>

                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="(          )            -              -"
                    inputRef={register}
                    name="telefono"
                    required={true}
                    defaultValue={sucursal.telefono_sucursal}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Código de establecimiento</strong>
                  </label>

                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Ingrese el código del establecimiento"
                    inputRef={register}
                    name="establecimiento_codigo"
                    required={true}
                    defaultValue={sucursal.descripcion_ubicacion}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <label style={{ fontSize: "0.8rem" }}>
                    <strong>Ubicación o bodega</strong>
                  </label>

                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Ingrese la ubicación o bodega"
                    inputRef={register}
                    name="bodega_ubicacion"
                    required={true}
                    defaultValue={sucursal.descripcion_bodega}
                  />
                </div>

                {/* Third Row */}
                <div className="col-md-4 mt-3"></div>
                <div className="col-md-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label style={{ fontSize: "0.8rem" }}>
                      <strong>Creación de caja</strong>
                    </label>
                  </div>
                  <p>
                    Puedes crear cajas para luego asignarla a sus vendedores
                  </p>
                  <div className="d-flex justify-content-end align-items-end">
                    <TextField
                      className="w-100"
                      placeholder="Nombre de caja"
                      id="caja-input"
                      inputRef={register}
                      name="caja_nombre"
                      required={false}
                    />

                    <IconButton
                      className="mx-1 mt-1 icon-btn-primary"
                      onClick={() => add_caja()}
                      size="small"
                    >
                      <AddIcon style={{ color: "white" }} />
                    </IconButton>
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  {caja_list.map((caja, index) => (
                    <>
                      <label style={{ fontSize: "0.8rem" }}>
                        <strong>Nombre de caja</strong>
                      </label>

                      <div
                        className="d-flex align-items-center pl-2 mb-2"
                        style={{
                          boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.06)",
                        }}
                      >
                        {!!edition ? (
                          <TextField
                            className="w-100"
                            placeholder="Nombre de caja"
                            id="caja_descripcion"
                            inputRef={register}
                            name="caja_descripcion"
                            required={true}
                            defaultValue={caja.descripcion}
                            onBlur={(e) => updateCaja(e, caja.id_caja_web_app)}
                          />
                        ) : (
                          <span
                            style={{
                              flex: "1",
                              color: "black",
                            }}
                          >
                            {caja.descripcion}
                          </span>
                        )}

                        <IconButton
                          className="btn-cs1 icon-btn-delete"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            deleteCaja(caja.id_caja_web_app, index)
                          }
                          size="small"
                        >
                          <DeleteIcon
                            style={{
                              color: "white",
                            }}
                          />
                        </IconButton>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
