import React, { Fragment, useState, useEffect, useContext } from "react";
import { SketchPicker } from "react-color";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress, ClickAwayListener } from "@material-ui/core";
import AlekoContext from "../../../context/ContextoAleko/AlekoContext";
import ColorPickerContext from "../../../context/ColorPickerContext/ColorPickContext";
import ProductColor from "../ProductColors/ProductColor";
import ProductSize from "../ProductSizes/ProductSize";
import ProductPersonalization from "../ProductPersonalization/ProductPersonalization";
import { getMoneda } from "../../../helpers/moneda";
import { getPerzonalizations } from "../../../api/products";

const Parametrizacion = () => {
  const moneda = getMoneda();

  const {
    getAleko,
    colors,
    saveProductColor,
    productColor,
    setProductColor,
    almacenarColores,
    clearProductColor,
    productSize,
    setProductSize,
    almacenarSizes,
    saveProductSize,
    clearProductSize,
    sizes,
    personalizations,
    saveProductPersonalization,
    setProductPersonalization,
    productPersonalization,
    almacenarPersonalizacion,
    clearProductPersonalization,
    createAlekoProduct,
    product,

    createBase,
    setCantidad,
    inputColorValidation,
    setInputColorValidation,
    inputSizeValidation,
    inputPersonalizationValidation,
    cantidad,
    setInputSizeValidation,
    setInputPersonalizationValidation,
    deleteAleko,
    clearState,
  } = useContext(AlekoContext);

  const { showColorPicker, setShowColorPicker } =
    useContext(ColorPickerContext);

  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [colorDefault, setColorDefault] = useState("#fff");

  useEffect(() => {
    getPersonalizaciones();
    return () => clearState();
  }, []);

  const getPersonalizaciones = async () => {
    const idEmpresa = window.localStorage.getItem("id_empresa");
    try {
      const data = await getPerzonalizations(idEmpresa);
      console.log("personalizaciones ->", data);
      almacenarColores(data.data.ColorItems);
      almacenarSizes(data.data.tallaItems);
      almacenarPersonalizacion(data.data.PersonalizacionItems);
    } catch (error) {
      console.log("personalizaciones error ->", error);
    }
  };

  const handleClick = () => setShowColorPicker(!showColorPicker);

  const handleColorChange = (e) => {
    const { name, value } = e.target;
    setProductColor({
      ...productColor,
      [name]: value,
    });
  };

  const handleProductSizeChange = (e) => {
    let { name, value } = e.target;

    if (value.length >= 6) {
      value = value.slice(0, 5);
    }

    setProductSize({ ...productSize, [name]: value });
  };

  const handleProductPersonalizationChange = (e) => {
    const { name, value: inputValue } = e.target;

    let value = inputValue;

    if (name === "precio_medida" || name === "precio_minimo") {
      let string = String(value);

      if (string.includes(",")) {
        string = string.replace(",", "");
        value = Number(string);
      }
    }

    setProductPersonalization({
      ...productPersonalization,
      [name]: value,
    });
  };

  // *******************************************************
  const validateColor = () => {
    productColor.codigo_hexadecimal = colorDefault;
    saveProductColor(productColor);
    setColorDefault("#fff");
  };

  const validateSize = () => {
    productSize.id_new_empresa = window.localStorage.getItem("id_empresa");
    saveProductSize(productSize);
  };

  const validatePersonalizacion = () => {
    productPersonalization.id_new_empresa =
      window.localStorage.getItem("id_empresa");

    saveProductPersonalization(productPersonalization);
  };

  // console.log("colors ->", colors);
  // console.log("size ->", sizes);
  // console.log("personalizations", personalizations);

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <h5 className="mb-0">
            <strong>Características de productos personalizables.</strong>
          </h5>
          <p className="mb-0">
            Gestione las características disponibles para perzonalizar.
          </p>
        </div>

        <div>
          {/* <Button
            type="submit"
            className="btn-cs1_blue mx-1 mt-1"
            variant="contained"
            color="primary"
            disabled={loader}
          >
            {loader ? <CircularProgress size={26} /> : "Guardar"}
          </Button> */}
          {/* <Button
            className="btn-cs1 mx-1 mt-1 delete-btn"
            variant="contained"
            color="primary"
            data-toggle="modal"
            data-target="#confirmExit"
          >
            Cancelar
          </Button> */}
        </div>
      </div>
      <br />
      <div className="row" style={{ position: "relative" }}>
        <label className="col-lg-2">
          <strong>Colores</strong>
        </label>

        <div className="col-lg-10">
          <hr style={{ borderTop: "1px solid #a4b0be" }} />
        </div>

        {Array.isArray(colors) &&
          colors.map((item) => (
            <div className="col-md-12 row">
              <ProductColor item={item} />
            </div>
          ))}
        <div className="col-md-12 row">
          <div className="col-lg-1 text-center relative">
            <div
              className="border"
              style={{
                background:
                  productColor.codigo_hexadecimal !== ""
                    ? productColor.codigo_hexadecimal
                    : "rgb(242,242,242)",
                borderRadius: "50%",
                height: "70px",
                width: "70px",
                lineHeight: "70px",
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              <span style={{ fontSize: 20 }}>+</span>
            </div>
            {productColor.codigo_hexadecimal === "" && inputColorValidation && (
              <span className="text-danger" style={{ fontSize: 13 }}>
                Requerido
              </span>
            )}
            {showColorPicker && (
              <ClickAwayListener onClickAway={handleClick}>
                <div
                  className="card"
                  style={{
                    position: "absolute",
                    top: 70,
                    width: "241.96px",
                    flexWrap: "wrap",
                    zIndex: 100,
                  }}
                >
                  {/* <CustomPicker /> */}
                  <SketchPicker
                    color={colorDefault}
                    onChange={(updateColor) => setColorDefault(updateColor.hex)}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
          <div className="col-lg-3">
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Nombre</strong>
            </label>
            <TextField
              type="text"
              className="w-100"
              name="nombre_color"
              value={productColor.nombre_color}
              placeholder="Nombre el color"
              onChange={handleColorChange}
            />
            {productColor.nombre_color.trim() === "" &&
              inputColorValidation && (
                <span className="text-danger">Este campo es requerido</span>
              )}
          </div>
          <div className="col-lg-2 d-flex align-items-center">
            <Button
              variant="contained"
              className="mt-2 w-100"
              style={{
                background: "#35baf6",
                color: "white",
                fontSize: 18,
              }}
              onClick={validateColor}
            >
              Añadir color
            </Button>
          </div>
        </div>
        {/* <div className="col-lg-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Cantidad disponible</strong>
          </label>
          <TextField
            type="number"
            className="w-100"
            name="cantidad_color"
            onChange={handleColorChange}
            value={
              productColor.cantidad_color ? productColor.cantidad_color : ""
            }
            placeholder="Cantidad de este color"
            InputProps={{
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
          {!productColor.cantidad_color && inputColorValidation && (
            <span className="text-danger">Este campo es requerido</span>
          )}
        </div>
        <div className="col-lg-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Precio extra</strong>
          </label>
          <TextField
            type="number"
            className="w-100"
            value={productColor.precio_extra || ""}
            onChange={handleColorChange}
            name="precio_extra"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{moneda}.</InputAdornment>
              ),
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
        </div> */}
        <div className="col-md-12 mt-2">
          <div className="col-md-3">
            <div
              style={{
                width: "35px",
                height: "20px",
                border: "solid #ccc 1px",
                borderRadius: "10px",
                backgroundColor: `${colorDefault}`,
              }}
            ></div>
          </div>
        </div>

        {/* <div className="col-lg-2 d-flex align-items-center">
          <Button
            variant="contained"
            className="mt-2 w-100"
            style={{
              background: "#35baf6",
              color: "white",
              fontSize: 18,
            }}
            onClick={validateColor}
          >
            Añadir color
          </Button>
        </div> */}
      </div>

      <br />

      <div className="row">
        <label className="col-lg-2">
          <strong>Tallas</strong>
        </label>

        <div className="col-lg-10">
          <hr style={{ borderTop: "1px solid #a4b0be" }} />
        </div>

        {Array.isArray(sizes) &&
          sizes?.map((item) => (
            <div className="col-md-12 row">
              <ProductSize item={item} />
            </div>
          ))}

        <div className="col-md-12 row">
          <div className="col-md-2">
            <label style={{ fontSize: "0.8rem" }}>
              <strong>Talla</strong>
            </label>
            <TextField
              type="text"
              className="w-100"
              name="nombre_talla"
              value={productSize.nombre_talla}
              onChange={handleProductSizeChange}
              placeholder="Añada la talla"
              InputProps={{
                inputProps: { min: 0, step: "0.00001" },
              }}
            />
            {productSize.nombre_talla.trim() === "" && inputSizeValidation && (
              <span className="text-danger">Campo requerido</span>
            )}
          </div>
          <div className="col-md-2 d-flex align-items-center">
            {/* <Button
              className="btn-cs1 delete-btn w-100 text-light"
              variant="contained"
              onClick={() => clearProductSize()}
            >
              Eliminar
            </Button> */}
            <Button
              variant="contained"
              className="mt-2 w-100"
              style={{
                background: "#35baf6",
                color: "white",
                fontSize: 18,
              }}
              onClick={validateSize}
            >
              Añadir Talla
            </Button>
          </div>
        </div>
        {/* <div className="col-lg-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Cantidad disponible</strong>
          </label>
          <TextField
            type="number"
            className="w-100"
            name="cantidad_talla"
            value={productSize.cantidad_talla || ""}
            onChange={handleProductSizeChange}
            placeholder="Cantidad"
            InputProps={{
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
          {!productSize.cantidad_talla && inputSizeValidation && (
            <span className="text-danger">Campo requerido</span>
          )}
        </div>
        <div className="col-lg-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Precio extra</strong>
          </label>
          <TextField
            type="number"
            className="w-100"
            value={productSize.precio_extra || ""}
            onChange={handleProductSizeChange}
            name="precio_extra"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{moneda}.</InputAdornment>
              ),
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
        </div> */}
        {/* <div className="col-lg-2 d-flex align-items-center">
          <Button
            variant="contained"
            className="mt-2 w-100"
            style={{
              background: "#35baf6",
              color: "white",
              fontSize: 18,
            }}
            onClick={validateSize}
          >
            Añadir
          </Button>
        </div> */}
      </div>

      <br />

      <div className="row">
        <label className="col-md-2">
          <strong>Personalización</strong>
        </label>
        <div className="col-lg-10">
          <hr style={{ borderTop: "1px solid #a4b0be" }} />
        </div>
        {Array.isArray(personalizations) &&
          personalizations?.map((item) => (
            <ProductPersonalization item={item} />
          ))}
        <div className="col-md-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Personalización</strong>
          </label>
          <TextField
            type="text"
            className="w-100"
            value={productPersonalization.nombre_personalizacion}
            name="nombre_personalizacion"
            onChange={handleProductPersonalizationChange}
            placeholder="Tipo"
            InputProps={{
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
          {productPersonalization.nombre_personalizacion.trim() === "" &&
            inputPersonalizationValidation && (
              <span className="text-danger">Campo requerido</span>
            )}
        </div>
        <div className="col-md-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Precio/cm2</strong>
          </label>
          <TextField
            type="text"
            className="w-100"
            value={productPersonalization.precio_medida}
            name="precio_medida"
            onChange={handleProductPersonalizationChange}
            placeholder="Tipo"
            InputProps={{
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
          {productPersonalization.precio_medida === "" &&
            inputPersonalizationValidation && (
              <span className="text-danger">Campo requerido</span>
            )}
        </div>
        <div className="col-md-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Precio mínimo (A cobrar)</strong>
          </label>
          <TextField
            type="text"
            className="w-100"
            value={productPersonalization.precio_minimo}
            name="precio_minimo"
            onChange={handleProductPersonalizationChange}
            placeholder="Tipo"
            InputProps={{
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
          {productPersonalization.precio_minimo === "" &&
            inputPersonalizationValidation && (
              <span className="text-danger">Campo requerido</span>
            )}
        </div>
        <div className="col-md-2 d-flex align-items-center">
          {/* <Button
              className="btn-cs1 delete-btn w-100 text-light"
              variant="contained"
              onClick={() => clearProductPersonalization()}
            >
              Eliminar
            </Button> */}
          <Button
            variant="contained"
            className="mt-2 w-100"
            style={{
              background: "#35baf6",
              color: "white",
              fontSize: 18,
            }}
            onClick={validatePersonalizacion}
          >
            Añadir
          </Button>
        </div>
      </div>

      {/* <div className="col-lg-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Cantidad disponible</strong>
          </label>
          <TextField
            type="number"
            className="w-100"
            name="cantidad_personalizacion"
            onChange={handleProductPersonalizationChange}
            value={productPersonalization.cantidad_personalizacion || ""}
            placeholder="Cantidad"
            InputProps={{
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
          {!productPersonalization.cantidad_personalizacion &&
            inputPersonalizationValidation && (
              <span className="text-danger">Campo requerido</span>
            )}
        </div>
        <div className="col-lg-3">
          <label style={{ fontSize: "0.8rem" }}>
            <strong>Precio extra</strong>
          </label>
          <TextField
            type="number"
            className="w-100"
            name="precio_extra"
            value={productPersonalization.precio_extra || ""}
            onChange={handleProductPersonalizationChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{moneda}.</InputAdornment>
              ),
              inputProps: { min: 0, step: "0.00001" },
            }}
          />
        </div> */}

      {/* <div className="col-lg-2 d-flex align-items-center">
          <Button
            variant="contained"
            className="mt-2 w-100"
            style={{
              background: "#35baf6",
              color: "white",
              fontSize: 18,
            }}
            onClick={validatePersonalizacion}
          >
            Añadir
          </Button>
        </div> */}
    </Fragment>
  );
};

export default Parametrizacion;
