import { TextField } from "@material-ui/core";
import React from "react";

const GuateFacturaForm = ({
  register,
  dataEditFEL,
  setGrandesRetenedores,
  grandes_retenedores,
}) => {
  return (
    <>
      <div className="col-md-4 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>USERNAME API</strong>
        </label>

        <TextField
          name="fe_usuario"
          type="text"
          inputRef={register}
          defaultValue={dataEditFEL?.fe_usuario}
          className="w-100"
          placeholder="Ingrese el username API"
          required={true}
        />
      </div>
      <div className="col-md-4 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>PASSWORD API</strong>
        </label>

        <TextField
          name="password_api"
          defaultValue={dataEditFEL?.password_api}
          inputRef={register}
          type="text"
          className="w-100"
          placeholder="Ingrese el password API"
          required={true}
        />
      </div>
      <div className="col-md-4 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>USUARIO WEB SERVICE</strong>
        </label>

        <TextField
          name="username"
          inputRef={register}
          defaultValue={dataEditFEL?.username}
          type="text"
          className="w-100"
          placeholder="Ingrese el usuario web service"
          required={true}
        />
      </div>
      <div className="col-md-4 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>CONTRASEÑA WEB SERVICE</strong>
        </label>

        <TextField
          type="text"
          className="w-100"
          inputRef={register}
          defaultValue={dataEditFEL?.password}
          placeholder="Ingrese la contraseña web service"
          name="password"
          required={true}
        />
      </div>
      <div className="col-md-4 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>CODIGO ESTABLECIMIENTO</strong>
        </label>

        <TextField
          type="text"
          className="w-100"
          inputRef={register}
          defaultValue={dataEditFEL?.codigo_establecimiento}
          placeholder="Ingrese el codigo del establecimiento"
          name="codigo_establecimiento"
          required={true}
        />
      </div>

      <div className="col-md-4 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>NIT del emisor</strong>
          <br />
          <br />
        </label>

        <TextField
          type="text"
          className="w-100"
          inputRef={register}
          defaultValue={dataEditFEL?.fe_nit_emisor}
          placeholder="Ingrese el NIT  del emisor"
          name="fe_nit_emisor"
          required={true}
        />
      </div>
      <div className="col-md-4 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>Grandes Retenedores</strong>
        </label>
        <label
          className="toggle-control mx-auto"
          style={{ left: "-15px", height: "40px" }}
        >
          <input
            type="checkbox"
            onClick={() => setGrandesRetenedores((prev) => !prev)}
            value={grandes_retenedores}
            defaultChecked={
              dataEditFEL ? dataEditFEL.grandes_retenedores : false
            }
          />
          <span className="control" />
        </label>
      </div>

      {/* <div className="col-md-4 mt-3">
        <label style={{ fontSize: "0.8rem" }}>
          <strong>¿Entorno producción?</strong>
        </label>
        <label
          className="toggle-control mx-auto"
          style={{ left: "-15px", height: "40px" }}
        >
          <input
            type="checkbox"
            onClick={() => setEntornoProduccion((prev) => !prev)}
            value={entorno_produccion}
            defaultChecked={
              dataEditFEL ? dataEditFEL.entorno_produccion : false
            }
          />
          <span className="control" />
        </label>
      </div> */}
    </>
  );
};

export default GuateFacturaForm;
